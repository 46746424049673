import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Alert from "@mui/lab/Alert";
import CustomBreadCrumb from "../../../common/CustomBreadCrumb";
import { LoggedInUserDetails } from "../../../common/LoggedInUserDetails/LoggedInUserDetails";
import CreateIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import IconSaveOrUpdate from "@mui/icons-material/SaveOutlined";
import IconRestore from "@mui/icons-material/AutorenewOutlined";
import IconBack from "@mui/icons-material/BackspaceOutlined";
import _ from "lodash/fp";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { CommonService } from "../../../../services";
import PropTypes from "prop-types";
import Switch from "@mui/material/Switch";
import IconSettings from "@mui/icons-material/Settings";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { MobileDatePicker } from "@mui/lab";
import CommonBackDrop from "../../../common/BackDrop";
import IconUserManagement from "@mui/icons-material/AccountCircle";
import IconRole from "@mui/icons-material/SupervisorAccount";
import MenuIcon from "@mui/icons-material/Menu";
import IconBranch from "@mui/icons-material/AccountTree";
import useStyles from "../../../common/CommonStyle/CommonStyle";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import EntityCodeCombo from "../../../common/Combos/EntityCodeCombo/EntityCodeCombo";
import { ModuleAccessPermissionKey } from "../../../../Constants/ModuleAccessKey";
import { GlobalEdiApiConstants } from "../../../../Constants/GlobalEdiApiConstants";
import UserTypeComboRole from "../../../common/Combos/UserTypeComboRole";
import IconSupplier from "@mui/icons-material/LocalShipping";
import ClearIcon from "@mui/icons-material/Clear";
import ViewIcon from "@mui/icons-material/Visibility";
import IconProject from "@mui/icons-material/AccountTree";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const CreateOrEditUser = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const password = "Abs@890555";

  const { mode, userId } = props.location.state;
  const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());
  const theme = useTheme();
  const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
  const smallScreen = useMediaQuery(theme.breakpoints.down(889));
  const moduleAccessKeyPermission = ModuleAccessPermissionKey();
  const { loggedInUserInfo, setLoggedInUserInfo } = useContext(LoggedInUserDetails);
  const userModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.user];
  const roleModuleAccessForModule = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.roleAccess];
  const branchModuleAccessForModule = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.branchAccess];
  const supplierModuleAccessForModule = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.supplierAccess];
  const customerModuleAccessForModule = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.customerAccess];
  const supplierGroupModuleAccessForModule = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.supplierGroup];
  const customerGroupModuleAccessForModule = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.customerGroup];
  const projectModuleAccessForModule = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.projectAccess];
  const [defaultOrgObj, setDefaultOrgObj] = useState({});

  useEffect(() => {
    if (orgInfoOfLeggedUser.erpOrgCode && mode === "create") {
      setDefaultOrgObj({
        erpOrgCode: orgInfoOfLeggedUser.erpOrgCode,
        orgName: orgInfoOfLeggedUser.orgName,
        valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}`,
      });
    }
  }, [orgInfoOfLeggedUser, mode]);

  const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState("");
  const [componentList, setComponentList] = useState([]);
  const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
  const [userToBeEdited, setUserToBeEdited] = React.useState({});
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [isRoleAccessLoading, setRoleAccessLoading] = useState(false);
  const [isBranchAccessLoading, setBranchAccessLoading] = useState(false);

  const [roles, setRoles] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const [validFromDate, changeValidFromDate] = useState(new Date());
  const [validToDate, changeValidToDate] = useState(null);
  const [branchValue, setBranchValue] = useState("");
  const [orgValue, setOrgValue] = useState("");

  const [roleList, setRoleList] = useState([]);
  const [userBranchAccessList, setUserBranchAccessList] = useState([]);
  const [userSupplierAccessList, setUserSupplierAccessList] = useState([]);
  const [userCustomerAccessList, setUserCustomerAccessList] = useState([]);
  const [userProjectAccessList, setUserProjectAccessList] = useState([]);
  const [userSupplierGroupAccessList, setUserSupplierGroupAccessList] = useState([]);
  const [userCustomerGroupAccessList, setUserCustomerGroupAccessList] = useState([]);
  const [isSupplierAccessLoading, setSupplierAccessLoading] = useState(false);
  const [isProjectAccessLoading, setProjectAccessLoading] = useState(false);
  const currentUser = CommonService.getLoggedInUserInfo();

  const [roleModuleAccessFlagForTabVisibility, setRoleModuleAccessFlagForTabVisibility] = useState(false);
  const [branchModuleAccessFlagForTabVisibility, setBranchModuleAccessFlagForTabVisibility] = useState(false);
  const [supplierModuleAccessFlagForTabVisibility, setSupplierModuleAccessFlagForTabVisibility] = useState(false);
  const [projectModuleAccessFlagForTabVisibility, setProjectModuleAccessFlagForTabVisibility] = useState(false);
  const [supplierAccessList, setSupplierAccessList] = useState([]);
  const [restorForEditMode, isRestorForEditMode] = useState(false);
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [userReadOnlyFields, setUserReadOnlyFields] = useState({
    userName: false,
    userType: false,
    branch: false,
    active: false,
    enabled2fa: false,
    emailId: false,
    phoneNo: false,
    loginId: false,
    password: false,
    validFromDate: false,
    validToDate: false,
    salutation: false,
    designation: false,
    allowLogin: false,
    organization: false,
    entity: false,
    purchasingGroup: false,
    accountLocked: false,
  });
  const [userIdToBeSavedOrUpdated, setUserIdToBeSavedOrUpdated] = useState(0);
  const pageLabelsConstantsAndMessages = {
    label: {
      backButton: "Back",
      restoreButton: "Reset",
      branch: "Entity",
      active: "Active",
      requiredSymbol: " *",
      menuHeading: "Role",
      statusHeading: "Status",
      moduleHeading: "Module Name",
      createFlagHeading: "Create",
      editFlagHeading: "Edit",
      viewOrReadFlagHeading: "View/Read",
      deleteFlagHeading: "Delete",
      defaultEntityHeading: "Default",
      userType: "User Type",
      userName: "User Name",
      emailId: "Email Id",
      phoneNo: "Phone Number",
      loginId: "Login Id",
      password: "Password",
      validFromDate: "Valid From",
      validToDate: "Valid To",
      tabUserRoleAccess: "Role Access",
      tabUserBranchAccess: "Entity Access",
      tabUserSupplierAccess: "Supplier Access",
      tabUserCustomerAccess: "Customer Access",
      tabUserSupplierGroupAccess: "Supplier Group Access",
      tabUserCustomerGroupAccess: "Customer Group Access",
      tabUserProjectAccess: "Project Access",
      branchAccessHeading: "Entity",
      projectAccessHeading: "Project",
      updateButton: "Update",
      saveButton: "Save",
      enabled2fa: "Enable 2 Factor Authentication",
      allowLogin: "allowLogin",
      designation: "designation",
      purchasingGroup: "purchasingGroup",
      accountLocked: "accountLocked",
    },
    name: {
      userName: "userName",
      userType: "userType",
      entity: "entity",
      active: "active",
      emailId: "emailId",
      phoneNo: "phoneNo",
      loginId: "loginId",
      password: "password",
      validFromDate: "validFromDate",
      validToDate: "validToDate",
      using2FA: "using2FA",
      salutation: "salutation",
      designation: "designation",
      allowLogin: "allowLogin",
      organization: "organization",
      purchasingGroup: "purchasingGroup",
      accountLocked: "accountLocked",
    },
  };
  const [alertBoxState, setAlertBoxState] = React.useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "",
  });
  const handleCloseAlert = () => {
    setAlertBoxState({ ...alertBoxState, openAlert: false });
  };
  const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

  const [searchedVendorName, setSearchedVendorName] = React.useState("");
  const [searchedCustomerName, setSearchedCustomerName] = React.useState("");
  const [searchedSupplierGroupName, setSearchedSupplierGroupName] = React.useState("");
  const [searchedCustomerGroupName, setSearchedCustomerGroupName] = React.useState("");
  const [searchedProjectName, setSearchedProjectName] = React.useState("");
  const [userTabsArray, setUserTabsArray] = React.useState([]);
  const [tabVisibilityPermissionChanged, setTabVisibilityPermissionChanged] = React.useState(false);
  const [using2FAOrActiveToBeEdited, setUsing2FAOrActiveToBeEdited] = useState({});
  const [value, setTabIndexValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabIndexValue(newValue);
  };
  const commonPatterns = GlobalEdiApiConstants();
  const [usertypeRoleUser, setUserTypeRoleUser] = useState("");
  const [disableSaveOrUpdateButton, issaveOrUpdateButtonDisabled] = useState(false);
  const [disabledForReadMode, isDisabledForReadMode] = useState(false);
  const { handleSubmit, formState: { errors }, reset, control, setValue, clearErrors } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      organization: "",
      userName: "",
      userType: "",
      branch: null,
      active: true,
      emailId: "",
      phoneNo: "",
      loginId: "",
      password: "",
      validFromDate: new Date(),
      validToDate: "",
      using2FA: false,
      salutation: "",
      designation: "",
      allowLogin: true,
      purchasingGroup: "",
      accountLocked: false,
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  useEffect(() => {
    if (mode === "edit" || mode === "read") {
      CommonService.getByIdApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.user, userId)
        .then((response) => {
          setUserToBeEdited(response.data);
          let tempArray = [];
          response.data.roles.forEach((element) => {
            tempArray.push({ id: element.id });
          });
          setRoles(tempArray);
          userResettingValues(response.data);
          setOpenBackDrop(false);
        })
        .catch(function (error) {
          setAlertBoxState({
            ...alertBoxState,
            openAlert: true,
            message: error.response.data.message,
            severity: "error",
          });
          setOpenBackDrop(false);
        });
    }
  }, [restorForEditMode]);

  function userResettingValues(responseData) {
    reset({
      organization:
        responseData.organization !== undefined ? {
          erpOrgCode: responseData.organization.erpOrgCode, orgName: responseData.organization.orgName,
        } : defaultOrgObj,
      userName: responseData.userName !== undefined ? responseData.userName : "",
      userType: responseData.userType,
      entity: responseData.entity !== undefined ? {
        erpEntityCode: responseData.entity.erpEntityCode,
        entityName: responseData.entity.entityName,
      } : null,
      active: responseData.active !== undefined ? responseData.active : true,
      emailId: responseData.emailId !== undefined ? responseData.emailId : "",
      phoneNo: responseData.phoneNo !== undefined ? responseData.phoneNo : "",
      loginId: responseData.loginId !== undefined ? responseData.loginId : "",
      password: responseData.password !== undefined ? responseData.password : mode === "edit" && responseData.allowLogin ? "Abs@890555" : "Abs@890555",
      allowLogin: responseData.allowLogin !== undefined ? responseData.allowLogin : true,
      accountLocked:
        responseData.accountLocked !== undefined
          ? responseData.accountLocked
          : false,
      designation:
        responseData.designation !== undefined ? responseData.designation : "",
      purchasingGroup:
        responseData.purchasingGroup !== undefined
          ? responseData.purchasingGroup
          : "",
      salutation:
        responseData.salutation !== undefined ? responseData.salutation : "",
      validFromDate:
        responseData.validFromDate !== undefined
          ? responseData.validFromDate
          : "",
      validToDate:
        responseData.validToDate !== null &&
          responseData.validToDate !== undefined
          ? responseData.validToDate
          : "",
      using2FA:
        responseData.using2FA !== undefined ? responseData.using2FA : false,
      preferedLanguage:
        responseData.preferedLanguage !== undefined
          ? responseData.preferedLanguage
          : { language: "ENGLISH", value: "en" },
      superUser: responseData.superUser !== undefined ? responseData.superUser : false
    });
    setOrgValue(
      responseData.organization !== undefined
        ? { valueToBeDisplayed: responseData.organization }
        : defaultOrgObj
    );
    setBranchValue(
      responseData.entity !== undefined
        ? { valuesToBeDisplayed: responseData.entity }
        : []
    );
    setUserTypeRoleUser(
      responseData.userType !== undefined
        ? { value: responseData.userType }
        : ""
    );
    changeValidFromDate(new Date(responseData.validFromDate));
    changeValidToDate(
      responseData.validToDate !== null &&
        responseData.validToDate !== undefined
        ? new Date(responseData.validToDate)
        : null
    );
  }

  useEffect(() => {
    if (mode === "create") {
      resetAllClearThePageInfo();
    }
  }, [defaultOrgObj]);

  useEffect(() => {
    setOpenBackDrop(true);
    let currentRouterComponent;
    setValue(
      `${pageLabelsConstantsAndMessages.name.validFromDate}`,
      validFromDate,
      { shouldValidate: true, shouldDirty: true }
    );
    if (mode === "create" && userId === 0) {
      setSaveOrUpdateButtonLabel(
        <FormattedMessage id="common.button.save" defaultMessage="Save" />
      );
      isRestoreButtonDisabled(false);
      setRoleModuleAccessFlagForTabVisibility(
        roleModuleAccessForModule !== undefined
          ? roleModuleAccessForModule.createFlag
          : false
      );
      setBranchModuleAccessFlagForTabVisibility(
        branchModuleAccessForModule !== undefined
          ? branchModuleAccessForModule.createFlag
          : false
      );
      setSupplierModuleAccessFlagForTabVisibility(
        supplierModuleAccessForModule !== undefined
          ? supplierModuleAccessForModule.createFlag
          : false
      );
      setProjectModuleAccessFlagForTabVisibility(
        projectModuleAccessForModule !== undefined
          ? projectModuleAccessForModule.createFlag
          : false
      );
      currentRouterComponent = {
        path: undefined,
        name: (
          <FormattedMessage
            id="common.button.create.breadcrumb.tooltip"
            defaultMessage="Create"
          />
        ),
        iconName: CreateIcon,
      };

      setTabVisibilityPermissionChanged(true);
      changeValidFromDate(new Date());
      resetAllClearThePageInfo();
      setOpenBackDrop(false);
    } else if (mode === "edit") {
      setSaveOrUpdateButtonLabel(
        <FormattedMessage id="common.button.update" defaultMessage="Update" />
      );
      isRestoreButtonDisabled(false);
      setUserReadOnlyFields({
        ...userReadOnlyFields,
        userType: true,
        userName: false,
        loginId: true,
        password: true,
        organization: true,
        branch: true,
      });

      setRoleModuleAccessFlagForTabVisibility(
        roleModuleAccessForModule !== undefined
          ? roleModuleAccessForModule.editFlag
          : false
      );
      setBranchModuleAccessFlagForTabVisibility(
        branchModuleAccessForModule !== undefined
          ? branchModuleAccessForModule.editFlag
          : false
      );
      setSupplierModuleAccessFlagForTabVisibility(
        supplierModuleAccessForModule !== undefined
          ? supplierModuleAccessForModule.editFlag
          : false
      );
      setProjectModuleAccessFlagForTabVisibility(
        projectModuleAccessForModule !== undefined
          ? projectModuleAccessForModule.editFlag
          : false
      );
      currentRouterComponent = {
        path: undefined,
        name: (
          <FormattedMessage
            id="common.tooltip.breadcrumb.edit"
            defaultMessage="Edit"
          />
        ),
        iconName: EditIcon,
      };
      setTabVisibilityPermissionChanged(true);
      userResettingValues(userToBeEdited);
    } else if (mode === "read") {
      setSaveOrUpdateButtonLabel(
        <FormattedMessage id="common.button.update" defaultMessage="Update" />
      );
      isDisabledForReadMode(true);
      isRestoreButtonDisabled(true);
      issaveOrUpdateButtonDisabled(true);
      setUserReadOnlyFields({
        ...userReadOnlyFields,
        userType: true,
        userName: false,
        loginId: true,
        password: true,
        organization: true,
        branch: true,
      });

      setRoleModuleAccessFlagForTabVisibility(
        roleModuleAccessForModule !== undefined
          ? roleModuleAccessForModule.readFlag
          : false
      );
      setBranchModuleAccessFlagForTabVisibility(
        branchModuleAccessForModule !== undefined
          ? branchModuleAccessForModule.readFlag
          : false
      );
      setSupplierModuleAccessFlagForTabVisibility(
        supplierModuleAccessForModule !== undefined
          ? supplierModuleAccessForModule.readFlag
          : false
      );
      setProjectModuleAccessFlagForTabVisibility(
        projectModuleAccessForModule !== undefined
          ? projectModuleAccessForModule.readFlag
          : false
      );
      currentRouterComponent = {
        path: undefined,
        name: (
          <FormattedMessage
            id="common.button.view.breadcrumb.tooltip"
            defaultMessage="View"
          />
        ),
        iconName: ViewIcon,
      };
      setTabVisibilityPermissionChanged(true);
    }
    setComponentList([
      {
        path: null,
        name: (
          <FormattedMessage
            id="common.menu.breadcrumb.settings"
            defaultMessage="Settings"
          />
        ),
        iconName: IconSettings,
      },
      {
        path: "/users",
        name: (
          <FormattedMessage
            id="common.module.breadcrumb.user"
            defaultMessage="User"
          />
        ),
        iconName: IconUserManagement,
      },
      currentRouterComponent,
    ]);
  }, [mode, userId, restorForEditMode]);

  useEffect(() => {
    setUsing2FAOrActiveToBeEdited(userToBeEdited);
    setUsing2FAOrActiveToBeEditedUser(userToBeEdited);
  }, [userToBeEdited]);

  const onSubmit = (data) => {
    data["id"] = userIdToBeSavedOrUpdated;
    data['superUser'] = userToBeEdited.superUser !== undefined ? userToBeEdited.superUser : false;
    data["userType"] = usertypeRoleUser.value;
    data["roles"] = roles;
    data["organization"] =
      userToBeEdited.organization === undefined
        ? { erpOrgCode: orgInfoOfLeggedUser.erpOrgCode }
        : userToBeEdited.organization;
    data["createdBy"] =
      userToBeEdited.createdBy === undefined ? "" : userToBeEdited.createdBy;
    data["createdTime"] = userToBeEdited.createdTime;
    data["updatedBy"] = currentUser.userName;
    data["updatedTime"] =
      userToBeEdited.updatedTime === undefined
        ? ""
        : userToBeEdited.updatedTime;
    data["lastLogin"] =
      userToBeEdited.lastLogin === undefined ? "" : userToBeEdited.lastLogin;
    data["emailVerified"] =
      userToBeEdited.emailVerified === undefined
        ? false
        : userToBeEdited.emailVerified;
    data["enabled2fa"] =
      userToBeEdited.enabled2fa === undefined
        ? false
        : userToBeEdited.enabled2fa;
    data["using2FA"] =
      userToBeEdited.using2FA === undefined
        ? mode === "create" && usertypeRoleUser.value === "SUPPLIER"
          ? using2FAOrActiveToBeEditedUser.using2FA
          : mode === "edit"
            ? userToBeEdited.using2FA
            : false
        : using2FAOrActiveToBeEditedUser.using2FA;
    data["secret"] =
      userToBeEdited.secret === undefined ? "" : userToBeEdited.secret;
    data["supplierAccessPresent"] =
      userSupplierAccessList.length > 0 ? "Assigned" : "Not-Assigned";
    data["customerAccessPresent"] =
      userCustomerAccessList.length > 0 ? "Assigned" : "Not-Assigned";
    data["preferedLanguage"] =
      userToBeEdited.preferedLanguage !== undefined
        ? userToBeEdited.preferedLanguage
        : { language: "ENGLISH", value: "en" };
    data["loginId"] =
      using2FAOrActiveToBeEdited.allowLogin !== undefined &&
        using2FAOrActiveToBeEdited.allowLogin
        ? data.loginId
        : data.loginId;
    data["preferredTimeZone"] =
      userToBeEdited.preferredTimeZone !== undefined ||
        userToBeEdited.preferredTimeZone !== null
        ? userToBeEdited.preferredTimeZone
        : "Asia/Singapore";
    setOpenBackDrop(true);
    if (mode === "edit" && password !== data.password) {
      CommonService.saveOrUpdateOrPost(`/updatePassword`, {
        userId: data.id,
        newPassword: data.password,
        erpEntityCode: data.entity.erpEntityCode,
        erpOrgCode: data.organization.erpOrgCode,
      });
    }
    CommonService.saveOrUpdateOrPost(
      apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.user,
      data
    )
      .then((userResponse) => {
        if (userIdToBeSavedOrUpdated === currentUser.id)
          setLoggedInUserInfo({
            ...loggedInUserInfo,
            defaultEntity:
              userBranchAccessList.find(
                (element) => element.defaultEntity === true
              ) !== -1
                ? userBranchAccessList.find(
                  (element) => element.defaultEntity === true
                ).entity.erpEntityCode
                : "",
          });
        setUserToBeEdited(userResponse.data);
        if (
          branchModuleAccessForModule !== undefined
            ? branchModuleAccessForModule.createFlag
            : false && userIdToBeSavedOrUpdated > 0
        ) {
          CommonService.saveOrUpdateOrPost(
            `/users/${userIdToBeSavedOrUpdated}/entities`,
            userBranchAccessList
          );
        }
        if (
          projectModuleAccessForModule !== undefined
            ? projectModuleAccessForModule.createFlag
            : false && userIdToBeSavedOrUpdated > 0
        ) {
          CommonService.saveOrUpdateOrPost(
            `/users/${userIdToBeSavedOrUpdated}/userProjectAccesses`,
            userProjectAccessList
          );
        }
      })
      .then((userBranchResponse) => {
        if (
          supplierModuleAccessForModule !== undefined
            ? supplierModuleAccessForModule.createFlag
            : false && userIdToBeSavedOrUpdated > 0
        ) {
          CommonService.saveOrUpdateOrPost(
            `/users/${userIdToBeSavedOrUpdated}/userSupplierAccesses`,
            userSupplierAccessList
          );
        }
        if (
          customerModuleAccessForModule !== undefined
            ? customerModuleAccessForModule.createFlag
            : false && userIdToBeSavedOrUpdated > 0
        ) {
          CommonService.saveOrUpdateOrPost(
            `/users/${userIdToBeSavedOrUpdated}/userCustomerAccesses`,
            userCustomerAccessList
          );
        }
        if (
          supplierGroupModuleAccessForModule !== undefined
            ? supplierGroupModuleAccessForModule.createFlag
            : false &&
            userIdToBeSavedOrUpdated > 0 &&
            userToBeEdited.bpType === "Supplier"
        ) {
          CommonService.saveOrUpdateOrPost(
            `/users/${userIdToBeSavedOrUpdated}/userSupplierGroupAccesses`,
            userSupplierGroupAccessList
          );
        }
        if (
          customerGroupModuleAccessForModule !== undefined
            ? customerGroupModuleAccessForModule.createFlag
            : false &&
            userIdToBeSavedOrUpdated > 0 &&
            userToBeEdited.bpType === "Customer"
        ) {
          CommonService.saveOrUpdateOrPost(
            `/users/${userIdToBeSavedOrUpdated}/userCustomerGroupAccesses`,
            userCustomerGroupAccessList
          );
        }
      })
      .then((userSupplierResponse) => {
        setOpenBackDrop(false);
        if (userIdToBeSavedOrUpdated > 0) {
          setAlertBoxState({
            ...alertBoxState,
            openAlert: true,
            message: (
              <FormattedMessage
                id="snackbar.recordupdatedsuccessfullypermission"
                defaultMessage="Record updated successfully"
              />
            ),
            severity: "success",
          });
        } else {
          isRestoreButtonDisabled(true);
          setAlertBoxState({
            ...alertBoxState,
            openAlert: true,
            message: (
              <FormattedMessage
                id="snackbar.recordupdatedsuccessfullypermission"
                defaultMessage="Record updated successfully, Please provide access permission"
              />
            ),
            severity: "success",
          });
        }
        history.goBack();
      })
      .catch(function (error) {
        setOpenBackDrop(false);
        setAlertBoxState({
          ...alertBoxState,
          openAlert: true,
          message: error.response.data.message,
          severity: "error",
        });
      });
  };

  function resetAllClearThePageInfo() {
    if (mode === "edit") {
      isRestorForEditMode(!restorForEditMode);
      setTabIndexValue(0);
    } else {
      reset({
        organization: defaultOrgObj,
        userName: "",
        userType: "",
        entity: "",
        active: true,
        emailId: "",
        phoneNo: "",
        loginId: "",
        password: "",
        validFromDate: "",
        validToDate: "",
        using2FA: false,
        allowLogin: true,
        accountLocked: false,
        salutation: "",
        designation: "",
        purchasingGroup: "",
      });
      setOrgValue(defaultOrgObj);
      setBranchValue([]);
      //setUserTypeValue([]);
      setUserTypeRoleUser("");
      changeValidFromDate(new Date());
      setValue(
        `${pageLabelsConstantsAndMessages.name.validFromDate}`,
        new Date(),
        { shouldValidate: true, shouldDirty: true }
      );
      changeValidToDate(null);
      setRoles([]);
      //setUserToBeEdited({ ...userToBeEdited, using2FA: false, active: true,allowLogin:true})
      setUsing2FAOrActiveToBeEdited({
        ...using2FAOrActiveToBeEdited,
        using2FA: false,
        active: true,
        allowLogin: true,
        accountLocked: false,
      });
      setUsing2FAOrActiveToBeEditedUser(using2FAOrActiveToBeEditedUser);
      setTabIndexValue(0);
    }
  }

  const isTabSectionDisplayAble =
    (branchModuleAccessFlagForTabVisibility && userIdToBeSavedOrUpdated > 0) ||
    roleModuleAccessFlagForTabVisibility ||
    (supplierModuleAccessFlagForTabVisibility &&
      userIdToBeSavedOrUpdated > 0) ||
    (projectModuleAccessFlagForTabVisibility && userIdToBeSavedOrUpdated > 0);

  const branchComboProperty = {
    name: pageLabelsConstantsAndMessages.name.entity,
    isRequired: true,
    label: intl.formatMessage({ id: "textfield.entityCode.label" }),
    isAutofocus: false,
    isDisabled: userReadOnlyFields.branch,
    variant: "outlined",
    isHookFormType: true,
    errors: errors.entity,
  };


  useEffect(() => {
    if (roleModuleAccessFlagForTabVisibility) {
      if (usertypeRoleUser !== "" || usertypeRoleUser === []) {
        let roleParamBody = {
          body: {
            userType: usertypeRoleUser.value,
            entity: {
              erpEntityCode:
                mode === "create"
                  ? branchValue.erpEntityCode
                  : branchValue.valuesToBeDisplayed.erpEntityCode,
            },
            organization: { erpOrgCode: "", orgName: "" },
            name: "",
            active: true,
          },
          sortKey: "updatedTime",
          sortDir: "desc",
          recordsPerPage: 25,
          pageNo: 0,
        };

        setRoleAccessLoading(true);
        CommonService.GetListApi(
          apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.role,
          roleParamBody
        )
          .then((response) => {
            setRoleAccessLoading(false);
            setRoleList(response.data.content);
          })
          .catch(function (error) {
            setRoleAccessLoading(false);
            setAlertBoxState({
              ...alertBoxState,
              openAlert: true,
              message: error.response.data.message,
              severity: "error",
            });
          });
      }
    }
  }, [roleModuleAccessFlagForTabVisibility, usertypeRoleUser, branchValue, mode]);

  const [paramBodyForEntities, setParamBodyForEntities] = useState(
    apiUrlAndParamBodyConstants.paramBodyForGetListApis.entity
  );

  const filterRoleList = roleList.filter((item) => {
    return item.active === true
  })

  useEffect(() => {
    if (usertypeRoleUser !== "" || usertypeRoleUser === []) {
      setParamBodyForEntities({
        ...paramBodyForEntities,
        body: { loadAllData: true, userType: usertypeRoleUser.value },
        sortDir: "asc",
        sortKey: "entityName",
      });
    }
  }, [usertypeRoleUser]);

  useEffect(() => {
    if (branchModuleAccessFlagForTabVisibility) {
      fetchBranchList();
      fetchUserBranchAccessList();
    }
  }, [
    branchModuleAccessFlagForTabVisibility,
    userIdToBeSavedOrUpdated,
    paramBodyForEntities,
  ]);
  const fetchBranchList = () => {
    setBranchAccessLoading(true);
    setBranchList([]);
    CommonService.GetListApi(
      apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.entity,
      paramBodyForEntities
    )
      .then((response) => {
        setBranchAccessLoading(false);
        setBranchList(response.data.content);
      })
      .catch(function (error) {
        setBranchAccessLoading(false);
        setAlertBoxState({
          ...alertBoxState,
          openAlert: true,
          message: error.response.data.message,
          severity: "error",
        });
      });
  };
  const fetchUserBranchAccessList = () => {
    CommonService.GetListApi(`/users/${userIdToBeSavedOrUpdated}/entities`, {
      body: {
        entity: {
          erpEntityCode: "",
        },
        user: {
          id: userIdToBeSavedOrUpdated,
        },
      },
      pageNo: 0,
      sortDir: "asc",
      sortKey: "entity.erpEntityCode",
    })
      .then((response) => {
        let tempArray = [];
        response.data.content.forEach((element) => {
          tempArray.push({
            accessId: element.accessId,
            active: element.active,
            entity: { erpEntityCode: element.entity.erpEntityCode },
            user: { id: element.user.id },
            defaultEntity: element.defaultEntity,
            createdBy: element.createdBy,
            updatedBy: currentUser.userName,
            createdTime: element.createdTime,
          });
        });
        setUserBranchAccessList(tempArray);
      })
      .catch(function (error) {
        setAlertBoxState({
          ...alertBoxState,
          openAlert: true,
          message: error.response.data.message,
          severity: "error",
        });
      });
  };

  const filterBranchList = branchList.filter((item) => {
    return item.active === true
  })

  useEffect(() => {
    if (projectModuleAccessFlagForTabVisibility) {
      fetchProjectList();
    }
  }, [
    projectModuleAccessFlagForTabVisibility,
    userToBeEdited,
    searchedProjectName,
    userIdToBeSavedOrUpdated,
  ]);

  useEffect(() => {
    if (projectModuleAccessFlagForTabVisibility) {
      fetchUserProjectAccessList();
    }
  }, [
    projectModuleAccessFlagForTabVisibility,
    userToBeEdited,
    userIdToBeSavedOrUpdated,
  ]);

  const fetchProjectList = () => {
    setProjectAccessLoading(true);
    setProjectList([]);
    CommonService.GetListApi("/projects", {
      body: {
        //  id:searchedProjectName,
        // entity:
        //   userToBeEdited.entity !== undefined
        //   ? 
        //   //userBranchAccessList.filter((item) =>item.active === true).map((col) => col.entity.erpEntityCode)
        //     // ? userToBeEdited.entity.erpEntityCode
        //     : "",
      },
      pageNo: 0,
      sortDir: "asc",
      sortKey: "projectCode",
      recordsPerPage: 5000,
    })
      .then((response) => {
        setProjectAccessLoading(false);
        setProjectList(response.data.content);
      })
      .catch(function (error) {
        setProjectAccessLoading(false);
        setAlertBoxState({
          ...alertBoxState,
          openAlert: true,
          message: error.response.data.message,
          severity: "error",
        });
      });
  };

  const filteredProjectList = projectList.filter((filterItem) => {
    return (
      filterItem.projectCode.startsWith(searchedProjectName) ||
      filterItem.projectName.toLowerCase().startsWith(searchedProjectName.toLowerCase())
    );
  });

  const activeProjectList = filteredProjectList.filter((item) => {
    return item.active === true
  })

  const fetchUserProjectAccessList = () => {
    CommonService.GetListApi(
      `/users/${userIdToBeSavedOrUpdated}/userProjectAccesses`,
      {
        body: {
          project: {
            id: 0,
          },

          user: {
            id: userIdToBeSavedOrUpdated,
          },
        },
        pageNo: 0,
        sortDir: "asc",
        sortKey: "prjAccessId",
      }
    )
      .then((response) => {
        setUserProjectAccessList(response.data.content);
      })
      .catch(function (error) {
        setAlertBoxState({
          ...alertBoxState,
          openAlert: true,
          message: error.response.data.message,
          severity: "error",
        });
      });
  };
  const [paramBodyForProjects, setParamBodyForProjects] = useState(
    apiUrlAndParamBodyConstants.paramBodyForGetListApis.project
  );

  useEffect(() => {
    if (usertypeRoleUser !== "" || usertypeRoleUser === []) {
      setParamBodyForProjects({
        ...paramBodyForProjects,
        body: {
          id: searchedProjectName,
          loadAllData: true,
          userType: usertypeRoleUser.value,
        },
        sortDir: "asc",
        sortKey: "projectCode",
        recordsPerPage: 5000,
      });
    }
  }, [usertypeRoleUser, searchedProjectName]);

  useEffect(() => {
    if (supplierModuleAccessFlagForTabVisibility) {
      fetchSupplierAccessList();
    }
  }, [
    supplierModuleAccessFlagForTabVisibility,
    userToBeEdited,
    searchedVendorName,
    userIdToBeSavedOrUpdated,
  ]);

  useEffect(() => {
    if (supplierModuleAccessFlagForTabVisibility) {
      fetchUserSupplierAccessList();
    }
  }, [
    supplierModuleAccessFlagForTabVisibility,
    userToBeEdited,
    userIdToBeSavedOrUpdated,
  ]);

  const fetchSupplierAccessList = () => {
    setSupplierAccessLoading(true);
    setSupplierAccessList([]);
    CommonService.GetListApi("/suppliers", {
      body: {
        bpCode: "",
        bpSupplierName: searchedVendorName,
        bpGroupName: "",
        supplierAccountGroup: "",
        organization: {
          erpOrgCode: "",
        },
        entity: {
          erpEntityCode: "",
        },
        // erpInterfaceHdr: {
        //     intfId: ''
        // }
      },
      pageNo: 0,
      sortDir: "asc",
      sortKey: "bpCode",
      recordsPerPage: 5000,
    })
      .then((response) => {
        setSupplierAccessLoading(false);
        setSupplierAccessList(response.data.content);
      })
      .catch(function (error) {
        setSupplierAccessLoading(false);
        setAlertBoxState({
          ...alertBoxState,
          openAlert: true,
          message: error.response.data.message,
          severity: "error",
        });
      });
  };

  const filterSupplierData = supplierAccessList.filter((filterItem) => {
    return filterItem.bpSupplierName
      .toLowerCase()
      .startsWith(searchedVendorName.toLowerCase());
  });

  const activeSupplierList = filterSupplierData.filter((item) => {
    return item.active === true
  })

  const fetchUserSupplierAccessList = () => {
    CommonService.GetListApi(
      `/users/${userIdToBeSavedOrUpdated}/userSupplierAccesses`,
      {
        body: {
          supplier: {
            bpCode: "",
          },
          user: {
            id: userIdToBeSavedOrUpdated,
          },
        },
        pageNo: 0,
        sortDir: "asc",
        sortKey: "supplier.bpCode",
      }
    )
      .then((response) => {
        setUserSupplierAccessList(response.data.content);
      })
      .catch(function (error) {
        setAlertBoxState({
          ...alertBoxState,
          openAlert: true,
          message: error.response.data.message,
          severity: "error",
        });
      });
  };

  const [paramBodyForSuplier, setParamBodyForSupplier] = useState(
    apiUrlAndParamBodyConstants.paramBodyForGetListApis.supplier
  );

  useEffect(() => {
    if (usertypeRoleUser !== "" || usertypeRoleUser === []) {
      setParamBodyForSupplier({
        ...paramBodyForSuplier,
        body: {
          bpSupplierName: searchedVendorName,
          userType: usertypeRoleUser.value,
        },
        sortDir: "asc",
        sortKey: "bpCode",
        recordsPerPage: 5000,
      });
    }
  }, [searchedVendorName, usertypeRoleUser]);

  const [paramBodyForCustomer, setParamBodyForCustomer] = useState(
    apiUrlAndParamBodyConstants.paramBodyForGetListApis.customer
  );

  useEffect(() => {
    setParamBodyForCustomer({
      ...paramBodyForCustomer,
      body: { bpCustomerName: searchedCustomerName },
      sortDir: "asc",
      sortKey: "bpCode",
      recordsPerPage: 5000,
    });
  }, [searchedCustomerName]);

  const [paramBodyForSuplierGroup, setParamBodyForSupplierGroup] = useState(
    apiUrlAndParamBodyConstants.paramBodyForGetListApis.BpGroupMaster
  );

  useEffect(() => {
    setParamBodyForSupplierGroup({
      ...paramBodyForSuplierGroup,
      body: { bpType: "Supplier", groupName: searchedSupplierGroupName },
      sortDir: "asc",
      sortKey: "bpType",
      recordsPerPage: 5000,
    });
  }, [searchedSupplierGroupName]);

  const [paramBodyForCustomerGroup, setParamBodyForCustomerGroup] = useState(
    apiUrlAndParamBodyConstants.paramBodyForGetListApis.BpGroupMaster
  );

  useEffect(() => {
    setParamBodyForCustomerGroup({
      ...paramBodyForCustomerGroup,
      body: { bpType: "Customer", groupName: searchedCustomerGroupName },
      sortDir: "asc",
      sortKey: "bpType",
      recordsPerPage: 5000,
    });
  }, [searchedCustomerGroupName]);

  useEffect(() => {
    setUserIdToBeSavedOrUpdated(
      userToBeEdited.id === undefined ? 0 : userToBeEdited.id
    );
  }, [userToBeEdited]);

  const [isRoleAccessStatusChanged, setRoleAccessStatusChanged] =
    React.useState(false);
  const handleRoleStatusChanges = (id) => async () => {
    let roleAccessibleStatus = false;
    for (let index = 0; index < roles.length; index++) {
      if (roles[index].id === id) {
        setRoles(roles.filter((item) => item.id !== id));
        roleAccessibleStatus = true;
        setRoleAccessStatusChanged(!isRoleAccessStatusChanged);
        break;
      }
    }
    if (!roleAccessibleStatus) {
      let tempRoleAccessList = roles;
      tempRoleAccessList.push(intializeAccessibleRoleObjectForCurrentUser(id));
      setRoles(tempRoleAccessList);
      setRoleAccessStatusChanged(!isRoleAccessStatusChanged);
    }
  };
  function intializeAccessibleRoleObjectForCurrentUser(id) {
    return {
      id: id,
    };
  }
  useEffect(() => {
    isRoleAccessibleForCurrentUser();
  }, [isRoleAccessStatusChanged]);
  const isRoleAccessibleForCurrentUser = (id) => {
    let roleAccessibleStatus = false;
    for (let index = 0; index < roles.length; index++) {
      if (roles[index].id === id) {
        roleAccessibleStatus = true;
        break;
      }
    }
    return roleAccessibleStatus;
  };

  const [isSupplierAccessStatusChanged, setSupplierAccessStatusChanged] =
    React.useState(false);
  useEffect(() => {
    isSupplierAccessibleForCurrentUser();
  }, [isSupplierAccessStatusChanged]);
  const isSupplierAccessibleForCurrentUser = (supplierId) => {
    let supplierAccessibleStatus = false;
    for (let index = 0; index < userSupplierAccessList.length; index++) {
      if (
        userSupplierAccessList[index].supplier.bpCode === supplierId &&
        userSupplierAccessList[index].active
      ) {
        supplierAccessibleStatus = true;
        break;
      }
    }
    return supplierAccessibleStatus;
  };

  const [
    isSupplierGroupAccessStatusChanged,
    setSupplierGroupAccessStatusChanged,
  ] = React.useState(false);
  useEffect(() => {
    isSupplierGroupAccessibleForCurrentUser();
  }, [isSupplierGroupAccessStatusChanged]);
  const isSupplierGroupAccessibleForCurrentUser = (supplierGroupId) => {
    let supplierGroupAccessibleStatus = false;
    for (let index = 0; index < userSupplierGroupAccessList.length; index++) {
      if (
        userSupplierGroupAccessList[index].bpGroupMaster.groupId ===
        supplierGroupId &&
        userSupplierGroupAccessList[index].active
      ) {
        supplierGroupAccessibleStatus = true;
        break;
      }
    }
    return supplierGroupAccessibleStatus;
  };

  const [isCustomerAccessStatusChanged, setCustomerAccessStatusChanged] =
    React.useState(false);
  useEffect(() => {
    isCustomerAccessibleForCurrentUser();
  }, [isCustomerAccessStatusChanged]);

  const isCustomerAccessibleForCurrentUser = (customerId) => {
    let customerAccessibleStatus = false;
    for (let index = 0; index < userCustomerAccessList.length; index++) {
      if (
        userCustomerAccessList[index].customer.bpCode === customerId &&
        userCustomerAccessList[index].active
      ) {
        customerAccessibleStatus = true;
        break;
      }
    }
    return customerAccessibleStatus;
  };

  const [
    isCustomerGroupAccessStatusChanged,
    setCustomerGroupAccessStatusChanged,
  ] = React.useState(false);
  useEffect(() => {
    isCustomerGroupAccessibleForCurrentUser();
  }, [isCustomerGroupAccessStatusChanged]);

  const isCustomerGroupAccessibleForCurrentUser = (customerGroupId) => {
    let customerGroupAccessibleStatus = false;
    for (let index = 0; index < userCustomerGroupAccessList.length; index++) {
      if (
        userCustomerGroupAccessList[index].bpGroupMaster.groupId ===
        customerGroupId &&
        userCustomerGroupAccessList[index].active
      ) {
        customerGroupAccessibleStatus = true;
        break;
      }
    }
    return customerGroupAccessibleStatus;
  };
  const [isProjectAccessStatusChanged, setProjectAccessStatusChanged] =
    React.useState(false);
  useEffect(() => {
    isProjectAccessibleForCurrentUser();
  }, [isProjectAccessStatusChanged]);

  const isProjectAccessibleForCurrentUser = (id) => {
    let projectAccessibleStatus = false;
    for (let index = 0; index < userProjectAccessList.length; index++) {
      if (
        userProjectAccessList[index].project.id === id &&
        userProjectAccessList[index].active
      ) {
        projectAccessibleStatus = true;
        break;
      }
    }
    return projectAccessibleStatus;
  };

  useEffect(async () => {
    let tempTabsArray = [];
    if (roleModuleAccessFlagForTabVisibility) {
      tempTabsArray.push(
        pageLabelsConstantsAndMessages.label.tabUserRoleAccess
      );
      setUserTabsArray(tempTabsArray);
    }
    if (branchModuleAccessFlagForTabVisibility) {
      tempTabsArray.push(
        pageLabelsConstantsAndMessages.label.tabUserBranchAccess
      );
      setUserTabsArray(tempTabsArray);
    }
    if (supplierModuleAccessFlagForTabVisibility) {
      tempTabsArray.push(
        pageLabelsConstantsAndMessages.label.tabUserSupplierAccess
      );
      setUserTabsArray(tempTabsArray);
    }
    if (projectModuleAccessFlagForTabVisibility) {
      tempTabsArray.push(
        pageLabelsConstantsAndMessages.label.tabUserProjectAccess
      );
      setUserTabsArray(tempTabsArray);
    }

    setUserTabsArray(await tempTabsArray);
  }, [tabVisibilityPermissionChanged, userToBeEdited]);

  const [isBranchAccessStatusChanged, setBranchAccessStatusChanged] =
    React.useState(false);
  const [isBranchAccessDefaultChanged, setBranchAccessDefaultChanged] =
    React.useState(false);

  useEffect(() => {
    isBranchAccessibleForCurrentUser();
    isBranchAccessibleForCurrentUserDefault();
  }, [isBranchAccessStatusChanged, isBranchAccessDefaultChanged]);

  const isBranchAccessibleForCurrentUser = (entityId) => {
    let branchAccessibleStatus = false;
    for (let index = 0; index < userBranchAccessList.length; index++) {
      if (
        userBranchAccessList[index].entity.erpEntityCode === entityId &&
        userBranchAccessList[index].active
      ) {
        branchAccessibleStatus = true;
        break;
      }
    }
    return branchAccessibleStatus;
  };
  const isBranchAccessibleForCurrentUserDefault = (entityId) => {
    let branchAccessibleDefault = false;
    for (let index = 0; index < userBranchAccessList.length; index++) {
      if (
        userBranchAccessList[index].entity.erpEntityCode === entityId &&
        userBranchAccessList[index].defaultEntity
      ) {
        branchAccessibleDefault = true;
        break;
      }
    }
    return branchAccessibleDefault;
  };
  const handleBranchAccessStatusChanges = (entityId) => async () => {
    let branchAccessibleStatus = false;
    var defaultEntityDetails = userBranchAccessList.filter(
      (element) => element.defaultEntity === true
    );
    for (let index = 0; index < userBranchAccessList.length; index++) {
      if (userBranchAccessList[index].entity.erpEntityCode === entityId) {
        if (
          !(
            (userBranchAccessList[index].active === true &&
              userBranchAccessList[index].defaultEntity === true) ||
            (userBranchAccessList[index].active === false &&
              defaultEntityDetails.length === 0)
          )
        ) {
          userBranchAccessList[index].active =
            !userBranchAccessList[index].active;
        }
        branchAccessibleStatus = true;
        break;
      }
    }
    if (
      !branchAccessibleStatus &&
      filterBranchList.length >= userBranchAccessList.length
    ) {
      if (defaultEntityDetails.length > 0)
        userBranchAccessList.push(
          intializeAccessibleBranchObjectForCurrentUser(entityId)
        );
    }
    var tempUserBranchAccessList = userBranchAccessList;
    setUserBranchAccessList(tempUserBranchAccessList);
    setBranchAccessStatusChanged(!isBranchAccessStatusChanged);
  };
  const handleBranchAccessStatusChangesForDefault = (entityId) => async () => {
    let tempUserBranchAccessList = userBranchAccessList;
    let branchAccessibleStatusIsDefault = false;
    for (let index = 0; index < tempUserBranchAccessList.length; index++) {
      if (tempUserBranchAccessList[index].entity.erpEntityCode === entityId) {
        tempUserBranchAccessList[index].active = true;
        tempUserBranchAccessList[index].defaultEntity = true;
        branchAccessibleStatusIsDefault = true;
      } else {
        tempUserBranchAccessList[index].defaultEntity = false;
      }
    }
    if (!branchAccessibleStatusIsDefault) {
      tempUserBranchAccessList.push(
        intializeADefaultBranchObjectForCurrentUser(entityId)
      );
    }
    setUserBranchAccessList(tempUserBranchAccessList);
    setBranchAccessDefaultChanged(!isBranchAccessDefaultChanged);
  };

  const handleUserSupplierStatusChanges = (supplierId) => async () => {
    let supplierAccessibleStatus = false;
    for (let index = 0; index < userSupplierAccessList.length; index++) {
      if (
        userSupplierAccessList[index].supplier.bpCode === supplierId &&
        userSupplierAccessList[index].active
      ) {
        userSupplierAccessList[index].active =
          !userSupplierAccessList[index].active;
        supplierAccessibleStatus = true;
        setSupplierAccessStatusChanged(!isSupplierAccessStatusChanged);
        break;
      }
    }
    if (!supplierAccessibleStatus) {
      let tempSupplierMenuAccessList = userSupplierAccessList;
      tempSupplierMenuAccessList.push(
        intializeAccessibleSupplierObjectForCurrentUser(supplierId)
      );
      setUserSupplierAccessList(tempSupplierMenuAccessList);
      setSupplierAccessStatusChanged(!isSupplierAccessStatusChanged);
    }
  };

  const handleProjectAccessStatusChanges = (id) => async () => {
    let projectAccessibleStatus = false;
    for (let index = 0; index < userProjectAccessList.length; index++) {
      if (userProjectAccessList[index].project.id === id) {
        userProjectAccessList[index].active =
          !userProjectAccessList[index].active;
        projectAccessibleStatus = true;
        setProjectAccessStatusChanged(!isProjectAccessStatusChanged);
        break;
      }
    }
    if (!projectAccessibleStatus) {
      let tempUserProjectAccessList = userProjectAccessList;
      tempUserProjectAccessList.push(
        intializeAccessibleProjectObjectForCurrentUser(id)
      );
      setUserProjectAccessList(tempUserProjectAccessList);
      setProjectAccessStatusChanged(!isProjectAccessStatusChanged);
    }
  };

  function intializeAccessibleSupplierObjectForCurrentUser(supplierId) {
    return {
      supplierId: 0,
      active: true,
      createdBy: currentUser.userName,
      updatedBy: currentUser.userName,
      user: {
        id: userIdToBeSavedOrUpdated,
      },
      supplier: {
        bpCode: supplierId,
      },
    };
  }
  function intializeAccessibleBranchObjectForCurrentUser(entityId) {
    return {
      accessId: 0,
      active: true,
      defaultEntity: false,
      createdBy: currentUser.userName,
      updatedBy: currentUser.userName,
      user: {
        id: userIdToBeSavedOrUpdated,
      },
      entity: {
        erpEntityCode: entityId,
      },
    };
  }
  function intializeADefaultBranchObjectForCurrentUser(entityId) {
    return {
      accessId: 0,
      active: true,
      defaultEntity: true,
      createdBy: currentUser.userName,
      updatedBy: currentUser.userName,
      user: {
        id: userIdToBeSavedOrUpdated,
      },
      entity: {
        erpEntityCode: entityId,
      },
    };
  }
  function intializeAccessibleProjectObjectForCurrentUser(id) {
    return {
      prjAccessId: 0,
      active: true,
      createdBy: currentUser.userName,
      updatedBy: currentUser.userName,
      project: {
        id: id,
      },
      user: {
        id: userIdToBeSavedOrUpdated,
      },
    };
  }
  const salutationValues = [
    {
      value: "Mr",
      label: "Mr",
    },
    {
      value: "Ms",
      label: "Ms",
    },
    {
      value: "Mrs",
      label: "Mrs",
    },
    {
      value: "Mr/Ms",
      label: "Mr/Ms",
    },
    {
      value: "Dr",
      label: "Dr",
    },
  ];

  const userTypeComboRoleComboProperty = {
    name: pageLabelsConstantsAndMessages.name.userType,
    isRequired: true,
    label: intl.formatMessage({ id: "columnname.usertype.label" }),
    isAutofocus: false,
    variant: "outlined",
    isHookFormType: true,
    isDisabled: userReadOnlyFields.userType,
    errors: errors.userType,
  };

  const [using2FAOrActiveToBeEditedUser, setUsing2FAOrActiveToBeEditedUser] =
    useState({});

  useEffect(() => {
    if (mode === "create") {
      setUserTypeRoleUser("");
    }
  }, [branchValue, mode]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseAlert} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <CommonBackDrop open={openBackDrop} />
      <Grid container className={classes.rootForCreateOrEditRole}>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          xs={6}
          sm={8}
          md={8}
          lg={6}
          className={classes.topGrid}
        >
          <CustomBreadCrumb componentList={componentList} />
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          alignItems="center"
          xs={6}
          sm={4}
          md={4}
          lg={6}
          className={classes.topGrid}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.stateButtonForBack}
            elevation={2}
            endIcon={smallScreen ? null : <IconBack />}
            onClick={history.goBack}
          >
            {" "}
            {smallScreen ? (
              <IconBack />
            ) : (
              <FormattedMessage id="common.button.back.lable" />
            )}{" "}
          </Button>

          <Button
            size="small"
            disabled={disableRestoreButton && userIdToBeSavedOrUpdated > 0}
            variant="contained"
            color="secondary"
            className={classes.stateButtonToRestore}
            elevation={2}
            endIcon={smallScreen ? null : <IconRestore />}
            onClick={() => {
              resetAllClearThePageInfo();
            }}
          >
            {" "}
            {smallScreen ? (
              <IconRestore />
            ) : (
              <FormattedMessage
                id="common.button.reset.lable"
                defaultMessage={
                  pageLabelsConstantsAndMessages.label.restoreButton
                }
              />
            )}{" "}
          </Button>

          {(userModuleAccess !== undefined
            ? userModuleAccess.createFlag
            : false) && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                className={classes.stateButtonForSave}
                elevation={2}
                disabled={disableSaveOrUpdateButton}
                endIcon={smallScreen ? null : <IconSaveOrUpdate />}
                onClick={handleSubmit(onSubmit)}
              >
                {" "}
                {smallScreen ? (
                  <IconSaveOrUpdate />
                ) : (
                  saveOrUpdateButtonLabel
                )}{" "}
              </Button>
            )}
        </Grid>
        <Paper className={classes.paperForCreateOrEditUser} elevation={3}>
          <form
            className={classes.formForCreateOrEditUser}
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
              <Grid item container xs={12} sm={6} md={7} lg={6}>
                <EntityCodeCombo
                  comboProperty={branchComboProperty}
                  control={control}
                  errors={errors}
                  comboValue={branchValue}
                  setComboValue={setBranchValue}
                />
              </Grid>
              <Grid item container xs={12} sm={6} md={3} lg={3}>
                <UserTypeComboRole
                  comboProperty={userTypeComboRoleComboProperty}
                  control={control}
                  errors={errors}
                  comboValue={usertypeRoleUser}
                  setComboValue={setUserTypeRoleUser}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              spacing={2}
              style={{ marginTop: "0.1%" }}
            >
              <Grid item container xs={12} sm={6} md={2} lg={1}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={pageLabelsConstantsAndMessages.name.salutation}
                    rules={
                      using2FAOrActiveToBeEdited.allowLogin
                        ? { required: false }
                        : { required: true }
                    }
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        className={classes.textField}
                        select
                        required={
                          !using2FAOrActiveToBeEdited.allowLogin ? true : false
                        }
                        variant="outlined"
                        margin="normal"
                        id="salutation"
                        size="small"
                        label={
                          <FormattedMessage
                            id="columnname.Salutation.label"
                            defaultMessage="Salutation"
                          />
                        }
                        error={errors.salutation}
                        {...field}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            asterisk: !using2FAOrActiveToBeEdited.allowLogin
                              ? classes.asterisk
                              : null,
                          },
                        }}
                        InputProps={{
                          readOnly: userReadOnlyFields.salutation,
                          classes: { notchedOutline: classes.specialOutline },
                        }}
                        disabled={disabledForReadMode}
                      >
                        {salutationValues.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  <div className={classes.error}>
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.salutation}.type`,
                      errors
                    ) === "required" && (
                        <FormHelperText className={classes.error}>
                          <FormattedMessage
                            id="textfield.error.salutation"
                            defaultMessage="Salution is required"
                          />
                        </FormHelperText>
                      )}
                  </div>
                </FormControl>
              </Grid>
              <Grid item container xs={12} sm={6} md={2} lg={2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={pageLabelsConstantsAndMessages.name.userName}
                    rules={{
                      required: true,
                      // pattern: commonPatterns.commonTextfeildPattern.patternForAlphaNumeric,
                      maxLength: 100,
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        className={classes.textField}
                        required={true}
                        variant="outlined"
                        margin="normal"
                        id="userName"
                        size="small"
                        label={
                          <FormattedMessage
                            id="columnname.username.label"
                            defaultMessage="User Name "
                          />
                        }
                        {...field}
                        error={errors.userName}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            asterisk: classes.asterisk,
                          },
                        }}
                        InputProps={{
                          readOnly: userReadOnlyFields.userName,
                        }}
                        disabled={disabledForReadMode}
                      />
                    )}
                  />
                  <div className={classes.error}>
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.userName}.type`,
                      errors
                    ) === "required" && (
                        <FormHelperText className={classes.error}>
                          {
                            <FormattedMessage
                              id="textfield.error.usernameempty"
                              defaultMessage="User Name is required"
                            />
                          }
                        </FormHelperText>
                      )}
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.userName}.type`,
                      errors
                    ) === "maxLength" && (
                        <FormHelperText className={classes.error}>
                          {
                            <FormattedMessage
                              id="columnname.username.label"
                              defaultMessage="User Name"
                            />
                          }{" "}
                          {
                            <FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />
                          }
                        </FormHelperText>
                      )}
                    {/* {_.get(`${pageLabelsConstantsAndMessages.name.userName}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}> {<FormattedMessage id="columnname.username.label" defaultMessage="User Name " />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNumeric}</FormHelperText>
                                        )} */}
                  </div>
                </FormControl>
              </Grid>
              <Grid item container xs={12} sm={6} md={3} lg={3}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={pageLabelsConstantsAndMessages.name.emailId}
                    rules={{
                      required: true,
                      pattern:
                        commonPatterns.commonTextfeildPattern
                          .patternForSingleEmailId,
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="normal"
                        id="emailId"
                        size="small"
                        required
                        label={
                          <FormattedMessage
                            id="textfield.label.emailid"
                            defaultMessage="Email Id "
                          />
                        }
                        error={errors.emailId}
                        {...field}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            asterisk: classes.asterisk,
                          },
                        }}
                        InputProps={{
                          readOnly: userReadOnlyFields.emailId,
                        }}
                        disabled={disabledForReadMode}
                      />
                    )}
                  />
                  {userReadOnlyFields.emailId && (
                    <FormHelperText>
                      {
                        <FormattedMessage
                          id="textfield.error.emailidcannotbeedited"
                          defaultMessage="Email Id can't be edited"
                        />
                      }{" "}
                    </FormHelperText>
                  )}
                  <div className={classes.error}>
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.emailId}.type`,
                      errors
                    ) === "required" && (
                        <FormHelperText className={classes.error}>
                          {
                            <FormattedMessage
                              id="common.textfield.error.emailid"
                              defaultMessage="Email Id is required"
                            />
                          }
                        </FormHelperText>
                      )}
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.emailId}.type`,
                      errors
                    ) === "maxLength" && (
                        <FormHelperText className={classes.error}>
                          {
                            <FormattedMessage
                              id="textfield.error.emailidcannotexceed"
                              defaultMessage="Email Id cannot exceed more than 100 chars"
                            />
                          }
                        </FormHelperText>
                      )}
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.emailId}.type`,
                      errors
                    ) === "pattern" && (
                        <FormHelperText className={classes.error}>
                          {" "}
                          {
                            <FormattedMessage
                              id="textfield.label.emailid"
                              defaultMessage="Email Id "
                            />
                          }{" "}
                          {
                            commonPatterns.commonErrorsForTextFields
                              .errorForSingleEmailId
                          }
                        </FormHelperText>
                      )}
                  </div>
                </FormControl>
              </Grid>
              <Grid item container xs={12} sm={6} md={3} lg={3}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={pageLabelsConstantsAndMessages.name.designation}
                    rules={
                      using2FAOrActiveToBeEdited.allowLogin
                        ? {
                          required: false,
                          pattern:
                            commonPatterns.commonTextfeildPattern
                              .patternWithAlphaNum_UHF,
                          maxLength: 100,
                        }
                        : {
                          required: true,
                          pattern:
                            commonPatterns.commonTextfeildPattern
                              .patternWithAlphaNum_UHF,
                          maxLength: 100,
                        }
                    }
                    render={({ field }) => (
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="normal"
                        id="designation"
                        size="small"
                        label={
                          <FormattedMessage
                            id="textfield.designation.label"
                            defaultMessage="Designation"
                          />
                        }
                        {...field}
                        error={
                          !using2FAOrActiveToBeEdited.allowLogin
                            ? errors.designation
                            : null
                        }
                        required={
                          !using2FAOrActiveToBeEdited.allowLogin ? true : false
                        }
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            asterisk: !using2FAOrActiveToBeEdited.allowLogin
                              ? classes.asterisk
                              : null,
                          },
                        }}
                        InputProps={{
                          readOnly: userReadOnlyFields.designation,
                        }}
                        disabled={disabledForReadMode}
                      />
                    )}
                  />
                  <div className={classes.error}>
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.designation}.type`,
                      errors
                    ) === "required" &&
                      !using2FAOrActiveToBeEdited.allowLogin && (
                        <FormHelperText className={classes.error}>
                          {
                            <FormattedMessage
                              id="textfield.error.designation"
                              defaultMessage="Designation is required"
                            />
                          }
                        </FormHelperText>
                      )}
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.designation}.type`,
                      errors
                    ) === "pattern" && (
                        <FormHelperText className={classes.error}>
                          {" "}
                          {
                            <FormattedMessage
                              id="textfield.designation.label"
                              defaultMessage="Designation"
                            />
                          }{" "}
                          {
                            commonPatterns.commonErrorsForTextFields
                              .errorForAlphaNum_UHF
                          }
                        </FormHelperText>
                      )}
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.designation}.type`,
                      errors
                    ) === "maxLength" && (
                        <FormHelperText className={classes.error}>
                          {
                            <FormattedMessage
                              id="textfield.designation.label"
                              defaultMessage="Designation"
                            />
                          }{" "}
                          {
                            <FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />
                          }
                        </FormHelperText>
                      )}
                  </div>
                </FormControl>
              </Grid>
              <Grid item container xs={12} sm={6} md={2} lg={3}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={pageLabelsConstantsAndMessages.name.phoneNo}
                    rules={{
                      minLength: 10,
                      maxLength: 18,
                      pattern:
                        commonPatterns.commonTextfeildPattern
                          .patternForTel_PhNumber,
                    }}
                    render={({ field }) => (
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="normal"
                        id="phoneNo"
                        size="small"
                        label={
                          <FormattedMessage
                            id="textfield.label.phoneno"
                            defaultMessage="Phone Number"
                          />
                        }
                        error={errors.phoneNo}
                        {...field}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            asterisk: classes.asterisk,
                          },
                        }}
                        InputProps={{
                          readOnly: userReadOnlyFields.phoneNo,
                        }}
                        disabled={disabledForReadMode}
                      />
                    )}
                  />
                  <div className={classes.error}>
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.phoneNo}.type`,
                      errors
                    ) === "required" && (
                        <FormHelperText className={classes.error}>
                          {
                            <FormattedMessage
                              id="textfield.error.phonenoempty"
                              defaultMessage="Phone Number is required"
                            />
                          }
                        </FormHelperText>
                      )}
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.phoneNo}.type`,
                      errors
                    ) === "maxLength" && (
                        <FormHelperText className={classes.error}>
                          {
                            <FormattedMessage
                              id="textfield.error.phonenomaxlength"
                              defaultMessage="Phone Number cannot exceed more than 10 chars"
                            />
                          }
                        </FormHelperText>
                      )}
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.phoneNo}.type`,
                      errors
                    ) === "pattern" && (
                        <FormHelperText className={classes.error}>
                          {" "}
                          {pageLabelsConstantsAndMessages.label.phoneNo}{" "}
                          {
                            commonPatterns.commonErrorsForTextFields
                              .errorForTele_PhoneNumber
                          }
                        </FormHelperText>
                      )}
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.phoneNo}.type`,
                      errors
                    ) === "minLength" && (
                        <FormHelperText className={classes.error}>
                          {
                            <FormattedMessage
                              id="textfield.error.phonenominlength"
                              defaultMessage="Phone Number cannot be less than 10 numbers"
                            />
                          }
                        </FormHelperText>
                      )}
                  </div>
                </FormControl>
              </Grid>
              {isScreenSmall && (
                <Grid item container xs={12} sm={6} md={3} lg={3}>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name={pageLabelsConstantsAndMessages.name.loginId}
                      rules={{
                        required: true,
                        pattern:
                          commonPatterns.commonTextfeildPattern
                            .patternWithAlphaNum_UHF,
                      }}
                      render={({ field }) => (
                        <TextField
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          id="loginId"
                          size="small"
                          autoComplete="off"
                          label={
                            <FormattedMessage
                              id="textfield.loginid.label"
                              defaultMessage="Login ID"
                            />
                          }
                          error={errors.loginId}
                          {...field}
                          required={true}
                          InputLabelProps={{
                            shrink: true,
                            classes: {
                              asterisk: classes.asterisk,
                            }
                          }}
                          InputProps={{
                            readOnly: userReadOnlyFields.loginId,
                          }}
                          // disabled={mode === "edit" ? true : false}
                          disabled={disabledForReadMode}
                        />
                      )}
                    />
                    <div className={classes.error}>
                      {_.get(
                        `${pageLabelsConstantsAndMessages.name.loginId}.type`,
                        errors
                      ) === "required" && (
                          <FormHelperText className={classes.error}>
                            {
                              <FormattedMessage
                                id="textfield.error.loginid"
                                defaultMessage="Login Id is required"
                              />
                            }
                          </FormHelperText>
                        )}
                      {_.get(
                        `${pageLabelsConstantsAndMessages.name.loginId}.type`,
                        errors
                      ) === "maxLength" && (
                          <FormHelperText className={classes.error}>
                            {
                              <FormattedMessage
                                id="textfield.error.loginidmaxlength"
                                defaultMessage="Login Id cannot exceed more than 100 chars"
                              />
                            }
                          </FormHelperText>
                        )}
                      {_.get(
                        `${pageLabelsConstantsAndMessages.name.loginId}.type`,
                        errors
                      ) === "pattern" && (
                          <FormHelperText className={classes.error}>
                            {pageLabelsConstantsAndMessages.label.loginId}{" "}
                            {
                              commonPatterns.commonErrorsForTextFields
                                .errorForAlphaNum_UHF
                            }
                          </FormHelperText>
                        )}
                    </div>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              spacing={2}
              style={{ marginTop: "0.1%" }}
            >
              {!isScreenSmall && (
                <Grid item container xs={12} sm={6} md={3} lg={3}>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name={pageLabelsConstantsAndMessages.name.loginId}
                      rules={{
                        required: true,
                        pattern:
                          commonPatterns.commonTextfeildPattern
                            .patternWithAlphaNum_UHF,
                      }}
                      render={({ field }) => (
                        <TextField
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          id="loginId"
                          size="small"
                          autoComplete="off"
                          label={
                            <FormattedMessage
                              id="textfield.loginid.label"
                              defaultMessage="Login ID "
                            />
                          }
                          error={errors.loginId}
                          {...field}
                          required={true}
                          InputLabelProps={{
                            shrink: true,
                            classes: {
                              asterisk: classes.asterisk
                            },
                          }}
                          // InputProps={{
                          //     readOnly: userReadOnlyFields.loginId,
                          // }}
                          // disabled={(mode === 'edit') ? true : false}
                          disabled={disabledForReadMode}
                        />
                      )}
                    />
                    <div className={classes.error}>
                      {_.get(
                        `${pageLabelsConstantsAndMessages.name.loginId}.type`,
                        errors
                      ) === "required" && (
                          <FormHelperText className={classes.error}>
                            {
                              <FormattedMessage
                                id="textfield.error.loginid"
                                defaultMessage="Login Id is required"
                              />
                            }
                          </FormHelperText>
                        )}
                      {_.get(
                        `${pageLabelsConstantsAndMessages.name.loginId}.type`,
                        errors
                      ) === "maxLength" && (
                          <FormHelperText className={classes.error}>
                            {
                              <FormattedMessage
                                id="textfield.error.loginidmaxlength"
                                defaultMessage="Login Id cannot exceed more than 100 chars"
                              />
                            }
                          </FormHelperText>
                        )}
                      {_.get(
                        `${pageLabelsConstantsAndMessages.name.loginId}.type`,
                        errors
                      ) === "pattern" && (
                          <FormHelperText className={classes.error}>
                            {
                              <FormattedMessage
                                id="textfield.loginid.label"
                                defaultMessage="Login ID "
                              />
                            }{" "}
                            {
                              commonPatterns.commonErrorsForTextFields
                                .errorForAlphaNum_UHF
                            }
                          </FormHelperText>
                        )}
                    </div>
                  </FormControl>
                </Grid>
              )}
              <Grid item container xs={12} sm={6} md={3} lg={3}>
                <Tooltip
                  placement="top-start"
                  title={
                    <FormattedMessage id="textfield.errorvalid.password" />
                  }
                >
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name={pageLabelsConstantsAndMessages.name.password}
                      rules={{
                        required: using2FAOrActiveToBeEdited.allowLogin,
                        pattern:
                          commonPatterns.commonTextfeildPattern
                            .patternForPassword,
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          id="password"
                          size="small"
                          autoComplete="new-password"
                          label={
                            <FormattedMessage
                              id="textfield.password.label"
                              defaultMessage="Password"
                            />
                          }
                          error={errors.password}
                          {...field}
                          required={
                            using2FAOrActiveToBeEdited.allowLogin ? true : false
                          }
                          inputProps={{ min: 0, style: { color: "grey" } }}
                          InputLabelProps={{
                            shrink: true,
                            classes: {
                              asterisk: using2FAOrActiveToBeEdited.allowLogin
                                ? classes.asterisk
                                : null,
                            },
                          }}
                          inputProps={{
                            type: "password",
                            autoComplete: "new-password",
                          }}
                          // InputProps={{
                          //     readOnly: userReadOnlyFields.password,
                          // }}
                          // disabled={(mode === 'edit' ? true : false)}
                          disabled={disabledForReadMode}
                        />
                      )}
                    />
                    <div className={classes.error}>
                      {_.get(
                        `${pageLabelsConstantsAndMessages.name.password}.type`,
                        errors
                      ) === "pattern" && (
                          <FormHelperText className={classes.error}>
                            {
                              <FormattedMessage
                                id="texfield.errorpattern.password"
                                defaultMessage="Enter a Valid Password"
                              />
                            }
                          </FormHelperText>
                        )}
                      {_.get(
                        `${pageLabelsConstantsAndMessages.name.password}.type`,
                        errors
                      ) === "required" && (
                          <FormHelperText className={classes.error}>
                            {
                              <FormattedMessage
                                id="texfield.error.password"
                                defaultMessage="Password is required"
                              />
                            }{" "}
                          </FormHelperText>
                        )}
                      {_.get(
                        `${pageLabelsConstantsAndMessages.name.password}.type`,
                        errors
                      ) === "maxLength" && (
                          <FormHelperText className={classes.error}>
                            {
                              <FormattedMessage
                                id="textfield.error.passwordmaxlength"
                                defaultMessage="Password  cannot exceed more than 100 chars"
                              />
                            }{" "}
                          </FormHelperText>
                        )}
                    </div>
                  </FormControl>
                </Tooltip>
              </Grid>
              <Grid item container xs={12} sm={6} md={2} lg={2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={pageLabelsConstantsAndMessages.name.purchasingGroup}
                    rules={{
                      required: false,
                      pattern:
                        commonPatterns.commonTextfeildPattern
                          .patternForAlphaNumeric,
                      maxLength: 100,
                    }}
                    render={({ field }) => (
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="normal"
                        id="purchasingGroup"
                        size="small"
                        label={
                          <FormattedMessage
                            id="textfield.purchasingGroup.label"
                            defaultMessage="Purchasing Group"
                          />
                        }
                        {...field}
                        error={errors.purchasingGroup}
                        required={false}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            asterisk: classes.asterisk,
                          },
                        }}
                        InputProps={{
                          readOnly: userReadOnlyFields.purchasingGroup,
                        }}
                        disabled={disabledForReadMode}
                      />
                    )}
                  />
                  <div className={classes.error}>
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.purchasingGroup}.type`,
                      errors
                    ) === "required" && (
                        <FormHelperText className={classes.error}>
                          {
                            <FormattedMessage
                              id="textfield.error.purchasingGroup"
                              defaultMessage="Purchasing Group is required"
                            />
                          }
                        </FormHelperText>
                      )}
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.purchasingGroup}.type`,
                      errors
                    ) === "pattern" && (
                        <FormHelperText className={classes.error}>
                          {" "}
                          {
                            <FormattedMessage
                              id="textfield.purchasingGroup.label"
                              defaultMessage="Purchasing Group"
                            />
                          }{" "}
                          {
                            commonPatterns.commonErrorsForTextFields
                              .errorForAlphaNumeric
                          }
                        </FormHelperText>
                      )}
                    {_.get(
                      `${pageLabelsConstantsAndMessages.name.purchasingGroup}.type`,
                      errors
                    ) === "maxLength" && (
                        <FormHelperText className={classes.error}>
                          <FormattedMessage
                            id="textfield.purchasingGroup.label"
                            defaultMessage="Purchasing Group"
                          />{" "}
                          {
                            <FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />
                          }
                        </FormHelperText>
                      )}
                  </div>
                </FormControl>
              </Grid>
              <Grid item container xs={12} sm={6} md={2} lg={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name={pageLabelsConstantsAndMessages.name.validFromDate}
                      rules={{ required: true }}
                      defaultValue={validFromDate}
                      render={({ field }) => (
                        <MobileDatePicker
                          renderInput={(props) => {
                            return (
                              <TextField
                                {...props}
                                variant="outlined"
                                size="small"
                                label={
                                  <FormattedMessage
                                    id="textfield.label.validfromdate"
                                    defaultMessage="Valid From  "
                                  />
                                }
                                {...field}
                                required
                                error={errors.validFromDate}
                                InputLabelProps={{
                                  shrink: true,
                                  classes: {
                                    asterisk: classes.asterisk,
                                  },
                                }}
                                disabled={disabledForReadMode}
                              />
                            );
                          }}
                          clearable
                          id="validFromDate"
                          inputFormat="dd-MM-yyyy"
                          minDate={validFromDate}
                          value={validFromDate}
                          required
                          InputProps={{
                            readOnly: userReadOnlyFields.validFromDate,
                          }}
                          onChange={(date) => {
                            changeValidFromDate(date);
                            setValue(
                              `${pageLabelsConstantsAndMessages.name.validFromDate}`,
                              date,
                              { shouldValidate: true, shouldDirty: true }
                            );
                          }}
                        />
                      )}
                    />
                    {userReadOnlyFields.validFromDate && (
                      <FormHelperText>
                        {
                          <FormattedMessage
                            id="textfield.error.validfromdatecannotbeedited"
                            defaultMessage="Valid From can't be edited"
                          />
                        }
                      </FormHelperText>
                    )}
                    <div className={classes.error}>
                      {_.get(
                        `${pageLabelsConstantsAndMessages.name.validFromDate}.type`,
                        errors
                      ) === "required" &&
                        validFromDate === null && (
                          <FormHelperText className={classes.error}>
                            {
                              <FormattedMessage
                                id="textfield.error.validfromdateempty"
                                defaultMessage="Valid From is required"
                              />
                            }
                          </FormHelperText>
                        )}
                    </div>
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              <Grid item container xs={12} sm={6} md={2} lg={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name={pageLabelsConstantsAndMessages.name.validToDate}
                      render={({ field }) => (
                        <MobileDatePicker
                          renderInput={(props) => {
                            return (
                              <TextField
                                {...props}
                                variant="outlined"
                                size="small"
                                label={
                                  <FormattedMessage
                                    id="textfield.label.validtodate"
                                    defaultMessage="Valid To"
                                  />
                                }
                                {...field}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            );
                          }}
                          clearable
                          id="validToDate"
                          inputFormat="dd-MM-yyyy"
                          minDate={validFromDate}
                          {...field}
                          error={errors.validToDate}
                          value={validToDate}
                          InputProps={{
                            readOnly: userReadOnlyFields.validToDate,
                          }}
                          onChange={(date) => {
                            changeValidToDate(date);
                            setValue(
                              `${pageLabelsConstantsAndMessages.name.validToDate}`,
                              date,
                              { shouldValidate: true, shouldDirty: true }
                            );
                          }}
                          name={pageLabelsConstantsAndMessages.name.validToDate}
                          disabled={disabledForReadMode}
                        />
                      )}
                    />
                    {userReadOnlyFields.validToDate && (
                      <FormHelperText>
                        {
                          <FormattedMessage
                            id="textfield.error.validtodatecannotbeedited"
                            defaultMessage="Valid To can't be edited"
                          />
                        }
                      </FormHelperText>
                    )}
                    <div className={classes.error}>
                      {_.get(
                        `${pageLabelsConstantsAndMessages.name.validToDate}.type`,
                        errors
                      ) === "required" &&
                        validToDate === null && (
                          <FormHelperText className={classes.error}>
                            {
                              <FormattedMessage
                                id="textfield.error.validtodateempty"
                                defaultMessage="Valid To is required"
                              />
                            }
                          </FormHelperText>
                        )}
                    </div>
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              {isScreenSmall && (
                <Grid item container xs={12} sm={6} md={3} lg={3}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Controller
                      render={({ field: { onChange } }) => (
                        <Switch
                          color="primary"
                          disabled={userReadOnlyFields.using2FA}
                          checked={
                            using2FAOrActiveToBeEditedUser.using2FA !==
                              undefined
                              ? using2FAOrActiveToBeEditedUser.using2FA
                              : mode === "create" &&
                                usertypeRoleUser.value === "SUPPLIER"
                                ? true
                                : false
                          }
                          onChange={(event) => {
                            onChange(
                              !(using2FAOrActiveToBeEditedUser.using2FA !==
                                undefined
                                ? using2FAOrActiveToBeEditedUser.using2FA
                                : false)
                            );
                            setUsing2FAOrActiveToBeEdited({
                              ...using2FAOrActiveToBeEditedUser,
                              using2FA: event.target.checked,
                            });
                          }}
                          disabled={disabledForReadMode}
                        />
                      )}
                      type="checkbox"
                      name={pageLabelsConstantsAndMessages.name.using2FA}
                      control={control}
                      defaultValue={
                        using2FAOrActiveToBeEditedUser.using2FA !== undefined
                          ? using2FAOrActiveToBeEditedUser.using2FA
                          : false
                      }
                    />
                    <InputLabel>
                      <FormattedMessage
                        id="textfield.label.using2fa"
                        defaultMessage={
                          pageLabelsConstantsAndMessages.label.enabled2fa
                        }
                      />
                    </InputLabel>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              spacing={2}
              style={{ marginTop: "0.1%" }}
            >
              {!isScreenSmall && (
                <Grid item container xs={12} sm={6} md={3} lg={3}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Controller
                      render={({ field: { onChange } }) => (
                        <Switch
                          color="primary"
                          disabled={userReadOnlyFields.using2FA}
                          checked={
                            using2FAOrActiveToBeEditedUser.using2FA !==
                              undefined
                              ? using2FAOrActiveToBeEditedUser.using2FA
                              : mode === "create" &&
                                usertypeRoleUser.value === "SUPPLIER"
                                ? true
                                : false
                          }
                          onChange={(event) => {
                            onChange(
                              !(using2FAOrActiveToBeEditedUser.using2FA !==
                                undefined
                                ? using2FAOrActiveToBeEditedUser.using2FA
                                : false)
                            );
                            setUsing2FAOrActiveToBeEditedUser({
                              ...using2FAOrActiveToBeEditedUser,
                              using2FA: event.target.checked,
                            });
                          }}
                          disabled={disabledForReadMode}
                        />
                      )}
                      type="checkbox"
                      name={pageLabelsConstantsAndMessages.name.using2FA}
                      control={control}
                      defaultValue={
                        using2FAOrActiveToBeEditedUser.using2FA !== undefined
                          ? using2FAOrActiveToBeEditedUser.using2FA
                          : mode === "create" &&
                            usertypeRoleUser.value === "SUPPLIER"
                            ? true
                            : false
                      }
                    />
                    <InputLabel>
                      <FormattedMessage
                        id="textfield.label.using2fa"
                        defaultMessage={
                          pageLabelsConstantsAndMessages.label.enabled2fa
                        }
                      />
                    </InputLabel>
                  </Grid>
                </Grid>
              )}
              <Grid item container xs={12} sm={6} md={3} lg={3}>
                <Grid container justifyContent="flex-start" alignItems="center">
                  <Controller
                    render={({ field: { onChange } }) => (
                      <Switch
                        color="primary"
                        disabled={userReadOnlyFields.active}
                        checked={
                          using2FAOrActiveToBeEdited.active !== undefined
                            ? using2FAOrActiveToBeEdited.active
                            : false
                        }
                        onChange={(event) => {
                          onChange(
                            !(using2FAOrActiveToBeEdited.active !== undefined
                              ? using2FAOrActiveToBeEdited.active
                              : false)
                          );
                          setUsing2FAOrActiveToBeEdited({
                            ...using2FAOrActiveToBeEdited,
                            active: event.target.checked,
                          });
                        }}
                        disabled={disabledForReadMode}
                      />
                    )}
                    type="checkbox"
                    name={pageLabelsConstantsAndMessages.name.active}
                    control={control}
                    defaultValue={
                      using2FAOrActiveToBeEdited.active !== undefined
                        ? using2FAOrActiveToBeEdited.active
                        : true
                    }
                  />
                  <InputLabel>
                    {
                      <FormattedMessage
                        id="common.active.lable"
                        defaultMessage={
                          pageLabelsConstantsAndMessages.label.active
                        }
                      />
                    }
                  </InputLabel>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} md={3} lg={3}>
                <Grid container justifyContent="flex-start" alignItems="center">
                  <Controller
                    render={({ field: { onChange } }) => (
                      <Switch
                        color="primary"
                        disabled={userReadOnlyFields.allowLogin}
                        checked={
                          using2FAOrActiveToBeEdited.allowLogin !== undefined
                            ? using2FAOrActiveToBeEdited.allowLogin
                            : true
                        }
                        onChange={(event) => {
                          onChange(
                            !(using2FAOrActiveToBeEdited.allowLogin !==
                              undefined
                              ? using2FAOrActiveToBeEdited.allowLogin
                              : true)
                          );
                          setUsing2FAOrActiveToBeEdited({
                            ...using2FAOrActiveToBeEdited,
                            allowLogin: event.target.checked,
                          });
                          clearErrors(["loginId", "password"]);
                        }}
                        disabled={disabledForReadMode}
                      />
                    )}
                    type="checkbox"
                    name={pageLabelsConstantsAndMessages.name.allowLogin}
                    control={control}
                    defaultValue={
                      using2FAOrActiveToBeEdited.allowLogin !== undefined
                        ? using2FAOrActiveToBeEdited.allowLogin
                        : true
                    }
                  />
                  <InputLabel>
                    {
                      <FormattedMessage
                        id="common.allowlogin.lable"
                        defaultMessage={
                          pageLabelsConstantsAndMessages.label.allowLogin
                        }
                      />
                    }
                  </InputLabel>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} md={3} lg={3}>
                <Grid container justifyContent="flex-start" alignItems="center">
                  <Controller
                    render={({ field: { onChange } }) => (
                      <Switch
                        color="primary"
                        disabled={userReadOnlyFields.accountLocked}
                        checked={
                          using2FAOrActiveToBeEdited.accountLocked !== undefined
                            ? using2FAOrActiveToBeEdited.accountLocked
                            : false
                        }
                        onChange={(event) => {
                          onChange(
                            !(using2FAOrActiveToBeEdited.accountLocked !==
                              undefined
                              ? using2FAOrActiveToBeEdited.accountLocked
                              : false)
                          );
                          setUsing2FAOrActiveToBeEdited({
                            ...using2FAOrActiveToBeEdited,
                            accountLocked: event.target.checked,
                          });
                        }}
                        disabled={disabledForReadMode}
                      />
                    )}
                    type="checkbox"
                    name={pageLabelsConstantsAndMessages.name.accountLocked}
                    control={control}
                    defaultValue={
                      using2FAOrActiveToBeEdited.accountLocked !== undefined
                        ? using2FAOrActiveToBeEdited.accountLocked
                        : false
                    }
                  />
                  <InputLabel>
                    {
                      <FormattedMessage
                        id="columnname.accountlocked.label"
                        defaultMessage={
                          pageLabelsConstantsAndMessages.label.accountLocked
                        }
                      />
                    }
                  </InputLabel>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>

        {isTabSectionDisplayAble && (
          <Paper
            className={classes.tabContainerForCreateOrEditUser}
            elevation={3}
          >
            <AppBar position="sticky" color="inherit" elevation={2}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                variant="fullWidth"
                scrollButtons
                textColor="secondary"
                aria-label="accessible tabs"
                allowScrollButtonsMobile
              >
                {roleModuleAccessFlagForTabVisibility && (
                  <Tab
                    style={{ fontWeight: 600 }}
                    disabled={!using2FAOrActiveToBeEdited.allowLogin}
                    label={
                      <FormattedMessage
                        id="common.module.tabname.roleaccess"
                        defaultMessage={
                          pageLabelsConstantsAndMessages.label.tabUserRoleAccess
                        }
                      />
                    }
                    {...a11yProps(
                      userTabsArray.indexOf(
                        pageLabelsConstantsAndMessages.label.tabUserRoleAccess,
                        0
                      )
                    )}
                  />
                )}
                {branchModuleAccessFlagForTabVisibility && (
                  <Tab
                    style={{ fontWeight: 600 }}
                    disabled={
                      userIdToBeSavedOrUpdated === 0 ||
                      !using2FAOrActiveToBeEdited.allowLogin
                    }
                    label={
                      <FormattedMessage
                        id="common.module.tabname.branchaccess"
                        defaultMessage={
                          pageLabelsConstantsAndMessages.label
                            .tabUserBranchAccess
                        }
                      />
                    }
                    {...a11yProps(
                      userTabsArray.indexOf(
                        pageLabelsConstantsAndMessages.label
                          .tabUserBranchAccess,
                        0
                      )
                    )}
                  />
                )}

                {supplierModuleAccessFlagForTabVisibility && (
                  <Tab
                    style={{ fontWeight: 600 }}
                    disabled={
                      (mode === "edit" &&
                        usertypeRoleUser.value === "SIIX-USER") ||
                      userIdToBeSavedOrUpdated === 0 ||
                      !using2FAOrActiveToBeEdited.allowLogin
                    }
                    label={
                      <FormattedMessage
                        id="common.module.tabname.supplieraccess"
                        defaultMessage={
                          pageLabelsConstantsAndMessages.label
                            .tabUserSupplierAccess
                        }
                      />
                    }
                    {...a11yProps(
                      userTabsArray.indexOf(
                        pageLabelsConstantsAndMessages.label
                          .tabUserSupplierAccess,
                        0
                      )
                    )}
                  />
                )}

                {projectModuleAccessFlagForTabVisibility && (
                  <Tab
                    style={{ fontWeight: 600 }}
                    disabled={
                      // userIdToBeSavedOrUpdated === 0 ||
                      // !using2FAOrActiveToBeEdited.allowLogin

                      (mode === "edit" &&
                        usertypeRoleUser.value === "SUPPLIER") ||
                      userIdToBeSavedOrUpdated === 0 ||
                      !using2FAOrActiveToBeEdited.allowLogin
                    }
                    label={
                      <FormattedMessage
                        id="common.module.tabname.projectaccess"
                        defaultMessage={
                          pageLabelsConstantsAndMessages.label
                            .tabUserProjectAccess
                        }
                      />
                    }
                    {...a11yProps(
                      userTabsArray.indexOf(
                        pageLabelsConstantsAndMessages.label
                          .tabUserProjectAccess,
                        0
                      )
                    )}
                  />
                )}
              </Tabs>
            </AppBar>
            {(usertypeRoleUser.value === "SIIX-USER" ||
              usertypeRoleUser.value === "SUPPLIER") &&
              roleModuleAccessFlagForTabVisibility && (
                <TabPanel
                  value={value}
                  index={userTabsArray.indexOf(
                    pageLabelsConstantsAndMessages.label.tabUserRoleAccess,
                    0
                  )}
                >
                  <Grid
                    item
                    container
                    justifyContent="center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <List className={classes.listforCreateOrEditUser}>
                      <ListItem className={classes.listHeader} key={0}>
                        <ListItemIcon>
                          <MenuIcon />
                        </ListItemIcon>
                        <ListItemText
                          id="switch-list-label-role"
                          primary={
                            <strong>
                              {
                                <FormattedMessage
                                  id="common.module.breadcrumb.role"
                                  defaultMessage={
                                    pageLabelsConstantsAndMessages.label
                                      .menuHeading
                                  }
                                />
                              }
                            </strong>
                          }
                        />
                        <ListItemSecondaryAction>
                          <strong>
                            {
                              <FormattedMessage
                                id="columnname.status.label"
                                defaultMessage={
                                  pageLabelsConstantsAndMessages.label
                                    .statusHeading
                                }
                              />
                            }
                          </strong>
                          <br />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      {isRoleAccessLoading && (
                        <Grid
                          item
                          container
                          justifyContent="center"
                          alignItems="center"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                        >
                          <CircularProgress />
                        </Grid>
                      )}
                      {filterRoleList &&
                        filterRoleList.map((role, index) => {
                          return (
                            <div>
                              <ListItem key={index + 1}>
                                <ListItemIcon>
                                  <IconRole />
                                </ListItemIcon>
                                <ListItemText
                                  id="switch-list-label"
                                  primary={role.name}
                                />
                                <ListItemSecondaryAction>
                                  <Switch
                                    edge="end"
                                    color="primary"
                                    onChange={handleRoleStatusChanges(role.id)}
                                    checked={isRoleAccessibleForCurrentUser(
                                      role.id
                                    )}
                                    inputProps={{
                                      "aria-labelledby": `switch-list-label-${role.name}`,
                                    }}
                                    disabled={
                                      using2FAOrActiveToBeEdited.allowLogin
                                        ? false
                                        : true
                                    }
                                    disabled={disabledForReadMode}
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                              <Divider />
                            </div>
                          );
                        })}
                    </List>
                  </Grid>
                </TabPanel>
              )}

            {branchModuleAccessFlagForTabVisibility && (
              <TabPanel
                value={value}
                index={userTabsArray.indexOf(
                  pageLabelsConstantsAndMessages.label.tabUserBranchAccess,
                  0
                )}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <List className={classes.listforCreateOrEditUser}>
                    <ListItem className={classes.listHeader} key={0}>
                      <ListItemIcon>
                        <MenuIcon />
                      </ListItemIcon>
                      <ListItemText
                        id="switch-list-label-role"
                        primary={
                          <strong>
                            {
                              <FormattedMessage
                                id="common.module.breadcrumb.columnname.entity"
                                defaultMessage={
                                  pageLabelsConstantsAndMessages.label
                                    .branchAccessHeading
                                }
                              />
                            }
                          </strong>
                        }
                      />
                      <ListItemSecondaryAction>
                        <strong style={{ paddingRight: 60 }}>
                          {
                            <FormattedMessage
                              id="columnname.status.label"
                              defaultMessage={
                                pageLabelsConstantsAndMessages.label
                                  .statusHeading
                              }
                            />
                          }
                        </strong>
                        <strong>
                          {
                            <FormattedMessage
                              id="columnname.defaultentity.label"
                              defaultMessage={
                                pageLabelsConstantsAndMessages.label
                                  .defaultEntityHeading
                              }
                            />
                          }
                        </strong>
                        <br />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    {isBranchAccessLoading && (
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                      >
                        <CircularProgress />
                      </Grid>
                    )}
                    {filterBranchList &&
                      filterBranchList.map((entity, index) => {
                        return (
                          <div>
                            <ListItem key={index + 1}>
                              <ListItemIcon>
                                <IconBranch />
                              </ListItemIcon>
                              <ListItemText
                                id="switch-list-label"
                                primary={entity.erpEntityCode}
                              />
                              <ListItemSecondaryAction>
                                <Switch
                                  sx={{ marginRight: 7 }}
                                  edge="end"
                                  color="primary"
                                  onChange={handleBranchAccessStatusChanges(
                                    entity.erpEntityCode
                                  )}
                                  checked={isBranchAccessibleForCurrentUser(
                                    entity.erpEntityCode
                                  )}
                                  inputProps={{
                                    "aria-labelledby": `switch-list-label-${entity.erpEntityCode}`,
                                  }}
                                  disabled={
                                    using2FAOrActiveToBeEdited.allowLogin
                                      ? false
                                      : true
                                  }
                                  disabled={disabledForReadMode}
                                />
                                <Switch
                                  edge="end"
                                  color="primary"
                                  onChange={handleBranchAccessStatusChangesForDefault(
                                    entity.erpEntityCode
                                  )}
                                  checked={isBranchAccessibleForCurrentUserDefault(
                                    entity.erpEntityCode
                                  )}
                                  inputProps={{
                                    "aria-labelledby": `switch-list-label-${entity.erpEntityCode}`,
                                  }}
                                  disabled={
                                    using2FAOrActiveToBeEdited.allowLogin
                                      ? false
                                      : true
                                  }
                                  disabled={disabledForReadMode}
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                          </div>
                        );
                      })}
                  </List>
                </Grid>
              </TabPanel>
            )}
            {usertypeRoleUser.value === "SUPPLIER" &&
              supplierModuleAccessFlagForTabVisibility && (
                <TabPanel
                  value={value}
                  index={userTabsArray.indexOf(
                    pageLabelsConstantsAndMessages.label.tabUserSupplierAccess,
                    0
                  )}
                >
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <div
                      style={{
                        width: 700,
                        padding: "5px 5px 0 5px",
                        marginTop: "0.6%",
                      }}
                    >
                      <FormControl fullWidth>
                        <TextField
                          className={classes.textField}
                          autoFocus
                          margin="normal"
                          id="vendorName"
                          size="small"
                          label={
                            <FormattedMessage
                              id="columnname.vendorname.label"
                              defaultMessage="Vendor Name"
                            />
                          }
                          placeholder={intl.formatMessage({
                            id: "textfield.placeholder.txt",
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={searchedVendorName}
                          onChange={(ev) => {
                            if (
                              ev.target.value !== "" ||
                              ev.target.value !== null
                            ) {
                              setSearchedVendorName(ev.target.value);
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {searchedVendorName.length > 0 && (
                                  <IconButton
                                    aria-label="clear_text"
                                    onClick={() => {
                                      setSearchedVendorName("");
                                    }}
                                    edge="end"
                                    size="large"
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                          disabled={disabledForReadMode}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <List className={classes.listforCreateOrEditUser}>
                      <ListItem className={classes.listHeader} key={0}>
                        <ListItemIcon>
                          <MenuIcon />
                        </ListItemIcon>
                        <ListItemText
                          id="switch-list-label-role"
                          primary={
                            <strong>
                              {
                                <FormattedMessage
                                  id="common.menu.module.breadcrumb.supplier"
                                  defaultMessage={
                                    pageLabelsConstantsAndMessages.label
                                      .tabUserSupplierAccess
                                  }
                                />
                              }
                            </strong>
                          }
                        />
                        <ListItemSecondaryAction>
                          <strong>
                            {
                              <FormattedMessage
                                id="columnname.status.label"
                                defaultMessage={
                                  pageLabelsConstantsAndMessages.label
                                    .statusHeading
                                }
                              />
                            }
                          </strong>
                          <br />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      {isSupplierAccessLoading && (
                        <Grid
                          item
                          container
                          justifyContent="center"
                          alignItems="center"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                        >
                          <CircularProgress />
                        </Grid>
                      )}
                      {activeSupplierList &&
                        activeSupplierList.map((supplier, index) => {
                          return (
                            <div>
                              <ListItem key={index + 1}>
                                <ListItemIcon>
                                  <IconSupplier />
                                </ListItemIcon>
                                <ListItemText
                                  id="switch-list-label"
                                  primary={
                                    supplier.bpSupplierName +
                                    " - " +
                                    supplier.bpCode
                                  }
                                />
                                <ListItemSecondaryAction>
                                  <Switch
                                    edge="end"
                                    color="primary"
                                    onChange={handleUserSupplierStatusChanges(
                                      supplier.id
                                    )}
                                    checked={isSupplierAccessibleForCurrentUser(
                                      supplier.id
                                    )}
                                    inputProps={{
                                      "aria-labelledby": `switch-list-label-${supplier.bpSupplierName}`,
                                    }}
                                    disabled={disabledForReadMode}
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                              <Divider />
                            </div>
                          );
                        })}
                    </List>
                  </Grid>
                </TabPanel>
              )}
            {projectModuleAccessFlagForTabVisibility && (
              <TabPanel
                value={value}
                index={userTabsArray.indexOf(
                  pageLabelsConstantsAndMessages.label.tabUserProjectAccess,
                  0
                )}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <div
                    style={{
                      width: 700,
                      padding: "5px 5px 0 5px",
                      marginTop: "0.6%",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textField}
                        autoFocus
                        margin="normal"
                        id="projectName"
                        size="small"
                        label={
                          <FormattedMessage
                            id="columnname.projectName.label"
                            defaultMessage="Project Name"
                          />
                        }
                        placeholder={intl.formatMessage({
                          id: "textfield.placeholder.projectName.txt",
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={searchedProjectName}
                        onChange={(ev) => {
                          if (
                            ev.target.value !== "" ||
                            ev.target.value !== null
                          ) {
                            setSearchedProjectName(ev.target.value);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {searchedProjectName.length > 0 && (
                                <IconButton
                                  aria-label="clear_text"
                                  onClick={() => {
                                    setSearchedProjectName("");
                                  }}
                                  edge="end"
                                  size="large"
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        disabled={disabledForReadMode}
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <List className={classes.listforCreateOrEditUser}>
                    <ListItem className={classes.listHeader} key={0}>
                      <ListItemIcon>
                        <MenuIcon />
                      </ListItemIcon>
                      <ListItemText
                        id="switch-list-label-role"
                        primary={
                          <strong>
                            {
                              <FormattedMessage
                                id="common.module.breadcrumb.columnname.project"
                                defaultMessage={
                                  pageLabelsConstantsAndMessages.label
                                    .projectAccessHeading
                                }
                              />
                            }
                          </strong>
                        }
                      />
                      <ListItemSecondaryAction>
                        <strong style={{ paddingRight: 60 }}>
                          {
                            <FormattedMessage
                              id="columnname.status.label"
                              defaultMessage={
                                pageLabelsConstantsAndMessages.label
                                  .statusHeading
                              }
                            />
                          }
                        </strong>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    {isProjectAccessLoading && (
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                      >
                        <CircularProgress />
                      </Grid>
                    )}
                    {activeProjectList &&
                      activeProjectList.map((project, index) => {
                        return (
                          <div>
                            <ListItem key={index + 1}>
                              <ListItemIcon>
                                <IconProject />
                              </ListItemIcon>
                              <ListItemText
                                id="switch-list-label"
                                primary={
                                  project.projectCode +
                                  " - " +
                                  project.projectName
                                }
                              />
                              <ListItemSecondaryAction>
                                <Switch
                                  sx={{ marginRight: 7 }}
                                  edge="end"
                                  color="primary"
                                  onChange={handleProjectAccessStatusChanges(
                                    project.id
                                  )}
                                  checked={isProjectAccessibleForCurrentUser(
                                    project.id
                                  )}
                                  inputProps={{
                                    "aria-labelledby": `switch-list-label-${project.id}`,
                                  }}
                                  disabled={
                                    using2FAOrActiveToBeEdited.allowLogin
                                      ? false
                                      : true
                                  }
                                  disabled={disabledForReadMode}
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                          </div>
                        );
                      })}
                  </List>
                </Grid>
              </TabPanel>
            )}
          </Paper>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default CreateOrEditUser;
