import { CircularProgress, CssBaseline, FormControl, FormHelperText, Grid, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import { CommonService } from '../../../../services';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import useStyles from '../../CommonStyle/CommonStyle'
import { FormattedMessage } from 'react-intl';
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';

const CommIdCombo = (props) => {

    const classes = useStyles();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const { comboProperty, control, errors, comboValue, setComboValue, commTypeValue, emailConfigNameList, setEmailConfigNameList, transConfigNameList, setTransConfigNameList ,bpCodeValue,erpEntityCode} = props;
    const [isLoading, setLoading] = useState(false);

    const [emailConfigdata, setEmailConfigdata] = useState([]);
    const [transConfigData, setTransConfigData] = useState([]);

    useEffect(() => {
        if (emailConfigNameList !== null) {
            setEmailConfigdata(emailConfigNameList.map((emailCommConfig) => {
                return { confName: emailCommConfig.emailConfName, commId: emailCommConfig.commId }
            }))
        }
    }, [emailConfigNameList]);

    var uniqueEmailConfData = emailConfigdata.filter((val, id) => emailConfigdata.indexOf(val) == id);

    useEffect(() => {
        if (transConfigNameList !== null) {
            setTransConfigData(transConfigNameList.map((transmissionConfig) => {
                return { confName: transmissionConfig.transConfName, commId: transmissionConfig.protoConfigId }
            }))
        }
    }, [transConfigNameList]);

    var uniqueTransConfData = transConfigData.filter((val, id) => transConfigData.indexOf(val) == id);

    const [paramBodyForEmailConf, setParamBodyForEmailConf] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.commTypeEmailConfig);

    useEffect(() => {
        setParamBodyForEmailConf({ ...paramBodyForEmailConf, sortDir: 'asc', sortKey: 'emailConfName', recordsPerPage: 100 });
    }, []);

    const [paramBodyForTransmissionConf, setParamBodyForTransmissionConf] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.commTypeTransmissionConfig);

    useEffect(() => {
        setParamBodyForTransmissionConf({ ...paramBodyForTransmissionConf, body: {bpCode: bpCodeValue,entity:{erpEntityCode}}, sortDir: 'asc', sortKey: 'transConfName', recordsPerPage: 100 });
    }, []);

    const loadEmailAndTransConfigData = () => {
        if (commTypeValue.newValue === 'Email') {
            setLoading(true);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.emailCommConfig, paramBodyForEmailConf)
                .then((response) => {
                    setLoading(false);
                    setEmailConfigNameList(response.data.content);
                })
        } else if (commTypeValue.newValue === 'AS2' || commTypeValue.newValue === 'FTP' || commTypeValue.newValue === 'VAN' || commTypeValue.newValue === 'SFTP') {
            setLoading(true);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.transmissionConfigs, paramBodyForTransmissionConf)
                .then((response) => {
                    setLoading(false);
                    setTransConfigNameList(response.data.content);
                })
        } else {
            setTransConfigNameList([]);
        }
    }

    return (
        <React.Fragment>
            <CssBaseline />
            { comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth>
                    <Controller
                        render={({
                            field: { onChange } }) => (
                            <Autocomplete
                                onChange={(event, newValue) => {
                                    onChange(newValue)

                                    if (typeof newValue === 'string') {

                                        if (newValue != null) {
                                            setComboValue({
                                                newValue,
                                            });
                                        }
                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setComboValue({
                                            title: newValue.inputValue,
                                        });

                                    }
                                    else if (newValue !== null) {

                                        setComboValue(newValue);
                                    } else if (newValue === null) {
                                        setComboValue('')
                                    } else {
                                        setComboValue(newValue);
                                    }
                                }}
                                selectOnFocus
                                handleHomeEndKeys
                                value={commTypeValue.newValue === 'Email' ? comboValue.confName : comboValue.confName || null}
                                autoHighlight
                                loadingText="Loading..."
                                loading={isLoading}
                                onOpen={() => {
                                    loadEmailAndTransConfigData('');
                                }}
                                options={commTypeValue.newValue === 'Email' ? uniqueEmailConfData : uniqueTransConfData}
                                getOptionLabel={(option) => {

                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return commTypeValue.newValue === 'Email' ? option.confName : option.confName;
                                }}
                                getoptionselected={(option, value) => (commTypeValue.newValue === 'Email' ? option.confName : option.confName) === value}
                                disabled={comboProperty.isDisabled}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        autoFocus={comboProperty.isAutofocus}
                                        name={comboProperty.name}
                                        size='small'
                                        required
                                        label={comboProperty.label}
                                        variant={comboProperty.variant}
                                        InputLabelProps={{
                                            shrink: true,
                                            classes: {
                                                asterisk: classes.asterisk
                                            }
                                        }}
                                        error={comboProperty.errors}
                                    />
                                )}

                            />
                        )}

                        name={comboProperty.name}
                        rules={{ required: comboProperty.isRequired }}
                        control={control}
                        defaultValue={{ commId: '' }}
                    />
                    {comboProperty.isDisabled}
                    <div className={classes.error}>
                        {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                            <FormHelperText className={classes.error}>{comboProperty.label} <FormattedMessage id="textfield.error.isrequirededmessage" defaultMessage="is required" /></FormHelperText>
                        )}
                    </div>
                </FormControl>
            </Grid>)}

            { !comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "3px" }}>
                <FormControl fullWidth>
                    <Autocomplete
                        fullWidth
                        openOnFocus={true}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {

                                if (newValue != null) {
                                    setComboValue({
                                        newValue,
                                    });
                                }
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setComboValue({
                                    title: newValue.inputValue,
                                });

                            }
                            else if (newValue !== null) {

                                setComboValue(newValue);
                            } else if (newValue === null) {
                                setComboValue('')
                            } else {
                                setComboValue(newValue);
                            }
                        }}
                        selectOnFocus
                        loadingText="Loading..."
                        loading={isLoading}
                        handleHomeEndKeys
                        value={commTypeValue.newValue === 'Email' ? comboValue.confName : comboValue.confName || null}
                        autoHighlight
                        onOpen={() => {
                            loadEmailAndTransConfigData('');
                        }}
                        options={commTypeValue.newValue === 'Email' ? emailConfigdata : transConfigData}
                        getOptionLabel={(option) => {

                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return commTypeValue.newValue === 'Email' ? option.confName : option.confName;
                        }}
                        getoptionselected={(option, value) => commTypeValue.newValue === 'Email' ? option.confName : option.confName === value}
                        disabled={comboProperty.isDisabled}
                        renderInput={params => (
                            <Tooltip title={comboValue.commId || ''}>
                                <TextField
                                    {...params}
                                    autoFocus={comboProperty.isAutofocus}
                                    name={comboProperty.name}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(ev) => {
                                        if (
                                            ev.target.value !== "" ||
                                            ev.target.value !== null
                                        ) {
                                            loadEmailAndTransConfigData(ev.target.value);
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {isLoading ?
                                                    <CircularProgress color="inherit" size={20} />
                                                    : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    size='small'
                                    label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                    variant={comboProperty.variant}
                                />
                            </Tooltip>
                        )}

                    />
                </FormControl>
            </Grid>)}

        </React.Fragment>
    );
};

CommIdCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default CommIdCombo;

