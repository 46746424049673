import { CircularProgress, CssBaseline, FormControl, FormHelperText, Grid, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import { CommonService } from '../../../../services';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import useStyles from '../../CommonStyle/CommonStyle';
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';
import { FormattedMessage } from 'react-intl';

const CustomerCombo = (props) => {

    const classes = useStyles();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const { comboProperty, control, errors, comboValue, setComboValue } = props;
    const [customerList, setCustomerList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const customerData = customerList.map((customer) => {
        return {  
            bpCustomerName:customer.bpCustomerName
        }
    });
    const [searchedBpCustomerName, setSearchedBpCustomerName] = useState('');

    const filteredCustomerData = customerData.filter(item => {
        return item.bpCustomerName.toLowerCase().startsWith(searchedBpCustomerName.toLowerCase());
    });
    
    const loadCustomerData = () => {
        setLoading(true);
        CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.customer, {
            body: { bpCustomerName: '',recordActiveStatus: 'All' }, sortDir: 'asc', sortKey: 'bpCustomerName', recordsPerPage: 2000
        } )
            .then((response) => {
                setLoading(false);
                setCustomerList(response.data.content);
            })
    }

    const key = "bpCustomerName"

    const uniqueCustomerName = [...new Map(filteredCustomerData.map(item => [item[key], item])).values()];

    return (
        <React.Fragment>
            <CssBaseline />
        { comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth>
                <Controller
                    render={props => (
                        <Autocomplete

                            onChange={(event, newValue) => {
                                props.onChange(newValue)

                                if (typeof newValue === 'string') {

                                    if (newValue != null) {
                                        setComboValue({
                                            newValue,
                                        });
                                    }
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the supplier input
                                    setComboValue({
                                        title: newValue.inputValue,
                                    });

                                }
                                else if (newValue !== null) {

                                    setComboValue(newValue);
                                } else if (newValue === null) {
                                    setComboValue('')
                                } else {
                                    setComboValue(newValue);
                                }
                            }}
                            selectOnFocus
                            handleHomeEndKeys
                            value={comboValue.bpCustomerName || null}
                            autoHighlight
                            loadingText="Loading..."
                            onOpen={() => {
                                setSearchedBpCustomerName('');
                            }}
                            options={uniqueCustomerName}
                            getOptionLabel={(option) => {

                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.bpCustomerName;
                            }}
                            getoptionselected={(option, value) => option.bpCustomerName === value}
                            disabled={comboProperty.isDisabled}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    autoFocus={comboProperty.isAutofocus}
                                    name={comboProperty.name}
                                    size='small'
                                    label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                    variant={comboProperty.variant}
                                />
                            )}

                        />
                    )}

                    name={comboProperty.name}
                    rules={{ required: comboProperty.isRequired }}
                    control={control}
                    defaultValue={{  bpCustomerName: '' }}
                />
                {comboProperty.isDisabled && (<FormHelperText>{comboProperty.label} can't be edited</FormHelperText>)}
                <div className={classes.error}>
                    {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                        <FormHelperText className={classes.error}>{comboProperty.label} <FormattedMessage id="textfield.error.isrequirededmessage" defaultMessage="is required" /></FormHelperText>
                    )}
                </div>
            </FormControl>
        </Grid>)}

        { !comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: "3px"}}>
            <FormControl fullWidth>
                        <Autocomplete
                            fullWidth
                            openOnFocus = {true}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {

                                    if (newValue != null) {
                                        setComboValue({
                                            newValue,
                                        });
                                    }
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the supplier input
                                    setComboValue({
                                        title: newValue.inputValue,
                                    });

                                }
                                else if (newValue !== null) {

                                    setComboValue(newValue);
                                } else if (newValue === null) {
                                    setComboValue('')
                                } else {
                                    setComboValue(newValue);
                                }
                            }}
                            selectOnFocus
                            loadingText="Loading..."
                            loading={isLoading}
                            handleHomeEndKeys
                            value={comboValue.bpCustomerName || null}
                            autoHighlight
                            onOpen={() => {
                                loadCustomerData();
                            }}
                            options={uniqueCustomerName}
                            getOptionLabel={(option) => {

                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.bpCustomerName;
                            }}
                            getoptionselected={(option, value) => option.bpCustomerName === value}
                            disabled={comboProperty.isDisabled}
                            renderInput={params => (
                                <Tooltip title={comboValue.bpCustomerName || ''}>
                                    <TextField
                                    {...params}
                                    autoFocus={comboProperty.isAutofocus}
                                    name={comboProperty.name}
                                    onChange={(ev) => {
                                        if (
                                            ev.target.value !== "" ||
                                            ev.target.value !== null
                                        ) {
                                            setSearchedBpCustomerName(ev.target.value);
                                        } 
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {isLoading ?
                                                    <CircularProgress color="inherit" size={20} />
                                                : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    size='small'
                                    label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                    variant={comboProperty.variant}
                                />
                                </Tooltip>
                            )}

                        />
            </FormControl>
        </Grid>)}

        </React.Fragment>
    );
};

CustomerCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default CustomerCombo;

