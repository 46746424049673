import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    Button, CssBaseline, FormControl,
    FormHelperText, Grid, IconButton,
    InputAdornment, InputLabel, Tooltip,
    Paper, Snackbar, TextField, useMediaQuery, useTheme, Badge
} from '@mui/material';
import ViewIcon from '@mui/icons-material/Visibility';
import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import {
    LicenseInfo, DataGridPro, getGridStringOperators,
    GridToolbarColumnsButton, GridToolbarDensitySelector
} from '@mui/x-data-grid-pro';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import Alert from '@mui/lab/Alert';
import Switch from '@mui/material/Switch';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconBack from '@mui/icons-material/BackspaceOutlined';
import _ from "lodash/fp";
import CommonBackDrop from "../../common/BackDrop/CommonBackDrop";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CustomBreadCrumb from "../../common/CustomBreadCrumb/CustomBreadCrumb";
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CommonService } from '../../../services/CommonServices.service';
import RefreshIcon from '@mui/icons-material/Refresh';
import useStyles from '../../common/CommonStyle/CommonStyle';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import EntityCodeCombo from "../../common/Combos/EntityCodeCombo/EntityCodeCombo";
import InterfaceTypeCombo from '../../common/Combos/InterfaceTypeCombo/interfaceTypeCombo';
import InterfaceDialogComponent from '../InterfaceDialogComponent/InterfaceDialogComponent';
import { CustomFilters } from '../../common/CustomFilters/CustomFilters';
import { GlobalEdiApiConstants } from "../../../Constants/GlobalEdiApiConstants";
import ERPNameCombo from '../../common/Combos/ERPNameCombo';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import IconInterfaceConfiguration from '@mui/icons-material/SwapCalls';
import { FilterList } from '@mui/icons-material';
import CustomFilterComponent, { GetFilterableColumns } from '../../common/CustomFilterComponent';

const columnFieldsName = {
    process: 'processMaster.process',
    active: 'active',
    inOut: 'inOut',
    fileType: 'fileType',

}

const CreateOrEditErpinterface = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const localtext = GridTextLocalization();
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const { mode, intfHdrId } = props.location.state;
    const currentUser = CommonService.getLoggedInUserInfo();
    const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('');
    const [componentList, setComponentList] = useState([]);
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [branchValue, setBranchValue] = useState('');
    const [intfTypeValue, setIntfTypeValue] = useState('');
    const [orgValue, setOrgValue] = useState({ erpOrgCode: '', orgName: '' });
    const [erpNameValue, setErpName] = useState('');
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [openErpInterfaceDialogBox, setOpenErpInterfaceDialog] = useState(false);
    const [modeChanged, setModeChanged] = useState('');
    const [erpInterfaceDtlForVieworEditMode, setErpInterfaceDtlForVieworEditMode] = useState('')
    const [interfaceHeaderDataToBeEditedorViewed, setInterfaceHeaderDataToBeEditedorViewed] = useState({})
    const [tableData, setTableData] = useState([]);
    const [resettingActiveValue, setResettingActiveValue] = useState({});
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.erpInterfaceDtls);
    const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());
    const [defaultOrgObj, setDefaultOrgObj] = useState({});
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const erpInterfaceHdrModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.erpInterfaceHdr];
    const commonPatterns = GlobalEdiApiConstants();
    const smallScreen = useMediaQuery(theme.breakpoints.down(889))
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    const defaulSelectedFilters = [];
    const [selectedFilters, setSelectedFilters] = useState([]);

    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    useEffect(() => {
        if (orgInfoOfLeggedUser.erpOrgCode && mode === 'create') {
            setDefaultOrgObj({ erpOrgCode: orgInfoOfLeggedUser.erpOrgCode, orgName: orgInfoOfLeggedUser.orgName, valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}` });
        }
    }, [orgInfoOfLeggedUser])

    const componentListforDetail = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.interfaceconfiguration" />,
            iconName: IconInterfaceConfiguration,
        },
        {
            path: '/erpInterfaceHdrs',
            name: <FormattedMessage id="common.menu.breadcrumb.erpinterface" defaultMessage="ERP Interface" />,
            iconName: CompareArrowsIcon,
        }
    ];
    const commonBreadCrumbToBeUsedForDialog = componentListforDetail;
    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density',
            baseUri: 'Base Uri',
            userName: 'User Name',
            intfDesc: 'ERP Interface Description',
        },
        name: {
            organization: 'organization',
            entity: 'entity',
            active: 'active',
            intfType: 'intfType',
            intfDesc: 'intfDesc',
            baseUri: 'baseUri',
            userName: 'userName',
            password: 'password',
            erpName: 'erpName',
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    }

    const columnsForErpDetails = [
        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: 130,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    {(erpInterfaceHdrModuleAccess !== undefined ? erpInterfaceHdrModuleAccess.editFlag : false) && (mode !== 'view') && (
                        <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                setOpenErpInterfaceDialog(true)
                                setModeChanged('edit')
                                setErpInterfaceDtlForVieworEditMode(cellParams.row)
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}

                    {(erpInterfaceHdrModuleAccess !== undefined ? erpInterfaceHdrModuleAccess.readFlag : false) &&
                        (<Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.readButton} />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                setOpenErpInterfaceDialog(true)
                                setModeChanged('view')
                                setErpInterfaceDtlForVieworEditMode(cellParams.row)
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <ViewIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                </div>
            )
        },
        {
            field: columnFieldsName.process,
            headerName: intl.formatMessage({ id: "columnname.processname.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 190,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            valueGetter: (params) => `${params.row.processMaster.process}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.inOut,
            headerName: intl.formatMessage({ id: "columnname.inout.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 230,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: ((cellParams) => {
                if (cellParams.row.inOut === 'IN') {
                    return 'Download from ERP'
                } else if (cellParams.row.inOut === 'OUT') {
                    return 'Upload to ERP'
                }
            })
        },
        {
            field: 'intfId',
            width: 160,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            headerName: intl.formatMessage({ id: "columnname.interfaceId.label" }),
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: 'frequency',
            width: 160,
            headerName: intl.formatMessage({ id: "columnname.frequency.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: false,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'updateAction',
            headerName: intl.formatMessage({ id: "common.interfaceAction.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: ((cellParams) => {
                if (cellParams.row.updateAction === undefined || cellParams.row.updateAction === '') {
                    return 'None'
                } else {
                    return cellParams.row.updateAction
                }
            }),
        },
        {
            field: columnFieldsName.fileType,
            headerName: intl.formatMessage({ id: "columnname.fileType.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 125,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.active,
            headerName: intl.formatMessage({ id: 'columnname.status.label' }),
            width: 145,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'string',
            filterable: true,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.customRenderStatusColumn
        },
        {
            field: 'updatedTime',
            headerName: intl.formatMessage({ id: 'columnname.updateddate.label' }),
            width: 180,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'dateTime',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.lastUpdatedDtDisp}</span>)
        },
        {
            field: 'updatedBy',
            headerName: intl.formatMessage({ id: 'columnname.updatedby.label' }),
            width: 170,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'createdBy',
            headerName: intl.formatMessage({ id: 'columnname.columnnamecreatedby.label' }),
            width: 180,
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'createdTime',
            headerName: intl.formatMessage({ id: 'columnname.createddate.label' }),
            width: 180,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'dateTime',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.createdDtDisp}</span>)
        },
    ]

    const [columns, setColumns] = useState([]);
    useEffect(() => {
        setColumns(columnsForErpDetails)
    }, [selectedLanguage])


    useEffect(() => {
        if (mode === 'edit' || mode === 'view') {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.erpInterfaceHdr}/${intfHdrId}/erpInterfaceDtls`, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [paramBody, refreshTable])

    if (columns.length > 0) {
        const processColumn = columns.find((column) => column.field === columnFieldsName.process);
        const processColIndex = columns.findIndex((col) => col.field === columnFieldsName.process);

        const processFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.ProcessInputValue,
            }),
        );

        columns[processColIndex] = {
            ...processColumn,
            filterOperators: processFilterOperators,
        };

        const activeColumn = columns.find((column) => column.field === columnFieldsName.active);
        const activeColIndex = columns.findIndex((col) => col.field === columnFieldsName.active);

        const activeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.StatusColInputValue,
            }),
        );

        columns[activeColIndex] = {
            ...activeColumn,
            filterOperators: activeFilterOperators,
        };

        const inOutColumn = columns.find((column) => column.field === columnFieldsName.inOut);
        const inOutColIndex = columns.findIndex((col) => col.field === columnFieldsName.inOut);

        const inOutFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.InOutInputValue,
            }),
        );

        columns[inOutColIndex] = {
            ...inOutColumn,
            filterOperators: inOutFilterOperators,
        };

        const fileTypeColumn = columns.find((column) => column.field === columnFieldsName.fileType);
        const fileTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.fileType);

        const fileTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.FileTypeInputValue,
            }),
        );

        columns[fileTypeColIndex] = {
            ...fileTypeColumn,
            filterOperators: fileTypeFilterOperators,
        };
    }

    const [erpIinterfaceReadOnlyFields, setErpInterfaceReadOnlyFields] = useState({
        organization: false,
        entity: false,
        active: false,
        intfType: false,
        intfDesc: false,
        baseUri: false,
        userName: false,
        password: false,

    });

    const { handleSubmit, formState: { errors, isDirty }, reset, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: {
            entity: '',
            active: true,
            organization: '',
            intfType: '',
            intfDesc: '',
            baseUri: '',
            userName: '',
            password: '',
        },
    });

    useEffect(() => {
        if (mode === 'edit' || mode === 'view') {
            CommonService.getByIdApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.erpInterfaceHdr, intfHdrId)
                .then((response) => {
                    setOpenBackDrop(false);
                    setInterfaceHeaderDataToBeEditedorViewed(response.data);
                    erpInterfaceResettingValues(response.data)


                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    setOpenBackDrop(false);
                });
        }
    }, []);

    function erpInterfaceResettingValues(responseData) {
        reset({
            organization: responseData.organization !== undefined ? { erpOrgCode: responseData.organization.erpOrgCode, orgName: responseData.organization.orgName } : defaultOrgObj,
            entity: responseData.entity !== undefined ? { erpEntityCode: responseData.entity.erpEntityCode, entityName: responseData.entity.entityName } : [],
            baseUri: responseData.baseUri !== undefined ? responseData.baseUri : '',
            userName: responseData.userName !== undefined ? responseData.userName : '',
            password: responseData.password !== undefined ? responseData.password : '',
            intfDesc: responseData.intfDesc !== undefined ? responseData.intfDesc : '',
            intfType: responseData.intfType !== undefined ? responseData.intfType : '',
            erpName: responseData.erpName !== undefined ? responseData.erpName : '',
            active: responseData.active !== undefined ? responseData.active : '',
        })
        setOrgValue(responseData.organization !== undefined ? { valueToBeDisplayed: responseData.organization } : defaultOrgObj);
        setBranchValue(responseData.entity !== undefined ? { valuesToBeDisplayed: responseData.entity } : []);
        setIntfTypeValue(responseData.intfType !== undefined ? { value: responseData.intfType } : []);
        setErpName(responseData.erpName !== undefined ? { value: responseData.erpName } : []);
    }

    useEffect(() => {
        if (mode === 'create') {
            erpInterfaceResettingValues({});
        }
    }, [defaultOrgObj])

    useEffect(() => {
        setOpenBackDrop(true);
        let currentRouterComponent;
        if (mode === 'create') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            isRestoreButtonDisabled(false);
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                iconName: CreateIcon,
            }
            setOpenBackDrop(false);
        } else if (mode === 'edit') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isRestoreButtonDisabled(false);
            setIsSaveButtonDisabled(false)
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                iconName: EditIcon,
            }
            setErpInterfaceReadOnlyFields({ ...erpIinterfaceReadOnlyFields, intfType: true, organization: true, entity: true })
            erpInterfaceResettingValues(interfaceHeaderDataToBeEditedorViewed)

        } else {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isRestoreButtonDisabled(true);
            setIsSaveButtonDisabled(true);
            currentRouterComponent =
            {
                path: null,
                name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
                iconName: ViewIcon,
            }
            setErpInterfaceReadOnlyFields({ ...erpIinterfaceReadOnlyFields, intfType: true, organization: true, entity: true, intfDesc: true, userName: true, password: true, baseUri: true })
            erpInterfaceResettingValues(interfaceHeaderDataToBeEditedorViewed)

        }
        setComponentList([
            {
                path: undefined,
                name: <FormattedMessage id="common.menu.breadcrumb.interfaceconfiguration" />,
                iconName: IconInterfaceConfiguration,
            },
            {
                path: '/erpInterfaceHdrs',
                name: <FormattedMessage id="common.menu.breadcrumb.erpinterface" defaultMessage="ERP Interface" />,
                iconName: CompareArrowsIcon,
            },
            currentRouterComponent
        ])
    }, [mode]);

    const onSubmitHeader = (data) => {
        data['intfHdrId'] = intfHdrId;
        data['intfType'] = (mode === 'edit') ? (interfaceHeaderDataToBeEditedorViewed.intfType === undefined) ? [] : interfaceHeaderDataToBeEditedorViewed.intfType : data.intfType.value;
        data['erpName'] = (mode === 'edit') ? (interfaceHeaderDataToBeEditedorViewed.erpName === undefined) ? [] : interfaceHeaderDataToBeEditedorViewed.erpName : data.erpName.value;
        data['active'] = (interfaceHeaderDataToBeEditedorViewed.active === undefined) ? true : interfaceHeaderDataToBeEditedorViewed.active;
        data['createdBy'] = (mode === 'create') ? currentUser.userName : interfaceHeaderDataToBeEditedorViewed.createdBy;
        data['createdTime'] = (interfaceHeaderDataToBeEditedorViewed.createdTime === undefined) ? '' : interfaceHeaderDataToBeEditedorViewed.createdTime;
        data['active'] = mode === 'create' ? resettingActiveValue.active !== undefined ? resettingActiveValue.active : true : resettingActiveValue.active;
        data['updatedBy'] = currentUser.userName;
        data['updatedTime'] = (interfaceHeaderDataToBeEditedorViewed.updatedTime === undefined) ? '' : interfaceHeaderDataToBeEditedorViewed.updatedTime;
        data['organization'] = (interfaceHeaderDataToBeEditedorViewed.organization === undefined) ? { erpOrgCode: orgInfoOfLeggedUser.erpOrgCode } : interfaceHeaderDataToBeEditedorViewed.organization;
        setOpenBackDrop(true)
        CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.erpInterfaceHdr, data)
            .then((response) => {
                setOpenBackDrop(false);
                history.goBack();
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success" });
            })
            .catch(function (error) {
                setOpenBackDrop(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            });
    }

    useEffect(() => {
        setResettingActiveValue(interfaceHeaderDataToBeEditedorViewed);
    }, [interfaceHeaderDataToBeEditedorViewed]);


    function resetAllClearThePageInfo() {
        if (mode === 'edit') {
            erpInterfaceResettingValues(interfaceHeaderDataToBeEditedorViewed)
            setResettingActiveValue({ ...interfaceHeaderDataToBeEditedorViewed })

        } else {
            reset({
                entity: '',
                active: true,
                organization: '',
                intfType: '',
                intfDesc: '',
                userName: '',
                password: '',
                baseUri: '',
                erpName: '',
            });
            setOrgValue([]);
            setErpName([]);
            setBranchValue([]);
            setIntfTypeValue([]);
            setResettingActiveValue({ ...interfaceHeaderDataToBeEditedorViewed });
            setDefaultOrgObj({ ...defaultOrgObj, valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} - ${orgInfoOfLeggedUser.orgName}` })
        }
    }

    const branchComboProperty = {
        name: pageLabelsConstantsAndMessages.name.entity,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.entityCode.label" }),
        isAutofocus: false,
        isDisabled: (mode === 'edit' || mode === 'view') ? true : false,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.entity
    }
    const erpInterfaceComboProperty = {
        name: pageLabelsConstantsAndMessages.name.intfType,
        isRequired: true,
        label: intl.formatMessage({ id: "columnname.erpInterfaceType.label" }),
        isAutofocus: false,
        isDisabled: (mode === 'edit' || mode === 'view') ? true : false,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.intfType
    }
    const erpNameComboProperty = {
        name: pageLabelsConstantsAndMessages.name.erpName,
        isRequired: true,
        label: intl.formatMessage({ id: "columnname.erpName.label" }),
        isAutofocus: false,
        isDisabled: (mode === 'edit' || mode === 'view') ? true : false,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.erpName
    }

    const closeErpInterfaceDetail = (confirmed) => {
        if (confirmed) {
            setRefreshTable(!refreshTable);
        }
        setErpInterfaceDtlForVieworEditMode({})
        setOpenErpInterfaceDialog(false)
    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let process = '';
        let inOut = '';
        let fileType = '';
        let active = '';
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'processMaster.process':
                    process = columns
                    break;
                case 'inOut':
                    inOut = columns
                    break;
                case 'fileType':
                    fileType = columns
                    break;
                case 'active':
                    active = columns
                    break;
            }
        })

        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    processMaster: {
                        process: '',
                        processId: process.value === 'Advance Shipment Note' ? 3 : process.value === 'Customer' ? 13 :
                            process.value === 'Inventory Report' ? 6 : process.value === 'Invoice' ? 5 :
                                process.value === 'PO Delivery Confirmation' ? 14 : process.value === 'PO PDF' ? 8 :
                                    process.value === 'Purchase Forecast' ? 2 : process.value === 'Purchase Order' ? 11 :
                                        process.value === 'Purchase Order/Purchase Forecast' ? 1 : process.value === 'Supplier' ? 12 : process.value === 'VMI Consumption Report' ? 7 : '',
                    },
                    inOut: inOut.value === 'Download from ERP' ? 'IN' : inOut.value === 'Upload to ERP' ? 'OUT' : '',
                    fileType: fileType.value,
                    active: active.value === 'Active' ? true : active.value === 'In-Active' ? false : '',
                    recordActiveStatus: active.value === '' ? 'All' : active.value === 'Active' ? true : active.value === 'In-Active' ? false : 'All'
                }
            }
        })
    }

    useEffect(() => {
        setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    return (

        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForCreateOrEditRole}>
                <Grid item container alignItems='center' justifyContent='flex-start' xs={6} sm={8} md={8} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6} sm={4} md={4} lg={6} className={classes.topGrid}>
                    <Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2}
                        endIcon={smallScreen ? null : <IconBack />} onClick={history.goBack}
                    > {smallScreen ? <IconBack /> : <FormattedMessage id="common.button.back.lable" defaultMessage="Back" />} </Button>
                    <Button size='small' disabled={disableRestoreButton}
                        variant="contained" color="secondary" className={classes.stateButtonToRestore} elevation={2}
                        endIcon={smallScreen ? null : <IconRestore />} onClick={(() => { resetAllClearThePageInfo() })}
                    > {smallScreen ? <IconRestore /> : <FormattedMessage id="common.button.reset.lable" defaultMessage="Reset" />} </Button>
                    <Button size='small'
                        variant="contained" color="primary" disabled={isSaveButtonDisabled} className={classes.stateButtonForSave} elevation={2}
                        endIcon={smallScreen ? null : <IconSaveOrUpdate />} onClick={handleSubmit(onSubmitHeader)}
                    > {smallScreen ? <IconSaveOrUpdate /> : saveOrUpdateButtonLabel} </Button>
                </Grid>
                <Paper className={classes.paperForCreateOrEditUser} elevation={3}>
                    <form className={classes.formForCreateOrEditUser} onSubmit={handleSubmit(onSubmitHeader)}>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                            <Grid item container xs={12} sm={6} md={4} lg={4}>
                                <EntityCodeCombo comboProperty={branchComboProperty} control={control} errors={errors} comboValue={branchValue} setComboValue={setBranchValue} />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={2} lg={2}>
                                <ERPNameCombo comboProperty={erpNameComboProperty} control={control} errors={errors} comboValue={erpNameValue} setComboValue={setErpName} />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <InterfaceTypeCombo comboProperty={erpInterfaceComboProperty} control={control} errors={errors} comboValue={intfTypeValue} setComboValue={setIntfTypeValue} />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.intfDesc}
                                        rules={{
                                            required: true,
                                            maxLength: 100,
                                            pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UCSHF
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="intfDesc"
                                                size='small'
                                                label={intl.formatMessage({ id: "columnname.erpInterfaceDescription.label" })}
                                                {...field}
                                                error={errors.intfDesc}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: erpIinterfaceReadOnlyFields.intfDesc,
                                                }}
                                                disabled={(mode === 'view') ? true : false}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.intfDesc}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                {<FormattedMessage id="columnname.erpInterfaceDescription.label" defaultMessage="ERP Interface Description" />}  {<FormattedMessage id="textfield.error.isrequirededmessage" defaultMessage="is required" />}
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.intfDesc}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>  {<FormattedMessage id="columnname.erpInterfaceDescription.label" defaultMessage="ERP Interface Description" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UCSHF}</FormHelperText>
                                        )}

                                        {_.get(`${pageLabelsConstantsAndMessages.name.intfDesc}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.error.erpInterfaceDescriptioncannotexceed" defaultMessage="Erp Interface Description cannot exceed more than 100 chars" />
                                            </FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} style={{ marginTop: '0.1%' }}>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.baseUri}
                                        rules={{
                                            // required: true,
                                            maxLength: 100,
                                            // pattern: commonPatterns.commonTextfeildPattern.patternForBaseURI
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                // required
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="baseUri"
                                                size='small'
                                                label={intl.formatMessage({ id: "columnname.baseURI.label" })}
                                                {...field}
                                                error={errors.baseUri}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: erpIinterfaceReadOnlyFields.baseUri,
                                                }}
                                                disabled={(mode === 'view') ? true : false}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.baseUri}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfiled.error.baseURI" defaultMessage="Base URI is required" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.baseUri}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}> {pageLabelsConstantsAndMessages.label.baseUri} {commonPatterns.commonErrorsForTextFields.errorForBaseURI}</FormHelperText>
                                        )}

                                        {_.get(`${pageLabelsConstantsAndMessages.name.baseUri}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.error.baseURIcannotexceed" defaultMessage="Base URI cannot exceed more than 100 chars" />
                                            </FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.userName}
                                        rules={{
                                            maxLength: 50,
                                            pattern: commonPatterns.commonTextfeildPattern.patternForAlphaNumeric,
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                autoComplete="off"
                                                size='small'
                                                label={<FormattedMessage id="columnname.username.label" defaultMessage="User Name " />}
                                                {...field}
                                                error={errors.userName}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    readOnly: erpIinterfaceReadOnlyFields.userName,
                                                }}
                                                disabled={(mode === 'view') ? true : false}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.userName}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                {<FormattedMessage id="textfield.error.usernameempty" defaultMessage="User Name is required" />}
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.userName}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                {<FormattedMessage id="textfield.error.cannotexceedfiftycharcts" defaultMessage="User Name cannot exceed more than 50 chars" />}
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.userName}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}> {<FormattedMessage id="columnname.username.label" defaultMessage="User Name " />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNumeric}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.password}
                                        rules={(getValues('userName') === '') ? { required: false } : (getValues('userName') !== '') ? { required: true } : ''}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="password"
                                                size='small'
                                                autoComplete="new-password"
                                                label={<FormattedMessage id="textfield.password.label" defaultMessage="Password" />}
                                                type={values.showPassword ? 'text' : 'password'}
                                                {...field}
                                                error={errors.password}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    readOnly: erpIinterfaceReadOnlyFields.password,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                edge="end"
                                                                size="large">
                                                                {values.showPassword ? <Visibility className={classes.iconButton} /> : <VisibilityOff className={classes.iconButton} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                disabled={(mode === 'view') ? true : false}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.password}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>{<FormattedMessage id="texfield.error.password" defaultMessage="Password is required" />}</FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.password}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                {<FormattedMessage id="textfield.error.user.passwordcannotexceed" defaultMessage="Password cannot exceed more than 100 chars" />}
                                            </FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={12} md={3} lg={3}>
                                <Grid container justifyContent='flex-start' alignItems='center'>
                                    <Controller
                                        render={({
                                            field: { onChange } }) => (<Switch
                                                color='primary'
                                                checked={resettingActiveValue.active !== undefined ? resettingActiveValue.active : true}
                                                onChange={((event) => {
                                                    onChange(!(resettingActiveValue.active !== undefined ? resettingActiveValue.active : true));
                                                    setResettingActiveValue({ ...resettingActiveValue, active: event.target.checked })
                                                })}
                                                disabled={mode === 'view' ? true : false}
                                            />)}
                                        type="checkbox"
                                        name={pageLabelsConstantsAndMessages.name.active}
                                        control={control}
                                        defaultValue={interfaceHeaderDataToBeEditedorViewed.active !== undefined ? interfaceHeaderDataToBeEditedorViewed.active : true}
                                    />
                                    <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <Paper elevation={3} className={classes.gridHeightAndWidthForERPDtl} style={{ height: 'auto' }} >
                    <DataGridPro
                        className={classes.customTableStyle}
                        sortingMode="server"
                        rows={tableData}
                        columns={columns}
                        showToolbar
                        pageSize={paramBody.recordsPerPage}
                        loading={tableLoadingStatus}
                        rowCount={totalRowCount}
                        scrollbarSize={30}
                        disableColumnFilter={true}
                        rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                        paginations
                        density="compact"
                        paginationMode="server"
                        disableSelectionOnClick={true}
                        disableMultipleColumnsSorting={true}
                        hideFooter={true}
                        localeText={localtext}
                        components={{
                            Toolbar: () => {
                                return (
                                    <Grid container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}  >
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}>
                                            <Grid>
                                                <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                    <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                </Tooltip>
                                            </Grid>
                                            <Grid>
                                                <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                    placement='bottom'><GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                </Tooltip>
                                            </Grid>
                                            <Grid>
                                                <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                    <span>
                                                        <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                            <Badge color="primary" badgeContent={filterCount}>
                                                                <FilterList />
                                                            </Badge>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </Grid>
                                            <Grid>
                                                <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                    <span>
                                                        <Button size="small" color="primary" variant="outlined"
                                                            className={classes.refreshToobarStyle}
                                                            onClick={(() => {
                                                                setRefreshTable(!refreshTable);
                                                            })}>
                                                            <RefreshIcon />
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </Grid>
                                            <Grid>
                                                <Tooltip title={<FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                    <span>
                                                        {(erpInterfaceHdrModuleAccess !== undefined ? erpInterfaceHdrModuleAccess.createFlag : false) && (mode === 'edit' || mode === 'create') ? (
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                disabled={(mode === 'create') ? true : false}
                                                                onClick={(() => {
                                                                    setModeChanged('create')
                                                                    setOpenErpInterfaceDialog(true)
                                                                    setErpInterfaceDtlForVieworEditMode(erpInterfaceDtlForVieworEditMode)
                                                                })}>
                                                                <CreateIcon />
                                                            </Button>) : null}
                                                    </span>
                                                </Tooltip>

                                                <InterfaceDialogComponent
                                                    open={openErpInterfaceDialogBox}
                                                    onClose={closeErpInterfaceDetail}
                                                    mode={modeChanged}
                                                    resettingValues={erpInterfaceDtlForVieworEditMode}
                                                    title={commonBreadCrumbToBeUsedForDialog}
                                                    intfHdrId={intfHdrId}
                                                    valuesforResetting={erpInterfaceDtlForVieworEditMode}
                                                    valuesTobeEdited={interfaceHeaderDataToBeEditedorViewed}
                                                    dialogHeader={interfaceHeaderDataToBeEditedorViewed}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <CommonGridPagination />
                                        </Grid>
                                    </Grid>
                                );
                            },
                        }}
                        hideFooterSelectedRowCount={true}
                        onPageSizeChange={((pageParams) => {
                            setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                        })}
                        onPageChange={((pageParams) => {
                            setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                        })}
                        onSortModelChange={(params) => {
                            let sortModel = params[0];
                            if (sortModel !== undefined) {
                                setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                            }
                        }}
                        sortingOrder={['desc', 'asc']}
                        filterMode="server"
                    />
                </Paper>
            </Grid>
            {/* </Grid> */}
        </React.Fragment >

    );
}

export default CreateOrEditErpinterface;