import {
    Badge,
    Button, CssBaseline, Grid, IconButton, Paper,

    Snackbar, Tooltip, useMediaQuery, useTheme
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    LicenseInfo, DataGridPro, getGridStringOperators, GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    getGridNumericColumnOperators,
} from '@mui/x-data-grid-pro';
import { CommonService } from '../../services';
import ChipInfoList from '../common/ChipInfoList';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import Alert from '@mui/lab/Alert';
import { useHistory } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CustomFilters } from '../common/CustomFilters/CustomFilters';
import ViewIcon from '@mui/icons-material/Visibility';
import useStyles from '../common/CommonStyle/CommonStyle';
import { FormattedMessage, useIntl } from 'react-intl';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import { SupportedLanguageDetails } from '../common/SupportedLanguageDetails/SupportedLanguageDetails';
import CommonGridPagination from '../common/CommonGridPagination/CommonGridPagination';
import { ModuleAccessPermissionKey } from '../../Constants/ModuleAccessKey';
import IconOrder from '@mui/icons-material/Assignment';
import IconMessage from '@mui/icons-material/Forum';
import CustomBreadCrumb from '../common/CustomBreadCrumb';
import IconApproval from '@mui/icons-material/LibraryAddCheck';
import CancelIcon from '@mui/icons-material/Cancel';
import saveAs from 'file-saver';
import GetAppIcon from '@mui/icons-material/GetApp';
import CommonBackDrop from '../common/BackDrop';
import { FilterList } from '@mui/icons-material';
import CustomFilterComponent, { GetFilterableColumns } from '../common/CustomFilterComponent';
import { manageUserPreferences } from '../../services/manageUserPreferences';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';

const columnFieldsName = {
    msgId: 'msgId',
    msgNum: 'msgNum',
    bpCode: 'bpCode',
    testInd: 'testInd',
    buyerPartyCode: 'buyerPartyCode',
    buyerName: 'buyerName',
    buyerAddress: "buyerAddress",
    buyerCity: "buyerCity",
    buyerState: "buyerState",
    buyerPostalCode: "buyerPostalCode",
    buyerCountry: "buyerCountry",
    buyerTelNo: "buyerTelNo",
    buyerFaxNo: "buyerFaxNo",
    buyerEmail: "buyerEmail",
    supplierPartyCode: 'supplierPartyCode',
    supplierName: 'supplierName',
    supplierAddress: "supplierAddress",
    supplierCity: "supplierCity",
    supplierState: "supplierState",
    supplierCountry: "supplierCountry",
    supplierPostalCode: "supplierPostalCode",
    supplierTelNo: "supplierTelNo",
    supplierFaxNo: "supplierFaxNo",
    supplierEmail: "supplierEmail",
    freightPartyCode: 'freightPartyCode',
    freightName: 'freightName',
    freightAddress: 'freightAddress',
    freightCity: 'freightCity',
    freightState: 'freightState',
    freightCountry: 'freightCountry',
    freightPostalCode: 'freightPostalCode',
    freightTelNo: 'freightTelNo',
    freightFaxNo: 'freightFaxNo',
    freightEmail: 'freightEmail',
    invoiceePartyCode: 'invoiceePartyCode',
    invoiceeName: 'invoiceeName',
    invoiceeAddress: 'invoiceeAddress',
    invoiceeCity: 'invoiceeCity',
    invoiceeState: 'invoiceeState',
    invoiceeCountry: 'invoiceeCountry',
    invoiceePostalCode: 'invoiceePostalCode',
    invoiceeTelNo: 'invoiceeTelNo',
    invoiceeFaxno: 'invoiceeFaxno',
    invoiceeEmail: 'invoiceeEmail',
    currencyCode: 'currencyCode',
    supplierVatNumber: 'supplierVatNumber',
    approved: 'approved',
    approvedBy: 'approvedBy',
    approvedDate: 'approvedDate',
    apprEmailSent: 'apprEmailSent',
    documentType: 'documentType',
    messageFunctionCode: 'messageFunctionCode',
    messageReceivedTime: 'messageReceivedTime',
    deliveryRemarks: 'deliveryRemarks',
    msgStatusFlag: 'msgStatusFlag',
    msgStatusRemarks: 'msgStatusRemarks',
    note: 'note',
    interfacedTime: 'interfacedTime',
    msgRcvMode: 'msgRcvMode',
    createdBy: 'createdBy',
    createdTime: 'createdTime',
    updatedBy: 'updatedBy',
    updatedTime: 'updatedTime',
    erpOrgCode: 'organization.erpOrgCode',
    erpEntityCode: 'entity.erpEntityCode',
    orgName: 'organization.orgName',
    entityName: 'entity.entityName',
    attribute1: 'attribute1',
    attribute2: 'attribute2',
    attribute3: 'attribute3',
    attribute4: 'attribute4',
    attribute5: 'attribute5',
    attribute6: 'attribute6',
    attribute7: 'attribute7',
    attribute8: 'attribute8',
    attribute9: 'attribute9',
    attribute10: 'attribute10',
    messageReceivedFromDate: 'messageReceivedFromDate',
    messageReceivedToDate: 'messageReceivedToDate',
};

function Order(props) {

    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            readButton: 'View',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            exportButton: 'Export',
            exportPOCfmdDtTooltip: 'Export PO Confirmation Date Report',
            importButton: 'Import',
            auditButtonForPO: 'Confirmation Audit History',
            auditButtonForPF: 'Purchase Forecast Audit History',
            exportCFMAuditHistory: 'Exp Cfm ADT History',
            exportPFAuditHistory: 'Exp PFT ADT History',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density',
            exportButtonTootip: 'Export Upload Dlv.Schedule',
            exportPo: `Export PO Report`,
            exportPr: 'Export PR Report',
            exportOsCfmDate: 'Export Dlv.Schedule SAP',
            importButtonTooltip: 'Import Dlv.Schedule',
            exportPR: 'PR REPORT',
            exportPO: 'PO REPORT',
            exportDlvCfmQntyReport: 'UPLOAD DLV. SCHEDULE',
            exportOsCfmDateReport: 'Export Dlv.Schedule SAP',
            exportPftAdtHistory: 'FORECAST DTL HISTORY',
            exportCfmAdtHistory: 'DLV. SCHEDULE HISTORY',

        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    };
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [selectedRow, setSelectedRow] = React.useState([]);
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const poModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.inboundPurchaseOrders];
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [searchedEntityCode, setSearchedEntityCode] = useState('');
    const [searchedStatusFlag, setSearchedStatusFlag] = useState('');
    const [searchedApprovedRecordsRecords, setSearchedApprovedRecordsRecords] = useState(null)
    const [searchedBpCode, setSearchedBpCode] = useState('');
    const [searchedSupplierName, setSearchedSupplierName] = useState('');
    const [searchedMsgRcvMode, setSearchedMsgRcvMode] = useState('');
    const [searchedMsgRcvFromDate, setSearchedMsgRcvFromDate] = useState('');
    const [searchedMsgRcvToDate, setSearchedMsgRcvToDate] = useState('');
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [chipInfoList, setChipInfoList] = useState([]);
    const [msgHdrPopUp, setMsgHdrPopUp] = useState(false);
    const [msgDtlsPopUp, setMsgDtlsPopUp] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [isRowSentForApproval, setIsRowSentForApproval] = useState(false);
    const [disableApproveButton, isDisableApproveButton] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [keyValueData, setKeyValueData] = useState({});
    const [msgIdValue, setMsgIdValue] = useState({});
    const [disiablingDownloadButtonForZeroRecords, setDisiablingDownloadButtonForZeroRecords] = useState(false)
    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleCloseForFilter = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    var defaultEntity = loggedInUserInfo.defaultEntity;
    const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.erpEntityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity }]
        : [];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);

    const [paramBody, setParamBody] = useState(
        {
            body: {
                approved: '',
                recordStatusFlag: 'All',
                recordApprovedStatus: 'All',
                entity: {
                    erpEntityCode: '',
                    entityName: ''
                }
            },
            pageNo: 0,
            sortDir: 'desc',
            sortKey: 'updatedTime',
            recordsPerPage: 25,
        }
    );

    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.message" />,
            iconName: IconMessage,
        },
        {
            path: '/purchaseOrders/ForecastInbound',
            name: <FormattedMessage id="common.menu.module.breadcrumb.purchaseorder" defaultMessage="Purchase Orders/Forecast Inbound" />,
            iconName: IconOrder,
        }
    ];
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState({
        left: ["__check__"],
        right: []
    });
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }


    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }
    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);


    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);

    const commonColumnForPO =
        [
            {
                field: 'action',
                headerName: intl.formatMessage({ id: "columnname.action.label" }),
                width: 100,
                sortable: false,
                hide: false,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (
                    <div>
                        {(poModuleAccess !== undefined ? poModuleAccess.readFlag : false)
                            &&
                            (<Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.readButton} />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    let msgId = cellParams.row.msgId;
                                    history.push(`/purchaseOrders/ForecastInbound/${msgId}`, { mode: 'view', msgId: msgId });
                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar} size="large">
                                        <ViewIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                        {(poModuleAccess !== undefined ? poModuleAccess.createFlag || poModuleAccess.editFlag : false)
                            &&
                            (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadExcelDtls" defaultMessage="downloadExcel" />}
                                placement='bottom' className={classes.actionButton} onClick={() => {
                                    setMsgDtlsPopUp(true)
                                    setMsgIdValue(cellParams.row.msgId)
                                }}>
                                <span>
                                    <IconButton className={classes.customeToolbar} size="large">
                                        <GetAppIcon size={20} />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                    </div>
                )
            },
            {
                field: columnFieldsName.erpEntityCode,
                headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 85,
                sortable: false,
                type: 'string',
                hide: false,
                filterable: true,
                valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.msgNum,
                width: 190,
                headerName: intl.formatMessage({ id: "columnname.messageNumber.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.bpCode,
                width: 130,
                headerName: intl.formatMessage({ id: "common.textfield.columname.bpcode" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.supplierName,
                width: 300,
                headerName: intl.formatMessage({ id: "columnName.bpname.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.buyerPartyCode,
                width: 200,
                headerName: intl.formatMessage({ id: "columnname.buyerPartyCode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.buyerName,
                width: 150,
                headerName: intl.formatMessage({ id: "columnname.buyersName.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: false,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.approved,
                width: 150,
                headerName: intl.formatMessage({ id: "columnName.approved.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.ApproveFailureChipsForPO
            },
            {
                field: columnFieldsName.approvedBy,
                width: 170,
                headerName: intl.formatMessage({ id: "columnName.approvedBy.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.approvedDate,
                width: 165,
                headerName: intl.formatMessage({ id: "columnName.approvedDate.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (
                    <span>{cellParams.row.approvedDtDisp}</span>
                )
            },
            {
                field: columnFieldsName.apprEmailSent,
                width: 170,
                headerName: intl.formatMessage({ id: "columnName.emailSent.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.customRenderStatusColumnForApprEmailSent
            },
            {
                field: columnFieldsName.msgStatusFlag,
                width: 150,
                headerName: intl.formatMessage({ id: "columnname.Msgstatus.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.PoStatusChips
            },
            {
                field: columnFieldsName.msgRcvMode,
                width: 160,
                headerName: intl.formatMessage({ id: "columnname.msgRcvMode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: ((cellParams) => {
                    if (cellParams.row.msgRcvMode === 'AS2') {
                        return 'AS2'
                    } else if (cellParams.row.msgRcvMode === 'VAN') {
                        return 'VAN'
                    }
                })
            },
            {
                field: columnFieldsName.messageReceivedTime,
                width: 160,
                headerName: intl.formatMessage({ id: "columnname.messageReceivedTime.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                sortable: true,
                type: 'dateTime',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.messageReceivedTimeDisp}</span>)
            },
            {
                field: columnFieldsName.currencyCode,
                width: 150,
                headerName: intl.formatMessage({ id: "columnname.curCd.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: false,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.documentType,
                width: 170,
                headerName: intl.formatMessage({ id: "columnName.documentType.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.messageFunctionCode,
                width: 240,
                headerName: intl.formatMessage({ id: "columnName.messageFunctionCode.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.supplierVatNumber,
                width: 220,
                headerName: intl.formatMessage({ id: "columnname.supplierVatNumber.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: false,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.msgStatusRemarks,
                width: 170,
                headerName: intl.formatMessage({ id: "columnName.msgSentStatusRemarks.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: false,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.deliveryRemarks,
                width: 170,
                headerName: intl.formatMessage({ id: "dlvremarks.accordian.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: false,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.note,
                headerName: intl.formatMessage({ id: "columnname.note.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 140,
                sortable: false,
                type: 'string',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.updatedBy,
                headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 155,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.updatedTime,
                headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.updatedTimeDisp}</span>)
            },
            {
                field: columnFieldsName.createdBy,
                headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 155,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.createdTime,
                headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.createdTimeDisp}</span>)
            },
            {
                field: columnFieldsName.attribute1,
                headerName: intl.formatMessage({ id: "columnname.attribute1.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute2,
                headerName: intl.formatMessage({ id: "columnname.attribute2.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute3,
                headerName: intl.formatMessage({ id: "columnname.attribute3.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute4,
                headerName: intl.formatMessage({ id: "columnname.attribute4.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute5,
                headerName: intl.formatMessage({ id: "columnname.attribute5.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute6,
                headerName: intl.formatMessage({ id: "columnname.attribute6.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute7,
                headerName: intl.formatMessage({ id: "columnname.attribute7.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute8,
                headerName: intl.formatMessage({ id: "columnname.attribute8.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute9,
                headerName: intl.formatMessage({ id: "columnname.attribute9.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute10,
                headerName: intl.formatMessage({ id: "columnname.attribute10.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.messageReceivedFromDate,
                headerName: intl.formatMessage({ id: "columnname.messageReceivedFromDate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 0,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.messageReceivedToDate,
                headerName: intl.formatMessage({ id: "columnname.messageReceivedToDate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 0,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
        ];

    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(commonColumnForPO);
                savePreference("visibility", commonColumnForPO);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, commonColumnForPO);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let entityCode = null;
                let bpCode = null;
                let supplierName = null;
                let approved = null;
                let msgStatusFlag = null;
                let msgRcvMode = null;
                let messageReceivedFromDate = null;
                let messageReceivedToDate = null;
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'supplierName':
                            supplierName = columns.value
                            break;
                        case 'msgRcvMode':
                            msgRcvMode = columns.value
                            break;
                        case 'entity.erpEntityCode':
                            entityCode = columns.value
                            break;
                        case 'msgStatusFlag':
                            msgStatusFlag = columns.value
                            break;
                        case 'bpCode':
                            bpCode = columns.value
                            break;
                        case 'approved':
                            approved = columns.value
                            break;
                        case 'messageReceivedFromDate':
                            messageReceivedFromDate = columns.value
                            break;
                        case 'messageReceivedToDate':
                            messageReceivedToDate = columns.value
                            break;
                    }
                })
                var filterBody = {
                    entity: {
                        erpEntityCode: entityCode,
                    },
                    bpCode: bpCode,
                    supplierName: supplierName,
                    approved: approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : '',
                    msgStatusFlag: msgStatusFlag === 'New Msg' ? 0 : msgStatusFlag === 'Pending Msg' ? 1 : msgStatusFlag === 'Success Msg' ? 2 : msgStatusFlag === 'Error Msg' ? 3 : msgStatusFlag === 'Upload Err Msg' ? 4 : '',
                    recordStatusFlag: msgStatusFlag === '' ? 'All' : msgStatusFlag,
                    recordApprovedStatus: approved === '' ? 'All' : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : '',
                    msgRcvMode: msgRcvMode,
                    messageReceivedFromDate: messageReceivedFromDate !== null ? messageReceivedFromDate : null,
                    messageReceivedToDate: messageReceivedToDate !== null ? messageReceivedToDate : null
                };
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0)
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [selectedLanguage]);

    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi('/poInboundMsgHdrs', paramBody)
                .then((response) => {
                    setTotalRowCount(response.data.totalElements);
                    setTableLoadingStatus(false);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [paramBody, refreshTable]);


    useEffect(() => {
        if (totalRowCount === 0) {
            setDisiablingDownloadButtonForZeroRecords(true)
        } else {
            setDisiablingDownloadButtonForZeroRecords(false)
        }
    }, [totalRowCount])

    if (columns.length > 0) {
        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };

        const approvedColumn = columns.find((column) => column.field === columnFieldsName.approved);
        const approvedColIndex = columns.findIndex((col) => col.field === columnFieldsName.approved);

        const approvedFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.ApprovedPOInputValue,
            }),
        );

        columns[approvedColIndex] = {
            ...approvedColumn,
            filterOperators: approvedFilterOperators,
        };

        const statusFlagColumn = columns.find((column) => column.field === columnFieldsName.msgStatusFlag);
        const statusFlagColIndex = columns.findIndex((col) => col.field === columnFieldsName.msgStatusFlag);

        const statusFlagFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.PoInboundInputValue,
            }),
        );

        columns[statusFlagColIndex] = {
            ...statusFlagColumn,
            filterOperators: statusFlagFilterOperators,
        };

        const commTypeColumn = columns.find((column) => column.field === columnFieldsName.msgRcvMode);
        const commTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.msgRcvMode);

        const commTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.MessageRcvInputValue,
            }),
        );

        columns[commTypeColIndex] = {
            ...commTypeColumn,
            filterOperators: commTypeFilterOperators,
        };

        const msgRcvfromDtColumn = columns.find((column) => column.field === columnFieldsName.messageReceivedFromDate);
        const msgRcvfromDtColIndex = columns.findIndex((col) => col.field === columnFieldsName.messageReceivedFromDate);

        const msgRcvfromDtFilterOperators = getGridNumericColumnOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[msgRcvfromDtColIndex] = {
            ...msgRcvfromDtColumn,
            filterOperators: msgRcvfromDtFilterOperators,
        };

        const msgRcvToDtColumn = columns.find((column) => column.field === columnFieldsName.messageReceivedToDate);
        const msgRcvToDtColIndex = columns.findIndex((col) => col.field === columnFieldsName.messageReceivedToDate);

        const msgRcvToDtFilterOperators = getGridNumericColumnOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[msgRcvToDtColIndex] = {
            ...msgRcvToDtColumn,
            filterOperators: msgRcvToDtFilterOperators,
        };

    }

    function currentlySelectedRow(selections) {
        setSelectedRow(selections)
        if (selections.length === 0) {
            isDisableApproveButton(true)
        } else {
            isDisableApproveButton(false)
        }
    }

    const onSubmit = () => {
        setIsFormSubmitted(true);
        setIsRowSentForApproval(true)
        CommonService.saveOrUpdateOrPost('/poApprovals', selectedRow)
            .then((response) => {
                setIsFormSubmitted(false)
                setIsRowSentForApproval(false)
                setAlertBoxState({
                    ...alertBoxState, openAlert: true, message: `MessageId : ${selectedRow} has been approved successfully`, severity: "success"
                });
                setSelectedRow([])
                setRefreshTable(!refreshTable);
                isDisableApproveButton(true)
            })
            .catch(function (error) {
                setIsFormSubmitted(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                isDisableApproveButton(false)
            });
    }

    const onSubmitReject = (data) => {
        setIsFormSubmitted(true);
        setIsRowSentForApproval(true)
        CommonService.saveOrUpdateOrPost('/poRejections', selectedRow)
            .then((response) => {
                setIsFormSubmitted(false)
                setAlertBoxState({
                    ...alertBoxState, openAlert: true, message: `MessageId : ${selectedRow} has been rejected successfully`, severity: "success"
                });
                setSelectedRow([])
                setRefreshTable(!refreshTable);
                isDisableApproveButton(true)
            })
            .catch(function (error) {
                setIsFormSubmitted(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                isDisableApproveButton(false)
            });
    }

    let filterBodyObj = {
        erpEntityCode: searchedEntityCode,
        bpCode: searchedBpCode,
        supplierName: searchedSupplierName,
        approved: searchedApprovedRecordsRecords,
        msgStatusFlag: searchedStatusFlag,
        msgRcvMode: searchedMsgRcvMode,
        messageReceivedFromDate: searchedMsgRcvFromDate !== null ? searchedMsgRcvFromDate.substr(1, 10) : null,
        messageReceivedToDate: searchedMsgRcvToDate !== null ? searchedMsgRcvToDate.substr(1, 10) : null
    }
    useEffect(() => {
        let colValDataObj = {}
        for (let key in filterBodyObj) {
            let value = filterBodyObj[key]
            if (filterBodyObj.hasOwnProperty(key) && ((value !== null) && (value !== "") && (value !== 0) && (value !== undefined) && (value !== {}))) {
                colValDataObj[key] = value.toString();
            }
            setKeyValueData(colValDataObj)
        }
    }, [searchedEntityCode, searchedBpCode, searchedSupplierName, searchedApprovedRecordsRecords, searchedStatusFlag, searchedMsgRcvMode, searchedMsgRcvFromDate, searchedMsgRcvToDate]);

    useEffect(() => {
        if (msgHdrPopUp === true) {
            setOpenBackDrop(true);
            CommonService.saveOrUpdateOrPost('/reports/SYS_PO_HDR', searchedBpCode === '' || searchedEntityCode === '' ?
                { erpEntityCode: '', bpCode: '' } : keyValueData)
                .then((response) => {
                    showDownloadPopUpExcelHdr(response.data.fileUrl)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />, severity: "success"
                    });
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgHdrPopUp(false)
        }
    }, [msgHdrPopUp, keyValueData]);

    const showDownloadPopUpExcelHdr = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            })
    }


    useEffect(() => {
        if (msgDtlsPopUp === true) {
            setOpenBackDrop(true);
            let msgId = { msgId: msgIdValue.toString() };
            CommonService.saveOrUpdateOrPost('/reports/SYS_PO_DTL', msgId)
                .then((response) => {
                    showDownloadPopUpExcelDtls(response.data.fileUrl)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />, severity: "success"
                    });
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgDtlsPopUp(false)
        }
    }, [msgIdValue, msgDtlsPopUp])

    const showDownloadPopUpExcelDtls = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            })
    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let entityCode = null;
        let bpCode = null;
        let supplierName = null;
        let approved = null;
        let msgStatusFlag = null;
        let msgRcvMode = null;
        let messageReceivedFromDate = null;
        let messageReceivedToDate = null;
        selectedFilters.map((columns) => {

            switch (columns.columnField) {
                case 'supplierName':
                    supplierName = columns.value
                    break;
                case 'msgRcvMode':
                    msgRcvMode = columns.value
                    break;
                case 'entity.erpEntityCode':
                    entityCode = columns.value
                    break;
                case 'msgStatusFlag':
                    msgStatusFlag = columns.value
                    break;
                case 'bpCode':
                    bpCode = columns.value
                    break;
                case 'approved':
                    approved = columns.value
                    break;
                case 'messageReceivedFromDate':
                    messageReceivedFromDate = columns.value
                    break;
                case 'messageReceivedToDate':
                    messageReceivedToDate = columns.value
                    break;
            }
        })

        setSearchedEntityCode(entityCode);
        setSearchedBpCode(bpCode);
        setSearchedSupplierName(supplierName);
        setSearchedApprovedRecordsRecords(approved);
        setSearchedStatusFlag(msgStatusFlag);
        setSearchedMsgRcvMode(msgRcvMode);
        setSearchedMsgRcvFromDate(messageReceivedFromDate);
        setSearchedMsgRcvToDate(messageReceivedToDate);

        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);
        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    entity: {
                        erpEntityCode: entityCode,
                    },
                    bpCode: bpCode,
                    supplierName: supplierName,
                    approved: approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : '',
                    msgStatusFlag: msgStatusFlag === 'New Msg' ? 0 : msgStatusFlag === 'Pending Msg' ? 1 : msgStatusFlag === 'Success Msg' ? 2 : msgStatusFlag === 'Error Msg' ? 3 : msgStatusFlag === 'Upload Err Msg' ? 4 : '',
                    recordStatusFlag: msgStatusFlag === '' ? 'All' : msgStatusFlag,
                    recordApprovedStatus: approved === '' ? 'All' : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : '',
                    msgRcvMode: msgRcvMode,
                    messageReceivedFromDate: messageReceivedFromDate !== null ? messageReceivedFromDate : null,
                    messageReceivedToDate: messageReceivedToDate !== null ? messageReceivedToDate : null
                }
            }
        })
    }

    useEffect(() => {
        if (intialParamBodyApiCall)
            setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CustomFilterComponent open={openFilterPanel} onClose={handleCloseForFilter} filterableColumns={filterableColumns}
                setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                defaulSelectedFilters={defaulSelectedFilters}/>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.topGrid} style={{ marginLeft: '-0.5%' }}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <ChipInfoList chipInfoList={chipInfoList} />
                <Paper elevation={3} className={classes.gridHeightAndWidthForRole}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            getRowId={(row) => row.id}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            density="compact"
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            isRowSelectable={(params) => params.row.approved === 0 ? true : false}
                            hideFooter={true}
                            checkboxSelection={true}
                            onSelectionModelChange={currentlySelectedRow}
                            selectionModel={selectedRow}
                            localeText={localtext}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'><GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterList />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                    setSelectedRow([])
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    {(poModuleAccess !== undefined ? poModuleAccess.createFlag || poModuleAccess.editFlag : false) && (<Tooltip title={<FormattedMessage id="common.button.approveselectedrecords.tooltip" defaultMessage="Approve selected records" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={onSubmit}
                                                                disabled={disableApproveButton || isFormSubmitted}
                                                            >
                                                                <IconApproval size={20} />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>)}
                                                </Grid>
                                                <Grid>
                                                    {(poModuleAccess !== undefined ? poModuleAccess.createFlag || poModuleAccess.editFlag : false) && (
                                                        <Tooltip title={<FormattedMessage id="common.button.approveselectedrecords.tooltip" defaultMessage="Reject selected records" />} placement='bottom'>
                                                            <span>
                                                                <Button size="small" color="primary" variant="outlined"
                                                                    className={classes.refreshToobarStyle}
                                                                    onClick={onSubmitReject}
                                                                    disabled={disableApproveButton || isFormSubmitted}
                                                                >
                                                                    <CancelIcon size={20} />
                                                                </Button>
                                                            </span>
                                                        </Tooltip>)}
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadExcelForMsg" />}
                                                        placement='bottom'>
                                                        <span>
                                                            {(poModuleAccess !== undefined ? poModuleAccess.createFlag || poModuleAccess.editFlag : false) && (
                                                                <Button size="small" color="primary" variant="outlined" className={classes.refreshToobarStyle}
                                                                    onClick={() => {
                                                                        setMsgHdrPopUp(true)
                                                                    }}
                                                                    disabled={disiablingDownloadButtonForZeroRecords}
                                                                >
                                                                    <GetAppIcon size={20} />
                                                                </Button>
                                                            )}
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            hideFooterSelectedRowCount={true}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length - 1, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length - 1, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length - 1, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                --params.oldIndex;
                                --params.targetIndex;
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </div>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}

export default Order;

