import { CssBaseline, FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React  from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import { CommonService } from '../../../../services';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '0 0.5%',
    },
    error: {
        color: theme.palette.error.main,
    }
}));

const PoOutboundStatusCombo = (props) => {

    const classes = useStyles();
    const intl = useIntl();
    const { comboProperty, control, errors, comboValue, setComboValue } = props;
    const statusOption = [
        {id: 0, value: 0, name: 'Not Sent' },
        {id: 1, value: 1, name: 'Pending' },
        {id: 2, value: 2, name: 'Successfully Sent'},
        {id: 3, value: 3, name: 'Failed to Send'},
        {id: 4, value: 4, name: 'Cancelled'},
    ];

    let dlvCfmStatusPlaceholder = intl.formatMessage({id: "seachbydeliverycfmstatus.placeholder"});
    let dlvCfmStatus = intl.formatMessage({id: "columnName.msgSentStatus.label"})

    return (
        <React.Fragment>
            <CssBaseline />
            { comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth>
                    <Controller
                        render={({
                            field: { onChange } }) => (
                            <Autocomplete

                                onChange={(event, newValue) => {
                                    onChange(newValue)

                                    if (typeof newValue === 'string') {

                                        if (newValue !== null) {
                                            setComboValue({
                                                newValue
                                            });
                                        }
                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setComboValue({
                                            title: newValue.inputValue,
                                        });

                                    }
                                    else if (newValue !== null) {

                                        setComboValue(newValue);
                                    } else if (newValue === null) {
                                        setComboValue(null)
                                    } else {
                                        setComboValue(newValue);
                                    }
                                }}
                            selectOnFocus
                            loadingText="Loading..."
                            handleHomeEndKeys
                            value={comboValue.name || null}
                            autoHighlight
                            options={statusOption}
                            getOptionLabel={(option) => {

                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.name;
                            }}
                            getoptionselected={(option, value) => option.name === value}
                            disabled={comboProperty.isDisabled}
                            renderOption={(props, option) => {
                                return <li {...props}>{
                                <React.Fragment>
                                        { option.value === 0 && (<CommonService.PoStatusForOutChips  value={0} />)}
                                        { option.value === 1 && (<CommonService.PoStatusForOutChips  value={1} />)}
                                        { option.value === 2 && (<CommonService.PoStatusForOutChips value={2} />)}
                                        { option.value === 3 && (<CommonService.PoStatusForOutChips  value={3} />)}
                                    </React.Fragment>}
                                </li>;
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    autoFocus={comboProperty.isAutofocus}
                                    name={comboProperty.name}
                                    size='small'
                                    placeholder={`${dlvCfmStatusPlaceholder}` + `${dlvCfmStatus}`}
                                    label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                    variant={comboProperty.variant}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    )}

                    name={comboProperty.name}
                    rules={{ required: comboProperty.isRequired }}
                    control={control}
                    defaultValue={{ id: 0, value: '' }}
                />
                {comboProperty.isDisabled && (<FormHelperText>{comboProperty.label} can't be edited</FormHelperText>)}
                <div className={classes.error}>
                    {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                        <FormHelperText className={classes.error}>{comboProperty.label} is required</FormHelperText>
                    )}
                </div>
            </FormControl>
        </Grid>)}
        { (!comboProperty.isHookFormType) && (<Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: "3px"}}>
        <FormControl fullWidth>
            <Autocomplete
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {

                                if (newValue != null) {
                                    setComboValue({
                                        newValue,
                                    });
                                }
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setComboValue({
                                    title: newValue.inputValue,
                                });
                            }
                            else if (newValue !== null) {
                                setComboValue(newValue);
                            } else if (newValue === null) {
                                setComboValue('')
                            } else {
                                setComboValue(newValue);
                            }
                        }}
                        selectOnFocus
                        handleHomeEndKeys
                        value={comboValue.name || null}
                        autoHighlight
                        options={statusOption}
                        loadingText="Loading..."
                        getOptionLabel={(option) => {

                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.name;
                        }}
                        getoptionselected={(option, value) => option.name === value}
                        disabled={comboProperty.isDisabled}
                        renderOption={(props, option) => {
                            return <li {...props}>{
                            <React.Fragment>
                                    { option.value === 0 && (<CommonService.PoStatusForOutChips  value={0} />)}
                                    { option.value === 1 && (<CommonService.PoStatusForOutChips  value={1} />)}
                                    { option.value === 2 && (<CommonService.PoStatusForOutChips value={2} />)}
                                    { option.value === 3 && (<CommonService.PoStatusForOutChips  value={3} />)}
                                    { option.value === 4 && (<CommonService.PoStatusForOutChips  value={4} />)}
                                </React.Fragment>}
                            </li>;
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                autoFocus={comboProperty.isAutofocus}
                                name={comboProperty.name}
                                size='small'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                variant={comboProperty.variant}
                            />
                        )}

                    />
        </FormControl>
        </Grid>)}
        </React.Fragment>
    );
};

PoOutboundStatusCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default PoOutboundStatusCombo;

