import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import CustomTheme from './components/common/CustomTheme';
import LoggedInUserProvider from './components/common/LoggedInUserDetails';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import LanguageDetailsProvider from './components/common/SupportedLanguageDetails/SupportedLanguageDetails';

ReactDOM.render(
  <LanguageDetailsProvider>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={CustomTheme}>
        <LoggedInUserProvider>
          <App />
        </LoggedInUserProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </LanguageDetailsProvider>,
  document.getElementById('root')
);

reportWebVitals();

