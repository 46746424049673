import {CommonService} from './CommonServices.service';
import { GlobalEdiApiConstants } from '../Constants/GlobalEdiApiConstants';


const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();

function saveLastOpenedScreen(screenName){
    const preferencesParams=apiUrlAndParamBodyConstants.paramBodyForGetListApis.tablePreference.body;
    preferencesParams.userId=CommonService.getUserIdLoggedInUser();
    const org= CommonService.getOrganizationOfLoggedInUser();
    const entity=CommonService.getEntityOfLoggedInUser();
    preferencesParams.organization.orgName=org.orgName;
    preferencesParams.organization.erpOrgCode=org.erpOrgCode;
    preferencesParams.entity.erpEntityCode=entity.erpEntityCode;
    preferencesParams.entity.entityName=entity.entityName;
    preferencesParams.screenName=screenName;
   return  CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.lastLoggedInScreen,preferencesParams)
}

function loadLastOpenScreen(){
    const userPreferencesParamBody=apiUrlAndParamBodyConstants.paramBodyForGetListApis.tablePreference;
    userPreferencesParamBody.body.userId=CommonService.getUserIdLoggedInUser();
    const org= CommonService.getOrganizationOfLoggedInUser();
    const entity=CommonService.getEntityOfLoggedInUser();
    userPreferencesParamBody.body.organization.orgName=org.orgName;
    userPreferencesParamBody.body.organization.erpOrgCode=org.erpOrgCode;
    userPreferencesParamBody.body.entity.erpEntityCode=entity.erpEntityCode;
    userPreferencesParamBody.body.entity.entityName=entity.entityName;
    return  CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.lastLoggedInScreen,userPreferencesParamBody)
}


function saveGridPreferences(preferencesParams){
    preferencesParams.userId=CommonService.getUserIdLoggedInUser();
    const org= CommonService.getOrganizationOfLoggedInUser();
    const entity=CommonService.getEntityOfLoggedInUser();
    preferencesParams.organization.orgName=org.orgName;
    preferencesParams.organization.erpOrgCode=org.erpOrgCode;
    preferencesParams.entity.erpEntityCode=entity.erpEntityCode;
    preferencesParams.entity.entityName=entity.entityName;
    preferencesParams.createdBy= CommonService.getLoggedInUserInfo().userName;
    preferencesParams.updatedBy= CommonService.getLoggedInUserInfo().userName;
    if(preferencesParams.createdBy!==undefined)
    return CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.userPreferences,preferencesParams);
}

function loadUserPreferences(preferencesParams){
    preferencesParams.body.userId=CommonService.getUserIdLoggedInUser();
    const org= CommonService.getOrganizationOfLoggedInUser();
    const entity=CommonService.getEntityOfLoggedInUser();
    preferencesParams.body.organization.orgName=org.orgName;
    preferencesParams.body.organization.erpOrgCode=org.erpOrgCode;
    preferencesParams.body.entity.erpEntityCode=entity.erpEntityCode;
    preferencesParams.body.entity.entityName=entity.entityName;
   return  CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.userPreferences,preferencesParams)
}


export const manageUserPreferences = { 
    saveGridPreferences,
    loadLastOpenScreen,
    saveLastOpenedScreen,
    loadUserPreferences
    
}