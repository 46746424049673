
import React, { useEffect, useState } from 'react';
import { CssBaseline, FormControl, Grid, InputLabel, Switch, TextField, useMediaQuery, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Controller, } from 'react-hook-form';
import useStyles from '../../common/CommonStyle/CommonStyle';
import OrgCodeCombo from '../../common/Combos/OrgCodeCombo';
import { useIntl } from 'react-intl';
import EntityCodeCombo from '../../common/Combos/EntityCodeCombo';

const BasicDetails = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const { mode, errors, control, basicDetail,branchValue, setBranchValue } = props;
    const [userToBeEdited, setUserToBeEdited] = React.useState({});
    const [basicDetailsValue, setBasicDetailsValue] = useState('');

    const pageLabelsConstantsAndMessages = {
        name: {
            bpCode: "bpCode",
            entity: 'entity',
            bpSupplierName: "bpSupplierName",
            bpGroupName: "bpGroupName",
            supplierAccountGroup: "supplierAccountGroup",
            language: "language",
            currency: "currency",
            active: "active",
            organization: "organization",
            acceptPriorDelivery: "acceptPriorDelivery",
            earlierAcceptanceDays: "earlierAcceptanceDays",
            lateAcceptanceDays: "lateAcceptanceDays",
            entityUOM: 'entityUOM',
            supplierUOM: 'supplierUOM'
        },
        label: {
            active: "active",
        }
    }
    const [supplierreadOnlyFields, setSupplierReadOnlyFields] = useState({
        bpCode: false,
        bpSupplierName: false,
        bpGroupName: false,
        supplierAccountGroup: false,
        language: false,
        currency: false,
        active: false,
        organization: false,
        entity: false,

    })
    useEffect(() => {
        setUserToBeEdited(basicDetail);
    }, [basicDetail]);

    useEffect(() => {
        if (mode === 'read') {
            setSupplierReadOnlyFields({
                ...supplierreadOnlyFields, bpCode: true, bpSupplierName: true, bpGroupName: true,
                supplierAccountGroup: true, language: true, currency: true, organization: true,
            })
            setBasicDetailsValue(basicDetail.organization !== undefined ? { valueToBeDisplayed: basicDetail.organization } : []);
            setBranchValue(basicDetail.entity !== undefined ? { valuesToBeDisplayed: basicDetail.entity } : []);
        } 
        if(mode === 'edit') {
            setSupplierReadOnlyFields({
                ...supplierreadOnlyFields, bpCode: false, bpSupplierName: false, bpGroupName: false,
                supplierAccountGroup: false, language: false, currency: false, organization: false,
            })
            setBasicDetailsValue(basicDetail.organization !== undefined ? { valueToBeDisplayed: basicDetail.organization } : []);
            setBranchValue(basicDetail.entity !== undefined ? { valuesToBeDisplayed: basicDetail.entity } : []);
        }
        if (mode === 'read') {
            setSupplierReadOnlyFields({
                ...supplierreadOnlyFields, active: true
            })
        }
    }, [mode, basicDetail]);

    const branchComboProperty = {
        name: pageLabelsConstantsAndMessages.name.entity,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.entityCode.label" }),
        isAutofocus: true,
        isDisabled: (mode === 'read' || mode === 'edit') ? true : false,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.entity
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} style={{ marginTop: '-1%' }}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                        <Grid item container xs={12} sm={6} md={5} lg={5}>
                            <EntityCodeCombo comboProperty={branchComboProperty} control={control} errors={errors} comboValue={branchValue} setComboValue={setBranchValue} />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                        <Grid item container xs={12} sm={6} md={5} lg={5}    >
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.bpSupplierName}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required
                                            variant="outlined"
                                            margin="normal"
                                            id="bpSupplierName"
                                            size='small'
                                            label={<FormattedMessage id="textfield.label.bpsuppliername" defaultMessage="BP Supplier Name" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            error = {errors.bpSupplierName}
                                            InputProps={{
                                                readOnly: supplierreadOnlyFields.bpSupplierName,
                                            }}
                                            disabled={mode === 'read' ? true : false}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={2} lg={2}  >
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.bpCode}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="bpCode"
                                            size='small'
                                            required={true}
                                            label={<FormattedMessage id="common.texfield.businesspartnercode" defaultMessage="Business Partner Code" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            error = {errors.bpCode}
                                            InputProps={{
                                                readOnly: supplierreadOnlyFields.bpCode,
                                            }}
                                            disabled={mode === 'read' || mode === 'edit' ? true : false}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={2} lg={1}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.currency}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="currency"
                                            size='small'
                                            label={<FormattedMessage id="common.textfield.columname.Currency.label" defaultMessage=" Currency" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            InputProps={{
                                                readOnly: supplierreadOnlyFields.currency,
                                            }}
                                            disabled={mode === 'read' ? true : false}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={2} lg={2}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.supplierAccountGroup}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="supplierAccountGroup"
                                            size='small'
                                            label={<FormattedMessage id="common.textfield.columname.supplieraccountgroup.label" defaultMessage="Supplier Account Group" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            InputProps={{
                                                readOnly: supplierreadOnlyFields.supplierAccountGroup,
                                            }}
                                            disabled={mode === 'read' ? true : false}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={2} lg={2}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.language}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="language"
                                            size='small'
                                            label={<FormattedMessage id="columnname.language.label" defaultMessage=" Language" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            InputProps={{
                                                readOnly: supplierreadOnlyFields.language,
                                            }}
                                            disabled={mode === 'read' ? true : false}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={2}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.bpGroupName}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="bpGroupName"
                                            size='small'
                                            label={<FormattedMessage id="common.textfield.columname.bpgroupname.label" defaultMessage=" BP Group Name" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            InputProps={{
                                                readOnly: supplierreadOnlyFields.bpGroupName,
                                            }}
                                            disabled={mode === 'read' ? true : false}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={2}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.entityUOM}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="entityUOM"
                                            size='small'
                                            label={<FormattedMessage id="textfield.label.entityUOM" defaultMessage="Entity UOM" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            disabled={mode === 'read' ? true : false}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={2}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.supplierUOM}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="supplierUOM"
                                            size='small'
                                            label={<FormattedMessage id="textfield.label.supplierUOM" defaultMessage="Supplier UOM" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            disabled={mode === 'read' ? true : false}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={2}>
                            <Grid container justifyContent='flex-start' alignItems='center'>
                                <Controller
                                    render={({
                                        field: { onChange } }) => (<Switch
                                            color='primary'
                                            disabled={supplierreadOnlyFields.active}
                                            checked={userToBeEdited.active !== undefined ? userToBeEdited.active : true}
                                            onChange={((event) => {
                                                onChange(!(userToBeEdited.active !== undefined ? userToBeEdited.active : true));
                                                setUserToBeEdited({ ...userToBeEdited, active: event.target.checked })

                                            })}
                                        />)}
                                    type="checkbox"
                                    name={pageLabelsConstantsAndMessages.name.active}
                                    control={control}
                                    defaultValue={userToBeEdited.active !== undefined ? userToBeEdited.active : true}
                                />
                                <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage={pageLabelsConstantsAndMessages.label.active} />}</InputLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
export default BasicDetails;