import React, { useEffect, useState } from 'react';
import { CssBaseline, FormControl, FormHelperText, Grid, InputLabel, Switch, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import _ from "lodash/fp";
import useStyles from '../../common/CommonStyle/CommonStyle';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const ProcessParameter = (props) => {
    const classes = useStyles();
    const { mode, errors, control, setValue, dlvyCfmDummyDate, setDlvyCfmDummyDate, supplierObjectToBeEditedOrView } = props;
    const pageLabelsConstantsAndMessages = {
        name: {
            acceptPriorDelivery: "acceptPriorDelivery",
            earlierAcceptanceDays: "earlierAcceptanceDays",
            lateAcceptanceDays: "lateAcceptanceDays",
            dlvyCfmDummyDate: "dlvyCfmDummyDate",
        }
    }

    const [userToBeEdited, setUserToBeEdited] = React.useState({});

    const [processParamReadOnlyFields, setProcessParamReadOnlyFields] = useState({
        acceptPriorDelivery: false,
        earlierAcceptanceDays: false,
        lateAcceptanceDays: false,
        dlvyCfmDummyDate: false

    })
    useEffect(() => {
        setValue(`${pageLabelsConstantsAndMessages.name.dlvyCfmDummyDate}`, dlvyCfmDummyDate, { shouldValidate: true, shouldDirty: true });
        if (mode === 'read' || mode === 'edit') {
            setProcessParamReadOnlyFields({
                ...processParamReadOnlyFields, acceptPriorDelivery: false, earlierAcceptanceDays: false, lateAcceptanceDays: false, dlvyCfmDummyDate: false
            })
        }
    }, [mode])

    useEffect(() => {
        setUserToBeEdited(supplierObjectToBeEditedOrView);
    }, [supplierObjectToBeEditedOrView]);


    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} >
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                        <Grid item container xs={12} sm={12} md={12} lg={12} >
                            <Typography>{<FormattedMessage id="pODeliveryConfirmationStatus.text" defaultMessage="PO Delivery Confirmation Status" />}</Typography>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={2} lg={2}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.earlierAcceptanceDays}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="earlierAcceptanceDays"
                                            size='small'
                                            label={<FormattedMessage id="columnname.earlieracceptancedays.label" defaultMessage="Earlier Acceptance Days" />}
                                            {...field}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            disabled={mode === 'read' ? true : false}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={2} lg={2}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.lateAcceptanceDays}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="lateAcceptanceDays"
                                            size='small'
                                            type="number"
                                            label={<FormattedMessage id="columnname.lateacceptancedays.label" defaultMessage="Late Acceptance Days" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            disabled={mode === 'read' ? true : false}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.dlvyCfmDummyDate}
                                        defaultValue={dlvyCfmDummyDate}
                                        render={({ field }) => (
                                            <MobileDatePicker
                                                renderInput={props => {
                                                    return <TextField {...props}
                                                        variant='outlined'
                                                        size="small"
                                                        label={<FormattedMessage id="textfield.label.dlvyCfmDummyDate" defaultMessage="Valid From  " />}
                                                        {...field}
                                                        error={errors.dlvyCfmDummyDate}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />;
                                                }}
                                                clearable
                                                id="dlvyCfmDummyDate"
                                                inputFormat="dd-MM-yyyy"
                                                value={dlvyCfmDummyDate}
                                                onChange={((date) => {
                                                    setDlvyCfmDummyDate(date);
                                                    setValue(`${pageLabelsConstantsAndMessages.name.dlvyCfmDummyDate}`, date, { shouldValidate: true, shouldDirty: true });
                                                })}
                                                disabled={mode === 'read' ? true : false}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.dlvyCfmDummyDate}.type`, errors) === "required" && dlvyCfmDummyDate === null && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.label.dlvyCfmDummyDate" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                            <Grid container justifyContent='flex-start' alignItems='center'>
                                <Controller
                                    render={({
                                        field: { onChange } }) => (<Switch
                                            color='primary'
                                            disabled={setProcessParamReadOnlyFields.acceptPriorDelivery}
                                            checked={userToBeEdited.acceptPriorDelivery !== undefined ? userToBeEdited.acceptPriorDelivery : false}
                                            onChange={((event) => {
                                                onChange(!(userToBeEdited.acceptPriorDelivery !== undefined ? userToBeEdited.acceptPriorDelivery : false));
                                                setUserToBeEdited({ ...userToBeEdited, acceptPriorDelivery: event.target.checked })

                                            })}
                                        />)}
                                    type="checkbox"
                                    name={pageLabelsConstantsAndMessages.name.acceptPriorDelivery}
                                    control={control}
                                    defaultValue={userToBeEdited.acceptPriorDelivery !== undefined ? userToBeEdited.acceptPriorDelivery : false}
                                />
                                <InputLabel>{<FormattedMessage id="common.acceptpriordelivery.label" />}</InputLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
export default ProcessParameter;