import { withStyles } from '@mui/styles';
import { Alert, ToggleButton, ToggleButtonGroup } from '@mui/lab';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, CircularProgress, ClickAwayListener, Divider, Fade, FormControl, Grid, IconButton, List, ListItem, MenuItem, MenuList, Paper, Popper, Snackbar, TextField, Tooltip, Typography, useMediaQuery, useTheme, TablePagination } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import IconBack from '@mui/icons-material/BackspaceOutlined';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import CustomBreadCrumb from '../common/CustomBreadCrumb';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import CommonBackDrop from '../common/BackDrop/CommonBackDrop';
import useStyles from '../common/CommonStyle/CommonStyle';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import { CommonService } from '../../services';
import { ModuleAccessPermissionKey } from '../../Constants/ModuleAccessKey';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';
import { SupportedLanguageDetails } from '../common/SupportedLanguageDetails/SupportedLanguageDetails';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';
import IconPurchaseOrderPurchaseForecastOutbound from '@mui/icons-material/AssignmentReturn';
import PCNFileUploadDialog from '../../components/common/PCNFileUploadDialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { CustomFilters } from '../common/CustomFilters/CustomFilters';
import { getGridStringOperators } from '@mui/x-data-grid-pro';
import ListIcon from '@mui/icons-material/List';
import ImportIcon from '@mui/icons-material/SaveAlt';
import YesOrNoCombo from '../common/Combos/YesOrNoCombo/YesOrNoCombo';
import PartChangeTypeCombo from '../common/Combos/PartChangeTypeCombo/PartChangeTypeCombo';
import saveAs from 'file-saver';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import CreateIcon from '@mui/icons-material/Add';
import TableChartIcon from '@mui/icons-material/TableChart';
import { GridToolbarColumnsButton, GridToolbarDensitySelector, DataGridPro } from '@mui/x-data-grid-pro';
import RefreshIcon from '@mui/icons-material/Refresh';
//import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import CommonGridPagination from '../../components/common/CommonGridPagination/CommonGridPagination';
import PFAuditIcon from '@mui/icons-material/Timeline';
import PcnDtAuditHistory from '../../components/Order/PcnDtAuditHistory/PcnDtAuditHistory';
import PCNTimeLine from '../../components/Order/PCNTimeLine/PCNTimeLine';
import HistoryIcon from '@mui/icons-material/HistorySharp';


const columnHeaderNames = {
    msgDtlsId: 'msgDtlsId',
    msgId: 'msgId',
    materialCode: 'materialCode',
    wbsCode: 'wbsCode',
    partChangeType: 'partChangeType',
    firstAnsDueDt: 'firstAnsDueDt',
    lastAnsDueDt: 'lastAnsDueDt',
    lastTimeBuyDt: 'lastTimeBuyDt',
    lastTimeShipmentDt: 'lastTimeShipmentDt',
    makerBpCode: 'makerBpCode',
    customerPartNo: 'customerPartNo',
    customerApprovalReqd: 'customerApprovalReqd',
    customerApprovedDt: 'customerApprovedDt',
    customerRemarks: 'customerRemarks',
    alternateMaterialCode: 'alternateMaterialCode',
    alternateMaterialDesc: 'alternateMaterialDesc',
    createdBy: 'createdBy',
    createdTime: 'createdTime',
    updatedBy: 'updatedBy',
    updatedTime: 'updatedTime',
    //itemDesc: 'itemDesc'

};


function EditOrViewPartChangeNotification(props) {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const { mode, msgId, isBusinessPartnerPortal, userAccessBpCode, userAccessBpName, defaultEntity, isPartchange, title, onClose, pcnNo, msgDtlsId ,msgStatusFlag } = props.location.state;
    const { handleSubmit, formState: { errors, isDirty, isValid }, control, reset, setValue ,getValues} = useForm({
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: {
            partChangeType: '',
            customerApprovalReqd: '',
            materialCode: '',

        },
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    });

    const pageLabelsConstantsAndMessages = {
        name: {
            partChangeType: 'partChangeType',
            customerApprovalReqd: 'customerApprovalReqd',
            materialCode: 'materialCode',

        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    }
    const mobileScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const smallScreen = useMediaQuery(theme.breakpoints.down(889))
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const localtext = GridTextLocalization();
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [rowsPerPageForList, setRowsPerPageForList] = React.useState(25);
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();

    const [paramBody, setParamBody] = useState({
        body: {
            // itemDesc: '',
            recordApprovedStatus: 'All',

        },
        pageNo: totalPageCount,
        sortDir: 'asc',
        sortKey: 'msgDtlsId',
        recordsPerPage: rowsPerPageForList,
    });
    const [alertBoxState, setAlertBoxState] = useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });

    const StyledToggleButton = withStyles((theme) => ({
        root: {
            '&:hover': {
                backgroundColor: '#f5f5f5',
            },
        },
        selected: {
            backgroundColor: "#d8d8d8",
        }

    }))(ToggleButton);

    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [disableRefreshButton, isRefreshButtonDisabled] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [msgDtlsPopUp, setMsgDtlsPopUp] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(true);
    const [columns, setColumns] = useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
    const [pcnToBeEdited, setPcnToBeEdited] = useState();
    const [branchValue, setBranchValue] = useState("");
    const [pcnDetailItemViewType, setPcnDetailItemViewType] = React.useState('list');
    const [isExportXlRequested, setExportXlRequested] = useState(false);
    const [disableDownloadButton, setDisableDownloadButton] = useState(false);
    const pcnModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.pcnModuleAccess];
    const [searchedMaterialDesc, setSearchedMaterialDesc] = useState('');
    const [selectedYesOrNo, setSelectedYesOrNo] = useState('');
    // const [selectedpcnType, setSelectedpcnType] = useState('');
    const [selectedpcnType, setSelectedpcnType] = useState({ value: '', displayName: '' });


    const [keyValueData, setKeyValueData] = useState({});
    const [searchedErpEntityCode, setSearchedErpEntityCode] = useState(defaultEntity);
    const [itemDesc, setItemDesc] = useState('');
    const [isResponseArrived, setResponseArrived] = useState(false);
    const [pcnItemDetailsObject, setPcnIteDetailsObject] = useState();
    const [openPcnAuditDialog, setOpenPcnAuditDialog] = useState(false);
    const [pcnNumber, setpcnNumber] = useState('');
    const [openPcnTimeLineDialog, setOpenPcnTimeLineDialog] = useState(false);

    const onError = ((error) => {
    })

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const closePcnAudit = (confirmed) => {
        setOpenPcnAuditDialog(false);
        setPcnIteDetailsObject(undefined);
    }

    const closePcnTimeLine = (confirmed) => {
        setOpenPcnTimeLineDialog(false);
        setPcnIteDetailsObject(undefined);
    }


    // const handleClose = (event) => {
    //     if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //         return;
    //     }
    //     setOpen(false);
    // };
    const breadcrumbForEdit = {
        path: undefined,
        name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage='Edit' />,
        iconName: EditIcon,
    };

    const breadcrumbForView = {
        path: undefined,
        name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage='View' />,
        iconName: ViewIcon,
    }
    const breadCrumbForPurchaseOrderForecastElement =
    {
        path: 'partChangeNotiHdrs',
        name: <FormattedMessage id="common.module.breadcrumb.partChangeNotificationHeader" defaultMessage='Part Change Notification Header' />,
        iconName: IconPurchaseOrderPurchaseForecastOutbound,
    };


    function getBaseBreadCrumbBasedOnPoForecastEDI() {
        let constructedBaseBreadCrumb = [];
        if (mode === 'view') {
            constructedBaseBreadCrumb = [breadCrumbForPurchaseOrderForecastElement, breadcrumbForView]
        } if (mode === 'edit') {
            constructedBaseBreadCrumb = [breadCrumbForPurchaseOrderForecastElement, breadcrumbForEdit]
        }
        return constructedBaseBreadCrumb;
    }
    function getBaseBreadCrumbBasedOnPoForecastForBusinessPortal() {
        let constructedBaseBreadCrumb = [];
        if (mode === 'edit') {
            constructedBaseBreadCrumb = [breadcrumbForEdit]
        } else {
            constructedBaseBreadCrumb = [breadcrumbForView]
        }
        return constructedBaseBreadCrumb;
    }
    const [componentList, setComponentList] = useState((() => {
        if (!isBusinessPartnerPortal) {
            return getBaseBreadCrumbBasedOnPoForecastEDI();
        } else if (isBusinessPartnerPortal) {
            return getBaseBreadCrumbBasedOnPoForecastForBusinessPortal();
        }
    }));

    const handleChangePage = (event, newPage) => {
        setTotalPageCount(newPage);
        setParamBody(prevState => { return { ...prevState, pageNo: newPage } })
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPageForList(parseInt(event.target.value, 10));
        setParamBody(prevState => { return { ...prevState, recordsPerPage: rowsPerPageForList, pageNo: totalPageCount } })
        setTotalPageCount(0);
    };

    const onSubmit = data => {
        data['partChangeType'] = selectedpcnType.value;
        data['customerApprovalReqd'] = selectedYesOrNo.value;
        data['msgDtlsId'] = tableData[0].msgDtlsId;
        setResponseArrived(true);
        CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.updatePcnDtls, data)
            .then((response) => {
                setResponseArrived(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success" });
                onClose(true);
            })
            .catch(function (error) {
                setResponseArrived(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            });
    }


    const commonColumnsForPcnTableView = [

        {
            field: columnHeaderNames.materialCode,
            headerName: intl.formatMessage({ id: "columnname.materialCode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.wbsCode,
            headerName: intl.formatMessage({ id: "columnname.wbscode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 130,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.partChangeType,
            headerName: intl.formatMessage({ id: "columnname.partChangeType.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.firstAnsDueDt,
            headerName: intl.formatMessage({ id: "columnname.firstAnsDueDt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'date',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.firstAnsDueDtDisp}</span>)
        },
        {
            field: columnHeaderNames.lastAnsDueDt,
            headerName: intl.formatMessage({ id: "columnname.lastAnsDueDt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'date',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.lastAnsDueDtDisp}</span>)

        },
        {
            field: columnHeaderNames.lastTimeBuyDt,
            headerName: intl.formatMessage({ id: "columnname.lastTimeBuyDt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'date',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.lastTimeBuyDtDisp}</span>)

        },
        {
            field: columnHeaderNames.lastTimeShipmentDt,
            headerName: intl.formatMessage({ id: "columnname.lastTimeShipmentDt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'date',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.lastTimeShipmentDtDisp}</span>)

        },
        {
            field: columnHeaderNames.makerBpCode,
            headerName: intl.formatMessage({ id: "columnname.makerBpCode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 130,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.customerPartNo,
            headerName: intl.formatMessage({ id: "columnname.customerPartNo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 150,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.customerApprovalReqd,
            headerName: intl.formatMessage({ id: "columnname.customerApprovalReqd.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 250,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.YesOrNoChip
        },
        {
            field: columnHeaderNames.customerApprovedDt,
            headerName: intl.formatMessage({ id: "columnname.customerApprovedDt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'date',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.customerApprovedDateDisp}</span>)

        },
        {
            field: columnHeaderNames.customerRemarks,
            headerName: intl.formatMessage({ id: "columnname.customerRemarks.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 140,
            sortable: true,
            type: 'string',
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.alternateMaterialCode,
            headerName: intl.formatMessage({ id: "columnname.alternateMaterialCode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 190,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.alternateMaterialDesc,
            headerName: intl.formatMessage({ id: "columnname.alternateMaterialDesc.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 220,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.createdBy,
            headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.createdTime,
            headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.createdTimeDisp}</span>)
        },
        {
            field: columnHeaderNames.updatedBy,
            headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 155,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.updatedTime,
            headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.updatedTimeDisp}</span>)
        },

    ];
    const closeFileUploadDialog = (confirmed) => {
        setOpenFileUploadDialog(false);
    }

    const onSubmitSearch = data => {
        setParamBody({
            ...paramBody, body: {
                // itemDesc: data.itemDesc,
                materialCode: data.materialCode,


            }
        });
        // setSearchedMaterialDesc(data.itemDesc);
        setSearchedMaterialDesc(data.materialCode);
    };

    useEffect(() => {
        setColumns(commonColumnsForPcnTableView)
    }, [selectedLanguage, tableData])

    // useEffect(() => {
    //     if (!isBusinessPartnerPortal) {
    //         setColumns(constructColumnsToBeDisplayedForEdi());
    //     } else {
    //         setColumns(constructColumnsToBeDisplayed());
    //     }
    // }, [selectedLanguage, tableData]);

    const handleClose = (value) => {
        setComponentList(title);
        onClose(value);
    }

    const showDownloadPopUp = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage='File downloaded successfully' />, severity: "success" });
                saveAs(blob, fileName);
                setExportXlRequested(false);
            })
            .catch(error => {
                setExportXlRequested(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage='Failed to download the file!' />, severity: "error" });
            })

    }

    function clearPoDtlSearchItem() {
        reset({
            // itemDesc: '',
            materialCode: '',

        });
        setParamBody({
            ...paramBody,
            body: {
                //   itemDesc: '',
                materialCode: '',

            }
        });
        setSearchedMaterialDesc('');
        setSearchedMaterialDesc('');

    }

    useEffect(() => {
        if (msgDtlsPopUp === true) {
            setOpenBackDrop(true);
            CommonService.saveOrUpdateOrPost('/reports/SYS_PCN_HDR_REPORT', searchedErpEntityCode === '' ? { erpEntityCode: '' } : keyValueData
            )
                .then((response) => {
                    if (response.data.sucess === "No records found for given condition") {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.sucess, severity: "error" });
                    } else {
                        // showDownloadPopUpExcelDtls(response.data.fileUrl)
                        setAlertBoxState({
                            ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success"
                        });
                    }
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occurred' : error.response.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgDtlsPopUp(false)
        }
    }, [msgId, msgDtlsPopUp])


    useEffect(() => {
        setOpenBackDrop(true);
        CommonService.getByIdApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.partChangeNotificationHdr, msgId)
            .then((response) => {
                setPcnToBeEdited(response.data);
                setOpenBackDrop(false);
            })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
                setOpenBackDrop(false);
            });
    }, [refreshFlag, msgId]);

    useEffect(() => {
        setTableLoadingStatus(true);
        setTableData([]);
        setOpenBackDrop(true);

        CommonService.GetListApi(`/partChangeNotificationHdr/${msgId}/partChangeNotificationDtl`, paramBody)
            .then((response) => {
                setTableLoadingStatus(false);
                setTotalRowCount(response.data.totalElements);

                const updatedData = [];
                response.data.content.forEach((detail) => {
                    const modifiedDetail = {
                        ...detail,
                        selectedpcnType: { value: detail.partChangeType, displayName: detail.partChangeType },
                        selectedYesOrNo: { value: detail.customerApprovalReqd, name: detail.customerApprovalReqd === true ? 'YES' : 'NO' },
                        isEditable: false // or any property to handle the edit state
                    };

                    updatedData.push(modifiedDetail);
                });

                setTableData(updatedData);
                setOpenBackDrop(false);
            })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setOpenBackDrop(false);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [mode, paramBody, refreshFlag]);




    const pcnTypeColumn = columns.find((column) => column.field === columnHeaderNames.partChangeType);
    const pcnTypeColIndex = columns.findIndex((col) => col.field === columnHeaderNames.partChangeType);
    const [disableSaveOrUpdateButton, issaveOrUpdateButtonDisabled] = useState(false);

    const pcnTypeFilterOperators = getGridStringOperators().map(
        (operator) => ({
            ...operator,
            InputComponent: CustomFilters.PartChangeTypeInputValue,
        }),
    );

    columns[pcnTypeColIndex] = {
        ...pcnTypeColumn,
        filterOperators: pcnTypeFilterOperators,
    };

    const handleToggleChange = (event, newAlignment) => {
        setPcnDetailItemViewType(newAlignment);
    };
    const yesOrNoComboProperty = {
        name: pageLabelsConstantsAndMessages.name.customerApprovalReqd,
        isRequired: true,
        // label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: mode == 'view' ? true : false,
        variant: 'standard',
        isHookFormType: false,
        errors: errors.customerApprovalReqd
    }
    const pcnTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.partChangeType,
        isRequired: true,
        // label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: mode == 'view' ? true : false,
        variant: 'standard',
        isHookFormType: true,
        errors: errors.partChangeType

    }
const validateRequiredFields = (getValues('partChangeType') !== '' && getValues('customerApprovalReqd') !== '');

  useEffect(() => {

    if (!(mode === 'view')) {
        if ((mode === 'create') && validateRequiredFields || !isValid) {
            issaveOrUpdateButtonDisabled(true)
        } else if ((mode === 'create') && !validateRequiredFields) {
            issaveOrUpdateButtonDisabled(false)
        } else if ((mode === 'edit') && (isDirty) || !isValid) {
            issaveOrUpdateButtonDisabled(false)
        } else {
            issaveOrUpdateButtonDisabled(true);
        }
    }
}, [selectedpcnType,selectedYesOrNo,mode, isValid, isDirty])


    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <PCNFileUploadDialog open={openFileUploadDialog} onClose={closeFileUploadDialog} isBusinessPartnerPortal={isBusinessPartnerPortal} defaultEntity={defaultEntity}
                defaultBpType={"SUPPLIER"} dialogHeader={<FormattedMessage id="uploadsPcn.header.text"/>} isPartchange={isPartchange} />

            <Grid container className={classes.rootForEditPcn}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={6} md={12} lg={8} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' alignItems='center' xs={12} sm={6} md={12} lg={4} className={classes.topGrid}>
                    <Button size='small' variant="contained" color="primary" className={classes.stateButton} elevation={2}
                        endIcon={smallScreen ? null : <IconBack />} onClick={history.goBack}>
                        {smallScreen ? <IconBack /> : <FormattedMessage id="common.button.back.lable" />}
                    </Button>

                    <Button size='small' disabled={disableRefreshButton} variant="contained" color="secondary" className={classes.stateButtonReferesh} elevation={2}
                        endIcon={smallScreen ? null : <IconRestore />} onClick={(() => { setRefreshFlag(!refreshFlag) })}>
                        {smallScreen ? <IconRestore /> : <FormattedMessage id="common.button.refresh.tooltip" />}
                    </Button>

                    {(mode === 'edit') ?
                        <Button size='medium'
                            variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                            endIcon={isResponseArrived ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />}
                            onClick={handleSubmit(onSubmit, onError)}
                            disabled={disableSaveOrUpdateButton}

                        >
                            {smallScreen ? <IconSaveOrUpdate /> : <FormattedMessage id="common.button.update" />}
                        </Button> : ""}
                      
                </Grid>
                <Paper className={classes.paperForEditPurchaseOrder} elevation={3}
                    style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%', height: 'auto' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}
                >
                    <form className={classes.formForPurchaseOrder}>
                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={1}>
                                    <Chip size='small' variant='outlined' className={classes.chipTheme} color='primary'
                                        label={<FormattedMessage id="pcnNumber.chip.text" values={{ pcnToBeEdited: `${(pcnToBeEdited !== undefined ? pcnToBeEdited.pcnNo : '')}` }} />}
                                    />

                                    <Chip size='small' variant='outlined' className={classes.chipTheme} color='primary'
                                        label={<FormattedMessage id="pcnReceivedDate.chip.text" values={{ pcnToBeEdited: `${(pcnToBeEdited !== undefined ? pcnToBeEdited.pcnDateDisp : '')}` }} />}
                                    />
                                    <Chip size='small' variant='outlined' className={classes.chipTheme} color='primary'
                                        label={<FormattedMessage id="pcnNoLineItem.chip.text" values={{ pcnToBeEdited: `${tableData.length}` }} />}
                                    />
                                    <Chip size='small' variant='outlined' className={classes.chipForPO} color='secondary'
                                        label={<FormattedMessage id="pcnApproved.chip.text" values={{ pcnToBeEdited: `${(pcnToBeEdited !== undefined ? pcnToBeEdited.approved === 1 ? 'Approved' : pcnToBeEdited.approved === 2 ? 'Rejected' : 'Not Approved' : '')}` }} />}

                                    />
                                </Grid>
                            </AccordionSummary>


                            <AccordionDetails className={classes.pcndetails}>
                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>

                                    <Grid item container justifyContent='center' alignItems='baseline' xs={12} sm={12} md={12} lg={12} className={classes.helper}>
                                        <Grid item container justifyContent='flex-start' alignItems='center' >
                                            <Typography variant='h6'><strong>{isBusinessPartnerPortal ? <FormattedMessage id="vendordetails" /> : <FormattedMessage id="supplierdetails" />}</strong></Typography>
                                        </Grid>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <div>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="name.accordian.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(pcnToBeEdited !== undefined ? pcnToBeEdited.bpName : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="richtexteditor.blocktype.code.text" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(pcnToBeEdited !== undefined ? pcnToBeEdited.bpCode : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                <FormattedMessage id="columnname.supplierAddress.label" />
                                                            </Typography>

                                                            </td>
                                                            <td> : </td>
                                                            <td>  <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                {(pcnToBeEdited !== undefined ? pcnToBeEdited.bpAddress : '')}
                                                            </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                    </form>
                </Paper>
                <Paper className={classes.paperForEditPurchaseOrder} elevation={3} style={{ height: smallScreen ? '' : '80vh' }} >
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{ padding: '5px 10px' }}>
                        <Grid item container justifyContent="flex-start" alignItems="center" xs={6} sm={6} md={6} lg={6}>
                            <Typography component="span" variant="h5" color="textPrimary">
                                <FormattedMessage id="materialdetails.label" />
                            </Typography>
                        </Grid>
                        <Grid item container justifyContent="flex-end" alignItems="center" xs={6} sm={6} md={6} lg={6}>
                            <ToggleButtonGroup size="small" value={pcnDetailItemViewType} exclusive onChange={handleToggleChange}>
                                <StyledToggleButton value="list">
                                    <Tooltip title={<FormattedMessage id="listview.tooltip" defaultMessage='List View' />} placement='bottom'>
                                        <ListIcon fontSize="small" />
                                    </Tooltip>
                                </StyledToggleButton>
                                <StyledToggleButton value="table">
                                    <Tooltip title={<FormattedMessage id="tableview.tooltip" defaultMessage='tableview.tooltip' />} placement='bottom'>
                                        <TableChartIcon fontSize="small" />
                                    </Tooltip>
                                </StyledToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    <form className={classes.formForPurchaseOrder}>
                        <Grid item container justifyContent="flex-start" alignItems="center" xs={12} sm={12} md={12} lg={12} spacing={1} style={{ padding: '5px 10px' }}>
                            <Grid item container xs={12} sm={3} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.materialCode}
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="materialCode"
                                                size='small'
                                                label={<FormattedMessage id="columnname.materialCode.label" defaultMessage='Material code' />}
                                                placeholder={intl.formatMessage({ id: "materialcode.placeholder.label" })}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            {(
                                <Grid item container xs={12} sm={3} md={3} lg={3}>
                                </Grid>)}
                            <Grid item container justifyContent="flex-start" alignItems="center" xs={12} sm={6} md={6} lg={6}>
                                <Tooltip title={<FormattedMessage id="common.button.clear.tooltip" />} placement='bottom'>
                                    <span>
                                        <Button size='small'
                                            variant="contained" color="inherit" className={classes.editPoToobarStyle} elevation={2}
                                            onClick={(() => { clearPoDtlSearchItem() })}
                                        >
                                            <ClearIcon />
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip title={<FormattedMessage id="common.button.search.tooltip" />} placement='bottom'>
                                    <span>
                                        <Button size='small'
                                            variant="contained" color="primary" className={classes.editPoToobarStyle} elevation={2}
                                            onClick={handleSubmit(onSubmitSearch)}
                                        >
                                            <SearchIcon />
                                        </Button>
                                    </span>
                                </Tooltip>

                                {(mode === 'edit') && (
                                    <Tooltip title={<FormattedMessage id="importPcnSchedulebutton" />} placement='bottom'>
                                        <span>
                                            <Button size='small'
                                                className={classes.editPoToobarStyle}
                                                variant="contained"
                                                disabled={tableData.length <= 0}
                                                color="primary" component="span"
                                                onClick={(() => {
                                                    setOpenFileUploadDialog(true);

                                                })}
                                            >
                                                <ImportIcon />
                                            </Button>
                                        </span>
                                    </Tooltip>)}
                            </Grid>

                        </Grid>
                    </form>
                    {(pcnDetailItemViewType === 'table') && (
                        <Paper elevation={3} className={classes.gridHeightAndWidthForPOOutboundTableView} >
                            <div style={{ width: '100%', height: '100%' }}>

                                <DataGridPro
                                    className={classes.customTableStyle}
                                    pageSize={25}
                                    loading={tableLoadingStatus}
                                    getRowId={(row) => row.msgDtlsId}
                                    rows={tableData}
                                    columns={columns}
                                    rowCount={totalRowCount}
                                    scrollbarSize={30}
                                    disableColumnFilter={true}
                                    rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                                    pagination
                                    density="compact"
                                    paginationMode="server"
                                    hideFooter={true}
                                    disableSelectionOnClick={true}
                                    disableMultipleColumnsSorting={true}
                                    localeText={localtext}
                                    disableColumnMenu={false}
                                    onPageSizeChange={((pageParams) => {
                                        setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                                    })}
                                    onPageChange={((pageParams) => {
                                        setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                                    })}
                                    components={{
                                        Toolbar: () => {
                                            return (
                                                <Grid container direction="row" justifyContent="flex-start" alignItems="center"
                                                    style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                                    <Grid item container direction="row" justifyContent="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" />} placement='bottom'>
                                                                <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.density.tooltip" />}
                                                                placement='bottom'>
                                                                <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                                <span>
                                                                    <Button size="small" color="primary" variant="outlined" className={classes.refreshToobarStyle}
                                                                        onClick={(() => {
                                                                            setRefreshFlag(!refreshFlag);
                                                                        })}>
                                                                        <RefreshIcon />
                                                                    </Button>
                                                                </span>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <CommonGridPagination />
                                                    </Grid>
                                                </Grid>
                                            );
                                        },
                                    }}
                                />
                            </div>
                        </Paper>
                    )}
                    {(pcnDetailItemViewType === 'list') && (
                        <div id="scrollableDiv">
                            <Divider />
                            <Grid item container xs={12} sm={12} md={12} lg={12} >
                                {(<Grid item container justifyContent='flex-start' xs={12} sm={12} md={6} lg={6} style={{ padding: '5px 10px' }}>

                                </Grid>)}
                                <Grid item container justifyContent='flex-end' xs={12} sm={12} md={6} lg={6}>
                                    <TablePagination
                                        component="div"
                                        count={totalRowCount}
                                        page={totalPageCount}
                                        onPageChange={handleChangePage}
                                        rowsPerPageOptions={[1, 10, 25, 50, 100]}
                                        rowsPerPage={rowsPerPageForList}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </Grid>
                            <List style={{ padding: '0 10px' }}>
                                {tableData.map((row, index) => {
                                    <React.Fragment key={index}></React.Fragment>
                                    return (
                                        <Paper elevation={5} style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '5px', width: '100%', height: '85%' }}
                                        >
                                            <ListItem dense key={index} className={classes.helperForPos}>
                                                <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={12}>
                                                    <Grid item container justifyContent='flex-start' xs={12} sm={12} md={4} lg={3}>

                                                        <table>
                                                            <tbody>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader} style={{ marginTop: '10px' }}>
                                                                            {<FormattedMessage id="columnname.materialCode.label" />}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.materialCode}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                {/* <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.materialdesctable.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.itemDesc}</Typography>
                                                                    </td>
                                                                </tr> */}
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.wbscode.label" defaultMessage=
                                                                                {columnHeaderNames.wbsCode} />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.wbsCode}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {<FormattedMessage id="columnname.partChangeType.label" />}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <PartChangeTypeCombo
                                                                        control={control}
                                                                        errors={errors}
                                                                        comboProperty={pcnTypeComboProperty}
                                                                        comboValue={row.selectedpcnType}
                                                                        setComboValue={(newValue) => {
                                                                            // Update the selectedpcnType state here
                                                                            setSelectedpcnType({ value: newValue.value, displayName: newValue.displayName });

                                                                            // Update the tableData array with the new value
                                                                            const updatedTableData = tableData.map((item, i) => {
                                                                                if (i === index) {
                                                                                    return { ...item, selectedpcnType: { value: newValue.value, displayName: newValue.displayName } };
                                                                                }
                                                                                return item;
                                                                            });

                                                                            setTableData(updatedTableData); // Update the tableData state
                                                                        }}
                                                                    />


                                                                    <td>

                                                                    </td>
                                                                </tr>

                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        {<Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {intl.formatMessage({ id: "columnname.pcnTimeLine.text" })}
                                                                        </Typography>}
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td><Tooltip title={<FormattedMessage id="exppcntimelinechart.tooltip" />}
                                                                        placement='bottom' className={classes.actionButton} onClick={(() => {
                                                                            setPcnIteDetailsObject(row.msgDtlsId);
                                                                            setOpenPcnTimeLineDialog(true);
                                                                        })}>
                                                                        <HistoryIcon className={classes.secondaryColor} />
                                                                    </Tooltip></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={3} className={classes.helper}>
                                                        <table>
                                                            <tbody>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.firstAnsDueDt.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.firstAnsDueDtDisp}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.lastAnsDueDt.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.lastAnsDueDtDisp}</Typography>
                                                                    </td>
                                                                </tr>

                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.lastTimeBuyDt.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' display='block' className={classes.boldHeader}>
                                                                            {row.lastTimeBuyDtDisp}</Typography>
                                                                    </td>
                                                                </tr>

                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.lastTimeShipmentDt.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' display='block' className={classes.boldHeader}>
                                                                            {row.lastTimeShipmentDtDisp}</Typography>
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={3} className={classes.helper}>
                                                        <table>
                                                            <tbody>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {<FormattedMessage id="columnname.makerBpCode.label" />}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.makerBpCode}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.customerPartNo.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.customerPartNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.customerApprovalReqd.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <YesOrNoCombo
                                                                        control={control}
                                                                        errors={errors}
                                                                        comboProperty={yesOrNoComboProperty}
                                                                        comboValue={row.selectedYesOrNo}
                                                                        setComboValue={(newValue) => {
                                                                            // Update the selectedpcnType state here
                                                                            setSelectedYesOrNo({ value: newValue.value, name: newValue.name });

                                                                            const updatedTableData = tableData.map((item, i) => {
                                                                                if (i === index) {
                                                                                    return { ...item, selectedYesOrNo: newValue };
                                                                                }
                                                                                return item;
                                                                            });
                                                                            setTableData(updatedTableData);
                                                                        }}
                                                                    />
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.customerApprovalReqd}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.customerApprovedDt.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.customerApprovedDateDisp}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={3} className={classes.helper}>
                                                        <table>
                                                            <tbody>
                                                                {<tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.customerRemarks.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.customerRemarks}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>}

                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.alternateMaterialCode.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.alternateMaterialCode}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                {<tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.alternateMaterialDesc.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.alternateMaterialDesc}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>}

                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        {<Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {intl.formatMessage({ id: "columnname.pcnaudithistoty.text" })}
                                                                        </Typography>}
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td><Tooltip title={<FormattedMessage id="exppcntimelinechart.tooltip" />}
                                                                        placement='bottom' className={classes.actionButton} onClick={(() => {
                                                                            setPcnIteDetailsObject(row.msgDtlsId);
                                                                            setOpenPcnAuditDialog(true);
                                                                        })}>
                                                                        <PFAuditIcon className={classes.secondaryColor} />
                                                                    </Tooltip></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>

                                                </Grid>
                                            </ListItem>
                                        </Paper>


                                    );


                                })}
                            </List>
                            {tableLoadingStatus && (
                                <Paper style={{ marginBottom: '5px', width: '100%' }}>
                                    <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                        <CircularProgress color='primary' size={40} />
                                    </Grid>
                                </Paper>
                            )}
                            {(tableData.length <= 0 && !tableLoadingStatus) && (
                                <Paper style={{ marginBottom: '5px', width: '100%' }}>
                                    <Divider />
                                    <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}><FormattedMessage id="norows.label" defaultMessage="No rows" /></Typography>
                                    </Grid>
                                </Paper>
                            )}
                        </div>
                    )}
                </Paper>
                <PcnDtAuditHistory open={openPcnAuditDialog} onClose={closePcnAudit} pcnHdr={pcnToBeEdited} pcnDtl={pcnItemDetailsObject} isBusinessPartnerPortal={isBusinessPartnerPortal} isPcnAudtitHistrory={isPartchange} msgIdValue={msgId} pcnNo={pcnToBeEdited !== undefined ? pcnToBeEdited.pcnNo : ''} msgDtlsId={msgDtlsId}
                />
                <PCNTimeLine open={openPcnTimeLineDialog} onClose={closePcnTimeLine} pcnHdr={pcnToBeEdited} pcnDtl={pcnItemDetailsObject} isBusinessPartnerPortal={isBusinessPartnerPortal} isPcnTimeLine={isPartchange} msgIdValue={msgId} pcnNo={pcnToBeEdited !== undefined ? pcnToBeEdited.pcnNo : ''} msgDtlsId={msgDtlsId} msgStatusFlag={msgStatusFlag} />

            </Grid>
        </React.Fragment>

    );
}

export default EditOrViewPartChangeNotification;







