import { getGridStringOperators, GridFilterPanel, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, LicenseInfo, DataGridPro, getGridDateOperators } from "@mui/x-data-grid-pro";
import React, { useMemo } from 'react';
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useStyles from "../common/CommonStyle/CommonStyle";
import GridTextLocalization from "../common/GridTextLocalization/GridTextLocalization";
import { SupportedLanguageDetails } from "../common/SupportedLanguageDetails/SupportedLanguageDetails";
import { FormattedMessage, useIntl } from "react-intl";
import { CommonService } from "../../services";
import { Badge, Button, CssBaseline, Grid, IconButton, Paper, Snackbar, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import GetAppIcon from '@mui/icons-material/CloudDownload';
import { Alert } from "@mui/lab";
import CustomBreadCrumb from "../common/CustomBreadCrumb";
import CommonGridPagination from "../common/CommonGridPagination/CommonGridPagination";
import RefreshIcon from '@mui/icons-material/Refresh';
import ConfirmationDialog from "../common/ConfirmationDialog";
import ChipInfoList from "../common/ChipInfoList";
import { CustomFilters } from "../common/CustomFilters/CustomFilters";
import { LoggedInUserDetails } from "../common/LoggedInUserDetails/LoggedInUserDetails";
import { ModuleAccessPermissionKey } from "../../Constants/ModuleAccessKey";
import { GlobalEdiApiConstants } from "../../Constants/GlobalEdiApiConstants";
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import IconEmailMessageLog from '@mui/icons-material/RateReview';
import { saveAs } from "file-saver";
import CommonBackDrop from "../common/BackDrop";
import CustomFilterComponent, { GetFilterableColumns } from "../common/CustomFilterComponent";
import { CollectionsOutlined, FilterList } from "@mui/icons-material";
import { manageUserPreferences } from '../../services/manageUserPreferences';
import GetAppDownloadIcon from '@mui/icons-material/GetApp';

const columnFieldsName = {
    erpOrgCode: 'organization.erpOrgCode',
    erpEntityCode: 'entity.erpEntityCode',
    orgName: 'organization.orgName',
    entityName: 'entity.entityName',
    emailTo: 'emailTo',
    emailCc: 'emailCc',
    emailSubject: 'emailSubject',
    attachmentPath: 'attachmentPath',
    deliveryRemarks: 'deliveryRemarks',
    bpCode: 'bpCode',
    bpName: 'bpName',
    process: 'process',
    createdTime: 'createdTime',
    deliveryStatus: 'deliveryStatus',
    createdBy: 'createdBy',
    updatedTime: 'updatedTime',
    updatedBy: 'updatedBy',
    processName: 'processName',
    sentToDate: "sentToDate",
    sentFromDate: "sentFromDate",
}

function EmailMessageLog() {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();


    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
        name: {
            name: 'name'
        }
    };

    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.messageconfiguration" />,
            iconName: IconMessageConfiguration,
        },
        {
            path: '/emailMessageTemplates',
            name: <FormattedMessage id="common.module.breadcrumb.emailmessagelog" />,
            iconName: IconEmailMessageLog,
        }
    ];

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [chipInfoList, setChipInfoList] = useState([]);
    const [paramBody, setParamBody] = useState(
        apiUrlAndParamBodyConstants.paramBodyForGetListApis.emailMessageLogs
    );
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [refreshTable, setRefreshTable] = useState(false);
    const [searchedProcess, setSearchedProcess] = useState('');
    const [searchedEntityCode, setSearchedEntityCode] = useState('');
    const [searchedBpCode, setSearchedBpCode] = useState('');
    const [searchedEntityName, setSearchedEntityName] = useState('');
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [searchedStatus] = useState(true);
    const [processNameValue, setProcessNameValue] = useState('')
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [searchedBpName, setSearchedBpName] = useState('');
    const [searchedDeliveryStatus, setSearchedDeliveryStatus] = useState('');
    const [searchedSentFromDate, setSearchedSentFromDate] = useState('');
    const [searchedSentToDate, setSearchedSentToDate] = useState('');
    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    var defaultEntity = loggedInUserInfo.defaultEntity;

    const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.erpEntityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity }]
        : [];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);

    const [reportDownloadPopUp, setReportDownloadPopUp] = useState(false);
    const [keyValueData, setKeyValueData] = useState({});
    const [disiablingDownloadButtonForZeroRecords, setDisiablingDownloadButtonForZeroRecords] = useState(false)

    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState(preference.columnsPinned);
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }


    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);

    const [columns, setColumns] = useState([]);
    const columnsForEmailMessageLog = [
        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: 100,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    {cellParams.row.attachmentPath !== '' && (<Tooltip title={<FormattedMessage id="downoad.defaulttext.button" />}
                        placement='bottom' className={classes.actionButton} onClick={(() => {
                            setProcessNameValue(cellParams.row.processName);
                            showDownloadPopUp(cellParams.row.attachmentPath, cellParams.row.processName);
                        })}>
                        <span>
                            <IconButton className={classes.customeToolbar} size="large">
                                <GetAppIcon color="primary" size='10' />
                            </IconButton>
                        </span>
                    </Tooltip>)}
                </div>
            )
        },
        {
            field: columnFieldsName.orgName,
            headerName: intl.formatMessage({ id: "columnname.OrganizationName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 160,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            valueGetter: (params) => `${params.row.organization.orgName}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.erpOrgCode,
            headerName: intl.formatMessage({ id: "columnname.organizationcode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 140,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            valueGetter: (params) => `${params.row.organization.erpOrgCode}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.entityName,
            headerName: intl.formatMessage({ id: "columnname.entityName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 160,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: true,
            valueGetter: (params) => `${params.row.entity.entityName}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.erpEntityCode,
            headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 85,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: true,
            valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.processName,
            headerName: intl.formatMessage({ id: "columnname.process.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 140,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'bpCode',
            headerName: intl.formatMessage({ id: "common.textfield.columname.bpcode" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 130,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.bpName,
            headerName: intl.formatMessage({ id: "columnName.bpname.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.attachmentPath,
            headerName: intl.formatMessage({ id: "columnname.fileattachment.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.deliveryStatus,
            headerName: intl.formatMessage({ id: "columnname.deliveryStatus.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 140,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.SuccessFailureChipsForEmailLog
        },
        {
            field: columnFieldsName.emailSubject,
            width: 190,
            headerName: intl.formatMessage({ id: "columnname.emailsubject.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.emailTo,
            headerName: intl.formatMessage({ id: "columnname.emailTo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.emailCc,
            headerName: intl.formatMessage({ id: "columnname.emailCC.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.deliveryRemarks,
            headerName: intl.formatMessage({ id: "columnname.deleiveryremarks.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.updatedBy,
            headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 155,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.updatedTime,
            headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.lastUpdatedDtDisp}</span>)
        },
        {
            field: columnFieldsName.createdBy,
            headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.createdTime,
            headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.createdDtDisp}</span>)
        },
        {
            field: columnFieldsName.sentToDate,
            width: 0,
            headerName: intl.formatMessage({ id: "columnname.emailSentToDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: false,
            type: 'date',
            hide: true,
            filterable: true,
        },
        {
            field: columnFieldsName.sentFromDate,
            width: 0,
            headerName: intl.formatMessage({ id: "columnname.emailSentFromDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: false,
            type: 'date',
            hide: true,
            filterable: true,
        }
    ];

    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(columnsForEmailMessageLog);
                savePreference("visibility", columnsForEmailMessageLog);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, columnsForEmailMessageLog);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let orgName = null;
                let processName = null;
                let orgCode = null;
                let bpCode = null;
                let bpName = null;
                let entityCode = null;
                let entityName = null;
                let sentToDate = null;
                let sentFromDate = null;
                let deliveryStatus = null;
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'processName':
                            processName = columns.value
                            break;
                        case 'organization.orgName':
                            orgName = columns.value
                            break;
                        case 'organization.erpOrgCode':
                            orgCode = columns.value
                            break;
                        case 'entity.erpEntityCode':
                            entityCode = columns.value
                            break;
                        case 'entity.entityName':
                            entityName = columns.value
                            break;
                        case 'bpCode':
                            bpCode = columns.value
                            break;
                        case 'bpName':
                            bpName = columns.value
                            break;
                        case 'sentToDate':
                            sentToDate = columns.value
                            break;
                        case 'sentFromDate':
                            sentFromDate = columns.value
                            break;
                        case 'deliveryStatus':
                            deliveryStatus = columns.value
                            break;
                    }
                })
                setSearchedProcess(processName);
                setSearchedEntityCode(entityCode);
                setSearchedBpCode(bpCode);
                setSearchedEntityName(entityName);
                setSearchedBpName(bpName);
                setSearchedDeliveryStatus(deliveryStatus);
                setSearchedSentFromDate(sentFromDate);
                setSearchedSentToDate(sentToDate);

                var filterBody = {
                    active: searchedStatus,
                    processName: processName,
                    bpCode: bpCode,
                    bpName: bpName,
                    organization: {
                        erpOrgCode: orgCode, orgName: orgName,
                    },
                    entity: {
                        erpEntityCode: entityCode,
                        entityName: entityName
                    },
                    sentToDate: sentToDate,
                    sentFromDate: sentFromDate,
                    deliveryStatus: deliveryStatus === 'Pending' ? 0 : deliveryStatus === 'Successfully Sent' ? 1 : deliveryStatus === 'Failed' ? 2 : deliveryStatus,
                    recordActiveStatus: deliveryStatus ? deliveryStatus : 'ALL'
                };
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0) {
                    setSearchedEntityCode(defaultEntity);
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                }
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, [selectedLanguage, processNameValue]);

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.emailMessageLogs, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }

    }, [paramBody, refreshTable])


    // const showDownloadPopUp = (filePath) => {
    //     setOpenBackDrop(true);
    //     const csvFilePath = filePath;
    //     const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
    //     const csvFilePathReplaceWithDoubleBackwardSlash = csvFilePath.replaceAll('\\', '/');
    //     const filePathTrim = csvFilePathReplaceWithDoubleBackwardSlash.split(';')[0].trim();
    //     const pdfFinalFilePath = filePathTrim.split('/')[filePathTrim.split('/').length - 1].trim();

    //     const fileName = processNameValue === 'PO PDF' || filePath.includes(';') ? pdfFinalFilePath : filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
    //     fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${processNameValue === 'PO PDF' || filePath.includes(';') ? filePathTrim : filePathReplaceWithDoubleBackwardSlash}`, {
    //         method: 'GET',
    //         headers: {
    //             authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
    //         },
    //     })
    //         .then((response) => {
    //             return response.blob();
    //         }
    //         )
    //         .then((blob) => {
    //             setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
    //             saveAs(blob, fileName);
    //             setOpenBackDrop(false);
    //         })
    //         .catch(error => {
    //             setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
    //             setOpenBackDrop(false);
    //         })
    // }

    const showDownloadPopUp = (filePath, processName) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        var fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();

        const current = new Date();
        const date = `_${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}_${current.getHours()}_${current.getMinutes()}_${current.getSeconds()}`;
        fileName = processName + date;

        fetch(`${process.env.REACT_APP_API_URL}/zip-download?MultiplefileName=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
                // var url = URL.createObjectURL(blob);
                // window.open(url)
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }

    if (columns.length > 0) {
        const orgCodeColumn = columns.find((column) => column.field === columnFieldsName.erpOrgCode);
        const orgCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpOrgCode);

        const orgCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgCodeInputValue,
            }),
        );

        columns[orgCodeColIndex] = {
            ...orgCodeColumn,
            filterOperators: orgCodeFilterOperators,
        };

        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };

        const orgNameColumn = columns.find((column) => column.field === columnFieldsName.orgName);
        const orgNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.orgName);

        const orgNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgNameInputValue,
            }),
        );

        columns[orgNameColIndex] = {
            ...orgNameColumn,
            filterOperators: orgNameFilterOperators,
        };

        const entityNameColumn = columns.find((column) => column.field === columnFieldsName.entityName);
        const entityNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.entityName);

        const entityNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityNameInputValue,
            }),
        );

        columns[entityNameColIndex] = {
            ...entityNameColumn,
            filterOperators: entityNameFilterOperators,
        };

        const bpNameColumn = columns.find((column) => column.field === columnFieldsName.bpName);
        const bpNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.bpName);

        const bpNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.BPMasterInputValue,
            }),
        );

        columns[bpNameColIndex] = {
            ...bpNameColumn,
            filterOperators: bpNameFilterOperators,
        };
        const sentFromDateColumn = columns.find((column) => column.field === columnFieldsName.sentFromDate);
        const sentFromDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.sentFromDate);

        const sentFromDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[sentFromDateColIndex] = {
            ...sentFromDateColumn,
            filterOperators: sentFromDateFilterOperators,
        };

        const sentToDateColumn = columns.find((column) => column.field === columnFieldsName.sentToDate);
        const sentToDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.sentToDate);

        const sentToDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[sentToDateColIndex] = {
            ...sentToDateColumn,
            filterOperators: sentToDateFilterOperators,
        };

        const deliveryStatusColumn = columns.find((column) => column.field === columnFieldsName.deliveryStatus);
        const deliveryStatusColIndex = columns.findIndex((col) => col.field === columnFieldsName.deliveryStatus);

        const deliveryStatusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EmailMessageLogStatusInputValue,
            }),
        );

        columns[deliveryStatusColIndex] = {
            ...deliveryStatusColumn,
            filterOperators: deliveryStatusFilterOperators,
        };
    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let orgName = null;
        let processName = null;
        let orgCode = null;
        let bpCode = null;
        let bpName = null;
        let entityCode = null;
        let entityName = null;
        let sentToDate = null;
        let sentFromDate = null;
        let deliveryStatus = null;

        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'processName':
                    processName = columns.value
                    break;
                case 'organization.orgName':
                    orgName = columns.value
                    break;
                case 'organization.erpOrgCode':
                    orgCode = columns.value
                    break;
                case 'entity.erpEntityCode':
                    entityCode = columns.value
                    break;
                case 'entity.entityName':
                    entityName = columns.value
                    break;
                case 'bpCode':
                    bpCode = columns.value
                    break;
                case 'bpName':
                    bpName = columns.value
                    break;
                case 'sentToDate':
                    sentToDate = columns.value
                    break;
                case 'sentFromDate':
                    sentFromDate = columns.value
                    break;
                case 'deliveryStatus':
                    deliveryStatus = columns.value
                    break;
            }
        })
        setSearchedProcess(processName);
        setSearchedEntityCode(entityCode);
        setSearchedBpCode(bpCode);
        setSearchedEntityName(entityName);
        setSearchedBpName(bpName);
        setSearchedDeliveryStatus(deliveryStatus);
        setSearchedSentFromDate(sentFromDate);
        setSearchedSentToDate(sentToDate);

        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);
        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    active: searchedStatus,
                    processName: processName,
                    bpCode: bpCode,
                    bpName: bpName,
                    organization: {
                        erpOrgCode: orgCode, orgName: orgName,
                    },
                    entity: {
                        erpEntityCode: entityCode,
                        entityName: entityName
                    },
                    sentToDate: sentToDate,
                    sentFromDate: sentFromDate,
                    deliveryStatus: deliveryStatus === 'Pending' ? 0 : deliveryStatus === 'Successfully Sent' ? 1 : deliveryStatus === 'Failed' ? 2 : deliveryStatus,
                    recordActiveStatus: deliveryStatus ? deliveryStatus : 'ALL'
                }
            }
        })
    }

    let filterBodyObj = {
        erpEntityCode: searchedEntityCode === null ? '' : searchedEntityCode,
        entityName: searchedEntityName === '' ? null : searchedEntityName,
        processName: searchedProcess === '' ? null : searchedProcess,
        bpCode: searchedBpCode === '' ? null : searchedBpCode,
        bpName: searchedBpName === '' ? null : searchedBpName,
        sentToDate: searchedSentToDate === '' ? null : searchedSentToDate,
        sentFromDate: searchedSentFromDate === '' ? null : searchedSentFromDate,
        deliveryStatus: searchedDeliveryStatus === '' ? null : searchedDeliveryStatus,
    }

    useEffect(() => {
        if (totalRowCount === 0) {
            setDisiablingDownloadButtonForZeroRecords(true)
        } else {
            setDisiablingDownloadButtonForZeroRecords(false)
        }
    }, [totalRowCount])

    useEffect(() => {
        let colValDataObj = {}
        for (let key in filterBodyObj) {
            let value = filterBodyObj[key]
            if (filterBodyObj.hasOwnProperty(key) && ((value !== null) && (value !== 0) && (value !== undefined) && (value !== {}))) {
                colValDataObj[key] = value.toString();
            }
            setKeyValueData(colValDataObj)
        }
    }, [searchedEntityCode, searchedEntityName, searchedProcess, searchedBpCode, searchedBpName, searchedDeliveryStatus, searchedSentToDate, searchedSentFromDate]);

    useEffect(() => {
        if (reportDownloadPopUp === true) {
            setOpenBackDrop(true);
            CommonService.saveOrUpdateOrPost('/reports/SYS_EMAIL_MSG_LOG', searchedEntityCode === '' ?
                { erpEntityCode: '' } : keyValueData)
                .then((response) => {
                    if (response.data.sucess === "No records found for given condition")
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.sucess, severity: "error" });
                    else {
                        setAlertBoxState({
                            ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success"
                        });
                    }
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setReportDownloadPopUp(false)
        }
    }, [reportDownloadPopUp, keyValueData]);

    useEffect(() => {
        if (intialParamBodyApiCall)
            setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <ChipInfoList chipInfoList={chipInfoList} />
                <Paper elevation={3} className={classes.gridHeightAndWidthForRole}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            disableColumnFilter={true}
                            density="compact"
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableColumnMenu={false}
                            disableMultipleColumnsSorting={true}
                            hideFooter={true}
                            localeText={localtext}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip"
                                                        defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'>
                                                        <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterList />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadExcelForEmailMsgLogs" />}
                                                        placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={() => {
                                                                    setReportDownloadPopUp(true)
                                                                }}
                                                                disabled={disiablingDownloadButtonForZeroRecords}
                                                            >
                                                                <GetAppDownloadIcon size={20} />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </div>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}

export default EmailMessageLog;