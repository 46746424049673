import { Accordion, AccordionDetails, AccordionSummary, Badge, Button, CircularProgress, CssBaseline, FormControl, Grid, IconButton, Paper, Snackbar, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Alert } from '@mui/lab';
import { getGridNumericColumnOperators, getGridStringOperators, GridFilterPanel, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, LicenseInfo, DataGridPro, getGridDateOperators } from '@mui/x-data-grid-pro';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';
import { ModuleAccessPermissionKey } from '../../Constants/ModuleAccessKey';
import useStyles from '../common/CommonStyle/CommonStyle';
import CustomBreadCrumb from '../common/CustomBreadCrumb';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import { SupportedLanguageDetails } from '../common/SupportedLanguageDetails/SupportedLanguageDetails';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import IconApproval from '@mui/icons-material/LibraryAddCheck';
import ProcessListCombo from '../common/Combos/ProcessListCombo/ProcessListCombo';
import { Controller, set, useForm } from 'react-hook-form';
import ChipInfoList from '../common/ChipInfoList';
import { CommonService } from '../../services';
import CommonGridPagination from '../common/CommonGridPagination/CommonGridPagination';
import RefreshIcon from '@mui/icons-material/Refresh';
import _ from "lodash/fp";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { CustomFilters } from '../common/CustomFilters/CustomFilters';
import saveAs from 'file-saver';
import { useHistory } from 'react-router-dom';
import ExcelIcon from '../PoVmiApproval/ExcelIcon.svg'
import ResendIcon from '@mui/icons-material/Send';
import GetAppIcon from '@mui/icons-material/GetApp';
import CommonBackDrop from '../common/BackDrop';
import CancelIcon from '@mui/icons-material/Cancel';
import { FilterList } from '@mui/icons-material';
import CustomFilterComponent, { GetFilterableColumns } from '../common/CustomFilterComponent';
import { manageUserPreferences } from '../../services/manageUserPreferences';

const columnFieldsName = {
    poNumber: 'artifactId',
    bpCode: 'bpCode',
    generatedDate: 'createdDate',
    approved: 'approved',
    approvedBy: 'approvedBy',
    approvedDate: 'approvedDate',
    intfId: 'intfId',
    generatedFromDt: 'generatedFromDt',
    generatedToDt: 'generatedToDt',
    appremailSent: 'apprEmailSent',
    bpName: 'bpName',
    contactDetails: 'contactDetails',
    projectCode: 'projectCode',
    entityName: 'entity.entityName',
    msgStatusFlag: 'msgStatusFlag',
    emailTo: 'bpEmailMessageConfig.emailTo',
    msgStatusRemarks: 'msgStatusRemarks',
    erpEntityCode: 'entity.erpEntityCode',
    purchasingGroup: 'purchasingGroup',
    createdTime: 'updatedTime',
    emailSentDate: 'emailSentDate',
    purchasingName: 'purchasingName',
    rejectionFlag: 'rejectionFlag',
    resendRequestedBy: 'resendRequestedBy',
    resendRequestedTime: 'resendRequestedTime'
}

const PoVmiApproval = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();
    const theme = useTheme();
    const { procesListValue, setChipInfoList, tabDetails, isBPVmi } = props;
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const poVmiApprovalModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.poVmiApprovalModuleAccess];
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    const pageLabelsConstantsAndMessages = {
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
    }

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [refreshTable, setRefreshTable] = useState(false);
    const [searchedGeneratedFromDt, setSearchedGeneratedFromDt] = useState(null);
    const [searchedGeneratedToDt, setSearchedGeneratedToDt] = useState(null);
    const [selectedRow, setSelectedRow] = React.useState([]);
    const [disableApproveButton, isDisableApproveButton] = useState(true);
    const [disableResendButton, isDisableResendButton] = useState(true);
    const [disableRejectionButton, isDisableRejectionButton] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isRowSentForApproval, setIsRowSentForApproval] = useState(false);
    const [isRowSentForRejection, setIsRowSentForRejection] = useState(false);
    const [isRowSentForResend, setIsRowSentForResend] = useState(false);
    const [searchedApprovedRecords, setSearchedApprovedRecords] = useState('');
    const [searchedEmailStatusRecords, setSearchedEmailStatusRecords] = useState(true);
    const [searchedBpCode, setSearchedBpCode] = useState('');
    const [searchedApprovedBy, setSearchedApprovedBy] = useState('');
    const [searchedRemarks, setSearchedRemarks] = useState('');
    const [searchedPoNo, setSearchedPoNo] = useState('');
    const [msgHdrPopUpForPoPdf, setMsgHdrPopUpForPoPdf] = useState(false);
    const [msgHdrPopUpForVmi, setMsgHdrPopUpForVmi] = useState(false);
    const [disiablingDownloadButtonForZeroRecords, setDisiablingDownloadButtonForZeroRecords] = useState(false);
    const [keyValueData, setKeyValueData] = useState('')
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [searchedProjectCode, setSearchedProjectCode] = useState('');
    var defaultEntity = loggedInUserInfo.defaultEntity;
    const [searchedErpEntityCode, setSearchedErpEntityCode] = useState(defaultEntity);
    const [searchedBpName, setSearchedBpName] = useState('');
    const [searchedEmailStatus, setSearchedEmailStatus] = useState('');
    const [searchedPurchasingName, setSearchedPurchasingName] = useState('');
    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);

    const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.erpEntityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity }]
        : [];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);

    const [isIntialDefaultEntityFilter, setIsIntialDefaultEntityFilter] = useState(defaultEntity !== '' ? true : false);

    const { handleSubmit, formState: { errors }, reset, control, setValue } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: {
            process: '',
            createdDate: '',
            generatedFromDt: '',
            generatedToDt: '',
            approved: ''
        },
    });

    const [paramBody, setParamBody] = useState({
        body: {
            entity: {
                erpEntityCode: '',
            },
            organization: {
                erpOrgCode: '',
            },
            intfId: '',
            bpCode: '',
            filePath: '',
            artifactId: '',
            approvedBy: '',
            process: procesListValue,
            createdDate: '',
            approved: '',
            apprEmailSent: true,
            processed: '',
            recordApprovedStatus: 'All',
            recordApprSentStatus: 'All',
            recordEmailSentDate: 'All',
            recordErrorStatus: 'All',
        },
        pageNo: 0,
        sortDir: 'desc',
        sortKey: 'createdDate',
        recordsPerPage: 25,
    });

    const insert = (arr, index, newItems) => [
        ...arr.slice(0, index),
        newItems,
        ...arr.slice(index)
    ]

    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState({
        left: ["__check__"],
        right: []
    });
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);
    const disableAuthorizationBasedOnTheRoleAccess = poVmiApprovalModuleAccess !== undefined ? poVmiApprovalModuleAccess.createFlag || poVmiApprovalModuleAccess.editFlag : false;

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }


    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(isBPVmi ? tabDetails[0].name.props.id : tabDetails[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: isBPVmi ? tabDetails[0].name.props.id : tabDetails[1].name.props.id,
                tabName: isBPVmi ? tabDetails[0].name.props.id : tabDetails[2].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);

    const commonColumnForPoVmi = [
        {
            field: 'file',
            headerName: intl.formatMessage({ id: "columnname.file.label" }),
            width: procesListValue === 'PO PDF' ? 130 : 100,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (

                <div>
                    {(cellParams.row.downloadFilePath !== null && procesListValue === 'PO PDF') &&
                        (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.pdf" defaultMessage="pdf" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                showPdf(cellParams.row.downloadFilePath)
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <PictureAsPdfIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {(cellParams.row.downloadCsvFilePath !== null && procesListValue === 'PO PDF') &&
                        (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadCSV" defaultMessage="downloadExcel" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                showDownloadExcel(cellParams.row.downloadCsvFilePath)
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <img icon src={ExcelIcon} className={classes.ExcelIconPO} alt='ExcelIcon' color="primary" size='10' />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {(cellParams.row.downloadFilePath !== null && procesListValue === 'VMI Consumption Report') && (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadExcel" defaultMessage="downloadExcel" />}
                        placement='bottom' className={classes.actionButton} onClick={(() => {
                            showDownloadPopUp(cellParams.row.downloadFilePath)
                        })}>
                        <span>
                            <IconButton className={classes.customeToolbar} size="large">
                                <img icon src={ExcelIcon} className={classes.ExcelIconPO} alt='ExcelIcon' color="primary" size='10' />
                            </IconButton>
                        </span>
                    </Tooltip>)}
                </div>
            )
        },
        {
            field: columnFieldsName.entityName,
            headerName: intl.formatMessage({ id: "common.EntityName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 190,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            valueGetter: (params) => `${params.row.entity.entityName}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.erpEntityCode,
            headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 85,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: true,
            valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.intfId,
            width: 140,
            headerName: intl.formatMessage({ id: "columnName.intfid.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.projectCode,
            width: 150,
            headerName: intl.formatMessage({ id: "common.textfield.column.project" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            filterable: procesListValue !== 'VMI Consumption Report' ? true : false,
            hide: procesListValue !== 'VMI Consumption Report' ? false : true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.bpCode,
            width: 130,
            headerName: intl.formatMessage({ id: "common.textfield.columname.bpcode" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.bpName,
            headerName: intl.formatMessage({ id: "columnName.bpname.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.purchasingGroup,
            headerName: intl.formatMessage({ id: "textfield.purchasingGroup.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 130,
            sortable: true,
            type: 'string',
            hide: procesListValue !== 'VMI Consumption Report' ? false : true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.purchasingName,
            headerName: intl.formatMessage({ id: "textfield.purchasingName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 135,
            sortable: false,
            type: 'string',
            hide: procesListValue !== 'VMI Consumption Report' ? false : true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.approved,
            width: 150,
            headerName: intl.formatMessage({ id: "columnName.approved.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.ApproveFailureChipsForPO
        },
        {
            field: columnFieldsName.msgStatusFlag,
            headerName: intl.formatMessage({ id: 'columnname.emailstatus.label' }),
            width: 155,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'string',
            filterable: true,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.SuccessFailureChipsForPoVmi
        },
        {
            field: columnFieldsName.contactDetails,
            headerName: intl.formatMessage({ id: "columnName.contactDetails.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.emailTo,
            headerName: intl.formatMessage({ id: "textfield.emailto.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            filterable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: ((cellParams) => {
                if (cellParams.row.bpEmailTO === null) {
                    return ''
                } else {
                    return cellParams.row.bpEmailTO
                }
            }),
        },
        {
            field: columnFieldsName.generatedDate,
            width: 200,
            headerName: intl.formatMessage({ id: "columnname.columnnamegeneratedDate.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <span>{cellParams.row.createdDtDisp}</span>
            )
        },
        {
            field: columnFieldsName.appremailSent,
            width: 170,
            headerName: intl.formatMessage({ id: "columnName.emailSent.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.customRenderYesOrNoColumn
        },

        {
            field: columnFieldsName.approvedBy,
            width: 160,
            headerName: intl.formatMessage({ id: "columnName.approvedBy.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.approvedDate,
            width: 165,
            headerName: intl.formatMessage({ id: "columnName.approvedDate.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <span>{cellParams.row.approvedDtDisp}</span>
            )
        },
        {
            field: columnFieldsName.msgStatusRemarks,
            headerName: intl.formatMessage({ id: "columnname.remarks.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 500,
            sortable: true,
            type: 'string',
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.emailSentDate,
            width: 175,
            headerName: intl.formatMessage({ id: "columnName.emailSentDate.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <span>{cellParams.row.emailSentDtDisp}</span>
            )
        },
        {
            field: columnFieldsName.generatedFromDt,
            width: 0,
            headerName: intl.formatMessage({ id: "textfield.fromdate.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'string',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: 'generatedToDt',
            width: 0,
            headerName: intl.formatMessage({ id: "textfield.todate.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'string',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.rejectionFlag,
            headerName: intl.formatMessage({ id: "common.rejectionRemark.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 190,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            valueGetter: (params) => `${params.row.rejectionRemark}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.resendRequestedBy,
            headerName: intl.formatMessage({ id: "common.resendrequestedby.label" }),
            width: 190,
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.resendRequestedTime,
            headerName: intl.formatMessage({ id: "common.resendrequestedtime.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <span>{cellParams.row.resendRequestedDtDisp}</span>
            )
        }
    ];

    const columnForPoNumber = {
        field: columnFieldsName.poNumber,
        width: 130,
        headerName: intl.formatMessage({ id: "columnname.pono.label" }),
        sortable: true,
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
        type: 'string',
        hide: false,
        filterable: true,
        renderHeader: CommonService.customRenderHeaderColumns
    };


    function constructColumnsToBeDisplayed() {
        if (procesListValue === 'PO PDF') {
            const commonColumnList = insert(commonColumnForPoVmi, 3, columnForPoNumber)
            return commonColumnList
        }
    }

    const [columns, setColumns] = useState([]);
    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        var createColumnsForDifferentScreens = columns;
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: isBPVmi ? tabDetails[0].name.props.id : tabDetails[1].name.props.id,
                tabName: isBPVmi ? tabDetails[0].name.props.id : tabDetails[2].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                createColumnsForDifferentScreens = procesListValue === 'PO PDF' ? constructColumnsToBeDisplayed() : commonColumnForPoVmi;
                setColumns(createColumnsForDifferentScreens);
                savePreference("visibility", createColumnsForDifferentScreens);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                setParamBody({ ...paramBody });

            }
            //preference present in db
            else {
                createColumnsForDifferentScreens = procesListValue === 'PO PDF' ? constructColumnsToBeDisplayed() : commonColumnForPoVmi;
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, createColumnsForDifferentScreens);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let bpName = null;
                let bpCode = null;
                let entityCode = null;
                let entityName = null;
                let msgStatusFlag = null;
                let artifactId = null;
                let projectCode = null;
                let msgStatusRemarks = null;
                let approvedBy = null;
                let approved = null;
                let purchasingName = null;
                let generatedFromDt = null;
                let generatedToDt = null;
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'bpName':
                            bpName = columns.value
                            break;
                        case 'msgStatusRemarks':
                            msgStatusRemarks = columns.value
                            break;
                        case 'artifactId':
                            artifactId = columns.value
                            break;
                        case 'projectCode':
                            projectCode = columns.value
                            break;
                        case 'msgStatusFlag':
                            msgStatusFlag = columns.value
                            break;
                        case 'bpCode':
                            bpCode = columns.value
                            break;
                        case 'approvedBy':
                            approvedBy = columns.value
                            break;
                        case 'approved':
                            approved = columns.value
                            break;
                        case 'purchasingName':
                            purchasingName = columns.value
                            break;
                        case 'generatedFromDt':
                            generatedFromDt = columns.value
                            break;
                        case 'generatedToDt':
                            generatedToDt = columns.value
                            break;
                        case 'entity.erpEntityCode':
                            entityCode = columns.value
                            break;
                    }
                })

                setSearchedApprovedRecords(approved);
                setSearchedBpCode(bpCode);
                setSearchedApprovedBy(approvedBy);
                setSearchedRemarks(msgStatusRemarks);
                setSearchedPoNo(artifactId);
                setSearchedProjectCode(projectCode);
                setSearchedErpEntityCode(entityCode);
                setSearchedBpName(bpName);
                setSearchedEmailStatus(msgStatusFlag);
                setSearchedGeneratedFromDt(generatedFromDt);
                setSearchedGeneratedToDt(generatedToDt);
                setSearchedPurchasingName(purchasingName);
                var filterBody = {
                    entity: {
                        erpEntityCode: entityCode,
                    },
                    process: procesListValue,
                    msgStatusRemarks: msgStatusRemarks,
                    bpCode: bpCode,
                    bpName: bpName,
                    artifactId: artifactId,
                    projectCode: projectCode,
                    msgStatusFlag: msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : msgStatusFlag === 'Duplicate' ? 5 : '',
                    createdDate: '',
                    approvedBy: approvedBy,
                    approved: approved === '' ? 'All' : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : '',
                    apprEmailSent: true,
                    recordApprovedStatus: approved === '' ? 'All' : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : 'All',
                    recordApprSentStatus: '',
                    recordEmailSentDate: searchedEmailStatusRecords === 'EMAIL SENT' ? true : searchedEmailStatusRecords === 'EMAIL NOT SENT' ? false : '',
                    recordErrorStatus: msgStatusFlag === '' ? 'All' : msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : msgStatusFlag === 'Duplicate' ? 5 : 'All',
                    generatedFromDt: generatedFromDt,
                    generatedToDt: generatedToDt,
                    purchasingName: purchasingName
                };
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0)
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [selectedLanguage, procesListValue])

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.fileAttachmentDtls, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [paramBody, refreshTable])


    const approveAndAttachId = tableData.map((row) => { return { approved: row.approved, attachId: row.attachId, msgStatusFlag: row.msgStatusFlag } })

    const filterValueLoad = approveAndAttachId.filter((item) => {
        if (selectedRow.includes(item.attachId)) {
            return item
        }
    })

    const filterValueApproved = filterValueLoad.map((row) => { return row.approved })
    const filterValueStatusFlag = filterValueLoad.map((row) => { return row.msgStatusFlag })

    function currentlySelectedRow(selections) {
        setSelectedRow(selections)
        if (selections.length === 0) {
            isDisableApproveButton(true)
            isDisableResendButton(true);
            isDisableRejectionButton(true);
        }
    }

    useEffect(() => {
        if (selectedRow.length === 0) {
            isDisableApproveButton(true)
            isDisableResendButton(true);
            isDisableRejectionButton(true);
        } else if (filterValueApproved.toString() === '0') {
            isDisableApproveButton(false);
            isDisableRejectionButton(false);
            isDisableResendButton(true);
        } else if (filterValueApproved.toString() === '1') {
            isDisableApproveButton(true);
            isDisableResendButton(false);
            isDisableRejectionButton(filterValueStatusFlag.toString() === '3' ? false : true);
        } else if (filterValueApproved.toString() === '2') {
            isDisableApproveButton(false);
            isDisableResendButton(true);
            isDisableRejectionButton(true);
        } else {
            isDisableApproveButton(false);
            isDisableResendButton(false);
            isDisableRejectionButton(false);
        }
    }, [filterValueApproved])


    const onSubmit = () => {
        setIsFormSubmitted(true);
        setIsRowSentForApproval(true)
        if (filterValueApproved.toString().includes('1')) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains already Approved records", severity: "warning"
            });
            setIsRowSentForApproval(false);
            setIsFormSubmitted(false);
        // } else if (filterValueApproved.toString().includes('2')) {
        //     setAlertBoxState({
        //         ...alertBoxState, openAlert: true, message: "Selected Records contains already Rejected records", severity: "warning"
        //     });
        //     setIsRowSentForApproval(false);
        //     setIsFormSubmitted(false);
        // }
        } else {
            CommonService.saveOrUpdateOrPost('/poVmiApprovals', selectedRow)
                .then((response) => {
                    setIsFormSubmitted(false)
                    setIsRowSentForApproval(false)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: `MessageId : ${selectedRow} has been Approved successfully`, severity: "success"
                    });
                    setSelectedRow([])
                    setRefreshTable(!refreshTable);
                    isDisableApproveButton(true);
                    isDisableResendButton(true);
                    isDisableRejectionButton(true);
                })
                .catch(function (error) {
                    setIsFormSubmitted(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: 'Error Occured', severity: "error" });
                    isDisableApproveButton(false)
                    isDisableResendButton(false);
                    isDisableRejectionButton(false);
                });
        }
    }

    const onSubmitReject = (data) => {
        setIsFormSubmitted(true);
        setIsRowSentForRejection(true);
        setOpenBackDrop(true);
        if (filterValueApproved.toString().includes('2')) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains already Rejected records", severity: "warning"
            });
            setIsRowSentForRejection(false);
            setIsFormSubmitted(false);
            setOpenBackDrop(false);
        } else if (filterValueApproved.toString().includes('1') && filterValueStatusFlag.toString().includes('2') || (filterValueApproved.toString().includes('1') && filterValueStatusFlag.toString().includes('2')) || (filterValueApproved.toString().includes('1') && filterValueStatusFlag.toString().includes('5'))) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains already Approved records", severity: "warning"
            });
            setIsRowSentForRejection(false);
            setIsFormSubmitted(false);
            setOpenBackDrop(false);
        } else {
            CommonService.saveOrUpdateOrPost('/poVmiRejections', selectedRow)
                .then((response) => {
                    setIsFormSubmitted(false);
                    setIsRowSentForRejection(false);
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: `MessageId : ${selectedRow} has been rejected successfully`, severity: "success"
                    });
                    setSelectedRow([])
                    setRefreshTable(!refreshTable);
                    isDisableApproveButton(true);
                    isDisableResendButton(true);
                    isDisableRejectionButton(true);
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setIsFormSubmitted(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.message, severity: "error" });
                    isDisableApproveButton(false);
                    isDisableResendButton(false);
                    isDisableRejectionButton(false);
                    setOpenBackDrop(false);
                });
        }
    }

    const onSubmitForResend = () => {
        setIsFormSubmitted(true);
        setIsRowSentForResend(true)
        if (filterValueApproved.toString().includes('0')) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains Un Approved records", severity: "warning"
            });
            setIsFormSubmitted(false);
            setIsRowSentForResend(false);
        } else if (filterValueApproved.toString().includes('2')) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains Rejected records", severity: "warning"
            });
            setIsFormSubmitted(false);
            setIsRowSentForResend(false);
        } else {
            CommonService.saveOrUpdateOrPost('/resendFileAttachments', selectedRow)
                .then((response) => {
                    setIsFormSubmitted(false)
                    setIsRowSentForResend(false)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: "Message Resent Successfully", severity: "success"
                    });
                    setSelectedRow([])
                    setRefreshTable(!refreshTable);
                    isDisableResendButton(true);
                    isDisableRejectionButton(true);
                    isDisableApproveButton(true);
                })
                .catch(function (error) {
                    setIsFormSubmitted(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: 'Error Occured', severity: "error" });
                    isDisableResendButton(false)
                    isDisableRejectionButton(false);
                    isDisableApproveButton(false)
                });
        }
    }

    if (columns.length > 0) {
        const approvedColumn = columns.find((column) => column.field === columnFieldsName.approved);
        const approvedColIndex = columns.findIndex((col) => col.field === columnFieldsName.approved);

        const approvedFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.ApprovedPOInputValue,
            }),
        );

        columns[approvedColIndex] = {
            ...approvedColumn,
            filterOperators: approvedFilterOperators,
        };

        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };

        const emailStatusColumn = columns.find((column) => column.field === columnFieldsName.msgStatusFlag);
        const emailStatusColIndex = columns.findIndex((col) => col.field === columnFieldsName.msgStatusFlag);

        const emailStatusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EmailStatusForPoVmiInputValue,
            }),
        );

        columns[emailStatusColIndex] = {
            ...emailStatusColumn,
            filterOperators: emailStatusFilterOperators,
        };

        const generatedFromDtColumn = columns.find((column) => column.field === columnFieldsName.generatedFromDt);
        const generatedFromDtColIndex = columns.findIndex((col) => col.field === columnFieldsName.generatedFromDt);

        const generatedFromDtFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[generatedFromDtColIndex] = {
            ...generatedFromDtColumn,
            filterOperators: generatedFromDtFilterOperators,
        };

        const generatedToDtColumn = columns.find((column) => column.field === columnFieldsName.generatedToDt);
        const generatedToDtColIndex = columns.findIndex((col) => col.field === columnFieldsName.generatedToDt);

        const generatedToDtFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[generatedToDtColIndex] = {
            ...generatedToDtColumn,
            filterOperators: generatedToDtFilterOperators,
        };

        const purchaserNameColumn = columns.find((column) => column.field === columnFieldsName.purchasingName);
        const purchaserNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.purchasingName);

        const purchaserNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.UserNameInputValue,
            }),
        );

        columns[purchaserNameColIndex] = {
            ...purchaserNameColumn,
            filterOperators: purchaserNameFilterOperators,
        };

        const supplierNameColumn = columns.find((column) => column.field === columnFieldsName.bpName);
        const supplierNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.bpName);

        const supplierNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.SupplierVendorNameInputValue,
            }),
        );

        columns[supplierNameColIndex] = {
            ...supplierNameColumn,
            filterOperators: supplierNameFilterOperators,
        };
    }

    const showDownloadPopUp = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }

    const showDownloadExcel = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }

    const showPdf = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
                var url = URL.createObjectURL(blob);
                window.open(url, fileName)
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }

    useEffect(() => {
        if (totalRowCount === 0) {
            setDisiablingDownloadButtonForZeroRecords(true)
        } else {
            setDisiablingDownloadButtonForZeroRecords(false)
        }
    }, [totalRowCount])

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let bpName = null;
        let bpCode = null;
        let entityCode = null;
        let entityName = null;
        let msgStatusFlag = null;
        let artifactId = null;
        let projectCode = null;
        let msgStatusRemarks = null;
        let approvedBy = null;
        let approved = null;
        let purchasingName = null;
        let generatedFromDt = null;
        let generatedToDt = null;
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'bpName':
                    bpName = columns.value
                    break;
                case 'msgStatusRemarks':
                    msgStatusRemarks = columns.value
                    break;
                case 'artifactId':
                    artifactId = columns.value
                    break;
                case 'projectCode':
                    projectCode = columns.value
                    break;
                case 'msgStatusFlag':
                    msgStatusFlag = columns.value
                    break;
                case 'bpCode':
                    bpCode = columns.value
                    break;
                case 'approvedBy':
                    approvedBy = columns.value
                    break;
                case 'approved':
                    approved = columns.value
                    break;
                case 'purchasingName':
                    purchasingName = columns.value
                    break;
                case 'generatedFromDt':
                    generatedFromDt = columns.value
                    break;
                case 'generatedToDt':
                    generatedToDt = columns.value
                    break;
                case 'entity.erpEntityCode':
                    entityCode = columns.value
                    break;
            }
        })
        setSearchedApprovedRecords(approved);
        setSearchedBpCode(bpCode);
        setSearchedApprovedBy(approvedBy);
        setSearchedRemarks(msgStatusRemarks);
        setSearchedPoNo(artifactId);
        setSearchedProjectCode(projectCode);
        setSearchedErpEntityCode(entityCode);
        setSearchedBpName(bpName);
        setSearchedEmailStatus(msgStatusFlag);
        setSearchedGeneratedFromDt(generatedFromDt);
        setSearchedGeneratedToDt(generatedToDt);
        setSearchedPurchasingName(purchasingName);

        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);

        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    entity: {
                        erpEntityCode: entityCode,
                    },
                    process: procesListValue,
                    msgStatusRemarks: msgStatusRemarks,
                    bpCode: bpCode,
                    bpName: bpName,
                    artifactId: artifactId,
                    projectCode: projectCode,
                    msgStatusFlag: msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : msgStatusFlag === 'Duplicate' ? 5 : '',
                    createdDate: '',
                    approvedBy: approvedBy,
                    approved: approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : '',
                    apprEmailSent: true,
                    recordApprovedStatus: approved === '' ? 'All' : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : 'All',
                    recordApprSentStatus: '',
                    recordEmailSentDate: searchedEmailStatusRecords === 'EMAIL SENT' ? true : searchedEmailStatusRecords === 'EMAIL NOT SENT' ? false : '',
                    recordErrorStatus: msgStatusFlag === '' ? 'All' : msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : msgStatusFlag === 'Duplicate' ? 5 : 'All',
                    generatedFromDt: generatedFromDt,
                    generatedToDt: generatedToDt,
                    purchasingName: purchasingName
                }


            }
        })
    }

    useEffect(() => {
        if (intialParamBodyApiCall)
            setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    let filterBodyObj = {
        process: procesListValue === 'PO PDF' ? 'PO PDF' : 'VMI Consumption Report',
        erpEntityCode: searchedErpEntityCode === null ? "" : searchedErpEntityCode,
        bpCode: searchedBpCode === "" ? null : searchedBpCode,
        approved: searchedApprovedRecords === 'Not Approved' ? 'NO' : searchedApprovedRecords === 'Approved' ? 'YES' : searchedApprovedRecords === 'Rejected' ? 'REJECTED' : null,
        bpName: searchedBpName === "" ? null : searchedBpName,
        artifactId: searchedPoNo === "" ? null : searchedPoNo,
        projectCode: searchedProjectCode === "" ? null : searchedProjectCode,
        approvedBy: searchedApprovedBy === "" ? null : searchedApprovedBy,
        msgStatusRemarks: searchedRemarks === "" ? null : searchedRemarks,
        msgStatusFlag: searchedEmailStatus === "" ? null : searchedEmailStatus,
        generatedFromDt: searchedGeneratedFromDt !== null ? searchedGeneratedFromDt : null,
        generatedToDt: searchedGeneratedToDt !== null ? searchedGeneratedToDt : null,
    }

    useEffect(() => {
        let colValDataObj = {}
        for (let key in filterBodyObj) {
            let value = filterBodyObj[key]
            if (filterBodyObj.hasOwnProperty(key) && ((value !== null) && (value !== undefined) && (value !== {}))) {
                colValDataObj[key] = value.toString();
            }
            setKeyValueData(colValDataObj)
        }
    }, [searchedErpEntityCode, searchedBpCode, searchedApprovedBy, searchedBpName, searchedPoNo, searchedProjectCode, searchedApprovedRecords, searchedRemarks,
        searchedEmailStatus, searchedGeneratedToDt, searchedGeneratedFromDt]);

    useEffect(() => {
        if (msgHdrPopUpForPoPdf === true) {
            setOpenBackDrop(true);
            CommonService.saveOrUpdateOrPost('/reports/PO_PDF_REPORT', searchedErpEntityCode === '' ? { erpEntityCode: '', process: procesListValue } : keyValueData)
                .then((response) => {
                    //showDownloadPopUpExcelHdrForPDF(response.data.fileUrl)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success"
                    });
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgHdrPopUpForPoPdf(false)
        }
    }, [msgHdrPopUpForPoPdf, keyValueData]);

    const showDownloadPopUpExcelHdrForPDF = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }

    useEffect(() => {
        if (msgHdrPopUpForVmi === true) {
            setOpenBackDrop(true);
            CommonService.saveOrUpdateOrPost('/reports/VMI_REPORT', searchedErpEntityCode === '' ? { erpEntityCode: '', process: procesListValue } : keyValueData)
                .then((response) => {
                    // showDownloadPopUpExcelHdrForVmi(response.data.fileUrl)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success"
                    });
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgHdrPopUpForVmi(false)
        }
    }, [msgHdrPopUpForVmi, keyValueData]);

    const showDownloadPopUpExcelHdrForVmi = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForOrder}>
                <Grid item container xs={12} sm={12} md={12} lg={12}>
                    <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                        setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                        defaulSelectedFilters={defaulSelectedFilters} />
                    <Paper elevation={3} className={classes.gridHeightAndWidthForPoVmi}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <DataGridPro
                                className={classes.customTableStyle}
                                sortingMode="server"
                                rows={tableData}
                                columns={columns}
                                pageSize={paramBody.recordsPerPage}
                                loading={tableLoadingStatus}
                                rowCount={totalRowCount}
                                scrollbarSize={30}
                                disableColumnFilter={true}
                                rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                                pagination
                                density="compact"
                                paginationMode="server"
                                disableSelectionOnClick={true}
                                disableColumnMenu={false}
                                disableMultipleColumnsSorting={true}
                                hideFooter={true}
                                isRowSelectable={(params) => (((params.row.approved === 1) && (params.row.msgStatusFlag === 0 || params.row.msgStatusFlag === 5 || params.row.msgStatusFlag === 1)) || ((params.row.approved === 0) && (params.row.msgStatusFlag === 5)) ||  ((params.row.approved === 2) && (params.row.msgStatusFlag === 4) && (params.row.rejectionFlag === 1 || params.row.rejectionFlag === 2 || params.row.rejectionFlag === 3 || params.row.rejectionFlag === 4 || params.row.rejectionFlag === 5 || params.row.rejectionFlag === 11))) ? false : true}
                                checkboxSelection
                                onSelectionModelChange={currentlySelectedRow}
                                selectionModel={selectedRow}
                                localeText={localtext}
                                onPageSizeChange={((pageParams) => {
                                    setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                                })}
                                onPageChange={((pageParams) => {
                                    setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                                })}
                                pinnedColumns={pinnedColumns}
                                onPinnedColumnsChange={((params) => {
                                    var OrderedColumns = columns;
                                    if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                        var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                        var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                        var reOrderedColumns = OrderedColumns.splice(index, 1);
                                        OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                    }
                                    else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                        var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                        var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                        var reOrderedColumns = OrderedColumns.splice(index, 1);
                                        OrderedColumns.splice(pinnedColumns.left.length - 1, 0, reOrderedColumns[0]);
                                    }
                                    else if (pinnedColumns.left.length < params.left.length) {
                                        var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                        var reOrderedColumns = OrderedColumns.splice(index, 1);
                                        OrderedColumns.splice(pinnedColumns.left.length - 1, 0, reOrderedColumns[0]);
                                    }
                                    else if (pinnedColumns.right.length < params.right.length) {
                                        var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                        var reOrderedColumns = OrderedColumns.splice(index, 1);
                                        OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                    }
                                    else if (pinnedColumns.right.length > params.right.length) {
                                        var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                        var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                        var reOrderedColumns = OrderedColumns.splice(index, 1);
                                        OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                    }
                                    else if (pinnedColumns.left.length > params.left.length) {
                                        var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                        var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                        var reOrderedColumns = OrderedColumns.splice(index, 1);
                                        OrderedColumns.splice(params.left.length - 1, 0, reOrderedColumns[0]);
                                    }
                                    savePreference("pinned", {
                                        left: params.left,
                                        right: params.right
                                    }, OrderedColumns);

                                })}
                                onColumnVisibilityChange={((params) => {
                                    if (params.field !== '__check__') {
                                        var visibleColumns = columns;
                                        var index = visibleColumns.findIndex(element => element.field === params.field);
                                        visibleColumns[index].hide = !params.isVisible;
                                        savePreference("visibility", visibleColumns);
                                    }
                                })}
                                onColumnOrderChange={((params) => {
                                    --params.oldIndex;
                                    --params.targetIndex;
                                    var OrderedColumns = columns;
                                    var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                    OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                    savePreference("order", OrderedColumns);
                                })}
                                onSortModelChange={(params) => {

                                    let sortModel = params[0];
                                    var previousParamBody = paramBody;
                                    if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                        setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                        savePreference("sort", params[0]);
                                    } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                        setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                        savePreference("sort", { field: null, sort: null });
                                    }

                                }}
                                sortModel={[{
                                    field: paramBody.sortKey,
                                    sort: paramBody.sortDir,
                                }]}
                                sortingOrder={['desc', 'asc']}
                                components={{
                                    Toolbar: () => {
                                        return (
                                            <Grid container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                                <Grid
                                                    item
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}>
                                                    <Grid>
                                                        <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" />} placement='bottom'>
                                                            <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium"
                                                            />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid>
                                                        <Tooltip title={<FormattedMessage id="common.density.tooltip" />}
                                                            placement='bottom'>
                                                            <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid>
                                                        <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                            <span>
                                                                <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                    <Badge color="primary" badgeContent={filterCount}>
                                                                        <FilterList />
                                                                    </Badge>
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    </Grid>
                                                   <Grid>
                                                        <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                            <span>
                                                                <Button size="small" color="primary" variant="outlined"
                                                                    className={classes.refreshToobarStyle}
                                                                    onClick={(() => {
                                                                        setRefreshTable(!refreshTable);
                                                                        setSelectedRow([])
                                                                        isDisableApproveButton(true);
                                                                        isDisableResendButton(true);
                                                                        isDisableRejectionButton(true);
                                                                    })}>
                                                                    <RefreshIcon />
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    </Grid>
                                                    {!isBPVmi && <Grid>
                                                        <Tooltip title={intl.formatMessage({ id: "common.button.approveselectedrecords.tooltip" })} placement='bottom'>
                                                            <span>
                                                                {disableAuthorizationBasedOnTheRoleAccess&& (
                                                                <Button size="small" color="primary" variant="outlined"
                                                                    className={classes.refreshToobarStyle}
                                                                    onClick={onSubmit}
                                                                    disabled={disableApproveButton || isFormSubmitted}
                                                                >
                                                                    {isRowSentForApproval ? <CircularProgress color="secondary" size={20} /> : <IconApproval />}
                                                                </Button>)}
                                                            </span>
                                                        </Tooltip>
                                                    </Grid>}
                                                    {!isBPVmi && <Grid>
                                                        <Tooltip title={intl.formatMessage({ id: "common.button.rejectselectedrecords.tooltip" })} placement='bottom'>
                                                            <span>
                                                                 {disableAuthorizationBasedOnTheRoleAccess&& (
                                                                <Button size="small" color="primary" variant="outlined"
                                                                    className={classes.refreshToobarStyle}
                                                                    onClick={onSubmitReject}
                                                                    disabled={disableRejectionButton || isFormSubmitted}
                                                                >
                                                                    {isRowSentForRejection ? <CircularProgress color="secondary" size={20} /> : <CancelIcon />}
                                                                </Button>)}
                                                            </span>
                                                        </Tooltip>
                                                    </Grid>}
                                                    {!isBPVmi && <Grid>
                                                        <Tooltip title={intl.formatMessage({ id: "common.button.resendselectedrecords.tooltip" })} placement='bottom'>
                                                            <span>
                                                                 {disableAuthorizationBasedOnTheRoleAccess&& (
                                                                <Button size="small" color="primary" variant="outlined"
                                                                    className={classes.refreshToobarStyle}
                                                                    onClick={onSubmitForResend}
                                                                    disabled={disableResendButton || isFormSubmitted}
                                                                >
                                                                    {isRowSentForResend ? <CircularProgress color="secondary" size={20} /> : <ResendIcon />}
                                                                </Button>)}
                                                            </span>
                                                        </Tooltip>
                                                    </Grid>}
                                                    <Grid>
                                                        <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadExcelForMsg" />}
                                                            placement='bottom'>
                                                            <span>
                                                                {/* {(poVmiApprovalModuleAccess !== undefined ? poVmiApprovalModuleAccess.readFlag : false) && ( */}
                                                                    <Button size="small" color="primary" variant="outlined" className={classes.refreshToobarStyle}
                                                                        onClick={() => {
                                                                            procesListValue === 'PO PDF' ? setMsgHdrPopUpForPoPdf(true) : setMsgHdrPopUpForVmi(true)
                                                                        }}
                                                                        disabled={disiablingDownloadButtonForZeroRecords}
                                                                    >
                                                                        <GetAppIcon size={20} />
                                                                    </Button>
                                                                {/* )} */}
                                                            </span>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <CommonGridPagination />
                                                </Grid>
                                            </Grid>
                                        );
                                    },

                                    FilterPanel: () => {
                                        return (
                                            <Grid container
                                                direction="row"
                                            >
                                                <Grid container
                                                    item
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    xs={6} sm={6} md={6} lg={6}
                                                >
                                                    <GridFilterPanel />
                                                </Grid>
                                                <Grid container
                                                    item
                                                    justifyContent="flex-end"
                                                    alignItems="flex-end"
                                                    xs={6} sm={6} md={6} lg={6}
                                                    style={{ marginLeft: '-24%' }}
                                                >
                                                    <Button color="primary" style={{ paddingBottom: '10px' }} onClick={(() => {
                                                        setParamBody(prevState => {
                                                            return {
                                                                ...prevState, body: {
                                                                    entity: { erpEntityCode: searchedErpEntityCode },
                                                                    process: procesListValue,
                                                                    msgStatusRemarks: searchedRemarks,
                                                                    bpCode: searchedBpCode,
                                                                    bpName: searchedBpName,
                                                                    artifactId: searchedPoNo,
                                                                    projectCode: searchedProjectCode,
                                                                    msgStatusFlag: searchedEmailStatus,
                                                                    createdDate: '',
                                                                    approvedBy: searchedApprovedBy,
                                                                    approved: searchedApprovedRecords === '' ? 'All' : searchedApprovedRecords,
                                                                    apprEmailSent: true,
                                                                    recordApprovedStatus: searchedApprovedRecords === '' ? 'All' : searchedApprovedRecords,
                                                                    recordApprSentStatus: '',
                                                                    recordEmailSentDate: searchedEmailStatusRecords === 'EMAIL SENT' ? true : searchedEmailStatusRecords === 'EMAIL NOT SENT' ? false : '',
                                                                    recordErrorStatus: searchedEmailStatus === '' ? 'All' : searchedEmailStatus,
                                                                    generatedFromDt: searchedGeneratedFromDt !== null ? searchedGeneratedFromDt.substr(1, 10) : null,
                                                                    generatedToDt: searchedGeneratedToDt !== null ? searchedGeneratedToDt.substr(1, 10) : null,
                                                                    purchasingName: searchedPurchasingName
                                                                },
                                                            }
                                                        });
                                                    })}>
                                                        <FormattedMessage id="common.applyfilter.lable" />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                }}
                                sortingOrder={['desc', 'asc']}
                                filterMode="server"
                                onFilterModelChange={async (params) => {
                                    let filterModelItem = params.items;
                                    if (filterModelItem.length > 0) {
                                        let approved = null;
                                        let bpCode = null;
                                        let bpName = null;
                                        let approvedBy = null;
                                        let msgStatusRemarks = null;
                                        let poNumber = null;
                                        let projectCode = null;
                                        let erpEntityCode = null;
                                        let msgStatusFlag = null;
                                        let generatedFromDt = null;
                                        let generatedToDt = null;
                                        let purchasingName = null;
                                        for (let index = 0; index < filterModelItem.length; index++) {
                                            if (filterModelItem[index].columnField === columnFieldsName.erpEntityCode) {
                                                erpEntityCode = await filterModelItem[index].value === undefined ? '' : JSON.parse(filterModelItem[index].value).erpEntityCode;
                                                break;
                                            }
                                        }
                                        for (let index = 0; index < filterModelItem.length; index++) {
                                            if (filterModelItem[index].columnField === columnFieldsName.approved) {
                                                approved = await filterModelItem[index].value === undefined ? '' : JSON.parse(filterModelItem[index].value).value;
                                                break;
                                            }
                                        }
                                        for (let index = 0; index < filterModelItem.length; index++) {
                                            if (filterModelItem[index].columnField === columnFieldsName.msgStatusFlag) {
                                                msgStatusFlag = await filterModelItem[index].value === undefined ? '' : JSON.parse(filterModelItem[index].value).value;
                                                break;
                                            }
                                        }
                                        for (let index = 0; index < filterModelItem.length; index++) {
                                            if (filterModelItem[index].columnField === columnFieldsName.bpCode) {
                                                bpCode = await filterModelItem[index].value === undefined ? '' : filterModelItem[index].value;
                                                break;
                                            }
                                        }
                                        for (let index = 0; index < filterModelItem.length; index++) {
                                            if (filterModelItem[index].columnField === columnFieldsName.bpName) {
                                                bpName = await filterModelItem[index].value === undefined ? '' : filterModelItem[index].value;
                                                break;
                                            }
                                        }
                                        for (let index = 0; index < filterModelItem.length; index++) {
                                            if (filterModelItem[index].columnField === columnFieldsName.projectCode) {
                                                projectCode = await filterModelItem[index].value === undefined ? '' : filterModelItem[index].value;
                                                break;
                                            }
                                        }
                                        for (let index = 0; index < filterModelItem.length; index++) {
                                            if (filterModelItem[index].columnField === columnFieldsName.msgStatusRemarks) {
                                                msgStatusRemarks = await filterModelItem[index].value === undefined ? '' : filterModelItem[index].value;
                                                break;
                                            }
                                        }
                                        for (let index = 0; index < filterModelItem.length; index++) {
                                            if (filterModelItem[index].columnField === columnFieldsName.approvedBy) {
                                                approvedBy = await filterModelItem[index].value === undefined ? '' : filterModelItem[index].value;
                                                break;
                                            }
                                        }
                                        for (let index = 0; index < filterModelItem.length; index++) {
                                            if (filterModelItem[index].columnField === columnFieldsName.poNumber) {
                                                poNumber = await filterModelItem[index].value === undefined ? '' : filterModelItem[index].value;
                                                break;
                                            }
                                        }
                                        for (let index = 0; index < filterModelItem.length; index++) {
                                            if (filterModelItem[index].columnField === columnFieldsName.generatedFromDt) {
                                                generatedFromDt = await ((filterModelItem[index].value !== undefined && filterModelItem[index].value !== null && filterModelItem[index].value !== 'null') ? filterModelItem[index].value : null);
                                                break;
                                            }
                                        }
                                        for (let index = 0; index < filterModelItem.length; index++) {
                                            if (filterModelItem[index].columnField === columnFieldsName.generatedToDt) {
                                                generatedToDt = await ((filterModelItem[index].value !== undefined && filterModelItem[index].value !== null && filterModelItem[index].value !== 'null') ? filterModelItem[index].value : null);
                                                break;
                                            }
                                        }
                                        for (let index = 0; index < filterModelItem.length; index++) {
                                            if (filterModelItem[index].columnField === columnFieldsName.purchasingName) {
                                                purchasingName = await filterModelItem[index].value === undefined ? undefined : JSON.parse(filterModelItem[index].value).userName;
                                                break;
                                            }
                                        }
                                        setSearchedApprovedRecords(approved);
                                        setSearchedBpCode(bpCode);
                                        setSearchedApprovedBy(approvedBy);
                                        setSearchedRemarks(msgStatusRemarks);
                                        setSearchedPoNo(poNumber);
                                        setSearchedProjectCode(projectCode);
                                        setSearchedErpEntityCode(erpEntityCode);
                                        setSearchedBpName(bpName);
                                        setSearchedEmailStatus(msgStatusFlag);
                                        setSearchedGeneratedFromDt(generatedFromDt);
                                        setSearchedGeneratedToDt(generatedToDt);
                                        setSearchedPurchasingName(purchasingName);
                                    }
                                }}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default PoVmiApproval;