import { Button, CssBaseline, Grid, Menu, MenuItem, MenuList, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { SupportedLanguageDetails } from '../SupportedLanguageDetails/SupportedLanguageDetails';
import TranslateIcon from '@mui/icons-material/Translate';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CommonService } from '../../../services';
import { LoggedInUserDetails } from '../LoggedInUserDetails/LoggedInUserDetails';

const languageOptions = [
    {
        language: 'ENGLISH',
        value: 'en'
    },
    {
        language: 'JAPANESE',
        value: 'jp'
    },
    {
        language: 'GERMAN',
        value: 'de'
    },
    {
        language: 'INDONESIAN',
        value: 'id'
    },
    {
        language: 'CHINESE (TRADITIONAL)',
        value: 'tw'
    },
    {
        language: 'CHINESE (SIMPLIFIED)',
        value: 'cn'
    }
];

function Selectlang(props) {
    const theme = useTheme();
    const context = useContext(SupportedLanguageDetails);
    const {isLoginPage} = props;
    const mobileScreen = useMediaQuery(theme.breakpoints.only('xs'))
    const { loggedInUserInfo, setLoggedInUserInfo } = useContext(LoggedInUserDetails);
    const [isLanguageChangeOnSelection, setIsLanguageChangeOnSelection] = useState(false);
    const [languageConvert, setLanguageConvert] = useState(context.locale);
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget)
    }

    function handleClose() {
        setAnchorEl(null)
    }

    useEffect(async () => {
        if (!loggedInUserInfo.isLanguageChanged && loggedInUserInfo.logInStatus) {
            setLanguageConvert(CommonService.getLoggedInUserInfo() !== null ? CommonService.getLoggedInUserInfo().preferedLanguage : null);
            context.setUserRequestedToChangeLang(true);
        } else if (!loggedInUserInfo.isLanguageChanged && (localStorage.getItem('preferedLanguage') !== null && localStorage.getItem('preferedLanguage') !== undefined)) {
            setLanguageConvert(JSON.parse(localStorage.getItem('preferedLanguage')));
        } else if (!loggedInUserInfo.isLanguageChanged && !loggedInUserInfo.logInStatus) {
            setLanguageConvert(context.locale);
        } else if (loggedInUserInfo.isLanguageChanged && loggedInUserInfo.logInStatus) {
            setLanguageConvert({ ...languageConvert });
        }
    }, [loggedInUserInfo]);

    useEffect(() => {
        context.changeLanguageForMenuList(languageConvert);
    }, [languageConvert])

    useEffect(() => {
        if (loggedInUserInfo.logInStatus && isLanguageChangeOnSelection) {
            const currentUserDetails = CommonService.getLoggedInUserInfo();
            const loginId = currentUserDetails !== null ? currentUserDetails.loginId : '';
            CommonService.saveOrUpdateOrPost(`/users/${loginId}/preferredLanguage`, {
                language: languageConvert.language
            })
                .then((response) => {
                    setIsLanguageChangeOnSelection(false);
                })
                .catch(function (error) {
                    setIsLanguageChangeOnSelection(false);
                })
        }
    }, [loggedInUserInfo, languageConvert])

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container item xs={6} sm={12} md={6} lg={6} >
                <Tooltip title={context.locale.language} placement="bottom" >
                    <Button
                        edge="start"
                        aria-label="Select Language"
                        aria-controls="SelectLanguage"
                        aria-haspopup="true"
                        style={{ marginLeft: '-10%' }}
                        onClick={handleClick}
                        color="primary"
                    >
                        <TranslateIcon />
                        {isLoginPage && !mobileScreen && (
                            <Typography
                                style={{ padding: '5%', fontSize: '15px', fontWeight: 500 }}
                            >
                                {context.locale.language}
                            </Typography>
                        )}
                        <ArrowDownIcon />
                    </Button>
                </Tooltip>
                <Menu
                    id="SelectLanguage"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    elevation={5}
                    onClick={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <MenuList style={{ backgroundColor: "#fff", margin: '-10% -0% -7% 0' }}>
                        {languageOptions.map((option, index) => (
                            <MenuItem
                                style={{ padding: '-% 0 -8% 0', color: '#4782da' }}
                                key={index}
                                divider
                                selected={index === languageOptions.findIndex(language => language.language === languageConvert.language)}
                                value={context.locale.language}
                                onClick={(() => {
                                    setLanguageConvert(option);
                                    setIsLanguageChangeOnSelection(true);
                                    localStorage.setItem('preferedLanguage', JSON.stringify(option));
                                    setLoggedInUserInfo({ ...loggedInUserInfo, isLanguageChanged: true });
                                })}
                            >
                                {option.language}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Grid>
        </React.Fragment>
    );
}
export default Selectlang;