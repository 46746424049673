import { useIntl } from "react-intl";

function CustomToolbarForRichTextEditor() {
  const intl = useIntl();

  return {
    // BlockType
    "components.controls.blocktype.h1": intl.formatMessage({ id: "richtexteditor.blocktype.H1.text" }),
    "components.controls.blocktype.h2": intl.formatMessage({ id: "richtexteditor.blocktype.H2.text" }),
    "components.controls.blocktype.h3": intl.formatMessage({ id: "richtexteditor.blocktype.H3.text" }),
    "components.controls.blocktype.h4": intl.formatMessage({ id: "richtexteditor.blocktype.H4.text" }),
    "components.controls.blocktype.h5": intl.formatMessage({ id: "richtexteditor.blocktype.H5.text" }),
    "components.controls.blocktype.h6": intl.formatMessage({ id: "richtexteditor.blocktype.H6.text" }),
    "components.controls.blocktype.blockquote": intl.formatMessage({ id: "richtexteditor.blocktype.blockquote.text" }),
    "components.controls.blocktype.code": intl.formatMessage({ id: "richtexteditor.blocktype.code.text" }),
    "components.controls.blocktype.blocktype": intl.formatMessage({ id: 'richtexteditor.blocktype.tooltip' }),
    "components.controls.blocktype.normal": intl.formatMessage({ id: "richtexteditor.blocktype.normal.text" }),
    // Color Picker
    "components.controls.colorpicker.colorpicker": intl.formatMessage({ id: 'richtexteditor.colorpicker.tooltip' }),
    "components.controls.colorpicker.text": intl.formatMessage({ id: 'richtexteditor.colorpicker.text' }),
    "components.controls.colorpicker.background": intl.formatMessage({ id: 'richtexteditor.colorpicker.background' }),

    // Emoji
    "components.controls.emoji.emoji": intl.formatMessage({ id: 'richtexteditor.emoji.tooltip' }),

    // FontFamily
    "components.controls.fontfamily.fontfamily": intl.formatMessage({ id: 'richtexteditor.font.tooltip' }),

    // FontSize
    "components.controls.fontsize.fontsize": intl.formatMessage({ id: 'richtexteditor.fontSize.tooltip' }),

    // History
    "components.controls.history.history": intl.formatMessage({ id: 'richtexteditor.history.tooltip' }),
    "components.controls.history.undo": intl.formatMessage({ id: 'richtexteditor.undo.tooltip' }),
    "components.controls.history.redo": intl.formatMessage({ id: 'richtexteditor.redo.background' }),

    // Inline
    "components.controls.inline.bold": intl.formatMessage({ id: 'richtexteditor.bold.tooltip' }),
    "components.controls.inline.italic": intl.formatMessage({ id: 'richtexteditor.italic.tooltip' }),
    "components.controls.inline.underline": intl.formatMessage({ id: 'richtexteditor.underline.tooltip' }),
    "components.controls.inline.strikethrough": intl.formatMessage({ id: 'richtexteditor.strikethrough.tooltip' }),
    "components.controls.inline.monospace": intl.formatMessage({ id: 'richtexteditor.monospace.tooltip' }),
    "components.controls.inline.superscript": intl.formatMessage({ id: 'richtexteditor.superscript.tooltip' }),
    "components.controls.inline.subscript": intl.formatMessage({ id: 'richtexteditor.subscript.tooltip' }),

    // // Link
    "components.controls.link.linkTitle": intl.formatMessage({ id: 'richtexteditor.linktitle.text' }),
    "components.controls.link.linkTarget": intl.formatMessage({ id: 'richtexteditor.linktarget.text' }),
    "components.controls.link.linkTargetOption": intl.formatMessage({ id: 'richtexteditor.openlinkinnw.text' }),
    "components.controls.link.link": intl.formatMessage({ id: 'richtexteditor.link.text' }),
    "components.controls.link.unlink": intl.formatMessage({ id: 'richtexteditor.unlink.tooltip' }),

    // List
    "components.controls.list.list": intl.formatMessage({ id: 'richtexteditor.list.text' }),
    "components.controls.list.unordered": intl.formatMessage({ id: 'richtexteditor.unordered.tooltip' }),
    "components.controls.list.ordered": intl.formatMessage({ id: 'richtexteditor.ordered.tooltip' }),
    "components.controls.list.indent": intl.formatMessage({ id: 'richtexteditor.indent.tooltip' }),
    "components.controls.list.outdent": intl.formatMessage({ id: 'richtexteditor.outdent.tooltip' }),

    // Remove
    "components.controls.remove.remove": intl.formatMessage({ id: 'richtexteditor.remove.tooltip' }),

    // TextAlign
    "components.controls.textalign.textalign": intl.formatMessage({ id: 'richtexteditor.textalign.tooltip' }),
    "components.controls.textalign.left": intl.formatMessage({ id: 'richtexteditor.left.tooltip' }),
    "components.controls.textalign.center": intl.formatMessage({ id: 'richtexteditor.center.tooltip' }),
    "components.controls.textalign.right": intl.formatMessage({ id: 'richtexteditor.right.tooltip' }),
    "components.controls.textalign.justifyContent": intl.formatMessage({ id: 'richtexteditor.Justify.tooltip' })
  }
}

export default CustomToolbarForRichTextEditor;