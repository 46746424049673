import React from 'react'
import NavItem from './NavItem'

const AppSidebarNavList = props => {
  const { items = [], isCollapsed = false, isNested = false, setIsSidebarCollapsed } = props
  return (
    <>
      {items.map((item, index) => (
        <NavItem {...item} isCollapsed={isCollapsed} isNested={isNested} key={index} setIsSidebarCollapsed={setIsSidebarCollapsed} />
      ))}
    </>
  )
}

export default AppSidebarNavList
