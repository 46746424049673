import { Chip } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import React from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import IconSupplier from '@mui/icons-material/LocalShipping';
import IconActive from '@mui/icons-material/Done';
import IconCloseOrInActive from '@mui/icons-material/Close';
import IconTotalRec from '@mui/icons-material/List';
import IconForLastActive from '@mui/icons-material/DateRange';
import { useIntl } from 'react-intl';
import NotConfirmedIcon from '@mui/icons-material/EventBusy';
import ConfirmedIcon from '@mui/icons-material/EventAvailable';
import PartiallyConfirmedIcon from '@mui/icons-material/DateRange';
import NewMsgIcon from '@mui/icons-material/Event';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    chip: {
        margin: theme.spacing(0.5),
    }
}))

const InActiveChip = withStyles((theme) => ({
    root: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main
    },
}))(Chip);

const ActiveChip = withStyles((theme) => ({
    root: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main
    },
}))(Chip);

const LastActive = withStyles((theme) => ({
    root: {
        color: theme.palette.customOrange.main,
        borderColor: theme.palette.customOrange.main,
    },
}))(Chip);

const PendingChip = withStyles((theme) => ({
    root: {
        color: '#dbc102',
        borderColor: '#dbc102',
    },
}))(Chip);

const BothActiveInactiveChip = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
    },
}))(Chip);

const ChipInfoList = (props) => {
    const { chipInfoList } = props;
    const classes = useStyles();
    const intl = useIntl();
    return (
        <React.Fragment>
            {chipInfoList.map((chip, index) => {
                let icon;
                let formattedLabelBasesdOnSelLang = intl.formatMessage({ id: `${chip.languageId}` })

                if (chip.iconName === 'AccountBoxIcon') {
                    icon = <AccountBoxIcon />;
                } else if (chip.iconName === 'IconSupplier') {
                    icon = <IconSupplier />
                } else if (chip.iconName === 'IconActive') {
                    icon = <IconActive />
                } else if (chip.iconName === 'IconCloseOrInActive') {
                    icon = <IconCloseOrInActive />
                } else if (chip.iconName === 'IconTotalRec') {
                    icon = <IconTotalRec />
                } else if (chip.iconName === 'IconThreeMonthsAgo') {
                    icon = <IconForLastActive />
                } else if (chip.iconName === 'IconFailure') {
                    icon = <NotConfirmedIcon />
                } else if (chip.iconName === 'IconSuccess') {
                    icon = <ConfirmedIcon />
                } else if (chip.iconName === 'IconAmended') {
                    icon = <PartiallyConfirmedIcon />
                } else if (chip.iconName === 'IconPending') {
                    icon = <NewMsgIcon />
                }

                if (chip.iconName === 'IconActive') {
                    return (
                        <ActiveChip
                            icon={icon}
                            key={index}
                            size='small'
                            variant='outlined'
                            label={`${formattedLabelBasesdOnSelLang} : ${chip.value}`}
                            className={classes.chip}
                            color='secondary'
                        />
                    );
                } else if (chip.iconName === 'IconCloseOrInActive') {
                    return (
                        <InActiveChip
                            icon={icon}
                            key={index}
                            size='small'
                            variant='outlined'
                            label={`${formattedLabelBasesdOnSelLang} : ${chip.value}`}
                            className={classes.chip}
                            color='secondary'
                        />
                    );
                } else if (chip.iconName === 'IconSupplier') {
                    return (
                        <InActiveChip
                            icon={icon}
                            key={index}
                            size='small'
                            variant='outlined'
                            label={`${formattedLabelBasesdOnSelLang} : ${chip.value}`}
                            className={classes.chip}
                            color='secondary'
                            disabled={true}
                        />
                    );
                } else if (chip.iconName === 'IconThreeMonthsAgo') {
                    return (
                        <LastActive
                            icon={icon}
                            key={index}
                            size='small'
                            variant='outlined'
                            label={`${formattedLabelBasesdOnSelLang} : ${chip.value}`}
                            className={classes.chip}
                            color='secondary'
                        />
                    );
                } else if (chip.iconName === 'IconFailure') {
                    return (
                        <InActiveChip
                            icon={icon}
                            key={index}
                            size='small'
                            variant='outlined'
                            label={`${formattedLabelBasesdOnSelLang} : ${chip.value}`}
                            className={classes.chip}
                            color='secondary'
                        />
                    );
                } else if (chip.iconName === 'IconSuccess') {
                    return (
                        <ActiveChip
                            icon={icon}
                            key={index}
                            size='small'
                            variant='outlined'
                            label={`${formattedLabelBasesdOnSelLang} : ${chip.value}`}
                            className={classes.chip}
                            color='secondary'
                        />
                    );
                } else if (chip.iconName === 'IconAmended') {
                    return (
                        <PendingChip
                            icon={icon}
                            key={index}
                            size='small'
                            variant='outlined'
                            label={`${formattedLabelBasesdOnSelLang} : ${chip.value}`}
                            className={classes.chip}
                            color='secondary'
                        />
                    );
                } else if (chip.iconName === 'IconPending') {
                    return (
                        <BothActiveInactiveChip
                            icon={icon}
                            key={index}
                            size='small'
                            variant='outlined'
                            label={`${formattedLabelBasesdOnSelLang} : ${chip.value}`}
                            className={classes.chip}
                            color='secondary'
                        />
                    );
                } else {
                    return (
                        <Chip
                            icon={icon}
                            key={index}
                            size='small'
                            variant='outlined'
                            label={`${formattedLabelBasesdOnSelLang} : ${chip.value}`}
                            className={classes.chip}
                            color={chip.color}
                        />
                    );
                }
            })}
        </React.Fragment>
    );
}
export default ChipInfoList;