import {
    Badge, Button, CircularProgress,
    Dialog, CssBaseline, Grid, IconButton, Paper, Snackbar, Tooltip, useMediaQuery, useTheme, Typography
} from '@mui/material';
import { Alert } from '@mui/lab';
import ClearIcon from "@mui/icons-material/Clear";

import CommonGridPagination from '../common/CommonGridPagination/CommonGridPagination';
import Box from '@mui/material/Box';
import { getGridStringOperators, GridToolbarColumnsButton, GridToolbarDensitySelector, LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CommonService } from '../../services';
import CreateIcon from '@mui/icons-material/Add';
import { withStyles } from "@mui/styles";
import MuiDialogContent from '@mui/material/DialogContent';
import saveAs from 'file-saver';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import useStyles from '../common/CommonStyle/CommonStyle';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import SupportedLanguageDetails from '../common/SupportedLanguageDetails';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import { Controller, useForm } from 'react-hook-form';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { ModuleAccessPermissionKey } from '../../Constants/ModuleAccessKey';
import MuiDialogTitle from "@mui/material/DialogTitle";
import CustomBreadCrumb from '../common/CustomBreadCrumb';
import MuiDialogActions from "@mui/material/DialogActions";
import FileUploadSection from './FileUploadSection';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


const columnFieldsName =
{
    documentName: 'documentName',
}

export default function CustomAddOrViewFiles(props) {

    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const pageLabelsConstantsAndMessages = {
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
    };
    const onSubmit = (data) => {

        data["id"] = Math.floor(Math.random() * 100);
        setAlertBoxState({
            ...alertBoxState,
            openAlert: true,
            message: "Record Updated successfully",
            severity: "success",
        });

    };

    const { open, onClose, defaultEntity, defaultBpType, dialogHeader, showToggleButton, isPartchange, selectedRow, refreshAddOrView, setRefreshAddOrView
    } = props;
    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    });
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disabletypography="true" className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
            </MuiDialogTitle>
        );
    });
    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(0),
        },
    }))(MuiDialogContent);
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);

    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const localtext = GridTextLocalization();
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [createConfigDtl, setMode] = React.useState('')
    const [refreshTable, setRefreshTable] = useState(false);
    const { configId, mode, } = props;
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [OpenDialogBox, SetOpenDialogBox] = useState(false)
    const [msgId, setMsgId] = useState(0);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [columns, setColumns] = useState([]);
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const [selectedModel, setSelectedModel] = useState([]);
    const [openFileUploadSection, setopenFileUploadSection] = useState(false);
    const [chipInfoList, setChipInfoList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [paramBody, setParamBody] = useState(
        {
            body: {
                referenceId: selectedRow !== undefined ? selectedRow.msgId : 0,
                orgCode: selectedRow !== undefined ? selectedRow.orgCode : null,

            },
            pageNo: 0,
            sortDir: 'desc',
            sortKey: 'updatedTime',
            recordsPerPage: 25,
        }
    );
  
    useEffect(() => {
        setParamBody({
            ...paramBody, body: {
                referenceId: selectedRow !== undefined ? selectedRow.msgId : 0,
                orgCode: selectedRow !== undefined ? selectedRow.organization.erpOrgCode : null,
                entityCode: selectedRow !== undefined ? selectedRow.entity.erpEntityCode : null
            },
            // pageNo: 0,
            // sortDir: 'desc',
            // sortKey: 'updatedTime',
            // recordsPerPage: 25, 
        });
    }, [refreshAddOrView]);

    // useEffect(() => {
    // }, [paramBody]);

    // if (selectedRow !== undefined) {

    // }
    const closeFileUploadSection = (confirmed) => {
        setopenFileUploadSection(false);
    }
    const componentList = [
        {
            path: null,
            name: <FormattedMessage id="common.menu.breadcrumb.Documents" />,
            iconName: IconMessageConfiguration,
        }
    ];
    const commonBreadCrumbToBeUsedForDialog = componentList;
    const onError = (errors) => {
        setAlertBoxState({
            ...alertBoxState,
            openAlert: true,
            message: "Form Has Errors",
            severity: "error",
            err: true,
        });
    };
    
    const pcnModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.pcnModuleAccess];
    const { handleSubmit, formState: { errors, isDirty, isValid, dirtyFields }, reset, control, getValues, setValue } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        shouldUnregister: false,
        defaultValues: {

        },
    });

    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const handleClose = (value) => {
        // handlereset(false);
        onClose(value);

    };

    const closeConfirmationDialog = (confirmed) => {
        setOpenConfirmationDialog(false);
        if (confirmed) {
            CommonService.deleteByIdApi(`/documentUploads/${msgId}`, {})
                .then((response) => {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: "File Deleted Successfully", severity: "success" });
                    setRefreshAddOrView(!refreshAddOrView);
                })
                .catch(function (error) {
                    let message = (error.response.data !== undefined || error.response.data !== null) ? error.response.data.message
                        : <FormattedMessage id="role.error.txt" defaultMessage="Error occurred while deleting File!" />;
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: message, severity: "error" });
                });
        }
    }


    // useEffect(() => {
        // setColumns(
       const columnsForDocumnets = [
            {
                field: columnFieldsName.documentName,
                headerName: intl.formatMessage({ id: "columnname.DocumentName.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 820,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: 'action',
                headerName: intl.formatMessage({ id: "columnname.action.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 130,
                sortable: false,
                hide: false,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (
                    <div>
                        {(cellParams.row.documentPath !== null) && (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadFile" defaultMessage="downloadFile" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                showDownloadPopUp(cellParams.row.documentPath)
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                <CloudDownloadIcon />
                                 </IconButton>
                            </span>
                        </Tooltip>)}
                        {(<Tooltip title={<FormattedMessage id="common.button.delete.tooltip" defaultMessage="Delete" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                handleDelete(cellParams.row.documentId, cellParams.row.documentName);
                                setMsgId(cellParams.row.documentId);
                            })}>
                            <span>
                                <IconButton color='secondary' size="large">
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    </div>

                )
            }
        ]
        // );
    // }, []);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        // if (intialParamBodyApiCall) {
        setTableLoadingStatus(true);
        setTableData([]);
        CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.documentUploads, paramBody)
            .then((response) => {
                setTotalRowCount(response.data.totalElements);
                setTableLoadingStatus(false);
                setTableData(response.data.content);
                (response.data.totalElements > 0 ? setChipInfoList(response.data.content[0].headerChipList === null ? [] :
                    response.data.content[0].headerChipList) : setChipInfoList([]));
            })
            .catch(function (error) {
                setTableLoadingStatus(false);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, [paramBody, refreshTable])

    const showDownloadPopUp = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }
    const handleDelete = (primaryKey, nameToBeDisplacedOnConfirmationDialog) => {
        setMsgId(primaryKey);
        setConfirmationMessage(<FormattedMessage id="common.dialog.areyousure.txt" values={{ nameToBeDisplacedOnConfirmationDialog: `${nameToBeDisplacedOnConfirmationDialog}` }} defaultMessage={`Are you sure, You want to delete '${nameToBeDisplacedOnConfirmationDialog}'?`} />);
        setOpenConfirmationDialog(true);
    }
    return (

        <React.Fragment>
            <CssBaseline />
            {<Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            }
            <ConfirmationDialog onClose={closeConfirmationDialog} message={confirmationMessage} open={openConfirmationDialog} />
            <Dialog fullWidth aria-labelledby="create-or-delete-dialog" maxWidth="md" open={open}>

                <FileUploadSection
                    open={openFileUploadSection}
                    onClose={closeFileUploadSection}
                    defaultEntity={defaultEntity}
                    defaultBpType={"SUPPLIER"}
                    dialogHeader={"Upload files"}
                    showToggleButton={false}
                    selectedRow={selectedRow}
                    isPartchange={isPartchange}
                    refreshAddOrView={refreshAddOrView}
                    setRefreshAddOrView={setRefreshAddOrView}
                />

                <DialogTitle className={classes.titleCommonDialogComponent}>
                    <Grid item container xs={12} sm={12} md={12} lg={12}>
                        <Grid item container alignItems='center' xs={12} sm={6} md={6} lg={6} style={{ marginLeft: '-0.8%' }} >
                            {<CustomBreadCrumb componentList={componentList} isDialog={true} />}
                        </Grid>{" "}
                    </Grid>
                </DialogTitle>

                <DialogContent style={mobileScreen ? { minHeight: '70vh' } : { minHeight: '60vh' }}>
                    <Paper  elevation={3} className={classes.gridHeightAndWidthForViewBoxDetails}>
                        <div style={{ height: '100%', width: '100%' }}>
                            {/* <Box sx={{ height: 400, width: '100%' }}> */}
                                <DataGridPro
                                    className={classes.customTableStyle}
                                    sortingMode="server"
                                    editMode="row"
                                    // columns={columns}
                                    columns={columnsForDocumnets}
                                    rows={tableData}
                                    getRowId={(row) => row.documentId === undefined ? row.id : row.documentId}
                                    pageSize={paramBody.recordsPerPage}
                                    loading={tableLoadingStatus}
                                    rowCount={totalRowCount}
                                    scrollbarSize={30}
                                    disableColumnFilter={true}
                                    rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                                    pagination
                                    paginationMode="server"
                                    disableSelectionOnClick={false}
                                    disableColumnMenu={false}
                                    disableMultipleColumnsSorting={true}
                                    onRowClick={(params) => {
                                        setSelectedRows(params.row)
                                    }}
                                    onSelectionModelChange={(model) => {
                                        setSelectedModel(model);
                                    }}
                                    selectionModel={selectedModel}
                                    hideFooter={true}
                                    localeText={localtext}
                                    page={paramBody?.pageNo ?? 0}
                                    density="compact"
                                    onPageSizeChange={((pageParams) => {
                                        setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                                    })}
                                    onPageChange={((pageParams, details) => {
                                        setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                                    })}
                                    onSortModelChange={(params) => {
                                        let sortModel = params[0];
                                        if (sortModel !== undefined) {
                                            setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                        }
                                    }}
                                    components={{
                                        Toolbar: () => {

                                            return (
                                                <Grid container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}  >
                                                    <Grid
                                                        item
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}>

                                                        <Grid>
                                                            {(pcnModuleAccess !== undefined ? pcnModuleAccess.createFlag || pcnModuleAccess.editFlag : false) && (
                                                                (<Tooltip title={<FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="create" />} placement='bottom'>
                                                                    <span>
                                                                        <Button size="small" color="primary" variant="outlined"
                                                                            className={classes.refreshToobarStyle}

                                                                            onClick={(() => {
                                                                                setMode('create');
                                                                                setopenFileUploadSection(true)
                                                                            })}
                                                                            disabled={(configId === 0 || mode === 'read') ? true : false}
                                                                        >
                                                                            <CreateIcon />
                                                                        </Button>
                                                                    </span>
                                                                </Tooltip>))}
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <CommonGridPagination />
                                                    </Grid>
                                                </Grid>
                                            );
                                        },
                                    }}
                                    sortingOrder={['desc', 'asc']}
                                    filterMode="server"
                                />
                            {/* </Box> */}
                        </div>
                    </Paper>
                </DialogContent>
                {!smallScreen && (
                    <DialogActions>
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={classes.commonDialogButtonSpacing}
                        >
                            <Tooltip
                                title={<FormattedMessage id="common.button.cancel.lable" />}
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.stateButtonForBack}
                                    elevation={2}
                                    onClick={() => handleClose(false)}
                                >
                                    <ClearIcon/>
                                    <FormattedMessage id="common.button.cancel.lable" />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </DialogActions>
                )}
                {smallScreen && (
                    <DialogActions>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={classes.commonDialogButtonSpacing}
                        >
                            <Tooltip
                                title={<FormattedMessage id="common.button.cancel.lable" />}
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.stateButton}
                                    elevation={2}
                                    onClick={() => handleClose(false)}
                                >
                                    <ClearIcon />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </DialogActions>
                )}

            </Dialog>
        </React.Fragment>
    )


}

