import { CssBaseline, FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import { CommonService } from '../../../../services';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import useStyles from '../../CommonStyle/CommonStyle'

const POInboundStatusCombo = (props) => {

    const classes = useStyles();
    const { comboProperty, control, errors, comboValue, setComboValue } = props;
    const statusOption = [
        {id: 1, value: 0, name: 'New Msg' },
        {id: 2, value: 1, name: 'Pending Msg' },
        {id: 2, value: 2, name: 'Success Msg' },
        {id: 3, value: 3, name: 'Error Msg'},
        {id: 4, value: 4, name: 'Upload Err Msg'},
    ];

    return (
        <React.Fragment>
            <CssBaseline />
        { comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth>
                <Controller
                    render={props => (
                        <Autocomplete

                            onChange={(event, newValue) => {
                                props.onChange(newValue)

                                if (typeof newValue === 'string') {

                                    if (newValue != null) {
                                        setComboValue({
                                            newValue,
                                        });
                                    }
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    setComboValue({
                                        title: newValue.inputValue,
                                    });
                                }
                                else if (newValue !== null) {
                                    setComboValue(newValue);
                                } else if (newValue === null) {
                                    setComboValue('');
                                } else {
                                    setComboValue(newValue);
                                }
                            }}
                            loadingText="Loading..."
                            selectOnFocus
                            handleHomeEndKeys
                            value={comboValue.name || null}
                            autoHighlight
                            options={statusOption}
                            renderOption={(props, option) => {
                                return <li {...props}>{
                                <React.Fragment>
                                    { option.value === 0 && (<CommonService.PoStatusChips  value={0} />)}
                                    { option.value === 1 && (<CommonService.PoStatusChips  value={1} />)}
                                    { option.value === 2 && (<CommonService.PoStatusChips value={2} />)}
                                    { option.value === 3 && (<CommonService.PoStatusChips  value={3} />)}
                                    { option.value === 4 && (<CommonService.PoStatusChips  value={4} />)}
                                    </React.Fragment>}
                                </li>;
                            }}
                            getOptionLabel={(option) => {

                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.name;
                            }}
                            getoptionselected={(option, value) => option.name === value}
                            disabled={comboProperty.isDisabled}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    autoFocus={comboProperty.isAutofocus}
                                    name={comboProperty.name}
                                    size='small'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                    variant={comboProperty.variant}
                                />
                            )}

                        />
                    )}

                    name={comboProperty.name}
                    rules={{ required: comboProperty.isRequired }}
                    control={control}
                    defaultValue={{ id: 0, name: '', value: '' }}
                />
                {comboProperty.isDisabled && (<FormHelperText>{comboProperty.label} can't be edited</FormHelperText>)}
                <div className={classes.error}>
                    {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                        <FormHelperText className={classes.error}>{comboProperty.label} is required</FormHelperText>
                    )}
                </div>
            </FormControl>
        </Grid>)}
        { (!comboProperty.isHookFormType) && (<Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: "3px"}}>
        <FormControl fullWidth>
            <Autocomplete
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {

                                if (newValue != null) {
                                    setComboValue({
                                        newValue,
                                    });
                                }
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setComboValue({
                                    title: newValue.inputValue,
                                });
                            }
                            else if (newValue !== null) {
                                setComboValue(newValue);
                            } else if (newValue === null) {
                                setComboValue('')
                            } else {
                                setComboValue(newValue);
                            }
                        }}
                        selectOnFocus
                        handleHomeEndKeys
                        value={comboValue.name || null}
                        autoHighlight
                        options={statusOption}
                        loadingText="Loading..."
                        getOptionLabel={(option) => {

                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.name;
                        }}
                        getoptionselected={(option, value) => option.name === value}
                        disabled={comboProperty.isDisabled}
                        renderOption={(props, option) => {
                            return <li {...props}>{
                            <React.Fragment>
                                { option.value === 0 && (<CommonService.PoStatusChips  value={0} />)}
                                { option.value === 1 && (<CommonService.PoStatusChips  value={1} />)}
                                { option.value === 2 && (<CommonService.PoStatusChips value={2} />)}
                                { option.value === 3 && (<CommonService.PoStatusChips  value={3} />)}
                                { option.value === 4 && (<CommonService.PoStatusChips  value={4} />)}
                                </React.Fragment>}
                            </li>;
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                autoFocus={comboProperty.isAutofocus}
                                name={comboProperty.name}
                                size='small'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                variant={comboProperty.variant}
                            />
                        )}

                    />
        </FormControl>
        </Grid>)}
        </React.Fragment>
    );
};

POInboundStatusCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default POInboundStatusCombo;

