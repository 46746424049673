import { Badge, Button, Chip, CssBaseline, Grid, IconButton, Paper, Snackbar, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import {
    LicenseInfo, DataGridPro, getGridStringOperators,
    GridToolbarColumnsButton, GridToolbarDensitySelector
} from '@mui/x-data-grid-pro';
import { CommonService } from '../../../services';
import ChipInfoList from '../../common/ChipInfoList';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Alert from '@mui/lab/Alert';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import IconSettings from '@mui/icons-material/Settings';
import IconRole from '@mui/icons-material/SupervisorAccount';
import IconOtherRole from '@mui/icons-material/People';
import { useHistory } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import PropTypes from 'prop-types';
import StatusCombo from '../../common/Combos/StatusCombo';
import { CustomFilters } from '../../common/CustomFilters/CustomFilters';
import useStyles from '../../common/CommonStyle/CommonStyle';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import CustomFilterComponent, { GetFilterableColumns } from '../../common/CustomFilterComponent';
import FilterListIcon from '@mui/icons-material/FilterList';
import { manageUserPreferences } from '../../../services/manageUserPreferences';

const columnFieldsName = {
    userType: 'userType',
    roleName: 'name',
    active: 'active',
    erpOrgCode: 'organization.erpOrgCode',
    entity: 'entity.erpEntityCode',
    erpEntityCode: 'entity.erpEntityCode'
};

function StatusColInputValue(props) {
    const { item, applyValue } = props;
    const [selectedActiveStatus, setSelectedActiveStatus] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedActiveStatus) });
    }, [selectedActiveStatus])
    const statusComboProperty = {
        name: 'value',
        isRequired: false,
        label: 'Value',
        isAutofocus: false,
        isDisabled: false,
        variant: undefined,
        isHookFormType: false
    }

    return (
        <StatusCombo
            comboProperty={statusComboProperty}
            comboValue={selectedActiveStatus}
            setComboValue={setSelectedActiveStatus} />
    );
};

StatusColInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function Role() {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const localtext = GridTextLocalization();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const smallScreen = useMediaQuery(theme.breakpoints.down(889));
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
        name: {
            name: 'name'
        }
    };
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const roleModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.role];
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [chipInfoList, setChipInfoList] = useState([]);
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    var defaultEntity = loggedInUserInfo.defaultEntity;

    const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.erpEntityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity }]
        : [];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);

    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.settings" defaultMessage="Settings" />,
            iconName: IconSettings,
        },
        {
            path: '/roles',
            name: <FormattedMessage id="common.module.breadcrumb.role" defaultMessage="Role" />,
            iconName: IconRole,
        }
    ];
    const [roleId, setRoleId] = useState(0);

    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.role);

    const renderRoleName = (cellParam) => {
        return (<Chip
            icon={<IconOtherRole />}
            variant="outlined"
            label={cellParam.value}
            color={cellParam.id % 2 === 0 ? 'primary' : 'secondary'}
        />)
    }
    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState(preference.columnsPinned);
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {

            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }


    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);
    const columnsForRole = [
        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: 130,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    {(roleModuleAccess !== undefined ? roleModuleAccess.editFlag : false) &&
                        (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                let roleId = cellParams.row.id;
                                history.push(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.role}/${roleId}`, { mode: 'edit', roleId: roleId });
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {(roleModuleAccess !== undefined ? roleModuleAccess.deleteFlag : false) &&
                        (<Tooltip title={<FormattedMessage id="common.button.delete.tooltip" defaultMessage="Delete" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                handleDelete(cellParams.row.id, cellParams.row.name);
                            })}>
                            <span>
                                <IconButton color='secondary' size="large">
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                </div>
            )
        },
        {
            field: columnFieldsName.entity,
            width: 85,
            headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: true,
            valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.roleName,
            width: 185,
            headerName: intl.formatMessage({ id: "common.module.breadcrumb.role" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: renderRoleName
        },
        {
            field: columnFieldsName.userType,
            width: 150,
            headerName: intl.formatMessage({ id: "columnname.usertype.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.erpOrgCode,
            headerName: intl.formatMessage({ id: "columnname.organizationcode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 135,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            valueGetter: (params) => `${params.row.organization.erpOrgCode}`,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.active,
            headerName: intl.formatMessage({ id: "columnname.status.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 140,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.customRenderStatusColumn
        },
        {
            field: 'updatedBy',
            headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
            width: 145,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'updatedTime',
            headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
            width: 170,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'date',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.lastUpdatedDtDisp}</span>)
        },
        {
            field: 'createdBy',
            headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
            width: 137,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'createdTime',
            headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
            width: 170,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'date',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.createdDtDisp}</span>)
        },
    ];

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(columnsForRole);
                savePreference("visibility", columnsForRole);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, columnsForRole);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let orgName = '';
                let userType = '';
                let orgCode = '';
                let name = '';
                let erpEntityCode='';
                let active = '';
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'userType':
                            userType = columns
                            break;
                        case 'organization.orgName':
                            orgName = columns
                            break;
                        case 'organization.erpOrgCode':
                            orgCode = columns
                            break;
                        case 'entity.erpEntityCode':
                            erpEntityCode = columns
                                break;
                        case 'name':
                            name = columns
                            break;
                        case 'active':
                            active = columns
                            break;
                    }
                })
                var filterBody = {
                    userType: userType.value,
                    name: name.value,
                    organization: {
                        erpOrgCode: orgCode.value, orgName: orgName.value,
                    },
                    entity:{
                        erpEntityCode: erpEntityCode.value,
                    },
                    active: true,
                    recordActiveStatus: active.value === '' ? 'All' : active.value === 'Active' ? true : active.value === 'In-Active' ? false : 'All',
                };
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0)
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [selectedLanguage]);

    const [tableData, setTableData] = useState([]);
    useEffect(() => {
         if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.role, paramBody)
                .then((response) => {

                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
     }

    }, [paramBody, refreshTable])

    const closeConfirmationDialog = (confirmed) => {
        setOpenConfirmationDialog(false);
        if (confirmed) {
            CommonService.deleteByIdApi(`/roles/${roleId}`, {})
                .then((response) => {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="role.snackbardelete.txt" defaultMessage="Role deleted successfully" />, severity: "success" });
                    setRefreshTable(!refreshTable);
                })
                .catch(function (error) {
                    let message = (error.response.data !== undefined || error.response.data !== null) ? error.response.data.message
                        : <FormattedMessage id="role.error.txt" defaultMessage="Error occurred while deleting Role!" />;
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: message, severity: "error" });
                });
        }
    }

    const handleDelete = (primaryKey, nameToBeDisplacedOnConfirmationDialog) => {
        setRoleId(primaryKey);
        setConfirmationMessage(<FormattedMessage id="common.dialog.areyousure.txt" values={{ nameToBeDisplacedOnConfirmationDialog: `${nameToBeDisplacedOnConfirmationDialog}` }} defaultMessage={`Are you sure, You want to delete '${nameToBeDisplacedOnConfirmationDialog}'?`} />);
        setOpenConfirmationDialog(true);
    }

    if (columns.length > 0) {
        const orgCodeColumn = columns.find((column) => column.field === columnFieldsName.erpOrgCode);
        const orgCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpOrgCode);

        const orgCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgCodeInputValue,
            }),
        );

        columns[orgCodeColIndex] = {
            ...orgCodeColumn,
            filterOperators: orgCodeFilterOperators,
        };

        const roleNameColumn = columns.find((column) => column.field === columnFieldsName.roleName);
        const roleNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.roleName);

        const roleNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.RoleNameInputValue,
            }),
        );

        columns[roleNameColIndex] = {
            ...roleNameColumn,
            filterOperators: roleNameFilterOperators,
        };
        const userTypeColumn = columns.find((column) => column.field === columnFieldsName.userType);
        const userTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.userType);

        const userTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.UserTypeRoleInputValue,
            }),
        );

        columns[userTypeColIndex] = {
            ...userTypeColumn,
            filterOperators: userTypeFilterOperators,
        };
        
        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };

        const statusColumn = columns.find((column) => column.field === columnFieldsName.active);
        const statusColIndex = columns.findIndex((col) => col.field === columnFieldsName.active);

        const statusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.StatusColInputValue,
            }),
        );

        columns[statusColIndex] = {
            ...statusColumn,
            filterOperators: statusFilterOperators,
        };
    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let orgName = '';
        let userType = '';
        let orgCode = '';
        let name = '';
        let erpEntityCode='';
        let active = '';
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'userType':
                    userType = columns
                    break;
                case 'organization.orgName':
                    orgName = columns
                    break;
                case 'organization.erpOrgCode':
                    orgCode = columns
                    break;
                case 'name':
                    name = columns
                    break;
                case 'entity.erpEntityCode':
                    erpEntityCode = columns
                    break;
                case 'active':
                    active = columns
                    break;
            }
        })
        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);
        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    userType: userType.value,
                    name: name.value,
                    organization: {
                        erpOrgCode: orgCode.value, orgName: orgName.value,
                    },
                    entity:{
                        erpEntityCode:erpEntityCode.value,
                    },
                    active: true,
                    recordActiveStatus: active.value === '' ? 'All' : active.value === 'Active' ? true : active.value === 'In-Active' ? false : 'All',
                }
            }
        })
    }

    useEffect(() => {
        setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container className={classes.rootForRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={8} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={4} sm={6} md={6} lg={6} className={classes.topGrid}>
                    {(roleModuleAccess !== undefined ? roleModuleAccess.createFlag : false) && (<Button size='small'
                        variant="contained" color="primary" className={classes.createButton} elevation={2} endIcon={smallScreen ? null : <CreateIcon />} onClick={(() => {
                            history.push(`/roles/0`, { mode: 'create', roleId: 0 });
                        })}> {smallScreen ? <CreateIcon /> : <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />} </Button>)}
                </Grid>
                <ChipInfoList chipInfoList={chipInfoList} />
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <Paper elevation={3} className={classes.gridHeightAndWidthForRole}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            paginationMode="server"
                            density="compact"
                            disableSelectionOnClick={true}
                            disableColumnMenu={false}
                            disableMultipleColumnsSorting={true}
                            hideFooter={true}
                            localeText={localtext}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'><GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterListIcon />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            hideFooterSelectedRowCount={true}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </div>
                </Paper>
                <ConfirmationDialog onClose={closeConfirmationDialog} message={confirmationMessage} open={openConfirmationDialog} />
            </Grid>
        </React.Fragment>
    )
}

export default Role;