import {
  Button, CircularProgress, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid,
  Snackbar, TextField, useTheme, useMediaQuery, Tooltip, FormControl, Typography, Switch,
} from "@mui/material";
import { Alert } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CommonBackDrop from "../common/BackDrop/CommonBackDrop";
import AttachFileIcon from "@mui/icons-material/AttachFileRounded";
import _ from "lodash/fp";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import { CommonService } from "../../services";
import useStyles from "../common/CommonStyle/CommonStyle";
import { FormattedMessage, useIntl } from "react-intl";
import Fab from "@mui/material/Fab";
import { GlobalEdiApiConstants } from "../../Constants/GlobalEdiApiConstants";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useHistory } from 'react-router-dom';


const FileUploadSection = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const intl = useIntl();

  const { onClose, open, isFileUpload, dialogHeader, isPartchange, selectedRow, isBusinessPartnerPortal, refreshAddOrView, setRefreshAddOrView } = props;
  const currentUser = CommonService.getLoggedInUserInfo();
  const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [uploadedUserTypeUpdate, setUploadedUserTypeUpdate] = useState(currentUser.userType === 'SIIX-USER' ? false : true);
  const [selectedRows, setSelectedRows] = useState([]);

  const pageLabelsNamesAndConst = {
    labels: {
      dialogTitle: "Upload File",
      attachButton: "Attach",
      cancel: "Cancel",
      submitButton: "Submit",
      uploadFile: "File",
    },
    name: {
      uploadFile: "uploadedFile",
      importTmplName: "importTmplName",
    },
  };

  const { handleSubmit, control, formState: { errors } } = useForm({
    mode: "all",
    defaultValues: {

    }
  });

  const [state, setState] = React.useState({
    openAlert: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
    severity: ''
  });


  const SelectedFileList = ({ selectedFileName }) => {
    return (
      <List>
        <ListItem>
          <ListItemText primary={selectedFileName} />
        </ListItem>
      </List>
    );
  };

  const { vertical, horizontal, openAlert, message, severity } = state;

  let defaultEntityName = currentUser.userEntityAccessList !== null ? currentUser.userEntityAccessList.filter((item) => {
    return item.defaultEntity === true
  }) : [];

  let entityAccessLength = (currentUser.userType !== 'SIIX-USER' && currentUser.userEntityAccessList !== null) && currentUser.userEntityAccessList.length > 1 ? true : false;
  const [refreshTable, setRefreshTable] = useState(false);

  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState('');
  const [isResponseArrived, setResponseArrived] = useState(false);
  const [importTmplNameValue, setImportTmplNameValue] = useState('');
  const [importedFileObjectList, setImportedFileObjectList] = useState('');
  const [importedFileObjectListForStartIndexZero, setImportedFileObjectListForStartIndexZero] = useState([]);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const closeCreateOrEditViewDialog = (confirmed) => {
    if (confirmed) {
      setAlertBoxState({
        ...alertBoxState,
        openAlert: true,
        message: (
          <FormattedMessage
            id="snackbar.recordupdatedsuccessfullypermission"
            defaultMessage="Record updated successfully"
          />
        ),
        severity: "success",
      });
      setRefreshTable(!refreshTable);
    }
  };
  const onError = (errors) => {
    setAlertBoxState({
      ...alertBoxState,
      openAlert: true,
      message: "Form Has Errors",
      severity: "error",
      err: true,
    });
  };
  const pageLabelsConstantsAndMessages = {
    labels: {
      dialogTitle: "Upload File",
      attachButton: "Attach",
      cancel: "Cancel",
      submitButton: "Submit",
      uploadFile: "File",
    },
    name: {
      uploadFile: "uploadedFile",
      importTmplName: "importTmplName",
    },
  };
  const handleCloseForGlobalEDI = (value) => {
    onClose(value);
    setUploadedUserTypeUpdate(currentUser.userType === 'SIIX-USER' ? false : true);
    if (value === true) {
      setSelectedFileName('');
      setSelectedFile('');

    } else {
      setSelectedFileName('');
      setSelectedFile('');

    }
  };

  const handleCloseForBP = (value) => {
    onClose(value);
    setUploadedUserTypeUpdate(currentUser.userType === 'SIIX-USER' ? false : true)
    if (value === true) {
      setSelectedFileName('');
      setSelectedFile('');

    }
  }
  const [alertBoxState, setAlertBoxState] = React.useState({
    openAlert: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
    severity: ''
  });

  const handleCloseAlert = () => {
    setAlertBoxState({ ...state, openAlert: false });
  };

  const onSubmit = data => {
    data['fileName'] = data.fileName;

    if (selectedFile !== '') {
      const formData = new FormData();

      formData.append('uploadedFile', selectedFile);
      formData.append('fileName', selectedFileName);
      formData.append('documentUpload', JSON.stringify({
        processMaster: {

          processId: 34,

        },
        referenceId: selectedRow.msgId,
        primaryNumber: selectedRow.pcnNo,
        process: "Part Change Notification",
        orgCode: "SIIXCORP",
        entityCode: selectedRow.entity.erpEntityCode
      }));

      //setOpenBackDrop(true);
      setOpenBackDrop(true);
      CommonService.uploadFileForDataImport('/documentFilesUpload', formData)
        .then((response) => {
          setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" />, severity: "success" });
          setResponseArrived(false);
          setOpenBackDrop(false);
          onClose(true);
          setSelectedFileName('');
          setRefreshAddOrView(!refreshAddOrView);
        });
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={9000}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <CommonBackDrop
        open={openBackDrop}
      />
      {/* <ConfirmationDialog onClose={closeConfirmationDialog} 
   message={confirmationMessage}  open={openConfirmationDialog} 
          /> */}
      <Dialog fullWidth aria-labelledby="create-or-delete-dialog" maxWidth="md" open={open}>
        <DialogTitle color="primary" className={classes.title}>
          <Grid container>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
            >
              <strong>
                {dialogHeader !== undefined
                  ? dialogHeader
                  : <FormattedMessage id="header.uploadDLVCFMSch.label" defaultMessage="Upload Delivery Confirmation Schedule" />}
              </strong>
            </Grid>
            {!isPartchange && currentUser.userType === 'SIIX-USER' && <Grid container justifyContent="flex-end" alignItems="center" item xs={12} sm={12} md={4} lg={4}>
              <Typography><strong><FormattedMessage id="header.SIIXUser.label" defaultMessage="SIIX User" /></strong></Typography>
              <Switch color='success' size="medium"
                checked={uploadedUserTypeUpdate}
                onChange={(event) => {
                  setUploadedUserTypeUpdate(event.target.checked)
                }} />
              <Typography><strong><FormattedMessage id="header.BehalfOfSupplier.label" defaultMessage="Behalf of Supplier" /></strong></Typography>
            </Grid>}
          </Grid>
        </DialogTitle>
        <DialogContent dividers style={{ minHeight: "100px" }}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                    <Grid item container xs={10} sm={11} md={11} lg={11}>
                      <TextField
                        className={classes.uploadTextField}
                        size="small"
                        margin="normal"
                        variant="outlined"
                        value={selectedFileName}
                        // InputProps={{
                        //   readOnly: true,
                        //   style: { fontSize: '12px' }
                        // }}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                      />
                      <SelectedFileList selectedFileName={selectedFileName} />
                    </Grid>
                    <Grid item container xs={2} sm={1} md={1} lg={1}>
                      <div>
                        <label htmlFor="contained-button-file">
                          <Fab size='small' color="primary" component="span" style={{marginTop: '15px'}}>
                            <AttachFileIcon />
                          </Fab>
                        </label>
                        <input
                          accept={importTmplNameValue.fileType === 'EXCEL_2003' ? ".xls" : importTmplNameValue.fileType === 'EXCEL' ? '.xlsx' : importTmplNameValue.fileType === 'TXT' ? '.txt' : importTmplNameValue.fileType === 'CSV' ? '.csv' : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                          className={classes.input}
                          id="contained-button-file"
                          name={pageLabelsConstantsAndMessages.name.sftpPrvKey}
                          type="file"
                          onChange={((event) => {
                            event.preventDefault();
                            if (event.target.files[0] !== undefined) {
                              setSelectedFileName(event.target.files[0].name);
                              setSelectedFile(event.target.files[0]);
                            }
                          })}
                        />

                      </div>
                    </Grid>
                    <Grid item container xs={10} sm={12} md={12} lg={12}>
                      <div className={classes.error}>
                        {_.get(`${pageLabelsNamesAndConst.name.uploadFile}.type`, errors) === "required" && (
                          <FormHelperText className={classes.error}>
                            <FormattedMessage id="selectAnyFile.helper" defaultMessage="Please select any file" />
                          </FormHelperText>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        {!mobileScreen && (
          <DialogActions className={classes.dialogActionForFileUploading}>
            <Grid container justifyContent="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                className={classes.buttonSpacing}
                startIcon={<ClearIcon />}
                onClick={() => {
                  handleCloseForGlobalEDI(currentUser.userType === 'SIIX-USER' ? true : false);
                  handleCloseForBP(currentUser.userType === 'SIIX-USER' ? false : true)
                }}
              >
                <FormattedMessage
                  id="common.button.cancel.lable"
                  defaultMessage={pageLabelsNamesAndConst.labels.cancel}
                />
              </Button>
              <Button
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                className={classes.buttonSpacing}
                onClick={handleSubmit(onSubmit)
                }
                disabled={
                  isResponseArrived ||
                  selectedFile === undefined ||
                  selectedFile === "" ||
                  selectedFileName === ""
                }
                startIcon={<SaveIcon />}
                endIcon={
                  isResponseArrived ? (
                    <CircularProgress color="secondary" size={20} />
                  ) : null
                }
              >
                <FormattedMessage
                  id="common.button.submit.lable"
                  defaultMessage={pageLabelsNamesAndConst.labels.submitButton}
                />
              </Button>
            </Grid>
          </DialogActions>
        )}
        {mobileScreen && (
          <DialogActions className={classes.dialogActionForFileUploading}>
            <Grid container justifyContent="center" alignItems="center" item xs={12} sm={12} md={12} lg={12} >
              <Tooltip
                title={<FormattedMessage id="common.button.cancel.lable" />}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  className={classes.buttonSpacing}
                  onClick={() => {
                    handleCloseForGlobalEDI(currentUser.userType === 'SIIX-USER' ? true : false);
                    handleCloseForBP(currentUser.userType === 'SIIX-USER' ? false : true)
                  }}
                >
                  <ClearIcon />
                </Button>
              </Tooltip>
              <Tooltip
                title={<FormattedMessage id="common.button.submit.lable" />}
              >
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.buttonSpacing}
                  onClick={handleSubmit(onSubmit, onError)
                  }
                  disabled={
                    isResponseArrived ||
                    selectedFile === undefined ||
                    selectedFile === "" ||
                    selectedFileName === ""
                  }
                  endIcon={
                    isResponseArrived ? (
                      <CircularProgress color="secondary" size={20} />
                    ) : null
                  }
                >
                  <SaveIcon />
                </Button>
              </Tooltip>
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default FileUploadSection;


