function FakeBackendForConditionColumn() {
    return (
        {
            "content": [
                {
                    "createdBy": "ADMIN",
                    "createdTime": "2021-06-03",
                    "updatedBy": null,
                    "updatedTime": "2021-06-09",
                    "active": true,
                    "createdDtDisp": "03-06-2021",
                    "lastUpdatedDtDisp": "09-06-2021",
                    "id": 1,
                    "columnName": "CUSTOMER BP CODE",
                    "process": {
                        "process": "Invoice",
                        "processId": 5
                    },
                    "condition": "",
                    "values": ''
                },
                {
                    "createdBy": "ADMIN",
                    "createdTime": "2021-06-03",
                    "updatedBy": null,
                    "updatedTime": "2021-06-09",
                    "active": true,
                    "createdDtDisp": "03-06-2021",
                    "lastUpdatedDtDisp": "09-06-2021",
                    "id": 2,
                    "columnName": "SHIP TO BP CODE",
                    "process": {
                        "process": "Invoice",
                        "processId": 5
                    },
                    "condition": "",
                    "values": ''
                },
                {
                    "createdBy": "ADMIN",
                    "createdTime": "2021-06-03",
                    "updatedBy": null,
                    "updatedTime": "2021-06-09",
                    "active": true,
                    "createdDtDisp": "03-06-2021",
                    "lastUpdatedDtDisp": "09-06-2021",
                    "id": 3,
                    "columnName": "INVOICE TYPE",
                    "process": {
                        "process": "Invoice",
                        "processId": 5
                    },
                    "condition": "",
                    "values": ''
                },
            ],
            "pageable": {
                "sort": {
                    "sorted": true,
                    "unsorted": false,
                    "empty": false
                },
                "offset": 0,
                "pageSize": 25,
                "pageNumber": 0,
                "paged": true,
                "unpaged": false
            },
            "totalElements": 1,
            "last": true,
            "totalPages": 1,
            "size": 25,
            "number": 0,
            "sort": {
                "sorted": true,
                "unsorted": false,
                "empty": false
            },
            "numberOfElements": 1,
            "first": true,
            "empty": false
        }
    )
}

export default FakeBackendForConditionColumn;