import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
    Button,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Snackbar,
    useTheme,
    useMediaQuery,
    Tooltip,
    Badge,
    Paper
} from "@mui/material";
import { Alert } from "@mui/lab";
import useStyles from "../../common/CommonStyle/CommonStyle";
import { FormattedMessage, useIntl } from "react-intl";
import CloseIcon from '@mui/icons-material/Close';
import { DataGridPro, getGridDateOperators, getGridStringOperators, GridFilterPanel, GridToolbarColumnsButton, GridToolbarDensitySelector, LicenseInfo } from '@mui/x-data-grid-pro';
import { CommonService } from '../../../services';
import { FilterList } from "@mui/icons-material";
import RefreshIcon from '@mui/icons-material/Refresh';
import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import { CustomFilters } from '../../common/CustomFilters/CustomFilters';
import CustomFilterComponent, { GetFilterableColumns } from '../../common/CustomFilterComponent';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommonBackDrop from '../../common/BackDrop';

const columnFieldsName = {
    entityCode: 'entityCode',
    bpCode: 'bpCode',
    bpName: 'bpName',
    requestedBy: 'requestedBy',
    requestEndDt: 'requestEndDt',
    requestStatusForDisplay: 'requestStatusForDisplay',
    requestStatus: 'requestStatus',
    uploadFromDate: 'uploadFromDate',
    uploadToDate: 'uploadToDate',
    errorLog: 'errorLog'
}

const DeliveryConfirmationStatistics = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const localtext = GridTextLocalization();
    const selectedLanguage = useContext(SupportedLanguageDetails);

    const {
        onClose,
        open,
    } = props;

    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        },
    };

    const dateValue = new Date();

    const dateValue1 = new Date();

    const [paramBody, setParamBody] = useState({
        body: {
            entityCode: defaultEntity,
            uploadFromDate: new Date(dateValue.setDate(dateValue.getDate() - 7)).toISOString().slice(0, 10),
            uploadToDate: new Date(dateValue1.setDate(dateValue1.getDate())).toISOString().slice(0, 10),
            recordRequestStatus: 'All'
        },
        pageNo: 0,
        sortDir: 'desc',
        sortKey: 'requestEndDt',
        recordsPerPage: 25
    });


    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });

    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [openFilterPanel, setFilterPanel] = useState(false);
    const [filterCount, setFilterCount] = React.useState(0);
    const [refreshTable, setRefreshTable] = useState(false);
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();

    const [searchedEntityCode, setSearchedEntityCode] = useState('');
    const [searchedBpCode, setSearchedBpCode] = useState('');
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [searchedBpName, setSearchedBpName] = useState('');
    const [searchedUploadFromDate, setSearchedUploadFromDate] = useState(null);
    const [searchedUploadToDate, setSearchedUploadToDate] = useState(null);
    const [downloadReportdlvycfmStat, setDownloadReportdlvycfmStat] = useState(false);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [filterInitialState, setFilterInitialState] = useState(false);
    const [searchedrequestStatus, setSearchedrequestStatus] = useState(null);

    var defaultEntity = loggedInUserInfo.defaultEntity;

    const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.entityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity },
        { columnField: columnFieldsName.uploadFromDate, headerName: 'Upload From Date', id: 1, value: new Date(dateValue.setDate(dateValue.getDate() - 0)).toISOString().slice(0, 10) },
        { columnField: columnFieldsName.uploadToDate, headerName: 'Upload to Date', id: 2, value: new Date(dateValue1.setDate(dateValue1.getDate())).toISOString().slice(0, 10) },
        ]
        : [{ columnField: columnFieldsName.uploadFromDate, headerName: 'Upload From Date', id: 0, value: new Date(dateValue.setDate(dateValue.getDate() - 0)).toISOString().slice(0, 10) },
        { columnField: columnFieldsName.uploadToDate, headerName: 'Upload to Date', id: 1, value: new Date(dateValue1.setDate(dateValue1.getDate())).toISOString().slice(0, 10) }];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);

    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const handleClose = (value) => {
        onClose(value);
        setSelectedFilters(defaulSelectedFilters);
        setParamBody({
            body: {
                entityCode: defaultEntity,
                uploadFromDate: searchedUploadFromDate,
                uploadToDate: searchedUploadToDate,
                recordRequestStatus: 'All'
            },
            pageNo: 0,
            sortDir: 'desc',
            sortKey: 'requestEndDt',
            recordsPerPage: 25
        })
    };
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };


    const handleCloseForFilter = () => {
        setFilterPanel(false);
    }


    const columnForDlvyCfmStatistics = [
        {
            field: columnFieldsName.entityCode,
            headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
            width: 140,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'string',
            filterable: true,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.bpName,
            headerName: intl.formatMessage({ id: "columnName.bpname.label" }),
            width: 300,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            filterable: true,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.bpCode,
            headerName: intl.formatMessage({ id: "common.textfield.columname.bpcode" }),
            width: 180,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'string',
            filterable: true,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.requestStatus,
            headerName: intl.formatMessage({ id: "common.textfield.columname.requestStatusForDisplay" }),
            width: 200,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            filterable: true,
            hide: false,
            valueGetter: (params) => `${params.row.requestStatusForDisplay}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.errorLog,
            headerName: intl.formatMessage({ id: "columnname.errormessage.label" }),
            width: 500,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.requestedBy,
            headerName: intl.formatMessage({ id: "common.textfield.columname.modifiedBy" }),
            width: 200,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.requestEndDt,
            headerName: intl.formatMessage({ id: "common.textfield.columname.createdDt" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 200,
            sortable: true,
            type: 'dateTime',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.uploadFromDate,
            headerName: intl.formatMessage({ id: "common.textfield.columname.uploadFromDate" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 0,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.uploadToDate,
            headerName: intl.formatMessage({ id: "common.textfield.columname.uploadToDate" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 0,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
    ];

    useEffect(() => {
        if (open) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.deliveryConfirmationStatistics, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }

    }, [paramBody, open])


    useEffect(() => {
        setColumns(columnForDlvyCfmStatistics);
    }, [selectedLanguage]);

    if (columns.length > 0) {
        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.entityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.entityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };

        const supplierNameColumn = columns.find((column) => column.field === columnFieldsName.bpName);
        const supplierNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.bpName);

        const supplierNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.SupplierVendorNameInputValue,
            }),
        );

        columns[supplierNameColIndex] = {
            ...supplierNameColumn,
            filterOperators: supplierNameFilterOperators,
        };

        const uploadFromDateTimeColumn = columns.find((column) => column.field === columnFieldsName.uploadFromDate);
        const uploadFromDateTimeColIndex = columns.findIndex((col) => col.field === columnFieldsName.uploadFromDate);

        const uploadFromDateTimeFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[uploadFromDateTimeColIndex] = {
            ...uploadFromDateTimeColumn,
            filterOperators: uploadFromDateTimeFilterOperators,
        };

        const uploadToDateTimeColumn = columns.find((column) => column.field === columnFieldsName.uploadToDate);
        const uploadToDateTimeColIndex = columns.findIndex((col) => col.field === columnFieldsName.uploadToDate);

        const uploadToDateTimeFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[uploadToDateTimeColIndex] = {
            ...uploadToDateTimeColumn,
            filterOperators: uploadToDateTimeFilterOperators,
        };

        const requestStatusColumn = columns.find((column) => column.field === columnFieldsName.requestStatus);
        const requestStatusColIndex = columns.findIndex((col) => col.field === columnFieldsName.requestStatus);

        const requestStatusFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.RequestStatusInputValue,
            }),
        );

        columns[requestStatusColIndex] = {
            ...requestStatusColumn,
            filterOperators: requestStatusFilterOperators,
        };

    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    useEffect(() => {
        let entityCode = null;
        let uploadFromDate = null;
        let uploadToDate = null;
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'entityCode':
                    entityCode = columns.value
                    break;
                case 'uploadFromDate':
                    uploadFromDate = columns.value
                    break;
                case 'uploadToDate':
                    uploadToDate = columns.value
                    break;
            }
        })
        setSearchedEntityCode(entityCode);
        setSearchedUploadToDate(uploadToDate);
        setSearchedUploadFromDate(uploadFromDate);
    }, [selectedFilters, paramBody])

    const applyFilter = () => {
        let entityCode = null;
        let bpCode = null;
        let bpName = null;
        let uploadFromDate = null;
        let uploadToDate = null;
        let requestStatus = null;

        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'bpName':
                    bpName = columns.value
                    break;
                case 'entityCode':
                    entityCode = columns.value
                    break;
                case 'bpCode':
                    bpCode = columns.value
                    break;
                case 'uploadFromDate':
                    uploadFromDate = columns.value
                    break;
                case 'uploadToDate':
                    uploadToDate = columns.value
                    break;
                case 'requestStatus':
                    requestStatus = columns.value
                    break;
            }
        })

        setSearchedEntityCode(entityCode);
        setSearchedBpCode(bpCode);
        setSearchedBpName(bpName);
        setSearchedUploadFromDate(uploadFromDate);
        setSearchedUploadToDate(uploadToDate);
        setSearchedrequestStatus(requestStatus);

        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    entityCode: entityCode,
                    bpCode: bpCode,
                    bpName: bpName,
                    uploadFromDate: uploadFromDate,
                    uploadToDate: uploadToDate,
                    requestStatus: requestStatus === 'PROCESSING' ? 0 : requestStatus === 'COMPLETED' ? 1 : requestStatus === 'FAILED' ? 2 : requestStatus === 'DATA ERROR' ? 3 : requestStatus === 'JOB TERMINATED' ? 4 : requestStatus === 'PARTIAL IMPORT' ? 5 : '',
                    recordRequestStatus: requestStatus === '' ? 'All' : requestStatus === 'PROCESSING' ? 0 : requestStatus === 'COMPLETED' ? 1 : requestStatus === 'FAILED' ? 2 : requestStatus === 'DATA ERROR' ? 3 : requestStatus === 'JOB TERMINATED' ? 4 : requestStatus === 'PARTIAL IMPORT' ? 5 : 'All',
                }
            }
        })
    }

    useEffect(() => {
        setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    const parameters = {

        entityCode: searchedEntityCode,
        bpCode: searchedBpCode,
        bpName: searchedBpName,
        uploadFromDate: searchedUploadFromDate,
        uploadToDate: searchedUploadToDate,
        requestStatus: searchedrequestStatus === 'PROCESSING' ? 0 : searchedrequestStatus === 'COMPLETED' ? 1 : searchedrequestStatus === 'FAILED' ? 2 : searchedrequestStatus === 'DATA ERROR' ? 3 : searchedrequestStatus === 'JOB TERMINATED' ? 4 : searchedrequestStatus === 'PARTIAL IMPORT' ? 5 : '',
        recordRequestStatus: searchedrequestStatus === '' ? 'All' : searchedrequestStatus === 'PROCESSING' ? 0 : searchedrequestStatus === 'COMPLETED' ? 1 : searchedrequestStatus === 'FAILED' ? 2 : searchedrequestStatus === 'DATA ERROR' ? 3 : searchedrequestStatus === 'JOB TERMINATED' ? 4 : searchedrequestStatus === 'PARTIAL IMPORT' ? 5 : 'All',
    }

    useEffect(() => {
        if (filterInitialState) {
            if (searchedEntityCode !== '' && searchedUploadFromDate !== null && searchedUploadToDate !== null) {
                if (downloadReportdlvycfmStat) {
                    setOpenBackDrop(true);
                    CommonService.saveOrUpdateOrPost('/reports/supplierUpldDlvCfm', parameters)
                        .then((response) => {
                            setAlertBoxState({
                                ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success"
                            });
                            setOpenBackDrop(false);
                        })
                        .catch(function (error) {
                            setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                            setOpenBackDrop(false);
                        });
                    setDownloadReportdlvycfmStat(false);
                }
            } else {
                setAlertBoxState({
                    ...alertBoxState, openAlert: true, message: "Kindly input these filters (Upload From Date , Upload To Date And Entity Code) to download Delivery Confirmation Statistics Report", severity: "warning"
                });
                setOpenBackDrop(false);
                setDownloadReportdlvycfmStat(false);
            }
            setFilterInitialState(false);
        }
    }, [downloadReportdlvycfmStat]);

    return (
        <React.Fragment>
            <CssBaseline />
            <CommonBackDrop opne={openBackDrop} />
            <CustomFilterComponent open={openFilterPanel} onClose={handleCloseForFilter} filterableColumns={filterableColumns}
                setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                defaulSelectedFilters={defaulSelectedFilters} />
            <Grid container className={classes.root}>
                <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <Dialog fullWidth maxWidth='lg' onClose={handleClose} aria-labelledby="create-or-delete-dialog" open={open} disableBackdropClick={true}  >
                        <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            open={openAlert}
                            onClose={handleCloseAlert}
                            key={vertical + horizontal}
                            autoHideDuration={9000}
                        >
                            <Alert onClose={handleCloseAlert} severity={severity}>
                                {message}
                            </Alert>
                        </Snackbar>
                        <DialogTitle color="primary" className={classes.title}>
                            <Grid container justifyContent='flex-start' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.dialogPOScreen}>
                                <strong>{intl.formatMessage({ id: "supplierUpldDlvCfm.button" })}</strong>
                            </Grid>
                        </DialogTitle>

                        <DialogContent dividers style={{ minHeight: '70vh' }} >
                            <Paper elevation={3} className={classes.gridHeightAndWidthForViewDlvyStatsData}>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <DataGridPro
                                        className={classes.customTableStyle}
                                        sortingMode="server"
                                        rows={tableData}
                                        density="compact"
                                        columns={columns}
                                        localeText={localtext}
                                        pageSize={paramBody.recordsPerPage}
                                        loading={tableLoadingStatus}
                                        rowCount={totalRowCount}
                                        getRowId={(row) => row.id}
                                        scrollbarSize={30}
                                        disableColumnFilter={true}
                                        rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                                        pagination
                                        paginationMode="server"
                                        disableSelectionOnClick={true}
                                        disableMultipleColumnsSorting={true}
                                        hideFooter={true}

                                        onPageSizeChange={((pageParams) => {
                                            setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                                        })}
                                        onPageChange={((pageParams) => {
                                            setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                                        })}
                                        onSortModelChange={(params) => {

                                            let sortModel = params[0];
                                            var previousParamBody = paramBody;
                                            if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                                setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                            } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                                setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                            }
                                        }}
                                        sortingOrder={['desc', 'asc']}
                                        sortModel={[{
                                            field: paramBody.sortKey,
                                            sort: paramBody.sortDir,
                                        }]}
                                        filterMode="server"

                                        components={{
                                            Toolbar: () => {
                                                return (
                                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center"
                                                        style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%', marginLeft: '1%' }}>
                                                        <Grid
                                                            item
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            xs={12}
                                                            sm={12}
                                                            md={6}
                                                            lg={6}>

                                                            <Grid>
                                                                <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip"
                                                                    defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                                    <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid>
                                                                <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                                    placement='bottom'>
                                                                    <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid>
                                                                <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                                    <span>
                                                                        <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                            <Badge color="primary" badgeContent={filterCount}>
                                                                                <FilterList />
                                                                            </Badge>
                                                                        </Button>
                                                                    </span>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid>
                                                                <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                                    <span>
                                                                        <Button size="small" color="primary" variant="outlined"
                                                                            className={classes.refreshToobarStyle}
                                                                            onClick={(() => {
                                                                                setRefreshTable(!refreshTable);
                                                                            })}>
                                                                            <RefreshIcon />
                                                                        </Button>
                                                                    </span>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid>
                                                                {(<Tooltip title={<FormattedMessage id="supplierUpldDlvCfm.button" />}
                                                                    placement='bottom'>
                                                                    <span>
                                                                        <Button size='small' className={classes.refreshToobarStyle} color="primary" variant="outlined"
                                                                            component="span"
                                                                            disabled={tableData.length <= 0}
                                                                            onClick={(() => {
                                                                                setFilterInitialState(true);
                                                                                setDownloadReportdlvycfmStat(true);
                                                                            })}
                                                                        > <FileDownloadIcon />
                                                                        </Button>
                                                                    </span>

                                                                </Tooltip>)}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <CommonGridPagination />
                                                        </Grid>
                                                    </Grid>
                                                );
                                            },
                                        }}

                                    />
                                </div>
                            </Paper>
                        </DialogContent>


                        <DialogActions className={classes.dialogAction} >
                            <Grid item container justifyContent="flex-start" xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} style={{ marginLeft: '-1.0%' }}>
                            </Grid>
                            <Grid item container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} >

                                <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2} endIcon={<CloseIcon />}
                                    onClick={() => handleClose(true)}
                                ><FormattedMessage id="common.button.cancel.lable" defaultMessage="Close" />
                                </Button>
                            </Grid>
                        </DialogActions>

                    </Dialog>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
export default DeliveryConfirmationStatistics;