import React from 'react';

const GetFilterableColumns = (availableColumns) => {

    if (availableColumns !== undefined) {
        const filterableColumns = availableColumns.filter((columns) => {
            if (columns.filterable !== false) {
                return columns
            }
        })

        return filterableColumns;
    } else {
        return []
    }
}

export { GetFilterableColumns }