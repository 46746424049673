import React, { useState } from 'react';
import List from '@mui/material/List';
import IconAccount from '@mui/icons-material/AccountBalance';
import IconSettings from '@mui/icons-material/Settings';
import IconSupplierGroup from '@mui/icons-material/SupervisedUserCircle';
import IconCustomerGroup from '@mui/icons-material/GroupWork';
import IconUserManagement from '@mui/icons-material/AccountCircle';
import IconRole from '@mui/icons-material/SupervisorAccount';
import IconBranch from '@mui/icons-material/AccountTree';
import IconRegion from '@mui/icons-material/ViewCarousel';
import IconOrder from '@mui/icons-material/Assignment';
import IconSupplier from '@mui/icons-material/LocalShipping';
import IconDefaultMenu from '@mui/icons-material/Apps';
import IconForecast from '@mui/icons-material/TrendingUp';
import NavList from './NavList';
import { CommonService } from '../../../../services';
import useStyles from '../../CommonStyle/CommonStyle';
import IconCustomer from '@mui/icons-material/Person';
import IconEmailTemplate from '@mui/icons-material/Email';
import IconMessageConfig from '@mui/icons-material/Message';
import IconEmailMessageConfig from '@mui/icons-material/ContactMail';
import IconERPInterface from '@mui/icons-material/CompareArrows';
import IconERPInterfaceStatus from '@mui/icons-material/ListAlt';
import Iconmessagetransmissionlog from '@mui/icons-material/Book';
import IconInterfaceConfiguration from '@mui/icons-material/SwapCalls';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import IconMessage from '@mui/icons-material/Forum';
import IconApproval from '@mui/icons-material/LibraryAddCheck';
import IconReportTemplate from '@mui/icons-material/ImportContacts';
import IconEmailMessageLog from '@mui/icons-material/RateReview';
import IconTransmissionConfiguration from '@mui/icons-material/PermDataSetting';
import IconAdvanceShipmentNoteOutbound from '@mui/icons-material/Receipt';
import IconPurchaseOrderPurchaseForecastOutbound from '@mui/icons-material/AssignmentReturn';
import IconInvoice from '@mui/icons-material/SpeakerNotes';
import IconViewRequest from '@mui/icons-material/Equalizer';
import IconProject from '@mui/icons-material/AccountTree';
import IconEmailNotificationSubscription from '@mui/icons-material/MarkEmailRead';
import IconEmailServerConfig from '@mui/icons-material/Storage';
import IconImportTemplate from '@mui/icons-material/UploadFile';
import IconPackingList from '@mui/icons-material/Inventory';

const SidebarNav = props => {
  const { isCollapsed, setIsSidebarCollapsed } = props;
  const classes = useStyles();
  const user = CommonService.getLoggedInUserInfo();
  const userRoles = user !== null ? user.roles : [];

  function prepareMenuItem(menu, roleModuleAccessList, previouslyPresentSubMenus) {
    let actualMenu = menu.menu;
    let subMenus = previouslyPresentSubMenus;
    {
      actualMenu.menuModules.map((moduleElement) => {    // loading Menus 
        {
          roleModuleAccessList.map((accessModule) => {
            let module = accessModule.module;
            //MATERIAL ICON NAME AND THE ICON NAME SAVED IN BACK-END SHOULD BE SAME
            let materialSubMenuIcon;
            if (module.iconName === 'IconRole') {
              materialSubMenuIcon = IconRole;
            } else if (module.iconName === 'IconBranch') {
              materialSubMenuIcon = IconBranch;
            } else if (module.iconName === 'IconRegion') {
              materialSubMenuIcon = IconRegion;
            } else if (module.iconName === 'IconAccount') {
              materialSubMenuIcon = IconAccount;
            } else if (module.iconName === 'IconUserManagement') {
              materialSubMenuIcon = IconUserManagement;
            } else if (module.iconName === 'IconSupplier') {
              materialSubMenuIcon = IconSupplier;
            } else if (module.iconName === 'IconCustomer') {
              materialSubMenuIcon = IconCustomer;
            } else if (module.iconName === 'IconSupplierGroup') {
              materialSubMenuIcon = IconCustomerGroup;
            } else if (module.iconName === 'IconCustomerGroup') {
              materialSubMenuIcon = IconSupplierGroup;
            } else if (module.iconName === 'IconERPInterface') {
              materialSubMenuIcon = IconERPInterface;
            } else if (module.iconName === 'IconERPInterfaceStatus') {
              materialSubMenuIcon = IconERPInterfaceStatus;
            } else if (module.iconName === 'IconEmailTemplate') {
              materialSubMenuIcon = IconEmailTemplate;
            } else if (module.iconName === 'IconMessageConfig') {
              materialSubMenuIcon = IconMessageConfig;
            } else if (module.iconName === 'IconEmailMessageConfig') {
              materialSubMenuIcon = IconEmailMessageConfig;
            } else if (module.iconName === 'Iconmessagetransmissionlog') {
              materialSubMenuIcon = Iconmessagetransmissionlog;
            } else if (module.iconName === 'IconOrder') {
              materialSubMenuIcon = IconOrder;
            } else if (module.iconName === 'IconForecast') {
              materialSubMenuIcon = IconForecast;
            } else if (module.iconName === 'IconApproval') {
              materialSubMenuIcon = IconApproval;
            } else if (module.iconName === 'IconReportTemplate') {
              materialSubMenuIcon = IconReportTemplate;
            } else if (module.iconName === 'IconEmailMsgLog') {
              materialSubMenuIcon = IconEmailMessageLog;
            } else if (module.iconName === 'IconEmailMsgLog') {
              materialSubMenuIcon = IconEmailMessageLog;
            } else if (module.iconName === 'IconTransmissionConfig') {
              materialSubMenuIcon = IconTransmissionConfiguration;
            } else if (module.iconName === 'IconAdvanceShipmentNoteOutbound') {
              materialSubMenuIcon = IconAdvanceShipmentNoteOutbound;
            } else if (module.iconName === 'IconPurchaseOrderPurchaseForecastOutbound') {
              materialSubMenuIcon = IconPurchaseOrderPurchaseForecastOutbound;
            } else if (module.iconName === 'IconInvoice') {
              materialSubMenuIcon = IconInvoice;
            } else if (module.iconName === 'IconEmailNotificationSubscription') {
              materialSubMenuIcon = IconEmailNotificationSubscription;
            } else if (module.iconName === 'IconEmailServerConfig') {
              materialSubMenuIcon = IconEmailServerConfig;
            } else if (module.iconName === 'IconProject') {
              materialSubMenuIcon = IconProject;
            } else if (module.iconName === 'IconPackingList') {
              materialSubMenuIcon = IconPackingList;
            } else {
              materialSubMenuIcon = IconRole;
            }
            if (module.moduleId === moduleElement.module.moduleId && accessModule.readFlag && moduleElement.module.displayedOnUi) {
              if ((subMenus.length === 0) || subMenus.findIndex((element) => element.id === module.moduleId) === -1) {
                let moduleObject = {
                  id: module.moduleId,
                  name: module.name,
                  link: module.moduleRoutingPath,
                  Icon: materialSubMenuIcon,
                  setIsSidebarCollapsed: setIsSidebarCollapsed
                }
                subMenus.push(moduleObject);
              }

            }
          })
        }
      })
    }
    //MATERIAL ICON NAME AND THE ICON NAME SAVED IN BACK-END SHOULD BE SAME 
    let materialMenuIcon;
    if (actualMenu.iconName === 'IconSettings') {
      materialMenuIcon = IconSettings;
    } else if (actualMenu.iconName === 'IconInterfaceConfiguration') {
      materialMenuIcon = IconInterfaceConfiguration;
    } else if (actualMenu.iconName === 'IconMessageConfiguration') {
      materialMenuIcon = IconMessageConfiguration;
    } else if (actualMenu.iconName === 'IconMessage') {
      materialMenuIcon = IconMessage;
    } else if (actualMenu.iconName === 'IconOrder') {
      materialMenuIcon = IconOrder;
    } else if (actualMenu.iconName === 'IconForecast') {
      materialMenuIcon = IconForecast;
    } else if (actualMenu.iconName === 'IconViewRequest') {
      materialMenuIcon = IconViewRequest;
    } else if (actualMenu.iconName === 'IconImportTemplate') {
      materialMenuIcon = IconImportTemplate;
    } else if (actualMenu.iconName === 'IconApproval') {
      materialMenuIcon = IconApproval;
    } else {
      materialMenuIcon = IconDefaultMenu;
    }
    let menuObject = {
      menuId: actualMenu.menuId,
      name: actualMenu.name,
      link: ((subMenus.length > 0) ? undefined : actualMenu.menuRoutingPath),
      Icon: materialMenuIcon,
      items: subMenus
    }
    return menuObject;
  }

  const [menuAndSubMenuList] = useState(() => {
    let tempMenuAndSubMenuList = [];
    {
      userRoles.map((role) => {
        {
          role.roleMenus.map((menu) => {
            let isMenuAlreadyPresent = false;
            if (tempMenuAndSubMenuList.length > 0) {
              for (let i = 0; i < tempMenuAndSubMenuList.length; i++) {
                if (tempMenuAndSubMenuList[i].menuId === menu.menu.menuId && menu.active) {
                  isMenuAlreadyPresent = true;
                  break;
                }
              }
              if (!isMenuAlreadyPresent && menu.active) {
                let menuObject = prepareMenuItem(menu, role.roleModuleAccess, []);
                tempMenuAndSubMenuList.push(menuObject);
              } else if (menu.active && tempMenuAndSubMenuList.length > 0) {
                let alreadyPushedMenuIndex = tempMenuAndSubMenuList.findIndex((element) => element.menuId === menu.menu.menuId);
                let alreadyPresentMenuObject = tempMenuAndSubMenuList.find((element) => element.menuId === menu.menu.menuId);
                alreadyPresentMenuObject = ((alreadyPresentMenuObject !== null && alreadyPresentMenuObject !== undefined)
                  && alreadyPresentMenuObject.items.length > 0) ? alreadyPresentMenuObject.items : [];
                let menuObject = prepareMenuItem(menu, role.roleModuleAccess, alreadyPresentMenuObject);
                tempMenuAndSubMenuList.splice(alreadyPushedMenuIndex, 1, menuObject);
              }
            }
            else {
              if (menu.active) {
                let menuObject = prepareMenuItem(menu, role.roleModuleAccess, []);
                tempMenuAndSubMenuList.push(menuObject);
              }
            }

          })
        }
      })
    }
    return tempMenuAndSubMenuList;
  });

  return (
    <div>
      <List className={classes.navList} disablePadding>
        <NavList isCollapsed={isCollapsed} items={menuAndSubMenuList} setIsSidebarCollapsed={setIsSidebarCollapsed} />
      </List>
    </div>
  )
}


export default SidebarNav

