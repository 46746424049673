import {
  AppBar, Box, Button, CircularProgress, CssBaseline, Dialog, DialogContent, Grid, MenuItem, Snackbar, Switch, Tab,
  Tabs, Tooltip, Typography, useMediaQuery, useTheme
} from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import React, { useContext, useEffect, useState } from "react";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconSaveOrUpdate from "@mui/icons-material/SaveOutlined";
import IconRestore from "@mui/icons-material/AutorenewOutlined";
import { LoggedInUserDetails } from "../components/common/LoggedInUserDetails/LoggedInUserDetails";
import { Alert } from "@mui/lab";
import CreateIcon from "@mui/icons-material/Add";
import ViewIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import { GlobalEdiApiConstants } from "../Constants/GlobalEdiApiConstants";
import { ModuleAccessPermissionKey } from "../Constants/ModuleAccessKey";
import InfoIcon from "@mui/icons-material/Info";
import _ from "lodash/fp";
import CustomBreadCrumb from "../components/common/CustomBreadCrumb";
import { withStyles } from "@mui/styles";
import MuiDialogActions from "@mui/material/DialogActions";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import useStyles from "../components/common/CommonStyle/CommonStyle";
import { Autocomplete } from "@mui/lab";
import TimeZoneCombo from "./SchedulerTabCombos/TimeZoneCombos";
import FreqCombo from "../components/common/Combos/CustomFreqCombo/PcnFreqCombo";
import CustomProcessDateCombo from "../components/common/Combos/CustomProcessDateCombo/CustomProcessDateCombo";

const everyHourValue = [
  { id: 1, value: "0", time: "0AM" },
  { id: 2, value: "1", time: "1AM" },
  { id: 3, value: "2", time: "2AM" },
  { id: 4, value: "3", time: "3AM" },
  { id: 5, value: "4", time: "4AM" },
  { id: 6, value: "5", time: "5AM" },
  { id: 7, value: "6", time: "6AM" },
  { id: 8, value: "7", time: "7AM" },
  { id: 9, value: "8", time: "8AM" },
  { id: 10, value: "9", time: "9AM" },
  { id: 11, value: "10", time: "10AM" },
  { id: 12, value: "11", time: "11AM" },
  { id: 13, value: "12", time: "12AM" },
  { id: 14, value: "13", time: "1PM" },
  { id: 15, value: "14", time: "2PM" },
  { id: 16, value: "15", time: "3PM" },
  { id: 17, value: "16", time: "4PM" },
  { id: 18, value: "17", time: "5PM" },
  { id: 19, value: "18", time: "6PM" },
  { id: 20, value: "19", time: "7PM" },
  { id: 21, value: "20", time: "8PM" },
  { id: 22, value: "21", time: "9PM" },
  { id: 23, value: "22", time: "10PM" },
  { id: 24, value: "23", time: "11PM" },
];
const everyMinuteValue = [
  { id: 1, value: "0", minute: "0" },
  { id: 2, value: "1", minute: "1" },
  { id: 3, value: "2", minute: "2" },
  { id: 4, value: "3", minute: "3" },
  { id: 5, value: "4", minute: "4" },
  { id: 6, value: "5", minute: "5" },
  { id: 7, value: "6", minute: "6" },
  { id: 8, value: "7", minute: "7" },
  { id: 9, value: "8", minute: "8" },
  { id: 10, value: "9", minute: "9" },
  { id: 11, value: "10", minute: "10" },
  { id: 12, value: "11", minute: "11" },
  { id: 13, value: "12", minute: "12" },
  { id: 14, value: "13", minute: "13" },
  { id: 15, value: "14", minute: "14" },
  { id: 16, value: "15", minute: "15" },
  { id: 17, value: "16", minute: "16" },
  { id: 18, value: "17", minute: "17" },
  { id: 19, value: "18", minute: "18" },
  { id: 20, value: "19", minute: "19" },
  { id: 21, value: "20", minute: "20" },
  { id: 22, value: "21", minute: "21" },
  { id: 23, value: "22", minute: "22" },
  { id: 24, value: "23", minute: "23" },
  { id: 25, value: "24", minute: "24" },
  { id: 26, value: "25", minute: "25" },
  { id: 27, value: "26", minute: "26" },
  { id: 28, value: "27", minute: "27" },
  { id: 29, value: "28", minute: "28" },
  { id: 30, value: "29", minute: "29" },
  { id: 31, value: "30", minute: "30" },
  { id: 32, value: "31", minute: "31" },
  { id: 33, value: "32", minute: "32" },
  { id: 34, value: "33", minute: "33" },
  { id: 35, value: "34", minute: "34" },
  { id: 36, value: "35", minute: "35" },
  { id: 37, value: "36", minute: "36" },
  { id: 38, value: "37", minute: "37" },
  { id: 39, value: "38", minute: "38" },
  { id: 40, value: "39", minute: "39" },
  { id: 41, value: "40", minute: "40" },
  { id: 42, value: "41", minute: "41" },
  { id: 43, value: "42", minute: "42" },
  { id: 44, value: "43", minute: "43" },
  { id: 45, value: "44", minute: "44" },
  { id: 46, value: "45", minute: "45" },
  { id: 47, value: "46", minute: "46" },
  { id: 48, value: "47", minute: "47" },
  { id: 49, value: "48", minute: "48" },
  { id: 50, value: "49", minute: "49" },
  { id: 51, value: "50", minute: "50" },
  { id: 52, value: "51", minute: "51" },
  { id: 53, value: "52", minute: "52" },
  { id: 54, value: "53", minute: "53" },
  { id: 55, value: "54", minute: "54" },
  { id: 56, value: "55", minute: "55" },
  { id: 57, value: "56", minute: "56" },
  { id: 58, value: "57", minute: "57" },
  { id: 59, value: "58", minute: "58" },
  { id: 60, value: "59", minute: "59" },
  { id: 61, value: "*/1", minute: "1" },
  { id: 62, value: "*/2", minute: "2" },
  { id: 63, value: "*/3", minute: "3" },
  { id: 64, value: "*/4", minute: "4" },
  { id: 65, value: "*/5", minute: "5" },
  { id: 66, value: "*/6", minute: "6" },
  { id: 67, value: "*/7", minute: "7" },
  { id: 68, value: "*/8", minute: "8" },
  { id: 69, value: "*/9", minute: "9" },
  { id: 70, value: "*/10", minute: "10" },
  { id: 71, value: "*/11", minute: "11" },
  { id: 72, value: "*/12", minute: "12" },
  { id: 73, value: "*/13", minute: "13" },
  { id: 74, value: "*/14", minute: "14" },
  { id: 75, value: "*/15", minute: "15" },
  { id: 76, value: "*/16", minute: "16" },
  { id: 77, value: "*/17", minute: "17" },
  { id: 78, value: "*/18", minute: "18" },
  { id: 79, value: "*/19", minute: "19" },
  { id: 80, value: "*/20", minute: "20" },
  { id: 81, value: "*/21", minute: "21" },
  { id: 82, value: "*/22", minute: "22" },
  { id: 83, value: "*/23", minute: "23" },
  { id: 84, value: "*/24", minute: "24" },
  { id: 85, value: "*/25", minute: "25" },
  { id: 86, value: "*/26", minute: "26" },
  { id: 87, value: "*/27", minute: "27" },
  { id: 88, value: "*/28", minute: "28" },
  { id: 89, value: "*/29", minute: "29" },
  { id: 90, value: "*/30", minute: "30" },
  { id: 91, value: "*/31", minute: "31" },
  { id: 92, value: "*/32", minute: "32" },
  { id: 93, value: "*/33", minute: "33" },
  { id: 94, value: "*/34", minute: "34" },
  { id: 95, value: "*/35", minute: "35" },
  { id: 96, value: "*/36", minute: "36" },
  { id: 97, value: "*/37", minute: "37" },
  { id: 98, value: "*/38", minute: "38" },
  { id: 99, value: "*/39", minute: "39" },
  { id: 100, value: "*/40", minute: "40" },
  { id: 101, value: "*/41", minute: "41" },
  { id: 102, value: "*/42", minute: "42" },
  { id: 103, value: "*/43", minute: "43" },
  { id: 104, value: "*/44", minute: "44" },
  { id: 105, value: "*/45", minute: "45" },
  { id: 106, value: "*/46", minute: "46" },
  { id: 107, value: "*/47", minute: "47" },
  { id: 108, value: "*/48", minute: "48" },
  { id: 109, value: "*/49", minute: "49" },
  { id: 110, value: "*/50", minute: "50" },
  { id: 111, value: "*/51", minute: "51" },
  { id: 112, value: "*/52", minute: "52" },
  { id: 113, value: "*/53", minute: "53" },
  { id: 114, value: "*/54", minute: "54" },
  { id: 115, value: "*/55", minute: "55" },
  { id: 116, value: "*/56", minute: "56" },
  { id: 117, value: "*/57", minute: "57" },
  { id: 118, value: "*/58", minute: "58" },
  { id: 119, value: "*/59", minute: "59" },
];
const everyDayValue = [
  { id: 1, value: "1", ordinals: "1st" },
  { id: 2, value: "2", ordinals: "2nd" },
  { id: 3, value: "3", ordinals: "3rd" },
  { id: 4, value: "4", ordinals: "4th" },
  { id: 5, value: "5", ordinals: "5th" },
  { id: 6, value: "6", ordinals: "6th" },
  { id: 7, value: "7", ordinals: "7th" },
  { id: 8, value: "8", ordinals: "8th" },
  { id: 9, value: "9", ordinals: "9th" },
  { id: 10, value: "10", ordinals: "10th" },
  { id: 11, value: "11", ordinals: "11th" },
  { id: 12, value: "12", ordinals: "12th" },
  { id: 13, value: "12", ordinals: "13th" },
  { id: 14, value: "14", ordinals: "14th" },
  { id: 15, value: "15", ordinals: "15th" },
  { id: 16, value: "16", ordinals: "16th" },
  { id: 17, value: "17", ordinals: "17th" },
  { id: 18, value: "18", ordinals: "18th" },
  { id: 19, value: "19", ordinals: "19th" },
  { id: 20, value: "20", ordinals: "20th" },
  { id: 21, value: "21", ordinals: "21st" },
  { id: 22, value: "22", ordinals: "22nd" },
  { id: 23, value: "23", ordinals: "23rd" },
  { id: 24, value: "24", ordinals: "24th" },
  { id: 25, value: "25", ordinals: "25th" },
  { id: 26, value: "26", ordinals: "26th" },
  { id: 27, value: "27", ordinals: "27th" },
  { id: 28, value: "28", ordinals: "28th" },
  { id: 29, value: "29", ordinals: "29th" },
  { id: 30, value: "30", ordinals: "30th" },
  { id: 31, value: "31", ordinals: "31st" },
];
const everyMonthValue = [
  { id: "1", value: "January" },
  { id: "2", value: "Febraury" },
  { id: "3", value: "March" },
  { id: "4", value: "April" },
  { id: "5", value: "May" },
  { id: "6", value: "June" },
  { id: "7", value: "July" },
  { id: "8", value: "August" },
  { id: "9", value: "September" },
  { id: "10", value: "October" },
  { id: "11", value: "November" },
  { id: "12", value: "December" },
];

const everyWeekValue = [
  { id: "1", value: "Monday" },
  { id: "2", value: "Tuesday" },
  { id: "3", value: "Wednesday" },
  { id: "4", value: "Thursday" },
  { id: "5", value: "Friday" },
  { id: "6", value: "Saturday" },
  { id: "7", value: "Sunday" },
];

function CustomDialogTab(props) {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const {
    mode,
    dialogHeader,
    title,
    configId,
    DialogHeaderAndHeaderValue,
    msgConfigDtlData,
    open,
    onClose,
    messageConfigDtlObjectToBeEditedOrView,
    setMessageConfigDtlObjectToBeEditedOrView,
    processNameValue,
    setMsgScheduleFrequenciesToBeEdited,
    msgScheduleFrequenciesToBeEdited,
    selectedCustomProcessDateCombo, setSelectedCustomProcessDateCombo,
    schedulerTab,
    setSchedulerTab,
    selectedRows,
    setSelectedRows,
    selectedFreqCombo, setSelectedFreqCombo, tableData, setTableData
  } = props;

  const [isResponseArrived] = useState(false);
  const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  });
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [disabledForReadMode, isDisabledForReadMode] = useState(false);
  const [resettingValues, setResettingValues] = useState(props.resettingValues);
  const [interfaceDefaultToBeEdited, setInterfaceDefaultToBeEdited] = useState(
    {}
  );

  const pageLabelsConstantsAndMessages = {
    name: {
      processDate: "processDate",
      frequency: "frequency",
      timeZone: "timeZone",
      relativeDate: "relativeDate",
      priorReminderDays: "priorReminderDays",
      postReminderDays: "postReminderDays",
    },
  };
  
  const [timeZoneValue, setTimeZoneValue] = useState({ value: selectedRows.timeZone !== undefined ? selectedRows.timeZone : DialogHeaderAndHeaderValue.entity.timeZone });

  const [cronExp, setCronExp] = useState(
    selectedRows.frequency !== undefined ? selectedRows.frequency : "* * * * *"
  );
  const [currentState, setCurrentState] = useState(
    "Executes: every minute (UTC)"
  );
  const [checked, setChecked] = React.useState(true);
  const [isCronDayFiledDisable, setIsCronDayFiledDisable] = useState(false);
  const [isWeekDayFiledDisable, setIsCronWeekFiledDisable] = useState(false);

  const frequency =
    selectedRows.frequency !== undefined ? selectedRows.frequency : "* * * * *";

  const defaultValues = frequency.split(" ");

  const defaultHourValue =
    selectedRows.frequency !== undefined ? defaultValues[1].split(",") : "";
  const defaultMinuteValue =
    selectedRows.frequency !== undefined ? defaultValues[0].split(",") : "";
  const defaultDayValue =
    selectedRows.frequency !== undefined ? defaultValues[2].split(",") : "";
  const defaultMonthValue =
    selectedRows.frequency !== undefined ? defaultValues[3].split(",") : "";
  const defaultWeekValue =
    selectedRows.frequency !== undefined ? defaultValues[4].split(",") : "";

  const cronHourValueLoad = everyHourValue.filter((item) => {
    if (defaultHourValue.includes(item.value)) {
      return item;
    }
  });

  const [hourValue, setHourValue] = useState(cronHourValueLoad);

  const cronMinuteValueLoad = everyMinuteValue.filter((item) => {
    if (defaultMinuteValue.includes(item.value)) {
      return item;
    }
  });

  const [minuteValue, setMinuteValue] = useState(cronMinuteValueLoad);

  const cronDayValueLoad = everyDayValue.filter((item) => {
    if (defaultDayValue.includes(item.value)) {
      return item;
    }
  });

  const [dayValue, setDayValue] = useState(cronDayValueLoad);

  const cronMonthValueLoad = everyMonthValue.filter((item) => {
    if (defaultMonthValue.includes(item.id)) {
      return item;
    }
  });

  const [monthValue, setMonthValue] = useState(cronMonthValueLoad);

  const cronWeekValueLoad = everyWeekValue.filter((item) => {
    if (defaultWeekValue.includes(item.id)) {
      return item;
    }
  });

  const [weekValue, setWeekValue] = useState(cronWeekValueLoad);

  useEffect(() => {
    if (mode === "edit") {
      setMinuteValue(cronMinuteValueLoad);
      setHourValue(cronHourValueLoad);
      setDayValue(cronDayValueLoad);
      setMonthValue(cronMonthValueLoad);
      setWeekValue(cronWeekValueLoad);
    }
  }, [selectedRows, mode]);

  useEffect(() => {
    if (weekValue.length !== 0) {
      setIsCronDayFiledDisable(true);
    } else if (dayValue.length !== 0) {
      setIsCronWeekFiledDisable(true);
    } else {
      setIsCronDayFiledDisable(false);
      setIsCronWeekFiledDisable(false);
    }
  }, [weekValue, dayValue]);


  const handleChangeForHourCron = (event, values) => {
    setHourValue(values);
  };

  const handleChangeForWeekCron = (event, values) => {
    setWeekValue(values);
  };

  const handleChangeForMonthCron = (event, values) => {
    setMonthValue(values);
  };

  const handleChangeForDayCron = (event, values) => {
    setDayValue(values);
  };

  const handleChangeForMinuteCron = (event, values) => {
    setMinuteValue(values);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const componentList = [
    {
      path: "/messageConfigHdrs",
      name: "MessageConfig",
      iconName: CommentIcon,
    },

    {
      path: undefined,
      name: "MessageSchedulingFrequency",
    },
    {
      path: undefined,
      name: "Edit",
      iconName: EditIcon,
    },
  ];

  const [
    createOrEditOrViewMessageConfigDialog,
    setCreateOrEditOrViewMessageConfigDialog,
  ] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [OpenDialogBox, SetOpenDialogBox] = useState(false);

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disabletypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
      </MuiDialogTitle>
    );
  });


  function resetingMsgConfigDtl(responseData) {
    reset({
      relativeDate: responseData.relativeDate !== undefined ? { value: responseData.relativeDate } : '',
      relativeDate: responseData.processDate !== undefined ? { value: responseData.processDate } : '',

      priorReminderDays:
        responseData.priorReminderDays !== undefined
          ? responseData.priorReminderDays
          : "",
      postReminderDays:
        responseData.postReminderDays !== undefined
          ? responseData.postReminderDays
          : "",
      frequency: responseData.frequency !== undefined ? responseData.frequency : '',
      timeZone: responseData.timeZone !== undefined ? { value: responseData.timeZone } : '',
    });
    setSelectedFreqCombo(
      responseData.relativeDate !== undefined
        ? { value: responseData.relativeDate }
        : ''
    );
    setSelectedCustomProcessDateCombo(
      responseData.processDate !== undefined
        ? { processDate: responseData.processDate }
        : ''
    );
    setTimeZoneValue(responseData.timeZone !== undefined ? { value: responseData.timeZone } : '');
    setCronExp(
      responseData.frequency !== undefined ? responseData.frequency : []
    );
  }
  const previousFrequency = props.messageConfigDtlObjectToBeEditedOrView.frequency;

  const moduleAccessKeyPermission = ModuleAccessPermissionKey();
  const { loggedInUserInfo } = useContext(LoggedInUserDetails);
  const onError = (errors) => {
    setAlertBoxState({
      ...alertBoxState,
      openAlert: true,
      message: "Form Has Errors",
      severity: "error",
      err: true,
    });
  };
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [disableSaveOrUpdateButton, issaveOrUpdateButtonDisabled] =
    useState(true);
  const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState("");
  const [alertBoxState, setAlertBoxState] = React.useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "",
  });

  const handleCloseAlert = () => {
    setAlertBoxState({ ...alertBoxState, openAlert: false });
  };
  const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

  const onSubmit = (data) => {
    data["msgScheduleId"] = selectedRows.msgScheduleId;
    data["relativeDate"] = selectedFreqCombo.value;
    data["processDate"] = selectedCustomProcessDateCombo.processDate;
    data["priorReminderDays"] = getValues("priorReminderDays");
    data["postReminderDays"] = getValues("postReminderDays");
    data["frequency"] = cronExp;
    data["timeZone"] = timeZoneValue.value;
    data["id"] = Math.floor(Math.random() * 100);
    if (selectedRows.id === undefined && selectedRows.msgScheduleId === undefined) {
      if (selectedFreqCombo.value === "Custom Date") {
        if (!schedulerTab.map((item) => item.relativeDate === selectedFreqCombo.value).includes(true)) {
          setAlertBoxState({
            ...alertBoxState,
            openAlert: true,
            message: "Record Updated successfully",
            severity: "success",
          });
          setSchedulerTab(schedulerTab !== [] ? [...schedulerTab, data] : [data]);
          onClose(true);
          reset({
            relativeDate: "",
            processDate: "",
            priorReminderDays: "0",
            postReminderDays: "0",
          })
          setSelectedFreqCombo([]);
          setSelectedCustomProcessDateCombo({ processDate: "" });
          setTimeZoneValue({ value: DialogHeaderAndHeaderValue.entity === undefined ? timeZoneValue.value : DialogHeaderAndHeaderValue.entity.timeZone });
          setCronExp("* * * * *");
          setValue("priorReminderDays", 0);
          setValue("postReminderDays", 0);
          setMinuteValue([]);
          setHourValue([]);
          setMonthValue([]);
          setWeekValue([]);
          setDayValue([]);
          setSelectedRows([]);
          setTableData([...schedulerTab, data]);

        } else {
          setAlertBoxState({
            ...alertBoxState,
            openAlert: true,
            message: `Selected Relative Date ${selectedFreqCombo.value} is already available in the Scheduler`,
            severity: "error",
          });
        }
      } else if (!schedulerTab.map((item) => item.processDate === selectedCustomProcessDateCombo.processDate).includes(true)) {
        setAlertBoxState({
          ...alertBoxState,
          openAlert: true,
          message: "Record Updated successfully",
          severity: "success",
        });
        setSchedulerTab(schedulerTab !== [] ? [...schedulerTab, data] : [data]);
        onClose(true);
        reset({
          relativeDate: "",
          processDate: "",
          priorReminderDays: "0",
          postReminderDays: "0",
        })
        setSelectedCustomProcessDateCombo({ processDate: "" });
        setSelectedFreqCombo({ value: "" });
        setTimeZoneValue({ value: DialogHeaderAndHeaderValue.entity === undefined ? timeZoneValue.value : DialogHeaderAndHeaderValue.entity.timeZone });
        setCronExp("* * * * *");
        setValue("priorReminderDays", 0);
        setValue("postReminderDays", 0);
        setMinuteValue([]);
        setHourValue([]);
        setMonthValue([]);
        setWeekValue([]);
        setDayValue([]);
        setSelectedRows([]);
        setTableData([...schedulerTab, data]);

      } else {
        setAlertBoxState({
          ...alertBoxState,
          openAlert: true,
          message: `Selected Process Date ${selectedCustomProcessDateCombo.processDate} is already available in the Scheduler`,
          severity: "error",
        });
      }
    } else {
      for (let i = 0; i < schedulerTab.length; i++) {
        if (schedulerTab[i].processDate === selectedCustomProcessDateCombo.processDate) {

          schedulerTab[i].priorReminderDays = getValues("priorReminderDays");
          schedulerTab[i].postReminderDays = getValues("postReminderDays");
          schedulerTab[i].frequency = cronExp;
          schedulerTab[i].timeZone = timeZoneValue.value;

          setAlertBoxState({
            ...alertBoxState,
            openAlert: true,
            message: "Record Updated successfully",
            severity: "success",
          });
          setSchedulerTab(schedulerTab !== [] ? [...schedulerTab] : [data]);
          onClose(true);
          reset({
            relativeDate: "",
            processDate: "",
            priorReminderDays: "0",
            postReminderDays: "0",
          })
          setSelectedFreqCombo([]);
          setSelectedCustomProcessDateCombo({ processDate: "" });
          setTimeZoneValue({ value: DialogHeaderAndHeaderValue.entity === undefined ? timeZoneValue.value : DialogHeaderAndHeaderValue.entity.timeZone });
          setCronExp("* * * * *");
          setValue("priorReminderDays", 0);
          setValue("postReminderDays", 0);
          setMinuteValue([]);
          setHourValue([]);
          setMonthValue([]);
          setWeekValue([]);
          setDayValue([]);
          setSelectedRows([]);
          setTableData(tableData !== [] ? [...schedulerTab] : [data]);

        }
      }
    }
  };

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const { handleSubmit, formState: { errors, isDirty, isValid }, control, reset, getValues, setValue } = useForm({
    mode: "all",
    reValidateMode: "all",
    defaultValues: {
      priorReminderDays: 0,
      postReminderDays: 0,
      relativeDate: "",
      timeZone: "",
      processDate: "",

    },
  });

  const [userReadOnlyFields, setUserReadOnlyFields] = useState({
    relativeDate: false,
    processDate: false,
    priorReminderDays: false,
    postReminderDays: false,
    frequency: false,
    timeZone: false

  });
  const messageConfigModuleAccess =
    loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.messageConfig];
  const [tabvalue, setTabValue] = React.useState(0);


  useEffect(() => {
    let currentRouterComponent;
    if (mode === "create") {
      setSaveOrUpdateButtonLabel(
        <FormattedMessage id="common.button.save" defaultMessage="Save" />
      );
      isRestoreButtonDisabled(false);
      currentRouterComponent = [
        {
          path: null,
          name: (
            <FormattedMessage
              id="common.button.create.breadcrumb.tooltip"
              defaultMessage="Create"
            />
          ),
          iconName: CreateIcon,
        },
      ];
    } else if (mode === "edit") {
      setSaveOrUpdateButtonLabel(
        <FormattedMessage id="common.button.update" defaultMessage="Update" />
      );
      setUserReadOnlyFields({
        ...userReadOnlyFields,
        relativeDate: true,
        processDate: true,
        priorReminderDays: true,
        postReminderDays: true,
        frequency: true,
        timeZone: true,
      });
      isRestoreButtonDisabled(false);
      issaveOrUpdateButtonDisabled(true);
      resetingMsgConfigDtl(selectedRows);
      currentRouterComponent = [
        {
          path: null,
          name: (
            <FormattedMessage
              id="common.tooltip.breadcrumb.edit"
              defaultMessage="Edit"
            />
          ),
          iconName: EditIcon,
        },
      ];
    } else if (mode === "read") {
      setSaveOrUpdateButtonLabel(
        <FormattedMessage id="common.button.update" defaultMessage="Update" />
      );
      isRestoreButtonDisabled(true);
      issaveOrUpdateButtonDisabled(true);
      isDisabledForReadMode(true);
      resetingMsgConfigDtl(selectedRows);
      setUserReadOnlyFields({
        ...userReadOnlyFields,
        relativeDate: true,
        processDate: true,
        priorReminderDays: true,
        postReminderDays: true,
        frequency: false,
        timeZone: true,
      });
      currentRouterComponent = [
        {
          path: null,
          name: (
            <FormattedMessage
              id="common.button.view.breadcrumb.tooltip"
              defaultMessage="View"
            />
          ),
          iconName: ViewIcon,
        },
      ];
    }
  }, [mode, selectedRows]);



  const validateRequiredFields = (getValues("relativeDate") === '' && getValues("timeZone") === '' && getValues("processDate") === '');

  useEffect(() => {
    if (!(mode === 'view')) {
      if ((mode === 'create') && validateRequiredFields || !isValid) {
        issaveOrUpdateButtonDisabled(true)
      } else if ((mode === 'create') && !validateRequiredFields) {
        issaveOrUpdateButtonDisabled(false)
      } else if ((mode === 'edit') && (isDirty) || !isValid) {
        issaveOrUpdateButtonDisabled(false)
      } else {
        issaveOrUpdateButtonDisabled(true);
      }
    }
  }, [selectedFreqCombo,
    selectedCustomProcessDateCombo,
    timeZoneValue,
    cronExp,
    getValues("priorReminderDays"),
    getValues("postReminderDays"), mode, isDirty, isValid])


  useEffect(() => {
    let cronMinuteValue = minuteValue.map((key) => {
      return key.value;
    });
    if (cronMinuteValue.length === 0) {
      cronMinuteValue = ["*"];
    }

    let cronHourValue = hourValue.map((key) => {
      return key.value;
    });
    if (cronHourValue.length === 0) {
      cronHourValue = ["*"];
    }

    let cronDayValue = dayValue.map((key) => {
      return key.value;
    });
    if (cronDayValue.length === 0) {
      cronDayValue = ["*"];
    }

    let cronMonthValue = monthValue.map((key) => {
      return key.id;
    });
    if (cronMonthValue.length === 0) {
      cronMonthValue = ["*"];
    }

    let cronWeekValue = weekValue.map((key) => {
      return key.id;
    });
    if (cronWeekValue.length === 0) {
      cronWeekValue = ["*"];
    }

    let currentStateMinuteValue = minuteValue.map((key) => {
      return key.value;
    });
    let currentStatePerMinuteValue = minuteValue.map((key) => {
      return key.minute;
    });
    if (currentStateMinuteValue.length === 0) {
      currentStateMinuteValue = ["every minute"];
    } else if (currentStateMinuteValue.length > 0) {
      const minutesIncludesStar = currentStateMinuteValue.filter((minute) =>
        minute.includes("*")
      );
      currentStateMinuteValue = [
        minutesIncludesStar.length !== 0
          ? `At Every ${currentStatePerMinuteValue} minute(s)`
          : `At ${currentStateMinuteValue} minute(s)`,
      ];
    }

    let currentStateHourValue = hourValue.map((key) => {
      return key.time;
    });
    if (currentStateHourValue.length === 0) {
      currentStateHourValue = ["every hour"];
    } else if (currentStateHourValue.length > 0) {
      currentStateHourValue = [`At ${currentStateHourValue} and`];
    }

    let currentStateWeekValue = weekValue.map((key) => {
      return key.value;
    });
    if (currentStateWeekValue.length > 0) {
      currentStateWeekValue = [`on ${currentStateWeekValue}`];
    }

    let currentStateDayValue = dayValue.map((key) => {
      return key.ordinals;
    });
    if (
      currentStateDayValue.length === 0 &&
      currentStateWeekValue.length === 0
    ) {
      currentStateDayValue = ["every day"];
    } else if (currentStateDayValue.length > 0) {
      currentStateDayValue = [`on ${currentStateDayValue}`];
    }

    let currentStateMonthValue = monthValue.map((key) => {
      return key.value;
    });
    if (currentStateMonthValue.length === 0) {
      currentStateMonthValue = ["Every Month"];
    } else if (currentStateMonthValue.length > 0) {
      currentStateMonthValue = [`In ${currentStateMonthValue}`];
    }

    let cronExpValue = [];
    let cronHourString = cronHourValue.join(",");
    let cronMinuteString = cronMinuteValue.join(",");
    let cronDayString = cronDayValue.join(",");
    let cronMonthString = cronMonthValue.join(",");
    let cronWeekString = cronWeekValue.join(",");
    cronExpValue = cronExpValue.concat(
      cronMinuteString,
      cronHourString,
      cronDayString,
      cronMonthString,
      cronWeekString
    );

    let cronExpString = cronExpValue.join(" ");

    let currentStateExeValue = [];
    let currentHourString = currentStateHourValue.join(",");
    let currentMonthString = currentStateMonthValue.join(",");
    let currentMinuteString = currentStateMinuteValue.join(",");
    let currentDayString = currentStateDayValue.join(",");
    let currentWeekString = currentStateWeekValue.join(",");
    currentStateExeValue = currentStateExeValue.concat(
      "Execute:",
      currentMonthString,
      currentDayString,
      currentWeekString,
      currentHourString,
      currentMinuteString
    );

    let currentExeString = currentStateExeValue.join(" ");

    if (mode === "edit" && selectedRows.frequency !== cronExpString) {
      setValue(
        `${pageLabelsConstantsAndMessages.name.frequency}`,
        cronExpString,
        { shouldValidate: true, shouldDirty: true }
      );
    }

    setCronExp(cronExpString);
    setCurrentState(currentExeString);
  }, [hourValue, minuteValue, weekValue, monthValue, dayValue]);


  const handlereset = (value) => {
    reset({ keepErrors: true, keepDirty: true, keepIsSubmitted: false, keepTouched: false, keepIsValid: false, keepSubmitCount: false, });
    reset(value)
    resetAllClearThePageInfo();
}

  const handleClose = (value) => {
    handlereset(true);
    onClose(value);
    setSelectedFreqCombo({ value: "" });
    setSelectedCustomProcessDateCombo({ processDate: "" });
    setTimeZoneValue({ value: DialogHeaderAndHeaderValue.entity === undefined ? selectedRows.timeZone : DialogHeaderAndHeaderValue.entity.timeZone });
    setCronExp("* * * * *");
    setValue("priorReminderDays", 0);
    setValue("postReminderDays", 0);
    setMinuteValue([]);
    setHourValue([]);
    setMonthValue([]);
    setWeekValue([]);
    setDayValue([]);
    setSelectedRows([]);

  };

  useEffect(() => {
    if (mode === 'edit' || mode === 'view') {


      setValue("priorReminderDays", selectedRows.priorReminderDays === undefined ? 0 : selectedRows.priorReminderDays);
      setValue("postReminderDays", selectedRows.postReminderDays === undefined ? 0 : selectedRows.postReminderDays);


      setTimeZoneValue(selectedRows.timeZone === undefined ? { value: DialogHeaderAndHeaderValue.entity === undefined ? '' : DialogHeaderAndHeaderValue.entity.timeZone } : { value: selectedRows.timeZone });
    }
}, [selectedRows]);



  function resetAllClearThePageInfo() {
    if (mode === 'edit') {
      resetingMsgConfigDtl(selectedRows)
      setSelectedRows({
        ...selectedRows
      })
      // setMessageConfigDtlObjectToBeEditedOrView({ ...messageConfigDtlObjectToBeEditedOrView, frequency: previousFrequency });
      setMinuteValue(cronMinuteValueLoad);
      setHourValue(cronHourValueLoad);
      setDayValue(cronDayValueLoad);
      setMonthValue(cronMonthValueLoad);
      setWeekValue(cronWeekValueLoad);
    } else {
      reset({
        relativeDate: "",
        processDate: "",
        priorReminderDays: "0",
        postReminderDays: "0",
        frequency: "",
        timeZone: "",
        minuteValue: "",
        hourValue: "",
        monthValue: "",
        weekValue: "",
        dayValue: ""
      })
      setSelectedFreqCombo({ value: "" });
      setSelectedCustomProcessDateCombo({ processDate: "" });
      setTimeZoneValue({ value: DialogHeaderAndHeaderValue.entity === undefined ? '' : DialogHeaderAndHeaderValue.entity.timeZone });
      setCronExp("* * * * *");
      setValue("priorReminderDays", 0);
      setValue("postReminderDays", 0);
      setMinuteValue([]);
      setHourValue([]);
      setDayValue([]);
      setMonthValue([]);
      setWeekValue([]);

    }
    setSelectedRows({
      ...selectedRows
    })
  };

 
  const timeZoneComboProperty = {
    name: pageLabelsConstantsAndMessages.name.timeZone,
    isRequired: false,
    label: intl.formatMessage({ id: "customschedulartab.timezone.label" }),
    isAutofocus: false,
    variant: "outlined",
    isHookFormType: true,
    isDisabled: mode === "read" || mode === "view" ? isDisabledForReadMode : false,
  };

  const FreqComboProperty = {
    name: pageLabelsConstantsAndMessages.name.relativeDate,
    isRequired: true,
    label: intl.formatMessage({ id: "customschedulartab.RelativeDate.label" }),
    isAutofocus: false,
    variant: "outlined",
    isHookFormType: true,
    isDisabled: mode === "edit" || mode === "read" || mode === "view" ? isDisabledForReadMode : false,
    errors: errors.relativeDate,
  };

  const CustomProcessDateComboProperty = {
    name: pageLabelsConstantsAndMessages.name.processDate,
    isRequired: selectedFreqCombo.value === "Relative Date" ? true : false,
    label: intl.formatMessage({
      id: "customschedulartab.CustomProcessDate.label",
    }),
    isAutofocus: false,
    variant: "outlined",
    isHookFormType: true,
    errors: errors.processDate,
    isDisabled: mode === "read" ? true : selectedFreqCombo.value === "Relative Date" ? false : true,
  };


  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container className={classes.root}>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <Dialog
            fullWidth
            maxWidth="lg"
            aria-labelledby="create-or-delete-dialog"
            open={open}
          >
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={openAlert}
              onClose={handleCloseAlert}
              key={vertical + horizontal}
              autoHideDuration={6000}
            >
              <Alert onClose={handleCloseAlert} severity={severity}>
                {message}
              </Alert>
            </Snackbar>
            <DialogTitle className={classes.titleCommonDialogComponent}>
              <Grid item container xs={12} sm={12} md={12} lg={12}>
                <Grid
                  item
                  container
                  alignItems="center"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ marginLeft: "-0.8%" }}
                >
                  {
                    <CustomBreadCrumb
                      componentList={componentList}
                      isDialog={true}
                    />
                  }
                </Grid>{" "}
              </Grid>
            </DialogTitle>
            <DialogContent style={{ minHeight: "70vh" }}>
              <React.Fragment>
                <Grid container className={classes.root}>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.textfieldSpacing}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      spacing={2}
                      className={classes.gridSpacing}
                    >

                      <Grid item container xs={12} sm={6} md={6} lg={3}>
                        <FreqCombo
                          comboProperty={FreqComboProperty}
                          control={control}
                          errors={errors}
                          comboValue={selectedFreqCombo}
                          setComboValue={setSelectedFreqCombo}
                        />
                      </Grid>
                      <Grid item container xs={8} sm={4} md={4} lg={3}>
                        <CustomProcessDateCombo
                          comboProperty={CustomProcessDateComboProperty}
                          control={control}
                          errors={errors}
                          comboValue={selectedFreqCombo.value === "Custom Date" ? '' : selectedCustomProcessDateCombo}
                          setComboValue={setSelectedCustomProcessDateCombo}
                          processNameValue={processNameValue}
                          messageConfigDtlObjectToBeEditedOrView={
                            messageConfigDtlObjectToBeEditedOrView
                          }
                        />
                      </Grid>
                      <Grid item container xs={8} sm={4} md={4} lg={3}>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            name={
                              pageLabelsConstantsAndMessages.name.priorReminderDays
                            }
                            render={({ field }) => (
                              <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="normal"
                                id="priorReminderDays"
                                size="small"
                                type="number"
                                label={
                                  <FormattedMessage
                                    id="columnname.PriorReminderDays.label"
                                    defaultMessage="Prior Reminder Days"
                                  />
                                }
                                {...field}
                                InputLabelProps={{
                                  shrink: true,
                                  classes: {
                                    asterisk: classes.asterisk,
                                  },
                                }}
                                disabled={mode === 'read' || (selectedFreqCombo.value != 'Relative Date')}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item container xs={8} sm={4} md={4} lg={3}>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            name={
                              pageLabelsConstantsAndMessages.name
                                .postReminderDays
                            }
                            render={({ field }) => (
                              <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="normal"
                                id="postReminderDays"
                                size="small"
                                type="number"
                                label={
                                  <FormattedMessage
                                    id="columnname.PostReminderDays.label"
                                    defaultMessage="Post Reminder Days"
                                  />
                                }
                                {...field}
                                InputLabelProps={{
                                  shrink: true,
                                  classes: {
                                    asterisk: classes.asterisk,
                                  },
                                }}
                                disabled={mode === 'read' || (selectedFreqCombo.value != 'Relative Date')}

                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item container xs={12} sm={6} md={6} lg={6}>
                        <TimeZoneCombo
                          comboProperty={timeZoneComboProperty}
                          control={control}
                          comboValue={timeZoneValue}
                          setComboValue={setTimeZoneValue}
                        />
                      </Grid>
                      <Grid item container xs={12} sm={6} md={6} lg={6}>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            name={pageLabelsConstantsAndMessages.name.frequency}
                            rules={{
                              required: false,
                            }}
                            render={({ field }) => (
                              <TextField
                                className={classes.textField}
                                {...field}
                                size="small"
                                variant="outlined"
                                label={
                                  <FormattedMessage
                                    id="customschedulartab.indicator.label"
                                    defaultMessage="Indicator"
                                  />
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                                value={cronExp}
                                disabled={disabledForReadMode}
                              />
                            )}
                          />
                        </FormControl>
                        <FormHelperText>
                          {
                            <FormattedMessage id="textfield.indicator.message" />
                          }
                        </FormHelperText>
                      </Grid>
                      <Grid item container xs={12} sm={12} md={12} lg={12}>
                        <FormControl fullWidth>
                          <TextField
                            className={classes.textField}
                            size="small"
                            variant="outlined"
                            label={
                              <FormattedMessage
                                id="customschedulartab.currentstate.label"
                                defaultMessage="Time Zone"
                              />
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                            value={currentState}
                            disabled={disabledForReadMode}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Divider
                      variant="middle"
                      style={{
                        height: "3px",
                        width: "98%",
                        margin: "10px 10px",
                      }}
                    />
                    <Grid
                      item
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      spacing={2}
                      className={classes.gridSpacing}
                    >
                      <Grid item container xs={12} sm={6} md={4} lg={4}>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            size="small"
                            id="selectMinute"
                            limitTags={checked ? 1 : -1}
                            options={everyMinuteValue}
                            filterSelectedOptions
                            onChange={handleChangeForMinuteCron}
                            getOptionLabel={(option) => option.value}
                            value={minuteValue}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={intl.formatMessage({
                                  id: "customschedulartab.everyMinute.label",
                                })}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                            defaultValue={cronMinuteValueLoad}
                            disabled={disabledForReadMode}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item container xs={12} sm={6} md={4} lg={4}>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            size="small"
                            id="selectHours"
                            limitTags={checked ? 1 : -1}
                            options={everyHourValue}
                            filterSelectedOptions
                            onChange={handleChangeForHourCron}
                            getOptionLabel={(option) => option.value}
                            value={hourValue}
                            getoptionselected={(option, value) =>
                              option.value === value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={intl.formatMessage({
                                  id: "customschedulartab.everyHour.label",
                                })}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                            defaultValue={cronHourValueLoad}
                            disabled={disabledForReadMode}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item container xs={12} sm={6} md={4} lg={4}>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            size='small'
                            id="selectDay"
                            limitTags={checked ? 1 : -1}
                            options={everyDayValue}
                            filterSelectedOptions
                            onChange={handleChangeForDayCron}
                            getOptionLabel={(option) => option.value}
                            value={dayValue}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={intl.formatMessage({ id: "customschedulartab.everyDay.label" })}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            defaultValue={cronDayValueLoad}
                            disabled={isCronDayFiledDisable || disabledForReadMode || (selectedFreqCombo.value === 'Relative Date')}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item container xs={12} sm={6} md={4} lg={4}>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            size="small"
                            id="selectMonth"
                            limitTags={checked ? 1 : -1}
                            options={everyMonthValue}
                            onChange={handleChangeForMonthCron}
                            filterSelectedOptions
                            getOptionLabel={(option) => option.value}
                            value={monthValue}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={intl.formatMessage({
                                  id: "customschedulartab.selectmonth.label",
                                })}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                            defaultValue={cronMonthValueLoad}
                            disabled={disabledForReadMode}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item container xs={12} sm={6} md={4} lg={4}>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            size='small'
                            id="selectWeek"
                            limitTags={checked ? 1 : -1}
                            options={everyWeekValue}
                            filterSelectedOptions
                            onChange={handleChangeForWeekCron}
                            getOptionLabel={(option) => option.value}
                            value={weekValue}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={intl.formatMessage({ id: "customschedulartab.everyDayOfWeek.label" })}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            defaultValue={cronWeekValueLoad}
                            disabled={isWeekDayFiledDisable || disabledForReadMode || (selectedFreqCombo.value === 'Relative Date')}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item container xs={12} sm={6} md={4} lg={4}>
                        <Grid
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Checkbox
                            color="primary"
                            onChange={handleChange}
                            checked={checked}
                          />
                          <InputLabel>
                            {
                              <FormattedMessage
                                id="customschedulartab.limittags.label"
                                defaultMessage="Limit Tags"
                              />
                            }
                          </InputLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            </DialogContent>

            {(!smallScreen) &&
              <DialogActions>
                <Grid container justifyContent='flex-start' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                  <InfoIcon color="primary" />
                  <Typography justifyContent="flex-start" style={{ fontSize: 12, marginLeft: '1%' }}><FormattedMessage id="textfield.placeholder.dialogActions" defaultMessage="All the '*' marked fields are mandatory to submit the form" /></Typography>
                </Grid>
                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing}>
                  <Button size='small' variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2} endIcon={<ClearIcon />}
                    onClick={() => handleClose(false)}
                  >
                    <FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />
                  </Button>

                  <Button size='small' disabled={disableRestoreButton}
                    variant="contained" color="secondary" className={classes.stateButtonToRestore} elevation={2}
                    endIcon={<IconRestore />} onClick={(() => { resetAllClearThePageInfo() })}
                  > {<FormattedMessage id="common.button.reset.lable" defaultMessage="Reset" />} </Button>
                  {(messageConfigModuleAccess !== undefined ? messageConfigModuleAccess.createFlag : false) && <Button size='small'
                    variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                    disabled={disableSaveOrUpdateButton || isFormSubmitted}
                    endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />}
                    onClick={handleSubmit(onSubmit, onError)}
                  > {saveOrUpdateButtonLabel} </Button>

                  }
                </Grid>
              </DialogActions>
            }

            {(smallScreen) && (
              <DialogActions>
                <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.topGrid}>
                  <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                    <span>
                      <Button size='small' variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2}
                        onClick={() => handleClose(false)}
                      >
                        <ClearIcon />
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title={<FormattedMessage id="common.button.reset.lable" />}>
                    <span>

                      <Button size='small' disabled={disableRestoreButton}
                        variant="contained" color="secondary" className={classes.stateButtonToRestore} elevation={2} onClick={(() => { resetAllClearThePageInfo() })}
                      > <IconRestore />
                      </Button>
                    </span>
                  </Tooltip>

                  {((messageConfigModuleAccess !== undefined ? messageConfigModuleAccess.createFlag : false) && (<Tooltip title={mode === 'create' ? <FormattedMessage id="common.button.save" /> : <FormattedMessage id="common.button.update" />}>
                    <span>
                      <Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                        disabled={disableSaveOrUpdateButton || isFormSubmitted}
                        endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : null}
                        onClick={handleSubmit(onSubmit, onError)}
                      > <IconSaveOrUpdate />
                      </Button>
                    </span>
                  </Tooltip>))}
                </Grid>
              </DialogActions>)}
          </Dialog>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CustomDialogTab;
