import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import CityCombo from '../Combos/CityCombo';
import StateCombo from '../Combos/StateCombo';
import CountryCombo from '../Combos/CountryCombo';
import UserCombo from '../Combos/UserCombo/UserCombo';
import UserTypeCombo from '../Combos/UserTypeCombo';
import { CssBaseline, FormControl, Grid, TextField } from '@mui/material';
import React from 'react';
import SupplierCombo from '../Combos/SupplierCombo';
import RoleCombo from '../Combos/RoleCombo/RoleCombo';
import DeliveryConfirmationStatusCombo from '../Combos/DeliveryConfirmationStatusCombo';
import PoStatusCombo from '../Combos/PoStatusCombo';
import useStyles from '../CommonStyle/CommonStyle'
import OrgCombo from '../Combos/OrgCombo';
import EmailTmplCombo from '../Combos/EmailTmplCombo';
import OrgCodeCombo from '../Combos/OrgCodeCombo';
import EntityCodeCombo from '../Combos/EntityCodeCombo';
import CustomerCombo from '../Combos/CustomerCombo/CustomerCombo';
import GroupNameCombo from '../Combos/GroupNameCombo';
import GroupDescriptionCombo from '../Combos/GroupDescriptionCombo/GroupDescriptionCombo';
import StatusCombo from '../Combos/StatusCombo';
import BpCodeCombo from '../Combos/BpCodeCombo/BpCodeCombo';
import EmailRptTmplCombo from '../Combos/EmailRptTmplCombo/EmailRptTmplCombo'
import EmailConfigNameCombo from '../Combos/EmailConfigNameCombo/EmailConfigNameCombo';
import ErpInterfaceDescriptionCombo from '../Combos/InterfaceDescription/InterfaceDescription';
import InterfaceTypeCombo from '../Combos/InterfaceTypeCombo/interfaceTypeCombo';
import ProcessNameCombo from '../Combos/ProcessNameCombo/ProcessNameCombo';
import BpTypeCombo from '../Combos/BpTypeCombo/BpTypeCombo';
import BpNameCombo from '../Combos/BpNameCombo/BpNameCombo';
import StartTimeCombo from '../Combos/StartTimeCombo/StartTimeCombo';
import EndTimeCombo from '../Combos/EndTimeCombo/EndTimeCombo';
import MsgTypeComboAPI from '../Combos/MessageTypeCombo/MessageTypeCombo';
import CommTypeCombo from '../Combos/CommTypeCombo/CommTypeCombo';
import InOutCombo from '../Combos/InOutCombo/InOutCombo';
import FileTypeCombo from '../Combos/FileType/FileType';
import TemplateNameCombo from '../Combos/TemplateNameCombo/TemplateNameCombo';
import MessageFormatCombo from '../Combos/MessageFormatCombo/MessageFormatCombo';
import InterfaceIDCombo from '../Combos/InterfaceIDCombo/InterfaceIDCombo';
import EntityNameCombo from '../Combos/EntityNameCombo/EntityNameCombo';
import MsgTransmissionLogMsgtype from '../Combos/MessagetransmissionLogMsgTypeCombo/MsgTransmissionLogMsgType';
import CommTypeLogCombo from '../Combos/CommTypeLogCombo/CommTypeLogCombo';
import MessageTypeLogCombo from '../Combos/MessageTypeLogCombo/MessageTypeLogCombo';
import InterfaceDescriptionErpStatusCombo from '../Combos/InterfaceDescriptionErpStatusCombo/InterfaceDescriptionErpStatusCombo';
import ProjectCodeCombo from '../Combos/ProjectCodeCombo/ProjectCodeCombo';
import SuccessOrFailureCombo from '../Combos/SuccessOrFailureCombo';
import ApprovedCombo from '../Combos/ApprovedCombo/ApprovedCombo';
import EmailStatusCombo from '../Combos/EmailStatusCombo/EmailStatusCombo';
import ApprovedPOCombo from '../Combos/ApprovedForPoCombo/ApprovedForPoCombo';
import POBuyerPartyCodeCombo from '../Combos/POBuyerPartyCodeCombo/POBuyerPartyCodeCombo';
import POSupplierPartyCodeCombo from '../Combos/POSupplierPartyCodeCombo/POSupplierPartyCodeCombo';
import POInvoiceePartyCodeCombo from '../Combos/POInvoicePartyCodeCombo/POInvoicePartyCodeCombo';
import MessageDirectionCombo from '../Combos/MsgDirectionCombo';
import SuccessFailureStatusCombo from '../Combos/SuccessFailureStatusCombo';
import ShipToCustomerCodeCombo from '../Combos/ShipToCustomerCombo';
import ShipmentNumberCombo from '../Combos/ShipmentNumberCombo';
import PackingNumberCombo from '../Combos/PackingNumberCombos';
import InvoiceNumberCombo from '../Combos/InvoiceNumberCombo';
import BuyerPartyCombo from '../Combos/BuyerPartyCombo';
import BillToCustomerPartyComboASN from '../Combos/BPCodeASNHdr/BPCodeASNHdr';
import SupplierPartyCombo from '../Combos/SupplierPartyCombo';
import ASNHdrStatusCombo from '../Combos/StatusComboASNHdr/StatusComboASNHdr';
import PoOutboundStatusCombo from '../Combos/PoOutboundStatusCombo';
import POStatusComboForEDIAndBP from '../Combos/POStatusComboForEDIAndBP';
import UserTypeComboRole from '../Combos/UserTypeComboRole';
import MessageRcvModeCombo from '../Combos/MessageRcvModeCombo';
import POInboundStatusCombo from '../Combos/POInboundStatusCombo';
import EmailStatusForPoVmiCombo from '../Combos/EmailStatusForPoVmiCombo';
import POAmendmentCombo from '../Combos/POAmendmentCombo';
import POCancellationCombo from '../Combos/POCancellationCombo';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { MobileDatePicker } from '@mui/lab';
import LatestOutSourceCombo from '../Combos/LatestOutSourceCombo.js/LatestOutSourceCombo';
import BPMasterCombo from '../Combos/BPMasterCombo';
import YesOrNoCombo from '../Combos/YesOrNoCombo/YesOrNoCombo';
import TriggerEventCombo from '../Combos/TriggerEventCombo/TriggerEventCombo';
import EmailMessageStatusCombo from '../Combos/EmailMessageStatusCombo/EmailMessageStatusCombo';
import RequestStatusCombo from '../Combos/RequestStatusCombo/RequestStatusCombo';
import ProgramNameCombo from '../Combos/ProgramNameCombo/ProgramNameCombo';
import JobTypeCombo from '../Combos/JobTypeCombo/JobTypeCombo';
import ProjectNameCombo from '../Combos/ProjectNameCombo/ProjectNameCombo';
import ImportTemplateNameCombo from '../Combos/ImportTemplateNameCombo/ImportTemplateNameCombo';
import ImportTemplateProcessMasterCombo from '../Combos/ImportTemplateProcessMasterCombo';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import PODeljitCombo from '../Combos/PODeljitCombo/PODeljitCombo';
import PcnNumberCombo from '../Combos/PcnCombo/PcnCombo';



function EntityNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedEntityName, setSelectedEntityName] = useState(item.value !== undefined ? { entityName: item.value } : '');
    useEffect(() => {
        let value = selectedEntityName !== undefined && selectedEntityName !== '' ? selectedEntityName.entityName : "";
        applyValue({ ...item, value: value });
    }, [selectedEntityName]);
    const entityNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <EntityNameCombo
            comboProperty={entityNameComboProperty}
            comboValue={selectedEntityName}
            setComboValue={setSelectedEntityName} />
    );
};

EntityNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function CityNameInputValue(props) {
    const intl = useIntl();
    const { item, applyValue } = props;
    const [selectedCity, setSelectedCity] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedCity) });
    }, [selectedCity]);
    const cityComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
    }

    return (
        <CityCombo
            comboProperty={cityComboProperty}
            comboValue={selectedCity}
            setComboValue={setSelectedCity} />
    );
};

CityNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired,
};

function StateNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [countryParam] = useState({ countryId: 0, countryName: '' });
    const [selectedState, setSelectedState] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedState) });
    }, [selectedState]);
    const stateComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
        countryParam: countryParam
    }

    return (
        <StateCombo
            comboProperty={stateComboProperty}
            comboValue={selectedState}
            setComboValue={setSelectedState} />
    );
};

StateNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired,
};

function CountryNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [countryParam] = useState({ countryId: 0, countryName: '' });
    const [selectedCountry, setSelectedCountry] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedCountry) });
    }, [selectedCountry]);
    const countryComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
        countryParam: countryParam
    }

    return (
        <CountryCombo
            comboProperty={countryComboProperty}
            comboValue={selectedCountry}
            setComboValue={setSelectedCountry} />
    );
};

CountryNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired,
};

function UserNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedUser, setSelectedUserName] = useState(item.value !== undefined ? { userName: item.value } : '');
    useEffect(() => {
        let value = selectedUser !== undefined && selectedUser !== '' ? selectedUser.userName : "";
        applyValue({ ...item, value: value });
    }, [selectedUser]);
    const userNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
        fieldNameToBeDisplayed: 'userName'
    }

    return (
        <UserCombo
            comboProperty={userNameComboProperty}
            comboValue={selectedUser}
            setComboValue={setSelectedUserName} />
    );
};

UserNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired,
};

function UserTypeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedUserType, setSelectedUserType] = useState(item.value !== undefined ? { value: item.value } : '');
    useEffect(() => {
        let value = selectedUserType !== undefined && selectedUserType !== '' ? selectedUserType.value : "";
        applyValue({ ...item, value: value });
    }, [selectedUserType]);

    const userTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
    }

    return (
        <UserTypeCombo
            comboProperty={userTypeComboProperty}
            comboValue={selectedUserType}
            setComboValue={setSelectedUserType} />
    );
};

UserTypeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired,
};

function UserTypeRoleInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedUserType, setSelectedUserType] = useState(item.value !== undefined ? { value: item.value } : '');
    useEffect(() => {
        let value = selectedUserType !== undefined && selectedUserType !== '' ? selectedUserType.value : "";
        applyValue({ ...item, value: value });
    }, [selectedUserType]);
    const userTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
    }

    return (
        <UserTypeComboRole
            comboProperty={userTypeComboProperty}
            comboValue={selectedUserType}
            setComboValue={setSelectedUserType} />
    );
};

UserTypeRoleInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired,
};

function DateInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedDate, setSelectedDate] = useState(item.value !== undefined || item.value !== null ? { value: item.value } : '');
    useEffect(() => {
        let value = selectedDate === null ? '' :
            selectedDate !== undefined && selectedDate !== '' && selectedDate !== null ?
                selectedDate.value === undefined ?
                    selectedDate === null ? '' : selectedDate : selectedDate === null ? '' : selectedDate.value : '';

        // let value = selectedDate !== undefined || selectedDate !== '' || selectedDate !== null ? 
        // selectedDate.value === undefined ? '': selectedDate: '';

        applyValue({ ...item, value: JSON.stringify(value).substr(1, 10) });
    }, [selectedDate]);

    const dateTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "3px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControl fullWidth>
                        <MobileDatePicker
                            renderInput={props => {
                                return <TextField {...props}
                                    variant='standard'
                                    size="small"
                                    label={dateTypeComboProperty.label}
                                    placeholder={intl.formatMessage({ id: "common.filterDatePickerPlaceholder.label", defaultMessage: "Enter valid Date" })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={false}
                                />;
                            }} inputVariant={dateTypeComboProperty.variant}
                            // clearable
                            required={dateTypeComboProperty.isRequired}
                            placeholder={intl.formatMessage({ id: "common.filterDatePickerPlaceholder.label", defaultMessage: "Enter valid Date" })}
                            inputFormat="dd-MM-yyyy"
                            // value={selectedDate !== undefined || selectedDate !== '' || selectedDate !== null ? 
                            // selectedDate.value === undefined ? '': selectedDate.value: ''}
                            value={selectedDate === null || selectedDate === undefined ? '' : selectedDate.value !== undefined ? selectedDate.value : selectedDate === null ? '' :
                                item.value === undefined ? '' : JSON.stringify(selectedDate).substr(1, 10) !== item.value ? item.value :
                                    selectedDate}
                            onChange={date => setSelectedDate(date)}
                            name={dateTypeComboProperty.name}
                        />
                    </FormControl>
                </LocalizationProvider>
            </Grid>
        </React.Fragment>
    );
};

DateInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired,
};

function DeliveryCfmStatusInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedDeliveryCfmStatus, setSelectedDeliveryCfmStatus] = useState(item.value !== undefined ? { value: item.value } : '');
    useEffect(() => {
        let value = selectedDeliveryCfmStatus !== undefined && selectedDeliveryCfmStatus !== '' ? selectedDeliveryCfmStatus.value : "";
        applyValue({ ...item, value: value });
    }, [selectedDeliveryCfmStatus]);

    const deliveryConfirmationStatusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <DeliveryConfirmationStatusCombo
            comboProperty={deliveryConfirmationStatusComboProperty}
            comboValue={selectedDeliveryCfmStatus.value !== item.value ? { value: item.value } : selectedDeliveryCfmStatus}
            setComboValue={setSelectedDeliveryCfmStatus} />
    );
};

DeliveryCfmStatusInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function PoStatusInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedPoStatus, setSelectedPoCfmStatus] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedPoStatus) });
    }, [selectedPoStatus]);

    const poStatusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
    }

    return (
        <PoStatusCombo
            comboProperty={poStatusComboProperty}
            comboValue={selectedPoStatus}
            setComboValue={setSelectedPoCfmStatus} />
    );
};

PoStatusInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function TextFieldInputValue(props) {
    const classes = useStyles();
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedText, setSelectedText] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedText) });
    }, [selectedText]);
    const textFieldTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "3px" }}>
                <TextField
                    className={classes.textField}
                    variant={textFieldTypeComboProperty.variant}
                    margin="normal"
                    id="phoneNo"
                    size='small'
                    label={textFieldTypeComboProperty.label}
                    name={textFieldTypeComboProperty.name}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={selectedText}
                    onChange={(ev) => {
                        if (
                            ev.target.value !== "" ||
                            ev.target.value !== null
                        ) {
                            setSelectedText(ev.target.value);
                        }
                    }}
                />
            </Grid>
        </React.Fragment>
    );
};

TextFieldInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired,
};


function SupplierVendorNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedSupplierName, setSelectedSupplierName] = useState(item.value !== undefined ? { bpSupplierName: item.value } : '');
    useEffect(() => {
        let value = selectedSupplierName !== undefined && selectedSupplierName !== '' ? selectedSupplierName.bpSupplierName : "";
        applyValue({ ...item, value: value });
    }, [selectedSupplierName]);
    const userNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
        fieldNameToBeDisplayed: 'bpSupplierName'
    }

    return (
        <SupplierCombo
            comboProperty={userNameComboProperty}
            comboValue={selectedSupplierName}
            setComboValue={setSelectedSupplierName} />
    );
};

SupplierVendorNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired,
};


function CustomerInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedCustomerName, setSelectedselectedCustomerName] = useState(item.value !== undefined ? { bpCustomerName: item.value } : '');
    useEffect(() => {
        let value = selectedCustomerName !== undefined && selectedCustomerName !== '' ? selectedCustomerName.bpCustomerName : "";
        applyValue({ ...item, value: value });
    }, [selectedCustomerName]);
    const userNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
        fieldNameToBeDisplayed: 'bpCustomerName'
    }

    return (
        <CustomerCombo
            comboProperty={userNameComboProperty}
            comboValue={selectedCustomerName}
            setComboValue={setSelectedselectedCustomerName} />
    );
};

CustomerInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired,
};



function RoleNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedRoleName, setSelectedRoleName] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedRoleName !== undefined && selectedRoleName !== '' ? selectedRoleName.name : "";
        applyValue({ ...item, value: value });
    }, [selectedRoleName])
    const roleComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <RoleCombo
            comboProperty={roleComboProperty}
            comboValue={selectedRoleName}
            setComboValue={setSelectedRoleName} />
    );
};

RoleNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function TemplateNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedTemplateName, setSelectedTemplateName] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedTemplateName !== undefined && selectedTemplateName !== '' ? selectedTemplateName.name : "";
        applyValue({ ...item, value: value });
    }, [selectedTemplateName])
    const templateNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <EmailTmplCombo
            comboProperty={templateNameComboProperty}
            comboValue={selectedTemplateName}
            setComboValue={setSelectedTemplateName} />
    );
};

TemplateNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function MessageDirectionSupplierInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedMsgDirection, setSelectedMsgDirection] = useState(item.value !== undefined ? { dataDisplay: item.value } : '');
    useEffect(() => {
        let value = selectedMsgDirection !== undefined && selectedMsgDirection !== '' ? selectedMsgDirection.dataDisplay : "";
        applyValue({ ...item, value: value });
    }, [selectedMsgDirection])
    const msgDirectionComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <MessageDirectionCombo
            comboProperty={msgDirectionComboProperty}
            comboValue={selectedMsgDirection}
            setComboValue={setSelectedMsgDirection}
            processNameValue={''}
            bpTypeValueCombo={'SUPPLIER'}
        />
    );
};

MessageDirectionSupplierInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired,
}

function MessageDirectionCustomerInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedMsgDirection, setSelectedMsgDirection] = useState(item.value !== undefined ? { dataDisplay: item.value } : '');
    useEffect(() => {
        let value = selectedMsgDirection !== undefined && selectedMsgDirection !== '' ? selectedMsgDirection.dataDisplay : "";
        applyValue({ ...item, value: value });
    }, [selectedMsgDirection])
    const msgDirectionComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <MessageDirectionCombo
            comboProperty={msgDirectionComboProperty}
            comboValue={selectedMsgDirection}
            setComboValue={setSelectedMsgDirection}
            processNameValue={''}
            bpTypeValueCombo={'CUSTOMER'}
        />
    );
};

MessageDirectionCustomerInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired,
}

function EmailReportTemplateInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedEmailReportTemplate, setSelectedEmailReportTemplate] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedEmailReportTemplate !== undefined && selectedEmailReportTemplate !== '' ? selectedEmailReportTemplate.name : "";
        applyValue({ ...item, value: value });
    }, [selectedEmailReportTemplate])
    const emailReportTemplateComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <EmailRptTmplCombo
            comboProperty={emailReportTemplateComboProperty}
            comboValue={selectedEmailReportTemplate}
            setComboValue={setSelectedEmailReportTemplate} />
    );
};

EmailReportTemplateInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};


function EmailConfigNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedEmailCofigName, setSelectedEmailConfigName] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedEmailCofigName) });
    }, [selectedEmailCofigName])
    const emailConfigNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <EmailConfigNameCombo
            comboProperty={emailConfigNameComboProperty}
            comboValue={selectedEmailCofigName}
            setComboValue={setSelectedEmailConfigName} />
    );
};

EmailConfigNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};


function OrgCodeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedOrgCode, setSelectedOrgCode] = useState(item.value !== undefined ? { erpOrgCode: item.value } : '');
    useEffect(() => {
        let value = selectedOrgCode !== undefined && selectedOrgCode !== '' ? selectedOrgCode.erpOrgCode : "";
        applyValue({ ...item, value: value });
    }, [selectedOrgCode])
    const orgCodeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <OrgCodeCombo
            comboProperty={orgCodeComboProperty}
            comboValue={selectedOrgCode}
            setComboValue={setSelectedOrgCode} />
    );
};

OrgCodeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function OrgNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedOrgName, setSelectedOrgName] = useState(item.value !== undefined ? { orgName: item.value } : '');
    useEffect(() => {
        let value = selectedOrgName !== undefined && selectedOrgName !== '' ? selectedOrgName.orgName : "";
        applyValue({ ...item, value: value });
    }, [selectedOrgName])
    const orgNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: "standard",
        isHookFormType: false,
    }

    return (
        <OrgCombo
            comboProperty={orgNameComboProperty}
            comboValue={selectedOrgName}
            setComboValue={setSelectedOrgName} />
    );
};

OrgNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function SupplierGroupNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedGroupName, setSelectedGroupName] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedGroupName) });
    }, [selectedGroupName])
    const groupNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <GroupNameCombo
            comboProperty={groupNameComboProperty}
            comboValue={selectedGroupName}
            setComboValue={setSelectedGroupName}
            bpType={"Supplier"}
        />
    );

};

SupplierGroupNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string,
    }).isRequired
};

function CustomerGroupNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedGroupName, setSelectedGroupName] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedGroupName) });
    }, [selectedGroupName])
    const groupNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <GroupNameCombo
            comboProperty={groupNameComboProperty}
            comboValue={selectedGroupName}
            setComboValue={setSelectedGroupName}
            bpType={"Customer"}
        />
    );

};

CustomerGroupNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string,
    }).isRequired
};

function SupplierGroupDescriptionInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedGroupDescription, setSelectedGroupDescription] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedGroupDescription) });
    }, [selectedGroupDescription])
    const groupDescriptionComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <GroupDescriptionCombo
            comboProperty={groupDescriptionComboProperty}
            comboValue={selectedGroupDescription}
            setComboValue={setSelectedGroupDescription}
            bpType={"Supplier"}
        />
    );

};

SupplierGroupDescriptionInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string,
    }).isRequired
};

function CustomerGroupDescriptionInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedGroupDescription, setSelectedGroupDescription] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedGroupDescription) });
    }, [selectedGroupDescription])
    const groupDescriptionComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <GroupDescriptionCombo
            comboProperty={groupDescriptionComboProperty}
            comboValue={selectedGroupDescription}
            setComboValue={setSelectedGroupDescription}
            bpType={"Customer"}
        />
    );

};

CustomerGroupDescriptionInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string,
    }).isRequired
};

function InterfaceDescriptionInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedInterfaceDescription, setSelectedInterfaceDescription] = useState(item.value !== undefined ? { intfDesc: item.value } : '');
    useEffect(() => {
        let value = selectedInterfaceDescription !== undefined && selectedInterfaceDescription !== '' ? selectedInterfaceDescription.intfDesc : "";
        applyValue({ ...item, value: value });
    }, [selectedInterfaceDescription])
    const interfaceDescriptionComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <ErpInterfaceDescriptionCombo
            comboProperty={interfaceDescriptionComboProperty}
            comboValue={selectedInterfaceDescription}
            setComboValue={setSelectedInterfaceDescription}
        />
    );

};

InterfaceDescriptionInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string,
    }).isRequired
};

function InterfaceDescriptionErpStatusInputValue(props) {
    const { item, applyValue, } = props;
    const intl = useIntl();
    const [selectedInterfaceDescription, setSelectedInterfaceDescription] = useState(item.value !== undefined ? { intfDesc: item.value } : '');
    useEffect(() => {
        let value = selectedInterfaceDescription !== undefined && selectedInterfaceDescription !== '' ? selectedInterfaceDescription.intfDesc : "";
        applyValue({ ...item, value: value });
    }, [selectedInterfaceDescription])
    const interfaceDescriptionComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <InterfaceDescriptionErpStatusCombo
            comboProperty={interfaceDescriptionComboProperty}
            comboValue={selectedInterfaceDescription}
            setComboValue={setSelectedInterfaceDescription}
        />
    );

};

InterfaceDescriptionErpStatusInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string,
    }).isRequired
};




function InterfaceTypeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedInterfaceType, setSelectedInterfaceType] = useState(item.value !== undefined ? { value: item.value } : '');
    useEffect(() => {
        let value = selectedInterfaceType !== undefined && selectedInterfaceType !== '' ? selectedInterfaceType.value : "";
        applyValue({ ...item, value: value });
    }, [selectedInterfaceType])
    const interfaceTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <InterfaceTypeCombo
            comboProperty={interfaceTypeComboProperty}
            comboValue={selectedInterfaceType}
            setComboValue={setSelectedInterfaceType}
        />
    );

};

InterfaceTypeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string,
    }).isRequired
};

function IntfIdInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedIntfId, setSelectedIntfId] = useState(item.value !== undefined ? { value: item.value } : '');
    useEffect(() => {
        let value = selectedIntfId !== undefined && selectedIntfId !== '' ? selectedIntfId.value : "";
        applyValue({ ...item, value: value });
    }, [selectedIntfId])
    const interfaceIdComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <InterfaceIDCombo
            comboProperty={interfaceIdComboProperty}
            comboValue={selectedIntfId}
            setComboValue={setSelectedIntfId}
        />
    );

};

IntfIdInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string,
    }).isRequired
};



function GroupDescriptionInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedGroupDescription, setSelectedGroupDescription] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedGroupDescription) });
    }, [selectedGroupDescription])
    const groupDescriptionComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <GroupDescriptionCombo
            comboProperty={groupDescriptionComboProperty}
            comboValue={selectedGroupDescription}
            setComboValue={setSelectedGroupDescription}
        />
    );

};


GroupDescriptionInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function ProcessInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedProcess, setSelectedProcess] = useState(item.value !== undefined ? { process: item.value } : '');
    useEffect(() => {
        let value = selectedProcess !== undefined && selectedProcess !== '' ? selectedProcess.process : "";
        applyValue({ ...item, value: value });
    }, [selectedProcess])
    const processComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <ProcessNameCombo
            comboProperty={processComboProperty}
            comboValue={selectedProcess}
            setComboValue={setSelectedProcess}
            bpTypeValueCombo={''}
        />
    );

};

ProcessInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string,
    }).isRequired
};

function ProcessIDInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedProcessID, setSelectedProcessID] = useState(item.value !== undefined ? { processId: item.value } : '');
    useEffect(() => {
        let value = selectedProcessID !== undefined && selectedProcessID !== '' ? selectedProcessID.processId : "";
        applyValue({ ...item, value: value });
    }, [selectedProcessID])
    const processComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <ProcessNameCombo
            comboProperty={processComboProperty}
            comboValue={selectedProcessID}
            setComboValue={setSelectedProcessID}
            bpTypeValueCombo={''}
        />
    );
};

ProcessIDInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string,
    }).isRequired
};

function InvoiceeMsgStatusValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [invoiceeMsgStatus, setInvoiceeMsgStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = invoiceeMsgStatus !== undefined && invoiceeMsgStatus !== '' ? invoiceeMsgStatus.name : "";
        applyValue({ ...item, value: value });
    }, [invoiceeMsgStatus])
    const invoiceeMsgStatusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <PoStatusCombo
            comboProperty={invoiceeMsgStatusComboProperty}
            comboValue={invoiceeMsgStatus}
            setComboValue={setInvoiceeMsgStatus}
        />
    );

};

InvoiceeMsgStatusValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string,
    }).isRequired
};

function EntityCodeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedEntityCode, setSelectedEntityCode] = useState(item.value !== undefined ? { erpEntityCode: item.value } : '');
    useEffect(() => {
        let value = selectedEntityCode !== undefined && selectedEntityCode !== '' ? selectedEntityCode.erpEntityCode : item.value;
        applyValue({ ...item, value: value });
    }, [selectedEntityCode])
    const entityCodeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <EntityCodeCombo
            comboProperty={entityCodeComboProperty}
            comboValue={selectedEntityCode.erpEntityCode !== item.value ? { erpEntityCode: item.value } : selectedEntityCode}
            setComboValue={setSelectedEntityCode} />
    );
};

EntityCodeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function BpTypeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedBpType, setSelectedBpType] = useState(item.value !== undefined ? { value: item.value } : '');
    useEffect(() => {
        let value = selectedBpType !== undefined && selectedBpType !== '' ? selectedBpType.value : "";
        applyValue({ ...item, value: value });
    }, [selectedBpType])
    const bpTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <BpTypeCombo
            comboProperty={bpTypeComboProperty}
            comboValue={selectedBpType}
            setComboValue={setSelectedBpType} />
    );
};

BpTypeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function BpCodeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedBpCode, setSelectedBpCode] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedBpCode) });
    }, [selectedBpCode])
    const bpTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <BpCodeCombo
            comboProperty={bpTypeComboProperty}
            comboValue={selectedBpCode}
            setComboValue={setSelectedBpCode} />
    );
};

BpCodeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};


function BpNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedBpName, setSelectedBpName] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedBpName) });
    }, [selectedBpName])
    const bpNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <BpNameCombo
            comboProperty={bpNameComboProperty}
            comboValue={selectedBpName}
            setComboValue={setSelectedBpName} />
    );
};

BpNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};


function MessageTempNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedTemplateName, setSelectedTemplateName] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedTemplateName) });
    }, [selectedTemplateName])
    const templateNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <TemplateNameCombo
            comboProperty={templateNameComboProperty}
            comboValue={selectedTemplateName}
            setComboValue={setSelectedTemplateName} />
    );
};

MessageTempNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function MessageFormatInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedMessageFormat, setSelectedMessageFormat] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedMessageFormat) });
    }, [selectedMessageFormat])
    const messageFormatComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <MessageFormatCombo
            comboProperty={selectedMessageFormat}
            comboValue={messageFormatComboProperty}
            setComboValue={setSelectedMessageFormat} />
    );
};

MessageFormatInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function MessageTypeAPIInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedMsgTypeType, setSelectedMsgType] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedMsgTypeType) });
    }, [selectedMsgTypeType])
    const msgTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <MsgTypeComboAPI
            comboProperty={msgTypeComboProperty}
            comboValue={selectedMsgTypeType}
            setComboValue={setSelectedMsgType} />
    );
};

MessageTypeAPIInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};


function MsgTypeAPIInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedMsgType, setSelectedMsgType] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedMsgType) });
    }, [selectedMsgType])
    const msgTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <MsgTransmissionLogMsgtype
            comboProperty={msgTypeComboProperty}
            comboValue={selectedMsgType}
            setComboValue={setSelectedMsgType} />
    );
};

MsgTypeAPIInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};


function CommTypeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedCommType, setSelectedCommType] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedCommType) });
    }, [selectedCommType])
    const commTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <CommTypeCombo
            comboProperty={commTypeComboProperty}
            comboValue={selectedCommType}
            setComboValue={setSelectedCommType} />
    );
};

CommTypeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function CommTypeLogInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedCommType, setSelectedCommType] = useState(item.value !== undefined ? { value: item.value } : '');
    useEffect(() => {
        let value = selectedCommType !== undefined && selectedCommType !== '' ? selectedCommType.value : "";
        applyValue({ ...item, value: value });
    }, [selectedCommType])
    const commTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <CommTypeLogCombo
            comboProperty={commTypeComboProperty}
            comboValue={selectedCommType}
            setComboValue={setSelectedCommType} />
    );
};

CommTypeLogInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function MsgTypeLogInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedMsgType, setSelectedMsgType] = useState(item.value !== undefined ? { value: item.value } : '');
    useEffect(() => {
        let value = selectedMsgType !== undefined && selectedMsgType !== '' ? selectedMsgType.value : "";
        applyValue({ ...item, value: value });
    }, [selectedMsgType])
    const msgTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <MessageTypeLogCombo
            comboProperty={msgTypeComboProperty}
            comboValue={selectedMsgType}
            setComboValue={setSelectedMsgType} />
    );
};

MsgTypeLogInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};


function InOutInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedinOut, setSelectedInOut] = useState(item.value !== undefined ? { displayName: item.value } : '');
    useEffect(() => {
        let value = selectedinOut !== undefined && selectedinOut !== '' ? selectedinOut.displayName : "";
        applyValue({ ...item, value: value });
    }, [selectedinOut])
    const inOutComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <InOutCombo
            comboProperty={inOutComboProperty}
            comboValue={selectedinOut}
            setComboValue={setSelectedInOut} />
    );
};

InOutInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function FileTypeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedFileType, setSelectedFileType] = useState(item.value !== undefined ? { value: item.value } : '');
    useEffect(() => {
        let value = selectedFileType !== undefined && selectedFileType !== '' ? selectedFileType.value : "";
        applyValue({ ...item, value: value });
    }, [selectedFileType])
    const fileTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <FileTypeCombo
            comboProperty={fileTypeComboProperty}
            comboValue={selectedFileType}
            setComboValue={setSelectedFileType} />
    );
};

FileTypeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};


function StartTimeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedStartTime, setSelectedStartTime] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedStartTime) });
    }, [selectedStartTime])
    const startTimeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <StartTimeCombo
            comboProperty={startTimeComboProperty}
            comboValue={selectedStartTime}
            setComboValue={setSelectedStartTime} />
    );
};

StartTimeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function EndTimeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedEndTime, setSelectedEndTime] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedEndTime) });
    }, [selectedEndTime])
    const endTimeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <EndTimeCombo
            comboProperty={endTimeComboProperty}
            comboValue={selectedEndTime}
            setComboValue={setSelectedEndTime} />
    );
};

EndTimeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function SuccessFailureStatusColInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedActiveStatus, setSelectedActiveStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedActiveStatus !== undefined && selectedActiveStatus !== '' ? selectedActiveStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedActiveStatus])
    const statusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <SuccessFailureStatusCombo
            comboProperty={statusComboProperty}
            comboValue={selectedActiveStatus}
            setComboValue={setSelectedActiveStatus} />
    );
};

SuccessFailureStatusColInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};


function StatusColInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedActiveStatus, setSelectedActiveStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedActiveStatus !== undefined && selectedActiveStatus !== '' ? selectedActiveStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedActiveStatus])
    const statusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <StatusCombo
            comboProperty={statusComboProperty}
            comboValue={selectedActiveStatus}
            setComboValue={setSelectedActiveStatus} />
    );
};

StatusColInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function StatusColSuccessOrFaliureInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedSuccessOrFailureStatus, setSelectedSuccessOrFailureStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedSuccessOrFailureStatus !== undefined && selectedSuccessOrFailureStatus !== '' ? selectedSuccessOrFailureStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedSuccessOrFailureStatus])
    const successOrFailureComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <SuccessOrFailureCombo
            comboProperty={successOrFailureComboProperty}
            comboValue={selectedSuccessOrFailureStatus}
            setComboValue={setSelectedSuccessOrFailureStatus} />
    );
};

StatusColSuccessOrFaliureInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function ApprovedColInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedActiveStatus, setSelectedActiveStatus] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedActiveStatus) });
    }, [selectedActiveStatus])
    const statusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <ApprovedCombo
            comboProperty={statusComboProperty}
            comboValue={selectedActiveStatus}
            setComboValue={setSelectedActiveStatus} />
    );
};

function ApprovedPOInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedActiveStatus, setSelectedActiveStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedActiveStatus !== undefined && selectedActiveStatus !== '' ? selectedActiveStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedActiveStatus])
    const statusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <ApprovedPOCombo
            comboProperty={statusComboProperty}
            comboValue={selectedActiveStatus}
            setComboValue={setSelectedActiveStatus} />
    );
};

ApprovedPOInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function EmailStatusInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedEmailStatus, setSelectedEmailStatus] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedEmailStatus) });
    }, [selectedEmailStatus])
    const emailStatusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <EmailStatusCombo
            comboProperty={emailStatusComboProperty}
            comboValue={selectedEmailStatus}
            setComboValue={setSelectedEmailStatus} />
    );
};

EmailStatusInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function POBuyerPartyCode(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [poBuyerPartyCode, setPOBuyerPartyCode] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(poBuyerPartyCode) });
    }, [poBuyerPartyCode])
    const poBuyerPartyCodeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <POBuyerPartyCodeCombo
            comboProperty={poBuyerPartyCodeComboProperty}
            comboValue={poBuyerPartyCode}
            setComboValue={setPOBuyerPartyCode} />
    );
};

POBuyerPartyCode.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function POSupplierPartyCode(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [poSupplierPartyCode, setPOSupplierPartyCode] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(poSupplierPartyCode) });
    }, [poSupplierPartyCode])
    const poSupplierPartyCodeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <POSupplierPartyCodeCombo
            comboProperty={poSupplierPartyCodeComboProperty}
            comboValue={poSupplierPartyCode}
            setComboValue={setPOSupplierPartyCode} />
    );
};

POSupplierPartyCode.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function POInvoiceePartyCode(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [poInvoiceePartyCode, setPOInvoiceePartyCode] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(poInvoiceePartyCode) });
    }, [poInvoiceePartyCode])
    const poInvoiceePartyCodeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <POInvoiceePartyCodeCombo
            comboProperty={poInvoiceePartyCodeComboProperty}
            comboValue={poInvoiceePartyCode}
            setComboValue={setPOInvoiceePartyCode} />
    );
};

POInvoiceePartyCode.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function ShipToCustomerNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [shipToCustomerName, setShipToCustomerName] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(shipToCustomerName) });
    }, [shipToCustomerName])

    const shipToCustomerNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <ShipToCustomerCodeCombo
            comboProperty={shipToCustomerNameComboProperty}
            comboValue={shipToCustomerName}
            setComboValue={setShipToCustomerName} />
    );
};

ShipToCustomerNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function ShipmentNumberInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [shipmentNumber, setShipmentNumber] = useState(item.value !== undefined ? { shipmentNumber: item.value } : '');
    useEffect(() => {
        let value = shipmentNumber !== undefined && shipmentNumber !== '' ? shipmentNumber.shipmentNumber : "";
        applyValue({ ...item, value: value });
    }, [shipmentNumber])

    const shipmentNumberComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <ShipmentNumberCombo
            comboProperty={shipmentNumberComboProperty}
            comboValue={shipmentNumber}
            setComboValue={setShipmentNumber} />
    );
};

ShipmentNumberInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function PackingNoInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [packingNo, setPackingNo] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(packingNo) });
    }, [packingNo])

    const packingNoComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <PackingNumberCombo
            comboProperty={packingNoComboProperty}
            comboValue={packingNo}
            setComboValue={setPackingNo} />
    );
};

PackingNoInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function InvoiceNoInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [invoiceNo, setInvoiceNo] = useState(item.value !== undefined ? { invoiceNo: item.value } : '');
    useEffect(() => {
        let value = invoiceNo !== undefined && invoiceNo !== '' ? invoiceNo.invoiceNo : "";
        applyValue({ ...item, value: value });
    }, [invoiceNo])

    const invoiceNoComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <InvoiceNumberCombo
            comboProperty={invoiceNoComboProperty}
            comboValue={invoiceNo}
            setComboValue={setInvoiceNo} />
    );
};

InvoiceNoInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function BuyerPartyCodeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [buyerPartyCode, setBuyerPartyCode] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(buyerPartyCode) });
    }, [buyerPartyCode])

    const buyerPartyCodeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <BuyerPartyCombo
            comboProperty={buyerPartyCodeComboProperty}
            comboValue={buyerPartyCode}
            setComboValue={setBuyerPartyCode} />
    );
};

BuyerPartyCodeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function SupplierPartyCodeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [supplierPartyCode, setSupplierPartyCode] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(supplierPartyCode) });
    }, [supplierPartyCode])

    const supplierPartyCodeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <SupplierPartyCombo
            comboProperty={supplierPartyCodeComboProperty}
            comboValue={supplierPartyCode}
            setComboValue={setSupplierPartyCode} />
    );
};

SupplierPartyCodeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function BillToCustomerPartyCodeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [billToCustomerPartyCode, setbillToCustomerPartyCode] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(billToCustomerPartyCode) });
    }, [billToCustomerPartyCode])

    const billToCustomerPartyCodeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <BillToCustomerPartyComboASN
            comboProperty={billToCustomerPartyCodeComboProperty}
            comboValue={billToCustomerPartyCode}
            setComboValue={setbillToCustomerPartyCode} />
    );
};

BillToCustomerPartyCodeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function ASNMsgStatusValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [asnMsgStatus, setASNMsgStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = asnMsgStatus !== undefined && asnMsgStatus !== '' ? asnMsgStatus.name : "";
        applyValue({ ...item, value: value });
    }, [asnMsgStatus])
    const asnMsgStatusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <ASNHdrStatusCombo
            comboProperty={asnMsgStatusComboProperty}
            comboValue={asnMsgStatus}
            setComboValue={setASNMsgStatus}
        />
    );

};

ASNMsgStatusValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string,
    }).isRequired
};


function PoStatusOutboundColInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedPoOutboundStatus, setSelectedPoOutboundStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedPoOutboundStatus !== undefined && selectedPoOutboundStatus !== '' ? selectedPoOutboundStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedPoOutboundStatus])
    const poStatusOutboundComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <PoOutboundStatusCombo
            comboProperty={poStatusOutboundComboProperty}
            comboValue={selectedPoOutboundStatus}
            setComboValue={setSelectedPoOutboundStatus} />
    );
};

PoStatusOutboundColInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function PoStatusOutboundForEDIAndBPColInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedPoOutboundStatus, setSelectedPoOutboundStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedPoOutboundStatus !== undefined && selectedPoOutboundStatus !== '' ? selectedPoOutboundStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedPoOutboundStatus])
    const poStatusOutboundComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <POStatusComboForEDIAndBP
            comboProperty={poStatusOutboundComboProperty}
            comboValue={selectedPoOutboundStatus.name !== item.value ? { name: item.value } : selectedPoOutboundStatus}
            setComboValue={setSelectedPoOutboundStatus} />
    );
};

PoStatusOutboundForEDIAndBPColInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function MessageRcvInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedActiveStatus, setSelectedActiveStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedActiveStatus !== undefined && selectedActiveStatus !== '' ? selectedActiveStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedActiveStatus])
    const statusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <MessageRcvModeCombo
            comboProperty={statusComboProperty}
            comboValue={selectedActiveStatus}
            setComboValue={setSelectedActiveStatus} />
    );
};

MessageRcvInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function PoInboundInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedActiveStatus, setSelectedActiveStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedActiveStatus !== undefined && selectedActiveStatus !== '' ? selectedActiveStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedActiveStatus])
    const statusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <POInboundStatusCombo
            comboProperty={statusComboProperty}
            comboValue={selectedActiveStatus}
            setComboValue={setSelectedActiveStatus} />
    );
};

PoInboundInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function EmailStatusForPoVmiInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedActiveStatus, setSelectedActiveStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedActiveStatus !== undefined && selectedActiveStatus !== '' ? selectedActiveStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedActiveStatus])
    const statusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <EmailStatusForPoVmiCombo
            comboProperty={statusComboProperty}
            comboValue={selectedActiveStatus}
            setComboValue={setSelectedActiveStatus} />
    );
};

EmailStatusForPoVmiInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function PoAmendmentInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedActiveStatus, setSelectedActiveStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedActiveStatus !== undefined && selectedActiveStatus !== '' ? selectedActiveStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedActiveStatus])
    const statusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <POAmendmentCombo
            comboProperty={statusComboProperty}
            comboValue={selectedActiveStatus}
            setComboValue={setSelectedActiveStatus} />
    );
};

PoAmendmentInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function PoCancellationInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedActiveStatus, setSelectedActiveStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedActiveStatus !== undefined && selectedActiveStatus !== '' ? selectedActiveStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedActiveStatus])
    const statusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <POCancellationCombo
            comboProperty={statusComboProperty}
            comboValue={selectedActiveStatus}
            setComboValue={setSelectedActiveStatus} />
    );
};

PoCancellationInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function LatestDataSourceInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedDataSource, setSelectedDataSource] = useState(item.value !== undefined ? { latestDataSource: item.value } : '');
    useEffect(() => {
        let value = selectedDataSource !== undefined && selectedDataSource !== '' ? selectedDataSource.latestDataSource : "";
        applyValue({ ...item, value: value });
    }, [selectedDataSource])
    const dataSourceComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <LatestOutSourceCombo
            comboProperty={dataSourceComboProperty}
            comboValue={selectedDataSource}
            setComboValue={setSelectedDataSource} />
    );
};

LatestDataSourceInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function BPMasterInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedBpName, setSelectedBpName] = useState(item.value !== undefined ? { bpName: item.value } : '');
    useEffect(() => {
        let value = selectedBpName !== undefined && selectedBpName !== '' ? selectedBpName.bpName : "";
        applyValue({ ...item, value: value });
    }, [selectedBpName])
    const bpNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <BPMasterCombo
            comboProperty={bpNameComboProperty}
            comboValue={selectedBpName}
            setComboValue={setSelectedBpName} />
    );
};

BPMasterInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function TriggerEventInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedTriggerEvent, setSelectedTriggerEvent] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedTriggerEvent !== undefined && selectedTriggerEvent !== '' ? selectedTriggerEvent.name : ''
        applyValue({ ...item, value: value });
    }, [selectedTriggerEvent])


    const triggerEventComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <TriggerEventCombo
            comboProperty={triggerEventComboProperty}
            comboValue={selectedTriggerEvent}
            setComboValue={setSelectedTriggerEvent}
        />
    );

};

TriggerEventInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string,
    }).isRequired
};

function YesOrNoInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedYesOrNo, setSelectedYesOrNo] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedYesOrNo !== undefined && selectedYesOrNo !== '' ? selectedYesOrNo.name : "";
        applyValue({ ...item, value: value });
    }, [selectedYesOrNo])

    const yesOrNoComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <YesOrNoCombo
            comboProperty={yesOrNoComboProperty}
            comboValue={selectedYesOrNo}
            setComboValue={setSelectedYesOrNo} />
    );
};

YesOrNoCombo.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function EmailMessageLogStatusInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedEmailMsgStatus, setSelectedEmailMsgStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedEmailMsgStatus !== undefined && selectedEmailMsgStatus !== '' ? selectedEmailMsgStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedEmailMsgStatus])
    const emailMessageStatusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }

    return (
        <EmailMessageStatusCombo
            comboProperty={emailMessageStatusComboProperty}
            comboValue={selectedEmailMsgStatus}
            setComboValue={setSelectedEmailMsgStatus} />
    );
};

EmailMessageLogStatusInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function WbsCodeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedWbsCode, setSelectedWbsCode] = useState(item.value !== undefined ? { projectCode: item.value } : '');
    useEffect(() => {
        let value = selectedWbsCode !== undefined && selectedWbsCode !== '' ? selectedWbsCode.projectCode : "";
        applyValue({ ...item, value: value });
    }, [selectedWbsCode])
    const wbsCodeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
    }

    return (
        <ProjectCodeCombo
            comboProperty={wbsCodeComboProperty}
            comboValue={selectedWbsCode}
            setComboValue={setSelectedWbsCode} />
    );
};

WbsCodeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function WbsDescriptionInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedWbsDescription, setSelectedWbsDescription] = useState(item.value !== undefined ? { projectName: item.value } : '');
    useEffect(() => {
        let value = selectedWbsDescription !== undefined && selectedWbsDescription !== '' ? selectedWbsDescription.projectName : item.value;
        applyValue({ ...item, value: value });
    }, [selectedWbsDescription])
    const wbsDescriptionComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
        fieldNameToBeDisplayed: 'projectName'
    }

    return (
        <ProjectNameCombo
            comboProperty={wbsDescriptionComboProperty}
            comboValue={selectedWbsDescription}
            setComboValue={setSelectedWbsDescription} />
    );
};

WbsDescriptionInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function ProgramNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedProgramName, setSelectedProgramName] = useState(item.value !== undefined ? { programName: item.value } : '');
    useEffect(() => {
        let value = selectedProgramName !== undefined && selectedProgramName !== '' ? selectedProgramName.programName : item.value;
        applyValue({ ...item, value: value });
    }, [selectedProgramName])
    const programNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
        fieldNameToBeDisplayed: 'programName'
    }

    return (
        <ProgramNameCombo
            comboProperty={programNameComboProperty}
            comboValue={selectedProgramName}
            setComboValue={setSelectedProgramName} />
    );
};

ProgramNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function JobTypeInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedJobType, setSelectedJobType] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedJobType !== undefined && selectedJobType !== '' ? selectedJobType.name : item.value;
        applyValue({ ...item, value: value });
    }, [selectedJobType])

    const jobTypeComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
        fieldNameToBeDisplayed: 'jobType'
    }

    return (
        <JobTypeCombo
            comboProperty={jobTypeComboProperty}
            comboValue={selectedJobType}
            setComboValue={setSelectedJobType} />
    );
};

JobTypeInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function RequestStatusInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedRequestStatus, setSelectedRequestStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedRequestStatus !== undefined && selectedRequestStatus !== '' ? selectedRequestStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedRequestStatus])
    const requestStatusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
        fieldNameToBeDisplayed: 'requestStatus'
    }

    return (
        <RequestStatusCombo
            comboProperty={requestStatusComboProperty}
            comboValue={selectedRequestStatus}
            setComboValue={setSelectedRequestStatus} />
    );
};

RequestStatusInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function ImportTemplateNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedImportTemplate, setSelectedImportTemplate] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedImportTemplate !== undefined && selectedImportTemplate !== '' ? selectedImportTemplate.name : "";
        applyValue({ ...item, value: value });
    }, [selectedImportTemplate])

    const importTemplateNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: 'Value',
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
    }

    return (
        <ImportTemplateNameCombo
            comboProperty={importTemplateNameComboProperty}
            comboValue={selectedImportTemplate}
            setComboValue={setSelectedImportTemplate} />
    );
};

ImportTemplateNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function ProcessNameInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedProcessName, setSelectedProcessName] = useState(item.value !== undefined ? { process: item.value } : '');
    useEffect(() => {
        let value = selectedProcessName !== undefined && selectedProcessName !== '' ? selectedProcessName.process : "";
        applyValue({ ...item, value: value });
    }, [selectedProcessName])

    const processNameComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false,
    }

    return (
        <ImportTemplateProcessMasterCombo
            comboProperty={processNameComboProperty}
            comboValue={selectedProcessName}
            setComboValue={setSelectedProcessName} />
    );
};

ProcessNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};

function DeljitInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [selectedActiveStatus, setSelectedActiveStatus] = useState(item.value !== undefined ? { name: item.value } : '');
    useEffect(() => {
        let value = selectedActiveStatus !== undefined && selectedActiveStatus !== '' ? selectedActiveStatus.name : "";
        applyValue({ ...item, value: value });
    }, [selectedActiveStatus])
    const statusComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    };
    return (
        <PODeljitCombo
            comboProperty={statusComboProperty}
            comboValue={selectedActiveStatus}
            setComboValue={setSelectedActiveStatus} />
    );

    DeljitInputValue.propTypes = {
        applyValue: PropTypes.func.isRequired,
        item: PropTypes.shape({
            columnField: PropTypes.string,
            id: PropTypes.number,
            operatorValue: PropTypes.string,
            value: PropTypes.string
        }).isRequired
    }

};
function PcnNumberInputValue(props) {
    const { item, applyValue } = props;
    const intl = useIntl();
    const [pcnNo, setPcnNumber] = useState(item.value !== undefined ? { pcnNo: item.value } : '');
    useEffect(() => {
        let value = pcnNo !== undefined && pcnNo !== '' ? pcnNo.pcnNo : "";
        applyValue({ ...item, value: value });
    }, [pcnNo])
    const pcnNumberComboProperty = {
        name: 'value',
        isRequired: false,
        label: intl.formatMessage({ id: "common.filterpanelinputlabel.toolbar.lable" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'standard',
        isHookFormType: false
    }
    return (
        <PcnNumberCombo
            comboProperty={pcnNumberComboProperty}
            comboValue={pcnNo}
            setComboValue={setPcnNumber} />
    );

    PcnNumberInputValue.propTypes = {
        applyValue: PropTypes.func.isRequired,
        item: PropTypes.shape({
            columnField: PropTypes.string,
            id: PropTypes.number,
            operatorValue: PropTypes.string,
            value: PropTypes.string
        }).isRequired
    };
};

export const CustomFilters = {

    EntityNameInputValue,
    CityNameInputValue,
    StateNameInputValue,
    CountryNameInputValue,
    UserNameInputValue,
    UserTypeInputValue,
    DateInputValue,
    TextFieldInputValue,
    SupplierVendorNameInputValue,
    RoleNameInputValue,
    DeliveryCfmStatusInputValue,
    PoStatusInputValue,
    TemplateNameInputValue,
    OrgCodeInputValue,
    EntityCodeInputValue,
    SupplierGroupNameInputValue,
    CustomerGroupNameInputValue,
    GroupDescriptionInputValue,
    BpTypeInputValue,
    StatusColInputValue,
    InterfaceDescriptionInputValue,
    InterfaceTypeInputValue,
    ProcessInputValue,
    // BasicDateRangePicker
    CustomerInputValue,
    EmailReportTemplateInputValue,
    BpCodeInputValue,
    EmailConfigNameInputValue,
    BpNameInputValue,
    StartTimeInputValue,
    EndTimeInputValue,
    CommTypeInputValue,
    MessageTypeAPIInputValue,
    InOutInputValue,
    FileTypeInputValue,
    MessageTempNameInputValue,
    MessageFormatInputValue,
    IntfIdInputValue,
    MsgTypeAPIInputValue,
    CommTypeLogInputValue,
    OrgNameInputValue,
    MsgTypeLogInputValue,
    InterfaceDescriptionErpStatusInputValue,
    CustomerGroupDescriptionInputValue,
    SupplierGroupDescriptionInputValue,
    StatusColSuccessOrFaliureInputValue,
    ApprovedColInputValue,
    EmailStatusInputValue,
    ApprovedPOInputValue,
    POBuyerPartyCode,
    POSupplierPartyCode,
    POInvoiceePartyCode,
    MessageDirectionSupplierInputValue,
    MessageDirectionCustomerInputValue,
    SuccessFailureStatusColInputValue,
    ShipToCustomerNameInputValue,
    ShipmentNumberInputValue,
    PackingNoInputValue,
    InvoiceNoInputValue,
    BuyerPartyCodeInputValue,
    SupplierPartyCodeInputValue,
    InvoiceeMsgStatusValue,
    BillToCustomerPartyCodeInputValue,
    ASNMsgStatusValue,
    ProcessIDInputValue,
    PoStatusOutboundColInputValue,
    PoStatusOutboundForEDIAndBPColInputValue,
    UserTypeRoleInputValue,
    MessageRcvInputValue,
    PoInboundInputValue,
    EmailStatusForPoVmiInputValue,
    PoAmendmentInputValue,
    PoCancellationInputValue,
    LatestDataSourceInputValue,
    BPMasterInputValue,
    TriggerEventInputValue,
    YesOrNoInputValue,
    EmailMessageLogStatusInputValue,
    WbsCodeInputValue,
    WbsDescriptionInputValue,
    ProgramNameInputValue,
    JobTypeInputValue,
    RequestStatusInputValue,
    ImportTemplateNameInputValue,
    ProcessNameInputValue,
    DeljitInputValue,
    PcnNumberInputValue
}

