import { Button, Grid, CssBaseline, FormControl, Snackbar, useTheme, useMediaQuery, FormHelperText, Tooltip, TextField } from '@mui/material';
import Alert from '@mui/lab/Alert';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { Controller, useForm, } from 'react-hook-form';
import _ from "lodash/fp";
import { useIntl } from "react-intl";
import CreateIcon from '@mui/icons-material/Add';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import useStyles from '../../../common/CommonStyle/CommonStyle';
import CustomBreadCrumb from '../../../common/CustomBreadCrumb';
import ReportTemplateConditionCombo from '../../../common/Combos/ReportTemplateConditionCombo';
import ConditionCombo from '../../../common/Combos/ConditionCombo';
import ConditionLogicCombo from '../../../common/Combos/ConditionLogicCombo';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { MobileDatePicker } from '@mui/lab';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },

});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disabletypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const EmailReportTemplateConditionDialog = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();
    const { onClose, open, title, mode, processValue, reportTemplateConditionTabToBeEdited, tableData, setTableData } = props;
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [componentList, setComponentList] = useState(title);
    useEffect(() => {
        setComponentList(title)
    }, [title])
    const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('');
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
    const [columnValue, setColumnValue] = useState('');
    const [conditionValue, setConditionValue] = useState(mode === 'create' ? { value: '' } : { value: reportTemplateConditionTabToBeEdited.condition });
    const [conditionValidDate, setConditionValidDate] = useState(null);
    const [reportTemplateConditionToBeEdited, setReportTemplateConditionToBeEdited] = React.useState(reportTemplateConditionTabToBeEdited);
    const [disableSaveOrUpdateButton, issaveOrUpdateButtonDisabled] = useState(false);
    const [conditionLogicValue, setConditionLogicValue] = useState('');

    const handleClose = (value) => {
        setComponentList(title);
        onClose(value);
    }
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const pageLabelsConstantsAndMessages = {
        name: {
            columnName: 'columnName',
            condition: 'condition',
            values: 'values',
            conditionType: 'conditionType'
        },
    }

    const { handleSubmit, formState: { errors }, control, reset, setValue } = useForm({
        mode: "all",
        reValidateMode: "all",
        defaultValues: {
            columnName: '',
            condition: '',
            values: '',
            conditionType: ''
        },
    });

    function resetReportTemplateConditionTabValues(responseData) {
        reset({
            columnName: responseData[0].columnName !== undefined ? responseData[0].columnName : '',
            condition: responseData[0].condition !== undefined ? responseData[0].condition : '',
        });
        setColumnValue(responseData[0].columnName !== undefined ? { columns: responseData[0].columnName } : []);
        setConditionValue(responseData[0].condition !== undefined ? { value: responseData[0].condition } : []);
        setConditionValidDate(new Date(responseData[0].values));
        setConditionLogicValue(responseData[0].conditionType !== undefined ? { value: responseData[0].conditionType } : [])
    }

    useEffect(() => {
        setReportTemplateConditionToBeEdited(reportTemplateConditionTabToBeEdited);
    }, [reportTemplateConditionTabToBeEdited]);

    useEffect(() => {
        let currentRouterComponent;
        setValue(`${pageLabelsConstantsAndMessages.name.condition}`, conditionValidDate, { shouldValidate: true, shouldDirty: true });
        if (mode === 'create') {
            isRestoreButtonDisabled(false);
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            currentRouterComponent = [
                {
                    path: null,
                    name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                    iconName: CreateIcon,
                }
            ];
        } else if (mode === 'edit') {
            isRestoreButtonDisabled(false);
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            resetReportTemplateConditionTabValues(reportTemplateConditionTabToBeEdited);
            currentRouterComponent = [
                {
                    path: null,
                    name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                    iconName: EditIcon,
                }
            ]
        } else if (mode === 'read') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isRestoreButtonDisabled(true);
            resetReportTemplateConditionTabValues(reportTemplateConditionTabToBeEdited);
            issaveOrUpdateButtonDisabled(true);
            currentRouterComponent = [
                {
                    path: null,
                    name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
                    iconName: ViewIcon,
                }
            ]
        }
    }, [mode]);

    const onSubmit = data => {
        data['tmplId'] = (mode === 'create') ? Math.floor(Math.random() * 100) : reportTemplateConditionToBeEdited[0].tmplId;
        data['condition'] = conditionValue.value;
        data['values'] = conditionValidDate.toISOString().slice(0, 10);
        data['columnName'] = columnValue.columns;
        data['conditionType'] = conditionLogicValue.value;
        data['dataType'] = typeof data.values;
        if (data.tmplId !== 0 && data.columnName !== '' && data.condition !== '' && data.values !== '') {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success" });
            setTableData(tableData !== [] ? [...tableData, data] : [data]);
            onClose(true);
        }
    }

    const columnComboProperty = {
        name: pageLabelsConstantsAndMessages.name.columnName,
        isRequired: true,
        label: intl.formatMessage({ id: "tabname.columns" }),
        isAutofocus: false,
        isDisabled: mode === 'read' ? true : false,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.columnName
    }

    const conditionComboProperty = {
        name: pageLabelsConstantsAndMessages.name.condition,
        isRequired: true,
        label: intl.formatMessage({ id: "tabname.conditions" }),
        isAutofocus: false,
        isDisabled: mode === 'read' ? true : false,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.condition
    }

    const conditionLogicComboProperty = {
        name: pageLabelsConstantsAndMessages.name.conditionType,
        isRequired: tableData.length === 0 ? false : true,
        label: intl.formatMessage({ id: "textfield.conditionlogic.label" }),
        isAutofocus: false,
        isDisabled: mode === 'read' || tableData.length === 0 || (mode === 'edit' && reportTemplateConditionTabToBeEdited[0].conditionType === null) || (mode === 'edit' && reportTemplateConditionTabToBeEdited[0].conditionType === undefined) ? true : false,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.conditionType
    }

    function resetAllClearThePageInfo() {
        if (mode === 'edit') {
            resetReportTemplateConditionTabValues(reportTemplateConditionTabToBeEdited);
        } else {
            reset({
                condition: '',
                conditionType: null,
                columnName: '',
                values: '',
            });
            setConditionValue([]);
            setConditionLogicValue([]);
            setConditionValidDate(null);
            setColumnValue([]);
        }
    };

    const onError = ((error) => {
    })

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} >
                        <Dialog
                            fullWidth
                            maxWidth='lg'
                            aria-labelledby="create-or-delete-dialog"
                            open={open}>
                            <Snackbar
                                anchorOrigin={{ vertical, horizontal }}
                                open={openAlert}
                                onClose={handleCloseAlert}
                                key={vertical + horizontal}
                                autoHideDuration={6000}
                            >
                                <Alert onClose={handleCloseAlert} severity={severity}>
                                    {message}
                                </Alert>
                            </Snackbar>
                            <DialogTitle className={classes.titleCommonDialogComponent}  >
                                <Grid item container xs={12} sm={12} md={12} lg={12}>
                                    <Grid item container alignItems='center' xs={12} sm={12} md={12} lg={12} style={{ marginLeft: '-1.3%' }} >
                                        <CustomBreadCrumb componentList={componentList} isDialog={true} />
                                    </Grid>
                                </Grid>
                            </DialogTitle>

                            <DialogContent dividers style={{ minHeight: '40vh' }} >
                                <Grid container className={classes.root}>
                                    <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                                            <form className={classes.form} onSubmit={handleSubmit(onSubmit, onError)}>
                                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                        <ConditionLogicCombo
                                                            comboProperty={conditionLogicComboProperty}
                                                            control={control} errors={errors}
                                                            comboValue={conditionLogicValue}
                                                            setComboValue={setConditionLogicValue}
                                                        />
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                        <ReportTemplateConditionCombo
                                                            comboProperty={columnComboProperty}
                                                            control={control} errors={errors}
                                                            comboValue={columnValue}
                                                            setComboValue={setColumnValue}
                                                            processValue={processValue}
                                                        />
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}  >
                                                        <ConditionCombo
                                                            comboProperty={conditionComboProperty}
                                                            control={control} errors={errors}
                                                            comboValue={conditionValue}
                                                            setComboValue={setConditionValue}
                                                        />
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                        {processValue === 'Purchase Order/Purchase Forecast' || processValue === 'Purchase Order/Purchase Forecast-Cancellation' ||
                                                            processValue === 'Purchase Order/Purchase Forecast-Amended' || processValue === 'Purchase Forecast-Only Forecast' ||
                                                            processValue === 'Purchase Order-Only Firm' || processValue === 'Purchase Order-Amended' ||
                                                            processValue === 'Purchase Order-Cancellation' ?
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <FormControl fullWidth>
                                                                    <Controller
                                                                        control={control}
                                                                        name={pageLabelsConstantsAndMessages.name.values}
                                                                        render={({ field }) => (
                                                                            <MobileDatePicker
                                                                                renderInput={props => {
                                                                                    return <TextField {...props}
                                                                                        variant='outlined'
                                                                                        size="small"
                                                                                        label={<FormattedMessage id="common.filterpanelinputlabel.toolbar.lable" />}
                                                                                        {...field}
                                                                                        required
                                                                                        error={errors.values}
                                                                                        InputLabelProps={{
                                                                                            shrink: true,
                                                                                            classes: {
                                                                                                asterisk: classes.asterisk
                                                                                            }
                                                                                        }}
                                                                                    />;
                                                                                }}
                                                                                clearable
                                                                                id="conditionValidDate"
                                                                                inputFormat="dd-MM-yyyy"
                                                                                value={conditionValidDate}
                                                                                error={errors.values}
                                                                                onChange={((date) => {
                                                                                    setConditionValidDate(date);
                                                                                    setValue(`${pageLabelsConstantsAndMessages.name.values}`, date, { shouldValidate: true, shouldDirty: true });
                                                                                })}
                                                                                disabled={mode === 'read' ? true : false}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <div className={classes.error}>
                                                                        {_.get(`${pageLabelsConstantsAndMessages.name.values}.type`, errors) === "required" && conditionValidDate === null && (
                                                                            <FormHelperText className={classes.error}>
                                                                                {<FormattedMessage id="common.filterpanelinputlabel.toolbar.lable" />}    {<FormattedMessage id="textfield.error.isrequirededmessage" defaultMessage="is required" />}
                                                                            </FormHelperText>
                                                                        )}
                                                                    </div>
                                                                </FormControl>
                                                            </LocalizationProvider>
                                                            :
                                                            <FormControl fullWidth>
                                                                <Controller
                                                                    control={control}
                                                                    name={pageLabelsConstantsAndMessages.name.values}
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            variant="outlined"
                                                                            label={<FormattedMessage id="common.filterpanelinputlabel.toolbar.lable" />}
                                                                            {...field}
                                                                            size="small"
                                                                            clearable
                                                                            error={errors.values}
                                                                            required
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {
                                                                                    asterisk: classes.asterisk
                                                                                }
                                                                            }}
                                                                            disabled={mode === 'read' ? true : false}
                                                                        />
                                                                    )}
                                                                />
                                                                <div className={classes.error}>
                                                                    {_.get(`${pageLabelsConstantsAndMessages.name.values}.type`, errors) === "required" && (
                                                                        <FormHelperText className={classes.error}>
                                                                            {<FormattedMessage id="common.filterpanelinputlabel.toolbar.lable" />}    {<FormattedMessage id="textfield.error.isrequirededmessage" defaultMessage="is required" />}
                                                                        </FormHelperText>
                                                                    )}
                                                                </div>
                                                            </FormControl>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>

                            {(!mobileScreen) && (
                                <DialogActions className={classes.dialogAction} >
                                    <Grid item container justifyContent="flex-start" alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} >
                                        {(mode === 'create' || mode === 'edit') ?
                                            <InfoIcon color="primary" /> : null}
                                        {(mode === 'create' || mode === 'edit') ?
                                            <Typography justifyContent="flex-start" style={{ fontSize: 12, marginLeft: '1%' }}><FormattedMessage id="textfield.placeholder.dialogActions" defaultMessage="All the '*' marked fields are mandatory to submit the form" /></Typography> : null}
                                    </Grid>
                                    <Grid item container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} >
                                        <Button size='small' variant="contained" color="primary" className={classes.stateButton} elevation={2} endIcon={<ClearIcon />}
                                            onClick={() => handleClose(false)}
                                        ><FormattedMessage id="common.button.Cancel.label" defaultMessage="Cancel" />
                                        </Button>

                                        <Button size='small' variant="contained" color="secondary" className={classes.stateButtonToRestore}
                                            disabled={disableRestoreButton} elevation={2} endIcon={<IconRestore />} onClick={(() => { resetAllClearThePageInfo() })}
                                        > <FormattedMessage id="common.button.reset.lable" defaultMessage="Restore" />
                                        </Button>
                                        <Button size='small'
                                            variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                            endIcon={<IconSaveOrUpdate />}
                                            disabled={disableSaveOrUpdateButton}
                                            onClick={handleSubmit(onSubmit, onError)}>
                                            {saveOrUpdateButtonLabel}
                                        </Button>
                                    </Grid>
                                </DialogActions>
                            )}
                            {(mobileScreen) && (<Grid item container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                <DialogActions className={classes.dialogAction}>
                                    <Grid item container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                        <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                                            <Button size='small' variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2}
                                                onClick={() => handleClose(false)}
                                            >
                                                <ClearIcon />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={<FormattedMessage id="common.button.reset.lable" />}>
                                            <Button size='small' variant="contained" color="secondary" className={classes.stateButtonToRestore}
                                                disabled={disableRestoreButton} elevation={2} onClick={(() => { resetAllClearThePageInfo() })}><IconRestore />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={(mode === 'create') ? <FormattedMessage id="common.button.save" /> : <FormattedMessage id="common.button.update" />}>
                                            <Button size='small'
                                                variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                                onClick={handleSubmit(onSubmit, onError, alertBoxState)} disabled={disableSaveOrUpdateButton}>
                                                <IconSaveOrUpdate />
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </DialogActions>
                            </Grid>)}
                        </Dialog>
                    </Grid>

                </Grid>
            </Grid>
        </React.Fragment>
    );
}
export default EmailReportTemplateConditionDialog;
