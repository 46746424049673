import React, { useEffect, useState } from 'react';
import _ from "lodash/fp";
import { FormControl, FormHelperText, TextField, useTheme, Grid, CssBaseline, } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import useStyles from '../../../common/CommonStyle/CommonStyle';
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';


const currencies = [
    {
        value: 'Mr',
        label: 'Mr',
    },
    {
        value: 'Ms',
        label: 'Ms',
    },
    {
        value: 'Mrs',
        label: 'Mrs',
    },
    {
        value: 'Mr/Ms',
        label: 'Mr/Ms',
    },
    {
        value: 'Dr',
        label: 'Dr',
    }
];

function ContactDetailsForm(props) {
    const classes = useStyles();
    let theme = useTheme();
    const { contactmode, errors, control, setHasErrors } = props;
    const commonPatterns = GlobalEdiApiConstants();

    const [organizationReadOnlyFields, setOrganizationReadOnlyFields] = useState({
        contactPersonSalutation: false,
        contactPersonName: false,
        contactPersonPhoneNo: false,
        contactPersonEmailId: false,
    });

    const pageLabelsConstantsAndMessages = {
        label: {
            contactPersonSalutation: 'Salutation',
            contactPersonName: 'Contact Person Name',
            contactPersonPhoneNo: 'Contact Person PhoneNo',
            contactPersonEmailId: 'Contact Person EmailID',
        },
        name: {
            contactPersonSalutation: 'contactPersonSalutation',
            contactPersonName: 'contactPersonName',
            contactPersonPhoneNo: 'contactPersonPhoneNo',
            contactPersonEmailId: 'contactPersonEmailId',
        }
    }

    useEffect(() => {
        if (contactmode === 'view') {
            setOrganizationReadOnlyFields({ ...organizationReadOnlyFields, contactPersonName: true, contactPersonPhoneNo: true, contactPersonEmailId: true, contactPersonSalutation: false, })
        }
    }, [contactmode]);


    useEffect(() => {
        setHasErrors(errors)
    }, [errors])


    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.contactPersonSalutation}
                                    rules={contactmode === 'view' ? { required: false } : { required: true }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            select
                                            required={true}
                                            variant="outlined"
                                            margin="normal"
                                            id="contactPersonSalutation"
                                            size='small'
                                            label={<FormattedMessage id="columnname.Salutation.label" defaultMessage="Salutation" />}
                                            error={errors.contactPersonSalutation}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.contactPersonSalutation,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={contactmode === 'view' ? true : false}
                                        >
                                            {currencies.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />

                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.contactPersonSalutation}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.Salutation.label" defaultMessage="Salutation" />  {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.contactPersonSalutation}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.Salutation.label" defaultMessage="Salutation" />  {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.contactPersonName}
                                    rules={contactmode === 'view' ? { required: false } : {
                                        required: true,
                                        maxLength: 100,
                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphabetsOnly
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required={true}
                                            variant="outlined"
                                            margin="normal"
                                            id="contactPersonName"
                                            size='small'
                                            label={<FormattedMessage id="columnname.ContactPersonName.label" defaultMessage="ContactPersonName" />}
                                            {...field}
                                            error={errors.contactPersonName}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.contactPersonName,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={contactmode === 'view' ? true : false}
                                        />
                                    )}
                                />


                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.contactPersonName}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.ContactPersonName.label" defaultMessage="ContactPersonName" />  {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.contactPersonName}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}> <FormattedMessage id="columnname.ContactPersonName.label" defaultMessage="ContactPersonName" /> {commonPatterns.commonErrorsForTextFields.errorForAplhabetsOnly}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.contactPersonName}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.ContactPersonName.label" defaultMessage="ContactPersonName" />  {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.contactPersonPhoneNo}
                                    rules={contactmode === 'view' ? { required: false } :
                                        {
                                            required: true,
                                            pattern: commonPatterns.commonTextfeildPattern.patternForTel_PhNumber,
                                            minLength: 10,
                                            maxLength: 18,
                                        }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required={true}
                                            variant="outlined"
                                            type='text'
                                            margin="normal"
                                            id="contactPersonPhoneNo"
                                            size='small'
                                            label={<FormattedMessage id="columnname.ContactPersonPhoneNo.label" defaultMessage="Contact Person PhoneNo" />}
                                            {...field}
                                            error={errors.contactPersonPhoneNo}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.contactPersonPhoneNo,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={contactmode === 'view' ? true : false}
                                        />
                                    )}
                                />


                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.contactPersonPhoneNo}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.ContactPersonPhoneNo.label" defaultMessage="Contact Person PhoneNo" />} {commonPatterns.commonErrorsForTextFields.errorForTele_PhoneNumber}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.contactPersonPhoneNo}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.ContactPersonPhoneNo.label" defaultMessage="Contact Person PhoneNo" />  {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.contactPersonPhoneNo}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.ContactPersonPhoneNo.label" defaultMessage="Contact Person PhoneNo" /> <FormattedMessage id="textfield.error.cannotexceedmorethaneighteencharacmessage" defaultMessage="Contact Person PhoneNo" /></FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.contactPersonPhoneNo}.type`, errors) === "minLength" && (
                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.ContactPersonPhoneNo.label" defaultMessage="Contact Person PhoneNo" /> {<FormattedMessage id="textfield.error.cannotexceedtendigitits" />}</FormHelperText>
                                    )}

                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.contactPersonEmailId}
                                    rules={contactmode === 'view' ? { required: false } :
                                        {
                                            required: true,
                                            maxLength: 500,
                                            pattern: commonPatterns.commonTextfeildPattern.patternForEmailId
                                        }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required={true}
                                            variant="outlined"
                                            margin="normal"
                                            id="contactPersonEmailId"
                                            multiline
                                            size='small'
                                            label={<FormattedMessage id="columnname.ContactPersonEmailID.label" defaultMessage="Contact Person EmailID" />}
                                            {...field}
                                            error={errors.contactPersonEmailId}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.contactPersonEmailId,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={contactmode === 'view' ? true : false}
                                        />
                                    )}
                                />
                                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={6} md={12} lg={12}>
                                    <FormHelperText><FormattedMessage id="texfield.placeholder.emailid.label"
                                        defaultMessage="Separate Multiple EmailID's with Semicolon(;)" /></FormHelperText>
                                </Grid>

                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.contactPersonEmailId}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}> <FormattedMessage id="columnname.ContactPersonEmailID.label" defaultMessage="Contact Person EmailID" /> {commonPatterns.commonErrorsForTextFields.errorForEmailId}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.contactPersonEmailId}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.ContactPersonEmailID.label" defaultMessage="Contact Person EmailID" />  {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.contactPersonEmailId}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.ContactPersonEmailID.label" defaultMessage="Contact Person EmailID" />{<FormattedMessage id="textfield.error.cannotexceedmorethanfivehundredcharsmessage" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                    </Grid>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default ContactDetailsForm;