import { Button, CircularProgress, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash/fp';
import { CommonService } from '../../../services';

import { LoggedInUserDetails } from '../LoggedInUserDetails/LoggedInUserDetails';
import { useHistory } from 'react-router';
import { Alert } from '@mui/lab';
import IconRestore from '@mui/icons-material/AutorenewOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    dialogAction: {
        padding: '1% 5%'
    },
    error: {
        color: "red",
    },
    buttonSpacing: {
        margin: theme.spacing(0.5),
    }
}));
const VerifyTotp = (props) => {

    const classes = useStyles();
    const history = useHistory();
    const { onClose, open, is2FAEnabled, userId, updateUserPreferredTimeZone } = props;
    const handleClose = (value) => {
        setQrCodeGenerated(false);
        onClose(value);
    }

    const [registerFor2Fa, setRegisterFor2Fa] = useState();
    useEffect(() => {
        setRegisterFor2Fa(!is2FAEnabled);
    }, [is2FAEnabled])
    const [qrCodeSrc, setQrCodeSrc] = useState('');
    const [reloadQRCode, setReloadQRCode] = useState(false);
    const [isQrCodeGenerated, setQrCodeGenerated] = useState(false);
    const [isResponseArrived, setResponseArrived] = useState(false);
    const { handleSubmit, control, formState: { errors } } = useForm({
        mode: "all",
    });
    const [state, setState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = state;
    const { loggedInUserInfo, setLoggedInUserInfo } = useContext(LoggedInUserDetails);
    const handleCloseAlert = () => {
        setState({ ...state, openAlert: false });
    };
    const pageLabelsNamesAndConst = {
        labels: {
            code: 'Multi Factor Authenticator Verification Code',
            verifyButton: 'Verify',
            dialogTitle: 'Multi Factor Authenticator Verification',
            qrScanner: 'Request Qr scanner',
            next: 'Next',
            doItLater: 'Do It Later',
            cancel: 'Cancel',
            reload: 'Reload qr code'
        },
        name: {
            code: 'code'
        }
    };

    useEffect(() => {
        if (open) {
            setQrCodeGenerated(true);
            if (registerFor2Fa) {
                CommonService.getByIdApi('/init2fa', '')
                    .then((response) => {
                        setQrCodeSrc(response.data);
                    });
                setQrCodeGenerated(false);
            } else {
                setQrCodeSrc('');
                setQrCodeGenerated(false);
            }
        }
    }, [registerFor2Fa, reloadQRCode, open])

    const onSubmit = data => {
        setResponseArrived(true);
        CommonService.saveOrUpdateOrPost('/validateG2fa', data)
            .then((response) => {
                setResponseArrived(false);
                localStorage.setItem('loggedInUser', JSON.stringify(response.data));
                setState({ ...state, openAlert: true, message: 'Login successful, redirecting...', severity: "success" });
                setLoggedInUserInfo({ logInStatus: true, roleModuleAccess: CommonService.getModuleAccessList() });
                updateUserPreferredTimeZone(response.data.loginId);
                history.push('/organizations');
                handleClose(true);
            })
            .catch(function (error) {
                setResponseArrived(false);
                let errorMessage = error.response === undefined ? `Connection Error, please check you internet connection` :
                    error.response.data.message;
                setLoggedInUserInfo({ logInStatus: false, roleModuleAccess: [] })
                setState({ ...state, openAlert: true, message: errorMessage, severity: "error" });
            });
    };

    const confirmAndEnable2FA = () => {

        CommonService.getByIdApi(`/confirmAndEnable2FA/${userId}`, '')
            .then((response) => {
                setState({ ...state, openAlert: true, message: 'Multi factor authentication is enabled successfully', severity: "success" });
            })
            .catch(function (error) {
                setState({ ...state, openAlert: true, message: error.response.data.message, severity: "error" });
            })

    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Dialog
                fullWidth
                aria-labelledby="create-or-delete-dialog"
                open={open}>
                <DialogTitle color='primary' className={classes.title}>
                    <Grid container>
                        <Grid container justifyContent='flex-start' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                            <strong>{pageLabelsNamesAndConst.labels.dialogTitle}</strong>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers style={{ minHeight: "200px" }}>
                    <Grid container justifyContent='center' alignItems='center' component='main' className={classes.root}>
                        {(registerFor2Fa && isQrCodeGenerated) && (
                            <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                <CircularProgress color="primary" size={40} />
                            </Grid>)}
                        {registerFor2Fa && (<Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                            { !isQrCodeGenerated && (
                                <div>
                                    <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                        <img src={qrCodeSrc} alt='qr-code' width="250" height="250" />

                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            (Note : Please scan this qr code in Google Authenticator app then click 'NEXT' to proceed further)
                                    </Typography>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='baseline' item xs={12} sm={12} md={12} lg={12}>
                                        <Tooltip placement='right-end' title={pageLabelsNamesAndConst.labels.reload}
                                            onClick={(() => {
                                                setReloadQRCode(!reloadQRCode);
                                            })}>
                                            <IconRestore />
                                        </Tooltip>
                                    </Grid>
                                </div>)}
                        </Grid>)}
                        {!registerFor2Fa && (<Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsNamesAndConst.name.code}
                                    rules={{ required: true }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            id="totp"
                                            type="number"
                                            inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder='_ _ _ _ _ _'
                                            label={pageLabelsNamesAndConst.labels.code + ' *'}
                                            name={pageLabelsNamesAndConst.name.code}
                                            {...field}
                                        // inputRef={register(
                                        //     {
                                        //         required: true,
                                        //         maxLength: 6,
                                        //         minLength: 6
                                        //     }
                                        // )}
                                        />
                                    )}
                                />
                                <FormHelperText>Please enter verification code displayed in Authenticator App</FormHelperText>
                                <div className={classes.error}>
                                    {_.get(`${pageLabelsNamesAndConst.name.code}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{pageLabelsNamesAndConst.labels.code} is required</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsNamesAndConst.name.code}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>should not exceed more than 6 chars</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsNamesAndConst.name.code}.type`, errors) === "minLength" && (
                                        <FormHelperText className={classes.error}>cannot be less than 6 chars</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>)}
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                        <Button size='small' variant='contained' color='secondary' className={classes.buttonSpacing}
                            onClick={(() => {
                                handleClose(false);
                            })}>{registerFor2Fa ? pageLabelsNamesAndConst.labels.doItLater : pageLabelsNamesAndConst.labels.cancel}
                        </Button>
                        {registerFor2Fa && (<Button size='small' variant='contained' color='primary' className={classes.buttonSpacing}
                            onClick={(() => {
                                setRegisterFor2Fa(false);
                                confirmAndEnable2FA();
                            })}>{pageLabelsNamesAndConst.labels.next}
                        </Button>)}
                        {!registerFor2Fa && (<Button type="submit" size='small' variant='contained' color='primary' className={classes.buttonSpacing}
                            onClick={handleSubmit(onSubmit)} disabled={isResponseArrived} endIcon={isResponseArrived ? <CircularProgress color="secondary" size={20} /> : null}
                        >{pageLabelsNamesAndConst.labels.verifyButton}
                        </Button>)}
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default VerifyTotp;