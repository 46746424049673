import { Badge, Button, CssBaseline, Grid, IconButton, Paper, Snackbar, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Alert } from '@mui/lab';
import { ModuleAccessPermissionKey } from '../Constants/ModuleAccessKey';
import CommonGridPagination from '../components/common/CommonGridPagination/CommonGridPagination';
import Box from '@mui/material/Box';
import { getGridStringOperators, GridToolbarColumnsButton, GridToolbarDensitySelector, LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CommonService } from '../services';
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LoggedInUserDetails } from '../components/common/LoggedInUserDetails/LoggedInUserDetails';
import useStyles from '../components/common/CommonStyle/CommonStyle';
import GridTextLocalization from '../components/common/GridTextLocalization/GridTextLocalization';
import { SupportedLanguageDetails } from '../components/common/SupportedLanguageDetails/SupportedLanguageDetails';
import { GlobalEdiApiConstants } from '../Constants/GlobalEdiApiConstants';
import ConfirmationDialog from '../components/common/ConfirmationDialog';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import { Controller, useForm } from 'react-hook-form';
import IconMessageConfig from '@mui/icons-material/Message';
import CustomDialogTab from './CustomDialogTab';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';


const columnFieldsName =
{
    relativeDate: 'relativeDate',
    processDate: 'processDate',
    priorReminderDays: 'priorReminderDays',
    postReminderDays: 'postReminderDays',
    frequency: 'frequency',
    timeZone: 'timeZone',
    msgScheduleId: 'msgScheduleId'
}

function CustomSchedulerRedesignTab(props) {
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const pageLabelsConstantsAndMessages = {
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
    };

    const { open, onClose, title, intfHdrId, valuesforResetting, valuesTobeEdited, DialogHeaderAndHeaderValue, processNameValue
        , messageConfigDtlObjectToBeEditedOrView, frequenciesScheduleTab, setFrequenciesScheduleTab, setMsgScheduleFrequenciesToBeEdited, schedulerTab, setSchedulerTab, msgId
    } = props;

    const [messageConfigDtlObjectToBeEditedOrViews, setMessageConfigDtlObjectToBeEditedOrView] = useState({});
    const [resettingValues, setResettingValues] = useState(props.resettingValues)
    const [interfaceDefaultToBeEdited, setInterfaceDefaultToBeEdited] = useState({})
    const [createOrEditOrViewMessageConfigDialog, setCreateOrEditOrViewMessageConfigDialog] = useState(false);
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const { configId, mode, messageConfigToBeEdited } = props;
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [columns, setColumns] = useState([]);
    const [refreshTable, setRefreshTable] = useState(false);
    const [msgSchdeuleId, setMsgSchdeuleId] = useState(0);
    const [msgIds, setMsgId] = useState(messageConfigDtlObjectToBeEditedOrView.msgId);
    const [OpenDialogBox, SetOpenDialogBox] = useState(false)
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedModel, setSelectedModel] = useState([]);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);
    const [timeZoneValue, setTimeZoneValue] = useState({ value: DialogHeaderAndHeaderValue.entity === undefined ? '' : DialogHeaderAndHeaderValue.entity.timeZone });

    const componentList = [
        {
            path: null,
            name: <FormattedMessage id="common.menu.breadcrumb.messageconfiguration" />,
            iconName: IconMessageConfiguration,
        },
        {
            path: '/messageConfigHdrs',
            name: <FormattedMessage id="common.menu.breadcrumb.messageConfig" defaultMessage="MessageConfig" />,
            iconName: IconMessageConfig,
        }
    ];
    const [paramBody, setParamBody] = useState(
        {
            body: {
                messageConfigDtls: { msgId: msgId },
                relativeDate: '',
                processDate: '',
                priorReminderDays: '',
                postReminderDays: '',
                frequency: '',
                timeZone: '',
            },
            pageNo: 0,
            sortDir: 'asc',
            sortKey: 'msgScheduleId',
            recordsPerPage: 25,
        }
    );

    useEffect(() => {
        if (msgId > 0) {
            setParamBody({
                ...paramBody, body:
                {
                    messageConfigDtls: { msgId: msgId },
                    relativeDate: '',
                    processDate: '',
                    priorReminderDays: '',
                    postReminderDays: '',
                    frequency: '',
                    timeZone: '',
                }
            })
        }
    }, [msgId])

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
      if(mode != 'create') {
        setTableLoadingStatus(true);
        setTableData([]);
        CommonService.GetListApi(`/messageConfigDtls/${msgId}/messageConfigDtlsFreq`, paramBody)
            .then((response) => {
                setTotalRowCount(response.data.totalElements);
                setTableLoadingStatus(false);
                setTableData(response.data.content);
            })
            .catch(function (error) {
                setTableLoadingStatus(false);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
        }
        }, [paramBody]);

    const { handleSubmit, formState: { errors, isDirty, isValid, dirtyFields }, reset, control, getValues, setValue } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        shouldUnregister: false,
        defaultValues: {

        },
    });

    const commonBreadCrumbToBeUsedForDialog = componentList;
    const closeCreateOrEditViewDialog = (confirmed) => {
        if (confirmed) {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success" });
            setRefreshTable(!refreshTable);
        }
        setMessageConfigDtlObjectToBeEditedOrView({});
        SetOpenDialogBox(false);
    }

    const messageConfigModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.messageConfig];
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const localtext = GridTextLocalization();
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [selectedFreqCombo, setSelectedFreqCombo] = useState({ value: "" });
    const [selectedCustomProcessDateCombo, setSelectedCustomProcessDateCombo] = useState({ processDate: "" });
    const inOutColumn = columns.find((column) => column.field === columnFieldsName.inOut);
    const inOutColIndex = columns.findIndex((col) => col.field === columnFieldsName.inOut);
    const [createConfigDtlMode, setMode] = useState('');


    const closeConfirmationDialog = (confirmed) => {
        setOpenConfirmationDialog(false);

        if (confirmed) {
            CommonService.deleteByIdApi(`/messageConfigDtlsFreq/${msgSchdeuleId}`, {})
                .then((response) => {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: "Message Config Frequency Deleted Successfully", severity: "success" });
                    setRefreshTable(!refreshTable);
                    setSelectedRows([]);
                })
                .catch(function (error) {

                    let message = (error.response.data !== undefined || error.response.data !== null) ? error.response.data.message
                        : <FormattedMessage id="role.error.txt" defaultMessage="Error occurred while deleting Message Config Frequency!" />;
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: message, severity: "error" });
                });
        }
    }

    useEffect(() => {
        if (createConfigDtlMode === 'delete') {
            closeConfirmationDialog();
            removeFromSchedulerTab(selectedRows.msgScheduleId)
          
        }
    }, [refreshTable]);

    const removeFromSchedulerTab = (itemIdToRemove) => {

        const updatedSchedulerTab = schedulerTab.filter(item => item.msgScheduleId !== itemIdToRemove);

        setSchedulerTab(updatedSchedulerTab);
        setTableData(updatedSchedulerTab);
    };

    useEffect(() => {
        setColumns([
            {
                field: columnFieldsName.relativeDate,
                headerName: intl.formatMessage({ id: "columnname.relativeDate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 200,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },

            {
                field: columnFieldsName.processDate,
                headerName: intl.formatMessage({ id: "columnname.processDate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 200,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },

            {
                field: columnFieldsName.priorReminderDays,
                headerName: intl.formatMessage({ id: "columnname.priorReminderDays.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 200,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.postReminderDays,
                headerName: intl.formatMessage({ id: "columnname.postReminderDays.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 200,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.frequency,
                headerName: intl.formatMessage({ id: "columnname.frequency.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 200,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },

            {
                field: columnFieldsName.timeZone,
                headerName: intl.formatMessage({ id: "customschedulartab.timezone.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 200,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
        ])
    }, [selectedLanguage, messageConfigToBeEdited]);

    // const handleRowSelectionModelChange = (selectionModel) => {
    //     setMode('edit');
    //     setSelectedRows(selectionModel);
    //     //  SetOpenDialogBox(true)
    //     setSchedulerTab(selectionModel);
    //     console.log('rowData', selectionModel)

    // };

    const handleDelete = (primaryKey, nameToBeDisplacedOnConfirmationDialog, processNameToBeDisplacedOnDelete) => {
        if (selectedRows !== null) {
            setMsgSchdeuleId(primaryKey);
            setConfirmationMessage(
                <FormattedMessage
                    id="common.dialog.areyousure.txt"
                    values={{
                        nameToBeDisplacedOnConfirmationDialog: nameToBeDisplacedOnConfirmationDialog === 'Custom Date' || processNameToBeDisplacedOnDelete === null ?
                            `${nameToBeDisplacedOnConfirmationDialog}` :
                            `${nameToBeDisplacedOnConfirmationDialog} for ${processNameToBeDisplacedOnDelete}`
                    }}
                    defaultMessage={`Are you sure, You want to delete '${nameToBeDisplacedOnConfirmationDialog}'${nameToBeDisplacedOnConfirmationDialog !== 'Custom Date' && processNameToBeDisplacedOnDelete !== null ? ` for '${processNameToBeDisplacedOnDelete}'` : ''}?`}
                />
            );
            setOpenConfirmationDialog(true);
        }
    }

    return (
        <React.Fragment>
            <CssBaseline />
            {<Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            }
            <Paper elevation={3} className={classes.gridHeightAndWidthForMessageConfigDtl}>
                <div style={{ height: '100%', width: '100%' }}>

                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            editMode="row"
                            columns={columns}
                            rows={tableData}
                            getRowId={(row) => row.msgScheduleId === undefined ? row.id : row.msgScheduleId}
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination={true}
                            paginationMode="server"
                            disableSelectionOnClick={false}
                            disableColumnMenu={false}
                            disableMultipleColumnsSorting={true}
                            onRowClick={(params) => {
                                setSelectedRows(params.row)
                            }}
                            onSelectionModelChange={(model) => {
                                setSelectedModel(model);
                            }}
                            selectionModel={selectedModel}
                            hideFooter={true}
                            localeText={localtext}
                            density="compact"
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            onSortModelChange={(params) => {
                                let sortModel = params[0];
                                if (sortModel !== undefined) {
                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                }
                            }}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}  >
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>

                                                <Grid>
                                                    {(messageConfigModuleAccess !== undefined ? messageConfigModuleAccess.createFlag : true) && (mode !== 'read' || mode == 'create' || mode == 'Edit') &&
                                                        (<Tooltip title={<FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="create" />} placement='bottom'>
                                                            <span>
                                                                <Button size="small" color="primary" variant="outlined"
                                                                    className={classes.refreshToobarStyle}

                                                                    onClick={(() => {
                                                                        setMode('create');
                                                                        SetOpenDialogBox(true)
                                                                    })}
                                                                    disabled={(configId === 0 || mode === 'read') ? true : false}
                                                                >
                                                                    <CreateIcon />
                                                                </Button>
                                                            </span>
                                                        </Tooltip>)}
                                                </Grid>
                                                <Grid>
                                                    {(messageConfigModuleAccess !== undefined ? messageConfigModuleAccess.createFlag : true) && (mode !== 'read' || mode == 'Edit') &&
                                                        (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />} placement='bottom'>
                                                            <span>
                                                                <Button size="small" color="primary" variant="outlined"
                                                                    className={classes.refreshToobarStyle}
                                                                    onClick={(() => {
                                                                        setMode('edit');
                                                                        SetOpenDialogBox(true)
                                                                    })}
                                                                    disabled={selectedRows.length != 0 ? false : true}                                                                    // disabled={(configId === 0 || mode === 'read') ? true : false}
                                                                >
                                                                    <EditIcon />
                                                                </Button>

                                                            </span>
                                                        </Tooltip>)}
                                                </Grid>
                                                <Grid>
                                                    {(messageConfigModuleAccess !== undefined ? messageConfigModuleAccess.deleteFlag : false) && (mode !== 'read') &&
                                                        (<Tooltip title={<FormattedMessage id="common.button.delete.tooltip" defaultMessage="Delete" />} placement='bottom'>
                                                            <span>
                                                                <Button size="small" color="primary" variant="outlined"
                                                                    className={classes.refreshToobarStyle}
                                                                    onClick={(() => {
                                                                        setMode('delete');
                                                                        handleDelete(selectedRows.msgScheduleId, selectedRows.relativeDate, selectedRows.processDate);
                                                                        setMsgSchdeuleId(selectedRows.msgScheduleId);
                                                                        setMsgId(messageConfigDtlObjectToBeEditedOrView.msgId)
                                                                    })}
                                                                    disabled={selectedRows.length != 0 ? false : true}                                                           
                                                                >
                                                                    <DeleteIcon />
                                                                </Button>

                                                            </span>
                                                        </Tooltip>)}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </Box>
                </div>
            </Paper>
            <CustomDialogTab
                open={OpenDialogBox}
                onClose={closeCreateOrEditViewDialog}
                control={control}
                errors={errors}
                setMessageConfigDtlObjectToBeEditedOrView={setResettingValues}
                messageConfigDtlObjectToBeEditedOrView={resettingValues}
                mode={createConfigDtlMode}
                reset={reset}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                selectedFreqCombo={selectedFreqCombo}
                setSelectedFreqCombo={setSelectedFreqCombo}
                selectedCustomProcessDateCombo={selectedCustomProcessDateCombo}
                setSelectedCustomProcessDateCombo={setSelectedCustomProcessDateCombo}
                setMsgConfigDtlToBeEdited={setInterfaceDefaultToBeEdited}
                setValue={setValue}
                setTimeZoneValue={setTimeZoneValue}
                timeZoneValue={timeZoneValue}
                getValues={getValues()}
                processNameValue={processNameValue}
                setFrequenciesScheduleTab={setFrequenciesScheduleTab}
                frequenciesScheduleTab={frequenciesScheduleTab}
                messageConfigDtlObjectToBeEditedOrView={messageConfigDtlObjectToBeEditedOrView}
                schedulerTab={schedulerTab}
                setSchedulerTab={setSchedulerTab}
                DialogHeaderAndHeaderValue={DialogHeaderAndHeaderValue}
                title={commonBreadCrumbToBeUsedForDialog}
                tableData={tableData}
                setTableData={setTableData}
            />
            <ConfirmationDialog onClose={closeConfirmationDialog} message={confirmationMessage} open={openConfirmationDialog} />

        </React.Fragment>
    )
}
export default CustomSchedulerRedesignTab;
