import { Grid,Typography } from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Link } from 'react-router-dom';
import useStyles from '../CommonStyle/CommonStyle'
import { FormattedMessage } from 'react-intl';

const Unauthorized = (props) => {

    const classes = useStyles();
    const warningTitle = <FormattedMessage id="warningtitle.unauthorized" defaultMessage='403 - Unauthorized Access' />;
    const warningSubTitle = <FormattedMessage id="warningsubtitle.unauthorized" defaultMessage='Causes for unauthorized access' />;

    return (
        <React.Fragment>
            <Grid className={classes.rootForUnauthorized} container justifyContent='center' alignItems='center' spacing={2}>
                <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                    <WarningIcon fontSize="large" className={classes.waringIcon} />
                </Grid>
                <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h4" style={{ textAlign: 'justifyContent', fontWeight: 'bold' }}>
                        {warningTitle}
                    </Typography>
                </Grid>
                <Grid container justifyContent='center' alignItems='baseline' item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="body1" style={{ textAlign: 'justifyContent', fontWeight: 'bold' }}>
                        {warningSubTitle}
                    </Typography>
                </Grid>
                <Grid container justifyContent='center' item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-20px' }}>
                    <ul>
                        <li><FormattedMessage id="permission.unauthorized" defaultMessage="May be you don't have access permission" /></li>
                        <li><FormattedMessage id="typeUrl.unauthorized" defaultMessage="May be you have typo in URL" /></li>
                        <li><FormattedMessage id="navigatewithoutsignin.unauthorized" defaultMessage="Navigated directly without Sign In" /></li>
                        <li><FormattedMessage id="reloadpage.unauthorized" defaultMessage="Reloaded the page" /></li>
                    </ul>

                </Grid>
                <Typography variant="body1" style={{ textAlign: 'justifyContent', fontWeight: 'bold' }}>
                    <Link to="/login" variant="h6">
                       <FormattedMessage id="backtologinpage.unauthorized" defaultMessage="Back to Login page" />
                </Link>
                </Typography>
            </Grid>
        </React.Fragment >
    );
}

export default Unauthorized;