import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, CircularProgress, ClickAwayListener, Divider, Fade, FormControl, Grid, IconButton, List, ListItem, MenuItem, MenuList, Paper, Popper, Snackbar, TextField, Tooltip, Typography, useMediaQuery, useTheme, TablePagination } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Alert, ToggleButton, ToggleButtonGroup } from '@mui/lab';
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import CommonBackDrop from '../../common/BackDrop';
import useStyles from '../../common/CommonStyle/CommonStyle';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import ViewIcon from '@mui/icons-material/Visibility';
import TabIcon from '@mui/icons-material/Tab';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconBack from '@mui/icons-material/BackspaceOutlined';
import IconMessage from '@mui/icons-material/Forum';
import IconPurchaseOrderPurchaseForecastOutbound from '@mui/icons-material/AssignmentReturn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CommonService } from '../../../services';
import TableChartIcon from '@mui/icons-material/TableChart';
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import saveAs from 'file-saver';
import GetAppIcon from '@mui/icons-material/Publish';
import { GridToolbarColumnsButton, GridToolbarDensitySelector, DataGridPro } from '@mui/x-data-grid-pro';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';
import HistoryIcon from '@mui/icons-material/HistorySharp';
import PFAuditIcon from '@mui/icons-material/Timeline';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import clsx from 'clsx';
import ExportIcon from '@mui/icons-material/Publish';
import ImportIcon from '@mui/icons-material/SaveAlt';
import AuditPoDelCfmDt from '../../Order/AuditPoDelCfmDt/AuditPoDelCfmDt';
import POConfirmDateDialog from '../../Order/POConfirmDateDialog/POConfirmDateDialog';
import EditIcon from '@mui/icons-material/Edit';
import { Doughnut } from 'react-chartjs-2';
import DeliveryConfirmationStatusCombo from '../../common/Combos/DeliveryConfirmationStatusCombo';
import CreateIcon from '@mui/icons-material/Add';
import FileUploadDialog from '../../common/FileUploadDialog';
import AuditPoPfDt from '../../Order/AuditPoDelCfmDt/AuditPoPfDt';
import { DataUsage } from '@mui/icons-material';
import DataSourceDialog from '../DataSourceDialog';
import GetApp from '@mui/icons-material/GetApp';

const StyledToggleButton = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
    },
    selected: {
        backgroundColor: "#d8d8d8",
    }

}))(ToggleButton);

const columnHeaderNames = {
    msgId: 'msgId',
    msgDtlsId: 'msgDtlsId',
    poLineNo: 'poLineNo',
    schedulingAgreementRelNo: 'schedulingAgreementRelNo',
    itemCode: 'itemCode',
    itemShortDesc: 'itemShortDesc',
    itemDesc: 'itemDesc',
    makerPartNo: 'makerPartNo',
    customerPartNo: 'customerPartNo',
    lineActionFlag: 'lineActionFlag',
    uom: 'uom',
    wbsCode: 'wbsCode',
    unitPrice: 'unitPrice',
    taxType1: 'taxType1',
    taxType2: 'taxType2',
    taxRate1: 'taxRate1',
    taxRate2: 'taxRate2',
    taxAmount1: 'taxAmount1',
    taxAmount2: 'taxAmount2',
    totalAmount: 'totalAmount',
    dlvrySchNo: "dlvrySchNo",
    dlvrySchIssueDt: "dlvrySchIssueDt",
    lastDespatchQty: 'lastDespatchQty',
    lastDespatchNo: 'lastDespatchNo',
    lastDespatchNoDt: 'lastDespatchNoDt',
    lastDespatchUom: 'lastDespatchUom',
    totalQtyReceived: 'totalQtyReceived',
    totalQtyReceivedStartDt: 'totalQtyReceivedStartDt',
    totalOutstandingOrderQty: 'totalOutstandingOrderQty',
    commitmentType: 'commitmentType',
    commitmentDuration: 'commitmentDuration',
    commitmentAuthorizationDate: 'commitmentAuthorizationDate',
    poLineDeleted: 'poLineDeleted',
    poLineStatus: 'poLineStatus',
    salesOrderNUmber: 'salesOrderNUmber',
    salesOrderLineNumber: 'salesOrderLineNumber',
    placeOfDelivery: 'placeOfDelivery',
    placeOfDischarge: 'placeOfDischarge',
    shipToPartyCode: 'shipToPartyCode',
    shipToName: 'shipToName',
    shipToAddress1: 'shipToAddress1',
    shipToCity: 'shipToCity',
    shipToState: 'shipToState',
    shipToCountry: 'shipToCountry',
    shipToPostalCode: 'shipToPostalCode',
    shipToTelNo: 'shipToTelNo',
    shipToFaxNo: 'shipToFaxNo',
    shipToEmail: 'shipToEmail',
    lineShipmentNo: 'lineShipmentNo',
    lineShipmentStatus: 'lineShipmentStatus',
    orderType: 'orderType',
    msgStatusFlag: 'msgStatusFlag',
    msgStatusRemarks: 'msgStatusRemarks',
    messageReceivedTime: 'messageReceivedTime',
    messageSentMode: 'messageSentMode',
    lastMessageSentTime: 'lastMessageSentTime',
    attribute1: 'attribute1',
    attribute2: 'attribute2',
    attribute3: 'attribute3',
    attribute4: 'attribute4',
    attribute5: 'attribute5',
    attribute6: 'attribute6',
    attribute7: 'attribute7',
    attribute8: 'attribute8',
    attribute9: 'attribute9',
    attribute10: 'attribute10',
    attribute11: 'attribute11',
    attribute12: 'attribute12',
    attribute13: 'attribute13',
    attribute14: 'attribute14',
    attribute15: 'attribute15',
    poLineDeliveryConfirmed: 'poLineDeliveryConfirmed',
    dlvyCfmStatus: 'dlvyCfmStatus',
    ackStatus: 'ackStatus',
    supplierInvoice:'supplierInvoice'

};

function EditOrViewPurchaseOrderForecastOutbound(props) {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const { mode, msgId, ispurchaseforecast, isBusinessPartnerPortal, userAccessBpCode, userAccessBpName, defaultEntity } = props.location.state;
    const { handleSubmit, register, control, errors, reset } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: {
            itemDesc: '',
            poLineDeliveryConfirmed: null,
            dlvyCfmStatus: '',
            
        },
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    });

    const pageLabelsConstantsAndMessages = {
        name: {
            itemDesc: 'itemDesc',
            poLineDeliveryConfirmed: 'poLineDeliveryConfirmed',
            dlvyCfmStatus: 'dlvyCfmStatus',
            ackStatus:'ackStatus',
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    }
    const mobileScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const smallScreen = useMediaQuery(theme.breakpoints.down(889))
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const localtext = GridTextLocalization();
    const poPfOutboundModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.outboundPurchaseOrders];
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [rowsPerPageForList, setRowsPerPageForList] = React.useState(25);

    const [paramBody, setParamBody] = useState({
        body: {
            itemDesc: '',
            poLineDeliveryConfirmed: null,
            dlvyCfmStatus: '',
            poOutboundMsgHdr: {
                approved: '',
            },
            recordApprovedStatus: 'All',
            recordPoDlvLineCfm: 'All',
            recordAmendmentCount: 'All',
            recordStatusFlag: 'All',
            fromUI: true
        },
        pageNo: totalPageCount,
        sortDir: 'asc',
        sortKey: 'msgDtlsId',
        recordsPerPage: rowsPerPageForList,
    });

    const [alertBoxState, setAlertBoxState] = useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [disableRefreshButton, isRefreshButtonDisabled] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [poOrPfToBeEdited, setPoOrPfToBeEdited] = useState();
    const [poDetailItemViewType, setPoDetailItemViewType] = React.useState('list');
    const [disableDownloadButton, setDisableDownloadButton] = useState(false);
    const [disableApproveButton, setDisableApproveButtton] = useState(false);
    const [disableRejectButton, setDisableRejectButton] = useState(false);
    const [msgDtlsPopUp, setMsgDtlsPopUp] = useState(false);
    const [msgDtlsPopUpForPf, setMsgDtlsPopUpForPf] = useState(false);
    const [selectedPoHdr, setSelectedPoHdr] = useState();
    const [tableData, setTableData] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(true);
    const [columns, setColumns] = useState([]);
    const [poDtlValue, setPoDtlvalue] = useState([]);
    const [poDtlObject, setPoDtlObject] = useState([]);
    const [deliveryCfmStatus, setDeliveryCfmStatus] = useState('');
    const [searchedMaterialDesc, setSearchedMaterialDesc] = useState('');
    const [searchedDelCfmStatus, setSearchedDelCfmStatus] = useState(null);
    const [poItemDetailsObject, setPOIteDetailsObject] = useState();
    const [openPoCfmAuditDialog, setOpenPoCfmAuditDialog] = useState(false);
    const [openPoPftAuditDialog, setOpenPoPftAuditDialog] = useState(false);
    const [openDataSourceDialog, setOpenDataSourceDialog] = useState(false);
    const [openPOConfirmDateDialog, setOpenPOConfirmDateDialog] = useState(false);
    const [modeForSchedule, setModeForSchedule] = useState('');
    const [msgSchDetails, setMsgSchDetails] = useState([]);
    const [itemCode, setItemCode] = useState('');
    const [poLineNumber, setPoLineNumber] = useState('');
    const [itemDesc, setItemDesc] = useState('');
    const [msgStatusFlagValue, setMsgStatusFlagValue] = useState('');
    const [isExportXlRequested, setExportXlRequested] = useState(false);
    const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [reportDownloadForDlvCfmDateAuditPopUp, setReportDownloadForDlvCfmDateAuditPopUp] = useState(false);

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
        requestXLReportExport(menuOptionsForExportButton[index], false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const PoStatusForOutBoundChips = (props) => {
        const { value } = props;
        return (
            <React.Fragment>
                { value === 2 && (
                    <Typography variant='body2' noWrap={true} className={(classes.boldHeader, classes.completeStatus)}>
                        {"Successfully Sent"}
                    </Typography>
                )}
                { value === 3 && (<Typography variant='body2' noWrap={true} padding='0 20px 0 20px'
                    className={(classes.boldHeader, classes.incompleteStatus)}>
                    {"Failed to Send"}
                </Typography>)}
                { value === 4 && (<Typography variant='body2' noWrap={true} padding='0 20px 0 20px'
                    className={(classes.boldHeader, classes.incompleteStatus)}>
                    {"Cancelled"}
                </Typography>)}
                { value === 1 && (<Typography variant='body2' noWrap={true}
                    className={(classes.boldHeader, classes.pendingStatus)}>
                    {"Pending"}
                </Typography>)}
                { value === 0 && (<Typography variant='body2' noWrap={true}
                    className={(classes.boldHeader, classes.newStatus)}>
                    {"Not Sent"}
                </Typography>)}
                { value === 6 && (<Typography variant='body2' noWrap={true}
                    className={(classes.boldHeader, classes.pendingStatus)}>
                    {"In-Queue"}
                </Typography>)}
            </React.Fragment>
        );
    }

    const DelCfmStatusDisplayField = (props) => {
        const { value } = props;
 
        return (
            <React.Fragment>
                { value === "1" && (
                    <Typography variant='body2' noWrap={true} className={(classes.boldHeader, classes.completeStatus)}>
                        CONFIRMED
                    </Typography>)}
                { value === "0" && (<Typography variant='body2' noWrap={true} padding='0 20px 0 20px'
                    className={(classes.boldHeader, classes.incompleteStatus)}>
                    NOT-CONFIRMED
                </Typography>)}
                { value === "2" && (<Typography variant='body2' noWrap={true} padding='0 20px 0 20px'
                    className={(classes.boldHeader, classes.pendingStatus)}>
                    ACK BY DUMMY DATE
                </Typography>)}
                { value === "3" && (<Typography variant='body2' noWrap={true} padding='0 20px 0 20px'
                    className={(classes.boldHeader, classes.pendingStatus)}>
                    PARTIALLY CONFIRMED
                </Typography>)}
            </React.Fragment>
        );
    }

    const AckStatusDisplayField = (props) => {
        const { value } = props;
        return (
            <React.Fragment>
                { value === 0 && (
                    <Typography variant='body2' noWrap={true} className={(classes.boldHeader, classes.newStatus)}>
                        {"Not Approved"}
                    </Typography>
                )}
                { value === 1 && (
                    <Typography variant='body2' noWrap={true} className={(classes.boldHeader, classes.pendingStatus)}>
                        {"Waiting"}
                    </Typography>
                )}
                { value === 2 && (
                    <Typography variant='body2' noWrap={true} className={(classes.boldHeader, classes.incompleteStatus)}>
                        {"Rejected"}
                    </Typography>
                )} 
                { value === 3 && (
                    <Typography variant='body2' noWrap={true} className={(classes.boldHeader, classes.completeStatus)}>
                        {"Approved"}
                    </Typography>
                )}
                { value === 4 && (
                    <Typography variant='body2' noWrap={true} className={(classes.boldHeader, classes.incompleteStatus)}>
                        {"Deleted"}
                    </Typography>
                )} 
            </React.Fragment>
        );
    }
    const commonColumnsForPOPfTableView = [
        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: 170,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    {(<Tooltip title={<FormattedMessage id="expcfmadthistory.tooltip" />}
                        placement='bottom' className={classes.actionButton} onClick={(() => {
                            setPOIteDetailsObject(cellParams.row.msgDtlsId);
                            setOpenPoCfmAuditDialog(true);
                            setPoLineNumber(cellParams.row.poLineNo);
                        })}>
                        <span>
                            <IconButton color='secondary' size="large">
                                <HistoryIcon />
                            </IconButton>
                        </span>
                    </Tooltip>)}
                    {(<Tooltip title={<FormattedMessage id="exppoadthistory.tooltip" />}
                        placement='bottom' className={classes.actionButton} onClick={(() => {
                            setPOIteDetailsObject(cellParams.row.msgDtlsId);
                            setOpenPoPftAuditDialog(true);
                            setPoLineNumber(cellParams.row.poLineNo);
                        })}>
                        <span>
                            <IconButton size="large">
                                <PFAuditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>)}
                    {(mode === "view")
                        && (<Tooltip title={<FormattedMessage id="viewScheduledPo.tooltip" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                setModeForSchedule('view');
                                setPoDtlObject(cellParams.row)
                                setPoDtlvalue(cellParams.row.msgDtlsId)
                                setOpenPOConfirmDateDialog(true);
                                setPoLineNumber(cellParams.row.poLineNo);
                                setItemDesc(cellParams.row.itemDesc);
                                setItemCode(cellParams.row.itemCode);
                                setMsgSchDetails(cellParams.row);
                            })}>
                            <span>
                                <IconButton color='secondary' size="large">
                                    <ViewIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {(mode === 'edit')
                        && (<Tooltip title={<FormattedMessage id="viewScheduledPo.tooltip" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                setModeForSchedule('edit');
                                setPoDtlObject(cellParams.row)
                                setPoDtlvalue(cellParams.row.msgDtlsId)
                                setOpenPOConfirmDateDialog(true);
                                setPoLineNumber(cellParams.row.poLineNo);
                                setItemDesc(cellParams.row.itemDesc);
                                setItemCode(cellParams.row.itemCode);
                                setMsgSchDetails(cellParams.row);
                                setMsgStatusFlagValue(cellParams.msgStatusFlag);
                            })}>
                            <span>
                                <IconButton color='secondary' size="large" disabled={cellParams.row.totalOutstandingOrderQty === 0 || cellParams.row.poLineGrCompleted === 'X' || cellParams.row.poLineDeleted === 'L' ? true : false}>
                                    <CreateIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    <Tooltip title={<FormattedMessage id="columnname.exportdlvcfmdatesaudit.label" />}
                        placement='bottom' className={classes.actionButton}>
                        <IconButton size='small' color="secondary"
                            disabled={cellParams.row.dlvyCfmStatus === '0' ? true : false}
                            onClick={(() => {
                                setPoLineNumber(cellParams.row.poLineNo);
                                setReportDownloadForDlvCfmDateAuditPopUp(true);
                            })}>
                            <GetApp />
                        </IconButton>

                    </Tooltip>

                </div>
            )
        },
        {
            field: columnHeaderNames.poLineNo,
            headerName: <FormattedMessage id="columnname.polineno.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.itemCode,
            headerName: <FormattedMessage id="columnname.lineItemCode.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 160,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.itemShortDesc,
            headerName: <FormattedMessage id="columnname.itemShortDesc.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.itemDesc,
            headerName: <FormattedMessage id="columnname.itemDesc.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 140,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.uom,
            headerName: <FormattedMessage id="columnname.uom.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 130,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.wbsCode,
            headerName: intl.formatMessage({ id: "columnname.wbscode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 130,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.unitPrice,
            headerName: intl.formatMessage({ id: "columnname.unitprice.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.taxType1,
            headerName: intl.formatMessage({ id: "columnname.taxType1.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.taxRate1,
            headerName: intl.formatMessage({ id: "columnname.taxRate1.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.taxAmount1,
            headerName: intl.formatMessage({ id: "columnname.taxAmt1.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 165,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.taxType2,
            headerName: intl.formatMessage({ id: "columnname.taxType2.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.taxRate2,
            headerName: intl.formatMessage({ id: "columnname.taxRate2.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.taxAmount2,
            headerName: intl.formatMessage({ id: "columnname.taxAmt3.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 165,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.totalAmount,
            headerName: <FormattedMessage id="columnname.totalamount.label" defaultMessage={columnHeaderNames.totalAmount} />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.lastDespatchQty,
            headerName: <FormattedMessage id="columnname.lastdespatchqty.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'number',
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.lastDespatchNo,
            headerName: <FormattedMessage id="columnname.lastdespatchno.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'number',
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.lastDespatchNoDt,
            headerName: <FormattedMessage id="columnname.lastdespatchdt.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'date',
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.lastDespatchUom,
            headerName: <FormattedMessage id="columnname.lastdespatchuom.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'date',
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.totalQtyReceived,
            headerName: <FormattedMessage id="columnname.totalqntyreceived.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 180,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.totalQtyReceivedStartDt,
            headerName: <FormattedMessage id="columnname.totalqtyrecieveddate.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 270,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'dlvyReqQty',
            headerName: <FormattedMessage id="columnname.poQty.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.salesOrderNUmber,
            headerName: <FormattedMessage id="columnname.salesOrderNo.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 270,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.salesOrderLineNumber,
            headerName: <FormattedMessage id="columnname.salesOrderLineNo.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 270,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.placeOfDischarge,
            headerName: <FormattedMessage id="columnname.placeOfDischarge.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 270,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.placeOfDelivery,
            headerName: <FormattedMessage id="columnname.placeOfDlv.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 270,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.supplierInvoice,
            headerName: <FormattedMessage id="columnname.supplierInvoice.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'string',
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
    ];

    const columnDeliveryCfmStatus = {
        field: 'dlvyCfmStatus',
        headerName: <FormattedMessage id="columnname.delCfmStatus.label" />,
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        width: 180,
        sortable: false,
        type: 'string',
        hide: false,
        renderHeader: CommonService.customRenderHeaderColumns,
        renderCell: CommonService.PoLineDlvyCnfDisplayField
    };

    const columnForStatusFlag = {
        field: columnHeaderNames.msgStatusFlag,
        width: 180,
        headerName: intl.formatMessage({ id: "columnName.msgSentStatus.label" }),
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        sortable: true,
        type: 'string',
        hide: false,
        filterable: false,
        renderHeader: CommonService.customRenderHeaderColumns,
        renderCell: CommonService.PoStatusForOutChips
    };


    const columnForStatusRemarks = {
        field: columnHeaderNames.msgStatusRemarks,
        width: 245,
        headerName: intl.formatMessage({ id: "columnName.msgSentStatusRemarks.label" }),
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        sortable: true,
        type: 'string',
        hide: true,
        filterable: false,
        renderHeader: CommonService.customRenderHeaderColumns,
    };

    const columnForPoLineStatus = {
        field: columnHeaderNames.poLineStatus,
        headerName: intl.formatMessage({ id: "columnname.poLineStatus.label" }),
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        width: 190,
        sortable: true,
        type: 'string',
        hide: false,
        filterable: false,
        renderHeader: CommonService.customRenderHeaderColumns,
        renderCell: ((cellParams) => {
            if ((cellParams.row.poLineStatus !== undefined && cellParams.row.poLineStatus !== '0') && ((cellParams.row.poLineDeleted !== undefined &&
                cellParams.row.poLineDeleted === null) || (cellParams.row.poLineDeleted !== undefined && cellParams.row.poLineDeleted === ''))) {
                return <Typography variant='body2' noWrap={true} className={classes.boldHeaderWithBorderForAmended}>
                    AMENDED
                </Typography>
            } else if (cellParams.row.poLineDeleted !== undefined && cellParams.row.poLineDeleted === 'L') {
                return <Typography variant='body2' noWrap={true} className={classes.boldHeaderWithBorderForCancelled}>
                    CANCELLED
                </Typography>
            } else if ((cellParams.row.poLineStatus !== undefined && cellParams.row.poLineStatus === '0') && ((cellParams.row.poLineDeleted !== undefined && cellParams.row.poLineDeleted === null) || (cellParams.row.poLineDeleted !== undefined && cellParams.row.poLineDeleted === ''))) {
                return <Typography variant='body2' noWrap={true} className={classes.boldHeaderWithBorderForOriginal}>
                    ORIGINAL
                    </Typography>
            }
        })
    };

    const columnForMessageSentTime = {
        field: columnHeaderNames.lastMessageSentTime,
        headerName: intl.formatMessage({ id: "columnname.lastMessageSentTime.label" }),
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        width: 190,
        sortable: true,
        type: 'string',
        hide: false,
        filterable: false,
        renderHeader: CommonService.customRenderHeaderColumns
    };

    
    const columnForPurchaseOrderAckStatus = {
        field: columnHeaderNames.ackStatus,
        width: 180,
        headerName: intl.formatMessage({ id: "columnname.ackStatus.label" }),
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        sortable: true,
        type: 'string',
        hide: false,
        filterable: false,
        renderHeader: CommonService.customRenderHeaderColumns,
        renderCell: CommonService.AckStatusDisplayField
    };

    const insert = (arr, index, newItems) => [
        ...arr.slice(0, index),
        newItems,
        ...arr.slice(index)
    ]

    function constructColumnsToBeDisplayed() {
        var commonColumnWithPOPFEdiAndBp = [];
        const commonColumnForDlvCfmSttaus = insert(commonColumnsForPOPfTableView, 5, columnDeliveryCfmStatus)
        commonColumnWithPOPFEdiAndBp = insert(commonColumnForDlvCfmSttaus, 6, columnForPoLineStatus)
        return commonColumnWithPOPFEdiAndBp;
    }

    function constructColumnsToBeDisplayedForEdi() {
        var commonColumnWithPOPFEdiAndBp = [];
        const commonColumnDeliveryCfmStatus = insert(commonColumnsForPOPfTableView, 5, columnDeliveryCfmStatus)
        const commonColumnForPoLinestatus = insert(commonColumnDeliveryCfmStatus, 6, columnForPoLineStatus)
        const commonColumnWithPoPfEdiStatusFlag = insert(commonColumnForPoLinestatus, 7, columnForStatusFlag)
        const commonColumnWithPoPfEdiStatusRemarks = insert(commonColumnWithPoPfEdiStatusFlag, 8, columnForStatusRemarks)
        const commonColumnWithAckStatus = insert(commonColumnWithPoPfEdiStatusRemarks, 10, columnForPurchaseOrderAckStatus)
        commonColumnWithPOPFEdiAndBp = insert(commonColumnWithAckStatus, 9, columnForMessageSentTime)
        return commonColumnWithPOPFEdiAndBp;
    }

    useEffect(() => {
        if (!isBusinessPartnerPortal) {
            setColumns(constructColumnsToBeDisplayedForEdi());
        } else {
            setColumns(constructColumnsToBeDisplayed());
        }
    }, [selectedLanguage, tableData]);

    const handleToggleChange = (event, newAlignment) => {
        setPoDetailItemViewType(newAlignment);
    };

    const breadcrumbForEdit = {
        path: undefined,
        name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage='Edit' />,
        iconName: EditIcon,
    };

    const breadcrumbForView = {
        path: undefined,
        name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage='View' />,
        iconName: ViewIcon,
    }

    const breadCrumbForOrdersElement =
    {
        path: '/orders',
        name: <FormattedMessage id="order.breadcrumb.text" defaultMessage='Orders' />,
        iconName: TabIcon,
    };

    const breadCrumbForMessageElement =
    {
        path: undefined,
        name: <FormattedMessage id="common.menu.breadcrumb.message" defaultMessage='Purchase Orders' />,
        iconName: IconMessage,
    };
    const breadCrumbForPurchaseOrderForecastElement =
    {
        path: '/purchaseOrdersOutbound',
        name: <FormattedMessage id="common.module.breadcrumb.purchaseorderpurchaseforecastoutbound" defaultMessage='Purchase Order Outbound' />,
        iconName: IconPurchaseOrderPurchaseForecastOutbound,
    };

    function getBaseBreadCrumbBasedOnPoForecastEDI() {
        let constructedBaseBreadCrumb = [];
        if (mode === 'view') {
            constructedBaseBreadCrumb = [breadCrumbForMessageElement, breadCrumbForPurchaseOrderForecastElement, breadcrumbForView]
        } if (mode === 'edit') {
            constructedBaseBreadCrumb = [breadCrumbForMessageElement, breadCrumbForPurchaseOrderForecastElement, breadcrumbForEdit]
        }
        return constructedBaseBreadCrumb;
    }

    function getBaseBreadCrumbBasedOnPoForecastForBusinessPortal() {
        let constructedBaseBreadCrumb = [];
        if (mode === 'edit') {
            constructedBaseBreadCrumb = [breadCrumbForOrdersElement, breadcrumbForEdit]
        } else {
            constructedBaseBreadCrumb = [breadCrumbForOrdersElement, breadcrumbForView]
        }
        return constructedBaseBreadCrumb;
    }

    const [componentList, setComponentList] = useState((() => {
        if (!isBusinessPartnerPortal) {
            return getBaseBreadCrumbBasedOnPoForecastEDI();
        } else if (isBusinessPartnerPortal) {
            return getBaseBreadCrumbBasedOnPoForecastForBusinessPortal();
        }
    }));

    useEffect(() => {
        isRefreshButtonDisabled(false);
        setComponentList(componentList)
    }, [mode, msgId, refreshFlag]);

    useEffect(() => {
        setOpenBackDrop(true);
        CommonService.getByIdApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.poPfOutbound, msgId)
            .then((response) => {
                setPoOrPfToBeEdited(response.data);
                setOpenBackDrop(false);
            })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
                setOpenBackDrop(false);
            });
    }, [refreshFlag, msgId]);

    const handleChangePage = (event, newPage) => {
        setTotalPageCount(newPage);
        setParamBody(prevState => { return { ...prevState, pageNo: newPage } })
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPageForList(parseInt(event.target.value, 10));
        setParamBody(prevState => { return { ...prevState, recordsPerPage: rowsPerPageForList, pageNo: totalPageCount } })
        setTotalPageCount(0);
    };

    useEffect(() => {
        let tempData = tableData;
        setTableLoadingStatus(true);
        setTableData([]);
        setOpenBackDrop(true);
        CommonService.GetListApi(`/poOutboundMsgHdrs/${msgId}/PoOutboundMsgDtls`, paramBody)
            .then((response) => {
                setTableLoadingStatus(false);
                setTotalRowCount(response.data.totalElements);
                setTableData(response.data.content)
                setOpenBackDrop(false);
            })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setOpenBackDrop(false);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [mode, paramBody, refreshFlag])

    const colorsList = ['#ffd600', '#64dd17', '#d50000'];

    const pieData = {
        maintainAspectRatio: false,
        responsive: false,
        datasets: [
            {
                data: [poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.totalCfmQty : 0,
                poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.totalReceivedQty : 0,
                poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.totalPoQty - poOrPfToBeEdited.totalReceivedQty : 0],
                backgroundColor: colorsList,
                hoverBackgroundColor: colorsList
            }
        ],
        labels: [intl.formatMessage({ id: "cnfqty.piedata" }), intl.formatMessage({ id: "delqty.piedata" }), intl.formatMessage({ id: "balqty.piedata" })],
    };
    const pieOptions = {
        maintainAspectRatio: false,
        responsive: false,
        legend: {
            labels: {
                fontColor: 'black',
                fontStyle: "bold",
                fontSize: 10,
            },
            position: 'bottom',
        }
    };

    const onSubmitSearch = data => {
        setParamBody({
            ...paramBody, body: {
                itemDesc: data.itemDesc,
                dlvyCfmStatus: data.dlvyCfmStatus.value,
                // poLineDeliveryConfirmed: ispurchaseforecast ? null : data.poLineDeliveryConfirmed !== null ? data.poLineDeliveryConfirmed.value : null,
                // recordPoDlvLineCfm: ispurchaseforecast ? null : data.poLineDeliveryConfirmed !== null ? data.poLineDeliveryConfirmed.value : 'All',
                recordAmendmentCount: 'All',
                recordStatusFlag: 'All',
                fromUI: true
            }
        });
        setSearchedMaterialDesc(data.itemDesc);
        // setSearchedDelCfmStatus(data.poLineDeliveryConfirmed !== null ? data.poLineDeliveryConfirmed.value : null);
        setSearchedDelCfmStatus(data.dlvyCfmStatus.value);
    };

    const onSubmitApproval = (data) => {
        const messagesId = [msgId];
        setOpenBackDrop(true);
        CommonService.saveOrUpdateOrPost('/poOutboundApprovals', {
            approved: 1,
            msgIds: messagesId
        })
            .then((response) => {
                setAlertBoxState({
                    ...alertBoxState, openAlert: true, message: `MessageId : ${messagesId} has been approved successfully`, severity: "success"
                });
                setDisableApproveButtton(true);
                history.push(`/purchaseOrdersOutbound`)
            })
            .catch(function (error) {
                setOpenBackDrop(false);
                setDisableApproveButtton(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.message, severity: "error" });
            });
    }

    const onSubmitReject = (data) => {
        const messagesId = [msgId];
        setOpenBackDrop(true);
        CommonService.saveOrUpdateOrPost('/poOutboundApprovals', {
            approved: 2,
            msgIds: messagesId
        })
            .then((response) => {
                setAlertBoxState({
                    ...alertBoxState, openAlert: true, message: `MessageId : ${messagesId} has been rejected successfully`, severity: "success"
                });
                setOpenBackDrop(false);
                setDisableRejectButton(true);
                history.push(`/purchaseOrdersOutbound`)
            })
            .catch(function (error) {
                setOpenBackDrop(false);
                setDisableRejectButton(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.message, severity: "error" });
            });
    }

    useEffect(() => {
        if (msgDtlsPopUp === true) {
            setOpenBackDrop(true);
            let msgId = { msgId: poOrPfToBeEdited.msgId.toString() };
            let itemDesc = { msgId: poOrPfToBeEdited.msgId.toString(), itemDesc: searchedMaterialDesc }
            // let poLineDeliveryConfirmed = { msgId: poOrPfToBeEdited.msgId.toString(), poLineDeliveryConfirmed: searchedDelCfmStatus === null ? '' : searchedDelCfmStatus }
            let dlvyCfmStatus = { msgId: poOrPfToBeEdited.msgId.toString(), dlvyCfmStatus: searchedDelCfmStatus === null ? '' : searchedDelCfmStatus }
            let AllFilter = { msgId: poOrPfToBeEdited.msgId.toString(), dlvyCfmStatus: searchedDelCfmStatus === null ? '' : searchedDelCfmStatus, itemDesc: searchedMaterialDesc }
            CommonService.saveOrUpdateOrPost('/reports/SYS_PO_OUTBOUND_DTL', (
                (searchedMaterialDesc === '' && searchedDelCfmStatus !== null) ?
                    dlvyCfmStatus : (searchedMaterialDesc !== '' && searchedDelCfmStatus === null) ? itemDesc :
                        (searchedMaterialDesc !== '' && searchedDelCfmStatus !== null) ? AllFilter : searchedMaterialDesc === '' && searchedDelCfmStatus === null ? msgId : ''))
                .then((response) => {
                    if (response.data.sucess === "No records found for given condition") {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.sucess, severity: "error" });
                    } else {
                        // showDownloadPopUpExcelDtls(response.data.fileUrl)
                        setAlertBoxState({
                            ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success"
                        });
                    }
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgDtlsPopUp(false)
        }
    }, [msgId, msgDtlsPopUp])

    const showDownloadPopUpExcelDtls = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }

    useEffect(() => {
        if (reportDownloadForDlvCfmDateAuditPopUp === true) {
            setOpenBackDrop(true);
            CommonService.saveOrUpdateOrPost('/reports/SYS_PO_OUTBOUND_DLV_CFM_LATEST_AUDIT',
                {
                    msgId: msgId.toString(),
                    poLineNo: poLineNumber
                }
            )
                .then((response) => {
                    //showDownloadPopUpForDlvCfmDateAudit(response.data.fileUrl)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success"
                    });
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setReportDownloadForDlvCfmDateAuditPopUp(false)
        }
    }, [reportDownloadForDlvCfmDateAuditPopUp]);

    const showDownloadPopUpForDlvCfmDateAudit = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }

    useEffect(() => {
        if (msgDtlsPopUpForPf === true) {
            setOpenBackDrop(true);
            let msgId = { msgId: poOrPfToBeEdited.msgId.toString() };
            let itemDesc = { msgId: poOrPfToBeEdited.msgId.toString(), itemDesc: searchedMaterialDesc }
            CommonService.saveOrUpdateOrPost('/reports/SYS_PURCHASE_FORECAST_OUTBOUND_DTL', (
                (searchedMaterialDesc === '') ? itemDesc : (searchedMaterialDesc !== '') ? msgId : ''))
                .then((response) => {
                    if (response.data.sucess === "No records found for given condition") {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.sucess, severity: "error" });
                    } else {
                        // showDownloadPopUpExcelDtlsForPF(response.data.fileUrl)
                        setAlertBoxState({
                            ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success"
                        });
                    }
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgDtlsPopUpForPf(false)
        }
    }, [msgId, msgDtlsPopUpForPf])

    const showDownloadPopUpExcelDtlsForPF = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }


    useEffect(() => {
        if (poOrPfToBeEdited && poOrPfToBeEdited.poNo === undefined) {
            return poOrPfToBeEdited.poNo = 'Loading..'
        }
    }, [poOrPfToBeEdited])

    useEffect(() => {
        if (poOrPfToBeEdited && poOrPfToBeEdited.poDateDisp === undefined) {
            return poOrPfToBeEdited.poDate = 'Loading..'
        }
    }, [poOrPfToBeEdited])

    function clearPoDtlSearchItem() {
        reset({
            itemDesc: '',
            poLineDeliveryConfirmed: null,
            dlvyCfmStatus: '',
            recordPoDlvLineCfm: 'All',
            recordAmendmentCount: 'All',
            recordStatusFlag: 'All',
            fromUI: true
        });
        setParamBody({
            ...paramBody,
            body: {
                itemDesc: '',
                poLineDeliveryConfirmed: null,
                dlvyCfmStatus: '',
                recordPoDlvLineCfm: 'All',
                recordAmendmentCount: 'All',
                recordStatusFlag: 'All',
                fromUI: true
            }
        });
        setDeliveryCfmStatus([]);
        setSearchedMaterialDesc('');
        setSearchedDelCfmStatus(null);
    }

    const deliveryConfirmationStatusComboProperty = {
        name: pageLabelsConstantsAndMessages.name.dlvyCfmStatus,
        isRequired: false,
        label: intl.formatMessage({ id: "columnname.delCfmStatus.label" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'outlined',
        isHookFormType: true
    }

    const closePOConfirmDateDialog = (confirmed) => {
        if (confirmed) {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="success.state.text" defaultMessage='Record updated successfully' />, severity: "success" });
            setOpenPOConfirmDateDialog(false);
            setRefreshFlag(!refreshFlag);
        } else {
            setOpenPOConfirmDateDialog(false);
            setRefreshFlag(!refreshFlag);
        }
    }

    const closePoConfirmationAudit = (confirmed) => {
        setOpenPoCfmAuditDialog(false);
        setPOIteDetailsObject(undefined);
    }

    const closePoPftAudit = (confirmed) => {
        setOpenPoPftAuditDialog(false);
        setPOIteDetailsObject(undefined);
    }

    const closePOPFDataSourceDialog = (confirmed) => {
        setOpenDataSourceDialog(false);
    }

    useEffect(() => {
        if (poOrPfToBeEdited != undefined && (poOrPfToBeEdited.approved === 1 || poOrPfToBeEdited.approved === 2)) {
            setDisableRejectButton(true);
            setDisableApproveButtton(true);
        } else if (poOrPfToBeEdited != undefined && poOrPfToBeEdited.approved !== 1) {
            setDisableRejectButton(false);
            setDisableApproveButtton(false);
        }
    }, [poOrPfToBeEdited])

    useEffect(() => {
        if (totalRowCount === 0) {
            setDisableDownloadButton(true)
        } else {
            setDisableDownloadButton(false)
        }
    }, [totalRowCount])

    const menuOptionsForExportButton = [
        {
            label: intl.formatMessage({ id: "uploaddlvschedule.button" }),
            tooltipTitle: intl.formatMessage({ id: "exportbutton.Tooltip" }),
            url: '/reports/poConfirmationDate',
            successMessage: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />,
            parameters: {
                poOutboundMsgHdr: {
                    msgId: poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.msgId.toString() : '',
                    poNo: poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.poNo.toString() : '',
                },
                dlvyCfmStatus: searchedDelCfmStatus === null ? '' : searchedDelCfmStatus === "0" ? 'NOT-CONFIRMED' : searchedDelCfmStatus === "1" ? 'CONFIRMED' : '',
                itemDesc: searchedMaterialDesc,
                recordPoDlvLineCfm: searchedDelCfmStatus === null ? 'All' : searchedDelCfmStatus === "0" ? 'NOT-CONFIRMED' : searchedDelCfmStatus === "1" ? 'CONFIRMED' : 'All',
                recordAmendmentCount: 'All',
                displayBpCode: userAccessBpCode,
                displayBpName: userAccessBpName,
                recordApprovedStatus: 'All',
                recordCancellationCount: 'All',
                recordStatusFlag: 'All'
            }
        },
        {
            label: intl.formatMessage({ id: "expcfmadthistory.button" }),
            tooltipTitle: intl.formatMessage({ id: "expcfmadthistory.tooltip" }),
            url: '/reports/DLV_SCHEDULE_HISTORY_REPORT',
            //successMessage: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`,reportName:`${response.data.reportName}` }} />,
            parameters: {
                poScheduleType: "1",
                msgId: poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.msgId.toString() : '',
            }
        }
    ]

    const requestXLReportExport = (selectedExportReportTypeObj) => {
        setExportXlRequested(true);
        setOpenBackDrop(true);
        if (selectedExportReportTypeObj.label === intl.formatMessage({ id: "uploaddlvschedule.button" })) {
            CommonService.saveOrUpdateOrPost(selectedExportReportTypeObj.url, selectedExportReportTypeObj.parameters)
                .then((response) => {
                    setExportXlRequested(false);
                    setOpenBackDrop(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success" });
                    // showDownloadPopUp(response.data.fileUrl);
                })
                .catch(function (error) {
                    setExportXlRequested(false);
                    setOpenBackDrop(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                });
        } else {
            CommonService.saveOrUpdateOrPost(selectedExportReportTypeObj.url, selectedExportReportTypeObj.parameters)
                .then((response) => {
                    setExportXlRequested(false);
                    setOpenBackDrop(false);
                    if (response.data.sucess === "No records found for given condition") {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.sucess, severity: "error" });
                    } else {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success" });
                        // showDownloadPopUp(response.data.fileUrl);
                    }
                })
                .catch(function (error) {
                    setExportXlRequested(false);
                    setOpenBackDrop(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                });
        }
    }

    const showDownloadPopUp = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage='File downloaded successfully' />, severity: "success" });
                saveAs(blob, fileName);
                setExportXlRequested(false);
            })
            .catch(error => {
                setExportXlRequested(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage='Failed to download the file!' />, severity: "error" });
            })

    }

    const closeFileUploadDialog = (confirmed) => {
        setOpenFileUploadDialog(false);
    }

    function requestPoDlvyCfmDtAuditHistory(poItemDetailsObject) {
        setExportXlRequested(true);
        setOpenBackDrop(true);
        CommonService.saveOrUpdateOrPost('/reports/DLV_SCHEDULE_HISTORY_REPORT', {
            poScheduleType: "1",
            msgId: poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.msgId.toString() : '',
        })
            .then((response) => {
                setExportXlRequested(false);
                setOpenBackDrop(false);
                if (response.data.sucess === "No records found for given condition") {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.sucess, severity: "error" });
                } else {
                    // showDownloadPopUpForUploadSchedule(response.data.fileUrl)
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success" });
                }
            })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setExportXlRequested(false);
                    setOpenBackDrop(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }

    const showDownloadPopUpForUploadSchedule = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }


    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <FileUploadDialog open={openFileUploadDialog} onClose={closeFileUploadDialog} isBusinessPartnerPortal={isBusinessPartnerPortal} defaultEntity={defaultEntity} defaultBpType={"SUPPLIER"} />
            <Grid container className={classes.rootForEditPurchaseOrder}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={6} md={12} lg={8} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' alignItems='center' xs={12} sm={6} md={12} lg={4} className={classes.topGrid}>
                    <Button size='small' variant="contained" color="primary" className={classes.stateButton} elevation={2}
                        endIcon={smallScreen ? null : <IconBack />} onClick={history.goBack}>
                        {smallScreen ? <IconBack /> : <FormattedMessage id="common.button.back.lable" />}
                    </Button>

                    <Button size='small' disabled={disableRefreshButton} variant="contained" color="secondary" className={classes.stateButtonReferesh} elevation={2}
                        endIcon={smallScreen ? null : <IconRestore />} onClick={(() => { setRefreshFlag(!refreshFlag) })}>
                        {smallScreen ? <IconRestore /> : <FormattedMessage id="common.button.refresh.tooltip" />}
                    </Button>
                    {(poPfOutboundModuleAccess !== undefined ? poPfOutboundModuleAccess.editFlag || poPfOutboundModuleAccess.createFlag : false) && (!isBusinessPartnerPortal) && (
                        <Button size='small'
                            disabled={disableApproveButton}
                            variant="contained"
                            color="primary"
                            endIcon={smallScreen ? null : <CheckCircleOutlineOutlinedIcon />}
                            className={classes.stateButtonForApprove} elevation={2}
                            onClick={onSubmitApproval}
                        >{smallScreen ? <CheckCircleOutlineOutlinedIcon /> : <FormattedMessage id="common.button.approv.tooltip" />}</Button>
                    )}
                    {(poPfOutboundModuleAccess !== undefined ? poPfOutboundModuleAccess.editFlag || poPfOutboundModuleAccess.createFlag : false) && (!isBusinessPartnerPortal) && (
                        <Button size='small'
                            disabled={disableRejectButton}
                            variant="contained"
                            color="primary"
                            endIcon={smallScreen ? null : <CancelOutlinedIcon />}
                            className={classes.stateButtonForReject} elevation={2}
                            onClick={onSubmitReject}
                        >{smallScreen ? <CancelOutlinedIcon /> : <FormattedMessage id="common.button.reject.tooltip" />}</Button>
                    )}
                </Grid>
                <Paper className={classes.paperForEditPurchaseOrder} elevation={3}>
                    <form className={classes.formForPurchaseOrder}>
                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={1}>
                                    <Chip size='small' variant='outlined' className={classes.chipTheme} color='primary'
                                        label={<FormattedMessage id="poNo.chip.text" values={{ poToBeEdited: `${(poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.poNo : '')}` }} />}
                                    />
                                    <Chip size='small' variant='outlined' className={classes.chipForPO} color='secondary'
                                        label={<FormattedMessage id="agreementNo.chip.text" values={{ poToBeEdited: `${(poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.schedulingAgreementRelNo === null ? '' : poOrPfToBeEdited.schedulingAgreementRelNo : '')}` }} />}
                                    />
                                    <Chip size='small' variant='outlined' className={classes.chipTheme} color='primary'
                                        label={<FormattedMessage id="poDate.chip.text" values={{ poToBeEdited: `${(poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.poDateDisp : '')}` }} />}
                                    />
                                    <Chip size='small' variant='outlined' className={classes.chipForPO} color='secondary'
                                        label={<FormattedMessage id="poAmount.chip.text" values={{ poToBeEdited: `${(poOrPfToBeEdited !== undefined ? (Math.floor(poOrPfToBeEdited.totalAmount) + ' ' + poOrPfToBeEdited.currencyCode) : '')}` }} />}
                                    />
                                    <Chip size='small' variant='outlined' className={classes.chipTheme} color='primary'
                                        label={<FormattedMessage id="noLineItem.chip.text" values={{
                                            poToBeEdited: `${(poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.totalCountOfPoLine : '')}`
                                        }} />}
                                    />
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                                    {(<Grid item container xs={12} sm={12} md={4} lg={4}>
                                        <Grid item container justifyContent='center' alignItems='center'>
                                            <Typography variant='h6'><strong><FormattedMessage id="summary.label" defaultMessage=" Summary" /></strong></Typography>
                                        </Grid>
                                        <Grid item container justifyContent='center' alignItems='center'>
                                            <Paper>
                                                <div>
                                                    <Doughnut options={pieOptions} data={pieData} />
                                                </div>
                                                <Grid item container justifyContent='center' alignItems='center'>
                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}><FormattedMessage id="totalqty.piechart" values={{ poToBeEditedtotalPoQty: poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.totalPoQty : 0 }} /></Typography>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>)}
                                    <Grid item container justifyContent='center' alignItems='baseline' xs={12} sm={12} md={4} lg={4} className={clsx(classes.helper)}>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <Typography variant='h6'><strong><FormattedMessage id="buyerdetails.label" defaultMessage="Buyer Details" /></strong></Typography>
                                        </Grid>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <div>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="name.accordian.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.buyerName : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="columnname.ordernumber.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.poNo : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="columnname.orderDt.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.poDateDisp : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        {<tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="columnname.columnnamecreatedby.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.createdBy : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent='center' alignItems='baseline' xs={12} sm={12} md={4} lg={4} className={classes.helper}>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <Typography variant='h6'><strong>{isBusinessPartnerPortal ? <FormattedMessage id="vendordetails" /> : <FormattedMessage id="supplierdetails" />}</strong></Typography>
                                        </Grid>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <div>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="name.accordian.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.supplierName : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="richtexteditor.blocktype.code.text" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(poOrPfToBeEdited !== undefined ? poOrPfToBeEdited.bpCode : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </form>
                </Paper>
                <Paper className={classes.paperForEditPurchaseOrder} elevation={3}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{ padding: '5px 10px' }}>
                        <Grid item container justifyContent="flex-start" alignItems="center" xs={6} sm={6} md={6} lg={6}>
                            <Typography component="span" variant="h5" color="textPrimary">
                                <FormattedMessage id="itemdetails.label" />
                            </Typography>
                        </Grid>
                        <Grid item container justifyContent="flex-end" alignItems="center" xs={6} sm={6} md={6} lg={6}>
                            <ToggleButtonGroup size="small" value={poDetailItemViewType} exclusive onChange={handleToggleChange}>
                                <StyledToggleButton value="list">
                                    <Tooltip title={<FormattedMessage id="listview.tooltip" defaultMessage='List View' />} placement='bottom'>
                                        <ListIcon fontSize="small" />
                                    </Tooltip>
                                </StyledToggleButton>
                                <StyledToggleButton value="table">
                                    <Tooltip title={<FormattedMessage id="tableview.tooltip" defaultMessage='tableview.tooltip' />} placement='bottom'>
                                        <TableChartIcon fontSize="small" />
                                    </Tooltip>
                                </StyledToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    <form className={classes.formForPurchaseOrder}>
                        <Grid item container justifyContent="flex-start" alignItems="center" xs={12} sm={12} md={12} lg={12} spacing={1} style={{ padding: '5px 10px' }}>
                            <Grid item container xs={12} sm={3} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.itemDesc}
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="itemDesc"
                                                size='small'
                                                label={<FormattedMessage id="columnname.materialdesctable.label" defaultMessage='Material Desc' />}
                                                placeholder={intl.formatMessage({ id: "materialdisc.placeholder.label" })}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            {(
                                <Grid item container xs={12} sm={3} md={3} lg={3}>
                                    <DeliveryConfirmationStatusCombo comboProperty={deliveryConfirmationStatusComboProperty} control={control} errors={errors} comboValue={deliveryCfmStatus} setComboValue={setDeliveryCfmStatus} />
                                </Grid>)}
                            <Grid item container justifyContent="flex-start" alignItems="center" xs={12} sm={6} md={6} lg={6}>
                                <Tooltip title={<FormattedMessage id="common.button.clear.tooltip" />} placement='bottom'>
                                    <span>
                                        <Button size='small'
                                            variant="contained" color="inherit" className={classes.editPoToobarStyle} elevation={2}
                                            onClick={(() => { clearPoDtlSearchItem() })}
                                        >
                                            <ClearIcon />
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip title={<FormattedMessage id="common.button.search.tooltip" />} placement='bottom'>
                                    <span>
                                        <Button size='small'
                                            variant="contained" color="primary" className={classes.editPoToobarStyle} elevation={2}
                                            onClick={handleSubmit(onSubmitSearch)}
                                        >
                                            <SearchIcon />
                                        </Button>
                                    </span>
                                </Tooltip>
                                {(poPfOutboundModuleAccess !== undefined ? poPfOutboundModuleAccess.editFlag || poPfOutboundModuleAccess.createFlag : false) && (!isBusinessPartnerPortal) && (
                                    <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadPoDtls" />} placement='bottom'>
                                        <span>
                                            <Button size='small'
                                                disabled={disableDownloadButton}
                                                variant="contained"
                                                color="primary"
                                                className={classes.editPoToobarStyle} elevation={2}
                                                onClick={() => { setMsgDtlsPopUp(true) }}
                                            >
                                                <GetAppIcon />
                                            </Button>
                                        </span>
                                    </Tooltip>
                                )}
                                {(mode === 'edit') && (
                                    <Tooltip title={menuOptionsForExportButton[selectedIndex].tooltipTitle} placement='bottom'>
                                        <span>
                                            <Button size='small'
                                                variant="contained"
                                                color="primary" className={classes.stateButton}
                                                ref={anchorRef}
                                                disabled={tableData.length <= 0 || isExportXlRequested}
                                                endIcon={isExportXlRequested ? <CircularProgress color="secondary" size={20} /> : null}
                                                onClick={handleToggle}
                                            >
                                                <ExportIcon />
                                                <Typography style={{ margin: '0 5px 0 5px', fontWeight: 500, fontSize: '13px' }} ><FormattedMessage id="export.defaulttext.button" defaultMessage="EXPORT" /></Typography>
                                                <ArrowDropDownIcon />
                                            </Button>
                                        </span>
                                    </Tooltip>)}
                                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition >
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper className={classes.splitButton} elevation={10}>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList>
                                                        {menuOptionsForExportButton.map((option, index) => (
                                                            <Tooltip title={menuOptionsForExportButton[index].tooltipTitle} placement="right-start">
                                                                <span>
                                                                    <MenuItem
                                                                        divider
                                                                        className={classes.menuListStyle}
                                                                        key={index}
                                                                        selected={index === selectedIndex}
                                                                        onClick={(event) => handleMenuItemClick(event, index)}
                                                                    >
                                                                        {option.label}
                                                                    </MenuItem>
                                                                </span>
                                                            </Tooltip>
                                                        ))}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>

                                {(mode === 'edit') && (
                                    <Tooltip title={<FormattedMessage id="importdlvschedulebutton" />} placement='bottom'>
                                        <span>
                                            <Button size='small'
                                                className={classes.editPoToobarStyle}
                                                variant="contained"
                                                disabled={tableData.length <= 0}
                                                color="primary" component="span"
                                                onClick={(() => {
                                                    setOpenFileUploadDialog(true);

                                                })}
                                            >
                                                <ImportIcon />
                                            </Button>
                                        </span>
                                    </Tooltip>)}
                                {/* {isBusinessPartnerPortal && (
                                    <Tooltip title={<FormattedMessage id="exppftadthistory.tooltip" />} placement='bottom'>
                                        <span>
                                            <Button size='small'
                                                className={classes.editPoToobarStyle}
                                                variant="contained"
                                                color="primary" component="span"
                                                disabled={tableData.length <= 0 || isExportXlRequested}
                                                onClick={(() => {
                                                    requestPoDlvyCfmDtAuditHistory(undefined);
                                                })}
                                                endIcon={isExportXlRequested ? <CircularProgress color="secondary" size={20} /> : null}
                                            >
                                                <ExportIcon />
                                            </Button>
                                        </span>
                                    </Tooltip>)} */}
                                {(
                                    <Tooltip title={<FormattedMessage id="expcfmadthistory.tooltip" />}
                                        placement='bottom' >
                                        <span>
                                            <Button color='secondary' size="small" variant="contained" className={classes.editPoToobarStyle} onClick={(() => {
                                                setOpenPoCfmAuditDialog(true);
                                                setPoLineNumber('');
                                            })}
                                                color="primary" disabled={tableData.length === 0 ? true : false}>
                                                <HistoryIcon />
                                            </Button>
                                        </span>
                                    </Tooltip>)}
                                {/* {(
                                    <Tooltip title={<FormattedMessage id="exppftadthistory.tooltip" />}
                                        placement='bottom' >
                                        <span>
                                            <Button color='primary' size="small" variant="contained" className={classes.editPoToobarStyle} onClick={(() => {
                                                setOpenPoPftAuditDialog(true);
                                                setPoLineNumber('');
                                            })} disabled={tableData.length === 0 ? true : false}>
                                                <PFAuditIcon />
                                            </Button>
                                        </span>
                                    </Tooltip>)} */}
                                {(
                                    <Tooltip title={<FormattedMessage id="exppoadthistory.tooltip" />}
                                        placement='bottom' >
                                        <span>
                                            <Button color='primary' size="small" variant="contained" className={classes.editPoToobarStyle} onClick={(() => {
                                                setOpenPoPftAuditDialog(true);
                                                setPoLineNumber('');
                                            })} disabled={tableData.length === 0 ? true : false}>
                                                <PFAuditIcon />
                                            </Button>
                                        </span>
                                    </Tooltip>)}
                                {(poPfOutboundModuleAccess !== undefined ? poPfOutboundModuleAccess.readFlag : false) && (!isBusinessPartnerPortal) && (
                                    <Tooltip title={<FormattedMessage id="datasourcehistory.tooltip" />}
                                        placement='bottom' >
                                        <span>
                                            <Button color='primary' size="small" variant="contained" className={classes.editPoToobarStyle} onClick={(() => {
                                                setOpenDataSourceDialog(true);
                                            })}
                                                disabled={tableData.length === 0 ? true : false}>
                                                <DataUsage />
                                            </Button>
                                        </span>
                                    </Tooltip>)}
                            </Grid>

                        </Grid>
                    </form>
                    {(poDetailItemViewType === 'table') && (
                        <Paper elevation={3} className={classes.gridHeightAndWidthForPOOutboundTableView} >
                            <div style={{ width: '100%', height: '100%' }}>

                                <DataGridPro
                                    className={classes.customTableStyle}
                                    pageSize={25}
                                    loading={tableLoadingStatus}
                                    getRowId={(row) => row.msgDtlsId}
                                    rows={tableData}
                                    columns={columns}
                                    rowCount={totalRowCount}
                                    scrollbarSize={30}
                                    disableColumnFilter={true}
                                    rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                                    pagination
                                    density="compact"
                                    paginationMode="server"
                                    hideFooter={true}
                                    disableSelectionOnClick={true}
                                    disableMultipleColumnsSorting={true}
                                    localeText={localtext}
                                    disableColumnMenu={false}
                                    onPageSizeChange={((pageParams) => {
                                        setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                                    })}
                                    onPageChange={((pageParams) => {
                                        setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                                    })}
                                    components={{
                                        Toolbar: () => {
                                            return (
                                                <Grid container direction="row" justifyContent="flex-start" alignItems="center"
                                                    style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                                    <Grid item container direction="row" justifyContent="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" />} placement='bottom'>
                                                                <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.density.tooltip" />}
                                                                placement='bottom'>
                                                                <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                                <span>
                                                                    <Button size="small" color="primary" variant="outlined" className={classes.refreshToobarStyle}
                                                                        onClick={(() => {
                                                                            setRefreshFlag(!refreshFlag);
                                                                        })}>
                                                                        <RefreshIcon />
                                                                    </Button>
                                                                </span>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <CommonGridPagination />
                                                    </Grid>
                                                </Grid>
                                            );
                                        },
                                    }}
                                />
                            </div>
                        </Paper>
                    )}
                    {(poDetailItemViewType === 'list') && (
                        <div id="scrollableDiv">
                            <Divider />
                            <Grid item container xs={12} sm={12} md={12} lg={12} >
                                {(<Grid item container justifyContent='flex-start' xs={12} sm={12} md={6} lg={6} style={{ padding: '5px 10px' }}>
                                    <Grid item container justifyContent='flex-start' alignItems='center' xs={4} sm={4} md={4} lg={4}>
                                        <div className={classes.redBox} />
                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}><FormattedMessage id="notconfirmed.label" defaultMessage="Not Confirmed" /></Typography>
                                    </Grid>
                                    <Grid item container justifyContent='flex-start' alignItems='center' xs={4} sm={4} md={4} lg={4}>
                                        <div className={classes.greenBox} />
                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}><FormattedMessage id="confirmed.label" defaultMessage="Confirmed" /></Typography>
                                    </Grid>
                                </Grid>)}
                                <Grid item container justifyContent='flex-end' xs={12} sm={12} md={6} lg={6}>
                                    <TablePagination
                                        component="div"
                                        count={totalRowCount}
                                        page={totalPageCount}
                                        onPageChange={handleChangePage}
                                        rowsPerPageOptions={1, 10, 25, 50, 100}
                                        rowsPerPage={rowsPerPageForList}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </Grid>
                            <List style={{ padding: '0 10px' }}>
                                {tableData.map((row, index) => {
                                    return (
                                        <Paper elevation={5} style={{ marginBottom: '5px', width: '100%' }}>
                                            <ListItem dense key={index}
                                                className={clsx((row.dlvyCfmStatus === "1") && classes.borderSuccess,
                                                    (row.dlvyCfmStatus === "0") && classes.borderError)}>
                                                <Grid item container justifyContent='center' xs={12} sm={12} md={12} lg={12}>
                                                    <Grid item container justifyContent='flex-start' xs={12} sm={12} md={4} lg={3}>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {<FormattedMessage id="columnname.polineno.label" />}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.poLineNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                {(<tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.delCfmStatus.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <DelCfmStatusDisplayField value={row.dlvyCfmStatus} />
                                                                    </td>
                                                                </tr>)}
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.delreqdt.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.dlvyReqDtDisp}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                {(mode === 'view') && <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="expcfmadthistorylistview.text" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td><Tooltip title={<FormattedMessage id="viewcfmadthistory.tooltip" />}
                                                                        placement='bottom' className={classes.actionButton} onClick={(() => {
                                                                            setPoDtlvalue(row.msgDtlsId)
                                                                            setPoDtlObject(row)
                                                                            setModeForSchedule('view');
                                                                            setOpenPOConfirmDateDialog(true);
                                                                            setPoLineNumber(row.poLineNo);
                                                                            setItemCode(row.itemCode);
                                                                            setItemDesc(row.itemDesc);
                                                                            setMsgSchDetails(row);
                                                                        })}>
                                                                        <ViewIcon className={classes.secondaryColor} />
                                                                    </Tooltip>
                                                                    </td>
                                                                </tr>}

                                                                {(mode === 'edit') && <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.deliveryconfirmationdate.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td><Tooltip title={<FormattedMessage id="addconfirmdates.tooltip" />}
                                                                        placement='bottom' className={classes.actionButton} onClick={(() => {
                                                                            setPoDtlvalue(row.msgDtlsId)
                                                                            setPoDtlObject(row)
                                                                            setModeForSchedule('edit');
                                                                            setOpenPOConfirmDateDialog(true);
                                                                            setPoLineNumber(row.poLineNo);
                                                                            setItemCode(row.itemCode);
                                                                            setItemDesc(row.itemDesc);
                                                                            setMsgSchDetails(row);
                                                                            setMsgStatusFlagValue(row.msgStatusFlag);
                                                                        })}>
                                                                        <IconButton disabled={row.totalOutstandingOrderQty === 0 || row.poLineGrCompleted === 'X' || row.poLineDeleted === 'L' ? true : false}>
                                                                            <CreateIcon className={classes.secondaryColor} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    </td>
                                                                </tr>}
                                                                {(<tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {intl.formatMessage({ id: "columnname.poLineStatus.label" })}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        {((row.poLineStatus !== undefined && row.poLineStatus !== '0') && ((row.poLineDeleted !== undefined && row.poLineDeleted === null) || (row.poLineDeleted !== undefined && row.poLineDeleted === ''))) &&
                                                                            <Typography variant='body2' noWrap={true} className={classes.boldHeaderWithBorderForAmended}>
                                                                                AMENDED
                                                                        </Typography>
                                                                        }
                                                                        {(row.poLineDeleted !== undefined && row.poLineDeleted === 'L') &&
                                                                            <Typography variant='body2' noWrap={true} className={classes.boldHeaderWithBorderForCancelled}>
                                                                                CANCELLED
                                                                        </Typography>}
                                                                        {((row.poLineStatus !== undefined && row.poLineStatus === '0') && ((row.poLineDeleted !== undefined && row.poLineDeleted === null || row.poLineDeleted === '') || (row.poLineDeleted !== undefined && row.poLineDeleted === ''))) &&
                                                                            <Typography variant='body2' noWrap={true} className={classes.boldHeaderWithBorderForOriginal}>
                                                                                ORIGINAL
                                                                        </Typography>}
                                                                    </td>
                                                                </tr>)}
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={4} lg={3} className={classes.helper}>
                                                        <table>
                                                            <tbody>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.materialshortdesc.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.itemShortDesc}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.materialdesctable.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.itemDesc}</Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.materialcode.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' display='block' className={classes.boldHeader}>
                                                                            {row.itemCode}</Typography>
                                                                    </td>
                                                                </tr>
                                                                {<tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {intl.formatMessage({ id: "columnname.cfmaudithistory.text" })}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td><Tooltip title={<FormattedMessage id="expcfmadthistorybutton.tooltip" />}
                                                                        placement='bottom' className={classes.actionButton} onClick={(() => {
                                                                            setPOIteDetailsObject(row.msgDtlsId);
                                                                            setOpenPoCfmAuditDialog(true);
                                                                            setPoLineNumber(row.poLineNo);
                                                                        })}>
                                                                        {<HistoryIcon className={classes.secondaryColor} />}
                                                                    </Tooltip></td>
                                                                </tr>}
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        {<Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {intl.formatMessage({ id: "columnname.poaudithistoty.text" })}
                                                                        </Typography>}
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td><Tooltip title={<FormattedMessage id="exppoadthistory.tooltip" />}
                                                                        placement='bottom' className={classes.actionButton} onClick={(() => {
                                                                            setPOIteDetailsObject(row.msgDtlsId);
                                                                            setOpenPoPftAuditDialog(true);
                                                                            setPoLineNumber(row.poLineNo);
                                                                        })}>
                                                                        <PFAuditIcon className={classes.secondaryColor} />
                                                                    </Tooltip></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={4} lg={2} className={classes.helper}>
                                                        <table>
                                                            <tbody>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {<FormattedMessage id="columnname.poQty.label" />}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.dlvyReqQty}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.unitprice.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.unitPrice}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.totalamount.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.totalAmount}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                {!isBusinessPartnerPortal && <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnName.msgSentStatus.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <PoStatusForOutBoundChips value={row.msgStatusFlag} />
                                                                    </td>
                                                                </tr>}
                                                                {!isBusinessPartnerPortal && <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.ackStatus.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <AckStatusDisplayField value={row.ackStatus} />
                                                                    </td>
                                                                </tr>}
                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container justifyContent='flex-start' xs={12} sm={12} md={6} lg={2} className={classes.helper}>
                                                        <table>
                                                            <tbody>
                                                                {<tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.totalqntyreceived.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.totalQtyReceived}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>}
                                                                <tr style={{ color: '#d50000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {<FormattedMessage id="columnname.pobalQty.label" defaultMessage={columnHeaderNames.poBalQty} />}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.totalOutstandingOrderQty}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ color: '#000' }}>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.wbscode.label" defaultMessage=
                                                                                {columnHeaderNames.wbsCode} />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.wbsCode}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container justifyContent='flex-start' xs={12} sm={12} md={6} lg={2} className={classes.helper}>
                                                        <Grid item container justifyContent='flex-start' alignItems='baseline' xs={12} sm={12} md={12} lg={12}>
                                                            <Typography variant='body2' noWrap={true} className={classes.boldHeader} style={{ color: '#000' }}>
                                                                <FormattedMessage id="columnname.lastdespatchinfo.label" defaultMessage={columnHeaderNames.lastDespatchInfo} />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item container justifyContent='flex-start' alignItems='baseline' xs={12} sm={12} md={12} lg={12}>
                                                            <table>
                                                                <tbody>
                                                                    <tr style={{ color: '#000' }}>
                                                                        <td>
                                                                            <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                                <FormattedMessage id="columnname.lastdespatchno.label" />
                                                                            </Typography>
                                                                        </td>
                                                                        <td>:</td>
                                                                        <td>
                                                                            <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                                {row.lastDespatchNo}
                                                                            </Typography>
                                                                        </td>
                                                                    </tr>
                                                                    <tr style={{ color: '#000' }}>
                                                                        <td>
                                                                            <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                                <FormattedMessage id="columnname.lastdespatchqty.label" />
                                                                            </Typography>
                                                                        </td>
                                                                        <td>:</td>
                                                                        <td>
                                                                            <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                                {row.lastDespatchQty}
                                                                            </Typography>
                                                                        </td>
                                                                    </tr>
                                                                    <tr style={{ color: '#000' }}>
                                                                        <td>
                                                                            <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                                <FormattedMessage id="columnname.lastdespatchdt.label" />
                                                                            </Typography>
                                                                        </td>
                                                                        <td>:</td>
                                                                        <td>
                                                                            <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                                {row.lastDespatchNoDtDisp}
                                                                            </Typography>
                                                                        </td>
                                                                    </tr>
                                                                    <tr style={{ color: '#000' }}>
                                                                        <td>
                                                                            <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                                <FormattedMessage id="columnname.supplierInvoice.label" />
                                                                            </Typography>
                                                                        </td>
                                                                        <td>:</td>
                                                                        <td>
                                                                            <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                                {row.supplierInvoice}
                                                                            </Typography>
                                                                        </td>
                                                                    </tr>
                                                                    <tr style={{ color: '#000' }}>
                                                                        <td>
                                                                            <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                                <FormattedMessage id="columnname.exportdlvcfmdatesaudit.label" />
                                                                            </Typography>
                                                                        </td>
                                                                        <td>:</td>
                                                                        <td><Tooltip title={<FormattedMessage id="columnname.exportdlvcfmdatesaudit.label" />}
                                                                            placement='bottom' className={classes.actionButton}>
                                                                            <IconButton size='small' color='secondary'
                                                                                disabled={row.dlvyCfmStatus === "0" ? true : false}
                                                                                onClick={(() => {
                                                                                    setPoLineNumber(row.poLineNo);
                                                                                    setReportDownloadForDlvCfmDateAuditPopUp(true);
                                                                                })}>
                                                                                <GetApp />
                                                                            </IconButton>

                                                                        </Tooltip>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </Paper>
                                    );
                                })}
                            </List>
                            {tableLoadingStatus && (
                                <Paper style={{ marginBottom: '5px', width: '100%' }}>
                                    <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                        <CircularProgress color='primary' size={40} />
                                    </Grid>
                                </Paper>
                            )}
                            {(tableData.length <= 0 && !tableLoadingStatus) && (
                                <Paper style={{ marginBottom: '5px', width: '100%' }}>
                                    <Divider />
                                    <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}><FormattedMessage id="norows.label" defaultMessage="No rows" /></Typography>
                                    </Grid>
                                </Paper>
                            )}
                        </div>
                    )}
                </Paper>
                <POConfirmDateDialog open={openPOConfirmDateDialog} onClose={closePOConfirmDateDialog} poItemDetails={msgSchDetails} setPOIteDetailsObject={setMsgSchDetails}
                    poId={msgSchDetails} msgDtlsId={poDtlValue} isBusinessPartnerPortal={isBusinessPartnerPortal} ispurchaseforecast={ispurchaseforecast}
                    modeForSchedule={modeForSchedule} poDtlObject={poDtlObject} poNumber={poOrPfToBeEdited !== undefined && poOrPfToBeEdited.poNo} itemCode={itemCode}
                    poDtlObject={poDtlObject} poLineNumber={poLineNumber} itemDesc={itemDesc} msgStatusFlagValue={msgStatusFlagValue} />
                <AuditPoDelCfmDt open={openPoCfmAuditDialog} onClose={closePoConfirmationAudit} poHdr={poOrPfToBeEdited} poDtl={poItemDetailsObject} isBusinessPartnerPortal={isBusinessPartnerPortal} isPoForecastAudtitHistrory={ispurchaseforecast} msgIdValue={msgId} poLineNumber={poLineNumber} />
                <AuditPoPfDt open={openPoPftAuditDialog} onClose={closePoPftAudit} poHdr={poOrPfToBeEdited} poDtl={poItemDetailsObject} isBusinessPartnerPortal={isBusinessPartnerPortal} isPoForecastAudtitHistrory={ispurchaseforecast} msgIdValue={msgId} poLineNumber={poLineNumber} />

                <DataSourceDialog open={openDataSourceDialog} onClose={closePOPFDataSourceDialog} msgIdValue={msgId} poNumber={poOrPfToBeEdited !== undefined && poOrPfToBeEdited.poNo} />
            </Grid>
        </React.Fragment >
    );
}

export default EditOrViewPurchaseOrderForecastOutbound;