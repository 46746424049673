import { HideGridColMenuItem } from "@mui/x-data-grid-pro";
import { FormattedMessage, useIntl } from "react-intl"

function GridTextLocalization() {
    const intl = useIntl();
    return {
        toolbarColumns: HideGridColMenuItem,
        toolbarColumnsLabel: <FormattedMessage id="common.toolbarcolumnslabel.toolbar.lable" defaultMessage="Select columns" />,
        columnsPanelTextFieldLabel: <FormattedMessage id="common.findcolumn.toolbar.lable" defaultMessage="Find Column" />,
        columnsPanelTextFieldPlaceholder: intl.formatMessage({ id: "common.columntitle.toolbar.lable" }),
        columnsPanelDragIconLabel: <FormattedMessage id="common.columnspaneldragiconlabel.toolbar.lable" defaultMessage="Reorder column" />,
        columnsPanelShowAllButton: <FormattedMessage id="common.columnspanelshowallbutton.toolbar.lable" defaultMessage="Show all" />,
        columnsPanelHideAllButton: <FormattedMessage id="common.columnspanelhideallbutton.toolbar.lable" defaultMessage="Hide all" />,
        toolbarFilters: HideGridColMenuItem,
        toolbarFiltersLabel: <FormattedMessage id="common.toolbarfilterslabel.toolbar.lable" defaultMessage="Show filters" />,
        toolbarFiltersTooltipHide: <FormattedMessage id="common.toolbarfilterstooltiphide.toolbar.lable" defaultMessage="Hide filters" />,
        toolbarFiltersTooltipShow: <FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" defaultMessage="Show filters" />,
        filterPanelAddFilter: <FormattedMessage id="common.filterpaneladdfilter.toolbar.lable" defaultMessage="Add filter" />,
        filterPanelDeleteIconLabel: intl.formatMessage({ id: "common.filterpaneldeleteiconLabel.toolbar.lable" }),
        filterPanelOperators: <FormattedMessage id="common.filterpaneloperators.toolbar.lable" defaultMessage="Operators" />,
        filterPanelOperatorAnd: intl.formatMessage({ id: "common.filterpaneloperatorand.toolbar.lable" }),
        filterPanelOperatorOr: intl.formatMessage({ id: "common.filterpaneloperatoror.toolbar.lable" }),
        filterPanelColumns: <FormattedMessage id="common.filterpanelcolumns.toolbar.lable" defaultMessage="Columns" />,
        filterPanelInputLabel: <FormattedMessage id="common.filterpanelinputlabel.toolbar.lable" defaultMessage="Value" />,
        filterPanelInputPlaceholder: intl.formatMessage({ id: "common.filterpanelinputplaceholder.toolbar.lable" }),
        filterOperatorContains: intl.formatMessage({ id: "common.filteroperatorcontains.toolbar.lable" }),
        filterOperatorEquals: intl.formatMessage({ id: "common.filteroperatorequals.toolbar.lable" }),
        filterOperatorStartsWith: intl.formatMessage({ id: "common.filteroperatorstartswith.toolbar.lable" }),
        filterOperatorEndsWith: intl.formatMessage({ id: "common.filteroperatorendswith.toolbar.lable" }),
        filterOperatorIs: intl.formatMessage({ id: "common.filteroperatoris.toolbar.lable" }),
        filterOperatorNot: intl.formatMessage({ id: "common.filteroperatornot.toolbar.lable" }),
        filterOperatorAfter: intl.formatMessage({ id: "common.filteroperatorafter.toolbar.lable" }),
        filterOperatorOnOrAfter: intl.formatMessage({ id: "common.filteroperatoronorafter.toolbar.lable" }),
        filterOperatorBefore: intl.formatMessage({ id: "common.filteroperatorbefore.toolbar.lable" }),
        filterOperatorOnOrBefore: intl.formatMessage({ id: "common.filteroperatoronorbefore.toolbar.lable" }),
        filterOperatorIsEmpty: intl.formatMessage({ id: "common.filteroperatorisempty.toolbar.lable" }),
        filterOperatorIsNotEmpty: intl.formatMessage({ id: "common.filteroperatorisnotempty.toolbar.lable" }),
        columnMenuLabel: intl.formatMessage({ id: "common.columnmenulabel.toolbar.lable" }),
        columnMenuShowColumns: <FormattedMessage id="common.columnmenushowcolumns.toolbar.lable" defaultMessage="Show Columns" />,
        columnMenuFilter: <FormattedMessage id="common.columnmenufilter.toolbar.lable" defaultMessage="Filter" />,
        columnMenuHideColumn: <FormattedMessage id="common.columnmenuhidecolumn.toolbar.lable" defaultMessage="Hide" />,
        columnMenuUnsort: <FormattedMessage id="common.columnmenuunsort.toolbar.lable" defaultMessage="Unsort" />,
        columnMenuSortAsc: <FormattedMessage id="common.columnmenusortasc.toolbar.lable" defaultMessage="Sort by ASC" />,
        columnMenuSortDesc: <FormattedMessage id="common.columnmenusortdesc.toolbar.lable" defaultMessage="Sort by DESC" />,
        toolbarDensity: HideGridColMenuItem,
        toolbarDensityLabel: <FormattedMessage id="common.toolbardensitylabel.toolbar.lable" defaultMessage="Density" />,
        toolbarDensityCompact: <FormattedMessage id="common.toolbardensitycompact.toolbar.lable" defaultMessage="Compact" />,
        toolbarDensityStandard: <FormattedMessage id="common.toolbardensitystandard.toolbar.lable" defaultMessage="Standard" />,
        toolbarDensityComfortable: <FormattedMessage id="common.toolbardensitycomfortable.toolbar.lable" defaultMessage="Comfortable" />,
        columnHeaderFiltersLabel: intl.formatMessage({ id: "common.columnheaderfilterslabel.toolbar.lable" }),
        columnHeaderSortIconLabel: intl.formatMessage({ id: "common.columnheadersorticonlabel.toolbar.lable" }),
        checkboxSelectionHeaderName: intl.formatMessage({ id: "common.approvedselection.toolbar.lable" }),
        toolbarFiltersTooltipActive: (count) =>
            count !== 1 ? <FormattedMessage id="common.toolbarfilterstooltipactivefilters.toolbar.lable" values={{ count: `${count}` }} defaultMessage={`${count} active filters`} /> : <FormattedMessage id="common.toolbarfilterstooltipactivefilter.toolbar.lable" values={{ count: `${count}` }} defaultMessage={`${count} active filter`} />,
        columnHeaderFiltersTooltipActive: (count) =>
            count !== 1 ? <FormattedMessage id="common.toolbarfilterstooltipactivefilters.toolbar.lable" values={{ count: `${count}` }} defaultMessage={`${count} active filters`} /> : <FormattedMessage id="common.toolbarfilterstooltipactivefilter.toolbar.lable" values={{ count: `${count}` }} defaultMessage={`${count} active filter`} />,
        noRowsLabel: intl.formatMessage({ id: "common.norowslabel.toolbar.lable" }),
        noResultsOverlayLabel: intl.formatMessage({ id: "common.noresultsoverlaylabel.toolbar.lable" }),
        errorOverlayDefaultLabel: intl.formatMessage({ id: "common.erroroverlaydefaultlabel.toolbar.lable" }),
    }
}

export default GridTextLocalization;