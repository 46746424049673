import {
    Button, Chip, CssBaseline, Grid, Paper, Snackbar, Tooltip, Typography, Accordion, AccordionSummary, AccordionDetails, List,
    ListItem, Divider, CircularProgress, useTheme, useMediaQuery, IconButton
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Alert, ToggleButton, ToggleButtonGroup } from '@mui/lab';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import CommonBackDrop from '../../common/BackDrop';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import IconOrder from '@mui/icons-material/Assignment';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconBack from '@mui/icons-material/BackspaceOutlined';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import { GridToolbarColumnsButton, GridToolbarDensitySelector, DataGridPro } from '@mui/x-data-grid-pro';
import { CommonService } from '../../../services';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableChartIcon from '@mui/icons-material/TableChart';
import ListIcon from '@mui/icons-material/List';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ViewIcon from '@mui/icons-material/Visibility';
import useStyles from '../../common/CommonStyle/CommonStyle'
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import ScheduleGridDialogComponent from './ScheduleGrid/ScheduleGrid';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import IconMessage from '@mui/icons-material/Forum';
import saveAs from 'file-saver';
import GetAppIcon from '@mui/icons-material/GetApp';
import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';

const StyledToggleButton = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
    },
    selected: {
        backgroundColor: "#d8d8d8",
    }

}))(ToggleButton);

const columnHeaderNames = {
    poLineNo: 'poLineNo',
    poNo: 'poNo',
    schedulingAgreementRelNo: 'schedulingAgreementRelNo',
    poDate: "poDate",
    itemCode: 'itemCode',
    itemShortDesc: 'itemShortDesc',
    itemDesc: 'itemDesc',
    lineActionFlag: 'lineActionFlag',
    makerPartNo: 'makerPartNo',
    customerPartNo: 'customerPartNo',
    uom: 'uom',
    unitPrice: 'unitPrice',
    orderType: 'orderType',
    taxType1: 'taxType1',
    taxType2: 'taxType2',
    taxRate1: 'taxRate1',
    taxRate2: 'taxRate2',
    totalamount1: 'totalamount1',
    totalamount2: 'totalAmount2',
    totalAmount: 'totalAmount',
    lastDespatchQty: 'lastDespatchQty',
    lastDespatchUom: 'lastDespatchUom',
    lastDespatchNo: 'lastDespatchNo',
    lastDespatchNoDt: 'lastDespatchNoDt',
    totalQtyReceived: 'totalQtyReceived',
    totalQtyReceivedStartDt: 'totalQtyReceivedStartDt',
    totalOutstandingOrderQty: 'totalOutstandingOrderQty',
    commitmentType: 'commitmentType',
    commitmentDuration: 'commitmentDuration',
    commitmentAuthorizationDate: 'commitmentAuthorizationDate',
    poLineStatus: 'poLineStatus',
    shipToPartyCode: 'shipToPartyCode',
    shipToName: 'shipToName',
    shipToAddress: 'shipToAddress',
    shipmentLineNo: 'shipmentLineNo',
    shipmentLineStatus: 'shipmentLineStatus',
    placeOfDischarge: 'placeOfDischarge',
    placeOfDelivery: 'placeOfDelivery',
    attribute1: 'attribute1',
    attribute2: 'attribute2',
    attribute3: 'attribute3',
    attribute4: 'attribute4',
    attribute5: 'attribute5',
    attribute6: 'attribute6',
    attribute7: 'attribute7',
    attribute8: 'attribute8',
    attribute9: 'attribute9',
    attribute10: 'attribute10',
    attribute11: 'attribute11',
    attribute12: 'attribute12',
    attribute13: 'attribute13',
    attribute14: 'attribute14',
    attribute15: 'attribute15',
};

const EditPurchaseOrder = (props) => {

    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const { mode, msgId } = props.location.state;
    const { formState: { errors } } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: {
            lineShortDesc: '',
            deliveryConfirmationStatus: null
        },
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    });
    const smallScreen = useMediaQuery(theme.breakpoints.down(889))
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const poModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.inboundPurchaseOrders];
    const [disableApproveButton, setDisableApproveButtton] = useState(false);
    const [disableRejectButton, setDisableRejectButton] = useState(false);
    const pageLabelsConstantsAndMessages = {
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    }
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [openScheduleGridDialog, setOpenScheduleGridDialog] = useState(false);
    const closeScheduleGridDialog = () => {
        setOpenScheduleGridDialog(false);
    }
    const [tableData, setTableData] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [msgDtlValue, setMsgDtlValue] = useState('');

    const [poNumber, setPoNumber] = useState('');
    const [poLineNumber, setPoLineNumber] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [materialDesc, setMaterialDesc] = useState('');

    const [columns, setColumns] = useState(0);

    const commonColumnsForPoEditView = [
        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: 100,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    <Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" />}
                        placement='bottom' className={classes.actionButton} onClick={(() => {
                            setMsgDtlValue(cellParams.row.msgDtlsId);
                            setOpenScheduleGridDialog(true);
                            setItemCode(cellParams.row.itemCode);
                            setPoNumber(cellParams.row.poNo);
                            setPoLineNumber(cellParams.row.poLineNo);
                            setMaterialDesc(cellParams.row.itemDesc);

                        })}>
                        <span>
                            <IconButton className={classes.customeToolbar} size="large">
                                <ViewIcon className={classes.primaryColor} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            )
        },
        {
            field: columnHeaderNames.poNo,
            headerName: <FormattedMessage id="columnname.pono.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.poLineNo,
            headerName: <FormattedMessage id="columnname.polineno.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.poDate,
            headerName: <FormattedMessage id="columnname.podate.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.itemCode,
            headerName: <FormattedMessage id="columnname.lineItemCode.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 170,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.itemShortDesc,
            headerName: <FormattedMessage id="columnname.itemShortDesc.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 170,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.itemDesc,
            headerName: <FormattedMessage id="columnname.itemDesc.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 170,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.makerPartNo,
            headerName: <FormattedMessage id="columnname.markerpartnotable.label" defaultMessage='Maker Part No' />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.customerPartNo,
            headerName: intl.formatMessage({ id: "columnname.customerPartNo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.unitOfMeasure,
            headerName: <FormattedMessage id="columnname.uom.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.unitPrice,
            headerName: <FormattedMessage id="columnname.unitprice.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.orderType,
            headerName: <FormattedMessage id="columnname.orderType.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.unitOfMeasure,
            headerName: <FormattedMessage id="columnname.uom.label" defaultMessage={columnHeaderNames.uom} />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.unitPrice,
            headerName: <FormattedMessage id="columnname.unitprice.label" defaultMessage={columnHeaderNames.unitPrice} />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.taxType1,
            headerName: intl.formatMessage({ id: "columnname.taxType1.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.taxRate1,
            headerName: intl.formatMessage({ id: "columnname.taxRate1.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.taxRate2,
            headerName: intl.formatMessage({ id: "columnname.taxRate2.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.taxType2,
            headerName: intl.formatMessage({ id: "columnname.taxType2.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.totalAmount,
            headerName: <FormattedMessage id="columnname.totalamount.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.totalAmount1,
            headerName: <FormattedMessage id="columnname.totalamount1.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.totalAmount2,
            headerName: <FormattedMessage id="columnname.totalamount2.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.totalQtyReceived,
            headerName: <FormattedMessage id="columnname.totalqntyreceived.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.totalOutstandingOrderQty,
            headerName: <FormattedMessage id="columnname.pobalQty.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.totalQtyReceivedStartDt,
            headerName: <FormattedMessage id="columnname.totalqntyrecivefromDate.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'date',
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.lastDespatchQty,
            headerName: <FormattedMessage id="columnname.lastdespatchqty.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'number',
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.lastDespatchNo,
            headerName: <FormattedMessage id="columnname.lastdespatchno.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'number',
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.lastDespatchNoDt,
            headerName: <FormattedMessage id="columnname.lastdespatchdt.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: false,
            type: 'date',
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.commitmentType,
            headerName: intl.formatMessage({ id: "columnname.commitmentType.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.commitmentDuration,
            headerName: intl.formatMessage({ id: "columnname.commitmentDuration.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.commitmentAuthorizationDate,
            headerName: intl.formatMessage({ id: "columnname.commitmentAuthorizationDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.shipToPartyCode,
            headerName: intl.formatMessage({ id: "columnname.shipToPartyCode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.shipToName,
            headerName: intl.formatMessage({ id: "columnname.shipToName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.shipmentLineNo,
            headerName: intl.formatMessage({ id: "columnname.lineShipmentNo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.schAgreementRelNo,
            headerName: intl.formatMessage({ id: "columnname.schAgreementRelNo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute1,
            headerName: intl.formatMessage({ id: "columnname.attribute1.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute2,
            headerName: intl.formatMessage({ id: "columnname.attribute2.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute3,
            headerName: intl.formatMessage({ id: "columnname.attribute3.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute4,
            headerName: intl.formatMessage({ id: "columnname.attribute4.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute5,
            headerName: intl.formatMessage({ id: "columnname.attribute5.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute6,
            headerName: intl.formatMessage({ id: "columnname.attribute6.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute7,
            headerName: intl.formatMessage({ id: "columnname.attribute7.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute8,
            headerName: intl.formatMessage({ id: "columnname.attribute8.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute9,
            headerName: intl.formatMessage({ id: "columnname.attribute9.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute10,
            headerName: intl.formatMessage({ id: "columnname.attribute10.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute11,
            headerName: intl.formatMessage({ id: "columnname.attribute11.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute12,
            headerName: intl.formatMessage({ id: "columnname.attribute12.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute13,
            headerName: intl.formatMessage({ id: "columnname.attribute13.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute14,
            headerName: intl.formatMessage({ id: "columnname.attribute14.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.attribute15,
            headerName: intl.formatMessage({ id: "columnname.attribute15.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },

    ]

    useEffect(() => {
        setColumns(commonColumnsForPoEditView);
    }, [selectedLanguage]);

    const [tableLoadingStatus, setTableLoadingStatus] = useState(true);

    const [paramBody, setParamBody] = useState(
        {
            body: {
            },
            pageNo: 0,
            sortDir: 'asc',
            sortKey: 'msgDtlsId',
            recordsPerPage: 25,
        }
    );

    const [refreshFlag, setRefreshFlag] = useState(false);
    const [componentList, setComponentList] = useState([]);
    const [disableRefreshButton, isRefreshButtonDisabled] = useState(false);
    const [poToBeEdited, setPoToBeEdited] = useState([]);
    const [msgDtlsPopUp, setMsgDtlsPopUp] = useState(false);

    useEffect(() => {
        let currentRouterComponent;
        currentRouterComponent = {
            path: undefined,
            name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage='View' />,
            iconName: ViewIcon,
        };
        setComponentList([
            {
                path: undefined,
                name: <FormattedMessage id="common.menu.breadcrumb.message" />,
                iconName: IconMessage,
            },
            {
                path: '/purchaseOrders/ForecastInbound',
                name: <FormattedMessage id="common.menu.module.breadcrumb.purchaseorder" defaultMessage="Purchase Orders/Forecast Inbound" />,
                iconName: IconOrder,
            },
            currentRouterComponent
        ])
    }, [mode, msgId, refreshFlag]);

    const [poDetailItemViewType, setPoDetailItemViewType] = React.useState('list');

    const handleToggleChange = (event, newAlignment) => {
        setPoDetailItemViewType(newAlignment);
    };


    useEffect(() => {
        setOpenBackDrop(true);
        CommonService.getByIdApi(`/poInboundMsgHdrs`, msgId)
            .then((response) => {
                setTableLoadingStatus(false);
                setPoToBeEdited(response.data);
                setOpenBackDrop(false);
            })
            .catch(function (error) {
                setTableLoadingStatus(false);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
                setOpenBackDrop(false);
            });
    }, [refreshFlag, msgId]);

    useEffect(() => {
        setTableLoadingStatus(true);
        setTableData([]);
        setOpenBackDrop(true);
        if (mode === 'view') {
            CommonService.GetListApi(`/poInboundMsgHdrs/${msgId}/poInboundMsgDtls`, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTableData(response.data.content)
                    setTotalRowCount(response.data.totalElements);
                    setDisablingButtons(response.data.content[0].purchaseOrderMsgHdr.approved)
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    setOpenBackDrop(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                    setOpenBackDrop(false);
                });
        }
    }, [mode, paramBody, refreshFlag])

    useEffect(() => {
        if (msgDtlsPopUp === true) {
            setOpenBackDrop(true);
            let messageId = { msgId: msgId.toString() };
            CommonService.saveOrUpdateOrPost('/reports/SYS_PO_DTL', messageId)
                .then((response) => {
                    showDownloadPopUpExcelDtls(response.data.fileUrl)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />, severity: "success"
                    });
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgDtlsPopUp(false)
        }
    }, [msgId, msgDtlsPopUp])

    const showDownloadPopUpExcelDtls = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            })
    }

    const onSubmitApproval = (data) => {
        const messagesId = [
            msgId
        ];
        CommonService.saveOrUpdateOrPost('/poApprovals', messagesId)
            .then((response) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: `MessageId : ${messagesId} has been approved successfully`, severity: "success" });
                setDisableRejectButton(true);
                history.push(`/purchaseOrders/ForecastInbound`)
            })
            .catch(function (error) {
                setDisableRejectButton(true);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            });
    }

    const onSubmitReject = (data) => {
        const messagesId = [
            msgId
        ];
        CommonService.saveOrUpdateOrPost('/poRejections', messagesId)
            .then((response) => {
                setAlertBoxState({
                    ...alertBoxState, openAlert: true, message: `MessageId : ${messagesId} has been rejected successfully`, severity: "success"

                });
                setDisableApproveButtton(true);
                history.push(`/purchaseOrders/ForecastInbound`)
            })
            .catch(function (error) {
                setDisableApproveButtton(true);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            });
    }

    const [disablingButtons, setDisablingButtons] = useState('')
    const [disableDownloadButton, setDisableDownloadButton] = useState(false)

    useEffect(() => {
        if (poToBeEdited != undefined && (poToBeEdited.approved === 1 || poToBeEdited.approved === 2)) {
            setDisableRejectButton(true);
            setDisableApproveButtton(true);
        } else if (poToBeEdited != undefined && poToBeEdited.approved !== 1) {
            setDisableRejectButton(false);
            setDisableApproveButtton(false);
        }
    }, [poToBeEdited])

    useEffect(() => {
        if (poToBeEdited.msgId === undefined) {
            return poToBeEdited.msgId = 'Loading..'
        }
    }, [poToBeEdited])

    useEffect(() => {
        if (poToBeEdited.messageReceivedTimeDisp === undefined) {
            return poToBeEdited.messageReceivedTimeDisp = 'Loading..'
        }
    }, [poToBeEdited])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForEditPurchaseOrder}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' alignItems='center' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <Button size='small'
                        variant="contained" color="primary" className={classes.stateButton} elevation={2}
                        endIcon={smallScreen ? null : <IconBack />} onClick={history.goBack}
                    >{smallScreen ? <IconBack /> : <FormattedMessage id="common.button.back.lable" />}</Button>
                    <Button size='small' disabled={disableRefreshButton}
                        variant="contained"
                        color="secondary"
                        className={classes.stateButtonReferesh}
                        elevation={2}
                        endIcon={smallScreen ? null : <IconRestore />} onClick={(() => { setRefreshFlag(!refreshFlag) })}
                    >{smallScreen ? <IconRestore /> : <FormattedMessage id="common.button.refresh.tooltip" />} </Button>
                    {(poModuleAccess !== undefined ? poModuleAccess.createFlag || poModuleAccess.editFlag : false) && (
                        <Button size='small'
                            disabled={disableDownloadButton}
                            variant="contained"
                            color="primary"
                            className={classes.stateButton}
                            elevation={2}
                            elevation={2}
                            onClick={() => { setMsgDtlsPopUp(true) }}
                        > <GetAppIcon /> </Button>
                    )}
                    {(poModuleAccess !== undefined ? poModuleAccess.createFlag || poModuleAccess.editFlag : false) && (
                        <Button size='small'
                            disabled={disableApproveButton}
                            variant="contained"
                            color="primary"
                            className={classes.stateButton} elevation={2}
                            elevation={2}
                            endIcon={smallScreen ? null : <CheckCircleOutlineOutlinedIcon />}
                            onClick={onSubmitApproval}
                        >{smallScreen ? <CheckCircleOutlineOutlinedIcon /> : <FormattedMessage id="common.button.approv.tooltip" />}</Button>
                    )}
                    {(poModuleAccess !== undefined ? poModuleAccess.createFlag || poModuleAccess.editFlag : false) && (
                        <Button size='small'
                            disabled={disableRejectButton}
                            variant="contained"
                            color="primary"
                            className={classes.stateButton} elevation={2}
                            elevation={2}
                            endIcon={smallScreen ? null : <CancelOutlinedIcon />}
                            onClick={onSubmitReject}
                        >{smallScreen ? <CancelOutlinedIcon /> : <FormattedMessage id="common.button.reject.tooltip" />}</Button>
                    )}
                </Grid>
                <Paper className={classes.paperForEditPurchaseOrder} elevation={3}>
                    <form className={classes.formForPurchaseOrder}>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                            >
                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={1}>
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id="messageId.chip.text" values={{ poToBeEdited: `${(poToBeEdited !== undefined ? poToBeEdited.msgId : '')}` }} />}
                                        className={classes.chipForPO}
                                        color='secondary'
                                    />
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id="msgReferenceDate.chip.text" values={{ poToBeEdited: `${(poToBeEdited !== undefined ? poToBeEdited.messageReceivedTimeDisp !== null ? poToBeEdited.messageReceivedTimeDisp : '' : '')}` }} />}
                                        className={classes.chipTheme}
                                        color='primary'
                                    />
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id="msgRcvMode.chip.text" values={{ poToBeEdited: `${(poToBeEdited !== undefined ? poToBeEdited.msgRcvMode === 'AS2' ? 'AS2' : 'VAN' : '')}` }} />}
                                        className={classes.chipForPO}
                                        
                                        color='secondary'
                                    />
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id="noLineItem.chip.text" values={{ poToBeEdited: `${tableData.length}` }} />}
                                        className={classes.chipTheme}
                                        color='secondary'
                                    />
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id="approved.chip.text" values={{ poToBeEdited: `${(poToBeEdited !== undefined ? poToBeEdited.approved === 1 ? 'Approved' : poToBeEdited.approved === 2 ? 'Rejected' : 'Not Approved' : '')}` }} />}
                                        className={classes.chipTheme}
                                        color='primary'
                                    />
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <Grid item container xs={6} sm={6} md={12} lg={12} spacing={2} className={classes.details}>
                                    <Grid item container justifyContent='center' alignItems='baseline' xs={6} sm={6} md={6} lg={6} className={classes.helperForAccordian}>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <Typography variant='h6'><strong><FormattedMessage id="buyerdetails.label" defaultMessage="Buyer Details" /></strong></Typography>
                                        </Grid>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <div>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="name.accordian.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(poToBeEdited !== undefined ? poToBeEdited.buyerName : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="richtexteditor.blocktype.code.text" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(poToBeEdited !== undefined ? poToBeEdited.buyerPartyCode : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="columnname.buyerAddress.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(poToBeEdited !== undefined ? poToBeEdited.buyerAddress : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent='center' alignItems='baseline' xs={12} sm={12} md={6} lg={6} className={classes.helperForPO}>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <Typography variant='h6'><strong><FormattedMessage id="supplierdetails" defaultMessage="Supplier Details" /></strong></Typography>
                                        </Grid>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <div>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="name.accordian.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(poToBeEdited !== undefined ? poToBeEdited.supplierName : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="richtexteditor.blocktype.code.text" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(poToBeEdited !== undefined ? poToBeEdited.supplierPartyCode : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="columnname.supplierAddress.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(poToBeEdited !== undefined ? poToBeEdited.supplierAddress : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </form>
                </Paper>
                <Paper className={classes.paperForEditPurchaseOrder} elevation={3}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{ padding: '5px 10px' }}>
                        <Grid item container justifyContent="flex-start" alignItems="center" xs={6} sm={6} md={6} lg={6}>
                            <Typography component="span" variant="h5" color="textPrimary">
                                <FormattedMessage id="itemdetails.label" />
                            </Typography>
                        </Grid>
                        <Grid item container justifyContent="flex-end" alignItems="center" xs={6} sm={6} md={6} lg={6}>
                            <ToggleButtonGroup size="small" value={poDetailItemViewType} exclusive onChange={handleToggleChange}>
                                <StyledToggleButton value="list">
                                    <Tooltip title={<FormattedMessage id="listview.tooltip" defaultMessage='List View' />} placement='bottom'>
                                        <ListIcon fontSize="small" />
                                    </Tooltip>
                                </StyledToggleButton>
                                <StyledToggleButton value="table">
                                    <Tooltip title={<FormattedMessage id="tableview.tooltip" />} placement='bottom'>
                                        <TableChartIcon fontSize="small" />
                                    </Tooltip>
                                </StyledToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    {(poDetailItemViewType === 'table') && (
                        <Paper elevation={3} className={classes.gridHeightAndWidthForPOPFTableView}>
                            <div style={{ width: '100%', height: '100%' }}>
                                <DataGridPro
                                    className={classes.customTableStyle}
                                    pageSize={paramBody.recordsPerPage}
                                    loading={tableLoadingStatus}
                                    rows={tableData}
                                    columns={columns}
                                    rowCount={totalRowCount}
                                    getRowId={(row) => row.id}
                                    scrollbarSize={30}
                                    disableColumnFilter={true}
                                    rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                                    pagination
                                    density="compact"
                                    paginationMode="server"
                                    hideFooter={true}
                                    disableSelectionOnClick={true}
                                    disableMultipleColumnsSorting={true}
                                    disableColumnMenu={false}
                                    localeText={localtext}
                                    onPageSizeChange={((pageParams) => {
                                        setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                                    })}
                                    onPageChange={((pageParams) => {
                                        setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                                    })}
                                    components={{
                                        Toolbar: () => {
                                            return (
                                                <Grid container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                                    <Grid
                                                        item
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}>
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" />} placement='bottom'>
                                                                <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.density.tooltip" />} placement='bottom'>
                                                                <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <CommonGridPagination />
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                    }}
                                />
                            </div>
                        </Paper>
                    )}
                    {(poDetailItemViewType === 'list') && (
                        <div>
                            <Divider />
                            <List style={{ padding: '0 10px' }}>
                                {tableData.map((row, index) => {
                                    return (
                                        <Paper elevation={5} style={{ marginBottom: '5px', width: '100%' }}>
                                            <ListItem dense key={index} className={classes.helperForPos}>
                                                <Grid item container justifyContent='center' xs={12} sm={12} md={12} lg={12} >
                                                    <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={2}>
                                                        <table>
                                                            <tbody>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeaderForHeading} style={{ marginTop: '10px' }}>
                                                                    <FormattedMessage id="columnname.PoLinedetails.label" />
                                                                </Typography>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.pono.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.poNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.linePoNo.label" defaultMessage={columnHeaderNames.linePoNo} />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.poLineNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.podate.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.linePoDtDisp}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.firmCount.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.firmCount}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.forecastCount.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.forecastCount}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.scheduleGrid.label" defaultMessage={columnHeaderNames.scheduleGrid} />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td><Tooltip title={<FormattedMessage id="viewScheduledPo.tooltip" />}
                                                                        placement='bottom' className={classes.actionButton} onClick={(() => {
                                                                            setMsgDtlValue(row.msgDtlsId)
                                                                            setOpenScheduleGridDialog(true);
                                                                            setItemCode(row.itemCode);
                                                                            setPoNumber(row.poNo);
                                                                            setPoLineNumber(row.poLineNo);
                                                                            setMaterialDesc(row.itemDesc);
                                                                        })}>
                                                                        <ViewIcon className={classes.primaryColor} />
                                                                    </Tooltip>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={3}
                                                        className={classes.helperForDetail}>
                                                        <table>
                                                            <tbody>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeaderForHeading}>
                                                                    <FormattedMessage id="columnname.materialInfo.label" defaultMessage={columnHeaderNames.materialInfo} />
                                                                </Typography>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.materialcode.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' display='block' className={classes.boldHeader}>
                                                                            {row.itemCode}</Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.materialShortcode.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.itemShortDesc}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.materialdesctable.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.itemDesc}</Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.markerpartnotable.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.makerPartNo}</Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.customerPartNo.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.customerPartNo}</Typography>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={2}
                                                        className={classes.helperForDetail}>
                                                        <table>
                                                            <tbody>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeaderForHeading}>
                                                                    <FormattedMessage id="columnname.linedeliverydtls.label" defaultMessage={columnHeaderNames.lineDlvDtls} />
                                                                </Typography>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.totalqntyreceived.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.totalQtyReceived}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="balqty.piedata" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.totalOutstandingOrderQty}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.currencyCode.label" defaultMessage={columnHeaderNames.currencyCode} />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.purchaseOrderMsgHdr.currencyCode}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.unitprice.label" defaultMessage={columnHeaderNames.unitPrice} />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.unitPrice}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.totalamount.label" defaultMessage={columnHeaderNames.totalAmount} />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.totalAmount}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={3} className={classes.helperForDetail}>
                                                        <table>
                                                            <tbody>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeaderForHeading}>
                                                                    <FormattedMessage id="columnname.lastdespatchinfo.label" defaultMessage={columnHeaderNames.lastDespatchInfo} />
                                                                </Typography>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.lastdespatchno.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.lastDespatchNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.lastdespatchqty.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.lastDespatchQty}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.lastdespatchuom.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.lastDespatchUom}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.lastdespatchdt.label" defaultMessage={columnHeaderNames.lastDespatchNoDt} />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.lastDespatchNoDtDisp}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={2} className={classes.helperForDetail}>
                                                        <table>
                                                            <tbody>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeaderForHeading}>
                                                                    <FormattedMessage id="columnname.shipmentDetails.label" />
                                                                </Typography>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.shipmenttoName.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.shipToName}</Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="richtexteditor.blocktype.code.text" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.shipToPartyCode}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.shipmentLineNo.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.shipmentLineNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.placeOfDischarge.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.placeOfDischarge}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.placeOfDlv.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.placeOfDelivery}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </Paper>
                                    );
                                })}
                                {tableLoadingStatus && (
                                    <Paper style={{ marginBottom: '5px', width: '100%' }}>
                                        <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                            <CircularProgress color='primary' size={40} />
                                        </Grid>
                                    </Paper>
                                )}
                                {(tableData.length <= 0 && !tableLoadingStatus) && (
                                    <Paper style={{ marginBottom: '5px', width: '100%' }}>
                                        <Divider />
                                        <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                            <Typography variant='body2' noWrap={true} className={classes.boldHeader}><FormattedMessage id="loading.text" defaultMessage="No rows" /></Typography>
                                        </Grid>
                                    </Paper>
                                )}
                            </List>
                        </div>
                    )}
                    {/* </Grid> */}
                </Paper>
                <ScheduleGridDialogComponent open={openScheduleGridDialog} onClose={closeScheduleGridDialog} msgId={msgId} msgDtlsId={msgDtlValue} mode={'view'}
                    poNumber={poNumber} itemCode={itemCode} itemDesc={materialDesc} poLineNumber={poLineNumber}
                />

            </Grid>
        </React.Fragment >
    );

}

export default EditPurchaseOrder;