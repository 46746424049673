import { Badge, Button, CssBaseline, Grid, IconButton, Paper, Snackbar, Tooltip, useMediaQuery, useTheme, CircularProgress, Typography, Switch, Popper, Fade, ClickAwayListener, Menu, MenuList, MenuItem, Chip } from '@mui/material';
import { LicenseInfo, DataGridPro, GridToolbarColumnsButton, GridToolbarDensitySelector, getGridStringOperators, getGridDateOperators } from '@mui/x-data-grid-pro';
import useStyles from '../common/CommonStyle/CommonStyle';
import CommonReportDownloadForMsg from '../common/CommonReportDownloadForMsg';
import CustomBreadCrumb from '../common/CustomBreadCrumb';
import IconMessage from '@mui/icons-material/Forum';
import { FormattedMessage, useIntl } from 'react-intl';
import IconAdvanceShipmentNoteOutbound from '@mui/icons-material/Receipt';
import CustomFilterComponent, { GetFilterableColumns } from '../common/CustomFilterComponent';
import ChipInfoList from '../common/ChipInfoList';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';
import { SupportedLanguageDetails } from '../common/SupportedLanguageDetails/SupportedLanguageDetails';
import { ModuleAccessPermissionKey } from '../../Constants/ModuleAccessKey';
import CustomReportIcon from '@mui/icons-material/Archive';
import CommonGridPagination from '../common/CommonGridPagination/CommonGridPagination';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import { CommonService } from '../../services';
import saveAs from 'file-saver';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import IconApproval from '@mui/icons-material/LibraryAddCheck';
import { manageUserPreferences } from '../../services/manageUserPreferences';
import Alert from '@mui/lab/Alert';
import CommonBackDrop from '../common/BackDrop';
import { CustomFilters } from '../common/CustomFilters/CustomFilters';
import PCNFileUploadDialog from '../common/PCNFileUploadDialog';
import ImportIcon from '@mui/icons-material/SaveAlt';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/EditOutlined';
import { useHistory } from 'react-router';
import ExportIcon from '@mui/icons-material/Publish';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PcnDtAuditHistory from '../../components/Order/PcnDtAuditHistory/PcnDtAuditHistory';
import PFAuditIcon from '@mui/icons-material/Timeline';
import FileUploadSection from './FileUploadSection';
import DriveFolderUpload from '@mui/icons-material/DriveFolderUpload'
import CustomAddOrViewFiles from './AddOrViewFiles';
import { withStyles } from '@mui/styles';
import { CheckCircle } from '@mui/icons-material';

const columnFieldsName = {
    msgId: 'msgId',
    pcnNo: 'pcnNo',
    bpCode: 'bpCode',
    pcnReceivedDt: 'pcnReceivedDt',
    createdBy: 'createdBy',
    createdTime: 'createdTime',
    updatedBy: 'updatedBy',
    updatedTime: 'updatedTime',
    erpOrgCode: 'organization.erpOrgCode',
    erpEntityCode: 'entity.erpEntityCode',
    orgName: 'organization.orgName',
    fromUpdatedDate: 'fromUpdatedDate',
    toUpdatedDate: 'toUpdatedDate',
    approved: 'approved',
    apprEmailSent: 'apprEmailSent',
    approvedBy: 'approvedBy',
    approvedDate: 'approvedDate',
    msgStatusFlag: 'msgStatusFlag',
    msgStatusRemarks: 'msgStatusRemarks',
    bpName: 'bpName',
    wbsDesc: 'wbsDesc',
    supplierAddress: 'supplierAddress',
    wbsCode: 'wbsCode',
    firstAnsDueDt: 'firstAnsDueDt',
    lastAnsDueDt: 'lastAnsDueDt',
    lastTimeBuyDt: 'lastTimeBuyDt',
    lastTimeShipmentDt: 'lastTimeShipmentDt',
    fromFirstAnsDueDt: 'fromFirstAnsDueDt',
    toFirstAnsDueDt: 'toFirstAnsDueDt',
    fromLastAnsDueDt: 'fromLastAnsDueDt',
    toLastAnsDueDt: 'toLastAnsDueDt',
    fromLastTimeBuyDt: 'fromLastTimeBuyDt',
    toLastTimeBuyDt: 'toLastTimeBuyDt',
    fromLastTimeShipmentDt: 'fromLastTimeShipmentDt',
    toLastTimeShipmentDt: 'toLastTimeShipmentDt',
    fromPcnDate: 'fromPcnDate',
    toPcnDate: 'toPcnDate',
    emailSentDate: 'emailSentDate',
    fromEmailSentDate: 'fromEmailSentDate',
    toEmailSentDate: 'toEmailSentDate',
    amendmentCount: 'amendmentCount',


};
const NoChip = withStyles((theme) => ({
    root: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        width: '60%',
    },
}))(Chip);

const YesChip = withStyles((theme) => ({
    root: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        width: '60%',
    },
}))(Chip);

function PartChangeNotification(props) {

    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const history = useHistory();

    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const pageLabelsConstantsAndMessages = {
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        },
    }
    const [selectedRow, setSelectedRow] = React.useState([]);
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const currentUser = CommonService.getLoggedInUserInfo();
    const pcnModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.pcnModuleAccess];
    const partChangeNotiBPModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.PartChangeNotiBP];

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [refreshTable, setRefreshTable] = useState(false);

    const [chipInfoList, setChipInfoList] = useState([]);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [disableApproveButton, isDisableApproveButton] = useState(true);
    const [disableRejectionButton, isDisableRejectionButton] = useState(true);
    const mobileScreen = useMediaQuery(theme.breakpoints.only('xs'));

    const [msgHdrPopUp, setMsgHdrPopUp] = useState(false);
    const [isRowSentForApproval, setIsRowSentForApproval] = useState(false);
    const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
    const [OpenCustomAddOrViewFiles, setOpenCustomAddOrViewFiles] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);
    const anchorRef = React.useRef(null);
    const { isBusinessPartnerPortal, isPartchange, msgId } = props;
    const [searchedWbsCode, setSearchedWbsCode] = useState('');
    const [open, setOpen] = React.useState(false);
    const dateValue = new Date();

    var defaultEntity = loggedInUserInfo.defaultEntity;
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [columns, setColumns] = useState([]);
    const [disiablingDownloadButtonForZeroRecords, setDisiablingDownloadButtonForZeroRecords] = useState(false)
    const [keyValueData, setKeyValueData] = useState({});
    const [searchedErpEntityCode, setSearchedErpEntityCode] = useState(defaultEntity);
    const [searchedpcnReceivedDate, setSearchedpcnReceivedDate] = useState('');
    const [searchedupdatedBy, setSearchedupdatedBy] = useState('');
    const [searchedBpCode, setSearchedBpCode] = useState('');
    const [searchedPcnNumber, setSearchedPcnNumber] = useState('');
    const [searchedUpdatedFromTime, setSearchedUpdatedFromTime] = useState(null);
    const [searchedUpdatedToTime, setSearchedUpdatedToTime] = useState('');
    const [searchedApprovedBy, setSearchedApprovedBy] = useState('');
    const [searchedRemarks, setSearchedRemarks] = useState('');
    const [searchedEmailStatus, setSearchedEmailStatus] = useState('');
    const [searchedWbsName, setSearchedWbsName] = useState('');
    const [bpTypeValue, setBpTypeValue] = useState('');
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [isExportXlRequested, setExportXlRequested] = useState(false);
    const [pcnToBeEdited, setPcnToBeEdited] = useState();
    const [searchedSupplierName, setSearchedSupplierName] = useState('');
    const [searchedFirstAnsDueDate, setSearchedFirstAnsDueDate] = useState('');
    const [searchedLastAnsDueDate, setSearchedLastAnsDueDate] = useState('');
    const [searchedLastTimeBuyDate, setSearchedLastTimeBuyDate] = useState('');
    const [searchedLastTimeShipmentDate, setSearchedLastTimeShipmentDate] = useState('');
    const [searchedStatusFlag, setSearchedStatusFlag] = useState('');
    const [searchedApprovedRecordsRecords, setSearchedApprovedRecordsRecords] = useState(null);
    const [searchedFromFirstAnsDueDate, setSearchedFromFirstAnsDueDate] = useState('');
    const [searchedToFirstAnsDueDate, setSearchedToFirstAnsDueDate] = useState('');
    const [searchedFromLastAnsDueDate, setSearchedFromLastAnsDueDate] = useState('');
    const [searchedToLastAnsDueDate, setSearchedToLastAnsDueDate] = useState('');
    const [searchedFromLastTimeBuyDate, setSearchedFromLastTimeBuyDate] = useState('');
    const [searchedToLastTimeBuyDate, setSearchedToLastTimeBuyDate] = useState('');
    const [searchedFromLastTimeShipmentDate, setSearchedFromLastTimeShipmentDate] = useState('');
    const [searchedToLastTimeShipmentDate, setSearchedToLastTimeShipmentDate] = useState('');
    const [searchedPcnFromDate, setSearchedPcnFromDate] = useState('');
    const [searchedPcnToDate, setSearchedPcnToDate] = useState('');
    const [searchedEmailSentDate, setSearchedEmailSentDate] = useState('');
    const [searchedFromEmailSentDate, setSearchedFromEmailSentDate] = useState('');
    const [searchedToEmailSentDate, setSearchedToEmailSentDate] = useState('');
    const [refreshAddOrView, setRefreshAddOrView] = useState(false);
    const [searchedAmendedValue, setSearchedAmendedValue] = useState('');

    const userAccessBpCode = currentUser.userSupplierAccessList === null ? null : currentUser.userSupplierAccessList[0].supplier.bpCode;
    const userAccessBpName = currentUser.userSupplierAccessList === null ? null : currentUser.userSupplierAccessList[0].supplier.bpSupplierName;

    const closeFileUploadDialog = (confirmed) => {
        setOpenFileUploadDialog(false);
    }



    const closeAddOrViewFiles = (confirmed) => {
        setOpenCustomAddOrViewFiles(false);
        setRefreshAddOrView(false);
    }

    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState({
        left: ["__check__"],
        right: []
    });

    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);

    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleCloseFilterPanel = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.erpEntityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity }]
        : [];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);

    const [openReportDownloadDialog, setOpenReportDownloadDialog] = useState(false);
    const [openPcnAuditDialog, setOpenPcnAuditDialog] = useState(false);
    const [pcnItemDetailsObject, setPcnIteDetailsObject] = useState();
    const [selectedRowDetails, setSelectedRowDetails] = useState([]);
    const [selectedRowMsgId, setSelectedRowMsgId] = useState(null);



    // const closePcnAudit = (confirmed) => {
    //     setOpenPcnAuditDialog(false);
    //     setPcnIteDetailsObject(undefined);
    // }


    const closeReportDownloadDialog = (confirmed) => {
        setOpenReportDownloadDialog(false);
    }
    const [paramBody, setParamBody] = useState(
        {
            body: {
                entity: {
                    erpEntityCode: defaultEntity,
                    entityName: ''
                },
                approved: isBusinessPartnerPortal ? 1 : null,
                recordStatusFlag: 'All',
                recordAmendmentCount: 'All',
                recordApprovedStatus: isBusinessPartnerPortal ? 1 : 'All',
                fromUpdatedDate: isBusinessPartnerPortal ? null : new Date(dateValue.setDate(dateValue.getDate() - 7)).toISOString().slice(0, 10),

            },
            pageNo: 0,
            sortDir: 'desc',
            sortKey: 'updatedTime',
            recordsPerPage: 25,
        }
    );

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
        requestXLReportExport(options[index], false);
    };

    const handleToggle = (event) => {
        setAnchorEl(null);
        setAnchorE2(anchorRef.current)
    };

    const handleClose = (event) => {
        setAnchorE2(null)
    };

    const handleMenuClick = (event, index) => {

    }

    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.message" />,
            iconName: IconMessage,
        },
        {
            path: '/partChangeNotification',
            name: <FormattedMessage id="common.module.breadcrumb.partchangenotification" />,
            iconName: IconAdvanceShipmentNoteOutbound,
        }
    ];


    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }


    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);


    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);



    const commonColumnForPCN = [
        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: isBusinessPartnerPortal ? 100 : 100,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    {(pcnModuleAccess !== undefined ? pcnModuleAccess.readFlag && !(pcnModuleAccess.editFlag || pcnModuleAccess.createFlag) : false) && (
                        <Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                let msgId = cellParams.row.msgId;
                                history.push(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.partChangeNotificationDtl}/${msgId}`,
                                    {
                                        mode: 'view',
                                        msgId: msgId,
                                        isBusinessPartnerPortal: isBusinessPartnerPortal, isPartchange: true, userAccessBpCode: userAccessBpCode,
                                        userAccessBpName: userAccessBpName,
                                        defaultEntity: defaultEntity, defaultBpType: "SUPPLIER"
                                    });
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <ViewIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {(pcnModuleAccess !== undefined ?  pcnModuleAccess.readFlag && !(pcnModuleAccess.editFlag || pcnModuleAccess.createFlag) : false)
                        && (!isBusinessPartnerPortal) && (
                            <Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    let msgId = cellParams.row.msgId;
                                    history.push(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.partChangeNotificationDtl}/${msgId}`,
                                        {
                                            mode: 'view', msgId: msgId,
                                            isBusinessPartnerPortal: isBusinessPartnerPortal,
                                            userAccessBpCode: userAccessBpCode,
                                            userAccessBpName: userAccessBpName,
                                            defaultEntity: defaultEntity,
                                            defaultBpType: "SUPPLIER",
                                            isPartchange: true

                                        });
                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar} size="large">
                                        <ViewIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                    {(pcnModuleAccess !== undefined ? pcnModuleAccess.editFlag || pcnModuleAccess.createFlag : false)
                        && (!isBusinessPartnerPortal) && (
                            <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    let msgId = cellParams.row.msgId;
                                    history.push(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.partChangeNotificationDtl}/${msgId}`, {
                                        mode: 'edit', msgId: msgId,
                                        isBusinessPartnerPortal: isBusinessPartnerPortal,
                                        userAccessBpCode: userAccessBpCode, userAccessBpName: userAccessBpName,
                                        defaultEntity: defaultEntity,
                                        defaultBpType: "SUPPLIER",
                                        isPartchange: true,


                                    });
                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar} size="large">
                                        <EditIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                    {(pcnModuleAccess !== undefined ? pcnModuleAccess.editFlag || pcnModuleAccess.createFlag : false) && (isBusinessPartnerPortal) && (
                        <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                let msgId = cellParams.row.msgId;
                                history.push(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.partChangeNotificationDtl}/${msgId}`, {
                                    mode: 'edit', msgId: msgId,
                                    isBusinessPartnerPortal: isBusinessPartnerPortal,
                                    userAccessBpCode: userAccessBpCode,
                                    userAccessBpName: userAccessBpName,
                                    defaultEntity: defaultEntity,
                                    defaultBpType: "SUPPLIER",
                                    isPartchange: true,
                                });
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {(pcnModuleAccess !== undefined ?  pcnModuleAccess.readFlag && !(pcnModuleAccess.editFlag || pcnModuleAccess.createFlag): false) && (isBusinessPartnerPortal) && (
                        <Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                let msgId = cellParams.row.msgId;
                                history.push(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.partChangeNotificationDtl}/${msgId}`, {
                                    mode: 'view', msgId: msgId,
                                    isBusinessPartnerPortal: isBusinessPartnerPortal,
                                    userAccessBpCode: userAccessBpCode,
                                    userAccessBpName: userAccessBpName,
                                    defaultEntity: defaultEntity,
                                    defaultBpType: "SUPPLIER",
                                    isPartchange: true

                                });
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <ViewIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                </div >
            )
        },
        {
            field: columnFieldsName.erpEntityCode,
            headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 85,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: true,
            valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.bpCode,
            width: 130,
            headerName: intl.formatMessage({ id: "common.textfield.columname.bpcode" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.bpName,
            width: 300,
            headerName: intl.formatMessage({ id: "columnName.bpname.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: isBusinessPartnerPortal ? true : false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.pcnNo,
            headerName: intl.formatMessage({ id: "columnname.pcnNumber.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 155,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },

        {
            field: columnFieldsName.pcnReceivedDt,
            headerName: intl.formatMessage({ id: "columnname.pcnReceivedDate.text" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 155,
            sortable: true,
            type: 'dateTime',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.pcnDateDisp}</span>)

        },


        {
            field: columnFieldsName.wbsCode,
            headerName: intl.formatMessage({ id: "common.textfield.column.project" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 90,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.wbsDesc,
            headerName: intl.formatMessage({ id: "common.textfield.column.projectName" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.updatedBy,
            headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 155,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.updatedTime,
            headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'dateTime',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.updatedTimeDisp}</span>)
        },
        {
            field: columnFieldsName.createdBy,
            headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.createdTime,
            headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.createdTimeDisp}</span>)
        },
        {
            field: columnFieldsName.fromUpdatedDate,
            headerName: intl.formatMessage({ id: "columnname.updatedFromDate.text" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 0,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.toUpdatedDate,
            headerName: intl.formatMessage({ id: "columnname.updatedToDate.text" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 0,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.firstAnsDueDt,
            headerName: intl.formatMessage({ id: "columnname.firstAnsDueDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'dateTime',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.firstAnsDueDtDisp}</span>)
        },
        {
            field: columnFieldsName.lastAnsDueDt,
            headerName: intl.formatMessage({ id: "columnname.lastAnsDueDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'dateTime',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.lastAnsDueDtDisp}</span>)

        },
        {
            field: columnFieldsName.lastTimeBuyDt,
            headerName: intl.formatMessage({ id: "columnname.lastTimeBuyDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'dateTime',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.lastTimeBuyDtDisp}</span>)

        },
        {
            field: columnFieldsName.lastTimeShipmentDt,
            headerName: intl.formatMessage({ id: "columnname.lastTimeShipmentDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'dateTime',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.lastTimeShipmentDtDisp}</span>)

        },
        {
            field: columnFieldsName.approved,
            headerName: intl.formatMessage({ id: "columnName.approved.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.ApproveFailureChipsForPO
        },
        {
            field: columnFieldsName.apprEmailSent,
            headerName: intl.formatMessage({ id: "columnName.apprEmailSent.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 200,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.customRenderStatusColumnForApprEmailSent
        },
        {
            field: columnFieldsName.approvedBy,
            width: 155,
            headerName: intl.formatMessage({ id: "columnName.approvedBy.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.approvedDate,
            width: 170,
            headerName: intl.formatMessage({ id: "columnName.approvedDate.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'dateTime',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.approvedDateDisp}</span>)
        },
        {
            field: columnFieldsName.msgStatusFlag,
            width: 150,
            headerName: intl.formatMessage({ id: "columnName.msgSentStatus.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.InvoiceeStatusForChips
        },

        {
            field: columnFieldsName.msgStatusRemarks,
            headerName: intl.formatMessage({ id: "columnname.remarks.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },

        {
            field: columnFieldsName.supplierAddress,
            width: 185,
            headerName: intl.formatMessage({ id: "columnname.supplierAddress.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.fromFirstAnsDueDt,
            headerName: intl.formatMessage({ id: "columnname.fromfirstAnsDueDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.fromFirstAnsDueDt}</span>)
        },
        {
            field: columnFieldsName.toFirstAnsDueDt,
            headerName: intl.formatMessage({ id: "columnname.tofirstAnsDueDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.toFirstAnsDueDt}</span>)
        },
        {
            field: columnFieldsName.fromLastAnsDueDt,
            headerName: intl.formatMessage({ id: "columnname.fromLastAnsDueDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.fromLastAnsDueDt}</span>)
        },
        {
            field: columnFieldsName.toLastAnsDueDt,
            headerName: intl.formatMessage({ id: "columnname.toLastAnsDueDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.toLastAnsDueDt}</span>)
        },
        {
            field: columnFieldsName.fromLastTimeBuyDt,
            headerName: intl.formatMessage({ id: "columnname.fromLastTimeBuyDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.fromLastTimeBuyDt}</span>)
        },
        {
            field: columnFieldsName.toLastTimeBuyDt,
            headerName: intl.formatMessage({ id: "columnname.toLastTimeBuyDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.toLastTimeBuyDt}</span>)
        },
        {
            field: columnFieldsName.fromLastTimeShipmentDt,
            headerName: intl.formatMessage({ id: "columnname.fromLastTimeShipmentDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.fromLastTimeShipmentDt}</span>)
        },
        {
            field: columnFieldsName.toLastTimeShipmentDt,
            headerName: intl.formatMessage({ id: "columnname.toLastTimeShipmentDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.toLastTimeShipmentDt}</span>)
        },
        {
            field: columnFieldsName.fromPcnDate,
            headerName: intl.formatMessage({ id: "columnname.fromPcnDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 0,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.fromPcnDate}</span>)
        },
        {
            field: columnFieldsName.toPcnDate,
            headerName: intl.formatMessage({ id: "columnname.toPcnDate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 0,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.toPcnDate}</span>)
        },
        {
            field: columnFieldsName.emailSentDate,
            width: 175,
            headerName: intl.formatMessage({ id: "columnName.emailSentDate.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'dateTime',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <span>{cellParams.row.msgSentTimeDisp}</span>
            )
        },
        {
            field: columnFieldsName.fromEmailSentDate,
            width: 175,
            headerName: intl.formatMessage({ id: "columnName.fromEmailSentDate.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <span>{cellParams.row.msgSentTimeDisp}</span>
            )
        },
        {
            field: columnFieldsName.toEmailSentDate,
            width: 175,
            headerName: intl.formatMessage({ id: "columnName.toEmailSentDate.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <span>{cellParams.row.msgSentTimeDisp}</span>
            )
        },
        {
            field: columnFieldsName.amendmentCount,
            width: 170,
            headerName: intl.formatMessage({ id: "columnname.pcnAmended.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            filterable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: ((cellParams) => {
                if (cellParams.row.amendmentCount > 0) {
                    return (<YesChip
                        icon={<CheckCircle />}
                        variant="outlined"
                        label='YES'
                        color='primary'
                    />)
                } else {
                    return (<NoChip
                        icon={<CancelIcon />}
                        variant="outlined"
                        label='NO'
                        color='secondary'
                    />)
                }
            }
            )
        }
    ];

    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(commonColumnForPCN);
                savePreference("visibility", commonColumnForPCN);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                paramBody.body.approved = isBusinessPartnerPortal ? 1 : null;
                paramBody.body.fromUpdatedDate = isBusinessPartnerPortal ? null : new Date(dateValue.setDate(dateValue.getDate() - 7)).toISOString().slice(0, 10);
                setParamBody({ ...paramBody });
            }
            //preference in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, commonColumnForPCN);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let entityCode = null;
                let pcnNo = null;
                let bpCode = null;
                let bpName = null;
                let updatedBy = null;
                let fromUpdatedDate = null;
                let toUpdatedDate = null;
                let approved = null;
                let approvedBy = null;
                let msgStatusFlag = null;
                let msgStatusRemarks = null;
                let emailSentDate = null;
                let wbsCode = null;
                let wbsDesc = null;
                let fromFirstAnsDueDt = null;
                let toFirstAnsDueDt = null;
                let fromLastAnsDueDt = null;
                let toLastAnsDueDt = null;
                let fromLastTimeBuyDt = null;
                let toLastTimeBuyDt = null;
                let fromLastTimeShipmentDt = null;
                let toLastTimeShipmentDt = null;
                let fromPcnDate = null;
                let toPcnDate = null;
                let fromEmailSentDate = null;
                let toEmailSentDate = null;
                let amendmentCount = null;

                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'entity.erpEntityCode':
                            entityCode = columns.value
                            break;
                        case 'pcnNo':
                            pcnNo = columns.value
                            break;
                        case 'bpCode':
                            bpCode = columns.value
                            break;
                        case 'bpName':
                            bpName = columns.value
                            break;
                        case 'updatedBy':
                            updatedBy = columns.value
                            break;
                        case 'updatedBy':
                            updatedBy = columns.value
                            break;
                        case 'fromUpdatedDate':
                            fromUpdatedDate = columns.value
                            break;
                        case 'toUpdatedDate':
                            toUpdatedDate = columns.value
                            break;
                        case 'approved':
                            approved = columns.value
                            break;
                        case 'approvedBy':
                            approvedBy = columns.value
                            break;
                        case 'msgStatusFlag':
                            msgStatusFlag = columns.value
                            break;
                        case 'msgStatusRemarks':
                            msgStatusRemarks = columns.value
                            break;
                        case 'wbsCode':
                            wbsCode = columns.value
                            break;
                        case 'wbsDesc':
                            wbsDesc = columns.value
                            break;
                        case 'emailSentDate':
                            emailSentDate = columns.value
                            break;
                        case 'fromFirstAnsDueDt':
                            fromFirstAnsDueDt = columns.value
                            break;
                        case 'toFirstAnsDueDt':
                            toFirstAnsDueDt = columns.value
                            break;
                        case 'fromLastAnsDueDt':
                            fromLastAnsDueDt = columns.value
                            break;
                        case 'toLastAnsDueDt':
                            toLastAnsDueDt = columns.value
                            break;
                        case 'fromLastTimeBuyDt':
                            fromLastTimeBuyDt = columns.value
                            break;
                        case 'toLastTimeBuyDt':
                            toLastTimeBuyDt = columns.value
                            break;
                        case 'fromLastTimeShipmentDt':
                            fromLastTimeShipmentDt = columns.value
                            break;
                        case 'toLastTimeShipmentDt':
                            toLastTimeShipmentDt = columns.value
                            break;
                        case 'fromPcnDate':
                            fromPcnDate = columns.value
                            break;
                        case 'toPcnDate':
                            toPcnDate = columns.value
                            break;
                        case 'fromEmailSentDate':
                            fromEmailSentDate = columns.value
                            break;
                        case 'toEmailSentDate':
                            toEmailSentDate = columns.value
                            break;
                        case 'amendmentCount':
                            amendmentCount = columns.value
                            break;
                    }
                })

                setSearchedBpCode(bpCode);
                setSearchedErpEntityCode(entityCode);
                setSearchedupdatedBy(updatedBy);
                setSearchedPcnNumber(pcnNo);
                setSearchedUpdatedFromTime(fromUpdatedDate);
                setSearchedUpdatedToTime(toUpdatedDate);
                setSearchedApprovedRecordsRecords(approved);
                setSearchedApprovedBy(approvedBy);
                setSearchedEmailStatus(msgStatusFlag);
                setSearchedRemarks(msgStatusRemarks);
                setSearchedWbsCode(wbsCode);
                setSearchedWbsName(wbsDesc);
                setSearchedEmailSentDate(emailSentDate);
                setSearchedSupplierName(bpName);
                setSearchedFromFirstAnsDueDate(fromFirstAnsDueDt);
                setSearchedToFirstAnsDueDate(toFirstAnsDueDt);
                setSearchedFromLastAnsDueDate(fromLastAnsDueDt);
                setSearchedToLastAnsDueDate(toLastAnsDueDt);
                setSearchedFromLastTimeBuyDate(fromLastTimeBuyDt);
                setSearchedToLastTimeBuyDate(toLastTimeBuyDt);
                setSearchedFromLastTimeShipmentDate(fromLastTimeShipmentDt);
                setSearchedToLastTimeShipmentDate(toLastTimeShipmentDt);
                setSearchedPcnFromDate(fromPcnDate);
                setSearchedPcnToDate(toPcnDate);
                setSearchedFromEmailSentDate(fromEmailSentDate);
                setSearchedToEmailSentDate(toEmailSentDate)
                setSearchedAmendedValue(amendmentCount);

                var filterBody = {
                    entity: { erpEntityCode: entityCode },
                    pcnNo: pcnNo,
                    bpCode: bpCode,
                    bpName: bpName,
                    updatedBy: updatedBy,
                    recordApprovedStatus: isBusinessPartnerPortal ? 1 : approved === '' ? 'All' : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : 'All',
                    approved: isBusinessPartnerPortal ? 1 : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : '',
                    msgStatusFlag: msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : '',
                    fromUpdatedDate: fromUpdatedDate,
                    toUpdatedDate: toUpdatedDate,
                    recordStatusFlag: msgStatusFlag === '' ? 'All' : msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : 'All',
                    wbsCode: wbsCode,
                    wbsDesc: wbsDesc,
                    emailSentDate: emailSentDate,
                    fromFirstAnsDueDt: fromFirstAnsDueDt,
                    toFirstAnsDueDt: toFirstAnsDueDt,
                    fromLastAnsDueDt: fromLastAnsDueDt,
                    toLastAnsDueDt: toLastAnsDueDt,
                    fromLastTimeBuyDt: fromLastTimeBuyDt,
                    toLastTimeBuyDt: toLastTimeBuyDt,
                    fromLastTimeShipmentDt: fromLastTimeShipmentDt,
                    toLastTimeShipmentDt: toLastTimeShipmentDt,
                    fromPcnDate: fromPcnDate,
                    toPcnDate: toPcnDate,
                    fromEmailSentDate: fromEmailSentDate,
                    toEmailSentDate: toEmailSentDate,
                    amendmentCount: amendmentCount === 'Yes' ? 1 : amendmentCount === 'No' ? 0 : '',
                    recordAmendmentCount: amendmentCount === null ? 'All' : amendmentCount === 'Yes' ? 1 : amendmentCount === 'No' ? 0 : '',

                };
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
        
                if (savedColumnsInResponse.columnsFilter.length <= 0) {
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                    paramBody.body.fromUpdatedDate = isBusinessPartnerPortal ? null : new Date(dateValue.setDate(dateValue.getDate() - 7)).toISOString().slice(0, 10);
                    
                }
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });
                
                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [selectedLanguage]);

    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi('/partChangeNotificationHdr', paramBody)
                .then((response) => {
                    setTotalRowCount(response.data.totalElements);
                    setTableLoadingStatus(false);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }

    }, [paramBody, refreshTable]);

    useEffect(() => {
        if (totalRowCount === 0) {
            setDisiablingDownloadButtonForZeroRecords(true)
        } else {
            setDisiablingDownloadButtonForZeroRecords(false)
        }
    }, [totalRowCount])

    if (columns.length > 0) {
        const orgNameColumn = columns.find((column) => column.field === columnFieldsName.orgName);
        const orgNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.orgName);

        const orgNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgNameInputValue,
            }),
        );

        columns[orgNameColIndex] = {
            ...orgNameColumn,
            filterOperators: orgNameFilterOperators,
        };

        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };

        const approvedColumn = columns.find((column) => column.field === columnFieldsName.approved);
        const approvedColIndex = columns.findIndex((col) => col.field === columnFieldsName.approved);

        const approvedFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.ApprovedPOInputValue,
            }),
        );

        columns[approvedColIndex] = {
            ...approvedColumn,
            filterOperators: approvedFilterOperators,
        };
        const statusFlagColumn = columns.find((column) => column.field === columnFieldsName.msgStatusFlag);
        const statusFlagColIndex = columns.findIndex((col) => col.field === columnFieldsName.msgStatusFlag);

        const statusFlagFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.PoStatusOutboundColInputValue,
            }),
        );

        columns[statusFlagColIndex] = {
            ...statusFlagColumn,
            filterOperators: statusFlagFilterOperators,
        };


        const fromUpdateTimeColumn = columns.find((column) => column.field === columnFieldsName.fromUpdatedDate);
        const fromUpdateTimeColIndex = columns.findIndex((col) => col.field === columnFieldsName.fromUpdatedDate);

        const fromUpdateTimeFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[fromUpdateTimeColIndex] = {
            ...fromUpdateTimeColumn,
            filterOperators: fromUpdateTimeFilterOperators,
        };

        const toUpdateTimeColumn = columns.find((column) => column.field === columnFieldsName.toUpdatedDate);
        const toUpdateTimeColIndex = columns.findIndex((col) => col.field === columnFieldsName.toUpdatedDate);

        const toUpdateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[toUpdateTimeColIndex] = {
            ...toUpdateTimeColumn,
            filterOperators: toUpdateFilterOperators,
        };


        const emailSentDateColumn = columns.find((column) => column.field === columnFieldsName.emailSentDate);
        const emailSentDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.emailSentDate);

        const emailSentDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[emailSentDateColIndex] = {
            ...emailSentDateColumn,
            filterOperators: emailSentDateFilterOperators,
        };


        const wbsCodeColumn = columns.find((column) => column.field === columnFieldsName.wbsCode);
        const wbsCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.wbsCode);

        const wbsCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.WbsCodeInputValue,
            }),
        );

        columns[wbsCodeColIndex] = {
            ...wbsCodeColumn,
            filterOperators: wbsCodeFilterOperators,
        };
        const supplierNameColumn = columns.find((column) => column.field === columnFieldsName.bpName);
        const supplierNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.bpName);

        const supplierNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.SupplierVendorNameInputValue,
            }),
        );

        columns[supplierNameColIndex] = {
            ...supplierNameColumn,
            filterOperators: supplierNameFilterOperators,
        };

        const wbsNameColumn = columns.find((column) => column.field === columnFieldsName.wbsDesc);
        const wbsNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.wbsDesc);

        const wbsNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.WbsDescriptionInputValue,
            }),
        );

        columns[wbsNameColIndex] = {
            ...wbsNameColumn,
            filterOperators: wbsNameFilterOperators,
        };


        const fromFirstAnsDueDateColumn = columns.find((column) => column.field === columnFieldsName.fromFirstAnsDueDt);
        const fromFirstAnsDueDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.fromFirstAnsDueDt);

        const fromFirstAnsDueDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[fromFirstAnsDueDateColIndex] = {
            ...fromFirstAnsDueDateColumn,
            filterOperators: fromFirstAnsDueDateFilterOperators,
        };

        const toFirstAnsDueDateColumn = columns.find((column) => column.field === columnFieldsName.toFirstAnsDueDt);
        const toFirstAnsDueDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.toFirstAnsDueDt);

        const toFirstAnsDueDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[toFirstAnsDueDateColIndex] = {
            ...toFirstAnsDueDateColumn,
            filterOperators: toFirstAnsDueDateFilterOperators,
        };

        const fromLastAnsDueDateColumn = columns.find((column) => column.field === columnFieldsName.fromLastAnsDueDt);
        const fromLastAnsDueDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.fromLastAnsDueDt);

        const fromLastAnsDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[fromLastAnsDueDateColIndex] = {
            ...fromLastAnsDueDateColumn,
            filterOperators: fromLastAnsDateFilterOperators,
        };

        const toLastAnsDueDateColumn = columns.find((column) => column.field === columnFieldsName.toLastAnsDueDt);
        const toLastAnsDueDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.toLastAnsDueDt);

        const toLastAnsDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[toLastAnsDueDateColIndex] = {
            ...toLastAnsDueDateColumn,
            filterOperators: toLastAnsDateFilterOperators,
        };

        const fromLastTimeBuyDateColumn = columns.find((column) => column.field === columnFieldsName.fromLastTimeBuyDt);
        const fromLastTimeBuyDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.fromLastTimeBuyDt);

        const fromLastTimeBuyDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[fromLastTimeBuyDateColIndex] = {
            ...fromLastTimeBuyDateColumn,
            filterOperators: fromLastTimeBuyDateFilterOperators,
        };

        const toLastTimeBuyDateColumn = columns.find((column) => column.field === columnFieldsName.toLastTimeBuyDt);
        const toLastTimeBuyDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.toLastTimeBuyDt);

        const toLastTimeBuyDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[toLastTimeBuyDateColIndex] = {
            ...toLastTimeBuyDateColumn,
            filterOperators: toLastTimeBuyDateFilterOperators,
        };

        const fromLastTimeShipmentDateColumn = columns.find((column) => column.field === columnFieldsName.fromLastTimeShipmentDt);
        const fromLastTimeShipmentDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.fromLastTimeShipmentDt);

        const fromLastTimeShipmentDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[fromLastTimeShipmentDateColIndex] = {
            ...fromLastTimeShipmentDateColumn,
            filterOperators: fromLastTimeShipmentDateFilterOperators,
        };

        const toLastTimeShipmentDateColumn = columns.find((column) => column.field === columnFieldsName.toLastTimeShipmentDt);
        const toLastTimeShipmentDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.toLastTimeShipmentDt);

        const toLastTimeShipmentDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[toLastTimeShipmentDateColIndex] = {
            ...toLastTimeShipmentDateColumn,
            filterOperators: toLastTimeShipmentDateFilterOperators,
        };

        const pcnFromDateColumn = columns.find((column) => column.field === columnFieldsName.fromPcnDate);
        const pcnFromDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.fromPcnDate);

        const pcnFromDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[pcnFromDateColIndex] = {
            ...pcnFromDateColumn,
            filterOperators: pcnFromDateFilterOperators,
        };


        const pcnToDateColumn = columns.find((column) => column.field === columnFieldsName.toPcnDate);
        const pcnToDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.toPcnDate);

        const pcnToDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[pcnToDateColIndex] = {
            ...pcnToDateColumn,
            filterOperators: pcnToDateFilterOperators,
        };

        const fromEmailSentDateColumn = columns.find((column) => column.field === columnFieldsName.fromEmailSentDate);
        const fromEmailSentDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.fromEmailSentDate);

        const fromEmailSentDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[fromEmailSentDateColIndex] = {
            ...fromEmailSentDateColumn,
            filterOperators: fromEmailSentDateFilterOperators,
        };

        const toEmailSentDateColumn = columns.find((column) => column.field === columnFieldsName.toEmailSentDate);
        const toEmailSentDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.toEmailSentDate);

        const toEmailSentDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[toEmailSentDateColIndex] = {
            ...toEmailSentDateColumn,
            filterOperators: toEmailSentDateFilterOperators,
        };

    }
    const amendedColumn = columns.find((column) => column.field === columnFieldsName.amendmentCount);
    const amendedColIndex = columns.findIndex((col) => col.field === columnFieldsName.amendmentCount);

    const amendedFilterOperators = getGridStringOperators().map(
        (operator) => ({
            ...operator,
            InputComponent: CustomFilters.PoAmendmentInputValue,
        }),
    );

    columns[amendedColIndex] = {
        ...amendedColumn,
        filterOperators: amendedFilterOperators,
    };

    const approveAndMsgId = tableData.map((row) => {
        return {
            approved: row.approved, msgId: row.msgId, amendmentCount: row.amendmentCount,
            bpCode: row.bpCode, entityCode: row.entity.erpEntityCode
        }
    })

    const filterValueLoad = approveAndMsgId.filter((item) => {
        if (selectedRow.includes(item.msgId)) {
            return item
        }
    })

    const handleRowSelection = (selectedRow) => {
        // Assuming 'selectedRow' is the currently selected row
        const selectedRowMsgId = selectedRow.msgId;
        // Now you have the 'msgId' of the selected row
        setSelectedRowMsgId(selectedRowMsgId);
    };


    // const getDataBasedOnTheSelectedField = tableData.map((row) => {
    //     return {
    //         bpCode: row.bpCode, entityCode: row.entity.erpEntityCode,
    //         approved: row.approved, msgId: row.msgHdrId
    //     }
    // })

    // const filterValueLoad = getDataBasedOnTheSelectedField.filter((item) => {
    //     if (selectedRow.includes(item.msgId)) {
    //         return item
    //     }
    // })


    const bpCodeValues = filterValueLoad.map((row) => { return row.bpCode })
    const entittyCodeValue = filterValueLoad.map((row) => { return row.entityCode })
    const filterValueApproved = filterValueLoad.map((row) => { return row.approved });

    function currentlySelectedRow(selections) {
        setSelectedRow(selections)
        setAnchorE2(null);
        setAnchorEl(null);

        const selectedRowId = selections[0];
        const selectedRowData = tableData.find((row) => row.msgId === selectedRowId);
        setPcnToBeEdited(selectedRowData);
        if (selections.length === 0) {
            isDisableApproveButton(true);
            isDisableRejectionButton(true);
        } else {
            isDisableApproveButton(false);
            isDisableRejectionButton(false);
        }
    }


    useEffect(() => {
        if (selectedRow.length === 0) {
            isDisableApproveButton(true)
            isDisableRejectionButton(true);
        } else if (filterValueApproved.toString().includes('0')) {
            isDisableApproveButton(false);
            isDisableRejectionButton(false);
        } else if (filterValueApproved.toString().includes('1')) {
            isDisableApproveButton(true);
            isDisableRejectionButton(true);
        } else if (filterValueApproved.toString().includes('2')) {
            isDisableApproveButton(false);
            isDisableRejectionButton(true);
        } else {
            isDisableApproveButton(false);
            isDisableRejectionButton(false);
        }
    }, [filterValueApproved])


    const onSubmit = () => {
        setIsFormSubmitted(true);
        setIsRowSentForApproval(true)
        setOpenBackDrop(true);
        if (filterValueApproved.toString().includes('1')) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains already Approved records", severity: "warning"
            });
            setIsRowSentForApproval(false);
            setIsFormSubmitted(false);
            setOpenBackDrop(false);
        } else {
            CommonService.saveOrUpdateOrPost('/pcnApprovals', selectedRow)
                .then((response) => {
                    setIsFormSubmitted(false)
                    setIsRowSentForApproval(false)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: `MessageId : ${selectedRow} has been approved successfully`, severity: "success"
                    });
                    setSelectedRow([])
                    setRefreshTable(!refreshTable);
                    isDisableApproveButton(true)
                    isDisableRejectionButton(true);
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setIsFormSubmitted(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.message, severity: "error" });
                    isDisableApproveButton(false)
                    setOpenBackDrop(false);
                });
        }
    }

    const onSubmitReject = (data) => {
        setIsFormSubmitted(true);
        setIsRowSentForApproval(true);
        setOpenBackDrop(true);
        if (filterValueApproved.toString().includes('2')) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains already Rejected records", severity: "warning"
            });
            setIsRowSentForApproval(false);
            setIsFormSubmitted(false);
            setOpenBackDrop(false);
        } else if (filterValueApproved.toString().includes('1')) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains already Approved records", severity: "warning"
            });
            setIsRowSentForApproval(false);
            setIsFormSubmitted(false);
            setOpenBackDrop(false);
        } else {
            CommonService.saveOrUpdateOrPost('/pcnRejections', selectedRow)
                .then((response) => {
                    setIsFormSubmitted(false)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: `MessageId : ${selectedRow} has been rejected successfully`, severity: "success"
                    });
                    setSelectedRow([])
                    setRefreshTable(!refreshTable);
                    isDisableApproveButton(true);
                    isDisableRejectionButton(true)
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setIsFormSubmitted(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.message, severity: "error" });
                    isDisableRejectionButton(false)
                    setOpenBackDrop(false);
                });
        }
    }


    const optionForExpPoPRAndAdtHistoryForEdi = [
        {
            label: intl.formatMessage({ id: "pcnexportbutton.Tooltip" }),
            tooltipTitle: intl.formatMessage({ id: "common.tooltip.breadcrumb.downloadPCNExcelHdr" }),
            url: '/reports/SYS_PCN_HDR_REPORT',
            successMessage: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />,
            parameters: keyValueData
        }
    ];

    const buttonOptionsForDlvCfmQty = [
        {
            label: intl.formatMessage({ id: "uploadPCNReport.button" }),
            tooltipTitle: intl.formatMessage({ id: "exportPcnReportbutton.Tooltip" }),
            url: '/reports/pcnConfirmationDate',
            successMessage: intl.formatMessage({ id: "snackbar.requestedForPcnreport" }),
            parameters: {
                requestedForStdtemplate: false,
                pcnNo: searchedPcnNumber,
                entity: { erpEntityCode: (searchedErpEntityCode === '' && searchedBpCode === '') ? '' : searchedErpEntityCode },
                bpCode: (searchedErpEntityCode === '' && searchedBpCode === '') ? '' : searchedBpCode,
                bpName: searchedSupplierName,
                wbsCode: searchedWbsCode,
                wbsDesc: searchedWbsName,
                // pcnReceivedDt: searchedpcnReceivedDate,
                // firstAnsDueDt:searchedFirstAnsDueDate,
                // lastAnsDueDt:searchedLastAnsDueDate,
                // lastTimeBuyDt:searchedLastTimeBuyDate,
                // lastTimeShipmentDt:searchedLastAnsDueDate,
                fromFirstAnsDueDt: searchedFromFirstAnsDueDate,
                toFirstAnsDueDt: searchedToFirstAnsDueDate,
                fromLastAnsDueDt: searchedFromLastAnsDueDate,
                toLastAnsDueDt: searchedToLastAnsDueDate,
                fromLastTimeBuyDt: searchedFromLastTimeBuyDate,
                toLastTimeBuyDt: searchedToLastTimeBuyDate,
                fromLastTimeShipmentDt: searchedFromLastTimeShipmentDate,
                toLastTimeShipmentDt: searchedToLastTimeShipmentDate,
                fromEmailSentDate: searchedFromEmailSentDate,
                toEmailSentDate: searchedToEmailSentDate,
                fromPcnDate: searchedPcnFromDate !== null ? searchedPcnFromDate : null,
                toPcnDate: searchedPcnToDate !== null ? searchedPcnToDate : null,
                fromUpdatedDate: (searchedErpEntityCode === '' && searchedBpCode === '') ? isBusinessPartnerPortal ? null : new Date(dateValue.setDate(dateValue.getDate() - 0)).toISOString().slice(0, 10) : (searchedBpCode === '') ? isBusinessPartnerPortal ? null : new Date(dateValue.setDate(dateValue.getDate() - 0)).toISOString().slice(0, 10) : searchedUpdatedFromTime,
                toUpdatedDate: searchedUpdatedToTime,
                approved: isBusinessPartnerPortal ? 1 : searchedApprovedRecordsRecords === 'Not Approved' ? 0 : searchedApprovedRecordsRecords === 'Approved' ? 1 : searchedApprovedRecordsRecords === 'Rejected' ? 2 : null,
                recordApprovedStatus: isBusinessPartnerPortal ? 1 : searchedApprovedRecordsRecords === '' ? 'All' : searchedApprovedRecordsRecords === 'Not Approved' ? 0 : searchedApprovedRecordsRecords === 'Approved' ? 1 : searchedApprovedRecordsRecords === 'Rejected' ? 2 : 'All',
                recordStatusFlag: searchedStatusFlag === '' || searchedStatusFlag === null ? 'All' : searchedStatusFlag === 'Not Sent' ? 0 : searchedStatusFlag === 'Pending' ? 1 : searchedStatusFlag === 'Successfully Sent' ? 2 : searchedStatusFlag === 'Failed to Send' ? 3 : searchedStatusFlag === 'Cancelled' ? 4 : '',
                msgStatusFlag: searchedStatusFlag === 'Not Sent' ? 0 : searchedStatusFlag === 'Pending' ? 1 : searchedStatusFlag === 'Successfully Sent' ? 2 : searchedStatusFlag === 'Failed to Send' ? 3 : searchedStatusFlag === 'Cancelled' ? 4 : null,
                amendmentCount: searchedAmendedValue === 'Yes' ? 1 : searchedAmendedValue === 'No' ? 0 : '',
                recordAmendmentCount: searchedAmendedValue === '' || searchedAmendedValue === null ? 'All' : '',

            }
        },

    ];

    const buttonOptionsForPCNUplaodTemplate = [
        {
            label: intl.formatMessage({ id: "uploadPcndlvschedule.button" }),
            tooltipTitle: intl.formatMessage({ id: "exportPcnDlvbutton.Tooltip" }),
            url: '/reports/pcnConfirmationDate',
            successMessage: intl.formatMessage({ id: "snackbar.requestedforcfmqtyexcelreport" }),
            parameters: {
                requestedForStdtemplate: true
            }
        },

    ];

    const options = buttonOptionsForPCNUplaodTemplate.concat(buttonOptionsForDlvCfmQty)
        .concat(optionForExpPoPRAndAdtHistoryForEdi)

    const requestXLReportExport = (selectedExportReportTypeObj) => {
        setExportXlRequested(true);
        setOpenBackDrop(true);
        if (selectedExportReportTypeObj.label === intl.formatMessage({ id: "uploadPcndlvschedule.button" })) {
            CommonService.saveOrUpdateOrPost(selectedExportReportTypeObj.url, selectedExportReportTypeObj.parameters)
                .then((response) => {
                    setExportXlRequested(false);
                    setOpenBackDrop(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: `PCN Upload Template Downloaded Successfully!`, severity: "success" });
                    showDownloadPopUp(response.data.fileUrl);
                })
                .catch(function (error) {
                    setExportXlRequested(false);
                    setOpenBackDrop(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                });
        } else {
            CommonService.saveOrUpdateOrPost(selectedExportReportTypeObj.url, selectedExportReportTypeObj.parameters)
                .then((response) => {
                    setExportXlRequested(false);
                    setOpenBackDrop(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success" });
                        //showDownloadPopUp(response.data.fileUrl);
                })
                .catch(function (error) {
                    setExportXlRequested(false);
                    setOpenBackDrop(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                });
        }
    }


    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let bpCode = null;
        let bpName = null;
        let pcnNo = null;
        let entityCode = null;
        let fromUpdatedDate = null;
        let toUpdatedDate = null;
        let approved = null;
        let wbsCode = null;
        let wbsDesc = null;
        let msgStatusFlag = null;
        let fromFirstAnsDueDt = null;
        let toFirstAnsDueDt = null;
        let fromLastAnsDueDt = null;
        let toLastAnsDueDt = null;
        let fromLastTimeBuyDt = null;
        let toLastTimeBuyDt = null;
        let fromLastTimeShipmentDt = null;
        let toLastTimeShipmentDt = null;
        let fromPcnDate = null;
        let toPcnDate = null;
        let fromEmailSentDate = null;
        let toEmailSentDate = null;
        let amendmentCount = null;
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'bpCode':
                    bpCode = columns.value
                    break;
                case 'bpName':
                    bpName = columns.value
                    break;
                case 'pcnNo':
                    pcnNo = columns.value
                    break;

                case 'entity.erpEntityCode':
                    entityCode = columns.value
                    break;
                case 'fromUpdatedDate':
                    fromUpdatedDate = columns.value
                    break;
                case 'toUpdatedDate':
                    toUpdatedDate = columns.value
                    break;
                case 'approved':
                    approved = columns.value
                    break;
                case 'wbsCode':
                    wbsCode = columns.value
                    break;
                case 'wbsDesc':
                    wbsDesc = columns.value
                    break;
                case 'msgStatusFlag':
                    msgStatusFlag = columns.value
                    break;
                case 'fromFirstAnsDueDt':
                    fromFirstAnsDueDt = columns.value
                    break;
                case 'toFirstAnsDueDt':
                    toFirstAnsDueDt = columns.value
                    break;
                case 'fromLastAnsDueDt':
                    fromLastAnsDueDt = columns.value
                    break;
                case 'toLastAnsDueDt':
                    toLastAnsDueDt = columns.value
                    break;
                case 'fromLastTimeBuyDt':
                    fromLastTimeBuyDt = columns.value
                    break;
                case 'toLastTimeBuyDt':
                    toLastTimeBuyDt = columns.value
                    break;
                case 'fromLastTimeShipmentDt':
                    fromLastTimeShipmentDt = columns.value
                    break;
                case 'toLastTimeShipmentDt':
                    toLastTimeShipmentDt = columns.value
                    break;
                case 'fromPcnDate':
                    fromPcnDate = columns.value
                    break;
                case 'toPcnDate':
                    toPcnDate = columns.value
                    break;
                case 'fromEmailSentDate':
                    fromEmailSentDate = columns.value
                    break;
                case 'toEmailSentDate':
                    toEmailSentDate = columns.value
                    break;
                case 'amendmentCount':
                    amendmentCount = columns.value
                    break;
            }
        })
        setSearchedBpCode(bpCode);
        setSearchedSupplierName(bpName);
        setSearchedErpEntityCode(entityCode);
        setSearchedPcnNumber(pcnNo);
        setSearchedUpdatedFromTime(fromUpdatedDate);
        setSearchedUpdatedToTime(toUpdatedDate);
        setSearchedApprovedRecordsRecords(approved);
        setSearchedWbsCode(wbsCode);
        setSearchedWbsName(wbsDesc);
        setSearchedStatusFlag(msgStatusFlag);
        setSearchedFromFirstAnsDueDate(fromFirstAnsDueDt);
        setSearchedToFirstAnsDueDate(toFirstAnsDueDt);
        setSearchedFromLastAnsDueDate(fromLastAnsDueDt);
        setSearchedToLastAnsDueDate(toLastAnsDueDt);
        setSearchedFromLastTimeBuyDate(fromLastTimeBuyDt);
        setSearchedToLastTimeBuyDate(toLastTimeBuyDt);
        setSearchedFromLastTimeShipmentDate(fromLastTimeShipmentDt);
        setSearchedToLastTimeShipmentDate(toLastTimeShipmentDt);
        setSearchedPcnFromDate(fromPcnDate);
        setSearchedPcnToDate(toPcnDate);
        setSearchedFromEmailSentDate(fromEmailSentDate);
        setSearchedToEmailSentDate(toEmailSentDate)
        setSearchedAmendedValue(amendmentCount);


        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);
        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    entity: { erpEntityCode: entityCode },
                    pcnNo: pcnNo,
                    bpCode: bpCode,
                    bpName: bpName,
                    wbsCode: wbsCode,
                    wbsDesc: wbsDesc,
                    fromUpdatedDate: fromUpdatedDate,
                    toUpdatedDate: toUpdatedDate,
                    recordApprovedStatus: isBusinessPartnerPortal ? 1 : approved === '' ? 'All' : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : 'All',
                    approved: isBusinessPartnerPortal ? 1 : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : '',
                    msgStatusFlag: msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : '',
                    recordStatusFlag: msgStatusFlag === '' ? 'All' : msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : 'All',
                    fromFirstAnsDueDt: fromFirstAnsDueDt,
                    toFirstAnsDueDt: toFirstAnsDueDt,
                    fromLastAnsDueDt: fromLastAnsDueDt,
                    toLastAnsDueDt: toLastAnsDueDt,
                    fromLastTimeBuyDt: fromLastTimeBuyDt,
                    toLastTimeBuyDt: toLastTimeBuyDt,
                    fromLastTimeShipmentDt: fromLastTimeShipmentDt,
                    toLastTimeShipmentDt: toLastTimeShipmentDt,
                    fromPcnDate: fromPcnDate,
                    toPcnDate: toPcnDate,
                    fromEmailSentDate: fromEmailSentDate,
                    toEmailSentDate: toEmailSentDate,
                    amendmentCount: amendmentCount === 'Yes' ? 1 : amendmentCount === 'No' ? 0 : '',
                    recordAmendmentCount: amendmentCount === null ? 'All' : amendmentCount === 'Yes' ? 1 : amendmentCount === 'No' ? 0 : '',

                }
            }
        })
    }

    useEffect(() => {
        if (intialParamBodyApiCall)
            setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    let filterBodyObj = {
        erpEntityCode: searchedErpEntityCode === null ? "" : searchedErpEntityCode,
        bpCode: searchedBpCode,
        bpName: searchedSupplierName,
        pcnNo: searchedPcnNumber,
        fromUpdatedDate: searchedUpdatedFromTime !== null ? searchedUpdatedFromTime : null,
        toUpdatedDate: searchedUpdatedToTime !== null ? searchedUpdatedToTime : null,
        msgStatusFlag: searchedStatusFlag === "" ? null : searchedStatusFlag,
        approved: searchedApprovedRecordsRecords === 'Not Approved' ? 'NO' : searchedApprovedRecordsRecords === 'Approved' ? 'YES' : searchedApprovedRecordsRecords === 'Rejected' ? 'REJECTED' : null,
        wbsCode: searchedWbsCode,
        wbsDesc: searchedWbsName,
        fromFirstAnsDueDt: searchedFromFirstAnsDueDate,
        toFirstAnsDueDt: searchedToFirstAnsDueDate,
        fromLastAnsDueDt: searchedFromLastAnsDueDate,
        toLastAnsDueDt: searchedToLastAnsDueDate,
        fromLastTimeBuyDt: searchedFromLastTimeBuyDate,
        toLastTimeBuyDt: searchedToLastTimeBuyDate,
        fromLastTimeShipmentDt: searchedFromLastTimeShipmentDate,
        toLastTimeShipmentDt: searchedToLastTimeShipmentDate,
        fromPcnDate: searchedPcnFromDate !== null ? searchedPcnFromDate : null,
        toPcnDate: searchedPcnToDate !== null ? searchedPcnToDate : null,
        fromEmailSentDate: searchedFromEmailSentDate,
        toEmailSentDate: searchedToEmailSentDate,
        amendmentCount: searchedAmendedValue === "" ? null : searchedAmendedValue,

    }
    useEffect(() => {
        let colValDataObj = {}
        for (let key in filterBodyObj) {
            let value = filterBodyObj[key]
            if (filterBodyObj.hasOwnProperty(key) && ((value !== null) && (value !== undefined))) {
                colValDataObj[key] = value.toString();
            }
            setKeyValueData(colValDataObj)
        }
    }, [searchedErpEntityCode, searchedBpCode, searchedSupplierName, searchedPcnNumber, searchedStatusFlag, searchedApprovedRecordsRecords, searchedWbsCode, searchedWbsName, searchedEmailSentDate, searchedFromFirstAnsDueDate, searchedToFirstAnsDueDate, searchedFromLastAnsDueDate, searchedToLastAnsDueDate, searchedFromLastTimeBuyDate, searchedToLastTimeBuyDate, searchedFromLastTimeShipmentDate, searchedToLastTimeShipmentDate, searchedFromEmailSentDate, searchedToEmailSentDate, searchedPcnFromDate, searchedPcnToDate, searchedAmendedValue
    ]);

    const showDownloadPopUp = (filePath) => {
        setOpenBackDrop(true);
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        }) 
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: "PCN Upload Template Downloaded Successfully!", severity: "success" });
                saveAs(blob, fileName);
                setOpenBackDrop(false);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
                setOpenBackDrop(false);
            })
    }

    useEffect(() => {
        if (msgHdrPopUp === true) {
            setOpenBackDrop(true);
            CommonService.saveOrUpdateOrPost('/reports/SYS_PCN_HDR_REPORT', searchedErpEntityCode === '' ? { erpEntityCode: '' } : keyValueData)
                .then((response) => {
                    if (response.data.sucess === "No records found for given condition")
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.sucess, severity: "error" });
                    else {
                        // showDownloadPopUpExcelHdr(response.data.fileUrl)
                        setAlertBoxState({
                            ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success"
                        });
                    }
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgHdrPopUp(false)
        }
    }, [msgHdrPopUp, keyValueData]);



    return (

        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>

            <CommonBackDrop open={openBackDrop} />
            <CommonReportDownloadForMsg open={openReportDownloadDialog} bpType={bpTypeValue} bpCode={bpCodeValues} entityCode={entittyCodeValue} proccessValue={{ processId: 3, processName: 'Part Change Notification' }}
                onClose={closeReportDownloadDialog} msgIdvalue={selectedRow} />
            <Grid container className={classes.rootForRole}>

                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.topGrid} style={{ marginLeft: '-0.5%' }}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <CustomFilterComponent open={openFilterPanel} onClose={handleCloseFilterPanel} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />

                <PCNFileUploadDialog
                    open={openFileUploadDialog}
                    onClose={closeFileUploadDialog}
                    isBusinessPartnerPortal={isBusinessPartnerPortal}
                    defaultEntity={defaultEntity}
                    defaultBpType={"SUPPLIER"}
                    dialogHeader={<FormattedMessage id="uploadsPcn.header.text" />}
                    showToggleButton={false} isPartchange={isPartchange}/>

                <CustomAddOrViewFiles
                    open={OpenCustomAddOrViewFiles}
                    onClose={closeAddOrViewFiles}
                    isBusinessPartnerPortal={isBusinessPartnerPortal}
                    defaultEntity={defaultEntity}
                    defaultBpType={"SUPPLIER"}
                    dialogHeader={"Upload files"}
                    showToggleButton={false}
                    selectedRow={pcnToBeEdited}
                    isPartchange={isPartchange}
                    refreshAddOrView={refreshAddOrView}
                    setRefreshAddOrView={setRefreshAddOrView}

                />

                <ChipInfoList chipInfoList={chipInfoList} />
                <Paper elevation={3} style={{ marginTop: '5px' }} className={classes.gridHeightAndWidthForASN}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            disableColumnFilter={true}
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            getRowId={(row) => row.msgId}
                            scrollbarSize={30}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            isRowSelectable={(params) => true}
                            hideFooter={true}
                            checkboxSelection={true}
                            onSelectionModelChange={currentlySelectedRow}
                            selectionModel={selectedRow}
                            localeText={localtext}
                            density="compact"
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" />}
                                                        placement='bottom'>
                                                        <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterListIcon />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    {(pcnModuleAccess !== undefined ? pcnModuleAccess.createFlag || pcnModuleAccess.editFlag : false) && (!isBusinessPartnerPortal) && (
                                                        <Tooltip title={<FormattedMessage id="common.button.approveselectedrecords.tooltip" defaultMessage="Approve selected records" />} placement='bottom'>
                                                            <span>
                                                                <Button size="small" color="primary" variant="outlined"
                                                                    className={classes.refreshToobarStyle}
                                                                    onClick={onSubmit}
                                                                    disabled={disableApproveButton || isFormSubmitted}
                                                                >
                                                                    <IconApproval size={20} />
                                                                </Button>

                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                                <Grid>
                                                    {(pcnModuleAccess !== undefined ? pcnModuleAccess.createFlag || pcnModuleAccess.editFlag : false) && (!isBusinessPartnerPortal) && (

                                                        <Tooltip title={<FormattedMessage id="common.button.rejectselectedrecords.tooltip" defaultMessage="Reject selected records" />} placement='bottom'>
                                                            <span>
                                                                <Button size="small" color="primary" variant="outlined"
                                                                    className={classes.refreshToobarStyle}
                                                                    onClick={onSubmitReject}
                                                                    disabled={disableRejectionButton || isFormSubmitted}
                                                                >
                                                                    <CancelIcon size={20} />
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                </Grid>

                                                <Grid> <Tooltip title={options[selectedIndex].tooltipTitle} placement='bottom'>
                                                    <span>
                                                        <Button
                                                            size="small"
                                                            variant="outlined"
                                                            ref={anchorRef}
                                                            color="primary"
                                                            disabled={isExportXlRequested}
                                                            endIcon={isExportXlRequested ? <CircularProgress color="secondary" size={20} /> : null}
                                                            onClick={handleToggle}
                                                            style={{ marginRight: '5px' }}
                                                        >
                                                            <ExportIcon />
                                                            <Typography color="primary" style={{ margin: '0 5px 0 5px', fontWeight: 500, fontSize: '13px' }} >
                                                                <FormattedMessage id="export.defaulttext.button" />
                                                            </Typography>
                                                            <ArrowDropDownIcon />
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                    <Menu
                                                        id="selectReportDownload"
                                                        anchorPosition={isBusinessPartnerPortal ? { left: 150, top: 125 } : { left: 240, top: 150 }}
                                                        anchorReference="anchorPosition"
                                                        open={Boolean(anchorE2)}
                                                        elevation={5}
                                                        onClick={handleClose}
                                                    >
                                                        <MenuList>
                                                            {options.map((option, index) => (
                                                                <Tooltip title={options[index].tooltipTitle} placement="right-start">
                                                                    <span>
                                                                        <MenuItem
                                                                            divider
                                                                            className={classes.menuListStyle}
                                                                            key={index}
                                                                            selected={index === selectedIndex}
                                                                            onClick={(event) => handleMenuItemClick(event, index)}
                                                                        >
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    </span>
                                                                </Tooltip>
                                                            ))}
                                                        </MenuList>
                                                    </Menu>
                                                </Grid>

                                                {((partChangeNotiBPModuleAccess !== undefined ? partChangeNotiBPModuleAccess.editFlag || partChangeNotiBPModuleAccess.createFlag : false) ||
                                                    (pcnModuleAccess !== undefined ? pcnModuleAccess.editFlag || pcnModuleAccess.createFlag : false))
                                                    && (
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.button.importPcnbutton.tooltip" />} placement='bottom'>
                                                                <span>
                                                                    <Button size='small' className={classes.refreshToobarStyle} color="primary" variant="outlined"
                                                                        onClick={(() => {
                                                                            setOpenFileUploadDialog(true);
                                                                        })}
                                                                    > <ImportIcon />
                                                                    </Button>
                                                                </span>
                                                            </Tooltip>
                                                        </Grid>
                                                    )}




                                                {((partChangeNotiBPModuleAccess !== undefined ? partChangeNotiBPModuleAccess.editFlag || partChangeNotiBPModuleAccess.createFlag : false) ||
                                                    (pcnModuleAccess !== undefined ? pcnModuleAccess.editFlag || pcnModuleAccess.createFlag : false))
                                                    && (
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.button.uploadfiles.tooltip" />} placement='bottom'>
                                                                <span>
                                                                    <Button size='small' className={classes.refreshToobarStyle} color="primary" variant="outlined"
                                                                        disabled={selectedRow.length > 0 ? false : true}
                                                                        onClick={(() => {
                                                                            setOpenCustomAddOrViewFiles(true);
                                                                            setRefreshAddOrView(true);

                                                                        })}
                                                                    > <DriveFolderUpload />
                                                                    </Button>
                                                                </span>
                                                            </Tooltip>
                                                        </Grid>
                                                    )}
                                                {/* <Grid> <Tooltip title={options[selectedIndex].tooltipTitle} placement='bottom'>
                                                    <span>
                                                        <Button
                                                            size="small"
                                                            variant="outlined"
                                                            ref={anchorRef}
                                                            color="primary"
                                                            disabled={tableData.length <= 0 || isExportXlRequested}
                                                            endIcon={isExportXlRequested ? <CircularProgress color="secondary" size={20} /> : null}
                                                            onClick={handleToggle}
                                                            style={{ marginRight: '5px' }}
                                                        >
                                                            <ExportIcon />
                                                            <Typography color="primary" style={{ margin: '0 5px 0 5px', fontWeight: 500, fontSize: '13px' }} >
                                                                <FormattedMessage id="export.defaulttext.button" />
                                                            </Typography>
                                                            <ArrowDropDownIcon />
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                    <Menu
                                                        id="selectReportDownload"
                                                        anchorPosition={isBusinessPartnerPortal ? { left: 150, top: 125 } : { left: 240, top: 150 }}
                                                        anchorReference="anchorPosition"
                                                        open={Boolean(anchorE2)}
                                                        elevation={5}
                                                        onClick={handleClose}
                                                    >
                                                        <MenuList>
                                                            {options.map((option, index) => (
                                                                <Tooltip title={options[index].tooltipTitle} placement="right-start">
                                                                    <span>
                                                                        <MenuItem
                                                                            divider
                                                                            className={classes.menuListStyle}
                                                                            key={index}
                                                                            selected={index === selectedIndex}
                                                                            onClick={(event) => handleMenuItemClick(event, index)}
                                                                        >
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    </span>
                                                                </Tooltip>
                                                            ))}
                                                        </MenuList>
                                                    </Menu>
                                                </Grid> */}

                                                {/* <Button
                                                    color='primary'
                                                    size="small"
                                                    variant="contained"
                                                    className={classes.editPcnToobarStyle}
                                                    onClick={() => {
                                                        if (selectedRow.length === 1) {  
                                                            const selectedRowMsgId = selectedRow[0]; 
                                                            const selectedDetails = tableData.find((row) => row.msgId === selectedRowMsgId);  
                                                            setSelectedRowDetails(selectedDetails);
                                                            setOpenPcnAuditDialog(true);
                                                        }
                                                    }}
                                                    disabled={selectedRow.length !== 1}  
                                                >
                                                    <PFAuditIcon />
                                                </Button> */}

                                                {/* <Grid>
                                                    {(<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadReport" />}
                                                        placement='bottom' className={classes.actionButton} onClick={() => {
                                                            setOpenReportDownloadDialog(true);
                                                        }}>
                                                        <span>
                                                            <Button size='small' className={classes.refreshToobarStyle} color="primary" variant="outlined"
                                                                component="span" disabled={selectedRow.length === 0 ? true : false}
                                                                onClick={() => {
                                                                    setOpenReportDownloadDialog(true);
                                                                }}>
                                                                <CustomReportIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>)}
                                                </Grid> */}
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            hideFooterSelectedRowCount={true}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length - 1, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length - 1, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length - 1, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                --params.oldIndex;
                                --params.targetIndex;
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"



                        />
                    </div>

                </Paper>
                {/* <PcnDtAuditHistory open={openPcnAuditDialog} onClose={closePcnAudit} pcnHdr={pcnToBeEdited} pcnDtl={pcnItemDetailsObject} isBusinessPartnerPortal={isBusinessPartnerPortal} isPcnAudtitHistrory={isPartchange} msgIdValue={selectedRowDetails ? selectedRowDetails.msgId : null }pcnNo = {selectedRowDetails ? selectedRowDetails.pcnNo : null}
                /> */}

            </Grid>

        </React.Fragment>

    )

}

export default PartChangeNotification;






