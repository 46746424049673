import { makeStyles } from '@mui/styles';

const screenHeightForOrders = 130;
const screenHeightForRepANdOrg = 115;
const screenHeightForErpStatus = 90;
const screenHeightForEntRoleUserSup = 110;
const screenHeightForUser = 124;
const screenHeightForRole = 125;
const bpGroupMasterPaperHeight = 320;
const emailReportPaperHeight = 335;
const screenHeightForMessageConfigDtl = 280;
const screenHeightForERPDetailDtl = -225;
const screenHeightForEmailMessageConfig = 430;
const screenHeightForPoVmi = 180;
const screenHeightForEmailReportTemplate = 220;
const screenHeightForImportTemplate = 240;
const screenHeightForReportTemplate = 110;
const screenHeightForCardPaperGorReportTemplaate = 230;
const screenHeightForCardPaperGorImportTemplaate = 250;
const screenHeightForEmailTemplatePaper = 250;
const screenHieghtForUserPaper = 367;
const createOrEditUserPaperHeight = 480;
const screenHeightForAsn = 150;
const screenHeightForAsnTableView = 390;
const screenHeightForInvoiceTableView = 390;
const screenHeightForViewASNBoxDetails = 220;
const screenHeightForPoPfTableView = 350;
const screenHeightForPoPfOuboundTableView = 410;
const screenHeightForPoOuboundTableView = 475;
const screenHeightForPOPFBp = 90;
const screenHeightForViewDataOutsource = 430;
const screenHeightForViewDlvyStats = 220;
const screenHeightForASNDropDown = 450;

const useStyles = makeStyles((theme) => ({
    //style for AppFooter.js
    rootFooter: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '90vh',
        justifyContent: 'center'
    },
    footer: {
        padding: theme.spacing(1, 1),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },

    //style for Appheader.js
    header: {
        background: theme.header.backgroundColor,
        boxShadow: theme.palette.customPrimary.main,
    },
    primaryColor: {
        background: `${theme.palette.primary.main} !important`,
    },
    menuButton: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.customText.main} !important`,
        width: 38,
        height: 38,
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.customText.main} !important`,
        },
    },
    actions: {
        marginLeft: 'auto',
        alignItems: 'center',
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    boldHeaderForAppHeader: {
        color: `${theme.palette.primary.main} !important`
    },

    //style for AppheaderNotifications.js
    headerNotifications: {
        marginRight: 23,
    },
    buttonForAppHeaderNotification: {
        color: theme.palette.customInherit.main,
    },
    badge: {
        color: theme.palette.customPrimary.main,
        backgroundColor: theme.palette.customPrimary.secondary,
    },
    notifications: {
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },

    //style for AppHeaderProfile.js
    headerProfile: {
        display: 'inline-flex',
    },
    profileButton: {
        borderRadius: 30,
        fontSize: '1.2rem',
        padding: 8,
        color: theme.palette.customSecondary.main,
    },
    helpButton: {
        borderRadius: 30,
        fontSize: '3rem',
        padding: 9,
        marginLeft: '770%'

        // color: theme.palette.customSecondary.main,
    },
    profileAvatar: {
        width: 30,
        height: 30,
        marginRight: 10,
        color: theme.palette.customSecondary.main,
    },
    profileName: {
        fontWeight: 500,
        marginRight: 5,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        color: theme.palette.customPrimary.secondary,
    },
    profileMenu: {
        marginLeft: '-16px',
        backgroundColor: theme.palette.customSecondary.main,
        color: theme.palette.customPrimary.main,
    },
    profileMenuItemIcon: {
        color: theme.palette.customPrimary.main,
    },

    //style for AppSidebar.js
    sidebar: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
    },
    sidebarBackground: {
        backgroundColor: theme.sidebar.backgroundColor,
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 0,
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat',
        backgroundSize: 'cover',
    },
    sidebarBody: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    sidebarHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        padding: '20px 8px',
        marginBottom: '10px',
        ...theme.mixins.toolbar,
    },
    sidebarTitleLink: {
        textDecoration: 'none',
        color: 'inherit',
        display: 'flex',
    },
    logo: {
        color: theme.palette.primary,
        zIndex: 10,
        width: '40%',
        height: '80%',
        marginLeft: 65
    },
    title: props => ({
        position: 'relative',
        overflow: 'visible',
        marginLeft: '5px',
        display: props.isCollapsed ? 'none' : 'block',
        fontSize: '1.1rem',
        letterSpacing: '.015em',
        // fontWeight: 'bold',
    }),
    name: {},
    tagline: {
        fontSize: 15,
        fontWeight: 'bold',
        position: 'absolute',
        top: '100%',
        margin: '-2px -35px 0 0',
        background: theme.palette.customPrimary.main,
        color: '#000',
        borderRadius: 2,
        padding: '1px 3px',
        right: 65,
    },
    logocollpase: {
        marginLeft: '-60%',
        marginTop: '-150%'
    },
    logosidebar: {
        width: '300%',
        marginTop: '400%'
    },

    // style for NavItem.js
    nested: {
        paddingLeft: theme.spacing(10),
    },
    navItemWrapper: {
        position: 'relative',
    },
    navItemWrapperActive: {
        background: 'rgba(0, 0, 0, 0.08)',
    },
    navItem: {
        position: 'relative',
        transition: 'background .23s ease',
        color: theme.palette.customPrimary.main,
        '&.active:not(.open)': {
            color: theme.palette.customPrimary.main,
            borderRight: '4px solid #ffff',
        },
        '&.open': {
            color: theme.palette.customPrimary.main,
        },
        lineHeight: '22px',
    },
    navItemPoper: {
        width: theme.sidebar.width,
        color: theme.sidebar.color,
        background: theme.sidebar.backgroundColor,
        fontSize: '14px'
    },
    navItemChildren: {
        transition: 'background .23s ease',
    },
    navItemCollapsed: {
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap',
        width: theme.sidebar.widthCollapsed,
        '& $iconToggle': {
            position: 'absolute',
            fontSize: '0.9vw',
            top: '50%',
            marginTop: '-0.5em',
            transform: 'rotate(90deg) !important',
            right: '3px !important',
        },
        '&.active': {
            color: theme.palette.customPrimary.main
        },
    },

    navItemIcon: theme.navItemIcon,
    iconToggle: {},
    iconToggleInactive: {
        opacity: 0.35,
        color: theme.palette.customPrimary.main,
    },
    iconSpacer: {
        fontSize: '3.9vw',
        marginLeft: 6,
    },

    //style for NavItemComponent.js
    navItemCollapsedWrapper: {
        width: theme.sidebar.widthCollapsed,
    },

    //style for Sidebarnav.js
    navList: {
        width: theme.sidebar.width,
        fontSize: '1.1em',
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: '0.00938em',
    },
    navListHeader: {
        textAlign: 'center',
        color: 'rgba(255,255,255,0.5)',
    },
    iconFeatures: {
        color: '#95de3c',
    },
    iconDocs: {
        color: '#f8cda9',
    },
    iconSupporters: {
        color: '#e3b546',
    },
    iconDiscuss: {
        color: '#ccc',
    },

    //style for AuditTimeline.js
    paperForAuditTimeline: {
        padding: '3px 10px',
    },

    //style for commonBackdrop.js    
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

    //style for Combos 
    error: {
        color: `${theme.palette.error.main} !important`,
    },

    //style for Confirmation Dialog
    title: {
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.customText.main
    },
    dialogAction: {
        padding: '1% 5%'

    },
    buttonSpacingForDialog: {
        margin: theme.spacing(0.5),
    },

    //style for CustomFilters.js
    textField: {
        marginTop: '0 !important',
        marginBottom: '0 !important'
    },

    //style for customViewColumns.js
    paperForCustomToolbar: {
        padding: theme.spacing(1),
    },

    //style for FileUploadDialog.js
    dialogActionForFileUploading: {
        padding: '1% 5%'
    },
    buttonSpacing: {
        margin: theme.spacing(0.5),
    },
    input: {
        display: 'none',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },

    // style for tabspurchaseOrder.js
    tabContainerForPurchaseOrder: {
        minHeight: 40,
        height: 'auto',
        width: '100%',
        marginTop: 5
    },
    rootForTabs: {
        flexGrow: 1,
        padding: '0 0.5%',
    },
    tabText: {
        color: '#2f303a',
        fontWeight: 600
    },

    asterisk: {
        color: "red"
    },

    //style for Unauthorized
    rootForUnauthorized: {
        flexGrow: 1,
        marginTop: '10% !important'
    },
    waringIcon: {
        color: 'red',
        width: '100px',
        height: '100px'
    },

    // style for AuditPodelConfDt.js
    rootForAuditPoDelCfmDt: {
        flexGrow: 1,
        padding: '0 0.5%',
    },
    restoreButton: {
        backgroundColor: theme.palette.warning.main
    },
    secondaryTail: {
        backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    primaryTail: {
        backgroundColor: `${theme.palette.primary.main} !important`,
    },
    exportButton: {
        color: theme.palette.customText.main,
        fontWeight: 600
    },

    //Style for EditPurchaseOrder.js
    rootForEditPurchaseOrder: {
        flexGrow: 1,
        padding: '0 0.6%',
    },
    stateButton: {
        margin: ' 1px 3px',
        color: theme.palette.customPrimary.secondary,
    },
    stateButtonForApprove: {
        margin: ' 1px 3px',
        color: '#fff',
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            color: `${theme.palette.secondary.secondary} !important`,
            backgroundColor: theme.palette.success.main,
        },
    },
    stateButtonForReject: {
        margin: ' 1px 3px',
        color: '#fff',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            color: `${theme.palette.secondary.secondary} !important`,
            backgroundColor: theme.palette.error.main,
        },
    },
    stateButtonReferesh: {
        margin: ' 1px 3px',
    },
    stateButtonClear: {
        margin: ' 1px 3px',
    },
    paperForEditPurchaseOrder: {
        height: 'auto',
        width: '100%',
        marginBottom: '0.2%'
    },
    paperForEditASN: {
        height: '91%',
        width: '100%',
    },
    paperForEditAdvanceShipmentNote: {
        height: 'auto',
        width: '100%',
        marginBottom: '5px'
    },
    formForPurchaseOrder: {
        width: '100%', // Fix IE 11 issue.
    },
    refreshButton: {
        backgroundColor: theme.palette.warning.main
    },
    textFieldForPO: {
        marginTop: '0 !important',
        marginBottom: '0 !important'
    },
    chipForPO: {
        margin: theme.spacing(0.5),
        fontWeight: 'bolder !important',
        flexGrow: 1,
        border: '2px solid #000'
    },
    chipTheme: {
        margin: theme.spacing(0.5),
        fontWeight: 'bolder !important',
        flexGrow: 1,
        color: theme.palette.customPrimary.secondary,
        border: '2px solid #4782da'
    },
    chipThemeForPoCfmDailog: {
        margin: theme.spacing(0.5),
        fontWeight: 'bolder !important',
        color: theme.palette.customPrimary.secondary,
        border: '2px solid #4782da'
    },
    customTableStyle: theme.tableStyle,
    colorError: {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.main
    },
    details: {
        alignItems: 'center',
        height: 'auto'
    },
    detailsForSupplier: {
        marginBottom: '-65px !important',
        height: 'auto'
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        height: '130px',
        padding: theme.spacing(1, 1),
    },
    helperForPO: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        height: 'auto',
    },
    helperForDetail: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        height: '170px',
        padding: theme.spacing(1, 1),
    },
    helperForAccordian: {
        borderLeft: '3px solid #4782da',
        height: 'auto',
    },
    helperForASNAccordian: {
        borderLeft: '3px solid #4782da',
        height: 'auto',
        marginTop: '-30px'
    },
    helperForInvoiceAccordian: {
        borderLeft: '3px solid #4782da',
        height: 'auto',
        marginTop: '-16px'
    },
    detailsForASNShip: {
        height: 'auto',
        marginTop: '-35px'
    },
    detailsForASNBill: {
        height: 'auto',
        marginTop: '-7px'
    },
    detailsForInvoiceShip: {
        height: 'auto',
        marginTop: '-10px'
    },
    detailsForInvoiceSupplier: {
        height: 'auto',
        marginTop: '-10px'
    },
    helperForPos: {
        borderLeft: '3px solid #4782da',
        height: 'auto',
        padding: theme.spacing(1, 1),
    },
    helperforPF: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 1),
        marginLeft: theme.spacing(40)
    },
    borderSuccess: {
        borderLeft: `4px solid ${theme.palette.success.main}`,
        padding: '2px 5px',
    },
    borderWarning: {
        borderLeft: `4px solid ${theme.palette.warning.main}`,
        padding: '2px 5px',
    },
    borderNew: {
        borderLeft: `4px solid #4782da`,
        padding: '2px 5px',
    },
    borderError: {
        borderLeft: `4px solid`,
        padding: '2px 5px',
        color: 'red'
    },
    borderColor: {
        borderLeft: '4px solid',
        padding: '2px 5px',
        color: 'blue'
    },
    boldHeaderForHeading: {
        fontWeight: '700',
        fontSize: '0.8rem'
    },
    boldHeader: {
        // fontWeight: 'bold'
        fontSize: '12px'
    },
    boldHeaderWithBorderForAmended: {
        color: '#ffd600',
        border: '1px #ffd600 solid',
        borderRadius: '25px',
        padding: '5px 5px 5px 30px',
        fontSize: '12px',
        flexGrow: 1
    },
    boldHeaderWithBorderForCancelled: {
        color: '#d50000',
        border: '1px #d50000 solid',
        borderRadius: '25px',
        padding: '5px 5px 5px 30px',
        fontSize: '12px',
        flexGrow: 1
    },
    boldHeaderWithBorderForOriginal: {
        color: '#2782da',
        border: '1px #2782da solid',
        borderRadius: '25px',
        padding: '5px 5px 5px 30px',
        fontSize: '12px',
        flexGrow: 1
    },
    boldHeaderForNoRows: {
        fontWeight: 'bold',
        fontSize: '12px'
    },
    boldHeaderForASN: {
        fontWeight: '500',
        fontSize: '13px'
    },
    pendingStatus: {
        color: '#ffd600',
        border: '1px #ffd600 solid',
        borderRadius: '25px',
        padding: '5px 10px 5px 10px',
        fontSize: '12px',
        flexGrow: 1
        
    },


    incompleteStatus: {
        color: '#d50000',
        border: '1px #d50000 solid',
        borderRadius: '25px',
        padding: '5px 18px 5px 18px',
        fontSize: '12px',
        flexGrow: 1
    },
    completeStatus: {
        color: '#64dd17',
        border: '1px #64dd17 solid',
        borderRadius: '25px',
        padding: '5px 18px 5px 18px',
        fontSize: '12px',
        flexGrow: 1
    },
    newStatus: {
        color: '#2782da',
        border: '1px #2782da solid',
        borderRadius: '25px',
        padding: '5px 10px 5px 10px',
        fontSize: '12px',
        flexGrow: 1
    },
    primaryColor: {
        color: theme.palette.customPrimary.secondary,
    },
    secondaryColor: {
        color: theme.palette.secondary.main
    },
    yellowBox: {
        backgroundColor: '#ffd600',
        width: '20px',
        height: '10px',
        marginRight: '10px',
    },
    redBox: {
        backgroundColor: '#d50000',
        width: '20px',
        height: '10px',
        marginRight: '10px',
    },
    greenBox: {
        backgroundColor: '#64dd17',
        width: '20px',
        height: '10px',
        marginRight: '10px',
    },
    blueBox: {
        backgroundColor: '#4782da',
        width: '20px',
        height: '10px',
        marginRight: '10px',
    },
    //style for PoConfirmdateDialog
    rootForPoConf: {
        flexGrow: 1,
    },
    dialogActionForPOConfirmDateDialog: {
        padding: '1% 5%'
    },
    buttonSpacingForPoConf: {
        margin: theme.spacing(0.5),
    },
    table: {
        width: '100%',
        border: `${theme.palette.customPrimary.secondary} solid !important`,
        textAlign: 'left',
        '& tr:nth-child(even)': {
            backgroundColor: '#f5f5f5'
        },
        '& TableHead th ': {
            textAlign: 'center',
            backgroundColor: `${theme.palette.primary.main} !important`,
            filter: 'grayscale(50%)',
            // height: '425x',
            padding: '0',
            position: 'sticky'
        },
        height: '1000%'
    },
    tableHead: {
        textAlign: 'center',
        fontWeight: 'bold !important',
        backgroundColor: `${theme.palette.customPrimary.secondary} !important`,
        color: `${theme.palette.customText.main} !important`,
        maxHeight: '25px !important',
        lineHeight: '10px',
        padding: '10px 0',
        position: 'sticky'
    },
    dummyBottomRowHeight: {
        marginTop: '-21px !important',
        [theme.breakpoints.down('md')]: {
            // marginTop: '10px !important',
        },
    },
    dummyBottomRowHeightDt: {
        marginTop: '-21px !important',
        [theme.breakpoints.down('md')]: {
            marginTop: '-30px !important',
        },
    },
    dummyTopRowHeight: {
        marginTop: '21px !important'
    },
    tableHeaderPadding: {
        fontWeight: 'bold !important',
        padding: '10px 0 !important',
        color: `${theme.palette.customText.main} !important`,
    },
    tableHeaderPaddingAction: {
        fontWeight: 'bold !important',
        padding: '2px 0 !important',
        color: `${theme.palette.customText.main} !important`,
    },
    textFieldForPoCnfDialog: {
        marginTop: '0 !important',
        marginBottom: '0 !important'
    },

    //style for order.js
    rootForOrder: {
        flexGrow: 1,
    },
    actionButton: {
        // margin: '2px 2px'
    },
    splitButton: {
        backgroundColor: `${theme.palette.customText.main} !important`,
        minWidth: 'auto !important',
        marginTop: '5px !important',
    },
    menuListStyle: {
        color: `${theme.palette.primary.main} !important`,
        fontWeight: 500,
        fontSize: '14px',
        '&:hover': {
            color: `${theme.palette.secondary.main} !important`,
            borderRight: '4px solid #4782da !important',
        },
    },
    customeToolbar: {
        color: `${theme.palette.customPrimary.secondary} !important`,
    },
    gridHeightAndWidth: {
        height: `calc(100vh - ${screenHeightForOrders}px) !important`,
        width: '100% !important',
    },
    gridHeightAndWidthForBP: {
        height: `calc(100vh - ${screenHeightForPOPFBp}px) !important`,
        width: '100% !important',
    },
    gridHeightAndWidthForUser: {
        height: `calc(100vh - ${screenHeightForUser}px) !important`,
        width: '100% !important',
    },
    gridHeightAndWidthForRole: {
        height: `calc(100vh - ${screenHeightForRole}px) !important`,
        width: '100% !important',
    },
    gridHeightAndWidthForASN: {
        height: `calc(100vh - ${screenHeightForAsn}px) !important`,
        width: '100% !important',
    },
    gridHeightAndWidthForASNTableView: {
        height: `calc(100vh - ${screenHeightForAsnTableView}px) !important`,
        width: '100% !important',
    },
    gridHeightAndWidthForInvoiceTableView: {
        height: `calc(100vh - ${screenHeightForInvoiceTableView}px) !important`,
        width: '100% !important',
    },
    gridHeightAndWidthForPOPFTableView: {
        height: `calc(100vh - ${screenHeightForPoPfTableView}px) !important`,
        width: '100% !important',
    },
    gridHeightAndWidthForPOPFOutboundTableView: {
        height: `calc(100vh - ${screenHeightForPoPfOuboundTableView}px) !important`,
        width: '100% !important',
    },
    gridHeightAndWidthForPOOutboundTableView: {
        height: `calc(100vh - ${screenHeightForPoOuboundTableView}px) !important`,
        width: '100% !important',
    },
    gridHeightAndWidthForMsgCreateHeader: {
        height: 'auto',
        width: '100%',
        paddingTop: '1%'
    },
    gridHeightAndWidthForMessageConfigDtl: {
        height: `calc(100vh - ${screenHeightForMessageConfigDtl}px) !important`,
        width: '100% !important',
    },
    gridHeightAndWidthForERPDtl: {
        height: `calc(100vh + ${screenHeightForERPDetailDtl}px) !important`,
        width: '100% !important',
        marginTop: 5
    },
    //style for Report.js
    rootForReport: {
        flexGrow: 1,
        padding: '0 0.5%',
    },
    topGridForReport: {
        padding: '0.3% 0',
    },
    gridHeightAndWidthForRepAndOrg: {
        height: `calc(100vh - ${screenHeightForRepANdOrg}px) !important`,
        width: '100% !important',
    },

    gridHeightAndWidthForErpStatus: {
        height: `calc(100vh - ${screenHeightForErpStatus}px) !important`,
        width: '100% !important',
    },

    //style for Supplier.js
    rootForSupplier: {
        flexGrow: 1,
        padding: '0 0.5%',
    },
    gridHeightAndWidthForEntRoleUserSup: {
        height: `calc(100vh - ${screenHeightForEntRoleUserSup}px) !important`,
        width: '100% !important',
    },

    // style for Branch.js
    rootForBranch: {
        flexGrow: 1,
        padding: '0 0.5%',
    },

    gridHeightAndWidthForErpInterfaceDtl: {
        height: `calc(100vh + ${screenHeightForMessageConfigDtl}px) !important`,
        width: '100% ',
    },

    gridHeightAndWidthForTransmissionConfigPaper: {
        // height: `calc(100vh + ${screenHrightForPaperTransmissionConfig}px) !important`,
        height: 'auto',
        paddingTop: '10px',
        paddingBottom: '7.9%',
        marginTop: '5px',
    },

    //style for ErpInterface.js
    rootForErpInterface: {
        flexGrow: 1,
        padding: '0 0.5%',
    },

    //style for Organization.js
    rootForOrganixation: {
        flexGrow: 1,
        padding: '0 0.5%',
    },

    //style for UserManagement.js
    rootForUserManagement: {
        flexGrow: 1,
        padding: '0 0.5%',
    },
    createButton: {
        backgroundColor: theme.palette.customPrimary.secondary
    },

    //style for createOredituser.js
    rootCreateOrEditUser: {
        flexGrow: 1,
        padding: '0 0.5%',
    },
    stateButtonBack: {
        margin: ' 1px 3px',
        backgroundColor: theme.palette.customPrimary.secondary
    },
    stateButton: {
        margin: ' 1px 3px'
    },
    stateButtonSave: {
        margin: ' 1px 3px',
        backgroundColor: theme.palette.customPrimary.secondary
    },
    paperForCreateOrEditUser: {
        marginTop: '5px',
        height: 'auto',
        width: '100%'
    },
    formStyleForReportTemplate: {
        height: 'auto',
        width: '100%'
    },
    formForCreateOrEditUser: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        padding: theme.spacing(1)
    },
    tabContainerForCreateOrEditUser: {
        height: `calc(100vh - ${screenHieghtForUserPaper}px) !important`,
        maxHeight: `calc(100vh - ${screenHieghtForUserPaper}px) !important`,
        width: '100%',
        marginTop: 5,
        overflow: 'auto'
    },

    listforCreateOrEditUser: {
        maxHeight: `calc(100vh - ${createOrEditUserPaperHeight}px) !important`,
        height: `calc(100vh - ${createOrEditUserPaperHeight}px) !important`,
        overflow: 'auto',
        width: 700,
    },

    gridSpacing: {
        margin: '0 !important'
    },
    gridSpacingForDailog: {
        padding: '10px !important',
    },

    //style for role.js
    rootForRole: {
        flexGrow: 1,
        padding: '0 0.5%',
    },

    //style for CreateOrEditRole.js
    rootForCreateOrEditRole: {
        flexGrow: 1,
        padding: '0 0.5%',
    },
    topGrid: {
        padding: '0.3% 0',
    },
    stateButtonForBack: {
        margin: ' 1px 3px',
        backgroundColor: theme.palette.customPrimary.secondary
    },
    stateButtonToRestore: {
        margin: ' 1px 3px'
    },
    stateButtonForSave: {
        margin: ' 1px 3px',
        backgroundColor: theme.palette.customPrimary.secondary
    },
    paperCreateOrEditRole: {
        height: 'auto',
        width: '100%'
    },
    formForCreateOrEdirRole: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        padding: theme.spacing(1)
    },
    checkboxSize: {
        width: "18px",
        height: "18px",
        color: theme.palette.secondary.contrastText.main
    },
    tabContainer: {
        minHeight: 470,
        height: 'auto',
        width: '100%',
        marginTop: 5
    },

    tabContainerForCommonDialog: {
        minHeight: 'auto',
        height: 'auto',
        width: '100%',
        marginTop: 5
    },
    listHeader: {
        backgroundColor: '#f5f5f5',
    },
    moduleAccessSecondaryIcon: {
        margin: '0 17px'
    },

    //AppHeader GridStyling
    gridStyling: {
        [theme.breakpoints.down('lg')]: {
            marginLeft: '-5%'
        },
        [theme.breakpoints.down('xl')]: {
            marginLeft: '-3%'
        },
    },

    uploadTextField: {
        width: "100%",
        [theme.breakpoints.only('xs')]: {
            width: "87%"
        },
    },

    //style for viewSupplier.js
    paperForviewSupplier: {
        height: 'auto',
        width: '100%'
    },

    //style for commonDialogComponent.js
    titleCommonDialogComponent: {
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.main,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider} !important `,
    },

    tabsforERPInterface: {
        borderRight: `1px solid ${theme.palette.divider} !important `,
    },
    tabtextstyling: {
        fontWeight: `600 !important`,
        marginTop: '-1.2%',
        marginBottom: '-1%',
        height: 'auto'
    },
    tabtextstylingForMsgConfig: {
        fontWeight: `600 !important`,
    },
    commondialogButtonStyle: {
        margin: ' 1px 3px',
        color: theme.palette.customPrimary.secondary,
    },
    cancelbuttonSpacing: {
        margin: theme.spacing(0.5),
    },
    commondialogstateButtonForSave: {
        margin: ' 1px 3px',
        backgroundColor: theme.palette.customPrimary.secondary,
        [theme.breakpoints.only('xs')]: {
            marginLeft: "100px !important"
        },
    },
    updateButtonForXs: {
        marginRight: "20px"
    },
    tabColor: {
        color: `${theme.palette.primary.main} !important`,
    },
    commonDialogButtonSpacingForConfigDtl: {
        padding: "0% 4% 0% 4%"
    },

    commonDialogButtonSpacing: {
        padding: "0% 1% 0% 2%"
    },


    //style for CreateOrEditBpGroup.js
    transferListRoot: {
        marginLeft: 'auto !important',
        width: '100% !important'
    },

    paperStyleForReportTemplate: {
        // height: '85vh', 
        width: '100%'
    },

    paperStyleForEmailTemplateEditor: {
        width: '100% !important',
        height: `calc(100vh - ${screenHeightForEmailTemplatePaper}px) !important`,
        marginTop: '5px !important',
        minHeight: `calc(100vh - ${screenHeightForEmailTemplatePaper}px) !important`,
        overflow: 'auto',
    },

    transferListCard: {
        width: '100% !important',
        height: '102% !important'
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    cardHeaderForReportTemplate: {
        padding: `${theme.spacing(1, 2)} !important`,
        backgroundColor: '#4782da',
        color: '#fff',
        fontSize: '14px !important'
    },
    transferListCardForReportTemplate: {
        width: '100% !important',
        height: `calc(100vh - ${screenHeightForCardPaperGorReportTemplaate}px) !important`,
        marginTop: '10px !important',
    },
    cardForImportTemplate: {
        width: '100% !important',
        height: `calc(100vh - ${screenHeightForCardPaperGorImportTemplaate}px) !important`,
        marginTop: '10px !important',
    },
    list: {
        width: '100% !important',
        minHeight: `calc(100vh - ${bpGroupMasterPaperHeight}px) !important`,
        height: `calc(100vh - ${bpGroupMasterPaperHeight}px) !important`,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    listForReportTemplate: {
        width: '100% !important',
        minHeight: `calc(100vh - ${emailReportPaperHeight}px) !important`,
        height: `calc(100vh - ${emailReportPaperHeight}px) !important`,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },

    button: {
        margin: theme.spacing(0.5, 0),
    },
    paperForBpGroupMapping: {
        marginTop: theme.spacing(1),
        minHeight: 500,
        width: '100%',
    },
    selected: {
        '&.Mui-selected': {
            backgroundColor: theme.palette.success.main,
        },
        "&:hover": {
            backgroundColor: ` ${theme.palette.success.main}!important`,
        }
    },
    //style common for all forms
    textfieldSpacing: {
        padding: "1% 2.6% 1% 0.6%"
    },

    dialogHeight: {
        height: 600
    },

    //createOrEditEmailTemplate
    createOrEditEmailTemplateTextfieldPaper: {
        height: 'auto !important',
        width: '100% !important',
        paddingTop: '1% !important'
    },

    //emailMessageConfigDialog
    textfieldSpacingEmailMessageConfig: {
        padding: '2% 2% 2% 2%'
    },
    gridHeightAndWidthForEmailMessageConfig: {
        height: `calc(100vh - ${screenHeightForEmailMessageConfig}px) !important`,
        width: '100% !important',
    },

    gridHeightAndWidthForViewBoxDetails: {
        height: `calc(100vh - ${screenHeightForViewASNBoxDetails}px) !important`,
        width: '100% !important',
    },

    gridHeightAndWidthForViewDataSource: {
        height: `calc(100vh - ${screenHeightForViewDataOutsource}px) !important`,
        width: '100% !important',
    },

    gridHeightAndWidthForViewDlvyStatsData: {
        height: `calc(100vh - ${screenHeightForViewDlvyStats}px) !important`,
        width: '100% !important',
    },

    gridHeightAndWidthForPoVmi: {
        height: `calc(100vh - ${screenHeightForPoVmi}px) !important`,
        width: '100% !important',
    },

    gridHeightAndWidthForEmailReportTemplate: {
        height: `calc(100vh - ${screenHeightForEmailReportTemplate}px) !important`,
        width: '100% !important',
    },

    gridHeightAndWidthForImportTemplate: {
        height: `calc(100vh - ${screenHeightForImportTemplate}px) !important`,
        width: '100% !important',
    },

    gridHeightAndWidthForReportTemplate: {
        height: `calc(100vh - ${screenHeightForReportTemplate}px) !important`,
        width: '100% !important',
    },

    tabIconStyling: {
        margin: theme.spacing(0.5, 1),
        color: `${theme.palette.error.main} !important`,
    },

    //   ExcelIcon style
    ExcelIcon: {
        height: "-23%",
    },

    ExcelIconPO: {
        color: `${theme.palette.primary.main} !important`,
        height: '20% !important',
        marginTop: '50% !important',
        marginBottom: '2% !important',
        padding: '1% 0% 0% 0% !important'
    },
    ExcelIconPOView: {
        color: `${theme.palette.primary.main} !important`,
        height: '20% !important',
        marginTop: '50% !important',
        marginBottom: '2% !important',
        marginRight: '55% !important',
        padding: '1% 0% 0% 0% !important'
    },
    ExcelIconPOOrder: {
        color: `${theme.palette.primary.main} !important`,
        height: '15% !important',
        marginTop: '2% !important',
        marginBottom: '-53% !important',
        marginLeft: '-55% !important',
        marginRight: '-55% !important',
        padding: '5% -10% -10% -10% !important'
    },
    ExcelIconPOOrderButtonStyling: {
        padding: '3px 1px 4px 0px !important',
    },
    ExcelIconPOOrderDtls: {
        color: `${theme.palette.primary.main} !important`,
        height: '15% !important',
        marginTop: '2% !important',
        marginBottom: '-53% !important',
        // marginLeft: '-55% !important',
        // marginRight: '-55% !important',
        // padding: '5% -10% -10% -10% !important'
    },
    stateButtonForDtls: {
        paddingRight: '-100px',
        margin: '1px 5px 0px 5px'
    },

    LastActiveFrom3Months: {
        color: '#ff8300'
    },
    dialogPOScreen: {
        color: '#fff',
        fontSize: '14px',
        // marginTop: '-22px'
    },
    toobarStyle: {
        maxWidth: '30px',
        maxHeight: '30px',
        minWidth: '40px',
        minHeight: '30px',
        paddingRight: '2px',
        marginRight: '4px',
    },
    refreshToobarStyle: {
        maxWidth: '30px',
        maxHeight: '30px',
        minWidth: '40px',
        minHeight: '30px',
        marginRight: '4px'
    },
    editPoToobarStyle: {
        maxWidth: '45px',
        maxHeight: '35px',
        minWidth: '45px',
        minHeight: '35px',
        marginRight: '5px'
    },
    transferListButtonStyle: {
        maxWidth: '45px',
        maxHeight: '35px',
        minWidth: '45px',
        minHeight: '35px',
        marginBottom: '5px'
    },
    gridHeightAndWidthForASNDropDown: {
        height: `calc(100vh - ${screenHeightForASNDropDown}px) !important`,
        width: '89% !important',
    },
    pcndetails: {
        width: '100 %',
        height: 'auto'
    },
    //Audit button for PCN
    editPcnToobarStyle: {
        maxWidth: '40px',
        maxHeight: '30px',
        minWidth: '40px',
        minHeight: '30px',
        marginRight: '5px'
    },

 //PCNAutit Date columns background Color 
    // timelineItemNotSent: {
    //     backgroundColor: '#d50000', 
    //   },
    //   timelineItemPending: {
    //     backgroundColor: '#ffd600', 
    //   },
    //   timelineItemSuccessfullySent: {
    //     backgroundColor:'#64dd17', 
    //   },
    //   timelineItemFailedToSend: {
    //     backgroundColor:'#4782da',
    //   },


}));

export default useStyles;