import React, { useEffect, useState } from 'react';
import _ from "lodash/fp";
import { FormControl, FormHelperText, TextField, Grid, CssBaseline } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useStyles from '../../../common/CommonStyle/CommonStyle';
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';


function ContatDetailsForm(props) {
    const classes = useStyles();
    const { addressmode, setHasErrors, control, errors } = props
    const commonPatterns = GlobalEdiApiConstants();

    const [organizationReadOnlyFields, setOrganizationReadOnlyFields] = useState({
        addressLine1: false,
        addressLine2: false,
        addressLine3: false,
        city: false,
        country: false,
        state: false,
        countryCode: false,
        postalCode: false,
        faxNo: false,
        telNo: false,
    });

    const pageLabelsConstantsAndMessages = {
        label: {
            addressLine1: 'Address Line1',
            addressLine2: 'Address Line2',
            addressLine3: 'Address Line3',
            city: 'City',
            country: 'Country',
            state: 'State',
            countryCode: 'Country Code',
            postalCode: 'Postal Code',
            faxNo: 'Fax No',
            telNo: 'Telephone No',
        },
        name: {
            addressLine1: 'addressLine1',
            addressLine2: 'addressLine2',
            addressLine3: 'addressLine3',
            city: 'city',
            country: 'country',
            state: 'state',
            countryCode: 'countryCode',
            postalCode: 'postalCode',
            faxNo: 'faxNo',
            telNo: 'telNo',
        }
    }

    useEffect(() => {
        if (addressmode === 'view') {
            setOrganizationReadOnlyFields({
                ...organizationReadOnlyFields, addressLine1: true,
                addressLine2: true,
                addressLine3: true,
                city: true,
                country: true,
                state: true,
                countryCode: true,
                postalCode: true,
                faxNo: true,
                telNo: true,
            })

        }
    }, [addressmode]);

    useEffect(() => {
        setHasErrors(errors)
    }, [errors])


    return (

        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.addressLine1}
                                    rules={addressmode === 'view' ? { required: false } : {
                                        required: true,
                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphaNumericAndSpecialCharAndSpace,
                                        maxLength: 1000
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required={true}
                                            variant="outlined"
                                            margin="normal"
                                            id="addressLine1"
                                            size='small'
                                            label={<FormattedMessage id="columnname.AddressLine1.label" defaultMessage="AddressLine1" />}
                                            {...field}
                                            error={errors.addressLine1}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.addressLine1,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={addressmode === 'view' ? true : false}
                                        />
                                    )}
                                />


                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.addressLine1}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.AddressLine1.label" defaultMessage="AddressLine1" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.addressLine1}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>   {<FormattedMessage id="columnname.AddressLine1.label" defaultMessage="AddressLine1" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNumericAndSpecialCharAndSpace}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.addressLine1}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.AddressLine1.label" defaultMessage="AddressLine1" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.addressLine2}
                                    rules={{
                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphaNumericAndSpecialCharAndSpace,
                                        maxLength: 1000
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}

                                            variant="outlined"
                                            margin="normal"
                                            id="addressLine2"
                                            size='small'
                                            label={<FormattedMessage id="columnname.AddressLine2.label" defaultMessage="AddressLine2" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.addressLine2,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={addressmode === 'view' ? true : false}
                                        />
                                    )}
                                />


                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.addressLine2}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.AddressLine2.label" defaultMessage="AddressLine2" /> {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.addressLine2}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>   {<FormattedMessage id="columnname.AddressLine2.label" defaultMessage="AddressLine2" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNumericAndSpecialCharAndSpace}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.addressLine2}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.AddressLine2.label" defaultMessage="AddressLine2" /> {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.addressLine3}
                                    rules={{
                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphaNumericAndSpecialCharAndSpace,
                                        maxLength: 1000
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="addressLine3"
                                            size='small'
                                            label={<FormattedMessage id="columnname.AddressLine3.label" defaultMessage="AddressLine3" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.addressLine3,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={addressmode === 'view' ? true : false}
                                        />
                                    )}
                                />



                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.addressLine3}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.AddressLine3.label" defaultMessage="AddressLine3" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.addressLine3}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>   {<FormattedMessage id="columnname.AddressLine3.label" defaultMessage="AddressLine3" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNumericAndSpecialCharAndSpace}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.addressLine3}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.AddressLine3.label" defaultMessage="AddressLine3" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.city}
                                    rules={addressmode === 'view' ? { required: false } : {
                                        required: true,
                                        maxLength: 50,
                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphabetsOnly
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required
                                            variant="outlined"
                                            margin="normal"
                                            id="city"
                                            size='small'
                                            label={<FormattedMessage id="columnname.City.label" defaultMessage="City" />}
                                            {...field}
                                            error={errors.city}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.city,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={addressmode === 'view' ? true : false}
                                        />
                                    )}
                                />


                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.city}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.City.label" defaultMessage="City" />}  {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.city}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.City.label" defaultMessage="City" />} {commonPatterns.commonErrorsForTextFields.errorForAplhabetsOnly}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.city}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.City.label" defaultMessage="City" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" defaultMessage="Org Code cannot exceed more than 50 chars" />}
                                        </FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                    </Grid>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.state}
                                    rules={{
                                        required: true,
                                        maxLength: 50,
                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphabetsOnly
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required={true}
                                            variant="outlined"
                                            margin="normal"
                                            id="state"
                                            size='small'
                                            label={<FormattedMessage id="columnname.State.label" defaultMessage="State" />}
                                            {...field}
                                            error={errors.state}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.state,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={addressmode === 'view' ? true : false}
                                        />
                                    )}
                                />


                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.state}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.State.label" defaultMessage="State" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.state}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}> {<FormattedMessage id="columnname.State.label" defaultMessage="State" />} {commonPatterns.commonErrorsForTextFields.errorForAplhabetsOnly}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.state}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.State.label" defaultMessage="State" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" defaultMessage="Org Code cannot exceed more than 50 chars" />}
                                        </FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.country}
                                    rules={addressmode === 'view' ? { required: false } : {
                                        required: true,
                                        maxLength: 50,
                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphabetsOnly
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required={true}
                                            variant="outlined"
                                            margin="normal"
                                            id="country"
                                            size='small'
                                            label={<FormattedMessage id="columnname.Country.label" defaultMessage="Country" />}
                                            {...field}
                                            error={errors.country}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.country,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={addressmode === 'view' ? true : false}
                                        />
                                    )}
                                />


                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.country}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.Country.label" defaultMessage="Country" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.country}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}> {<FormattedMessage id="columnname.Country.label" defaultMessage="Country" />} {commonPatterns.commonErrorsForTextFields.errorForAplhabetsOnly}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.country}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.Country.label" defaultMessage="Country" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" defaultMessage="Org Code cannot exceed more than 50 chars" />}
                                        </FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.countryCode}
                                    rules={addressmode === 'view' ? { required: false } : {
                                        required: true,
                                        maxLength: 3,
                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphabetsOnly
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required={true}
                                            variant="outlined"
                                            margin="normal"
                                            id="countryCode"
                                            size='small'
                                            label={<FormattedMessage id="columnname.CountryCode.label" defaultMessage="CountryCode" />}
                                            {...field}
                                            error={errors.countryCode}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.countryCode,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={addressmode === 'view' ? true : false}
                                        />
                                    )}
                                />


                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.countryCode}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.CountryCode.label" defaultMessage="CountryCode" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.countryCode}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}> {<FormattedMessage id="columnname.CountryCode.label" defaultMessage="CountryCode" />} {commonPatterns.commonErrorsForTextFields.errorForAplhabetsOnly}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.countryCode}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.CountryCode.label" defaultMessage="CountryCode" />} {<FormattedMessage id="textfield.error.countrycode.maxlength" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.postalCode}
                                    rules={addressmode === 'view' ? { required: false } : {
                                        required: true,
                                        maxLength: 50,
                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphaNumeric
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required={true}
                                            variant="outlined"
                                            margin="normal"
                                            id="postalCode"
                                            size='small'
                                            label={<FormattedMessage id="columnname.PostalCode.label" defaultMessage="PostalCode" />}
                                            {...field}
                                            error={errors.postalCode}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.postalCode,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={addressmode === 'view' ? true : false}
                                        />
                                    )}
                                />


                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.postalCode}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.PostalCode.label" defaultMessage="PostalCode" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.postalCode}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}> {<FormattedMessage id="columnname.PostalCode.label" defaultMessage="PostalCode" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNumeric}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.postalCode}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.PostalCode.label" defaultMessage="PostalCode" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" defaultMessage="Org Code cannot exceed more than 50 chars" />}
                                        </FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                    </Grid>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.telNo}
                                    rules={addressmode === 'view' ? { required: false } :
                                        {
                                            required: true,
                                            maxLength: 18,
                                            pattern: commonPatterns.commonTextfeildPattern.patternForTel_PhNumber
                                        }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required={true}
                                            variant="outlined"
                                            margin="normal"
                                            id="telNo"
                                            size='small'
                                            label={<FormattedMessage id="columnname.TelNo.label" defaultMessage="TelNo" />}
                                            {...field}
                                            error={errors.telNo}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.telNo,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={addressmode === 'view' ? true : false}
                                        />
                                    )}
                                />

                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.telNo}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.TelNo.label" defaultMessage="TelNo" /> {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.telNo}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.TelNo.label" defaultMessage="TelNo" /> {<FormattedMessage id="textfield.error.cannotexceedmorethaneighteencharacmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.telNo}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.TelNo.label" defaultMessage="TelNo" />} {commonPatterns.commonErrorsForTextFields.errorForTele_PhoneNumber}</FormHelperText>
                                    )}

                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.faxNo}
                                    rules={addressmode === 'view' ? { required: false } :
                                        {
                                            maxLength: 50,
                                            pattern: commonPatterns.commonTextfeildPattern.patternForTel_PhNumber
                                        }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}

                                            variant="outlined"
                                            margin="normal"
                                            id="faxNo"
                                            size='small'
                                            label={<FormattedMessage id="columnname.FaxNo.label" defaultMessage="FaxNo" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,

                                            }}
                                            error={errors.faxNo}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.faxNo,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={addressmode === 'view' ? true : false}
                                        />
                                    )}
                                />
                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.faxNo}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.FaxNo.label" defaultMessage="FaxNo" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.faxNo}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.FaxNo.label" defaultMessage="FaxNo" />} {commonPatterns.commonErrorsForTextFields.errorForTele_PhoneNumber}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.faxNo}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.FaxNo.label" defaultMessage="FaxNo" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" defaultMessage="Org Code cannot exceed more than 50 chars" />}
                                        </FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};


export default ContatDetailsForm;