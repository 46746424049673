import { Badge, Button, CssBaseline, Grid, IconButton, Paper, Snackbar, Tooltip, useMediaQuery, useTheme, Menu, MenuList, CircularProgress, Typography, MenuItem } from '@mui/material';
import { Alert } from '@mui/lab';
import { getGridStringOperators, GridToolbarColumnsButton, GridToolbarDensitySelector, LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import { CommonService } from '../../../services';
import useStyles from '../../common/CommonStyle/CommonStyle';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import ViewIcon from '@mui/icons-material/Visibility';
import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { CustomFilters } from '../../common/CustomFilters/CustomFilters';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import CustomFilterComponent, { GetFilterableColumns } from '../../common/CustomFilterComponent';
import { FilterList } from '@mui/icons-material';
import IconProject from '@mui/icons-material/AccountTree';
import IconSettings from '@mui/icons-material/Settings';
import ChipInfoList from '../../common/ChipInfoList';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import CreateOrEditProject from './CreateOrEditProject/CreateOrEditProject';
import { manageUserPreferences } from '../../../services/manageUserPreferences';
//added
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExportIcon from '@mui/icons-material/Publish';
import CommonBackDrop from '../../common/BackDrop/CommonBackDrop';
//end

const columnFieldsName = {
    orgCode: 'organization',
    entityCode: 'entity',
    projectCode: 'projectCode',
    projectName: 'projectName',
    active: 'active',
    createdBy: 'createdBy',
    createdTime: 'createdTime',
    updatedBy: 'updatedBy',
    updatedTime: 'updatedTime',
}

function Project() {
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());
    const projectModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.project];
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.project);
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [chipInfoList, setChipInfoList] = useState([]);
    const [basicmode, setbasicmode] = React.useState('');
    const [openProjectDialog, setOpenProjectDialog] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);
    const smallScreen = useMediaQuery(theme.breakpoints.down(890))
    const [projectObjectToBeEditedOrView, setProjectObjectToBeEditedOrView] = useState([]);
    const [prjId, setPrjId] = useState(0);
    const [projectName, setProjectName] = useState('');
    const [projectCode, setProjectCode] = useState('')
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openFilterPanel, setFilterPanel] = useState(false);
    const [filterCount, setFilterCount] = React.useState(0);
    //added
    const [isExportXlRequested, setExportXlRequested] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [keyValueData, setKeyValueData] = useState({});
    const anchorRef = React.useRef(null);
    const [searchedEntityCode, setSearchedEntityCode] = useState('');
    const [searchedProjectCode, setSearchedProjectCode] = useState('');
    const [searchedProjectName, setSearchedProjectName] = useState('');

    const handleToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        requestXLReportExport(menuOptionsForExportButton[index], false);
    };
    //end
    var defaultEntity = loggedInUserInfo.defaultEntity;

    const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.entityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity }]
        : [];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);

    const handleClose = () => {
        setFilterPanel(false);
        //added
        setAnchorEl(null);
    }

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });

    const pageLabelsConstantsAndMessages = {
        label: {
            active: 'Active',
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },

    };

    const closeCreateOrEditViewDialog = (confirmed) => {
        if (confirmed) {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success" });
            setRefreshTable(!refreshTable);
        }
        setProjectObjectToBeEditedOrView({});
        setOpenProjectDialog(false);
    }

    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };

    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.settings" defaultMessage="Settings" />,
            iconName: IconSettings,
        },
        {
            path: '/projects',
            name: <FormattedMessage id="common.module.breadcrumb.columnname.project" defaultMessage="Projects" />,
            iconName: IconProject,
        }
    ];
    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState(preference.columnsPinned);
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }


    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }

        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);

    const columnsForProject =
        [
            {
                field: 'action',
                headerName: intl.formatMessage({ id: "columnname.action.label" }),
                width: 130,
                sortable: false,
                hide: false,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) =>
                (
                    <div>
                        {(projectModuleAccess !== undefined ? projectModuleAccess.editFlag : false) &&
                            (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    setOpenProjectDialog(true)
                                    setbasicmode('edit')
                                    setProjectObjectToBeEditedOrView(cellParams.row)
                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar}>
                                        <EditIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}

                        {(projectModuleAccess !== undefined ? projectModuleAccess.readFlag : false) &&
                            (<Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.readButton} />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    setOpenProjectDialog(true)
                                    setbasicmode('view')
                                    setProjectObjectToBeEditedOrView(cellParams.row)

                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar}>
                                        <ViewIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                        {(projectModuleAccess !== undefined ? projectModuleAccess.deleteFlag : false) &&
                            (<Tooltip title={<FormattedMessage id="common.button.delete.tooltip" defaultMessage="Delete" />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    handleDelete(cellParams.row.id, cellParams.row.projectName);
                                    setProjectName(cellParams.row.projectName);
                                    setProjectCode(cellParams.row.projectCode)
                                })}>
                                <span>
                                    <IconButton color='secondary' size="large">
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                    </div>
                )
            },

            {
                field: columnFieldsName.orgCode,
                headerName: intl.formatMessage({ id: "columnname.organizationcode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 140,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                valueGetter: (params) => `${params.row.organization.erpOrgCode}`,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.entityCode,
                headerName: intl.formatMessage({ id: "common.EntityCode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 140,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.projectCode,
                width: 200,
                headerName: intl.formatMessage({ id: "common.textfield.column.project" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,

            },
            {
                field: columnFieldsName.projectName,
                width: 200,
                headerName: intl.formatMessage({ id: "columnname.projectName.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.active,
                headerName: intl.formatMessage({ id: "columnname.status.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 140,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.customRenderStatusColumn
            },
            {
                field: columnFieldsName.createdBy,
                headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 150,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.createdTime,
                headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.createdDtDisp}</span>)
            },

            {
                field: columnFieldsName.updatedBy,
                headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 155,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.updatedTime,
                headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.lastUpdatedDtDisp}</span>)
            },
        ];

    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(columnsForProject);
                savePreference("visibility", columnsForProject);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, columnsForProject);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let orgCode = '';
                let entityCode = '';
                let projectCode = '';
                let projectName = '';
                let active = '';
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'organization':
                            orgCode = columns
                            break;
                        case 'entity':
                            entityCode = columns
                            break;
                        case 'projectCode':
                            projectCode = columns
                            break;
                        case 'projectName':
                            projectName = columns
                            break;
                        case 'active':
                            active = columns
                            break;
                    }
                })
                //added
                setSearchedProjectCode(projectCode.value);
                setSearchedProjectName(projectName.value);
                setSearchedEntityCode(entityCode.value);

                var filterBody = {
                    entity: {
                        erpEntityCode: entityCode.value,
                    },
                    organization: {
                        erpOrgCode: orgCode.value,
                    },
                    projectCode: projectCode.value,
                    projectName: projectName.value,
                    active: true,
                    recordActiveStatus: active.value === '' ? 'All' : active.value === 'Active' ? true : active.value === 'In-Active' ? false : 'All',
                };
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0)
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [selectedLanguage])

    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.project, paramBody)
                .then((response) => {
                    setTotalRowCount(response.data.totalElements);
                    setTableLoadingStatus(false);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [paramBody, refreshTable])

    const closeConfirmationDialog = (confirmed) => {
        setOpenConfirmationDialog(false);
        if (confirmed) {
            CommonService.deleteByIdApi(`/projects/${prjId}`, {})
                .then((response) => {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: "Project Deleted Successfully", severity: "success" });
                    setRefreshTable(!refreshTable);
                })
                .catch(function (error) {
                    let message = (error.response.data !== undefined || error.response.data !== null) ? `Proj/WBS code "${projectCode}- ${projectName}" is referenced in User Management. Cannot be deleted!`
                        : "Error occurred while deleting  a Project!";
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: message, severity: "error" });
                });
        }
    }

    const handleDelete = (primaryKey, nameToBeDisplacedOnConfirmationDialog) => {
        setPrjId(primaryKey);
        setConfirmationMessage(<FormattedMessage id="common.dialog.areyousure.txt" values={{ nameToBeDisplacedOnConfirmationDialog: `${nameToBeDisplacedOnConfirmationDialog}` }} defaultMessage={`Are you sure, You want to delete '${nameToBeDisplacedOnConfirmationDialog}'?`} />);
        setOpenConfirmationDialog(true);
    }

    if (columns.length > 0) {

        const orgCodeColumn = columns.find((column) => column.field === columnFieldsName.orgCode);
        const orgCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.orgCode);

        const orgCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgCodeInputValue,
            }),
        );

        columns[orgCodeColIndex] = {
            ...orgCodeColumn,
            filterOperators: orgCodeFilterOperators,
        };

        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.entityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.entityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };

        const projectCodeColumn = columns.find((column) => column.field === columnFieldsName.projectCode);
        const projectCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.projectCode);

        const projectCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.WbsCodeInputValue,
            }),
        );

        columns[projectCodeColIndex] = {
            ...projectCodeColumn,
            filterOperators: projectCodeFilterOperators,
        };

        const projectNameColumn = columns.find((column) => column.field === columnFieldsName.projectName);
        const projectNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.projectName);

        const projectNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.WbsDescriptionInputValue,
            }),
        );

        columns[projectNameColIndex] = {
            ...projectNameColumn,
            filterOperators: projectNameFilterOperators,
        };

        const statusColumn = columns.find((column) => column.field === columnFieldsName.active);
        const statusColIndex = columns.findIndex((col) => col.field === columnFieldsName.active);

        const statusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.StatusColInputValue,
            }),
        );

        columns[statusColIndex] = {
            ...statusColumn,
            filterOperators: statusFilterOperators,
        };
    }

    //added
    const menuOptionsForExportButton = [
        {
            label: intl.formatMessage({ id: "uploadProject.button" }),
            tooltipTitle: intl.formatMessage({ id: "exportbuttonProject.Tooltip" }),
            url: '/reports/SYS_PROJECT_REPORT',
            successMessage: <FormattedMessage id="snackbar.successExportReport" defaultMessage="Generated the Excel Report Successfully" />,
            parameters: keyValueData
        },
        {
            label: intl.formatMessage({ id: "uploadProjectUser.button" }),
            tooltipTitle: intl.formatMessage({ id: "exportbuttonProjectUser.Tooltip" }),
            url: '/reports/SYS_PROJECT_USER_REPORT',
            successMessage: <FormattedMessage id="snackbar.successExportReport" defaultMessage="Generated the Excel Report Successfully" />,
            parameters: keyValueData
        }
    ]
    //end
    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let orgCode = '';
        let entityCode = '';
        let projectCode = '';
        let projectName = '';
        let active = '';
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'organization':
                    orgCode = columns
                    break;
                case 'entity':
                    entityCode = columns
                    break;
                case 'projectCode':
                    projectCode = columns
                    break;
                case 'projectName':
                    projectName = columns
                    break;
                case 'active':
                    active = columns
                    break;
            }
        })
        //added
        setSearchedProjectCode(projectCode.value);
        setSearchedProjectName(projectName.value);
        setSearchedEntityCode(entityCode.value);

        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);

        setParamBody(prevState => {

            return {
                ...prevState, body: {
                    entity: {
                        erpEntityCode: entityCode.value,
                    },
                    organization: {
                        erpOrgCode: orgCode.value,
                    },
                    projectCode: projectCode.value,
                    projectName: projectName.value,
                    active: true,
                    recordActiveStatus : active.value === '' ? 'All' : active.value === 'Active' ? true : active.value === 'In-Active' ? false : 'All',
                },
            }
        });
    }

    //added
    let filterBodyObj = {
       projectCode: searchedProjectCode === '' ? null : searchedProjectCode,
       projectName: searchedProjectName === '' ? null : searchedProjectName,
       erpEntityCode: searchedEntityCode === '' ? null : searchedEntityCode,
    }

    useEffect(() => {
        let colValDataObj = {}
        for (let key in filterBodyObj) {
            let value = filterBodyObj[key]
            if (filterBodyObj.hasOwnProperty(key) && ((value !== null) && (value !== 0) && (value !== undefined) && (value !== {}))) {
                colValDataObj[key] = value.toString();        
            }
            setKeyValueData(colValDataObj)
        }
    },[searchedProjectCode, searchedProjectName, searchedEntityCode]);


    const requestXLReportExport = (selectedExportReportTypeObj) => {
        setExportXlRequested(true);
        setOpenBackDrop(true);
        CommonService.saveOrUpdateOrPost(selectedExportReportTypeObj.url, selectedExportReportTypeObj.parameters)

            .then((response) => {
                if (response.data.success === "No records found for given condition") {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.success, severity: "error" });
                } else {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success" });
                }
                setOpenBackDrop(false);
                setExportXlRequested(false);
            })
            .catch(function (error) {
                setExportXlRequested(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                setOpenBackDrop(false);
            });
    }
    //end
    useEffect(() => {
        setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            {/* added */}
            <CommonBackDrop open={openBackDrop} />
            {/* end */}
            <Grid container className={classes.rootForRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={8} md={6} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6} sm={4} md={6} lg={6} className={classes.topGrid}>
                    {(projectModuleAccess !== undefined ? projectModuleAccess.createFlag : false) && (
                        <Button size='small'
                            variant="contained" color="primary" className={classes.createButton} elevation={2} endIcon={smallScreen ? null : <CreateIcon />}
                            onClick={(() => {

                                setbasicmode('create')
                                setOpenProjectDialog(true)
                            })}>
                            {smallScreen ? <CreateIcon /> : <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />}
                        </Button>)}
                </Grid>

                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <ChipInfoList chipInfoList={chipInfoList} />
                <CreateOrEditProject
                    open={openProjectDialog}
                    onClose={closeCreateOrEditViewDialog}
                    title={componentList}
                    mode={basicmode}
                    projectObjectToBeEditedOrView={projectObjectToBeEditedOrView}
                />
                <Paper elevation={3} className={classes.gridHeightAndWidthForRole}>
                    <div style={{ height: '100%', width: '100%' }}>

                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            hideFooter={true}
                            localeText={localtext}
                            density="compact"
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}  >
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'><GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterList />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined" className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                {/* added */}
                                                <Grid>
                                                    <Tooltip
                                                        title={menuOptionsForExportButton[selectedIndex].tooltipTitle} placement='bottom'>
                                                        <span>
                                                            <Button size='small'
                                                                variant='outlined'
                                                                color='primary'
                                                                ref={anchorRef}
                                                                disabled={tableData.length <= 0 || isExportXlRequested}
                                                                endIcon={isExportXlRequested ? <CircularProgress color='secondary' size={20} /> : null}
                                                                onClick={handleToggle}
                                                            >
                                                                <ExportIcon />
                                                                <Typography style={{ margin: '0 5px 0 5px', fontWeight: 500, fontSize: '13px' }} >
                                                                    <FormattedMessage id="export.defaulttext.button" defaultMessage="EXPORT" />
                                                                </Typography>
                                                                <ArrowDropDownIcon />
                                                            </Button>
                                                        </span>

                                                    </Tooltip>
                                                    <Menu
                                                        id="selectReportExport"
                                                        anchorPosition={{ left: 280, top: 150}}
                                                        anchorReference="anchorPosition"
                                                        anchorEl={anchorEl}
                                                        elevation={5}
                                                        open={Boolean(anchorEl)}
                                                        onClose={handleClose}
                                                    >
                                                    <MenuList>
                                                            {menuOptionsForExportButton.map((Option, index)=>(
                                                               <Tooltip title={menuOptionsForExportButton[index].tooltipTitle} placement="right-start">
                                                                <span>
                                                                    <MenuItem
                                                                        divider
                                                                        className={classes.menuListStyle}
                                                                        key={index}
                                                                        selected={index === selectedIndex}
                                                                        onClick={(event)=> handleMenuItemClick(event, index)}
                                                                    >
                                                                        {Option.label}
                                                                    </MenuItem>
                                                                </span>
                                                               </Tooltip> 
                                                            ))}
                                                    </MenuList>
                                                    </Menu>
                                                </Grid>
                                                {/* end */}
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {
                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {
                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </div>
                </Paper>
                <ConfirmationDialog onClose={closeConfirmationDialog} message={confirmationMessage} open={openConfirmationDialog} />
            </Grid>
        </React.Fragment>
    )
}
export default Project;
