import { Chip, Tab } from '@mui/material';
import Axios from 'axios'
import { withStyles } from '@mui/styles';
import IconActive from '@mui/icons-material/Done';
import IconCloseOrInActive from '@mui/icons-material/Close';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import NotConfirmedIcon from '@mui/icons-material/EventBusy';
import ConfirmedIcon from '@mui/icons-material/EventAvailable';
import PartiallyConfirmedIcon from '@mui/icons-material/DateRange';
import BothActiveOrInActiveIcon from '@mui/icons-material/DoneAll';
import NewMsgIcon from '@mui/icons-material/Event';

const InActiveChip = withStyles((theme) => ({
    root: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        width: '83%',
        height: '75%'
    },
}))(Chip);

const ActiveChip = withStyles((theme) => ({
    root: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        width: '83%',
        height: '75%'
    },
}))(Chip);

const NoChip = withStyles((theme) => ({
    root: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        width: '90%',
        height: '75%'
    },
}))(Chip);

const YesChip = withStyles((theme) => ({
    root: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        width: '90%',
        height: '75%'
    },
}))(Chip);

const PendingChip = withStyles((theme) => ({
    root: {
        color: '#dbc102',
        borderColor: '#dbc102',
        width: '83%',
        height: '75%'
    },
}))(Chip);

const BothActiveInactiveChip = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        width: '83%',
        height: '75%'
    },
}))(Chip);

const ErrorTab = withStyles((theme) => ({
    wrapper: {
        flexDirection: "row-reverse",
    },
    labelIcon: {
        width: "auto",
        padding: '10px 10px 10px 10px'
    }
}))(Tab);

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

const printBaseUrl = () => {
    return BASE_URL;
}

function LoginApi(userCredentials) {
    return Axios.post(BASE_URL + "/authenticate/admin", userCredentials);
}

function GetListApi(apiUrl, params) {
    return Axios.get(`${BASE_URL}${apiUrl}`, {
        headers: {
            'Authorization': 'Bearer ' + getJwtTokenOfLoggedInUser()
        },
        params
    });
}

function Logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('siix-authorization');
}

function getJwtTokenOfLoggedInUser() {
    let jwToken = localStorage.getItem('siix-authorization');
    return jwToken !== null ? jwToken : '';
}

function getLoggedInUserInfo() {
    return JSON.parse(localStorage.getItem('loggedInUser'));
}

function getOrganizationOfLoggedInUser() {
    let currentlyLoggedInUser = null;
    currentlyLoggedInUser = getLoggedInUserInfo();
    return (currentlyLoggedInUser !== null && currentlyLoggedInUser !== undefined) ? currentlyLoggedInUser.organization : null;
}

function getUserIdLoggedInUser() {
    let currentlyLoggedInUser = null;
    currentlyLoggedInUser = getLoggedInUserInfo();
    return (currentlyLoggedInUser !== null && currentlyLoggedInUser !== undefined) ? currentlyLoggedInUser.id : 0;
}

function getEntityOfLoggedInUser() {
    let currentlyLoggedInUser = null;
    currentlyLoggedInUser = getLoggedInUserInfo();
    return (currentlyLoggedInUser !== null && currentlyLoggedInUser !== undefined) ? currentlyLoggedInUser.entity : null;
}

function getdefaultEntity() {
    let currentlyLoggedInUserEntityAccessList;
    currentlyLoggedInUserEntityAccessList = getLoggedInUserInfo();
    var defaultEntityDetails = (currentlyLoggedInUserEntityAccessList !== null && currentlyLoggedInUserEntityAccessList !== undefined) ? currentlyLoggedInUserEntityAccessList.userEntityAccessList.find(element => element.defaultEntity === true) : null;
    return (defaultEntityDetails !== null && defaultEntityDetails !== -1) ? defaultEntityDetails.entity.erpEntityCode : '';
}

function getByIdApi(apiUrl, pId) {
    return Axios.get(`${BASE_URL}${apiUrl}/${pId}`, {
        headers: {
            'Authorization': 'Bearer ' + getJwtTokenOfLoggedInUser()
        }
    });
}

function saveOrUpdateOrPost(apiUrl, body) {
    return Axios.post(`${BASE_URL}${apiUrl}`, body,
        {
            headers: {
                'Authorization': 'Bearer ' + getJwtTokenOfLoggedInUser()
            }
        });
}

function deleteByIdApi(apiUrl, params) {
    return Axios.delete(`${BASE_URL}${apiUrl}`,
        {
            headers: {
                'Authorization': 'Bearer ' + getJwtTokenOfLoggedInUser()
            },
            params
        });
}

const prepareModuleAccessObject = (moduleAccessObject) => {
    let moduleObject = {
        moduleId: moduleAccessObject.module.moduleId,
        moduleName: moduleAccessObject.module.name,
        createFlag: moduleAccessObject.createFlag,
        readFlag: moduleAccessObject.readFlag,
        editFlag: moduleAccessObject.editFlag,
        deleteFlag: moduleAccessObject.deleteFlag,
    }
    return moduleObject;
}

function getModuleAccessList() {

    let roleModule;
    let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

    let roleList = loggedInUser.roles;
    {
        roleList.map((moduleAccess) => {
            {
                moduleAccess.roleModuleAccess.map((module) => {
                    if (roleModule === undefined) {
                        roleModule = {};
                        roleModule[module.module.name] = prepareModuleAccessObject(module);
                    }
                    else {
                        if (roleModule[module.module.name] !== undefined) {
                            roleModule[module.module.name].createFlag = module.createFlag ? true : roleModule[module.module.name].createFlag;
                            roleModule[module.module.name].readFlag = module.readFlag ? true : roleModule[module.module.name].readFlag;
                            roleModule[module.module.name].editFlag = module.editFlag ? true : roleModule[module.module.name].editFlag;
                            roleModule[module.module.name].deleteFlag = module.deleteFlag ? true : roleModule[module.module.name].deleteFlag;
                        } else {
                            roleModule[module.module.name] = prepareModuleAccessObject(module);
                        }
                    }
                })
            }
        })
    }
    return roleModule;
}

function ActiveOrInActiveChip(props) {
    const { value } = props;
    return (
        <React.Fragment>
            {value && (<ActiveChip
                icon={<IconActive />}
                variant="outlined"
                label='Active'
                color='primary'
            />)}
            {!value && (<InActiveChip
                icon={<IconCloseOrInActive />}
                variant="outlined"
                label='In-Active'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

function YesOrNoChip(props) {
    const { value } = props;
    return (
        <React.Fragment>
            {value && (<YesChip
                icon={<IconActive />}
                variant="outlined"
                label='Yes'
                color='primary'
            />)}
            {!value && (<NoChip
                icon={<IconCloseOrInActive />}
                variant="outlined"
                label='No'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

const customRenderYesOrNoColumn = (cellParam) => {
    return (<YesOrNoChip value={cellParam.value} />)
}

function BothActiveOrInactiveChip(props) {
    const { value } = props;
    return (
        <React.Fragment>
            {value && (<BothActiveInactiveChip
                icon={<BothActiveOrInActiveIcon />}
                label='All'
                variant="outlined"
                color='secondary'
            />)}
        </React.Fragment>
    )
}

const customRenderStatusColumn = (cellParam) => {
    return (<ActiveOrInActiveChip value={cellParam.value} />)
}

const customRenderBothStatusColumn = (cellParam) => {
    return (<BothActiveOrInactiveChip value={cellParam.value} />)
}

function SuccessOrFailureChip(props) {
    const { value } = props;
    return (
        <React.Fragment>
            {value === 1 && (<InActiveChip
                icon={<IconCloseOrInActive />}
                variant="outlined"
                label='Failure'
                color='secondary'
            />)}
            {value === 0 && (< ActiveChip
                icon={<IconActive />}
                variant="outlined"
                label='Success'
                color='primary'

            />)}
        </React.Fragment>
    );
}

function SuccessOrFailureChipForTransmissionLog(props) {
    const { value } = props;
    return (
        <React.Fragment>
            {value === 'FAILED' && (<InActiveChip
                icon={<IconCloseOrInActive />}
                variant="outlined"
                label='Failed'
                color='secondary'
            />)}
            {value === 'SUCCESS' && (< ActiveChip
                icon={<IconActive />}
                variant="outlined"
                label='Success'
                color='primary'

            />)}
            {value === 'PARTIAL' && (< PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='Partial'
                color='primary'
            />)}
            {value === 'PENDING' && (< PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='Pending'
                color='primary'
            />)}
        </React.Fragment>
    );
}

const customRenderStatusColumnForErpInterface = (cellParam) => {
    return (<SuccessOrFailureChip value={cellParam.value} />)
}

const customRenderStatusColumnForTransmissionLog = (cellParam) => {
    return (<SuccessOrFailureChipForTransmissionLog value={cellParam.value} />)
}

const PoStatusDisplayField = (props) => {
    const { value } = props;
    return (
        <React.Fragment>
            {value === 'COMPLETE' && (<ActiveChip
                icon={<CheckCircleIcon />}
                variant="outlined"
                label='Complete'
                color='primary'
            />)}
            {value === 'IN-COMPLETE' && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='In-Complete'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

const AmendedAndCancellationDisplayField = (props) => {
    const { value } = props;
    return (
        <React.Fragment>
            {value !== 0 && (<ActiveChip
                icon={<CheckCircleIcon />}
                variant="outlined"
                label='YES'
                color='primary'
            />)}
            {value === 0 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='NO'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

const DelCfmStatusDisplayField = (props) => {
    const { value } = props;

    return (
        <React.Fragment>
            {value === "CONFIRMED" && (<ActiveChip
                icon={<ConfirmedIcon />}
                variant="outlined"
                label='Confirmed'
                color='primary'
            />)}
            {value === 'NOT-CONFIRMED' && (<InActiveChip
                icon={<NotConfirmedIcon />}
                variant="outlined"
                label='Not Confirmed'
                color='secondary'
            />)}
            {value === 'PARTIALLY-CONFIRMED' && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='Partially Confirmed'
                color='secondary'
            />)}
            {value === 'NOT/PARTIAL CONFIRMED' && (<BothActiveInactiveChip
                icon={<NewMsgIcon />}
                variant="outlined"
                label='Not/Partial Confirmed'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

const PoLineDlvyCnfDisplayField = (props) => {
    const { value } = props;

    return (
        <React.Fragment>
            {value === '1' && (<ActiveChip
                icon={<ConfirmedIcon />}
                variant="outlined"
                label='Confirmed'
                color='primary'
            />)}
            {value === '0' && (<InActiveChip
                icon={<NotConfirmedIcon />}
                variant="outlined"
                label='Not Confirmed'
                color='secondary'
            />)}
             {value === '2' && (<BothActiveInactiveChip
                icon={<NewMsgIcon />}
                variant="outlined"
                label='Ack by Dummy Date'
                color='secondary'
            />)}
             {value === '3' && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='Partially Confirmed'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

function YesOrNoForOUtBoundChip(props) {
    const { value } = props;
    return (
        <React.Fragment>
            {value && (<YesChip
                icon={<IconActive />}
                variant="outlined"
                label='Yes'
                color='primary'
            />)}
            {!value && (<NoChip
                icon={<IconCloseOrInActive />}
                variant="outlined"
                label='No'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

const customRenderStatusColumnForApprEmailSent = (cellParam) => {
    return (<YesOrNoForOUtBoundChip value={cellParam.value} />)
}

const SuccessFailureChipsForPoVmi = (props) => {
    const { value } = props;

    return (
        <React.Fragment>
            {value === 0 && (<BothActiveInactiveChip
                icon={<NewMsgIcon />}
                variant="outlined"
                label='Not Sent'
                color='secondary'
            />)}
            {value === 1 && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='Pending'
                color='secondary'
            />)}
            {value === 2 && (<ActiveChip
                icon={<ConfirmedIcon />}
                variant="outlined"
                label='Successfully Sent'
                color='primary'
            />)}
            {value === 3 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Failed to Send'
                color='secondary'
            />)}
            {value === 4 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Cancelled'
                color='secondary'
            />)}
            {value === 5 && (<BothActiveInactiveChip
                icon={<NewMsgIcon />}
                variant="outlined"
                label='Duplicate'
                color='secondary'
            />)}
            {value === 6 && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='IN-QUEUE'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

const PoStatusChips = (props) => {
    const { value } = props;

    return (
        <React.Fragment>
            {value === 0 && (<BothActiveInactiveChip
                icon={<NewMsgIcon />}
                variant="outlined"
                label='New Msg'
                color='primary'
            />)}
            {value === 1 && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='Pending msg'
                color='secondary'
            />)}
            {value === 2 && (<ActiveChip
                icon={<ConfirmedIcon />}
                variant="outlined"
                label='Success Msg'
                color='primary'
            />)}
            {value === 3 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Error Msg'
                color='secondary'
            />)}
            {value === 4 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Upload Error Msg'
                color='secondary'
            />)}
            {value === 6 && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='IN-QUEUE'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

const PoStatusForOutChips = (props) => {
    const { value } = props;

    return (
        <React.Fragment>
            {value === 0 && (<BothActiveInactiveChip
                icon={<NewMsgIcon />}
                variant="outlined"
                label='Not Sent'
                color='secondary'
            />)}
            {value === 1 && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='Pending'
                color='secondary'
            />)}
            {value === 2 && (<ActiveChip
                icon={<ConfirmedIcon />}
                variant="outlined"
                label='Successfully Sent'
                color='primary'
            />)}
            {value === 3 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Failed to Send'
                color='secondary'
            />)}
            {value === 4 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Cancelled'
                color='secondary'
            />)}
            {value === 6 && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='IN-QUEUE'
                color='secondary'
            />)}
        </React.Fragment>
    );
}
const InvoiceeStatusForChips = (props) => {
    const { value } = props;

    return (
        <React.Fragment>
            {value === 0 && (<BothActiveInactiveChip
                icon={<NewMsgIcon />}
                variant="outlined"
                label='Not Sent'
                color='secondary'
            />)}
            {value === 1 && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='Pending'
                color='secondary'
            />)}
            {value === 2 && (<ActiveChip
                icon={<ConfirmedIcon />}
                variant="outlined"
                label='Successfully Sent'
                color='primary'
            />)}
            {value === 3 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Failed to Send'
                color='secondary'
            />)}
            {value === 4 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Cancelled'
                color='secondary'
            />)}
            {value === 6 && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='IN-QUEUE'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

const ASNStatusChips = (props) => {
    const { value } = props;

    return (
        <React.Fragment>
            {value === 0 && (<BothActiveInactiveChip
                icon={<NewMsgIcon />}
                variant="outlined"
                label='Not Sent'
                color='secondary'
            />)}
            {value === 1 && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='Pending'
                color='primary'
            />)}
            {value === 2 && (<ActiveChip
                icon={<ConfirmedIcon />}
                variant="outlined"
                label='Successfully Sent'
                color='primary'
            />)}
            {value === 3 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Failed to Send'
                color='secondary'
            />)}
            {value === 4 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Cancelled'
                color='secondary'
            />)}
            {value === 6 && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='IN-QUEUE'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

const ApproveFailureChipsForPO = (props) => {
    const { value } = props;

    return (
        <React.Fragment>
            {value === 1 && (<ActiveChip
                icon={<CheckCircleIcon />}
                variant="outlined"
                label='Approved'
                color='primary'
            />)}
            {value === 0 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Not Approved'
                color='secondary'
            />)}
            {value === 2 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Rejected'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

const SuccessFailureChipsForEmailLog = (props) => {
    const { value } = props;

    return (
        <React.Fragment>
            {value === 0 && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='Pending'
                color='secondary'
            />)}
            {value === 1 && (<ActiveChip
                icon={<ConfirmedIcon />}
                variant="outlined"
                label='Successfully Sent'
                color='primary'
            />)}
            {value === 2 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Failed'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

const RequestStatusDisplayField = (props) => {
    const { value } = props;
    return (
        <React.Fragment>
            {value === 1 && (<ActiveChip
                variant="outlined"
                label='COMPLETED'
                color='primary'
            />)}
            {value === 2 && (<InActiveChip
                variant="outlined"
                label='FAILED'
                color='secondary'
            />)}
            {value === 0 && (<PendingChip
                variant="outlined"
                label='PROCESSING'
                color='secondary'
            />)}
            {value === 5 && (<BothActiveInactiveChip
                variant="outlined"
                label='PARTIAL-ERROR'
                color='secondary'
            />)}
            {value === 4 && (<InActiveChip
                variant="outlined"
                label='JOB TERMINATED'
                color='secondary'
            />)}
            {value === 3 && (<InActiveChip
                variant="outlined"
                label='DATA ERROR'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

function uploadFileForDataImport(apiUrl, body) {
    return Axios.post(`${BASE_URL}${apiUrl}`, body,
        {
            headers: {
                'Authorization': 'Bearer ' + getJwtTokenOfLoggedInUser(),
                'Content-Type': 'multipart/form-data'
            }
        });
}

const customRenderHeaderColumns = (params) => {
    return (<strong>
        {params.colDef.headerName}
    </strong>)
}

const TriggerEventsForChips = (props) => {
    const { value } = props;

    return (
        <React.Fragment>

            {value === 0 && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='New'
                color='secondary'
            />)}
            {value === 1 && (<ActiveChip
                icon={<ConfirmedIcon />}
                variant="outlined"
                label='Approval Intimation'
                color='primary'
            />)}
            {value === 2 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Error'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

const AckStatusDisplayField = (props) => {
    const { value } = props;
    return (
        <React.Fragment>
            {value === 0 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Not Approved'
                color='secondary'
            />)}
            {value === 1 && (<PendingChip
                icon={<PartiallyConfirmedIcon />}
                variant="outlined"
                label='Waiting'
                color='secondary'
            />)}
            {value === 2 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Rejected'
                color='secondary'
            />)}
             {value === 3 && (<ActiveChip
                icon={<CheckCircleIcon />}
                variant="outlined"
                label='Approved'
                color='primary'
            />)}
             {value === 4 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Deleted'
                color='secondary'
            />)}
            {value === 5 && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='Accepted'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

const DeljitDisplayField = (props) => {
    const { value } = props;
    return (
        <React.Fragment>
            {value === 'X' && (<ActiveChip
                icon={<CheckCircleIcon />}
                variant="outlined"
                label='YES'
                color='primary'
            />)}
             {value === 'x' && (<ActiveChip
                icon={<CheckCircleIcon />}
                variant="outlined"
                label='YES'
                color='primary'
            />)}
            {value === '' && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='NO'
                color='secondary'
            />)}
            {value === null && (<InActiveChip
                icon={<CancelIcon />}
                variant="outlined"
                label='NO'
                color='secondary'
            />)}
        </React.Fragment>
    );
}

export const CommonService = {
    printBaseUrl,
    LoginApi,
    GetListApi,
    Logout,
    getJwtTokenOfLoggedInUser,
    getByIdApi,
    getLoggedInUserInfo,
    getModuleAccessList,
    customRenderStatusColumn,
    saveOrUpdateOrPost,
    deleteByIdApi,
    ActiveOrInActiveChip,
    PoStatusDisplayField,
    DelCfmStatusDisplayField,
    PoLineDlvyCnfDisplayField,
    InActiveChip,
    ActiveChip,
    NoChip,
    YesChip,
    YesOrNoChip,
    uploadFileForDataImport,
    customRenderHeaderColumns,
    getOrganizationOfLoggedInUser,
    customRenderStatusColumnForErpInterface,
    BothActiveOrInactiveChip,
    customRenderBothStatusColumn,
    customRenderYesOrNoColumn,
    ErrorTab,
    SuccessOrFailureChip,
    SuccessFailureChipsForPoVmi,
    ApproveFailureChipsForPO,
    PendingChip,
    SuccessOrFailureChipForTransmissionLog,
    customRenderStatusColumnForTransmissionLog,
    PoStatusChips,
    ASNStatusChips,
    PoStatusForOutChips,
    YesOrNoForOUtBoundChip,
    customRenderStatusColumnForApprEmailSent,
    InvoiceeStatusForChips,
    AmendedAndCancellationDisplayField,
    SuccessFailureChipsForEmailLog,
    getdefaultEntity,
    getUserIdLoggedInUser,
    getEntityOfLoggedInUser,
    TriggerEventsForChips,
    RequestStatusDisplayField,
    AckStatusDisplayField,
    DeljitDisplayField
}