function FakeBackendForSelectedColumns() {
    return (
        {
            "content": [
                {
                    "id": 1,
                    "columnName": "MATERIAL_CODE",
                    "displayName": "Material Code",
                    "index": 0,
                },
                {
                    "id": 2,
                    "columnName": "MATERIAL_SHORT_DESC",
                    "displayName": "Material Short Desc",
                    "index": 1,
                },
                {
                    "id": 6,
                    "columnName": "UNIT_OF_MEASURE",
                    "displayName": "Unit Of Measure",
                    "index": 2,
                },
                {
                    "id": 9,
                    "columnName": "SALES_ORDER_NO",
                    "displayName": "Sales Order No",
                    "index": 3,
                },
                {
                    "id": 11,
                    "columnName": "INVOICE_QTY",
                    "displayName": "Invoice Qty",
                    "index": 4,
                },
                {
                    "id": 13,
                    "columnName": "UNIT_PRICE",
                    "displayName": "Unit Price",
                    "index": 5,
                },
                {
                    "id": 31,
                    "columnName": "INVOICE_CURRENCY",
                    "displayName": "Invoice Currency",
                    "index": 6,
                },
                {
                    "id": 30,
                    "columnName": "SHIP_TO_BP_CODE",
                    "displayName": "Ship To BP Code",
                    "index": 7,
                },
                {
                    "id": 33,
                    "columnName": "TAXABLE_AMOUNT",
                    "displayName": "Taxable Amount",
                    "index": 8,
                }
            ],
            "pageable": {
                "sort": {
                    "sorted": true,
                    "unsorted": false,
                    "empty": false
                },
                "offset": 0,
                "pageSize": 25,
                "pageNumber": 0,
                "paged": true,
                "unpaged": false
            },
            "totalElements": 1,
            "last": true,
            "totalPages": 1,
            "size": 25,
            "number": 0,
            "sort": {
                "sorted": true,
                "unsorted": false,
                "empty": false
            },
            "numberOfElements": 1,
            "first": true,
            "empty": false
        }
    )
}

export default FakeBackendForSelectedColumns;