import {
    Avatar, Box, Button, CssBaseline, FormControl, FormHelperText, Grid,
    IconButton, InputAdornment, Link, Snackbar, TextField, Typography, useMediaQuery, useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useForm, Controller } from 'react-hook-form';
import _ from "lodash/fp";
import Copyright from '../Copyright';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconUser from '@mui/icons-material/Person';
import SiixLogo from '../AppSideBar/siix_logo.png';
import Alert from '@mui/lab/Alert';
import { CommonService } from '../../../services';
import jwt_decode from "jwt-decode";
import CommonBackDrop from '../BackDrop';
import { LoggedInUserDetails } from '../LoggedInUserDetails/LoggedInUserDetails';
import VerifyTotp from '../VerifyTotp';
import PoweredBy from '../PoweredBy/PoweredBy';
import VerifyOTP from '../VerifyOTP/VerifyOTP';
import ForgetPasswordIcon from '@mui/icons-material/VpnKey';
import { FormattedMessage } from 'react-intl';
import Selectlang from '../Selectlang/Selectlang';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import { manageUserPreferences } from '../../../services/manageUserPreferences.js';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'hidden'
    },
    image: {
        backgroundImage: 'url(/siix_login_page.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
            marginTop: '-10%'
        },
    },
    paper: {
        margin: theme.spacing(10, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: { //alignment with respect to image on laptops
            backgroundColor: '#fff'
        },
        [theme.breakpoints.down('md')]: { //alignment with respect to image on mobiles
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
            marginTop: '10%',
            padding: '30px 20px',
            opacity: 0.95,
            outerWidth: '100%'
        },
        [theme.breakpoints.down('sm')]: { //alignment with respect to image on mobiles
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
            marginTop: '2%',
            padding: '20px 20px',
            opacity: 0.95,
            outerWidth: '100%',
        },
        padding: '15px 10px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginTop: '0%',
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        [theme.breakpoints.down('sm')]: {
            marginTop: '3%'
        },
    },
    error: {
        color: "red",
    },
    iconButton: {
        color: 'black'
    },
    companyLogo: {
        height: 100,
        width: 170,
        [theme.breakpoints.only('sm')]: {
            marginTop: '5%'
        },
    },
    forgetPassword: {
        color: theme.palette.customPrimary.secondary,
    },
    asterisk: {
        color: "red"
    },
}))

function LoginPage(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const [isForgetPasswordRequested, setForgetPasswordRequested] = useState(false);
    const COPY_RIGHT_COMPANY_NAME = `${process.env.REACT_APP_COPY_RIGHT_COMPANY_NAME}`;
    const COPY_RIGHT_COMPANY_URL = `${process.env.REACT_APP_COPY_RIGHT_COMPANY_URL}`;
    const POWERED_BY_COMPANY_NAME = `${process.env.REACT_APP_POWERED_BY_COMPANY_NAME}`;
    const POWERED_BY_COMPANY_URL_VITALMED = `${process.env.REACT_APP_POWERED_BY_COMPANY_URL}`;
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const { handleSubmit, control, formState: { errors } } = useForm({
        mode: "all",
        defaultValues: {
            username: '',
            password: '',
            emailId: ''
        }
    });
    const [userId, setUserId] = useState(0);
    const [emailId, setEmailId] = useState('');
    const [loginId, setLoginId] = useState('');
    const causeOfGeneration = "PWD_RESET";
    const [isRequestedFormVerifyOtpDialog, setRequestedFormVerifyOtpDialog] = useState(false);
    const commonPatterns = GlobalEdiApiConstants();

    const labelsNamesMessagesAndHelperText = {
        labels: {
            loginId: "Login Id",
            password: "Password",
            loginButton: "Sign In",
            forgotPassword: "Forgot Password?",
            emailId: "Email Id",
            submit: "Submit",
            backToLogin: "Back To LoginPage",
            username: "username",
        },
        messages: {

        },
        name: {
            username: "username",
            password: "password",
            emailId: "emailId",
            loginId: "loginId"
        }
    }
    const [state, setState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const [openVerifyTotpDialog, setOpenVerifyTotpDialog] = useState(false);
    const { vertical, horizontal, openAlert, message, severity } = state;

    const { loggedInUserInfo, setLoggedInUserInfo } = useContext(LoggedInUserDetails);
    const [is2FAEnabled, set2FAEnabled] = useState();
    const handleCloseAlert = () => {
        setState({ ...state, openAlert: false });
    };
    const [open, setOpen] = React.useState(false);
    const handleClose = (value) => {
        if (value) {
            history.push(`/forgetPassword`, { renderScreenFor: 'forgetPassword', emailId: emailId, loginId: loginId });
        }
        setOpen(false);
    };

    function validateLoginIdAndSendOtp(data) {
        setRequestedFormVerifyOtpDialog(true)
        if (!open || isRequestedFormVerifyOtpDialog) {
            CommonService.saveOrUpdateOrPost(`/users/validate`, data)
                .then((response) => {
                    setState({ ...state, openAlert: true, message: response.data.message, severity: "success" });
                    setOpen(true)
                })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setState({ ...state, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }

    }

    const onSubmit = data => {
        let loginIdOfUser = data.username;
        if (isForgetPasswordRequested) {
            validateLoginIdAndSendOtp(data);
        } else {
            setOpenBackDrop(true);
            CommonService.LoginApi(data)
                .then((response) => {
                    let jwtToken = response.data.token;
                    localStorage.setItem('siix-authorization', jwtToken);
                    var decodedJwt = jwt_decode(jwtToken);
                    setUserId(decodedJwt.uid);
                    if (decodedJwt.g2fa) {
                        set2FAEnabled(decodedJwt.mfae);
                        setOpenVerifyTotpDialog(true)
                    } else {
                        updateUserPreferredTimeZone(loginIdOfUser);
                        redirectToHomePage(decodedJwt.uid);
                    }
                })
                .catch((error) => { catchError(error) });
        }

    }
    const handleOnSubmit = (event) => {
        event.preventDefault();
    };


    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const closeVerifyTotpDialog = (confirmed) => {
        setOpenVerifyTotpDialog(false);
        setOpenBackDrop(false);
    }

    function redirectToHomePage(userId) {
        CommonService.getByIdApi('/users', userId)
            .then((responseForCurrentUserInfo) => {
                localStorage.setItem('loggedInUser', JSON.stringify(responseForCurrentUserInfo.data));
                setState({ ...state, openAlert: true, message: <FormattedMessage id="snackbar.login.txt" defaultMessage="Login successful, redirecting.." />, severity: "success" });
                setLoggedInUserInfo({ ...loggedInUserInfo, logInStatus: true, roleModuleAccess: CommonService.getModuleAccessList(), defaultEntity: CommonService.getdefaultEntity() });
                manageUserPreferences.loadLastOpenScreen(responseForCurrentUserInfo.data.userId, responseForCurrentUserInfo.data.entity, responseForCurrentUserInfo.data.organization)
                    .then((response) => {
                        if (response.data !== "")
                            history.push(response.data);
                        else
                            history.push(responseForCurrentUserInfo.data.userType === 'SUPPLIER' ? '/orders' : '/organizations');
                    })
                    .catch((error) => {
                        if (error.response !== undefined) {
                            setState({ ...state, openAlert: true, message: error.response.data.message, severity: "error" });
                        }
                    });
                setOpenBackDrop(false);
            })
            .catch((error) => { catchError(error) })
    }

    function catchError(error) {
        let errorMessage = error.response === undefined ? <FormattedMessage id="snackbar.error.txt" defaultMessage="Connection Error, please check you internet connection" /> : error.response.data.message;
        setLoggedInUserInfo({ logInStatus: false, roleModuleAccess: [] })
        setState({ ...state, openAlert: true, message: errorMessage, severity: "error" });
        setOpenBackDrop(false);
    }

    const updateUserPreferredTimeZone = (loginId) => {
        CommonService.saveOrUpdateOrPost(`/users/${loginId}/preferredTimeZone`, {
            "preferredTimeZone": Intl.DateTimeFormat().resolvedOptions().timeZone
        })
            .then((responseForCurrentUserInfo) => {

            })
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <VerifyTotp onClose={closeVerifyTotpDialog} open={openVerifyTotpDialog} is2FAEnabled={is2FAEnabled} userId={userId}
                updateUserPreferredTimeZone={updateUserPreferredTimeZone} loginId={loginId} />
            <CommonBackDrop open={openBackDrop} />
            <Grid container alignItems='center' className={classes.image}>
                <Grid container item xs={12} sm={12} md={4} lg={4}
                    alignItems='center' justifyContent='center' >
                    <div className={classes.paper}>

                        <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                            <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-8%', marginBottom: '5%' }}>
                                <img src={SiixLogo} className={classes.companyLogo} alt='company_logo' />
                            </Grid>
                            <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-6%', fontWeight: 600, fontSize: 20, color: 'primary' }}>
                                {window.location.hostname.includes('edi') ? <FormattedMessage id="login.siixUsertitle.header" defaultMessage="SIIX Business Portal" /> : window.location.hostname.includes('partner') ? <FormattedMessage id="login.businessPortaltitle.header" defaultMessage='Business Partner Portal' /> : "SIIX Business Portal"}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12} style={smallScreen ? { marginTop: '50px' } : { marginTop: '0' }}>
                            <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                <Avatar className={classes.avatar}>
                                    {isForgetPasswordRequested ? <ForgetPasswordIcon /> : <LockOutlinedIcon />}
                                </Avatar>
                            </Grid>
                            <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                <Typography component="h1" variant="h5">
                                    {!isForgetPasswordRequested ? <FormattedMessage id="login.signin.header" defaultMessage="Sign inn" /> : <FormattedMessage id="forget.forgotpassword.header" defaultMessage="Forgot Passwordd" />}
                                </Typography>
                            </Grid>
                        </Grid>

                        <form className={classes.form} onSubmit={isForgetPasswordRequested ? handleSubmit(onSubmit) : handleOnSubmit}>
                            {!(isForgetPasswordRequested) && (
                                <div>
                                    <FormControl fullWidth>
                                        <Controller
                                            control={control}
                                            name={labelsNamesMessagesAndHelperText.name.username}
                                            rules={{
                                                required: true,
                                                pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNumLoginId_UHF
                                            }}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    autoFocus
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    id="username"
                                                    label={<FormattedMessage id="textfield.loginid.label" defaultMessage="Login ID" />}
                                                    {...field}
                                                    error={errors.username}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconUser className={classes.iconButton} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                        <div className={classes.error}>
                                            {_.get(`${labelsNamesMessagesAndHelperText.name.username}.type`, errors) === "required" && (
                                                <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.error.loginid" defaultMessage="Login Id is required" />}</FormHelperText>
                                            )}
                                            {_.get(`${labelsNamesMessagesAndHelperText.name.username}.type`, errors) === "pattern" && (
                                                <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.errorpattern.loginid" defaultMessage="Enter a Valid LoginID" />}</FormHelperText>
                                            )}
                                        </div>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <Controller
                                            control={control}
                                            name={labelsNamesMessagesAndHelperText.name.password}
                                            rules={{
                                                required: true,
                                                // pattern: commonPatterns.commonTextfeildPattern.patternForPassword
                                            }}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={<FormattedMessage id="textfield.password.label" defaultMessage="Password" />}
                                                    type={values.showPassword ? 'text' : 'password'}
                                                    id="password"
                                                    {...field}
                                                    error={errors.password}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    edge="end"
                                                                    size="large">
                                                                    {values.showPassword ? <Visibility className={classes.iconButton} /> : <VisibilityOff className={classes.iconButton} />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                        <div className={classes.error}>
                                            {_.get(`${labelsNamesMessagesAndHelperText.name.password}.type`, errors) === "required" && (
                                                <FormHelperText className={classes.error}>{<FormattedMessage id="texfield.error.password" defaultMessage="Password is required" />}</FormHelperText>
                                            )}
                                            {_.get(`${labelsNamesMessagesAndHelperText.name.password}.type`, errors) === "pattern" && (
                                                <FormHelperText className={classes.error}>{<FormattedMessage id="texfield.errorpattern.password" defaultMessage="Enter a Valid Password" />}</FormHelperText>
                                            )}
                                        </div>
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit(onSubmit)}
                                        className={classes.submit}
                                    >
                                        {<FormattedMessage id="signin.button.label" defaultMessage="SIGN IN" />}
                                    </Button>
                                </div>)}

                            {(isForgetPasswordRequested) && (
                                <React.Fragment>
                                    <FormControl fullWidth>
                                        <Controller
                                            control={control}
                                            name={labelsNamesMessagesAndHelperText.name.loginId}
                                            rules={{
                                                required: true,
                                                // pattern: commonPatterns.commonTextfeildPattern.patternForSingleEmailId
                                            }}
                                            defaultValue=""
                                            render={({ field: { onChange } }) => (
                                                <TextField
                                                    className={classes.textField}
                                                    required={true}
                                                    autoFocus
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={<FormattedMessage id="textfield.loginid.label" defaultMessage="Login ID" />}
                                                    error={errors.loginId}
                                                    onChange={(event) => {
                                                        onChange(event.target.value)
                                                        if (event.target.value !== null) {
                                                            setLoginId(event.target.value)
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        shrink:true,
                                                        classes:{asterisk:classes.asterisk}
                                                    }}
                                                />
                                            )}
                                        />
                                        <div className={classes.error}>
                                            {_.get(`${labelsNamesMessagesAndHelperText.name.loginId}.type`, errors) === "required" && (
                                                <FormHelperText className={classes.error}>{<FormattedMessage id="common.textfield.error.loginid" defaultMessage="Login Id is required" />}</FormHelperText>
                                            )}

                                        </div>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <Controller
                                            control={control}
                                            name={labelsNamesMessagesAndHelperText.name.emailId}
                                            rules={{
                                                required: true,
                                                pattern: commonPatterns.commonTextfeildPattern.patternForSingleEmailId
                                            }}
                                            defaultValue=""
                                            render={({ field: { onChange } }) => (
                                                <TextField
                                                    className={classes.textField}
                                                    required={true}
                                                    autoFocus
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={<FormattedMessage id="textfield.emailid.label" defaultMessage="Email ID" />}
                                                    error={errors.emailId}
                                                    onChange={(event) => {
                                                        onChange(event.target.value)
                                                        if (event.target.value !== null) {
                                                            setEmailId(event.target.value)
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        shrink:true,
                                                        classes:{asterisk:classes.asterisk}
                                                    }}
                                                />
                                            )}
                                        />
                                        <div className={classes.error}>
                                            {_.get(`${labelsNamesMessagesAndHelperText.name.emailId}.type`, errors) === "required" && (
                                                <FormHelperText className={classes.error}>{<FormattedMessage id="common.textfield.error.emailid" defaultMessage="Email Id is required" />}</FormHelperText>
                                            )}
                                            {_.get(`${labelsNamesMessagesAndHelperText.name.emailId}.type`, errors) === "pattern" && (
                                                <FormHelperText className={classes.error}>{<FormattedMessage id="common.textField.error.emailid" defaultMessage="Please enter valid Email Id" />}</FormHelperText>
                                            )}
                                        </div>
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit(onSubmit)}
                                        className={classes.submit}
                                    >
                                        {<FormattedMessage id="forget.submit.button" defaultMessage="SUBMIT" />}
                                    </Button>
                                </React.Fragment>
                            )}
                            <VerifyOTP onClose={handleClose} open={open} emailId={emailId} setForgetPasswordRequested={setForgetPasswordRequested}
                                causeOfGeneration={causeOfGeneration} onResend={validateLoginIdAndSendOtp} setRequestedFormVerifyOtpDialog={setRequestedFormVerifyOtpDialog}
                                loginId={loginId} />

                            <Grid item container>
                                <Grid item container alignItems='center' justifyContent="flex-start" xs={6} sm={9} md={4} lg={6}>
                                    <Link component='button' variant="body2" className={classes.forgetPassword}
                                        onClick={(() => {
                                            !isForgetPasswordRequested ? setForgetPasswordRequested(true) : setForgetPasswordRequested(false)
                                        })}>
                                        {!isForgetPasswordRequested ? <FormattedMessage id="login.forgotpassword.link" defaultMessage="Forgot Password?" /> : <FormattedMessage id="forget.backtologinpage.link" defaultMessage="Back To LoginPage" />}
                                    </Link>
                                </Grid>
                                <Grid item container xs={6} sm={3} md={7} lg={6} justifyContent="flex-end"  >
                                    <Selectlang isLoginPage={true} />
                                </Grid>
                            </Grid>
                            <Box mt={2}>
                                <Copyright companyName={COPY_RIGHT_COMPANY_NAME} companyURL={COPY_RIGHT_COMPANY_URL} />
                            </Box>
                            <Box mt={2}>
                                <PoweredBy companyName={POWERED_BY_COMPANY_NAME} companyURL={POWERED_BY_COMPANY_URL_VITALMED} />
                            </Box>
                        </form>
                    </div>
                </Grid>
                <Grid container item xs={12} sm={12} md={4} lg={4}></Grid>
            </Grid>
        </div>
    );
}

export default LoginPage;