import { Button, Grid, CssBaseline, CircularProgress, useTheme, useMediaQuery, Tooltip, FormControl, FormHelperText, TextField, InputLabel, Switch, Alert, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import useStyles from '../../../common/CommonStyle/CommonStyle';
import EditIcon from '@mui/icons-material/Edit';
import CreateIcon from '@mui/icons-material/Add';
import ViewIcon from '@mui/icons-material/Visibility';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import CustomBreadCrumb from '../../../common/CustomBreadCrumb';
import EntityCodeCombo from '../../../common/Combos/EntityCodeCombo';
import OrgCodeCombo from '../../../common/Combos/OrgCodeCombo';
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';
import { Controller, useForm } from 'react-hook-form';
import _ from "lodash/fp";
import { CommonService } from '../../../../services';
import InfoIcon from '@mui/icons-material/Info';
import CommonBackDrop from '../../../common/BackDrop';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),

    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const CreateOrEditProject = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const { onClose, open, title, mode, projectObjectToBeEditedOrView } = props;
    const commonPatterns = GlobalEdiApiConstants();
    const theme = useTheme();
    const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());
    const currentUser = CommonService.getLoggedInUserInfo();
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [projectObjectToBeEdited, setProjectObjectToBeEdited] = React.useState(projectObjectToBeEditedOrView);
    const [componentList, setComponentList] = useState(title);
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [branchValue, setBranchValue] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [erpOrgCodeValue, setErpOrgCode] = useState('');
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
    const [disablesaveButton, isSetSaveButtonDisabled] = useState(true);
    const [disabledForReadMode, isDisabledForReadMode] = useState(false);
    const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('');
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [activeToBeEdited, setActiveToBeEdited] = useState('');

    const [defaultOrgObj, setDefaultOrgObj] = useState({});

    useEffect(() => {
        if (orgInfoOfLeggedUser.erpOrgCode && mode === 'create') {
            setDefaultOrgObj({
                erpOrgCode: orgInfoOfLeggedUser.erpOrgCode, orgName: orgInfoOfLeggedUser.orgName,
                valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}`
            });
        }
    }, [orgInfoOfLeggedUser]);

    const pageLabelsConstantsAndMessages = {
        label: {
            active: 'active'
        },
        name: {
            orgCode: 'organization',
            entityCode: 'entity',
            projectCode: 'projectCode',
            projectName: 'projectName',
            active: 'active',
        }
    }

    const handleClose = (value) => {
        setComponentList(title);
        onClose(value);
        handlereset(true);
        setBranchValue('');
        setValue(`${pageLabelsConstantsAndMessages.name.projectCode}`, '');
        setValue(`${pageLabelsConstantsAndMessages.name.entityCode}`, '');
    }

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });

    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };

    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const [projectReadOnlyFields, setProjectReadOnlyFields] = useState({
        entityCode: false,
        projectCode: false,
        projectName: false,
        active: false
    });

    const { handleSubmit, formState: { errors, isDirty, isValid }, control, reset, getValues, setValue } = useForm({
        mode: "all",
        reValidateMode: 'all',
        defaultValues: {
            entity: '',
            projectCode: '',
            projectName: '',
        }
    });

    useEffect(() => {
        if (mode === 'edit' || mode === 'view') {
            setActiveToBeEdited(projectObjectToBeEdited);
        }
    }, [mode, projectObjectToBeEdited])

    function functionForReset(responseData) {
        reset({
            organization: responseData.organization !== undefined ? { erpOrgCode: responseData.organization.erpOrgCode, orgName: responseData.organization.orgName } : null,
            entity: responseData.entity !== undefined ? { erpEntityCode: responseData.entity.erpEntityCode, entityName: responseData.entity.entityName } : null,
            projectCode: responseData.projectCode !== undefined ? responseData.projectCode : '',
            projectName: responseData.projectName !== undefined ? responseData.projectName : '',
            active: responseData.active !== undefined ? responseData.active : true,
        });
        setBranchValue(responseData.entity !== undefined ? { valuesToBeDisplayed: responseData.entity } : []);
        setErpOrgCode(responseData.organization !== undefined ? { valueToBeDisplayed: responseData.organization } : defaultOrgObj);
    }

    useEffect(() => {
        setProjectObjectToBeEdited(projectObjectToBeEditedOrView);
    }, [projectObjectToBeEditedOrView, mode]);

    useEffect(() => {
        let currentRouterComponent;
        if (mode === 'create') {
            isRestoreButtonDisabled(false);
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            isDisabledForReadMode(false);
            currentRouterComponent = [
                {
                    path: null,
                    name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                    iconName: CreateIcon,
                }
            ];
        } else if (mode === 'edit') {
            isRestoreButtonDisabled(false);
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            functionForReset(projectObjectToBeEdited);
            isDisabledForReadMode(false);
            isSetSaveButtonDisabled(true);
            setProjectReadOnlyFields({
                ...projectReadOnlyFields, entityCode: false, projectCode: false, projectName: false, active: false
            })
            currentRouterComponent = [
                {
                    path: null,
                    name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                    iconName: EditIcon,
                }
            ]
        } else if (mode === 'view') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            isRestoreButtonDisabled(true);
            isSetSaveButtonDisabled(true);
            functionForReset(projectObjectToBeEdited);
            isDisabledForReadMode(true);
            setProjectReadOnlyFields({
                ...projectReadOnlyFields, entityCode: true, projectCode: true, projectName: true, active: true
            })
            currentRouterComponent = [
                {
                    path: null,
                    name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
                    iconName: ViewIcon,
                }
            ]
        }
        setComponentList(title.concat(currentRouterComponent !== undefined ? currentRouterComponent : []));
    }, [mode, projectObjectToBeEdited]);

    const onSubmit = data => {
        data['id'] = (mode === 'create') ? 0 : projectObjectToBeEditedOrView.id;
        data['createdBy'] = (mode === 'create') ? currentUser.userName : projectObjectToBeEditedOrView.createdBy;
        data['createdTime'] = (projectObjectToBeEditedOrView.createdTime === undefined) ? '' : projectObjectToBeEditedOrView.createdTime;
        data['updatedBy'] = currentUser.userName;
        data['updatedTime'] = (projectObjectToBeEditedOrView.updatedTime === undefined) ? '' : projectObjectToBeEditedOrView.updatedTime;
        data['organization'] = (projectObjectToBeEditedOrView.organization === undefined) ? { erpOrgCode: orgInfoOfLeggedUser.erpOrgCode } : projectObjectToBeEditedOrView.organization;
        setIsFormSubmitted(true);
        CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.project, data)
            .then((response) => {
                setIsFormSubmitted(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success" });
                handleClose(true);
            })
            .catch(function (error) {
                setIsFormSubmitted(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            });

    }

    const branchComboProperty = {
        name: pageLabelsConstantsAndMessages.name.entityCode,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.entityCode.label" }),
        isAutofocus: false,
        isDisabled: (mode === 'edit' || mode === 'view') ? true : false,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.entity
    }

    const resetAllClearTheBasicInfo = () => {
        if (mode === 'edit') {
            functionForReset(projectObjectToBeEdited)
            setProjectObjectToBeEdited({
                ...projectObjectToBeEdited
            })
            setActiveToBeEdited({ ...projectObjectToBeEdited })
        } else {
            reset({
                entityCode: '',
                projectCode: '',
                projectName: '',
            });
            setBranchValue([])
        }
        setProjectObjectToBeEdited({ ...projectObjectToBeEdited });
        setActiveToBeEdited({ ...activeToBeEdited, active: true })
    }

    const onError = ((error) => {
    })

    const handlereset = (value) => {
        reset({ keepErrors: true, keepDirty: true, keepIsSubmitted: false, keepTouched: false, keepIsValid: false, keepSubmitCount: false, });
        reset(value)
    }

    const validateRequiredFields = (getValues('entity') === '' && getValues('projectCode') === '')

    useEffect(() => {
        if (!(mode === 'view')) {
            if ((mode === 'create') && validateRequiredFields || !isValid) {
                isSetSaveButtonDisabled(true)
            } else if ((mode === 'create') && !validateRequiredFields) {
                isSetSaveButtonDisabled(false)
            } else if ((mode === 'edit') && (isDirty) || !isValid) {
                isSetSaveButtonDisabled(false)
            } else {
                isSetSaveButtonDisabled(true);
            }
        }
    }, [branchValue, projectObjectToBeEdited, activeToBeEdited, mode, isValid, isDirty])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.root}>
                <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} >
                        <Dialog fullWidth maxWidth='lg' onClose={handleClose} aria-labelledby="create-or-delete-dialog" open={open} disableBackdropClick={true} >
                            <DialogTitle className={classes.titleCommonDialogComponent}>
                                <Grid item container alignItems='center' item xs={12} sm={12} md={12} lg={12} style={{ marginLeft: '-1%' }} >
                                    <Grid item container alignItems='center' xs={6} sm={8} md={8} lg={6}>
                                        <CustomBreadCrumb componentList={componentList} isDialog={true} />
                                    </Grid>
                                </Grid>
                            </DialogTitle>

                            <DialogContent dividers style={smallScreen ? { minHeight: '30vh' } : { minHeight: '30vh' }} >
                                <Grid item container xs={8} sm={12} md={12} lg={12} className={classes.textfieldSpacing} style={{ marginBottom: '-1.4%' }}>
                                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                            <EntityCodeCombo comboProperty={branchComboProperty}
                                                control={control}
                                                errors={errors}
                                                comboValue={branchValue}
                                                setComboValue={setBranchValue}
                                            />
                                        </Grid>
                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    control={control}
                                                    name={pageLabelsConstantsAndMessages.name.projectCode}
                                                    rules={{
                                                        required: true,
                                                        maxLength: 100,
                                                        pattern: commonPatterns.commonTextfeildPattern.patternNumericOnly
                                                    }}

                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextField
                                                            className={classes.textField}
                                                            variant="outlined"
                                                            margin="normal"
                                                            id="projectCode"
                                                            size='small'
                                                            label={<FormattedMessage id="common.textfield.column.project" defaultMessage="projectCode" />}
                                                            required={true}
                                                            {...field}
                                                            error={errors.projectCode}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {
                                                                    asterisk: classes.asterisk
                                                                }
                                                            }}
                                                            InputProps={{
                                                                classes: { notchedOutline: classes.specialOutline }
                                                            }}
                                                            disabled={mode === 'view' ? true : false}
                                                        />
                                                    )}
                                                />

                                                <div className={classes.error}>
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.projectCode}.type`, errors) === "required" && (
                                                        <FormHelperText className={classes.error}>
                                                            {<FormattedMessage id="columnname.projectCode.label" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}
                                                        </FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.projectCode}.type`, errors) === "pattern" && (
                                                        <FormHelperText className={classes.error}>   {<FormattedMessage id="columnname.projectCode.label" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UCSHF}</FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.projectCode}.type`, errors) === "maxLength" && (
                                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.projectCode.label" defaultMessage="projectCode" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>

                                                    )}
                                                </div>

                                            </FormControl>
                                        </Grid>
                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    control={control}
                                                    name={pageLabelsConstantsAndMessages.name.projectName}
                                                    rules={{
                                                        required: false,
                                                        maxLength: 100,
                                                        // pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UHF
                                                    }}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextField
                                                            className={classes.textField}
                                                            variant="outlined"
                                                            margin="normal"
                                                            id="projectName"
                                                            size='small'
                                                            label={<FormattedMessage id="columnname.projectName.label" defaultMessage="projectName" />}
                                                            required={false}
                                                            {...field}
                                                            error={errors.projectName}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {
                                                                    asterisk: classes.asterisk
                                                                }
                                                            }}
                                                            InputProps={{
                                                                classes: { notchedOutline: classes.specialOutline }
                                                            }}

                                                            disabled={mode === 'view' ? true : false}
                                                        />
                                                    )}
                                                />

                                                <div className={classes.error}>
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.projectName}.type`, errors) === "maxLength" && (
                                                        <FormHelperText className={classes.error}>
                                                           {<FormattedMessage id="textfield.error.user.projectnamecannotexceed" defaultMessage="Project Name cannot exceed more than 100 chars" />}
                                                        </FormHelperText>
                                                    )}
                                                    {/* {_.get(`${pageLabelsConstantsAndMessages.name.projectName}.type`, errors) === "pattern" && (
                                                        <FormHelperText className={classes.error}> {<FormattedMessage id="columnname.projectName.label" defaultMessage="User Name " />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNumericAndSpecialChar}</FormHelperText>
                                                    )} */}
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                                            <Grid container justifyContent='flex-start' alignItems='center'>
                                                <Controller
                                                    render={({
                                                        field: { onChange } }) => (<Switch
                                                            color='primary'
                                                            checked={activeToBeEdited.active !== undefined ? activeToBeEdited.active : true}
                                                            onChange={((event) => {
                                                                onChange(!(activeToBeEdited.active !== undefined ? activeToBeEdited.active : true));
                                                                setActiveToBeEdited({ ...activeToBeEdited, active: event.target.checked })
                                                            })}
                                                            disabled={mode === 'view' ? true : false}
                                                        />)}
                                                    type="checkbox"
                                                    name={pageLabelsConstantsAndMessages.name.active}
                                                    control={control}
                                                    defaultValue={activeToBeEdited.active !== undefined ? activeToBeEdited.active : true}
                                                    disabled={mode === 'view' ? true : false}
                                                />
                                                <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>

                            {!mobileScreen && (
                                <DialogActions className={classes.dialogAction} >
                                    <Grid container justifyContent="flex-start" alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} >
                                        {(mode === 'create' || mode === 'edit') ?
                                            <InfoIcon color="primary" /> : null}
                                        {(mode === 'create' || mode === 'edit') ?
                                            <Typography justifyContent="flex-start" style={{ fontSize: 12, marginLeft: '1%' }}><FormattedMessage id="textfield.placeholder.dialogActions" defaultMessage="All the '*' marked fields are mandatory to submit the form" /></Typography> : null}
                                    </Grid>
                                    <Grid item container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} >
                                        <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2} endIcon={<ClearIcon />}
                                            onClick={() => handleClose(false)}
                                        ><FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />
                                        </Button>

                                        <Button size='small' variant="contained" color="secondary" className={classes.stateButtonToRestore}
                                            disabled={disableRestoreButton} elevation={2} endIcon={<IconRestore />} onClick={(() => { resetAllClearTheBasicInfo() })}
                                        > <FormattedMessage id="common.button.reset.lable" defaultMessage="Restore" />
                                        </Button>

                                        {(mode === 'edit') ?
                                            <Button size='small'
                                                variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                                endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />}
                                                disabled={isFormSubmitted || disablesaveButton}
                                                onClick={handleSubmit(onSubmit, onError)}>
                                                {saveOrUpdateButtonLabel}
                                            </Button> : <Button size='small'
                                                variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                                endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />}
                                                disabled={isFormSubmitted || disablesaveButton}
                                                onClick={handleSubmit(onSubmit, onError)}>
                                                {saveOrUpdateButtonLabel}
                                            </Button>}
                                    </Grid>
                                </DialogActions>
                            )}

                            {mobileScreen && (<Grid item container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                <DialogActions className={classes.dialogAction} >
                                    <Grid item container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.topGrid} >
                                        <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                                            <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2}
                                                onClick={() => handleClose(false)}
                                            ><ClearIcon />
                                            </Button>
                                        </Tooltip>

                                        <Tooltip title={<FormattedMessage id="common.button.reset.lable" />}>
                                            <Button size='small' variant="contained" color="secondary" className={classes.stateButtonToRestore}
                                                disabled={disableRestoreButton} elevation={2} onClick={(() => { resetAllClearTheBasicInfo() })}><IconRestore />
                                            </Button>
                                        </Tooltip>

                                        <Tooltip title={(mode === 'create') ? <FormattedMessage id="common.button.save" /> : <FormattedMessage id="common.button.update" />}>
                                            {(mode === 'edit') ?
                                                <Button size='small'
                                                    variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                                    endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : null}
                                                    disabled={isFormSubmitted || disablesaveButton}
                                                    onClick={handleSubmit(onSubmit, onError)}>
                                                    <IconSaveOrUpdate /> </Button> :
                                                <Button size='small'
                                                    variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                                    disabled={isFormSubmitted || disablesaveButton}
                                                    endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : null}
                                                    onClick={handleSubmit(onSubmit, onError)}>
                                                    <IconSaveOrUpdate /></Button>}
                                        </Tooltip>
                                    </Grid>
                                </DialogActions>
                            </Grid>)}
                        </Dialog>

                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

export default CreateOrEditProject;