import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CssBaseline, Grid, Snackbar, Tooltip, IconButton, Paper, Button, Badge } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from "lodash/fp";
import { getGridNumericColumnOperators, getGridStringOperators, GridFilterPanel, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, LicenseInfo, DataGridPro, getGridDateOperators } from '@mui/x-data-grid-pro';
import IconSettings from '@mui/icons-material/Settings';
import IconSupplier from '@mui/icons-material/LocalShipping';
import IconEmailMessageConfig from '@mui/icons-material/ContactMail';
import CreateIcon from '@mui/icons-material/Add';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/EditOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Alert } from '@mui/lab';
import IconUserManagement from '@mui/icons-material/AccountCircle';
import CommonGridPagination from '../common/CommonGridPagination/CommonGridPagination';
import useStyles from '../common/CommonStyle/CommonStyle';
import { CustomFilters } from '../common/CustomFilters/CustomFilters';
import EmailMessageConfigDiaglog from '../BPEmailMessageConfig/EmailMessageConfigDialog';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import { SupportedLanguageDetails } from '../common/SupportedLanguageDetails/SupportedLanguageDetails';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';
import { ModuleAccessPermissionKey } from '../../Constants/ModuleAccessKey';
import { CommonService } from '../../services';
import CustomFilterComponent, { GetFilterableColumns } from '../common/CustomFilterComponent';
import { FilterList } from '@mui/icons-material';

const columnFieldsName = {
    contactName: 'contactName',
    contactSalutation: 'contactSalutation',
    project: 'project',
    countryCode: 'countryCode',
    contactNo: 'contactNo',
    emailTo: 'emailTo',
    emailCc: 'emailCc',
    emailBcc: 'emailBcc',
    bpType: 'bpType',
    bpCode: 'bpCode',
    bpName: 'bpName',
    createdTime: 'createdTime',
    createdBy: 'createdBy',
    updatedTime: 'updatedTime',
    updatedBy: 'updatedBy',
    validFrom: 'validFrom',
    validTo: 'validTo',
    entityContact: 'entityContact'
}

const EmailMessageConfig = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const { mode, componentListForBp, supplierObjectToBeEditedOrView, customerObjectToBeEditedOrView } = props;
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const emailMessageConfigModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.emailMessageConfig];
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();

    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density',
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
        name: {
            name: 'name'
        }
    };

    const componentListForSupplier = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.settings" defaultMessage="Settings" />,
            iconName: IconSettings,
        },
        {
            path: '/suppliers',
            name: <FormattedMessage id="common.menu.module.breadcrumb.supplier" defaultMessage="Suppliers" />,
            iconName: IconSupplier,
        },
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.emailmessageconfig" />,
            iconName: IconEmailMessageConfig,
        }
    ];

    const componentListForCustomer = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.settings" defaultMessage="Settings" />,
            iconName: IconSettings,
        },
        {
            path: '/customer',
            name: <FormattedMessage id="common.menu.breadcrumb.customer" defaultMessage="Customer" />,
            iconName: IconUserManagement,
        },
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.emailmessageconfig" />,
            iconName: IconEmailMessageConfig,
        }
    ];

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const [paramBody, setParamBody] = useState({ ...apiUrlAndParamBodyConstants.paramBodyForGetListApis.bpEmailMessageConfigs, bpCode: columnFieldsName.bpCode });

    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [refreshTable, setRefreshTable] = useState(false);
    const [OpenEmailMessageConfigDialog, setOpenEmailMessageConfigDialog] = useState(false);
    const [modeForEmailConfig, setModeForEmailConfig] = useState('');
    const [emailMessageConfigObjectToBeEditedOrView, setEmailMessageConfigObjectToBeEditedOrView] = useState({});
    const [serachedProject, setSearchedProject] = useState('');
    const [searchedValidFromDate, setSearchedValidFromDate] = useState(null);
    const [searchedValidToDate, setSearchedValidToDate] = useState(null);

    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    const defaulSelectedFilters = [];
    const [selectedFilters, setSelectedFilters] = useState([]);

    const closeEmailMessageConfigDialog = (confirmed) => {
        if (confirmed) {
            setRefreshTable(!refreshTable);
        }
        setEmailMessageConfigObjectToBeEditedOrView({});
        setOpenEmailMessageConfigDialog(false);
    }
    const commonBreadCrumbToBeUsedForDialog = componentListForCustomer;

    const columnsForEmailMessageConfig =
        [
            {
                field: 'action',
                headerName: intl.formatMessage({ id: "columnname.action.label" }),
                width: mode === 'read' ? 100 : 130,
                sortable: false,
                hide: false,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (
                    <div>
                        {(emailMessageConfigModuleAccess !== undefined ? emailMessageConfigModuleAccess.editFlag : false) && (mode !== 'read') && (
                            <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    setModeForEmailConfig('edit');
                                    setEmailMessageConfigObjectToBeEditedOrView(cellParams.row);
                                    setOpenEmailMessageConfigDialog(true)
                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar} size="large">
                                        <EditIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                        {(emailMessageConfigModuleAccess !== undefined ? emailMessageConfigModuleAccess.readFlag : false) && (
                            <Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.readButton} />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    setModeForEmailConfig('read')
                                    setEmailMessageConfigObjectToBeEditedOrView(cellParams.row)
                                    setOpenEmailMessageConfigDialog(true)
                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar} size="large">
                                        <ViewIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                    </div>
                )
            },
            {
                field: columnFieldsName.project,
                width: 140,
                headerName: intl.formatMessage({ id: "common.textfield.column.project" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.contactName,
                headerName: intl.formatMessage({ id: "columnname.textfield.attntoname.label" }),
                width: 180,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.countryCode,
                headerName: intl.formatMessage({ id: "columnname.textfield.countrycodeph.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 170,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.contactNo,
                headerName: intl.formatMessage({ id: "columnname.textfield.attntocontactno.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 210,
                sortable: true,
                filterable: false,
                type: 'string',
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.emailTo,
                headerName: intl.formatMessage({ id: "textfield.emailTo.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 350,
                sortable: true,
                filterable: false,
                type: 'string',
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.emailCc,
                headerName: intl.formatMessage({ id: "textfield.emailCc.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 350,
                sortable: true,
                filterable: false,
                type: 'string',
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.emailBcc,
                headerName: intl.formatMessage({ id: "columnname.textfield.emailbcc.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 350,
                sortable: true,
                filterable: false,
                type: 'string',
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.validFrom,
                headerName: intl.formatMessage({ id: "columnname.validfromdate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 190,
                sortable: true,
                type: 'date',
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (
                    <span>{cellParams.row.validFromDisp}</span>
                )
            },
            {
                field: columnFieldsName.validTo,
                headerName: intl.formatMessage({ id: "columnname.validtodate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 190,
                sortable: true,
                filterable: true,
                type: 'date',
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (
                    <span>{cellParams.row.validToDisp}</span>
                )
            },
            {
                field: columnFieldsName.entityContact,
                headerName: intl.formatMessage({ id: "columnname.entitycontact.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 165,
                sortable: true,
                filterable: false,
                type: 'date',
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.createdTime,
                headerName: intl.formatMessage({ id: "columnname.columnnamecreatedtime.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.createdDtDisp}</span>)
            },
            {
                field: columnFieldsName.createdBy,
                headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.updatedTime,
                headerName: intl.formatMessage({ id: "columnname.updatedtime.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.lastUpdatedDtDisp}</span>)
            },
            {
                field: columnFieldsName.updatedBy,
                headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
        ]

    const [columns, setColumns] = useState([]);
    useEffect(() => {
        setColumns(columnsForEmailMessageConfig)
    }, [selectedLanguage])

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setTableLoadingStatus(true);
        setTableData([]);
        CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.bpEmailMessageConfigs, paramBody)
            .then((response) => {
                setTableLoadingStatus(false);
                setTotalRowCount(response.data.totalElements);
                setTableData(response.data.content);
            })
            .catch(function (error) {
                setTableLoadingStatus(false);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [refreshTable, paramBody])

    if (columns.length > 0) {

        const projectColumn = columns.find((column) => column.field === columnFieldsName.project);
        const projectColIndex = columns.findIndex((col) => col.field === columnFieldsName.project);

        const projectFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.TextFieldInputValue,
            }),
        );

        columns[projectColIndex] = {
            ...projectColumn,
            filterOperators: projectFilterOperators,
        };


        const validFromDateColumn = columns.find((column) => column.field === columnFieldsName.validFrom);
        const validFromDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.validFrom);

        const validFromDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[validFromDateColIndex] = {
            ...validFromDateColumn,
            filterOperators: validFromDateFilterOperators,
        };

        const validToDateColumn = columns.find((column) => column.field === columnFieldsName.validTo);
        const validToDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.validTo);

        const validToDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[validToDateColIndex] = {
            ...validToDateColumn,
            filterOperators: validToDateFilterOperators,
        };
    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let project = '';
        let validFrom = '';
        let validTo = '';
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'project':
                    project = columns
                    break;
                case 'validFrom':
                    validFrom = columns
                    break;
                case 'validTo':
                    validTo = columns
                    break;
            }
        })

        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    project: project.value,
                    validFrom: validFrom.value,
                    validTo: validTo.value,
                }
            }
        })
    }

    useEffect(() => {
        setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container className={classes.rootForRole}>
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <Paper elevation={3} className={classes.gridHeightAndWidthForEmailMessageConfig}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            density="compact"
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableColumnMenu={false}
                            disableMultipleColumnsSorting={true}
                            hideFooter={true}
                            localeText={localtext}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            onSortModelChange={(params) => {
                                let sortModel = params[0];
                                if (sortModel !== undefined) {
                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                }
                            }}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center">
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton style={{ marginRight: '5px', paddingRight: '2px' }} variant="outlined" size="medium" className={classes.customeToolbar} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'>
                                                        <GridToolbarDensitySelector style={{ marginRight: '5px', paddingRight: '3px' }} variant="outlined" size="medium" className={classes.customeToolbar} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterList />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                style={{ marginRight: '5px' }}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    {(emailMessageConfigModuleAccess !== undefined ? emailMessageConfigModuleAccess.createFlag : false) && (mode !== 'read') && (<Tooltip title={<FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                style={{ marginRight: '5px' }}
                                                                onClick={(() => {
                                                                    setModeForEmailConfig('create');
                                                                    setOpenEmailMessageConfigDialog(true);
                                                                })}
                                                            >
                                                                <CreateIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>)}
                                                    <EmailMessageConfigDiaglog
                                                        open={OpenEmailMessageConfigDialog}
                                                        onClose={closeEmailMessageConfigDialog}
                                                        title={commonBreadCrumbToBeUsedForDialog}
                                                        mode={modeForEmailConfig}
                                                        emailMessageConfigToBeEdited={emailMessageConfigObjectToBeEditedOrView}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                                FilterPanel: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                        >
                                            <Grid container
                                                item
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                xs={6} sm={6} md={6} lg={6}
                                            >
                                                <GridFilterPanel />
                                            </Grid>
                                            <Grid container
                                                item
                                                justifyContent="flex-end"
                                                alignItems="flex-end"
                                                xs={6} sm={6} md={6} lg={6}
                                                style={{ marginLeft: '-24%' }}
                                            >
                                                <Button color="primary" style={{ paddingBottom: '10px' }} onClick={(() => {
                                                    setParamBody(prevState => {
                                                        return {
                                                            ...prevState, body: {
                                                                project: serachedProject,
                                                                validFrom: searchedValidFromDate !== null ? searchedValidFromDate.substr(1, 10) : null,
                                                                validTo: searchedValidToDate !== null ? searchedValidToDate.substr(1, 10) : null,
                                                            }
                                                        }
                                                    });
                                                })}>
                                                    <FormattedMessage id="common.applyfilter.lable" defaultMessage=
                                                        {pageLabelsConstantsAndMessages.label.applyFilterButton} />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    );
                                }
                            }}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                            onFilterModelChange={async (params) => {
                                let filterModelItem = params.items;
                                if (filterModelItem.length > 0) {
                                    let project = '';
                                    let validFrom = null;
                                    let validTo = null;
                                    // for (let index = 0; index < filterModelItem.length; index++) {
                                    //     if (filterModelItem[index].columnField === columnFieldsName.project) {
                                    //         project = await filterModelItem[index].value === undefined ? '' : filterModelItem[index].value;
                                    //         break;
                                    //     }
                                    // }

                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.project) {
                                            project = await filterModelItem[index].value === undefined ? '' : JSON.parse(filterModelItem[index].value);
                                            break;
                                        }
                                    }
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.validFrom) {
                                            validFrom = await ((filterModelItem[index].value !== undefined && filterModelItem[index].value !== null && filterModelItem[index].value !== 'null') ? filterModelItem[index].value : null);
                                            break;
                                        }
                                    }
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.validTo) {
                                            validTo = await ((filterModelItem[index].value !== undefined && filterModelItem[index].value !== null && filterModelItem[index].value !== 'null') ? filterModelItem[index].value : null);
                                            break;
                                        }
                                    }
                                    setSearchedValidFromDate(validFrom);
                                    setSearchedValidToDate(validTo);
                                    setSearchedProject(project);
                                }
                            }}
                        />
                    </div>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}
export default EmailMessageConfig;