import { Backdrop, CircularProgress} from '@mui/material';
import React from 'react';
import useStyles from '../CommonStyle/CommonStyle'

function CommonBackDrop(props) {
    const open = props.open;
    const classes = useStyles();

    return (
        <div>
          <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      );
}

export default CommonBackDrop;