import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Portal from './components/common/Portal';
import LoginPage from './components/common/LoginPage'
import ProtectedRoutes from './components/ProtectedRoutes';
import Organization from './components/Settings/Organization';
import Unauthorized from './components/common/Unauthorized';
import Region from './components/Settings/Region';
import Branch from './components/Settings/Branch';
import Role from './components/Settings/Role';
import UserManagement from './components/Settings/UserManagement';
import Supplier from './components/Supplier';
import Report from './components/Report';
import CreateOrEditRole from './components/Settings/Role/CreateOrEditRole/CreateOrEditRole';
import CreateOrEditUser from './components/Settings/UserManagement/CreateOrEditUser';
import EditPurchaseOrder from './components/Order/EditPurchaseOrder';
import ChangeOrForgotPassword from './components/common/ChangeOrForgotPassword';
import Customer from './components/Customer/Customer';
import EmailTemplate from './components/EmailTemplate/EmailTemplate';
import CreateOrEditEmailTemplate from './components/EmailTemplate/CreateOrEditEmailTemplate';
import BPGroup from './components/common/BPGroup/BPGroup';
import MessageConfigHdr from './components/MessageConfig';
import CreateOrEditMessageConfigHdr from './components/MessageConfig/CreateOrEditMessageConfigHdr';
import EmailMessageConfig from './components/EmailMessageConfig/EmailMessageConfig'
import ErpInterface from './components/ERPInterface/ERPinterface'
import CreateOrEditErpinterface from './components/ERPInterface/CreateOrEditErpInterface/CreateOrEditErpInterface';
import ErpInterfaceStatus from './components/ERPInterfaceStatus/ERPInterfaceStatus';
import MessageTransmissionLog from './components/MessageTransmissionLog/MessageTransmissionLog';
import CreateOrEditBpGroup from './components/common/CreateOrEditBpGroup';
import fakeBackendService from './fakeBackendForEmailReportTemplate/fakebackendService';
import EmailReportTemplate from './components/EmailReportTemplate';
import CreateOrEditEmailReportTemplate from './components/EmailReportTemplate/CreateOrEditEmailReportTemplate';
import TransmissionConfiguration from './components/TransmissionConfiguration/TransmissionConfiguration';
import EmailMessageLog from './components/EmailMessageLog';
import CreateOrEditTransmissionConfiguration from './components/TransmissionConfiguration/CreateOrEditTransmissionConfiguration';
import Order from './components/Order/Order'
import AdvanceShipmentNoteOutbound from './components/AdvanceShipmentNoteOutboundHeader';
import InvoiceHeader from './components/InvoiceHeader';
import PurchaseOrderForecastOutbound from './components/PurchaseOrderForecastOutbound';
import EditOrViewPurchaseOrderForecastOutbound from './components/PurchaseOrderForecastOutbound/EditOrViewPurchaseOrderForecastOutbound';
import { LoggedInUserDetails } from './components/common/LoggedInUserDetails/LoggedInUserDetails';
import { CommonService } from './services/CommonServices.service';
import TabsForPoPdfVmiApproval from './components/common/TabsForPodPdfVmiApproval/TabsForPoPdfVmiApproval';
import Project from './components/Settings/Project/Project';
import EmailNotificationSubscription from './components/EmailNotificationSubscription/EmailNotificationSubscription';
import EmailServerConfig from './components/EmailServerConfig/EmailServerConfig';
import ImportTemplate from './components/ImportTemplate';
import CreateOrEditImportTemplate from './components/ImportTemplate/CreateOrEditImportTemplate/CreateOrEditImportTemplate';
import AdvanceShipmentNoteInbound from './components/AdvanceShipmentNoteInboundHeader/AdvanceShipmentNoteInboundHeader';
import AdvanceShipmentNoteInboundDtls from './components/AdvanceShipmentNoteInboundDtl/AdvanceShipmentNoteInboundHeaderDtl';
import InvoiceHeaderInbound from './components/InvoiceHeaderInbound/InvoiceHeaderInbound';
import InvoiceInboundDtl from './components/InvoiceInboundDtl/InvoiceInboundDtl';
import PurchaseForecastOutbound from './components/PurchaseForecastOutbound/PurchaseForecastOutbound';
import EditOrViewPurchaseForecastOutbound from './components/PurchaseForecastOutbound/EditOrViewPurchaseForecastOutbound/EditOrViewPurchaseForecastOutbound';
import PackingList from './components/PackingList';
//import PartChangeNotification from './components/PartChangeNotification/PartChangeNotificationHdr';
import PartChangeNotification from './components/PartChangeNotification/PartChangeNotificationHdr';
import EditOrViewPartChangeNotification from './components/PartChangeNotification/EditOrViewPartChangeNotification';
import CustomSchedulerRedesignTab from'./CustomSchedulerTab/CustomSchedulerRedesignTab';



function App() {

  fakeBackendService();
  const { loggedInUserInfo } = useContext(LoggedInUserDetails);
  const currentUser = CommonService.getLoggedInUserInfo();
  useEffect(() => {
    if ((loggedInUserInfo.logInStatus === false && window.location.hostname.includes('edi')) ||
      ((currentUser !== null && currentUser !== undefined) && loggedInUserInfo.logInStatus === true && currentUser.userType === 'SIIX-USER')) {
      document.title = "SIIX Business Portal"
    } else if ((loggedInUserInfo.logInStatus === false && window.location.hostname.includes('partner')) ||
      ((currentUser !== null && currentUser !== undefined) && loggedInUserInfo.logInStatus === true && currentUser.userType === 'SUPPLIER')) {
      document.title = "Business Partner Portal"
    } else {
      document.title = "SIIX Business Portal"
    }
  }, [loggedInUserInfo, currentUser])

  return (
    <Router>
      <Switch>
        <Route exact from="/" path="/login" render={props => <LoginPage {...props} />} />
        <Route exact path='/unauthorized' component={Unauthorized} />
        <Route exact path='/forgetPassword' component={ChangeOrForgotPassword} />
        <Route>
          <Portal>
            <ProtectedRoutes exact path='/organizations' component={Organization} />
            <ProtectedRoutes exact path='/suppliergroup' component={() => <BPGroup bpType={'Supplier'} />} />
            <ProtectedRoutes exact path='/customergroup' component={() => <BPGroup bpType={'Customer'} />} />
            <ProtectedRoutes exact path='/erpInterfaceHdrs' component={ErpInterface} />
            <ProtectedRoutes exact path='/erpInterfaceStatus' component={ErpInterfaceStatus} />
            <ProtectedRoutes exact path='/messagetransmissionlog' component={MessageTransmissionLog} />
            <ProtectedRoutes exact path='/erpInterfaceHdrs/:intfHdrId' component={CreateOrEditErpinterface} />
            <ProtectedRoutes exact path='/regions' component={Region} />
            <ProtectedRoutes exact path='/entities' component={Branch} />
            <ProtectedRoutes exact path='/roles' component={Role} />
            <ProtectedRoutes exact path='/users' component={UserManagement} />
            <ProtectedRoutes exact path='/suppliers' component={Supplier} />
            <ProtectedRoutes exact path='/reports' component={Report} />
            <ProtectedRoutes exact path='/projects' component={Project} />
            <ProtectedRoutes exact path='/emailMessageTemplates' component={EmailTemplate} />
            <ProtectedRoutes exact path='/messageConfigHdrs' component={MessageConfigHdr} />
            <ProtectedRoutes exact path='/roles/:roleId' component={CreateOrEditRole} />
            <ProtectedRoutes exact path='/users/:userId' component={CreateOrEditUser} />
            <ProtectedRoutes exact path='/purchaseOrders/ForecastInbound/:poId' component={EditPurchaseOrder} />
            <ProtectedRoutes exact path='/purchaseOrders/ForecastInbound' component={Order} />
            <ProtectedRoutes exact path='/changePassword' component={ChangeOrForgotPassword} />
            <ProtectedRoutes exact path='/customer' component={Customer} />
            <ProtectedRoutes exact path='/customerGroup/:id' component={CreateOrEditBpGroup} bpType='Supplier' />
            <ProtectedRoutes exact path='/supplierGroup/:id' component={CreateOrEditBpGroup} bpType='Customer' />
            <ProtectedRoutes exact path='/emailMessageTemplates/:emailTmplId' component={CreateOrEditEmailTemplate} />
            <ProtectedRoutes exact path='/messageConfigHdrs/:configId' component={CreateOrEditMessageConfigHdr} />
            <ProtectedRoutes exact path='/emailMessageConfig' component={EmailMessageConfig} />
            <ProtectedRoutes exact path='/fileAttachmentDtls' component={() => <TabsForPoPdfVmiApproval isBPVmiConsumption={false} />} />
            <ProtectedRoutes exact path='/bpfileAttachmentDtls' component={() => <TabsForPoPdfVmiApproval isBPVmiConsumption={true} />} />
            <ProtectedRoutes exact path='/reportTemplates' component={EmailReportTemplate} />
            <ProtectedRoutes exact path='/transmissionConfigs' component={TransmissionConfiguration} />
            <ProtectedRoutes exact path='/transmissionConfigs/:protoConfigId' component={CreateOrEditTransmissionConfiguration} />
            <ProtectedRoutes exact path='/reportTemplates/:rptTmplId' component={CreateOrEditEmailReportTemplate} />
            <ProtectedRoutes exact path='/emailMessageLogs' component={EmailMessageLog} />
            <ProtectedRoutes exact path='/advanceShipmentNoteOutbound' component={AdvanceShipmentNoteOutbound} />
            <ProtectedRoutes exact path='/purchaseOrdersOutbound' component={() => <PurchaseOrderForecastOutbound ispurchaseforecast={false} isBusinessPartnerPortal={false} />} />
            <ProtectedRoutes exact path="/orders" component={() => <PurchaseOrderForecastOutbound isBusinessPartnerPortal={true} />} />
            <ProtectedRoutes exact path="/forecast" component={() => <PurchaseForecastOutbound ispurchaseforecast={true} isBusinessPartnerPortal={true} />} />
            <ProtectedRoutes exact path='/poOutboundMsgHdrs/:poId' component={EditOrViewPurchaseOrderForecastOutbound} />
            <ProtectedRoutes exact path='/invoices' component={InvoiceHeader} />
            <ProtectedRoutes exact path='/emailNotificationSubscription' component={EmailNotificationSubscription} />
            <ProtectedRoutes exact path='/emailServerConfig' component={EmailServerConfig} />
            <ProtectedRoutes exact path='/importTemplates' component={ImportTemplate} />
            <ProtectedRoutes exact path='/importTemplates/:importTmplId' component={CreateOrEditImportTemplate} />
            <ProtectedRoutes exact path='/advanceShipmentNoteInbound' component={AdvanceShipmentNoteInbound} />
            <ProtectedRoutes exact path='/advanceShipmentNoteInboundDtls/:msgHdrId' component={AdvanceShipmentNoteInboundDtls} />
            <ProtectedRoutes exact path='/invoiceInbound' component={InvoiceHeaderInbound} />
            <ProtectedRoutes exact path='/invoiceInboundDtls/:msgId' component={InvoiceInboundDtl} />
            <ProtectedRoutes exact path='/purchaseForecastOutbound' component={() => <PurchaseForecastOutbound ispurchaseforecast={true} isBusinessPartnerPortal={false} />} />
            <ProtectedRoutes exact path='/purchaseForecastOutbound/:poId' component={EditOrViewPurchaseForecastOutbound} />
            <ProtectedRoutes exact path='/packingLists' component={PackingList} />
            <ProtectedRoutes exact path='/partChangeNotification' component={() => <PartChangeNotification isPartchange={true} />} />
            <ProtectedRoutes exact path='/partChangeNotificationDtl/:msgId' component={EditOrViewPartChangeNotification}/>
            <ProtectedRoutes exact path='/CustomSchedulerRedesignTab' component={CustomSchedulerRedesignTab} />

          </Portal>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

