import { GridPagination } from '@mui/x-data-grid-pro';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

function CommonGridPagination() {
    const intl = useIntl();

    return (
        <React.Fragment>
            <GridPagination
                backiconbuttontext={intl.formatMessage({ id: "common.backiconbuttontext.pagination.lable" })}
                labelRowsPerPage={intl.formatMessage({ id: "common.labelrowsperpage.pagination.lable" })}
                nexticonbuttontext={intl.formatMessage({ id: "common.nexticonbuttontext.pagination..lable" })}
                labelDisplayedRows={({ from, to, count }) =>
                    <FormattedMessage id="common.paginationfrom.pagination.lable" values={{ from: `${from}`, to: `${to}`, count: `${count !== -1 ? count : `more than ${to}`}` }} defaultMessage={`${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`} />}
            />
        </React.Fragment>
    )
}

export default CommonGridPagination;