import { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import useStyles from "../../common/CommonStyle/CommonStyle"
import { LoggedInUserDetails } from "../../common/LoggedInUserDetails/LoggedInUserDetails";
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import IconEmailTemplate from '@mui/icons-material/Email';
import React from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary, Button, CssBaseline, FormControl, FormHelperText, Grid, InputLabel, Paper, Snackbar,
    Switch, TextField, Typography, useMediaQuery, useTheme
} from "@mui/material";
import { Alert } from "@mui/lab";
import CommonBackDrop from "../../common/BackDrop";
import CustomBreadCrumb from "../../common/CustomBreadCrumb";
import IconBack from '@mui/icons-material/BackspaceOutlined';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import IconRestore from '@mui/icons-material/AutorenewOutlined'
import { Controller, useForm } from "react-hook-form";
import _ from "lodash/fp";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CommonService } from "../../../services";
import { stateToHTML } from "draft-js-export-html";
import CustomToolbarForRichTextEditor from "../../common/CustomToolbarForRichRextEditor/CustomToolbarForRichTextEditor";
import EntityCodeCombo from "../../common/Combos/EntityCodeCombo";
import { stateFromHTML } from "draft-js-import-html";
import sanitize from "sanitize-html";
import { GlobalEdiApiConstants } from "../../../Constants/GlobalEdiApiConstants";
import { ModuleAccessPermissionKey } from "../../../Constants/ModuleAccessKey";
import ViewIcon from '@mui/icons-material/Visibility';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import EmailContentTypeCombo from "../../common/Combos/EmailContentTypeCombo/EmailContentTypeCombo";
import CharSetTypeCombo from "../../common/Combos/CharSetTypeCombo";

const CreateOrEditEmailTemplate = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const commonPatterns = GlobalEdiApiConstants();
    const theme = useTheme();
    const { mode, emailTmplId } = props.location.state;
    const currentUser = CommonService.getLoggedInUserInfo();
    const { isHideParameters } = false;
    const localtext = CustomToolbarForRichTextEditor();
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const emailTemplateModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.emailtemplate];
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());
    const [defaultOrgObj, setDefaultOrgObj] = useState({});
    const smallScreen = useMediaQuery(theme.breakpoints.down(889))
    useEffect(() => {
        if (orgInfoOfLeggedUser.erpOrgCode && mode === 'create') {
            setDefaultOrgObj({
                erpOrgCode: orgInfoOfLeggedUser.erpOrgCode, orgName: orgInfoOfLeggedUser.orgName,
                valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}`
            });
        }
    }, [orgInfoOfLeggedUser])

    const pageLabelsConstantsAndMessages = {
        name: {
            name: 'name',
            subject: 'subject',
            organization: 'organization',
            entity: 'entity',
            active: 'active',
            msgContent: 'msgContent',
            msgPlainContent: 'msgPlainContent',
            contentType: 'contentType',
            charSet: 'charSet'
        }
    }

    const contactSalutation = '${Contact_Salutation}';
    const contactName = '${Contact_Name}';
    const sendersName = '${Senders_Name}';
    const sendersDesignation = '${Senders_Designation}';

    const [defaultEmailTemplate] = useState(`Dear ${contactSalutation}.${contactName},<br/><br/><br/><br/>Please type your text here<br/><br/><br/><br/>Thanks,<br/>${sendersName}<br/>${sendersDesignation}`);

    const [emailMessageContent, setEmailMessageContent] = useState(mode === 'create' ? defaultEmailTemplate : 'Loading...');
    const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('');
    const [componentList, setComponentList] = useState([]);
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
    const [templateToBeEdited, setTemplateToBeEdited] = React.useState({});
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [branchValue, setBranchValue] = useState('');
    const [emailContentTypeValue, setEmailContentTypeValue] = useState('');
    const [charSetTypeValue, setCharSetTypeValue] = useState({ id: 2, value: 'iso-8859-1', name: 'iso-8859-1' });
    const [orgValue, setOrgValue] = useState('');
    const [activeToBeEdited, setActiveToBeEdited] = useState({});
    const [disabledForReadMode, isDisabledForReadMode] = useState(false);
    const [disableTemplateName, setDisableTemplateName] = useState(false);
    const [disableSaveOrUpdateButton, issaveOrUpdateButtonDisabled] = useState(false);
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const [userReadOnlyFields, setUserReadOnlyFields] = useState({
        name: false,
        subject: false,
        organization: false,
        entity: false,
        active: false,
        msgContent: false,
        contentType: false,
        msgPlainContent: false,
        charSet: false
    })

    const { handleSubmit, formState: { errors }, reset, control } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: {
            entity: null,
            active: true,
            name: null,
            subject: null,
            orgCode: null,
            msgContent: null,
            contentType: null,
            msgPlainContent: null,
            charSet: "iso-8859-1"
        },
    });

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    useEffect(() => {
        if (emailTmplId > 0) {
            CommonService.getByIdApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.emailTemplate, emailTmplId)
                .then((response) => {
                    setTemplateToBeEdited(response.data);
                    emailTemplateResettingValues(response.data);
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    setOpenBackDrop(false);
                });
        }
    }, []);

    function convertToPlain(html) {
        var tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    let emailMsgPlainContent = convertToPlain(emailMessageContent)

    function emailTemplateResettingValues(responseData) {
        reset({
            organization: responseData.organization !== undefined ? { erpOrgCode: responseData.organization.erpOrgCode, orgName: responseData.organization.orgName } : defaultOrgObj,
            entity: responseData.entity !== undefined ? { erpEntityCode: responseData.entity.erpEntityCode, entityName: responseData.entity.entityName } : null,
            name: responseData.name !== undefined ? responseData.name : '',
            subject: responseData.subject !== undefined ? responseData.subject : '',
            active: responseData.active !== undefined ? responseData.active : true,
            msgContent: responseData.msgContent !== undefined ? responseData.msgContent : '',
            contentType: responseData.contentType !== undefined ? responseData.contentType : '',
            msgPlainContent: responseData.msgPlainContent !== undefined ? responseData.msgPlainContent : '',
            charSet: responseData.charSet !== undefined ? responseData.charSet : { id: 2, value: 'iso-8859-1', name: 'iso-8859-1' },
        });
        setOrgValue(responseData.organization !== undefined ? { valueToBeDisplayed: responseData.organization } : defaultOrgObj);
        setBranchValue(responseData.entity !== undefined ? { valuesToBeDisplayed: responseData.entity } : []);
        setEmailContentTypeValue(responseData.contentType !== undefined ? { id: 1, value: responseData.contentType } : []);
        const rawContent = stateFromHTML(sanitize(responseData.msgContent !== undefined ? responseData.msgContent : emailMessageContent));
        setEditorState(() => EditorState.createWithContent(rawContent));
        setEmailMessageContent(responseData.msgContent !== undefined ? responseData.msgContent : '');
        setCharSetTypeValue(responseData.charSet !== undefined ? { id: 1, value: responseData.charSet } : { id: 2, value: 'iso-8859-1', name: 'iso-8859-1' });
    }

    useEffect(() => {
        if (mode === 'create') {
            emailTemplateResettingValues({ ...templateToBeEdited, msgContent: emailMessageContent });
        }
    }, [defaultOrgObj])

    useEffect(() => {
        setOpenBackDrop(true);
        let currentRouterComponent;
        if (mode === 'create' && emailTmplId === 0) {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            isRestoreButtonDisabled(false);
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                iconName: CreateIcon,
            }
            setOpenBackDrop(false);
        } else if (mode === 'edit') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isRestoreButtonDisabled(false);
            setDisableTemplateName(true)
            setUserReadOnlyFields({ ...userReadOnlyFields, name: true, subject: false, organization: true, entity: true, msgContent: false });
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                iconName: EditIcon,
            }
            emailTemplateResettingValues(templateToBeEdited);
        } else if (mode === 'read') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isDisabledForReadMode(true);
            isRestoreButtonDisabled(false);
            setDisableTemplateName(true)
            issaveOrUpdateButtonDisabled(true);
            isRestoreButtonDisabled(true);
            setUserReadOnlyFields({ ...userReadOnlyFields, name: true, subject: true, organization: true, entity: true, msgContent: true })
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
                iconName: ViewIcon,
            }
            emailTemplateResettingValues(templateToBeEdited);
        }
        setComponentList([
            {
                path: null,
                name: <FormattedMessage id="common.menu.breadcrumb.messageconfiguration" />,
                iconName: IconMessageConfiguration,
            },
            {
                path: '/emailMessageTemplates',
                name: <FormattedMessage id="common.menu.breadcrumb.emailTemplate" defaultMessage="Email Template" />,
                iconName: IconEmailTemplate,
            },
            currentRouterComponent
        ])
    }, [mode]);


    const contentStateHtml = stateToHTML(editorState.getCurrentContent(emailMessageContent));

    useEffect(() => {
        setActiveToBeEdited(templateToBeEdited);
    }, [templateToBeEdited])

    const onSubmit = data => {
        if (emailMessageContent === '<p><br></p>' || emailMessageContent === '') {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="editor.error.message" />, severity: "error" })
        } else {
            data['id'] = emailTmplId;
            data['parameters'] = '';
            data['msgContent'] = emailMessageContent;
            data['createdBy'] = (mode === 'create') ? currentUser.userName : templateToBeEdited.createdBy;
            data['createdTime'] = (templateToBeEdited.createdTime === undefined) ? '' : templateToBeEdited.createdTime;
            data['updatedBy'] = currentUser.userName;
            data['updatedTime'] = (templateToBeEdited.updatedTime === undefined) ? '' : templateToBeEdited.updatedTime;
            data['organization'] = (templateToBeEdited.organization === undefined) ? { erpOrgCode: orgInfoOfLeggedUser.erpOrgCode } : templateToBeEdited.organization;
            data['contentType'] = templateToBeEdited.contentType === undefined ? emailContentTypeValue.value : emailContentTypeValue.value;
            data['charSet'] = templateToBeEdited.charSet === undefined ? charSetTypeValue.value : charSetTypeValue.value;
            data['msgPlainContent'] = emailMsgPlainContent;
            setOpenBackDrop(true)
            CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.emailTemplate, data)
                .then((response) => {
                    setOpenBackDrop(false);
                    history.goBack();
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" />, severity: "success" });
                })
                .catch(function (error) {
                    setOpenBackDrop(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                });
        }
    }

    function resetAllClearThePageInfo() {
        if (mode === 'edit') {
            emailTemplateResettingValues(templateToBeEdited);
            setActiveToBeEdited({ ...templateToBeEdited });
        } else {
            reset({
                entity: null,
                active: true,
                organization: null,
                msgContent: '',
                subject: '',
                name: ''
            });
            setOrgValue([]);
            setBranchValue([]);
            setEmailContentTypeValue([]);
            setCharSetTypeValue([]);
            setEmailMessageContent(defaultEmailTemplate);
            setDefaultOrgObj({ ...defaultOrgObj, valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}` });
            setTemplateToBeEdited({ ...templateToBeEdited, active: true });
        }
    }

    const branchComboProperty = {
        name: pageLabelsConstantsAndMessages.name.entity,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.entityCode.label" }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.entity,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.entity
    }

    const emailContentTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.contentType,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.emailcontenttype.label" }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.contentType,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.contentType
    }

    const charSetTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.charSet,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.emailcharsettype.label" }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.charSet,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.charSet
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForCreateOrEditRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={8} md={8} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' alignItems='center' xs={6} sm={4} md={4} lg={6} className={classes.topGrid}>
                    <Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2}
                        endIcon={smallScreen ? null : <IconBack />} onClick={history.goBack}
                    > {smallScreen ? <IconBack /> : <FormattedMessage id="common.button.back.lable" defaultMessage="Back" />} </Button>
                    <Button size='small' disabled={disableRestoreButton}
                        variant="contained" color="secondary" className={classes.stateButtonToRestore} elevation={2}
                        endIcon={smallScreen ? null : <IconRestore />} onClick={(() => { resetAllClearThePageInfo() })}
                    > {smallScreen ? <IconRestore /> : <FormattedMessage id="common.button.reset.lable" defaultMessage="Reset" />} </Button>

                    {(emailTemplateModuleAccess !== undefined ? emailTemplateModuleAccess.createFlag : false) && (<Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                        disabled={disableSaveOrUpdateButton}
                        endIcon={smallScreen ? null : <IconSaveOrUpdate />} onClick={handleSubmit(onSubmit)}
                    > {smallScreen ? <IconSaveOrUpdate /> : saveOrUpdateButtonLabel} </Button>)}
                </Grid>
                <form className={classes.paperCreateOrEditRole} onSubmit={handleSubmit(onSubmit)}>
                    <Paper className={classes.formForCreateOrEditUser} elevation={3}>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} style={{ marginTop: 0 }}>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <EntityCodeCombo comboProperty={branchComboProperty} control={control} errors={errors} comboValue={branchValue} setComboValue={setBranchValue} />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.name}
                                        rules={{
                                            required: true,
                                            maxLength: 100,
                                            pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UHF
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.label.templatename" })}
                                                error={errors.name}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disableTemplateName}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.name}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfiled.error.templatenameisrequired" defaultMessage="Template Name is required" />
                                            </FormHelperText>
                                        )}

                                        {_.get(`${pageLabelsConstantsAndMessages.name.name}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.error.templatenamecannotexceed" defaultMessage="Template Name cannot exceed more than 100 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.name}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}> <FormattedMessage id="textfield.label.templatename" /> {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={4} lg={6}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.subject}
                                        rules={{
                                            required: true,
                                            maxLength: 500,
                                            // pattern: commonPatterns.commonTextfeildPattern.patternForAlphaNumericAndSpecialChar
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="subject"
                                                size='small'
                                                label={intl.formatMessage({ id: "columnname.subject.label" })}
                                                {...field}
                                                error={errors.subject}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: userReadOnlyFields.subject,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.subject}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfiled.error.subjectisrequired" defaultMessage="Subject is required" />
                                            </FormHelperText>
                                        )}

                                        {_.get(`${pageLabelsConstantsAndMessages.name.subject}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.error.subjectcannotexceed" defaultMessage="Subject cannot exceed more than 500 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.subject}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>   {<FormattedMessage id="columnname.subject.label" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNumericAndSpecialChar}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <EmailContentTypeCombo comboProperty={emailContentTypeComboProperty} control={control} errors={errors} comboValue={emailContentTypeValue} setComboValue={setEmailContentTypeValue} />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <CharSetTypeCombo comboProperty={charSetTypeComboProperty} control={control} errors={errors} comboValue={charSetTypeValue} setComboValue={setCharSetTypeValue} />
                            </Grid>
                            <Grid item container xs={12} sm={12} md={2} lg={2}>
                                <Grid container justifyContent='flex-start' alignItems='center'>
                                    <Controller
                                        render={({
                                            field: { onChange } }) => (<Switch
                                                color='primary'
                                                checked={activeToBeEdited.active !== undefined ? activeToBeEdited.active : true}
                                                onChange={((event) => {
                                                    onChange(!(activeToBeEdited.active !== undefined ? activeToBeEdited.active : true));
                                                    setActiveToBeEdited({ ...activeToBeEdited, active: event.target.checked })
                                                })}
                                                disabled={mode === 'read' ? disabledForReadMode : false}
                                            />)}
                                        type="checkbox"
                                        name={pageLabelsConstantsAndMessages.name.active}
                                        control={control}
                                        defaultValue={activeToBeEdited.active !== undefined ? activeToBeEdited.active : true}
                                    />
                                    <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    {(isHideParameters) && (<Paper style={{ width: '100%', height: 'auto', marginTop: '5px' }} elevation={3}>
                        <Accordion defaultExpanded={false}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                            >
                                <Typography><strong>Parameters Details</strong></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container item xs={12} sm={12} md={12} lg={12}>
                                    <Typography>Parameters</Typography>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Paper>)}
                    <Paper className={classes.paperStyleForEmailTemplateEditor} elevation={3}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <Editor
                                required={true}
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                editorStyle={{ marginLeft: '20px', fontSize: '14px' }}
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                }}
                                localization={{ locale: 'en', translations: localtext }}
                                onChange={((event) => {
                                    setEmailMessageContent(contentStateHtml);
                                })}
                                readOnly={mode === 'read' ? disabledForReadMode : false}
                            />
                        </div>
                    </Paper>
                </form>
            </Grid >
        </React.Fragment >
    );

}
export default CreateOrEditEmailTemplate;