import { Button, Grid, CssBaseline, CircularProgress, useTheme, useMediaQuery, Tooltip, Paper } from '@mui/material';
import { getGridStringOperators, GridFilterPanel, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';
import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import useStyles from '../../../common/CommonStyle/CommonStyle';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import GridTextLocalization from '../../../common/GridTextLocalization/GridTextLocalization';
import { LoggedInUserDetails } from '../../../common/LoggedInUserDetails/LoggedInUserDetails';
import { SupportedLanguageDetails } from '../../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import { CommonService } from '../../../../services';
import CommonGridPagination from '../../../common/CommonGridPagination/CommonGridPagination';
import ClearIcon from '@mui/icons-material/Clear';
import CustomBreadCrumb from '../../../common/CustomBreadCrumb';
import ViewIcon from '@mui/icons-material/Visibility';
import ScheduleIcon from '@mui/icons-material/Schedule';
import IconOrder from '@mui/icons-material/Assignment';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),

    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disabletypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const columnFieldsName = {
    deliveryScheduleLineNo: 'deliveryScheduleLineNo',
    scheduleFrequency: 'scheduleFrequency',
    scheduleType: 'scheduleType',
    scheduleReqDt: 'scheduleReqDt',
    deliveryReqQty: 'deliveryReqQty',
    deliveryReqDt: 'deliveryReqDt',
    deliveryEarlierAcceptanceDt: 'deliveryEarlierAcceptenceDt',
    dlvLastAcceptanceDt: 'deliveryLastAcceptanceDt',
    cumulativeQtyTotal: "cumulativeQtyTotal",
    cumulativeQtyTotalUom: "cumulativeQtyTotalUom",
    scheduleRequiredQtyUom: 'scheduleRequiredQtyUom'
}

const ScheduleGridDialogComponent = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const { open, onClose, msgId, msgDtlsId, poNumber, itemCode, itemDesc, poLineNumber } = props;
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });

    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const pageLabelsConstantsAndMessages = {
        label: {
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density',
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
    }
    const [columns, setColumns] = useState([]);
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [tableData, setTableData] = useState([]);
    const handleClose = (value) => {
        onClose(value)
    }

    const commonColumnForScheduleGrid = [
        {
            field: columnFieldsName.deliveryScheduleLineNo,
            width: 140,
            headerName: intl.formatMessage({ id: "columnname.deliveryScheduleLineNo.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.scheduleType,
            headerName: intl.formatMessage({ id: "columnname.scheduleType.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 130,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: ((cellParams) => {
                if (cellParams.row.scheduleType === '1') {
                    return 'Firm Order'
                } else if (cellParams.row.scheduleType === '4') {
                    return 'Forecast'
                }
            })
        },
        {
            field: columnFieldsName.scheduleFrequency,
            headerName: intl.formatMessage({ id: "columnname.scheduleFreq.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 130,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: ((cellParams) => {
                if (cellParams.row.scheduleFrequency === 'W') {
                    return 'Weekly'
                } else if (cellParams.row.scheduleFrequency === 'M') {
                    return 'Monthly'
                }
            })
        },
        {
            field: columnFieldsName.deliveryReqDt,
            width: 140,
            headerName: intl.formatMessage({ id: "columnName.scheduledReqDt.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.deliveryReqDtDisp}</span>)
        },
        {
            field: columnFieldsName.deliveryReqQty,
            headerName: intl.formatMessage({ id: "columnName.scheduledReqQty.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 140,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.dlvLastAcceptanceDt,
            headerName: intl.formatMessage({ id: "columnname.dlvLastAcceptanceDt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 170,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.deliveryLastAcceptanceDtDisp}</span>)
        },
        {
            field: columnFieldsName.deliveryEarlierAcceptanceDt,
            headerName: intl.formatMessage({ id: "columnname.dlvEarlierAcceptanceDt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 170,
            sortable: true,
            type: 'dateTime',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.deliveryEarlierAcceptenceDtDisp}</span>)
        },
        {
            field: columnFieldsName.cumulativeQtyTotal,
            headerName: intl.formatMessage({ id: "columnName.cumQtyTotal.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 140,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.cumulativeQtyTotalUom,
            headerName: intl.formatMessage({ id: "columnName.cumQtyTotalUom.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 180,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
    ]

    useEffect(() => {
        setColumns(commonColumnForScheduleGrid)
    }, [selectedLanguage])

    const [paramBody, setParamBody] = useState(
        {
            body: {
            },
            pageNo: 0,
            sortDir: 'asc',
            sortKey: 'MsgSccId',
            recordsPerPage: 25,
        }
    );

    const componentList = [
        {
            path: '/purchaseOrders/ForecastInbound',
            name: <FormattedMessage id="common.menu.module.breadcrumb.purchaseorder" defaultMessage="Purchase Orders/Forecast Inbound" />,
            iconName: IconOrder,
        },
        {
            path: '/purchaseOrders/ForecastInbound',
            name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage='View' />,
            iconName: ViewIcon,
        },
        {
            path: undefined,
            name: <FormattedMessage id="common.button.schGrid.breadcrumb.tooltip" defaultMessage='schGrid' />,
            iconName: ScheduleIcon,
        },
    ];

    useEffect(() => {
        if (open === true) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(`/poInboundMsgHdrs/${msgId}/poInboundMsgDtls/${msgDtlsId}/poInboundMsgScc`, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [open, paramBody, refreshTable])

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <Dialog
                        fullWidth
                        maxWidth='lg'
                        aria-labelledby="create-or-delete-dialog"
                        open={open}>
                        <DialogTitle className={classes.titleCommonDialogComponent}>
                            <Grid item container xs={12} sm={12} md={12} lg={12}>
                                <Grid item container alignItems='center' item xs={12} sm={6} md={8} lg={6} >
                                    <CustomBreadCrumb componentList={componentList} isDialog={true} />
                                </Grid>
                                <Grid item container justifyContent='flex-end' xs={12} sm={6} md={4} lg={6} className={classes.dialogPOScreen} >
                                    {poNumber} | {poLineNumber} | {itemCode} | {itemDesc}
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={mobileScreen ? { minHeight: '70vh' } : { minHeight: '60vh' }}>
                            <Paper elevation={3} className={classes.gridHeightAndWidthForViewBoxDetails}>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <DataGridPro
                                        className={classes.customTableStyle}
                                        disableColumnFilter={true}
                                        loading={tableLoadingStatus}
                                        rowCount={totalRowCount}
                                        rows={tableData}
                                        pageSize={paramBody.recordsPerPage}
                                        columns={columns}
                                        getRowId={(row) => row.id}
                                        showToolbar
                                        scrollbarSize={30}
                                        disableColumnFilter={true}
                                        rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                                        pagination
                                        density="compact"
                                        paginationMode="server"
                                        hideFooter={true}
                                        disableSelectionOnClick={true}
                                        disableMultipleColumnsSorting={true}
                                        disableColumnMenu={true}
                                        disableColumnSelector={false}
                                        localeText={localtext}
                                        components={{
                                            Toolbar: () => {
                                                return (
                                                    <Grid container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                                        <Grid
                                                            item
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            xs={12}
                                                            sm={12}
                                                            md={6}
                                                            lg={6}>
                                                            <Grid>
                                                                <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                                    <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium"/>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid>
                                                                <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                                    placement='bottom'><GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid>
                                                                <GridToolbarFilterButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                            </Grid>
                                                            <Grid>
                                                                <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                                    <span>
                                                                        <Button size="small" color="primary" variant="outlined"
                                                                            className={classes.refreshToobarStyle}
                                                                            onClick={(() => {
                                                                                setRefreshTable(!refreshTable);
                                                                            })}>
                                                                            <RefreshIcon />
                                                                        </Button>
                                                                    </span>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <CommonGridPagination />
                                                        </Grid>
                                                    </Grid>
                                                );
                                            },
                                            FilterPanel: () => {
                                                return (
                                                    <Grid container
                                                        direction="row"
                                                    >
                                                        <Grid container
                                                            item
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                            xs={6} sm={6} md={6} lg={6}
                                                        >
                                                            <GridFilterPanel />
                                                        </Grid>
                                                        <Grid container
                                                            item
                                                            justifyContent="flex-end"
                                                            alignItems="flex-end"
                                                            xs={6} sm={6} md={6} lg={6}
                                                            style={{ marginLeft: '-24%' }}
                                                        >
                                                            <Button color="primary" style={{ paddingBottom: '10px' }} onClick={(() => {
                                                                setParamBody(prevState => {
                                                                    return {
                                                                        ...prevState, body: {
                                                                        },
                                                                    }
                                                                });
                                                            })}>
                                                                <FormattedMessage id="common.applyfilter.lable" defaultMessage=
                                                                    {pageLabelsConstantsAndMessages.label.applyFilterButton} />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            }
                                        }}
                                        hideFooterSelectedRowCount={true}
                                        onPageSizeChange={((pageParams) => {
                                            setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                                        })}
                                        onPageChange={((pageParams) => {
                                            setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                                        })}
                                        onSortModelChange={(params) => {
                                            let sortModel = params[0];
                                            if (sortModel !== undefined) {
                                                setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                            }
                                        }}
                                        sortingOrder={['desc', 'asc']}
                                        filterMode="server"
                                    // onFilterModelChange={async (params) => {
                                    //     let filterModelItem = params.items;
                                    //     if (filterModelItem.length > 0) {

                                    //     }
                                    // }}
                                    />
                                </div>
                            </Paper>
                        </DialogContent>
                        {!mobileScreen && (
                            <DialogActions className={classes.dialogAction} >

                                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} >
                                    <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2} endIcon={<ClearIcon />}
                                        onClick={() => handleClose(false)}
                                    ><FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />
                                    </Button>

                                </Grid>

                            </DialogActions>)}
                        {mobileScreen && (<Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                            <DialogActions className={classes.dialogAction}>
                                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.topGrid} >
                                    <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                                        <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2}
                                            onClick={() => handleClose(false)}
                                        >  <ClearIcon />
                                        </Button>
                                    </Tooltip>

                                </Grid>
                            </DialogActions>
                        </Grid>)}
                    </Dialog>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}
export default ScheduleGridDialogComponent;