import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';

const ProtectedRoutes = ({ component: Component, userLoggedInStatus, ...rest }) => {

    const {loggedInUserInfo} = useContext(LoggedInUserDetails);
    return (
      <Route {...rest} render={
        props => {
          if (loggedInUserInfo.logInStatus) {
            return <Component {...rest} {...props} />
          } else {
            return <Redirect to={
              {
                pathname: '/unauthorized',
                state: {
                  from: props.location
                }
              }
            } />
          }
        }
      } />
    )
  }

export default ProtectedRoutes;