import React, { useContext, useEffect, useState } from 'react';
import {
    LicenseInfo, DataGridPro, GridFilterPanel, getGridStringOperators,
    GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector
} from '@mui/x-data-grid-pro';
import CommonGridPagination from '../CommonGridPagination/CommonGridPagination';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/EditOutlined';
import ViewIcon from '@mui/icons-material/Visibility';
import useStyles from '../CommonStyle/CommonStyle';
import { Button, Grid, IconButton, Paper, Snackbar, Tooltip } from '@mui/material';
import { CommonService } from '../../../services';
import { CustomFilters } from '../CustomFilters/CustomFilters';
import { SupportedLanguageDetails } from '../SupportedLanguageDetails/SupportedLanguageDetails';
import GridTextLocalization from '../GridTextLocalization/GridTextLocalization';
import Alert from '@mui/lab/Alert';
import IconSettings from '@mui/icons-material/Settings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import CreateIcon from '@mui/icons-material/Add';
import CustomBreadCrumb from '../CustomBreadCrumb/CustomBreadCrumb';
import { useHistory } from 'react-router-dom';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import ChipInfoList from '../ChipInfoList/ChipInfoList';
import { GlobalEdiApiConstants } from "../../../Constants/GlobalEdiApiConstants";
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import { LoggedInUserDetails } from '../LoggedInUserDetails/LoggedInUserDetails';

function BPGroup(props) {

    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );

    const { bpType } = props;
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const supplierGroupModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.supplierGroup];
    const customerGroupModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.customerGroup];
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });


    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };

    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const columnFieldsName = {
        orgName: 'organization.orgName',
        erpOrgCode: 'organization.erpOrgCode',
        groupName: 'groupName',
        groupDescription: 'groupDescription',
        createdTime: 'createdTime',
        createdBy: 'createdBy',
        updatedTime: 'updatedTime',
        updatedBy: 'updatedBy',
    }


    const localtext = GridTextLocalization();
    const [refreshTable, setRefreshTable] = useState(false);
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [searchedGroupName, setsearchedGroupName] = useState('');
    const [searchedGroupDescription, setsearchedGroupDescription] = useState('');
    const [searchedOrgCode, setSearchedOrgCode] = useState('');
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [chipInfoList, setChipInfoList] = useState([]);
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [searchedOrgName, setSearchedOrgName] = useState('');

    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.BpGroupMaster);

    useEffect(() => {
        if (bpType !== '') {
            setParamBody({
                ...paramBody, body: {
                    bpType: bpType,
                    organization: {
                        erpOrgCode: ''
                    },
                    groupName: '',
                    groupDescription: '',
                    active: true,
                    recordActiveStatus: 'All',

                },
            });
        }
    }, [bpType]);

    const pageLabelsConstantsAndMessages = {
        label: {
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    };

    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.settings" defaultMessage="Settings" />,
            iconName: IconSettings,
        },
        (bpType === 'Supplier') ?
            {
                path: '/suppliergroup',
                name: <FormattedMessage id="common.module.breadcrumb.columnname.suppliergroup" defaultMessage="Organization" />,
                iconName: SupervisedUserCircleIcon,
            }
            :
            {
                path: '/customergroup',
                name: <FormattedMessage id="common.module.breadcrumb.columnname.customergroup" defaultMessage="Customer Group" />,
                iconName: GroupWorkIcon,
            }
    ];


    const columnsFortype = [
        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: 130,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    {((supplierGroupModuleAccess || customerGroupModuleAccess) !== undefined ? (supplierGroupModuleAccess || customerGroupModuleAccess).editFlag : false) && (
                        <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                bpGroupCreate(cellParams.row.groupId, 'edit');
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {((supplierGroupModuleAccess || customerGroupModuleAccess) !== undefined ? (supplierGroupModuleAccess || customerGroupModuleAccess).readFlag : false) && (
                        <Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.readButton} />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                bpGroupCreate(cellParams.row.groupId, 'view');
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <ViewIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                </div>
            )
        },
        {
            field: columnFieldsName.orgName,
            headerName: intl.formatMessage({ id: "columnname.OrganizationName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 290,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: true,
            valueGetter: (params) => `${params.row.organization.orgName}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.erpOrgCode,
            headerName: intl.formatMessage({ id: "columnname.organizationcode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: true,
            valueGetter: (params) => `${params.row.organization.erpOrgCode}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.groupName,
            width: 160,
            headerName: intl.formatMessage({ id: "columnname.groupName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.groupDescription,
            headerName: intl.formatMessage({ id: "columnname.groupDescription.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },

        {
            field: 'active',
            headerName: intl.formatMessage({ id: "columnname.status.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 143,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.customRenderStatusColumn
        },
        {
            field: columnFieldsName.createdTime,
            headerName: intl.formatMessage({ id: "columnname.columnnamecreatedtime.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.createdDtDisp}</span>)
        },
        {
            field: columnFieldsName.createdBy,
            headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.updatedTime,
            headerName: intl.formatMessage({ id: "columnname.updatedtime.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.lastUpdatedDtDisp}</span>)
        },
        {
            field: columnFieldsName.updatedBy,
            headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
    ]
    const [columns, setColumns] = useState([]);
    useEffect(() => {
        setColumns(columnsFortype)
    }, [selectedLanguage])

    const bpGroupCreate = (supplierOrCustomerGroupId, mode) => {
        const bpGroupURI = ((bpType === 'Supplier') ? `/supplierGroup/${supplierOrCustomerGroupId}` : `/customerGroup/${supplierOrCustomerGroupId}`);
        history.push(bpGroupURI, { mode: mode, bpType: bpType === 'Supplier' ? 'SUPPLIER' : 'CUSTOMER', supplierOrCustomerGroupId: supplierOrCustomerGroupId });
    }


    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (paramBody.body.bpType !== '') {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.BpGroupMaster, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));

                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [paramBody, refreshTable])

    if (columns.length > 0) {
        const orgCodeColumn = columns.find((column) => column.field === columnFieldsName.erpOrgCode);
        const orgCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpOrgCode);

        const orgCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgCodeInputValue,
            }),
        );

        columns[orgCodeColIndex] = {
            ...orgCodeColumn,
            filterOperators: orgCodeFilterOperators,
        };

        const orgNameColumn = columns.find((column) => column.field === columnFieldsName.orgName);
        const orgNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.orgName);

        const orgNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgNameInputValue,
            }),
        );

        columns[orgNameColIndex] = {
            ...orgNameColumn,
            filterOperators: orgNameFilterOperators,
        };

        const groupNameColumn = columns.find((column) => column.field === columnFieldsName.groupName);
        const groupNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.groupName);

        const groupNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: bpType === 'Supplier' ? CustomFilters.SupplierGroupNameInputValue : CustomFilters.CustomerGroupNameInputValue,

            }),
        );

        columns[groupNameColIndex] = {
            ...groupNameColumn,
            filterOperators: groupNameFilterOperators,
        };

        const groupDescriptionColumn = columns.find((column) => column.field === columnFieldsName.groupDescription);
        const groupDescriptionColIndex = columns.findIndex((col) => col.field === columnFieldsName.groupDescription);

        const groupDescriptionFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: bpType === 'Supplier' ? CustomFilters.SupplierGroupDescriptionInputValue : CustomFilters.CustomerGroupDescriptionInputValue,
            }),
        );

        columns[groupDescriptionColIndex] = {
            ...groupDescriptionColumn,
            filterOperators: groupDescriptionFilterOperators,
        };


    }


    return (
        <React.Fragment>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>

            </Snackbar>
            <Grid container className={classes.rootForOrganixation}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={8} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' alignItems='center' xs={4} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <Button size='small' variant="contained" color="primary" className={classes.createButton} elevation={2} endIcon={<CreateIcon />} onClick={() => {
                        bpGroupCreate(0, 'create');
                    }}>
                        {<FormattedMessage id="common.button.Create.label" defaultMessage="Create" />}
                    </Button>
                </Grid>
                <ChipInfoList chipInfoList={chipInfoList} />
                <Paper elevation={3} className={classes.gridHeightAndWidthForUser}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            disableColumnFilter={true}
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            hideFooter={true}
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            localeText={localtext}
                            density="compact"
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center">
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton style={{ marginRight: '5px', paddingRight: '2px' }} variant="outlined" size="medium" className={classes.customeToolbar} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'><GridToolbarDensitySelector style={{ marginRight: '5px', paddingRight: '3px' }} variant="outlined" size="medium" className={classes.customeToolbar} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <GridToolbarFilterButton style={{ marginRight: '5px', paddingRight: '1px' }} variant="outlined" className={classes.customeToolbar} />
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                style={{ marginRight: '5px' }}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>

                                        </Grid>
                                    );
                                },
                                FilterPanel: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                        >
                                            <Grid container
                                                item
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                xs={6} sm={6} md={6} lg={6}
                                            >
                                                <GridFilterPanel />
                                            </Grid>
                                            <Grid container
                                                item
                                                justifyContent="flex-end"
                                                alignItems="flex-end"
                                                xs={6} sm={6} md={6} lg={6}
                                                style={{ marginLeft: '-24%' }}
                                            >
                                                <Button color="primary" style={{ paddingBottom: '10px' }} onClick={(() => {
                                                    setParamBody(prevState => {
                                                        return {
                                                            ...prevState, body: {
                                                                bpType: bpType,
                                                                organization: { erpOrgCode: searchedOrgCode, orgName: searchedOrgName },
                                                                groupName: searchedGroupName,
                                                                groupDescription: searchedGroupDescription,
                                                                active: true,
                                                                recordActiveStatus: 'All'
                                                            }
                                                        }
                                                    });
                                                })}>
                                                    <FormattedMessage id="common.applyfilter.lable" defaultMessage=
                                                        {pageLabelsConstantsAndMessages.label.applyFilterButton} />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    );
                                }
                            }}
                            hideFooterSelectedRowCount={true}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            onSortModelChange={(params) => {
                                let sortModel = params[0];
                                if (sortModel !== undefined) {
                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                }
                            }}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                            onFilterModelChange={async (params) => {
                                let filterModelItem = params.items;
                                if (filterModelItem.length > 0) {
                                    let orgName = '';
                                    let erpOrgCode = '';
                                    let groupName = '';
                                    let groupDescription = '';
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.erpOrgCode) {
                                            erpOrgCode = await filterModelItem[index].value === undefined ? '' : JSON.parse(filterModelItem[index].value).erpOrgCode;
                                            break;
                                        }
                                    }
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.orgName) {
                                            orgName = await filterModelItem[index].value === undefined ? '' : JSON.parse(filterModelItem[index].value).orgName;
                                            break;
                                        }
                                    }
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.groupName) {
                                            groupName = await filterModelItem[index].value === undefined ? '' : JSON.parse(filterModelItem[index].value).groupName;
                                            break;
                                        }
                                    }
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.groupDescription) {
                                            groupDescription = await filterModelItem[index].value === undefined ? undefined : JSON.parse(filterModelItem[index].value).groupDescription;
                                            break;
                                        }
                                    }
                                    setsearchedGroupName(groupName);
                                    setsearchedGroupDescription(groupDescription);
                                    setSearchedOrgName(orgName);
                                    setSearchedOrgCode(erpOrgCode);
                                }
                            }}
                        />
                    </div>

                </Paper>
            </Grid>
        </React.Fragment>
    );
};


export default BPGroup;