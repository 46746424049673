import { Badge, Button, Chip, CircularProgress, CssBaseline, Grid, IconButton, MenuItem, Paper, Snackbar, Tooltip, Typography, useMediaQuery, useTheme, Menu, MenuList} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import {
    LicenseInfo, DataGridPro, getGridStringOperators, GridToolbarColumnsButton, GridToolbarDensitySelector, getGridDateOperators
} from '@mui/x-data-grid-pro';
import { CommonService } from '../../../services';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import IconSupplier from '@mui/icons-material/LocalShipping';
import ChipInfoList from '../../common/ChipInfoList';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Alert from '@mui/lab/Alert';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import IconSettings from '@mui/icons-material/Settings';
import IconUserManagement from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CustomFilters } from '../../common/CustomFilters/CustomFilters';
import UserSupplierAssignedIcon from '@mui/icons-material/AssignmentTurnedIn';
import UserSupplierNotAssignedIcon from '@mui/icons-material/AssignmentLate';
import useStyles from '../../common/CommonStyle/CommonStyle';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import ViewIcon from '@mui/icons-material/Visibility';
import CustomFilterComponent, { GetFilterableColumns } from '../../common/CustomFilterComponent';
import FilterListIcon from '@mui/icons-material/FilterList';
import { manageUserPreferences } from '../../../services/manageUserPreferences';
//added
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExportIcon from '@mui/icons-material/Publish';
import CommonBackDrop from '../../common/BackDrop/CommonBackDrop';
//end

const UserSupplierAccessDisplayField = (props) => {
    const { value } = props;
    return (
        <React.Fragment>
            {value === 'Assigned' && (
                <CommonService.ActiveChip
                    icon={<UserSupplierAssignedIcon />}
                    variant="outlined"
                    label={value}
                    color='primary'
                />
            )}
            {value === 'Not-Assigned' && (
                <CommonService.InActiveChip
                    icon={<UserSupplierNotAssignedIcon />}
                    variant="outlined"
                    label={value}
                    color='secondary'
                />
            )}
            {value === 'N/A' && (
                <Chip
                    // icon={<ConfirmedIcon />}
                    variant="outlined"
                    label={value}
                    color='primary'
                />
            )}
        </React.Fragment>

    );
}
const columnFieldsName = {
    erpEntityCode: 'entity.erpEntityCode',
    erpOrgCode: 'organization.erpOrgCode',
    orgName: 'organization.orgName',
    entityName: 'entity.entityName',
    userName: 'userName',
    userType: 'userType',
    active: 'active',
    validFromDate: 'validFromDate',
    validToDate: 'validToDate',
    designation: 'designation',
    allowLogin: 'allowLogin',
    purchasingGroup: 'purchasingGroup',
    accountLocked: 'accountLocked'
};

function UserManagement(props) {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const { isFileUpload } = props;
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    };
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const userModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.user];
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const localtext = GridTextLocalization();
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [chipInfoList, setChipInfoList] = useState([]);
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const smallScreen = useMediaQuery(theme.breakpoints.down(889))
    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
        //added
        setAnchorEl(null);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    //added 
    const [isExportXlRequested, setExportXlRequested] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [keyValueData, setKeyValueData] = useState({});
    const anchorRef = React.useRef(null);
    const [searchedUserName, setSearchedUserName] = useState('');
    const [searchedEntityCode, setSearchedEntityCode] = useState('');
    const [searchedLoginId, setSearchedLoginId] = useState('');
    const [searchedUserType, setSearchedUserType] = useState('');
    const [searchedPurchasingGroup,setSearchedPurchasingGroup] = useState('');
    const [searchedValidToDate,setSearchedValidToDate] = useState('');
    const [searchedValidFromDate,setSearchedValidFromDate] = useState('');
    
    const handleToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        requestXLReportExport(menuOptionsForExportButton[index], false);
    };
    //end
    var defaultEntity = loggedInUserInfo.defaultEntity;

    const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.erpEntityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity }]
        : []

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);

    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.settings" defaultMessage="Settings" />,
            iconName: IconSettings,
        },
        {
            path: '/users',
            name: <FormattedMessage id="common.module.breadcrumb.usermanagement" defaultMessage="User Management" />,
            iconName: IconUserManagement,
        }
    ];
    const [primaryKeyOfSelectedRow, setPrimaryKeyOfSelectedRow] = useState(0);
    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.user);

    const renderUserType = (cellParam) => {
        if (cellParam.value === 'SIIX-USER') {
            return (<Chip
                icon={<AccountBoxIcon />}
                variant="outlined"
                label={cellParam.value}
                color="primary"
            />)
        } else if (cellParam.value === 'SUPPLIER') {
            return (<Chip
                icon={<IconSupplier />}
                variant="outlined"
                label={cellParam.value}
                color="secondary"
            />)
        } else if (cellParam.value === 'CUSTOMER') {
            return (<Chip
                icon={<IconSupplier />}
                variant="outlined"
                label={cellParam.value}
                color="secondary"
            />)
        }

    }

    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState(preference.columnsPinned);
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }


    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }

        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);

    const columnsForUserMangement = [
        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: 130,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    {(userModuleAccess !== undefined ? userModuleAccess.editFlag : false) &&
                        (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                let userId = cellParams.row.id;
                                history.push(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.user}/${userId}`, { mode: 'edit', userId: userId });
                            })} >

                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {(userModuleAccess !== undefined ? userModuleAccess.readFlag : false) &&
                        (<Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="read" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                let userId = cellParams.row.id;
                                history.push(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.user}/${userId}`, { mode: 'read', userId: userId });
                            })} >

                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <ViewIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {(userModuleAccess !== undefined ? userModuleAccess.deleteFlag : false) &&
                        (<Tooltip title={<FormattedMessage id="common.button.delete.tooltip" defaultMessage="Delete" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                handleDelete(cellParams.row.id, cellParams.row.userName);
                            })}>
                            <span>
                                <IconButton color='secondary' size="large">
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                </div>
            )
        },
        {
            field: columnFieldsName.orgName,
            headerName: intl.formatMessage({ id: "columnname.OrganizationName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 160,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            valueGetter: (params) => `${params.row.organization.orgName}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.erpOrgCode,
            headerName: intl.formatMessage({ id: "columnname.organizationcode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 160,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            valueGetter: (params) => `${params.row.organization.erpOrgCode}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.entityName,
            headerName: intl.formatMessage({ id: "columnname.entityName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 190,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            valueGetter: (params) => `${params.row.entity.entityName}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.erpEntityCode,
            headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 85,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: true,
            valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.userName,
            width: 150,
            headerName: intl.formatMessage({ id: "columnname.username.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'loginId',
            headerName: intl.formatMessage({ id: "columnname.loginid.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 140,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'userType',
            headerName: intl.formatMessage({ id: "columnname.usertype.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 140,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: renderUserType
        },
        {
            field: columnFieldsName.designation,
            headerName: intl.formatMessage({ id: "textfield.designation.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.purchasingGroup,
            headerName: intl.formatMessage({ id: "textfield.purchasingGroup.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 145,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.accountLocked,
            headerName: intl.formatMessage({ id: "columnname.accountlocked.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 195,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.customRenderStatusColumnForApprEmailSent
        },
        {
            field: 'supplierAccessPresent',
            headerName: intl.formatMessage({ id: "columnname.supaccperms.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: UserSupplierAccessDisplayField
        },
        {
            field: columnFieldsName.validFromDate,
            headerName: intl.formatMessage({ id: "columnname.validfromdate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 190,
            sortable: true,
            type: 'date',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <span>{cellParams.row.validFromDateDisp}</span>
            )
        },
        {
            field: columnFieldsName.validToDate,
            headerName: intl.formatMessage({ id: "columnname.validtodate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 180,
            sortable: true,
            type: 'date',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <span>{cellParams.row.validToDateDisp}</span>
            )
        },
        {
            field: 'active',
            headerName: intl.formatMessage({ id: "columnname.status.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 140,
            sortable: true,
            type: 'string',
            filterable: true,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.customRenderStatusColumn
        },
        {
            field: columnFieldsName.allowLogin,
            headerName: intl.formatMessage({ id: "common.allowlogin.lable" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 145,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.customRenderStatusColumn
        },
        {
            field: 'updatedBy',
            headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 160,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'updatedTime',
            headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 190,
            sortable: true,
            type: 'dateTime',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.lastUpdatedDtDisp}</span>)
        },
        {
            field: 'createdBy',
            headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 170,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'createdTime',
            headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 180,
            sortable: true,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.createdDtDisp}</span>)
        },
    ]
    const [columns, setColumns] = useState([]);
    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(columnsForUserMangement);
                savePreference("visibility", columnsForUserMangement);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, columnsForUserMangement);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let orgName = '';
                let userName = '';
                let orgCode = '';
                let entityCode = '';
                let entityName = '';
                let purchasingGroup = '';
                let validFromDate = '';
                let validToDate = '';
                let loginId = '';
                let userType = '';
                let active = '';
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'userName':
                            userName = columns
                            break;
                        case 'organization.orgName':
                            orgName = columns
                            break;
                        case 'organization.erpOrgCode':
                            orgCode = columns
                            break;
                        case 'entity.erpEntityCode':
                            entityCode = columns
                            break;
                        case 'entity.entityName':
                            entityName = columns
                            break;
                        case 'purchasingGroup':
                            purchasingGroup = columns
                            break;
                        case 'validFromDate':
                            validFromDate = columns
                            break;
                        case 'validToDate':
                            validToDate = columns
                            break;
                        case 'loginId':
                            loginId = columns
                            break;
                        case 'userType':
                            userType = columns
                            break;
                        case 'active':
                            active = columns
                            break;
                    }
                })
                //added
                setSearchedUserName(userName.value);
                setSearchedEntityCode(entityCode.value);
                setSearchedLoginId(loginId.value);
                setSearchedUserType(userType.value);
                setSearchedValidFromDate(validFromDate.value);
                setSearchedValidToDate(validToDate.value);
                setSearchedPurchasingGroup(purchasingGroup.value);

                var filterBody = {
                    userName: userName.value,
                    purchasingGroup: purchasingGroup.value,
                    organization: {
                        erpOrgCode: orgCode.value, orgName: orgName.value,
                    },
                    entity: {
                        erpEntityCode: entityCode.value,
                        entityName: entityName.value
                    },
                    recordAllowLogin: 'All',
                    validFromDate: validFromDate.value === undefined ? '' : validFromDate.value,
                    validToDate: validToDate.value === undefined ? '' : validToDate.value,
                    loginId: loginId.value,
                    userType: userType.value,
                    active: true,
                    recordActiveStatus: active.value === '' ? 'All' : active.value === 'Active' ? true : active.value === 'In-Active' ? false : 'All',
                };
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0)
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [selectedLanguage])

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.user, paramBody)
                .then((response) => {

                    setTotalRowCount(response.data.totalElements);
                    setTableLoadingStatus(false);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ? setChipInfoList(response.data.content[0].headerChipList === null ? [] :
                        response.data.content[0].headerChipList) : setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [paramBody, refreshTable])


    const closeConfirmationDialog = (confirmed) => {
        setOpenConfirmationDialog(false);
        if (confirmed) {
            CommonService.deleteByIdApi(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.user}/${primaryKeyOfSelectedRow}`, {})
                .then((response) => {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="usermangement.snackbar.deletemsg" defaultMessage="User deleted successfully" />, severity: "success" });
                    setRefreshTable(!refreshTable);
                })
                .catch(function (error) {
                    let message = (error.response.data !== undefined || error.response.data !== null) ? error.response.data.message
                        : <FormattedMessage id="usermangement.snackbar.error" defaultMessage="Error occurred while deleting User!" />;
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: message, severity: "error" });
                });
        }
    }

    const handleDelete = (primaryKey, nameToBeDisplacedOnConfirmationDialog) => {
        setPrimaryKeyOfSelectedRow(primaryKey);
        setConfirmationMessage(<FormattedMessage id="common.dialog.areyousure.txt" values={ {nameToBeDisplacedOnConfirmationDialog: `${nameToBeDisplacedOnConfirmationDialog}` }}  defaultMessage={`Are you sure, You want to delete '${nameToBeDisplacedOnConfirmationDialog}'?`} />);
        setOpenConfirmationDialog(true);
    }

    if (columns.length > 0) {
        const branchNameColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const branchNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const branchNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[branchNameColIndex] = {
            ...branchNameColumn,
            filterOperators: branchNameFilterOperators,
        };

        const orgCodeColumn = columns.find((column) => column.field === columnFieldsName.erpOrgCode);
        const orgCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpOrgCode);

        const orgCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgCodeInputValue,
            }),
        );

        columns[orgCodeColIndex] = {
            ...orgCodeColumn,
            filterOperators: orgCodeFilterOperators,
        };

        const userNameColumn = columns.find((column) => column.field === columnFieldsName.userName);
        const userNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.userName);

        const userNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.UserNameInputValue,
            }),
        );

        columns[userNameColIndex] = {
            ...userNameColumn,
            filterOperators: userNameFilterOperators,
        };

        const userTypeColumn = columns.find((column) => column.field === columnFieldsName.userType);
        const userTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.userType);

        const userTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.UserTypeInputValue,
            }),
        );

        columns[userTypeColIndex] = {
            ...userTypeColumn,
            filterOperators: userTypeFilterOperators,
        };

        const validFromDateColumn = columns.find((column) => column.field === columnFieldsName.validFromDate);
        const validFromDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.validFromDate);

        const validFromDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[validFromDateColIndex] = {
            ...validFromDateColumn,
            filterOperators: validFromDateFilterOperators,
        };

        const validToDateColumn = columns.find((column) => column.field === columnFieldsName.validToDate);
        const validToDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.validToDate);

        const validToDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[validToDateColIndex] = {
            ...validToDateColumn,
            filterOperators: validToDateFilterOperators,
        };

        const statusColumn = columns.find((column) => column.field === columnFieldsName.active);
        const statusColIndex = columns.findIndex((col) => col.field === columnFieldsName.active);

        const statusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.StatusColInputValue,
            }),
        );

        columns[statusColIndex] = {
            ...statusColumn,
            filterOperators: statusFilterOperators,
        };
    }

    //added
    const menuOptionsForExportButton = [
        {
            label: intl.formatMessage({ id: "uploadUser.button" }),
            tooltipTitle: intl.formatMessage({ id: "exportbuttonUser.Tooltip"}),
            url: '/reports/SYS_USER_REPORT',
            successMessage: <FormattedMessage id="snackbar.successExportReport" defaultMessage="Generated the Excel Report Successfully"/>,
            parameters: keyValueData
        },
        {
            label: intl.formatMessage({id : "uploadUserProject.button" }),
            tooltipTitle: intl.formatMessage({ id: "exportbuttonUserProject.Tooltip"}),
            url: '/reports/SYS_USER_PROJECT_REPORT',
            successMessage: <FormattedMessage id="snackbar.successExportReport" defaultMessage="Generated the Excel Report Successfully" />,
            parameters: keyValueData
        }
    ]
    //end

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let orgName = '';
        let userName = '';
        let orgCode = '';
        let entityCode = '';
        let entityName = '';
        let purchasingGroup = '';
        let validFromDate = '';
        let validToDate = '';
        let loginId = '';
        let userType = '';
        let active = '';
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'userName':
                    userName = columns
                    break;
                case 'organization.orgName':
                    orgName = columns
                    break;
                case 'organization.erpOrgCode':
                    orgCode = columns
                    break;
                case 'entity.erpEntityCode':
                    entityCode = columns
                    break;
                case 'entity.entityName':
                    entityName = columns
                    break;
                case 'purchasingGroup':
                    purchasingGroup = columns
                    break;
                case 'validFromDate':
                    validFromDate = columns
                    break;
                case 'validToDate':
                    validToDate = columns
                    break;
                case 'loginId':
                    loginId = columns
                    break;
                case 'userType':
                    userType = columns
                    break;
                case 'active':
                    active = columns
                    break;
                }
        })
        //added
        setSearchedUserName(userName.value);
        setSearchedEntityCode(entityCode.value);
        setSearchedLoginId(loginId.value);
        setSearchedUserType(userType.value);
        setSearchedValidFromDate(validFromDate.value);
        setSearchedValidToDate(validToDate.value);
        setSearchedPurchasingGroup(purchasingGroup.value);

        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);
        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    userName: userName.value,
                    purchasingGroup: purchasingGroup.value,
                    organization: {
                        erpOrgCode: orgCode.value, orgName: orgName.value,
                    },
                    entity: {
                        erpEntityCode: entityCode.value,
                        entityName: entityName.value
                    },
                    recordAllowLogin: 'All',
                    validFromDate: validFromDate.value === undefined ? '' : validFromDate.value,
                    validToDate: validToDate.value === undefined ? '' : validToDate.value,
                    loginId: loginId.value,
                    userType: userType.value,
                    active: true,
                    recordActiveStatus: active.value === '' ? 'All' : active.value === 'Active' ? true : active.value === 'In-Active' ? false : 'All',
                }
            }
        })
    }
    //added
    let filterBodyObj = {
         userName: searchedUserName === '' ? null : searchedUserName,
         erpEntityCode: searchedEntityCode === '' ? null : searchedEntityCode,
         loginId: searchedLoginId === '' ? null : searchedLoginId,
         userType: searchedUserType === '' ? null : searchedUserType,
         validFromDate: searchedValidFromDate === '' ? null : searchedValidFromDate,
         validToDate: searchedValidToDate === '' ? null : searchedValidToDate,
         purchasingGroup: searchedPurchasingGroup === '' ? null : searchedPurchasingGroup
    }

    useEffect(() => {
        let colValDataObj = {}
        for (let key in filterBodyObj) {
            let value = filterBodyObj[key]
            if (filterBodyObj.hasOwnProperty(key) && ((value !== null) && (value !== 0) && (value !== undefined) && (value !== {}))) {
                colValDataObj[key] = value.toString();            
            }
            setKeyValueData(colValDataObj)
        }
    },[searchedUserName, searchedEntityCode, searchedLoginId, searchedUserType, searchedValidFromDate, searchedValidToDate, searchedPurchasingGroup]);
   
    const requestXLReportExport = (selectedExportReportTypeObj) => {
        setExportXlRequested(true);
        setOpenBackDrop(true);
        CommonService.saveOrUpdateOrPost(selectedExportReportTypeObj.url, selectedExportReportTypeObj.parameters)

        .then((response) => {
            if (response.data.success === "No records found for given condition"){
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.success, severity:"error"});
            } else {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}`}}/>, severity: "success" });
            }
            setOpenBackDrop(false);
            setExportXlRequested(false);

        })
        .catch(function(error) {
            setExportXlRequested(false);
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error"});
            setOpenBackDrop(false);
        });
    }
  //end

    useEffect(() => {
        if (intialParamBodyApiCall)
            setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            {/* added */}
            <CommonBackDrop open={openBackDrop} />
            {/* end */}
            <Grid container className={classes.rootForUserManagement}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    {(userModuleAccess !== undefined ? userModuleAccess.createFlag : false) && (<Button size='small'
                        variant="contained" color="primary" className={classes.createButton} elevation={2} endIcon={smallScreen ? null : <CreateIcon />} onClick={(() => {
                            history.push(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.user}/0`, { mode: 'create', userId: 0 });
                        })}> {smallScreen ? <CreateIcon /> : <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />} </Button>)}
                </Grid>
                <ChipInfoList chipInfoList={chipInfoList} />
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <Paper elevation={3} className={classes.gridHeightAndWidthForUser}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            hideFooter={true}
                            density="compact"
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            localeText={localtext}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'><GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterListIcon />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            {/* added for export in user management*/}
                                            <Grid>
                                                <Tooltip
                                                    title={menuOptionsForExportButton[selectedIndex].tooltipTitle} placement='bottom'>
                                                    <span>
                                                        <Button size='small'
                                                                variant='outlined'
                                                                color='primary'
                                                                ref={anchorRef}
                                                                disabled={tableData.length <= 0 || isExportXlRequested}
                                                                endIcon={isExportXlRequested ? <CircularProgress color='secondary' size={20}/> : null}
                                                                onClick={handleToggle}
                                                                >
                                                                <ExportIcon />
                                                                <Typography style={{ margin: '0 5px 0 5px', fontWeight: 500, fontSize: '13px' }} >
                                                                 <FormattedMessage id="export.defaulttext.button" defaultMessage="EXPORT" />
                                                                </Typography>
                                                                <ArrowDropDownIcon/>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                            
                                                <Menu
                                                    id="selectReportExport"
                                                    anchorPosition = {{ left: 280, top: 150 }}
                                                    anchorReference="anchorPosition"
                                                    anchorEl={anchorEl}
                                                    elevation={5}
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleClose}
                                                >
                                                    <MenuList>
                                                        {menuOptionsForExportButton.map((option, index) => (
                                                            <Tooltip title={menuOptionsForExportButton[index].tooltipTitle} placement="right-start">
                                                                <span>
                                                                    <MenuItem
                                                                        divider
                                                                        className={classes.menuListStyle}
                                                                        key={index}
                                                                        selected={index === selectedIndex}
                                                                        onClick={(event) => handleMenuItemClick(event, index)}
                                                                    >
                                                                        {option.label}
                                                                    </MenuItem>
                                                                </span>
                                                            </Tooltip>
                                                        ))}
                                                    </MenuList>
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                            {/* end */}
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            hideFooterSelectedRowCount={true}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </div>
                </Paper>
                <ConfirmationDialog onClose={closeConfirmationDialog} message={confirmationMessage} open={openConfirmationDialog} />
            </Grid>
        </React.Fragment>
    )
}

export default UserManagement;