
export const ModuleAccessPermissionKey = () => {
    return {
        organization: "common.module.breadcrumb.columnname.oragnization",
        entity: "common.module.breadcrumb.columnname.entity",
        userManagement: "common.module.breadcrumb.usermanagement",
        user: "common.module.breadcrumb.user",
        role: "common.module.breadcrumb.role",
        supplier: "common.menu.module.breadcrumb.supplier",
        emailtemplate: "common.menu.breadcrumb.emailTemplate",
        customer: "common.menu.breadcrumb.customer",
        purchaseOrder: "common.menu.module.breadcrumb.purchaseorder.tabname",
        roleAccess: "common.module.tabname.roleaccess",
        branchAccess: "common.module.tabname.branchaccess",
        supplierAccess: "common.module.tabname.supplieraccess",
        customerAccess: "common.module.customeraccess",
        supplierGroup: "common.module.suppliergroup",
        customerGroup: "common.module.customergroup",
        messageConfig: "common.menu.breadcrumb.messageConfig",
        emailMessageConfig:"common.menu.breadcrumb.emailmessageconfig",
        erpInterfaceHdr: "common.menu.breadcrumb.erpinterface",
        poVmiApprovalModuleAccess: 'common.module.breadcrumb.povmiapproval',
        emailReportTemplateModuleAccess: "common.module.breadcrumb.emailreporttemplate",
        transmissionConfiguration: "common.modue.breadcrumb.transconfig",
        inboundPurchaseOrders: "common.menu.module.breadcrumb.purchaseorder",
        advanceShipmentNoteOutbound: "common.module.breadcrumb.advanceshipmentnoteoutbound",
        outboundPurchaseOrders: "common.module.breadcrumb.purchaseorderpurchaseforecastoutbound",
        invoice: "common.module.breadcrumb.invoice",
        purchaseOrderBP: "purchaseorder.module.text",
        purchaseForecastBP: "purchaseforecast.module.text",
        viewRequest: "viewrequest.module.text",
        systemReportTemplate: "systemreporttemplate.module.text",
        userReportTemplate: "userreporttemplate.module.text",
        emailnotificationsubscription: "common.menu.breadcrumb.emailnotificationsubscription",
        emailServerConfig: "common.modue.breadcrumb.columnname.emailserverconfig",
        project:"common.module.breadcrumb.columnname.project",
        projectAccess: "common.module.tabname.projectaccess",
        advanceShipmentNoteInbound: "common.module.breadcrumb.advanceshipmentnoteinbound",
        invoiceInbound: "common.module.breadcrumb.invoiceInbound",
        outboundPurchaseForecast:"common.module.breadcrumb.purchaseforecastoutbound" ,
        importTemplate: "importtemplate.module.text",
        packingList: "common.module.breadcrumb.packinglist",
        pcnModuleAccess: "common.module.breadcrumb.partchangenotification",
        PartChangeNotiBP:"partChangeNotification.module.text"
    }
}
