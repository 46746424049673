import React, { useEffect, useState } from 'react';
import {
    Button, CircularProgress, CssBaseline, Dialog, DialogActions,
    DialogContent, DialogTitle, Divider, Grid, Paper, Snackbar, Tooltip, Typography, useMediaQuery, useTheme
} from '@mui/material';
import { Alert, Timeline } from '@mui/lab';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CommonService } from '../../../services';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import ExportIcon from '@mui/icons-material/Publish';
import AuditTimeline from '../../common/AuditTimeline/AuditTimeline';
import useStyles from '../../common/CommonStyle/CommonStyle'
import { FormattedMessage, useIntl } from 'react-intl';
import { saveAs } from 'file-saver';

const AuditPoDelCfmDt = (props) => {

    const classes = useStyles();
    let theme = useTheme();
    const intl = useIntl();
    const { onClose, open, poHdr, poDtl, isPoForecastAudtitHistrory, poLineNumber, msgIdValue } = props;
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const pageLabelsNamesAndConst = {
        labels: {
            dialogTitle: 'Delivery Confirmation Audit History',
            dialogTitleForPFT: 'Purchase Forecast Audit History',
            cancel: 'Cancel',
            exportAuditHistory: 'Exp CFM ADT History',
            exportPFTAuditHistory: 'Exp PFT ADT History',
        },
    };

    const [recordsPerPage] = useState(30);
    const [pageNo, setPageNo] = useState(0);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(true);
    const [isExportXlRequested, setExportXlRequested] = useState(false);
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [data, setData] = useState([]);
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };

    const handleClose = (value) => {
        onClose(value);
        setData([]);
        setPageNo(0);
    }

    const next = (props) => {
        setPageNo(pageNo + 1);
    }

    useEffect(() => {
        if (open) {
            fetchPoCfmDtAuditList();
        }
    }, [poHdr, open, pageNo]);

    function fetchPoCfmDtAuditList() {
        let tempData = data;
        setTableLoadingStatus(true);
        CommonService.GetListApi(isPoForecastAudtitHistrory ? '/poOutboundSchAuditHistory' : `/poOutboundCfmDateAudit`, {
            body: {
                poOutboundMsgDtls: {
                    msgDtlsId: poDtl
                },
                poOutboundMsgHdr: {
                    msgId: poHdr !== undefined ? poHdr.msgId : ''
                }
            },
            pageNo: pageNo,
            sortDir: 'desc',
            sortKey: 'createdDt',
            recordsPerPage: recordsPerPage
        })
            .then((response) => {
                setTotalPageCount(response.data.totalPages);
                setData(tempData.concat(response.data.content));
                setTableLoadingStatus(false);
            })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setTableLoadingStatus(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }

    function requestPoDlvyCfmDtAuditHistory() {
        setExportXlRequested(true);
        CommonService.saveOrUpdateOrPost(isPoForecastAudtitHistrory ? '/reports/PURCHASE_ORDER_FORECAST_DTL_HISTORY_REPORT' : '/reports/DLV_SCHEDULE_HISTORY_REPORT', {
            poScheduleType: isPoForecastAudtitHistrory ? "4" : "1",
            msgId: msgIdValue.toString(),
        })
            .then((response) => {
              //  showDownloadPopUp(response.data.fileUrl)
                setExportXlRequested(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}` ,reportName:`${response.data.reportName}`}} />, severity: "success" });
            })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setExportXlRequested(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }

    const showDownloadPopUp = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}>
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container className={classes.root}>
                <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <Dialog fullWidth maxWidth='md' open={open}>
                        <DialogTitle className={classes.title}>
                            <Grid item container xs={12} sm={12} md={12} lg={12}>
                                <Grid item container alignItems='center' item xs={12} sm={6} md={6} lg={6} >
                                    <strong>{!isPoForecastAudtitHistrory ? <FormattedMessage id="dlvcfmadthistorytitle.text" defaultMessage={pageLabelsNamesAndConst.labels.dialogTitle} /> :
                                        <FormattedMessage id="PFtAuditHistory.txt" defaultMessage={pageLabelsNamesAndConst.labels.dialogTitleForPFT} />} - {poHdr !== undefined ? poHdr.poNo : ''}
                                    </strong>
                                </Grid>
                                {<Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={6} md={6} lg={6}>
                                    <Button
                                        size='small'
                                        style={{ color: theme.palette.customText.main, fontWeight: 600 }}
                                        disabled={data.length <= 0 || isExportXlRequested}
                                        component="span"
                                        startIcon={<ExportIcon />}
                                        onClick={(() => {
                                            requestPoDlvyCfmDtAuditHistory();
                                        })}
                                        endIcon={isExportXlRequested ? <CircularProgress color="secondary" size={20} /> : null}
                                    > {!isPoForecastAudtitHistrory ? <FormattedMessage id="expcfmadthistory.tooltip" /> : <FormattedMessage id="exppftadthistory.tooltip" />}
                                    </Button>
                                </Grid>}
                            </Grid>
                        </DialogTitle>
                        <DialogContent id="scrollableDiv" dividers style={{ minHeight: '200px' }}>
                            {(data.length > 0) && (<InfiniteScroll
                                dataLength={data.length}
                                next={next}
                                hasMore={totalPageCount !== (pageNo + 1)}
                                scrollableTarget="scrollableDiv"
                                loader={
                                    <Grid container justifyContent={isMobile ? 'flex-start' : 'center'} alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                        <CircularProgress />
                                        <h6><FormattedMessage id="loading.text" defaultMessage="Loading....." /></h6>
                                    </Grid>}>
                                <Timeline align={isMobile ? "left" : "alternate"}>
                                    {data && data.map((poCfmDtAudit, index) => {
                                        let timelineIcon;
                                        let timelineColor;
                                        let timelineConnectorColor;
                                        let title;
                                        let modifiedDate;
                                        let detailedMessage;
                                        let isLastNode = (data.length - 1) !== index;
                                        let modifiedBy = poCfmDtAudit.modifiedBy !== null || poCfmDtAudit.modifiedBy !== '' ? poCfmDtAudit.modifiedBy : intl.formatMessage({ id: "system.text" });
                                        if (isPoForecastAudtitHistrory) {
                                            if (poCfmDtAudit.recordStatus === 'INSERTED') {
                                                timelineIcon = <SaveIcon />;
                                                timelineColor = 'primary';
                                                timelineConnectorColor = classes.primaryTail;
                                                modifiedDate = poCfmDtAudit.createdDtDisp;
                                                title = <FormattedMessage id="adtInserTitle.text" values={{ details: `${poCfmDtAudit.poOutboundMsgDtls.poLineNo}` }} />;
                                                detailedMessage = <FormattedMessage id="pft.detailedmessage.confirm.text" values={{ modified: `${modifiedBy}`, newDelveryReqQty: `${poCfmDtAudit.newDeliveryQty}`, newDeliveryDtDisp: `${poCfmDtAudit.newScheduleReqDtDisp}` }} />;
                                            } else if (poCfmDtAudit.recordStatus === 'CHANGED') {
                                                timelineIcon = <EditIcon />;
                                                timelineColor = 'secondary';
                                                timelineConnectorColor = classes.secondaryTail;
                                                modifiedDate = poCfmDtAudit.createdDtDisp;
                                                title = <FormattedMessage id="adtEditTitle.text" values={{ details: `${poCfmDtAudit.poOutboundMsgDtls.poLineNo}` }} />;

                                                detailedMessage = <FormattedMessage id="pft.detailedmessage.edit.text" values={{ modified: `${modifiedBy}`, oldDelveryReqQty: `${poCfmDtAudit.oldDeliveryQty}`, oldDeliveryDtDisp: `${poCfmDtAudit.oldScheduleReqDtDisp}`, newDelveryReqQty: `${poCfmDtAudit.newDeliveryQty}`, newDeliveryDtDisp: `${poCfmDtAudit.newScheduleReqDtDisp}` }} />;

                                            } else if (poCfmDtAudit.recordStatus === 'DELETED') {
                                                timelineIcon = <DeleteIcon />;
                                                modifiedDate = poCfmDtAudit.createdDtDisp;
                                                title = <FormattedMessage id="adtRemoveTitle.text" values={{ details: `${poCfmDtAudit.poOutboundMsgDtls.poLineNo}` }} />;

                                                detailedMessage = <FormattedMessage id="pft.detailedmessage.remove.text" values={{ modified: `${modifiedBy}`, oldDelveryReqQty: `${poCfmDtAudit.oldDeliveryQty}`, oldDeliveryDtDisp: `${poCfmDtAudit.oldScheduleReqDtDisp}` }} />;
                                            }
                                        } else {
                                            if (poCfmDtAudit.oldConfirmQty === 0 && poCfmDtAudit.oldConfirmDt === null) {
                                                timelineIcon = <SaveIcon />;
                                                timelineColor = 'primary';
                                                timelineConnectorColor = classes.primaryTail;
                                                modifiedDate = poCfmDtAudit.createdDtDisp;
                                                title = <FormattedMessage id="CfmAdtInsetTitle.text" values={{ details: `${poCfmDtAudit.poOutboundMsgDtls.poLineNo}` }} />
                                                detailedMessage = <FormattedMessage id="detailedmessage.confirm.text" values={{ modified: `${poCfmDtAudit.modifiedBy}`, newConfirmQty: ` ${poCfmDtAudit.newConfirmQty}`, newConfirmDtDisp: `${poCfmDtAudit.newConfirmDtDisp}` }} />;
                                            } else if (poCfmDtAudit.oldConfirmQty !== 0 && poCfmDtAudit.oldConfirmDt !== null && poCfmDtAudit.newConfirmQty !== 0 && poCfmDtAudit.newConfirmDt !== null) {
                                                timelineIcon = <EditIcon />;
                                                timelineColor = 'secondary';
                                                timelineConnectorColor = classes.secondaryTail;
                                                modifiedDate = poCfmDtAudit.createdDtDisp;
                                                title = <FormattedMessage id="CfmAdtEditTitle.text" values={{ details: ` ${poCfmDtAudit.poOutboundMsgDtls.poLineNo}` }} />;
                                                detailedMessage = <FormattedMessage id="detailedmessage.edit.text" values={{ modified: `${poCfmDtAudit.modifiedBy}`, oldCfnQnty: `'${poCfmDtAudit.oldConfirmQty}`, oldCfmDtDisp: `${poCfmDtAudit.oldConfirmDtDisp}'`, newCfmQty: `'${poCfmDtAudit.newConfirmQty}`, newConfirmDtDisp: ` ${poCfmDtAudit.newConfirmDtDisp}'` }} />;
                                            } else if (poCfmDtAudit.newConfirmQty === 0 && poCfmDtAudit.newConfirmDt === null) {
                                                timelineIcon = <DeleteIcon />;
                                                modifiedDate = poCfmDtAudit.createdDtDisp;
                                                title = <FormattedMessage id="CfmAdtRemovedTitle.text" values={{ details: `${poCfmDtAudit.poOutboundMsgDtls.poLineNo}` }} />;
                                                detailedMessage = <FormattedMessage id="detailedmessage.remove.text" values={{ modified: `${poCfmDtAudit.modifiedBy}`, oldCfnQnty: `'${poCfmDtAudit.oldConfirmQty}`, oldCfmDtDisp: `${poCfmDtAudit.oldConfirmDtDisp}'` }} defaultMessage={`${poCfmDtAudit.modifiedBy} removed rec. '${poCfmDtAudit.oldConfirmQty} qty - ${poCfmDtAudit.oldConfirmDtDisp}'`} />;
                                            }
                                        }

                                        return (
                                            <React.Fragment>
                                                <AuditTimeline
                                                    timelineIcon={timelineIcon}
                                                    timelineColor={timelineColor}
                                                    timelineConnectorColor={timelineConnectorColor}
                                                    title={title}
                                                    isLastNode={isLastNode}
                                                    modifiedDate={modifiedDate}
                                                    detailedMessage={detailedMessage}
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                                </Timeline>
                            </InfiniteScroll>)}
                            {tableLoadingStatus && (
                                <Paper style={{ marginTop: '25px', width: '100%' }}>
                                    <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                        <CircularProgress color='primary' size={40} />
                                    </Grid>
                                </Paper>
                            )}
                            {(data.length <= 0 && !tableLoadingStatus) && (
                                <Paper style={{ marginTop: '25px', width: '100%' }}>
                                    <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                        <Typography variant='body2' noWrap={true} className={classes.boldHeaderForNoRows}><FormattedMessage id="norows.label" defaultMessage="No rows" /></Typography>
                                    </Grid>
                                </Paper>
                            )}
                        </DialogContent>
                        {!isMobile && (
                            <DialogActions className={classes.dialogAction} >
                                <Grid container justifyContent='flex-end' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} >
                                    <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2} endIcon={<ClearIcon />}
                                        onClick={() => handleClose(false)}
                                    ><FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />
                                    </Button>
                                </Grid>
                            </DialogActions>)}
                        {isMobile && (<Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                            <DialogActions className={classes.dialogAction}>
                                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.topGrid} >
                                    <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                                        <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2}
                                            onClick={() => handleClose(false)}
                                        >  <ClearIcon />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </DialogActions>
                        </Grid>)}
                    </Dialog>
                </Grid>
            </Grid>
        </React.Fragment >
    );
}

export default AuditPoDelCfmDt;
