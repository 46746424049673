import { Badge, Button, CssBaseline, Grid, IconButton, Paper, Snackbar, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Alert } from '@mui/lab';
import { getGridStringOperators, GridToolbarColumnsButton, GridToolbarDensitySelector, LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import { CommonService } from '../../../services';
import useStyles from '../../common/CommonStyle/CommonStyle';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import ViewIcon from '@mui/icons-material/Visibility';
import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import CreateOrEditMessageConfigDtl from './CreateOrEditMessageConfigDtl';
import IconMessageConfig from '@mui/icons-material/Message';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { CustomFilters } from '../../common/CustomFilters/CustomFilters';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import CustomFilterComponent, { GetFilterableColumns } from '../../common/CustomFilterComponent';
import { FilterList } from '@mui/icons-material';

const columnFieldsName = {
    processName: 'processName',
    inOut: 'inOut',
    msgFormat: 'msgFormat',
    msgType: 'msgType',
    msgVersion: 'msgVersion',
    frequency: 'frequency',
    commType: 'commType',
    commId: 'commId',
    approvalReqd: 'approvalReqd',
    progId: 'progId',
    createdTime: 'createdTime',
    createdBy: 'createdBy',
    updatedTime: 'updatedTime',
    updatedBy: 'updatedBy',
    active: 'active'
}

function MessageConfigDtl(props) {
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const { configId, mode, messageConfigToBeEdited } = props;
    const messageConfigModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.messageConfig];
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    const pageLabelsConstantsAndMessages = {
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
    };

    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.messageConfigDtl);

    useEffect(() => {
        if (configId > 0) {
            setParamBody({
                ...paramBody, body:
                {
                    messageConfigHdr: { configId: configId },
                    active: '',
                    processName: '',
                    inOut: '',
                    msgFormat: '',
                    msgType: '',
                    msgVersion: '',
                    commType: '',
                    approvalStatus: 'All',
                    approvalReqd: true,
                }
            })
        }
    }, [configId])


    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [refreshTable, setRefreshTable] = useState(false);
    const [createConfigDtl, setMode] = React.useState('')
    const [createOrEditOrViewMessageConfigDialog, setCreateOrEditOrViewMessageConfigDialog] = useState(false);
    const [messageConfigDtlObjectToBeEditedOrView, setMessageConfigDtlObjectToBeEditedOrView] = useState({});
    const [searchedMessageConfigHdr] = useState('');
    const [searchedApprovalStatus] = useState('');
    const [msgId, setMsgId] = useState(0);

    const closeCreateOrEditViewDialog = (confirmed) => {
        if (confirmed) {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success" });
            setRefreshTable(!refreshTable);
        }
        setMessageConfigDtlObjectToBeEditedOrView({});
        setCreateOrEditOrViewMessageConfigDialog(false);
    }

    const [columns, setColumns] = useState([]);

    const componentList = [
        {
            path: null,
            name: <FormattedMessage id="common.menu.breadcrumb.messageconfiguration" />,
            iconName: IconMessageConfiguration,
        },
        {
            path: '/messageConfigHdrs',
            name: <FormattedMessage id="common.menu.breadcrumb.messageConfig" defaultMessage="Organization" />,
            iconName: IconMessageConfig,
        }
    ];

    const commonBreadCrumbToBeUsedForDialog = componentList;

    useEffect(() => {
        setColumns([
            {
                field: 'action',
                headerName: intl.formatMessage({ id: "columnname.action.label" }),
                width: mode === 'read' ? 100 : 160,
                sortable: false,
                hide: false,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (
                    <div>
                        {(messageConfigModuleAccess !== undefined ? messageConfigModuleAccess.editFlag : false) && (mode !== 'read') && (
                            <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    setMode('edit');
                                    setCreateOrEditOrViewMessageConfigDialog(true);
                                    setMessageConfigDtlObjectToBeEditedOrView(cellParams.row)
                                })}  >
                                <span>
                                    <IconButton className={classes.customeToolbar} size="large">
                                        <EditIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                        {(messageConfigModuleAccess !== undefined ? messageConfigModuleAccess.readFlag : false) &&
                            (<Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage='read' />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    setMode('read');
                                    setCreateOrEditOrViewMessageConfigDialog(true);
                                    setMessageConfigDtlObjectToBeEditedOrView(cellParams.row)
                                }
                                )} >
                                <span>
                                    <IconButton className={classes.customeToolbar} size="large">
                                        <ViewIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                        {(messageConfigModuleAccess !== undefined ? messageConfigModuleAccess.deleteFlag : false) && (mode !== 'read') && (
                            <Tooltip title={<FormattedMessage id="common.button.delete.tooltip" defaultMessage="Delete" />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    handleDelete(cellParams.row.id, cellParams.row.processName);
                                    setMsgId(cellParams.row.id);
                                })}>
                                <span>
                                    <IconButton color='secondary' size="large">
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                    </div>
                )
            },
            {
                field: columnFieldsName.processName,
                headerName: intl.formatMessage({ id: "columnname.process.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 170,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.inOut,
                headerName: intl.formatMessage({ id: "columname.msgdirection.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 170,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: ((cellParams) => {
                    if (messageConfigToBeEdited.bpType === 'SUPPLIER' && cellParams.row.processName === 'Part Change Notification') {
                        return 'To Internal';
                    } else if (messageConfigToBeEdited.bpType === 'CUSTOMER' && cellParams.row.inOut === 'IN') {
                        return 'From Customer';
                    } else if (messageConfigToBeEdited.bpType === 'CUSTOMER' && cellParams.row.inOut === 'OUT') {
                        return 'To Customer';
                    } else if (messageConfigToBeEdited.bpType === 'SUPPLIER' && cellParams.row.inOut === 'IN') {
                        return 'From Supplier';
                    } else if (messageConfigToBeEdited.bpType === 'SUPPLIER' && cellParams.row.inOut === 'OUT') {
                        return 'To Supplier';
                    }
                })


            },
            {
                field: columnFieldsName.msgFormat,
                headerName: intl.formatMessage({ id: "columnname.messageformat.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 200,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.msgType,
                headerName: intl.formatMessage({ id: "columnname.messagetype.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 150,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.msgVersion,
                headerName: intl.formatMessage({ id: "columnname.messageversion.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 150,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            // {
            //     field: columnFieldsName.frequency,
            //     headerName: intl.formatMessage({ id: "columnname.frequency.label" }),
            //     headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            //     align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            //     width: 150,
            //     sortable: true,
            //     type: 'string',
            //     filterable: false,
            //     hide: false,
            //     renderHeader: CommonService.customRenderHeaderColumns,
            // },
            {
                field: columnFieldsName.commType,
                headerName: intl.formatMessage({ id: "columnname.commType.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 160,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: 'confName',
                headerName: intl.formatMessage({ id: "columnname.commId.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 160,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.approvalReqd,
                headerName: intl.formatMessage({ id: "columnname.approvalreqd.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.customRenderYesOrNoColumn
            },
            {
                field: columnFieldsName.active,
                headerName: intl.formatMessage({ id: "columnname.status.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 140,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.customRenderStatusColumn
            },
            {
                field: columnFieldsName.updatedBy,
                headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 155,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.updatedTime,
                headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.lastUpdatedDtDisp}</span>)
            },
            {
                field: columnFieldsName.createdBy,
                headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 150,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.createdTime,
                headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.createdDtDisp}</span>)
            },
        ])
    }, [selectedLanguage, messageConfigToBeEdited]);

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (configId > 0) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.messageConfigHdr}/${configId}/messageConfigDtls`, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [paramBody, refreshTable])

    const closeConfirmationDialog = (confirmed) => {
        setOpenConfirmationDialog(false);
        if (confirmed) {
            CommonService.deleteByIdApi(`/messageConfigDtls/${msgId}`, {})
                .then((response) => {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: "Message Config Deleted Successfully", severity: "success" });
                    setRefreshTable(!refreshTable);
                })
                .catch(function (error) {
                    let message = (error.response.data !== undefined || error.response.data !== null) ? error.response.data.message
                        : <FormattedMessage id="role.error.txt" defaultMessage="Error occurred while deleting Message Config!" />;
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: message, severity: "error" });
                });
        }
    }

    const handleDelete = (primaryKey, nameToBeDisplacedOnConfirmationDialog) => {
        setMsgId(primaryKey);
        setConfirmationMessage(<FormattedMessage id="common.dialog.areyousure.txt" values={{ nameToBeDisplacedOnConfirmationDialog: `${nameToBeDisplacedOnConfirmationDialog}` }} defaultMessage={`Are you sure, You want to delete '${nameToBeDisplacedOnConfirmationDialog}'?`} />);
        setOpenConfirmationDialog(true);
    }

    if (columns.length > 0) {
        const processNameColumn = columns.find((column) => column.field === columnFieldsName.processName);
        const processNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.processName);

        const processNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.ProcessInputValue,
            }),
        );

        columns[processNameColIndex] = {
            ...processNameColumn,
            filterOperators: processNameFilterOperators,
        };

        const inOutColumn = columns.find((column) => column.field === columnFieldsName.inOut);
        const inOutColIndex = columns.findIndex((col) => col.field === columnFieldsName.inOut);

        const inOutFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: messageConfigToBeEdited.bpType === 'SUPPLIER' ? CustomFilters.MessageDirectionSupplierInputValue : CustomFilters.MessageDirectionCustomerInputValue,
            }),
        );

        columns[inOutColIndex] = {
            ...inOutColumn,
            filterOperators: inOutFilterOperators,
        };

        const statusColumn = columns.find((column) => column.field === columnFieldsName.active);
        const statusColIndex = columns.findIndex((col) => col.field === columnFieldsName.active);

        const statusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.StatusColInputValue,
            }),
        );

        columns[statusColIndex] = {
            ...statusColumn,
            filterOperators: statusFilterOperators,
        };

        const msgTypeColumn = columns.find((column) => column.field === columnFieldsName.msgType);
        const msgTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.msgType);

        const msgTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.MsgTypeLogInputValue,
            }),
        );

        columns[msgTypeColIndex] = {
            ...msgTypeColumn,
            filterOperators: msgTypeFilterOperators,
        };

        const commTypeColumn = columns.find((column) => column.field === columnFieldsName.commType);
        const commTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.commType);

        const commTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.CommTypeLogInputValue,
            }),
        );

        columns[commTypeColIndex] = {
            ...commTypeColumn,
            filterOperators: commTypeFilterOperators,
        };
    }

    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const defaulSelectedFilters = [];

    const [selectedFilters, setSelectedFilters] = useState([]);

    const key = "columnField"

    const uniqueSelectedFiltersData = [...new Map(selectedFilters.map(item => [item[key], item])).values()];

    const applyFilter = () => {
        let processName = '';
        let inOut = '';
        let msgFormat = '';
        let msgType = '';
        let msgVersion = '';
        let commType = '';
        let active = '';
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'processName':
                    processName = columns
                    break;
                case 'inOut':
                    inOut = columns
                    break;
                case 'msgFormat':
                    msgFormat = columns
                    break;
                case 'msgType':
                    msgType = columns
                    break;
                case 'msgVersion':
                    msgVersion = columns
                    break;
                case 'commType':
                    commType = columns
                    break;
                case 'active':
                    active = columns
                    break;
            }
        })
        

        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    messageConfigHdr: { configId: searchedMessageConfigHdr },
                    processName:
                        inOut.value === 'To Internal' ? 'Part Change Notification' :
                            processName.value,
                    inOut: inOut.value === 'From Supplier' || inOut.value === 'From Customer' ? 'IN' :
                        inOut.value === 'To Supplier' || inOut.value === 'To Customer' ? 'OUT' : '',
                    msgFormat: msgFormat.value,
                    msgType: msgType.value,
                    msgVersion: msgVersion.value,
                    commType: commType.value,
                    approvalStatus: searchedApprovalStatus === '' ? 'All' : searchedApprovalStatus,
                    recordActiveStatus: active.value === '' ? 'All' : active.value === 'Active' ? true : active.value === 'In-Active' ? false : 'All',
                }
            }
        })
    }

    useEffect(() => {
        setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container className={classes.rootForRole}>
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <Paper elevation={3} className={classes.gridHeightAndWidthForMessageConfigDtl}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableColumnMenu={false}
                            disableMultipleColumnsSorting={true}
                            hideFooter={true}
                            localeText={localtext}
                            density="compact"
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            onSortModelChange={(params) => {
                                let sortModel = params[0];
                                if (sortModel !== undefined) {
                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                }
                            }}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}  >
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" />}
                                                        placement='bottom'>
                                                        <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterList />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    {(messageConfigModuleAccess !== undefined ? messageConfigModuleAccess.createFlag : false) && (mode !== 'read' && mode !== 'create') && (<Tooltip title={<FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setMode('create');
                                                                    setCreateOrEditOrViewMessageConfigDialog(true)
                                                                })}
                                                                disabled={(configId === 0 || mode === 'read') ? true : false}
                                                            >
                                                                <CreateIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>)}
                                                    <CreateOrEditMessageConfigDtl
                                                        open={createOrEditOrViewMessageConfigDialog}
                                                        onClose={closeCreateOrEditViewDialog}
                                                        mode={createConfigDtl}
                                                        title={commonBreadCrumbToBeUsedForDialog}
                                                        messageConfigDtlObjectToBeEditedOrView={messageConfigDtlObjectToBeEditedOrView}
                                                        configId={configId}
                                                        DialogHeaderAndHeaderValue={messageConfigToBeEdited}
                                                        msgConfigDtlData={tableData}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </div>
                </Paper>
                <ConfirmationDialog onClose={closeConfirmationDialog} message={confirmationMessage} open={openConfirmationDialog} />
            </Grid>
        </React.Fragment>
    )
}

export default MessageConfigDtl;