import { Badge, Button, CssBaseline, Grid, Paper, Snackbar, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    LicenseInfo, DataGridPro, getGridStringOperators,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import { CommonService } from '../../../services';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import EditIcon from '@mui/icons-material/EditOutlined';
import Alert from '@mui/lab/Alert';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import IconSettings from '@mui/icons-material/Settings';
import IconAccount from '@mui/icons-material/AccountBalance';
import RefreshIcon from '@mui/icons-material/Refresh';
import PropTypes from 'prop-types';
import OrgCombo from '../../common/Combos/OrgCombo';
import useStyles from '../../common/CommonStyle/CommonStyle';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';
import CreateIcon from '@mui/icons-material/Add';
import ViewIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import CommonDialogComponent from '../../common/CommonDialogComponent/CommonDialogComponent'
import BasicDetailsForm from './CreateorEdit/BasicDetailsFormOrg'
import ContactDetailsForm from './CreateorEdit/ContactDetailsFormOrg';
import AddressForm from './CreateorEdit/AddressFormOrg';
import AccoutPolicyForm from './CreateorEdit/AccoutPolicyFormOrg'
import Others from './CreateorEdit/Others'
import { useForm } from 'react-hook-form';
import ChipInfoList from '../../common/ChipInfoList';
import { CustomFilters } from "../../common/CustomFilters/CustomFilters";
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import InfoIcon from '@mui/icons-material/Info';
import CustomFilterComponent, { GetFilterableColumns } from '../../common/CustomFilterComponent';
import FilterListIcon from '@mui/icons-material/FilterList';
import { manageUserPreferences } from '../../../services/manageUserPreferences';


const columnFieldsName = {
    orgName: 'orgName',
    erpOrgCode: 'erpOrgCode',
    active: 'active',
};

function OrgNameInputValue(props) {
    const { item, applyValue } = props;
    const [selectedOrganization, setSelectedOrganization] = useState(item.value !== undefined ? JSON.parse(item.value) : '');
    useEffect(() => {
        applyValue({ ...item, value: JSON.stringify(selectedOrganization) });
    }, [selectedOrganization])

    const orgComboProperty = {
        name: 'value',
        isRequired: false,
        label: 'Value',
        isAutofocus: false,
        isDisabled: false,
        variant: "standard",
        isHookFormType: false
    }

    return (
        <OrgCombo
            comboProperty={orgComboProperty}
            comboValue={selectedOrganization}
            setComboValue={setSelectedOrganization} />
    );
};

OrgNameInputValue.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string,
        id: PropTypes.number,
        operatorValue: PropTypes.string,
        value: PropTypes.string
    }).isRequired
};


function Organization(props) {
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );

    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    };
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const organizationModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.organization];
    const localtext = GridTextLocalization();
    const currentUser = CommonService.getLoggedInUserInfo();
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const [orgObjectToBeEditedOrView, setOrgObjectToBeEditedOrView] = useState([]);
    const [orgToBeSavedOrUpdated, setOrgToBeSavedOrUpdated] = useState(0);
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const [modeChanged, setModeChanged] = React.useState('')
    const [openOrganisationDialog, setOpenOrganisationDialog] = useState(false);
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [chipInfoList, setChipInfoList] = useState([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.organization);
    const [tabvalue, setTabValue] = React.useState(0);
    const [isIconRequiredForTheTab] = useState(true)
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [hasErrors, setHasErrors] = useState('')
    const smallScreen = useMediaQuery(theme.breakpoints.down(889))
    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    const defaulSelectedFilters = [];
    const [selectedFilters, setSelectedFilters] = useState([]);

    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.settings" defaultMessage="Settings" />,
            iconName: IconSettings,
        },
        {
            path: '/organizations',
            name: <FormattedMessage id="common.module.breadcrumb.columnname.oragnization" defaultMessage="Organization" />,
            iconName: IconAccount,
        }
    ];
    const commonBreadCrumbToBeUsedForDialog = componentList;
    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState(preference.columnsPinned);
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }

    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);

    const columnsForOrganization =
        [
            {
                field: 'action',
                headerName: intl.formatMessage({ id: "columnname.action.label" }),
                width: 130,
                sortable: false,
                hide: false,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) =>
                (
                    <div>
                        {(organizationModuleAccess !== undefined ? organizationModuleAccess.editFlag : false) &&
                            (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    functionForReset(cellParams.row)
                                    setOpenOrganisationDialog(true)
                                    setOrgObjectToBeEditedOrView(cellParams.row)
                                    setModeChanged('edit')
                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar} size="large">
                                        <EditIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}

                        {(organizationModuleAccess !== undefined ? organizationModuleAccess.readFlag : false) &&
                            (<Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.readButton} />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    setOrgObjectToBeEditedOrView(cellParams.row);
                                    functionForReset(cellParams.row)
                                    setOpenOrganisationDialog(true)
                                    setModeChanged('view')

                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar} size="large">
                                        <ViewIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                    </div>
                )
            },
            {
                field: columnFieldsName.orgName,
                width: 280,
                headerName: intl.formatMessage({ id: 'columnname.OrganizationName.label' }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.erpOrgCode,
                headerName: intl.formatMessage({ id: 'columnname.organizationcode.label' }),
                width: 150,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: 'country',
                headerName: intl.formatMessage({ id: 'columnname.country.labele' }),
                width: 160,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: 'contactPersonName',
                width: 220,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                headerName: intl.formatMessage({ id: 'columnname.contactpersonname.label' }),
                sortable: true,
                type: 'string',
                filterable: false,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: 'contactPersonEmailId',
                width: 230,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                headerName: intl.formatMessage({ id: 'columnname.emailid.label' }),
                sortable: true,
                type: 'string',
                filterable: false,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.active,
                headerName: intl.formatMessage({ id: "columnname.status.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 145,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.customRenderStatusColumn
            },
            {
                field: 'updatedBy',
                headerName: intl.formatMessage({ id: 'columnname.updatedby.label' }),
                width: 150,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: 'updatedTime',
                headerName: intl.formatMessage({ id: 'columnname.updateddate.label' }),
                width: 180,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                sortable: true,
                type: 'dateTime',
                filterable: false,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.lastUpdatedDtDisp}</span>)
            },
            {
                field: 'createdBy',
                headerName: intl.formatMessage({ id: 'columnname.columnnamecreatedby.label' }),
                width: 150,
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                type: 'string',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: 'createdTime',
                headerName: intl.formatMessage({ id: 'columnname.createddate.label' }),
                width: 180,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                sortable: true,
                type: 'dateTime',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.createdDtDisp}</span>)
            },
            {
                field: 'contactPersonPhoneNo',
                width: 160,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                headerName: intl.formatMessage({ id: 'columnname.phoneno.label' }),
                sortable: true,
                type: 'number',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: 'city',
                headerName: intl.formatMessage({ id: 'columnname.city.label' }),
                width: 150,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: 'state',
                headerName: intl.formatMessage({ id: 'columnname.state.label' }),
                width: 140,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: 'addressLine1',
                headerName: intl.formatMessage({ id: 'columnname.AddressLine1.label' }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 300,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: 'addressLine2',
                headerName: intl.formatMessage({ id: 'columnname.AddressLine2.label' }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 300,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: 'addressLine3',
                headerName: intl.formatMessage({ id: 'columnname.AddressLine3.label' }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 300,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },

            {
                field: 'postalCode',
                headerName: intl.formatMessage({ id: 'columnname.postalcode.label' }),
                width: 170,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: 'telNo',
                headerName: intl.formatMessage({ id: 'columnname.telephone.label' }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
                width: 180,
                sortable: true,
                type: 'number',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: 'webUrl',
                headerName: intl.formatMessage({ id: 'columnname.WebURL.label' }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 230,
                sortable: true,
                type: 'number',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: 'emailDomains',
                headerName: intl.formatMessage({ id: 'columnname.EmailDomains.label' }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'number',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: 'passwordExpiryDays',
                headerName: intl.formatMessage({ id: 'columnname.PasswordExpiryDays.label' }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
                width: 220,
                sortable: true,
                type: 'number',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: 'passwordExpiryNotifyDays',
                headerName: intl.formatMessage({ id: 'columnname.PasswordExpiryNoifyDays.label' }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
                width: 260,
                sortable: true,
                type: 'number',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: 'maxLoginFailCount',
                headerName: intl.formatMessage({ id: 'columnname.MaxLoginFailCount.label' }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
                width: 210,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
        ]
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }
        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(columnsForOrganization);
                savePreference("visibility", columnsForOrganization);
                setParamBody({ ...paramBody });

            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, columnsForOrganization);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let orgName = '';
                let erpOrgCode = '';
                let active = '';
               
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'orgName':
                            orgName = columns
                            break;
                        case 'erpOrgCode':
                            erpOrgCode = columns
                            break;
                        case 'active':
                            active = columns
                            break;
                    }
                })
                var filterBody = {
                        active: true,
                        recordActiveStatus: active.value === '' ? 'All' : active.value === 'Active' ? true : active.value === 'In-Active' ? false : 'All',
                        erpOrgCode: erpOrgCode.value, 
                        orgName: orgName.value,

                };
              //  paramBody.body = filterBody.erpOrgCode != undefined && filterBody.orgName != undefined ? filterBody :
              //  apiUrlAndParamBodyConstants.paramBodyForGetListApis.organization.body;
                paramBody.body = filterBody;

                if (savedColumnsInResponse.columnsFilter.length <= 0)
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, [selectedLanguage])


    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([])
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.organization, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }

    }, [paramBody, refreshTable])


    const { handleSubmit, reset, formState: { errors, isDirty, isValid }, shouldFocusError, control, trigger, getValues, setValue } = useForm({
        mode: "all",
        reValidateMode: 'all',
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        defaultValues: {
            active: true,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',

        }
    });

    const functionForReset = (selectObject) => {
        reset({
            orgName: selectObject.orgName !== undefined ? selectObject.orgName : '',
            erpOrgCode: selectObject.erpOrgCode !== undefined ? selectObject.erpOrgCode : '',
            active: selectObject.active !== undefined ? selectObject.active : true,
            contactPersonSalutation: selectObject.contactPersonSalutation !== undefined ? selectObject.contactPersonSalutation : '',
            contactPersonName: selectObject.contactPersonName !== undefined ? selectObject.contactPersonName : '',
            contactPersonEmailId: selectObject.contactPersonEmailId !== undefined ? selectObject.contactPersonEmailId : '',
            contactPersonPhoneNo: selectObject.contactPersonPhoneNo !== undefined ? selectObject.contactPersonPhoneNo : '',
            addressLine1: selectObject.addressLine1 !== undefined ? selectObject.addressLine1 : '',
            addressLine2: selectObject.addressLine2 !== undefined ? selectObject.addressLine2 : '',
            addressLine3: selectObject.addressLine3 !== undefined ? selectObject.addressLine3 : '',
            city: selectObject.city !== undefined ? selectObject.city : '',
            state: selectObject.state !== undefined ? selectObject.state : '',
            country: selectObject.country !== undefined ? selectObject.country : '',
            countryCode: selectObject.countryCode !== undefined ? selectObject.countryCode : '',
            postalCode: selectObject.postalCode !== undefined ? selectObject.postalCode : '',
            telNo: selectObject.telNo !== undefined ? selectObject.telNo : '',
            faxNo: selectObject.faxNo !== undefined ? selectObject.faxNo : '',
            passwordExpiryDays: selectObject.passwordExpiryDays !== undefined ? selectObject.passwordExpiryDays : '',
            passwordExpiryNotifyDays: selectObject.passwordExpiryNotifyDays !== undefined ? selectObject.passwordExpiryNotifyDays : '',
            maxLoginFailCount: selectObject.maxLoginFailCount !== undefined ? selectObject.maxLoginFailCount : '',
            webUrl: selectObject.webUrl !== undefined ? selectObject.webUrl : '',
            emailDomains: selectObject.emailDomains !== undefined ? selectObject.emailDomains : '',
        })
    }

    const onSubmit = data => {
        data['id'] = (modeChanged === 'create') ? '' : orgObjectToBeEditedOrView.id;
        data['createdBy'] = (modeChanged === 'create') ? currentUser.userName : orgObjectToBeEditedOrView.createdBy;
        data['createdTime'] = (orgObjectToBeEditedOrView.createdTime === undefined) ? '' : orgObjectToBeEditedOrView.createdTime;
        data['updatedBy'] = currentUser.userName;
        data['updatedTime'] = (orgObjectToBeEditedOrView.updatedTime === undefined) ? '' : orgObjectToBeEditedOrView.updatedTime;
        setIsFormSubmitted(true);
        CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.organization, data)
            .then((response) => {

                setAlertBoxState({
                    ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success"
                });
                setIsFormSubmitted(false);
                closeOrgansisationDialog(true)
                setTabValue(0)
            })
            .catch(function (error) {
                setIsFormSubmitted(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            });
    }

    if (columns.length > 0) {
        const orgNameColumn = columns.find((column) => column.field === columnFieldsName.orgName);
        const orgNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.orgName);

        const orgNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgNameInputValue,
            }
            ),
        );

        columns[orgNameColIndex] = {
            ...orgNameColumn,
            filterOperators: orgNameFilterOperators,
        };
        const orgCodeColumn = columns.find((column) => column.field === columnFieldsName.erpOrgCode);
        const orgCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpOrgCode);

        const orgCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgCodeInputValue,
            }),
        );

        columns[orgCodeColIndex] = {
            ...orgCodeColumn,
            filterOperators: orgCodeFilterOperators,
        };

        const statusColumn = columns.find((column) => column.field === columnFieldsName.active);
        const statusColIndex = columns.findIndex((col) => col.field === columnFieldsName.active);

        const statusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.StatusColInputValue,
            }),
        );

        columns[statusColIndex] = {
            ...statusColumn,
            filterOperators: statusFilterOperators,
        };
    }

    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };

    const closeOrgansisationDialog = (confirmed) => {
        if (confirmed) {
            setRefreshTable(!refreshTable);
        }
        setOpenOrganisationDialog(false);
        setModeChanged('')
    }

    const onError = errors => {
        setAlertBoxState({ ...alertBoxState, openAlert: true, message: "Please fill all the required fields to Submit", severity: "error", err: true }
        )
    }

    const resetAllClearTheBasicInfo = () => {
        if (modeChanged === 'edit') {
            functionForReset(orgObjectToBeEditedOrView)
            setOrgObjectToBeEditedOrView({ ...orgObjectToBeEditedOrView });
            setTabValue(0);
        } else {
            reset({
                orgName: '',
                erpOrgCode: '',
                contactPersonSalutation: '',
                contactPersonName: '',
                contactPersonPhoneNo: '',
                contactPersonEmailId: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                country: '',
                state: '',
                countryCode: '',
                postalCode: '',
                faxNo: '',
                telNo: '',
                passwordExpiryDays: '',
                passwordExpiryNotifyDays: '',
                maxLoginFailCount: '',
                emailDomains: '',
                webUrl: '',
                active: true
            });
            setOrgObjectToBeEditedOrView({ ...orgObjectToBeEditedOrView, active: true });
            setValue('passwordExpiryDays', 180)
            setValue('passwordExpiryNotifyDays', 15)
            setValue('maxLoginFailCount', 3)
            setTabValue(0);
        }
    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let orgName = '';
        let erpOrgCode = '';
        let active = '';
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'orgName':
                    orgName = columns
                    break;
                case 'erpOrgCode':
                    erpOrgCode = columns
                    break;
                case 'active':
                    active = columns
                    break;
            }
        })
         savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);
        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    erpOrgCode: erpOrgCode.value,
                    orgName: orgName.value,
                    active: true,
                    recordActiveStatus: active.value === '' ? 'All' : active.value === 'Active' ? true : active.value === 'In-Active' ? false : 'All',
                }
            }
        })
    }

    useEffect(() => {
        if (intialParamBodyApiCall)
        setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData]);

    const validateRequiredFieldsForBasicDetails = ((!(getValues('orgName') !== '' && getValues('erpOrgCode') !== '')));

    const validateRequiredFieldsForTab1 = ((!( ('contactPersonSalutation') !== '' && getValues('contactPersonName') !== '' && getValues('contactPersonEmailId') !== '' && getValues('contactPersonPhoneNo') !== '')));

    const validateRequiredFieldsForTab2 = ((!(getValues('addressLine1') !== '' && getValues('city') !== '' && getValues('state') !== '' && getValues('country') !== '' && getValues('countryCode') !== '' && getValues('postalCode') !== '' && getValues('telNo') !== '')));

    const validateRequiredFieldsForTab3 = ((!(getValues('passwordExpiryDays') !== '' && getValues('passwordExpiryNotifyDays') !== '' && getValues('maxLoginFailCount') !== '')));

    const validateRequiredFieldsForTab4 = ((!(getValues('emailDomains') !== '')));

    const validateRequiredFieldsForTab5 = false;

    const errorObjectsCollectedForTab1 = (!(hasErrors['orgName'] !== undefined || hasErrors['erpOrgCode'] !== undefined || hasErrors['contactPersonSalutation'] !== undefined || hasErrors['contactPersonName'] !== undefined || hasErrors['contactPersonEmailId'] !== undefined || hasErrors['contactPersonPhoneNo'] !== undefined))

    const errorObjectsCollectedForTab2 = (!(hasErrors['addressLine1'] !== undefined || hasErrors['city'] !== undefined || hasErrors['state'] !== undefined || hasErrors['country'] !== undefined || hasErrors['countryCode'] !== undefined || hasErrors['postalCode'] !== undefined || hasErrors['telNo'] !== undefined || hasErrors['faxNo'] !== undefined))
  
    const errorObjectsCollectedForTab3 = (!(hasErrors['passwordExpiryDays'] !== undefined || hasErrors['passwordExpiryNotifyDays'] !== undefined || hasErrors['maxLoginFailCount'] !== undefined))
  
    const errorObjectsCollectedForTab4 = (!(hasErrors['emailDomains'] !== undefined || hasErrors['webUrl'] !== undefined));

    const errorObjectsCollectedForTab5 =  true;
    
    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container className={classes.rootForRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    {(organizationModuleAccess !== undefined ? organizationModuleAccess.createFlag : false) &&
                        (<Button size='small' variant="contained" color="primary" className={classes.createButton} elevation={2} endIcon={smallScreen ? null : <CreateIcon />} onClick={() => {
                            setOpenOrganisationDialog(true)
                            setModeChanged('create')
                        }}>

                            {smallScreen ? <CreateIcon /> : <FormattedMessage id="common.button.Create.label" defaultMessage="Create" />}
                        </Button>)}
                </Grid>
                <ChipInfoList chipInfoList={chipInfoList} />
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <CommonDialogComponent
                    open={openOrganisationDialog}
                    onClose={closeOrgansisationDialog}
                    title={commonBreadCrumbToBeUsedForDialog}
                    control={control}
                    basicDetails={<BasicDetailsForm basicmode={modeChanged} errors={errors} control={control} orgObjectToBeEditedOrView={orgObjectToBeEditedOrView} setHasErrors={setHasErrors} />}
                    contactDetails={<ContactDetailsForm contactmode={modeChanged} errors={errors} control={control} setHasErrors={setHasErrors} />}
                    addressDetails={<AddressForm addressmode={modeChanged} errors={errors} control={control} setHasErrors={setHasErrors} />}
                    bankOrAccountPolicyDetails={<AccoutPolicyForm accountmode={modeChanged} errors={errors} control={control} orgObjectToBeEditedOrView={orgObjectToBeEditedOrView} setValue={setValue} setHasErrors={setHasErrors} />}
                    others={<Others onSubmit={onSubmit} othersmode={modeChanged} errors={errors} control={control} setHasErrors={setHasErrors} />}
                    trigger={trigger}
                    reset={resetAllClearTheBasicInfo}
                    handleSubmit={handleSubmit}
                    submit={onSubmit}
                    errors={onError}
                    mode={modeChanged}
                    contactDetailsTabtitle={<FormattedMessage id="common.contactdetails.label" defaultMessage="CONTACT DETAILS" />}
                    addressDetailsTabtitle={<FormattedMessage id="common.addressdetails.label" defaultMessage="ADDRESS DETAILS" />}
                    bankorAccountDetailstabtitle={<FormattedMessage id="common.accountpolicy.label" defaultMessage="ACCOUNT POLICY" />}
                    OthersDetailstabtitle={<FormattedMessage id="common.others.label" defaultMessage="OTHERS" />}
                    warningMessage={(modeChanged === 'edit' || modeChanged === 'create') ? <Typography justifyContent="flex-start" alignItems="center" style={{ fontSize: 12 }} ><InfoIcon fontSize="small" style={{ marginBottom: '-1.4%' }} color="primary" /><FormattedMessage id="textfield.placeholder.dialogActions" defaultMessage="All the '*' marked fields are mandatory to submit the form" />
                    </Typography> : null}
                    isDirty={isDirty}
                    isValid={isValid}
                    getValues={getValues}
                    shouldFocusError={shouldFocusError}
                    hasErrors={hasErrors}
                    tabvalue={tabvalue}
                    setTabValue={setTabValue}
                    isFormSubmitted={isFormSubmitted}
                    isIconRequiredForTheTab={isIconRequiredForTheTab}
                    validateRequiredFieldsForBasicDetails={validateRequiredFieldsForBasicDetails}
                    validateRequiredFieldsForTab1={validateRequiredFieldsForTab1}
                    validateRequiredFieldsForTab2={validateRequiredFieldsForTab2}
                    validateRequiredFieldsForTab3={validateRequiredFieldsForTab3}
                    validateRequiredFieldsForTab4={validateRequiredFieldsForTab4}
                    validateRequiredFieldsForTab5={validateRequiredFieldsForTab5}
                    errorObjectsCollectedForTab1={errorObjectsCollectedForTab1}
                    errorObjectsCollectedForTab2={errorObjectsCollectedForTab2}
                    errorObjectsCollectedForTab3={errorObjectsCollectedForTab3}
                    errorObjectsCollectedForTab4={errorObjectsCollectedForTab4}
                    errorObjectsCollectedForTab5={errorObjectsCollectedForTab5}
                />
                <Paper elevation={3} className={classes.gridHeightAndWidthForRole}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            hideFooter={true}
                            localeText={localtext}
                            density="compact"
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}  >
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'><GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterListIcon />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined" className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            hideFooterSelectedRowCount={true}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </div>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}

export default Organization;