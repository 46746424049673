import { Badge, Button, CssBaseline, Grid, Paper, Snackbar, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    LicenseInfo, DataGridPro, getGridStringOperators,
    GridToolbarColumnsButton, GridToolbarDensitySelector
} from '@mui/x-data-grid-pro';
import { CommonService } from '../../../services';
import ChipInfoList from '../../common/ChipInfoList';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import EditIcon from '@mui/icons-material/EditOutlined';
import Alert from '@mui/lab/Alert';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import IconSettings from '@mui/icons-material/Settings';
import IconBranch from '@mui/icons-material/AccountTree';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CustomFilters } from '../../common/CustomFilters/CustomFilters';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';
import CommonDialogComponent from '../../common/CommonDialogComponent/CommonDialogComponent'
import CreateIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import BasicDetails from './CreateorEdit/BasicDetailsEntity'
import ContactDetails from './CreateorEdit/ContactDetailsEntity';
import AddressDetails from './CreateorEdit/AddressDetailsEntity';
import BankDetails from './CreateorEdit/BankDetailsEntity';
import IconButton from '@mui/material/IconButton';
import ViewIcon from '@mui/icons-material/Visibility';
import AccountPolicyForm from './CreateorEdit/AccountPolicyEntity';
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import InfoIcon from '@mui/icons-material/Info';
import CustomFilterComponent from '../../common/CustomFilterComponent/CustomFilterComponent';
import FilterListIcon from '@mui/icons-material/FilterList';
import { GetFilterableColumns } from '../../common/CustomFilterComponent';
import { manageUserPreferences } from '../../../services/manageUserPreferences';
import ProcessParameterEntity from './CreateorEdit/ProcessParameterEntity';
import useStyles from '../../common/CommonStyle/CommonStyle';

const columnFieldsName = {
    erpOrgCode: 'organization.erpOrgCode',
    erpEntityCode: 'erpEntityCode',
    city: 'city',
    state: 'state',
    country: 'country',
    orgName: 'organization.orgName',
    entityName: 'entityName',
    active: 'active',
    bankName: 'bankName',
};

function Branch() {
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );

    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    };

    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const localtext = GridTextLocalization();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const entityModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.entity];
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const smallScreen = useMediaQuery(theme.breakpoints.down(889))
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [chipInfoList, setChipInfoList] = useState([]);
    const [entityToBeSavedOrUpdated, setEntityToBeSavedOrUpdated] = useState(0);
    const [modeChanged, setModeChanged] = React.useState('')
    const [entityObjectToBeEditedOrView, setEntityObjectToBeEditedOrView] = useState([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [tabvalue, setTabValue] = React.useState(0);
    const [openEntityDialog, setOpenEntityDialog] = useState(false);
    const currentUser = CommonService.getLoggedInUserInfo();
    const [defaultOrgObj, setDefaultOrgObj] = useState({});
    const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());
    const [tableData, setTableData] = useState([]);
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.entity);
    const [isIconRequiredForTheTab] = useState(true);
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [timeZoneValue, setTimeZoneValue] = useState({ value: '' });
    const [dlvyCfmDummyDate, setDlvyCfmDummyDate] = useState(new Date("2049/12/31"));

    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    var defaultEntity = loggedInUserInfo.defaultEntity;

    const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.erpEntityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity }]
        : [];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);

    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.settings" defaultMessage="Settings" />,
            iconName: IconSettings,
        },
        {
            path: '/entities',
            name: <FormattedMessage id="common.module.breadcrumb.columnname.entity" defaultMessage="Entity" />,
            iconName: IconBranch,
        }
    ];

    const commonBreadCrumbToBeUsedForDialog = componentList;


    const { handleSubmit, reset, formState: { errors, isDirty, isValid }, control, getValues, setValue } = useForm({
        mode: "all",
        reValidateMode: 'all',
        defaultValues: {
            active: true,
            reg1No: '',
            reg2No: '',
            reg3No: '',
            organization: null,
            entityName: '',
            erpEntityCode: '',
            contactPersonSalutation: '',
            contactPersonName: '',
            contactPersonPhoneNo: '',
            contactPersonEmailId: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            city: '',
            country: '',
            state: '',
            countryCode: '',
            postalCode: '',
            faxNo: '',
            telNo: '',
            passwordExpiryDays: 180,
            passwordExpiryNotifyDays: 15,
            maxLoginFailCount: 3,
            bankAccount: '',
            accountHolder: '',
            bankKey: '',
            vatRegNo: '',
            bankName: '',
            timeZone: '',
            lateAcceptanceDays: 0,
            earlierAcceptanceDays: 0,
            dlvyCfmDummyDate:new Date("2049/12/31"),
        }
    });
    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState(preference.columnsPinned);
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }


    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        // else if (preferenceChangeIn === "order") {
        // }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);

    const columnsForBranch = [

        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: 130,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    {(entityModuleAccess !== undefined ? entityModuleAccess.editFlag : false) &&
                        (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                setEntityObjectToBeEditedOrView(cellParams.row)
                                setOpenEntityDialog(true)
                                setModeChanged('edit')
                                EntityResetValues(cellParams.row)

                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {(entityModuleAccess !== undefined ? entityModuleAccess.readFlag : false) &&
                        (<Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.readButton} />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                setEntityObjectToBeEditedOrView(cellParams.row);
                                setOpenEntityDialog(true);
                                setModeChanged('view');
                                EntityResetValues(cellParams.row);

                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <ViewIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                </div>
            )
        },
        {
            field: columnFieldsName.orgName,
            headerName: intl.formatMessage({ id: "columnname.OrganizationName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 280,
            hide: true,
            sortable: true,
            type: 'string',
            filterable: false,
            valueGetter: (params) => `${params.row.organization.orgName}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.erpOrgCode,
            width: 150,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            headerName: intl.formatMessage({ id: "columnname.organizationcode.label" }),
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            valueGetter: (params) => `${params.row.organization.erpOrgCode}`,
        },
        {
            field: columnFieldsName.entityName,
            width: 250,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            headerName: intl.formatMessage({ id: "common.EntityName.label" }),
            sortable: true,
            filterable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.erpEntityCode,
            width: 85,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
            sortable: false,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.country,
            headerName: intl.formatMessage({ id: 'columnname.country.labele' }),
            width: 150,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: 'contactPersonName',
            width: 220,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            headerName: intl.formatMessage({ id: 'columnname.contactpersonname.label' }),
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'contactPersonEmailId',
            width: 230,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            headerName: intl.formatMessage({ id: 'columnname.emailid.label' }),
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'bankAccount',
            width: 200,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            headerName: intl.formatMessage({ id: 'columnname.textfield.bankaccount.label' }),
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'accountHolder',
            width: 200,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            headerName: intl.formatMessage({ id: 'columnname.accountHolder.label' }),
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'vatRegNo',
            width: 160,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            headerName: intl.formatMessage({ id: "columnname.vatregno.label" }),
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'bankKey',
            width: 200,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            headerName: intl.formatMessage({ id: 'common.BankKey.label' }),
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.active,
            headerName: intl.formatMessage({ id: 'columnname.status.label' }),
            width: 140,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.customRenderStatusColumn
        },
        {
            field: 'updatedBy',
            headerName: intl.formatMessage({ id: 'columnname.updatedby.label' }),
            width: 145,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'updatedTime',
            headerName: intl.formatMessage({ id: 'columnname.updateddate.label' }),
            width: 180,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'dateTime',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.lastUpdatedDtDisp}</span>)
        },
        {
            field: 'createdBy',
            headerName: intl.formatMessage({ id: 'columnname.columnnamecreatedby.label' }),
            width: 145,
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'createdTime',
            headerName: intl.formatMessage({ id: 'columnname.createddate.label' }),
            width: 180,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'dateTime',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.createdDtDisp}</span>)
        },
        {
            field: 'passwordExpiryDays',
            headerName: intl.formatMessage({ id: 'columnname.PasswordExpiryDays.label' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 220,
            sortable: true,
            type: 'number',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'passwordExpiryNotifyDays',
            headerName: intl.formatMessage({ id: 'columnname.PasswordExpiryNoifyDays.label' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 260,
            sortable: true,
            type: 'number',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'maxLoginFailCount',
            headerName: intl.formatMessage({ id: 'columnname.MaxLoginFailCount.label' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 210,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.city,
            headerName: intl.formatMessage({ id: 'columnname.city.label' }),
            width: 150,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.state,
            headerName: intl.formatMessage({ id: 'columnname.state.label' }),
            width: 150,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: 'addressLine1',
            headerName: intl.formatMessage({ id: 'columnname.AddressLine1.label' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'addressLine2',
            headerName: intl.formatMessage({ id: "columnname.AddressLine2.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'addressLine3',
            headerName: intl.formatMessage({ id: 'columnname.AddressLine3.label' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },

        {
            field: 'postalCode',
            headerName: intl.formatMessage({ id: 'columnname.postalcode.label' }),
            width: 160,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: 'telNo',
            headerName: intl.formatMessage({ id: "columnname.telephone.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 180,
            sortable: true,
            type: 'number',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'faxNo',
            headerName: intl.formatMessage({ id: "columnname.faxno.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 150,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
    ]
    const [columns, setColumns] = useState([]);
    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(columnsForBranch);
                savePreference("visibility", columnsForBranch);
                paramBody.body.erpEntityCode = defaultEntity;
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, columnsForBranch);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let orgName = '';
                let orgCode = '';
                let entityCode = '';
                let entityName = '';
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'organization.orgName':
                            orgName = columns
                            break;
                        case 'organization.erpOrgCode':
                            orgCode = columns
                            break;
                        case 'erpEntityCode':
                            entityCode = columns
                            break;
                        case 'entityName':
                            entityName = columns
                            break;
                    }
                })
                var filterBody = {
                    recordActiveStatus: 'All',
                    active: true,
                    organization: {
                        erpOrgCode: orgCode.value, orgName: orgName.value,
                    },
                    erpEntityCode: entityCode.value,
                    entityName: entityName.value
                };
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0)
                    paramBody.body.erpEntityCode = defaultEntity;
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [selectedLanguage])


    function EntityResetValues(selectObject) {
        reset({
            organization: selectObject.organization !== undefined ? { erpOrgCode: selectObject.organization.erpOrgCode, orgName: selectObject.organization.orgName } : defaultOrgObj,
            entityName: selectObject.entityName !== undefined ? selectObject.entityName : '',
            erpEntityCode: selectObject.erpEntityCode !== undefined ? selectObject.erpEntityCode : '',
            active: selectObject.active !== undefined ? selectObject.active : true,
            contactPersonSalutation: selectObject.contactPersonSalutation !== undefined ? selectObject.contactPersonSalutation : '',
            contactPersonName: selectObject.contactPersonName !== undefined ? selectObject.contactPersonName : '',
            contactPersonEmailId: selectObject.contactPersonEmailId !== undefined ? selectObject.contactPersonEmailId : '',
            contactPersonPhoneNo: selectObject.contactPersonPhoneNo !== undefined ? selectObject.contactPersonPhoneNo : '',
            addressLine1: selectObject.addressLine1 !== undefined ? selectObject.addressLine1 : '',
            addressLine2: selectObject.addressLine2 !== undefined ? selectObject.addressLine2 : '',
            addressLine3: selectObject.addressLine3 !== undefined ? selectObject.addressLine3 : '',
            city: selectObject.city !== undefined ? selectObject.city : '',
            state: selectObject.state !== undefined ? selectObject.state : '',
            country: selectObject.country !== undefined ? selectObject.country : '',
            countryCode: selectObject.countryCode !== undefined ? selectObject.countryCode : '',
            postalCode: selectObject.postalCode !== undefined ? selectObject.postalCode : '',
            telNo: selectObject.telNo !== undefined ? selectObject.telNo : '',
            faxNo: selectObject.faxNo !== undefined ? selectObject.faxNo : '',
            passwordExpiryDays: selectObject.passwordExpiryDays !== undefined ? selectObject.passwordExpiryDays : '',
            passwordExpiryNotifyDays: selectObject.passwordExpiryNotifyDays !== undefined ? selectObject.passwordExpiryNotifyDays : '',
            maxLoginFailCount: selectObject.maxLoginFailCount !== undefined ? selectObject.maxLoginFailCount : '',
            bankAccount: selectObject.bankAccount !== undefined ? selectObject.bankAccount : '',
            accountHolder: selectObject.accountHolder !== undefined ? selectObject.accountHolder : '',
            bankKey: selectObject.bankKey !== undefined ? selectObject.bankKey : '',
            vatRegNo: selectObject.vatRegNo !== undefined ? selectObject.vatRegNo : '',
            bankKey: selectObject.bankKey !== undefined ? selectObject.bankKey : '',
            bankName: selectObject.bankName !== undefined ? selectObject.bankName : '',
            timeZone: selectObject.timeZone !== undefined ? { value: selectObject.timeZone } : '',
            reg1No: selectObject.reg1No !== undefined ? selectObject.reg1No : '',
            reg2No: selectObject.reg2No !== undefined ? selectObject.reg2No : '',
            reg3No: selectObject.reg3No !== undefined ? selectObject.reg3No : '',
            earlierAcceptanceDays: selectObject.earlierAcceptanceDays !== undefined ? selectObject.earlierAcceptanceDays : 0,
            lateAcceptanceDays: selectObject.lateAcceptanceDays !== undefined ? selectObject.lateAcceptanceDays : 0,
            dlvyCfmDummyDate: selectObject.dlvyCfmDummyDate !== undefined ? selectObject.dlvyCfmDummyDate : new Date("2049/12/31"),
        });
        setTimeZoneValue(selectObject.timeZone !== undefined ? { value: selectObject.timeZone } : '');
        setDlvyCfmDummyDate(selectObject.dlvyCfmDummyDate !== undefined ? selectObject.dlvyCfmDummyDate : new Date("2049/12/31"));

    }

    useEffect(() => {
        if (modeChanged === 'create') {
            EntityResetValues({})
        }
    }, [defaultOrgObj])

    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi('/entities', paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [paramBody, refreshTable])


    if (columns.length > 0) {
        const orgCodeColumn = columns.find((column) => column.field === columnFieldsName.erpOrgCode);
        const orgCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpOrgCode);

        const orgCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgCodeInputValue,
            }),
        );

        columns[orgCodeColIndex] = {
            ...orgCodeColumn,
            filterOperators: orgCodeFilterOperators,
        };

        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };
        const orgNameColumn = columns.find((column) => column.field === columnFieldsName.orgName);
        const orgNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.orgName);

        const orgNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgNameInputValue,
            }),
        );

        columns[orgNameColIndex] = {
            ...orgNameColumn,
            filterOperators: orgNameFilterOperators,
        };

        const entityNameColumn = columns.find((column) => column.field === columnFieldsName.entityName);
        const entityNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.entityName);

        const entityNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityNameInputValue,
            }),
        );

        columns[entityNameColIndex] = {
            ...entityNameColumn,
            filterOperators: entityNameFilterOperators,
        };
    }

    const closeEntityDialog = (confirmed) => {
        if (confirmed) {
            setRefreshTable(!refreshTable);
        }
        setEntityObjectToBeEditedOrView({});
        setOpenEntityDialog(false);
    }

    const resetAllClearTheBasicInfo = () => {
        if (modeChanged === 'edit') {
            EntityResetValues(entityObjectToBeEditedOrView);
            setEntityObjectToBeEditedOrView({ ...entityObjectToBeEditedOrView });
            setTabValue(0);
        } else {
            reset({
                organization: null,
                entityName: '',
                erpEntityCode: '',
                active: true,
                contactPersonSalutation: '',
                contactPersonName: '',
                contactPersonPhoneNo: '',
                contactPersonEmailId: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                country: '',
                state: '',
                countryCode: '',
                postalCode: '',
                faxNo: '',
                telNo: '',
                passwordExpiryDays: '',
                passwordExpiryNotifyDays: '',
                maxLoginFailCount: '',
                bankAccount: '',
                accountHolder: '',
                bankKey: '',
                vatRegNo: '',
                bankName: '',
                timeZone: '',
                reg3No: '',
                reg2No: '',
                reg1No: '',
                lateAcceptanceDays: 0,
                earlierAcceptanceDays: 0,
                dlvyCfmDummyDate:new Date("2049/12/31")
            });
            setEntityObjectToBeEditedOrView({ ...entityObjectToBeEditedOrView, active: true });
            setTabValue(0);
            setTimeZoneValue({ ...entityObjectToBeEditedOrView, timeZone: '' });
            setValue('passwordExpiryDays', 180)
            setValue('passwordExpiryNotifyDays', 15)
            setValue('maxLoginFailCount', 3)
            setDlvyCfmDummyDate(new Date("2049/12/31"));

        }
    }

    const [hasErrors, setHasErrors] = useState('')

    const onSubmit = data => {
        data['id'] = (modeChanged === 'create') ? entityToBeSavedOrUpdated : entityObjectToBeEditedOrView.id;
        data['createdBy'] = currentUser.userName
        data['createdTime'] = (entityObjectToBeEditedOrView.createdTime === undefined) ? '' : entityObjectToBeEditedOrView.createdTime;
        data['updatedBy'] = currentUser.userName;
        data['updatedTime'] = (entityObjectToBeEditedOrView.updatedTime === undefined) ? '' : entityObjectToBeEditedOrView.updatedTime;
        data['accountHolder'] = (entityObjectToBeEditedOrView.entityName === undefined) ? data.entityName : entityObjectToBeEditedOrView.entityName;
        data['timeZone'] = timeZoneValue.value;
        data['dlvyCfmDummyDate'] = dlvyCfmDummyDate;
        
        setIsFormSubmitted(true);
        CommonService.saveOrUpdateOrPost('/entities', data)
            .then((response) => {
                setAlertBoxState({
                    ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success"
                });
                setIsFormSubmitted(false);
                closePoConfirmationAudit(true)
                setTabValue(0)
            })
            .catch(function (error) {
                setIsFormSubmitted(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            });
    }

    const closePoConfirmationAudit = (confirmed) => {
        if (confirmed) {
            setRefreshTable(!refreshTable);
        }
        setOpenEntityDialog(false);
        setModeChanged('')
    }

    const onError = errors => {
        setAlertBoxState({ ...alertBoxState, openAlert: true, message: "Please fill all the required fields to Submit", severity: "error", err: true }
        )
    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let orgName = '';
        let orgCode = '';
        let entityCode = '';
        let entityName = '';
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'organization.orgName':
                    orgName = columns
                    break;
                case 'organization.erpOrgCode':
                    orgCode = columns
                    break;
                case 'erpEntityCode':
                    entityCode = columns
                    break;
                case 'entityName':
                    entityName = columns
                    break;
            }
        })
        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);
        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    recordActiveStatus: 'All',
                    active: true,
                    organization: {
                        erpOrgCode: orgCode.value, orgName: orgName.value,
                    },
                    erpEntityCode: entityCode.value,
                    entityName: entityName.value
                }
            }
        })
    }

    useEffect(() => {
        if (intialParamBodyApiCall)
            setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData]);

    const validateRequiredFieldsForBasicDetails = ((!(getValues('entityName') !== '' && getValues('erpEntityCode') !== '')));

    const validateRequiredFieldsForTab1 = ((!(getValues('contactPersonSalutation') !== '' && getValues('contactPersonName') !== '' && getValues('contactPersonEmailId') !== '' && getValues('contactPersonPhoneNo') !== '')));

    const validateRequiredFieldsForTab2 = ((!(getValues('addressLine1') !== '' && getValues('city') !== '' && getValues('state') !== '' && getValues('country') !== '' && getValues('countryCode') !== '' && getValues('postalCode') !== '' && getValues('telNo') !== '')));

    const validateRequiredFieldsForTab3 = ((!(getValues('passwordExpiryDays') !== '' && getValues('passwordExpiryNotifyDays') !== '' && getValues('maxLoginFailCount') !== '')));

    const validateRequiredFieldsForTab4 = false;
    // (((getValues('bankName') !== '' || getValues('bankKey') !== '' || getValues('bankAccount') !== ''))
    // );

    const validateRequiredFieldsForTab5 = false;   

    const errorObjectsCollectedForTab1 = (!(hasErrors['entityName'] !== undefined || hasErrors['erpEntityCode'] !== undefined || hasErrors['contactPersonSalutation'] !== undefined || hasErrors['contactPersonName'] !== undefined || hasErrors['contactPersonEmailId'] !== undefined || hasErrors['contactPersonPhoneNo'] !== undefined))

    const errorObjectsCollectedForTab2 = (!(hasErrors['addressLine1'] !== undefined || hasErrors['city'] !== undefined || hasErrors['state'] !== undefined || hasErrors['country'] !== undefined || hasErrors['countryCode'] !== undefined || hasErrors['postalCode'] !== undefined || hasErrors['telNo'] !== undefined || hasErrors['faxNo'] !== undefined))

    const errorObjectsCollectedForTab3 = (!(hasErrors['passwordExpiryDays'] !== undefined || hasErrors['passwordExpiryNotifyDays'] !== undefined || hasErrors['maxLoginFailCount'] !== undefined))

    const errorObjectsCollectedForTab4 = (!(hasErrors['bankName'] !== undefined || hasErrors['bankKey'] !== undefined || hasErrors['bankAccount'] !== undefined));

    const errorObjectsCollectedForTab5 = (!(hasErrors['earlierAcceptanceDays'] !== undefined || hasErrors['lateAcceptanceDays'] !== undefined ));


    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container className={classes.rootForBranch}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    {(entityModuleAccess !== undefined ? entityModuleAccess.createFlag : false) &&
                        (<Button size='small' variant="contained" color="primary" className={classes.createButton} elevation={2} endIcon={smallScreen ? null : <CreateIcon />} onClick={() => {
                            setOpenEntityDialog(true)
                            setModeChanged('create')
                        }}>
                            {smallScreen ? <CreateIcon /> : <FormattedMessage id="common.button.Create.label" defaultMessage="Create" />}
                        </Button>)}
                </Grid>
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <ChipInfoList chipInfoList={chipInfoList} />
                <CommonDialogComponent
                    open={openEntityDialog}
                    onClose={closePoConfirmationAudit}
                    title={commonBreadCrumbToBeUsedForDialog}
                    basicDetails={<BasicDetails basicmode={modeChanged} control={control} errors={errors} basicReset={entityObjectToBeEditedOrView} EntityResetValues={EntityResetValues} defaultOrgObj={defaultOrgObj} setDefaultOrgObj={setDefaultOrgObj} orgInfoOfLeggedUser={orgInfoOfLeggedUser} setHasErrors={setHasErrors} />}
                    contactDetails={<ContactDetails contactmode={modeChanged} control={control} errors={errors} setHasErrors={setHasErrors} />}
                    addressDetails={<AddressDetails addressmode={modeChanged} control={control} errors={errors} setHasErrors={setHasErrors} setTimeZoneValue={setTimeZoneValue} timeZoneValue={timeZoneValue} />}
                    others={<BankDetails bankmode={modeChanged} control={control} errors={errors} setHasErrors={setHasErrors} />}
                    bankOrAccountPolicyDetails={<AccountPolicyForm accountmode={modeChanged} control={control} errors={errors} setHasErrors={setHasErrors} setValue={setValue} />}
                    processParameterEntity={<ProcessParameterEntity prcParamMode={modeChanged} errors={errors} control={control} setValue={setValue} 
                    dlvyCfmDummyDate ={dlvyCfmDummyDate} setDlvyCfmDummyDate={setDlvyCfmDummyDate} />}
                    reset={resetAllClearTheBasicInfo}
                    handleSubmit={handleSubmit}
                    submit={onSubmit}
                    errors={onError}
                    mode={modeChanged}
                    contactDetailsTabtitle={<FormattedMessage id="common.contactdetails.label" defaultMessage="CONTACT DETAILS" />}
                    addressDetailsTabtitle={<FormattedMessage id="common.addressdetails.label" defaultMessage="ADDRESS DETAILS" />}
                    bankorAccountDetailstabtitle={<FormattedMessage id="common.accountpolicy.label" defaultMessage="ACCOUNT POLICY" />}
                    OthersDetailstabtitle={<FormattedMessage id="common.bankdetails.label" defaultMessage="BANK DETAILS" />}
                    processParameterTabtitle={<FormattedMessage id="common.processparameter.label" defaultMessage="PROCESS PARAMETER" />}
                    warningMessage={(modeChanged === 'edit' || modeChanged === 'create') ? <Typography justifyContent="flex-start" alignItems="center" style={{ fontSize: 12 }} ><InfoIcon fontSize="small" style={{ marginBottom: '-1.4%', paddingRight: "1%" }} color="primary" /><FormattedMessage id="textfield.placeholder.dialogActions" defaultMessage="All the '*' marked fields are mandatory to submit the form" />
                    </Typography> : null}
                    isDirty={isDirty}
                    getValues={getValues}
                    isValid={isValid}
                    tabvalue={tabvalue}
                    setTabValue={setTabValue}
                    hasErrors={hasErrors}
                    isFormSubmitted={isFormSubmitted}
                    isIconRequiredForTheTab={isIconRequiredForTheTab}
                    validateRequiredFieldsForBasicDetails={validateRequiredFieldsForBasicDetails}
                    validateRequiredFieldsForTab1={validateRequiredFieldsForTab1}
                    validateRequiredFieldsForTab2={validateRequiredFieldsForTab2}
                    validateRequiredFieldsForTab3={validateRequiredFieldsForTab3}
                    validateRequiredFieldsForTab4={validateRequiredFieldsForTab4}
                    validateRequiredFieldsForTab5={validateRequiredFieldsForTab5}
                    errorObjectsCollectedForTab1={errorObjectsCollectedForTab1}
                    errorObjectsCollectedForTab2={errorObjectsCollectedForTab2}
                    errorObjectsCollectedForTab3={errorObjectsCollectedForTab3}
                    errorObjectsCollectedForTab4={errorObjectsCollectedForTab4}
                    errorObjectsCollectedForTab5={errorObjectsCollectedForTab5}

                />
                <Paper elevation={3} className={classes.gridHeightAndWidthForRole}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            density="compact"
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            hideFooter={true}
                            localeText={localtext}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'><GridToolbarDensitySelector variant="outlined" size="medium" className={classes.toobarStyle} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterListIcon />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            hideFooterSelectedRowCount={true}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </div>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}

export default Branch;