import { Button, Grid, CssBaseline, CircularProgress, useTheme, useMediaQuery, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import useStyles from '../../common/CommonStyle/CommonStyle'
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import CreateIcon from '@mui/icons-material/Add';
import ViewIcon from '@mui/icons-material/Visibility';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import TabPanel from './HorizontalTab';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CommonService } from '../../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),

  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CommonDialogComponent = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { others, onClose, open, title, basicDetails, bankOrAccountPolicyDetails, contactDetails, addressDetails, processParameter, processParameterEntity, contactDetailsTabtitle, addressDetailsTabtitle, bankorAccountDetailstabtitle, processParameterTabtitle, OthersDetailstabtitle, submit, errors, mode, reset, tabvalue, setTabValue, handleSubmit, isDirty, isFormSubmitted, isValid, getValues, hasErrors,
    warningMessage, dialogHeader, orgName, entityName, isIconRequiredForTheTab, contactDetailsForCustomer, modeForTabValidation, validateRequiredFieldsForBasicDetails,
    validateRequiredFieldsForTab1, validateRequiredFieldsForTab2, validateRequiredFieldsForTab3, validateRequiredFieldsForTab4, validateRequiredFieldsForTab5, errorObjectsCollectedForTab1,
    errorObjectsCollectedForTab2, errorObjectsCollectedForTab3, errorObjectsCollectedForTab4, errorObjectsCollectedForTab5 } = props;

  const isScreenSizeSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
  const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [componentList, setComponentList] = useState(title);
  const [disablesaveButton, setDisableSaveButton] = useState(true);
  const [showIcon, setShowIcon] = useState(null)

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = (value) => {
    setComponentList(title);
    setTabValue(0);
    handlereset(true)
    onClose(value)
  }

  const handlereset = (value) => {
    reset({ keepErrors: true, keepDirty: true, keepIsSubmitted: false, keepTouched: false, keepIsValid: false, keepSubmitCount: false, });
    reset(value)
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if ((mode === 'create' || mode === 'edit')) {
      setShowIcon(
        <Tooltip title={<FormattedMessage id="common.errorIcon.label" />}>
          <ErrorOutlineIcon className={classes.tabIconStyling} fontSize='small' />
        </Tooltip>
      )
    } else {
      setShowIcon(null)
    }
  }, [mode])


  useEffect(() => {
    if (mode !== '') {
      let currentRouterComponent;
      if (mode === 'create') {
        handlereset(true)
        currentRouterComponent = [
          {
            path: null,
            name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
            iconName: CreateIcon,
          }
        ]
      } else if (mode === 'edit') {
        isRestoreButtonDisabled(false)
        currentRouterComponent = [
          {
            path: null,
            name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
            iconName: EditIcon,
          }
        ]
      } else if (mode === 'read' || (mode === 'view')) {
        isRestoreButtonDisabled(true)
        currentRouterComponent = [
          {
            path: null,
            name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
            iconName: ViewIcon,
          }
        ]
      }
      setComponentList(title.concat(currentRouterComponent !== undefined ? currentRouterComponent : []));
    }
  }, [mode]);

  // const validateRequiredFieldsForBasicDetails = ((!(getValues('orgName') !== '' && getValues('erpOrgCode') !== '')));

  // const validateRequiredFieldsForTab1 = ((!(getValues('contactPersonName') !== '' && getValues('contactPersonEmailId') !== '' && getValues('contactPersonPhoneNo') !== '')));

  // const validateRequiredFieldsForTab2 = ((!(getValues('addressLine1') !== '' && getValues('city') !== '' && getValues('state') !== '' && getValues('country') !== '' && getValues('countryCode') !== '' && getValues('postalCode') !== '' && getValues('telNo') !== '')));

  // const validateRequiredFieldsForTab3 = ((!(getValues('passwordExpiryDays') !== '' && getValues('passwordExpiryNotifyDays') !== '' && getValues('maxLoginFailCount') !== '')));

  // const validateRequiredFieldsForTab4 = ((!(getValues('emailDomains') !== '')));

  // const errorObjectsCollectedForTab1 = (!(hasErrors['orgName'] !== undefined || hasErrors['erpOrgCode'] !== undefined || hasErrors['contactPersonSalutation'] !== undefined || hasErrors['contactPersonName'] !== undefined || hasErrors['contactPersonEmailId'] !== undefined || hasErrors['contactPersonPhoneNo'] !== undefined))

  // const errorObjectsCollectedForTab2 = (!(hasErrors['addressLine1'] !== undefined || hasErrors['city'] !== undefined || hasErrors['state'] !== undefined || hasErrors['country'] !== undefined || hasErrors['countryCode'] !== undefined || hasErrors['postalCode'] !== undefined || hasErrors['telNo'] !== undefined || hasErrors['faxNo'] !== undefined))

  // const errorObjectsCollectedForTab3 = (!(hasErrors['passwordExpiryDays'] !== undefined || hasErrors['passwordExpiryNotifyDays'] !== undefined || hasErrors['maxLoginFailCount'] !== undefined))

  // const errorObjectsCollectedForTab4 = (!(hasErrors['emailDomains'] !== undefined || hasErrors['webUrl'] !== undefined));

  useEffect(() => {
    if (mode === 'create' && (!(validateRequiredFieldsForBasicDetails || validateRequiredFieldsForTab1 || validateRequiredFieldsForTab2 || validateRequiredFieldsForTab3 ||
      validateRequiredFieldsForTab4 || validateRequiredFieldsForTab5) && (errorObjectsCollectedForTab1 && errorObjectsCollectedForTab2 && errorObjectsCollectedForTab3 &&
        errorObjectsCollectedForTab4 && errorObjectsCollectedForTab5) && isValid)) {
      setDisableSaveButton(false);
    } else if (!isDirty) {
      setDisableSaveButton(true);
    } else if (mode === 'edit' && errorObjectsCollectedForTab1 && errorObjectsCollectedForTab2 && errorObjectsCollectedForTab3 &&
      errorObjectsCollectedForTab4 && errorObjectsCollectedForTab5 && isValid) {
      setDisableSaveButton(false);
    } else {
      setDisableSaveButton(true);
    }
  }, [errorObjectsCollectedForTab1, errorObjectsCollectedForTab2, errorObjectsCollectedForTab3, errorObjectsCollectedForTab4, errorObjectsCollectedForTab5, validateRequiredFieldsForBasicDetails,
    validateRequiredFieldsForTab1, validateRequiredFieldsForTab2, validateRequiredFieldsForTab3, validateRequiredFieldsForTab4, validateRequiredFieldsForTab5, isDirty, mode, isValid]);



  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container className={classes.root}>
        <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
          <Grid item container xs={12} sm={12} md={12} lg={12} >
            <Dialog
              fullWidth
              maxWidth='lg'
              aria-labelledby="create-or-delete-dialog"
              open={open}>
              <DialogTitle className={classes.titleCommonDialogComponent}>
                <Grid item container alignItems='center' xs={12} sm={12} md={12} lg={12} style={{ marginLeft: '-1%' }} >
                  <Grid item container alignItems='center' xs={6} sm={8} md={8} lg={6}>
                    <CustomBreadCrumb componentList={componentList} isDialog={true} />
                  </Grid>
                  <Grid item container justifyContent='flex-end' alignItems='center' xs={6} sm={4} md={4} lg={6} style={{ color: '#fff', fontSize: '14px' }} >
                    {orgName} {dialogHeader} {entityName}
                  </Grid>
                </Grid>
              </DialogTitle>

              <DialogContent dividers style={smallScreen ? { minHeight: '70vh' } : { minHeight: '60vh' }} >
                <Grid item container alignItems='center' xs={12} sm={12} md={12} lg={12}>
                  <Grid item container alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <form className={classes.form}>
                      {basicDetails}
                      <AppBar position="sticky" color="inherit" elevation={2} >
                        <Grid item container alignItems="flex-start" xs={12} sm={12} md={12} lg={12}>
                          <Tabs
                            value={tabvalue}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="secondary"
                            variant={isScreenSizeSmall ? "scrollable" : "fullWidth"}
                            scrollButtons
                            aria-label="accessible tabs"
                            className={classes.tabContainerForCommonDialog}
                            TabIndicatorProps={{
                              style: {
                                height: '5%'
                              },
                            }}
                            allowScrollButtonsMobile>
                            {(contactDetailsForCustomer || modeForTabValidation === true ?
                              <CommonService.ErrorTab className={`${classes.tabtextstyling}`} label={contactDetailsTabtitle} {...a11yProps(0)}
                              /> :
                              <CommonService.ErrorTab className={`${classes.tabtextstyling}`} label={contactDetailsTabtitle} {...a11yProps(0)}
                                icon={validateRequiredFieldsForTab1 ? showIcon : errorObjectsCollectedForTab1 ? setShowIcon : contactDetailsForCustomer ? '' : showIcon} iconPosition="end" />)}
                            <CommonService.ErrorTab className={`${classes.tabtextstyling}`} label={addressDetailsTabtitle} {...a11yProps(1)}
                              icon={isIconRequiredForTheTab ? (validateRequiredFieldsForTab2 ? showIcon : errorObjectsCollectedForTab2 ? setShowIcon : showIcon) : null} iconPosition="end" />
                            <CommonService.ErrorTab className={`${classes.tabtextstyling}`} label={bankorAccountDetailstabtitle} {...a11yProps(2)}
                              icon={errorObjectsCollectedForTab3 ? setShowIcon : showIcon} />

                            {others && (<CommonService.ErrorTab className={`${classes.tabtextstyling}`} label={OthersDetailstabtitle} {...a11yProps(3)}
                               icon={isIconRequiredForTheTab ? (validateRequiredFieldsForTab4 ? showIcon : (errorObjectsCollectedForTab4) ? setShowIcon : showIcon) : null} iconPosition="end" />
                              //icon={errorObjectsCollectedForTab4 ? setShowIcon : showIcon}/>
                              )}
                            {processParameter && <CommonService.ErrorTab className={`${classes.tabtextstyling}`} label={processParameterTabtitle} {...a11yProps(3)} iconPosition="end" />}
                            {processParameterEntity && <CommonService.ErrorTab className={`${classes.tabtextstyling}`} label={processParameterTabtitle} {...a11yProps(4)} iconPosition="end" />}


                          </Tabs>
                        </Grid>
                      </AppBar>

                      <TabPanel value={tabvalue} index={0}>
                        {contactDetailsForCustomer ? contactDetailsForCustomer : contactDetails}
                      </TabPanel>
                      {/* <TabPanel value={tabvalue} index={0}>
                        {contactDetails}
                      </TabPanel> */}
                      <TabPanel value={tabvalue} index={1}>
                        {addressDetails}
                      </TabPanel>
                      <TabPanel value={tabvalue} index={2}>
                        {bankOrAccountPolicyDetails}
                      </TabPanel>
                      {others && <TabPanel value={tabvalue} index={3}>
                        {others}
                      </TabPanel>}
                      {processParameter && <TabPanel value={tabvalue} index={3}>
                        {processParameter}
                      </TabPanel>}
                      {processParameterEntity && <TabPanel value={tabvalue} index={4}>
                        {processParameterEntity}
                      </TabPanel>}
                    </form>
                  </Grid>
                </Grid>

              </DialogContent>
              {!mobileScreen && (
                <DialogActions className={classes.dialogAction} >
                  <Grid item container justifyContent="flex-start" xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} style={{ marginLeft: '-1.0%' }}>
                    {warningMessage}
                  </Grid>
                  <Grid item container justifyContent='flex-end' alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} >
                    <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2} endIcon={<ClearIcon />}
                      onClick={() => handleClose(false)}>
                      <FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />
                    </Button>
                    <Button size='small' variant="contained" color="secondary" disabled={disableRestoreButton} className={classes.stateButton} elevation={2}
                      endIcon={<IconRestore />} onClick={(() => { handlereset(true) })}>
                      <FormattedMessage id="common.button.reset.lable" defaultMessage="Restore" />
                    </Button>
                    <Button size='small' variant="contained" color="primary" className={classes.commondialogstateButtonForSave} elevation={2}
                      disabled={disablesaveButton || isFormSubmitted}
                      endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />} onClick={handleSubmit(submit, errors)}>
                      {mode === 'create' ? <FormattedMessage id="common.button.save" defaultMessage="Save" /> :
                        <FormattedMessage id="common.button.update" defaultMessage="Update" />}
                    </Button>
                  </Grid>
                </DialogActions>
              )}

              {mobileScreen && (<Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                <DialogActions className={classes.dialogAction} >
                  <Grid item container justifyContent='flex-end' alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.topGrid} >
                    <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                      <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2} onClick={() => handleClose(false)}>
                        <ClearIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="common.button.reset.lable" />}>
                      <Button size='small' variant="contained" color="secondary" className={classes.stateButton} elevation={2} onClick={(() => { handlereset(true) })}
                        disabled={disableRestoreButton}>
                        <IconRestore />
                      </Button>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="common.button.save" />}>
                      <Button size='small' variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2} disabled={disablesaveButton || isFormSubmitted}
                        endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : ''} onClick={handleSubmit(submit, errors)}>
                        <IconSaveOrUpdate />
                      </Button>
                    </Tooltip>
                  </Grid>
                </DialogActions>
              </Grid>)}
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default CommonDialogComponent;
