import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CloseSideMenu from '@mui/icons-material/FormatIndentDecrease';
import OpenSideMenu from '@mui/icons-material/FormatIndentIncrease';
import HeaderProfile from './AppHeaderProfile'
import { Fab, Grid, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CommonService } from '../../../services'
import useStyles from '../CommonStyle/CommonStyle'
import Selectlang from '../Selectlang/Selectlang'
import { FormattedMessage } from 'react-intl';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';

const AppHeader = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState('true');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const onToggleClick = props.onToggleClick;
  const userLoggedInStatus = props.userLoggedInStatus;
  const setUserLoggedInStatus = props.setUserLoggedInStatus;
  const { loggedInUserInfo } = useContext(LoggedInUserDetails);

  const currentUser = CommonService.getLoggedInUserInfo();

  let filePath = '/help/global-edi/index.html#/'

  return (
    <AppBar position="static" elevation={1} className={classes.header}>
      <Toolbar style={{ minHeight: '1%' }}>
        <Grid container>
          <Grid item container justifyContent='flex-start' alignItems='center' xs={2} sm={3} md={3} lg={4}>
            <Tooltip title={<FormattedMessage id="common.sidemenu.tooltip" defaultMessage="Open/Close Side Menuu" />} placement="right">
              <Fab
                edge="start"
                size="small"
                aria-label="Toggle sidebar"
                onClick={(() => {
                  onToggleClick();
                  setIsMenuOpen(!isMenuOpen);
                })}
                className={classes.menuButton}>
                {isDesktop ? (isMenuOpen ? <CloseSideMenu /> : <OpenSideMenu />) : <OpenSideMenu />}
              </Fab>
            </Tooltip>
          </Grid>
          <Grid item container justifyContent='center' alignItems='center' xs={3} sm={4} md={4} lg={3} >
            {(currentUser !== null && (currentUser.userType === 'SUPPLIER')) && (
              <Tooltip placement='bottom' title={currentUser.userSupplierAccessList[0].supplier.bpCode + ' - ' + currentUser.userSupplierAccessList[0].supplier.bpSupplierName}>
                <Typography
                  variant='h6'
                  noWrap={true}
                  align='justify'
                  className={classes.boldHeaderForAppHeader}>
                  {currentUser.userSupplierAccessList[0].supplier.bpCode + ' - ' + currentUser.userSupplierAccessList[0].supplier.bpSupplierName}
                </Typography>
              </Tooltip>
            )}
          </Grid>
          <Grid item container justifyContent='flex-end' alignItems='center' xs={7} sm={5} md={5} lg={5}>
            <Grid item container justifyContent="flex-end" xs={12} sm={12} md={12} lg={12}>
              <div className={classes.actions} >
                <IconButton
                  color="primary"
                  style={{ marginRight: '11px' }}
                  onClick={(() => { currentUser.userType === "SIIX-USER" ? window.open('/help/global-edi/index.html#/') : window.open('/help/bp-portal/index.html#/') })}
                  size="large">
                  <HelpOutlinedIcon />
                </IconButton>
                <Selectlang isLoginPage={false} />
                <HeaderProfile userLoggedInStatus={userLoggedInStatus} setUserLoggedInStatus={setUserLoggedInStatus} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

AppHeader.propTypes = {
  onToggleClick: PropTypes.func,
}

export default AppHeader
