import { CssBaseline, FormControl, FormHelperText,
     Grid, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import { CommonService } from '../../../../services';
import useStyles from '../../CommonStyle/CommonStyle'
import { useIntl } from 'react-intl';

const DeliveryConfirmationStatusCombo = (props) => {

    const classes = useStyles();
    const intl = useIntl();
    const { comboProperty, control, errors, comboValue, setComboValue } = props;
    const deliveryCfmStatus = [
        {id: 1, value: 'PARTIALLY-CONFIRMED'},
        {id: 2, value: 'CONFIRMED'},
        {id: 3, value: 'NOT-CONFIRMED'},
        {id: 4, value: 'NOT/PARTIAL CONFIRMED'}
    ];

    const plLineDlvyCfmStatus = [
        {id: 1, value: "1", name: 'CONFIRMED'},
        {id: 2, value: "0", name: 'NOT-CONFIRMED'},
        {id: 3, value: "2", name: 'ACK BY DUMMY DATE'},
        {id: 4, value: "3", name: 'PARTIALLY CONFIRMED'}
    ];

    let dlvCfmStatusPlaceholder = intl.formatMessage({id: "seachbydeliverycfmstatus.placeholder"});
    let dlvCfmStatus = intl.formatMessage({id: "columnname.delCfmStatus.label"})

    return (
        <React.Fragment>
            <CssBaseline />
            { comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth>
                <Controller
                    render={({
                            field: { onChange } }) => (
                            <Autocomplete
                            onChange={(event, newValue) => {
                                onChange(newValue)

                                if (typeof newValue === 'string') {

                                    if (newValue != null) {
                                        setComboValue({
                                            newValue,
                                        });
                                    }
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    setComboValue({
                                        title: newValue.inputValue,
                                    });

                                }
                                else if (newValue !== null) {

                                    setComboValue(newValue);
                                } else if (newValue === null) {
                                    setComboValue('')
                                } else {
                                    setComboValue(newValue);
                                }
                            }}
                            selectOnFocus
                            handleHomeEndKeys
                            value={comboValue.name || null}
                            autoHighlight
                            options={plLineDlvyCfmStatus}
                            getOptionLabel={(option) => {

                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.name;
                            }}
                            getoptionselected={(option, value) => option.name === value}
                            disabled={comboProperty.isDisabled}
                            renderOption={(props, option) => {
                                return <li {...props}>{
                                <React.Fragment>
                                    { option.value === "1" && (<CommonService.PoLineDlvyCnfDisplayField value={"1"} />)}
                                    { option.value === "0" && (<CommonService.PoLineDlvyCnfDisplayField value={"0"} />)}
                                    { option.value === '2' && (<CommonService.PoLineDlvyCnfDisplayField value={"2"}  />)}
                                    { option.value === '3' && (<CommonService.PoLineDlvyCnfDisplayField value={"3"} />)}
                                </React.Fragment>}
                                </li>;
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    autoFocus={comboProperty.isAutofocus}
                                    name={comboProperty.name}
                                    size='small'
                                    placeholder={`${dlvCfmStatusPlaceholder}` + `${dlvCfmStatus}`}
                                    label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                    variant={comboProperty.variant}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    )}

                    name={comboProperty.name}
                    rules={{ required: comboProperty.isRequired }}
                    control={control}
                    defaultValue={{ id: 0, value: '' }}
                />
                {comboProperty.isDisabled && (<FormHelperText>{comboProperty.label} can't be edited</FormHelperText>)}
                <div className={classes.error}>
                    {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                        <FormHelperText className={classes.error}>{comboProperty.label} is required</FormHelperText>
                    )}
                </div>
            </FormControl>
        </Grid>)}

        { !comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: "3px"}}>

        <FormControl fullWidth>
                        <Autocomplete
                            fullWidth
                            openOnFocus = {true}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {

                                    if (newValue != null) {
                                        setComboValue({
                                            newValue,
                                        });
                                    }
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    setComboValue({
                                        title: newValue.inputValue,
                                    });

                                }
                                else if (newValue !== null) {

                                    setComboValue(newValue);
                                } else if (newValue === null) {
                                    setComboValue('')
                                } else {
                                    setComboValue(newValue);
                                }
                            }}
                            selectOnFocus
                            handleHomeEndKeys
                            value={comboValue.value || null}
                            autoHighlight
                            options={deliveryCfmStatus}
                            getOptionLabel={(option) => {

                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.value;
                            }}
                            getoptionselected={(option, value) => option.value === value}
                            disabled={comboProperty.isDisabled}
                            renderOption={(props, option) => {
                                return <li {...props}>{
                                <React.Fragment>
                                    { option.value === 'PARTIALLY-CONFIRMED' && (<CommonService.DelCfmStatusDisplayField value={option.value} />)}
                                    { option.value === 'CONFIRMED' && (<CommonService.DelCfmStatusDisplayField value={option.value} />)}
                                    { option.value === 'NOT-CONFIRMED' && (<CommonService.DelCfmStatusDisplayField value={option.value} />)}
                                    { option.value === 'NOT/PARTIAL CONFIRMED' && (<CommonService.DelCfmStatusDisplayField value={option.value} />)}
                                </React.Fragment>}
                                </li>;
                            }}
                            renderInput={params => (
                                <Tooltip title={comboValue.value || ''}>
                                    <TextField
                                    {...params}
                                    autoFocus={comboProperty.isAutofocus}
                                    name={comboProperty.name}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size='small'
                                    label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                    variant={comboProperty.variant}
                                />
                                </Tooltip>
                            )}

                        />
            </FormControl>

        </Grid>)}
        </React.Fragment>
    );
};

DeliveryConfirmationStatusCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default DeliveryConfirmationStatusCombo;

