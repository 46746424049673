import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';

const PoweredBy = (props) => {
    return (
        
        <Typography variant="body2" color="textSecondary" align="center">
            {<FormattedMessage id="app.poweredby.txt" defaultMessage="Powered By "/>}
            <Link color="primary" to={{ pathname: props.companyURL }} target="_blank">
                {props.companyName}
            </Link>
        </Typography>

    );
}

PoweredBy.propTypes = {
    companyName : PropTypes.string.isRequired,
    companyURL : PropTypes.string.isRequired,
};

export default PoweredBy;