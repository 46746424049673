import { FormattedMessage } from 'react-intl';

export const GlobalEdiApiConstants = () => {
    const commonOrganizationObjUsedForParamBody = {
        erpOrgCode: '',
        orgName: ''
    }

    const commonEntityObjUsedForParamBody = {
        erpEntityCode: '',
        entityName: ''
    }

    const commonMessageConfigHdrObjUsedForParamBody = {
        configId: ''
    }

    const commonErpInterfaceHdrObjUsedForParamBody = {
        intfHdrId: null
    }

    const commonEmailMessageTemplateObjUsedForParamBody = {
        id: ''
    }

    const commonReportTemplateObjUsedForParamBody = {
        rptTmplId: ''
    }

    const commonPageableObj = {
        pageNo: 0,
        sortDir: 'desc',
        sortDirAsc: 'asc',
        sortKey: 'updatedTime',
        sortKeyName: 'name',
        recordsPerPage: 25,
        sortKeyErpStatus: 'erpInterfaceStatusId',
        sortKeyTransmission: 'msgTransmissionLogId',
    }

    return {
        paramBodyForGetListApis: {
            emailTemplate: {
                body: {
                    organization: commonOrganizationObjUsedForParamBody,
                    entity: commonEntityObjUsedForParamBody,
                    name: ''
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            messageConfigHdr: {
                body: {
                    organization: commonOrganizationObjUsedForParamBody,
                    entity: commonEntityObjUsedForParamBody,
                    bpCode: '',
                    bpType: '',
                    bpName: '',
                    active: true,
                    recordActiveStatus: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            messageConfigDtl: {
                body: {
                    messageConfigHdr: commonMessageConfigHdrObjUsedForParamBody,
                    active: '',
                    processName: '',
                    inOut: '',
                    msgFormat: '',
                    msgType: '',
                    msgVersion: '',
                    commType: '',
                    approvalStatus: 'All',
                    approvalReqd: true,
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            role: {
                body: {
                    organization: commonOrganizationObjUsedForParamBody,
                    name: '',
                    userType: null,
                    active: true,
                    entity: commonEntityObjUsedForParamBody
                    // recordActiveStatus: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            organization: {
                body: {
                    orgName: "",
                    erpOrgCode: "",
                    active: true,
                    recordActiveStatus: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            user: {
                body: {
                    userName: '',
                    organization: commonOrganizationObjUsedForParamBody,
                    entity: commonEntityObjUsedForParamBody,
                    salutation: '',
                    designation: '',
                    allowLogin: true,
                    recordAllowLogin: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            entity: {
                body: {
                    erpEntityCode: '',
                    entityName: '',
                    bankName: '',
                    organization: commonOrganizationObjUsedForParamBody,
                    active: true,
                    recordActiveStatus: 'All',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            supplier: {
                body: {
                    bpCode: null,
                    bpSupplierName: null,
                    bpGroupName: null,
                    supplierAccountGroup: null,
                    organization: {
                        erpOrgCode: null,
                        orgName: null
                    },
                    entity: {
                        erpEntityCode: null,
                        entityName: null
                    },
                    erpInterfaceHdr: commonErpInterfaceHdrObjUsedForParamBody,
                    active: true,
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            customer: {
                body: {
                    bpCode: null,
                    bpCustomerName: null,
                    bpGroupName: null,
                    customerAccountGroup: null,
                    organization: {
                        erpOrgCode: null,
                        orgName: null
                    },
                    entity: {
                        erpEntityCode: null,
                        entityName: null
                    },
                    erpInterfaceHdr: commonErpInterfaceHdrObjUsedForParamBody,
                    active: true,
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            processNameCombo: {
                body: {
                    organization: commonOrganizationObjUsedForParamBody,
                    active: true,
                    process: '',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: 'asc',
                sortKey: 'process',
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            commTypeEmailConfig: {
                body: {
                    entity: commonEntityObjUsedForParamBody,
                    organization: commonOrganizationObjUsedForParamBody,
                    emailMessageTemplate: commonEmailMessageTemplateObjUsedForParamBody,
                    reportTemplate: commonReportTemplateObjUsedForParamBody,
                    emailConfName: '',
                    emailTo: '',
                    emailCc: '',
                    bpCode: '',
                    bpType: '',
                    active: '',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            commTypeTransmissionConfig: {
                body: {
                    transConfName: '',
                    organization: commonOrganizationObjUsedForParamBody,
                    entity: commonEntityObjUsedForParamBody,
                    userId: '',
                    active: true,
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            commTypeCombo: {
                body: {
                    organization: commonOrganizationObjUsedForParamBody,
                    processCommMappingId: '',
                    messageMaster: {
                        msgFormatId: ''
                    },
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            messageFormat: {
                body: {
                    organization: commonOrganizationObjUsedForParamBody,
                    processMaster: {
                        processId: ''
                    }
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            erpInterfaceHdr: {
                body: {
                    intfType: '',
                    intfDesc: '',
                    entity: commonEntityObjUsedForParamBody,
                    organization: commonOrganizationObjUsedForParamBody,
                    active: true,
                    recordActiveStatus: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            erpInterfaceDtls: {
                body: {
                    processMaster: {
                        process: '',
                        processId: '',
                    },
                    inOut: '',
                    fileType: '',
                    recordActiveStatus: 'All',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            erpInterfaceStatus: {
                body: {
                    processMaster: {
                        process: '',
                    },
                    erpInterfaceDtls: {
                        intfId: ''
                    },
                    intfType: '',
                    intfDesc: '',
                    messageReference: '',
                    processedDate: '',
                    organization: {
                        erpOrgCode: '',
                        orgName: '',
                    },
                    entity: {
                        erpEntityCode: '',
                        entityName: '',
                    },
                    status: '',
                    recordErrorStatus: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKeyErpStatus,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            messageTransmissionLog: {
                body: {
                    entity: commonEntityObjUsedForParamBody,
                    organization: commonOrganizationObjUsedForParamBody,
                    processMaster: {
                        process: '',
                        processId: ''
                    },
                    commType: '',
                    bpCode: '',
                    msgType: '',
                    bpType: '',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKeyTransmission,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            emailMessageConfig: {
                body: {
                    entity: commonEntityObjUsedForParamBody,
                    organization: commonOrganizationObjUsedForParamBody,
                    emailMessageTemplate: commonEmailMessageTemplateObjUsedForParamBody,
                    reportTemplate: commonReportTemplateObjUsedForParamBody,
                    emailConfName: '',
                    emailTo: '',
                    emailCc: '',
                    bpCode: '',
                    bpType: '',
                    active: true,
                    recordActiveStatus: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            BpGroupMaster: {
                body: {
                    bpType: '',
                    organization: {
                        erpOrgCode: '',
                    },
                    groupName: '',
                    groupDescription: '',
                    active: true,
                    recordActiveStatus: 'All',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            BpGroupMapping: {
                body: {
                    bpGroupMappingId: '',
                    bpGroupMaster: {
                        groupId: ''
                    },
                    organization: {
                        erpOrgCode: ''
                    },
                },
                loadAllData: true,
                pageNo: commonPageableObj.pageNo,
                SortDir: 'asc',
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            bpCustomerAndSupplier: {
                body: {
                    bpCode: "",
                    bpGroupName: "",
                    erpInterfaceHdr: {
                        intfHdrId: ''
                    },
                    entity: commonEntityObjUsedForParamBody,
                    organization: commonOrganizationObjUsedForParamBody,
                    active: true,
                    recordActiveStatus: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            emailReportTemplateCombo: {
                body: {
                    name: '',
                    entity: commonEntityObjUsedForParamBody,
                    organization: commonOrganizationObjUsedForParamBody,
                    active: true,
                    reportTemplateMaster: {
                        process: ''
                    },
                    recordActiveStatus: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            BpSupplierNameCombo: {
                body: {
                    bpSupplierName: '',
                    organization: {
                        erpOrgCode: ''
                    },
                    entity: {
                        erpEntityCode: '',
                    },
                    recordActiveStatus: 'All'
                },
                pageNo: 0,
                sortDir: 'asc',
                sortKey: 'bpSupplierName',
                recordsPerPage: 100
            },
            BpCustomerNameCombo: {
                body: {
                    bpCustomerName: '',
                    organization: {
                        erpOrgCode: ''
                    },
                    entity: {
                        erpEntityCode: '',
                    },
                    recordActiveStatus: 'All'
                },
                pageNo: 0,
                sortDir: 'asc',
                sortKey: 'name',
                recordsPerPage: 100
            },
            erpInterfaceDescCombo: {
                body: {
                    intfType: '',
                    intfDesc: '',
                    entity: commonEntityObjUsedForParamBody,
                    organization: commonOrganizationObjUsedForParamBody,
                    active: true,
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            bpEmailMessageConfigs: {
                body: {
                    project: '',
                    bpCode: '',
                    bpType: '',
                    validFrom: '',
                    validTo: '',
                    contactName: '',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            fileAttachmentDtls: {
                body: {
                    entity: commonEntityObjUsedForParamBody,
                    organization: commonOrganizationObjUsedForParamBody,
                    intfId: '',
                    bpCode: '',
                    filePath: '',
                    artifactId: '',
                    process: '',
                    createdDate: '',
                    fromDate: '',
                    toDate: '',
                    approved: true,
                    apprEmailSent: '',
                    processed: '',
                    recordApprovedStatus: 'All',
                    recordApprSentStatus: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            emailMessageLogs: {
                body: {
                    entity: commonEntityObjUsedForParamBody,
                    organization: commonOrganizationObjUsedForParamBody,
                    processName: '',
                    bpCode: '',
                    active: true,
                    recordActiveStatus: 'All',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: 'desc',
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            reportTemplateMasters: {
                body: {
                    process: '',
                    inOut: ''
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            reportTemplateCondition: {
                body: {
                    process: '',
                    column: ''
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            transmissionVendor: {
                body: {
                    entity: commonEntityObjUsedForParamBody,
                    organization: commonOrganizationObjUsedForParamBody,
                    vendorName: ''
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            POBuyerPartyCode: {
                body: {
                    entity: {
                        entityName: '',
                    },
                    organization: {
                        orgName: '',
                    },
                    supplierPartyCode: '',
                    invoiceePartyCode: '',
                    buyerPartyCode: '',
                    recordApprovedStatus: 'All',
                    recordApprovedStatus: 'All',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            advanceShipmentNoteHdrs: {
                body: {
                    billToCustomerCode: '',
                    approved: '',
                    shipToCustomerName: null,
                    shipmentNumber: null,
                    packingNo: null,
                    recordStatusFlag: 'All',
                    entity: commonEntityObjUsedForParamBody,
                    recordApprovedStatus: 'All',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            invoiceHdrs: {
                body: {
                    approved: '',
                    recordStatusFlag: 'All',
                    entity: commonEntityObjUsedForParamBody,
                    recordApprovedStatus: 'All',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            poPfOutbound: {
                body: {
                    approved: '',
                    recordStatusFlag: 'All',
                    recordApprovedStatus: 'All',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            project: {
                body: {
                    organization: commonOrganizationObjUsedForParamBody,
                    entity: commonEntityObjUsedForParamBody,
                    active: true,
                    recordActiveStatus: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            EmailNotificationSubscription: {
                body: {
                    entity: {
                        erpEntityCode: null
                    },
                    organization: {
                        erpOrgCode: null
                    },
                    processMaster: {
                        processId: null
                    },
                    triggerEvent: '',
                    active: true,
                    recordActiveStatus: 'All',
                    recordTriggerEvent: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            tablePreference: {
                body: {
                    userId: '',
                    organization: commonOrganizationObjUsedForParamBody,
                    entity: commonEntityObjUsedForParamBody,
                    screenName: '',
                    preferences: null,
                    lastOpen: false
                }
            },
            userPreference: {
                columnsSort: {
                    field: "updatedTime",
                    sort: "desc"
                },
                columnsFilter: [],
                columnsOrdervisible: [],

                columnsPinned: {
                    left: [],
                    right: []
                }
            },
            emailServerConfig: {
                body: {
                    organization: commonOrganizationObjUsedForParamBody,
                    entity: commonEntityObjUsedForParamBody,

                    active: true,
                    recordActiveStatus: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            importTemplate: {
                body: {
                    importTmplId: 0,
                    organization: commonOrganizationObjUsedForParamBody,
                    entity: commonEntityObjUsedForParamBody,
                    importTmplName: '',
                    importTemplateProcessMaster: {
                        importTmplMasterId: ''
                    },
                    active: true,
                    recordActiveStatus: 'All'
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            importTemplateProcessMasters: {
                body: {
                    importTmplId: 0,
                    process: ''
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            advanceShipmentNoteInboundHdrs: {
                body: {
                    entity: {
                        entityName: '',
                    },
                    organization: {
                        orgName: '',
                    },

                    recordApprovedStatus: 'All',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            invoiceInboundHdr: {
                body: {
                    entity: {
                        entityName: '',
                    },
                    organization: {
                        orgName: '',
                    },

                    recordApprovedStatus: 'All',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            deliveryConfirmationStatistics: {
                body:{
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage
            },
            pfOutbound: {
                body: {
                    approved: '',
                    recordStatusFlag: 'All',
                    recordApprovedStatus: 'All',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            relatedProcessMapping: {
                body: {
                    processId: '',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: 'relatedProcessName',
                recordsPerPage: commonPageableObj.recordsPerPage,
            },
            packingList: {
                body: {
                    approved: '',
                    recordStatusFlag: 'All',
                    entity: commonEntityObjUsedForParamBody,
                    recordApprovedStatus: 'All',
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage,
            },

            partChangeNotificationHdrs:{
                body: {
                    recordStatusFlag: 'All',
                    recordApprovedStatus: 'All',
                  // pcnNo: null,
                    approved: '',
                    organization: {
                        orgName: '',

                    },
                    entity: commonEntityObjUsedForParamBody
                },
                pageNo: commonPageableObj.pageNo,
                sortDir: commonPageableObj.sortDir,
                sortKey: commonPageableObj.sortKey,
                recordsPerPage: commonPageableObj.recordsPerPage, 
            },
            // processDate:{
            //     body: {
            //         processMaster: {
            //             processId: ,

            //         }
            //     },
            //     pageNo: commonPageableObj.pageNo,
            //     sortDir: commonPageableObj.sortDir,
            //     sortKey: commonPageableObj.sortKey,
            //     recordsPerPage: commonPageableObj.recordsPerPage, 
            // }
        },

        

        commonApiUrlForAllTheScreen: {
            emailTemplate: "/emailMessageTemplates",
            messageConfigHdr: "/messageConfigHdrs",
            messageConfigDtl: "/messageConfigDtls",
            role: '/roles',
            user: '/users',
            supplier: '/suppliers',
            customer: '/customers',
            entity: '/entities',
            organization: '/organizations',
            processMaster: '/processMaster',
            processMessageMapping: '/processMessageMapping',
            erpInterfaceHdr: '/erpInterfaceHdrs',
            erpInterfaceDtls: '/erpInterfaceDtls',
            erpInterfaceStatus: '/erpInterfaceStatus',
            messageTransmissionLog: '/msgTransmissionLogs',
            emailCommConfig: '/emailCommConfig',
            transmissionConfigs: "/transmissionConfigs",
            processCommMapping: "/processCommMapping",
            BpGroupMaster: "/bpGroupMaster",
            BpGroupMapping: "/bpGroupMapping",
            emailReportTemplateCombo: "/reportTemplates",
            bpEmailMessageConfigs: '/bpEmailMessageConfigs',
            fileAttachmentDtls: '/fileAttachmentDtls',
            emailMessageLogs: '/emailMessage',
            reportTemplateMasters: '/reportTemplateMasters',
            reportTemplateCondition: '/reportTemplateConditions',
            transmissionVendor: '/transmissionVendor',
            POBuyerPartyCodeApi: '/purchaseOrderMsgHdrs',
            advanceShipmentNoteHdrs: '/advanceShipmentNoteHdrs',
            invoiceHdrs: '/invoiceHdrs',
            poPfOutbound: '/poOutboundMsgHdrs',
            project: '/projects',
            EmailNotificationSubscription: '/emailNotificationSubscription',
            userPreferences: '/preferences',
            lastLoggedInScreen: '/lastloggedinscreen',
            setloggedinscreen: '/setloggedinscreen',
            emailServerConfig: '/emailServerConfig',
            userPreferences: '/userPreferences',
            lastLoggedInScreen: '/lastLoggedInScreen',
            importTemplate: '/importTemplates',
            importTemplateProcessMasters: '/importTemplateProcessMasters',
            advanceShipmentNoteInboundHdrs: '/advanceShipmentNoteInboundHdr',
            invoiceInboundHdr: '/invoiceInboundHdr',
            deliveryConfirmationStatistics : '/deliveryConfirmationStatistics',
            pfOutbound: '/purchaseForecastOutbound',
            relatedProcessMapping: '/relatedProcessMapping',
            additionalMsgConfig: '/additionalMsgConfig',
            packingHeaders: '/packingHeaders',
            partChangeNotificationHdr: '/partChangeNotificationHdr',
            partChangeNotificationDtl:'/partChangeNotificationDtl',
            processDate:'/msgschedule/processdates',
            updatePcnDtls:'/updatePcnDtls',
            documentUploads:'/documentUploads'
        },
        commonURLForSystemReportDownload: {
            messageTranmissionLog: '/reports/SYS_MSG_TRANSMISSION_lOG',
            erpStatus: '/reports/SYS_ERP_INTERFACE_STATUS'
        },
        //U-underScore, C-commoa, H-hyphen, S-space, F-fullStop
        commonTextfeildPattern: {
            patternForAlphaNumeric: /^[a-zA-Z0-9]+(\s*[a-zA-Z0-9])*$/,
            // /^[^\s]+[A-Za-z0-9]+((\s*)+[A-Za-z0-9]+)[^\s]*$/,
            patternWithAlphaNum_UCSHF: /^[A-Za-z0-9_.,-]+(\s*[A-Za-z0-9_,.-])*$/,
            patternWithAlphaNum_UHF: /^[A-Za-z0-9_.-]+(\s*[A-Za-z0-9_.-])*$/,
            patternWithAlphaNumLoginId_UHF: /^[A-Za-z0-9_.-]*$/,
            patternForAlphabetsOnly: /^[-a-zA-Z-()]+(\s+[-a-zA-Z-()]+)*$/,
            ///^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/,
            patternForTel_PhNumber: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9][^\s]*$/,
            patternForEmailId: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+)+([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+))*$/,
            patternForAlphabetsandSpace: /^[ A-Za-z ]*$/,
            patternNumericOnly: /^[0-9\b]+$/,
            patternForBaseURI: /^([a-z][a-z0-9+.-]*):(?:\/\/((?:(?=((?:[a-z0-9-._~!$&'()*+,;=:]|%[0-9A-F]{2})*))(\3)@)?(?=(\[[0-9A-F:.]{2,}\]|(?:[a-z0-9-._~!$&'()*+,;=]|%[0-9A-F]{2})*))\5(?::(?=(\d*))\6)?)(\/(?=((?:[a-z0-9-._~!$&'()*+,;=:@\/]|%[0-9A-F]{2})*))\8)?|(\/?(?!\/)(?=((?:[a-z0-9-._~!$&'()*+,;=:@\/]|%[0-9A-F]{2})*))\10)?)(?:\?(?=((?:[a-z0-9-._~!$&'()*+,;=:@\/?]|%[0-9A-F]{2})*))\11)?(?:#(?=((?:[a-z0-9-._~!$&'()*+,;=:@\/?]|%[0-9A-F]{2})*))\12)?$/i,
            patternForSingleEmailId: /^[A-Z0-9._%+-\s]+@[A-Z0-9.-\s]+\.[A-Z]{2,}$/i,
            patternForAlphaNumericAndSpecialChar: /^[a-zA-Z0-9!-@#$}{%^/&*)(-,+=._]*$/,
            patternForAlphaNumericAndSpecialCharAndSpace: /^[a-zA-Z0-9!-@#$%/^&*)(,+=._}{\s]*$/,
            patternForAlphaNumericAndSpecialCharAndAvoidSpace: /^[a-zA-Z0-9!-@#$%/^&*)(,+=._}{]*$/,
            patternForPassword: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%/^&*+`~'=?\|\]\[\(\)\-<>/]).{8,}$/gm, 
        },
        commonErrorsForTextFields: {
            errorForAlphaNumeric: <FormattedMessage id="common.errorForAlphaNumeric.message" />,
            errorForAlphaNum_UCSHF: <FormattedMessage id="common.errorForAlphaNum_UCSHF.message" />,
            errorForAlphaNum_UHF: <FormattedMessage id="common.errorForAlphaNum_UHF.message" />,
            errorForAplhabetsOnly: <FormattedMessage id="common.errorForAplhabetsOnly.message" />,
            errorForTele_PhoneNumber: <FormattedMessage id="common.errorForTele_PhoneNumber.message" />,
            errorForEmailId: <FormattedMessage id="common.errorForEmailId.message" />,
            errorForAplhabetsWithSpaces: <FormattedMessage id="common.errorForAplhabetsWithSpaces.message" />,
            errorForNumericOnly: <FormattedMessage id="common.errorForNumericOnly.message" />,
            errorForBaseURI: <FormattedMessage id="common.errorForBaseURI.message" />,
            errorForSingleEmailId: <FormattedMessage id="common.errorForSingleEmailId.message" />,
            errorForAlphaNumericAndSpecialChar: <FormattedMessage id="common.errorForAlphaNumericAndSpecialChar.message" />,
            errorForPassword: <FormattedMessage id="common.errorForPassword.message" />,
            errorForAlphaNumericAndSpecialCharAndSpace: <FormattedMessage id="common.errorForAlphaNumericAndSpecialCharAndSpace" />,
        }
    }
}



