import { GridToolbarColumnsButton, GridToolbarDensitySelector, LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import useStyles from '../../common/CommonStyle/CommonStyle';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import IconReportTemplate from '@mui/icons-material/ImportContacts';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import { AppBar, Box, Button, Card, CardHeader, Checkbox, CircularProgress, CssBaseline, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemIcon, ListItemText, Paper, Snackbar, Switch, Tab, Tabs, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Autocomplete } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import { CommonService } from '../../../services';
import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import EntityCodeCombo from '../../common/Combos/EntityCodeCombo';
import IconBack from '@mui/icons-material/BackspaceOutlined';
import { useHistory } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Add';
import ViewIcon from '@mui/icons-material/Visibility';
import IconRestore from '@mui/icons-material/AutorenewOutlined'
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import CommonBackDrop from '../../common/BackDrop';
import ReportTemplateMasterProcessCombo from '../../common/Combos/ReportTemplateMasterProcessCombo';
import _ from "lodash/fp";
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EmailReportTemplateConditionDialog from './EmailReportTemplateConditionDialog/EmailReportTemplateConditionDialog';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import RefreshIcon from '@mui/icons-material/Refresh';
import TemplateTypeCombo from '../../common/Combos/TemplateTypeCombo';
import ReportTempInOutCombo from '../../common/Combos/ReportTempInOutCombo';
import DateFormaterCombo from '../../common/Combos/DateFormaterCombo';

function not(a, b) {
    return a.filter((value) => (b.findIndex(item => item.columnName === value.columnName)) === -1);
}

function intersection(a, b) {
    return a.filter((value) => (b.findIndex(item => item.columnName === value.columnName)) !== -1);
}

function union(a, b) {

    return [...a, ...not(b, a)];
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const columnFieldsName = {
    columnName: 'columnName',
    condition: 'condition',
    values: 'values',
    conditionType: 'conditionType'
}

const CreateOrEditEmailReportTemplate = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();
    const theme = useTheme();
    const commonPatterns = GlobalEdiApiConstants();
    const smallScreen = useMediaQuery(theme.breakpoints.down('890'));
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const { mode, rptTmplId, messageReportObjectToBeEditedOrView } = props.location.state;
    const currentUser = CommonService.getLoggedInUserInfo();
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const emailReportTemplateModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.emailReportTemplateModuleAccess];
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());
    const [defaultOrgObj, setDefaultOrgObj] = useState({});

    const pageLabelsConstantsAndMessages = {
        name: {
            reportTemplateMaster: 'reportTemplateMaster',
            entity: 'entity',
            name: 'name',
            active: 'active',
            displayName: '',
            index: '',
            condition: '',
            bpCode: 'bpCode',
            templateType: 'templateType',
            inOut: 'inout',
            rptDateFormat: 'rptDateFormat'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
    }

    const [tabValue, setTabValue] = React.useState(0);
    const [processNameValue, setProcessNameValue] = useState({ process: '', processId: '', view: '', schema: '', processType: '', inout: '' });
    const [templateTypeValue, setTemplateTypeValue] = useState([]);
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);

    useEffect(() => {
        left.sort(function (a, b) {
            if (a.columnName < b.columnName) {
                return -1;
            }
            if (a.columnName > b.columnName) {
                return 1;
            }
        })
    }, [left])
    const [right, setRight] = React.useState([]);
    const [isTransferButtonsDisabled] = useState(false);
    const [isColumnNameMappingLoading, setColumnNameMappingLoading] = useState(false);
    const [columnListAccessLoading, setColumnListAccessLoading] = useState(false);
    const [columnList, setColumnList] = useState([]);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [branchValue, setBranchValue] = useState({ entityName: '', erpEntityCode: '' });
    const [inOutValue, setInOutValue] = useState({});
    const [activeToBeEdited, setActiveToBeEdited] = useState({});
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [componentList, setComponentList] = useState([]);
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
    const [disableSaveOrUpdateButton, issaveOrUpdateButtonDisabled] = useState(false);
    const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('');
    const [reportTemplateToBeEdited, setReportTemplateToBeEdited] = useState({});
    const [modeForReportTemplateCondition, setModeForReportTemplateCondition] = useState('');
    const [OpenReportTemplateMessageDialog, setOpenReportTemplateMessageDialog] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);
    const [reportTemplateConditionTabToBeEdited, setReportTemplateConditionTabToBeEdited] = useState([]);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [indexOfSelectedRow, setIndexOfSelectedRow] = useState(0);
    const [primaryKeyOfSelectedRow, setPrimaryKeyOfSelectedRow] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [renderTableChnages, setRenderTableChanges] = useState(false);
    const [dateFormatterValue, setDateFormatterValue] = useState({ id: 1, value: 'dd-MM-yyyy' });

    const closeReportTemplateDialog = (confirmed) => {
        if (confirmed) {
            setRefreshTable(!refreshTable);
            setRenderTableChanges(!renderTableChnages);
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success" });
        }
        setOpenReportTemplateMessageDialog(false);
    }

    function handleTextFieldChange(value, index, displayName) {
        right[index].displayName = value.toUpperCase()
        setRight(right.map((column) => {
            return {
                columnName: column.columnName,
                displayName: column.displayName,
                index: column.index,
                dataType: column.dataType
            }
        }))
    }

    function handleTextFieldChangeForEdit(value, index, displayName) {
        right[index].displayName = value.toUpperCase()
        setRight(right.map((column) => {
            return {
                columnName: column.columnName,
                displayName: column.displayName,
                index: column.index,
                dataType: column.dataType
            }
        }))
    }

    useEffect(() => {
        if (orgInfoOfLeggedUser.erpOrgCode && mode === 'create') {
            setDefaultOrgObj({
                erpOrgCode: orgInfoOfLeggedUser.erpOrgCode, orgName: orgInfoOfLeggedUser.orgName,
                valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}`
            });
        }
    }, [orgInfoOfLeggedUser])

    const [paramBody, setParamBody] = useState({ ...apiUrlAndParamBodyConstants.paramBodyForGetListApis.reportTemplateMasters, body: { process: mode === 'edit' ? messageReportObjectToBeEditedOrView.reportTemplateMaster.process : processNameValue.process, inOut: mode === 'edit' ? messageReportObjectToBeEditedOrView.reportTemplateMaster.inOut : inOutValue.value }, sortDir: 'asc', sortKey: 'process', recordsPerPage: 100 });

    useEffect(() => {
        setParamBody({ ...paramBody, body: { process: mode === 'edit' ? messageReportObjectToBeEditedOrView.reportTemplateMaster.process : processNameValue.process, inOut: mode === 'edit' ? messageReportObjectToBeEditedOrView.reportTemplateMaster.inOut : inOutValue.value }, sortDir: 'asc', sortKey: 'process', recordsPerPage: 100 })
    }, [processNameValue, inOutValue])


    const [paramBodyForGetById, setParamBodyForGetById] = useState({
        ...apiUrlAndParamBodyConstants.paramBodyForGetListApis.emailReportTemplateCombo,
        body: { rptTmplId: rptTmplId, processMaster: { process: processNameValue.process, processId: processNameValue.processId }, reportTemplateMaster: { view: processNameValue.view, schema: processNameValue.schema } }
    })

    useEffect(() => {
        setParamBodyForGetById({ ...paramBodyForGetById, body: { rptTmplId: rptTmplId, processMaster: { process: processNameValue.process, processId: processNameValue.processId }, reportTemplateMaster: { view: processNameValue.view, schema: processNameValue.schema } } })
    }, [processNameValue])

    const totalPoHdrDtl = <FormattedMessage id="availablefields.cardheader.label" values={{ processNameValue: `${(processNameValue.process !== undefined ? processNameValue.process : '')}` }} defaultMessage={`Available ${(processNameValue.process !== undefined ? processNameValue.process : 'Available fields')} fields`} />

    const selectedPoHdrDtl = <FormattedMessage id="selectedfields.cardheader.label" values={{ processNameValue: `${(processNameValue.process !== undefined ? processNameValue.process : '')}` }} defaultMessage={`Selected ${(processNameValue.process !== undefined ? processNameValue.process : 'Selected fields')} fields`} />

    const columnsForConditionTab = [
        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: mode === 'read' ? 120 : 155,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    {(emailReportTemplateModuleAccess !== undefined ? emailReportTemplateModuleAccess.editFlag : false) && (mode !== 'read') && (
                        <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                setModeForReportTemplateCondition('edit');
                                setReportTemplateConditionTabToBeEdited([cellParams.row]);
                                setOpenReportTemplateMessageDialog(true);
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar}>
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {(emailReportTemplateModuleAccess !== undefined ? emailReportTemplateModuleAccess.readFlag : false) && (
                        <Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                setModeForReportTemplateCondition('read')
                                setReportTemplateConditionTabToBeEdited([cellParams.row])
                                setOpenReportTemplateMessageDialog(true);
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar}>
                                    <ViewIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {(emailReportTemplateModuleAccess !== undefined ? emailReportTemplateModuleAccess.deleteFlag : false) && (mode !== 'read') && (
                        <Tooltip title={<FormattedMessage id="common.button.delete.tooltip" defaultMessage="Delete" />}
                            placement='bottom' className={classes.actionButton}
                            onClick={(() => {
                                const indexOftableData = uniqueTableData.findIndex(item => item.tmplId === cellParams.row.tmplId);
                                handleDelete(cellParams.row.tmplId, cellParams.row.values, indexOftableData);
                            })}
                        >
                            <span>
                                <IconButton color='secondary'>
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                </div>
            )
        },
        {
            field: columnFieldsName.conditionType,
            width: 200,
            headerName: intl.formatMessage({ id: 'textfield.conditionlogic.label' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: false,
            type: 'string',
            filterable: false,
            hide: false,
            editable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.columnName,
            width: 200,
            headerName: intl.formatMessage({ id: 'columnsnamelist.label' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: false,
            type: 'string',
            filterable: false,
            hide: false,
            editable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.condition,
            width: 150,
            headerName: intl.formatMessage({ id: 'tabname.conditions' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: false,
            type: 'string',
            filterable: false,
            hide: false,
            editable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.values,
            width: 190,
            headerName: intl.formatMessage({ id: 'columnname.valueorvalues.label' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            sortable: false,
            filterable: false,
            hide: false,
            editable: true,
            disabled: mode === 'read' ? true : false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
    ];

    const [columns, setColumns] = useState([]);
    useEffect(() => {
        setColumns(columnsForConditionTab)
        const indexOftableDataInEdit = uniqueTableData.findIndex(item => item.tmplId === uniqueTableData[0].tmplId);
        if (indexOftableDataInEdit === 0) {
            if (uniqueTableData[0].tmplId === 0) {
                setTableData([])
            };
        }
    }, [selectedLanguage, tableData])

    useEffect(() => {
        if (rptTmplId > 0) {
            setColumnList([]);
            setColumnNameMappingLoading(true);
            setTableData([]);
            setColumnListAccessLoading(true);
            setTableLoadingStatus(true);
            setParamBody({ ...paramBody, body: { process: messageReportObjectToBeEditedOrView.reportTemplateMaster.process, inOut: messageReportObjectToBeEditedOrView.reportTemplateMaster.inOut }, sortDir: 'asc', sortKey: 'process', recordsPerPage: 100 });
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.reportTemplateMasters, paramBody)
                .then((response) => {
                    if (response.data.content !== null) {
                        CommonService.GetListApi(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.emailReportTemplateCombo}/${rptTmplId}`,
                            {
                                body: {
                                    rptTmplId: rptTmplId,
                                    // processMaster: { process: response.data.content[0].process },
                                    reportTemplateMaster: { view: response.data.content[0].view, schema: response.data.content[0].schema, process: response.data.content[0].process, inOut: response.data.content[0].inOut }
                                }
                            })
                            .then((response) => {
                                reportTemplateResettingValues(response.data);
                                setReportTemplateToBeEdited(response.data);
                                setOpenBackDrop(false);
                                setColumnNameMappingLoading(false);
                                setColumnList(response.data.remainingColumns);
                                setTotalRowCount(response.data.totalElements);
                                setTableData(response.data.conditions);
                                setColumnListAccessLoading(false);
                                setTableLoadingStatus(false);
                            })
                            .catch(function (error) {
                                setColumnListAccessLoading(false);
                                setTableLoadingStatus(false);
                                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                                setOpenBackDrop(false);
                            });
                    }
                })

        }
    }, [rptTmplId, mode]);
    const [tmplId, setTmplId] = useState(0);

    useEffect(() => {
        if (mode === 'create' && inOutValue !== {} && processNameValue.process !== '') {
            setLeft([]);
            setColumnNameMappingLoading(true);
            setTableData([]);
            setColumnListAccessLoading(true);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.reportTemplateMasters, {
                body: {
                    process: processNameValue.process,
                    inOut: inOutValue.value,
                }
            })
                .then((response) => {
                    setTmplId(processNameValue.process === 'Purchase Order/Purchase Forecast' && inOutValue.value === 'OUT' ? response.data.content[2].tmplId : response.data.content[0].tmplId)
                    if (response.data.content.length > 0) {
                        CommonService.GetListApi(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.emailReportTemplateCombo}/0`, {
                            body: {
                                rptTmplId: rptTmplId,
                                reportTemplateMaster: {
                                    tmplId: processNameValue.process === 'Purchase Order/Purchase Forecast' && inOutValue.value === 'OUT' ? response.data.content[2].tmplId : response.data.content[0].tmplId,
                                    process: processNameValue.process, inOut: inOutValue.value, view: processNameValue.process === 'Purchase Order/Purchase Forecast' && inOutValue.value === 'OUT' ? response.data.content[2].view :
                                        response.data.content[0].view, schema: processNameValue.schema, processMaster: { processId: processNameValue.processId },
                                },
                            }
                        })
                            .then((response) => {
                                setColumnNameMappingLoading(false);
                                setLeft(response.data.remainingColumns.concat({
                                    columnName: "ROW_NUM",
                                    displayName: null,
                                    index: null,
                                    dataType: "CHARACTER VARYING(50)"
                                }));
                                setRight([]);
                                setColumnListAccessLoading(false);
                            })
                            .catch(function (error) {
                                setTableLoadingStatus(false);
                                setColumnNameMappingLoading(false);
                                setColumnListAccessLoading(false);
                                let message = (error.response.data !== undefined || error.response.data !== null) ? error.response.data.message
                                    : "Error occurred while Loading column List!";
                                setAlertBoxState({ ...alertBoxState, openAlert: true, message: message, severity: "error" });
                            });
                    } else {
                        setLeft([]);
                        setColumnListAccessLoading(false);
                        setColumnNameMappingLoading(false);
                    }
                })
        }
    }, [processNameValue, mode, inOutValue])

    function reportTemplateResettingValues(responseData) {
        reset({
            entity: responseData.entity !== undefined ? { erpEntityCode: responseData.entity.erpEntityCode, entityName: responseData.entity.entityName } : null,
            templateType: responseData.templateType !== undefined ? { value: responseData.templateType } : null,
            inout: responseData.inout !== undefined ? { value: responseData.inout } : null,
            name: responseData.name !== undefined ? responseData.name : '',
            reportTemplateMaster: responseData.reportTemplateMaster !== undefined ? {
                process: responseData.reportTemplateMaster.process,
                processId: responseData.reportTemplateMaster.tmplId
            } : null,
            active: responseData.active !== undefined ? responseData.active : true,
            rptDateFormat: responseData.rptDateFormat !== undefined ? { value: responseData.rptDateFormat } : { value: 'dd-MM-yyyy' }
        });
        setBranchValue(responseData.entity !== undefined ? { valuesToBeDisplayed: responseData.entity } : []);
        setTemplateTypeValue(responseData.templateType !== undefined ? { value: responseData.templateType } : []);
        setProcessNameValue(responseData.reportTemplateMaster !== undefined ? {
            process: responseData.reportTemplateMaster.process,
            processId: responseData.reportTemplateMaster.tmplId
        } : []);
        setRight(responseData.columns !== undefined || responseData.columns !== null ? responseData.columns : '');
        setLeft(responseData.remainingColumns !== undefined || responseData.remainingColumns !== null ? responseData.columns.map((columns) => columns.columnName === 'ROW_NUM').includes(true) === false ?
            responseData.remainingColumns.concat({
                columnName: "ROW_NUM",
                displayName: null,
                index: null,
                dataType: "CHARACTER VARYING(50)"
            }) : responseData.remainingColumns : '');
        setTableData(responseData.conditions !== undefined ? responseData.conditions : []);
        setInOutValue(responseData.inout !== undefined ? { value: responseData.inout } : []);
        setDateFormatterValue(responseData.rptDateFormat !== undefined ? { value: responseData.rptDateFormat } : { value: 'dd-MM-yyyy' })
    }

    const key = "tmplId"
    const uniqueTableData = [...new Map(tableData.map(item => [item[key], item])).values()];

    useEffect(() => {
        setTableData(uniqueTableData);
        setRenderTableChanges(!renderTableChnages)
    }, [])

    useEffect(() => {
        setTotalRowCount(uniqueTableData.length)
    }, [uniqueTableData])

    const closeConfirmationDialog = (confirmed) => {
        setOpenConfirmationDialog(false);
        if (confirmed) {
            if (primaryKeyOfSelectedRow > 0) {
                uniqueTableData.splice(indexOfSelectedRow, 1);
                setTableData(uniqueTableData);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: "Condition Deleted Successfully", severity: "success" });
            }
            if (indexOfSelectedRow === 0) {
                uniqueTableData[0].conditionType = null;
            }
        }
    }

    const handleDelete = (primaryKey, nameToBeDisplacedOnConfirmationDialog, index) => {
        setIndexOfSelectedRow(index);
        setPrimaryKeyOfSelectedRow(primaryKey);
        setConfirmationMessage(<FormattedMessage id="common.dialog.areyousure.txt" values={{ nameToBeDisplacedOnConfirmationDialog: `${nameToBeDisplacedOnConfirmationDialog}` }} defaultMessage={`Are you sure, You want to delete '${nameToBeDisplacedOnConfirmationDialog}'?`} />);
        setOpenConfirmationDialog(true);
    }

    useEffect(() => {
        setActiveToBeEdited(reportTemplateToBeEdited);
    }, [reportTemplateToBeEdited])

    useEffect(() => {
        setOpenBackDrop(true);
        let currentRouterComponent;
        if (mode === 'create' && rptTmplId === 0) {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            isRestoreButtonDisabled(false);
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                iconName: CreateIcon,
            }
            setOpenBackDrop(false);
        } else if (mode === 'edit') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isRestoreButtonDisabled(false);
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                iconName: EditIcon,
            }
        } else if (mode === 'read') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isRestoreButtonDisabled(false);
            issaveOrUpdateButtonDisabled(true);
            isRestoreButtonDisabled(true);
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
                iconName: ViewIcon,
            }
        }
        setComponentList([
            {
                path: undefined,
                name: <FormattedMessage id="common.menu.breadcrumb.messageconfiguration" />,
                iconName: IconMessageConfiguration,
            },
            {
                path: '/reportTemplates',
                name: <FormattedMessage id="common.module.breadcrumb.emailreporttemplate" />,
                iconName: IconReportTemplate,
            },
            currentRouterComponent
        ])
    }, [mode]);

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const { handleSubmit, reset, formState: { errors }, control } = useForm({
        mode: "all",
        reValidateMode: 'all',
        defaultValues: {
            reportTemplateMaster: '',
            name: '',
            entity: '',
            active: true,
            templateType: '',
            inout: '',
            rptDateFormat: ''
        }
    });

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.findIndex((column) => column.columnName === value.columnName);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const [searchedAvailableColumns, setSearchedAvailableColumns] = useState('');

    const filteredDataForAvalibleColumns = left.filter(item => {
        return item.columnName.toLowerCase().startsWith(searchedAvailableColumns.toLowerCase());
    });

    const [searchedSelectedColumns, setSearchedSelectedColumns] = useState('');

    const filteredDataForSelectedColumns = right.filter(item => {
        return item.columnName.toLowerCase().startsWith(searchedSelectedColumns.toLowerCase());
    });

    const searchAvailableColumns = () => {
        return (
            <TextField
                size="large"
                variant="standard"
                placeholder={intl.formatMessage({ id: 'searchByAvailableColumnNames.textfield.placeholder' })}
                value={searchedAvailableColumns}
                onChange={(ev) => {
                    if (
                        ev.target.value !== "" ||
                        ev.target.value !== null
                    ) {
                        setSearchedAvailableColumns(ev.target.value);
                    }
                }}
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {(searchedAvailableColumns !== '') && (
                                <IconButton
                                    aria-label="clear_text"
                                    onClick={(() => {
                                        setSearchedAvailableColumns('');
                                    })}
                                    edge="end"
                                    size="large"><ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
                style={{ width: '90%', marginLeft: '4%', marginTop: '2%' }}
            />
        )
    }

    const searchSelectedColumns = () => {
        return (
            <TextField
                size="large"
                variant="standard"
                placeholder={intl.formatMessage({ id: 'SearchBySelectedColumnNames.textfield.placeholder' })}
                value={searchedSelectedColumns}
                onChange={(ev) => {
                    if (
                        ev.target.value !== "" ||
                        ev.target.value !== null
                    ) {
                        setSearchedSelectedColumns(ev.target.value);
                    }
                }}
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {(searchedSelectedColumns !== '') && (
                                <IconButton
                                    aria-label="clear_text"
                                    onClick={(() => {
                                        setSearchedSelectedColumns('');
                                    })}
                                    edge="end"
                                    size="large"><ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
                style={{ width: '90%', marginLeft: '4%', marginTop: '2%' }}
            />
        )
    }

    const customCardColumnList = (title, items, isloading) => (
        <Card
            className={classes.transferListCardForReportTemplate}
            elevation={5}>
            <CardHeader
                className={classes.cardHeaderForReportTemplate}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0 || isTransferButtonsDisabled || mode === 'read' ? true : false}
                        inputProps={{ 'aria-label': 'all items selected' }}
                        color="secondary"
                    />
                }
                title={title}
                action={title === selectedPoHdrDtl ?
                    <Grid item container xs={12} sm={12} md={12} lg={12}>
                        <DateFormaterCombo
                            comboProperty={rptDateFormatterComboProperty}
                            control={control}
                            errors={errors}
                            comboValue={dateFormatterValue}
                            setComboValue={setDateFormatterValue}
                        />
                    </Grid>
                    : ''
                }
            />
            {title === totalPoHdrDtl ? searchAvailableColumns() : searchSelectedColumns()}
            <List className={classes.listForReportTemplate} dense component="div" role="list">
                {items.map((element, index) => {
                    const labelId = `transfer-list-all-item-${element.columnName}-label`;
                    return (
                        <ListItem key={element.columnName} role="listitem"
                            disabled={isTransferButtonsDisabled} selected={(checked.length > 0) && checked.findIndex(item => item.columnName === element.columnName) !== -1} divider>
                            <Grid item container xs={2} sm={2} md={2} lg={1}>
                                <ListItemIcon>
                                    <Checkbox
                                        color='secondary'
                                        checked={checked.findIndex(item => item.columnName === element.columnName) !== -1}
                                        onClick={handleToggle(element)}
                                        tabIndex={-1}
                                        disabled={mode === 'read' ? true : false}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                            </Grid>
                            <Grid item container xs={12} sm={12} md={12} lg={12}>
                                <Grid item container xs={12} sm={7} md={6} lg={5}>
                                    {title === totalPoHdrDtl ?
                                        <ListItemText
                                            primary={`${element.columnName}`}
                                        /> : <ListItemText style={{ marginTop: '13px' }}
                                            primary={`${element.columnName}`}
                                        />}
                                </Grid>
                                <Grid item container xs={12} sm={5} md={6} lg={5}>
                                    {title === selectedPoHdrDtl ?
                                        <ListItemText>
                                            <FormControl>
                                                <TextField
                                                    placeholder={intl.formatMessage({ id: 'displaycolumname.textfield.placeholder' })}
                                                    size="small"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        classes: {
                                                            asterisk: classes.asterisk
                                                        }
                                                    }}
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    onInput={mode === 'edit' ? e => handleTextFieldChangeForEdit((e.target.value), index, 'displayName') : e => handleTextFieldChange((e.target.value), index, 'displayName')}
                                                    disabled={mode === 'read' ? true : false}
                                                    name={pageLabelsConstantsAndMessages.name.displayName}
                                                    defaultValue={element.displayName === undefined || element.displayName === null ? '' : `${element.displayName}`}
                                                />
                                            </FormControl>
                                        </ListItemText>
                                        : null}
                                </Grid>
                                <Grid item container xs={6} sm={3} md={3} lg={2}>
                                    {title === selectedPoHdrDtl ?
                                        <ListItemText>
                                            <FormControl>
                                                <TextField
                                                    placeholder={intl.formatMessage({ id: 'index.textfield.placeholder' })}
                                                    size="small"
                                                    variant="outlined"
                                                    type="number"
                                                    defaultValue={element.index === null ? '' : element.index}
                                                    disabled={mode === 'read' ? true : false}
                                                    name={pageLabelsConstantsAndMessages.name.index}
                                                    onInput={mode === 'edit' ? e => handleTextFieldChangeForEdit((e.target.value), index, 'index') : e => handleTextFieldChange((e.target.value), index, 'index')}
                                                />
                                            </FormControl>
                                        </ListItemText>
                                        : null}
                                </Grid>
                            </Grid>
                        </ListItem>
                    )
                })}
                {isloading && (<Grid item container justifyContent="center" alignItems="center" ><CircularProgress /></Grid>)}
                {title === totalPoHdrDtl ? (filteredDataForAvalibleColumns.length === 0 && searchedAvailableColumns !== '') && (<Grid item container justifyContent="center" alignItems="center" ><Typography>No Columns Found</Typography></Grid>) : (filteredDataForSelectedColumns.length === 0 && searchedSelectedColumns !== '') && (<Grid item container justifyContent="center" alignItems="center" ><Typography>No Columns Found</Typography></Grid>)}
            </List>
        </Card>
    );

    useEffect(() => {
        setRight(right);
    }, [right]);

    const selectedColumn = right.map((column) => {
        return {
            columnName: column.columnName,
            displayName: column.displayName,
            index: column.index === '' ? null : column.index,
            dataType: column.dataType === 'DATE' ? column.dataType : typeof column.columnName
        }
    });

    const testCase = selectedColumn.sort((firstItem, secondItem) => firstItem.index - secondItem.index);

    const test = right.map((indecies) => {
        return indecies.index === '' ? null : indecies.index
    }).sort()

    const tableDataColumnsValueForCreateMode = uniqueTableData.map((columnName) => {
        return {
            columnName: columnName.columnName,
            condition: columnName.condition,
            values: columnName.values,
            tmplId: columnName.tmplId,
            conditionType: columnName.conditionType === undefined ? null : columnName.conditionType,
            dataType: columnName.dataType
        }
    });

    const onSubmit = data => {
        if (selectedColumn.length === 0) {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: "Selected column shouldn't be empty", severity: "error" });
        } else {
            data['createdBy'] = (mode === 'create') ? currentUser.userName : reportTemplateToBeEdited.createdBy;
            data['createdTime'] = (reportTemplateToBeEdited.createdTime === undefined) ? '' : reportTemplateToBeEdited.createdTime;
            data['updatedTime'] = (reportTemplateToBeEdited.updatedTime === undefined) ? '' : reportTemplateToBeEdited.updatedTime;
            data['updatedBy'] = currentUser.userName;
            data['rptTmplId'] = rptTmplId;
            data['organization'] = (reportTemplateToBeEdited.organization === undefined) ? { erpOrgCode: orgInfoOfLeggedUser.erpOrgCode } : reportTemplateToBeEdited.organization;
            data['reportTemplateMaster'] = mode === 'create' ? { tmplId: tmplId, process: processNameValue.process } : reportTemplateToBeEdited.reportTemplateMaster;
            data['entity'] = mode === 'create' ? { erpEntityCode: branchValue.erpEntityCode } : reportTemplateToBeEdited.entity;
            data['columns'] = selectedColumn;
            data['conditions'] = tableDataColumnsValueForCreateMode;
            data['templateType'] = mode === 'create' ? templateTypeValue.value : reportTemplateToBeEdited.templateType;
            data['inout'] = mode === 'create' ? inOutValue.value : reportTemplateToBeEdited.inout;
            data['rptDateFormat'] = dateFormatterValue === '' ? 'dd-MM-yyyy' : dateFormatterValue.value;
            setOpenBackDrop(true)
            CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.emailReportTemplateCombo, data)
                .then((response) => {
                    setOpenBackDrop(false);
                    history.goBack();
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" />, severity: "success" });
                })
                .catch(function (error) {
                    setOpenBackDrop(false);
                    let message = (error.response.data !== undefined || error.response.data !== null) ? error.response.data.message
                        : "Error occurred while Save/Update!";
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: message, severity: "error" });
                });
        }
    }

    const processNameComboProperty = {
        name: pageLabelsConstantsAndMessages.name.reportTemplateMaster,
        isRequired: true,
        label: intl.formatMessage({ id: "columnname.process.label" }),
        isAutofocus: false,
        isDisabled: mode === 'create' ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.reportTemplateMaster
    }

    const branchComboProperty = {
        name: pageLabelsConstantsAndMessages.name.entity,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.entityCode.label" }),
        isAutofocus: true,
        isDisabled: mode === 'create' ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.entity
    }

    const templateTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.templateType,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.label.templateType" }),
        isAutofocus: false,
        isDisabled: mode === 'create' ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.templateType
    }

    const rptDateFormatterComboProperty = {
        name: pageLabelsConstantsAndMessages.name.rptDateFormat,
        isRequired: false,
        label: intl.formatMessage({ id: "textfield.label.dateFormat" }),
        isAutofocus: false,
        isDisabled: mode === 'read' ? true : false,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.rptDateFormat
    }

    const inOutComboProperty = {
        name: pageLabelsConstantsAndMessages.name.inOut,
        isRequired: processNameValue.process === 'Message Transmission Log' ? false : true,
        label: intl.formatMessage({ id: "columnname.inoutReport.label" }),
        isAutofocus: false,
        isDisabled: mode === 'create' && (processNameValue.process !== 'Message Transmission Log') ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.inout
    }

    function resetAllClearThePageInfo() {
        if (mode === 'edit') {
            reportTemplateResettingValues(reportTemplateToBeEdited);
            setActiveToBeEdited({ ...reportTemplateToBeEdited });
            setTabValue(0);
        } else {
            reset({
                entity: null,
                active: true,
                organization: null,
                name: '',
                reportTemplateMaster: null,
                templateType: null,
                inout: '',
                rptDateFormat: { id: 1, value: 'dd-MM-yyyy' }
            });
            setBranchValue([]);
            setProcessNameValue([]);
            setRight([]);
            setTableData([]);
            setTemplateTypeValue([]);
            setInOutValue([]);
            setReportTemplateToBeEdited({ ...reportTemplateToBeEdited, active: true });
            setTabValue(0)
            setDateFormatterValue({ id: 1, value: 'dd-MM-yyyy' })
        }
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForCreateOrEditRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={8} md={8} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6} sm={4} md={4} lg={6} className={classes.topGrid}>
                    <Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2}
                        endIcon={smallScreen ? null : <IconBack />} onClick={history.goBack}
                    > {smallScreen ? <IconBack /> : <FormattedMessage id="common.button.back.lable" defaultMessage="Back" />} </Button>
                    <Button size='small' disabled={disableRestoreButton}
                        variant="contained" color="secondary" className={classes.stateButtonToRestore} elevation={2}
                        endIcon={smallScreen ? null : <IconRestore />} onClick={(() => { resetAllClearThePageInfo() })}
                    > {smallScreen ? <IconRestore /> : <FormattedMessage id="common.button.reset.lable" defaultMessage="Reset" />} </Button>
                    {(emailReportTemplateModuleAccess !== undefined ? emailReportTemplateModuleAccess.createFlag : false) && (<Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                        disabled={disableSaveOrUpdateButton} onClick={handleSubmit(onSubmit)}
                        endIcon={smallScreen ? null : <IconSaveOrUpdate />}
                    > {smallScreen ? <IconSaveOrUpdate /> : saveOrUpdateButtonLabel} </Button>)}
                </Grid>
                <Grid item container alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <form className={classes.formStyleForReportTemplate} onSubmit={handleSubmit(onSubmit)}>
                        <Paper style={{ height: 'auto', paddingBottom: '0.6%', marginBottom: '5px' }} elevation={3}>
                            <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} style={{ marginTop: '0.3%', paddingLeft: '10px' }}>
                                <Grid item container xs={12} sm={6} md={3} lg={2}>
                                    <EntityCodeCombo
                                        comboProperty={branchComboProperty}
                                        control={control}
                                        errors={errors}
                                        comboValue={branchValue}
                                        setComboValue={setBranchValue}
                                    />
                                </Grid>
                                <Grid item container xs={12} sm={6} md={3} lg={2}>
                                    <FormControl fullWidth>
                                        <Controller
                                            control={control}
                                            name={pageLabelsConstantsAndMessages.name.name}
                                            rules={{
                                                required: true,
                                                // pattern: commonPatterns.commonTextfeildPattern.patternForAlphaNumeric
                                            }}

                                            render={({ field }) => (
                                                <TextField
                                                    className={classes.textField}
                                                    variant="outlined"
                                                    margin="normal"
                                                    id="name"
                                                    size='small'
                                                    required
                                                    label={intl.formatMessage({ id: "textfield.emailReportTemplate.label" })}
                                                    error={errors.name}
                                                    {...field}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        classes: {
                                                            asterisk: classes.asterisk
                                                        }
                                                    }}
                                                    disabled={mode === 'create' ? false : true}
                                                />
                                            )}
                                        />
                                        <div className={classes.error}>
                                            {_.get(`${pageLabelsConstantsAndMessages.name.name}.type`, errors) === "required" && (
                                                <FormHelperText className={classes.error}>
                                                    <FormattedMessage id="textfield.emailReportTemplate.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                                </FormHelperText>
                                            )}
                                            {/* {_.get(`${pageLabelsConstantsAndMessages.name.name}.type`, errors) === "pattern" && (
                                                <FormHelperText className={classes.error}> {<FormattedMessage id="textfield.emailReportTemplate.label" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNumeric}</FormHelperText>
                                            )} */}
                                        </div>
                                    </FormControl>
                                </Grid>
                                <Grid item container xs={12} sm={6} md={3} lg={2}>
                                    <ReportTemplateMasterProcessCombo
                                        comboProperty={processNameComboProperty}
                                        control={control}
                                        errors={errors}
                                        comboValue={processNameValue}
                                        inOutValue={inOutValue}
                                        setComboValue={setProcessNameValue}
                                    />
                                </Grid>
                                <Grid item container xs={12} sm={6} md={3} lg={2}>
                                    <ReportTempInOutCombo
                                        comboProperty={inOutComboProperty}
                                        control={control}
                                        errors={errors}
                                        comboValue={inOutValue}
                                        setComboValue={setInOutValue} />
                                </Grid>
                                <Grid item container xs={12} sm={6} md={3} lg={2}>
                                    <TemplateTypeCombo
                                        comboProperty={templateTypeComboProperty}
                                        control={control}
                                        errors={errors}
                                        comboValue={templateTypeValue}
                                        setComboValue={setTemplateTypeValue}
                                    />
                                </Grid>
                                <Grid item container xs={12} sm={6} md={3} lg={2}>
                                    <Grid container justifyContent='flex-start' alignItems='center'>
                                        <Controller
                                            render={({
                                                field: { onChange } }) => (<Switch
                                                    color='primary'
                                                    checked={activeToBeEdited.active !== undefined ? activeToBeEdited.active : true}
                                                    onChange={((event) => {
                                                        onChange(!(activeToBeEdited.active !== undefined ? activeToBeEdited.active : true));
                                                        setActiveToBeEdited({ ...activeToBeEdited, active: event.target.checked })
                                                    })}
                                                    disabled={mode === 'read' ? true : false}
                                                />)}
                                            type="checkbox"
                                            name={pageLabelsConstantsAndMessages.name.active}
                                            control={control}
                                            defaultValue={activeToBeEdited.active !== undefined ? activeToBeEdited.active : true}
                                        />
                                        <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper className={classes.paperStyleForReportTemplate} elevation={3}>
                            <AppBar position="sticky" color="inherit" elevation={2} >
                                <Grid item container alignItems="flex-start" xs={12} sm={12} md={12} lg={12}>
                                    <Tabs
                                        value={tabValue}
                                        onChange={handleChange}
                                        indicatorColor="primary"
                                        scrollButtons='on'
                                        textColor="secondary"
                                        variant="fullWidth"
                                        aria-label="accessible tabs"
                                        style={{ width: '100%' }}
                                    >
                                        <Tab style={{ fontWeight: 600 }} label={<FormattedMessage id="tabname.columns" />} {...a11yProps(0)} />
                                        <Tab style={{ fontWeight: 600 }} label={<FormattedMessage id="tabname.conditions" />} {...a11yProps(1)} />
                                    </Tabs>
                                </Grid>
                            </AppBar>
                            <TabPanel value={tabValue} index={0}>
                                <Grid item container justifyContent="center" className={classes.transferListRoot} xs={12} sm={12} md={12} lg={12}>
                                    <Grid item container justifyContent="center" alignItems="flex-start" xs={5} sm={5} md={5} lg={5}>
                                        {customCardColumnList(totalPoHdrDtl, filteredDataForAvalibleColumns, isColumnNameMappingLoading)}
                                    </Grid>
                                    <Grid item container justifyContent="center" alignItems="center" xs={2} sm={2} md={2} lg={1}>
                                        <Grid item container justifyContent="center" direction={"column"} alignItems="center" >
                                            <Button
                                                variant="contained"
                                                size="medium"
                                                color="primary"
                                                className={classes.transferListButtonStyle}
                                                onClick={handleCheckedRight}
                                                disabled={leftChecked.length === 0 || isTransferButtonsDisabled}
                                                aria-label="move selected right"
                                            >
                                                &gt;
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="medium"
                                                color="primary"
                                                className={classes.transferListButtonStyle}
                                                onClick={handleCheckedLeft}
                                                disabled={rightChecked.length === 0 || isTransferButtonsDisabled}
                                                aria-label="move selected left"
                                            >
                                                &lt;
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent="center" alignItems="flex-start" xs={5} sm={5} md={5} lg={5}>
                                        {customCardColumnList(selectedPoHdrDtl, filteredDataForSelectedColumns, columnListAccessLoading)}
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <Paper elevation={3} className={classes.gridHeightAndWidthForEmailReportTemplate}>
                                    <div style={{ height: '100%', width: '100%' }}>
                                        <DataGridPro
                                            className={classes.customTableStyle}
                                            sortingMode="server"
                                            rows={tableData}
                                            columns={columns}
                                            pageSize={paramBodyForGetById.recordsPerPage}
                                            loading={tableLoadingStatus}
                                            rowCount={totalRowCount}
                                            getRowId={(row) => row.tmplId}
                                            scrollbarSize={30}
                                            disableColumnFilter={true}
                                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                                            pagination
                                            density="compact"
                                            paginationMode="server"
                                            disableSelectionOnClick={true}
                                            disableColumnMenu={false}
                                            disableMultipleColumnsSorting={true}
                                            hideFooter={true}
                                            localeText={localtext}
                                            components={{
                                                Toolbar: () => {
                                                    return (
                                                        <Grid container direction="row" justifyContent="flex-start"
                                                            alignItems="center"
                                                            style={smallScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                                            <Grid item container direction="row" justifyContent="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                                <Grid>
                                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip"
                                                                    />} placement='bottom'>
                                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid>
                                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" />}
                                                                        placement='bottom'>
                                                                        <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid>
                                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                                        <span>
                                                                            <Button size="small" color="primary" variant="outlined"
                                                                                className={classes.refreshToobarStyle}
                                                                                onClick={(() => {
                                                                                    setRefreshTable(!refreshTable);
                                                                                })}>
                                                                                <RefreshIcon />
                                                                            </Button>
                                                                        </span>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid>
                                                                    {processNameValue.process !== '' && processNameValue.process !== undefined && (mode !== 'read') &&
                                                                        <Tooltip title={<FormattedMessage id="common.button.create.breadcrumb.tooltip" />} placement='bottom'>
                                                                            <span>
                                                                                <Button size="small" color="primary" variant="outlined" disabled={mode === 'read' ? true : false}
                                                                                    className={classes.refreshToobarStyle}
                                                                                    onClick={(() => {
                                                                                        setModeForReportTemplateCondition('create');
                                                                                        setOpenReportTemplateMessageDialog(true);
                                                                                    })}
                                                                                >
                                                                                    <CreateIcon />
                                                                                </Button>
                                                                            </span>
                                                                        </Tooltip>}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container direction="row" justifyContent="flex-end" xs={12} sm={12} md={6} lg={6}>
                                                                <CommonGridPagination />
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                },
                                            }}
                                            onPageSizeChange={((pageParams) => {
                                                setParamBodyForGetById(prevState => { return { ...prevState, recordsPerPage: pageParams, pageNo: (pageParams) } })
                                            })
                                            }
                                            onPageChange={((pageParams) => {
                                                setParamBodyForGetById(prevState => { return { ...prevState, pageNo: (pageParams) } })
                                            })}
                                            onSortModelChange={(params) => {
                                                let sortModel = params[0];
                                                if (sortModel !== undefined) {
                                                    setParamBodyForGetById({ ...paramBodyForGetById, sortDir: sortModel.sort, sortKey: sortModel.field });
                                                }
                                            }}
                                            sortingOrder={['desc', 'asc']}
                                            filterMode="server"
                                        />
                                    </div>
                                </Paper>
                            </TabPanel>
                        </Paper>
                        <ConfirmationDialog onClose={closeConfirmationDialog} message={confirmationMessage} open={openConfirmationDialog} />
                        <EmailReportTemplateConditionDialog
                            open={OpenReportTemplateMessageDialog}
                            onClose={closeReportTemplateDialog}
                            title={componentList}
                            mode={modeForReportTemplateCondition}
                            processValue={processNameValue.process}
                            reportTemplateConditionTabToBeEdited={reportTemplateConditionTabToBeEdited}
                            setReportTemplateConditionTabToBeEdited={setReportTemplateConditionTabToBeEdited}
                            tableData={tableData}
                            setTableData={setTableData}
                        />
                    </form>
                </Grid>

            </Grid>
        </React.Fragment >
    )
}

export default CreateOrEditEmailReportTemplate;