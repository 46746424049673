import { CssBaseline, Grid, InputLabel, Switch } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import EmailRptTmplCombo from "../../../../common/Combos/EmailRptTmplCombo/EmailRptTmplCombo";
import RelatedMsgTypeCombo from "../../../../common/Combos/RelatedMsgTypeCombo";
import RelatedProcessNameCombo from "../../../../common/Combos/RelatedProcessNameCombo";
import useStyles from "../../../../common/CommonStyle/CommonStyle";

const CreateAdditionalMsgConfigDtl = (props) => {

    const { relatedProcessNameValue, setRelatedProcessNameValue, relatedMsgTypeValue, setRelatedMsgTypeValue, relatedEmailRptTmplValue, setRelatedEmailRptTmplValue,
        addtnlMsgConfigDtlToBeEdited, setAddtnlMsgConfigDtlToBeEdited, processNameValue, commTypeValue, msgDirection, DialogHeaderAndHeaderValue,
        disabledForReadMode, control, errors, textFieldPanel, emptyTextField, id, setTextFieldPanel, addtnMsgConfigActive, setAddtnlMsgConfigActive, mode, messageConfigDtlObjectToBeEditedOrView } = props;
    const classes = useStyles();
    const intl = useIntl();

    const [addedTextFieldValue, setAddedTextFieldValue] = useState('')

    const pageLabelsConstantsAndMessages = {
        name: {
            active: 'active',
            relatedProcessName: 'relatedProcessName',
            relatedMsgType: 'relatedMsgType',
            relatedEmailRptTemplate: 'relatedEmailRptTemplate'
        }
    }

    const [tempTextFieldPanel] = useState(() => {
        return emptyTextField ? '' : textFieldPanel
    })



    useEffect(() => {
        setAddedTextFieldValue({
            uId: id,
            relatedProcess: relatedProcessNameValue.process === '' ? tempTextFieldPanel.relatedProcessName : relatedProcessNameValue,
            relatedMsgType: relatedMsgTypeValue.relatedMsgType === '' ? tempTextFieldPanel.relatedMsgType : relatedMsgTypeValue.relatedMsgType,
            relatedEmailRptTemplate: relatedEmailRptTmplValue !== null ? relatedEmailRptTmplValue.name === '' ? tempTextFieldPanel.relatedEmailRptTemplate : relatedEmailRptTmplValue : null,
            active: addtnMsgConfigActive.active === undefined ? tempTextFieldPanel.active : addtnMsgConfigActive.active,
        })
    }, [tempTextFieldPanel, id, relatedProcessNameValue, relatedMsgTypeValue, relatedEmailRptTmplValue, addtnMsgConfigActive]);

    useEffect(() => {
        setAddedTextFieldValue({
            uId: id,
            relatedProcess: '',
            relatedMsgType: '',
            relatedEmailRptTemplate: null,
            active: true
        });
        setRelatedMsgTypeValue('');
        setRelatedProcessNameValue('');
        setRelatedEmailRptTmplValue(null);
        setAddtnlMsgConfigActive({ active: true })
    }, [id])

    useEffect(() => {
        if (addedTextFieldValue.relatedProcess !== '') {
            setTextFieldPanel(addedTextFieldValue);
        }
    }, [addedTextFieldValue]);

    const relatedProcessNameComboProperty = {
        name: pageLabelsConstantsAndMessages.name.relatedProcessName,
        isRequired: false,
        label: intl.formatMessage({ id: "columnname.relatedprocessname.label" }),
        isAutofocus: true,
        variant: 'outlined',
        isDisabled: mode === 'read' ? true : mode === 'edit' && textFieldPanel.id !== undefined ? true : false,
        isHookFormType: true,
    };

    const relatedMsgTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.relatedMsgType,
        isRequired: false,
        label: intl.formatMessage({ id: "columnname.messagetype.label" }),
        isAutofocus: false,
        variant: 'outlined',
        isDisabled: mode === 'read' ? true : mode === 'edit' && textFieldPanel.id !== undefined ? true: textFieldPanel === '' ? true : textFieldPanel.process === '' ? true : false,
        isHookFormType: true,
    }

    const relatedEmailRptTmplComboProperty = {
        name: pageLabelsConstantsAndMessages.name.relatedEmailRptTemplate,
        isRequired: false,
        label: intl.formatMessage({ id: 'textfield.emailReportTemplate.label' }),
        isAutofocus: false,
        variant: 'outlined',
        isDisabled: mode === 'read' ? true : mode === 'edit' && textFieldPanel.id !== undefined ? true: textFieldPanel === '' ? true : textFieldPanel.process === '' ? true : textFieldPanel.relatedProcess !== undefined && textFieldPanel.relatedProcess.process === 'PO PDF' ? true : textFieldPanel.relatedProcess !== undefined && textFieldPanel.relatedProcess.process === 'VMI Consumption Report' ? true : false,
        isHookFormType: true,
    }


    return (
        <React.Fragment key={id}>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    {/* <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}> */}
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                        <Grid item container xs={12} sm={6} md={3} lg={4} key={id}>
                            <RelatedProcessNameCombo
                                comboProperty={relatedProcessNameComboProperty}
                                control={control} errors={errors}
                                comboValue={textFieldPanel.relatedProcess}
                                setComboValue={setRelatedProcessNameValue}
                                processMasterValue={processNameValue}
                                test={textFieldPanel.relatedProcessName}
                                bpTypeValueCombo={DialogHeaderAndHeaderValue}
                                msgDirection={msgDirection.value}
                            />
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={2} key={id}>
                            <RelatedMsgTypeCombo
                                comboProperty={relatedMsgTypeComboProperty}
                                control={control} errors={errors}
                                comboValue={textFieldPanel}
                                setComboValue={setRelatedMsgTypeValue}
                                processMasterValue={relatedProcessNameValue}
                                test={textFieldPanel.relatedMsgType}
                            />
                        </Grid>
                        {commTypeValue.newValue === 'Email' && (relatedProcessNameValue.process !== 'PO PDF' || relatedProcessNameValue.process !== 'VMI Consumption Report') &&
                            (<Grid item container xs={12} sm={6} md={3} lg={4} key={id}>
                                <EmailRptTmplCombo
                                    comboProperty={relatedEmailRptTmplComboProperty}
                                    control={control} errors={errors}
                                    comboValue={textFieldPanel.relatedEmailRptTemplate}
                                    setComboValue={setRelatedEmailRptTmplValue}
                                    processNameValue={relatedProcessNameValue}
                                    msgDirection={msgDirection.value}
                                    branchValue={DialogHeaderAndHeaderValue.entity}
                                />
                            </Grid>)}
                        <Grid item container xs={12} sm={6} md={3} lg={2} key={id}>
                            <Grid container justifyContent='flex-start' alignItems='center'>
                                <Controller
                                    render={({
                                        field: { onChange } }) => (<Switch
                                            color='primary'
                                            checked={textFieldPanel.active !== undefined ? textFieldPanel.active : true}
                                            onChange={((event) => {
                                                onChange(!(textFieldPanel.active !== undefined ? textFieldPanel.active : true));
                                                setAddtnlMsgConfigActive({ ...addtnMsgConfigActive, active: event.target.checked });
                                                setTextFieldPanel({ ...textFieldPanel, active: event.target.checked })
                                            })}
                                            disabled={disabledForReadMode}
                                        />)}
                                    type="checkbox"
                                    name={pageLabelsConstantsAndMessages.name.active}
                                    control={control}
                                />
                                <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* </Grid> */}
        </React.Fragment>
    )
}

export default CreateAdditionalMsgConfigDtl;