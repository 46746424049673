import React, { useEffect, useState } from 'react';
import _ from "lodash/fp";
import {
    FormControl, FormHelperText,
    TextField, Grid, CssBaseline
} from '@mui/material';
import { Controller, } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useStyles from '../../../common/CommonStyle/CommonStyle'

function AccountPolicyForm(props) {
    const classes = useStyles();
    const { accountmode, errors, control, setValue} = props

    const [organizationReadOnlyFields, setOrganizationReadOnlyFields] = useState({
        passwordExpiryDays: false,
        passwordExpiryNotifyDays: false,
        maxLoginFailCount: false,
    });

    const pageLabelsConstantsAndMessages = {
        label: {
            backButton: 'Back',
            restoreButton: 'Reset',
            passwordExpiryDays: 'Password Expiry Days',
            passwordExpiryNotifyDays: 'Password Expiry Noify Days',
            maxLoginFailCount: 'Max Login Fail Count',
        },
        name: {
            passwordExpiryDays: 'passwordExpiryDays',
            passwordExpiryNotifyDays: 'passwordExpiryNotifyDays',
            maxLoginFailCount: 'maxLoginFailCount',
        }
    }

    useEffect(() => {
        if (accountmode === 'view') {
            setOrganizationReadOnlyFields({ ...organizationReadOnlyFields, passwordExpiryDays: true, passwordExpiryNotifyDays: true, maxLoginFailCount: true, })
        } else if (accountmode === 'create') {
            setValue('passwordExpiryDays', 180)
            setValue('passwordExpiryNotifyDays', 15)
            setValue('maxLoginFailCount', 3)
        }
    }, [accountmode]);

    return (


        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.passwordExpiryDays}
                                    rules={{
                                        pattern: /^[0-9\b]+$/
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="passwordExpiryDays"
                                            size='small'
                                            type="number"
                                            label={<FormattedMessage id="columnname.PasswordExpiryDays.label" defaultMessage="Password Expiry Days" />}
                                            {...field}
                                            error={errors.passwordExpiryDays}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.passwordExpiryDays,
                                                classes: { notchedOutline: classes.specialOutline },
                                                inputProps: { min: "0", max: "10", step: "1" }
                                            }}
                                            disabled={accountmode === 'view' ? true : false}
                                        />
                                    )}
                                />

                                <div className={classes.error}>

                                    {_.get(`${pageLabelsConstantsAndMessages.name.passwordExpiryDays}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.label.passwordExpiryDays} cannot exceed more than 100 chars</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.passwordExpiryNotifyDays}
                                    rules={{
                                        pattern: /^[0-9\b]+$/
                                    }}

                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="passwordExpiryNotifyDays"
                                            size='small'
                                            type='number'
                                            label={<FormattedMessage id="columnname.PasswordExpiryNoifyDays.label" defaultMessage="Password Expiry NoifyDays" />}
                                            {...field}
                                            error={errors.passwordExpiryNotifyDays}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.passwordExpiryNotifyDays,
                                                classes: { notchedOutline: classes.specialOutline },
                                                inputProps: { min: "0", max: "10", step: "1" }
                                            }}
                                            disabled={accountmode === 'view' ? true : false}
                                        />
                                    )}
                                />

                                <div className={classes.error}>

                                    {_.get(`${pageLabelsConstantsAndMessages.name.passwordExpiryNotifyDays}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.label.passwordExpiryNotifyDays} cannot exceed more than 100 chars</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.maxLoginFailCount}
                                    rules={{
                                        pattern: /^[0-9\b]+$/
                                    }}

                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="maxLoginFailCount"
                                            size='small'
                                            type='number'
                                            label={<FormattedMessage id="columnname.MaxLoginFailCount.label" defaultMessage="Max Login FailCount" />}
                                            {...field}
                                            error={errors.maxLoginFailCount}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.maxLoginFailCount,
                                                classes: { notchedOutline: classes.specialOutline },
                                                inputProps: { min: "0", max: "10", step: "1" }
                                            }}
                                            disabled={accountmode === 'view' ? true : false}
                                        />
                                    )}
                                />

                                <div className={classes.error}>

                                    {_.get(`${pageLabelsConstantsAndMessages.name.maxLoginFailCount}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.label.maxLoginFailCount} cannot exceed more than 100 chars</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>

    );
}

export default AccountPolicyForm;