import {
  Button, CircularProgress, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid,
  Snackbar, TextField, useTheme, useMediaQuery, Tooltip, FormControl, Typography, Switch, IconButton,
} from "@mui/material";
import { Alert } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AttachFileIcon from "@mui/icons-material/AttachFileRounded";
import _ from "lodash/fp";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import { CommonService } from "../../../services/CommonServices.service";
import useStyles from "../CommonStyle/CommonStyle";
import { FormattedMessage, useIntl } from "react-intl";
import Fab from "@mui/material/Fab";
import GetAppIcon from "@mui/icons-material/GetApp";
import BpCodeCombo from "../Combos/BpCodeCombo/BpCodeCombo";
import * as XLSX from 'xlsx/xlsx.mjs';
import EntityCodeCombo from "../Combos/EntityCodeCombo";
import { GlobalEdiApiConstants } from "../../../Constants/GlobalEdiApiConstants";
import ConfirmationDialog from "../ConfirmationDialog";
import ExcelIcon from '../../PoVmiApproval/ExcelIcon.svg';
import saveAs from 'file-saver';

const FileUploadDialog = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const { onClose, open, isFileUpload, dialogHeader, isBusinessPartnerPortal, defaultEntity, defaultBpType } = props;
  const currentUser = CommonService.getLoggedInUserInfo();
  const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [uploadedUserTypeUpdate, setUploadedUserTypeUpdate] = useState(currentUser.userType === 'SIIX-USER' ? false : true);

  const bpTypeValue = { value: defaultBpType }

  const pageLabelsNamesAndConst = {
    labels: {
      dialogTitle: "Upload File",
      attachButton: "Attach",
      cancel: "Cancel",
      submitButton: "Submit",
      uploadFile: "File",
    },
    name: {
      uploadFile: "uploadedFile",
      bpCode: "bpCode",
      importTmplName: "importTmplName",
      entity: 'entity'
    },
  };

  const { handleSubmit, control, formState: { errors } } = useForm({
    mode: "all",
    defaultValues: {
      importTmplName: null
    }
  });

  const [state, setState] = React.useState({
    openAlert: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
    severity: ''
  });

  const { vertical, horizontal, openAlert, message, severity } = state;

  let defaultEntityName = currentUser.userEntityAccessList !== null ? currentUser.userEntityAccessList.filter((item) => {
    return item.defaultEntity === true
  }) : [];

  let entityAccessLength = (currentUser.userType !== 'SIIX-USER' && currentUser.userEntityAccessList !== null) && currentUser.userEntityAccessList.length > 1 ? true : false;

  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState('');
  const [isResponseArrived, setResponseArrived] = useState(false);
  const [branchValue, setBranchValue] = useState({
    entityName: defaultEntityName[0].entity.entityName,
    erpEntityCode: defaultEntity,
    valuesToBeDisplayed: `${defaultEntity} - ${defaultEntityName[0].entity.entityName}`,
    earlierAcceptanceDays: defaultEntityName[0].entity.earlierAcceptanceDays,
    lateAcceptanceDays: defaultEntityName[0].entity.lateAcceptanceDays,
  });

  const [bpCodeValue, setBpCodeValue] = useState(currentUser.userType === 'SIIX-USER' ? '' : {
    bpCode: currentUser.userSupplierAccessList !== null && currentUser.userSupplierAccessList[0].supplier.bpCode,
    name: currentUser.userSupplierAccessList !== null && currentUser.userSupplierAccessList[0].supplier.bpSupplierName,
    valueToBeDisplayed: `${currentUser.userSupplierAccessList !== null && currentUser.userSupplierAccessList[0].supplier.bpCode} - ${currentUser.userSupplierAccessList !== null && currentUser.userSupplierAccessList[0].supplier.bpSupplierName}`,
    earlierAcceptanceDays: currentUser.userSupplierAccessList[0].supplier.earlierAcceptanceDays,
    lateAcceptanceDays: currentUser.userSupplierAccessList[0].supplier.lateAcceptanceDays,
  });

  const [importTmplNameValue, setImportTmplNameValue] = useState('');
  const [importedFileObjectList, setImportedFileObjectList] = useState('');
  const [importedFileObjectListForStartIndexZero, setImportedFileObjectListForStartIndexZero] = useState([]);

  const handleCloseForGlobalEDI = (value) => {
    onClose(value);
    setUploadedUserTypeUpdate(currentUser.userType === 'SIIX-USER' ? false : true);
    if (value === true) {
      setSelectedFileName('');
      setSelectedFile('');
      setBpCodeValue('');
      setBranchValue({
        entityName: defaultEntityName[0].entity.entityName,
        erpEntityCode: defaultEntity,
        valuesToBeDisplayed: `${defaultEntity} - ${defaultEntityName[0].entity.entityName}`,
        earlierAcceptanceDays: defaultEntityName[0].entity.earlierAcceptanceDays,
        lateAcceptanceDays: defaultEntityName[0].entity.lateAcceptanceDays,
      });
      setImportTmplNameValue('');
      setImpTmplHeaderColumns([]);
      setImportedFileHeaderNames([]);
    } else {
      setSelectedFileName('');
      setSelectedFile('');
      setImpTmplHeaderColumns([]);
      setImportedFileHeaderNames([]);
    }
  }

  const handleCloseForBP = (value) => {
    onClose(value);
    setUploadedUserTypeUpdate(currentUser.userType === 'SIIX-USER' ? false : true)
    if (value === true) {
      setSelectedFileName('');
      setSelectedFile('');
      setImpTmplHeaderColumns([]);
      setImportedFileHeaderNames([]);
    }
  }

  const handleCloseAlert = () => {
    setState({ ...state, openAlert: false });
  };

  const [templateNameList, setTemplateNameList] = useState('');
  console.log('templateNameList', templateNameList)
  const templateNameData = templateNameList !== '' ? templateNameList.length > 0 ? templateNameList.map((importTemplate) => {
    return {
      name: importTemplate.importTmplName, id: importTemplate.importTmplId, fileType: importTemplate.fileType,
      startIndex: importTemplate.startIndex, active: importTemplate.active, filePath: importTemplate.filePath
    }
  }) : [] : [] ;
  const [impTmplHeaderColumns, setImpTmplHeaderColumns] = useState([]);
  const [importedFileHeaderNames, setImportedFileHeaderNames] = useState([]);

  useEffect(() => {
    if (open) {
      if (bpCodeValue.bpCode !== undefined) {
        loadTemplateNameData();
      }
    }
  }, [bpCodeValue, open]);

  const loadTemplateNameData = () => {
    CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.importTemplate, {
      body: {
        importTmplName: '', entity: { erpEntityCode: branchValue.erpEntityCode !== undefined ? branchValue.erpEntityCode : '' },
        bpCode: bpCodeValue.bpCode !== undefined ? bpCodeValue.bpCode : ''
      }, sortDir: 'asc', sortKey: 'importTmplName', recordsPerPage: 100
    })
      .then((response) => {
        if (uploadedUserTypeUpdate === false) {
          setTemplateNameList([]);
        } else {
          console.log('Inside1')
          setTemplateNameList(response.data.content);
        }
      })
  }

  useEffect(() => {
    if (bpCodeValue === '') {
      setTemplateNameList('');
      setImportTmplNameValue('');
    }
  }, [bpCodeValue])

  useEffect(() => {
    if (bpCodeValue === '' && templateNameList.length > 0) {
      setImportTmplNameValue('');
    } else if (bpCodeValue === '' && templateNameList === '') {
      setImportTmplNameValue('');
    } else if (bpCodeValue === '' && templateNameList.length === 0) {
      setImportTmplNameValue('');
    } else if (bpCodeValue !== '' && templateNameList === '') {
      setImportTmplNameValue('');
    } else if (bpCodeValue !== '' && templateNameList.length === 0) {
      setImportTmplNameValue({ name: 'SIIX-Delivery Confirmation Upload template' })
    } else if (bpCodeValue !== '' && templateNameList.length !== 0) {
      setImportTmplNameValue({
        name: templateNameData[0] !== undefined && templateNameData[0].name,
        id: templateNameData[0] !== undefined && templateNameData[0].id, fileType: templateNameData[0].fileType,
        startIndex: templateNameData[0].startIndex, filePath: templateNameData[0].filePath
      })
    }
  }, [templateNameList, bpCodeValue])

  const [paramBodyForDefaultImpTemp, setParamBodyForDefaultImpTemp] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.importTemplate);

  useEffect(() => {
    if (templateNameList.length === 0 && bpCodeValue !== '' && importTmplNameValue.name === 'SIIX-Delivery Confirmation Upload template') {
      setParamBodyForDefaultImpTemp({ ...paramBodyForDefaultImpTemp, body: { importTmplName: importTmplNameValue.name, entity: { erpEntityCode: branchValue !== undefined ? branchValue.erpEntityCode : '' }, bpCode: 'All Supplier BP Code' }, sortDir: 'asc', sortKey: 'importTmplName', recordsPerPage: 100 });
    }
  }, [templateNameList, importTmplNameValue]);

  useEffect(() => {
    if (open) {
      if (paramBodyForDefaultImpTemp.sortDir !== 'desc' && paramBodyForDefaultImpTemp.sortKey !== 'updatedTime') {
        if (templateNameList.length === 0 && bpCodeValue !== '' && importTmplNameValue.name === 'SIIX-Delivery Confirmation Upload template') {
          loadDefaultTemplateNameData();
        }
      }
    }
  }, [importTmplNameValue, templateNameList, paramBodyForDefaultImpTemp, open])

  const loadDefaultTemplateNameData = () => {
    if (templateNameList.length === 0 && bpCodeValue !== '' && importTmplNameValue.name === 'SIIX-Delivery Confirmation Upload template') {
      CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.importTemplate, paramBodyForDefaultImpTemp)
        .then((response) => {
          if (response.data.content.length !== 0) {
            setTemplateNameList(response.data.content);
          }
        })
    }
  }

  useEffect(() => {
    if ((templateNameList !== '' || templateNameList.length !== 0) && importTmplNameValue !== '') {
      let headerColumns = templateNameList.filter((item) => {
        return item.importTmplName === importTmplNameValue.name
      }).map((col) => col.headerColumns)
      if (headerColumns[0] !== undefined) {
        setImpTmplHeaderColumns(headerColumns[0].map((col) => { return { headerName: col.headerName } }))
      }
    }
  }, [templateNameList, importTmplNameValue]);

  useEffect(() => {
    if (selectedFileName !== '') {
      if (importTmplNameValue.startIndex !== '') {
        if (importTmplNameValue.startIndex === 0) {
          setImportedFileHeaderNames(importedFileObjectListForStartIndexZero.map((col) => {
            return {
              headerName: col,
            }
          }))
        }
        else if (importTmplNameValue.startIndex >= 0) {
          if (importedFileObjectList[importTmplNameValue.startIndex] !== undefined) {
            setImportedFileHeaderNames(Object.values(importedFileObjectList[importTmplNameValue.startIndex - 1]).map((col) => {
              return {
                headerName: col,
              }
            }))
          }
        }
      }
    }
  }, [selectedFileName, importTmplNameValue, importedFileObjectList]);

  const [columnMappedCheck, setColumnMappedCheck] = useState('')

  useEffect(() => {
    if (importedFileHeaderNames.length !== 0) {
      setColumnMappedCheck(impTmplHeaderColumns.map((col) => col.headerName).toString() === importedFileHeaderNames.map((col) => col.headerName).toString())
    }
  }, [impTmplHeaderColumns, importedFileHeaderNames])

  const onSubmit = () => {
    if (templateNameData[0] !== undefined) {
      if (templateNameData[0].active === true) {
        if (columnMappedCheck === false) {
          setState({
            ...state, openAlert: true, message: `Columns from the imported file do not matching with the registered template. 
          Kindly check!`, severity: "error"
          });
        } else if (columnMappedCheck === true) {
          const formData = new FormData();
          formData.append('uploadedFile', selectedFile);
          formData.append('bpType', bpTypeValue.value);
          formData.append('bpCode', bpCodeValue.bpCode);
          formData.append('importTmplId', importTmplNameValue.id);
          formData.append('importTmplName', importTmplNameValue.name);
          formData.append('entity', branchValue.erpEntityCode);
          formData.append('bpName', bpCodeValue.name);
          formData.append('uploadedUserType', uploadedUserTypeUpdate === false ? 0 : currentUser.userType === 'SIIX-USER' ? 1 : 2);
          formData.append('moduleName', 'PO DLV CFM');
          onClose(true);
          setResponseArrived(true);
          CommonService.uploadFileForDataImport('/importDataFile', formData)
            .then((response) => {
              setResponseArrived(false);
              setState({ ...state, openAlert: true, message: <FormattedMessage id="yourrequestnoforimport.snackbar.text" values={{ responsedata: `${response.data.value}` }} />, severity: "success" });
              handleCloseForGlobalEDI(currentUser.userType === 'SIIX-USER' ? true : false);
              handleCloseForBP(currentUser.userType === 'SIIX-USER' ? false : true)
            })
            .catch(function (error) {
              setResponseArrived(false);
              let errorMessage = error.response === undefined ? <FormattedMessage id="connectionerror.message.text" defaultMessage={`Connection Error, please check you internet connection`} /> :
                error.response.data.message;
              setState({ ...state, openAlert: true, message: errorMessage, severity: "error" });
            });
        }
      } else {
        setState({
          ...state, openAlert: true, message: `Template is In-Active`, severity: "error"
        });
      }
    } else {
      setState({
        ...state, openAlert: true, message: `Template is Not Created`, severity: "error"
      });
    }
  };

  const branchComboProperty = {
    name: pageLabelsNamesAndConst.name.entity,
    isRequired: true,
    label: intl.formatMessage({ id: "textfield.entityCode.label" }),
    isAutofocus: false,
    isDisabled: isBusinessPartnerPortal ? entityAccessLength === false ? true : false : bpCodeValue !== '' ? true : false,
    variant: 'outlined',
    isHookFormType: true,
    errors: errors.entity,
  }

  const bpCodeComboProperty = {
    name: pageLabelsNamesAndConst.name.bpCode,
    isRequired: isBusinessPartnerPortal ? entityAccessLength === false ? true : false : true,
    label: intl.formatMessage({ id: "common.textfield.bpcode" }),
    isAutofocus: false,
    isDisabled: isBusinessPartnerPortal ? entityAccessLength === false ? true : false : false,
    variant: "outlined",
    isHookFormType: true,
    errors: errors.bpCode,
  };

  const closeConfirmationDialog = (confirmed) => {
    if (confirmed) {
      onSubmit();
    }
    setOpenConfirmationDialog(false);
  }

  const handleOpenUploadedUserTypeConfirmation = () => {
    setConfirmationMessage(uploadedUserTypeUpdate === false ? <FormattedMessage id="common.diolog.siixuserswitchpopupforimport.text" />
      : <FormattedMessage id="common.diolog.supplierbehalfswitchpopupforimport.text" />);
    setOpenConfirmationDialog(true);
  }

  let tempAcceptPriorMonthDelivery = bpCodeValue.acceptPriorDelivery;

  const earlierAcceptanceDays = uploadedUserTypeUpdate ?
    !tempAcceptPriorMonthDelivery ? bpCodeValue.earlierAcceptanceDays !== 0 ?
      bpCodeValue.earlierAcceptanceDays : bpCodeValue.earlierAcceptanceDays === 0 && bpCodeValue.lateAcceptanceDays === 0 && branchValue.earlierAcceptanceDays !== 0 ? branchValue.earlierAcceptanceDays : 0 : 0 : 0;

  const lateAcceptanceDays = uploadedUserTypeUpdate ? !tempAcceptPriorMonthDelivery ? bpCodeValue.lateAcceptanceDays !== 0 ?
    bpCodeValue.lateAcceptanceDays : bpCodeValue.lateAcceptanceDays === 0 && bpCodeValue.earlierAcceptanceDays === 0 && branchValue.lateAcceptanceDays !== 0 ? branchValue.lateAcceptanceDays : 0 : 0 : 0;

  const lateAcceptanceDaysWhenCrossMonthEnabled = uploadedUserTypeUpdate ? tempAcceptPriorMonthDelivery ?
    bpCodeValue.lateAcceptanceDays !== 0 ? bpCodeValue.lateAcceptanceDays : bpCodeValue.lateAcceptanceDays === 0 &&
      bpCodeValue.earlierAcceptanceDays === 0 && branchValue.lateAcceptanceDays !== 0 ? branchValue.lateAcceptanceDays : 0 : 0 : 0;

  console.log('importTmplNameValue',importTmplNameValue);

  const showDownloadExcel = (filePath) => {
    const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
    const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
    fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
      method: 'GET',
      headers: {
        authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
      },
    })
      .then((response) => {
        return response.blob();
      }
      )
      .then((blob) => {
        setState({ ...state, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
        saveAs(blob, fileName);
      })
      .catch(error => {
        setState({ ...state, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
      })
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={9000}
      >
        <Alert onClose={handleCloseAlert} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <ConfirmationDialog onClose={closeConfirmationDialog} message={confirmationMessage} open={openConfirmationDialog} />
      <Dialog fullWidth aria-labelledby="create-or-delete-dialog" maxWidth="md" open={open}>
        <DialogTitle color="primary" className={classes.title}>
          <Grid container>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
            >
              <strong>
                {dialogHeader !== undefined
                  ? dialogHeader
                  : <FormattedMessage id="header.uploadDLVCFMSch.label" defaultMessage="Upload Delivery Confirmation Schedule" />}
              </strong>
            </Grid>
            {currentUser.userType === 'SIIX-USER' && <Grid container justifyContent="flex-end" alignItems="center" item xs={12} sm={12} md={4} lg={4}
            >
              <Typography><strong><FormattedMessage id="header.SIIXUser.label" defaultMessage="SIIX User" /></strong></Typography>
              <Switch color='success' size="medium"
                checked={uploadedUserTypeUpdate}
                onChange={(event) => {
                  setUploadedUserTypeUpdate(event.target.checked)
                }} />
              <Typography><strong><FormattedMessage id="header.BehalfOfSupplier.label" defaultMessage="Behalf of Supplier" /></strong></Typography>
            </Grid>}
          </Grid>
        </DialogTitle>
        <DialogContent dividers style={{ minHeight: "100px" }}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <EntityCodeCombo comboProperty={branchComboProperty} control={control} errors={errors} comboValue={branchValue} setComboValue={setBranchValue} />
                </Grid>
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <BpCodeCombo
                    comboProperty={bpCodeComboProperty}
                    control={control}
                    errors={errors}
                    comboValue={bpCodeValue}
                    bpTypeValue={bpTypeValue}
                    setComboValue={setBpCodeValue}
                    branchValue={branchValue}
                  />
                </Grid>
                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                  <Grid item container xs={11} sm={11} md={11} lg={11}>
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        type="string"
                        variant="outlined"
                        value={bpCodeValue === '' ? '' : importTmplNameValue.name}
                        label={<FormattedMessage id="textfield.label.templatename" defaultMessage="Template Name" />}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item container xs={1} sm={1} md={1} lg={1}>
                    <Button className={classes.customeToolbar} size="large" disabled={importTmplNameValue.filePath !== undefined ? false : true} onClick={() => {
                      showDownloadExcel(importTmplNameValue.filePath)
                    }}>
                      <img icon src={ExcelIcon} alt='ExcelIcon' color="primary" size='10' />
                    </Button>
                  </Grid>
                </Grid>

              </Grid>
              <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                <Grid item container xs={10} sm={11} md={11} lg={11}>
                  <TextField className={classes.uploadTextField}
                    size="small"
                    variant="outlined"
                    value={selectedFileName}
                    InputProps={{
                      readOnly: true,
                      style: { fontSize: '12px' }
                    }}
                  />

                </Grid>
                <Grid item container xs={2} sm={1} md={1} lg={1}>
                  <div>
                    <label htmlFor="contained-button-file">
                      <Fab size='small'
                        color="primary" component="span"
                      ><AttachFileIcon /></Fab>
                    </label>
                    <input
                      accept={importTmplNameValue.fileType === 'EXCEL_2003' ? ".xls" : importTmplNameValue.fileType === 'EXCEL' ? '.xlsx' : importTmplNameValue.fileType === 'TXT' ? '.txt' : importTmplNameValue.fileType === 'CSV' ? '.csv' : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                      className={classes.input}
                      id="contained-button-file"
                      name={pageLabelsNamesAndConst.name.uploadFile}
                      type="file"
                      onChange={((event) => {
                        event.preventDefault();
                        // setOpenBackDrop(true);
                        if (event.target.files[0] !== undefined) {
                          setSelectedFileName(event.target.files[0].name);
                          setSelectedFile(event.target.files[0]);
                          const reader = new FileReader();
                          reader.onload = (event) => {
                            const data = event.target.result;
                            const workbook = XLSX.read(data, { type: "_" });
                            const sheetName = workbook.SheetNames[0];
                            const worksheet = workbook.Sheets[sheetName];

                            const workBookSheetNoReplaceWithEmpty = Object.keys(workbook.Sheets[sheetName]).toString().replace(/[0-9]/g, '')

                            const workSheetkeyObject = workBookSheetNoReplaceWithEmpty !== '' ? workBookSheetNoReplaceWithEmpty.split(',').map((col) => {
                              return {
                                index: col
                              }
                            }) : []

                            const key = "index"

                            const uniqueHeaderIndexData = [...new Map(workSheetkeyObject.map(item => [item[key], item])).values()];

                            const workbookStrings = workbook.Strings.slice(0, uniqueHeaderIndexData.filter((col) => {
                              return !col.index.includes('!')
                            }).length)
                            const test = workbookStrings.map((col) => col.t)
                            setImportedFileObjectListForStartIndexZero(test)
                            setImportedFileObjectList(XLSX.utils.sheet_to_json(worksheet));
                            // setOpenBackDrop(false);
                          };
                          // setOpenBackDrop(false);
                          reader.readAsArrayBuffer(event.target.files[0]);
                        }
                      })}
                    />
                  </div>
                </Grid>
                <Grid item container xs={10} sm={12} md={12} lg={12}>
                  <div className={classes.error}>
                    {_.get(`${pageLabelsNamesAndConst.name.uploadFile}.type`, errors) === "required" && (
                      <FormHelperText className={classes.error}>
                        <FormattedMessage id="selectAnyFile.helper" defaultMessage="Please select any file" />
                      </FormHelperText>
                    )}
                  </div>
                </Grid>
              </Grid>

              {tempAcceptPriorMonthDelivery && uploadedUserTypeUpdate && (lateAcceptanceDaysWhenCrossMonthEnabled > 0) && (
                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography color="red">
                      <FormattedMessage id='notedlvcfmDate.note' />
                      <br />
                      <FormattedMessage id="lateacceptancewhencrossmonthallowenabled.note" values={{
                        earlierAcceptancedays: `${earlierAcceptanceDays}`,
                        lateAcceptancedays: `${lateAcceptanceDaysWhenCrossMonthEnabled}`
                      }} /></Typography>
                  </Grid>
                </Grid>
              )}
              {tempAcceptPriorMonthDelivery && uploadedUserTypeUpdate && (lateAcceptanceDaysWhenCrossMonthEnabled === 0) && (
                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography color="red">
                      <FormattedMessage id='notedlvcfmDate.note' />
                      <br />
                      <FormattedMessage id="whencrossmonthallowed.note" /></Typography>
                  </Grid>
                </Grid>
              )}
              {(!tempAcceptPriorMonthDelivery && uploadedUserTypeUpdate && (earlierAcceptanceDays > 0 && lateAcceptanceDays > 0)) && (<Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography color="red">
                    <FormattedMessage id='notedlvcfmDate.note' />
                    <br />
                    <FormattedMessage id="ealierlateaccetancedaysnotzero.note" values={{
                      earlierAcceptancedays: `${earlierAcceptanceDays}`,
                      lateAcceptancedays: `${lateAcceptanceDays}`
                    }} /></Typography>
                </Grid>
              </Grid>)}
              {(!tempAcceptPriorMonthDelivery && uploadedUserTypeUpdate && (earlierAcceptanceDays === 0 && lateAcceptanceDays > 0)) && (<Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography color="red">
                    <FormattedMessage id='notedlvcfmDate.note' />
                    <br />
                    <FormattedMessage id="ealieraccetancedayszero.note" values={{
                      earlierAcceptancedays: `${earlierAcceptanceDays}`,
                      lateAcceptancedays: `${lateAcceptanceDays}`
                    }} /></Typography>
                </Grid>
              </Grid>)}
              {(!tempAcceptPriorMonthDelivery && uploadedUserTypeUpdate && (earlierAcceptanceDays > 0 && lateAcceptanceDays === 0)) && (<Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography color="red">
                    <FormattedMessage id='notedlvcfmDate.note' />
                    <br />
                    <FormattedMessage id="lateaccetancedayszero.note" values={{
                      earlierAcceptancedays: `${earlierAcceptanceDays}`,
                      lateAcceptancedays: `${lateAcceptanceDays}`
                    }} /></Typography>
                </Grid>
              </Grid>)}
              {(!tempAcceptPriorMonthDelivery && uploadedUserTypeUpdate && (earlierAcceptanceDays === 0 && lateAcceptanceDays === 0)) && (<Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography color="red">
                    <FormattedMessage id='notedlvcfmDate.note' />
                    <br />
                    <FormattedMessage id="whencrossmonthnotallowed.note" /></Typography>
                </Grid>
              </Grid>)}
            </Grid>
          </form>
        </DialogContent>
        {!mobileScreen && (
          <DialogActions className={classes.dialogActionForFileUploading}>
            <Grid container justifyContent="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                className={classes.buttonSpacing}
                startIcon={<ClearIcon />}
                onClick={() => {
                  handleCloseForGlobalEDI(currentUser.userType === 'SIIX-USER' ? true : false);
                  handleCloseForBP(currentUser.userType === 'SIIX-USER' ? false : true)
                }}
              >
                <FormattedMessage
                  id="common.button.cancel.lable"
                  defaultMessage={pageLabelsNamesAndConst.labels.cancel}
                />
              </Button>
              <Button
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                className={classes.buttonSpacing}
                onClick={currentUser.userType === 'SIIX-USER' ?
                  handleOpenUploadedUserTypeConfirmation : handleSubmit(onSubmit)
                }
                disabled={
                  isResponseArrived ||
                  selectedFile === undefined ||
                  selectedFile === "" ||
                  selectedFileName === "" ||
                  bpCodeValue === ""
                }
                startIcon={<SaveIcon />}
                endIcon={
                  isResponseArrived ? (
                    <CircularProgress color="secondary" size={20} />
                  ) : null
                }
              >
                <FormattedMessage
                  id="common.button.submit.lable"
                  defaultMessage={pageLabelsNamesAndConst.labels.submitButton}
                />
              </Button>
            </Grid>
          </DialogActions>
        )}

        {mobileScreen && (
          <DialogActions className={classes.dialogActionForFileUploading}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Tooltip
                title={
                  <FormattedMessage id="common.downloadtemplatebutton.tooltip" />
                }
              >
                {!isFileUpload && !isBusinessPartnerPortal ? (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className={classes.buttonSpacing}
                    endIcon={
                      isResponseArrived ? (
                        <CircularProgress color="secondary" size={20} />
                      ) : null
                    }
                  >
                    <GetAppIcon />
                  </Button>
                ) : null}
              </Tooltip>
              <Tooltip
                title={<FormattedMessage id="common.button.cancel.lable" />}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  className={classes.buttonSpacing}
                  onClick={() => {
                    handleCloseForGlobalEDI(currentUser.userType === 'SIIX-USER' ? true : false);
                    handleCloseForBP(currentUser.userType === 'SIIX-USER' ? false : true)
                  }}
                >
                  <ClearIcon />
                </Button>
              </Tooltip>
              <Tooltip
                title={<FormattedMessage id="common.button.submit.lable" />}
              >
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.buttonSpacing}
                  onClick={currentUser.userType === 'SIIX-USER' ?
                    handleOpenUploadedUserTypeConfirmation : handleSubmit(onSubmit)
                  }
                  disabled={
                    isResponseArrived ||
                    selectedFile === undefined ||
                    selectedFile === "" ||
                    selectedFileName === "" ||
                    bpCodeValue === ""
                  }
                  endIcon={
                    isResponseArrived ? (
                      <CircularProgress color="secondary" size={20} />
                    ) : null
                  }
                >
                  <SaveIcon />
                </Button>
              </Tooltip>
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default FileUploadDialog;