import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import SiixLogo from './siix_logo_without_title.png'
import SidebarNav from './SidebarNav'
import { Paper, Chip } from '@mui/material'
import { CommonService } from '../../../services/CommonServices.service';
import { LoggedInUserDetails } from '../LoggedInUserDetails/LoggedInUserDetails';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  sidebar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  sidebarBackground: {
    backgroundColor: theme.sidebar.backgroundColor,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
  },
  sidebarBody: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  sidebarHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    // background: theme.header.backgroundColor,
    boxShadow: '#ffff',
    marginTop: '-8.9px',
    marginBottom: '3px',
    ...theme.mixins.toolbar,
  },
  sidebarTitleLink: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
  },
  logo: {
    color: theme.palette.primary,
    zIndex: 10,
    width: '40%',
    height: '70%',
    marginLeft: 70,
  },
  title: props => ({
    position: 'relative',
    overflow: 'visible',
    marginLeft: '5px',
    display: props.isCollapsed ? 'none' : 'block',
    fontSize: '1.1rem',
    letterSpacing: '.015em',
    // fontWeight: 'bold',
  }),
  name: {},
  tagline: {
    fontSize: 14,
    fontWeight: 'bold',
    position: 'absolute',
    top: '100%',
    margin: '-23px -8px 0px 0',
    // background: theme.palette.customPrimary.main,
    color: '#000',
    borderRadius: 2,
    // padding: '1px 3px',
    right: 75,
  },
  taglineBusiness: {
    fontSize: 14,
    fontWeight: 'bold',
    position: 'absolute',
    top: '100%',
    margin: '-23px -35px 0px 0',
    // background: theme.palette.customPrimary.main,
    color: '#000',
    borderRadius: 2,
    // padding: '1px 3px',
    right: 75,
  },
  logocollpase: {
    marginTop: '9% !important'
  },
  logosidebar: {
    marginLeft: '-6.5px !important',
    width: '134% !important',
    marginTop: '1% !important',
    marginBottom: '-10% !important'
  },
  logoName:{
    marginLeft:'-50%',
    marginTop: '8%',
    position: 'relative',
    fontSize: 14,
    fontWeight: 600
  },
  logoNameportal:{
    marginLeft:'-57%',
    marginTop: '8%',
    position: 'relative',
    fontSize: 14,
    fontWeight: 600
  }

}))

const Sidebar = props => {
  const { isCollapsed, setIsSidebarCollapsed } = props
  const classes = useStyles(props);
  const currentUser = CommonService.getLoggedInUserInfo();
  const { loggedInUserInfo } = useContext(LoggedInUserDetails);

  return (
    <aside className={classes.sidebar}>
      <div className={classes.sidebarBackground} />
      <div className={classes.sidebarBody}>
        <div className={classes.sidebarHeader}>
          {(isCollapsed) && (
            <Paper square elevation={15}>
              {/* <Toolbar className={classes.logocollpase}> */}
                <img src={SiixLogo} className={classes.logosidebar} alt='company_logo' />
              {/* </Toolbar> */}
            </Paper>)}
          <Paper elevation={15} square>
            <Link to="/organizations" className={classes.sidebarTitleLink}>
              <Typography
                component="h6"
                variant="h5"
                color="inherit"
                noWrap
                className={classes.title}
              >
                <img src={SiixLogo} className={classes.logo} alt='company_logo' />
                <Chip
                className={((currentUser !== null && currentUser !== undefined) && loggedInUserInfo.logInStatus === true && currentUser.userType === 'SIIX-USER') ? classes.logoName : classes.logoNameportal}
                label = {((currentUser !== null && currentUser !== undefined) && loggedInUserInfo.logInStatus === true && currentUser.userType === 'SIIX-USER') ? <FormattedMessage id="login.siixUsertitle.header" defaultMessage="Business Partner Portal" /> : ((currentUser !== null && currentUser !== undefined) && loggedInUserInfo.logInStatus === true && currentUser.userType === 'SUPPLIER') ? <FormattedMessage id="login.businessPortaltitle.header" defaultMessage="Business Partner Portal" /> : "SIIX Business Portal"}
                variant="outlined"
                color="primary" 
              />
              </Typography>
            
            </Link>
          </Paper>
        </div>
        <SidebarNav isCollapsed={isCollapsed} setIsSidebarCollapsed={setIsSidebarCollapsed} />
      </div>
    </aside>
  )
}

Sidebar.defaultProps = {
  isCollapsed: false,
}

Sidebar.propTypes = {
  isCollapsed: PropTypes.bool,
}

export default Sidebar;
