function FakeBackendForPoScheduler() {
    return (
        {
            "content": [
                {
                    "id": 1,
                    "poSchGridId": 1,
                    "poInfoHdrId": 1,
                    "deliveryReqQty": 40000,
                    "scheduleType": 4,
                    "scheduledDt": "2021-08-30",
                    "scheduledQty": 4896,
                    "scheduleFreq": "Weekly"
                },
                {
                    "id": 2,
                    "poSchGridId": 2,
                    "poInfoHdrId": 2,
                    "deliveryReqQty": 40000,
                    "scheduleType": 4,
                    "scheduledDt": "2021-09-06",
                    "scheduledQty": 2000,
                    "scheduleFreq": "Weekly"
                },
                {
                    "id": 3,
                    "poSchGridId": 3,
                    "poInfoHdrId": 3,
                    "deliveryReqQty": 40000,
                    "scheduleType": 4,
                    "scheduledDt": "2021-09-12",
                    "scheduledQty": 26784,
                    "scheduleFreq": "Weekly"
                },
                {
                    "id": 4,
                    "poSchGridId": 4,
                    "poInfoHdrId": 4,
                    "deliveryReqQty": 40000,
                    "scheduleType": 4,
                    "scheduledDt": "2021-09-18",
                    "scheduledQty": 2764,
                    "scheduleFreq": "Weekly"
                },
                {
                    "id": 5,
                    "poSchGridId": 5,
                    "poInfoHdrId": 5,
                    "deliveryReqQty": 40000,
                    "scheduleType": 4,
                    "scheduledDt": "2021-09-24",
                    "scheduledQty": 1814,
                    "scheduleFreq": "Weekly"
                },
                {
                    "id": 6,
                    "poSchGridId": 6,
                    "poInfoHdrId": 6,
                    "deliveryReqQty": 40000,
                    "scheduleType": 4,
                    "scheduledDt": "2021-10-01",
                    "scheduledQty": 1843,
                    "scheduleFreq": "Monthly"
                },
                {
                    "id": 7,
                    "poSchGridId": 7,
                    "poInfoHdrId": 7,
                    "deliveryReqQty": 40000,
                    "scheduleType": 4,
                    "scheduledDt": "2021-11-01",
                    "scheduledQty": 3882,
                    "scheduleFreq": "Monthly"
                },
                {
                    "id": 8,
                    "poSchGridId": 8,
                    "poInfoHdrId": 8,
                    "deliveryReqQty": 40000,
                    "scheduleType": 4,
                    "scheduledDt": "2021-12-01",
                    "scheduledQty": 2448,
                    "scheduleFreq": "Monthly"
                },
                {
                    "id": 9,
                    "poSchGridId": 9,
                    "poInfoHdrId": 9,
                    "deliveryReqQty": 40000,
                    "scheduleType": 4,
                    "scheduledDt": "2022-01-01",
                    "scheduledQty": 1258,
                    "scheduleFreq": "Monthly"
                },
                {
                    "id": 10,
                    "poSchGridId": 10,
                    "poInfoHdrId": 10,
                    "deliveryReqQty": 40000,
                    "scheduleType": 4,
                    "scheduledDt": "2022-02-01",
                    "scheduledQty": 4184,
                    "scheduleFreq": "Monthly"
                },
                {
                    "id": 11,
                    "poSchGridId": 11,
                    "poInfoHdrId": 11,
                    "deliveryReqQty": 40000,
                    "scheduleType": 4,
                    "scheduledDt": "2022-03-01",
                    "scheduledQty": 1843,
                    "scheduleFreq": "Monthly"
                },
                {
                    "id": 12,
                    "poSchGridId": 12,
                    "poInfoHdrId": 12,
                    "deliveryReqQty": 40000,
                    "scheduleType": 4,
                    "scheduledDt": "2022-04-01",
                    "scheduledQty": 2887,
                    "scheduleFreq": "Monthly"
                },
                
            ],
            "pageable": {
                "sort": {
                    "sorted": true,
                    "unsorted": false,
                    "empty": false
                },
                "offset": 0,
                "pageSize": 25,
                "pageNumber": 0,
                "paged": true,
                "unpaged": false
            },
            "totalElements": 1,
            "last": true,
            "totalPages": 1,
            "size": 25,
            "number": 0,
            "sort": {
                "sorted": true,
                "unsorted": false,
                "empty": false
            },
            "numberOfElements": 1,
            "first": true,
            "empty": false
        }
    )
}

export default FakeBackendForPoScheduler;