import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import English from '../../../lang/en.json';
import Japanese from '../../../lang/jp.json';
import German from '../../../lang/de.json';
import Indonesian from '../../../lang/id.json';
import Chinese_Traditional from '../../../lang/tw.json';
import Chinese_Simplified from '../../../lang/cn.json';


export const SupportedLanguageDetails = React.createContext();//The React.createContext creates a Context object.

const browserLanguage = navigator.language;

let defaultLanguage = {
    language: 'ENGLISH',
    value: 'en'
}

let lang;
if (browserLanguage.includes('en')) {
    lang = English;
} else if (browserLanguage === 'jp') {
    lang = Japanese;
    defaultLanguage = {
        language: 'JAPANESE',
        value: 'jp'
    }
} else if (browserLanguage === 'de') {
    lang = German;
    defaultLanguage = {
        language: 'GERMAN',
        value: 'de'
    }
}
else if (browserLanguage === 'id') {
    lang = Indonesian;
    defaultLanguage = {
        language: 'INDONESIAN',
        value: 'id'
    }

} else if (browserLanguage === 'tw') {
    lang = Chinese_Traditional;
    defaultLanguage = {
        language: 'CHINESE (TRADITIONAL)',
        value: 'tw'
    }

} else {
    lang = Chinese_Simplified;
    defaultLanguage = {
        language: 'CHINESE (SIMPLIFIED)',
        value: 'cn'
    }

}



const LanguageDetailsProvider = (props) => {

    const [locale, setLocale] = useState(defaultLanguage);
    const [messages, setMessages] = useState(lang);
    const [isUserRequestedToChangeLang, setUserRequestedToChangeLang] = useState(false);

    function languageSelection(selectedLanguage) {
        setUserRequestedToChangeLang(false);
        const newLocale = selectedLanguage === null ? defaultLanguage : selectedLanguage;
        setLocale(newLocale);
        if (newLocale.value === 'en') {
            setMessages(English);
        } else if (newLocale.value === 'jp') {
            setMessages(Japanese);
        } else if (newLocale.value === 'de') {
            setMessages(German);
        } else if (newLocale.value === 'id') {
            setMessages(Indonesian);
        } else if (newLocale.value === 'tw') {
            setMessages(Chinese_Traditional);
        } else {
            setMessages(Chinese_Simplified);
        }
    }

    const onChangeLanguage = (event, selectedLanguage) => {
        languageSelection(selectedLanguage);
    }

    function changeLanguageForMenuList(selectedLanguage) {
        languageSelection(selectedLanguage);
    }

    return (
        <SupportedLanguageDetails.Provider value={{ locale, onChangeLanguage, messages, changeLanguageForMenuList, isUserRequestedToChangeLang, setUserRequestedToChangeLang }}>
            <IntlProvider messages={messages} locale={locale.value}>
                {props.children}
            </IntlProvider>
        </SupportedLanguageDetails.Provider>
    );
}
export default LanguageDetailsProvider;
