function FakeBackendForReportTemplate() {
    return (
        {
            "content": [
                {
                    'rptTmplId': 1,
                    'rptName': 'PFU Invoice',
                    // 'rptConfig': '',
                    'process': {
                        'process': "Invoice",
                        'processId': 5
                    },
                    'organization': {
                        'erpOrgCode': 'SIIXCORP',
                        'orgName': 'SIIX'
                    },
                    'entity': {
                        'erpEntityCode': 'SS',
                        'entityName': 'SIIX Singapore Pte Ltd'
                    },
                    "createdBy": "ADMIN",
                    "createdTime": "2021-06-03",
                    "updatedBy": 'ADMIN',
                    "updatedTime": "2021-06-09",
                    "active": true,
                    "createdDtDisp": "03-06-2021",
                    "lastUpdatedDtDisp": "09-06-2021",
                },
            ],
            "pageable": {
                "sort": {
                    "sorted": true,
                    "unsorted": false,
                    "empty": false
                },
                "offset": 0,
                "pageSize": 25,
                "pageNumber": 0,
                "paged": true,
                "unpaged": false
            },
            "totalElements": 1,
            "last": true,
            "totalPages": 1,
            "size": 25,
            "number": 0,
            "sort": {
                "sorted": true,
                "unsorted": false,
                "empty": false
            },
            "numberOfElements": 1,
            "first": true,
            "empty": false
        }
    )
}
export default FakeBackendForReportTemplate;