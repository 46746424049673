import { Breadcrumbs, Icon, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    rootForCustomBreadCrumb: {
        flexGrow: 1,
        padding: '0 1% !important',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    icon: {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(-0.5),
        width: '20px !important',
        height: '20px !important',
        display: 'inline !important',
        [theme.breakpoints.down(undefined)]: {
            marginRight: theme.spacing(0.5),
            marginBottom: theme.spacing(-0.5),
            width: '28px !important',
            height: '28px !important',
            display: 'inline !important',
        }
    },
    iconForDialog: {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(-0.5),
        width: '23px !important',
        height: '23px !important',
        display: 'inline !important',
    },
    inActiveBdreadCrumbColor: {
        color: '#d8d8d8',
    },
    activeBdreadCrumbColor: {
        color: '#fff',
    }
}))

const CustomBreadCrumb = (props) => {

    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const { componentList, isDialog } = props;

    const handleRouting = (path) => {
        history.push(path);
    }

    const smallScreen = useMediaQuery(theme.breakpoints.down(889))

    return (
        !smallScreen ? <Breadcrumbs className={clsx((isDialog && classes.activeBdreadCrumbColor), classes.rootForCustomBreadCrumb)} separator="›" aria-label="breadcrumb">
            {componentList.map((component, index) => {
                return (
                    <div key={index}>
                        { ((index === 0) && (componentList.length !== index + 1)) && (
                            <Typography key={index} className={clsx(isDialog && classes.inActiveBdreadCrumbColor)}>
                                <Icon component={component.iconName} className={classes.icon} />
                                {component.name}
                            </Typography>)}
                        { ((componentList.length !== index + 1) && (index !== 0)) && (
                            <Link color="textPrimary" className={clsx(isDialog && classes.activeBdreadCrumbColor)} key={index} underline='hover' component="button" variant="body1"
                                onClick={(() => { handleRouting(component.path) })} >
                                <Icon component={component.iconName} className={classes.icon} />
                                {component.name}
                            </Link>)}
                        {(componentList.length === index + 1) && (<Typography key={index} color="textPrimary" className={clsx(isDialog && classes.activeBdreadCrumbColor)}><Icon component={component.iconName} className={classes.icon} />{component.name}</Typography>)}
                    </div>
                );
            })}
        </Breadcrumbs>
            :
            <Breadcrumbs className={clsx((isDialog && classes.activeBdreadCrumbColor), classes.rootForCustomBreadCrumb)} separator="›" aria-label="breadcrumb">
                {componentList.map((component, index) => {
                    return (
                        <div key={index}>
                            { ((index === 0) && (componentList.length !== index + 1)) && (
                                <Typography key={index} className={clsx(isDialog && classes.inActiveBdreadCrumbColor)}>
                                    <Icon component={component.iconName} className={clsx(isDialog && classes.iconForDialog, classes.icon)} />
                                </Typography>
                            )}
                            { ((componentList.length !== index + 1) && (index !== 0)) && (
                                <Link color="textPrimary" className={clsx(isDialog && classes.activeBdreadCrumbColor)} key={index} underline='hover' component="button" variant="body1"
                                    onClick={(() => { handleRouting(component.path) })} >
                                    <Icon component={component.iconName} className={clsx(isDialog && classes.iconForDialog, classes.icon)} />
                                </Link>)}
                            {(componentList.length === index + 1) && (
                                <Typography key={index} color="textPrimary" className={clsx(isDialog && classes.activeBdreadCrumbColor)}>
                                    <Icon component={component.iconName} className={clsx(isDialog && classes.iconForDialog, classes.icon)} />
                                </Typography>
                            )}
                        </div>
                    );
                })}
            </Breadcrumbs>
    );
}

CustomBreadCrumb.prototype = {
    componentList: PropTypes.object.isRequired,
    componentListForDialog: PropTypes.object.isRequired,
}

export default CustomBreadCrumb;