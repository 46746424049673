import React, { useEffect, useState } from 'react';
import {
    CssBaseline, InputLabel, FormControl,
    FormHelperText, Grid, TextField
} from '@mui/material';
import { Controller, } from 'react-hook-form';
import _ from "lodash/fp";
import { FormattedMessage } from 'react-intl';
import Switch from '@mui/material/Switch';
import useStyles from '../../../common/CommonStyle/CommonStyle'
import { useIntl } from "react-intl";
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';

function BasicDetails(props) {
    const classes = useStyles();
    const intl = useIntl();
    const [OrganizationValue, setOrganizationValue] = useState({ erpOrgCode: '', orgName: '' });
    const commonPatterns = GlobalEdiApiConstants();

    const { basicmode, basicReset, control, errors,
        defaultOrgObj, setDefaultOrgObj, setHasErrors,
        orgInfoOfLeggedUser } = props;

    const [EntityReadOnlyFields, setEntityReadOnlyFields] = useState({
        organization: false,
        entityName: false,
        erpEntityCode: false,
        active: false,
        reg1No: false,
        reg2No: false,
        reg3No: false,
    });

    const pageLabelsConstantsAndMessages = {
        label: {
            organization: 'organization',
            entityName: 'Entity Name',
            erpEntityCode: 'Entity Code',
            active: 'active',
        },
        name: {
            organization: 'organization',
            entityName: 'entityName',
            erpEntityCode: 'erpEntityCode',
            active: 'active',
            active: 'active',
            reg1No: 'reg1No',
            reg2No: 'reg2No',
            reg3No: 'reg3No'
        }
    }

    const [EntitytoBeEdited, setEntitytoBeEdited] = React.useState({});

    useEffect(() => {
        setEntitytoBeEdited(basicReset);
    }, [basicReset]);



    useEffect(() => {
        setHasErrors(errors)
    }, [errors])

    useEffect(() => {
        if (orgInfoOfLeggedUser.erpOrgCode && basicmode === 'create') {
            setDefaultOrgObj({ erpOrgCode: orgInfoOfLeggedUser.erpOrgCode, orgName: orgInfoOfLeggedUser.orgName, valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}` });
        }
    }, [orgInfoOfLeggedUser])

    useEffect(() => {
        if (basicmode === 'view' || basicmode === 'edit') {
            setEntityReadOnlyFields({ ...EntityReadOnlyFields, organization: true, entityName: true, erpEntityCode: true, active: true})
        }
    }, [basicmode, basicReset, defaultOrgObj]);

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} style={{ marginTop: '-1%' }}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.entityName}
                                    rules={{
                                        required: true,
                                        maxLength: 100,
                                        pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UCSHF
                                    }}

                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required
                                            variant="outlined"
                                            margin="normal"
                                            id="entityName"
                                            size='small'
                                            {...field}
                                            error={errors.entityName}
                                            label={<FormattedMessage id="common.EntityName.label" defaultMessage="Entity Name" />}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            disabled={basicmode === 'view' || basicmode === 'edit' ? true : false}
                                            InputProps={{
                                                readOnly: EntityReadOnlyFields.entityName,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                        />
                                    )}
                                />

                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.entityName}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="common.EntityName.label" defaultMessage="Entity Name" />}   {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.entityName}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="common.EntityName.label" defaultMessage="Entity Name" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UCSHF}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.entityName}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="common.EntityName.label" defaultMessage="Entity Name" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>

                                    )}
                                </div>

                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.erpEntityCode}
                                    rules={basicmode === 'view' ? { required: false } : {
                                        required: true,
                                        maxLength: 50,
                                        pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UHF
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required
                                            variant="outlined"
                                            margin="normal"
                                            id="erpEntityCode"
                                            size='small'
                                            {...field}
                                            error={errors.erpEntityCode}
                                            label={<FormattedMessage id="columnname.entitycode.label" defaultMessage="Entity Code" />}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            disabled={basicmode === 'view' || basicmode === 'edit' ? true : false}
                                            InputProps={{
                                                readOnly: EntityReadOnlyFields.erpEntityCode,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                        />
                                    )}
                                />

                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.erpEntityCode}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.entitycode.label" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.erpEntityCode}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.entitycode.label" defaultMessage="Entity Code" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.erpEntityCode}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>
                                            {<FormattedMessage id="columnname.entitycode.label" defaultMessage="Entity Code" />}  {<FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" defaultMessage="Org Code cannot exceed more than 50 chars" />}
                                        </FormHelperText>
                                    )}
                                </div>

                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <Grid container justifyContent='flex-start' alignItems='center'>
                                <Controller
                                    render={({
                                        field: { onChange } }) => (<Switch
                                            color='primary'
                                            checked={EntitytoBeEdited.active !== undefined ? EntitytoBeEdited.active : true}
                                            onChange={((event) => {
                                                onChange(!(EntitytoBeEdited.active !== undefined ? EntitytoBeEdited.active : true));
                                                setEntitytoBeEdited({ ...EntitytoBeEdited, active: event.target.checked });

                                            })}
                                            disabled={basicmode === 'view' ? true : false}
                                        />)}
                                    type="checkbox"
                                    name={pageLabelsConstantsAndMessages.name.active}
                                    control={control}
                                    defaultValue={EntitytoBeEdited.active !== undefined ? EntitytoBeEdited.active : true}
                                />
                                <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.reg1No}
                                    rules={{
                                        maxLength: 50,
                                        pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UHF
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="coRegNo"
                                            size='small'
                                            {...field}
                                            error={errors.reg1No}
                                            label={intl.formatMessage({ id: "column.coregno.label" })}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            disabled={basicmode === 'view' ? true : false}
                                            InputProps={{
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                        />
                                    )}
                                />
                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.erpEntityCode}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>{intl.formatMessage({ id: "column.coregno.label" })} {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.erpEntityCode}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>
                                            {intl.formatMessage({ id: "column.coregno.label" })}  {<FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" />}
                                        </FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.reg2No}
                                    rules={{
                                        maxLength: 50,
                                        pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UHF
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="gstRegNo"
                                            size='small'
                                            {...field}
                                            error={errors.reg2No}
                                            label={intl.formatMessage({ id: "column.gstregno.label" })}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            disabled={basicmode === 'view' ? true : false}
                                            InputProps={{
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                        />
                                    )}
                                />
                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.erpEntityCode}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>{intl.formatMessage({ id: "column.gstregno.label" })} {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.erpEntityCode}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>
                                            {intl.formatMessage({ id: "column.gstregno.label" })}  {<FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" />}
                                        </FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.reg3No}
                                    rules={{
                                        maxLength: 50,
                                        pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UHF
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="crRegNo"
                                            size='small'
                                            {...field}
                                            error={errors.reg3No}
                                            label={intl.formatMessage({ id: "column.crregno.label" })}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            disabled={basicmode === 'view' ? true : false}
                                            InputProps={{
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                        />
                                    )}
                                />
                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.erpEntityCode}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>{intl.formatMessage({ id: "column.crregno.label" })} {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.erpEntityCode}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>
                                            {intl.formatMessage({ id: "column.crregno.label" })}  {<FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" defaultMessage="Org Code cannot exceed more than 50 chars" />}
                                        </FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>
    );
};

export default BasicDetails;