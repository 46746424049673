import { Badge, Button, CssBaseline, Grid, IconButton, Paper, Snackbar, Tooltip, useMediaQuery } from '@mui/material';
import { Alert } from '@mui/lab';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import CustomBreadCrumb from '../common/CustomBreadCrumb';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import { GridPagination, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, DataGridPro, LicenseInfo, getGridStringOperators } from '@mui/x-data-grid-pro';
import { CommonService } from '../../services';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import saveAs from 'file-saver';
import useStyles from '../common/CommonStyle/CommonStyle'
import { FormattedMessage, useIntl } from 'react-intl';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import { SupportedLanguageDetails } from '../common/SupportedLanguageDetails/SupportedLanguageDetails';
import CommonGridPagination from '../common/CommonGridPagination/CommonGridPagination';
import IconViewRequest from '@mui/icons-material/ViewList';
import { ModuleAccessPermissionKey } from '../../Constants/ModuleAccessKey';
import { useTheme } from '@emotion/react';
import CommonBackDrop from '../common/BackDrop';
import ChipInfoList from '../common/ChipInfoList';
import CustomFilterComponent, { GetFilterableColumns } from '../common/CustomFilterComponent';
import { FilterList } from '@mui/icons-material';
import { CustomFilters } from '../common/CustomFilters/CustomFilters';
import { manageUserPreferences } from '../../services/manageUserPreferences';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';

const columnFieldsName = {
    requestNo: 'requestId',
    programName: 'programName',
    requestStatusForDisplay: 'requestStatusForDisplay',
    requestStatus: 'requestStatus',
    jobType: 'jobType',
    errorLog: 'errorLog',
    requestDt: 'requestDt',
    requestEndDt: 'requestEndDt',
    requestToDt: 'requestToDt',
    requestFromDt: 'requestFromDt',
    requestedBy: 'requestedBy',
    serverFilePath: 'serverFilePath',
    bpCode: 'bpCode',
    bpName: 'bpName',
};

const Report = () => {
    const classes = useStyles();
    const intl = useIntl();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density',
            downloadButtonTooltip: 'Download'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
        name: {
            name: 'name'
        }
    };
    const localtext = GridTextLocalization();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const currentUser = CommonService.getLoggedInUserInfo();
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const viewRequestModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.viewRequest];
    const [chipInfoList, setChipInfoList] = useState([]);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    const defaulSelectedFilters = [];
    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();

    const componentList = [
        {
            path: '/reports',
            name: <FormattedMessage id="viewrequest.menu.text" defaultMessage="View Request" />,
            iconName: IconViewRequest,
        }
    ];

    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState(preference.columnsPinned);
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);


    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }
    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        // else if (preferenceChangeIn === "order") {
        // }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }
    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(
            componentList[0].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[0].name.props.id,
                tabName: componentList[0].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);

    const columnForReport = [
        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: 130,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    {((cellParams.row.requestStatus !== 0) &&
                        (viewRequestModuleAccess !== undefined ? viewRequestModuleAccess.editFlag : false || viewRequestModuleAccess !== undefined ? viewRequestModuleAccess.createFlag : false)
                        && (cellParams.row.serverFilePath !== '') && (
                            <Tooltip title={<FormattedMessage id="common.download.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.downloadButtonTooltip} />} placement='bottom'>
                                <span><IconButton
                                    className={classes.customeToolbar}
                                    onClick={(() => {
                                        const splitFiles = cellParams.row.serverFilePath.split(';')
                                        if (splitFiles.length > 1) {
                                            showDownloadZipFilePopUp(cellParams.row.serverFilePath);
                                        } else {
                                            showDownloadPopUp(cellParams.row.serverFilePath);
                                        }
                                    })}
                                    size="large">
                                    <CloudDownloadIcon />
                                </IconButton>
                                </span>
                            </Tooltip>
                        ))}
                </div>
            )
        },
        {
            field: columnFieldsName.bpName,
            headerName: intl.formatMessage({ id: "columnName.bpname.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.bpCode,
            headerName: intl.formatMessage({ id: "common.textfield.columname.bpcode" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 130,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.requestNo,
            width: 190,
            headerName: intl.formatMessage({ id: "columnname.requestno.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },

        {
            field: columnFieldsName.programName,
            headerName: intl.formatMessage({ id: "columnname.requestname.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 350,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.jobType,
            headerName: intl.formatMessage({ id: "columnname.requesttype.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.requestStatus,
            headerName: intl.formatMessage({ id: "columnname.requeststatus.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.RequestStatusDisplayField
        },
        {
            field: columnFieldsName.errorLog,
            headerName: intl.formatMessage({ id: "columnname.errormessage.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 350,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.requestedBy,
            headerName: intl.formatMessage({ id: "columnname.requestedby.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.requestDt,
            headerName: intl.formatMessage({ id: "columnname.startdate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 240,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.requestDtDisp}</span>)
        },

        {
            field: columnFieldsName.requestEndDt,
            headerName: intl.formatMessage({ id: "columnname.completedDt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 240,
            sortable: true,
            type: 'date',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.requestEndDtDisp}</span>)
        },

        {
            field: columnFieldsName.requestToDt,
            headerName: intl.formatMessage({ id: "columnname.requestToDt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 0,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },

        {
            field: columnFieldsName.requestFromDt,
            headerName: intl.formatMessage({ id: "columnname.requestFromDt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 0,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,

        },
    ]

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[0].name.props.id,
                tabName: componentList[0].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(columnForReport);
                savePreference("visibility", columnForReport);
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, columnForReport);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let programName = '';
                let bpCode = '';
                let bpName = '';
                let jobType = '';
                let requestStatus = '';
                let requestToDt = '';
                let requestFromDt = '';
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'programName':
                            programName = columns;
                            break;
                        case 'bpCode':
                            bpCode = columns
                            break;
                        case 'bpName':
                            bpName = columns
                            break;
                        case 'jobType':
                            jobType = columns;
                            break;
                        case 'requestStatus':
                            requestStatus = columns;
                            break;
                        case 'requestToDt':
                            requestToDt = columns;
                            break;
                        case 'requestFromDt':
                            requestFromDt = columns;
                            break;
                    }
                })
                var filterBody = {
                    programName: programName.value,
                    bpCode: bpCode.value,
                    bpName: bpName.value,
                    jobType: jobType.value,
                    requestStatus: requestStatus.value === 'PROCESSING' ? 0 : requestStatus.value === 'COMPLETED' ? 1 : requestStatus.value === 'FAILED' ? 2 : requestStatus.value === 'DATA ERROR' ? 3 : requestStatus.value === 'JOB TERMINATED' ? 4 : requestStatus.value === 'PARTIAL-ERROR' ? 5 : '',
                    recordRequestStatus: requestStatus.value === '' ? 'All' : requestStatus.value === 'PROCESSING' ? 0 : requestStatus.value === 'COMPLETED' ? 1 : requestStatus.value === 'FAILED' ? 2 : requestStatus.value === 'DATA ERROR' ? 3 : requestStatus.value === 'JOB TERMINATED' ? 4 : requestStatus.value === 'PARTIAL-ERROR' ? 5 : 'All',
                    usrId: currentUser !== undefined && currentUser !== null ? currentUser.id : 0,
                    recordImportandExport: "All",
                    requestToDt: requestToDt.value,
                    requestFromDt: requestFromDt.value
                };
                paramBody.body = filterBody;
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [selectedLanguage])

    const [tableData, setTableData] = useState([]);

    const [paramBody, setParamBody] = useState({
        body: {
            usrId: currentUser !== undefined && currentUser !== null ? currentUser.id : 0,
            programName: '',
            bpCode: '',
            bpName: '',
            recordImportandExport: 'All',
            applyStatusValidator: false,
            requestStatus: '',
            jobType: '',
            requestToDt: '',
            recordRequestStatus: 'All',
        },
        pageNo: 0,
        sortDir: 'desc',
        sortKey: 'requestDt',
        recordsPerPage: 25
    });

    useEffect(() => {
        if (intialParamBodyApiCall) {
        setTableLoadingStatus(true);
        setTableData([]);
        CommonService.GetListApi('/requestJobs', paramBody)
            .then((response) => {
                setTableLoadingStatus(false);
                setTotalRowCount(response.data.totalElements);
                setTableData(response.data.content);
                (response.data.totalElements > 0 ?
                    setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                    :
                    setChipInfoList([]));
            })
            .catch(function (error) {
                setTableLoadingStatus(false);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
        }

    }, [paramBody, refreshTable])

    const showDownloadPopUp = (filePath) => {
        setOpenBackDrop(true);
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
                setOpenBackDrop(false);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
                setOpenBackDrop(false);
            })
    }

    const showDownloadZipFilePopUp = (filePath) => {
        setOpenBackDrop(true);
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        var fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        const current = new Date();
        const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}_${current.getHours()}_${current.getMinutes()}_${current.getSeconds()}`;

        if (fileName.includes("PO_REVISION")) {
            fileName = "PO_REVISION_LETTER_PDF_" + date;
        } else {
            fileName = "PO_CANCELLATION_LETTER_PDF_ " + date;
        }

        fetch(`${process.env.REACT_APP_API_URL}/zip-download?MultiplefileName=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
                setOpenBackDrop(false);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
                setOpenBackDrop(false);
            })
    }


    if (columns.length > 0) {
        const programNameColumn = columns.find((column) => column.field === columnFieldsName.programName);
        const programNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.programName);

        const programNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.ProgramNameInputValue,
            }),
        );

        columns[programNameColIndex] = {
            ...programNameColumn,
            filterOperators: programNameFilterOperators,
        };

        const bpNameColumn = columns.find((column) => column.field === columnFieldsName.bpName);
        const bpNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.bpName);

        const bpNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.BPMasterInputValue,
            }),
        );

        columns[bpNameColIndex] = {
            ...bpNameColumn,
            filterOperators: bpNameFilterOperators,
        };

        const jobTypeColumn = columns.find((column) => column.field === columnFieldsName.jobType);
        const jobTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.jobType);

        const jobTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.JobTypeInputValue,
            }),
        );

        columns[jobTypeColIndex] = {
            ...jobTypeColumn,
            filterOperators: jobTypeFilterOperators,
        };

        const requestStatusColumn = columns.find((column) => column.field === columnFieldsName.requestStatus);
        const requestStatusColIndex = columns.findIndex((col) => col.field === columnFieldsName.requestStatus);

        const requestStatusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.RequestStatusInputValue,
            }),
        );

        columns[requestStatusColIndex] = {
            ...requestStatusColumn,
            filterOperators: requestStatusFilterOperators,
        };

        const requestDtColumn = columns.find((column) => column.field === columnFieldsName.requestToDt);
        const requestDtColIndex = columns.findIndex((col) => col.field === columnFieldsName.requestToDt);

        const requestDtFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[requestDtColIndex] = {
            ...requestDtColumn,
            filterOperators: requestDtFilterOperators,
        };

        const requestFromDtColumn = columns.find((column) => column.field === columnFieldsName.requestFromDt);
        const requestFromDtColIndex = columns.findIndex((col) => col.field === columnFieldsName.requestFromDt);

        const requestFromDtFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[requestFromDtColIndex] = {
            ...requestFromDtColumn,
            filterOperators: requestFromDtFilterOperators,
        };
    }
    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let programName = '';
        let bpCode = '';
        let bpName = '';
        let jobType = '';
        let requestStatus = '';
        let requestToDt = '';
        let requestFromDt = '';
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'programName':
                    programName = columns;
                    break;
                case 'bpCode':
                    bpCode = columns
                    break;
                case 'bpName':
                    bpName = columns
                    break;
                case 'jobType':
                    jobType = columns;
                    break;
                case 'requestStatus':
                    requestStatus = columns;
                    break;
                case 'requestToDt':
                    requestToDt = columns;
                    break;
                case 'requestFromDt':
                    requestFromDt = columns;
                    break;
            }
        })

        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);

        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    programName: programName.value,
                    bpCode: bpCode.value,
                    bpName: bpName.value,
                    jobType: jobType.value,
                    requestStatus: requestStatus.value === 'PROCESSING' ? 0 : requestStatus.value === 'COMPLETED' ? 1 : requestStatus.value === 'FAILED' ? 2 : requestStatus.value === 'DATA ERROR' ? 3 : requestStatus.value === 'JOB TERMINATED' ? 4 : requestStatus.value === 'PARTIAL-ERROR' ? 5 : '',
                    recordRequestStatus: requestStatus.value === '' ? 'All' : requestStatus.value === 'PROCESSING' ? 0 : requestStatus.value === 'COMPLETED' ? 1 : requestStatus.value === 'FAILED' ? 2 : requestStatus.value === 'DATA ERROR' ? 3 : requestStatus.value === 'JOB TERMINATED' ? 4 : requestStatus.value === 'PARTIAL-ERROR' ? 5 : 'All',
                    usrId: currentUser !== undefined && currentUser !== null ? currentUser.id : 0,
                    recordImportandExport: "All",
                    requestToDt: requestToDt.value,
                    requestFromDt: requestFromDt.value
                }
            }
        })

    }

    useEffect(() => {
        if (intialParamBodyApiCall)
        setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForReport}>
                <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={12} className={classes.topGridForReport}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <ChipInfoList chipInfoList={chipInfoList} />
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <Paper elevation={3} className={classes.gridHeightAndWidthForRepAndOrg}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            hideFooter={true}
                            density="compact"
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                            hideFooterSelectedRowCount={true}
                            disableColumnFilter={true}
                            localeText={localtext}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}

                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            // onSortModelChange={(params) => {
                            //     let sortModel = params.sortModel[0];
                            //     if (sortModel !== undefined) {
                            //         setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                            //     }
                            // }}


                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                              
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}  >
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'><GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterList />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                }
                            }}
                        />
                    </div>
                </Paper>
            </Grid>
        </React.Fragment>
    );
}

export default Report;