
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import useStyles from '../common/CommonStyle/CommonStyle';
import { DataGridPro, getGridDateOperators, getGridStringOperators, GridFilterPanel, GridToolbarColumnsButton, GridToolbarDensitySelector, LicenseInfo } from '@mui/x-data-grid-pro';
import { SupportedLanguageDetails } from '../common/SupportedLanguageDetails/SupportedLanguageDetails';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import { ModuleAccessPermissionKey } from '../../Constants/ModuleAccessKey';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import { Badge, Button, Chip, CircularProgress, CssBaseline, Grid, IconButton, Menu, MenuItem, MenuList, Paper, Snackbar, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Alert } from '@mui/lab';
import CommonGridPagination from '../common/CommonGridPagination/CommonGridPagination';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CommonService } from '../../services';
import { CustomFilters } from '../common/CustomFilters/CustomFilters';
import IconApproval from '@mui/icons-material/LibraryAddCheck';
import CancelIcon from '@mui/icons-material/Cancel';
import ViewIcon from '@mui/icons-material/Visibility';
import CommonBackDrop from '../common/BackDrop';
import ExportIcon from '@mui/icons-material/Publish';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CustomReportIcon from '@mui/icons-material/Archive';
import CommonReportDownloadForMsg from '../common/CommonReportDownloadForMsg';
import FileUploadDialog from '../common/FileUploadDialog';
import { CheckCircle, FilterList } from '@mui/icons-material';
import CustomFilterComponent, { GetFilterableColumns } from '../common/CustomFilterComponent';
import { manageUserPreferences } from '../../services/manageUserPreferences';
import IconMessage from '@mui/icons-material/Forum';
import IconForecast from '@mui/icons-material/TrendingUp';
import ChipInfoList from '../common/ChipInfoList';
import CustomBreadCrumb from '../common/CustomBreadCrumb';
import TabIcon from '@mui/icons-material/Tab';

const columnFieldsName = {
    erpOrgCode: 'organization.erpOrgCode',
    erpEntityCode: 'entity.erpEntityCode',
    orgName: 'organization.orgName',
    entityName: 'entity.entityName',
    bpCode: 'bpCode',
    bpType: 'bpType',
    msgId: 'msgId',
    testInd: 'testInd',
    poNo: 'poNo',
    poNoRef: 'poNoRef',
    poDate: 'poDate',
    poScheduleType: 'poScheduleType',
    buyerPartyCode: 'buyerPartyCode',
    latestDataSource: 'latestDataSource',
    buyerName: 'buyerName',
    buyerAddress: 'buyerAddress',
    buyerCity: 'buyerCity',
    buyerState: 'buyerState',
    buyerCountry: 'buyerCountry',
    buyerPostalCode: 'buyerPostalCode',
    buyerTelNo: 'buyerTelNo',
    buyerFaxNo: 'buyerFaxNo',
    buyerEmail: 'buyerEmail',
    supplierPartyCode: 'supplierPartyCode',
    supplierName: 'supplierName',
    supplierAddress: 'supplierAddress',
    supplierCity: 'supplierCity',
    supplierState: 'supplierState',
    supplierCountry: 'supplierCountry',
    supplierPostalCode: 'supplierPostalCode',
    supplierTelNo: 'supplierTelNo',
    supplierFaxNo: 'supplierFaxNo',
    supplierEmail: 'supplierEmail',
    currencyCode: 'currencyCode',
    supplierVatNum: 'supplierVatNum',
    approved: 'approved',
    approvedBy: 'approvedBy',
    approvedDate: 'approvedDate',
    msgCreatedDt: 'msgCreatedDt',
    deliveryRemarks: 'deliveryRemarks',
    msgStatusFlag: 'msgStatusFlag',
    msgStatusRemarks: 'msgStatusRemarks',
    note: 'note',
    lastMessageSentMode: 'lastMessageSentMode',
    lastMessageSentTime: 'lastMessageSentTime',
    attribute1: 'attribute1',
    attribute2: 'attribute2',
    attribute3: 'attribute3',
    attribute4: 'attribute4',
    attribute5: 'attribute5',
    attribute6: 'attribute6',
    attribute7: 'attribute7',
    attribute8: 'attribute8',
    attribute9: 'attribute9',
    attribute10: 'attribute10',
    apprEmailSent: 'apprEmailSent',
    companyCode: 'companyCode',
    plantCode: 'plantCode',
    createdBy: 'createdBy',
    createdTime: 'createdTime',
    updatedBy: 'updatedBy',
    updatedTime: 'updatedTime',
    fromMsgSentTime: 'fromMsgSentTime',
    toMsgSentTime: 'toMsgSentTime',
    poFromDate: 'poFromDate',
    poToDate: 'poToDate',
    poStatus: 'poStatus',
    dlvyCfmStatus: 'dlvyCfmStatus',
    incoterms: 'incoterms',
    purchasingGroup: 'purchasingGroup',
    paymentTerms: 'paymentTerms',
    createdById: 'createdById',
    amendmentCount: 'amendmentCount',
    cancellationCount: 'cancellationCount',
    fromUpdatedDate: 'fromUpdatedDate',
    toUpdatedDate: 'toUpdatedDate',
    wbsCode: 'wbsCode',
    projectName: 'projectName',
    deljitFlag: 'deljitFlag',
};

const NoChip = withStyles((theme) => ({
    root: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        width: '60%',
        //flexGrow: 1,   
    },
}))(Chip);

const YesChip = withStyles((theme) => ({
    root: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        width: '60%',
    },
}))(Chip);

function PurchaseForecastOutbound(props) {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const { ispurchaseforecast, isBusinessPartnerPortal, tabDetails } = props;
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const currentUser = CommonService.getLoggedInUserInfo();
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const poPfOutboundModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.outboundPurchaseForecast];
    const purchaseForecastBPModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.purchaseForecastBP];

    const pageLabelsConstantsAndMessages = {
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
    }

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });

    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const [refreshTable, setRefreshTable] = useState(false);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isRowSentForApproval, setIsRowSentForApproval] = useState(false);
    const [selectedRow, setSelectedRow] = React.useState([]);
    const [disableApproveButton, isDisableApproveButton] = useState(true);
    const [disableRejectionButton, isDisableRejectionButton] = useState(true);
    const [disiablingDownloadButtonForZeroRecords, setDisiablingDownloadButtonForZeroRecords] = useState(false);
    const [keyValueData, setKeyValueData] = useState({});
    var defaultEntity = loggedInUserInfo.defaultEntity;
    const [searchedEntityCode, setSearchedEntityCode] = useState(defaultEntity);
    const [searchedBpCode, setSearchedBpCode] = useState('');
    const [searchedCommType, setSearchedCommType] = useState('');
    const [searchedPoNo, setSearchedPoNo] = useState('');
    const [searchedStatusFlag, setSearchedStatusFlag] = useState('');
    const [searchedBuyerName, setSearchedBuyerName] = useState('');
    const [searchedSupplierName, setSearchedSupplierName] = useState('');
    const [searchedOrderCreatedBy, setSearchedOrderCreatedBy] = useState('');
    const [searchedPoFromDate, setSearchedPoFromDate] = useState('');
    const [searchedPoToDate, setSearchedPoToDate] = useState('');
    const [searchedMsgSentFromDate, setSeachedMessageSentFromDate] = useState('');
    const [searchedMsgSentToDate, setSeachedMessageSentToDate] = useState('');
    const [searchedAmendedValue, setSearchedAmendedValue] = useState('');
    const [searchedCancelledValue, setSearchedCancelledValue] = useState('');
    const [searchedDataSourceValue, setSearchedDataSourceValue] = useState('');
    const [searchedApprovedRecordsRecords, setSearchedApprovedRecordsRecords] = useState(null);
    const [isDlvScheduleForSapExportXlRequested, setDlvScheduleForSapExportXlRequested] = useState(false);
    const [isExportXlRequested, setExportXlRequested] = useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const anchorRef = React.useRef(null);
    const [searchedPoStatus, setSearchedPoStatus] = useState('');
    const [searchedDeliveryConfirmed, setSearchedDeliveryConfirmed] = useState(null);
    const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
    const [searchedUpdatedFromTime, setSearchedUpdatedFromTime] = useState(null);
    const [searchedUpdatedToTime, setSearchedUpdatedToTime] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);
    const [searchedWbsCode, setSearchedWbsCode] = useState('');
    const [searchedWbsName, setSearchedWbsName] = useState('');
    const [chipInfoList, setChipInfoList] = useState([]);
    const[searchedDeljitCount,setSearchedDeljitValue] = useState('');

    const userAccessBpCode = currentUser.userSupplierAccessList === null ? null : currentUser.userSupplierAccessList[0].supplier.bpCode;
    const userAccessBpName = currentUser.userSupplierAccessList === null ? null : currentUser.userSupplierAccessList[0].supplier.bpSupplierName;

    const dateValue = new Date();

    const [paramBody, setParamBody] = useState(
        {
            body: {
                entity: {
                    erpEntityCode: defaultEntity,
                    entityName: ''
                },
                approved: isBusinessPartnerPortal ? 1 : null,
                poScheduleType: "4",
                recordStatusFlag: 'All',
                recordApprovedStatus: isBusinessPartnerPortal ? 1 : 'All',
                recordCancellationCount: 'All',
                recordAmendmentCount: 'All',
                fromUpdatedDate: isBusinessPartnerPortal ? null : new Date(dateValue.setDate(dateValue.getDate() - 7)).toISOString().slice(0, 10),
                poFromDate: isBusinessPartnerPortal ? new Date(dateValue.setDate(dateValue.getDate() - 7)).toISOString().slice(0, 10) : null,
                recordDeljitFlg: 'All',
            },
            pageNo: 0,
            sortDir: 'desc',
            sortKey: 'updatedTime',
            recordsPerPage: 25,
        }
    );

    const breadCrumbForOrdersElement =
    {
        path: '/orders',
        name: <FormattedMessage id="order.breadcrumb.text" defaultMessage='Orders' />,
        iconName: TabIcon,
    };

    const breadCrumbForPoForecastElementForBP = {
        path: undefined,
        name: <FormattedMessage id="purchaseforecast.module.text" defaultMessage='Purchase Forecast' />,
        iconName: IconForecast,
    };

    const breadCrumbForMessageElement =
    {
        path: undefined,
        name: <FormattedMessage id="common.menu.breadcrumb.message" defaultMessage='Purchase Forecast' />,
        iconName: IconMessage,
    };
    const breadCrumbForPurchaseForecastElement =
    {
        path: '/purchaseForecastOutbound',
        name: <FormattedMessage id="common.module.breadcrumb.purchaseforecastoutbound" defaultMessage='Purchase Forecast' />,
        iconName: IconForecast,
    };

    const [componentList, setComponentList] = useState((() => {
        let constructedBaseBreadCrumb = [];
        if (!isBusinessPartnerPortal) {
            constructedBaseBreadCrumb = [breadCrumbForMessageElement, breadCrumbForPurchaseForecastElement,
            ]
            return constructedBaseBreadCrumb;
        } else if (isBusinessPartnerPortal) {
            constructedBaseBreadCrumb = [breadCrumbForOrdersElement,
                breadCrumbForPoForecastElementForBP]
            return constructedBaseBreadCrumb;
        }
    }));

    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleCloseForFilter = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);

    const defaultFilterForGlobalEdiWithoutEntityFilter = [
        { columnField: columnFieldsName.fromUpdatedDate, headerName: 'Updated From Date', id: 0, value: new Date(dateValue.setDate(dateValue.getDate() - 0)).toISOString().slice(0, 10) },
    ];

    const defaultFilterForBpPODateWithoutEntityFilter = [
        { columnField: columnFieldsName.poFromDate, headerName: 'Purchase Forecast from Date', id: 0, value: new Date(dateValue.setDate(dateValue.getDate() - 0)).toISOString().slice(0, 10) },
    ];

    const defaultFilterForBpPRDateWithEntityFilter = [
        { columnField: columnFieldsName.erpEntityCode, headerName: 'Ent. Code', id: 0, value: defaultEntity },
        { columnField: columnFieldsName.poFromDate, headerName: 'Purchase Forecast from Date', id: 1, value: new Date(dateValue.setDate(dateValue.getDate() - 0)).toISOString().slice(0, 10) }
    ];

    const defaultFilterForGlobalEdi = [
        { columnField: columnFieldsName.fromUpdatedDate, headerName: 'Updated From Date', id: 0, value: new Date(dateValue.setDate(dateValue.getDate() - 0)).toISOString().slice(0, 10) },
        { columnField: columnFieldsName.erpEntityCode, headerName: 'Ent. Code', id: 1, value: defaultEntity }
    ];

    const defaultFilterOptionswithDefaultEntityFilter = isBusinessPartnerPortal ? defaultFilterForBpPRDateWithEntityFilter : defaultFilterForGlobalEdi;

    const defaultFilterOptionswithoutDefaultEntityFilter = isBusinessPartnerPortal ? defaultFilterForBpPODateWithoutEntityFilter : defaultFilterForGlobalEdiWithoutEntityFilter;

    const defaulSelectedFilters = defaultEntity === '' ? defaultFilterOptionswithoutDefaultEntityFilter : defaultFilterOptionswithDefaultEntityFilter;

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);

    const [openReportDownloadDialog, setOpenReportDownloadDialog] = useState(false);
    const [proccessValue, setProcessValue] = useState('');
    const [bpCodeValue, setBpCodeValue] = useState('');
    const [entityValue, setEntityValue] = useState('');
    const [messageIdvalue, setMessageIdValue] = useState('');

    const closeReportDownloadDialog = (confirmed) => {
        setOpenReportDownloadDialog(false);
    }

    const closeFileUploadDialog = (confirmed) => {
        setOpenFileUploadDialog(false);
    }

    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState({
        left: ["__check__"],
        right: []
    });
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }

    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);


    const commonColumnForPOPF = [
        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: isBusinessPartnerPortal ? 100 : 120,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    {(poPfOutboundModuleAccess !== undefined ? poPfOutboundModuleAccess.readFlag && !(poPfOutboundModuleAccess.editFlag || poPfOutboundModuleAccess.createFlag) : false) && (
                        <Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                let msgId = cellParams.row.msgId;
                                history.push(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.pfOutbound}/${msgId}`,
                                    {
                                        mode: 'view', msgId: msgId,
                                        isBusinessPartnerPortal: isBusinessPartnerPortal, ispurchaseforecast: ispurchaseforecast, userAccessBpCode: userAccessBpCode,
                                        userAccessBpName: userAccessBpName,
                                        defaultEntity: defaultEntity, defaultBpType: "SUPPLIER"
                                    });
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <ViewIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {(poPfOutboundModuleAccess !== undefined ? poPfOutboundModuleAccess.readFlag : false) && (!isBusinessPartnerPortal) && (
                        <Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                let msgId = cellParams.row.msgId;
                                history.push(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.pfOutbound}/${msgId}`, {
                                    mode: 'view', msgId: msgId,
                                    isBusinessPartnerPortal: isBusinessPartnerPortal, ispurchaseforecast: ispurchaseforecast, userAccessBpCode: userAccessBpCode, userAccessBpName: userAccessBpName, defaultEntity: defaultEntity, defaultBpType: "SUPPLIER"
                                });
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <ViewIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                    {(purchaseForecastBPModuleAccess !== undefined ? purchaseForecastBPModuleAccess.readFlag : false) && (isBusinessPartnerPortal) && (
                        <Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                let msgId = cellParams.row.msgId;
                                history.push(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.pfOutbound}/${msgId}`, {
                                    mode: 'view', msgId: msgId,
                                    isBusinessPartnerPortal: isBusinessPartnerPortal, ispurchaseforecast: ispurchaseforecast, userAccessBpCode: userAccessBpCode, userAccessBpName: userAccessBpName, defaultEntity: defaultEntity, defaultBpType: "SUPPLIER"
                                });
                            })}>
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <ViewIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                </div >
            )
        },
        {
            field: columnFieldsName.erpEntityCode,
            headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 85,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: true,
            valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.bpCode,
            headerName: intl.formatMessage({ id: "common.textfield.columname.bpcode" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 130,
            sortable: true,
            type: 'string',
            hide: isBusinessPartnerPortal ? true : false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.supplierName,
            width: 300,
            headerName: intl.formatMessage({ id: "columnName.bpname.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: isBusinessPartnerPortal ? true : false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.poNo,
            headerName: intl.formatMessage({ id: "columnname.prno.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 220,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.wbsCode,
            headerName: intl.formatMessage({ id: "common.textfield.column.project" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 90,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.projectName,
            headerName: intl.formatMessage({ id: "common.textfield.column.projectName" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 110,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.poNoRef,
            headerName: intl.formatMessage({ id: "columnname.prnoref.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 250,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.poDate,
            headerName: intl.formatMessage({ id: "columnname.prdate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 240,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.poDateDisp}</span>)
        },
        {
            field: columnFieldsName.poScheduleType,
            headerName: intl.formatMessage({ id: "columnname.poscheduletype.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 190,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: ((cellParams) => {
                if (cellParams.row.poScheduleType === "4") {
                    return 'FORECAST'
                }
            })
        },
        {
            field: columnFieldsName.latestDataSource,
            width: 170,
            headerName: intl.formatMessage({ id: "columnname.latestDataSource.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.buyerName,
            width: 170,
            headerName: intl.formatMessage({ id: "columnname.buyersName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.buyerAddress,
            width: 170,
            headerName: intl.formatMessage({ id: "columnname.buyerAddress.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.buyerCity,
            width: 150,
            headerName: intl.formatMessage({ id: "columnname.buyerCity.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.buyerState,
            width: 150,
            headerName: intl.formatMessage({ id: "columnname.buyerState.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.buyerCountry,
            width: 165,
            headerName: intl.formatMessage({ id: "columnname.buyerCountry.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.buyerPostalCode,
            width: 190,
            headerName: intl.formatMessage({ id: "columnname.buyerPostalCode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.buyerTelNo,
            width: 180,
            headerName: intl.formatMessage({ id: "columnname.buyerTelNo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.buyerFaxNo,
            width: 180,
            headerName: intl.formatMessage({ id: "columnname.buyerFaxNo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.buyerEmail,
            width: 180,
            headerName: intl.formatMessage({ id: "columnname.buyerEmail.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.supplierAddress,
            width: 185,
            headerName: intl.formatMessage({ id: "columnname.supplierAddress.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.supplierCity,
            width: 160,
            headerName: intl.formatMessage({ id: "columnname.supplierCity.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.supplierState,
            width: 160,
            headerName: intl.formatMessage({ id: "columnname.supplierState.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.supplierCountry,
            width: 180,
            headerName: intl.formatMessage({ id: "columnname.supplierCountry.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.supplierPostalCode,
            width: 220,
            headerName: intl.formatMessage({ id: "columnname.supplierPostalCode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.supplierTelNo,
            width: 180,
            headerName: intl.formatMessage({ id: "columnname.supplierTelNo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.supplierFaxNo,
            width: 180,
            headerName: intl.formatMessage({ id: "columnname.supplierFaxNo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.supplierEmail,
            width: 180,
            headerName: intl.formatMessage({ id: "columnname.supplierEmail.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.currencyCode,
            width: 170,
            headerName: intl.formatMessage({ id: "columnname.curCd.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: isBusinessPartnerPortal ? true : false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.supplierVatNum,
            width: 170,
            headerName: intl.formatMessage({ id: "columnname.supplierVatNumber.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.note,
            headerName: intl.formatMessage({ id: "columnname.note.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 140,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.incoterms,
            headerName: intl.formatMessage({ id: "columnname.incoTerms.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 140,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.purchasingGroup,
            headerName: intl.formatMessage({ id: "columnname.purchasingGroup.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 185,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.paymentTerms,
            headerName: intl.formatMessage({ id: "columnname.paymentTerms.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 170,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.companyCode,
            headerName: intl.formatMessage({ id: "columnname.companycode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.plantCode,
            headerName: intl.formatMessage({ id: "columnname.plantcode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.updatedBy,
            headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 155,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.updatedTime,
            headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.updatedTimeDisp}</span>)
        },
        {
            field: columnFieldsName.createdTime,
            headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.createdTimeDisp}</span>)
        },
        {
            field: columnFieldsName.attribute1,
            headerName: intl.formatMessage({ id: "columnname.attribute1.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.attribute2,
            headerName: intl.formatMessage({ id: "columnname.attribute2.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.attribute3,
            headerName: intl.formatMessage({ id: "columnname.attribute3.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.attribute4,
            headerName: intl.formatMessage({ id: "columnname.attribute4.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.attribute5,
            headerName: intl.formatMessage({ id: "columnname.attribute5.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.attribute6,
            headerName: intl.formatMessage({ id: "columnname.attribute6.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.attribute7,
            headerName: intl.formatMessage({ id: "columnname.attribute7.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.attribute8,
            headerName: intl.formatMessage({ id: "columnname.attribute8.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.attribute9,
            headerName: intl.formatMessage({ id: "columnname.attribute9.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.attribute10,
            headerName: intl.formatMessage({ id: "columnname.attribute10.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.poFromDate,
            headerName: intl.formatMessage({ id: "columnname.pffromdate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 0,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.poToDate,
            headerName: intl.formatMessage({ id: "columnname.pftodate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 0,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.fromUpdatedDate,
            headerName: intl.formatMessage({ id: "columnname.updatedFromDate.text" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 0,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.toUpdatedDate,
            headerName: intl.formatMessage({ id: "columnname.updatedToDate.text" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 0,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
    ];

    const columnsForPOPFApproved = {
        field: columnFieldsName.approved,
        headerName: intl.formatMessage({ id: "columnName.approved.label" }),
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        width: 150,
        sortable: true,
        type: 'string',
        hide: false,
        filterable: true,
        renderHeader: CommonService.customRenderHeaderColumns,
        renderCell: CommonService.ApproveFailureChipsForPO
    };

    const columnsForPOPFApprovedBy = {
        field: columnFieldsName.approvedBy,
        width: 155,
        headerName: intl.formatMessage({ id: "columnName.approvedBy.label" }),
        sortable: true,
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        type: 'string',
        hide: true,
        filterable: false,
        renderHeader: CommonService.customRenderHeaderColumns,
    };

    const columnsForPOPFApprovedDate = {
        field: columnFieldsName.approvedDate,
        width: 170,
        headerName: intl.formatMessage({ id: "columnName.approvedDate.label" }),
        sortable: true,
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        type: 'dateTime',
        hide: true,
        filterable: false,
        renderHeader: CommonService.customRenderHeaderColumns,
        renderCell: (cellParams) => (<span>{cellParams.row.approvedDateDisp}</span>)
    };

    const columnsForPOPFApprEmailSent = {
        field: columnFieldsName.apprEmailSent,
        width: 170,
        headerName: intl.formatMessage({ id: "columnName.emailSent.label" }),
        sortable: true,
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
        type: 'string',
        hide: true,
        filterable: false,
        renderHeader: CommonService.customRenderHeaderColumns,
        renderCell: CommonService.customRenderStatusColumnForApprEmailSent
    };

    const columnsForPOPFStatusFlag = {
        field: columnFieldsName.msgStatusFlag,
        width: 180,
        headerName: intl.formatMessage({ id: "columnName.msgSentStatus.label" }),
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        sortable: true,
        type: 'string',
        hide: false,
        filterable: true,
        renderHeader: CommonService.customRenderHeaderColumns,
        renderCell: CommonService.PoStatusForOutChips
    };

    const columnsForPOPFMsgStatusRemarks = {
        field: columnFieldsName.msgStatusRemarks,
        width: 245,
        headerName: intl.formatMessage({ id: "columnName.msgSentStatusRemarks.label" }),
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        sortable: true,
        type: 'string',
        hide: true,
        filterable: false,
        renderHeader: CommonService.customRenderHeaderColumns,
    };

    const columnsForPOPFMessageSentMode = {
        field: columnFieldsName.lastMessageSentMode,
        headerName: intl.formatMessage({ id: "columnname.lastMessageSentMode.label" }),
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        width: 180,
        sortable: true,
        type: 'string',
        hide: true,
        filterable: true,
        renderHeader: CommonService.customRenderHeaderColumns
    };

    const columnsForPOPFLastMessageSentTime = {
        field: columnFieldsName.lastMessageSentTime,
        headerName: intl.formatMessage({ id: "columnname.lastMessageSentTime.label" }),
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        width: 190,
        sortable: true,
        type: 'string',
        hide: true,
        filterable: false,
        renderHeader: CommonService.customRenderHeaderColumns,
        renderCell: (cellParams) => (<span>{cellParams.row.msgSentTimeDisp}</span>)
    };

    const columnForPurchaseOrderMsgSentFromTime = {
        field: columnFieldsName.fromMsgSentTime,
        headerName: intl.formatMessage({ id: "columnname.frommessagesentdate.label" }),
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        width: 150,
        sortable: false,
        type: 'dateTime',
        hide: true,
        filterable: true,
        renderHeader: CommonService.customRenderHeaderColumns
    };

    const columnForPurchaseOrderMsgSentToTime = {
        field: columnFieldsName.toMsgSentTime,
        headerName: intl.formatMessage({ id: "columnname.tomessagesentdate.label" }),
        headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
        width: 150,
        sortable: false,
        type: 'dateTime',
        hide: true,
        filterable: true,
        renderHeader: CommonService.customRenderHeaderColumns
    };

    const insert = (arr, index, newItems) => [
        ...arr.slice(0, index),
        newItems,
        ...arr.slice(index)
    ]


    function constructColumnsToBeDisplayedForEdiPF() {
        var commonColumnWithPOPFEdiAndBp = [];
        const commonColumnWithPoPfEdiApproved = insert(commonColumnForPOPF, 10, columnsForPOPFApproved)
        const commonColumnWithPoPfEdiApprovedBy = insert(commonColumnWithPoPfEdiApproved, 11, columnsForPOPFApprovedBy)
        const commonColumnWithPoPfEdiApprovedDt = insert(commonColumnWithPoPfEdiApprovedBy, 12, columnsForPOPFApprovedDate)
        const commonColumnWithPoPfEdiAppEmailSent = insert(commonColumnWithPoPfEdiApprovedDt, 13, columnsForPOPFApprEmailSent)
        const commonColumnWithPoPfEdiStatusFlag = insert(commonColumnWithPoPfEdiAppEmailSent, 14, columnsForPOPFStatusFlag)
        const commonColumnWithPoPfEdiMsgStatusRemarks = insert(commonColumnWithPoPfEdiStatusFlag, 15, columnsForPOPFMsgStatusRemarks)
        const commonColumnWithPoPfEdiMsgSentMode = insert(commonColumnWithPoPfEdiMsgStatusRemarks, 16, columnsForPOPFMessageSentMode)
        const commonColumnWithPoPfEdiMsgSentTime = insert(commonColumnWithPoPfEdiMsgSentMode, 17, columnsForPOPFLastMessageSentTime)
        const commonColumnWithPoPfEdiMsgSentFromTime = insert(commonColumnWithPoPfEdiMsgSentTime, 58, columnForPurchaseOrderMsgSentFromTime)
        commonColumnWithPOPFEdiAndBp = insert(commonColumnWithPoPfEdiMsgSentFromTime, 59, columnForPurchaseOrderMsgSentToTime)
        return commonColumnWithPOPFEdiAndBp;
    }
    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        var createColumnsForDifferentScreens = columns;

        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                if (!isBusinessPartnerPortal) {
                    createColumnsForDifferentScreens = constructColumnsToBeDisplayedForEdiPF()
                } else {
                    createColumnsForDifferentScreens = commonColumnForPOPF;
                }
                setColumns(createColumnsForDifferentScreens);
                savePreference("visibility", createColumnsForDifferentScreens);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                paramBody.body.approved = isBusinessPartnerPortal ? 1 : null;
                paramBody.body.fromUpdatedDate = isBusinessPartnerPortal ? null : new Date(dateValue.setDate(dateValue.getDate() - 7)).toISOString().slice(0, 10);
                paramBody.body.poFromDate = isBusinessPartnerPortal ? new Date(dateValue.setDate(dateValue.getDate() - 7)).toISOString().slice(0, 10) : null;
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                if (!isBusinessPartnerPortal) {
                    createColumnsForDifferentScreens = constructColumnsToBeDisplayedForEdiPF()
                } else {
                    createColumnsForDifferentScreens = commonColumnForPOPF;
                }
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, createColumnsForDifferentScreens);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);
                var previousParamBody = paramBody;

                //filter start
                let bpName = null;
                let bpCode = null;
                let entityCode = null;
                let buyerName = null;
                let artifactId = null;
                let createdBy = null;
                let msgStatusFlag = null;
                let approved = null;
                let poStatus = null;
                let dlvyCfmStatus = null;
                let lastMessageSentMode = null;
                let amendmentCount = null;
                let cancellationCount = null;
                let poFromDate = null;
                let poToDate = null;
                let fromMsgSentTime = null;
                let toMsgSentTime = null;
                let fromUpdatedDate = null;
                let toUpdatedDate = null;
                let latestDataSource = null;
                let wbsCode = null;
                let projectName = null;
                let deljitFlag = null;
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'supplierName':
                            bpName = columns.value
                            break;
                        case 'createdBy':
                            createdBy = columns.value
                            break;
                        case 'poNo':
                            artifactId = columns.value
                            break;
                        case 'lastMessageSentMode':
                            lastMessageSentMode = columns.value
                            break;
                        case 'entity.erpEntityCode':
                            entityCode = columns.value
                            break;
                        case 'msgStatusFlag':
                            msgStatusFlag = columns.value
                            break;
                        case 'bpCode':
                            bpCode = columns.value
                            break;
                        case 'approved':
                            approved = columns.value
                            break;
                        case 'buyerName':
                            buyerName = columns.value
                            break;
                        case 'poFromDate':
                            poFromDate = columns.value
                            break;
                        case 'poToDate':
                            poToDate = columns.value
                            break;
                        case 'poStatus':
                            poStatus = columns.value
                            break;
                        case 'dlvyCfmStatus':
                            dlvyCfmStatus = columns.value
                            break;
                        case 'cancellationCount':
                            cancellationCount = columns.value
                            break;
                        case 'amendmentCount':
                            amendmentCount = columns.value
                            break;
                        case 'fromMsgSentTime':
                            fromMsgSentTime = columns.value
                            break;
                        case 'toMsgSentTime':
                            toMsgSentTime = columns.value
                            break;
                        case 'fromUpdatedDate':
                            fromUpdatedDate = columns.value
                            break;
                        case 'toUpdatedDate':
                            toUpdatedDate = columns.value
                            break;
                        case 'latestDataSource':
                            latestDataSource = columns.value
                            break;
                        case 'wbsCode':
                            wbsCode = columns.value
                            break;
                        case 'projectName':
                            projectName = columns.value
                            break;
                        case 'deljitFlag':
                            deljitFlag = columns.value
                            break;
                    }
                })

                setSearchedEntityCode(entityCode);
                setSearchedPoNo(artifactId);
                setSearchedBpCode(bpCode);
                setSearchedApprovedRecordsRecords(approved);
                setSearchedCommType(lastMessageSentMode);
                setSearchedStatusFlag(msgStatusFlag);
                setSearchedBuyerName(buyerName);
                setSearchedSupplierName(bpName);
                setSearchedPoFromDate(poFromDate);
                setSearchedPoToDate(poToDate);
                setSeachedMessageSentFromDate(fromMsgSentTime);
                setSeachedMessageSentToDate(toMsgSentTime);
                setSearchedPoStatus(poStatus);
                setSearchedDeliveryConfirmed(dlvyCfmStatus);
                setSearchedOrderCreatedBy(createdBy);
                setSearchedAmendedValue(amendmentCount);
                setSearchedCancelledValue(cancellationCount);
                setSearchedUpdatedFromTime(fromUpdatedDate)
                setSearchedUpdatedToTime(toUpdatedDate);
                setSearchedDataSourceValue(latestDataSource);
                setSearchedWbsCode(wbsCode);
                setSearchedWbsName(projectName);
                setSearchedDeljitValue(deljitFlag);


                var filterBody = {
                    entity: {
                        erpEntityCode: entityCode,
                    },
                    poScheduleType: "4",
                    bpCode: bpCode,
                    poNo: artifactId,
                    lastMessageSentMode: lastMessageSentMode,
                    buyerName: buyerName,
                    createdBy: createdBy,
                    supplierName: bpName,
                    recordApprovedStatus: isBusinessPartnerPortal ? 1 : approved === '' ? 'All' : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : 'All',
                    approved: isBusinessPartnerPortal ? 1 : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : '',
                    msgStatusFlag: msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : '',
                    poStatus: poStatus === 'Complete' ? 'COMPLETE' : poStatus === 'In-Complete' ? 'IN-COMPLETE' : null,
                    dlvyCfmStatus: dlvyCfmStatus,
                    recordStatusFlag: msgStatusFlag === '' ? 'All' : msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : 'All',
                    poFromDate: poFromDate,
                    poToDate: poToDate,
                    fromMsgSentTime: fromMsgSentTime,
                    toMsgSentTime: toMsgSentTime,
                    cancellationCount: cancellationCount === 'Yes' ? 1 : cancellationCount === 'No' ? 0 : '',
                    amendmentCount: amendmentCount === 'Yes' ? 1 : amendmentCount === 'No' ? 0 : '',
                    recordCancellationCount: cancellationCount === null ? 'All' : cancellationCount === 'Yes' ? 1 : cancellationCount === 'No' ? 0 : '',
                    recordAmendmentCount: amendmentCount === null ? 'All' : amendmentCount === 'Yes' ? 1 : amendmentCount === 'No' ? 0 : '',
                    fromUpdatedDate: fromUpdatedDate,
                    toUpdatedDate: toUpdatedDate,
                    latestDataSource: latestDataSource,
                    wbsCode: wbsCode,
                    projectName: projectName,
                    deljitFlag: deljitFlag === 'Yes' ? 'X' : deljitFlag === 'No' ? null : deljitFlag === 'No' ? '' :'All',
                    recordDeljitFlg: deljitFlag === null ? 'All' : deljitFlag === 'Yes' ? 'X' :deljitFlag === 'No' ? '':'All',
                };

                //filter end 
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0) {
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                    paramBody.body.approved = isBusinessPartnerPortal ? 1 : null;
                    paramBody.body.fromUpdatedDate = isBusinessPartnerPortal ? null : new Date(dateValue.setDate(dateValue.getDate() - 7)).toISOString().slice(0, 10);
                    paramBody.body.poFromDate = isBusinessPartnerPortal ? new Date(dateValue.setDate(dateValue.getDate() - 7)).toISOString().slice(0, 10) : null;
                }
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, [selectedLanguage]);

    const approveAndMsgId = tableData.map((row) => {
        return {
            approved: row.approved, msgId: row.msgId, amendmentCount: row.amendmentCount,
            cancellationCount: row.cancellationCount, batchId: row.batchId, poNo: row.poNo,
            poPdfFilePath: row.poPdfFilePath, bpCode: row.bpCode, entityCode: row.entity.erpEntityCode,
            deljitFlag:row.deljitFlag

        }
    })

    const filterValueLoad = approveAndMsgId.filter((item) => {
        if (selectedRow.includes(item.msgId)) {
            return item
        }
    })

    const filterValueApproved = filterValueLoad.map((row) => { return row.approved });
    const bpCodeValues = filterValueLoad.map((row) => { return row.bpCode })
    const entittyCodeValue = filterValueLoad.map((row) => { return row.entityCode })

    const optionForExpPoPRAndAdtHistoryForEdiForecast = [
        {
            label: intl.formatMessage({ id: "common.tooltip.breadcrumb.downloadExcelDtl" }),
            tooltipTitle: intl.formatMessage({ id: "common.tooltip.breadcrumb.downloadExcelDtl" }),
            url: '/reports/SYS_PURCHASE_FORECAST_OUTBOUND',
            successMessage: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />,
            parameters: searchedEntityCode === '' && searchedBpCode === '' ?
                isBusinessPartnerPortal ? {
                    erpEntityCode: '', bpCode: '', poScheduleType: "4", approved: isBusinessPartnerPortal ? 1 : null,
                } : {
                    erpEntityCode: '', bpCode: '', poScheduleType: "4", fromUpdatedDate: new Date(dateValue.setDate(dateValue.getDate() - 0)).toISOString().slice(0, 10),
                } : searchedBpCode === '' ? isBusinessPartnerPortal ? {
                    erpEntityCode: defaultEntity, bpCode: '', poScheduleType: "4", approved: isBusinessPartnerPortal ? 1 : null,
                } : {
                    erpEntityCode: defaultEntity, bpCode: '', poScheduleType: "4", fromUpdatedDate: new Date(dateValue.setDate(dateValue.getDate() - 0)).toISOString().slice(0, 10),
                } : keyValueData
        },
    ];

    const options = optionForExpPoPRAndAdtHistoryForEdiForecast;

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        requestXLReportExport(options[index], false);
    };

    const handleToggle = (event) => {
        setAnchorEl(null);
        setAnchorE2(anchorRef.current)
    };

    const handleClose = (event) => {
        setAnchorE2(null)
    };

    useEffect(() => {
        setAnchorE2(anchorRef.current);
    }, [anchorRef]);


    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.pfOutbound, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));

                })
                .catch(function (error) {
                    setTableLoadingStatus(false);

                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [paramBody, refreshTable])

    function currentlySelectedRow(selections) {
        setSelectedRow(selections)
        setAnchorE2(null);
        setAnchorEl(null);
        if (selections.length === 0) {
            isDisableApproveButton(true);
            isDisableRejectionButton(true);
        } else {
            isDisableApproveButton(false);
            isDisableRejectionButton(false);
        }
    }
    if (columns.length > 0) {

        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };

        const commTypeColumn = columns.find((column) => column.field === columnFieldsName.lastMessageSentMode);
        const commTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.lastMessageSentMode);

        const commTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.CommTypeLogInputValue,
            }),
        );

        columns[commTypeColIndex] = {
            ...commTypeColumn,
            filterOperators: commTypeFilterOperators,
        };

        const approvedColumn = columns.find((column) => column.field === columnFieldsName.approved);
        const approvedColIndex = columns.findIndex((col) => col.field === columnFieldsName.approved);

        const approvedFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.ApprovedPOInputValue,
            }),
        );

        columns[approvedColIndex] = {
            ...approvedColumn,
            filterOperators: approvedFilterOperators,
        };

        const statusFlagColumn = columns.find((column) => column.field === columnFieldsName.msgStatusFlag);
        const statusFlagColIndex = columns.findIndex((col) => col.field === columnFieldsName.msgStatusFlag);

        const statusFlagFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.PoStatusOutboundColInputValue,
            }),
        );

        columns[statusFlagColIndex] = {
            ...statusFlagColumn,
            filterOperators: statusFlagFilterOperators,
        };

        const poFromDateColumn = columns.find((column) => column.field === columnFieldsName.poFromDate);
        const poFromDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.poFromDate);

        const poFromDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[poFromDateColIndex] = {
            ...poFromDateColumn,
            filterOperators: poFromDateFilterOperators,
        };

        const poToDateColumn = columns.find((column) => column.field === columnFieldsName.poToDate);
        const poToDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.poToDate);

        const poToDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[poToDateColIndex] = {
            ...poToDateColumn,
            filterOperators: poToDateFilterOperators,
        };

        const fromMsgSentTimeColumn = columns.find((column) => column.field === columnFieldsName.fromMsgSentTime);
        const fromMsgSentTimeColIndex = columns.findIndex((col) => col.field === columnFieldsName.fromMsgSentTime);

        const fromMsgSentTimeFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[fromMsgSentTimeColIndex] = {
            ...fromMsgSentTimeColumn,
            filterOperators: fromMsgSentTimeFilterOperators,
        };

        const toMsgSentTimeColumn = columns.find((column) => column.field === columnFieldsName.toMsgSentTime);
        const toMsgSentTimeColIndex = columns.findIndex((col) => col.field === columnFieldsName.toMsgSentTime);

        const toMsgSentTimeFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[toMsgSentTimeColIndex] = {
            ...toMsgSentTimeColumn,
            filterOperators: toMsgSentTimeFilterOperators,
        };

        const deliveryCfmStatusColumn = columns.find((column) => column.field === columnFieldsName.dlvyCfmStatus);
        const deliveryCfmStatusColIndex = columns.findIndex((col) => col.field === columnFieldsName.dlvyCfmStatus);

        const deliveryCfmStatusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DeliveryCfmStatusInputValue,
            }),
        );

        columns[deliveryCfmStatusColIndex] = {
            ...deliveryCfmStatusColumn,
            filterOperators: deliveryCfmStatusFilterOperators,
        };

        const poStatusColumn = columns.find((column) => column.field === columnFieldsName.poStatus);
        const poStatusColIndex = columns.findIndex((col) => col.field === columnFieldsName.poStatus);

        const poStatusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.PoStatusOutboundForEDIAndBPColInputValue,
            }),
        );

        columns[poStatusColIndex] = {
            ...poStatusColumn,
            filterOperators: poStatusFilterOperators,
        };

        const amendedColumn = columns.find((column) => column.field === columnFieldsName.amendmentCount);
        const amendedColIndex = columns.findIndex((col) => col.field === columnFieldsName.amendmentCount);

        const amendedFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.PoAmendmentInputValue,
            }),
        );

        columns[amendedColIndex] = {
            ...amendedColumn,
            filterOperators: amendedFilterOperators,
        };

        const cancellationColumn = columns.find((column) => column.field === columnFieldsName.cancellationCount);
        const cancellationColIndex = columns.findIndex((col) => col.field === columnFieldsName.cancellationCount);

        const cancellationFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.PoCancellationInputValue,
            }),
        );

        columns[cancellationColIndex] = {
            ...cancellationColumn,
            filterOperators: cancellationFilterOperators,
        };

        const supplierNameColumn = columns.find((column) => column.field === columnFieldsName.supplierName);
        const supplierNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.supplierName);

        const supplierNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.SupplierVendorNameInputValue,
            }),
        );

        columns[supplierNameColIndex] = {
            ...supplierNameColumn,
            filterOperators: supplierNameFilterOperators,
        };

        const fromUpdateTimeColumn = columns.find((column) => column.field === columnFieldsName.fromUpdatedDate);
        const fromUpdateTimeColIndex = columns.findIndex((col) => col.field === columnFieldsName.fromUpdatedDate);

        const fromUpdateTimeFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[fromUpdateTimeColIndex] = {
            ...fromUpdateTimeColumn,
            filterOperators: fromUpdateTimeFilterOperators,
        };

        const toUpdateTimeColumn = columns.find((column) => column.field === columnFieldsName.toUpdatedDate);
        const toUpdateTimeColIndex = columns.findIndex((col) => col.field === columnFieldsName.toUpdatedDate);

        const toUpdateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[toUpdateTimeColIndex] = {
            ...toUpdateTimeColumn,
            filterOperators: toUpdateFilterOperators,
        };

        const wbsCodeColumn = columns.find((column) => column.field === columnFieldsName.wbsCode);
        const wbsCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.wbsCode);

        const wbsCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.WbsCodeInputValue,
            }),
        );

        columns[wbsCodeColIndex] = {
            ...wbsCodeColumn,
            filterOperators: wbsCodeFilterOperators,
        };

        const wbsNameColumn = columns.find((column) => column.field === columnFieldsName.projectName);
        const wbsNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.projectName);

        const wbsNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.WbsDescriptionInputValue,
            }),
        );

        columns[wbsNameColIndex] = {
            ...wbsNameColumn,
            filterOperators: wbsNameFilterOperators,
        };

        const deljitColumn = columns.find((column) => column.field === columnFieldsName.deljitFlag);
        const deljitColIndex = columns.findIndex((col) => col.field === columnFieldsName.deljitFlag);

        const deljitFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DeljitInputValue,
            }),
        );

        columns[deljitColIndex] = {
            ...deljitColumn,
            filterOperators: deljitFilterOperators,
        };
    }
    useEffect(() => {
        if (totalRowCount === 0) {
            setDisiablingDownloadButtonForZeroRecords(true)
        } else {
            setDisiablingDownloadButtonForZeroRecords(false)
        }
    }, [totalRowCount])


    function currentlySelectedRow(selections) {
        setSelectedRow(selections)
        setAnchorE2(null);
        setAnchorEl(null);
        if (selections.length === 0) {
            isDisableApproveButton(true);
            isDisableRejectionButton(true);
        } else {
            isDisableApproveButton(false);
            isDisableRejectionButton(false);
        }
    }

    useEffect(() => {
        if (selectedRow.length === 0) {
            isDisableApproveButton(true)
            isDisableRejectionButton(true);
        } else if (filterValueApproved.toString().includes('0')) {
            isDisableApproveButton(false);
            isDisableRejectionButton(false);
        } else if (filterValueApproved.toString().includes('1')) {
            isDisableApproveButton(true);
            isDisableRejectionButton(true);
        } else if (filterValueApproved.toString().includes('2')) {
            isDisableApproveButton(false);
            isDisableRejectionButton(true);
        } else {
            isDisableApproveButton(false);
            isDisableRejectionButton(false);
        }
    }, [filterValueApproved])

    const onSubmitApprovals = () => {
        setIsFormSubmitted(true);
        setIsRowSentForApproval(true);
        setOpenBackDrop(true);
        setAnchorEl(null);
        setAnchorE2(null);
        if (filterValueApproved.toString().includes('1')) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains already Approved records", severity: "warning"
            });
            setIsRowSentForApproval(false);
            setIsFormSubmitted(false);
            setOpenBackDrop(false);
        } else {
            CommonService.saveOrUpdateOrPost('/poOutboundApprovals', {
                approved: 1,
                msgIds: selectedRow
            })
                .then((response) => {
                    setIsFormSubmitted(false)
                    setIsRowSentForApproval(false)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: `MessageId : ${selectedRow} has been approved successfully`, severity: "success"
                    });
                    setSelectedRow([])
                    setRefreshTable(!refreshTable);
                    isDisableApproveButton(true);
                    isDisableRejectionButton(true);
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setIsFormSubmitted(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.message, severity: "error" });
                    isDisableApproveButton(false);
                    setOpenBackDrop(false);
                });
        }
    }

    const onSubmitReject = (data) => {
        setIsFormSubmitted(true);
        setIsRowSentForApproval(true);
        setOpenBackDrop(true);
        setAnchorEl(null);
        setAnchorE2(null);
        if (filterValueApproved.toString().includes('2')) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains already Rejected records", severity: "warning"
            });
            setIsRowSentForApproval(false);
            setIsFormSubmitted(false);
            setOpenBackDrop(false);
        } else if (filterValueApproved.toString().includes('1')) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains already Approved records", severity: "warning"
            });
            setIsRowSentForApproval(false);
            setIsFormSubmitted(false);
            setOpenBackDrop(false);
        } else {
            CommonService.saveOrUpdateOrPost('/poOutboundApprovals', {
                approved: 2,
                msgIds: selectedRow
            })
                .then((response) => {
                    setIsFormSubmitted(false)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: `MessageId : ${selectedRow} has been rejected successfully`, severity: "success"
                    });
                    setSelectedRow([])
                    setRefreshTable(!refreshTable);
                    isDisableApproveButton(true);
                    isDisableRejectionButton(true);
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setIsFormSubmitted(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.message, severity: "error" });
                    isDisableRejectionButton(false);
                    setOpenBackDrop(false);
                });
        }
    }
    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let bpName = null;
        let bpCode = null;
        let entityCode = null;
        let buyerName = null;
        let artifactId = null;
        let createdBy = null;
        let msgStatusFlag = null;
        let approved = null;
        let poStatus = null;
        let dlvyCfmStatus = null;
        let lastMessageSentMode = null;
        let amendmentCount = null;
        let cancellationCount = null;
        let poFromDate = null;
        let poToDate = null;
        let fromMsgSentTime = null;
        let toMsgSentTime = null;
        let fromUpdatedDate = null;
        let toUpdatedDate = null;
        let latestDataSource = null;
        let wbsCode = null;
        let projectName = null;
        let deljitFlag = null;

        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'supplierName':
                    bpName = columns.value
                    break;
                case 'createdBy':
                    createdBy = columns.value
                    break;
                case 'poNo':
                    artifactId = columns.value
                    break;
                case 'lastMessageSentMode':
                    lastMessageSentMode = columns.value
                    break;
                case 'entity.erpEntityCode':
                    entityCode = columns.value
                    break;
                case 'msgStatusFlag':
                    msgStatusFlag = columns.value
                    break;
                case 'bpCode':
                    bpCode = columns.value
                    break;
                case 'approved':
                    approved = columns.value
                    break;
                case 'buyerName':
                    buyerName = columns.value
                    break;
                case 'poFromDate':
                    poFromDate = columns.value
                    break;
                case 'poToDate':
                    poToDate = columns.value
                    break;
                case 'poStatus':
                    poStatus = columns.value
                    break;
                case 'dlvyCfmStatus':
                    dlvyCfmStatus = columns.value
                    break;
                case 'cancellationCount':
                    cancellationCount = columns.value
                    break;
                case 'amendmentCount':
                    amendmentCount = columns.value
                    break;
                case 'fromMsgSentTime':
                    fromMsgSentTime = columns.value
                    break;
                case 'toMsgSentTime':
                    toMsgSentTime = columns.value
                    break;
                case 'fromUpdatedDate':
                    fromUpdatedDate = columns.value
                    break;
                case 'toUpdatedDate':
                    toUpdatedDate = columns.value
                    break;
                case 'latestDataSource':
                    latestDataSource = columns.value
                    break;
                case 'wbsCode':
                    wbsCode = columns.value
                    break;
                case 'projectName':
                    projectName = columns.value
                    break;
                case 'deljitFlag':
                    deljitFlag = columns.value
                    break;
            }
        })

        setSearchedEntityCode(entityCode);
        setSearchedPoNo(artifactId);
        setSearchedBpCode(bpCode);
        setSearchedApprovedRecordsRecords(approved);
        setSearchedCommType(lastMessageSentMode);
        setSearchedStatusFlag(msgStatusFlag);
        setSearchedBuyerName(buyerName);
        setSearchedSupplierName(bpName);
        setSearchedPoFromDate(poFromDate);
        setSearchedPoToDate(poToDate);
        setSeachedMessageSentFromDate(fromMsgSentTime);
        setSeachedMessageSentToDate(toMsgSentTime);
        setSearchedPoStatus(poStatus);
        setSearchedDeliveryConfirmed(dlvyCfmStatus);
        setSearchedOrderCreatedBy(createdBy);
        setSearchedAmendedValue(amendmentCount);
        setSearchedCancelledValue(cancellationCount);
        setSearchedUpdatedFromTime(fromUpdatedDate)
        setSearchedUpdatedToTime(toUpdatedDate);
        setSearchedDataSourceValue(latestDataSource);
        setSearchedWbsCode(wbsCode);
        setSearchedWbsName(projectName);
        setSearchedDeljitValue(deljitFlag);


        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);
        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    entity: {
                        erpEntityCode: entityCode,
                    },
                    poScheduleType: "4",
                    bpCode: bpCode,
                    poNo: artifactId,
                    lastMessageSentMode: lastMessageSentMode,
                    buyerName: buyerName,
                    createdBy: createdBy,
                    supplierName: bpName,
                    recordApprovedStatus: isBusinessPartnerPortal ? 1 : approved === '' ? 'All' : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : 'All',
                    approved: isBusinessPartnerPortal ? 1 : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : '',
                    msgStatusFlag: msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : '',
                    poStatus: poStatus === 'Complete' ? 'COMPLETE' : poStatus === 'In-Complete' ? 'IN-COMPLETE' : null,
                    dlvyCfmStatus: dlvyCfmStatus,
                    recordStatusFlag: msgStatusFlag === '' ? 'All' : msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : 'All',
                    poFromDate: poFromDate,
                    poToDate: poToDate,
                    fromMsgSentTime: fromMsgSentTime,
                    toMsgSentTime: toMsgSentTime,
                    cancellationCount: cancellationCount === 'Yes' ? 1 : cancellationCount === 'No' ? 0 : '',
                    amendmentCount: amendmentCount === 'Yes' ? 1 : amendmentCount === 'No' ? 0 : '',
                    recordCancellationCount: cancellationCount === null ? 'All' : cancellationCount === 'Yes' ? 1 : cancellationCount === 'No' ? 0 : '',
                    recordAmendmentCount: amendmentCount === null ? 'All' : amendmentCount === 'Yes' ? 1 : amendmentCount === 'No' ? 0 : '',
                    fromUpdatedDate: fromUpdatedDate,
                    toUpdatedDate: toUpdatedDate,
                    latestDataSource: latestDataSource,
                    wbsCode: wbsCode,
                    projectName: projectName,
                    deljitFlag: deljitFlag === 'Yes' ? 'X' :  deljitFlag === 'No' ? null: deljitFlag === 'No' ? '':'All',
                    recordDeljitFlg: deljitFlag === null ? 'All' : deljitFlag === 'Yes' ? 'X'  :deljitFlag === 'No' ? '':'All',

                }
            }
        })
    }

    useEffect(() => {
        if (intialParamBodyApiCall)
            setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    let filterBodyObj = {
        erpEntityCode: searchedEntityCode === null ? "" : searchedEntityCode,
        bpCode: searchedBpCode === null ? "" : searchedBpCode,
        supplierName: searchedSupplierName,
        approved: isBusinessPartnerPortal ? 1 : searchedApprovedRecordsRecords === 'Not Approved' ? 0 : searchedApprovedRecordsRecords === 'Approved' ? 1 : searchedApprovedRecordsRecords === 'Rejected' ? 2 : null,
        poNo: searchedPoNo,
        createdBy: searchedOrderCreatedBy,
        dlvyCfmStatus: searchedDeliveryConfirmed,
        poStatus: searchedPoStatus === 'Complete' ? 'COMPLETE' : searchedPoStatus === 'In-Complete' ? 'IN-COMPLETE' : null,
        msgStatusFlag: searchedStatusFlag === 'Not Sent' ? 0 : searchedStatusFlag === 'Pending' ? 1 : searchedStatusFlag === 'Successfully Sent' ? 2 : searchedStatusFlag === 'Failed to Send' ? 3 : searchedStatusFlag === 'Cancelled' ? 4 : null,
        lastMessageSentMode: searchedCommType,
        buyerName: searchedBuyerName,
        poFromDate: searchedPoFromDate !== null ? searchedPoFromDate : null,
        poToDate: searchedPoToDate !== null ? searchedPoToDate : null,
        fromLastMessageSentTime: searchedMsgSentFromDate !== null ? searchedMsgSentFromDate : null,
        toLastMessageSentTime: searchedMsgSentToDate !== null ? searchedMsgSentToDate : null,
        poScheduleType: "4",
        fromUpdatedDate: searchedUpdatedFromTime !== null ? searchedUpdatedFromTime : null,
        toUpdatedDate: searchedUpdatedToTime !== null ? searchedUpdatedToTime : null,
        latestDataSource: searchedDataSourceValue,
        cancellationCount: searchedCancelledValue === 'Yes' ? 1 : searchedCancelledValue === 'No' ? 0 : null,
        amendmentCount: searchedAmendedValue === 'Yes' ? 1 : searchedAmendedValue === 'No' ? 0 : null,
        wbsCode: searchedWbsCode,
        projectName: searchedWbsName,
        deljitFlag: searchedDeljitCount === 'Yes' ? 'X'  : searchedDeljitCount === 'No' ? null :searchedDeljitCount === 'No' ? '' : null,
 
    }
    useEffect(() => {
        let colValDataObj = {}
        for (let key in filterBodyObj) {
            let value = filterBodyObj[key]
            if (filterBodyObj.hasOwnProperty(key) && ((value !== null) && (value !== undefined) && (value !== {}))) {
                colValDataObj[key] = value.toString();
            }
            setKeyValueData(colValDataObj)
        }
    }, [searchedEntityCode, searchedBpCode, searchedSupplierName, searchedPoNo, searchedDeliveryConfirmed, searchedPoStatus, searchedStatusFlag, searchedCommType, searchedBuyerName, searchedApprovedRecordsRecords, searchedPoFromDate, searchedPoToDate, searchedMsgSentFromDate, searchedMsgSentToDate, searchedOrderCreatedBy, searchedUpdatedFromTime, searchedUpdatedToTime, searchedWbsCode, searchedWbsName]);

    const requestXLReportExport = (selectedExportReportTypeObj, isExportDlvScheduleForSap) => {
        isExportDlvScheduleForSap ? setDlvScheduleForSapExportXlRequested(true) : setExportXlRequested(true);
        setExportXlRequested(true);
        setOpenBackDrop(true);
        CommonService.saveOrUpdateOrPost(selectedExportReportTypeObj.url, selectedExportReportTypeObj.parameters)
            .then((response) => {
                setExportXlRequested(false);
                setDlvScheduleForSapExportXlRequested(false);
                if (response.data.sucess === "No records found for given condition")
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.sucess, severity: "error" });
                else {
                    //showDownloadPopUp(response.data.fileUrl);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success" });
                }
                setOpenBackDrop(false);
            })
            .catch(function (error) {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                setOpenBackDrop(false);
                setExportXlRequested(false);
                setDlvScheduleForSapExportXlRequested(false);
            });
    }


    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={9000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForEditPurchaseOrder}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.topGrid} style={{ marginLeft: '-0.5%' }}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                {!isBusinessPartnerPortal && <ChipInfoList chipInfoList={chipInfoList} />}
                <CustomFilterComponent open={openFilterPanel} onClose={handleCloseForFilter} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <FileUploadDialog open={openFileUploadDialog} onClose={closeFileUploadDialog} isBusinessPartnerPortal={isBusinessPartnerPortal} defaultEntity={defaultEntity}
                    defaultBpType={"SUPPLIER"} />
                <CommonReportDownloadForMsg open={openReportDownloadDialog} bpCode={bpCodeValues} entityCode={entittyCodeValue} proccessValue={{ processId: 2, processName: 'Purchase Forecast' }}
                    onClose={closeReportDownloadDialog} msgIdvalue={selectedRow} />
                {/* <Grid container className={classes.rootForEditPurchaseOrder}> */}
                <Paper elevation={3} className={isBusinessPartnerPortal ? classes.gridHeightAndWidthForBP : classes.gridHeightAndWidth}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            getRowId={(row) => row.msgId}
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            density="compact"
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            hideFooter={true}
                            checkboxSelection={isBusinessPartnerPortal ? false : true}
                            onSelectionModelChange={currentlySelectedRow}
                            selectionModel={selectedRow}
                            isRowSelectable={(params) => true}
                            localeText={localtext}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container direction="row" justifyContent="flex-start" alignItems="center"
                                            style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                            <Grid item container direction="row" justifyContent="flex-start" xs={12} sm={12} md={8} lg={8}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" />} placement='bottom'>
                                                        <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium"
                                                                onClick={() => { setFilterPanel(true); setAnchorEl(null); setAnchorE2(null) }}
                                                            >
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterList />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    {(poPfOutboundModuleAccess !== undefined ? poPfOutboundModuleAccess.createFlag || poPfOutboundModuleAccess.editFlag : false) && (!isBusinessPartnerPortal) && (<Tooltip title={<FormattedMessage id="common.button.approveselectedrecords.tooltip" defaultMessage="Approve selected records" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={onSubmitApprovals}
                                                                disabled={disableApproveButton || isFormSubmitted}
                                                            >
                                                                <IconApproval size={20} />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>)}
                                                </Grid>
                                                <Grid>
                                                    {(poPfOutboundModuleAccess !== undefined ? poPfOutboundModuleAccess.createFlag || poPfOutboundModuleAccess.editFlag : false) && (!isBusinessPartnerPortal) && (
                                                        <Tooltip title={<FormattedMessage id="common.button.rejectselectedrecords.tooltip" defaultMessage="Reject selected records" />} placement='bottom'>
                                                            <span>
                                                                <Button size="small" color="primary" variant="outlined"
                                                                    className={classes.refreshToobarStyle}
                                                                    onClick={onSubmitReject}
                                                                    disabled={disableRejectionButton || isFormSubmitted}
                                                                >
                                                                    <CancelIcon size={20} />
                                                                </Button>
                                                            </span>
                                                        </Tooltip>)}
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={options[selectedIndex].tooltipTitle} placement='bottom'>
                                                        <span>
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                ref={anchorRef}
                                                                color="primary"
                                                                disabled={tableData.length <= 0 || isExportXlRequested}
                                                                endIcon={isExportXlRequested ? <CircularProgress color="secondary" size={20} /> : null}
                                                                onClick={handleToggle}
                                                                style={{ marginRight: '5px' }}
                                                            >
                                                                <ExportIcon />
                                                                <Typography color="primary" style={{ margin: '0 5px 0 5px', fontWeight: 500, fontSize: '13px' }} >
                                                                    <FormattedMessage id="export.defaulttext.button" />
                                                                </Typography>
                                                                <ArrowDropDownIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                    <Menu
                                                        id="selectReportDownload"
                                                        anchorPosition={isBusinessPartnerPortal ? { left: 200, top: 120 } : { left: 300, top: 150 }}
                                                        anchorReference="anchorPosition"
                                                        open={Boolean(anchorE2)}
                                                        elevation={5}
                                                        onClick={handleClose}
                                                    >
                                                        <MenuList>
                                                            {options.map((option, index) => (
                                                                <Tooltip title={options[index].tooltipTitle} placement="right-start">
                                                                    <span>
                                                                        <MenuItem
                                                                            divider
                                                                            className={classes.menuListStyle}
                                                                            key={index}
                                                                            selected={index === selectedIndex}
                                                                            onClick={(event) => handleMenuItemClick(event, index)}
                                                                        >
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    </span>
                                                                </Tooltip>
                                                            ))}
                                                        </MenuList>
                                                    </Menu>
                                                </Grid>
                                                {(!isBusinessPartnerPortal) && (
                                                    <Grid>
                                                        {(<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadReport" />}
                                                            placement='bottom' className={classes.actionButton} onClick={() => {
                                                                setOpenReportDownloadDialog(true);
                                                            }}>
                                                            <span>
                                                                <Button size='small' className={classes.refreshToobarStyle} color="primary" variant="outlined"
                                                                    component="span" disabled={selectedRow.length === 0 ? true : false}
                                                                    onClick={() => {
                                                                        setOpenReportDownloadDialog(true);
                                                                    }}>
                                                                    <CustomReportIcon />
                                                                </Button>
                                                                {/* <IconButton color="secondary" size="large"> */}
                                                                {/* <CustomReportIcon /> */}
                                                                {/* </IconButton> */}
                                                            </span>
                                                        </Tooltip>)}
                                                    </Grid>
                                                )}
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined" className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                    setSelectedRow([]);
                                                                    setAnchorE2(null);
                                                                    setAnchorEl(null);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            hideFooterSelectedRowCount={true}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length - 1, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length - 1, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length - 1, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                --params.oldIndex;
                                --params.targetIndex;
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </div>
                </Paper>
            </Grid>
        </React.Fragment >
    )
}

export default PurchaseForecastOutbound;