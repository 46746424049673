import { Button, CssBaseline, Divider, Fab, FormControl, FormHelperText, Grid, IconButton, InputLabel, Paper, Snackbar, Switch, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import { CommonService } from '../../../services';
import useStyles from '../../common/CommonStyle/CommonStyle';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import IconBack from '@mui/icons-material/BackspaceOutlined';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import IconTransmissionConfiguration from '@mui/icons-material/PermDataSetting';
import { Alert, Autocomplete } from '@mui/lab';
import CommonBackDrop from '../../common/BackDrop';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import { Controller, useForm } from 'react-hook-form';
import _ from "lodash/fp";
import EntityCodeCombo from '../../common/Combos/EntityCodeCombo';
import BpCodeCombo from '../../common/Combos/BpCodeCombo/BpCodeCombo';
import BpTypeCombo from '../../common/Combos/BpTypeCombo/BpTypeCombo';
import TransmissionVendorCombo from '../../common/Combos/TransmissionVendorCombo';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { MobileDatePicker } from '@mui/lab';
import TransmissionConfigServiceTypeCombo from '../../common/Combos/TransmissionConfigServiceTypeCombo';
import TransmissionConfigProtocolCombo from '../../common/Combos/TransmissionConfigProtocolCombo';
import { AttachFile } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';

const CreateOrEditTransmissionConfiguration = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const { mode, protoConfigId } = props.location.state;
    const currentUser = CommonService.getLoggedInUserInfo();
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const emailTemplateModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.emailtemplate];
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());
    const [defaultOrgObj, setDefaultOrgObj] = useState({});
    const smallScreen = useMediaQuery(theme.breakpoints.down(889))
    useEffect(() => {
        if (orgInfoOfLeggedUser.erpOrgCode && mode === 'create') {
            setDefaultOrgObj({
                erpOrgCode: orgInfoOfLeggedUser.erpOrgCode, orgName: orgInfoOfLeggedUser.orgName,
                valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}`
            });
        }
    }, [orgInfoOfLeggedUser]);

    const { isHideFtpSftp } = false;

    const pageLabelsConstantsAndMessages = {
        name: {
            qualifier: 'qualifier',
            protocol: 'protocol',
            mailBoxId: 'mailBoxId',
            uri: 'uri',
            active: 'active',
            as2Identifier: 'as2Identifier',
            contentType: "contentType",
            mdnRequestType: "mdnRequestType",
            mdnSigned: "mdnSigned",
            status: 'status',
            bpEdiAddresses: 'bpEdiAddresses',
            ssid: "ssid",
            sfid: 'sfid',
            isdnNumber: 'isdnNumber',
            isdnPassword: 'isdnPassword',
            userId: "userId",
            password: "password",
            host: "host",
            port: "port",
            outbox: "outbox",
            entity: "entity",
            bpCode: 'bpCode',
            bpType: 'bpType',
            transConfName: 'transConfName',
            serviceProvider: 'serviceProvider',
            serviceType: 'serviceType',
            sftpPrvKey: 'sftpPrvKey',
            sftpPassPhrase: 'sftpPassPhrase',
            transmissionVendorMaster: 'transmissionVendorMaster',
            licenceFromDate: 'licenceFromDate',
            licenceToDate: 'licenceToDate',
            inbox: 'inbox',
            entityEdiAddresses: 'entityEdiAddresses',
            entityEdiQulifier: 'entityEdiQulifier',
            ediQulifier: 'ediQulifier',
        }
    }

    const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('');
    const [componentList, setComponentList] = useState([]);
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [branchValue, setBranchValue] = useState('');
    const [bpTypeValue, setBpTypeValue] = useState('');
    const [bpCodeValue, setBpCodeValue] = useState('');
    const [vendorNameValue, setVendorNameValue] = useState('');
    const [disabledForReadMode, isDisabledForReadMode] = useState(false);
    const [disableSaveOrUpdateButton, issaveOrUpdateButtonDisabled] = useState(false);
    const [transmissionConfigToBeEdited, setTransmissionConfigToBeEdited] = useState([]);
    const [ftpSftpConfigToBeEdited, setFtpSftpConfigToBeEdited] = useState({ status: false, mdnSigned: true, active: true });
    const [licenceFromDate, changeLicenceFromDate] = useState(new Date());
    const [licenceToDate, changeLicenceToDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState('');
    const [sftpPrvKey, setSftpPrvKey] = useState('');

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const [userReadOnlyFields, setUserReadOnlyFields] = useState({
        name: false,
        subject: false,
        organization: false,
        entity: false,
        active: false,
    });


    const { handleSubmit, formState: { errors }, reset, control, setValue } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: {
            qualifier: '',
            protocol: '',
            mailBoxId: '',
            uri: '',
            active: true,
            as2Identifier: '',
            contentType: "",
            mdnRequestType: "",
            mdnSigned: true,
            bpEdiAddresses: '',
            ssid: "",
            sfid: '',
            isdnNumber: '',
            isdnPassword: '',
            userId: "",
            password: "",
            host: "",
            port: "",
            outbox: "",
            inbox: '',
            entity: '',
            transConfName: '',
            serviceProvider: '',
            serviceType: '',
            sftpPrvKey: '',
            sftpPassPhrase: '',
            transmissionVendorMaster: '',
            licenceFromDate: new Date(),
            licenceToDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            bpCode: '',
            bpType: '',
            entityEdiAddresses: '',
            entityEdiQulifier: '',
            ediQulifier: '',

        },
    });

    const [as2VanProtocalValue, setAs2VanProtocalValue] = useState({ value: '' });
    const [ftpSftpProtocalValue, setFtpSftpProtocalValue] = useState({ value: '' });

    useEffect(() => {
        setOpenBackDrop(true);
        if (protoConfigId > 0) {
            CommonService.getByIdApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.transmissionConfigs, protoConfigId)
                .then((response) => {
                    transmissionConfigResettingValues(response.data);
                    setTransmissionConfigToBeEdited(response.data);
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                    setOpenBackDrop(false);
                });
        }
    }, [protoConfigId]);

    function transmissionConfigResettingValues(responseData) {
        reset({
            protocol: responseData.protocol !== undefined ? { value: responseData.protocol } : null,
            transConfName: responseData.transConfName !== undefined ? responseData.transConfName : '',
            entity: responseData.entity !== undefined ? { erpEntityCode: responseData.entity.erpEntityCode, entityName: responseData.entity.entityName } : null,
            serviceProvider: responseData.serviceProvider !== undefined ? responseData.serviceProvider : '',
            serviceType: responseData.serviceType !== undefined ? { value: responseData.serviceType } : '',
            bpType: responseData.bpType !== undefined ? { id: 1, value: responseData.bpType } : '',
            bpCode: responseData.bpCode !== undefined ? { bpCode: responseData.bpCode } : '',
            entity: responseData.entity !== undefined ? { erpEntityCode: responseData.entity.erpEntityCode, entityName: responseData.entity.entityName } : null,
            qualifier: responseData.qualifier !== undefined ? responseData.qualifier : '',
            mailBoxId: responseData.mailBoxId !== undefined ? responseData.mailBoxId : '',
            active: responseData.active !== undefined ? responseData.active : true,
            uri: responseData.uri !== undefined ? responseData.uri : '',
            as2Identifier: responseData.as2Identifier !== undefined ? responseData.as2Identifier : '',
            contentType: responseData.contentType !== undefined ? responseData.contentType : '',
            mdnRequestType: responseData.mdnRequestType !== undefined ? responseData.mdnRequestType : '',
            mdnSigned: responseData.mdnSigned !== undefined ? responseData.mdnSigned : true,
            bpEdiAddresses: responseData.bpEdiAddresses !== undefined ? responseData.bpEdiAddresses : '',
            entityEdiAddresses: responseData.entityEdiAddresses !== undefined ? responseData.entityEdiAddresses : '',
            ssid: responseData.ssid !== undefined ? responseData.ssid : '',
            sfid: responseData.sfid !== undefined ? responseData.sfid : '',
            isdnNumber: responseData.isdnNumber !== undefined ? responseData.isdnNumber : '',
            isdnPassword: responseData.isdnPassword !== undefined ? responseData.isdnPassword : '',
            userId: responseData.userId !== undefined ? responseData.userId : '',
            password: responseData.password !== undefined ? responseData.password : '',
            host: responseData.host !== undefined ? responseData.host : '',
            outbox: responseData.outbox !== undefined ? responseData.outbox : '',
            inbox: responseData.inbox !== undefined ? responseData.inbox : '',
            port: responseData.port !== undefined ? responseData.port : '',
            sftpPrvKey: responseData.sftpPrvKey !== undefined ? responseData.sftpPrvKey : '',
            sftpPassPhrase: responseData.sftpPassPhrase !== undefined ? responseData.sftpPassPhrase : '',
            transmissionVendorMaster: responseData.transmissionVendorMaster !== undefined ? { vendorName: responseData.transmissionVendorMaster.vendorName, transmissionVendorId: responseData.transmissionVendorMaster.transmissionVendorId } : '',
            licenceFromDate: responseData.licenceFromDate !== undefined ? responseData.licenceFromDate : '',
            licenceToDate: (responseData.licenceToDate !== null && responseData.licenceToDate !== undefined) ? responseData.licenceToDate : '',
            entityEdiQulifier: responseData.entityEdiQulifier !== undefined ? responseData.entityEdiQulifier : '',
            ediQulifier: responseData.ediQulifier !== undefined ? responseData.ediQulifier : '',

        });
        setAs2VanProtocalValue(responseData.serviceType !== undefined ? { value: responseData.serviceType } : []);
        setFtpSftpProtocalValue(responseData.protocol !== undefined ? { value: responseData.protocol } : []);
        setBranchValue(responseData.entity !== undefined ? { valuesToBeDisplayed: responseData.entity } : []);
        setBpTypeValue(responseData.bpType !== undefined ? { value: responseData.bpType } : []);
        setBpCodeValue(responseData.bpCode !== undefined ? {
            valueToBeDisplayed: `${responseData.bpCode}-${responseData.bpName}`,
        } : []);
        setVendorNameValue(responseData.transmissionVendorMaster !== undefined ? { vendorName: responseData.transmissionVendorMaster.vendorName, transmissionVendorId: responseData.transmissionVendorMaster.transmissionVendorId } : []);
        changeLicenceFromDate(new Date(responseData.licenceFromDate));
        changeLicenceToDate((responseData.licenceToDate !== null && responseData.licenceToDate !== undefined) ? new Date(responseData.licenceToDate) : null);
        setSftpPrvKey(responseData.sftpPrvKey !== undefined ? responseData.sftpPrvKey : '');
        setSelectedFileName(responseData.sftpPrvKey !== undefined ? responseData.sftpPrvKey.replace(/^.*[\\\/]/, '') : '');
    }

    useEffect(() => {
        setOpenBackDrop(true);
        let currentRouterComponent;
        setValue(`${pageLabelsConstantsAndMessages.name.licenceFromDate}`, licenceFromDate, { shouldValidate: true, shouldDirty: true });
        setValue(`${pageLabelsConstantsAndMessages.name.licenceToDate}`, licenceToDate, { shouldValidate: true, shouldDirty: true });
        if (mode === 'create' && protoConfigId === 0) {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            isRestoreButtonDisabled(false);
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                iconName: CreateIcon,
            }
            setOpenBackDrop(false);
        } else if (mode === 'edit') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isRestoreButtonDisabled(false);
            setUserReadOnlyFields({ ...userReadOnlyFields, name: true, subject: false, organization: true, entity: true, msgContent: false });
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                iconName: EditIcon,
            }
        } else if (mode === 'read') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isDisabledForReadMode(true);
            isRestoreButtonDisabled(false);
            issaveOrUpdateButtonDisabled(true);
            isRestoreButtonDisabled(true);
            setUserReadOnlyFields({ ...userReadOnlyFields, name: true, subject: true, organization: true, entity: true, msgContent: true })
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
                iconName: ViewIcon,
            }
        }
        setComponentList([
            {
                path: null,
                name: <FormattedMessage id="common.menu.breadcrumb.messageconfiguration" />,
                iconName: IconMessageConfiguration,
            },
            {
                path: '/transmissionConfigs',
                name: <FormattedMessage id="common.modue.breadcrumb.transconfig" />,
                iconName: IconTransmissionConfiguration,
            },
            currentRouterComponent
        ])
    }, [mode, protoConfigId]);

    const [enableSftpPrvKeyButton, setEnableSftpPrvKeyButton] = useState(false);
    const [submitSftpPrvkey, setSubmitSftpPrvKey] = useState(false);

    const handleSubmitForSftPrvKey = data => {
        const formData = new FormData();
        formData.append('uploadedFile', selectedFile);
        formData.append('bpCode', mode === 'create' ? bpCodeValue.bpCode : transmissionConfigToBeEdited.bpCode);
        formData.append('fileName', selectedFileName);
        setOpenBackDrop(true)
        CommonService.uploadFileForDataImport('/sftpPrvKeyImport', formData)
            .then((response) => {
                setOpenBackDrop(false);
                setSftpPrvKey(response.data);
                setSubmitSftpPrvKey(true);
            })
            .catch(function (error) {
                setOpenBackDrop(false);
            });
    }

    const onSubmit = data => {
        data['protoConfigId'] = protoConfigId;
        data['createdBy'] = (mode === 'create') ? currentUser.userName : transmissionConfigToBeEdited.createdBy;
        data['entity'] = mode === 'create' ? { erpEntityCode: branchValue.erpEntityCode } : transmissionConfigToBeEdited.entity;
        data['bpCode'] = mode === 'create' ? bpCodeValue.bpCode : transmissionConfigToBeEdited.bpCode;
        data['bpType'] = mode === 'create' ? bpTypeValue.value : transmissionConfigToBeEdited.bpType;
        data['createdTime'] = (transmissionConfigToBeEdited.createdTime === undefined) ? '' : transmissionConfigToBeEdited.createdTime;
        data['updatedBy'] = currentUser.userName;
        data['port'] = parseInt(data.port);
        data['serviceType'] = mode === 'create' ? as2VanProtocalValue.value : transmissionConfigToBeEdited.serviceType;
        data['protocol'] = ftpSftpProtocalValue.value;
        data['updatedTime'] = (transmissionConfigToBeEdited.updatedTime === undefined) ? '' : transmissionConfigToBeEdited.updatedTime;
        data['organization'] = (transmissionConfigToBeEdited.organization === undefined) ? { erpOrgCode: orgInfoOfLeggedUser.erpOrgCode } : transmissionConfigToBeEdited.organization;
        data['secured'] = mode === 'create' ? true : transmissionConfigToBeEdited.secured;
        data['sftpPrvKey'] = data.sftpPrvKey;
        setOpenBackDrop(true);
        CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.transmissionConfigs, data)
            .then((response) => {
                setOpenBackDrop(false);
                history.goBack();
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" />, severity: "success" });
                if (selectedFile !== '') {
                    if (response.data !== null) {
                        const formData = new FormData();
                        formData.append('uploadedFile', selectedFile);
                        formData.append('bpCode', mode === 'create' ? bpCodeValue.bpCode : transmissionConfigToBeEdited.bpCode);
                        formData.append('fileName', selectedFileName);
                        setOpenBackDrop(true)
                        CommonService.uploadFileForDataImport('/sftpPrvKeyImport', formData)
                            .then((response) => {
                                setOpenBackDrop(false);
                                // setSftpPrvKey(response.data);
                                // setSubmitSftpPrvKey(true);
                            })
                            .catch(function (error) {
                                setOpenBackDrop(false);
                            });
                    }
                }
            })
            .catch(function (error) {

                setOpenBackDrop(false);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
        // }
    }


    function resetAllClearThePageInfo() {
        if (mode === 'edit') {
            transmissionConfigResettingValues(transmissionConfigToBeEdited);
            setFtpSftpConfigToBeEdited({ ...transmissionConfigToBeEdited });
        } else {
            reset({
                qualifier: '',
                protocol: '',
                mailBoxId: '',
                uri: '',
                active: true,
                as2Identifier: '',
                contentType: "",
                mdnRequestType: "",
                mdnSigned: true,
                bpEdiAddresses: '',
                ssid: "",
                sfid: '',
                isdnNumber: '',
                isdnPassword: '',
                userId: "",
                password: "",
                host: "",
                port: "",
                outbox: "",
                inbox: "",
                entity: '',
                transConfName: '',
                serviceProvider: '',
                serviceType: '',
                sftpPrvKey: '',
                sftpPassPhrase: '',
                transmissionVendorMaster: '',
                entityEdiAddresses: '',
                entityEdiQulifier: '',
                ediQulifier: ''
            });
            setBranchValue([]);
            setVendorNameValue([]);
            setFtpSftpProtocalValue([]);
            setAs2VanProtocalValue([]);
            setBpCodeValue([]);
            setBpTypeValue([]);
            setTransmissionConfigToBeEdited({ ...transmissionConfigToBeEdited, active: true, status: false, mdnSigned: true });
            changeLicenceFromDate(new Date());
            setValue(`${pageLabelsConstantsAndMessages.name.licenceFromDate}`, new Date(), { shouldValidate: true, shouldDirty: true });
            setValue(`${pageLabelsConstantsAndMessages.name.licenceToDate}`, new Date(new Date().setFullYear(new Date().getFullYear() + 1)), { shouldValidate: true, shouldDirty: true });
            changeLicenceToDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
            setFtpSftpConfigToBeEdited({ ...ftpSftpConfigToBeEdited, active: true, status: false, mdnSigned: true })
        }
    }

    const branchComboProperty = {
        name: pageLabelsConstantsAndMessages.name.entity,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.entityCode.label" }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.entity,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.entity
    }

    const bpTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.bpType,
        isRequired: true,
        label: intl.formatMessage({ id: 'textfield.label.bptype' }),
        isAutofocus: false,
        isDisabled: mode === 'edit' || mode === 'read' ? true : userReadOnlyFields.bpType,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.bpType
    }

    const bpCodeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.bpCode,
        isRequired: true,
        label: intl.formatMessage({ id: 'common.textfield.bpcode' }),
        isAutofocus: false,
        isDisabled: mode === 'edit' || mode === 'read' ? true : userReadOnlyFields.bpCode,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.bpCode
    }

    const vendorNameComboProperty = {
        name: pageLabelsConstantsAndMessages.name.transmissionVendorMaster,
        isRequired: true,
        label: intl.formatMessage({ id: 'textField.transmissionVendorName.lable' }),
        isAutofocus: false,
        isDisabled: mode === 'read' ? true : false,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.transmissionVendorMaster
    }

    const serviceTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.serviceType,
        isRequired: true,
        label: intl.formatMessage({ id: 'textfield.servicetype.label' }),
        isAutofocus: false,
        isDisabled: mode === 'create' ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.serviceType
    }

    const protocolComboProperty = {
        name: pageLabelsConstantsAndMessages.name.protocol,
        isRequired: true,
        label: intl.formatMessage({ id: 'textfield.protocols.labels' }),
        isAutofocus: false,
        isDisabled: mode === 'read' ? true : false,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.protocol
    }

    const resetValuesForAS2VAN = () => {
        if (as2VanProtocalValue.value === 'VAN') {
            setValue(`${pageLabelsConstantsAndMessages.name.uri}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.as2Identifier}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.contentType}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.mdnRequestType}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.mdnSigned}`, true);
            setValue(`${pageLabelsConstantsAndMessages.name.active}`, true);
        } else if (as2VanProtocalValue.value === 'AS2') {
            setValue(`${pageLabelsConstantsAndMessages.name.qualifier}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.mailBoxId}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.bpEdiAddresses}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.ssid}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.sfid}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.isdnNumber}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.isdnPassword}`, '');
        }
    }

    const resetValuesForFTPSFTP = () => {
        if (ftpSftpProtocalValue.value === 'FTP') {
            setValue(`${pageLabelsConstantsAndMessages.name.userId}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.password}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.host}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.port}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.inbox}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.outbox}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.sftpPrvKey}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.sftpPassPhrase}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.entityEdiAddresses}`, '');
        } else if (ftpSftpProtocalValue.value === 'SFTP') {
            setValue(`${pageLabelsConstantsAndMessages.name.userId}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.password}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.host}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.port}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.inbox}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.outbox}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.entityEdiAddresses}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.sftpPrvKey}`, '');
            setValue(`${pageLabelsConstantsAndMessages.name.sftpPassPhrase}`, '');
        }
    }

    const resetComboValueForBpType = () => {
        setBpCodeValue([]);
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForCreateOrEditRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={8} md={8} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6} sm={4} md={4} lg={6} className={classes.topGrid}>
                    <Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2}
                        endIcon={smallScreen ? null : <IconBack />} onClick={history.goBack}
                    > {smallScreen ? <IconBack /> : <FormattedMessage id="common.button.back.lable" defaultMessage="Back" />} </Button>
                    <Button size='small' disabled={disableRestoreButton}
                        variant="contained" color="secondary" className={classes.stateButtonToRestore} elevation={2}
                        endIcon={smallScreen ? null : <IconRestore />} onClick={(() => { resetAllClearThePageInfo() })}
                    > {smallScreen ? <IconRestore /> : <FormattedMessage id="common.button.reset.lable" defaultMessage="Reset" />} </Button>

                    {(emailTemplateModuleAccess !== undefined ? emailTemplateModuleAccess.createFlag : false) && (<Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                        disabled={disableSaveOrUpdateButton}
                        endIcon={smallScreen ? null : <IconSaveOrUpdate />} onClick={handleSubmit(onSubmit)}
                    > {smallScreen ? <IconSaveOrUpdate /> : saveOrUpdateButtonLabel} </Button>)}
                </Grid>
                <form className={classes.paperForCreateOrEditUser} onSubmit={handleSubmit(onSubmit)}>
                    <Paper style={{ height: 'auto', width: '100%', paddingTop: '10px', paddingBottom: '0.6%' }} elevation={3}>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacingForDailog}>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <EntityCodeCombo comboProperty={branchComboProperty} control={control} errors={errors} comboValue={branchValue} setComboValue={setBranchValue} />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <BpTypeCombo comboProperty={bpTypeComboProperty} control={control} errors={errors} comboValue={bpTypeValue} setComboValue={setBpTypeValue} resetComboValueForBpType={resetComboValueForBpType} />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <BpCodeCombo comboProperty={bpCodeComboProperty} control={control} errors={errors}
                                    comboValue={bpCodeValue}
                                    bpTypeValue={bpTypeValue}
                                    setComboValue={setBpCodeValue}
                                    branchValue={branchValue}
                                />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.transConfName}
                                        rules={{
                                            required: true,
                                            maxLength: 30,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternWithAlphaNum_UHF
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textField.transmissionConfName.lable" })}
                                                error={errors.transConfName}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={mode === 'create' ? false : true}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.transConfName}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textField.transmissionConfName.lable" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.transConfName}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textField.transmissionConfName.lable" />
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanthirtycharsmessage" defaultMessage="Transmission Configuation Name cannot exceed more than 30 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.transConfName}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.transConfName} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.serviceProvider}
                                        rules={{
                                            required: true,
                                            maxLength: 30,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternWithAlphaNum_UHF
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.serviceprovide.label" })}
                                                error={errors.serviceProvider}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={mode === 'create' ? false : true}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.serviceProvider}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.serviceprovide.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.serviceProvider}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                 <FormattedMessage id="textfield.serviceprovide.label" />
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanthirtycharsmessage" defaultMessage="Service Provider cannot exceed more than 30 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.serviceProvider}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.serviceProvider} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <TransmissionConfigServiceTypeCombo
                                    comboProperty={serviceTypeComboProperty}
                                    control={control} errors={errors}
                                    comboValue={as2VanProtocalValue}
                                    setComboValue={setAs2VanProtocalValue}
                                    resetValuesForAS2VAN={resetValuesForAS2VAN}
                                />
                            </Grid>
                            {as2VanProtocalValue.value === 'VAN' ? <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.qualifier}
                                        rules={{
                                            required: true,
                                            maxLength: 30,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternWithAlphaNum_UHF
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.qualifier.label" })}
                                                error={errors.qualifier}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.qualifier}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.qualifier.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.qualifier}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                 <FormattedMessage id="textfield.qualifier.label"/>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanthirtycharsmessage" defaultMessage="Qualifier cannot exceed more than 30 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.qualifier}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.qualifier} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid> : null}
                            {as2VanProtocalValue.value === 'VAN' ? <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.mailBoxId}
                                        rules={{
                                            required: true,
                                            maxLength: 30,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternWithAlphaNum_UHF
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.mailboxid.label" })}
                                                error={errors.mailBoxId}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.mailBoxId}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.mailboxid.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.mailBoxId}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.mailboxid.label"/>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanthirtycharsmessage" defaultMessage="Mail Box ID cannot exceed more than 30 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.mailBoxId}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.mailBoxId} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid> : null}
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.entityEdiAddresses}
                                        rules={{
                                            required: true,
                                            maxLength: 50,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumericAndSpecialCharAndAvoidSpace
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.entityEdiAddresses.label" })}
                                                error={errors.entityEdiAddresses}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.entityEdiAddresses}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.entityEdiAddresses.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.entityEdiAddresses}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.entityEdiAddresses.label"/>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" defaultMessage="Entity EDI address cannot exceed more than 50 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.entityEdiAddresses}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{intl.formatMessage({ id: "textfield.entityEdiAddresses.label" })} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumericAndSpecialChar}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.entityEdiQulifier}
                                        rules={{
                                            required: false,
                                            maxLength: 30,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternWithAlphaNum_UHF
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required={false}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.entityEdiQulifier.label" })}
                                                error={errors.entityEdiQulifier}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.entityEdiQulifier}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanthirtycharsmessage" defaultMessage="cannot exceed more than 30 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.entityEdiQulifier}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.entityEdiQulifier} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            {as2VanProtocalValue.value === 'VAN' ? <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.ssid}
                                        rules={{
                                            required: true,
                                            maxLength: 30,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumeric
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.ssid.label" })}
                                                error={errors.ssid}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.ssid}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.ssid.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.ssid}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                 <FormattedMessage id="textfield.ssid.label"/>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanthirtycharsmessage" defaultMessage="SSID cannot exceed more than 30 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.ssid}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.ssid} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumeric}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid> : null}
                            {as2VanProtocalValue.value === 'VAN' ? <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.sfid}
                                        rules={{
                                            required: true,
                                            maxLength: 30,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumeric
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.sfid.label" })}
                                                error={errors.sfid}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.sfid}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.sfid.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.sfid}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                 <FormattedMessage id="textfield.sfid.label"/>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanthirtycharsmessage" defaultMessage="SFID cannot exceed more than 30 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.sfid}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.sfid} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumeric}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid> : null}
                            {as2VanProtocalValue.value === 'VAN' ? <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.isdnNumber}
                                        rules={{
                                            required: true,
                                            maxLength: 100,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumeric
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.isdnnumber.label" })}
                                                error={errors.isdnNumber}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.isdnNumber}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.isdnnumber.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.isdnNumber}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                  <FormattedMessage id="textfield.isdnnumber.label"/>
                                                  <FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" defaultMessage="ISDN Number cannot exceed more than 100 chars" /> 
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.isdnNumber}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.isdnNumber} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumeric}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid> : null}
                            {as2VanProtocalValue.value === 'VAN' ? <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.isdnPassword}
                                        rules={{
                                            required: true,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumericAndSpecialCharAndAvoidSpace,
                                            maxLength: 100,
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="password"
                                                size='small'
                                                autoComplete="new-password"
                                                label={intl.formatMessage({ id: "textfield.isdnpassword.label" })}
                                                error={errors.isdnPassword}
                                                {...field}
                                                required={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                inputProps={{
                                                    type: "password",
                                                    autoComplete: "new-password",
                                                }}
                                                InputProps={{
                                                    readOnly: userReadOnlyFields.password,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.isdnPassword}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.isdnpassword.label"/>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" defaultMessage="ISDN Password cannot exceed more than 100 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.isdnPassword}.type`, errors) === "required" && (
                                             <FormHelperText className={classes.error}>
                                                 <FormattedMessage id="textfield.isdnpassword.label" />
                                                 <FormattedMessage id="textfield.error.isrequirededmessage" defaultMessage="Password is required" />
                                             </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.isdnPassword}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{intl.formatMessage({ id: "textfield.isdnpassword.label" })} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumericAndSpecialChar}</FormHelperText>
                                        )}
                                    </div>

                                </FormControl>
                            </Grid> : null}
                            {as2VanProtocalValue.value !== 'VAN' ? <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.uri}
                                        rules={{
                                            required: true,
                                            maxLength: 100,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForBaseURI,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.uri.label" })}
                                                error={errors.uri}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.uri}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.uri.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.uri}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" defaultMessage="cannot exceed more than 100 chars" />} </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.uri}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{<FormattedMessage id="common.errorForBaseURI.message" defaultMessage="can contain : // . , ()" />} </FormHelperText>
                                        )}

                                    </div>
                                </FormControl>
                            </Grid> : null}
                            {as2VanProtocalValue.value !== 'VAN' ? <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.as2Identifier}
                                        rules={{
                                            required: true,
                                            maxLength: 50,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumericAndSpecialCharAndAvoidSpace
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.as2identifier.label" })}
                                                error={errors.as2Identifier}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.as2Identifier}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.as2identifier.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.as2Identifier}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.as2identifier.label"/>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" defaultMessage="AS2 Identifier cannot exceed more than 50 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.as2Identifier}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{intl.formatMessage({ id: "textfield.as2identifier.label" })} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumericAndSpecialChar}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid> : null}
                            {as2VanProtocalValue.value !== 'VAN' ? <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.contentType}
                                        rules={{
                                            required: true,
                                            maxLength: 30,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumeric
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.contenttype.label" })}
                                                error={errors.contentType}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.contentType}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.contenttype.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.contentType}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.contenttype.label" />
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanthirtycharsmessage" defaultMessage="Content Type cannot exceed more than 30 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.contentType}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.contentType} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumeric}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid> : null}
                            {as2VanProtocalValue.value !== 'VAN' ? <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.mdnRequestType}
                                        rules={{
                                            required: true,
                                            maxLength: 30,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumeric
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.mdnrequesttype.label" })}
                                                error={errors.mdnRequestType}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.mdnRequestType}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.mdnrequesttype.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.mdnRequestType}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                 <FormattedMessage id="textfield.mdnrequesttype.label"/>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanthirtycharsmessage" defaultMessage="MDN Request Type cannot exceed more than 30 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.mdnRequestType}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.mdnRequestType} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumeric}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid> : null}

                            {as2VanProtocalValue.value !== 'VAN' ? <Grid item container xs={12} sm={12} md={3} lg={3}>
                                <Grid container justifyContent='flex-start' alignItems='center'>
                                    <Controller
                                        render={({
                                            field: { onChange } }) => (<Switch
                                                color='primary'
                                                checked={ftpSftpConfigToBeEdited.mdnSigned !== undefined ? ftpSftpConfigToBeEdited.mdnSigned : true}
                                                onChange={((event) => {
                                                    onChange(!(ftpSftpConfigToBeEdited.mdnSigned !== undefined ? ftpSftpConfigToBeEdited.mdnSigned : true));
                                                    setFtpSftpConfigToBeEdited({ ...ftpSftpConfigToBeEdited, mdnSigned: event.target.checked })
                                                })}
                                                disabled={mode === 'read' ? disabledForReadMode : false}
                                            />)}
                                        type="checkbox"
                                        name={pageLabelsConstantsAndMessages.name.mdnSigned}
                                        control={control}
                                        defaultValue={ftpSftpConfigToBeEdited.mdnSigned !== undefined ? ftpSftpConfigToBeEdited.mdnSigned : true}
                                    />
                                    <InputLabel>{<FormattedMessage id="textfield.mdnsigned.label" />}</InputLabel>
                                </Grid>
                            </Grid> : null}
                            <Grid item container xs={12} sm={12} md={3} lg={3}>
                                <Grid container justifyContent='flex-start' alignItems='center'>
                                    <Controller
                                        render={({
                                            field: { onChange } }) => (<Switch
                                                color='primary'
                                                checked={ftpSftpConfigToBeEdited.active !== undefined ? ftpSftpConfigToBeEdited.active : true}
                                                onChange={((event) => {
                                                    onChange(!(ftpSftpConfigToBeEdited.active !== undefined ? ftpSftpConfigToBeEdited.active : true));
                                                    setFtpSftpConfigToBeEdited({ ...ftpSftpConfigToBeEdited, active: event.target.checked })
                                                })}
                                                disabled={mode === 'read' ? disabledForReadMode : false}
                                            />)}
                                        type="checkbox"
                                        name={pageLabelsConstantsAndMessages.name.active}
                                        control={control}
                                        defaultValue={ftpSftpConfigToBeEdited.active !== undefined ? ftpSftpConfigToBeEdited.active : true}
                                    />
                                    <InputLabel>{<FormattedMessage id="common.active.lable" />}</InputLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                        {(isHideFtpSftp) && (<Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                            <Grid item container xs={12} sm={12} md={6} lg={12}>
                                <Grid container justifyContent='flex-start' alignItems='center'>
                                    <Controller
                                        render={({
                                            field: { onChange } }) => (<Switch
                                                color='primary'
                                                checked={ftpSftpConfigToBeEdited.status !== undefined ? ftpSftpConfigToBeEdited.status : false}
                                                onChange={((event) => {
                                                    onChange(!(ftpSftpConfigToBeEdited.status !== undefined ? ftpSftpConfigToBeEdited.status : false));
                                                    setFtpSftpConfigToBeEdited({ ...ftpSftpConfigToBeEdited, status: event.target.checked })
                                                })}
                                                hidden={true}
                                                disabled={mode === 'read' ? disabledForReadMode : false}
                                            />)}
                                        type="checkbox"
                                        name={pageLabelsConstantsAndMessages.name.status}
                                        control={control}
                                        defaultValue={ftpSftpConfigToBeEdited.status !== undefined ? ftpSftpConfigToBeEdited.status : false}
                                    />
                                    <InputLabel><FormattedMessage id="textfield.ftpsftp.label" /></InputLabel>
                                </Grid>
                            </Grid>
                        </Grid>)}

                    </Paper>
                    <Paper className={classes.gridHeightAndWidthForTransmissionConfigPaper} elevation={3}>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacingForDailog}>
                            <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                                <Typography><FormattedMessage id="transmissionvendordetails.heading.title" /></Typography>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={4} lg={4}>
                                <TransmissionVendorCombo
                                    comboProperty={vendorNameComboProperty}
                                    control={control} errors={errors}
                                    comboValue={vendorNameValue}
                                    setComboValue={setVendorNameValue}
                                    branchValue={branchValue}
                                />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={4} lg={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <FormControl fullWidth>
                                        <Controller
                                            control={control}
                                            name={pageLabelsConstantsAndMessages.name.licenceFromDate}
                                            rules={{ required: true }}
                                            defaultValue={licenceFromDate}
                                            render={({ field }) => (
                                                <MobileDatePicker
                                                    renderInput={props => {
                                                        return <TextField {...props}
                                                            variant='outlined'
                                                            size="small"
                                                            label={<FormattedMessage id="textfield.label.licencevalidfrom" defaultMessage="Valid From  " />}
                                                            {...field}
                                                            required
                                                            error={errors.licenceFromDate}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {
                                                                    asterisk: classes.asterisk
                                                                }
                                                            }}
                                                        />;
                                                    }}
                                                    clearable
                                                    id="licenceFromDate"
                                                    inputFormat="dd-MM-yyyy"
                                                    maxDate={licenceToDate}
                                                    value={licenceFromDate}
                                                    onChange={((date) => {
                                                        changeLicenceFromDate(date);
                                                        setValue(`${pageLabelsConstantsAndMessages.name.licenceFromDate}`, date, { shouldValidate: true, shouldDirty: true });
                                                    })}
                                                    disabled={mode === 'read' ? true : false}
                                                />
                                            )}
                                        />
                                        <div className={classes.error}>
                                            {_.get(`${pageLabelsConstantsAndMessages.name.licenceFromDate}.type`, errors) === "required" && licenceFromDate === null && (
                                                <FormHelperText className={classes.error}>
                                                    <FormattedMessage id="textfield.label.licencevalidfrom" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                                </FormHelperText>
                                            )}
                                        </div>
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={4} lg={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <FormControl fullWidth>
                                        <Controller
                                            control={control}
                                            name={pageLabelsConstantsAndMessages.name.licenceToDate}
                                            render={({ field }) => (
                                                <MobileDatePicker
                                                    renderInput={props => {
                                                        return <TextField {...props}
                                                            variant='outlined'
                                                            size="small"
                                                            label={<FormattedMessage id="textfield.label.licencevalidto" defaultMessage="Valid To" />}
                                                            {...field}
                                                            error={errors.licenceToDate}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />;
                                                    }}
                                                    clearable
                                                    id="licenceToDate"
                                                    inputFormat="dd-MM-yyyy"
                                                    minDate={licenceFromDate}
                                                    value={licenceToDate}
                                                    onChange={((date) => {
                                                        changeLicenceToDate(date);
                                                        setValue(`${pageLabelsConstantsAndMessages.name.licenceToDate}`, date, { shouldValidate: true, shouldDirty: true });
                                                    }
                                                    )}
                                                    name={pageLabelsConstantsAndMessages.name.licenceToDate}
                                                    disabled={mode === 'read' ? true : false}
                                                />
                                            )}
                                        />
                                        <div className={classes.error}>
                                            {(new Date() >= licenceToDate && licenceToDate !== null) ? <FormHelperText className={classes.error}>Transmission Vendor licence is overdue kindly check</FormHelperText> : ''}
                                        </div>
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>
                            <Grid><Divider /></Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <TransmissionConfigProtocolCombo
                                    comboProperty={protocolComboProperty}
                                    control={control} errors={errors}
                                    comboValue={ftpSftpProtocalValue}
                                    setComboValue={setFtpSftpProtocalValue}
                                    resetValuesForFTPSFTP={resetValuesForFTPSFTP}
                                />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.userId}
                                        rules={{
                                            required: true,
                                            maxLength: 50,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternWithAlphaNum_UHF
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.user.label" })}
                                                error={errors.userId}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={mode === 'read' ? true : false}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.userId}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.user.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.userId}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                 <FormattedMessage id="textfield.user.label"/>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" defaultMessage="User cannot exceed more than 50 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.userId}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}><FormattedMessage id="textfield.user.label"/> {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.bpEdiAddresses}
                                        rules={{
                                            required: true,
                                            maxLength: 50,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumericAndSpecialCharAndAvoidSpace
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.ediaddress.label" })}
                                                error={errors.bpEdiAddresses}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.bpEdiAddresses}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.ediaddress.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.bpEdiAddresses}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                 <FormattedMessage id="textfield.ediaddress.label"/>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" defaultMessage="EDI address cannot exceed more than 50 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.bpEdiAddresses}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{intl.formatMessage({ id: "textfield.ediaddress.label" })} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumericAndSpecialChar}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.ediQulifier}
                                        rules={{
                                            required: false,
                                            maxLength: 30,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternWithAlphaNum_UHF
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required={false}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.ediQulifier.label" })}
                                                error={errors.ediQulifier}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.ediQulifier}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanthirtycharsmessage" defaultMessage="cannot exceed more than 30 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.ediQulifier}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.ediQulifier} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.password}
                                        rules={{
                                            required: false,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumericAndSpecialCharAndAvoidSpace,
                                            maxLength: 100
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.password.label" })}
                                                error={errors.password}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                inputProps={{
                                                    type: "password",
                                                    autoComplete: "new-password",
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.password}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.password} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumericAndSpecialChar}</FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.password}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>{<FormattedMessage id="texfield.error.password" defaultMessage="Password is required" />} </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.password}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.error.passwordmaxlength" defaultMessage="Password cannot exceed more than 100 chars" />} </FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={9} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.sftpPrvKey}
                                        rules={{
                                            required: false,
                                            maxLength: 200,
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField 
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.sftpPrevKey.label" })}
                                                {...field}
                                                value={selectedFileName}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                disabled={mode === 'read' ? true : false
                                                }
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container xs={3} sm={6} md={1} lg={1}>
                                <label htmlFor="contained-button-file">
                                    <Fab size='small'
                                        color="primary" component="span"
                                    ><AttachFile /></Fab>
                                </label>
                                <input
                                    accept={".pem"}
                                    className={classes.input}
                                    id="contained-button-file"
                                    name={pageLabelsConstantsAndMessages.name.sftpPrvKey}
                                    type="file"
                                    onChange={((event) => {
                                        event.preventDefault();
                                        if (event.target.files[0] !== undefined) {
                                            setSelectedFileName(event.target.files[0].name);
                                            setSelectedFile(event.target.files[0]);
                                        }
                                    })}
                                />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.host}
                                        rules={{
                                            required: true,
                                            maxLength: 100,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumericAndSpecialCharAndAvoidSpace,
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.ip.label" })}
                                                error={errors.host}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={mode === 'read' ? true : false}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.host}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.ip.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.host}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{intl.formatMessage({ id: "textfield.ip.label" })} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumericAndSpecialChar}</FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.host}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                  <FormattedMessage id="textfield.ip.label"/>
                                                  <FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" defaultMessage="IP cannot exceed more than 100 chars" />
                                                </FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={2}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.port}
                                        rules={{
                                            required: true,
                                            maxLength: 6,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternNumericOnly
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.puerto.label" })}
                                                error={errors.port}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={mode === 'read' ? true : false}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.port}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.puerto.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.port}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.port} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForNumericOnly}</FormHelperText>
                                        )}

                                        {_.get(`${pageLabelsConstantsAndMessages.name.port}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.error.cannotexceedmorethansixcharsmessage" defaultMessage="cannot exceed more than 6 chars" />} </FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.inbox}
                                        rules={{
                                            required: true,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumericAndSpecialCharAndAvoidSpace,
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.inbox.label" })}
                                                error={errors.inbox}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={mode === 'read' ? true : false}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.inbox}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.inbox.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.inbox}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanthirtycharsmessage" defaultMessage="cannot exceed more than 30 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.inbox}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.inbox} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumericAndSpecialChar}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.outbox}
                                        rules={{
                                            required: true,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumericAndSpecialCharAndAvoidSpace,
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.outbox.label" })}
                                                error={errors.outbox}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={mode === 'read' ? true : false}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.outbox}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.outbox.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.outbox}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanthirtycharsmessage" defaultMessage="cannot exceed more than 30 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.outbox}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.outbox} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumericAndSpecialChar}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            {/* <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.sftpPrvKey}
                                        rules={{
                                            required: true,
                                            maxLength: 200,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumericAndSpecialCharAndSpace,
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.sftpPrevKey.label" })}
                                                error={errors.sftpPrvKey}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={mode === 'read' ? true : false}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.sftpPrvKey}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.sftpPrevKey.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.sftpPrvKey}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethantwohundredcharsmessage" defaultMessage="cannot exceed more than 200 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.sftpPrvKey}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.sftpPrvKey} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumericAndSpecialCharAndSpace}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid> 
                            {ftpSftpProtocalValue.value !== 'FTP' || ftpSftpProtocalValue.value !== 'SFTP' ? <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.sftpPassPhrase}
                                        rules={{
                                            required: true,
                                            maxLength: 200,
                                            pattern: apiUrlAndParamBodyConstants.commonTextfeildPattern.patternForAlphaNumericAndSpecialCharAndSpace,
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                required={true}
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                label={intl.formatMessage({ id: "textfield.sftppassphrase.label" })}
                                                error={errors.sftpPassPhrase}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                disabled={mode === 'read' ? true : false}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.sftpPassPhrase}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.sftppassphrase.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.sftpPassPhrase}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfield.error.cannotexceedmorethantwohundredcharsmessage" defaultMessage="cannot exceed more than 200 chars" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.sftpPassPhrase}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.name.sftpPassPhrase} {apiUrlAndParamBodyConstants.commonErrorsForTextFields.errorForAlphaNumericAndSpecialCharAndSpace}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid> : null} */}
                        </Grid>
                    </Paper>
                </form>
            </Grid >
        </React.Fragment >
    )
}

export default CreateOrEditTransmissionConfiguration;