import React, { useEffect, useState } from 'react';
import _ from "lodash/fp";
import { FormControl, FormHelperText, TextField, Grid, CssBaseline } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useStyles from '../../../common/CommonStyle/CommonStyle'


function OthersFormOrg(props) {
    const classes = useStyles();
    const { othersmode, control, errors, setHasErrors } = props

    const [organizationReadOnlyFields, setOrganizationReadOnlyFields] = useState({
        emailDomains: false,
        webUrL: false,
        Active: false
    });

    const pageLabelsConstantsAndMessages = {
        label: {
            emailDomains: 'Email Domains',
            webUrl: 'webUrl',

        },
        name: {
            emailDomains: 'emailDomains',
            webUrl: 'webUrl',

        }
    }


    useEffect(() => {
        if (othersmode === 'view') {
            setOrganizationReadOnlyFields({
                ...organizationReadOnlyFields, emailDomains: true,
                webUrl: true,

            })
        }
    }, [othersmode]);

    useEffect(() => {
        setHasErrors(errors)
    }, [errors])


    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.webUrl}
                                    rules={othersmode === 'view' ? { required: false } : {
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            type="url"
                                            variant="outlined"
                                            margin="normal"
                                            id="webUrl"
                                            size='small'
                                            label={<FormattedMessage id="columnname.WebURL.label" defaultMessage="Web URL" />}
                                            {...field}
                                            error={errors.webUrl}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.webUrl,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={othersmode === 'view' ? true : false}
                                        />
                                    )}
                                />
                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.webUrl}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.label.webUrl} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.webUrl}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.label.webUrl} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.webUrl}.type`, errors) === "pattern" && (<FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.label.webUrl} {<FormattedMessage id="textfield.Pleaseentervalidmessage" />} {pageLabelsConstantsAndMessages.label.webUrl}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={9} lg={9}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.emailDomains}
                                    rules={othersmode === 'view' ? { required: false } : {
                                        required: true,
                                        pattern: /\S+\S+\.\S+/
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required={true}
                                            multiline
                                            maxRows={2}
                                            variant="outlined"
                                            margin="normal"
                                            id="emailDomains"
                                            size='small'
                                            label={<FormattedMessage id="columnname.EmailDomains.label" defaultMessage="Email Domains" />}
                                            {...field}
                                            error={errors.emailDomains}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.emailDomains,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={othersmode === 'view' ? true : false}
                                        />
                                    )}
                                />
                                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={6} md={12} lg={12}>
                                    <FormHelperText><FormattedMessage id="texfield.placeholder.emaildomains.label"
                                        defaultMessage="Separate Multiple Email Domains's with Semicolon(;)" /></FormHelperText>
                                </Grid>


                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.emailDomains}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.EmailDomains.label" defaultMessage="Email Domains" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.emailDomains}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.EmailDomains.label" defaultMessage="Email Domains" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.emailDomains}.type`, errors) === "pattern" && (<FormHelperText className={classes.error}>{<FormattedMessage id="textfield.Pleaseentervalidmessage" />}{<FormattedMessage id="columnname.EmailDomains.label" defaultMessage="Email Domains" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.emailDomains}.type`, errors) === "pattern" && (<FormHelperText className={classes.error}>{<FormattedMessage id="common.errorForAlphaNum_UHF.message" />}{<FormattedMessage id="columnname.EmailDomains.label" defaultMessage="Email Domains" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={12} md={6} lg={3}>
                        </Grid>

                        <Grid item container xs={12} sm={12} md={6} lg={3}>
                        </Grid>

                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default OthersFormOrg;