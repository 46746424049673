import {
    AppBar, Autocomplete, Box, Button, CircularProgress, CssBaseline, Dialog, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Snackbar, Switch,
    Tab, Tabs, TextField, Tooltip, Typography, useMediaQuery, useTheme
} from '@mui/material';
import { withStyles } from '@mui/styles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import React, { useContext, useEffect, useState } from 'react';
import useStyles from '../../../common/CommonStyle/CommonStyle';
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomBreadCrumb from '../../../common/CustomBreadCrumb';
import PropTypes from 'prop-types';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import CustomSchedulerTab from '../../../../CustomSchedulerTab';
import { Controller, useForm } from 'react-hook-form';
import { CommonService } from '../../../../services';
import ProcessNameCombo from '../../../common/Combos/ProcessNameCombo';
import MessageFormatCombo from '../../../common/Combos/MessageFormatCombo/MessageFormatCombo';
import MessageTypeCombo from '../../../common/Combos/MessageTypeCombo/MessageTypeCombo';
import MessageVersionCombo from '../../../common/Combos/MessageVersionCombo/MessageVersionCombo';
import CommTypeCombo from '../../../common/Combos/CommTypeCombo/CommTypeCombo';
import CommIdCombo from '../../../common/Combos/CommIdCombo';
import { Alert } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';
import { ModuleAccessPermissionKey } from '../../../../Constants/ModuleAccessKey';
import { LoggedInUserDetails } from '../../../common/LoggedInUserDetails/LoggedInUserDetails';
import EmailTmplCombo from '../../../common/Combos/EmailTmplCombo';
import EmailRptTmplCombo from '../../../common/Combos/EmailRptTmplCombo/EmailRptTmplCombo';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import _ from "lodash/fp";
import DelimitersCombo from '../../../common/Combos/DelimitersCombo/DelimitersCombo';
import AmmendEmailTmplCombo from '../../../common/Combos/AmmendEmailTmplCombo/AmmendEmailTmplCombo';
import MsgTypeComboForAmendment from '../../../common/Combos/MsgTypeComboForAmendment';
import CreateAdditionalMsgConfigDtl from './CreateAdditionalMsgConfigDtl/CreateAdditionalMsgConfigDtl';
import AddIcon from '@mui/icons-material/Add';
import CustomSchedulerRedesignTab from '../../../../CustomSchedulerTab/CustomSchedulerRedesignTab';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),

    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disabletypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const CreateOrEditMessageConfigDtl = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const mobileScreen = useMediaQuery(theme.breakpoints.only('xs'))
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const messageConfigModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.messageConfig];
    const { open, onClose, mode, title, configId, DialogHeaderAndHeaderValue, msgConfigDtlData, msgId, tableData } = props;
    const [messageConfigDtlObjectToBeEditedOrView, setMessageConfigDtlObjectToBeEditedOrView] = useState(props.messageConfigDtlObjectToBeEditedOrView)

    const previousFrequency = props.messageConfigDtlObjectToBeEditedOrView.frequency;

    const [messageFormatList, setMessageFormatList] = useState([]);
    const [emailConfigNameList, setEmailConfigNameList] = useState([]);
    const [transConfigNameList, setTransConfigNameList] = useState([]);
    const [disableSaveOrUpdateButton, issaveOrUpdateButtonDisabled] = useState(true);
    const [msgDirectionValue, setMsgDirectionValue] = useState({ dataDisplay: '' });
    const [processNameValue, setProcessNameValue] = useState({ process: '', processId: '' });
    const [msgFormatValue, setMsgFormatValue] = useState({ msgFormat: '', msgFormatId: '' });
    const [msgTypeValue, setMsgTypeValue] = useState({ msgType: '' });
    const [relatedMsgTypeValue, setRelatedMsgTypeValue] = useState({ relatedMsgType: '' });
    const [msgVersionValue, setMsgVersionValue] = useState({ msgVersion: '' });
    const [commTypeValue, setCommTypeValue] = useState({ newValue: '' });
    const [commIdValue, setCommIdValue] = useState({ confName: '', commId: 0 });
    const [emailMessageTemplateValue, setEmailMessageTemplateValue] = useState({ name: '', id: 0 });
    const [emailReportTemplateValue, setEmailReportTemplateValue] = useState({ name: '', rptTmplId: 0 });
    const [relatedEmailRptTmplValue, setRelatedEmailRptTmplValue] = useState({ name: '', rptTmplId: 0 });
    const [amendmentEmailMessageTemplateValue, setAmendmentEmailMessageTemplateValue] = useState({ name: '', id: 0 });
    const [msgTypeAmendmentValue, setMsgTypeAmendmentValue] = useState({ value: '' });
    const [amendmentEmailReportTemplateValue, setAmendmentEmailReportTemplateValue] = useState({ name: '', rptTmplId: 0 });
    const [projectValue, setProjectValue] = useState({ project: '' });
    const [delimiters, setDelimiters] = useState({ value: '' })
    const [relatedProcessNameValue, setRelatedProcessNameValue] = useState({ process: '', processId: '' });
    const [timeZoneValue, setTimeZoneValue] = useState({ value: DialogHeaderAndHeaderValue.entity === undefined ? '' : DialogHeaderAndHeaderValue.entity.timeZone });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [disabledForReadMode, isDisabledForReadMode] = useState(false);
    const [showIcon, setShowIcon] = useState(null);
    const [msgConfigDtlToBeEdited, setMsgConfigDtlToBeEdited] = useState({});
    const [addtnMsgConfigActive, setAddtnlMsgConfigActive] = useState({ active: true });
    const [schedulerTab, setSchedulerTab] = useState([]);


    const groupMsgBy = [
        {
            value: 'BP_CODE',
            label: 'BP Code',
        },
        {
            value: 'BP_CODE_AND_WBS_CODE',
            label: 'BP Code & WBS Code',
        },
    ];
    const [groupMsgByValue, setGroupMsgByValue] = useState({ value: '', label: '' });

    useEffect(() => {
        setMsgConfigDtlToBeEdited(messageConfigDtlObjectToBeEditedOrView);
    }, []);

    const [addtnlMsgConfigDtlToBeEdited, setAddtnlMsgConfigDtlToBeEdited] = useState(messageConfigDtlObjectToBeEditedOrView.additionalMsgConfig !== undefined ?
        messageConfigDtlObjectToBeEditedOrView.additionalMsgConfig.map((item, index) => {
            return {
                id: item.id,
                uId: index,
                relatedProcess: {
                    process: item.relatedProcess.process,
                    processId: item.relatedProcess.processId
                },
                relatedMsgType: item.relatedMsgType,
                relatedEmailRptTemplate: item.relatedEmailRptTemplate,
                active: item.active
            }
        }) : []);

    useEffect(() => {
        if (mode === 'create') {
            (processNameValue.process === 'Purchase Order' || processNameValue.process === 'Purchase Order/Purchase Forecast') && msgFormatValue.msgFormat === 'FILE' ?
                msgTypeValue.msgType === 'EXCEL' ? setMsgTypeAmendmentValue({ value: 'EXCEL' }) : msgTypeValue.msgType === 'CSV' ? setMsgTypeAmendmentValue({ value: 'CSV' }) :
                    msgTypeValue.msgType === 'TXT' ? setMsgTypeAmendmentValue({ value: 'TXT' }) : msgTypeValue.msgType === 'EXCEL_2003' ? setMsgTypeAmendmentValue({ value: 'EXCEL_2003' }) : setMsgTypeAmendmentValue({ value: '' }) : setMsgTypeAmendmentValue({ value: '' })
        }
    }, [msgTypeValue])

    useEffect(() => {

        if (messageConfigDtlObjectToBeEditedOrView.groupMsgBy === null || messageConfigDtlObjectToBeEditedOrView.groupMsgBy === '' || messageConfigDtlObjectToBeEditedOrView.groupMsgBy === undefined) {
            if (processNameValue.process === 'PO PDF') {
                if (groupMsgByValue.value === '') {
                    setGroupMsgByValue(groupMsgBy[1])
                } else {
                    setGroupMsgByValue({
                        value: groupMsgByValue.value,
                        label: groupMsgByValue.label
                    });
                }
            } else if (processNameValue.process === 'VMI Consumption Report') {
                setGroupMsgByValue(groupMsgBy[0])
            } else {
                setGroupMsgByValue({ value: '', label: '' })
            }
        } else {
            setGroupMsgByValue({
                value: groupMsgByValue.value,
                // label: messageConfigDtlObjectToBeEditedOrView.groupMsgBy === groupMsgBy[0].value ? groupMsgBy[0].label : groupMsgBy[1].label
                label: groupMsgByValue.label
            });
        }
    }, [processNameValue, messageConfigDtlObjectToBeEditedOrView])

    const pageLabelsConstantsAndMessages = {
        name: {
            processName: 'processName',
            inOut: 'inOut',
            msgFormat: 'msgFormat',
            msgType: 'msgType',
            msgVersion: 'msgVersion',
            frequency: 'frequency',
            commType: 'commType',
            commId: 'commId',
            approvalReqd: 'approvalReqd',
            progId: 'progId',
            active: 'active',
            emailMessageTemplate: 'emailMessageTemplate',
            reportTemplate: 'reportTemplate',
            project: 'project',
            timeZone: 'timeZone',
            delimiters: 'delimiters',
            headerReqd: 'headerReqd',
            amendmentApprovalReqd: 'amendmentApprovalReqd',
            amendmentEmailMessageTemplate: 'amendmentEmailMessageTemplate',
            amendmentReportTemplate: 'amendmentReportTemplate',
            amendedMsgType: 'amendedMsgType',
            combineNewAndAmended: 'combineNewAndAmended',
            combineSimilarMsg: 'combineSimilarMsg',
            groupMsgBy: 'groupMsgBy',
            amendmentProgId: "amendmentProgId",
            ackRequired: 'ackRequired',
            deljitProgId: 'deljitProgId',
            deljitApprovalReqd: 'deljitApprovalReqd'

        }
    }

    const [componentList, setComponentList] = useState('');
    const [tabvalue, setTabValue] = React.useState(0);
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
    const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('');
    const [userReadOnlyFields, setUserReadOnlyFields] = useState({
        processName: false,
        inOut: false,
        msgFormat: false,
        msgType: false,
        msgVersion: false,
        frequency: false,
        commType: false,
        commId: false,
        approvalReqd: false,
        progId: false,
        active: false,
        emailMessageTemplate: false,
        reportTemplate: false,
        project: false,
        delimiters: false,
        amendmentEmailMessageTemplate: false,
        amendmentReportTemplate: false,
    })

    const currentUser = CommonService.getLoggedInUserInfo();
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const { handleSubmit, reset, formState: { errors, isDirty, isValid }, control, getValues, setValue } = useForm({
        mode: "all",
        reValidateMode: 'all',
        defaultValues: {
            processName: '',
            inOut: '',
            msgFormat: '',
            msgType: '',
            msgVersion: '',
            frequency: '',
            commType: '',
            commId: '',
            approvalReqd: true,
            amendmentApprovalReqd: true,
            headerReqd: true,
            active: true,
            project: '',
            emailMessageTemplate: '',
            reportTemplate: null,
            minute: '',
            timeZone: '',
            delimiters: '',
            amendmentEmailMessageTemplate: '',
            amendmentReportTemplate: null,
            amendedMsgType: '',
            combineNewAndAmended: false,
            combineSimilarMsg: false,
            relatedProcessName: '',
            relatedMsgType: '',
            relatedEmailRptTemplate: '',
            groupMsgBy: '',
            progId: '',
            amendmentProgId: '',
            ackRequired: false,
            deljitApprovalReqd: true,
            deljitProgId: ''
        }
    });

    const handleClose = (value) => {
        setComponentList(title);
        handlereset(true);
        onClose(value);
        setSchedulerTab([]);
    }

    const handlereset = (value) => {
        reset({ keepErrors: true, keepDirty: true, keepIsSubmitted: false, keepTouched: false, keepIsValid: false, keepSubmitCount: false, });
        reset(value)
    }

    useEffect(() => {
        let currentRouterComponent;
        if (mode === 'create') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            isRestoreButtonDisabled(false);
            currentRouterComponent = [
                {
                    path: null,
                    name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                    iconName: CreateIcon,
                }
            ]
        } else if (mode === 'edit') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            setUserReadOnlyFields({ ...userReadOnlyFields, processName: true, inOut: true, msgFormat: true, msgType: true, msgVersion: true, frequency: false, commType: true, commId: true, approvalReqd: false, active: false, delimiters: false, amendmentEmailMessageTemplate: false, amendmentReportTemplate: false })
            isRestoreButtonDisabled(false);
            issaveOrUpdateButtonDisabled(true);
            setSchedulerTab(messageConfigDtlObjectToBeEditedOrView.msgScheduleFrequencies);
            resetingMsgConfigDtl(messageConfigDtlObjectToBeEditedOrView);
            currentRouterComponent = [
                {
                    path: null,
                    name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                    iconName: EditIcon,
                }
            ]
        } else if (mode === 'read') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isRestoreButtonDisabled(true);
            issaveOrUpdateButtonDisabled(true);
            isDisabledForReadMode(true);
            resetingMsgConfigDtl(messageConfigDtlObjectToBeEditedOrView);
            setSchedulerTab(messageConfigDtlObjectToBeEditedOrView.msgScheduleFrequencies);
            setUserReadOnlyFields({ ...userReadOnlyFields, processName: true, inOut: true, msgFormat: true, msgType: true, msgVersion: true, frequency: true, commType: true, commId: true, approvalReqd: true, active: true, emailMessageTemplate: true, reportTemplate: true, project: true, delimiters: true, amendmentEmailMessageTemplate: true, amendmentReportTemplate: true })
            currentRouterComponent = [
                {
                    path: null,
                    name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
                    iconName: ViewIcon,
                }
            ]
        }
        setComponentList(title.concat(currentRouterComponent !== undefined ? currentRouterComponent : []));
    }, [mode]);

    function forToResetInout() {
        if (DialogHeaderAndHeaderValue.bpType === 'CUSTOMER' && messageConfigDtlObjectToBeEditedOrView.inOut === 'IN') {
            return 'From Customer'
        } else if (DialogHeaderAndHeaderValue.bpType === 'CUSTOMER' && messageConfigDtlObjectToBeEditedOrView.inOut === 'OUT') {
            return 'To Customer'
        } else if (DialogHeaderAndHeaderValue.bpType === 'SUPPLIER' && messageConfigDtlObjectToBeEditedOrView.inOut === 'IN') {
            return 'From Supplier'
        } else if (DialogHeaderAndHeaderValue.bpType === 'SUPPLIER' && messageConfigDtlObjectToBeEditedOrView.inOut === 'OUT') {
            return 'To Supplier'
        }
        else if (DialogHeaderAndHeaderValue.bpType === 'SUPPLIER' && messageConfigDtlObjectToBeEditedOrView.inOut === 'OUT' && messageConfigDtlObjectToBeEditedOrView.processNameValue.process === 'Part Change Notification') {
            return 'To Internal'
        }
        else if (DialogHeaderAndHeaderValue.bpType === 'SUPPLIER' && messageConfigDtlObjectToBeEditedOrView.inOut === 'IN' && messageConfigDtlObjectToBeEditedOrView.processNameValue.process === 'Part Change Notification') {
            return 'To Internal'
        }
    }

    useEffect(() => {
        if (mode === 'edit' || mode === 'view') {
            setTimeZoneValue(messageConfigDtlObjectToBeEditedOrView.timeZone === null ? { value: DialogHeaderAndHeaderValue.entity === undefined ? '' : DialogHeaderAndHeaderValue.entity.timeZone } : { value: messageConfigDtlObjectToBeEditedOrView.timeZone });
        }
    }, [messageConfigDtlObjectToBeEditedOrView]);

    function resetingMsgConfigDtl(messageConfigDtlObjectToBeEditedOrView) {
        reset({
            frequency: messageConfigDtlObjectToBeEditedOrView.frequency !== undefined ? messageConfigDtlObjectToBeEditedOrView.frequency : '',
            processName: messageConfigDtlObjectToBeEditedOrView.processName !== undefined ? { process: messageConfigDtlObjectToBeEditedOrView.processName } : '',
            inOut: messageConfigDtlObjectToBeEditedOrView.inOut !== undefined ? { value: messageConfigDtlObjectToBeEditedOrView.inOut } : 'vdxcb',
            msgFormat: messageConfigDtlObjectToBeEditedOrView.msgFormat !== undefined ? { msgFormat: messageConfigDtlObjectToBeEditedOrView.msgFormat } : '',
            msgType: messageConfigDtlObjectToBeEditedOrView.msgType !== undefined ? { msgType: messageConfigDtlObjectToBeEditedOrView.msgType } : '',
            msgVersion: messageConfigDtlObjectToBeEditedOrView.msgVersion !== undefined ? { msgVersion: messageConfigDtlObjectToBeEditedOrView.msgVersion } : '',
            commType: messageConfigDtlObjectToBeEditedOrView.commType !== undefined ? { newValue: messageConfigDtlObjectToBeEditedOrView.commType } : '',
            commId: messageConfigDtlObjectToBeEditedOrView.confName !== undefined ? { confName: messageConfigDtlObjectToBeEditedOrView.confName } : '',
            project: messageConfigDtlObjectToBeEditedOrView.project !== undefined && messageConfigDtlObjectToBeEditedOrView.project !== null && messageConfigDtlObjectToBeEditedOrView.project !== undefined ? { project: messageConfigDtlObjectToBeEditedOrView.project } : '',

            emailMessageTemplate: messageConfigDtlObjectToBeEditedOrView.emailMessageTemplate !== undefined && messageConfigDtlObjectToBeEditedOrView.emailMessageTemplate !== null && messageConfigDtlObjectToBeEditedOrView.emailMessageTemplate.name !== undefined ? messageConfigDtlObjectToBeEditedOrView.emailMessageTemplate.name : '',

            reportTemplate: messageConfigDtlObjectToBeEditedOrView.reportTemplate !== undefined && messageConfigDtlObjectToBeEditedOrView.reportTemplate !== null && messageConfigDtlObjectToBeEditedOrView.reportTemplate.name !== undefined ? { name: messageConfigDtlObjectToBeEditedOrView.reportTemplate.name, rptTmplId: messageConfigDtlObjectToBeEditedOrView.reportTemplate.rptTmplId } : null,

            amendmentReportTemplate: messageConfigDtlObjectToBeEditedOrView.amendmentReportTemplate !== undefined && messageConfigDtlObjectToBeEditedOrView.amendmentReportTemplate !== null && messageConfigDtlObjectToBeEditedOrView.amendmentReportTemplate.name !== undefined ? { name: messageConfigDtlObjectToBeEditedOrView.amendmentReportTemplate.name, rptTmplId: messageConfigDtlObjectToBeEditedOrView.amendmentReportTemplate.rptTmplId } : null,

            timeZone: messageConfigDtlObjectToBeEditedOrView.timeZone !== undefined ? { value: messageConfigDtlObjectToBeEditedOrView.timeZone } : '',
            delimiters: messageConfigDtlObjectToBeEditedOrView.delimiters !== undefined ? { value: messageConfigDtlObjectToBeEditedOrView.delimiters } : '',

            amendedMsgType: messageConfigDtlObjectToBeEditedOrView.amendedMsgType !== undefined || messageConfigDtlObjectToBeEditedOrView.amendedMsgType !== null ? { value: messageConfigDtlObjectToBeEditedOrView.amendedMsgType } : null,
            groupMsgBy: messageConfigDtlObjectToBeEditedOrView.groupMsgBy !== undefined || messageConfigDtlObjectToBeEditedOrView.groupMsgBy !== null ? messageConfigDtlObjectToBeEditedOrView.groupMsgBy : '',
            progId: messageConfigDtlObjectToBeEditedOrView.progId !== undefined ? messageConfigDtlObjectToBeEditedOrView.progId : '',
            amendmentProgId: messageConfigDtlObjectToBeEditedOrView.amendmentProgId !== undefined ? messageConfigDtlObjectToBeEditedOrView.amendmentProgId : '',
            deljitProgId: messageConfigDtlObjectToBeEditedOrView.deljitProgId !== undefined ? messageConfigDtlObjectToBeEditedOrView.deljitProgId : ''
        });
        setProcessNameValue({
            process: (messageConfigDtlObjectToBeEditedOrView.processName !== undefined ? messageConfigDtlObjectToBeEditedOrView.processName : ''),
            processId: (messageConfigDtlObjectToBeEditedOrView.processId !== undefined ? messageConfigDtlObjectToBeEditedOrView.processId : messageConfigDtlObjectToBeEditedOrView.processName === 'Purchase Order/Purchase Forecast' ? 1 : messageConfigDtlObjectToBeEditedOrView.processName === 'Purchase Forecast' ? 2 : messageConfigDtlObjectToBeEditedOrView.processName === 'Advance Shipment Note' ? 3 : messageConfigDtlObjectToBeEditedOrView.processName === 'Invoice' ? 5 : messageConfigDtlObjectToBeEditedOrView.processName === 'Inventory Report' ? 6 : messageConfigDtlObjectToBeEditedOrView.processName === 'VMI Consumption Report' ? 7 : messageConfigDtlObjectToBeEditedOrView.processName === 'PO PDF' ? 8 : messageConfigDtlObjectToBeEditedOrView.processName === 'Purchase Order' ? 11 :
                messageConfigDtlObjectToBeEditedOrView.processName === 'Backlog' ? 34 :
                    messageConfigDtlObjectToBeEditedOrView.processName === 'Shipment' ? 35 : '')
        });
        setTimeZoneValue(messageConfigDtlObjectToBeEditedOrView.timeZone !== undefined ? { value: messageConfigDtlObjectToBeEditedOrView.timeZone } : '');
        // setMsgDirectionValue({ dataDisplay: (messageConfigDtlObjectToBeEditedOrView.inOut !== undefined ? forToResetInout() : '') })
        setMsgFormatValue({ msgFormat: (messageConfigDtlObjectToBeEditedOrView.msgFormat !== undefined ? messageConfigDtlObjectToBeEditedOrView.msgFormat : '') })
        setMsgTypeValue({ msgType: (messageConfigDtlObjectToBeEditedOrView.msgType !== undefined ? messageConfigDtlObjectToBeEditedOrView.msgType : '') })
        setMsgVersionValue({ msgVersion: (messageConfigDtlObjectToBeEditedOrView.msgVersion !== undefined ? messageConfigDtlObjectToBeEditedOrView.msgVersion : '') })
        setCommTypeValue({ newValue: (messageConfigDtlObjectToBeEditedOrView.commType) !== undefined ? messageConfigDtlObjectToBeEditedOrView.commType : '' })
        setCommIdValue({
            confName: (messageConfigDtlObjectToBeEditedOrView.confName) !== undefined ? messageConfigDtlObjectToBeEditedOrView.confName : '',
            commId: (messageConfigDtlObjectToBeEditedOrView.commId) !== undefined ? messageConfigDtlObjectToBeEditedOrView.commId : 0
        })
        setEmailMessageTemplateValue({
            name: messageConfigDtlObjectToBeEditedOrView.emailMessageTemplate !== undefined && messageConfigDtlObjectToBeEditedOrView.emailMessageTemplate !== null && messageConfigDtlObjectToBeEditedOrView.emailMessageTemplate.name !== undefined ? messageConfigDtlObjectToBeEditedOrView.emailMessageTemplate.name : '',

            id: messageConfigDtlObjectToBeEditedOrView.emailMessageTemplate !== undefined && messageConfigDtlObjectToBeEditedOrView.emailMessageTemplate !== null && messageConfigDtlObjectToBeEditedOrView.emailMessageTemplate.id !== undefined ? messageConfigDtlObjectToBeEditedOrView.emailMessageTemplate.id : 0,
        })
        setEmailReportTemplateValue({
            name: messageConfigDtlObjectToBeEditedOrView.reportTemplate !== undefined && messageConfigDtlObjectToBeEditedOrView.reportTemplate !== null && messageConfigDtlObjectToBeEditedOrView.reportTemplate.name !== undefined ? messageConfigDtlObjectToBeEditedOrView.reportTemplate.name : '',

            rptTmplId: messageConfigDtlObjectToBeEditedOrView.reportTemplate !== undefined && messageConfigDtlObjectToBeEditedOrView.reportTemplate !== null && messageConfigDtlObjectToBeEditedOrView.reportTemplate.rptTmplId !== undefined ? messageConfigDtlObjectToBeEditedOrView.reportTemplate.rptTmplId : 0,
        })

        setAmendmentEmailMessageTemplateValue({
            name: messageConfigDtlObjectToBeEditedOrView.amendmentEmailMessageTemplate !== undefined && messageConfigDtlObjectToBeEditedOrView.amendmentEmailMessageTemplate !== null && messageConfigDtlObjectToBeEditedOrView.amendmentEmailMessageTemplate.name !== undefined ? messageConfigDtlObjectToBeEditedOrView.amendmentEmailMessageTemplate.name : '',

            id: messageConfigDtlObjectToBeEditedOrView.amendmentEmailMessageTemplate !== undefined && messageConfigDtlObjectToBeEditedOrView.amendmentEmailMessageTemplate !== null && messageConfigDtlObjectToBeEditedOrView.amendmentEmailMessageTemplate.id !== undefined ? messageConfigDtlObjectToBeEditedOrView.amendmentEmailMessageTemplate.id : 0,
        })

        setDelimiters({
            value: messageConfigDtlObjectToBeEditedOrView.delimiters !== undefined ? messageConfigDtlObjectToBeEditedOrView.delimiters : '',
        })

        setMsgTypeAmendmentValue({
            value: messageConfigDtlObjectToBeEditedOrView.amendedMsgType !== undefined || messageConfigDtlObjectToBeEditedOrView.amendedMsgType !== null ? messageConfigDtlObjectToBeEditedOrView.amendedMsgType : '',
        })

        setGroupMsgByValue({ value: messageConfigDtlObjectToBeEditedOrView.groupMsgBy !== undefined || messageConfigDtlObjectToBeEditedOrView.groupMsgBy !== null ? messageConfigDtlObjectToBeEditedOrView.groupMsgBy : groupMsgByValue.value, label: (messageConfigDtlObjectToBeEditedOrView.groupMsgBy !== undefined || messageConfigDtlObjectToBeEditedOrView.groupMsgBy !== null) && messageConfigDtlObjectToBeEditedOrView.groupMsgBy === groupMsgBy[0].value ? groupMsgBy[0].label : groupMsgBy[1].label })

        if (messageConfigDtlObjectToBeEditedOrView.commId === 0) {
            setProjectValue({
                commId: (messageConfigDtlObjectToBeEditedOrView.commId) !== undefined ? messageConfigDtlObjectToBeEditedOrView.commId : 0
            })
        } else {
            setProjectValue({
                project: (messageConfigDtlObjectToBeEditedOrView.project) !== undefined ? messageConfigDtlObjectToBeEditedOrView.project : '',
                commId: (messageConfigDtlObjectToBeEditedOrView.commId) !== undefined ? messageConfigDtlObjectToBeEditedOrView.commId : 0
            })
        }
    }

    const processValue = [];
    useEffect(() => {

        if (msgConfigDtlData.length !== undefined) {

            for (let index = 0; index < msgConfigDtlData.length; index++) {

                if (msgConfigDtlData[index].processName === 'Purchase Order' || msgConfigDtlData[index].processName === 'Purchase Forecast' || msgConfigDtlData[index].processName === 'Purchase Order/Purchase Forecast') {

                    processValue.push(msgConfigDtlData[index].processName)

                }

            }
        }

    }, [msgConfigDtlData])

    function processNameValidationForAddtnMsgConfig() {

        const validationForAddtnMsgConfig = [];
        if (msgConfigDtlData.length !== undefined) {
            for (let index = 0; index < msgConfigDtlData.length; index++) {
                if (msgConfigDtlData[index].active) {
                    if (addtnlMsgConfigDtlToBeEdited.length > 0) {
                        if (msgConfigDtlData[index].processName === addtnlMsgConfigDtlToBeEdited[0].relatedProcess.process) {
                            validationForAddtnMsgConfig.push(msgConfigDtlData[index].processName)
                        }
                    } else if (msgConfigDtlData[index].additionalMsgConfig.length > 0) {
                        for (let idx = 0; idx < msgConfigDtlData[index].additionalMsgConfig.length; idx++) {
                            if (msgConfigDtlData[index].additionalMsgConfig[idx].active) {
                                if (processNameValue.process === msgConfigDtlData[index].additionalMsgConfig[idx].relatedProcess.process) {
                                    validationForAddtnMsgConfig.push(msgConfigDtlData[index].additionalMsgConfig[idx].relatedProcess.process)
                                }
                            }
                        }
                    }
                }
            }
        }
        return validationForAddtnMsgConfig
    }

    const additionalMessageConfig = addtnlMsgConfigDtlToBeEdited.map(addtnMsgConfig => ({
        ...addtnMsgConfig,
        msgId: messageConfigDtlObjectToBeEditedOrView.msgId,
        createdBy: currentUser.userName,
        createdTime: (messageConfigDtlObjectToBeEditedOrView.createdTime === undefined) ? '' : messageConfigDtlObjectToBeEditedOrView.createdTime,
        updatedBy: currentUser.userName,
        updatedTime: (messageConfigDtlObjectToBeEditedOrView.updatedTime === undefined) ? '' : messageConfigDtlObjectToBeEditedOrView.updatedTime
    })).forEach((item) => {
        delete item.uId
    });

    console.log('schedulerTab', schedulerTab)

    const onSubmit = data => {
        if ((processValue[0] === 'Purchase Order/Purchase Forecast') && data.processName.process === 'Purchase Order') {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: "Process Purchase Order/Purchase Forecast already exists", severity: "error" })
        } else if ((processValue[0] === 'Purchase Order/Purchase Forecast') && data.processName.process === 'Purchase Forecast') {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: 'Process Purchase Order/Purchase Forecast already exists', severity: "error" })
        } else if ((processValue[0] === 'Purchase Order' || processValue[0] === 'Purchase Forecast') && data.processName.process === 'Purchase Order/Purchase Forecast') {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: 'Process Purchase Order and Purchase Forecast already exists', severity: "error" })
        } else if (processNameValidationForAddtnMsgConfig().length > 0) {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: `Process ${processNameValidationForAddtnMsgConfig()[0]} already added as Message Config/Additional Msg Config`, severity: "error" })
        } else {
            data['msgId'] = messageConfigDtlObjectToBeEditedOrView.msgId;
            data['messageConfigHdr'] = { configId: configId }
            data['inOut'] = msgDirection.value;
            data['msgType'] = (mode === 'edit') ? (messageConfigDtlObjectToBeEditedOrView.msgType === undefined ? '' : messageConfigDtlObjectToBeEditedOrView.msgType) : (data.msgType !== undefined && data.msgType !== null ? data.msgType.msgType : '');
            data['msgFormat'] = (mode === 'edit') ? (messageConfigDtlObjectToBeEditedOrView.msgFormat === undefined ? '' : messageConfigDtlObjectToBeEditedOrView.msgFormat) : data.msgFormat !== undefined && data.msgFormat !== null ? data.msgFormat.msgFormat : '';
            if (msgFormatValue.msgFormat === 'EDIFACT') {
                data['msgVersion'] = (mode === 'edit') ? (messageConfigDtlObjectToBeEditedOrView.msgVersion === undefined ? '' : messageConfigDtlObjectToBeEditedOrView.msgVersion) : (data.msgVersion !== undefined && data.msgVersion !== null ? data.msgVersion.msgVersion : '');
            } else {
                data['msgVersion'] = '';
            }
            data['processName'] = (mode === 'edit') ? (messageConfigDtlObjectToBeEditedOrView.processName === undefined ? '' : messageConfigDtlObjectToBeEditedOrView.processName) : data.processName !== undefined && data.processName !== null ? data.processName.process : '';
            data['frequency'] = messageConfigDtlObjectToBeEditedOrView.frequency === undefined ? '* * * * *' : messageConfigDtlObjectToBeEditedOrView.frequency
            data['commType'] = (mode === 'edit') ? ((messageConfigDtlObjectToBeEditedOrView.commType === undefined) ? '' : messageConfigDtlObjectToBeEditedOrView.commType) : data.commType !== undefined ? data.commType : '';
            data['project'] = null;
            if (commTypeValue.newValue === 'Email') {
                data['commId'] = projectValue.commId;
            } else {
                data['commId'] = commIdValue.commId;
            }
            if (commTypeValue.newValue !== 'Email') {
                data['emailMessageTemplate'] = null;
                data['reportTemplate'] = null;
                data['amendmentEmailMessageTemplate'] = null;
                data['amendmentReportTemplate'] = null;
            } else if (((processNameValue.process === 'VMI Consumption Report' || processNameValue.process === 'PO PDF' || processNameValue.process === 'Purchase Forecast' || processNameValue.process === 'Inventory Report' ||
                processNameValue.process === 'Advance Shipment Note' || processNameValue.process === 'Invoice' || processNameValue.process === 'Part Change Notification' || processNameValue.process === 'Backlog' || processNameValue.process === 'Shipment') && commTypeValue.newValue === 'Email')) {
                data['emailMessageTemplate'] = emailMessageTemplateValue;
                data['amendmentEmailMessageTemplate'] = null;
                data['amendmentReportTemplate'] = null;
            } else if (((processNameValue.process === 'Purchase Order/Purchase Forecast' || processNameValue.process === 'Purchase Order') && commTypeValue.newValue === 'Email')) {
                data['emailMessageTemplate'] = emailMessageTemplateValue;
                data['amendmentEmailMessageTemplate'] = amendmentEmailMessageTemplateValue;
            } else {
                data['emailMessageTemplate'] = emailMessageTemplateValue;
            }
            data['headerReqd'] = mode === 'create' ? msgConfigDtlToBeEdited.headerReqd !== undefined ? msgConfigDtlToBeEdited.headerReqd : true : msgConfigDtlToBeEdited.headerReqd;
            data['amendmentApprovalReqd'] = mode === 'create' ? msgConfigDtlToBeEdited.amendmentApprovalReqd !== undefined ? msgConfigDtlToBeEdited.amendmentApprovalReqd : true : msgConfigDtlToBeEdited.amendmentApprovalReqd;
            data['approvalReqd'] = mode === 'create' ? msgConfigDtlToBeEdited.approvalReqd !== undefined ? msgConfigDtlToBeEdited.approvalReqd : mode === 'create' && msgFormatValue.msgFormat === 'FILE ATTACHMENT' || msgFormatValue.msgFormatId === 12 ? false : true : msgConfigDtlToBeEdited.approvalReqd;
            data['active'] = mode === 'create' ? msgConfigDtlToBeEdited.active !== undefined ? msgConfigDtlToBeEdited.active : true : msgConfigDtlToBeEdited.active;
            data['ackRequired'] = mode === 'create' ? msgConfigDtlToBeEdited.ackRequired !== undefined ? msgConfigDtlToBeEdited.ackRequired : false : msgConfigDtlToBeEdited.ackRequired;
            data['amendedMsgType'] = msgTypeAmendmentValue.value !== '' ? msgTypeAmendmentValue.value : null;
            data['combineNewAndAmended'] =
                msgTypeAmendmentValue.value === 'PDF' || msgTypeAmendmentValue.value === '' ? false : msgConfigDtlToBeEdited.combineNewAndAmended !== undefined ?
                    msgConfigDtlToBeEdited.combineNewAndAmended : true;
            data['delimiters'] = delimiters.value;
            data['relatedEmailRptTemplate'] = undefined;
            data['relatedMsgType'] = undefined;
            data['relatedProcessName'] = undefined;
            data["additionalMsgConfig"] = addtnlMsgConfigDtlToBeEdited.filter(addtnMsgConfig => {
                return addtnMsgConfig.relatedProcess !== ''
            }).map(addtnMsgConfig => ({
                ...addtnMsgConfig,
                //  id: messageConfigDtlObjectToBeEditedOrView.additionalMsgConfig.id,
                msgId: messageConfigDtlObjectToBeEditedOrView.msgId,
                createdBy: currentUser.userName,
                createdTime: (messageConfigDtlObjectToBeEditedOrView.createdTime === undefined) ? '' : messageConfigDtlObjectToBeEditedOrView.createdTime,
                updatedBy: currentUser.userName,
                updatedTime: (messageConfigDtlObjectToBeEditedOrView.updatedTime === undefined) ? '' : messageConfigDtlObjectToBeEditedOrView.updatedTime,
                // active: addtnMsgConfigActive.active !== undefined ? addtnMsgConfigActive.active : true
            }));
            data["msgScheduleFrequencies"] = schedulerTab.map((msgScheduleFrequencies) => {
                return {
                    ...msgScheduleFrequencies,
                    msgId: messageConfigDtlObjectToBeEditedOrView.msgId,
                    id: undefined
                }
            })
            data['createdBy'] = currentUser.userName;
            data['createdTime'] = (messageConfigDtlObjectToBeEditedOrView.createdTime === undefined) ? '' : messageConfigDtlObjectToBeEditedOrView.createdTime;
            data['updatedBy'] = currentUser.userName;
            data['updatedTime'] = (messageConfigDtlObjectToBeEditedOrView.updatedTime === undefined) ? '' : messageConfigDtlObjectToBeEditedOrView.updatedTime;
            data['timeZone'] = timeZoneValue.value;
            data['groupMsgBy'] = msgConfigDtlToBeEdited.combineSimilarMsg === true ? groupMsgByValue.value : '';
            data['combineSimilarMsg'] = msgConfigDtlToBeEdited.combineSimilarMsg;
            data['deljitApprovalReqd'] = mode === 'create' ? msgConfigDtlToBeEdited.deljitApprovalReqd !== undefined ? msgConfigDtlToBeEdited.deljitApprovalReqd : true : msgConfigDtlToBeEdited.deljitApprovalReqd;
            setIsFormSubmitted(true);
            CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.messageConfigDtl, data)
                .then((response) => {
                    setIsFormSubmitted(false);
                    onClose(true);
                    setSchedulerTab([]);
                })
                .catch(function (error) {
                    setIsFormSubmitted(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                });
        }
    }

    const resetAllClearThePageInfo = () => {
        if (mode === 'edit') {
            resetingMsgConfigDtl(messageConfigDtlObjectToBeEditedOrView);
            setMsgConfigDtlToBeEdited({ ...messageConfigDtlObjectToBeEditedOrView })
            setMessageConfigDtlObjectToBeEditedOrView({ ...messageConfigDtlObjectToBeEditedOrView, frequency: previousFrequency });
            setTabValue(0);
        } else {
            reset({
                approvalReqd: true,
                active: true,
                amendmentApprovalReqd: true,
                headerReqd: true,
                frequency: '',
                processName: null,
                inOut: '',
                msgVersion: '',
                msgFormat: '',
                msgType: '',
                commType: '',
                commId: '',
                emailMessageTemplate: '',
                reportTemplate: null,
                project: '',
                delimiters: '',
                amendmentReportTemplate: null,
                amendmentEmailMessageTemplate: '',
                amendedMsgType: '',
                combineNewAndAmended: false,
                combineSimilarMsg: false,
                relatedProcessName: '',
                groupMsgBy: '',
                ackRequired: false,
                deljitApprovalReqd: true
            });
            setProcessNameValue([]);
            // setMsgDirectionValue([]);
            setMsgFormatValue([]);
            setMsgVersionValue([]);
            setMsgTypeValue([]);
            setCommTypeValue([]);
            setCommIdValue([]);
            setDelimiters([]);
            setAmendmentEmailMessageTemplateValue([]);
            // setAmendmentEmailReportTemplateValue([]);
            setGroupMsgByValue(processNameValue.process === 'PO PDF' ? groupMsgBy[1] : groupMsgBy[0])
            setTimeZoneValue({ value: DialogHeaderAndHeaderValue.entity === undefined ? '' : DialogHeaderAndHeaderValue.entity.timeZone });
            setEmailMessageTemplateValue([]);
            setEmailReportTemplateValue([]);
            setMsgConfigDtlToBeEdited({ ...msgConfigDtlToBeEdited, active: true, approvalReqd: true, amendmentApprovalReqd: true, headerReqd: true, combineNewAndAmended: false, combineSimilarMsg: false, ackRequired: false, deljitApprovalReqd: true })
            setMessageConfigDtlObjectToBeEditedOrView({ ...messageConfigDtlObjectToBeEditedOrView, active: true, ackRequired: false, approvalReqd: true, amendmentApprovalReqd: true, deljitApprovalReqd: true, headerReqd: true, frequency: '* * * * *' });
            setMsgTypeAmendmentValue({ value: '' })
            setTabValue(0);
            setSchedulerTab([])
        }
    }

    const resetComboValueForProcessName = () => {
        reset({
            processName: null,
            // inOut: '',
            msgVersion: '',
            msgFormat: '',
            msgType: '',
            commType: '',
            commId: '',
            emailMessageTemplate: '',
            reportTemplate: null,
            amendmentReportTemplate: null,
            project: '',
            progId: '',
            amendmentProgId: '',
            deljitProgId: ''
        })
        setProcessNameValue([]);
        // setMsgDirectionValue({ ...msgDirectionValue, ['dataDisplay']: '' });
        setMsgFormatValue({ ...msgFormatValue, ['msgFormat']: '' });
        setMsgVersionValue({ ...msgVersionValue, ['msgVersion']: '' });
        setMsgTypeValue({ ...msgTypeValue, ['msgType']: '' });
        setCommTypeValue({ ...commTypeValue, ['newValue']: '' });
        setCommIdValue({ ...commIdValue, ['confName']: '' });
        setProjectValue({ ...projectValue, ['project']: '' })
        setEmailMessageTemplateValue({ ...emailMessageTemplateValue, ['name']: '' });
        setEmailReportTemplateValue({ ...emailReportTemplateValue, ['name']: '' });
        setAmendmentEmailMessageTemplateValue({ ...amendmentEmailMessageTemplateValue, ['name']: '' });
        setDelimiters({ ...delimiters, ['value']: '' })
        setMsgTypeAmendmentValue({ ...msgTypeAmendmentValue, ['value']: '' });
        setValue('amendmentProgId', '');
        setValue('progId', '');
        setValue('deljitProgId', '');
    }

    const resetComboValueForCommType = () => {
        if (commTypeValue.newValue !== 'Email') {
            setCommIdValue({ ...commIdValue, ['commId']: null, ['confName']: '' });
        } else {
            setProjectValue({ ...projectValue, ['project']: '', ['commId']: null })
            setEmailMessageTemplateValue({ ...emailMessageTemplateValue, ['name']: '' });
            setEmailReportTemplateValue({ ...emailReportTemplateValue, ['name']: '', ['rptTmplId']: 0 });
            setAmendmentEmailMessageTemplateValue({ ...amendmentEmailMessageTemplateValue, ['name']: '' });
            setDelimiters({ ...delimiters, ['value']: '' })
            setMsgTypeAmendmentValue({ ...msgTypeAmendmentValue, ['value']: '' })
        }
    }

    const resetComboValueForMessageFormat = () => {
        setMsgVersionValue({ ...msgVersionValue, ['msgVersion']: '' });
        setMsgTypeValue({ ...msgTypeValue, ['msgType']: '' });
        setCommTypeValue({ ...commTypeValue, ['newValue']: '' });
        setCommIdValue({ ...commIdValue, ['commId']: null, ['confName']: '' });
        setProjectValue({ ...projectValue, ['project']: '', ['commId']: null })
        setEmailMessageTemplateValue({ ...emailMessageTemplateValue, ['name']: '' });
        setEmailReportTemplateValue({ ...emailReportTemplateValue, ['name']: '', ['rptTmplId']: 0 });
        setDelimiters({ ...delimiters, ['value']: '' })
        setMsgTypeAmendmentValue({ ...msgTypeAmendmentValue, ['value']: '' });
        setValue('amendmentProgId', '');
        setValue('progId', '');
        setValue('deljitProgId', '');
    }

    const resetComboValueForMessageType = () => {
        setMsgVersionValue({ ...msgVersionValue, ['msgVersion']: '' });
        setDelimiters({ ...delimiters, ['value']: '' })
        setMsgTypeAmendmentValue({ ...msgTypeAmendmentValue, ['value']: '' });
    }

    const processNameComboProperty = {
        name: pageLabelsConstantsAndMessages.name.processName,
        isRequired: true,
        label: intl.formatMessage({ id: "columnname.process.label" }),
        isAutofocus: true,
        isDisabled: userReadOnlyFields.processName,
        variant: 'outlined',
        errors: errors.processName,
        isHookFormType: true,
    }

    const messageFormatComboProperty = {
        name: pageLabelsConstantsAndMessages.name.msgFormat,
        isRequired: true,
        label: intl.formatMessage({ id: "columnname.messageformat.label" }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.msgFormat,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.msgFormat
    }

    const messageTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.msgType,
        isRequired: true,
        label: intl.formatMessage({ id: "columnname.messagetype.label" }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.msgType,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.msgType
    }

    const messageVersionComboProperty = {
        name: pageLabelsConstantsAndMessages.name.msgVersion,
        isRequired: false,
        label: intl.formatMessage({ id: "columnname.messageversion.label" }),
        isAutofocus: false,
        isDisabled: msgFormatValue.msgFormat === 'FILE' || msgFormatValue.msgFormat === 'FILE ATTACHMENT' ? true : userReadOnlyFields.msgVersion,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.msgVersion
    }

    const commTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.commType,
        isRequired: true,
        label: intl.formatMessage({ id: "columnname.commType.label" }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.commType,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.commType
    }

    const emailMessageTemplateComboProperty = {
        name: pageLabelsConstantsAndMessages.name.emailMessageTemplate,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.label.emailmessagetemplate" }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.emailMessageTemplate,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.emailMessageTemplate
    }

    const emailReportTemplateComboProperty = {
        name: pageLabelsConstantsAndMessages.name.reportTemplate,
        isRequired: false,
        label: intl.formatMessage({ id: 'textfield.emailReportTemplate.label' }),
        isAutofocus: false,
        isDisabled: msgFormatValue.msgFormat === 'FILE ATTACHMENT' ? true : userReadOnlyFields.reportTemplate,
        variant: 'outlined',
        isHookFormType: true,
        // optionLableToBeVisibleInDropDwon: 'name',
        errors: errors.reportTemplate
    }

    const commIdComboProperty = {
        name: pageLabelsConstantsAndMessages.name.commId,
        isRequired: true,
        label: intl.formatMessage({ id: "common.modue.breadcrumb.transconfig" }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.commId,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.commId
    }

    const delimiterComboProperty = {
        name: pageLabelsConstantsAndMessages.name.delimiters,
        isRequired: true,
        label: intl.formatMessage({ id: "columnname.delimiter.label" }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.delimiters,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.delimiters
    }

    const ammendEmailMessageTemplateComboProperty = {
        name: pageLabelsConstantsAndMessages.name.amendmentEmailMessageTemplate,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.label.ammendEmailmessagetemplate" }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.amendmentEmailMessageTemplate,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.amendmentEmailMessageTemplate
    }

    const msgTypeAmendmentComboProperty = {
        name: pageLabelsConstantsAndMessages.name.amendedMsgType,
        isRequired: true,
        label: intl.formatMessage({ id: "columnname.amendmentmsgtype.text" }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.amendmentReportTemplate,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.amendedMsgType
    }

    const onError = errors => {
        setAlertBoxState({ ...alertBoxState, openAlert: true, message: "Form Has Errors", severity: "error", err: true }
        )
    }


    const [validateRequiredFields, setValidateRequiredFields] = useState(false);
    useEffect(() => {
        let validatePORequiredField = !((getValues('emailMessageTemplate') !== '') && (amendmentEmailMessageTemplateValue.name !== '') &&
            ((getValues('amendedMsgType') !== null) || (getValues('amendedMsgType') !== '')));
        let process = (processNameValue.process === "Shipment");

        let validateProcessNameEmailType = ((processNameValue.process === 'VMI Consumption Report' || processNameValue.process === 'PO PDF' ||
            processNameValue.process === 'Purchase Forecast' || processNameValue.process === 'Advance Shipment Note' || processNameValue.process === 'Invoice' || processNameValue.process === 'Backlog' ||
            processNameValue.process === 'Packing List' || processNameValue.process === 'Part Change Notification') && (process) && commTypeValue.newValue === 'Email') ? !(getValues('emailMessageTemplate') !== '') :
            !((getValues('emailMessageTemplate') !== '') || (getValues('amendmentEmailMessageTemplate') !== '') ||
                (getValues('amendedMsgType') !== '') || (getValues('amendedMsgType') !== null));

        const validateRequiredFields =
            ((mode === 'create') && (!(getValues('processName') !== '' && getValues('inOut') !== '' && getValues('msgFormat') !== '' && getValues('msgType') !== '' && getValues('commType') !== '' && (getValues('commId') !== ''
                || (getValues('emailMessageTemplate') !== '') || (getValues('amendmentEmailMessageTemplate') !== '') ||
                (getValues('amendedMsgType') !== '') || (getValues('amendedMsgType') !== null)))))

            || ((processNameValue === '') || (msgDirection === '') || (msgFormatValue === '') || (msgTypeValue === '') || (commTypeValue === '')
                || (commIdValue === '') || (emailMessageTemplateValue === '') || (amendmentEmailMessageTemplateValue === '') || msgTypeAmendmentValue === '' || (amendmentEmailReportTemplateValue === '') ||
                (processNameValue.process === 'Purchase Order/Purchase Forecast' || processNameValue.process === 'Purchase Order') &&
                msgFormatValue.msgFormat !== 'EDIFACT' ? validatePORequiredField :
                (mode === 'create' ? msgTypeAmendmentValue.value !== '' : msgTypeAmendmentValue.value !== null))

            || ((mode === 'edit' || mode === 'create') && validateProcessNameEmailType)

            || mode === 'create' && (commTypeValue.newValue !== 'Email') && commIdValue.commId === null


            || ((mode === 'edit' || mode === 'create') && ((processNameValue.process === 'VMI Consumption Report' || processNameValue.process === 'PO PDF' || processNameValue.process === 'Advance Shipment Note' || processNameValue.process === 'Invoice' || processNameValue.process === 'Packing List' || processNameValue.process === 'Purchase Forecast' || processNameValue.process === 'Part Change Notification' || processNameValue.process === 'Backlog' || processNameValue.process === 'Shipment') && commTypeValue.newValue === 'Email') ? emailMessageTemplateValue.name === '' : (msgFormatValue.msgFormat === 'EDIFACT') ? msgFormatValue.msgFormat === '' :
                amendmentEmailMessageTemplateValue.name === '');

        setValidateRequiredFields(validateRequiredFields)
        let finalValidateRequiredFields = validateRequiredFields || ((mode === 'create') && msgFormatValue.msgFormatId === null &&
            (msgTypeValue.msgType === '' && commTypeValue.newValue === '') || (mode === 'edit' || mode === 'create') &&
            (msgTypeValue.msgType === 'CSV' || msgTypeValue.msgType === 'TXT') ? delimiters.value === '' : delimiters.value !== '');

        if (!(mode === 'read')) {
            if ((mode === 'create') && finalValidateRequiredFields || !isValid || (schedulerTab !== undefined && schedulerTab.length === 0)) {
                issaveOrUpdateButtonDisabled(true)
            } else if ((mode === 'create') && !finalValidateRequiredFields) {
                issaveOrUpdateButtonDisabled(false)
            } else if ((mode === 'edit') && (isDirty) && !finalValidateRequiredFields || !isValid ||
                (messageConfigDtlObjectToBeEditedOrView.timeZone !== timeZoneValue.value)) {
                issaveOrUpdateButtonDisabled(false);
            } else if ((mode === 'create') && (commTypeValue.newValue !== 'Email') && commIdValue.commId === null) {
                issaveOrUpdateButtonDisabled(true);
            } else {
                issaveOrUpdateButtonDisabled(true);
            }
        }
    }, [processNameValue, msgDirectionValueForSupplier, msgFormatValue, msgTypeValue, msgVersionValue, commTypeValue, commIdValue, tabvalue,
        emailMessageTemplateValue, mode, isValid, msgConfigDtlToBeEdited, messageConfigDtlObjectToBeEditedOrView, emailReportTemplateValue,
        projectValue, timeZoneValue, delimiters, amendmentEmailMessageTemplateValue, amendmentEmailReportTemplateValue,
        msgTypeAmendmentValue, relatedProcessNameValue, relatedMsgTypeValue, relatedEmailRptTmplValue, addtnMsgConfigActive, textFieldPanel, groupMsgByValue,
        schedulerTab])

    useEffect(() => {
        if (mode === 'create' || mode === 'edit') {
            setShowIcon(
                <Tooltip title={<FormattedMessage id="common.errorIcon.label" />}>
                    <ErrorOutlineIcon className={classes.tabIconStyling} fontSize='small' />
                </Tooltip>
            )
        } else {
            setShowIcon(null)
        }
    }, [mode])

    const [erpOrgCode, setErpOrgCode] = useState(DialogHeaderAndHeaderValue);
    const [erpEntityCode, setErpEntityCode] = useState(DialogHeaderAndHeaderValue);
    const [bpCode, setBpCode] = useState(DialogHeaderAndHeaderValue);

    useEffect(() => {
        if ((mode === 'edit') || (mode === 'create') || (mode === 'read')) {
            setErpOrgCode(DialogHeaderAndHeaderValue.organization.erpOrgCode)
            setErpEntityCode(DialogHeaderAndHeaderValue.entity.erpEntityCode)
            setBpCode(DialogHeaderAndHeaderValue.bpCode)
        }
    }, [DialogHeaderAndHeaderValue])

    const msgDirectionValueForSupplier = (DialogHeaderAndHeaderValue.bpType === 'SUPPLIER') && processNameValue.processId === 8 || processNameValue.processId === 7 ||
        processNameValue.processId === 2 || processNameValue.processId === 1 || processNameValue.processId === 11 || processNameValue.process === 'VMI Consumption Report' ||
        processNameValue.process === 'PO PDF' || processNameValue.process === 'Purchase Order/Purchase Forecast' || processNameValue.process === 'Purchase Forecast' ||
        processNameValue.process === 'Purchase Order' ?
        { id: 2, value: 'OUT', dataDisplay: 'To Supplier' } : processNameValue.process === 'Invoice' || processNameValue.process === 'PO Delivery Confirmation' ||
            processNameValue.process === 'Advance Shipment Note' ? { id: 1, value: 'IN', dataDisplay: 'From Supplier' } : processNameValue.process === 'Part Change Notification' ? { id: 4, value: 'OUT', dataDisplay: 'To Internal' } : { id: 0, value: '', dataDisplay: '' }


    const poPrCustomerMsgDirection = processNameValue.processId === 23 || processNameValue.processId === 24 || processNameValue.process === 'Purchase Order' || processNameValue.process === 'Purchase Forecast';

    const msgDirectionValueForCustomer = (DialogHeaderAndHeaderValue.bpType === 'CUSTOMER') && processNameValue.processId === 6 || poPrCustomerMsgDirection ||
        processNameValue.process === 'Inventory Report' || processNameValue.process === 'Purchase Order/Purchase Forecast' ? { id: 1, value: 'IN', dataDisplay: 'From Customer' } :
        DialogHeaderAndHeaderValue.bpType === 'CUSTOMER' && (processNameValue.processId === 3 || processNameValue.processId === 5 ||
            processNameValue.processId === 15 ||
            processNameValue.process === 'Invoice' || processNameValue.process === 'Advance Shipment Note' || processNameValue.process === 'Packing List' || processNameValue.process === 'Backlog' || processNameValue.process === 'Shipment') ? { id: 2, value: 'OUT', dataDisplay: 'To Customer' } : { id: 0, value: '', dataDisplay: '' }

    const msgDirection = DialogHeaderAndHeaderValue.bpType === 'SUPPLIER' ? msgDirectionValueForSupplier :
        processNameValue.process === 'Backlog' || processNameValue.process === 'Shipment' ?
            { id: 2, value: 'OUT', dataDisplay: 'To Customer' } :
            msgDirectionValueForCustomer;
    const resetComboValueForDelimter = () => {
    }

    const [textFieldPanel, setTextFieldPanel] = useState('');
    const [isAddTextField, setIsAddTextField] = useState(false);

    const initialState = () => {
        return [{ textFieldPanel: textFieldPanel, emptyTextField: false }]
    }

    const [multipleFields, setMultipleFields] = useState(() => initialState());

    const filterAddtnlMsgConfigDtlToBeEdited = addtnlMsgConfigDtlToBeEdited.filter((item) => {
        return item !== ''
    })

    const updateSelectedAddtnlTextField = () => {
        let addFields = filterAddtnlMsgConfigDtlToBeEdited;
        // if (isAddTextField) {
        if (filterAddtnlMsgConfigDtlToBeEdited.length > 0) {
            let replaced = false;
            filterAddtnlMsgConfigDtlToBeEdited.forEach((existingTF, index) => {
                if (textFieldPanel !== '') {
                    // if (existingTF.relatedProcess === textFieldPanel.relatedProcess || existingTF.relatedProcess.process === textFieldPanel.relatedProcess.process) {
                    if (!isAddTextField) {
                        let index = addFields.indexOf(existingTF)
                        addFields[index] = textFieldPanel;
                        replaced = true;
                    }
                    // }
                    if (isAddTextField && existingTF.uId === textFieldPanel.uId) {
                        let index = addFields.indexOf(existingTF)
                        addFields[index] = textFieldPanel;
                        replaced = true;
                    }
                }
            })
            if (!replaced) {
                if (isAddTextField) {
                    addFields.push(textFieldPanel);
                } else {
                    return addFields
                }
            }
        } else {
            if (textFieldPanel !== '') {
                addFields.push(textFieldPanel);
            }
        }
        //}
        return addFields.filter((item, index) => addFields.indexOf(item) === index);
    }

    useEffect(() => {
        if (updateSelectedAddtnlTextField().length > 0)
            setAddtnlMsgConfigDtlToBeEdited(() => updateSelectedAddtnlTextField());
    }, [textFieldPanel])

    useEffect(() => {
        if (addtnlMsgConfigDtlToBeEdited.length > 0) {
            let addnMsgDtls = addtnlMsgConfigDtlToBeEdited.map((filterColumn, index) => (
                { textFieldPanel: filterColumn, emptyFreqTextField: false }
            ))
            setMultipleFields(addnMsgDtls);
        } else {
            setMultipleFields(() => initialState())
        }
    }, [addtnlMsgConfigDtlToBeEdited, textFieldPanel]);

    const addMultipleProcess = () => {
        setMultipleFields(multipleFields.concat([
            { textFieldPanel: '', emptyTextField: true }
        ]));
        setIsAddTextField(true);
    }

    useEffect(() => {
        if (addtnlMsgConfigDtlToBeEdited.length === 1 && textFieldPanel.relatedProcess === '') {
            setIsAddTextField(true)
        }
    }, [addtnlMsgConfigDtlToBeEdited, textFieldPanel]);

    let test1 = (processNameValue.process === 'PO PDF' || processNameValue.process.tableData === 'VMI Consumption Report' || processNameValue.process === 'Part Change Notification') ?
        !msgConfigDtlToBeEdited.combineSimilarMsg ? true : msgConfigDtlToBeEdited.combineSimilarMsg && (groupMsgByValue !== null && groupMsgByValue.value) === groupMsgBy[0].value ? false : true : false;

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <Dialog
                        fullWidth
                        maxWidth='lg'
                        aria-labelledby="create-or-delete-dialog"
                        open={open}>
                        <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            open={openAlert}
                            onClose={handleCloseAlert}
                            key={vertical + horizontal}
                            autoHideDuration={6000}
                        >
                            <Alert onClose={handleCloseAlert} severity={severity}>
                                {message}
                            </Alert>
                        </Snackbar>
                        <DialogTitle className={classes.titleCommonDialogComponent}>
                            <Grid item container xs={12} sm={12} md={12} lg={12}>
                                <Grid item container alignItems='center' xs={12} sm={6} md={6} lg={6} style={{ marginLeft: '-0.8%' }} >
                                    <CustomBreadCrumb componentList={componentList} isDialog={true} />
                                </Grid>
                                <Grid item container justifyContent='flex-end' xs={12} sm={6} md={6} lg={6} style={{ color: '#fff', fontSize: '14px', marginLeft: '0.8%' }} >
                                    {erpOrgCode} | {erpEntityCode} | {bpCode}
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent dividers style={{ minHeight: '60vh' }}>
                            <Grid item container alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                <AppBar position="sticky" color="inherit" elevation={2} >
                                    <Grid item container alignItems="flex-start" xs={12} sm={12} md={12} lg={12}>
                                        <Tabs
                                            value={tabvalue}
                                            onChange={handleChange}
                                            indicatorColor="primary"
                                            scrollButtons
                                            textColor="secondary"
                                            variant={mobileScreen ? "scrollable" : "fullWidth"}
                                            aria-label="accessible tabs"
                                            style={{ width: '100%' }}
                                        >
                                            <CommonService.ErrorTab className={`${classes.tabtextstyling}`} label={<FormattedMessage id="tabname.messageconfigdetails" />} {...a11yProps(0)} style={mode === 'read' ? { width: '100%', marginTop: '0.6px' } : { width: '100%' }}
                                                icon={validateRequiredFields ? showIcon : setShowIcon} iconPosition="end" />
                                            <Tab className={`${classes.tabtextstyling}`} label={<FormattedMessage id="tabname.additionalMessageConfig" />} {...a11yProps(1)}
                                                style={mode === 'read' ? { width: '100%', marginTop: '0.6px' } : { width: '100%' }}
                                                disabled={commTypeValue.newValue === 'Email' ? (test1) ? true : false : true}
                                            />
                                            <CommonService.ErrorTab className={`${classes.tabtextstyling}`} label={<FormattedMessage id="columnname.frequency.label" />} {...a11yProps(1)}
                                                icon={schedulerTab !== undefined && schedulerTab.length == 0 ? showIcon : setShowIcon} iconPosition="end" />
                                        </Tabs>
                                    </Grid>
                                </AppBar>
                                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                                    <TabPanel value={tabvalue} index={0}>
                                        <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                                            <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                                <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                    <ProcessNameCombo
                                                        comboProperty={processNameComboProperty}
                                                        control={control} errors={errors}
                                                        comboValue={processNameValue}
                                                        setComboValue={setProcessNameValue}
                                                        resetComboValueForProcessName={resetComboValueForProcessName}
                                                        bpTypeValueCombo={DialogHeaderAndHeaderValue}
                                                    />
                                                </Grid>
                                                <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                    <FormControl fullWidth>
                                                        <Controller
                                                            control={control}
                                                            name={pageLabelsConstantsAndMessages.name.inOut}
                                                            rules={{
                                                                maxLength: 30,
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    className={classes.textField}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    id="name"
                                                                    size='small'
                                                                    label={intl.formatMessage({ id: "columname.msgdirection.label" })}
                                                                    error={errors.inOut}
                                                                    {...field}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        classes: {
                                                                            asterisk: classes.asterisk
                                                                        }
                                                                    }}
                                                                    value={msgDirection.dataDisplay}
                                                                    InputProps={{
                                                                        readOnly: false,
                                                                    }}
                                                                    disabled={userReadOnlyFields.inOut}
                                                                />
                                                            )}
                                                        />
                                                        <div className={classes.error}>
                                                            {_.get(`${pageLabelsConstantsAndMessages.name.inOut}.type`, errors) === "required" && (
                                                                <FormHelperText className={classes.error}>
                                                                    <FormattedMessage id="columname.msgdirection.label" /> <FormattedMessage id="textfield.error.isrequirededmessage" />
                                                                </FormHelperText>
                                                            )}
                                                        </div>
                                                    </FormControl>
                                                    {/* <MessageDirectionCombo
                                                        comboProperty={msgDirectionComboProperty}
                                                        control={control} errors={errors}
                                                        comboValue={msgDirectionValue}
                                                        setComboValue={setMsgDirectionValue}
                                                        resetComboValueForInOut={resetComboValueForInOut}
                                                        bpTypeValueCombo={DialogHeaderAndHeaderValue}
                                                        processNameValue={processNameValue}
                                                    /> */}
                                                </Grid>
                                                {!smallScreen && (<Grid item container xs={12} sm={12} md={4} lg={4}>
                                                    <MessageFormatCombo
                                                        comboProperty={messageFormatComboProperty}
                                                        control={control} errors={errors}
                                                        comboValue={msgFormatValue}
                                                        setComboValue={setMsgFormatValue}
                                                        processNameValue={processNameValue}
                                                        inOutValue={msgDirection}
                                                        messageFormatList={messageFormatList}
                                                        setMessageFormatList={setMessageFormatList}
                                                        resetComboValueForMessageFormat={resetComboValueForMessageFormat}
                                                        bpTypeValueCombo={DialogHeaderAndHeaderValue}
                                                    />
                                                </Grid>)}
                                            </Grid>
                                            <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                                {smallScreen && (<Grid item container xs={12} sm={6} md={4} lg={4}>
                                                    <MessageFormatCombo
                                                        comboProperty={messageFormatComboProperty}
                                                        control={control} errors={errors}
                                                        comboValue={msgFormatValue}
                                                        setComboValue={setMsgFormatValue}
                                                        processNameValue={processNameValue}
                                                        inOutValue={msgDirection}
                                                        messageFormatList={messageFormatList}
                                                        setMessageFormatList={setMessageFormatList}
                                                        resetComboValueForMessageFormat={resetComboValueForMessageFormat}
                                                        bpTypeValueCombo={DialogHeaderAndHeaderValue}
                                                    />
                                                </Grid>)}
                                                <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                    <MessageTypeCombo
                                                        comboProperty={messageTypeComboProperty}
                                                        control={control} errors={errors}
                                                        comboValue={msgTypeValue}
                                                        setComboValue={setMsgTypeValue}
                                                        messageFormatList={messageFormatList}
                                                        setMessageFormatList={setMessageFormatList}
                                                        resetComboValueForMessageType={resetComboValueForMessageType}
                                                        msgFormatValue={msgFormatValue}
                                                    />
                                                </Grid>
                                                <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                    <MessageVersionCombo
                                                        comboProperty={messageVersionComboProperty}
                                                        control={control} errors={errors}
                                                        comboValue={msgVersionValue}
                                                        setComboValue={setMsgVersionValue}
                                                        messageFormatList={messageFormatList}
                                                        setMessageFormatList={setMessageFormatList}
                                                        msgTypeValue={msgTypeValue}
                                                    />
                                                </Grid>
                                                {(!smallScreen) && (<Grid item container xs={12} sm={12} md={4} lg={4}>
                                                    <CommTypeCombo
                                                        comboProperty={commTypeComboProperty}
                                                        control={control} errors={errors}
                                                        comboValue={commTypeValue}
                                                        setComboValue={setCommTypeValue}
                                                        msgFormatValue={msgFormatValue}
                                                        resetComboValueForCommType={resetComboValueForCommType}
                                                    />
                                                </Grid>)}
                                                {(smallScreen) && (<Grid item container xs={12} sm={6} md={4} lg={4}>
                                                    <CommTypeCombo
                                                        comboProperty={commTypeComboProperty}
                                                        control={control} errors={errors}
                                                        comboValue={commTypeValue}
                                                        setComboValue={setCommTypeValue}
                                                        msgFormatValue={msgFormatValue}
                                                        resetComboValueForCommType={resetComboValueForCommType}
                                                    />
                                                </Grid>)}
                                            </Grid>
                                            {commTypeValue.newValue === 'Email' && ((processNameValue.process === 'Purchase Order/Purchase Forecast' || processNameValue.process === 'Purchase Order') && (msgDirectionValueForSupplier.dataDisplay === 'To Supplier')) &&
                                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                                    <Grid item container xs={12} sm={12} md={12} lg={12}>
                                                        <Typography>{intl.formatMessage({ id: "configurationnewmsg.text" })}</Typography>
                                                    </Grid>
                                                </Grid>
                                            }

                                            {commTypeValue.newValue === 'Email' &&
                                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                                    <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                        <EmailTmplCombo
                                                            comboProperty={emailMessageTemplateComboProperty}
                                                            control={control} errors={errors}
                                                            comboValue={emailMessageTemplateValue}
                                                            setComboValue={setEmailMessageTemplateValue}
                                                            branchValue={DialogHeaderAndHeaderValue.entity}
                                                        />
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                        <EmailRptTmplCombo
                                                            comboProperty={emailReportTemplateComboProperty}
                                                            control={control} errors={errors}
                                                            comboValue={emailReportTemplateValue}
                                                            setComboValue={setEmailReportTemplateValue}
                                                            processNameValue={processNameValue}
                                                            msgDirection={msgDirection.value}
                                                            branchValue={DialogHeaderAndHeaderValue.entity}
                                                        />
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                        <Grid container justifyContent='flex-start' alignItems='center' className={classes.gridSpacing} >
                                                            <Controller
                                                                render={({
                                                                    field: { onChange } }) => (<Switch
                                                                        color='primary'
                                                                        checked={msgConfigDtlToBeEdited.approvalReqd !== undefined ? msgConfigDtlToBeEdited.approvalReqd : mode === 'create' && msgFormatValue.msgFormat === 'FILE ATTACHMENT' ? false : true}
                                                                        onChange={((event) => {
                                                                            onChange(!(msgConfigDtlToBeEdited.approvalReqd !== undefined ? msgConfigDtlToBeEdited.approvalReqd : true));
                                                                            setMsgConfigDtlToBeEdited({ ...msgConfigDtlToBeEdited, approvalReqd: event.target.checked })
                                                                        })}
                                                                        disabled={disabledForReadMode}
                                                                    />)}
                                                                type="checkbox"
                                                                name={pageLabelsConstantsAndMessages.name.approvalReqd}
                                                                control={control}
                                                            />
                                                            <InputLabel>{<FormattedMessage id="columnname.approvalreqd.label" defaultMessage="Active" />}</InputLabel>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>}
                                            {msgFormatValue.msgFormat === 'EDIFACT' && <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                                {msgFormatValue.msgFormat === 'EDIFACT' && <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                    <CommIdCombo
                                                        comboProperty={commIdComboProperty}
                                                        control={control} errors={errors}
                                                        comboValue={commIdValue}
                                                        setComboValue={setCommIdValue}
                                                        commTypeValue={commTypeValue}
                                                        emailConfigNameList={emailConfigNameList}
                                                        transConfigNameList={transConfigNameList}
                                                        setEmailConfigNameList={setEmailConfigNameList}
                                                        setTransConfigNameList={setTransConfigNameList}
                                                        bpCodeValue={bpCode}
                                                        branchValue={erpEntityCode}
                                                    />
                                                </Grid>}
                                                <Grid item container xs={12} sm={12} md={12} lg={12}>
                                                    <Typography>{intl.formatMessage({ id: "configurationnewmsg.text" })}</Typography>
                                                </Grid>
                                                <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                    <FormControl fullWidth>
                                                        <Controller
                                                            control={control}
                                                            name={pageLabelsConstantsAndMessages.name.progId}
                                                            rules={{
                                                                maxLength: 50,
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    className={classes.textField}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    id="name"
                                                                    size='small'
                                                                    label={intl.formatMessage({ id: "columnname.progId.label" })}
                                                                    error={errors.progId}
                                                                    {...field}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        classes: {
                                                                            asterisk: classes.asterisk
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        readOnly: false,
                                                                    }}
                                                                    disabled={mode === 'create' ? false : true}
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                    <Grid container justifyContent='flex-start' alignItems='center' className={classes.gridSpacing} >
                                                        <Controller
                                                            render={({
                                                                field: { onChange } }) => (<Switch
                                                                    color='primary'
                                                                    checked={msgConfigDtlToBeEdited.approvalReqd !== undefined ? msgConfigDtlToBeEdited.approvalReqd : mode === 'create' && msgFormatValue.msgFormat === 'FILE ATTACHMENT' ? false : true}
                                                                    onChange={((event) => {
                                                                        onChange(!(msgConfigDtlToBeEdited.approvalReqd !== undefined ? msgConfigDtlToBeEdited.approvalReqd : true));
                                                                        setMsgConfigDtlToBeEdited({ ...msgConfigDtlToBeEdited, approvalReqd: event.target.checked })
                                                                    })}
                                                                    disabled={disabledForReadMode}
                                                                />)}
                                                            type="checkbox"
                                                            name={pageLabelsConstantsAndMessages.name.approvalReqd}
                                                            control={control}
                                                        />
                                                        <InputLabel>{<FormattedMessage id="columnname.approvalreqd.label" defaultMessage="Active" />}</InputLabel>
                                                    </Grid>
                                                </Grid>
                                            </Grid>}
                                            {msgFormatValue.msgFormat === 'EDIFACT' && msgDirectionValueForSupplier.dataDisplay === 'To Supplier' && processNameValue.process === 'Purchase Order' && <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                                {msgFormatValue.msgFormat === 'EDIFACT' &&
                                                    <Grid item container xs={12} sm={12} md={12} lg={12}>
                                                        <Typography>{intl.formatMessage({ id: "configurationamendcancel.text" })}</Typography>
                                                    </Grid>}
                                                <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                    <FormControl fullWidth>
                                                        <Controller
                                                            control={control}
                                                            name={pageLabelsConstantsAndMessages.name.amendmentProgId}
                                                            rules={{
                                                                maxLength: 50,
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    className={classes.textField}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    id="name"
                                                                    size='small'
                                                                    label={intl.formatMessage({ id: "columnname.amendmentprogId.label" })}
                                                                    error={errors.progId}
                                                                    {...field}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        classes: {
                                                                            asterisk: classes.asterisk
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        readOnly: false,
                                                                    }}
                                                                    disabled={mode === 'create' ? false : true}
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                    <Grid container justifyContent='flex-start' alignItems='center'>
                                                        <Controller
                                                            render={({
                                                                field: { onChange } }) => (<Switch
                                                                    color='primary'
                                                                    checked={msgConfigDtlToBeEdited.amendmentApprovalReqd !== undefined ? msgConfigDtlToBeEdited.amendmentApprovalReqd : true}
                                                                    onChange={((event) => {
                                                                        onChange(!(msgConfigDtlToBeEdited.amendmentApprovalReqd !== undefined ? msgConfigDtlToBeEdited.amendmentApprovalReqd : true));
                                                                        setMsgConfigDtlToBeEdited({ ...msgConfigDtlToBeEdited, amendmentApprovalReqd: event.target.checked })
                                                                    })}
                                                                    disabled={disabledForReadMode}
                                                                />)}
                                                            type="checkbox"
                                                            name={pageLabelsConstantsAndMessages.name.amendmentApprovalReqd}
                                                            control={control}
                                                        />
                                                        <InputLabel>{<FormattedMessage id="common.amendmentApprovalReqd.lable" defaultMessage="Amendment Appr Reqd" />}</InputLabel>
                                                    </Grid>
                                                </Grid>
                                            </Grid>}
                                            {msgFormatValue.msgFormat === 'EDIFACT' && msgDirectionValueForSupplier.dataDisplay === 'To Supplier' && processNameValue.process === 'Purchase Order' && <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                                {msgFormatValue.msgFormat === 'EDIFACT' &&
                                                    <Grid item container xs={12} sm={12} md={12} lg={12}>
                                                        <Typography>{intl.formatMessage({ id: "configurationDeljit.text" })}</Typography>
                                                    </Grid>}
                                                <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                    <FormControl fullWidth>
                                                        <Controller
                                                            control={control}
                                                            name={pageLabelsConstantsAndMessages.name.deljitProgId}
                                                            rules={{
                                                                maxLength: 50,
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    className={classes.textField}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    id="name"
                                                                    size='small'
                                                                    label={intl.formatMessage({ id: "columnname.deljitProgId.label" })}
                                                                    error={errors.progId}
                                                                    {...field}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        classes: {
                                                                            asterisk: classes.asterisk
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        readOnly: false,
                                                                    }}
                                                                    disabled={mode === 'create' ? false : true}
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                    <Grid container justifyContent='flex-start' alignItems='center'>
                                                        <Controller
                                                            render={({
                                                                field: { onChange } }) => (<Switch
                                                                    color='primary'
                                                                    checked={msgConfigDtlToBeEdited.deljitApprovalReqd !== undefined ? msgConfigDtlToBeEdited.deljitApprovalReqd : true}
                                                                    onChange={((event) => {
                                                                        onChange(!(msgConfigDtlToBeEdited.deljitApprovalReqd !== undefined ? msgConfigDtlToBeEdited.deljitApprovalReqd : true));
                                                                        setMsgConfigDtlToBeEdited({ ...msgConfigDtlToBeEdited, deljitApprovalReqd: event.target.checked })
                                                                    })}
                                                                    disabled={disabledForReadMode}
                                                                />)}
                                                            type="checkbox"
                                                            name={pageLabelsConstantsAndMessages.name.deljitApprovalReqd}
                                                            control={control}
                                                        />
                                                        <InputLabel>{<FormattedMessage id="common.deljitApprovalReqd.lable" defaultMessage="Deljit Appr Reqd" />}</InputLabel>
                                                    </Grid>
                                                </Grid>
                                            </Grid>}
                                            {commTypeValue.newValue === 'Email' && ((processNameValue.process === 'Purchase Order/Purchase Forecast' || processNameValue.process === 'Purchase Order') && (msgDirectionValueForSupplier.dataDisplay === 'To Supplier')) &&
                                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                                    <Grid item container xs={12} sm={12} md={12} lg={12}>
                                                        <Typography>{intl.formatMessage({ id: "configurationamendcancel.text" })}</Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                            {(((processNameValue.process === 'Purchase Order/Purchase Forecast' || processNameValue.process === 'Purchase Order') && (msgDirectionValueForSupplier.dataDisplay === 'To Supplier')) && msgFormatValue.msgFormat === 'FILE' && commTypeValue.newValue === 'Email') &&
                                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                                    <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                        <AmmendEmailTmplCombo
                                                            comboProperty={ammendEmailMessageTemplateComboProperty}
                                                            control={control} errors={errors}
                                                            comboValue={amendmentEmailMessageTemplateValue}
                                                            setComboValue={setAmendmentEmailMessageTemplateValue}
                                                            branchValue={DialogHeaderAndHeaderValue.entity}
                                                        />
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                        <MsgTypeComboForAmendment
                                                            comboProperty={msgTypeAmendmentComboProperty}
                                                            control={control} errors={errors}
                                                            comboValue={msgTypeAmendmentValue}
                                                            setComboValue={setMsgTypeAmendmentValue}
                                                            msgTypeValue={msgTypeValue}
                                                        />
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                        <Grid container justifyContent='flex-start' alignItems='center'>
                                                            <Controller
                                                                render={({
                                                                    field: { onChange } }) => (<Switch
                                                                        color='primary'
                                                                        checked={msgConfigDtlToBeEdited.amendmentApprovalReqd !== undefined ? msgConfigDtlToBeEdited.amendmentApprovalReqd : true}
                                                                        onChange={((event) => {
                                                                            onChange(!(msgConfigDtlToBeEdited.amendmentApprovalReqd !== undefined ? msgConfigDtlToBeEdited.amendmentApprovalReqd : true));
                                                                            setMsgConfigDtlToBeEdited({ ...msgConfigDtlToBeEdited, amendmentApprovalReqd: event.target.checked })
                                                                        })}
                                                                        disabled={disabledForReadMode}
                                                                    />)}
                                                                type="checkbox"
                                                                name={pageLabelsConstantsAndMessages.name.amendmentApprovalReqd}
                                                                control={control}
                                                            />
                                                            <InputLabel>{<FormattedMessage id="common.amendmentApprovalReqd.lable" defaultMessage="Amendment Appr Reqd" />}</InputLabel>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>}
                                            {(msgTypeValue.msgType === 'CSV' || msgTypeValue.msgType === 'TXT') &&
                                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                                    <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                        <DelimitersCombo
                                                            comboProperty={delimiterComboProperty}
                                                            control={control} errors={errors}
                                                            comboValue={delimiters}
                                                            setComboValue={setDelimiters}
                                                            resetComboValueForDelimter={resetComboValueForDelimter}
                                                        />
                                                    </Grid>
                                                </Grid>}

                                            <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                                <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                    <Grid container justifyContent='flex-start' alignItems='center'>
                                                        <Controller
                                                            render={({
                                                                field: { onChange } }) => (<Switch
                                                                    color='primary'
                                                                    checked={msgConfigDtlToBeEdited.active !== undefined ? msgConfigDtlToBeEdited.active : true}
                                                                    onChange={((event) => {
                                                                        onChange(!(msgConfigDtlToBeEdited.active !== undefined ? msgConfigDtlToBeEdited.active : true));
                                                                        setMsgConfigDtlToBeEdited({ ...msgConfigDtlToBeEdited, active: event.target.checked })
                                                                    })}
                                                                    disabled={disabledForReadMode}
                                                                />)}
                                                            type="checkbox"
                                                            name={pageLabelsConstantsAndMessages.name.active}
                                                            control={control}
                                                        />
                                                        <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>
                                                    </Grid>
                                                </Grid>
                                                {processNameValue.process === 'Purchase Order' && <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                    <Grid container justifyContent='flex-start' alignItems='center' className={classes.gridSpacing} >
                                                        <Controller
                                                            render={({
                                                                field: { onChange } }) => (<Switch
                                                                    color='primary'
                                                                    checked={msgConfigDtlToBeEdited.ackRequired !== undefined ? msgConfigDtlToBeEdited.ackRequired : false}
                                                                    onChange={((event) => {
                                                                        onChange(!(msgConfigDtlToBeEdited.ackRequired !== undefined ? msgConfigDtlToBeEdited.ackRequired : false));
                                                                        setMsgConfigDtlToBeEdited({ ...msgConfigDtlToBeEdited, ackRequired: event.target.checked })
                                                                    })}
                                                                    disabled={disabledForReadMode}
                                                                />)}
                                                            type="checkbox"
                                                            name={pageLabelsConstantsAndMessages.name.ackRequired}
                                                            control={control}
                                                        />
                                                        <InputLabel>{<FormattedMessage id="columnname.isacknowledged.label" defaultMessage="Acknowledgment Required" />}</InputLabel>
                                                    </Grid>
                                                </Grid>}

                                                {(msgTypeValue.msgType === 'EXCEL' || msgTypeValue.msgType === 'EXCEL_2003' || msgTypeValue.msgType === 'CSV' || msgTypeValue.msgType === 'TXT') && msgFormatValue.msgFormat === 'FILE' &&
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                        <Grid container justifyContent='flex-start' alignItems='center'>
                                                            <Controller
                                                                render={({
                                                                    field: { onChange } }) => (<Switch
                                                                        color='primary'
                                                                        checked={msgConfigDtlToBeEdited.headerReqd !== undefined ? msgConfigDtlToBeEdited.headerReqd : true}
                                                                        onChange={((event) => {
                                                                            onChange(!(msgConfigDtlToBeEdited.headerReqd !== undefined ? msgConfigDtlToBeEdited.headerReqd : true));
                                                                            setMsgConfigDtlToBeEdited({ ...msgConfigDtlToBeEdited, headerReqd: event.target.checked })
                                                                        })}
                                                                        disabled={disabledForReadMode}
                                                                    />)}
                                                                type="checkbox"
                                                                name={pageLabelsConstantsAndMessages.name.headerReqd}
                                                                control={control}
                                                            />
                                                            <InputLabel>{<FormattedMessage id="common.InclHdrDetails.lable" defaultMessage="Include Header Details" />}</InputLabel>
                                                        </Grid>
                                                    </Grid>}
                                                {(commTypeValue.newValue === 'Email' && ((processNameValue.process === 'Purchase Order' || processNameValue.process === 'Purchase Order/Purchase Forecast') && msgTypeAmendmentValue.value !== 'PDF' && msgTypeAmendmentValue.value !== '') && (
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                        <Grid container justifyContent='flex-start' alignItems='center'>
                                                            <Controller
                                                                render={({
                                                                    field: { onChange } }) => (<Switch
                                                                        color='primary'
                                                                        checked={msgConfigDtlToBeEdited.combineNewAndAmended !== undefined ? msgTypeAmendmentValue.value === 'PDF' || msgTypeAmendmentValue.value === '' ? false : msgConfigDtlToBeEdited.combineNewAndAmended : true}
                                                                        onChange={((event) => {
                                                                            onChange(!(msgConfigDtlToBeEdited.combineNewAndAmended !== undefined ? msgTypeAmendmentValue.value === 'PDF' || msgTypeAmendmentValue.value === '' ? false : msgConfigDtlToBeEdited.combineNewAndAmended : true));
                                                                            setMsgConfigDtlToBeEdited({ ...msgConfigDtlToBeEdited, combineNewAndAmended: event.target.checked })
                                                                        })}
                                                                        disabled={disabledForReadMode}
                                                                    />)}
                                                                type="checkbox"
                                                                name={pageLabelsConstantsAndMessages.name.combineNewAndAmended}
                                                                control={control}
                                                            />
                                                            <InputLabel>{intl.formatMessage({ id: 'columnname.combinenewamendmsg.text' })}</InputLabel>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                {commTypeValue.newValue === 'Email' &&
                                                    (<Grid item container xs={12} sm={6} md={3} lg={3}>
                                                        <Grid container justifyContent='flex-start' alignItems='center'>
                                                            <Controller
                                                                render={({
                                                                    field: { onChange } }) => (<Switch
                                                                        color='primary'
                                                                        checked={msgConfigDtlToBeEdited.combineSimilarMsg !== undefined ? msgConfigDtlToBeEdited.combineSimilarMsg : false}
                                                                        onChange={((event) => {
                                                                            onChange(!(msgConfigDtlToBeEdited.combineSimilarMsg !== undefined ? msgConfigDtlToBeEdited.combineSimilarMsg : false));
                                                                            setMsgConfigDtlToBeEdited({ ...msgConfigDtlToBeEdited, combineSimilarMsg: event.target.checked });
                                                                            if (event.target.checked === false) {
                                                                                setValue(`${pageLabelsConstantsAndMessages.name.groupMsgBy}`, '');
                                                                            }
                                                                        })}
                                                                        disabled={mode === 'read' ? disabledForReadMode : processNameValue.process === 'PO PDF' || processNameValue.process === 'VMI Consumption Report' ? false : true}
                                                                        defaultValue={msgConfigDtlToBeEdited.combineSimilarMsg !== undefined ? msgConfigDtlToBeEdited.combineSimilarMsg : false}

                                                                    />)}
                                                                type="checkbox"
                                                                name={pageLabelsConstantsAndMessages.name.combineSimilarMsg}
                                                                control={control}
                                                            />
                                                            <InputLabel>{<FormattedMessage id="common.combinesimilarmsgs.lable" />}</InputLabel>
                                                        </Grid>
                                                    </Grid>)}
                                                {commTypeValue.newValue === 'Email' && (msgConfigDtlToBeEdited.combineSimilarMsg === true) &&
                                                    (<Grid item container xs={12} sm={6} md={3} lg={3}>
                                                        <FormControl fullWidth>
                                                            <Controller
                                                                control={control}
                                                                name={pageLabelsConstantsAndMessages.name.groupMsgBy}
                                                                rules={{
                                                                    required: false,
                                                                }}
                                                                render={({
                                                                    field: { onChange } }) => (
                                                                    <Autocomplete
                                                                        size='small'
                                                                        id="selectMinute"
                                                                        options={groupMsgBy}
                                                                        onChange={(event, newValue) => {
                                                                            onChange(newValue)
                                                                            setGroupMsgByValue(newValue);
                                                                        }}
                                                                        getOptionLabel={(option) => option.label}
                                                                        value={groupMsgByValue || null}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="outlined"
                                                                                label={<FormattedMessage id="columnname.groupmsgby.label" defaultMessage="Group Msg By" />}
                                                                                InputLabelProps={{
                                                                                    shrink: true
                                                                                }}
                                                                            />
                                                                        )}
                                                                        defaultValue={processNameValue.process === 'PO PDF' ? groupMsgBy[1] : groupMsgBy[0]}
                                                                        disabled={mode === 'read' ? disabledForReadMode :
                                                                            processNameValue.process === 'PO PDF' ? false : true}
                                                                    />
                                                                )}
                                                            />
                                                            <div className={classes.error}>
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.groupMsgBy}.type`, errors) === "required" && (
                                                                    <FormHelperText className={classes.error}>
                                                                        <FormattedMessage id="textfield.error.contactpersonsalution" defaultMessage="Contact Person Salution is required" />
                                                                    </FormHelperText>
                                                                )}

                                                            </div>
                                                        </FormControl>
                                                    </Grid>)}
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={tabvalue} index={1}>
                                        <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                                            <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} style={{ marginLeft: '0' }} >
                                                <Grid item container xs={12} sm={6} md={4} lg={4}>
                                                    <Button size='small' color='primary' variant="outlined"
                                                        onClick={() => {
                                                            addMultipleProcess()
                                                        }}
                                                        startIcon={<AddIcon />}
                                                        disabled={disabledForReadMode}
                                                    > <FormattedMessage id="common.button.addMultipleProcess" defaultMessage="ADD Multiple Process" />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            {multipleFields.map((addtnField, index) => (
                                                <CreateAdditionalMsgConfigDtl messageConfigDtlObjectToBeEditedOrView={messageConfigDtlObjectToBeEditedOrView}
                                                    relatedProcessNameValue={relatedProcessNameValue} setRelatedProcessNameValue={setRelatedProcessNameValue}
                                                    processNameValue={processNameValue} relatedMsgTypeValue={relatedMsgTypeValue} setRelatedMsgTypeValue={setRelatedMsgTypeValue}
                                                    relatedEmailRptTmplValue={relatedEmailRptTmplValue} setRelatedEmailRptTmplValue={setRelatedEmailRptTmplValue}
                                                    addtnlMsgConfigDtlToBeEdited={addtnlMsgConfigDtlToBeEdited} commTypeValue={commTypeValue} msgDirection={msgDirection}
                                                    DialogHeaderAndHeaderValue={DialogHeaderAndHeaderValue} disabledForReadMode={disabledForReadMode} control={control}
                                                    errors={errors} id={index} open={open} setAddtnlMsgConfigDtlToBeEdited={setAddtnlMsgConfigDtlToBeEdited}
                                                    textFieldPanel={addtnField.textFieldPanel} multipleFields={multipleFields} setMultipleFields={setMultipleFields}
                                                    setTextFieldPanel={setTextFieldPanel} addMultipleProcess={addMultipleProcess} emptyTextField={addtnField.emptyTextField}
                                                    DialogHeaderAndHeaderValue={DialogHeaderAndHeaderValue} addtnMsgConfigActive={addtnMsgConfigActive}
                                                    setAddtnlMsgConfigActive={setAddtnlMsgConfigActive}
                                                    addtnField={addtnField} mode={mode}
                                                />))}
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={tabvalue} index={2}>
                                        <CustomSchedulerRedesignTab control={control} errors={errors}
                                            messageConfigDtlObjectToBeEditedOrView={messageConfigDtlObjectToBeEditedOrView}
                                            msgId={messageConfigDtlObjectToBeEditedOrView.msgId}
                                            msgConfigDtlData={tableData}
                                            setMessageConfigDtlObjectToBeEditedOrView={setMessageConfigDtlObjectToBeEditedOrView} mode={mode} reset={reset} setValue={setValue} setTimeZoneValue={setTimeZoneValue} timeZoneValue={timeZoneValue}
                                            processId={processNameValue.processId}
                                            processNameValue={processNameValue}
                                            setSchedulerTab={setSchedulerTab}
                                            schedulerTab={schedulerTab}
                                            DialogHeaderAndHeaderValue={DialogHeaderAndHeaderValue}
                                            title={title}
                                        />
                                    </TabPanel>
                                </form>
                            </Grid>
                        </DialogContent>
                        {(!smallScreen) &&
                            <DialogActions>
                                <Grid container justifyContent='flex-start' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                                    <InfoIcon color="primary" />
                                    <Typography justifyContent="flex-start" style={{ fontSize: 12, marginLeft: '1%' }}><FormattedMessage id="textfield.placeholder.dialogActions" defaultMessage="All the '*' marked fields are mandatory to submit the form" /></Typography>
                                </Grid>

                                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing}>
                                    <Button size='small' variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2} endIcon={<ClearIcon />}
                                        onClick={() => handleClose(false)}
                                    >
                                        <FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />
                                    </Button>

                                    <Button size='small' disabled={disableRestoreButton}
                                        variant="contained" color="secondary" className={classes.stateButtonToRestore} elevation={2}
                                        endIcon={<IconRestore />} onClick={(() => { resetAllClearThePageInfo() })}
                                    > {<FormattedMessage id="common.button.reset.lable" defaultMessage="Reset" />} </Button>
                                    {(messageConfigModuleAccess !== undefined ? messageConfigModuleAccess.createFlag : false) && <Button size='small'
                                        variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                        disabled={disableSaveOrUpdateButton || isFormSubmitted}
                                        endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />}
                                        onClick={handleSubmit(onSubmit, onError)}
                                    > {saveOrUpdateButtonLabel} </Button>

                                    }
                                </Grid>
                            </DialogActions>
                        }

                        {(smallScreen) && (
                            <DialogActions>
                                <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.topGrid}>
                                    <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                                        <span>
                                            <Button size='small' variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2}
                                                onClick={() => handleClose(false)}
                                            >
                                                <ClearIcon />
                                            </Button>
                                        </span>
                                    </Tooltip>
                                    <Tooltip title={<FormattedMessage id="common.button.reset.lable" />}>
                                        <span>

                                            <Button size='small' disabled={disableRestoreButton}
                                                variant="contained" color="secondary" className={classes.stateButtonToRestore} elevation={2} onClick={(() => { resetAllClearThePageInfo() })}
                                            > <IconRestore />
                                            </Button>
                                        </span>
                                    </Tooltip>

                                    {((messageConfigModuleAccess !== undefined ? messageConfigModuleAccess.createFlag : false) && (<Tooltip title={mode === 'create' ? <FormattedMessage id="common.button.save" /> : <FormattedMessage id="common.button.update" />}>
                                        <span>
                                            <Button size='small'
                                                variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                                disabled={disableSaveOrUpdateButton || isFormSubmitted}
                                                endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : null}
                                                onClick={handleSubmit(onSubmit, onError)}
                                            > <IconSaveOrUpdate />
                                            </Button>
                                        </span>
                                    </Tooltip>))}
                                </Grid>
                            </DialogActions>)}
                    </Dialog>
                </Grid>
            </Grid >
        </React.Fragment >
    );
}

export default CreateOrEditMessageConfigDtl;