import { Button, Grid, CssBaseline, useTheme, useMediaQuery, Tooltip, Paper } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, LicenseInfo, DataGridPro }
    from '@mui/x-data-grid-pro';
import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import useStyles from '../../common/CommonStyle/CommonStyle';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import { CommonService } from '../../../services';
import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import ClearIcon from '@mui/icons-material/Clear';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import ViewIcon from '@mui/icons-material/Visibility';
import ScheduleIcon from '@mui/icons-material/Schedule';
import IconAdvanceShipmentNoteOutbound from '@mui/icons-material/Receipt';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),

    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disabletypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const columnFieldsName = {
    boxCount: 'boxCount',
    boxBarCode: 'boxBarCode',
    boxType: 'boxType',
    boxGrossWeight: 'boxGrossWeight',
    boxNetWeight: 'boxNetWeight',
    boxHeight: 'boxHeight',
    boxLength: 'boxLength',
    boxWide: 'boxWide',
    boxCapacity: "boxCapacity",
    boxQty: "boxQty",
    markingTypeCode: 'markingTypeCode',
    agencyCode: 'agencyCode',
    latestDataSource: 'latestDataSource',
    batchId: 'batchId'
}

const AdvanceShipmentNoteInboundBoxDtl = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const { open, onClose, msgHdrId, messageDtlsId } = props;
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });

    const pageLabelsConstantsAndMessages = {
        label: {
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density',
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
    }
    const [columns, setColumns] = useState([]);
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [tableData, setTableData] = useState([]);
    const handleClose = (value) => {
        onClose(value)
    }

    const commonColumnForASNGrid = [
        {
            field: columnFieldsName.boxCount,
            width: 140,
            headerName: intl.formatMessage({ id: "columnname.boxCount.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.boxBarCode,
            headerName: intl.formatMessage({ id: "columnname.boxBarCode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 130,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,

        },
        {
            field: columnFieldsName.boxType,
            headerName: intl.formatMessage({ id: "columnname.boxType.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 130,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.boxGrossWeight,
            width: 140,
            headerName: intl.formatMessage({ id: "columnName.boxGrossWeight.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,

        },
        {
            field: columnFieldsName.boxNetWeight,
            headerName: intl.formatMessage({ id: "columnName.boxNetWeight.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 140,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.boxHeight,
            headerName: intl.formatMessage({ id: "columnname.boxHeight.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 170,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.boxWide,
            headerName: intl.formatMessage({ id: "columnname.boxWide.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 170,
            sortable: true,
            type: 'dateTime',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.boxCapacity,
            headerName: intl.formatMessage({ id: "columnName.boxCapacity.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 140,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.boxQty,
            headerName: intl.formatMessage({ id: "columnName.boxQty.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 180,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.markingTypeCode,
            headerName: intl.formatMessage({ id: "columnName.markingTypeCode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 180,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.agencyCode,
            headerName: intl.formatMessage({ id: "columnName.agencyCode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 180,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.latestDataSource,
            headerName: intl.formatMessage({ id: "columnName.latestDataSource.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 180,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.batchId,
            headerName: intl.formatMessage({ id: "columnName.batchId.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 180,
            sortable: true,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
    ]

    useEffect(() => {
        setColumns(commonColumnForASNGrid)
    }, [selectedLanguage])

    const [paramBody, setParamBody] = useState(
        {
            body: {
            },
            pageNo: 0,
            sortDir: 'asc',
            sortKey: 'boxDtlsId',
            recordsPerPage: 25,
        }
    );

    const componentList = [
        {
            path: '/advanceShipmentNoteInbound',
            name: <FormattedMessage id="common.module.breadcrumb.advanceshipmentnoteinbound" defaultMessage="Advance ShipmentNote Inbound" />,
            iconName: IconAdvanceShipmentNoteOutbound,
        },
        {
            path: '/advanceShipmentNoteInbound',
            name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage='View' />,
            iconName: ViewIcon,
        },
        {
            path: undefined,
            name: <FormattedMessage id="common.button.BoxGrid.breadcrumb.tooltip" defaultMessage='boxGrid' />,
            iconName: ScheduleIcon,
        },
    ];

    useEffect(() => {
        if (open === true) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(`/advanceShipmentNoteInboundHdr/${msgHdrId}/advanceShipmentNoteInboundDtls/${messageDtlsId}/advanceShipmentNoteInboundBoxDtls`, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [open, paramBody, refreshTable])

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <Dialog
                        fullWidth
                        maxWidth='lg'
                        aria-labelledby="create-or-delete-dialog"
                        open={open}>
                        <DialogTitle className={classes.titleCommonDialogComponent}>
                            <Grid item container xs={12} sm={12} md={12} lg={12}>
                                <Grid item container alignItems='center' item xs={12} sm={6} md={8} lg={6} >
                                    <CustomBreadCrumb componentList={componentList} isDialog={true} />
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={mobileScreen ? { minHeight: '70vh' } : { minHeight: '60vh' }}>
                            <Paper elevation={3} className={classes.gridHeightAndWidthForViewBoxDetails}>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <DataGridPro
                                        className={classes.customTableStyle}
                                        disableColumnFilter={true}
                                        loading={tableLoadingStatus}
                                        rowCount={totalRowCount}
                                        rows={tableData}
                                        pageSize={paramBody.recordsPerPage}
                                        columns={columns}
                                        getRowId={(row) => row.boxDtlsId}
                                        showToolbar
                                        scrollbarSize={30}
                                        disableColumnFilter={true}
                                        rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                                        pagination
                                        density="compact"
                                        paginationMode="server"
                                        hideFooter={true}
                                        disableSelectionOnClick={true}
                                        disableMultipleColumnsSorting={true}
                                        disableColumnMenu={true}
                                        disableColumnSelector={false}
                                        localeText={localtext}
                                        components={{
                                            Toolbar: () => {
                                                return (
                                                    <Grid container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                                        <Grid
                                                            item
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            xs={12}
                                                            sm={12}
                                                            md={6}
                                                            lg={6}>
                                                            <Grid>
                                                                <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                                    <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid>
                                                                <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                                    placement='bottom'><GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid>
                                                                <GridToolbarFilterButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                            </Grid>
                                                            <Grid>
                                                                <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                                    <span>
                                                                        <Button size="small" color="primary" variant="outlined"
                                                                            className={classes.refreshToobarStyle}
                                                                            onClick={(() => {
                                                                                setRefreshTable(!refreshTable);
                                                                            })}>
                                                                            <RefreshIcon />
                                                                        </Button>
                                                                    </span>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <CommonGridPagination />
                                                        </Grid>
                                                    </Grid>
                                                );
                                            },
                                        }}
                                        hideFooterSelectedRowCount={true}
                                        onPageSizeChange={((pageParams) => {
                                            setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                                        })}
                                        onPageChange={((pageParams) => {
                                            setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                                        })}
                                        onSortModelChange={(params) => {
                                            let sortModel = params[0];
                                            if (sortModel !== undefined) {
                                                setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                            }
                                        }}
                                        sortingOrder={['desc', 'asc']}
                                        filterMode="server"
                                    />
                                </div>
                            </Paper>
                        </DialogContent>
                        {!mobileScreen && (
                            <DialogActions className={classes.dialogAction} >

                                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} >
                                    <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2} endIcon={<ClearIcon />}
                                        onClick={() => handleClose(false)}
                                    ><FormattedMessage id="common.button.Cancel.label" defaultMessage="Cancel" />
                                    </Button>

                                </Grid>

                            </DialogActions>)}
                        {mobileScreen && (<Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                            <DialogActions className={classes.dialogAction}>
                                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.topGrid} >
                                    <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                                        <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2}
                                            onClick={() => handleClose(false)}
                                        >  <ClearIcon />
                                        </Button>
                                    </Tooltip>

                                </Grid>
                            </DialogActions>
                        </Grid>)}
                    </Dialog>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}
export default AdvanceShipmentNoteInboundBoxDtl;