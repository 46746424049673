import { Button, Grid, CssBaseline, CircularProgress, useTheme, useMediaQuery, Tooltip, FormControl, FormHelperText, TextField, InputLabel, Switch, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import useStyles from '../../common/CommonStyle/CommonStyle';
import EditIcon from '@mui/icons-material/Edit';
import CreateIcon from '@mui/icons-material/Add';
import ViewIcon from '@mui/icons-material/Visibility';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import _ from "lodash/fp";
import { Controller } from 'react-hook-form';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import EntityCodeCombo from '../../common/Combos/EntityCodeCombo';
import { useIntl } from 'react-intl';
import TriggerEventCombo from '../../common/Combos/TriggerEventCombo/TriggerEventCombo';
import ProcessNameCombo from '../../common/Combos/ProcessNameCombo/ProcessNameCombo';
import { CommonService } from '../../../services';
import { useForm } from 'react-hook-form'
import { Alert } from "@mui/lab";
import BpTypeCombo from '../../common/Combos/BpTypeCombo/BpTypeCombo';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),

    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const DialogComponentEns = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { onClose, open, title, mode, dialogHeader, orgName, entityName, ensObjectToBeEditedOrView, TriggerEvent_state } = props;
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [componentList, setComponentList] = useState(title);
    const [disablesaveButton, isSetSaveButtonDisabled] = useState(false);
    const intl = useIntl();
    const [emailNotificationSubscriptiontoBeEdited, setEmailNotificationSubscriptiontoBeEdited] = React.useState(ensObjectToBeEditedOrView);
    const [branchValue, setBranchValue] = useState(null);
    const [selectedProcess, setSelectedProcess] = useState(null);
    const [selectedTriggerEvent, setSelectedTriggerEvent] = useState('');
    const commonPatterns = GlobalEdiApiConstants();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const currentUser = CommonService.getLoggedInUserInfo();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());
    const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('');
    const [bpTypeValue, setBpTypeValue] = useState('');
    const [emailNotificationSubscriptionReadOnlyFields, setEmailNotificationSubscriptionReadOnlyFields] = useState({
        active: false,
        entity: false,
        processMaster: false,
        triggerEvent: false,
        entityEmailTo: false,
        entityEmailCc: false,
        entityEmailBcc: false,
    });
    const pageLabelsConstantsAndMessages = {
        name: {
            entity: 'entity',
            active: "active",
            organization: 'organization',
            processMaster: 'processMaster',
            triggerEvent: 'triggerEvent',
            entityEmailTo: 'entityEmailTo',
            entityEmailCc: 'entityEmailCc',
            entityEmailBcc: 'entityEmailBcc',
            processedEmailTo: 'processedEmailTo',
            processedEmailCc: 'processedEmailCc',
            processedEmailBcc: 'processedEmailBcc',
            bpCode: 'bpCode',
            bpType: 'bpType'
        },
        label: {
            active: 'active'
        }
    }
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const { handleSubmit, reset, formState: { errors, isDirty, isValid }, control, getValues } = useForm({
        mode: "all",
        defaultValues: {
            entity: null,
            organization: null,
            processMaster: null,
            triggerEvent: null,
            entityEmailTo: null,
            entityEmailCc: null,
            entityEmailBcc: null,
            bpCode: null,
            active: true,
            bpType: null,
        }
    });

    const functionForReset = (selectObject) => {
        reset({
            entity: selectObject.entity !== undefined ? { erpEntityCode: selectObject.entity.erpEntityCode, entityName: selectObject.entity.entityName } : null,
            processMaster: selectObject.processMaster !== undefined ? selectObject.processMaster.process : '',
            triggerEvent: selectObject.triggerEvent !== undefined ? selectObject.triggerEvent.id !== undefined ? selectObject.triggerEvent.id : selectObject.triggerEvent : '',
            active: selectObject.active !== undefined ? selectObject.active : true,
            entityEmailTo: selectObject.entityEmailTo !== undefined ? selectObject.entityEmailTo : '',
            entityEmailCc: selectObject.entityEmailCc !== undefined ? selectObject.entityEmailCc : '',
            entityEmailBcc: selectObject.entityEmailBcc !== undefined ? selectObject.entityEmailBcc : '',
            processedEmailTo: selectObject.processedEmailTo !== undefined ? selectObject.processedEmailTo : '',
            processedEmailCc: selectObject.processedEmailCc !== undefined ? selectObject.processedEmailCc : '',
            processedEmailBcc: selectObject.processedEmailBcc !== undefined ? selectObject.processedEmailBcc : '',
            bpCode: selectObject.bpCode !== undefined ? selectObject.bpCode : '',
            bpType: selectObject.bpType !== undefined ? selectObject.bpType : '',
        })
        setBranchValue(selectObject.entity !== undefined ? { valuesToBeDisplayed: selectObject.entity } : null);
        setSelectedProcess(selectObject.processMaster !== undefined ? selectObject.processMaster : null);
        setSelectedTriggerEvent(selectObject.triggerEvent !== undefined ? TriggerEvent_state[selectObject.triggerEvent] : '');
        setBpTypeValue({ value: selectObject.bpType !== undefined ? selectObject.bpType : '' })
    }

    const onSubmit = (data) => {
        data['emailNotificationId'] = (mode === 'create') ? '' : ensObjectToBeEditedOrView.id;
        data['organization'] = (ensObjectToBeEditedOrView.organization !== undefined) ? { erpOrgCode: ensObjectToBeEditedOrView.organization.erpOrgCode } : { erpOrgCode: orgInfoOfLeggedUser.erpOrgCode };
        data['entity'] = (ensObjectToBeEditedOrView.entity === undefined) ? { erpEntityCode: data.entity.erpEntityCode } : { erpEntityCode: ensObjectToBeEditedOrView.entity.erpEntityCode };
        data['createdBy'] = (mode === 'create') ? currentUser.userName : ensObjectToBeEditedOrView.createdBy
        data['createdTime'] = (ensObjectToBeEditedOrView.createdTime === undefined) ? '' : ensObjectToBeEditedOrView.createdTime;
        data['updatedBy'] = currentUser.userName;
        data['updatedTime'] = (ensObjectToBeEditedOrView.updatedTime === undefined) ? '' : ensObjectToBeEditedOrView.updatedTime;
        data['triggerEvent'] = (data.triggerEvent !== undefined) ? data.triggerEvent.id !== undefined ? data.triggerEvent.id : data.triggerEvent : ensObjectToBeEditedOrView.triggerEvent;
        data['processMaster'] = (data.processMaster.processId === undefined) ? { processId: ensObjectToBeEditedOrView.processMaster.processId } : { processId: data.processMaster.processId };
        data["bpType"] = bpTypeValue.value;
        setIsFormSubmitted(true);
        CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.EmailNotificationSubscription, data)
            .then((response) => {
                setIsFormSubmitted(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success" });
                onClose(true)
            })
            .catch(function (error) {

                setIsFormSubmitted(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            });
    }

    const resetAllClearTheBasicInfo = () => {
        if (mode === 'edit') {
            functionForReset(ensObjectToBeEditedOrView)
            setEmailNotificationSubscriptiontoBeEdited({ ...ensObjectToBeEditedOrView });
        } else {
            setBranchValue([]);
            setSelectedProcess('');
            setSelectedTriggerEvent('');
            setBpTypeValue('');
            reset({
                entityEmailTo: '',
                entityEmailCc: '',
                entityEmailBcc: '',
                processedEmailTo: '',
                processedEmailCc: '',
                processedEmailBcc: '',
                bpCode: '',
                active: true,
                entity: null,
                processMaster: null,
                triggerEvent: null,
                bpType: '',
            });
            setEmailNotificationSubscriptiontoBeEdited({ ...ensObjectToBeEditedOrView, active: true });
        }
    }

    const handleClose = (value) => {
        setComponentList(title);
        handlereset(true)
        onClose(value)

    }

    const handlereset = (value) => {
        reset({ keepErrors: true, keepDirty: true, keepIsSubmitted: false, keepTouched: false, keepIsValid: false, keepSubmitCount: false });
        resetAllClearTheBasicInfo(value)
    }

    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };

    useEffect(() => {
        if (mode !== '') {
            let currentRouterComponent;
            if (mode === 'create') {
                setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
                handlereset(true)
                currentRouterComponent = [
                    {
                        path: null,
                        name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                        iconName: CreateIcon,
                    }
                ]
            } else if (mode === 'edit') {
                isRestoreButtonDisabled(false);
                isSetSaveButtonDisabled(true);
                setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
                currentRouterComponent = [
                    {
                        path: null,
                        name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                        iconName: EditIcon,
                    }
                ]
            } else if (mode === 'read' || (mode === 'view')) {
                isRestoreButtonDisabled(true);
                isSetSaveButtonDisabled(true);
                setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
                currentRouterComponent = [
                    {
                        path: null,
                        name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
                        iconName: ViewIcon,
                    }
                ]
            }
            setComponentList(title.concat(currentRouterComponent !== undefined ? currentRouterComponent : []));
        }
    }, [mode]);

    useEffect(() => {
        setEmailNotificationSubscriptiontoBeEdited(ensObjectToBeEditedOrView);
    }, [ensObjectToBeEditedOrView]);

    useEffect(() => {
        if (mode === 'view' || mode === 'edit') {
            setEmailNotificationSubscriptionReadOnlyFields({
                ...emailNotificationSubscriptionReadOnlyFields,
                erpEntityCode: false, processId: false, entityEmailTo: false, entityEmailCc: false, entityEmailBcc: false, triggerEvent: false
            })
            setBranchValue(ensObjectToBeEditedOrView.entity !== undefined ? { valuesToBeDisplayed: ensObjectToBeEditedOrView.entity } : null);
            setSelectedProcess(ensObjectToBeEditedOrView.processMaster !== undefined ? ensObjectToBeEditedOrView.processMaster : null);
            setSelectedTriggerEvent(ensObjectToBeEditedOrView.triggerEvent !== undefined ? TriggerEvent_state[ensObjectToBeEditedOrView.triggerEvent] : '');
            setBpTypeValue({value: ensObjectToBeEditedOrView.bpType !== undefined ? ensObjectToBeEditedOrView.bpType : ''})
            functionForReset(ensObjectToBeEditedOrView);
        }
        if (mode === 'view') {
            setEmailNotificationSubscriptionReadOnlyFields({
                ...emailNotificationSubscriptionReadOnlyFields,
                erpEntityCode: true, processId: true, entityEmailTo: true, entityEmailCc: true, entityEmailBcc: true, triggerEvent: true
            })
        }
        if (mode === 'create') {
            setBranchValue([]);
            setSelectedProcess('');
            setSelectedTriggerEvent('');
            setEmailNotificationSubscriptionReadOnlyFields({
                ...emailNotificationSubscriptionReadOnlyFields,
                erpEntityCode: false, processId: false, entityEmailTo: false, entityEmailCc: false, entityEmailBcc: false, triggerEvent: false
            })
        }
    }, [mode, ensObjectToBeEditedOrView]);

    const branchComboProperty = {
        name: pageLabelsConstantsAndMessages.name.entity,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.entityCode.label" }),
        isAutofocus: false,
        isDisabled: (mode === 'create') ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.entity
    }

    const processComboProperty = {
        name: pageLabelsConstantsAndMessages.name.processMaster,
        isRequired: true,
        label: intl.formatMessage({ id: 'columnname.process.label' }),
        isAutofocus: false,
        isDisabled: (mode === 'edit' || mode === 'create') ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.processMaster
    }

    const triggerEventComboProperty = {
        name: pageLabelsConstantsAndMessages.name.triggerEvent,
        isRequired: true,
        label: intl.formatMessage({ id: 'columnname.triggerEvent.label' }),
        isAutofocus: false,
        isDisabled: (mode === 'edit' || mode === 'create') ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.triggerEvent
    }

    const bpTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.bpType,
        isRequired: true,
        label: intl.formatMessage({ id: 'textfield.label.bptype' }),
        isAutofocus: false,
        isDisabled: (mode === 'edit' || mode === 'create') ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.bpType
    }

    const resetComboValueForProcessName = () => { }

    const onError = errors => {
        setAlertBoxState({ ...alertBoxState, openAlert: true, message: "Form Has Errors", severity: "error", err: true }
        )
    }

    const validateRequiredFields = (getValues('entity') === '' && getValues('processMaster') === '' && getValues('triggerEvent') === '' && getValues('bpType') === '')

    useEffect(() => {
        if (!(mode === 'view')) {
            if ((mode === 'create') && (validateRequiredFields || !isValid)) {
                isSetSaveButtonDisabled(true)
            } else if ((mode === 'create') && !validateRequiredFields) {
                isSetSaveButtonDisabled(false)
            } else if ((mode === 'edit') && (isDirty)) {
                isSetSaveButtonDisabled(false);
            } else {
                isSetSaveButtonDisabled(true);
            }
        }
    }, [branchValue, ensObjectToBeEditedOrView, emailNotificationSubscriptiontoBeEdited, mode, isValid, isDirty, selectedTriggerEvent, bpTypeValue])

    const resetComboValueForBpType = () => {

    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container className={classes.root}>
                <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} >
                        <Dialog fullWidth maxWidth='lg' onClose={handleClose} aria-labelledby="create-or-delete-dialog" open={open} disableBackdropClick={true} >
                            <DialogTitle className={classes.titleCommonDialogComponent}>
                                <Grid item container alignItems='center' item xs={12} sm={12} md={12} lg={12} style={{ marginLeft: '-1%' }} >
                                    <Grid item container alignItems='center' xs={6} sm={8} md={8} lg={6}>
                                        <CustomBreadCrumb componentList={componentList} isDialog={true} />
                                    </Grid>
                                    <Grid item container justifyContent='flex-end' alignItems='center' xs={6} sm={4} md={4} lg={6} style={{ color: '#fff', fontSize: '14px' }} >
                                        {orgName} {dialogHeader} {entityName}
                                    </Grid>
                                </Grid>
                            </DialogTitle>

                            <DialogContent dividers style={smallScreen ? { minHeight: '70vh' } : { minHeight: '60vh' }} >
                                <Grid item container alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                    <Grid item container alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                        <form className={classes.form}>
                                            <Grid container className={classes.root}>
                                                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} style={{ marginBottom: '-1.4%' }}>
                                                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                                        <Grid item container xs={12} sm={6} md={7} lg={6}>
                                                            <EntityCodeCombo comboProperty={branchComboProperty}
                                                                control={control}
                                                                errors={errors}
                                                                comboValue={branchValue}
                                                                setComboValue={setBranchValue} />
                                                        </Grid>
                                                        <Grid item container xs={12} sm={6} md={7} lg={6}>
                                                            <BpTypeCombo
                                                                comboProperty={bpTypeComboProperty}
                                                                control={control} errors={errors}
                                                                comboValue={bpTypeValue}
                                                                setComboValue={setBpTypeValue}
                                                                resetComboValueForBpType={resetComboValueForBpType} />
                                                        </Grid>
                                                        <Grid item container xs={12} sm={6} md={7} lg={6}>
                                                            <ProcessNameCombo
                                                                comboProperty={processComboProperty}
                                                                control={control}
                                                                errors={errors}
                                                                comboValue={selectedProcess}
                                                                setComboValue={setSelectedProcess}
                                                                bpTypeValueCombo={{ bpType: bpTypeValue.value }}
                                                                resetComboValueForProcessName={resetComboValueForProcessName}
                                                            />
                                                        </Grid>
                                                        <Grid item container xs={12} sm={6} md={7} lg={6}>
                                                            <TriggerEventCombo
                                                                comboProperty={triggerEventComboProperty}
                                                                control={control}
                                                                errors={errors}
                                                                comboValue={selectedTriggerEvent}
                                                                setComboValue={setSelectedTriggerEvent}
                                                            />
                                                        </Grid>
                                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                                            <FormControl fullWidth>
                                                                <Controller

                                                                    control={control}
                                                                    name={pageLabelsConstantsAndMessages.name.entityEmailTo}
                                                                    rules={mode === 'view' ? { required: false } : {
                                                                        // required: true,
                                                                        maxLength: 5000,
                                                                        pattern: commonPatterns.commonTextfeildPattern.patternForEmailId
                                                                    }}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            className={classes.textField}
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            id="entityEmailTo"
                                                                            size='small'
                                                                            label={<FormattedMessage id="textfield.emailto.label"
                                                                                defaultMessage="entityEmailTo" />}
                                                                            multiline={true}
                                                                            // required={true}
                                                                            {...field}
                                                                            error={errors.entityEmailTo}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {
                                                                                    asterisk: classes.asterisk
                                                                                }
                                                                            }}
                                                                            InputProps={{
                                                                                readOnly: emailNotificationSubscriptionReadOnlyFields.entityEmailTo,
                                                                                classes: { notchedOutline: classes.specialOutline }
                                                                            }}
                                                                            disabled={mode === 'view' ? true : false}
                                                                        />
                                                                    )}
                                                                />
                                                                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={6} md={12} lg={12}>
                                                                    <FormHelperText><FormattedMessage id="texfield.placeholder.emailid.label"
                                                                        defaultMessage="Separate Multiple EmailID's with Semicolon(;)" /></FormHelperText>
                                                                </Grid>

                                                                <div className={classes.error}>
                                                                    {/* {_.get(`${pageLabelsConstantsAndMessages.name.entityEmailTo}.type`, errors) === "required" && (
                                                                        <FormHelperText className={classes.error}><FormattedMessage id="textfield.emailto.label" defaultMessage="Email To" />  {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                                                    )} */}

                                                                    {_.get(`${pageLabelsConstantsAndMessages.name.entityEmailTo}.type`, errors) === "maxLength" && (
                                                                        <FormHelperText className={classes.error}><FormattedMessage id="textfield.emailto.label" defaultMessage="Email To" />{<FormattedMessage id="textfield.error.cannotexceedmorethanfivethousandcharsmessage" />}</FormHelperText>
                                                                    )}
                                                                    {_.get(`${pageLabelsConstantsAndMessages.name.entityEmailTo}.type`, errors) === "pattern" && (
                                                                        <FormHelperText className={classes.error}> <FormattedMessage id="textfield.emailto.label" defaultMessage="Email To" /> {commonPatterns.commonErrorsForTextFields.errorForEmailId}</FormHelperText>
                                                                    )}
                                                                </div>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                                            <FormControl fullWidth>
                                                                <Controller

                                                                    control={control}
                                                                    name={pageLabelsConstantsAndMessages.name.entityEmailCc}
                                                                    rules={mode === 'view' ? { required: false } : {
                                                                        //required: true,
                                                                        pattern: commonPatterns.commonTextfeildPattern.patternForEmailId
                                                                    }}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            className={classes.textField}
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            id="entityEmailCc"
                                                                            size='small'
                                                                            label={<FormattedMessage id="columnname.entityEmailCc.label"
                                                                                defaultMessage="entityEmailCc" />}
                                                                            multiline={true}
                                                                            //required={true}
                                                                            {...field}
                                                                            error={errors.entityEmailCc}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {
                                                                                    asterisk: classes.asterisk
                                                                                }
                                                                            }}
                                                                            InputProps={{
                                                                                readOnly: emailNotificationSubscriptionReadOnlyFields.entityEmailCc,
                                                                                classes: { notchedOutline: classes.specialOutline }
                                                                            }}
                                                                            disabled={mode === 'view' ? true : false}
                                                                        />
                                                                    )}
                                                                />
                                                                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={6} md={12} lg={12}>
                                                                    <FormHelperText><FormattedMessage id="texfield.placeholder.emailid.label"
                                                                        defaultMessage="Separate Multiple EmailID's with Semicolon(;)" /></FormHelperText>
                                                                </Grid>
                                                                <div className={classes.error}>
                                                                    {/* {_.get(`${pageLabelsConstantsAndMessages.name.entityEmailCc}.type`, errors) === "required" && (
                                                                        <FormHelperText className={classes.error}><FormattedMessage id="textfield.emailCc.label" defaultMessage="Email CC" />  {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                                                    )} */}

                                                                    {_.get(`${pageLabelsConstantsAndMessages.name.entityEmailCc}.type`, errors) === "maxLength" && (
                                                                        <FormHelperText className={classes.error}><FormattedMessage id="textfield.emailCc.label" defaultMessage="Email CC" />{<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                                                    )}
                                                                    {_.get(`${pageLabelsConstantsAndMessages.name.entityEmailCc}.type`, errors) === "pattern" && (
                                                                        <FormHelperText className={classes.error}> <FormattedMessage id="textfield.emailCc.label" defaultMessage="Email CC" /> {commonPatterns.commonErrorsForTextFields.errorForEmailId}</FormHelperText>
                                                                    )}
                                                                </div>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                                            <FormControl fullWidth>
                                                                <Controller

                                                                    control={control}
                                                                    name={pageLabelsConstantsAndMessages.name.entityEmailBcc}
                                                                    rules={mode === 'view' ? { required: false } : {
                                                                        // required: true,
                                                                        pattern: commonPatterns.commonTextfeildPattern.patternForEmailId
                                                                    }}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            className={classes.textField}
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            id="entityEmailBcc"
                                                                            size='small'
                                                                            label={<FormattedMessage id="columnname.entityEmailBcc.label"
                                                                                defaultMessage="entityEmailBcc" />}
                                                                            multiline={true}
                                                                            //required={true}
                                                                            {...field}
                                                                            error={errors.entityEmailBcc}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {
                                                                                    asterisk: classes.asterisk
                                                                                }
                                                                            }}
                                                                            InputProps={{
                                                                                readOnly: emailNotificationSubscriptionReadOnlyFields.entityEmailBcc,
                                                                                classes: { notchedOutline: classes.specialOutline }
                                                                            }}
                                                                            disabled={mode === 'view' ? true : false}
                                                                        />
                                                                    )}
                                                                />
                                                                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={6} md={12} lg={12}>
                                                                    <FormHelperText><FormattedMessage id="texfield.placeholder.emailid.label"
                                                                        defaultMessage="Separate Multiple EmailID's with Semicolon(;)" /></FormHelperText>
                                                                </Grid>

                                                                <div className={classes.error}>
                                                                    {/* {_.get(`${pageLabelsConstantsAndMessages.name.entityEmailBcc}.type`, errors) === "required" && (
                                                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.entityEmailBcc.label" defaultMessage="Email BCC" />  {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                                                    )} */}

                                                                    {_.get(`${pageLabelsConstantsAndMessages.name.entityEmailBcc}.type`, errors) === "maxLength" && (
                                                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.entityEmailBcc.label" defaultMessage="Email BCC" />{<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                                                    )}
                                                                    {_.get(`${pageLabelsConstantsAndMessages.name.entityEmailBcc}.type`, errors) === "pattern" && (
                                                                        <FormHelperText className={classes.error}> <FormattedMessage id="columnname.entityEmailBcc.label" defaultMessage="Email BCC" /> {commonPatterns.commonErrorsForTextFields.errorForEmailId}</FormHelperText>
                                                                    )}
                                                                </div>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                                            <FormControl fullWidth>
                                                                <Controller
                                                                    control={control}
                                                                    name={pageLabelsConstantsAndMessages.name.bpCode}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            className={classes.textField}
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            id="bpCode"
                                                                            size='small'
                                                                            label={<FormattedMessage id="common.textfield.columname.bpcode" />}
                                                                            multiline={true}
                                                                            {...field}
                                                                            error={errors.bpCode}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {
                                                                                    asterisk: classes.asterisk
                                                                                }
                                                                            }}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                                classes: { notchedOutline: classes.specialOutline }
                                                                            }}
                                                                            disabled={mode === 'view' ? true : false}
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                                            <FormControl fullWidth>
                                                                <Controller
                                                                    control={control}
                                                                    name={pageLabelsConstantsAndMessages.name.processedEmailTo}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            className={classes.textField}
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            id="processedEmailTo"
                                                                            size='small'
                                                                            label={<FormattedMessage id="columnname.processedEmailTo.label" />}
                                                                            multiline={true}
                                                                            {...field}
                                                                            // error={errors.processedEmailTo}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {
                                                                                    asterisk: classes.asterisk
                                                                                }
                                                                            }}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                                classes: { notchedOutline: classes.specialOutline }
                                                                            }}
                                                                            disabled={mode === 'view' ? true : false}
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                                            <FormControl fullWidth>
                                                                <Controller

                                                                    control={control}
                                                                    name={pageLabelsConstantsAndMessages.name.processedEmailCc}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            className={classes.textField}
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            id="processedEmailCc"
                                                                            size='small'
                                                                            label={<FormattedMessage id="columnname.processedEmailCc.label" />}
                                                                            multiline={true}
                                                                            {...field}
                                                                            // error={errors.processedEmailCc}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {
                                                                                    asterisk: classes.asterisk
                                                                                }
                                                                            }}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                                classes: { notchedOutline: classes.specialOutline }
                                                                            }}
                                                                            disabled={mode === 'view' ? true : false}
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                                            <FormControl fullWidth>
                                                                <Controller

                                                                    control={control}
                                                                    name={pageLabelsConstantsAndMessages.name.processedEmailBcc}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            className={classes.textField}
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            id="processedEmailBcc"
                                                                            size='small'
                                                                            label={<FormattedMessage id="columnname.processedEmailBcc.label" />}
                                                                            multiline={true}
                                                                            {...field}
                                                                            // error={errors.processedEmailBcc}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {
                                                                                    asterisk: classes.asterisk
                                                                                }
                                                                            }}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                                classes: { notchedOutline: classes.specialOutline }
                                                                            }}
                                                                            disabled={mode === 'view' ? true : false}
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                            <Grid container justifyContent='flex-start' alignItems='center'>
                                                                <Controller
                                                                    render={({
                                                                        field: { onChange } }) => (<Switch
                                                                            color='primary'
                                                                            checked={emailNotificationSubscriptiontoBeEdited.active !== undefined ? emailNotificationSubscriptiontoBeEdited.active : true}
                                                                            onChange={((event) => {
                                                                                onChange(!(emailNotificationSubscriptiontoBeEdited.active !== undefined ? emailNotificationSubscriptiontoBeEdited.active : true));
                                                                                setEmailNotificationSubscriptiontoBeEdited({ ...emailNotificationSubscriptiontoBeEdited, active: event.target.checked })
                                                                            })}
                                                                            disabled={mode === 'view' ? true : false}
                                                                        />)}
                                                                    type="checkbox"
                                                                    name={pageLabelsConstantsAndMessages.name.active}
                                                                    control={control}
                                                                    defaultValue={emailNotificationSubscriptiontoBeEdited.active !== undefined ? emailNotificationSubscriptiontoBeEdited.active : true}

                                                                />
                                                                <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                </Grid>

                            </DialogContent>

                            {!mobileScreen && (
                                <DialogActions className={classes.dialogAction} >
                                    <Grid container justifyContent="flex-start" alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} >
                                        {(mode === 'create' || mode === 'edit') ?
                                            null : null}
                                        {(mode === 'create' || mode === 'edit') ?
                                            <Typography justifyContent="flex-start" style={{ fontSize: 12, marginLeft: '1%' }}><FormattedMessage id="textfield.placeholder.dialogActions" defaultMessage="All the '*' marked fields are mandatory to submit the form" /></Typography> : null}
                                    </Grid>
                                    <Grid item container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} >
                                        <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2} endIcon={<ClearIcon />}
                                            onClick={() => handleClose(false)}
                                        ><FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />
                                        </Button>

                                        <Button size='small' variant="contained" color="secondary" className={classes.stateButtonToRestore}
                                            disabled={disableRestoreButton} elevation={2} endIcon={<IconRestore />} onClick={(() => { resetAllClearTheBasicInfo() })}
                                        > <FormattedMessage id="common.button.reset.lable" defaultMessage="Restore" />
                                        </Button>

                                        <Button size='small'
                                            variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                            endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />}
                                            disabled={isFormSubmitted || disablesaveButton}
                                            onClick={handleSubmit(onSubmit, onError)}>
                                            {saveOrUpdateButtonLabel}
                                        </Button>
                                    </Grid>
                                </DialogActions>
                            )}

                            {mobileScreen && (<Grid item container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                <DialogActions className={classes.dialogAction} >
                                    <Grid item container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.topGrid} >
                                        <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                                            <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2}
                                                onClick={() => handleClose(false)}
                                            ><ClearIcon />
                                            </Button>
                                        </Tooltip>

                                        <Tooltip title={<FormattedMessage id="common.button.reset.lable" />}>
                                            <Button size='small' variant="contained" color="secondary" className={classes.stateButtonToRestore}
                                                disabled={disableRestoreButton} elevation={2} onClick={(() => { resetAllClearTheBasicInfo() })}><IconRestore />
                                            </Button>
                                        </Tooltip>

                                        <Tooltip title={(mode === 'create') ? <FormattedMessage id="common.button.save" /> : <FormattedMessage id="common.button.update" />}>
                                            <Button size='small'
                                                variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                                disabled={isFormSubmitted || disablesaveButton}
                                                endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : null}
                                                onClick={handleSubmit(onSubmit, onError)}>
                                                <IconSaveOrUpdate />
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </DialogActions>
                            </Grid>)}
                        </Dialog>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
export default DialogComponentEns;

