function FakeBackendForEmailReportTemplateMapping() {
    return (
        {
            "content": [
            ],
            "pageable": {
                "sort": {
                    "sorted": true,
                    "unsorted": false,
                    "empty": false
                },
                "offset": 0,
                "pageSize": 25,
                "pageNumber": 0,
                "paged": true,
                "unpaged": false
            },
            "totalElements": 1,
            "last": true,
            "totalPages": 1,
            "size": 25,
            "number": 0,
            "sort": {
                "sorted": true,
                "unsorted": false,
                "empty": false
            },
            "numberOfElements": 1,
            "first": true,
            "empty": false
        }
    )
}
export default FakeBackendForEmailReportTemplateMapping;