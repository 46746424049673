import React, { useEffect, useState } from 'react';
import _ from "lodash/fp";
import { FormControl, FormHelperText, CssBaseline, TextField, Grid, InputLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import useStyles from '../../../common/CommonStyle/CommonStyle';
import { FormattedMessage } from 'react-intl';
import Switch from '@mui/material/Switch';
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';



function BasicDetailsForm(props) {
    const classes = useStyles();
    const { basicmode, control, errors, orgObjectToBeEditedOrView, setHasErrors } = props
    const [orgaizationtoBeEdited, setOrganizationtoBeEdited] = React.useState({});
    const commonPatterns = GlobalEdiApiConstants();

    const [organizationReadOnlyFields, setOrganizationReadOnlyFields] = useState({
        orgName: false,
        erpOrgCode: false,
        active: false
    });

    const pageLabelsConstantsAndMessages = {
        label: {
            orgName: 'Organization Name',
            erpOrgCode: 'Organization Code',
            active: 'Active'
        },
        name: {
            orgName: 'orgName',
            erpOrgCode: 'erpOrgCode',
            active: 'active',
        }
    }

    useEffect(() => {
        setOrganizationtoBeEdited(orgObjectToBeEditedOrView);
    }, [orgObjectToBeEditedOrView]);


    useEffect(() => {
        if (basicmode === 'view') {
            setOrganizationReadOnlyFields({ ...organizationReadOnlyFields, orgName: true, erpOrgCode: true });
        }
    }, [basicmode]);

    useEffect(() => {
        setHasErrors(errors)
    }, [errors])

    return (
        <React.Fragment>
            <CssBaseline />

            <Grid container className={classes.root}>
                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} style={{marginTop: '-1%'}}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >

                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.orgName}
                                    rules={basicmode === 'view' ? { required: false } : {
                                        required: true,
                                        maxLength: 100,
                                        pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UCSHF
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="orgName"
                                            size='small'
                                            label={<FormattedMessage id="columnname.OrganizationName.label" defaultMessage="OrganizationName" />}
                                            required={true}
                                            {...field}
                                            error={errors.orgName}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.orgName,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={basicmode === 'view' ? true : false}
                                        />
                                    )}
                                />

                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.orgName}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>
                                            {<FormattedMessage id="columnname.OrganizationName.label" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}
                                        </FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.orgName}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>   {<FormattedMessage id="columnname.OrganizationName.label" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UCSHF}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.orgName}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.OrganizationName.label" defaultMessage="OrganizationName" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>

                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.erpOrgCode}
                                    rules={basicmode === 'view' ? { required: false } : {
                                        required: true,
                                        maxLength: 50,
                                        pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UHF
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required={true}
                                            variant="outlined"
                                            margin="normal"
                                            id="erpOrgCode"
                                            size='small'
                                            label={<FormattedMessage id="columnname.organizationcode.label" defaultMessage="Org Code" />}
                                            {...field}
                                            error={errors.erpOrgCode}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.erpOrgCode,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={basicmode === 'view' ? true : false}
                                        />
                                    )}
                                />
                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.erpOrgCode}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>
                                            {<FormattedMessage id="columnname.organizationcode.label" />}  {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.erpOrgCode}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>  {<FormattedMessage id="columnname.organizationcode.label" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.erpOrgCode}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>
                                            {<FormattedMessage id="columnname.organizationcode.label" defaultMessage="Org Code" />}  {<FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" defaultMessage="Org Code cannot exceed more than 50 chars" />}
                                        </FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <Grid container justifyContent='flex-start' alignItems='center'>
                                <Controller
                                    render={({
                                        field: { onChange } }) => (<Switch
                                            color='primary'
                                            checked={orgaizationtoBeEdited.active !== undefined ? orgaizationtoBeEdited.active : true}
                                            onChange={((event) => {
                                                onChange(!(orgaizationtoBeEdited.active !== undefined ? orgaizationtoBeEdited.active : true));
                                                setOrganizationtoBeEdited({ ...orgaizationtoBeEdited, active: event.target.checked })
                                            })}
                                            disabled={basicmode === 'view' ? true : false}
                                        />)}
                                    type="checkbox"
                                    name={pageLabelsConstantsAndMessages.name.active}
                                    control={control}
                                    defaultValue={orgaizationtoBeEdited.active !== undefined ? orgaizationtoBeEdited.active : true}

                                />
                                <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default BasicDetailsForm;