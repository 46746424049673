import React, { useState } from 'react';
import {
    Button, CircularProgress, CssBaseline, Dialog, DialogActions, DialogContent,
    DialogTitle, FormControl, FormHelperText, Grid, Paper, Snackbar, TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm, Controller } from 'react-hook-form';
import _ from "lodash/fp";
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import { CommonService } from '../../../services';
import { Alert } from '@mui/lab';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    buttonSpacing: {
        margin: theme.spacing(0.5),
    },
    buttonSpacingSubmit: {
        margin: theme.spacing(0.5),
        backgroundColor: theme.palette.customPrimary.secondary,
    },
    buttonSpacingResend: {
        margin: theme.spacing(0.5),
        color: theme.palette.customPrimary.secondary,
    },
    dialogactions: {
        padding: '1% 5%'
    },
    dialogecontent: {
        padding: theme.spacing(2),
    },
    error: {
        color: theme.palette.error.main,
    },
    title: {
        fontWeight: "bold",
        backgroundColor: theme.palette.customPrimary.secondary,
        color: theme.palette.customText.main,
    },
    asterisk: {
        color: "red"
    }
}));
function VerifyOTP(props) {
    const classes = useStyles();
    const { onClose, open, emailId, causeOfGeneration, onResend, setRequestedFormVerifyOtpDialog, loginId } = props;

    const { handleSubmit, control, formState: { errors } } = useForm({
        mode: "all",
        defaultValues: {
            OTP: "",
        }
    });

    const maxTimeInSecToReSendOtp = 90;
    const [timeLeft, setTimeLeft] = useState(maxTimeInSecToReSendOtp);

    const handleClose = (value) => {
        setTimeLeft(maxTimeInSecToReSendOtp);
        onClose(value);
    }

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const verifyOtpPageLabelsNamesAndConst = {
        labels: {

            cancel: 'Cancel',
            submit: 'Submit',
            dialogTitle: 'Verify OTP',
            OTP: "OTP",
            reSend: "Resend"
        },
        name: {
            OTP: "value",
        },
    };

    const [isResponseArrived, setResponseArrived] = useState(false);
    const onSubmit = data => {
        data['emailId'] = emailId !== undefined ? emailId : '';
        data['causeOfGeneration'] = causeOfGeneration !== undefined ? causeOfGeneration : '';
        data['loginId'] = loginId;
        setResponseArrived(true);
        CommonService.saveOrUpdateOrPost('/users/verifyOtp', data)
            .then((response) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.message, severity: "success" });
                setResponseArrived(false);
                onClose(true);
            })
            .catch(function (error) {
                setResponseArrived(false);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }


    function calculateTimeLeft() {
        if (timeLeft > 0) {
            return timeLeft - 1;
        }
        else {
            return 0;
        }
    }


    React.useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                setTimeLeft(
                    calculateTimeLeft()
                );
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [open, timeLeft]);

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container className={classes.root}>
                <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={6} lg={6}>
                    <Paper className={classes.paper} elevation={4} >
                        <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                            <Grid item container xs={12} sm={12} md={12} lg={12}>
                                <Dialog
                                    fullWidth
                                    aria-labelledby="customized-dialog-title"
                                    open={open}>
                                    <DialogTitle color='primary' className={classes.title} >
                                        <Grid container>
                                            <Grid container justifyContent="flex-start" item xs={12} sm={12} md={12} lg={12} className={classes.verifyOtp}>
                                                <strong>{<FormattedMessage id="dialogtitle.verifyotp.label" defaultMessage="Verify OTP" />} </strong>
                                            </Grid>
                                        </Grid>
                                    </DialogTitle>
                                    <DialogContent dividers className={classes.dialogecontent}>
                                        <form className={classes.form}>
                                            <Grid item container alignItems="flex-start" xs={12} sm={12} md={12} lg={12}>
                                                <FormControl fullWidth>
                                                    <Controller
                                                        control={control}
                                                        name={verifyOtpPageLabelsNamesAndConst.name.OTP}
                                                        rules={{ required: true ,
                                                            minLength: 4}}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <TextField
                                                                autoFocus
                                                                required={true}
                                                                type="number"
                                                                alignItems="center"
                                                                variant="outlined"
                                                                margin="normal"
                                                                size='small'
                                                                name={verifyOtpPageLabelsNamesAndConst.name.OTP}
                                                                label={<FormattedMessage id="verifyotp.otp.label" defaultMessage="OTP" />}
                                                                inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                                                {...field}
                                                                error={errors.OTP}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    classes: {
                                                                        asterisk: classes.asterisk
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />

                                                    <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                                        <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={6} md={6} lg={6}>
                                                            <FormHelperText><FormattedMessage id="textfield.error.pleaseenterotp" defaultMessage="Please enter OTP" /></FormHelperText>
                                                        </Grid>
                                                        <Grid item container justifyContent='flex-end' alignItems='center' xs={12} sm={6} md={6} lg={6}>

                                                            <Button className={classes.buttonSpacingResend} size='small' color='primary' disabled={!(timeLeft === 0)}
                                                                onClick={(() => {
                                                                    setRequestedFormVerifyOtpDialog(true);
                                                                    onResend();
                                                                    setTimeLeft(60)
                                                                })}
                                                            >
                                                                {<FormattedMessage id="verifyotp.resend.label" defaultMessage="Resend" />}
                                                            </Button>
                                                            {(timeLeft !== 0) && (<span><FormattedMessage id="verify.in.txt" defaultMessage="in" /> {timeLeft} <FormattedMessage id="verify.sec.txt" defaultMessage="sec" /></span>)}

                                                        </Grid>
                                                    </Grid>
                                                    <div className={classes.error}>
                                                        {_.get(`${verifyOtpPageLabelsNamesAndConst.name.OTP}.type`, errors) === "required" && (
                                                            <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.error.otpisrequired" defaultMessage="OTP is required" />}</FormHelperText>
                                                        )}
                                                        {_.get(`${verifyOtpPageLabelsNamesAndConst.name.OTP}.type`, errors) === "minLength" && (
                                                            <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.error.verifyotpminlength" defaultMessage="OTP cannot be less than 4 characters" />} </FormHelperText>
                                                        )}
                                                    </div>
                                                </FormControl>
                                            </Grid>
                                        </form>
                                    </DialogContent>
                                    <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                        <DialogActions className={classes.dialogAction}>
                                            <Button size='small' variant='contained' color='secondary' className={classes.buttonSpacing}
                                                startIcon={<ClearIcon />}
                                                onClick={(() => {
                                                    handleClose(false);
                                                })}
                                            >{<FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />}
                                            </Button>
                                            <Button type="submit" size='small' variant='contained' color='primary' className={classes.buttonSpacingSubmit} startIcon={<SaveIcon />}
                                                disabled={isResponseArrived} endIcon={isResponseArrived ? <CircularProgress color="secondary" size={20} /> : null}
                                                onClick={handleSubmit(onSubmit)}> {<FormattedMessage id="common.button.submit.lable" defaultMessage="Submit" />}
                                            </Button>
                                        </DialogActions>
                                    </Grid>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default VerifyOTP;
