import {CssBaseline, FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import useStyles from '../../CommonStyle/CommonStyle'

const JobTypeCombo = (props) => {

    const classes = useStyles();
    const { comboProperty, control, errors, comboValue, setComboValue } = props;
    const statusOption = [
        {id: 1, value: 'IMPORT', name: 'IMPORT'},
        {id: 2, value: 'EXPORT', name: 'EXPORT'},  
    ];

return (
    <React.Fragment>
        <CssBaseline />
    { comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
        <FormControl fullWidth>
            <Controller
                render={props => (
                    <Autocomplete

                        onChange={(event, newValue) => {
                            props.onChange(newValue)

                            if (typeof newValue === 'string') {

                                if (newValue != null) {
                                    setComboValue({
                                        newValue,
                                    });
                                }
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setComboValue({
                                    title: newValue.inputValue,
                                });
                            }
                            else if (newValue !== null) {
                                setComboValue(newValue);
                            } else if (newValue === null) {
                                setComboValue('');
                            } else {
                                setComboValue(newValue);
                            }
                        }}
                        loadingText="Loading..."
                        selectOnFocus
                        handleHomeEndKeys
                        value={comboValue.name || null}
                        autoHighlight
                        // onOpen={loadRolesData}
                        options={statusOption}
                        getOptionLabel={(option) => {

                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.name;
                        }}
                        getoptionselected={(option, value) => option.name === value}
                        disabled={comboProperty.isDisabled}
                        renderInput={params => (
                            <TextField
                                {...params}
                                autoFocus={comboProperty.isAutofocus}
                                name={comboProperty.name}
                                size='small'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                variant={comboProperty.variant}
                            />
                        )}

                    />
                )}

                name={comboProperty.name}
                rules={{ required: comboProperty.isRequired }}
                control={control}
                defaultValue={{ id: 0, name: '', value: '' }}
            />
            {comboProperty.isDisabled && (<FormHelperText>{comboProperty.label} can't be edited</FormHelperText>)}
            <div className={classes.error}>
                {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                    <FormHelperText className={classes.error}>{comboProperty.label} is required</FormHelperText>
                )}
            </div>
        </FormControl>
    </Grid>)}
    { (!comboProperty.isHookFormType) && (<Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: "3px"}}>
    <FormControl fullWidth>
        <Autocomplete
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {

                            if (newValue != null) {
                                setComboValue({
                                    newValue,
                                });
                            }
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setComboValue({
                                title: newValue.inputValue,
                            });
                        }
                        else if (newValue !== null) {
                            setComboValue(newValue);
                        } else if (newValue === null) {
                            setComboValue('')
                        } else {
                            setComboValue(newValue);
                        }
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                    value={comboValue.name || null}
                    autoHighlight
                    options={statusOption}
                    loadingText="Loading..."
                    getOptionLabel={(option) => {

                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.name;
                    }}
                    getoptionselected={(option, value) => option.name === value}
                    disabled={comboProperty.isDisabled}
                    renderInput={params => (
                        <TextField
                            {...params}
                            autoFocus={comboProperty.isAutofocus}
                            name={comboProperty.name}
                            size='small'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                            variant={comboProperty.variant}
                        />
                    )}

                />
    </FormControl>
    </Grid>)}
    </React.Fragment>
);
};

JobTypeCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default JobTypeCombo;

