import { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import useStyles from "../../common/CommonStyle/CommonStyle"
import { LoggedInUserDetails } from "../../common/LoggedInUserDetails/LoggedInUserDetails";
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import {
    Autocomplete,
    Button, CssBaseline, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar,
    Switch, TextField, Tooltip, useMediaQuery, useTheme
} from "@mui/material";
import { Alert } from "@mui/lab";
import CommonBackDrop from "../../common/BackDrop";
import CustomBreadCrumb from "../../common/CustomBreadCrumb";
import IconBack from '@mui/icons-material/BackspaceOutlined';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import IconRestore from '@mui/icons-material/AutorenewOutlined'
import { Controller, useForm } from "react-hook-form";
import _ from "lodash/fp";
import { CommonService } from "../../../services";
import EntityCodeCombo from "../../common/Combos/EntityCodeCombo";
import { GlobalEdiApiConstants } from "../../../Constants/GlobalEdiApiConstants";
import { ModuleAccessPermissionKey } from "../../../Constants/ModuleAccessKey";
import ViewIcon from '@mui/icons-material/Visibility';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import IconImportTemplate from '@mui/icons-material/UploadFile';
import FileTypeForCustomReports from "../../common/Combos/FileTypeForCustomReports";
import AttachFileIcon from '@mui/icons-material/AttachFileRounded';
import ImportTemplateProcessMasterCombo from "../../common/Combos/ImportTemplateProcessMasterCombo";
import { DataGridPro, gridColumnVisibilityModelSelector, useGridApiContext } from "@mui/x-data-grid-pro";
import PropTypes from 'prop-types';
import BpTypeCombo from "../../common/Combos/BpTypeCombo/BpTypeCombo";
import BpCodeCombo from "../../common/Combos/BpCodeCombo/BpCodeCombo";
import * as XLSX from 'xlsx/xlsx.mjs';
import DelimitersCombo from '../../common/Combos/DelimitersCombo/DelimitersCombo';

const CreateOrEditImportTemplate = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const commonPatterns = GlobalEdiApiConstants();
    const theme = useTheme();
    const { mode, importTmplId } = props.location.state;
    const currentUser = CommonService.getLoggedInUserInfo();
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const importTemplateModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.importTemplate];
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());
    const [defaultOrgObj, setDefaultOrgObj] = useState({});
    const smallScreen = useMediaQuery(theme.breakpoints.down(889))
    useEffect(() => {
        if (orgInfoOfLeggedUser.erpOrgCode && mode === 'create') {
            setDefaultOrgObj({
                erpOrgCode: orgInfoOfLeggedUser.erpOrgCode, orgName: orgInfoOfLeggedUser.orgName,
                valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}`
            });
        }
    }, [orgInfoOfLeggedUser])

    const pageLabelsConstantsAndMessages = {
        name: {
            importTmplName: 'importTmplName',
            fileType: 'fileType',
            bpType: 'bpType',
            importTmplMasterId: 'importTmplMasterId',
            organization: 'organization',
            entity: 'entity',
            active: 'active',
            bpCode: 'bpCode',
            delimiter: 'delimiter',
            importTemplateProcessMaster: 'importTemplateProcessMaster',
            startIndex: 'startIndex',
            fileName: 'fileName'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
    }

    const dateFormatValue = [
        { value: 'dd-MM-yyyy' },
        { value: 'dd.MM.yyyy' },
        { value: 'dd/MM/yyyy' },
        { value: 'MM-dd-yyyy' },
        { value: 'MM/dd/yyyy' },
        { value: 'yyyy-MM-dd' },
        { value: 'yyyyMMdd' },
        { value: 'ddMMyyyy' },
        { value: 'dd-MMM-yyyy' },
        { value: 'dd/MMM/yyyy' }
    ];

    const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('');
    const [componentList, setComponentList] = useState([]);
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
    const [templateToBeEdited, setTemplateToBeEdited] = React.useState({});
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [branchValue, setBranchValue] = useState('');
    const [orgValue, setOrgValue] = useState('');
    const [fileTypeValue, setFileTypeValue] = useState('');
    const [bpTypeValue, setBpTypeValue] = useState('');
    const [bpCodeValue, setBpCodeValue] = useState('');
    const [disabledForReadMode, isDisabledForReadMode] = useState(false);
    const [disableSaveOrUpdateButton, issaveOrUpdateButtonDisabled] = useState(false);
    const [dataStartIndexValue, setDataStartIndexValue] = useState('0');
    const [delimiterValue, setDelimiterValue] = useState({ id: '', value: '' });
    const [processNameValue, setProcessNameValue] = useState('');
    const [importedColumnList, setImportedColumnList] = useState([]);
    const [importedColumnListForEdit, setImportedColumnListForEdit] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState('');
    const [importTemplateMasterData, setImportTemplateMasterData] = useState([]);
    const [processColumnList, setProcessColumnList] = useState([]);
    const importTmplMasterId = processNameValue === '' ? 0 : processNameValue.importTmplMasterId;
    const [importedFileObjectList, setImportedFileObjectList] = useState([]);
    const [importedFileObjectListForStartIndexZero, setImportedFileObjectListForStartIndexZero] = useState([]);
    const [importTemplateConfiguration, setImportTemplateConfiguration] = useState([]);
    const [uniqueCombinationsObject, setUniqueCombinationsObject] = useState([]);
    const [destinationColumnsObject, setDestinationColumnsObject] = useState([]);
    const [pipeLineObject, setPipeLineObject] = useState([]);
    const [repeatObject, setRepeatObject] = useState([]);
    const [errorCodesObject, setErrorCodesObject] = useState([]);
    const [importTemplateHedaerColumns, setImportTemplateHedaerColumns] = useState([]);

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const [userReadOnlyFields, setUserReadOnlyFields] = useState({
        name: false,
        subject: false,
        organization: false,
        entity: false,
        active: false,
        msgContent: false
    });

    const [mappedColumnValue, setMappedColumnValue] = useState(null);

    const [worksheet, setWorkSheet] = useState('');

    const workSheetkeyObject = worksheet !== '' ? worksheet.split(',').map((col) => {
        return {
            index: col
        }
    }) : []

    const key = "index"

    const uniqueHeaderIndexData = [...new Map(workSheetkeyObject.map(item => [item[key], item])).values()];

    const filterUniqueHeaderIndexData = uniqueHeaderIndexData.filter((col) => {
        return !col.index.includes('!')
    });

    useEffect(() => {
        if (selectedFileName !== '') {
            if (dataStartIndexValue !== '') {
                if (dataStartIndexValue === '0') {
                    if (importedFileObjectListForStartIndexZero.length > 0) {
                        if (filterUniqueHeaderIndexData.length > 0) {
                            setImportedColumnList(importedFileObjectListForStartIndexZero.map((col, index) => {
                                return {
                                    headerName: col,
                                    headerIndex: filterUniqueHeaderIndexData[index] !== undefined ? filterUniqueHeaderIndexData[index].index : '',
                                    displayName: '',
                                    // index: null,
                                    // id: null,
                                    validations: {
                                        requiredDataType: null,
                                        templateDataType: null,
                                        minLength: null,
                                        maxLength: null,
                                        mandatory: null,
                                        dataFormat: null,
                                        minOccurrence: null,
                                        maxOccurrence: null,
                                        repeatation: null,
                                        group: null,
                                        transit: null
                                    }
                                }
                            }))
                        }
                    }
                } else if (dataStartIndexValue >= '0') {
                    if (filterUniqueHeaderIndexData.length > 0) {
                        if (importedFileObjectList[dataStartIndexValue] !== undefined) {
                            setImportedColumnList(Object.values(importedFileObjectList[dataStartIndexValue - 1]).map((col, index) => {
                                return {
                                    headerName: col,
                                    headerIndex: filterUniqueHeaderIndexData[index] !== undefined ? filterUniqueHeaderIndexData[index].index : '',
                                    displayName: '',
                                    // index: null,
                                    // id: null,
                                    validations: {
                                        requiredDataType: null,
                                        templateDataType: null,
                                        minLength: null,
                                        maxLength: null,
                                        mandatory: null,
                                        dataFormat: null,
                                        minOccurrence: null,
                                        maxOccurrence: null,
                                        repeatation: null,
                                        group: null,
                                        transit: null
                                    }
                                }
                            }))
                        }
                    }
                }
            }
        }
    }, [selectedFileName, dataStartIndexValue, importedFileObjectList, importedFileObjectListForStartIndexZero])

    useEffect(() => {
        if (mode === 'edit' || mode === 'read') {
            setImportTemplateHedaerColumns(importedColumnList.map((col) => {
                return {
                    headerName: col.headerName,
                    displayName: '',
                    headerIndex: col.headerIndex,
                    // index: null,
                    // id: null,
                    validations: {
                        requiredDataType: null,
                        templateDataType: null,
                        minLength: null,
                        maxLength: null,
                        mandatory: null,
                        dataFormat: null,
                        minOccurrence: null,
                        maxOccurrence: null,
                        repeatation: null,
                        transit: null
                    }
                }
            }))
        }
    }, [mode, importedColumnList])

    let mappedColumn = processColumnList.filter((col) => { return col.headerName !== null })
    let sameHeaderMappedColumn = mappedColumn.filter((nnn, index) => {
        return mappedColumn.find((x, ind) => x.headerName === nnn.headerName && index !== ind)
    });

    const totalHeaderMappedCol = importedColumnListForEdit.map((cols) => cols.displayName).filter((col) => {
        return col !== ''
    }).flatMap(column => column).map((columns) => columns.headerName)

    const multipleColMapped = importedColumnListForEdit.map((cols) => cols.displayName).filter((col) => {
        return col !== ''
    }).flatMap(column => column)

    const multipleColMappedHeaderNameList = sameHeaderMappedColumn.filter((column) => {
        return !multipleColMapped.map((col) => col.displayName).includes(column.displayName)
    }).map((columns) => columns.headerName)

    useEffect(() => {
        if (mode === 'edit' || mode === 'read') {
            const multipleColMappedWithIndexSplit = sameHeaderMappedColumn.reduce(function (r, a) {
                r[a.index] = r[a.index] || [];
                r[a.index].push(a);
                return r;
            }, Object.create(null));

            if (multipleColMappedHeaderNameList.every(element => { return totalHeaderMappedCol.includes(element) })) {
                const importedColumnListNotMappedCol = importedColumnListForEdit[importedColumnListForEdit.findIndex((col) => {
                    return multipleColMappedHeaderNameList.map((col) => col).includes(col.headerName)
                })]

                if (importedColumnListNotMappedCol !== undefined && importedColumnListNotMappedCol.displayName.length === 1) {
                    importedColumnListNotMappedCol.displayName = multipleColMappedWithIndexSplit[importedColumnListForEdit.findIndex((col) => {
                        return multipleColMappedHeaderNameList.map((col) => col).includes(col.headerName)
                    })]
                }
            }
        }
    }, [multipleColMappedHeaderNameList, totalHeaderMappedCol])


    useEffect(() => {
        if (mode === 'edit' || mode === 'read') {
            let mappedColumn = processColumnList.filter((col) => { return col.headerName !== null })
            let indexOfImport = importedColumnList.map((col, index) => index);
            mappedColumn.forEach((items) => {

                let findIndex = indexOfImport.indexOf(items.index);
                let sameHeaderMappedColumn = mappedColumn.filter((nnn, index) => {
                    return mappedColumn.find((x, ind) => x.headerName === nnn.headerName && index !== ind)
                });
                let indexOfProCol = processColumnList.findIndex((item) => item.index === findIndex && item.displayName === items.displayName)

                const testing = sameHeaderMappedColumn.reduce(function (r, a) {
                    r[a.index] = r[a.index] || [];
                    r[a.index].push(a);
                    return r;
                }, Object.create(null));

                const duplicateColumn = Object.keys(testing)

                if (duplicateColumn.length > 0) {
                    duplicateColumn !== undefined && duplicateColumn.forEach((col) => {
                        let indexOfMulMappedCol = importedColumnList.findIndex((_, index) => index === parseInt(col));
                        setImportedColumnListForEdit(importedColumnList.map((_, index) => {
                            importedColumnList[findIndex].displayName = processColumnList[indexOfProCol];
                            return {
                                headerName: importedColumnList[index].headerName,
                                headerIndex: importedColumnList[index].headerIndex,
                                displayName: index === indexOfMulMappedCol ? testing[`${indexOfMulMappedCol}`] : [importedColumnList[index].displayName],
                            }
                        }))
                    })
                } else {
                    setImportedColumnListForEdit(importedColumnList.map((_, index) => {
                        importedColumnList[findIndex].displayName = processColumnList[indexOfProCol];
                        return {
                            headerName: importedColumnList[index].headerName,
                            headerIndex: importedColumnList[index].headerIndex,
                            displayName: [importedColumnList[index].displayName],
                        }
                    }))
                }
            })
        }
    }, [mode, processColumnList, importedColumnList])

    useEffect(() => {
        if (selectedFileName !== '') {
            if (importedColumnList.length === 0) {
                setOpenBackDrop(true)
            } else if (importedColumnList.length > 0) {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: "File Imported Successfully", severity: "success" });
                setOpenBackDrop(false)

            }
        }
    }, [importedColumnList, selectedFileName])

    useEffect(() => {
        if (processNameValue !== '') {
            CommonService.GetListApi(`/importTemplateProcessMasters/${importTmplMasterId}`, {
                body: {

                }
            })
                .then((response) => {
                    setImportTemplateMasterData(response.data)
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                });
        }
    }, [processNameValue])

    useEffect(() => {
        if (mode === 'create') {
            if (importTemplateMasterData.importTemplateConfiguration !== undefined && importedColumnList.length > 0) {
                setProcessColumnList(importTemplateMasterData.importTemplateConfiguration.mappingSource.processColumns.map((item, index) => {
                    return {
                        headerName: item.headerName,
                        displayName: item.displayName,
                        index: item.index,
                        id: item.id,
                        columGroup: item.columGroup,
                        validations: {
                            requiredDataType: item.validations.requiredDataType,
                            templateDataType: item.validations.templateDataType,
                            minLength: item.validations.minLength,
                            maxLength: item.validations.maxLength,
                            mandatory: item.validations.mandatory,
                            dataFormat: item.validations.dataFormat,
                            minOccurrence: item.validations.minOccurrence,
                            maxOccurrence: item.validations.maxOccurrence,
                            repeatation: item.validations.repeatation,
                            group: item.validations.group,
                            transit: item.validations.transit
                        }
                    }
                }))
                setUniqueCombinationsObject(importTemplateMasterData.importTemplateConfiguration.uniqueCombinations.combinations.map((item) => {
                    return {
                        id: item.id,
                        sourceColumns: item.sourceColumns
                    }
                }))
                setDestinationColumnsObject(importTemplateMasterData.importTemplateConfiguration.destinationColumns.destTable)
                // setDestinationColumnsObject({
                //     schema: importTemplateMasterData.importTemplateConfiguration.destinationColumns.destTable.schema,
                //     name: importTemplateMasterData.importTemplateConfiguration.destinationColumns.destTable.name,
                //     primaryKey: importTemplateMasterData.importTemplateConfiguration.destinationColumns.destTable.primaryKey,
                //     type: importTemplateMasterData.importTemplateConfiguration.destinationColumns.destTable.type,
                //     sourceColumns: importTemplateMasterData.importTemplateConfiguration.destinationColumns.destTable.sourceColumns,
                //     destinationColumns: importTemplateMasterData.importTemplateConfiguration.destinationColumns.destTable.destinationColumns
                // }
                // )
                setPipeLineObject(importTemplateMasterData.importTemplateConfiguration.pipeLine.steps.map((item) => {
                    return {
                        id: item.id,
                        type: item.type,
                        procedureName: item.procedureName
                    }
                }))
                setRepeatObject({
                    count: importTemplateMasterData.importTemplateConfiguration.repeat.group.count,
                    repeatedColumns: importTemplateMasterData.importTemplateConfiguration.repeat.group.repeatedColumns,
                })
                setErrorCodesObject({
                    errors: importTemplateMasterData.importTemplateConfiguration.errorCodes.errors,
                })
            }
        }
    }, [importTemplateMasterData, importedColumnList, mode])

    const { handleSubmit, formState: { errors }, reset, control } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: {
            importTmplName: null,
            fileType: null,
            bpType: null,
            importTmplMasterId: null,
            organization: null,
            entity: null,
            active: null,
            bpCode: null,
            delimiter: null,
            importTemplateProcessMaster: null,
            startIndex: null,
            fileName: null
        },
    });

    useEffect(() => {
        if (importTmplId > 0 && mode !== 'create') {
            CommonService.getByIdApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.importTemplate, importTmplId)
                .then((response) => {
                    setTemplateToBeEdited(response.data);
                    importTemplateResettingValues(response.data);
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    setOpenBackDrop(false);
                });
        }
    }, [mode, importTmplId]);

    function importTemplateResettingValues(responseData) {
        reset({
            organization: responseData.organization !== undefined ? { erpOrgCode: responseData.organization.erpOrgCode, orgName: responseData.organization.orgName } : defaultOrgObj,
            entity: responseData.entity !== undefined ? { erpEntityCode: responseData.entity.erpEntityCode, entityName: responseData.entity.entityName } : null,
            importTmplName: responseData.importTmplName !== undefined ? responseData.importTmplName : '',
            active: responseData.active !== undefined ? responseData.active : true,
            bpType: responseData.bpType !== undefined ? { id: 1, value: responseData.bpType } : '',
            bpCode: responseData.bpCode !== undefined ? { bpCode: responseData.bpCode } : '',
            fileType: responseData.fileType !== undefined ? { value: responseData.fileType } : '',
            delimiter: responseData.delimiter !== undefined ? { value: responseData.delimiter } : '',
            importTemplateProcessMaster: responseData.importTemplateProcessMaster !== undefined ? { process: responseData.importTemplateProcessMaster.process } : '',
            startIndex: responseData.startIndex !== undefined ? responseData.startIndex : '0',
            fileName: responseData.fileName !== undefined ? responseData.fileName : '',
            headerColumns: responseData.headerColumns !== undefined ? responseData.headerColumns : []
        });
        setOrgValue(responseData.organization !== undefined ? { valueToBeDisplayed: responseData.organization } : defaultOrgObj);
        setBranchValue(responseData.entity !== undefined ? { valuesToBeDisplayed: responseData.entity } : []);
        setBpTypeValue(responseData.bpType !== undefined ? { id: 1, value: responseData.bpType } : []);
        setBpCodeValue(responseData.bpCode !== undefined ? {
            valueToBeDisplayed: `${responseData.bpCode}-${responseData.bpName}`,
        } : []);
        setFileTypeValue(responseData.fileType !== undefined ? { value: responseData.fileType } : [])
        setDelimiterValue(responseData.delimiter !== undefined ? { value: responseData.delimiter } : [])
        setProcessNameValue(responseData.importTemplateProcessMaster !== undefined ? { process: responseData.importTemplateProcessMaster.process, importTmplMasterId: responseData.importTemplateProcessMaster.importTmplMasterId } : '');
        setDataStartIndexValue(responseData.startIndex !== undefined ? responseData.startIndex : '0');
        setSelectedFileName(responseData.fileName !== undefined ? responseData.fileName : '');
        setProcessColumnList(responseData.importTemplateConfiguration !== undefined ? responseData.importTemplateConfiguration.mappingSource.processColumns : []);
        setUniqueCombinationsObject(responseData.importTemplateConfiguration !== undefined ? responseData.importTemplateConfiguration.uniqueCombinations.combinations : []);
        setDestinationColumnsObject(responseData.importTemplateConfiguration !== undefined ? responseData.importTemplateConfiguration.destinationColumns.destTable : [])
        setPipeLineObject(responseData.importTemplateConfiguration !== undefined ? responseData.importTemplateConfiguration.pipeLine.steps : []);
        setImportedColumnList(responseData.headerColumns !== undefined ? responseData.headerColumns : []);
        setRepeatObject(responseData.importTemplateConfiguration !== undefined ? responseData.importTemplateConfiguration.repeat.group : []);
        setErrorCodesObject(responseData.importTemplateConfiguration !== undefined ? responseData.importTemplateConfiguration.errorCodes : [])
    }

    useEffect(() => {
        if (mode === 'create') {
            importTemplateResettingValues({});
        }
    }, [defaultOrgObj])

    useEffect(() => {
        setOpenBackDrop(true);
        let currentRouterComponent;
        if (mode === 'create' && importTmplId === 0) {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            isRestoreButtonDisabled(false);
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                iconName: CreateIcon,
            }
            setOpenBackDrop(false);
        } else if (mode === 'edit') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isRestoreButtonDisabled(false);
            setUserReadOnlyFields({ ...userReadOnlyFields, name: true, subject: false, organization: true, entity: true, msgContent: false });
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                iconName: EditIcon,
            }
            importTemplateResettingValues(templateToBeEdited);
        } else if (mode === 'read') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isDisabledForReadMode(true);
            isRestoreButtonDisabled(false);
            issaveOrUpdateButtonDisabled(true);
            isRestoreButtonDisabled(true);
            setUserReadOnlyFields({ ...userReadOnlyFields, name: true, subject: true, organization: true, entity: true, msgContent: true })
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
                iconName: ViewIcon,
            }
            importTemplateResettingValues(templateToBeEdited);
        }
        setComponentList([
            {
                path: '/importTemplates',
                name: <FormattedMessage id="importtemplate.menu.text" />,
                iconName: IconImportTemplate,
            },
            currentRouterComponent
        ])
    }, [mode]);

    function resetAllClearThePageInfo() {
        if (mode === 'edit') {
            importTemplateResettingValues(templateToBeEdited);
        } else {
            reset({
                entity: null,
                active: true,
                organization: null,
                msgContent: '',
                subject: '',
                name: ''
            });
            setOrgValue([]);
            setBranchValue([]);
            setDefaultOrgObj({ ...defaultOrgObj, valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}` });
            setTemplateToBeEdited({ ...templateToBeEdited, active: true });
        }
    }

    const branchComboProperty = {
        name: pageLabelsConstantsAndMessages.name.entity,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.entityCode.label" }),
        isAutofocus: false,
        isDisabled: mode === 'create' ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.entity
    }

    const fileTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.fileType,
        isRequired: true,
        label: intl.formatMessage({ id: "columnname.fileType.label" }),
        isAutofocus: false,
        isDisabled: mode === 'create' ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.fileType
    }

    const processNameComboProperty = {
        name: pageLabelsConstantsAndMessages.name.importTemplateProcessMaster,
        isRequired: true,
        label: intl.formatMessage({ id: "columnname.process.label" }),
        isAutofocus: false,
        isDisabled: mode === 'create' ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.importTemplateProcessMaster
    }

    const bpTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.bpType,
        isRequired: true,
        label: intl.formatMessage({ id: 'textfield.label.bptype' }),
        isAutofocus: false,
        isDisabled: mode === 'create' ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.bpType
    }

    const bpCodeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.bpCode,
        isRequired: true,
        label: intl.formatMessage({ id: 'common.textfield.bpcode' }),
        isAutofocus: false,
        isDisabled: mode === 'create' ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.bpCode
    }

    const delimiterComboProperty = {
        name: pageLabelsConstantsAndMessages.name.delimiter,
        isRequired: false,
        label: intl.formatMessage({ id: "columnname.seperatortype.text" }),
        isAutofocus: false,
        isDisabled: mode === 'create' && (fileTypeValue.value === 'EXCEL_2003' || fileTypeValue.value === 'EXCEL') ? true :
            mode === 'create' && (fileTypeValue.value === 'CSV' || fileTypeValue.value === 'TXT') ? false : true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.delimiter
    }

    const resetComboValueForBpType = () => {
        setBpCodeValue([]);
    }

    const resetComboValueForFileType = () => {
        setDelimiterValue({});
        setDataStartIndexValue('0');
        setSelectedFile('');
        setSelectedFileName('');
        setImportedColumnList([]);
        setImportedFileObjectListForStartIndexZero([]);
    }

    const resetComboValueForDataStartIndex = () => {
        setDelimiterValue({});
        setSelectedFile('');
        setSelectedFileName('');
        setImportedColumnList([]);
        setImportedFileObjectListForStartIndexZero([]);
    }

    const resetComboValueForDelimter = () => {
        setSelectedFile('');
        setSelectedFileName('');
        setImportedColumnList([]);
        setImportedFileObjectListForStartIndexZero([]);
    }

    const resetComboValueForSelectedFile = () => {
        setImportedColumnList([]);
        setImportedFileObjectListForStartIndexZero([]);
    }

    const [selectedColumnName, setSelectedColumnName] = useState([]);
    const [selectedDateFormatterValue, setSelectedDateFormatterValue] = useState(null);
    const [selectedTransitCellValue, setSelectedTransitValue] = useState(null);
    const [headerName, setHeaderName] = useState(null);
    const [selectedHeaderNameForDateFormat, setSelectedHeaderNameForDateFormat] = useState('');
    const [selectedHeaderNameForTransit, setSelectedHeaderNameForTransit] = useState('');

    useEffect(() => {
        if (processColumnList !== undefined) {
            let object = processColumnList.filter((col) => {
                return col.headerName === selectedHeaderNameForDateFormat
            })
            if (selectedHeaderNameForDateFormat !== '' && object.length > 0) {
                setHeaderName(object[0].headerName)
            }
        }
    }, [selectedHeaderNameForDateFormat])

    useEffect(() => {
        if (processColumnList !== undefined) {
            let object = processColumnList.filter((col) => {
                return col.headerName === selectedHeaderNameForTransit
            })
            if (selectedHeaderNameForTransit !== '' && object.length > 0) {
                setHeaderName(object[0].headerName)
            }
        }
    }, [selectedHeaderNameForTransit])

    function selectedColumnIndex() {
        let indexOfSelectedColumnName = []
        if (typeof selectedColumnName === 'string') {

            if (selectedHeaderNameForDateFormat !== '') {
                if (selectedDateFormatterValue !== '') {
                    const splitColumnName = Object.values(selectedColumnName.split(',')).map((col) => {
                        return {
                            displayName: col
                        }
                    });
                    if (splitColumnName.length > 1) {
                        processColumnList.map((column) => {
                            splitColumnName.forEach((columns) => {
                                if (columns.displayName === column.displayName) {
                                    indexOfSelectedColumnName = processColumnList.findIndex((column) => {
                                        return column.displayName === columns.displayName
                                    });
                                }
                            })
                        })
                    } else {
                        indexOfSelectedColumnName = processColumnList.findIndex((column) => {
                            return column.displayName === selectedColumnName
                        })
                    }
                }
            }
            if (selectedHeaderNameForTransit !== '') {
                if (selectedDateFormatterValue !== '') {
                    indexOfSelectedColumnName = processColumnList.findIndex((column) => {
                        return column.displayName === selectedColumnName
                    })
                }
            }
        } else if (typeof selectedColumnName !== 'string' || selectedColumnName !== '') {
            if (selectedDateFormatterValue !== '') {
                selectedColumnName.forEach((columns) => {
                    indexOfSelectedColumnName = processColumnList.findIndex((columnItem) => {
                        return columnItem.displayName === columns.displayName
                    })
                })
                if (selectedColumnName.map((col) => col.displayName).includes('None')) {
                    processColumnList.map((column) => {
                        selectedColumnName.forEach((columns) => {
                            if (columns.displayName !== 'None') {
                                indexOfSelectedColumnName = processColumnList.indexOf(columns);
                            }
                        })
                    })
                }
            }
        }
        return indexOfSelectedColumnName;
    }

    const selectedHeaderNameIndex = headerName !== null && importedColumnList.findIndex((column) => {
        if (selectedHeaderNameForDateFormat !== '') {
            return column.headerName === selectedHeaderNameForDateFormat
        } else if (selectedHeaderNameForTransit !== '') {
            return column.headerName === selectedHeaderNameForTransit
        } else {
            return column.headerName === headerName
        }
    })

    const changeKeyObjects = (arr, replaceKeys) => {
        return arr.map(item => {
            const newItem = {};
            Object.keys(item).forEach(key => {
                newItem[replaceKeys[key]] = item[[key]];
            });
            return newItem;
        });
    };

    useEffect(() => {
        setMappedColumnValue({
            headerName: importedColumnList[selectedHeaderNameIndex] !== undefined ? importedColumnList[selectedHeaderNameIndex].headerName : null,
            displayName: processColumnList[selectedColumnIndex()] !== undefined ? processColumnList[selectedColumnIndex()].displayName : null,
            index: selectedHeaderNameIndex !== undefined ? selectedHeaderNameIndex : null,
            id: processColumnList[selectedColumnIndex()] !== undefined ? processColumnList[selectedColumnIndex()].id : null,
            columGroup: processColumnList[selectedColumnIndex()] !== undefined ? processColumnList[selectedColumnIndex()].columGroup : null,
            validations: {
                requiredDataType: processColumnList[selectedColumnIndex()] !== undefined ? processColumnList[selectedColumnIndex()].validations.requiredDataType : null,
                templateDataType: processColumnList[selectedColumnIndex()] !== undefined ? processColumnList[selectedColumnIndex()].validations.templateDataType : null,
                minLength: processColumnList[selectedColumnIndex()] !== undefined ? processColumnList[selectedColumnIndex()].validations.minLength : null,
                maxLength: processColumnList[selectedColumnIndex()] !== undefined ? processColumnList[selectedColumnIndex()].validations.maxLength : null,
                mandatory: processColumnList[selectedColumnIndex()] !== undefined ? processColumnList[selectedColumnIndex()].validations.mandatory : null,
                dataFormat: processColumnList[selectedColumnIndex()] !== undefined ?
                    processColumnList[selectedColumnIndex()].validations.templateDataType === 'DATE' ?
                        selectedDateFormatterValue === null || selectedDateFormatterValue === '' ? processColumnList[selectedColumnIndex()].validations.dataFormat : processColumnList[selectedColumnIndex()].validations.dataFormat = typeof selectedDateFormatterValue === 'string' ? selectedDateFormatterValue.split(',').map((item) => { return { value: item } }) : selectedDateFormatterValue : null : null,
                minOccurrence: processColumnList[selectedColumnIndex()] !== undefined ? processColumnList[selectedColumnIndex()].validations.minOccurrence : null,
                maxOccurrence: processColumnList[selectedColumnIndex()] !== undefined ? processColumnList[selectedColumnIndex()].validations.maxOccurrence : null,
                repeatation: processColumnList[selectedColumnIndex()] !== undefined ? processColumnList[selectedColumnIndex()].validations.repeatation : null,
                group: processColumnList[selectedColumnIndex()] !== undefined ? processColumnList[selectedColumnIndex()].validations.group : null,
                transit: processColumnList[selectedColumnIndex()] !== undefined ?
                    processColumnList[selectedColumnIndex()].validations.templateDataType === 'DATE' ?
                        selectedTransitCellValue === null || selectedTransitCellValue === '' ? processColumnList[selectedColumnIndex()].validations.transit : processColumnList[selectedColumnIndex()].validations.transit = selectedTransitCellValue : null : null
            }
        })
    }, [selectedColumnName, selectedDateFormatterValue, selectedTransitCellValue]);

    useEffect(() => {
        if (processColumnList !== undefined) {
            processColumnList.forEach((existingCol) => {
                let replaced = false;
                if (selectedColumnName !== '' && typeof selectedColumnName !== 'string' && selectedColumnName.map((col) => col.displayName).includes('None')) {
                    let selectedColumnFilter = selectedColumnName.filter((col) => {
                        return col.displayName !== 'None'
                    })
                    selectedColumnFilter.forEach((columns) => {
                        if (columns.displayName !== null) {
                            let indexToReplaceHeaderNameWithNull = processColumnList.findIndex((col) => col.displayName === columns.displayName);
                            if (indexToReplaceHeaderNameWithNull !== -1) {
                                processColumnList[indexToReplaceHeaderNameWithNull].headerName = null;
                            }
                        }
                        replaced = true;
                    })
                } else if ((selectedDateFormatterValue !== null) && typeof selectedDateFormatterValue !== 'string' && (selectedDateFormatterValue.map((col) => col.value).includes(null) || selectedDateFormatterValue.map((col) => col.value).includes('None'))) {
                    if (existingCol.headerName === selectedHeaderNameForDateFormat) {
                        let indexToReplaceDateFormatWithNull = processColumnList.findIndex((col) => col.displayName === selectedColumnName);
                        if (indexToReplaceDateFormatWithNull !== -1) {
                            processColumnList[indexToReplaceDateFormatWithNull].validations.dataFormat = null;
                            replaced = true;
                        }
                    }
                } else {
                    if (existingCol.headerName === selectedHeaderNameForDateFormat || existingCol.headerName === selectedHeaderNameForTransit) {
                        if (existingCol.displayName !== mappedColumnValue.displayName) {
                            let indexToMakeNull = processColumnList.indexOf(existingCol);
                            if (mappedColumnValue.validations.templateDataType !== 'DATE') {
                                processColumnList[indexToMakeNull].validations.dataFormat = null;
                            }
                            if (mappedColumnValue.validations.templateDataType !== 'DATE') {
                                processColumnList[indexToMakeNull].validations.transit = 0;
                            }
                            replaced = true
                        }
                        let indexToUpdateMulDateMappedCol = processColumnList.indexOf(existingCol);
                        if (mappedColumnValue.validations.templateDataType === 'DATE') {
                            processColumnList[indexToUpdateMulDateMappedCol].validations.dataFormat = typeof selectedDateFormatterValue !== 'string' ? selectedDateFormatterValue : selectedDateFormatterValue.split(',').map((item) => {
                                return { value: item }
                            });
                        }
                        if (mappedColumnValue.validations.templateDataType === 'DATE') {
                            processColumnList[indexToUpdateMulDateMappedCol].validations.transit = selectedTransitCellValue;
                        }
                    }
                    if (existingCol.displayName === mappedColumnValue.displayName) {
                        let index = processColumnList.indexOf(existingCol)
                        processColumnList[index] = mappedColumnValue;
                        replaced = true;

                    }
                }
                if (replaced === true && typeof selectedColumnName !== 'string' && selectedColumnName.map((col) => col.displayName).includes('None')) {
                    const filterNoneColumn = selectedColumnName.filter((col) => {
                        return col.displayName === 'None'
                    })
                    filterNoneColumn.forEach((columns) => {
                        let indexToMakeDisplayNameNullInSelectedColumn = selectedColumnName.findIndex((col) => col.displayName === columns.displayName);
                        selectedColumnName[indexToMakeDisplayNameNullInSelectedColumn].displayName = null;
                    })
                }
                if (replaced === true && mappedColumnValue.validations.dataFormat !== null && mappedColumnValue.validations.dataFormat.map((item) => item.value).includes('None')) {
                    mappedColumnValue.validations.dataFormat = null
                }
                if (replaced === true && selectedDateFormatterValue !== null && typeof selectedDateFormatterValue !== 'string' && selectedDateFormatterValue.map((col) => col.value).includes('None')) {
                    const filterNoneColumn = selectedDateFormatterValue.filter((col) => {
                        return col.value === 'None'
                    })
                    filterNoneColumn.forEach((columns) => {
                        let indexToMakeDateFormatNullInSeletedDate = selectedDateFormatterValue.findIndex((col) => col.value === columns.value);
                        selectedDateFormatterValue[indexToMakeDateFormatNullInSeletedDate].value = null;
                    })
                }
            })
        }
    }, [mappedColumnValue, selectedDateFormatterValue, selectedHeaderNameForDateFormat])

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    function SelectedEditColumnNameCell(props) {
        const { id, value, field, row } = props;
        const apiRef = useGridApiContext();
        const handleChange = async (event, values) => {
            await apiRef.current.setEditCellValue({
                id, field, value: row.displayName !== '' ? typeof row.displayName === 'string' ? processColumnList.filter((columns) => {
                    return Object.values(row.displayName.split(',')).map((col) => {
                        return {
                            displayName: col
                        }
                    }).map((cols) => cols.displayName).includes(columns.displayName)
                }).concat(values) :
                    row.displayName.map((col) => col).toString() === '' ? values :
                        row.displayName.map((col) => col.displayName).includes(null) === true ? values :
                            row.displayName.concat(values) : values
            });
            apiRef.current.stopCellEditMode({ id, field });
        };

        setSelectedColumnName(typeof value === 'string' ? row.displayName : value);
        setHeaderName(id);
        setSelectedHeaderNameForDateFormat('')
        setSelectedHeaderNameForTransit('');

        let notMappedColumns = processColumnList.filter((item) => {
            return item.headerName === null
        })

        let columnColumn = {
            headerName: null,
            displayName: 'None',
            index: null,
            id: null,
            validations: {
                requiredDataType: null,
                templateDataType: null,
                minLength: null,
                maxLength: null,
                mandatory: null,
                dataFormat: null,
                minOccurrence: null,
                maxOccurrence: null,
                repeatation: null,
                group: null,
                transit: null
            }
        }

        let finalNotmappedColumns = [columnColumn].concat(notMappedColumns);

        return (
            <FormControl fullWidth>
                <Autocomplete
                    multiple
                    disableClearable={true}
                    size='small'
                    id="selectMinute"
                    options={finalNotmappedColumns}
                    filterSelectedOptions
                    onChange={handleChange}
                    getOptionLabel={(option) => option.displayName}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    )}
                    disabled={disabledForReadMode}
                />
            </FormControl>
        );
    }


    SelectedEditColumnNameCell.propTypes = {
        field: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        value: PropTypes.any,
        row: PropTypes.any,
    };

    function SelectedEditDateFormatterCell(props) {
        const { id, value, field, row } = props;
        const apiRef = useGridApiContext();

        const listOfValue = typeof value === 'string' ? value.split(',').map((item) => {
            return { value: item }
        }) : value;

        const handleChange = async (event, values) => {
            const key = 'value'
            await apiRef.current.setEditCellValue({
                id, field, value:
                    listOfValue !== null ? [...new Map(listOfValue.concat(values).filter((item) => item.value !== '').map(item => [item[key], item])).values()] : null
            });
            apiRef.current.stopCellEditMode({ id, field });
        };
        setSelectedColumnName(typeof row.displayName === 'string' ? row.displayName : row.displayName.map((col) => col.displayName).toString());

        setSelectedDateFormatterValue(value);
        setSelectedHeaderNameForDateFormat(id);
        setHeaderName('');
        setSelectedHeaderNameForTransit('');

        let dateFormatterDate = { value: 'None' }

        const finalDateFormatterList = [dateFormatterDate].concat(dateFormatValue)

        return (
            <FormControl fullWidth>
                <Autocomplete
                    multiple
                    disableClearable={true}
                    size='small'
                    id="selectMinute"
                    options={finalDateFormatterList}
                    onChange={handleChange}
                    getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    )}
                    disabled={disabledForReadMode}
                />
            </FormControl>
        );
    }

    SelectedEditDateFormatterCell.propTypes = {
        field: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        value: PropTypes.any,
        row: PropTypes.any,
    };

    function SelectedEdiTransitCell(props) {
        const { id, value, field, row } = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event, values) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
            apiRef.current.stopCellEditMode({ id, field });
        };
        setSelectedColumnName(typeof row.displayName === 'string' ? row.displayName : row.displayName.map((col) => col.displayName).toString())
        setSelectedTransitValue(value);
        setSelectedHeaderNameForTransit(id);
        setSelectedHeaderNameForDateFormat('');
        setHeaderName('');

        return (
            <FormControl fullWidth>
                <TextField
                    type="number"
                    variant="outlined"
                    value={value}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </FormControl>
        );
    }

    SelectedEdiTransitCell.propTypes = {
        field: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        value: PropTypes.any,
        row: PropTypes.any,
    };

    const renderSelectEditColumnNameCell = (params) => {
        return <SelectedEditColumnNameCell {...params} />;
    };

    const renderSelectEditDateFormatterCell = (params) => {
        return <SelectedEditDateFormatterCell {...params} />;
    };

    const renderSelectEditTransitCell = (params) => {
        return <SelectedEdiTransitCell {...params} />;
    };

    const mappingColumns = [
        {
            field: 'headerName',
            headerName: intl.formatMessage({ id: 'columnname.partnerfilehdrcolumn.txt' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 260,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: 'headerIndex',
            headerName: intl.formatMessage({ id: 'columnname.partnerfilehdrcolumnIndex.txt' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 260,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: 'displayName',
            headerName: intl.formatMessage({ id: 'columnname.gedicolumn.txt' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 300,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            editable: mode === 'read' ? false : true,
            valueGetter: (params) => `${(params.value === '') ? '' : typeof params.value === 'string' ? params.value : params.value !== undefined && params.value.map((col) => col.displayName).includes(null) === true ? '' : params.value.map((col) => col.displayName).toString()}`,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderEditCell: renderSelectEditColumnNameCell
        },
        {
            field: 'validations.minLength',
            headerName: intl.formatMessage({ id: 'columnname.gedimincolumnsize.txt' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 200,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            valueGetter: (params) => `${params.row.displayName === '' ? '' : typeof params.row.displayName === 'string' ? params.row.displayName.split(',').length === 1 ? processColumnList[processColumnList.findIndex((column) => {
                return column.displayName === params.row.displayName
            })].validations.minLength : processColumnList.filter((columns) => {
                return Object.values(params.row.displayName.split(',')).map((col) => {
                    return {
                        displayName: col
                    }
                }).map((cols) => cols.displayName).includes(columns.displayName)
            }).map((col) => col.validations.minLength).toString()
                : params.row.displayName.map((col) => col).toString() === "" ? '' : params.row.displayName !== undefined && params.row.displayName.map((col) => col.displayName).includes(null) === true ? '' : params.row.displayName.map((col) => col.validations.minLength).toString()}`
        },
        {
            field: 'validations.maxLength',
            headerName: intl.formatMessage({ id: 'columnname.gedimaxcolumnsize.txt' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter} `,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight} `,
            width: 200,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            valueGetter: (params) => `${params.row.displayName === '' ? '' : typeof params.row.displayName === 'string' ? params.row.displayName.split(',').length === 1 ? processColumnList[processColumnList.findIndex((column) => {
                return column.displayName === params.row.displayName
            })].validations.minLength : processColumnList.filter((columns) => {
                return Object.values(params.row.displayName.split(',')).map((col) => {
                    return {
                        displayName: col
                    }
                }).map((cols) => cols.displayName).includes(columns.displayName)
            }).map((col) => col.validations.maxLength).toString() : params.row.displayName.map((col) => col).toString() === "" ? '' : params.row.displayName !== undefined && params.row.displayName.map((col) => col.displayName).includes(null) === true ? '' : params.row.displayName.map((col) => col.validations.maxLength).toString()
                } `
        },
        {
            field: 'validations.mandatory',
            headerName: intl.formatMessage({ id: 'columnname.mandatoryfield.txt' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter} `,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight} `,
            width: 160,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            valueGetter: (params) => `${params.row.displayName === '' ? '' : typeof params.row.displayName === 'string' ? params.row.displayName.split(',').length === 1 ? processColumnList[processColumnList.findIndex((column) => {
                return column.displayName === params.row.displayName
            })].validations.mandatory : processColumnList.filter((columns) => {
                return Object.values(params.row.displayName.split(',')).map((col) => {
                    return {
                        displayName: col
                    }
                }).map((cols) => cols.displayName).includes(columns.displayName)
            }).map((col) => col.validations.mandatory).toString() : params.row.displayName.map((col) => col).toString() === "" ? '' : params.row.displayName !== undefined && params.row.displayName.map((col) => col.displayName).includes(null) === true ? '' : params.row.displayName.map((col) => col.validations.mandatory).toString()
                } `
        },
        {
            field: 'validations.dataFormat',
            headerName: intl.formatMessage({ id: 'columnname.dateformat.txt' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter} `,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight} `,
            width: 240,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            editable: mode === 'read' ? false : true,
            renderHeader: CommonService.customRenderHeaderColumns,
            valueGetter: (params) => `${params.value === '' || params.value === undefined ? params.row.displayName === '' ? '' : typeof params.row.displayName === 'string' ? params.row.displayName.split(',').length === 1 ? processColumnList[processColumnList.findIndex((column) => {
                return column.displayName === params.row.displayName
            })].validations.dataFormat !== null ? processColumnList[processColumnList.findIndex((column) => {
                return column.displayName === params.row.displayName
            })].validations.dataFormat.map((col) => col.value) : '' : processColumnList.filter((columns) => {
                return Object.values(params.row.displayName.split(',')).map((col) => {
                    return {
                        displayName: col
                    }
                }).map((cols) => cols.displayName).includes(columns.displayName)
            }).map((col) => col.validations.dataFormat !== null ? col.validations.dataFormat.map((col) => col.value) : '').toString() :
                params.row.displayName.map((col) => col).toString() === "" ? '' : params.row.displayName !== undefined && params.row.displayName.map((col) => col.displayName).includes(null) === true ? '' : params.row.displayName.map((col) => col.validations.dataFormat !== null ? col.validations.dataFormat.map((col) => col.value) : '') :
                typeof params.value === 'string' ? params.value :
                    params.value !== undefined && params.value.map((col) => col.value).includes(null) === true ? '' : params.value.map((col) => col.value).toString()}`,
            renderEditCell: renderSelectEditDateFormatterCell
        },
        {
            field: 'validations.transit',
            headerName: intl.formatMessage({ id: 'columnname.dlvytransitdays.txt' }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter} `,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight} `,
            width: 160,
            sortable: false,
            type: 'number',
            hide: false,
            filterable: false,
            editable: mode === 'read' ? false : true,
            renderHeader: CommonService.customRenderHeaderColumns,
            valueGetter: (params) => `${params.value === '' || params.value === undefined ? params.row.displayName === '' ? '' :
                typeof params.row.displayName === 'string' ? params.row.displayName.split(',').length === 1 ?
                    processColumnList[processColumnList.findIndex((column) => {
                        return column.displayName === params.row.displayName
                    })].validations.transit :
                    processColumnList.filter((columns) => {
                        return Object.values(params.row.displayName.split(',')).map((col) => {
                            return {
                                displayName: col
                            }
                        }).map((cols) => cols.displayName).includes(columns.displayName)
                    }).map((col) => col.validations.transit).toString() :
                    params.row.displayName.map((col) => col).toString() === "" ? '' : params.row.displayName !== undefined && params.row.displayName.map((col) => col.displayName).includes(null) === true ? '' : params.row.displayName.map((col) => col.validations.transit) : params.value
                } `,
            renderEditCell: renderSelectEditTransitCell
        },
    ]

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        setColumns(mappingColumns)
    }, [selectedFileName, processColumnList])

    let mappedColumnHeaderCol = processColumnList.filter((col) => {
        return col.headerName !== null
    })

    let dataFormatIsMapped = mappedColumnHeaderCol.filter((col) => {
        if (col.validations !== undefined) {
            return col.validations.templateDataType === 'DATE' && (col.validations.dataFormat === undefined || col.validations.dataFormat === null || col.validations.dataFormat === '')
        }
    }).map((item) => item.displayName).toString();

    function repeatationCountIncrementation() {
        let repeatationCount = 1;
        let repeatedColumnsObject = repeatObject.repeatedColumns
        let listOfRepeatedMappedObject = processColumnList.filter((col) => {
            if (repeatedColumnsObject !== undefined) {
                return repeatedColumnsObject.map((item) => item.sourceId).includes(col.id) && col.headerName !== null
            }
        })

        let totalMappedRepeatatedCol = listOfRepeatedMappedObject.reduce(function (r, a) {
            r[a.id] = r[a.id] || [];
            r[a.id].push(a);
            return r;
        }, Object.create(null));

        let replaceKeysForDlvyCfm = { 8: "subGroup1", 9: "subGroup2" };
        const newMappedColWithNewArray = changeKeyObjects([totalMappedRepeatatedCol], replaceKeysForDlvyCfm);

        const lengthOfDlvyCfmSubGroup1 = newMappedColWithNewArray[0].subGroup1 !== undefined ? newMappedColWithNewArray[0].subGroup1.length : [];
        const lengthOfDlvyCfmSubGroup2 = newMappedColWithNewArray[0].subGroup2 !== undefined ? newMappedColWithNewArray[0].subGroup2.length : [];

        return repeatationCount = lengthOfDlvyCfmSubGroup1 === lengthOfDlvyCfmSubGroup2 ? lengthOfDlvyCfmSubGroup1 : 1;
    }

    function increamentGroupCount() {
        let groupCount = 0;
        const filterColumnGroup = processColumnList.filter((col) => {
            return col.headerName !== null && col.columGroup !== ''
        }).map((col) => { return { columGroup: col.columGroup } })

        const test1 = filterColumnGroup.reduce(function (r, a) {
            r[a.columGroup] = r[a.columGroup] || [];
            r[a.columGroup].push(a);
            return r;
        }, Object.create(null));

        const test2 = { a1: "grp1", a2: "grp2", a3: "grp3", a4: "grp4", a5: "grp5" };
        const test3 = changeKeyObjects([test1], test2);
        processColumnList.forEach((existingCol) => {
            if (existingCol.columGroup === 'a1') {
                if (test3[0].grp1 !== undefined && test3[0].grp1.length === 2) {
                    return groupCount = existingCol.validations.group = 1;
                } else {
                    return groupCount = existingCol.validations.group = 0;
                }
            } else if (existingCol.columGroup === 'a2') {
                if (test3[0].grp2 !== undefined && test3[0].grp2.length === 2) {
                    return groupCount = existingCol.validations.group = 2;
                } else {
                    return groupCount = existingCol.validations.group = 0;
                }
            } else if (existingCol.columGroup === 'a3') {
                if (test3[0].grp3 !== undefined && test3[0].grp3.length === 2) {
                    return groupCount = existingCol.validations.group = 3;
                } else {
                    return groupCount = existingCol.validations.group = 0;
                }
            } else if (existingCol.columGroup === 'a4') {
                if (test3[0].grp4 !== undefined && test3[0].grp4.length === 2) {
                    return groupCount = existingCol.validations.group = 4;
                } else {
                    return groupCount = existingCol.validations.group = 0;
                }
            } else if (existingCol.columGroup === 'a5') {
                if (test3[0].grp5 !== undefined && test3[0].grp5.length === 2) {
                    return groupCount = existingCol.validations.group = 5;
                } else {
                    return groupCount = existingCol.validations.group = 0;
                }
            }
        })
    }

    const uniqueCombinationValidation = importTemplateMasterData.importTemplateConfiguration !== undefined && importTemplateMasterData.importTemplateConfiguration.uniqueCombinations.required ? importTemplateMasterData.importTemplateConfiguration.uniqueCombinations.combinations : false;
   


    const uniqueCombinationMapped = () => {
        if (!uniqueCombinationValidation) {
            let uniqueCombination = [];
            let lengthOfUniqueCombinations = uniqueCombinationValidation.length;
            let i = 0;
            let mandatoryColumnMapped = processColumnList.filter((col) => {
                return col.headerName !== null
            }).map((item) => item.displayName);

            for (i = 0; i < lengthOfUniqueCombinations; i++) {
                if (uniqueCombinationValidation[i].sourceColumns.map((col) => col.name).every(ai => mandatoryColumnMapped.includes(ai))) {
                    uniqueCombination = [uniqueCombinationValidation[i]];
                }
            }

            return uniqueCombination;
        }
    }

    const uniqueCombinationMappedColumns = () => {
        if (uniqueCombinationValidation != undefined) {
            let uniqueCombinationMap = [];
            let lengthOfUniqueCombinations = uniqueCombinationValidation.length;
            let i = 0;
            for (i = 0; i < lengthOfUniqueCombinations; i++) {
                let col = [uniqueCombinationValidation[i].sourceColumns.map((col) => col.name).toString()]
                uniqueCombinationMap = [uniqueCombinationValidation[i].sourceColumns.map((col) => col.name).toString()].concat(col).join(' OR ');
            }
            return uniqueCombinationMap;
        }
    }

    const mandatoryProcessColumns = () => {
        let mandatoryColumnMap = ''
        mandatoryColumnMap = processColumnList.filter((col) => {
            if (col.validations !== undefined) {
                return col.validations.mandatory === 'Yes' && col.headerName === null
            }
        }).map((item) => item.displayName).toString();

        return mandatoryColumnMap;
    }


    useEffect(() => {
        setImportTemplateConfiguration({
            mappingSource: {
                processColumns: processColumnList
            },
            uniqueCombinations: { combinations: uniqueCombinationMapped() },
            destinationColumns: { destTable: destinationColumnsObject },
            pipeLine: { steps: pipeLineObject },
            repeat: {
                group: {
                    count: repeatationCountIncrementation(),
                    repeatedColumns: importTemplateMasterData.importTemplateConfiguration !== undefined ? importTemplateMasterData.importTemplateConfiguration.repeat.group.repeatedColumns : {},
                }
            },
            errorCodes: errorCodesObject
        })
    }, [processColumnList, uniqueCombinationsObject, destinationColumnsObject, pipeLineObject, importTemplateMasterData]);

    const scheduleReqDtmappedTwice = () => {
        let mappedRecord = '';
        mappedRecord = processColumnList.filter((item) => {
            return item.displayName.includes('SCHEDULE REQ DT')
        }).filter((col) => {
            return col.headerName !== null
        }).map((cols) => cols.displayName).toString();
        return mappedRecord;
    }

    const onSubmit = data => {
        if (mandatoryProcessColumns() !== '') {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: `Mandatory Column ${mandatoryProcessColumns()} is not mapped`, severity: "error" });
        } else if (uniqueCombinationMapped().length === 0 && importTemplateMasterData.importTemplateConfiguration.uniqueCombinations.isRequired === true) {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: `Unique Combination ${uniqueCombinationMappedColumns()} is not mapped`, severity: "error" });
        } else if (importTemplateMasterData.process === 'PO DLV CFM' && scheduleReqDtmappedTwice().split(',').length > 1) {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: `${scheduleReqDtmappedTwice()} Kindly any column among these 2`, severity: "error" });
        } else if (dataFormatIsMapped !== '') {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: `${dataFormatIsMapped} column date format is not mapped`, severity: "error" });
        } else {
            data['importTmplId'] = importTmplId;
            data['importTemplateConfiguration'] = {
                ...importTemplateConfiguration, repeat: {
                    group: {
                        count: repeatationCountIncrementation(),
                        repeatedColumns: importTemplateMasterData.importTemplateConfiguration !== undefined ? importTemplateMasterData.importTemplateConfiguration.repeat.group.repeatedColumns : {},
                    },
                },
                mappingSource: {
                    processColumns: Object.values({
                        ...processColumnList, validations: increamentGroupCount() === undefined ? undefined : {
                            group: increamentGroupCount() === undefined ? 0 : increamentGroupCount()
                        },
                    })
                },
                uniqueCombinations: {
                    required:importTemplateMasterData.importTemplateConfiguration.uniqueCombinations.required,
                    combinations: uniqueCombinationMapped(),
                }
            };

            data['createdBy'] = (mode === 'create') ? currentUser.userName : templateToBeEdited.createdBy;
            data['createdTime'] = (templateToBeEdited.createdTime === undefined) ? '' : templateToBeEdited.createdTime;
            data['updatedBy'] = currentUser.userName;
            data['updatedTime'] = (templateToBeEdited.updatedTime === undefined) ? '' : templateToBeEdited.updatedTime;
            data['organization'] = (templateToBeEdited.organization === undefined) ? { erpOrgCode: orgInfoOfLeggedUser.erpOrgCode } : templateToBeEdited.organization;
            data['entity'] = data.entity.erpEntityCode
            data['bpType'] = data.bpType.value;
            data['bpCode'] = data.bpCode.bpCode;
            data['fileType'] = (templateToBeEdited.fileType === undefined) ? fileTypeValue.value : templateToBeEdited.fileType;;
            data['delimiter'] = (templateToBeEdited.delimiter === undefined) ? delimiterValue.value === undefined ? '' : delimiterValue.value : templateToBeEdited.delimiter;
            data['active'] = (templateToBeEdited.active === undefined) ? true : templateToBeEdited.active;
            data['importTemplateProcessMaster'] = { importTmplMasterId: importTemplateMasterData.importTmplMasterId };
            data['startIndex'] = dataStartIndexValue;
            data['fileName'] = selectedFileName;
            data['processId'] = importTemplateMasterData.processMaster.processId;
            data['headerColumns'] = (templateToBeEdited.headerColumns === undefined) ? importedColumnList : importTemplateHedaerColumns;

            const formData = new FormData();
            formData.append('importedFile', selectedFile);
            formData.append('importTemplateParam',  JSON.stringify(data));

            setOpenBackDrop(true)
            CommonService.uploadFileForDataImport(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.importTemplate, formData)
                .then((response) => {
                    setOpenBackDrop(false);
                    history.goBack();
                    localStorage.setItem(data.importTmplName, JSON.stringify(importedColumnList));
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" />, severity: "success" });
                })
                .catch(function (error) {
                    setOpenBackDrop(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                });
        }
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForCreateOrEditRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={8} md={8} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' alignItems='center' xs={6} sm={4} md={4} lg={6} className={classes.topGrid}>
                    <Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2}
                        endIcon={smallScreen ? null : <IconBack />} onClick={history.goBack}
                    > {smallScreen ? <IconBack /> : <FormattedMessage id="common.button.back.lable" defaultMessage="Back" />} </Button>
                    <Button size='small' disabled={disableRestoreButton}
                        variant="contained" color="secondary" className={classes.stateButtonToRestore} elevation={2}
                        endIcon={smallScreen ? null : <IconRestore />} onClick={(() => { resetAllClearThePageInfo() })}
                    > {smallScreen ? <IconRestore /> : <FormattedMessage id="common.button.reset.lable" defaultMessage="Reset" />} </Button>

                    {(importTemplateModuleAccess !== undefined ? importTemplateModuleAccess.createFlag : false) && (<Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                        disabled={disableSaveOrUpdateButton}
                        endIcon={smallScreen ? null : <IconSaveOrUpdate />} onClick={handleSubmit(onSubmit)}
                    > {smallScreen ? <IconSaveOrUpdate /> : saveOrUpdateButtonLabel} </Button>)}
                </Grid>
                <form className={classes.paperCreateOrEditRole} onSubmit={handleSubmit(onSubmit)}>
                    <Paper className={classes.formForCreateOrEditUser} elevation={3}>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} style={{ marginTop: 0 }}>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <EntityCodeCombo comboProperty={branchComboProperty} control={control} errors={errors} comboValue={branchValue} setComboValue={setBranchValue} />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={2}>
                                <BpTypeCombo comboProperty={bpTypeComboProperty} control={control} errors={errors} comboValue={bpTypeValue} setComboValue={setBpTypeValue}
                                    resetComboValueForBpType={resetComboValueForBpType}
                                />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={4}>
                                <BpCodeCombo comboProperty={bpCodeComboProperty} control={control} errors={errors}
                                    comboValue={bpCodeValue}
                                    bpTypeValue={bpTypeValue}
                                    setComboValue={setBpCodeValue}
                                    branchValue={branchValue} />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <ImportTemplateProcessMasterCombo
                                    comboProperty={processNameComboProperty}
                                    control={control}
                                    errors={errors}
                                    comboValue={processNameValue}
                                    setComboValue={setProcessNameValue}
                                />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.importTmplName}
                                        rules={{
                                            required: true,
                                            maxLength: 100,
                                            pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UHF
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                required
                                                label={intl.formatMessage({ id: "columnname.templatename.text" })}
                                                error={errors.importTmplName}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                disabled={mode === 'create' ? false : true}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.importTmplName}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="columnname.templatename.text" />
                                                <FormattedMessage id="textfield.error.isrequirededmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.importTmplName}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="columnname.templatename.text" />{" "}
                                                <FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.importTmplName}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="columnname.templatename.text" />{" "}
                                                {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UHF}
                                            </FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={2}>
                                <FileTypeForCustomReports
                                    comboProperty={fileTypeComboProperty}
                                    control={control} errors={errors}
                                    comboValue={fileTypeValue}
                                    setComboValue={setFileTypeValue}
                                    processMasterValue={''}
                                    resetComboValueForFileType={resetComboValueForFileType}
                                />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={1}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.startIndex}
                                        rules={{
                                            required: false,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                required
                                                type="number"
                                                label={intl.formatMessage({ id: "columnname.datastartindex.text" })}
                                                error={errors.startIndex}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                value={dataStartIndexValue}
                                                onChange={(ev) => {
                                                    const { value } = ev.target;
                                                    setDataStartIndexValue(value);
                                                    resetComboValueForDataStartIndex();
                                                }}
                                                InputProps={{
                                                    classes: { notchedOutline: classes.specialOutline },
                                                    inputProps: { min: "0", max: "20", step: "1" }
                                                }}
                                                disabled={mode === 'create' ? false : true}
                                                defaultValue={'0'}

                                            />
                                        )}
                                        defaultValue={'0'}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={1}>
                                <DelimitersCombo
                                    comboProperty={delimiterComboProperty}
                                    control={control} errors={errors}
                                    comboValue={delimiterValue}
                                    setComboValue={setDelimiterValue}
                                    resetComboValueForDelimter={resetComboValueForDelimter}
                                />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={selectedFileName}
                                        name={pageLabelsConstantsAndMessages.name.fileName}
                                        label={intl.formatMessage({ id: "textfield.selectfile.label" })}
                                        InputLabelProps={{
                                            shrink: true,
                                            classes: {
                                                asterisk: classes.asterisk
                                            }
                                        }}
                                        disabled={mode === 'create' ? false : true}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <div>
                                                        <label htmlFor="contained-button-file">
                                                            <IconButton size='small'
                                                                color="primary" component="span"
                                                                disabled={dataStartIndexValue === '' ? true : mode !== 'create' ? true : false}
                                                            ><AttachFileIcon /></IconButton>
                                                        </label>
                                                        <input
                                                            accept={fileTypeValue.value === 'EXCEL_2003' ? ".xls" : fileTypeValue.value === 'EXCEL' ? '.xlsx' : fileTypeValue.value === 'TXT' ? '.txt' : fileTypeValue.value === 'CSV' ? ".csv" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                                                            className={classes.input}
                                                            id="contained-button-file"
                                                            type="file"
                                                            onChange={((event) => {
                                                                event.preventDefault();
                                                                resetComboValueForSelectedFile();
                                                                setOpenBackDrop(true);
                                                                if (event.target.files[0] !== undefined) {
                                                                    setSelectedFileName(event.target.files[0].name);
                                                                    setSelectedFile(event.target.files[0]);
                                                                    const reader = new FileReader();
                                                                    reader.onload = (event) => {
                                                                        const data = event.target.result;
                                                                        const workbook = XLSX.read(data, { type: "_", raw: true });
                                                                        const sheetName = workbook.SheetNames[0];
                                                                        const workBookSheetNoReplaceWithEmpty = Object.keys(workbook.Sheets[sheetName]).toString().replace(/[0-9]/g, '')

                                                                        setWorkSheet(Object.keys(workbook.Sheets[sheetName]).toString().replace(/[0-9]/g, ''))

                                                                        const worksheet = workbook.Sheets[sheetName];
                                                                        setImportedFileObjectList(XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]));

                                                                        const workSheetkeyObject = workBookSheetNoReplaceWithEmpty !== '' ? workBookSheetNoReplaceWithEmpty.split(',').map((col) => {
                                                                            return {
                                                                                index: col
                                                                            }
                                                                        }) : []

                                                                        const key = "index"

                                                                        const test2 = Object.values([worksheet][0]).slice(0, [...new Map(Object.keys([worksheet][0]).toString().replace(/[0-9]/g, '').split(',').map((col) => {
                                                                            return {
                                                                                index: col
                                                                            }
                                                                        }).map(item => [item[key], item])).values()].filter((item) => {
                                                                            return !item.index.includes('!')
                                                                        }).length).map((col) => col.v)


                                                                        const uniqueHeaderIndexData = [...new Map(workSheetkeyObject.map(item => [item[key], item])).values()];

                                                                        const workbookStrings = workbook.Strings !== undefined ? workbook.Strings.slice(0, uniqueHeaderIndexData.filter((col) => {
                                                                            return !col.index.includes('!')
                                                                        }).length) : []

                                                                        const test = workbookStrings.map((col) => col.t);

                                                                        setImportedFileObjectListForStartIndexZero(test.length !== 0 ? test : test2)
                                                                        setOpenBackDrop(false);

                                                                    };
                                                                    setOpenBackDrop(false);
                                                                    reader.readAsArrayBuffer(event.target.files[0]);
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={12} md={2} lg={2}>
                                <Grid container justifyContent='flex-start' alignItems='center'>
                                    <Controller
                                        render={({
                                            field: { onChange } }) => (<Switch
                                                color='primary'
                                                checked={templateToBeEdited.active !== undefined ? templateToBeEdited.active : true}
                                                onChange={((event) => {
                                                    onChange(!(templateToBeEdited.active !== undefined ? templateToBeEdited.active : true));
                                                    setTemplateToBeEdited({ ...templateToBeEdited, active: event.target.checked })
                                                })}
                                                disabled={mode === 'read' ? disabledForReadMode : false}
                                            />)}
                                        type="checkbox"
                                        name={pageLabelsConstantsAndMessages.name.active}
                                        control={control}
                                        defaultValue={templateToBeEdited.active !== undefined ? templateToBeEdited.active : true}
                                    />
                                    <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={3} className={classes.gridHeightAndWidthForImportTemplate}
                        style={{ marginTop: '0.3%' }}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <DataGridPro
                                rows={mode === 'edit' || mode === 'read' ? importedColumnListForEdit : importedColumnList}
                                columns={columns}
                                className={classes.customTableStyle}
                                getRowId={(row) =>
                                    row.headerName
                                }
                                density="compact"
                                editMode="cell"
                                hideFooter={true}
                                experimentalFeatures={{ newEditingApi: true }}
                                disableSelectionOnClick={false}
                                onRowEditStart={handleRowEditStart}
                                onRowEditStop={handleRowEditStop}
                            />
                        </div>
                    </Paper>
                </form>
            </Grid >
        </React.Fragment >
    );

}
export default CreateOrEditImportTemplate;