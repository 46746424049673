import React, { useLayoutEffect } from 'react'
import clsx from 'clsx'
import { useTheme } from '@mui/material/styles'
import List from '@mui/material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import Popover from '@mui/material/Popover'
import Collapse from '@mui/material/Collapse'
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import IconSpacer from '@mui/icons-material/FiberManualRecord'
import NavItemComponent from './NavItemComponent'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import useStyles from '../../CommonStyle/CommonStyle'
import { FormattedMessage } from 'react-intl'

const NavItemCollapsed = props => {
  const {
    name,
    link,
    Icon,
    IconStyles = {},
    IconClassName = '',
    isCollapsed,
    className,
    items = [],
  } = props
  const classes = useStyles()
  const hasChildren = items && items.length > 0
  const location = useLocation();
  const itemsAll = getItemsAll(items)
  const hasChildrenAndIsActive =
    hasChildren &&
    itemsAll.filter(item => location.pathname.includes(`${item.link}`)).length > 0

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = event => {
    if (!hasChildren) {
      return false
    }

    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'sidebar-nav-item-popper' : undefined

  const ListItemIconInner =
    (!!Icon && <Icon />) ||
    (isCollapsed && <IconSpacer className={classes.iconSpacer} />) ||
    ''
  // ICONS AND TOOL TIP FOR CLOSED DRAWER(MINI-ICON MENU)
  const ListItemRoot = (
    <Tooltip
      disableFocusListener={true}
      disableHoverListener={false}
      disableTouchListener={true}
      title={<FormattedMessage id={name} />}
      placement="right"
    >
      <NavItemComponent
        link={link}
        className={clsx(
          classes.navItem,
          classes.navItemCollapsed,
          hasChildrenAndIsActive && 'active',
          open && 'open',
          className,
        )}
        isCollapsed={true}
        aria-describedby={id}
        onClick={handlePopoverOpen}
      >
        {!!ListItemIconInner && (
          <ListItemIcon
            style={IconStyles}
            className={classes.navItemIcon}
          >
            {ListItemIconInner}
          </ListItemIcon>
        )}
        <ListItemText
          primary={<FormattedMessage id={name} />}
          style={{ visibility: 'hidden' }}
          disableTypography={true}
        />
        {hasChildren && (
          <IconExpandLess
            className={clsx(classes.iconToggle, !open && classes.iconToggleInactive)}
          />
        )}
      </NavItemComponent>
    </Tooltip>
  )

  //SUB-MENU LIST ON CLICK POP OVER FOR CLOSED DRAWER(MINI-ICON MENU)
  const ListItemChildren = hasChildren ? (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      classes={{
        paper: classes.navItemPoper,
      }}
    >
      <div className={clsx(classes.navItemChildren)}>
        <List component="div" disablePadding>
          {items.map(item => (
            <NavItem
              {...item}
              isNested={true}
              nestingLevel={0}
              isCollapsed={false}
              key={item.name || item.link}
              isOpen={open}
              onClick={!item.items || !item.items.length ? handlePopoverClose : undefined}
            />
          ))}
        </List>
      </div>
    </Popover>
  ) : null

  return (
    <div
      className={clsx(
        hasChildrenAndIsActive && classes.navItemWrapperActive,
        hasChildrenAndIsActive && isCollapsed && classes.navItemWrapperActiveCollapsed,
      )}
    >
      {ListItemRoot}
      {ListItemChildren}
    </div>
  )
}


//FOR OPENED DRAWER
const NavItemDefault = props => {
  let theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    name,
    link,
    Icon,
    IconStyles = {},
    IconClassName = '',
    isCollapsed,
    nestingLevel = 0,
    nestingOffset = 16,
    className,
    items = [],
    onClick = () => { },
    setIsSidebarCollapsed
  } = props
  const classes = useStyles()
  const hasChildren = items && items.length > 0
  const location = useLocation();
  const itemsAll = getItemsAll(items)
  const hasChildrenAndIsActive =
    hasChildren &&
    itemsAll.filter(item => location.pathname.includes(`${item.link}`)).length > 0
  const isOpen = hasChildrenAndIsActive || false
  const [open, setOpen] = React.useState(isOpen)

  // This is a work around for fixing the collapsed item poper overflow bug
  useLayoutEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'))
  })

  function handleClick() {
    if (!hasChildren && !smallScreen) {
      setIsSidebarCollapsed(!isCollapsed);
    }
    setOpen(!open)
  }

  const ListItemIconInner =
    (!!Icon && <Icon />) ||
    (isCollapsed && <IconSpacer className={classes.iconSpacer} />) ||
    ''
  const nestingOffsetChildren = !isCollapsed ? nestingOffset + 16 : 16

  //Menu Icon, Name and collapsible symbols for open drawer
  const ListItemRoot = (
    <NavItemComponent
      link={link}
      className={clsx(
        classes.navItem,
        isCollapsed && classes.navItemCollapsed,
        hasChildrenAndIsActive && 'active',
        className,
      )}
      style={{
        fontSize: `${1 - 0.07 * nestingLevel}em`,
        paddingLeft: `${!ListItemIconInner ? nestingOffset + 40 : nestingOffset}px`,
      }}
      isCollapsed={isCollapsed}
      onClick={handleClick}
      id={"root_menu_"+name}
    >
      {!!ListItemIconInner && (
        <ListItemIcon
          style={IconStyles}
          className={clsx(classes.navItemIcon, IconClassName)}
        >
          {ListItemIconInner}
        </ListItemIcon>
      )}
      <ListItemText primary={<FormattedMessage id={name} defaultMessage="" />} disableTypography={true} style={{ fontSize: '14px' }} />
      {hasChildren && !open && <IconExpandMore className={classes.iconToggle} />}
      {hasChildren && open && <IconExpandLess className={classes.iconToggle} />}
    </NavItemComponent>
  )

  const ListItemChildren = hasChildren ? (
    <div className={clsx(classes.navItemChildren)}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {/* <Divider /> */}
        <List component="div" disablePadding>
          {items.map(item => (
            <NavItem
              {...item}
              isNested={true}
              nestingLevel={nestingLevel + 1}
              isCollapsed={isCollapsed}
              key={item.name || item.link}
              isOpen={open}
              nestingOffset={nestingOffsetChildren}
              id={"child_menu_"+item.name}
            />
          ))}
        </List>
      </Collapse>
    </div>
  ) : null

  return (
    <div
      className={clsx(
        hasChildrenAndIsActive && classes.navItemWrapperActive,
        hasChildrenAndIsActive && isCollapsed && classes.navItemWrapperActiveCollapsed,
      )}
      onClick={onClick}
    >
      {ListItemRoot}
      {ListItemChildren}
    </div>
  )
}

const NavItem = props => {
  if (props.isCollapsed) {
    return <NavItemCollapsed {...props} />
  } else {
    return <NavItemDefault {...props} />
  }
}

// ----------------------
// Flattened array of all children
function getItemsAll(items) {
  return items.reduce((allItems, item) => {
    // let res = allItems.concat([item])
    if (item.items && item.items.length) {
      return allItems.concat([item], getItemsAll(item.items))
    } else {
      return allItems.concat([item])
    }
  }, [])
}

export default NavItem;