import React, { useEffect, useState } from 'react';
import _ from "lodash/fp";
import { FormControl, FormHelperText, TextField, Grid, CssBaseline } from '@mui/material';
import { Controller, } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useStyles from '../../../common/CommonStyle/CommonStyle'

function AccountPolicyForm(props) {
    const classes = useStyles();
    const { accountmode, errors, control, setValue, setHasErrors } = props

    const [organizationReadOnlyFields, setOrganizationReadOnlyFields] = useState({
        passwordExpiryDays: false,
        passwordExpiryNotifyDays: false,
        maxLoginFailCount: false,
    });

    const pageLabelsConstantsAndMessages = {
        label: {
            passwordExpiryDays: 'Password Expiry Days',
            passwordExpiryNotifyDays: 'Password Expiry Noify Days',
            maxLoginFailCount: 'Max Login Fail Count',
        },
        name: {
            passwordExpiryDays: 'passwordExpiryDays',
            passwordExpiryNotifyDays: 'passwordExpiryNotifyDays',
            maxLoginFailCount: 'maxLoginFailCount'
        }
    }

    useEffect(() => {
        if (accountmode === 'view') {
            setOrganizationReadOnlyFields({ ...organizationReadOnlyFields, passwordExpiryDays: true, passwordExpiryNotifyDays: true, maxLoginFailCount: true, })
        } else if (accountmode === 'create') {
            setValue('passwordExpiryDays', 180)
            setValue('passwordExpiryNotifyDays', 15)
            setValue('maxLoginFailCount', 3)
        }
    }, [accountmode]);


    useEffect(() => {
        setHasErrors(errors)
    }, [errors])

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.passwordExpiryDays}
                                    rules={accountmode === 'view' ? { required: false } : {
                                        required: true,
                                        pattern: /^[0-9\b]+$/
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required
                                            variant="outlined"
                                            margin="normal"
                                            id="passwordExpiryDays"
                                            size='small'
                                            type='number'
                                            label={<FormattedMessage id="columnname.PasswordExpiryDays.label" defaultMessage="Password Expiry Days" />}
                                            {...field}
                                            error={errors.passwordExpiryDays}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }
                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.passwordExpiryDays,
                                                classes: { notchedOutline: classes.specialOutline },
                                                inputProps: { min: "0", max: "10", step: "1" }
                                            }}
                                            disabled={accountmode === 'view' ? true : false}
                                        />
                                    )}
                                />

                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.passwordExpiryDays}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.PasswordExpiryDays.label" defaultMessage="Password Expiry Days" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.passwordExpiryDays}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.PasswordExpiryDays.label" defaultMessage="Password Expiry Days" />}  {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.passwordExpiryDays}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.PasswordExpiryDays.label" defaultMessage="Password Expiry Days" />}  {<FormattedMessage id="common.errorForNumericOnly.message" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.passwordExpiryNotifyDays}
                                    rules={accountmode === 'view' ? { required: false } : {
                                        required: true,
                                        pattern: /^[0-9\b]+$/
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required
                                            variant="outlined"
                                            margin="normal"
                                            id="passwordExpiryNotifyDays"
                                            size='small'
                                            type='number'
                                            label={<FormattedMessage id="columnname.PasswordExpiryNoifyDays.label" defaultMessage="Password Expiry NoifyDays" />}
                                            {...field}
                                            error={errors.passwordExpiryNotifyDays}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.passwordExpiryNotifyDays,
                                                classes: { notchedOutline: classes.specialOutline },
                                                inputProps: { min: "0", max: "10", step: "1" }
                                            }}
                                            disabled={accountmode === 'view' ? true : false}
                                        />
                                    )}
                                />

                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.passwordExpiryNotifyDays}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.PasswordExpiryNoifyDays.label" defaultMessage="Password Expiry NoifyDays" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.passwordExpiryNotifyDays}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.PasswordExpiryNoifyDays.label" defaultMessage="Password Expiry NoifyDays" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.passwordExpiryNotifyDays}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.PasswordExpiryNoifyDays.label" defaultMessage="Password Expiry NoifyDays" />} {<FormattedMessage id="common.errorForNumericOnly.message" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>


                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.maxLoginFailCount}
                                    rules={accountmode === 'view' ? { required: false } : {
                                        required: true,
                                        pattern: /^[0-9\b]+$/
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            required
                                            variant="outlined"
                                            margin="normal"
                                            id="maxLoginFailCount"
                                            size='small'
                                            type='number'
                                            label={<FormattedMessage id="columnname.MaxLoginFailCount.label" defaultMessage="Max Login FailCount" />}
                                            {...field}
                                            error={errors.maxLoginFailCount}
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                readOnly: organizationReadOnlyFields.maxLoginFailCount,
                                                classes: { notchedOutline: classes.specialOutline },
                                                inputProps: { min: "0", max: "10", step: "1" }
                                            }}
                                            disabled={accountmode === 'view' ? true : false}
                                        />
                                    )}
                                />

                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.maxLoginFailCount}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.MaxLoginFailCount.label" defaultMessage="Max Login FailCount" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.maxLoginFailCount}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.MaxLoginFailCount.label" defaultMessage="Max Login FailCount" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.maxLoginFailCount}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.MaxLoginFailCount.label" defaultMessage="Max Login FailCount" />} {<FormattedMessage id="common.errorForNumericOnly.message" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>

    );
}

export default AccountPolicyForm;