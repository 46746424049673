import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Copyright from '../Copyright';
import useStyles from '../CommonStyle/CommonStyle'

export default function StickyFooter() {
  const classes = useStyles();
  const COPY_RIGHT_COMPANY_NAME = `${process.env.REACT_APP_COPY_RIGHT_COMPANY_NAME}`;
  const COPY_RIGHT_COMPANY_URL = `${process.env.REACT_APP_COPY_RIGHT_COMPANY_URL}`;

  return (
    <div className={classes.rootFooter}>
      <CssBaseline />
      <footer className={classes.footer}>
        <Container maxWidth="lg">
          <Copyright companyName={COPY_RIGHT_COMPANY_NAME} companyURL={COPY_RIGHT_COMPANY_URL} />
        </Container>
      </footer>
    </div>
  );
}
