import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button, CircularProgress, CssBaseline, FormControl, FormHelperText, Grid,
  IconButton, InputAdornment, Paper, Snackbar, TextField, Typography, Tooltip
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import _ from "lodash/fp";
import { useHistory } from 'react-router-dom';
import { CommonService } from '../../../services';
import { Alert } from '@mui/lab';
import SiixLogo from '../AppSideBar/siix_logo.png';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '90vh'
  },
  companyLogo: {
    height: 100,
    width: 170,
    [theme.breakpoints.only('sm')]: {
      marginTop: '5%'
    },
  },
  paperStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(5)
  },
  paper: {
    height: 'auto',
    width: '100%'
  },
  Password: {
    fontWeight: 'bold',
    alignItems: 'center',
    fontSize: '21px'

  },
  TextField: {
    margin: theme.spacing(3, 2, 2),
  },
  form: {
    width: '100%',
    padding: '12px 10px 12px 10px'

  },

  iconButton: {
    color: 'black'
  },
  error: {
    color: theme.palette.error.main,
  },
  buttonSpacing: {
    margin: theme.spacing(0.5),
  },
  buttonSpacingSubmit: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.customPrimary.secondary
  },

}));

function ChangeOrForgotPassword(props) {
  const classes = useStyles();
  const history = useHistory();
  const commonPatterns = GlobalEdiApiConstants();

  const { renderScreenFor, emailId, loginId } = props.location.state;

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isResponseArrived, setResponseArrived] = useState(false);

  const [alertBoxState, setAlertBoxState] = React.useState({
    openAlert: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
    severity: ''
  });
  const handleCloseAlert = () => {
    setAlertBoxState({ ...alertBoxState, openAlert: false });
  };
  const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
  };

  const [oldValues, setOldValues] = React.useState({
    Password: '',
    showPassword: false,
  });
  const handleClickShowOldPassword = () => {
    setOldValues({ ...oldValues, showPassword: !oldValues.showPassword });
  };

  const [newValues, setNewValues] = React.useState({
    Password: '',
    showPassword: false,
  });
  const handleClickShowNewPassword = () => {
    setNewValues({ ...newValues, showPassword: !newValues.showPassword });
  };


  const [confirmValues, setConfirmValues] = React.useState({
    Password: '',
    showPassword: false,
  });
  const handleClickShowConfirmPassword = () => {
    setConfirmValues({ ...confirmValues, showPassword: !confirmValues.showPassword });
  };

  const { handleSubmit, formState: { errors }, control, } = useForm({
    mode: "all",
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    }
  });

  const pageLabelsNamesAndConst = {
    labels: {
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      loginId: 'Login Id',
      cancel: 'Cancel',
      submit: 'Submit',
      forgetPassword: 'Forget Password',
      changePassword: 'Change or Reset Password',
      resetPassword: 'Reset Password'
    },
    name: {
      oldPassword: 'oldPassword',
      newPassword: 'newPassword',
      confirmPassword: 'confirmPassword',
      emailId: 'emailId'

    }
  };


  const onSubmit = data => {
    setResponseArrived(true);
    if (renderScreenFor === 'changePassword' || renderScreenFor === 'forgetPassword') {
      if (data[pageLabelsNamesAndConst.name.newPassword] !== data[pageLabelsNamesAndConst.name.confirmPassword]) {
        setResponseArrived(false);
        setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="changeorforgetpassword.snackbar.txt" defaultMessage="New and Confirm Passwords didn't match...!" />, severity: "warning" });
      } else {
        data['emailId'] = emailId;
        data['loginId'] = loginId;
        CommonService.saveOrUpdateOrPost((renderScreenFor === 'forgetPassword') ? '/users/forgetPassword'
          : '/users/changePassword', data)
          .then((response) => {
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.message, severity: "success" });
            setResponseArrived(false);
            history.goBack();
          })
          .catch(function (error) {
            setResponseArrived(false);
            if (error.response !== undefined) {
              setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            }
          });
      }
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container className={classes.root}>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item container justifyContent='center' alignItems='center' xs={10} sm={6} md={6} lg={6}>
            {renderScreenFor === 'forgetPassword' &&
              <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '-10%', marginBottom: '5%' }}>
                <img src={SiixLogo} className={classes.companyLogo} alt='company_logo' />
              </Grid>}
            <Paper className={classes.paper} elevation={4} >
              <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                  <div >
                    <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                      {renderScreenFor === 'changePassword' && <Typography className={classes.Password} >{<FormattedMessage id="dialogtitle.changepasswordreset.label" defaultMessage="Change or Reset Password" />}</Typography>}
                      {renderScreenFor === 'forgetPassword' && <Typography className={classes.Password} >{<FormattedMessage id="dialogtitle.resetpassword.label" defaultMessage="Reset Password" />}</Typography>}
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12} lg={12}>
                      {(renderScreenFor === 'changePassword' || renderScreenFor === 'forgetPassword') && (<React.Fragment>
                        {renderScreenFor === 'changePassword' && (<Grid item container xs={12} sm={12} md={12} lg={12}>
                          <FormControl fullWidth>
                            <Controller
                              control={control}
                              name={pageLabelsNamesAndConst.name.oldPassword}
                              rules={{
                                required: true,
                              }}
                              render={({ field: { onChange } }) => (
                                <TextField
                                  autoFocus={renderScreenFor === 'changePassword' ? true : false}
                                  className={classes.textField}
                                  required={true}
                                  variant="outlined"
                                  margin="normal"
                                  size='small'
                                  autoComplete="new-password"
                                  label={<FormattedMessage id="textfield.oldpassword.label" defaultMessage="Old Password" />}
                                  type={oldValues.showPassword ? 'text' : 'password'}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowOldPassword}
                                          edge="end"
                                          size="large">
                                          {oldValues.showPassword ? <Visibility className={classes.iconButton} /> : <VisibilityOff className={classes.iconButton} />}
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                  error={errors.oldPassword}
                                  onChange={(event) => {
                                    onChange(event.target.value)
                                    if (event.target.value !== null) {
                                      setOldPassword(event.target.value)
                                    }
                                  }}
                                />
                              )}
                            />
                            <div className={classes.error}>
                              {_.get(`${pageLabelsNamesAndConst.name.oldPassword}.type`, errors) === "required" && (
                                <FormHelperText className={classes.error}>  {<FormattedMessage id="textfield.error.oldpasswordisrequired" defaultMessage="Old Password is required" />}</FormHelperText>
                              )}
                            </div>
                          </FormControl>
                        </Grid>)}

                        <Grid item container xs={12} sm={12} md={12} lg={12}>
                          <Tooltip placement="top-start" title={<FormattedMessage id="textfield.errorvalid.password" />}>
                            <FormControl fullWidth>
                              <Controller
                                control={control}
                                name={pageLabelsNamesAndConst.name.newPassword}
                                rules={{
                                  required: true,
                                  pattern: commonPatterns.commonTextfeildPattern.patternForPassword,
                                }}
                                render={({ field: { onChange } }) => (
                                  <TextField
                                    autoFocus={renderScreenFor === 'forgetPassword' ? true : false}
                                    className={classes.textField}
                                    required={true}
                                    variant="outlined"
                                    margin="normal"
                                    size='small'
                                    autoComplete="new-password"
                                    label={<FormattedMessage id="textfield.newpassword.label" defaultMessage="New Password" />}
                                    type={newValues.showPassword ? 'text' : 'password'}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowNewPassword}
                                            edge="end"
                                            size="large">
                                            {newValues.showPassword ? <Visibility className={classes.iconButton} /> : <VisibilityOff className={classes.iconButton} />}
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }}
                                    error={errors.newPassword}
                                    onChange={(event) => {
                                      onChange(event.target.value)
                                      if (event.target.value !== null) {
                                        setNewPassword(event.target.value)
                                      }
                                    }}
                                  />
                                )}
                              />
                              <div className={classes.error}>
                                {_.get(`${pageLabelsNamesAndConst.name.newPassword}.type`, errors) === "pattern" && (
                                  <FormHelperText className={classes.error}>{"Please enter a valid Password"}</FormHelperText>
                                )}
                                {_.get(`${pageLabelsNamesAndConst.name.newPassword}.type`, errors) === "required" && (
                                  <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.error.newpasswordisrequired" defaultMessage="New Password is required" />}</FormHelperText>
                                )}
                              </div>
                            </FormControl>
                          </Tooltip>
                        </Grid>

                        <Grid item container xs={12} sm={12} md={12} lg={12}>
                          <FormControl fullWidth>
                            <Controller
                              control={control}
                              name={pageLabelsNamesAndConst.name.confirmPassword}
                              rules={{
                                required: true,
                              }}
                              render={({ field: { onChange } }) => (
                                <TextField
                                  className={classes.textField}
                                  required={true}
                                  variant="outlined"
                                  margin="normal"
                                  size='small'
                                  label={<FormattedMessage id="textfield.confirmpassword.label" defaultMessage="Confirm Password" />}
                                  type={confirmValues.showPassword ? 'text' : 'password'}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowConfirmPassword}
                                          edge="end"
                                          size="large">
                                          {confirmValues.showPassword ? <Visibility className={classes.iconButton} /> : <VisibilityOff className={classes.iconButton} />}
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                  error={errors.confirmPassword}
                                  onChange={(event) => {
                                    onChange(event.target.value)
                                    if (event.target.value !== null) {
                                      setConfirmPassword(event.target.value)
                                    }
                                  }}
                                />
                              )}
                            />
                            <div className={classes.error}>
                              {_.get(`${pageLabelsNamesAndConst.name.confirmPassword}.type`, errors) === "required" && (
                                <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.error.confirmpassworisrequired" defaultMessage="Confirm Password is required" />} </FormHelperText>
                              )}
                              {((newPassword !== null || newPassword !== undefined) && (confirmPassword !== null || confirmPassword !== undefined) && (newPassword !== confirmPassword)) && (
                                <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.error.confirmandnewpassworderror" defaultMessage="Confirm Password and New Password are not matching" />}</FormHelperText>
                              )}
                            </div>
                          </FormControl>
                        </Grid>
                      </React.Fragment>)}

                      <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                        <Button size='small' variant='contained' color='secondary' className={classes.buttonSpacing}
                          startIcon={<ClearIcon />}
                          onClick={history.goBack}
                        >{<FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />}
                        </Button>
                        <Button
                          type="submit"
                          size='small' variant='contained' color='primary' className={classes.buttonSpacingSubmit}
                          startIcon={<SaveIcon />}
                          onClick={handleSubmit(onSubmit)}
                          disabled={isResponseArrived} endIcon={isResponseArrived ? <CircularProgress color="secondary" size={20} /> : null}
                        >{<FormattedMessage id="common.button.submit.lable" defaultMessage="Submit" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </Grid>
            </Paper>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={openAlert}
              onClose={handleCloseAlert}
              key={vertical + horizontal}
              autoHideDuration={6000}
            >
              <Alert onClose={handleCloseAlert} severity={severity}>
                {message}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </Grid>

    </React.Fragment>
  );
}

export default ChangeOrForgotPassword;


