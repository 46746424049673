import { Badge, Button, CssBaseline, Grid, Paper, Snackbar, Tooltip, Typography, useMediaQuery, useTheme, IconButton } from '@mui/material';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import useStyles from '../common/CommonStyle/CommonStyle';
import { FormattedMessage } from 'react-intl';
import { CommonService } from '../../services';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import IconEmailNotificationSubscription from '@mui/icons-material/MarkEmailRead';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import { ModuleAccessPermissionKey } from '../../Constants/ModuleAccessKey';
import { SupportedLanguageDetails } from '../common/SupportedLanguageDetails/SupportedLanguageDetails';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import Alert from '@mui/lab/Alert';
import CustomBreadCrumb from '../common/CustomBreadCrumb/CustomBreadCrumb';
import CreateIcon from '@mui/icons-material/Add';
import ChipInfoList from '../common/ChipInfoList/ChipInfoList';
import RefreshIcon from '@mui/icons-material/Refresh';
import CommonGridPagination from '../common/CommonGridPagination/CommonGridPagination';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/EditOutlined';
import { CustomFilters } from '../common/CustomFilters/CustomFilters';
import InfoIcon from '@mui/icons-material/Info';
import DialogComponentEns from './DialogComponentEns/DialogComponentEns';
import { DataGridPro, getGridStringOperators, GridToolbarColumnsButton, GridToolbarDensitySelector, LicenseInfo } from '@mui/x-data-grid-pro';
import { FilterList } from "@mui/icons-material";
import CustomFilterComponent, { GetFilterableColumns } from "../common/CustomFilterComponent";
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { manageUserPreferences } from '../../services/manageUserPreferences';

const columnFieldsName = {
    erpEntityCode: 'entity.erpEntityCode',
    erpOrgCode: 'organization.erpOrgCode',
    processId: 'processMaster.processId',
    process: 'processMaster.process',
    active: 'active',
    createdBy: 'createdBy',
    createdTime: 'createdTime',
    emailNotificationId: 'emailNotificationId',
    entityEmailTo: 'entityEmailTo',
    entityEmailCc: 'entityEmailCc',
    entityEmailBcc: 'entityEmailBcc',
    triggerEvent: 'triggerEvent',
    updatedBy: 'updatedBy',
    updatedTime: 'updatedTime',
    bpType: 'bpType',
    bpCode: 'bpCode'
}

function EmailNotificationSubscription() {
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const emailNotificationModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.emailnotificationsubscription];
    const localtext = GridTextLocalization();
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.EmailNotificationSubscription);
    const smallScreen = useMediaQuery(theme.breakpoints.down('890'));
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        },
    };
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [chipInfoList, setChipInfoList] = useState([]);
    const [openEmailNotificationSubscriptionDialog, setOpenEmailNotificationSubscriptionDialog] = useState(false);
    const [modeChanged, setModeChanged] = React.useState('');
    const [ensObjectToBeEditedOrView, setEnsObjectToBeEditedOrView] = useState([]);
    var defaultEntity = loggedInUserInfo.defaultEntity;

    const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.erpEntityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity }]
        : [];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [primaryKeyOfSelectedRow, setPrimaryKeyOfSelectedRow] = useState(0);
    const TriggerEvent_state = { 0: "New", 1: "Approval Intimation", 2: "Error" }
    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.messageconfiguration" defaultMessage="Messageconfiguration" />,
            iconName: IconMessageConfiguration,
        },
        {
            path: '/emailnotificationsubscription',
            name: <FormattedMessage id="common.menu.breadcrumb.emailnotificationsubscription" defaultMessage="emailnotificationsubscription" />,
            iconName: IconEmailNotificationSubscription,
        }
    ];
    const commonBreadCrumbToBeUsedForDialog = componentList;
    //start
    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState(preference.columnsPinned);
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }

    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);
    //end
    const columnsForEmailNotifications =
        [
            {
                field: 'action',
                headerName: intl.formatMessage({ id: "columnname.action.label" }),
                width: 130,
                sortable: false,
                hide: false,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) =>
                (
                    <div>
                        {(emailNotificationModuleAccess !== undefined ? emailNotificationModuleAccess.editFlag : false) &&
                            (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    //functionForReset(cellParams.row)
                                    setOpenEmailNotificationSubscriptionDialog(true)
                                    setEnsObjectToBeEditedOrView(cellParams.row)
                                    setModeChanged('edit')
                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar}>
                                        <EditIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}

                        {(emailNotificationModuleAccess !== undefined ? emailNotificationModuleAccess.readFlag : false) &&
                            (<Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.readButton} />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    //functionForReset(cellParams.row)
                                    setOpenEmailNotificationSubscriptionDialog(true)
                                    setModeChanged('view')
                                    setEnsObjectToBeEditedOrView(cellParams.row)

                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar}>
                                        <ViewIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                        {(emailNotificationModuleAccess !== undefined ? emailNotificationModuleAccess.deleteFlag : false) &&
                            (<Tooltip title={<FormattedMessage id="common.button.delete.tooltip" defaultMessage="Delete" />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    handleDelete(cellParams.row.id, cellParams.row.entity.erpEntityCode, cellParams.row.triggerEvent, cellParams.row.processMaster.process);
                                })}>
                                <span>
                                    <IconButton color='secondary' size="large">
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                    </div>
                )

            },
            {
                field: columnFieldsName.erpOrgCode,
                headerName: intl.formatMessage({ id: 'textfield.label.orgcode' }),
                width: 140,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                valueGetter: (params) => `${params.row.organization.erpOrgCode}`,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.erpEntityCode,
                width: 85,
                headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: false,
                type: 'string',
                filterable: true,
                hide: false,
                valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.process,
                headerName: intl.formatMessage({ id: 'columnname.process.label' }),
                width: 180,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                valueGetter: (params) => `${params.row.processMaster.process}`,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.bpType,
                headerName: intl.formatMessage({ id: 'columnname.label.bptype' }),
                width: 180,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.bpCode,
                headerName: intl.formatMessage({ id: 'common.textfield.columname.bpcode' }),
                width: 180,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.entityEmailTo,
                headerName: intl.formatMessage({ id: 'textfield.emailto.label' }),
                width: 235,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.entityEmailCc,
                width: 235,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                headerName: intl.formatMessage({ id: 'columnname.entityEmailCc.label' }),
                sortable: true,
                type: 'string',
                filterable: false,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.entityEmailBcc,
                headerName: intl.formatMessage({ id: 'columnname.entityEmailBcc.label' }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 235,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.triggerEvent,
                headerName: intl.formatMessage({ id: 'columnname.triggerEvent.label' }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 300,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.TriggerEventsForChips
            },
            {
                field: columnFieldsName.active,
                headerName: intl.formatMessage({ id: "columnname.status.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 150,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.customRenderStatusColumn
            },
            {
                field: columnFieldsName.createdBy,
                headerName: intl.formatMessage({ id: 'columnname.createdBy.label' }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 150,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.createdTime,
                headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'dateTime',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.createdDtDisp}</span>)
            },
            {
                field: columnFieldsName.updatedBy,
                headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 150,
                sortable: true,
                type: 'dateTime',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.updatedTime,
                headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'dateTime',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.lastUpdatedDtDisp}</span>)
            },
        ]

    const [columns, setColumns] = useState([]);
    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }
        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(columnsForEmailNotifications);
                savePreference("visibility", columnsForEmailNotifications);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, columnsForEmailNotifications);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let entityCode = '';
                let orgCode = '';
                let process = '';
                let triggerEvent = '';
                let bpType = '';
                let bpCode = '';
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'entity.erpEntityCode':
                            entityCode = columns
                            break;
                        case 'organization.erpOrgCode':
                            orgCode = columns
                            break;
                        case 'processMaster.process':
                            process = columns
                            break;
                        case 'triggerEvent':
                            triggerEvent = columns
                            break;
                        case 'bpType':
                            bpType = columns
                            break;
                        case 'bpCode':
                            bpCode = columns
                            break;
                    }
                })
                var filterBody = {
                    entity: {
                        erpEntityCode: entityCode.value,
                    },
                    organization: {
                        erpOrgCode: orgCode.value,
                    },
                    processMaster: {
                        process: process.value,
                    },
                    triggerEvent: triggerEvent.value === 'New' ? 0 : triggerEvent.value === 'Approval Intimation' ? 1 : triggerEvent.value === 'Error' ? 2 : '',
                    bpType: bpType.value,
                    bpCode: bpCode.value,
                    active: true,
                    recordActiveStatus: 'All',
                    recordTriggerEvent: (triggerEvent.value === undefined) ? 'All' : 'ALLL'
                };
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0)
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [selectedLanguage])

    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([])
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.EmailNotificationSubscription, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                }).catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [paramBody, refreshTable])
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const closeEmailNotificationSubscriptionDialog = (confirmed) => {
        if (confirmed) {
            setRefreshTable(!refreshTable);
        }
        setOpenEmailNotificationSubscriptionDialog(false);
        setModeChanged('');
    }


    const handleDelete = (primaryKey, plantcode, triggerEvent, process) => {
        setPrimaryKeyOfSelectedRow(primaryKey);
        setConfirmationMessage(<FormattedMessage id="common.dialog.areyousureENS.txt" values={{ triggerEvent: `${TriggerEvent_state[triggerEvent]}`, process: `${process}`, plantcode: `${plantcode}` }} />);
        setOpenConfirmationDialog(true);
    }

    const closeConfirmationDialog = (confirmed) => {
        setOpenConfirmationDialog(false);
        if (confirmed) {
            CommonService.deleteByIdApi(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.EmailNotificationSubscription}/${primaryKeyOfSelectedRow}`, {})
                .then((response) => {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="EmailNotificationSubscription.snackbar.deletemsg" defaultMessage="Record deleted successfully" />, severity: "success" });
                    setRefreshTable(!refreshTable);
                })
                .catch(function (error) {
                    let message = (error.response.data !== undefined || error.response.data !== null) ? error.response.data.message
                        : <FormattedMessage id="EmailNotificationSubscription.snackbar.error" defaultMessage="Error occurred while deleting Record!" />;
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: message, severity: "error" });
                });
        }
    }

    if (columns.length > 0) {
        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };

        const orgCodeColumn = columns.find((column) => column.field === columnFieldsName.erpOrgCode);
        const orgCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpOrgCode);

        const orgCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgCodeInputValue,
            }),
        );

        columns[orgCodeColIndex] = {
            ...orgCodeColumn,
            filterOperators: orgCodeFilterOperators,
        };

        const processColumn = columns.find((column) => column.field === columnFieldsName.process);
        const processIndex = columns.findIndex((col) => col.field === columnFieldsName.process);

        const processFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.ProcessInputValue,
            }),
        );

        columns[processIndex] = {
            ...processColumn,
            filterOperators: processFilterOperators,
        };

        const triggerEventColumn = columns.find((column) => column.field === columnFieldsName.triggerEvent);
        const triggerEventIndex = columns.findIndex((col) => col.field === columnFieldsName.triggerEvent);

        const triggerEventFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.TriggerEventInputValue,
            }),
        );

        columns[triggerEventIndex] = {
            ...triggerEventColumn,
            filterOperators: triggerEventFilterOperators,
        };

        const bpTypeColumn = columns.find((column) => column.field === columnFieldsName.bpType);
        const bpTypeIndex = columns.findIndex((col) => col.field === columnFieldsName.bpType);

        const bpTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.BpTypeInputValue,
            }),
        );

        columns[bpTypeIndex] = {
            ...bpTypeColumn,
            filterOperators: bpTypeFilterOperators,
        };
    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])
    const key = "columnField"

    const applyFilter = () => {
        let entityCode = '';
        let orgCode = '';
        let process = '';
        let triggerEvent = '';
        let bpType = '';
        let bpCode = '';
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'entity.erpEntityCode':
                    entityCode = columns
                    break;
                case 'organization.erpOrgCode':
                    orgCode = columns
                    break;
                case 'processMaster.process':
                    process = columns
                    break;
                case 'triggerEvent':
                    triggerEvent = columns
                    break;
                case 'bpType':
                    bpType = columns
                    break;
                case 'bpCode':
                    bpCode = columns
                    break;
            }
        })

        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);
        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    entity: {
                        erpEntityCode: entityCode.value,
                    },
                    organization: {
                        erpOrgCode: orgCode.value,
                    },
                    processMaster: {
                        process: process.value,
                    },
                    triggerEvent: triggerEvent.value === 'New' ? 0 : triggerEvent.value === 'Approval Intimation' ? 1 : triggerEvent.value === 'Error' ? 2 : '',
                    bpType: bpType.value,
                    bpCode: bpCode.value,
                    active: true,
                    recordActiveStatus: 'All',
                    recordTriggerEvent: (triggerEvent.value === undefined) ? 'All' : 'ALLL'
                },
            }
        });
    }

    useEffect(() => {
        if (intialParamBodyApiCall)
            setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container className={classes.rootForRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    {(emailNotificationModuleAccess !== undefined ? emailNotificationModuleAccess.createFlag : false) &&
                        (<Button size='small' variant="contained" color="primary" className={classes.createButton} elevation={2} endIcon={smallScreen ? null : <CreateIcon />} onClick={() => {
                            setOpenEmailNotificationSubscriptionDialog(true)
                            setModeChanged('create')
                            setEnsObjectToBeEditedOrView({})
                        }}>
                            {smallScreen ? <CreateIcon /> : <FormattedMessage id="common.button.Create.label" defaultMessage="Create" />}
                        </Button>)}
                </Grid>
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <ChipInfoList chipInfoList={chipInfoList} />
                <DialogComponentEns
                    open={openEmailNotificationSubscriptionDialog}
                    onClose={closeEmailNotificationSubscriptionDialog}
                    title={commonBreadCrumbToBeUsedForDialog}
                    ensObjectToBeEditedOrView={ensObjectToBeEditedOrView}
                    mode={modeChanged}
                    TriggerEvent_state={TriggerEvent_state}
                    warningMessage={<Grid container justifyContent="flex-start" alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} >
                        {(modeChanged === 'create' || modeChanged === 'edit') ?
                            <InfoIcon color="primary" /> : null}
                        {(modeChanged === 'create' || modeChanged === 'edit') ?
                            <Typography justifyContent="flex-start" style={{ fontSize: 12, marginLeft: '1%' }}><FormattedMessage id="textfield.placeholder.dialogActions" defaultMessage="All the '*' marked fields are mandatory to submit the form" />
                            </Typography> : null}
                    </Grid>}
                />
                <Paper elevation={3} className={classes.gridHeightAndWidthForRole}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            density="compact"
                            columns={columns}
                            showToolbar
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableColumnMenu={false}
                            disableMultipleColumnsSorting={true}
                            hideFooter={true}
                            localeText={localtext}

                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}

                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip"
                                                        defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'>
                                                        <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterList />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            hideFooterSelectedRowCount={true}
                            // onPageSizeChange={((pageParams) => {
                            //     setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams.pageSize, pageNo: (pageParams.page) } })
                            // })}
                            // onPageChange={((pageParams) => {
                            //     setParamBody(prevState => { return { ...prevState, pageNo: (pageParams.page) } })
                            // })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortingOrder={['desc', 'asc']}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            filterMode="server"
                        />
                    </div>
                </Paper>
                <ConfirmationDialog onClose={closeConfirmationDialog} message={confirmationMessage} open={openConfirmationDialog} ensHeader={true} />
            </Grid>
        </React.Fragment>
    )
}

export default EmailNotificationSubscription;


