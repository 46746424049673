import FakeBackendForEmailReportTemplate from "./fakeBackendEmailReportTemplate";
import FakeBackendForEmailReportTemplateMapping from "./fakeBackendEmailReportTemplateMapping";
import FakeBackendForAvailableColumnsInLeft from "./fakeBackendForAvailableColumnsInLeft";
import FakeBackendForConditionColumn from "./fakeBackendForConditionColumn";
import FakeBackendForEmailReportTemplatePo from "./fakeBackendForEmailReportTemplatePo";
import FakeBackendForPoOrdersById from "./FakeBackendForPoOrders/fakeBackendForPoOrderDtl1";
import FakeBackendForPoOrdersById2 from "./FakeBackendForPoOrders/fakeBackendForPoOrderDtl2";
import FakeBackendForPoOrdersById3 from "./FakeBackendForPoOrders/fakeBackendForPoOrderDtl3";
import FakeBackendForPoOrders from "./FakeBackendForPoOrders/fakeBackendForPoOrders";
import PoScheduler2 from "./FakeBackendForPoOrders/ScheduleGridData1/ScheduleGridDataId2";
import FakeBackendForPoScheduler from "./FakeBackendForPoOrders/ScheduleGridData1/SchedulerData";
import FakeBackendForSelectedColumns from "./fakeBackendForSelectedColumns";
import FakeBackendForSelectedConditionColumns from "./fakeBackendForSelectedConditionColumns";
import FakeBackeendForTransmissionConfigs from "./FakeBackendForTransmissionConfigs/FakeBackendForTransmissionConfigs";
import FakeBackendForReportTemplate from "./fakeBackendReportTemplate";

function fakeBackendService() {
    let emailReportTemplate = FakeBackendForEmailReportTemplate();
    let emailReportTemplateMapping = FakeBackendForEmailReportTemplateMapping();
    let reportTemplate = FakeBackendForReportTemplate();
    let selectedColumns = FakeBackendForSelectedColumns();
    let availableColumnsInLeft = FakeBackendForAvailableColumnsInLeft();
    let selectedConditionColumn = FakeBackendForSelectedConditionColumns();
    let conditionColumn = FakeBackendForConditionColumn();
    let ordersGridDataGetList = FakeBackendForPoOrders();
    let ordersGridDataById = FakeBackendForPoOrdersById();
    let orderGridDataById2 = FakeBackendForPoOrdersById2();
    let orderGirdDataById3 = FakeBackendForPoOrdersById3();
    let schGridData = FakeBackendForPoScheduler()
    let schGridDataId2 = PoScheduler2();
    let emailReportTemplatePo = FakeBackendForEmailReportTemplatePo();
    let transmissionConfigById = FakeBackeendForTransmissionConfigs();
    let realFetch = window.fetch;
    window.fetch = function (url, opts) {
        const { method } = opts;

        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call
            setTimeout(handleRoute, 500);

            function handleRoute() {
                switch (true) {
                    case url.endsWith('/emailReportTemplates') && method === 'GET':
                        return loadReportTemplate();
                    case url.endsWith('/createOrEditEmailReportTemplate') && method === 'GET':
                        return loadEmailReportTemplate();
                    case url.endsWith('/availableColumnsInLeft') && method === 'GET':
                        return loadAvailableColumnsInLeft();
                    case url.endsWith('/selectedColumns') && method === 'GET':
                        return loadSelectedColumns();
                    case url.endsWith('/selectedConditionColumn') && method === 'GET':
                        return loadSelectedConditionColumn();
                    case url.endsWith('/conditionColumn') && method === 'GET':
                        return loadConditionColumn();
                    case url.endsWith('/emailReportTemplateMapping') && method === 'GET':
                        return loadEmailReportTemplateMapping();
                    case url.endsWith('/purchaseOrders') && method === 'GET':
                        return loadPurchaseOdersDataMapping();
                    case url.endsWith('/purchaseOrdersById') && method === 'GET':
                        return loadPurchaseOderDtlsDataMapping();
                    case url.endsWith('/purchaseOrdersById2') && method === 'GET':
                        return loadPurchaseOderDtls2DataMapping();
                    case url.endsWith('/purchaseOrdersById3') && method === 'GET':
                        return loadPurchaseOderDtls3DataMapping();
                    case url.endsWith('/purchaseOderSchData') && method === 'GET':
                        return loadPurchaseOderSchDataDataMapping();
                    case url.endsWith('/purchaseOderSch2Data') && method === 'GET':
                        return loadPurchaseOderSchData2DataMapping();
                    case url.endsWith('/emailReportTemplatePo') && method === 'GET':
                        return loadEmailReportTemplatePo();
                    case url.endsWith('/transmissionConfigById') && method === 'GET':
                        return loadTransmissionConfigById();
                    default:
                        // pass through any requests not handled above
                        return realFetch(url, opts)
                            .then(response => resolve(response))
                            .catch(error => reject(error));
                }
            }

            function loadReportTemplate() {
                return ok({
                    data: reportTemplate
                });
            }

            function loadTransmissionConfigById() {
                return ok({
                    data: transmissionConfigById
                });
            }

            function loadEmailReportTemplatePo() {
                return ok({
                    data: emailReportTemplatePo
                });
            }

            function loadConditionColumn() {
                return ok({
                    data: conditionColumn
                });
            }

            function loadEmailReportTemplate() {
                return ok({
                    data: emailReportTemplate
                });
            }

            function loadEmailReportTemplateMapping() {
                return ok({
                    data: emailReportTemplateMapping
                });
            }

            function loadAvailableColumnsInLeft() {
                return ok({
                    data: availableColumnsInLeft
                });
            }

            function loadSelectedColumns() {
                return ok({
                    data: selectedColumns
                });
            }

            function loadSelectedConditionColumn() {
                return ok({
                    data: selectedConditionColumn
                });
            }

            function loadPurchaseOdersDataMapping() {
                return ok({
                    data: ordersGridDataGetList
                });
            }

            function loadPurchaseOderDtlsDataMapping() {
                return ok({
                    data: ordersGridDataById
                });
            }

            function loadPurchaseOderDtls2DataMapping() {
                return ok({
                    data: orderGridDataById2
                });
            }

            function loadPurchaseOderDtls3DataMapping() {
                return ok({
                    data: orderGirdDataById3
                });
            }

            function loadPurchaseOderSchDataDataMapping() {
                return ok({
                    data: schGridData
                });
            }

            function loadPurchaseOderSchData2DataMapping() {
                return ok({
                    data: schGridDataId2
                });
            }

            function ok(body) {
                resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(body)) });
            }

        });
    }
}

export default fakeBackendService;