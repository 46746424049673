import { CssBaseline, FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import { CommonService } from '../../../../services';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import useStyles from '../../CommonStyle/CommonStyle'
import { FormattedMessage } from 'react-intl';

const TriggerEventCombo = (props) => {
    const classes = useStyles();
    const { comboProperty, control, errors, comboValue, setComboValue } = props;
    const statusOption = [
        { id: 0, value: 0, name: "New" },
        { id: 1, value: 1, name: "Approval Intimation" },
        { id: 2, value: 2, name: "Error" },
    ];
    return (
        <React.Fragment>
            <CssBaseline />
            { comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth>
                    <Controller
                        render={({
                            field: { onChange } }) => (
                            <Autocomplete

                                onChange={(event, newValue) => {
                                    onChange(newValue)
                                    if (typeof newValue === 'string') {

                                        if (newValue != null) {
                                            setComboValue({
                                                newValue,
                                            });
                                        }
                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setComboValue({
                                            title: newValue.inputValue,
                                        });
                                    }
                                    else if (newValue !== null) {
                                        setComboValue(newValue);
                                    } else if (newValue === null) {
                                        setComboValue('');
                                    } else {
                                        setComboValue(newValue);
                                    }
                                }}
                                loadingText="Loading..."
                                selectOnFocus
                                handleHomeEndKeys
                                value={comboValue || null}
                                autoHighlight
                                options={statusOption}
                                renderOption={(props, option) => {
                                    return <li {...props} >{
                                        <React.Fragment>
                                            {option.value === 0 && (<CommonService.TriggerEventsForChips value={0} />)}
                                            {option.value === 1 && (<CommonService.TriggerEventsForChips value={1} />)}
                                            {option.value === 2 && (<CommonService.TriggerEventsForChips value={2} />)}
                                        </React.Fragment>
                                    }
                                    </li>
                                }}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    if (option.name !== undefined)
                                        return option.name;
                                    else
                                        return option;
                                    // return option.name;

                                }}
                                getoptionselected={(option, value) => option.name === value}
                                disabled={comboProperty.isDisabled}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        required={comboProperty.isRequired}
                                        autoFocus={comboProperty.isAutofocus}
                                        name={comboProperty.name}
                                        error={comboProperty.errors}
                                        size='small'
                                        label={comboProperty.label}
                                        variant={comboProperty.variant}
                                        InputLabelProps={{
                                            shrink: true,
                                            classes: {
                                                asterisk: classes.asterisk
                                            }
                                        }}
                                    />
                                )}


                            />
                        )}

                        name={comboProperty.name}
                        rules={{ required: comboProperty.isRequired }}
                        control={control}
                        defaultValue={{ id: 0, name: '', value: '' }}
                    />
                    {comboProperty.isDisabled}
                    <div className={classes.error}>
                        {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                            <FormHelperText className={classes.error}>{comboProperty.label}  <FormattedMessage id="textfield.error.isrequirededmessage" defaultMessage="is required" /></FormHelperText>
                        )}
                    </div>
                </FormControl>
            </Grid>)}
            { (!comboProperty.isHookFormType) && (<Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "3px" }}>
                <FormControl fullWidth>
                    <Autocomplete
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {

                                if (newValue != null) {
                                    setComboValue({
                                        newValue,
                                    });
                                }
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setComboValue({
                                    title: newValue.inputValue,
                                });
                            }
                            else if (newValue !== null) {
                                setComboValue(newValue);
                            } else if (newValue === null) {
                                setComboValue('')
                            } else {
                                setComboValue(newValue);
                            }
                        }}
                        selectOnFocus
                        handleHomeEndKeys
                        value={comboValue.name || null}
                        autoHighlight
                        options={statusOption}
                        loadingText="Loading..."
                        getOptionLabel={(option) => {

                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.name;
                        }}
                        getoptionselected={(option, value) => option.name === value}
                        disabled={comboProperty.isDisabled}
                        renderOption={(props, option) => {
                            return <li {...props} >{
                                <React.Fragment>
                                    {option.value === 0 && (<CommonService.TriggerEventsForChips value={0} />)}
                                    {option.value === 1 && (<CommonService.TriggerEventsForChips value={1} />)}
                                    {option.value === 2 && (<CommonService.TriggerEventsForChips value={2} />)}
                                </React.Fragment>
                            }
                            </li>
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                autoFocus={comboProperty.isAutofocus}
                                name={comboProperty.name}
                                size='small'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                variant={comboProperty.variant}
                            />
                        )}

                    />
                </FormControl>
            </Grid>)}
        </React.Fragment>
    );
};

TriggerEventCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default TriggerEventCombo;