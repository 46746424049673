import React, { useEffect, useState } from 'react';
import {
    Button, CircularProgress, CssBaseline, Dialog, DialogActions,
    DialogContent, DialogTitle, Divider, Grid, Paper, Snackbar, Tooltip, Typography, useMediaQuery, useTheme
} from '@mui/material';
import { Alert, Timeline } from '@mui/lab';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CommonService } from '../../../services';
import EditIcon from '@mui/icons-material/EditOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import ExportIcon from '@mui/icons-material/Publish';
import AuditTimeline from '../../common/AuditTimeline/AuditTimeline';
import useStyles from '../../common/CommonStyle/CommonStyle'
import { FormattedMessage, useIntl } from 'react-intl';
import { saveAs } from 'file-saver';


const PCNTimeLine = (props) => {

    const classes = useStyles();
    let theme = useTheme();
    const intl = useIntl();
    const { onClose, open, pcnHdr, pcnDtl, isPcnTimeLine, isBusinessPartnerPortal, msgIdValue, pcnNo, msgDtlsId, msgStatusFlag } = props;
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const pageLabelsNamesAndConst = {
        labels: {
            dialogTitle: 'Delivery Confirmation Audit History',
            dialogTitleForPcnTL: 'Part Change Notification Time Line ',
            cancel: 'Cancel',
            exportTimeLine: 'Exp TimeLine',
            exportPFTAuditHistory: 'Exp PFT ADT History',
        },
    };
    const relativeDateNames = {
        "first_ans_due_dt": "First Answer Due Date",
        "last_ans_due_dt": "Last Answer Due Date",
        "last_time_buy_dt": "Last Time Buy Date",
        "last_time_shipment_dt": "Last Time Shipment Date",
    };

    const [recordsPerPage] = useState(30);
    const [pageNo, setPageNo] = useState(0);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(true);
    const [isExportXlRequested, setExportXlRequested] = useState(false);
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [data, setData] = useState([]);

    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };

    const handleClose = (value) => {
        onClose(value);
        setData([]);
        setPageNo(0);
    }
    const next = () => {
        setPageNo(pageNo + 1);
    }
    useEffect(() => {
        if (open) {
            fetchPcnTiemLineList();
        }
    }, [pcnHdr, open, pageNo]);

    function fetchPcnTiemLineList() {
        let tempData = data;
        setTableLoadingStatus(true);

        CommonService.GetListApi(isPcnTimeLine ? `/pcnOutboudMsgStatusHistory` : `/poOutboundSchAuditHistory`, {
            body: {
                msgId: msgIdValue
            },
            pageNo: pageNo,
            sortDir: 'desc',
            sortKey: 'pcnOutbndMsgStsId',
            recordsPerPage: recordsPerPage
        })
            .then((response) => {
                setTotalPageCount(response.data.totalPages);
                setData(tempData.concat(response.data.content));
                setTableLoadingStatus(false);
            })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setTableLoadingStatus(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }

    function requestPcnDtTimeLineHistory() {
        setExportXlRequested(true);
        CommonService.saveOrUpdateOrPost(isPcnTimeLine ? '/reports/PART_CHANGE_NOTIFICATION_TIMELINE_HISTORY_REPORT' : ' ', {
            msgId: msgIdValue.toString(),
        })
            .then((response) => {
                // showDownloadPopUp(response.data.fileUrl)
                setExportXlRequested(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success" });
            })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setExportXlRequested(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }

    const showDownloadPopUp = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })

            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }



    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}>
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container className={classes.root}>
                <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <Dialog fullWidth maxWidth='md' open={open}>
                        <DialogTitle className={classes.title}>
                            <Grid item container xs={12} sm={12} md={12} lg={12}>
                                <Grid item container alignItems='center' item xs={12} sm={6} md={6} lg={6} >
                                    <strong>{!isPcnTimeLine ? <FormattedMessage id="PoAuditHistory.txt" defaultMessage={pageLabelsNamesAndConst.labels.dialogTitle} /> :
                                        <FormattedMessage id="PcnTimeLine.txt" defaultMessage={pageLabelsNamesAndConst.labels.dialogTitleForPcnTL} />} - {pcnNo}
                                    </strong>
                                </Grid>
                                {<Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={6} md={6} lg={6}>
                                    <Button
                                        size='small'
                                        style={{ color: theme.palette.customText.main, fontWeight: 600 }}
                                        disabled={data.length <= 0 || isExportXlRequested}
                                        component="span"
                                        startIcon={<ExportIcon />}
                                        onClick={(() => {
                                            requestPcnDtTimeLineHistory();
                                        })}
                                        endIcon={isExportXlRequested ? <CircularProgress color="secondary" size={20} /> : null}
                                    > {!isPcnTimeLine ? <FormattedMessage id="exppoDtladthistory.tooltip" /> : <FormattedMessage id="exppcnadthistory.tooltip" />}
                                    </Button>
                                </Grid>}
                            </Grid>
                        </DialogTitle>
                        <DialogContent id="scrollableDiv" dividers style={{ minHeight: '200px' }}>
                            {(data.length > 0) && (<InfiniteScroll
                                dataLength={data.length}
                                next={next}
                                hasMore={totalPageCount !== (pageNo + 1)}
                                scrollableTarget="scrollableDiv"
                                loader={
                                    <Grid container justifyContent={isMobile ? 'flex-start' : 'center'} alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                        <CircularProgress />
                                        <h6><FormattedMessage id="loading.text" defaultMessage="Loading....." /></h6>
                                    </Grid>
                                }
                            >



                                <Timeline align={isMobile ? "left" : "alternate"}>
                                    {data && data.map((pcnTimeLine, index) => {
                                        let timelineIcon = <EditIcon />;
                                        let timelineColor = 'secondary';
                                        let timelineConnectorColor = classes.primaryTail;
                                        let isLastNode = (data.length - 1) !== index;
                                        let modifiedBy =
                                            pcnTimeLine.updatedBy !== null && pcnTimeLine.updatedBy !== ""
                                                ? pcnTimeLine.updatedBy
                                                : intl.formatMessage({ id: "system.text" });
                                        const lastMessageSentTime = pcnTimeLine.lastMessageSentTime;
                                        const relativeDateKey = pcnTimeLine.relativeDate;
                                        const relativeDateDisplayName = relativeDateNames[relativeDateKey] || relativeDateKey;
                                        const msgStatusFlag = pcnTimeLine.msgStatusFlag;

                                        return (
                                            <React.Fragment key={index}>
                                                {msgStatusFlag > 1 && (
                                                    <AuditTimeline
                                                        timelineIcon={timelineIcon}
                                                        timelineColor={timelineColor}
                                                        timelineConnectorColor={timelineConnectorColor}
                                                        title={relativeDateDisplayName}
                                                        isLastNode={isLastNode}
                                                        detailedMessage={lastMessageSentTime && (
                                                            <FormattedMessage
                                                                id="pcn.TLdetailedmessage.confirm.text"
                                                                values={{
                                                                    modified: `${modifiedBy}`,
                                                                    lastMessageSentTimeDisp: `${pcnTimeLine.lastMessageSentTimeDisp}`
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </Timeline>


                            </InfiniteScroll>)}
                            {tableLoadingStatus && (
                                <Paper style={{ marginTop: '25px', width: '100%' }}>
                                    <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                        <CircularProgress color='primary' size={40} />
                                    </Grid>
                                </Paper>
                            )}
                            {(data.length <= 0 && !tableLoadingStatus) && (
                                <Paper style={{ marginTop: '25px', width: '100%' }}>
                                    <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                        <Typography variant='body2' noWrap={true} className={classes.boldHeaderForNoRows}><FormattedMessage id="norows.label" defaultMessage="No rows" /></Typography>
                                    </Grid>
                                </Paper>
                            )}
                        </DialogContent>
                        {!isMobile && (
                            <DialogActions className={classes.dialogAction} >
                                <Grid container justifyContent='flex-end' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} >
                                    <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2} endIcon={<ClearIcon />}
                                        onClick={() => handleClose(false)}
                                    ><FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />
                                    </Button>
                                </Grid>
                            </DialogActions>)}
                        {isMobile && (<Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                            <DialogActions className={classes.dialogAction}>
                                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.topGrid} >
                                    <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                                        <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2}
                                            onClick={() => handleClose(false)}
                                        >  <ClearIcon />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </DialogActions>
                        </Grid>)}
                    </Dialog>
                </Grid>
            </Grid>
        </React.Fragment >
    );
}
export default PCNTimeLine;

