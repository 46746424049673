import React, { useEffect, useState } from 'react';
import { CssBaseline, FormControl, Grid, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Controller } from 'react-hook-form';
import _ from "lodash/fp";
import useStyles from '../../common/CommonStyle/CommonStyle';

const AccountPolicy = (props) => {
    const classes = useStyles();
    const { mode, control } = props;
    const pageLabelsConstantsAndMessages = {
        name: {
            bankAccount: "bankAccount",
            accountHolder: "accountHolder",
            bankKey: "bankKey",
            vatRegNo: "vatRegNo"
        }
    }

    const [accountReadOnlyFields, setAccountReadOnlyFields] = useState({
        bankAccount: false,
        accountHolder: false,
        bankKey: false,
        vatRegNo: false,

    })
    useEffect(() => {
        if (mode === 'read') {
            setAccountReadOnlyFields({
                ...accountReadOnlyFields, bankAccount: false, accountHolder: false, bankKey: false, vatRegNo: false
            })
        }
    }, [mode])

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.bankAccount}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="bankaccount"
                                            size='small'
                                            label={<FormattedMessage id="columnname.textfield.bankaccount.label" defaultMessage=" Bank Account" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: accountReadOnlyFields.bankAccount,
                                            }}
                                            disabled={mode === 'read' ? true : false}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.accountHolder}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="accountHolder"
                                            size='small'
                                            label={<FormattedMessage id="columnname.textfield.accountholder.label" defaultMessage=" Account Holder" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: accountReadOnlyFields.accountHolder,
                                            }}
                                            disabled={mode === 'read' ? true : false}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.bankKey}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="bankKey"
                                            size='small'
                                            label={<FormattedMessage id="columnname.textfield.bankkey.label" defaultMessage=" Bank Key" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: accountReadOnlyFields.bankKey,
                                            }}
                                            disabled={mode === 'read' ? true : false}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.vatRegNo}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="vatRegNo"
                                            size='small'
                                            label={<FormattedMessage id="columnname.vatregno.label" defaultMessage=" Vat Reg No" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: accountReadOnlyFields.vatRegNo,
                                            }}
                                            disabled={mode === 'read' ? true : false}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
export default AccountPolicy;