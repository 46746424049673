import { Paper, Typography } from '@mui/material';
import { TimelineConnector, TimelineContent, TimelineDot, 
    TimelineItem, TimelineOppositeContent, TimelineSeparator 
} from '@mui/lab';
import React from 'react';
import useStyles from '../CommonStyle/CommonStyle'

const AuditTimeline = (props) => {
    const classes = useStyles()
    const { modifiedDate,timelineIcon,timelineColor,title,detailedMessage,isLastNode, timelineConnectorColor } = props;
    
    return(
        <React.Fragment>
            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textPrimary">
                        {modifiedDate}
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot color={timelineColor}>{timelineIcon}</TimelineDot>                   
                    {(isLastNode) && (<TimelineConnector className={timelineConnectorColor} />)}
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={classes.paperForAuditTimeline}>
                        <Typography variant="h6">{title}</Typography>
                        <Typography variant="subtitle2">{detailedMessage}</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        </React.Fragment>
    )
}
export default AuditTimeline;