function FakeBackeendForTransmissionConfigs() {
    return (
        {
            "content":
                [
                    {
                        "createdBy": "EADMIN",
                        "createdTime": "11-09-2021 10:12:19+0000",
                        "updatedBy": "EADMIN",
                        "updatedTime": "11-09-2021 10:12:19+0000",
                        "active": true,
                        "protoConfigId": 1,
                        "transConfName": "VALEO VAN",
                        "protocol": {
                            "value": "VAN"
                        },
                        "entity": {
                            "erpEntityCode": "SES",
                            "entityName": "SIIX EMS Slovakia s.r.o.",
                            "valuesToBeDisplayed": "SES - SIIX EMS Slovakia s.r.o."
                        },
                        'user': "SFTP_SIIX",
                        'password': "$FTPS1IX.21",
                        'ip': "201.116.200.211",
                        'outbox': "ATEB",
                        'protocol2': {
                            "value": "SFTP"
                        },
                        "ediAddress": "812781034",
                        "ssid": "O0931000009503443330ECC",
                        "sfid": "O0931000009503443330ECC",
                        "isdnNumber": "+33141831941",
                        "isdnPassword": "VALEO",
                        "serviceProvider": "EDI PORT",
                        "serviceType": "VAN",
                        "secured": true,
                        "host": "host test 1",
                        "port": 9992,
                        "encrypted": true,
                        "encryptCert": "encrypt_cert",
                        "signature": true,
                        "signCert": "sign_certtest 1",
                        "authority": "authoritytest 1",
                        "encryptionAlgo": "encryption_also",
                        "validation": true,
                        "compression": true,
                        "ipForFirewall": "ip_for_firewall",
                        "inbox": "inbox",
                        "qualifier": "ZZ",
                        "mailBoxId": "valeo_mailBox",
                        "uri": "uri",
                        "as2Identifier": "as2_identifier",
                        "contentType": "content_type",
                        "mdnRequestType": "mdn_request_type",
                        "mdnSigned": true,
                        "userId": "1",
                        "recordActiveStatus": "All"
                    },
                    {
                        "createdBy": "EADMIN",
                        "createdTime": "11-09-2021 10:12:19+0000",
                        "updatedBy": "EADMIN",
                        "updatedTime": "11-09-2021 10:12:19+0000",
                        "active": true,
                        "protoConfigId": 2,
                        "transConfName": "BOSCH",
                        "protocol": {
                            "value": "VAN"
                        },
                        "entity": {
                            "erpEntityCode": "SES",
                            "entityName": "SIIX EMS Slovakia s.r.o.",
                            "valuesToBeDisplayed": "SES - SIIX EMS Slovakia s.r.o."
                        },
                        'user': "SFTP_SIIX",
                        'password': "$FTPS1IX.21",
                        'ip': "201.116.200.211",
                        'outbox': "ATEB",
                        'protocol2': {
                            "value": "SFTP"
                        },
                        "ediAddress": "812781034",
                        "ssid": "O0931000009503443330ECC",
                        "sfid": "O0931000009503443330ECC",
                        "isdnNumber": "+33141831941",
                        "isdnPassword": "VALEO",
                        "serviceProvider": "EDI PORT",
                        "serviceType": "VAN",
                        "secured": true,
                        "host": "host test 1",
                        "port": 9992,
                        "encrypted": true,
                        "encryptCert": "encrypt_cert",
                        "signature": true,
                        "signCert": "sign_certtest 1",
                        "authority": "authoritytest 1",
                        "encryptionAlgo": "encryption_also",
                        "validation": true,
                        "compression": true,
                        "ipForFirewall": "ip_for_firewall",
                        "inbox": "inbox",
                        "qualifier": "ZZ",
                        "mailBoxId": "bosch_mailBox",
                        "uri": "uri",
                        "as2Identifier": "as2_identifier",
                        "contentType": "content_type",
                        "mdnRequestType": "mdn_request_type",
                        "mdnSigned": true,
                        "userId": "101",
                        "recordActiveStatus": "All"
                    }
                ],
            "pageable": {
                "sort": {
                    "unsorted": false,
                    "sorted": true,
                    "empty": false
                },
                "offset": 0,
                "pageSize": 25,
                "pageNumber": 0,
                "unpaged": false,
                "paged": true
            },
            "totalPages": 1,
            "last": true,
            "totalElements": 1,
            "size": 25,
            "number": 0,
            "sort": {
                "unsorted": false,
                "sorted": true,
                "empty": false
            },
            "first": true,
            "numberOfElements": 1,
            "empty": false
        }
    )
}

export default FakeBackeendForTransmissionConfigs;