import { CircularProgress, CssBaseline, FormControl, FormHelperText, Grid, TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import useStyles from '../../CommonStyle/CommonStyle'
import { FormattedMessage } from 'react-intl';


const EmailStatusCombo = (props) => {
    const classes = useStyles();
    const { comboProperty, control, errors, comboValue, setComboValue} = props;
    const [isLoading, setLoading] = useState(false);
    const EmailStatusValue = [
        { id:1, value: 'ALL'},
        { id:2, value: 'EMAIL SENT'},
        { id:3, value: 'EMAIL NOT SENT'},
    ];

    return (
        <React.Fragment>
            <CssBaseline />
        {comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth>
                <Controller
                     render={({ 
                        field: {onChange}}) => (
                        <Autocomplete

                            onChange={(event, newValue) => {
                                onChange(newValue)

                                if (typeof newValue === 'string') {

                                    if (newValue != null) {
                                        setComboValue({
                                            newValue,
                                        });
                                    }
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    setComboValue({
                                        title: newValue.inputValue,
                                    });

                                }
                                else if (newValue !== null) {

                                    setComboValue(newValue);
                                } else if (newValue === null) {
                                    setComboValue('')
                                } else {
                                    setComboValue(newValue);
                                }
                            }}
                            selectOnFocus
                            loadingText="Loading..."
                            loading={isLoading}
                            handleHomeEndKeys
                            value={comboValue.value || null}
                            autoHighlight
                            options={EmailStatusValue}
                            getOptionLabel={(option) => {

                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.value;
                            }}
                            getoptionselected={(option, value) => option.value === value}
                            disabled={comboProperty.isDisabled}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    className={classes.textField}
                                    autoFocus={comboProperty.isAutofocus}                              
                                    name={comboProperty.name}
                                    size='small'
                                    label={comboProperty.label }
                                    error={comboProperty.errors}
                                    variant={comboProperty.variant}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    )}

                    name={comboProperty.name}
                    rules={{ required: comboProperty.isRequired }}
                    control={control}
                    defaultValue={{ id: 0, value: '' }}
                />
                <div className={classes.error}>
                    {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                        <FormHelperText className={classes.error}>{comboProperty.label} <FormattedMessage id="textfield.error.isrequirededmessage" defaultMessage="is required" /></FormHelperText>
                    )}
                </div>
            </FormControl>
        </Grid>)}

        { (!comboProperty.isHookFormType) && (<Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: "3px"}}>
        <FormControl fullWidth>
            <Autocomplete
                        fullWidth
                        openOnFocus = {true}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {

                                if (newValue != null) {
                                    setComboValue({
                                        newValue,
                                    });
                                }
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setComboValue({
                                    title: newValue.inputValue,
                                });
                            }
                            else if (newValue !== null) {

                                setComboValue(newValue);
                            } else if (newValue === null) {
                                setComboValue('')
                            } else {
                                setComboValue(newValue);
                            }
                        }}
                        selectOnFocus
                        loadingText="Loading..."
                        loading={isLoading}
                        handleHomeEndKeys
                        value={comboValue.value || null}
                        autoHighlight
                        options={EmailStatusValue}
                        getOptionLabel={(option) => {

                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.value;
                        }}
                        getoptionselected={(option, value) => option.value === value}
                        disabled={comboProperty.isDisabled}
                        renderInput={params => (
                            <Tooltip title={comboValue.value || ''}>
                            <TextField
                                {...params}
                                autoFocus={comboProperty.isAutofocus}
                                name={comboProperty.name}
                                size='small'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {isLoading ?
                                                <CircularProgress color="inherit" size={20} />
                                            : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                variant={comboProperty.variant}
                            />
                            </Tooltip>
                        )}

                    />
        </FormControl>
        </Grid>)}
        </React.Fragment>
);
}
EmailStatusCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};



export default EmailStatusCombo;