import { Badge, Button, CssBaseline, Grid, IconButton, Paper, Snackbar, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    LicenseInfo, DataGridPro, GridToolbarColumnsButton, GridToolbarDensitySelector, getGridStringOperators,
    getGridDateOperators
} from '@mui/x-data-grid-pro';
import { CommonService } from '../../services';
import ChipInfoList from '../common/ChipInfoList';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import Alert from '@mui/lab/Alert';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CustomFilters } from '../common/CustomFilters/CustomFilters';
import useStyles from '../common/CommonStyle/CommonStyle';
import { FormattedMessage, useIntl } from 'react-intl';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import { SupportedLanguageDetails } from '../common/SupportedLanguageDetails/SupportedLanguageDetails';
import CommonGridPagination from '../common/CommonGridPagination/CommonGridPagination';
import { ModuleAccessPermissionKey } from '../../Constants/ModuleAccessKey';
import IconMessage from '@mui/icons-material/Forum';
import CustomBreadCrumb from '../common/CustomBreadCrumb';
import IconApproval from '@mui/icons-material/LibraryAddCheck';
import CancelIcon from '@mui/icons-material/Cancel';
import GetAppIcon from '@mui/icons-material/GetApp';
import CustomReportIcon from '@mui/icons-material/Archive';
import CommonBackDrop from '../common/BackDrop';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';
import IconInvoice from '@mui/icons-material/SpeakerNotes';
import CommonReportDownloadForMsg from '../common/CommonReportDownloadForMsg';
import CustomFilterComponent, { GetFilterableColumns } from '../common/CustomFilterComponent';
import { FilterList } from '@mui/icons-material';
import { manageUserPreferences } from '../../services/manageUserPreferences';

const columnFieldsName = {
    msgId: 'msgId',
    testInd: 'testInd',
    invoiceNo: 'invoiceNo',
    invoiceDt: 'invoiceDt',
    inOut: 'inOut',
    servComplDt: 'servComplDt',
    freeText: 'freeText',
    taxExempReason: 'taxExempReason',
    buyerPartyCode: 'buyerPartyCode',
    buyerName: 'buyerName',
    buyerAddress: 'buyerAddress',
    buyerCity: 'buyerCity',
    buyerState: 'buyerState',
    buyerCountry: 'buyerCountry',
    buyerPostalCode: 'buyerPostalCode',
    buyerVat: 'buyerVat',
    supplierPartyCode: 'supplierPartyCode',
    supplierName: 'supplierName',
    supplierAddress: 'supplierAddress',
    supplierCity: 'supplierCity',
    supplierState: 'supplierState',
    supplierCountry: 'supplierCountry',
    supplierPostalCode: 'supplierPostalCode',
    supplierVat: 'supplierVat',
    fcNo: 'fcNo',
    compRegNo: 'compRegNo',
    invoiceePartyCode: 'invoiceePartyCode',
    invoiceeName: 'invoiceeName',
    invoiceeAddress: 'invoiceeAddress',
    invoiceeCity: 'invoiceeCity',
    invoiceeState: 'invoiceeState',
    invoiceeCountry: 'invoiceeCountry',
    invoiceePostalCode: 'invoiceePostalCode',
    invoiceIssuerPartyCode: 'invoiceIssuerPartyCode',
    invoiceIssuerName: 'invoiceIssuerName',
    invoiceIssuerAddress: 'invoiceIssuerAddress',
    invoiceIssuerCity: 'invoiceIssuerCity',
    invoiceIssuerState: 'invoiceIssuerState',
    invoiceIssuerCountry: 'invoiceIssuerCountry',
    invoiceIssuerPostalCode: 'invoiceIssuerPostalCode',
    shipFromPartyCode: 'shipFromPartyCode',
    shipFromName: 'shipFromName',
    shipFromAddress: 'shipFromAddress',
    shipFromCity: 'shipFromCity',
    shipFromState: 'shipFromState',
    shipFromCountry: 'shipFromCountry',
    shipFromPostalCode: 'shipFromPostalCode',
    shipToPartyCode: 'shipToPartyCode',
    shipToName: 'shipToName',
    shipToAddress: 'shipToAddress',
    shipToCity: 'shipToCity',
    shipToState: 'shipToState',
    shipToCountry: 'shipToCountry',
    shipToPostalCode: 'shipToPostalCode',
    payeePartyCode: 'payeePartyCode',
    payeeName: 'payeeName',
    payeeAddress: 'payeeAddress',
    payeeCity: 'payeeCity',
    payeeState: 'payeeState',
    payeeCountry: 'payeeCountry',
    payeePostalCode: 'payeePostalCode',
    currency: 'currency',
    paymentTerms: 'paymentTerms',
    shipmentTerms: 'shipmentTerms',
    modeOfTransport: 'modeOfTransport',
    invoiceAmt: 'invoiceAmt',
    totalTaxableAmt: 'totalTaxableAmt',
    totalLineItemAmt: 'totalLineItemAmt',
    totalTaxAmt: 'totalTaxAmt',
    totalInvoiceAddtnlAmt: 'totalInvoiceAddtnlAmt',
    taxRate1: 'taxRate1',
    taxAmount1: 'taxAmount1',
    taxRate2: 'taxRate2',
    taxAmount2: 'taxAmount2',
    taxRate3: 'taxRate3',
    taxAmount3: 'taxAmount3',
    packingCharges: 'packingCharges',
    freightCharges: 'freightCharges',
    deliveryCharges: 'deliveryCharges',
    incoterm: 'incoterm',
    incotermPart2: 'incotermPart2',
    approved: 'approved',
    approvedBy: 'approvedBy',
    approvedDate: 'approvedDate',
    apprEmailSent: 'apprEmailSent',
    msgStatusFlag: 'msgStatusFlag',
    msgStatusRemarks: 'msgStatusRemarks',
    createdTime: 'createdTime',
    createdBy: 'createdBy',
    updatedTime: 'updatedTime',
    updatedBy: 'updatedBy',
    erpOrgCode: 'organization.erpOrgCode',
    erpEntityCode: 'entity.erpEntityCode',
    orgName: 'organization.orgName',
    entityName: 'entity.entityName',
    bpCode: 'bpCode',
    bpType: 'bpType',
    attribute1: 'attribute1',
    attribute2: 'attribute2',
    attribute3: 'attribute3',
    attribute4: 'attribute4',
    attribute5: 'attribute5',
    attribute6: 'attribute6',
    attribute7: 'attribute7',
    attribute8: 'attribute8',
    attribute9: 'attribute9',
    attribute10: 'attribute10',
    invoiceType: 'invoiceType',
    billingType: 'billingType',
    buyerCountryCode: 'buyerCountryCode',
    supplierCountryCode: 'supplierCountryCode',
    invoiceeCountryCode: 'invoiceeCountryCode',
    invoiceeAmount: 'invoiceAmt',
    invoiceIssuerCountryCode: 'invoiceIssuerCountryCode',
    shipFromCountryCode: 'shipFromCountryCode',
    shipToCountryCode: 'shipToCountryCode',
    payeeCountryCode: 'payeeCountryCode',
    companyCode: 'companyCode',
    plantCode: 'plantCode',
    fromDt: 'fromDt',
    toDt: 'toDt',
    fromServComplDt: 'fromServComplDt',
    toServComplDt: 'toServComplDt',
    messageSentMode: 'messageSentMode',
    messageSentTime: 'messageSentTime',
    fromMessageSentTime: 'fromMessageSentTime',
    toMessageSentTime: 'toMessageSentTime',
    billingCreatedByUserId: 'billingCreatedByUserId',
    billingCreatedByUserName: 'billingCreatedByUserName',
    portInfoFrom: 'portInfoFrom',
    portInfoTo: 'portInfoTo',
    vessel: 'vessel',
    ETD: 'ETD',
    latestDataSource: 'latestDataSource'
};

function InvoiceHeader(props) {

    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const pageLabelsConstantsAndMessages = {
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        },
    };

    const [selectedRow, setSelectedRow] = React.useState([]);
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const invoiceModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.invoice];
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [chipInfoList, setChipInfoList] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [disableApproveButton, isDisableApproveButton] = useState(true);
    const [disiablingDownloadButtonForZeroRecords, setDisiablingDownloadButtonForZeroRecords] = useState(false)
    const [keyValueData, setKeyValueData] = useState({});
    const [searchedFromDt, setSearchedFromDt] = useState('');
    const [searchedToDt, setSearchedToDt] = useState('');
    const [searchedMsgSentFromDt, setSearchedMsgSentFromDt] = useState('');
    const [searchedMsgSentToDt, setSearchedMsgSentToDt] = useState('');
    const [searchedFromServComplDt, setSearchedFromServComplDt] = useState('');
    const [searchedToServComplDt, setSearchedToServComplDt] = useState('');
    const [isRowSentForApproval, setIsRowSentForApproval] = useState(false);
    var defaultEntity = loggedInUserInfo.defaultEntity;
    const [searchedErpEntityCode, setSearchedErpEntityCode] = useState(defaultEntity);
    const [searchedBpType, setSearchedBpType] = useState('');
    const [searchedBpCode, setSearchedBpCode] = useState('');
    const [searchedInvoiceNo, setSearchedInvoiceNo] = useState('');
    const [searchedBuyerPartyCode, setSearchedBuyerPartyCode] = useState('');
    const [searchedBuyerName, setSearchedBuyerName] = useState('');
    const [searchedSupplierPartyCode, setSearchedSupplierPartyCode] = useState('');
    const [searchedSupplierName, setSearchedSupplierName] = useState('');
    const [msgHdrPopUp, setMsgHdrPopUp] = useState(false);
    const [searchedApprovedRecordsRecords, setSearchedApprovedRecordsRecords] = useState(null);
    const [searchedMsgStatus, setSearchMsgStatus] = useState('');
    const [searchedCommType, setSearchedCommType] = useState('');
    const [searchedBillingCreatedByUserName, setSearchedBillingCreatedByUserName] = useState('');
    const [disableRejectionButton, isDisableRejectionButton] = useState(true);
    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);

    const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.erpEntityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity }]
        : [];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);

    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.invoiceHdrs);

    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.message" />,
            iconName: IconMessage,
        },
        {
            path: '/invoices',
            name: <FormattedMessage id="common.module.breadcrumb.invoice" />,
            iconName: IconInvoice,
        }
    ];

    const [openReportDownloadDialog, setOpenReportDownloadDialog] = useState(false);
    const [proccessValue, setProcessValue] = useState('');
    const [bpCodeValue, setBpCodeValue] = useState('');
    const [bpTypeValue, setBpTypeValue] = useState('');
    const [entityValue, setEntityValue] = useState('');
    const [msgIdvalue, setMsgIdValue] = useState('');

    const closeReportDownloadDialog = (confirmed) => {
        setOpenReportDownloadDialog(false);
    }

    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState({
        left: ["__check__"],
        right: []
    });
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);


    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }


    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);

    const commonColumnForAsnOutbound =
        [
            // {
            //     field: 'action',
            //     headerName: intl.formatMessage({ id: "columnname.action.label" }),
            //     width: 100,
            //     sortable: false,
            //     hide: false,
            //     headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            //     align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            //     filterable: false,
            //     renderHeader: CommonService.customRenderHeaderColumns,
            //     renderCell: (cellParams) => (
            //         <div>
            //             {(invoiceModuleAccess !== undefined ? invoiceModuleAccess.readFlag : false) &&
            //                 (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadReport" />}
            //                     placement='bottom' className={classes.actionButton} onClick={() => {
            //                         setOpenReportDownloadDialog(true);
            //                         setProcessValue({ processId: 5, processName: 'Invoice' });
            //                         setBpCodeValue(cellParams.row.bpCode);
            //                         setBpTypeValue(cellParams.row.bpType);
            //                         setEntityValue(cellParams.row.entity.erpEntityCode);
            //                         setMsgIdValue(cellParams.row.msgId);
            //                     }}>
            //                     <span>
            //                         <IconButton color="secondary" size="large">
            //                             <CustomReportIcon size={20} />
            //                         </IconButton>
            //                     </span>
            //                 </Tooltip>)}
            //         </div>
            //     )
            // },
            {
                field: columnFieldsName.erpEntityCode,
                headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 85,
                sortable: false,
                type: 'string',
                hide: false,
                filterable: true,
                valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.buyerPartyCode,
                width: 130,
                headerName: intl.formatMessage({ id: "columnname.bpCode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.buyerName,
                width: 300,
                headerName: intl.formatMessage({ id: "columnName.bpname.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.invoiceNo,
                headerName: intl.formatMessage({ id: "columnname.invoiceno.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
                width: 120,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.invoiceDt,
                headerName: intl.formatMessage({ id: "columnname.invoicedate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 125,
                sortable: true,
                type: 'dateTime',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.invoiceDtDisp}</span>)
            },
            {
                field: columnFieldsName.latestDataSource,
                width: 170,
                headerName: intl.formatMessage({ id: "columnname.latestDataSource.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.servComplDt,
                headerName: intl.formatMessage({ id: "columnname.shipmentdate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 130,
                sortable: true,
                type: 'dateTime',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.servComplDtDisp}</span>)
            },
            {
                field: columnFieldsName.billingCreatedByUserId,
                headerName: intl.formatMessage({ id: "columnname.billingCreatedByUserId.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 250,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.billingCreatedByUserName,
                headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 140,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.approved,
                width: 150,
                headerName: intl.formatMessage({ id: "columnName.approved.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.ApproveFailureChipsForPO
            },
            {
                field: columnFieldsName.approvedBy,
                width: 155,
                headerName: intl.formatMessage({ id: "columnName.approvedBy.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.approvedDate,
                width: 170,
                headerName: intl.formatMessage({ id: "columnName.approvedDate.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.approvedDateDtDisp}</span>)
            },
            {
                field: columnFieldsName.msgStatusFlag,
                width: 150,
                headerName: intl.formatMessage({ id: "columnname.Msgstatus.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.InvoiceeStatusForChips
            },
            {
                field: columnFieldsName.apprEmailSent,
                headerName: intl.formatMessage({ id: "columnName.apprEmailSent.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 200,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.customRenderStatusColumnForApprEmailSent
            },
            {
                field: columnFieldsName.messageSentMode,
                width: 185,
                headerName: intl.formatMessage({ id: "columnname.messageSentMode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.messageSentTime,
                headerName: intl.formatMessage({ id: "columnname.messageSentTime.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 170,
                sortable: true,
                type: 'dateTime',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.messageSentTimeDisp}</span>)
            },
            {
                field: columnFieldsName.updatedBy,
                headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 155,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.updatedTime,
                headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.updatedTimeDtDisp}</span>)
            },
            {
                field: columnFieldsName.createdBy,
                headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 150,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.createdTime,
                headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.createdTimeDtDisp}</span>)
            },
            {
                field: columnFieldsName.invoiceeAmount,
                width: 205,
                headerName: intl.formatMessage({ id: "columnname.invoiceeAmount.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.totalTaxableAmt,
                headerName: intl.formatMessage({ id: "columnname.totaltaxableamount.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 210,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.totalTaxAmt,
                headerName: intl.formatMessage({ id: "columnname.totaltaxamount.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 200,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.msgStatusRemarks,
                headerName: intl.formatMessage({ id: "columnname.remarks.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.fromServComplDt,
                headerName: intl.formatMessage({ id: "columnname.fromServComplDt.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 0,
                sortable: false,
                type: 'string',
                hide: true,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.toServComplDt,
                headerName: intl.formatMessage({ id: "columnname.toServComplDt.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 0,
                sortable: false,
                type: 'string',
                hide: true,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: 'fromDt',
                headerName: intl.formatMessage({ id: "columnname.invoiceFromDt.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 0,
                sortable: false,
                type: 'string',
                hide: true,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: 'toDt',
                headerName: intl.formatMessage({ id: "columnname.invoiceToDt.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 0,
                sortable: false,
                type: 'string',
                hide: true,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.fromMessageSentTime,
                headerName: intl.formatMessage({ id: "columnname.msgSentfromDt.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 0,
                sortable: false,
                type: 'string',
                hide: true,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.toMessageSentTime,
                headerName: intl.formatMessage({ id: "columnname.msgSenttoDt.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 0,
                sortable: false,
                type: 'string',
                hide: true,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.portInfoFrom,
                headerName: intl.formatMessage({ id: "columnname.portInfoFrom.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 170,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.portInfoTo,
                headerName: intl.formatMessage({ id: "columnname.portInfoTo.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 170,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.vessel,
                headerName: intl.formatMessage({ id: "columnname.vessel.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 130,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.ETD,
                headerName: intl.formatMessage({ id: "columnname.ETD.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 130,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
        ];

    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(commonColumnForAsnOutbound);
                savePreference("visibility", commonColumnForAsnOutbound);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, commonColumnForAsnOutbound);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let buyerName = null;
                let bpCode = null;
                let buyerPartyCode = null;
                let entityCode = null;
                let approved = null;
                let invoiceNo = null;
                let billingCreatedByUserName = null;
                let msgStatusFlag = null;
                let messageSentMode = null;
                let fromServComplDt = null;
                let toServComplDt = null;
                let fromMessageSentTime = null;
                let toMessageSentTime = null;
                let fromInvoiceDt = null;
                let toInvoiceDt = null;
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'buyerName':
                            buyerName = columns.value
                            break;
                        case 'buyerPartyCode':
                            buyerPartyCode = columns.value
                            bpCode = columns.value
                            break;
                        case 'entity.erpEntityCode':
                            entityCode = columns.value
                            break;
                        case 'approved':
                            approved = columns.value
                            break;
                        case 'msgStatusFlag':
                            msgStatusFlag = columns.value
                            break;
                        case 'invoiceNo':
                            invoiceNo = columns.value
                            break;
                        case 'billingCreatedByUserName':
                            billingCreatedByUserName = columns.value
                            break;
                        case 'messageSentMode':
                            messageSentMode = columns.value
                            break;
                        case 'fromMessageSentTime':
                            fromMessageSentTime = columns.value
                            break;
                        case 'toMessageSentTime':
                            toMessageSentTime = columns.value
                            break;
                        case 'fromServComplDt':
                            fromServComplDt = columns.value
                            break;
                        case 'toServComplDt':
                            toServComplDt = columns.value
                            break;
                        case 'fromDt':
                            fromInvoiceDt = columns.value
                            break;
                        case 'toDt':
                            toInvoiceDt = columns.value
                            break;
                    }
                })

                setSearchedErpEntityCode(entityCode);
                setSearchedApprovedRecordsRecords(approved);
                setSearchedBpCode(bpCode);
                setSearchedCommType(messageSentMode);
                setSearchedInvoiceNo(invoiceNo);
                setSearchedBuyerPartyCode(buyerPartyCode);
                setSearchedBuyerName(buyerName);
                setSearchedFromDt(fromInvoiceDt);
                setSearchedToDt(toInvoiceDt);
                setSearchMsgStatus(msgStatusFlag)
                setSearchedFromServComplDt(fromServComplDt);
                setSearchedToServComplDt(toServComplDt);
                setSearchedMsgSentToDt(toMessageSentTime);
                setSearchedMsgSentFromDt(fromMessageSentTime)
                setSearchedBillingCreatedByUserName(billingCreatedByUserName)

                var filterBody = {
                    bpCode: bpCode,
                    approved: approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : '',
                    recordApprovedStatus: approved === '' ? 'All' : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : 'All',
                    recordStatusFlag: msgStatusFlag === '' ? 'All' : msgStatusFlag === undefined ? 'All' : msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : 'All',
                    messageSentMode: messageSentMode,
                    msgStatusFlag: msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : '',
                    invoiceNo: invoiceNo,
                    buyerPartyCode: buyerPartyCode,
                    buyerName: buyerName,
                    fromServComplDt: fromServComplDt,
                    toServComplDt: toServComplDt,
                    fromMessageSentTime: fromMessageSentTime,
                    toMessageSentTime: toMessageSentTime,
                    billingCreatedByUserName: billingCreatedByUserName,
                    fromDt: fromInvoiceDt,
                    toDt: toInvoiceDt,
                    entity: { erpEntityCode: entityCode },
                };

                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0)
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [selectedLanguage]);

    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.invoiceHdrs, paramBody)
                .then((response) => {
                    setTotalRowCount(response.data.totalElements);
                    setTableLoadingStatus(false);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }

    }, [paramBody, refreshTable]);


    useEffect(() => {
        if (totalRowCount === 0) {
            setDisiablingDownloadButtonForZeroRecords(true)
        } else {
            setDisiablingDownloadButtonForZeroRecords(false)
        }
    }, [totalRowCount])

    if (columns.length > 0) {
        const orgNameColumn = columns.find((column) => column.field === columnFieldsName.orgName);
        const orgNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.orgName);

        const orgNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgNameInputValue,
            }),
        );

        columns[orgNameColIndex] = {
            ...orgNameColumn,
            filterOperators: orgNameFilterOperators,
        };

        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };

        const statusFlagColumn = columns.find((column) => column.field === columnFieldsName.msgStatusFlag);
        const statusFlagColIndex = columns.findIndex((col) => col.field === columnFieldsName.msgStatusFlag);

        const statusFlagFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.InvoiceeMsgStatusValue,
            }),
        );

        columns[statusFlagColIndex] = {
            ...statusFlagColumn,
            filterOperators: statusFlagFilterOperators,
        };

        const invoiceNoColumn = columns.find((column) => column.field === columnFieldsName.invoiceNo);
        const invoiceNoColIndex = columns.findIndex((col) => col.field === columnFieldsName.invoiceNo);

        const invoiceNoFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.InvoiceNoInputValue,
            }),
        );

        columns[invoiceNoColIndex] = {
            ...invoiceNoColumn,
            filterOperators: invoiceNoFilterOperators,
        };

        const supplierPartyCodeColumn = columns.find((column) => column.field === columnFieldsName.supplierPartyCode);
        const supplierPartyCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.supplierPartyCode);

        const supplierPartyCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.SupplierPartyCodeInputValue,
            }),
        );

        columns[supplierPartyCodeColIndex] = {
            ...supplierPartyCodeColumn,
            filterOperators: supplierPartyCodeFilterOperators,
        };

        const approvedColumn = columns.find((column) => column.field === columnFieldsName.approved);
        const approvedColIndex = columns.findIndex((col) => col.field === columnFieldsName.approved);

        const approvedFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.ApprovedPOInputValue,
            }),
        );

        columns[approvedColIndex] = {
            ...approvedColumn,
            filterOperators: approvedFilterOperators,
        };

        const commTypeColumn = columns.find((column) => column.field === columnFieldsName.messageSentMode);
        const commTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.messageSentMode);

        const commTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.CommTypeLogInputValue,
            }),
        );

        columns[commTypeColIndex] = {
            ...commTypeColumn,
            filterOperators: commTypeFilterOperators,
        };

        const fromDtColumn = columns.find((column) => column.field === columnFieldsName.fromDt);
        const fromDtColIndex = columns.findIndex((col) => col.field === columnFieldsName.fromDt);

        const fromDtFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[fromDtColIndex] = {
            ...fromDtColumn,
            filterOperators: fromDtFilterOperators,
        };

        const toDtColumn = columns.find((column) => column.field === columnFieldsName.toDt);
        const toDtColIndex = columns.findIndex((col) => col.field === columnFieldsName.toDt);

        const toDtFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[toDtColIndex] = {
            ...toDtColumn,
            filterOperators: toDtFilterOperators,
        };

        const msgSentfromDtColumn = columns.find((column) => column.field === columnFieldsName.fromMessageSentTime);
        const msgSentfromDtColIndex = columns.findIndex((col) => col.field === columnFieldsName.fromMessageSentTime);

        const msgSentfromDtFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[msgSentfromDtColIndex] = {
            ...msgSentfromDtColumn,
            filterOperators: msgSentfromDtFilterOperators,
        };

        const msgSenttoDtColumn = columns.find((column) => column.field === columnFieldsName.toMessageSentTime);
        const msgSenttoDtColIndex = columns.findIndex((col) => col.field === columnFieldsName.toMessageSentTime);

        const msgSenttoDtFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[msgSenttoDtColIndex] = {
            ...msgSenttoDtColumn,
            filterOperators: msgSenttoDtFilterOperators,
        };

        const fromServComplDtColumn = columns.find((column) => column.field === columnFieldsName.fromServComplDt);
        const fromServComplDtColIndex = columns.findIndex((col) => col.field === columnFieldsName.fromServComplDt);

        const fromServComplDtFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[fromServComplDtColIndex] = {
            ...fromServComplDtColumn,
            filterOperators: fromServComplDtFilterOperators,
        };

        const toServComplDtColumn = columns.find((column) => column.field === columnFieldsName.toServComplDt);
        const toServComplDtColIndex = columns.findIndex((col) => col.field === columnFieldsName.toServComplDt);

        const toServComplDtFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[toServComplDtColIndex] = {
            ...toServComplDtColumn,
            filterOperators: toServComplDtFilterOperators,
        };

        const buyerNameColumn = columns.find((column) => column.field === columnFieldsName.buyerName);
        const buyerNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.buyerName);

        const buyerNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.CustomerInputValue,
            }),
        );

        columns[buyerNameColIndex] = {
            ...buyerNameColumn,
            filterOperators: buyerNameFilterOperators,
        };
    }

    const getDataBasedOnTheSelectedField = tableData.map((row) => {
        return {
            bpCode: row.bpCode, entityCode: row.entity.erpEntityCode,
            approved: row.approved, msgId: row.msgId
        }
    })

    const filterValueLoad = getDataBasedOnTheSelectedField.filter((item) => {
        if (selectedRow.includes(item.msgId)) {
            return item
        }
    })

    const bpCodeValues = filterValueLoad.map((row) => { return row.bpCode });
    const entittyCodeValue = filterValueLoad.map((row) => { return row.entityCode });
    const filterValueApproved = filterValueLoad.map((row) => { return row.approved })

    function currentlySelectedRow(selections) {
        setSelectedRow(selections)
        if (selections.length === 0) {
            isDisableApproveButton(true);
            isDisableRejectionButton(true);
        } else {
            isDisableApproveButton(false);
            isDisableRejectionButton(false);
        }
    }

    useEffect(() => {
        if (selectedRow.length === 0) {
            isDisableApproveButton(true)
            isDisableRejectionButton(true);
        } else if (filterValueApproved.toString().includes('0')) {
            isDisableApproveButton(false);
            isDisableRejectionButton(false);
        } else if (filterValueApproved.toString().includes('1')) {
            isDisableApproveButton(true);
            isDisableRejectionButton(true);
        } else if (filterValueApproved.toString().includes('2')) {
            isDisableApproveButton(false);
            isDisableRejectionButton(true);
        } else {
            isDisableApproveButton(false);
            isDisableRejectionButton(false);
        }
    }, [filterValueApproved])

    const onSubmit = () => {
        setIsFormSubmitted(true);
        setIsRowSentForApproval(true);
        setOpenBackDrop(true);
        if (filterValueApproved.toString().includes('1')) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains already Approved records", severity: "warning"
            });
            setIsRowSentForApproval(false);
            setIsFormSubmitted(false);
            setOpenBackDrop(false);
        } else {
            CommonService.saveOrUpdateOrPost('/invoiceApprovals', selectedRow)
                .then((response) => {
                    setIsFormSubmitted(false)
                    setIsRowSentForApproval(false)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: `MessageId : ${selectedRow} has been approved successfully`, severity: "success"
                    });
                    setSelectedRow([])
                    setRefreshTable(!refreshTable);
                    isDisableApproveButton(true);
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setIsFormSubmitted(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.message, severity: "error" });
                    isDisableApproveButton(false);
                    setOpenBackDrop(false);
                });
        }
    }

    const onSubmitReject = (data) => {
        setIsFormSubmitted(true);
        setIsRowSentForApproval(true);
        setOpenBackDrop(true);
        if (filterValueApproved.toString().includes('2')) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains already Rejected records", severity: "warning"
            });
            setIsRowSentForApproval(false);
            setIsFormSubmitted(false);
            setOpenBackDrop(false);
        } else if (filterValueApproved.toString().includes('1')) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: "Selected Records contains already Approved records", severity: "warning"
            });
            setIsRowSentForApproval(false);
            setIsFormSubmitted(false);
            setOpenBackDrop(false);
        } else {
            CommonService.saveOrUpdateOrPost('/invoiceRejections', selectedRow)
                .then((response) => {
                    setIsFormSubmitted(false)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: `MessageId : ${selectedRow} has been rejected successfully`, severity: "success"
                    });
                    setSelectedRow([])
                    setRefreshTable(!refreshTable);
                    isDisableApproveButton(true);
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setIsFormSubmitted(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.message, severity: "error" });
                    isDisableApproveButton(false);
                    setOpenBackDrop(false);
                });
        }
    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let buyerName = null;
        let bpCode = null;
        let buyerPartyCode = null;
        let entityCode = null;
        let approved = null;
        let invoiceNo = null;
        let billingCreatedByUserName = null;
        let msgStatusFlag = null;
        let messageSentMode = null;
        let fromServComplDt = null;
        let toServComplDt = null;
        let fromMessageSentTime = null;
        let toMessageSentTime = null;
        let fromInvoiceDt = null;
        let toInvoiceDt = null;
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'buyerName':
                    buyerName = columns.value
                    break;
                case 'buyerPartyCode':
                    buyerPartyCode = columns.value
                    bpCode = columns.value
                    break;
                case 'entity.erpEntityCode':
                    entityCode = columns.value
                    break;
                case 'approved':
                    approved = columns.value
                    break;
                case 'msgStatusFlag':
                    msgStatusFlag = columns.value
                    break;
                case 'invoiceNo':
                    invoiceNo = columns.value
                    break;
                case 'billingCreatedByUserName':
                    billingCreatedByUserName = columns.value
                    break;
                case 'messageSentMode':
                    messageSentMode = columns.value
                    break;
                case 'fromMessageSentTime':
                    fromMessageSentTime = columns.value
                    break;
                case 'toMessageSentTime':
                    toMessageSentTime = columns.value
                    break;
                case 'fromServComplDt':
                    fromServComplDt = columns.value
                    break;
                case 'toServComplDt':
                    toServComplDt = columns.value
                    break;
                case 'fromDt':
                    fromInvoiceDt = columns.value
                    break;
                case 'toDt':
                    toInvoiceDt = columns.value
                    break;
            }
        })

        setSearchedErpEntityCode(entityCode);
        setSearchedApprovedRecordsRecords(approved);
        setSearchedBpCode(bpCode);
        setSearchedCommType(messageSentMode);
        setSearchedInvoiceNo(invoiceNo);
        setSearchedBuyerPartyCode(buyerPartyCode);
        setSearchedBuyerName(buyerName);
        setSearchedFromDt(fromInvoiceDt);
        setSearchedToDt(toInvoiceDt);
        setSearchMsgStatus(msgStatusFlag)
        setSearchedFromServComplDt(fromServComplDt);
        setSearchedToServComplDt(toServComplDt);
        setSearchedMsgSentToDt(toMessageSentTime);
        setSearchedMsgSentFromDt(fromMessageSentTime)
        setSearchedBillingCreatedByUserName(billingCreatedByUserName)

        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);

        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    bpCode: bpCode,
                    approved: approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : '',
                    recordApprovedStatus: approved === '' ? 'All' : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : 'All',
                    recordStatusFlag: msgStatusFlag === '' ? 'All' : msgStatusFlag === undefined ? 'All' : msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : 'All',
                    messageSentMode: messageSentMode,
                    msgStatusFlag: msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : '',
                    invoiceNo: invoiceNo,
                    buyerPartyCode: buyerPartyCode,
                    buyerName: buyerName,
                    fromServComplDt: fromServComplDt,
                    toServComplDt: toServComplDt,
                    fromMessageSentTime: fromMessageSentTime,
                    toMessageSentTime: toMessageSentTime,
                    billingCreatedByUserName: billingCreatedByUserName,
                    fromDt: fromInvoiceDt,
                    toDt: toInvoiceDt,
                    entity: { erpEntityCode: entityCode },
                }
            }
        })
    }

    useEffect(() => {
        if (intialParamBodyApiCall)
            setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])


    let filterBodyObj = {
        entityCode: searchedErpEntityCode === null ? "" : searchedErpEntityCode,
        bpType: searchedBpType === "" ? null : searchedBpType,
        bpCode: searchedBpCode === "" ? null : searchedBpCode,
        approved: searchedApprovedRecordsRecords === 'Not Approved' ? 0 : searchedApprovedRecordsRecords === 'Approved' ? 1 : searchedApprovedRecordsRecords === 'Rejected' ? 2 : null,
        invoiceNo: searchedInvoiceNo === "" ? null : searchedInvoiceNo,
        buyerPartyCode: searchedBuyerPartyCode === "" ? null : searchedBuyerPartyCode,
        buyerName: searchedBuyerName === "" ? null : searchedBuyerName,
        supplierPartyCode: searchedSupplierPartyCode === "" ? null : searchedSupplierPartyCode,
        supplierName: searchedSupplierName === "" ? null : searchedSupplierName,
        fromInvoiceDt: searchedFromDt === "" ? null : searchedFromDt,
        toInvoiceDt: searchedToDt === "" ? null : searchedToDt,
        fromServComplDt: searchedFromServComplDt === "" ? null : searchedFromServComplDt,
        toServComplDt: searchedToServComplDt === "" ? null : searchedToServComplDt,
        fromMessageSentTime: searchedMsgSentFromDt === "" ? null : searchedMsgSentFromDt,
        toMessageSentTime: searchedMsgSentToDt === "" ? null : searchedMsgSentToDt,
        messageSentMode: searchedCommType === "" ? null : searchedCommType,
        msgStatusFlag: searchedMsgStatus === 'Not Sent' ? 0 : searchedMsgStatus === 'Pending' ? 1 : searchedMsgStatus === 'Successfully Sent' ? 2 : searchedMsgStatus === 'Failed to Send' ? 3 : searchedMsgStatus === 'Cancelled' ? 4 : null,
        billingCreatedByUserName: searchedBillingCreatedByUserName === "" ? null : searchedBillingCreatedByUserName
    }

    useEffect(() => {
        let colValDataObj = {}
        for (let key in filterBodyObj) {
            let value = filterBodyObj[key]
            if (filterBodyObj.hasOwnProperty(key) && ((value !== null) && (value !== undefined) && (value !== {}))) {
                colValDataObj[key] = value.toString();
            }
            setKeyValueData(colValDataObj)
        }
    }, [searchedErpEntityCode, searchedBpType, searchedBpCode, searchedApprovedRecordsRecords, searchedInvoiceNo, searchedBuyerPartyCode, searchedBuyerName, searchedSupplierPartyCode, searchedSupplierName, searchedFromDt, searchedToDt, searchedFromServComplDt, searchedToServComplDt, searchedMsgSentFromDt, searchedMsgSentToDt, searchedMsgStatus, searchedCommType, searchedBillingCreatedByUserName]);

    useEffect(() => {
        if (msgHdrPopUp === true) {
            setOpenBackDrop(true);
            CommonService.saveOrUpdateOrPost('/reports/SYS_INVOICE_REPORT', searchedErpEntityCode === '' ? { entityCode: '' } : keyValueData)
                .then((response) => {
                    if (response.data.sucess === "No records found for given condition")
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.sucess, severity: "error" });
                    else {
                        setAlertBoxState({
                            ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success"
                        });
                    }
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgHdrPopUp(false)
        }
    }, [msgHdrPopUp, keyValueData]);

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <CommonReportDownloadForMsg open={openReportDownloadDialog} bpType={bpTypeValue} bpCode={bpCodeValues} entityCode={entittyCodeValue} proccessValue={{ processId: 5, processName: 'Invoice' }}
                onClose={closeReportDownloadDialog} msgIdvalue={selectedRow} />
            <Grid container className={classes.rootForRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.topGrid} style={{ marginLeft: '-0.5%' }}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <ChipInfoList chipInfoList={chipInfoList} />
                <Paper elevation={3} style={{ marginTop: '5px' }} className={classes.gridHeightAndWidthForASN}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            getRowId={(row) => row.id}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            density="compact"
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            isRowSelectable={(params) => true}
                            hideFooter={true}
                            checkboxSelection={true}
                            onSelectionModelChange={currentlySelectedRow}
                            selectionModel={selectedRow}
                            localeText={localtext}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" />}
                                                        placement='bottom'>
                                                        <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterList />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.approveselectedrecords.tooltip" defaultMessage="Approve selected records" />} placement='bottom'>
                                                        <span>
                                                            {(invoiceModuleAccess !== undefined ? invoiceModuleAccess.editFlag || invoiceModuleAccess.createFlag : false) && (
                                                                <Button size="small" color="primary" variant="outlined"
                                                                    className={classes.refreshToobarStyle}
                                                                    onClick={onSubmit}
                                                                    disabled={disableApproveButton || isFormSubmitted}
                                                                >
                                                                    <IconApproval size={20} />
                                                                </Button>
                                                            )}
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.rejectselectedrecords.tooltip" defaultMessage="Reject selected records" />} placement='bottom'>
                                                        <span>
                                                            {(invoiceModuleAccess !== undefined ? invoiceModuleAccess.editFlag || invoiceModuleAccess.createFlag : false) && (
                                                                <Button size="small" color="primary" variant="outlined"
                                                                    className={classes.refreshToobarStyle}
                                                                    onClick={onSubmitReject}
                                                                    disabled={disableRejectionButton || isFormSubmitted}
                                                                >
                                                                    <CancelIcon size={20} />
                                                                </Button>
                                                            )}
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadExcelForMsg" />}
                                                        placement='bottom'>
                                                        <span>
                                                            {(invoiceModuleAccess !== undefined ? invoiceModuleAccess.readFlag : false) && (
                                                                <Button size="small" color="primary" variant="outlined" className={classes.refreshToobarStyle}
                                                                    onClick={() => {
                                                                        setMsgHdrPopUp(true)
                                                                    }}
                                                                    disabled={disiablingDownloadButtonForZeroRecords}
                                                                >
                                                                    <GetAppIcon size={20} />
                                                                </Button>
                                                            )}
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    {(<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadReport" />}
                                                        placement='bottom' className={classes.actionButton} onClick={() => {
                                                            setOpenReportDownloadDialog(true);
                                                        }}>
                                                        <span>
                                                            <Button size='small' className={classes.refreshToobarStyle} color="primary" variant="outlined"
                                                                component="span" disabled={selectedRow.length === 0 ? true : false}
                                                                onClick={() => {
                                                                    setOpenReportDownloadDialog(true);
                                                                }}>
                                                                <CustomReportIcon />
                                                            </Button>
                                                            {/* <IconButton color="secondary" size="large"> */}
                                                            {/* <CustomReportIcon /> */}
                                                            {/* </IconButton> */}
                                                        </span>
                                                    </Tooltip>)}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            hideFooterSelectedRowCount={true}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length - 1, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length - 1, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length - 1, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                --params.oldIndex;
                                --params.targetIndex;
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {
                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </div>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}

export default InvoiceHeader;

