import { Button, CircularProgress, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, Snackbar, Switch, Typography } from '@mui/material';
import { Alert } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useStyles from '../CommonStyle/CommonStyle';
import ClearIcon from '@mui/icons-material/Clear';
import { Controller, useForm } from 'react-hook-form';
import EmailRptTmplCombo from '../Combos/EmailRptTmplCombo/EmailRptTmplCombo';
import DelimitersCombo from '../Combos/DelimitersCombo/DelimitersCombo';
import { GetApp } from '@mui/icons-material';
import CustomReportIcon from '@mui/icons-material/Archive';
import { CommonService } from '../../../services';
import saveAs from 'file-saver';
import FileTypeForCustomReports from '../Combos/FileTypeForCustomReports';

const CommonReportDownloadForMsg = (props) => {
    const classes = useStyles();
    const intl = useIntl();

    const { open, bpCode, entityCode, proccessValue, onClose, msgIdvalue } = props;
    const [emailReportTemplateValue, setEmailReportTemplateValue] = useState({ name: '', rptTmplId: 0 });
    const [msgTypeValue, setMsgTypeValue] = useState('');
    const [delimiters, setDelimiters] = useState('');
    const [headerReqdToBeEdited, setHeaderReqdToBeEdited] = useState('');
    const [reportDownloadPopup, setReportDownloadPopup] = useState(false);
    const [isDownlaodRequested, setIsDownloadRequested] = useState(false);

    const pageLabelsConstantsAndMessages = {
        name: {
            reportName: 'reportName',
            fileType: 'fileType',
            delimiters: 'delimiters',
            headerReqd: 'headerReqd'
        }
    }

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });

    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };

    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const handleClose = (value) => {
        onClose(value);
        setEmailReportTemplateValue({ name: '', rptTmplId: 0 });
        setMsgTypeValue({});
        setDelimiters({});
        setHeaderReqdToBeEdited({})
    }

    const { formState: { errors }, control } = useForm({
        mode: "all",
        reValidateMode: 'all',
        defaultValues: {
            reportName: '',
            fileType: '',
            headerReqd: true,
        }
    });

    const bpCodeKey = "bpCode";
    const entityCodeKey = "entityCode";

    const bpCodeFilteredValue = [...new Map(bpCode.map((col) => { return { bpCode: col } }).map(item => [item[bpCodeKey], item])).values()].map((col) => col.bpCode);

    const entityCodeFilteredValue = [...new Map(entityCode.map((col) => { return { entityCode: col } }).map(item => [item[entityCodeKey], item])).values()].map((col) => col.entityCode);

    useEffect(() => {
        if (open) {
            CommonService.GetListApi('/process/messageConfigDtls', {
                body: {
                    processName: proccessValue.processName,
                    messageConfigHdr: {
                        bpCode: bpCodeFilteredValue.toString(),
                        entity: {
                            erpEntityCode: entityCodeFilteredValue.toString()
                        }
                    },
                    processNameEqualValidaterReq: true,
                    approvalStatus: 'All',
                },
                pageNo: 0,
                sortDir: 'desc',
                sortKey: 'updatedTime',
                recordsPerPage: 25,
            })
                .then((response) => {
                    setEmailReportTemplateValue({ name: response.data !== undefined ? response.data.reportTemplate.name : '', rptTmplId: response.data !== undefined ? response.data.reportTemplate.rptTmplId : 0 })
                    setMsgTypeValue({ value: response.data !== undefined ? response.data.msgType : '' })
                    setDelimiters({ value: response.data !== undefined ? response.data.delimiters : '' })
                    setHeaderReqdToBeEdited({ headerReqd: response.data !== undefined ? response.data.headerReqd : '' })
                })
                .catch(function (error) {

                });
        }
    }, [proccessValue, open]);

    const keyValueData = {
        msgId: msgIdvalue.toString().replace("'", ""),
        rptTmplId: emailReportTemplateValue === null ? '' : emailReportTemplateValue.rptTmplId,
        reportName: emailReportTemplateValue === null ? '' : emailReportTemplateValue.name,
        fileType: msgTypeValue.value,
        delimiters: delimiters.value,
        process: proccessValue.processName,
        headerReqd: headerReqdToBeEdited.headerReqd
    }

    useEffect(() => {
        if (reportDownloadPopup === true) {
            setIsDownloadRequested(true);
            CommonService.saveOrUpdateOrPost('/customeReports', keyValueData)
                .then((response) => {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success" });
                    setIsDownloadRequested(false);
                })
                .catch(function (error) {
                    setIsDownloadRequested(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                });
            setReportDownloadPopup(false)
        }
    }, [reportDownloadPopup, keyValueData]);

    const showDownloadPopUpToDownlaodReport = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }

    const emailReportTemplateComboProperty = {
        name: pageLabelsConstantsAndMessages.name.reportName,
        isRequired: false,
        label: intl.formatMessage({ id: 'common.textfield.emailreporttemplate' }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'outlined',
        isHookFormType: true,
    }

    const messageTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.fileType,
        isRequired: false,
        label: intl.formatMessage({ id: "columnname.fileType.label" }),
        isAutofocus: false,
        isDisabled: false,
        variant: 'outlined',
        isHookFormType: true,
    }

    const delimiterComboProperty = {
        name: pageLabelsConstantsAndMessages.name.delimiters,
        isRequired: false,
        label: intl.formatMessage({ id: "columnname.delimiter.label" }),
        isAutofocus: false,
        isDisabled: ((msgTypeValue.value === 'CSV' || msgTypeValue.value === 'TXT')) ? false : true,
        variant: 'outlined',
        isHookFormType: true,
    }

    useEffect(() => {
        if (msgTypeValue.value === 'EXCEL') {
            setDelimiters({ value: '' });
        }
    }, [msgTypeValue])

    const resetComboValueForFileType = () => {
        setDelimiters({});
    }

    const resetComboValueForDelimter = () => {
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Dialog fullWidth open={open}>
                <DialogTitle color='primary' className={classes.title}>
                    <Grid container justifyContent='flex-start' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                        <CustomReportIcon />
                        <Typography style={{ marginLeft: '10px' }}><FormattedMessage id="common.emailreportdownload.txt" /></Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers style={{ minHeight: '25vh' }}>
                    <Grid item container alignItems='center' xs={12} sm={12} md={12} lg={12} spacing={2}>
                        <Grid item container alignItems='center' xs={12} sm={12} md={12} lg={12} spacing={2}>
                            <EmailRptTmplCombo
                                comboProperty={emailReportTemplateComboProperty}
                                control={control} errors={errors}
                                comboValue={emailReportTemplateValue}
                                setComboValue={setEmailReportTemplateValue}
                                processNameValue={proccessValue}
                                branchValue={entityCode}
                            />
                        </Grid>
                        <Grid item container alignItems='center' xs={12} sm={12} md={4} lg={4} spacing={2} >
                            <FileTypeForCustomReports
                                comboProperty={messageTypeComboProperty}
                                control={control} errors={errors}
                                comboValue={msgTypeValue}
                                setComboValue={setMsgTypeValue}
                                processMasterValue={proccessValue}
                                resetComboValueForFileType={resetComboValueForFileType}
                            />
                        </Grid>
                        <Grid item container xs={12} sm={12} md={4} lg={4} spacing={2}>
                            <DelimitersCombo
                                comboProperty={delimiterComboProperty}
                                control={control} errors={errors}
                                comboValue={delimiters}
                                setComboValue={setDelimiters}
                                resetComboValueForDelimter={resetComboValueForDelimter}
                            />
                        </Grid>
                        <Grid item container xs={12} sm={12} md={4} lg={4} spacing={2} style={{ marginTop: '0.1%' }}>
                            <Grid container justifyContent='flex-start' alignItems='center'>
                                <Controller
                                    render={({
                                        field: { onChange } }) => (<Switch
                                            color='primary'
                                            checked={headerReqdToBeEdited.headerReqd !== undefined ? headerReqdToBeEdited.headerReqd : false}
                                            onChange={((event) => {
                                                onChange(!(headerReqdToBeEdited.headerReqd !== undefined ? headerReqdToBeEdited.headerReqd : false));
                                                setHeaderReqdToBeEdited({ ...headerReqdToBeEdited, headerReqd: event.target.checked })
                                            })}
                                        />)}
                                    type="checkbox"
                                    name={pageLabelsConstantsAndMessages.name.headerReqd}
                                    control={control}
                                />
                                <InputLabel>{<FormattedMessage id="common.InclHdrDetails.lable" defaultMessage="Include Header Details" />}</InputLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActionForFileUploading}>
                    <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                        <Button size='small' variant='contained' color='secondary' className={classes.buttonSpacing}
                            startIcon={<ClearIcon />}
                            onClick={(() => {
                                handleClose(false);
                            })}><FormattedMessage id="common.button.cancel.lable" />
                        </Button>
                        <Button size='small' variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                            startIcon={<GetApp />}
                            onClick={() => {
                                setReportDownloadPopup(true)
                            }}
                            disabled={isDownlaodRequested}
                            endIcon={isDownlaodRequested ? <CircularProgress color="secondary" size={20} /> : null}
                        >
                            <FormattedMessage id="common.download.tooltip" />
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

export default CommonReportDownloadForMsg;