import {
    Button, Chip, CssBaseline, Grid, Paper, Snackbar, Tooltip, Typography, Accordion, AccordionSummary, AccordionDetails, List,
    ListItem, Divider, CircularProgress, useTheme, useMediaQuery, IconButton
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Alert, ToggleButton, ToggleButtonGroup } from '@mui/lab';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CommonBackDrop from '../common/BackDrop';
import CustomBreadCrumb from '../common/CustomBreadCrumb';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconBack from '@mui/icons-material/BackspaceOutlined';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import { GridToolbarColumnsButton, GridToolbarDensitySelector, DataGridPro } from '@mui/x-data-grid-pro';
import { CommonService } from '../../services';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableChartIcon from '@mui/icons-material/TableChart';
import ListIcon from '@mui/icons-material/List';
import ViewIcon from '@mui/icons-material/Visibility';
import useStyles from '../common/CommonStyle/CommonStyle'
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleAccessPermissionKey } from '../../Constants/ModuleAccessKey';
import IconMessage from '@mui/icons-material/Forum';
import saveAs from 'file-saver';
import GetAppIcon from '@mui/icons-material/GetApp';
import CommonGridPagination from '../common/CommonGridPagination/CommonGridPagination';
import { SupportedLanguageDetails } from '../common/SupportedLanguageDetails/SupportedLanguageDetails';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import IconAdvanceShipmentNoteOutbound from '@mui/icons-material/Receipt';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';
import AdvanceShipmentNoteInboundBoxDtl from './AdvanceShipmentNoteInboundBoxDtl/AdvanceShipmentNoteInboundBoxDtl';
const StyledToggleButton = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
    },
    selected: {
        backgroundColor: "#d8d8d8",
    }

}))(ToggleButton);

const columnHeaderNames = {
    orderNumber: 'orderNumber',
    orderLineNumber: 'orderLineNumber',
    customerPartNumber: 'customerPartNumber',
    supplierPartNno: 'supplierPartNno',
    buyerPartNo: 'buyerPartNo',
    makerPartNumber: 'makerPartNumber',
    materialManfDt: 'materialManfDt',
    salesOrderNumber: 'salesOrderNumber',
    materialDescription: 'materialDescription',
    materialShortDescription: 'materialShortDescription',
    uom: 'uom',
    palletBarcode: 'palletBarcode',
    packageType: 'packageType',
    palletGrossWeight: 'palletGrossWeight',
    palletNetWeight: 'palletNetWeight',
    palletHeight: 'palletHeight',
    palletLength: 'palletLength',
    palletWide: 'palletWide',
    palletCapacity: 'palletCapacity',
    palletQty: 'palletQty',
    materialQty: 'materialQty',
    packingListNo: 'packingListNo',
    caseMarkLine1: 'caseMarkLine1',
    caseMarkLine2: 'caseMarkLine2',
    caseMarkLine3: 'caseMarkLine3',
    caseMarkLine4: 'caseMarkLine4',
    caseMarkLine5: 'caseMarkLine5',
    caseMarkLine6: 'caseMarkLine6',
    caseMarkLine7: 'caseMarkLine7',
    caseMarkLine8: 'caseMarkLine8',
    attribute1: 'attribute1',
    attribute2: 'attribute2',
    attribute3: 'attribute3',
    attribute4: 'attribute4',
    attribute5: 'attribute5',
    attribute6: 'attribute6',
    attribute7: 'attribute7',
    attribute8: 'attribute8',
    attribute9: 'attribute9',
    attribute10: 'attribute10',
    attribute10: 'attribute11',
    attribute10: 'attribute12',
    attribute10: 'attribute13',
    attribute10: 'attribute14',
    attribute10: 'attribute15',
    latestDataSource: 'latestDataSource',
    batchId: 'batchId',
};

const AdvanceShipmentNoteInboundDtls = (props) => {

    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const { mode, msgHdrId } = props.location.state;
    const smallScreen = useMediaQuery(theme.breakpoints.down(889))
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const asnModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.advanceShipmentNoteInbound];
    const [disableDownloadButton] = useState(true)
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const pageLabelsConstantsAndMessages = {
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    }
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [openScheduleGridDialog, setOpenScheduleGridDialog] = useState(false);
    const closeScheduleGridDialog = () => {
        setOpenScheduleGridDialog(false);
    }
    const [tableData, setTableData] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [msgDtlValue, setMsgDtlValue] = useState('');

    const [msgHdrIdValue, setMsgHdrId] = useState('');
    const [orderLineNumber, setOrderLineNumber] = useState('');
    const [orderNumber, setOrderNumber] = useState('');

    const [columns, setColumns] = useState(0);

    const commonColumnsForAsnEditView = [
        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: 100,
            sortable: false,
            hide: false,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    <Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" />}
                        placement='bottom' className={classes.actionButton} onClick={(() => {
                            setMsgDtlValue(cellParams.row.messageDtlsId);
                            setOpenScheduleGridDialog(true);
                            setMsgHdrId(cellParams.row.msgHdrId);
                            setOrderLineNumber(cellParams.row.orderLineNumber);
                            setOrderNumber(cellParams.row.orderNumber);
                        })}>
                        <span>
                            <IconButton className={classes.customeToolbar} size="large">
                                <ViewIcon className={classes.primaryColor} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            )
        },

        {
            field: columnHeaderNames.orderNumber,
            width: 190,
            headerName: intl.formatMessage({ id: "columnname.orderNumber.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.orderLineNumber,
            headerName: <FormattedMessage id="columnname.orderLineNumber.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.customerPartNumber,
            headerName: <FormattedMessage id="columnname.customerPartNumber.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 170,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.supplierPartNno,
            headerName: <FormattedMessage id="columnname.supplierPartNo.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 170,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.buyerPartNo,
            headerName: <FormattedMessage id="columnname.buyerPartNo.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 170,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.makerPartNumber,
            headerName: <FormattedMessage id="columnname.markerpartnotable.label" defaultMessage='Maker Part No' />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.palletBarcode,
            headerName: intl.formatMessage({ id: "columnname.palletBarcodeNo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.packageType,
            headerName: <FormattedMessage id="columnname.packageType.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.palletGrossWeight,
            headerName: <FormattedMessage id="columnname. palletGrossWeight.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.palletHeight,
            headerName: <FormattedMessage id="columnname.palletHeight.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.palletLength,
            headerName: <FormattedMessage id="columnname.palletLength.label" defaultMessage={columnHeaderNames.uom} />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.palletCapacity,
            headerName: <FormattedMessage id="columnname.palletCapacity.label" defaultMessage={columnHeaderNames.unitPrice} />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.palletQty,
            headerName: intl.formatMessage({ id: "columnname.palletQty.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.materialQty,
            headerName: intl.formatMessage({ id: "columnname.materialQuantity.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.packingListNo,
            headerName: intl.formatMessage({ id: "columnname.packingListNumber.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },


    ]

    useEffect(() => {
        setColumns(commonColumnsForAsnEditView);
    }, [selectedLanguage]);

    const [tableLoadingStatus, setTableLoadingStatus] = useState(true);

    const [paramBody, setParamBody] = useState(
        {
            body: {

            },
            pageNo: 0,
            sortDir: 'asc',
            sortKey: 'messageDtlsId',
            recordsPerPage: 25,
        }
    );

    const [refreshFlag, setRefreshFlag] = useState(false);
    const [componentList, setComponentList] = useState([]);
    const [disableRefreshButton] = useState(false);
    const [asnToBeEdited, setAsnToBeEdited] = useState([]);
    const [msgDtlsPopUp, setMsgDtlsPopUp] = useState(false);

    useEffect(() => {
        let currentRouterComponent;
        currentRouterComponent = {
            path: undefined,
            name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage='View' />,
            iconName: ViewIcon,
        };
        setComponentList([
            {
                path: undefined,
                name: <FormattedMessage id="common.menu.breadcrumb.message" />,
                iconName: IconMessage,
            },
            {
                path: '/advanceShipmentNoteInbound',
                name: <FormattedMessage id="common.module.breadcrumb.advanceshipmentnoteinbound" defaultMessage="Advance ShipmentNote Inbound" />,
                iconName: IconAdvanceShipmentNoteOutbound,
            },
            currentRouterComponent
        ])
    }, [mode, msgHdrId, refreshFlag]);

    const [asnDetailItemViewType, setAsnDetailItemViewType] = React.useState('list');

    const handleToggleChange = (event, newAlignment) => {
        setAsnDetailItemViewType(newAlignment);
    };


    useEffect(() => {
        setOpenBackDrop(true);
        CommonService.getByIdApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.advanceShipmentNoteInboundHdrs, msgHdrId)
            .then((response) => {
                setTableLoadingStatus(false);
                setAsnToBeEdited(response.data);
                setOpenBackDrop(false);
            })
            .catch(function (error) {
                setTableLoadingStatus(false);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
                setOpenBackDrop(false);
            });
    }, [refreshFlag, msgHdrId]);

    useEffect(() => {
        setTableLoadingStatus(true);
        setTableData([]);
        setOpenBackDrop(true);
        if (mode === 'view') {
            CommonService.GetListApi(`/advanceShipmentNoteInboundHdr/${msgHdrId}/advanceShipmentNoteInboundDtls`, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTableData(response.data.content)
                    setTotalRowCount(response.data.totalElements);
                    // setDisablingButtons(response.data.content[0].asnHdr.approved)
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    setOpenBackDrop(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                    setOpenBackDrop(false);
                });
        }
    }, [mode, paramBody, refreshFlag])

    useEffect(() => {
        if (msgDtlsPopUp === true) {
            setOpenBackDrop(true);
            let messageId = { msgHdrId: msgHdrId.toString() };
            CommonService.saveOrUpdateOrPost('/reports/SYS_PO_DTL', messageId)
                .then((response) => {
                    showDownloadPopUpExcelDtls(response.data.fileUrl)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />, severity: "success"
                    });
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgDtlsPopUp(false)
        }
    }, [msgHdrId, msgDtlsPopUp])

    const showDownloadPopUpExcelDtls = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            })
    }



    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForEditPurchaseOrder}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' alignItems='center' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <Button size='small'
                        variant="contained" color="primary" className={classes.stateButton} elevation={2}
                        endIcon={smallScreen ? null : <IconBack />} onClick={history.goBack}
                    >{smallScreen ? <IconBack /> : <FormattedMessage id="common.button.back.lable" />}</Button>
                    <Button size='small' disabled={disableRefreshButton}
                        variant="contained"
                        color="secondary"
                        className={classes.stateButtonReferesh}
                        elevation={2}
                        endIcon={smallScreen ? null : <IconRestore />} onClick={(() => { setRefreshFlag(!refreshFlag) })}
                    >{smallScreen ? <IconRestore /> : <FormattedMessage id="common.button.refresh.tooltip" />} </Button>
                    {(asnModuleAccess !== undefined ? asnModuleAccess.createFlag || asnModuleAccess.editFlag : false) && (
                        <Button size='small'
                            disabled={disableDownloadButton}
                            variant="contained"
                            color="primary"
                            className={classes.stateButton}
                            elevation={2}
                            elevation={2}
                            onClick={() => { setMsgDtlsPopUp(true) }}
                        > <GetAppIcon /> </Button>
                    )}

                </Grid>
                <Paper className={classes.paperForEditPurchaseOrder} elevation={3}>
                    <form className={classes.formForPurchaseOrder}>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                            >
                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={1}>
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id="messageHdrId.chip.text" values={{ asnToBeEdited: `${(asnToBeEdited !== undefined ? asnToBeEdited.msgHdrId : '')}` }} />}
                                        className={classes.chipForPO}
                                        color='secondary'
                                    />
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id="msgReferenceDateASN.chip.text" values={{ asnToBeEdited: `${(asnToBeEdited !== undefined ? asnToBeEdited.messageReceivedTimeDisp !== null ? asnToBeEdited.messageReceivedTimeDisp : '' : '')}` }} />}
                                        className={classes.chipTheme}
                                        color='primary'
                                    />
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id="msgRcvModeASN.chip.text" values={{ asnToBeEdited: `${(asnToBeEdited !== undefined ? asnToBeEdited.msgRcvMode === 0 ? 'AS2' : 'VAN' : '')}` }} />}
                                        className={classes.chipForPO}
                                        color='secondary'
                                    />
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id="noLineItemASN.chip.text" values={{ asnToBeEdited: `${tableData.length}` }} />}
                                        className={classes.chipTheme}
                                        color='secondary'
                                    />
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id="approvedASN.chip.text" values={{ asnToBeEdited: `${(asnToBeEdited !== undefined ? asnToBeEdited.approved === 1 ? 'Approved' : asnToBeEdited.approved === 2 ? 'Rejected' : 'Not Approved' : '')}` }} />}
                                        className={classes.chipTheme}
                                        color='primary'
                                    />
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <Grid item container xs={6} sm={6} md={12} lg={12} spacing={2} className={classes.details}>
                                    <Grid item container justifyContent='center' alignItems='baseline' xs={6} sm={6} md={6} lg={6} className={classes.helperForAccordian}>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <Typography variant='h6'><strong><FormattedMessage id="buyerdetails.label" defaultMessage="Buyer Details" /></strong></Typography>
                                        </Grid>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <div>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="name.accordian.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(asnToBeEdited !== undefined ? asnToBeEdited.buyerName : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="richtexteditor.blocktype.code.text" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(asnToBeEdited !== undefined ? asnToBeEdited.buyerPartyCode : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="columnname.buyerAddress.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(asnToBeEdited !== undefined ? asnToBeEdited.buyerAddress : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent='center' alignItems='baseline' xs={12} sm={12} md={6} lg={6} className={classes.helperForPO}>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <Typography variant='h6'><strong><FormattedMessage id="supplierdetails" defaultMessage="Supplier Details" /></strong></Typography>
                                        </Grid>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <div>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="name.accordian.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(asnToBeEdited !== undefined ? asnToBeEdited.supplierName : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="richtexteditor.blocktype.code.text" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(asnToBeEdited !== undefined ? asnToBeEdited.bpCode : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="columnname.supplierAddress.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(asnToBeEdited !== undefined ? asnToBeEdited.sellerAddress : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </form>
                </Paper>
                <Paper className={classes.paperForEditPurchaseOrder} elevation={3}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{ padding: '5px 10px' }}>
                        <Grid item container justifyContent="flex-start" alignItems="center" xs={6} sm={6} md={6} lg={6}>
                            <Typography component="span" variant="h5" color="textPrimary">
                                <FormattedMessage id="itemdetails.label" />
                            </Typography>
                        </Grid>
                        <Grid item container justifyContent="flex-end" alignItems="center" xs={6} sm={6} md={6} lg={6}>
                            <ToggleButtonGroup size="small" value={asnDetailItemViewType} exclusive onChange={handleToggleChange}>
                                <StyledToggleButton value="list">
                                    <Tooltip title={<FormattedMessage id="listview.tooltip" defaultMessage='List View' />} placement='bottom'>
                                        <ListIcon fontSize="small" />
                                    </Tooltip>
                                </StyledToggleButton>
                                <StyledToggleButton value="table">
                                    <Tooltip title={<FormattedMessage id="tableview.tooltip" />} placement='bottom'>
                                        <TableChartIcon fontSize="small" />
                                    </Tooltip>
                                </StyledToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    {(asnDetailItemViewType === 'table') && (
                        <Paper elevation={3} className={classes.gridHeightAndWidthForPOPFTableView}>
                            <div style={{ width: '100%', height: '100%' }}>
                                <DataGridPro
                                    className={classes.customTableStyle}
                                    pageSize={paramBody.recordsPerPage}
                                    loading={tableLoadingStatus}
                                    rows={tableData}
                                    columns={columns}
                                    rowCount={totalRowCount}
                                    getRowId={(row) => row.id}
                                    scrollbarSize={30}
                                    disableColumnFilter={true}
                                    rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                                    pagination
                                    density="compact"
                                    paginationMode="server"
                                    hideFooter={true}
                                    disableSelectionOnClick={true}
                                    disableMultipleColumnsSorting={true}
                                    disableColumnMenu={false}
                                    localeText={localtext}
                                    onPageSizeChange={((pageParams) => {
                                        setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                                    })}
                                    onPageChange={((pageParams) => {
                                        setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                                    })}
                                    components={{
                                        Toolbar: () => {
                                            return (
                                                <Grid container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                                    <Grid
                                                        item
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}>
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" />} placement='bottom'>
                                                                <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.density.tooltip" />} placement='bottom'>
                                                                <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <CommonGridPagination />
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                    }}
                                />
                            </div>
                        </Paper>
                    )}
                    {(asnDetailItemViewType === 'list') && (
                        <div>
                            <Divider />
                            <List style={{ padding: '0 10px' }}>
                                {tableData.map((row, index) => {
                                    return (
                                        <Paper elevation={5} style={{ marginBottom: '5px', width: '100%' }}>
                                            <ListItem dense key={index} className={classes.helperForPos}>

                                                <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={3}
                                                    className={classes.helperForDetail}>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.palletBarcode.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.palletBarcode}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.palletQty.label" defaultMessage={columnHeaderNames.palletQty} />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.palletQty}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.palletCapacity.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.palletCapacity}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.palletNetWeight.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.palletNetWeight}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.palletHeight.label" defaultMessage={columnHeaderNames.palletHeight} />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.palletHeight}
                                                                    </Typography>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </Grid>
                                                <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={3}
                                                    className={classes.helperForDetail}>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.packageType.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.packageType}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.palletLength.label" defaultMessage={columnHeaderNames.palletLength} />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.palletLength}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.palletWide.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.palletWide}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.packingListNumber.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.packingListNo}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.uom.label" defaultMessage={columnHeaderNames.buyerPartNo} />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.uom}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Grid>
                                                <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={3}
                                                    className={classes.helperForDetail}>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.orderNumber.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.orderNumber}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.orderLineNumber.label" defaultMessage={columnHeaderNames.palletQty} />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.orderLineNumber}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.makerPartNumber.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.makerPartNumber}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.supplierPartNno.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.supplierPartNno}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.buyerPartNo.label" defaultMessage={columnHeaderNames.buyerPartNo} />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.buyerPartNo}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Grid>
                                                <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={3}
                                                    className={classes.helperForDetail}>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.materialQtyASN.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' display='block' className={classes.boldHeader}>
                                                                        {row.materialQty}</Typography>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.materialShortDescription.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        {row.materialShortDescription}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.materialDescription.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        {row.materialDescription}</Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.materialManfDt.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        {row.materialManfDt}</Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.boxDetails.label" defaultMessage={columnHeaderNames.palletHeight} />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td><Tooltip title={<FormattedMessage id="viewBoxDetails.tooltip" />}
                                                                    placement='bottom' className={classes.actionButton} onClick={(() => {
                                                                        setMsgDtlValue(row.messageDtlsId)
                                                                        setOpenScheduleGridDialog(true);
                                                                        setMsgHdrId(row.msgHdrId);
                                                                        // setAsnNumber(row.poNo);
                                                                        setOrderLineNumber(row.orderLineNumber);
                                                                        setOrderNumber(row.orderNumber);
                                                                    })}>
                                                                    <ViewIcon className={classes.primaryColor} />
                                                                </Tooltip>
                                                                </td>
                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </Grid>
                                                <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={3}
                                                    className={classes.helperForDetail}>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.customerPartNo.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        {row.customerPartNumber}</Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.caseMarkLine1.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        {row.caseMarkLine1}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.caseMarkLine2.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        {row.caseMarkLine2}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.caseMarkLine3.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        {row.caseMarkLine3}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Grid>
                                                <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={2} >
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.caseMarkLine4.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        {row.caseMarkLine4}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.caseMarkLine5.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' className={classes.boldHeader}>
                                                                        {row.caseMarkLine5}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.caseMarkLine6.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.caseMarkLine6}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.caseMarkLine7.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.caseMarkLine7}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        <FormattedMessage id="columnname.caseMarkLine8.label" />
                                                                    </Typography>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                        {row.caseMarkLine8}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Grid>
                                            </ListItem>
                                        </Paper>
                                    );
                                })}
                                {tableLoadingStatus && (
                                    <Paper style={{ marginBottom: '5px', width: '100%' }}>
                                        <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                            <CircularProgress color='primary' size={40} />
                                        </Grid>
                                    </Paper>
                                )}
                                {(tableData.length <= 0 && !tableLoadingStatus) && (
                                    <Paper style={{ marginBottom: '5px', width: '100%' }}>
                                        <Divider />
                                        <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                            <Typography variant='body2' noWrap={true} className={classes.boldHeader}><FormattedMessage id="loading.text" defaultMessage="No rows" /></Typography>
                                        </Grid>
                                    </Paper>
                                )}
                            </List>
                        </div>
                    )}
                    {/* </Grid> */}
                </Paper>
                <AdvanceShipmentNoteInboundBoxDtl open={openScheduleGridDialog} onClose={closeScheduleGridDialog} msgHdrId={msgHdrId} messageDtlsId={msgDtlValue} mode={'view'}
                    orderLineNumber={orderLineNumber} orderNumber={orderNumber}
                />

            </Grid>
        </React.Fragment >
    );

}

export default AdvanceShipmentNoteInboundDtls;