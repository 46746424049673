import { AppBar, Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomBreadCrumb from '../CustomBreadCrumb';
import useStyles from '../CommonStyle/CommonStyle'
import { FormattedMessage } from 'react-intl';
import ChipInfoList from '../ChipInfoList';
import POPDFApprovalIcon from '@mui/icons-material/AssignmentTurnedIn';
import VMIApprovalIcon from '@mui/icons-material/Beenhere';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import IconApproval from '@mui/icons-material/LibraryAddCheck';
import PoVmiApproval from '../../PoVmiApproval/PoVmiApproval';
import IconMessage from '@mui/icons-material/Forum';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const TabsForPoPdfVmiApproval = (props) => {
    const classes = useStyles();
    const { isBPVmiConsumption } = props;
    const [value, setValue] = React.useState(isBPVmiConsumption ? 1 : 0);
    const [chipInfoList, setChipInfoList] = useState([]);

    const breadCrumbForMessageElement =
    {
        path: undefined,
        name: <FormattedMessage id="common.menu.breadcrumb.message" />,
        iconName: IconMessage,
    };

    const breadCrumbForPoVmiApproval = {
        path: '/fileAttachmentDtls',
        name: <FormattedMessage id="common.module.breadcrumb.povmiapproval" />,
        iconName: IconApproval,
    };

    const breadCrumbForPoPdfApprovalElement =
    {
        path: undefined,
        name: <FormattedMessage id="common.module.breadcrumb.popdfapproval" />,
        iconName: POPDFApprovalIcon,
    };

    const breadCrumbForVMIApprovalElement =
    {
        path: undefined,
        name: <FormattedMessage id="common.module.breadcrumb.vmiapproval" />,
        iconName: VMIApprovalIcon,
    };

    const breadCrumbForBPVMIApprovalElement =
    {
        path: undefined,
        name: <FormattedMessage id="viewVmiApproval.menu.text" />,
        iconName: VMIApprovalIcon,
    };

    function getBaseBreadCrumbBasedOnPoVMI() {
        let constructedBaseBreadCrumb = [];
        if (value === 1) {
            constructedBaseBreadCrumb = [breadCrumbForMessageElement, breadCrumbForPoVmiApproval,
                breadCrumbForVMIApprovalElement]
        } else {
            constructedBaseBreadCrumb = [breadCrumbForMessageElement, breadCrumbForPoVmiApproval,
                breadCrumbForPoPdfApprovalElement]
        }
        return constructedBaseBreadCrumb;
    }

    function getBaseBreadCrumbBasedOnBPVMI() {
        let constructedBaseBreadCrumb = [];
        constructedBaseBreadCrumb = [breadCrumbForBPVMIApprovalElement]
        return constructedBaseBreadCrumb;
    }

    const [componentList, setComponentList] = useState((() => {
        if (!isBPVmiConsumption) {
            return getBaseBreadCrumbBasedOnPoVMI();
        } else if (isBPVmiConsumption) {
            return getBaseBreadCrumbBasedOnBPVMI();
        }

    }));

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            return setComponentList([breadCrumbForMessageElement, breadCrumbForPoVmiApproval,
                breadCrumbForPoPdfApprovalElement])
        } else if (newValue === 1) {
            return setComponentList([breadCrumbForMessageElement, breadCrumbForPoVmiApproval,
                breadCrumbForVMIApprovalElement])
        }
    };

    return (
        <Grid container className={classes.rootForTabs}>
            <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={12} className={classes.topGrid}>
                <CustomBreadCrumb componentList={componentList} />
            </Grid>
            <ChipInfoList chipInfoList={chipInfoList} />
            <Paper className={classes.tabContainerForPurchaseOrder} elevation={3}>
                <AppBar position="sticky" color="inherit" elevation={2} >
                    {!isBPVmiConsumption ?
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            scrollButtons='auto'
                            textColor="secondary"
                            variant="fullWidth"
                            aria-label="accessible tabs"
                        >
                            <Tab style={{ fontWeight: 600 }} label={<FormattedMessage id="common.module.breadcrumb.popdfapproval" />}  {...a11yProps(0)} />
                            <Tab style={{ fontWeight: 600 }} label={<FormattedMessage id="common.module.breadcrumb.vmiapproval" />} {...a11yProps(1)} />
                        </Tabs> : <Tabs
                            value={value}
                            indicatorColor="primary"
                            scrollButtons='auto'
                            textColor="secondary"
                            variant="fullWidth"
                            aria-label="accessible tabs"
                        >
                            <Tab style={{ fontWeight: 600 }} label={<FormattedMessage id="common.module.breadcrumb.vmiapproval" />} {...a11yProps(1)} />
                        </Tabs>}
                </AppBar>
                <TabPanel value={value} index={0}>
                    <PoVmiApproval procesListValue={'PO PDF'} setChipInfoList={setChipInfoList} tabDetails={componentList} isBPVmi={false} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                {isBPVmiConsumption ?
                    <PoVmiApproval procesListValue={'VMI Consumption Report'} setChipInfoList={setChipInfoList} tabDetails={componentList} isBPVmi={true} /> :
                    <PoVmiApproval procesListValue={'VMI Consumption Report'} setChipInfoList={setChipInfoList} tabDetails={componentList} isBPVmi={false} />}
                </TabPanel>
            </Paper>
        </Grid>
    );
}
export default TabsForPoPdfVmiApproval;