import {
    Button, Chip, CssBaseline, Grid, Paper, Snackbar, Tooltip, Typography, Accordion, AccordionSummary, AccordionDetails, List,
    ListItem, Divider, CircularProgress, useTheme, useMediaQuery,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Alert, ToggleButton, ToggleButtonGroup } from '@mui/lab';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CommonBackDrop from '../common/BackDrop';
import CustomBreadCrumb from '../common/CustomBreadCrumb';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconBack from '@mui/icons-material/BackspaceOutlined';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import { GridToolbarColumnsButton, GridToolbarDensitySelector, DataGridPro } from '@mui/x-data-grid-pro';
import { CommonService } from '../../services';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableChartIcon from '@mui/icons-material/TableChart';
import ListIcon from '@mui/icons-material/List';
import ViewIcon from '@mui/icons-material/Visibility';
import useStyles from '../common/CommonStyle/CommonStyle'
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleAccessPermissionKey } from '../../Constants/ModuleAccessKey';
import IconMessage from '@mui/icons-material/Forum';
import saveAs from 'file-saver';
import GetAppIcon from '@mui/icons-material/GetApp';
import CommonGridPagination from '../common/CommonGridPagination/CommonGridPagination';
import { SupportedLanguageDetails } from '../common/SupportedLanguageDetails/SupportedLanguageDetails';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';
import IconInvoice from '@mui/icons-material/SpeakerNotes';

const StyledToggleButton = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
    },
    selected: {
        backgroundColor: "#d8d8d8",
    }

}))(ToggleButton);

const columnHeaderNames = {
  
    lineItemNo:"lineItemNo",
    buyerItemNo: "buyerItemNo",
    supplierItemNo: "supplierItemNo",
    itemDesc: "itemDesc",
    itemShortDesc: "itemShortDesc",
    invoicedQty: "invoicedQty",
    uom: 'uom',
    servCompDt: 'servCompDt',
    itemNetAmt:'itemNetAmt',
    itemPriceAmt: 'itemPriceAmt',
    calcNetAmt: 'calcNetAmt',
    orderNo: 'orderNo',
    orderLineNo: 'orderLineNo',
    orderDt: 'orderDt',
    despAdvNo:'despAdvNo',
    taxRate: 'taxRate',
    chargeAmt: 'chargeAmt',
    doNo:'doNo',
    doLineNo: 'doLineNo',
    soNo: 'soNo',
    soLineNo: 'soLineNo',
    makerPartNo: 'makerPartNo',
    custPartNo: 'custPartNo',
    unitPrice: 'unitPrice',
    unitPriceBasisQuantity:'unitPriceBasisQuantity',
    taxType: 'taxType',
    taxCategory: 'taxCategory',
    latestDataSource: 'latestDataSource',
    batchId: 'batchId'
};

const InvoiceInboundDtl = (props) => {

    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const { mode,msgId } = props.location.state;
    const smallScreen = useMediaQuery(theme.breakpoints.down(889))
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const invoiceModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.invoiceInbound];
    const [disableDownloadButton, setDisableDownloadButton] = useState(true)
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const pageLabelsConstantsAndMessages = {
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    }
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [openScheduleGridDialog, setOpenScheduleGridDialog] = useState(false);
    const closeScheduleGridDialog = () => {
        setOpenScheduleGridDialog(false);
    }
    const [tableData, setTableData] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [columns, setColumns] = useState(0);

    const commonColumnsForAsnEditView = [
       
        {
            field: columnHeaderNames. lineItemNo,
            headerName: intl.formatMessage({ id: "columnname. lineItemNo.label"}),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.supplierItemNo,
            headerName: <FormattedMessage id="columnname.supplierItemNo.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 170,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.buyerItemNo,
            headerName: <FormattedMessage id="columnname.buyerItemNo.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 170,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.orderNo,
            width: 190,
            headerName: intl.formatMessage({ id:"columnname.orderNumber.label" }),
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.orderLineNo,
            headerName: <FormattedMessage id="columnname.orderLineNumber.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.custPartNo,
            headerName: <FormattedMessage id="columnname.customerPartNumber.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 170,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
      
        {
            field: columnHeaderNames.makerPartNo,
            headerName: <FormattedMessage id="columnname.markerpartnotable.label" defaultMessage='Maker Part No' />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
       
        {
            field: columnHeaderNames.despAdvNo,
            headerName: <FormattedMessage id="columnname.despAdvNo.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.orderDt,
            headerName: intl.formatMessage({ id: "columnname.orderDt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.createdTimeDisp}</span>)
        },
        {
            field: columnHeaderNames.itemDesc,
            headerName: <FormattedMessage id="columnname.itemDesc.label" />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.itemShortDesc,
            headerName: <FormattedMessage id="columnname.itemShortDesc.label" defaultMessage={columnHeaderNames.uom} />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: false,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.invoicedQty,
            headerName: <FormattedMessage id="columnname.invoicedQty.label" defaultMessage={columnHeaderNames.unitPrice} />,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 120,
            sortable: false,
            type: 'number',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.itemNetAmt,
            headerName: intl.formatMessage({ id: "columnname.itemNetAmt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.servCompDt,
            headerName: intl.formatMessage({ id: "columnname.servCompDt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.createdTimeDisp}</span>)
        },
        {
            field: columnHeaderNames.itemPriceAmt,
            headerName: intl.formatMessage({ id: "columnname.itemPriceAmt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.taxRate,
            headerName: intl.formatMessage({ id:  "columnname.taxRate.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        
        {
            field: columnHeaderNames.chargeAmt,
            headerName: intl.formatMessage({ id: "columnname.chargeAmt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.doNo,
            headerName: intl.formatMessage({ id: "columnname.doNo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.doLineNo,
            headerName: intl.formatMessage({ id: "columnname.doLineNo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.soNo,
            headerName: intl.formatMessage({ id: "columnname.soNo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.soLineNo,
            headerName: intl.formatMessage({ id: "columnname.soLineNo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.calcNetAmt,
            headerName: intl.formatMessage({ id: "columnname.calcNetAmt.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.unitPrice,
            headerName: intl.formatMessage({ id: "columnname.unitPrice.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.unitPriceBasisQuantity,
            headerName: intl.formatMessage({ id: "columnname.unitPriceBasisQuantity.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.taxType,
            headerName: intl.formatMessage({ id: "columnname.taxType.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.taxCategory,
            headerName: intl.formatMessage({ id: "columnname.taxCategory.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.latestDataSource,
            headerName: intl.formatMessage({ id: "columnname.latestDataSource.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnHeaderNames.batchId,
            headerName: intl.formatMessage({ id: "columnname.batchId.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 150,
            sortable: false,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        
    ]

    useEffect(() => {
        setColumns(commonColumnsForAsnEditView);
    }, [selectedLanguage]);

    const [tableLoadingStatus, setTableLoadingStatus] = useState(true);

    const [paramBody, setParamBody] = useState(
        {
            body: {

            },
            pageNo: 0,
            sortDir: 'asc',
            sortKey: 'msgDtlId',
            recordsPerPage: 25,
        }
    );

    const [refreshFlag, setRefreshFlag] = useState(false);
    const [componentList, setComponentList] = useState([]);
    const [disableRefreshButton] = useState(false);
    const [invoiceToBeEdited, setInvoiceToBeEdited] = useState([]);
    const [msgDtlsPopUp, setMsgDtlsPopUp] = useState(false);

    useEffect(() => {
        let currentRouterComponent;
        currentRouterComponent = {
            path: undefined,
            name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage='View' />,
            iconName: ViewIcon,
        };
        setComponentList([
            {
                path: undefined,
                name: <FormattedMessage id="common.menu.breadcrumb.message" />,
                iconName: IconMessage,
            },
             {
            path: '/invoiceInbound',
            name: <FormattedMessage id="common.module.breadcrumb.invoiceInbound" defaultMessage="Invoice Inbound" />,
            iconName: IconInvoice,
        },
            currentRouterComponent
        ])
    }, [mode, msgId, refreshFlag]);

    const [invoiceDetailItemViewType, setInvoiceDetailItemViewType] = React.useState('list');

    const handleToggleChange = (event, newAlignment) => {
        setInvoiceDetailItemViewType(newAlignment);
    };


    useEffect(() => {
        setOpenBackDrop(true);
        CommonService.getByIdApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.invoiceInboundHdr, msgId)
            .then((response) => {
                setTableLoadingStatus(false);
                setInvoiceToBeEdited(response.data);
                setOpenBackDrop(false);
            })
            .catch(function (error) {
                setTableLoadingStatus(false);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
                setOpenBackDrop(false);
            });
    }, [refreshFlag, msgId]);

    useEffect(() => {
        setTableLoadingStatus(true);
        setTableData([]);
        setOpenBackDrop(true);
         if (mode === 'view') {
            CommonService.GetListApi(`/invoiceInboundHdr/${msgId}/invoiceInboundDtls`, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTableData(response.data.content)
                    setTotalRowCount(response.data.totalElements);
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    setOpenBackDrop(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                    setOpenBackDrop(false);
                });
        }
    }, [mode, paramBody, refreshFlag])

    useEffect(() => {
        if (msgDtlsPopUp === true) {
            setOpenBackDrop(true);
            let messageId = { msgId: msgId.toString() };
            CommonService.saveOrUpdateOrPost('/reports/SYS_PO_DTL', messageId)
                .then((response) => {
                    showDownloadPopUpExcelDtls(response.data.fileUrl)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />, severity: "success"
                    });
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgDtlsPopUp(false)
        }
    }, [msgId, msgDtlsPopUp])

    const showDownloadPopUpExcelDtls = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            })
    }

   

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForEditPurchaseOrder}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' alignItems='center' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <Button size='small'
                        variant="contained" color="primary" className={classes.stateButton} elevation={2}
                        endIcon={smallScreen ? null : <IconBack />} onClick={history.goBack}
                    >{smallScreen ? <IconBack /> : <FormattedMessage id="common.button.back.lable" />}</Button>
                    <Button size='small' disabled={disableRefreshButton}
                        variant="contained"
                        color="secondary"
                        className={classes.stateButtonReferesh}
                        elevation={2}
                        endIcon={smallScreen ? null : <IconRestore />} onClick={(() => { setRefreshFlag(!refreshFlag) })}
                    >{smallScreen ? <IconRestore /> : <FormattedMessage id="common.button.refresh.tooltip" />} </Button>
                    {(invoiceModuleAccess !== undefined ? invoiceModuleAccess.createFlag || invoiceModuleAccess.editFlag : false) && (
                        <Button size='small'
                            disabled={disableDownloadButton}
                            variant="contained"
                            color="primary"
                            className={classes.stateButton}
                            elevation={2}
                            elevation={2}
                            onClick={() => { setMsgDtlsPopUp(true) }}
                        > <GetAppIcon /> </Button>
                    )}
                   
                </Grid>
                <Paper className={classes.paperForEditPurchaseOrder} elevation={3}>
                    <form className={classes.formForPurchaseOrder}>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                            >
                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={1}>
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id="messageIdinvoice.chip.text" values={{ invoiceToBeEdited: `${(invoiceToBeEdited !== undefined ? invoiceToBeEdited.msgId:'' )}` }} />}
                                        className={classes.chipForPO}
                                        color='secondary'
                                    />
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id= "msgReferenceDateInvoice.chip.text" values={{ invoiceToBeEdited: `${(invoiceToBeEdited !== undefined ? invoiceToBeEdited.messageReceivedTimeDisp !== null ? invoiceToBeEdited.messageReceivedTimeDisp : '' : '')}` }} />}
                                        className={classes.chipTheme}
                                        color='primary'
                                    />
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id="msgRcvModeInvoice.chip.text" values={{ invoiceToBeEdited: `${(invoiceToBeEdited !== undefined ? invoiceToBeEdited.messageReceivedMode === 0 ? 'AS2' : 'VAN' : '')}` }} />}
                                        className={classes.chipForPO}
                                        color='secondary'
                                    />
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id="noLineItemInvoice.chip.text" values={{ invoiceToBeEdited: `${tableData.length}` }} />}
                                        className={classes.chipTheme}
                                        color='secondary'
                                    />
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        label={<FormattedMessage id=   "approvedInvoice.chip.text" values={{ invoiceToBeEdited: `${(invoiceToBeEdited !== undefined ? invoiceToBeEdited.approved === 1 ? 'Approved' : invoiceToBeEdited.approved === 2 ? 'Rejected' : 'Not Approved' : '')}` }} />}
                                        className={classes.chipTheme}
                                        color='primary'
                                    />
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <Grid item container xs={6} sm={6} md={12} lg={12} spacing={2} className={classes.details}>
                                    <Grid item container justifyContent='center' alignItems='baseline' xs={6} sm={6} md={6} lg={6} className={classes.helperForAccordian}>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <Typography variant='h6'><strong><FormattedMessage id="buyerdetails.label" defaultMessage="Buyer Details" /></strong></Typography>
                                        </Grid>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <div>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="name.accordian.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(invoiceToBeEdited !== undefined ? invoiceToBeEdited.buyerName : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="richtexteditor.blocktype.code.text" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(invoiceToBeEdited !== undefined ? invoiceToBeEdited.buyerPartyCode : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="columnname.buyerAddress.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(invoiceToBeEdited !== undefined ? invoiceToBeEdited.buyerAddress : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent='center' alignItems='baseline' xs={12} sm={12} md={6} lg={6} className={classes.helperForPO}>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <Typography variant='h6'><strong><FormattedMessage id="supplierdetails" defaultMessage="Supplier Details" /></strong></Typography>
                                        </Grid>
                                        <Grid item container justifyContent='flex-start' alignItems='center'>
                                            <div>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="name.accordian.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(invoiceToBeEdited !== undefined ? invoiceToBeEdited.supplierName : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id="richtexteditor.blocktype.code.text" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(invoiceToBeEdited !== undefined ? invoiceToBeEdited.sellerPartyCode : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    <FormattedMessage id= "columnname.supplierAddress.label" />
                                                                </Typography>
                                                            </td>
                                                            <td> : </td>
                                                            <td>
                                                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                    {(invoiceToBeEdited !== undefined ? invoiceToBeEdited.sellerAddress : '')}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </form>
                </Paper>
                <Paper className={classes.paperForEditPurchaseOrder} elevation={3}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{ padding: '5px 10px' }}>
                        <Grid item container justifyContent="flex-start" alignItems="center" xs={6} sm={6} md={6} lg={6}>
                            <Typography component="span" variant="h5" color="textPrimary">
                                <FormattedMessage id="itemdetails.label" />
                            </Typography>
                        </Grid>
                        <Grid item container justifyContent="flex-end" alignItems="center" xs={6} sm={6} md={6} lg={6}>
                            <ToggleButtonGroup size="small" value={invoiceDetailItemViewType} exclusive onChange={handleToggleChange}>
                                <StyledToggleButton value="list">
                                    <Tooltip title={<FormattedMessage id="listview.tooltip" defaultMessage='List View' />} placement='bottom'>
                                        <ListIcon fontSize="small" />
                                    </Tooltip>
                                </StyledToggleButton>
                                <StyledToggleButton value="table">
                                    <Tooltip title={<FormattedMessage id="tableview.tooltip" />} placement='bottom'>
                                        <TableChartIcon fontSize="small" />
                                    </Tooltip>
                                </StyledToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    {(invoiceDetailItemViewType === 'table') && (
                        <Paper elevation={3} className={classes.gridHeightAndWidthForPOPFTableView}>
                            <div style={{ width: '100%', height: '100%' }}>
                                <DataGridPro
                                    className={classes.customTableStyle}
                                    pageSize={paramBody.recordsPerPage}
                                    loading={tableLoadingStatus}
                                    rows={tableData}
                                    columns={columns}
                                    rowCount={totalRowCount}
                                    getRowId={(row) => row.id}
                                    scrollbarSize={30}
                                    disableColumnFilter={true}
                                    rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                                    pagination
                                    density="compact"
                                    paginationMode="server"
                                    hideFooter={true}
                                    disableSelectionOnClick={true}
                                    disableMultipleColumnsSorting={true}
                                    disableColumnMenu={false}
                                    localeText={localtext}
                                    onPageSizeChange={((pageParams) => {
                                        setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                                    })}
                                    onPageChange={((pageParams) => {
                                        setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                                    })}
                                    components={{
                                        Toolbar: () => {
                                            return (
                                                <Grid container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                                    <Grid
                                                        item
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}>
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" />} placement='bottom'>
                                                                <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid>
                                                            <Tooltip title={<FormattedMessage id="common.density.tooltip" />} placement='bottom'>
                                                                <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <CommonGridPagination />
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                    }}
                                />
                            </div>
                        </Paper>
                    )}
                    {(invoiceDetailItemViewType === 'list') && (
                        <div>
                            <Divider/>
                            <List style={{ padding: '0 10px' }}>
                                {tableData.map((row, index) => {
                                    return (
                                        <Paper elevation={5} style={{ marginBottom: '5px', width: '100%' }}>
                                            <ListItem dense key={index} className={classes.helperForPos}>
                                            
                                                <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={3}
                                                        className={classes.helperForDetail}>
                                                        <table>
                                                            <tbody>
                                                                 <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.lineItemNo.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.lineItemNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.buyerItemNo.label" defaultMessage={columnHeaderNames.buyerItemNo} />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.buyerItemNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.supplierItemNo.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.supplierItemNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.itemDesc.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.itemDesc}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.itemShortDesc.label" defaultMessage={columnHeaderNames.itemShortDesc} />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.itemShortDesc}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={3}
                                                        className={classes.helperForDetail}>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id= "columnname.invoicedQty.label"/>
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' display='block' className={classes.boldHeader}>
                                                                            {row.invoicedQty}</Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id= "columnname.uom.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.uom}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id= "columnname.servCompDt.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.servCompDt}</Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.itemNetAmt.label"/>
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.itemNetAmt}</Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.calcNetAmt.label"/>
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.calcNetAmt}</Typography>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={2}
                                                        className={classes.helperForDetail}>
                                                        <table>
                                                            <tbody>
                                                            <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.orderNo.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.orderNo}</Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.orderLineNo.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.orderLineNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.orderDt.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.orderDt}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.despAdvNo.label"/>
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.despAdvNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.taxRate.label"/>
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.taxRate}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                               
                                                            </tbody>
                                                        </table>
                                                    </Grid> 
                                                    <Grid item container wrap="nowrap" justifyContent='flex-start' xs={12} sm={12} md={12} lg={2}
                                                        className={classes.helperForDetail}>
                                                        <table>
                                                            <tbody>
                                                            <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.chargeAmt.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.chargeAmt}</Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.doNo.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.doNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.doLineNo.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.doLineNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.soNo.label"/>
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.soNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.taxRate.label"/>
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.taxRate}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                               
                                                            </tbody>
                                                        </table>
                                                    </Grid> 
                                                    <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={2} className={classes.helperForDetail}>
                                                        <table>
                                                            <tbody>
                                                               <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id= "columnname.soLineNo.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.soLineNo}</Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.makerPartNo.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.makerPartNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                               
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.custPartNo.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.custPartNo}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.unitPrice.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.unitPrice}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                                    <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={2} className={classes.helperForDetail}>
                                                        <table>
                                                            <tbody>
                                                               <tr>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            <FormattedMessage id= "columnname.unitPriceBasisQuantity.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.unitPriceBasisQuantity}</Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.taxType.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            {row.taxType}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                               
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.taxCategory.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.taxCategory}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Typography variant='body2' noWrap={true} className={classes.boldHeader}>
                                                                            <FormattedMessage id="columnname.latestDataSource.label" />
                                                                        </Typography>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Typography variant='body2' className={classes.boldHeader}>
                                                                            {row.latestDataSource}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>
                                            </ListItem>
                                        </Paper>
                                    );
                                })}
                                {tableLoadingStatus && (
                                    <Paper style={{ marginBottom: '5px', width: '100%' }}>
                                        <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                            <CircularProgress color='primary' size={40} />
                                        </Grid>
                                    </Paper>
                                )}
                                {(tableData.length <= 0 && !tableLoadingStatus) && (
                                    <Paper style={{ marginBottom: '5px', width: '100%' }}>
                                        <Divider />
                                        <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                            <Typography variant='body2' noWrap={true} className={classes.boldHeader}><FormattedMessage id="loading.text" defaultMessage="No rows" /></Typography>
                                        </Grid>
                                    </Paper>
                                )}
                            </List>
                        </div>
                    )}
                    {/* </Grid> */}
                </Paper>
              </Grid>
        </React.Fragment >
    );

}

export default InvoiceInboundDtl;