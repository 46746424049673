import { Delete } from '@mui/icons-material';
import { Autocomplete, FormControl, Grid, IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const CustomFilterPanel = (props) => {
    const { filterableColumns, selectedPanelFilter, setSelectedPanelFilter, emptySelectedFilter, itemId, setDeletedFilterIndex, key, defaultPanel, resetFlag, setResetFlag } = props;

    const [tempSelectedPanelFilter] = useState(() => {
        return emptySelectedFilter ? '' : selectedPanelFilter
    })

    const [filteredColumn, setFilteredColumn] = useState('');

    const InputValue = filteredColumn.filterOperators !== undefined ? filteredColumn.filterOperators[0].InputComponent : '';

    const [selectedValue, setSelectedValue] = useState('');

    const [item, setItem] = useState("");

    useEffect(() => {
        setItem({
            columnField: filteredColumn.field,
            headerName: filteredColumn.headerName,
            id: itemId,
            operatorValue: 'contains',
            value: selectedValue === '' ? tempSelectedPanelFilter.value : selectedValue.value
        })
    }, [selectedValue, filteredColumn, selectedPanelFilter])

    useEffect(() => {
        if (filteredColumn === '') {
            filterableColumns.filter((columns) => {
                if (columns.field === tempSelectedPanelFilter.columnField) {
                    setFilteredColumn(columns)
                    return columns
                }
            })
        }
    }, [filteredColumn, selectedValue])

    useEffect(() => {
        if (resetFlag === true) {
            setSelectedValue(defaultPanel);
        }
    }, [resetFlag])

    useEffect(() => {
        setSelectedValue('');
    }, [filteredColumn])

    useEffect(() => {
        if (selectedValue !== '') {
            setSelectedPanelFilter(selectedValue);
        }
    }, [selectedValue])

    const getDefaultFilterIndex = () => {
        let columnIndex = null;
        if (tempSelectedPanelFilter !== undefined || tempSelectedPanelFilter !== '') {
            columnIndex = filterableColumns.findIndex((column) => {
                return column.field === tempSelectedPanelFilter.columnField
            })
        }
        return columnIndex
    }

    useEffect(() => {
        filterableColumns.sort(function (a, b) {
            if (a.headerName < b.headerName) {
                return -1;
            }
            if (a.headerName > b.headerName) {
                return 1;
            }
        })
    }, [filterableColumns])

    const [value, setValue] = useState(defaultPanel.value);

    return (
        <React.Fragment key={key}>
            <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                <Grid item xs={1} sm={1} md={1} lg={1} style={{ marginTop: '10px' }} >
                    <IconButton key={key} onClick={(() => {
                        setDeletedFilterIndex(emptySelectedFilter === true ? itemId : selectedPanelFilter);
                        setSelectedPanelFilter('')
                    })}
                    >
                        <Delete />
                    </IconButton>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} >
                    <FormControl fullWidth>
                        <Autocomplete

                            key={key}
                            onChange={(event, newValue) => {
                                if (newValue === null) {
                                    setFilteredColumn('');
                                } else {
                                    setFilteredColumn(newValue);
                                }
                            }}
                            size='small'
                            selectOnFocus
                            handleHomeEndKeys
                            autoHighlight
                            value={filteredColumn.headerName}
                            options={filterableColumns}
                            getOptionLabel={(option) => option.headerName}
                            getoptionselected={(option, value) => option.headerName === value}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    variant="standard"
                                    label={<FormattedMessage id="common.filterpanelcolumns.toolbar.lable" defaultMessage="Columns" />}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            )}
                            defaultValue={getDefaultFilterIndex() !== null || getDefaultFilterIndex() >= 0 ? filterableColumns[getDefaultFilterIndex()] : ''}
                        />
                    </FormControl>
                </Grid>
                {
                    filteredColumn.filterOperators !== undefined ?
                        <Grid item xs={7} sm={7} md={7} lg={7}>
                            <InputValue key={key} item={item} applyValue={(value) => {
                                setSelectedValue(value);
                                setResetFlag(false);
                            }} />
                        </Grid> :
                        <Grid item xs={7} sm={7} md={7} lg={7}>
                            <FormControl fullWidth key={key}>
                                <TextField
                                    size="small"
                                    variant="standard"
                                    value={selectedValue.value}
                                    autoFocus={value === '' || value === undefined ? false : true}
                                    onChange={(ev) => {
                                        const { value } = ev.target;
                                        setValue(ev.target.defaultValue)
                                        setSelectedValue({ columnField: filteredColumn.field, headerName: filteredColumn.headerName, id: itemId, operatorValue: "contains", value: value });
                                        setResetFlag(false);
                                    }}
                                    label={<FormattedMessage id="common.filterpanelinputlabel.toolbar.lable" defaultMessage="Value" />}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    defaultValue={tempSelectedPanelFilter === undefined || tempSelectedPanelFilter === '' ? '' : tempSelectedPanelFilter.value}
                                />
                            </FormControl>
                        </Grid>
                }
            </Grid>
        </React.Fragment >
    )
}

export default CustomFilterPanel