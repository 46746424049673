import React, { useContext, useEffect, useState } from 'react';
import {
    AppBar,
    Box, Button, Checkbox, CircularProgress, Collapse, CssBaseline, Divider, FormControl, FormControlLabel,
    FormHelperText, Grid, Icon, InputLabel, List, ListItem, ListItemIcon, ListItemSecondaryAction,
    ListItemText, Paper, Snackbar, Tab, Tabs, TextField, Tooltip, Typography, useMediaQuery, useTheme
} from '@mui/material';
import IconRole from '@mui/icons-material/SupervisorAccount';
import Alert from '@mui/lab/Alert';
import CustomBreadCrumb from '../../../common/CustomBreadCrumb';
import ChipInfoList from '../../../common/ChipInfoList';
import { LoggedInUserDetails } from '../../../common/LoggedInUserDetails/LoggedInUserDetails';
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconBack from '@mui/icons-material/BackspaceOutlined';
import _ from "lodash/fp";
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CommonService } from '../../../../services';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import Switch from '@mui/material/Switch';
import IconSettings from '@mui/icons-material/Settings';
import IconDefaultMenu from '@mui/icons-material/Apps';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommonBackDrop from '../../../common/BackDrop';
import useStyles from '../../../common/CommonStyle/CommonStyle';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleAccessPermissionKey } from '../../../../Constants/ModuleAccessKey';
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';
import IconInterfaceConfiguration from '@mui/icons-material/SwapCalls';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import IconMessage from '@mui/icons-material/Forum';
import UserTypeComboRole from '../../../common/Combos/UserTypeComboRole';
import IconOrder from '@mui/icons-material/Assignment';
import IconForecast from '@mui/icons-material/TrendingUp';
import IconViewRequest from '@mui/icons-material/Equalizer';
import IconImportTemplate from '@mui/icons-material/UploadFile';
import UserTypeCombo from '../../../common/Combos/UserTypeCombo';
import ProcessListCombo from '../../../common/Combos/ProcessListCombo/ProcessListCombo';
import EntityCodeCombo from '../../../common/Combos/EntityCodeCombo';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const CreateOrEditRole = (props) => {
    const classes = useStyles();
    const history = useHistory();
    let theme = useTheme();
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const mobileScreen = useMediaQuery(theme.breakpoints.down('890'))
    const { mode, roleId } = props.location.state;
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const commonPatterns = GlobalEdiApiConstants();
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const currentUser = CommonService.getLoggedInUserInfo();
    const roleModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.role];
    const [branchValue, setBranchValue] = useState('');

    const pageLabelsConstantsAndMessages = {
        label: {
            backButton: 'Back',
            restoreButton: 'Reset',
            roleName: 'Role',
            active: 'Active',
            requiredSymbol: ' *',
            menuHeading: 'Menu Name',
            statusHeading: 'Status',
            moduleHeading: 'Module Name',
            createFlagHeading: 'Create',
            editFlagHeading: 'Edit',
            viewOrReadFlagHeading: 'View/Read',
            deleteFlagHeading: 'Delete'
        },
        name: {
            roleName: 'name',
            userType: 'userType',
            active: 'active',
            entity: 'entity'
        }
    }
    const [userReadOnlyFields, setUserReadOnlyFields] = useState({
        name: false,
        subject: false,
        organization: false,
        entity: false,
        active: false,
        msgContent: false,
        contentType: false,
        msgPlainContent: false,
        charSet: false
    });

    const [defaultValuesForRole, setDefaultValuesForRole] = useState({
        name: '',
        active: true,
    });
    const [isMenuAccessLoading, setMenuAccessLoading] = useState(false);
    const [roleModuleAccessList, setRoleModuleAccessList] = useState([]);
    const [roleMenuAccessList, setRoleMenuAccessList] = useState([]);
    const [tempMenuList, setTempMenuList] = useState([]);
    const [restorForEditMode, isRestorForEditMode] = useState(false);
    const [menuList, setMenuList] = useState([]);
    const [usertypeRole, setUserTypeRole] = useState('');
    const [resetCalled, setResetCalled] = useState(false)
    useEffect(() => {
        setResetCalled(false)
        if ((usertypeRole !== "" || usertypeRole === [])) {
            let user = {
                body: {
                    userType: usertypeRole.value
                }
            }
            setMenuAccessLoading(true);
            CommonService.GetListApi('/menus', user)
                .then((response) => {
                    setMenuAccessLoading(false);
                    setMenuList(response.data.content);
                    setTempMenuList(response.data.content);
                })
                .catch(function (error) {
                    setMenuAccessLoading(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                });
        }
    }, [usertypeRole, resetCalled])
    const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('');
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [chipInfoList] = useState([]);
    const [componentList, setComponentList] = useState([]);
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);

    const [selectedIndex, setSelectedIndex] = React.useState("")
    const handleOnListItemClick = index => {
        if (selectedIndex === index) {
            setSelectedIndex("")
        } else {
            setSelectedIndex(index)
        }
    };
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [value, setValue] = React.useState(0);
    const [roleToBeEdited, setRoleToBeEdited] = React.useState({});
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (mode === 'edit') {
            CommonService.getByIdApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.role, roleId)
                .then((response) => {
                    setRoleToBeEdited(response.data)
                    setRoleModuleAccessList(response.data.roleModuleAccess);
                    setRoleMenuAccessList(response.data.roleMenus);
                    roleResettingValues(response.data);
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setOpenBackDrop(false);
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                });
        }
    }, [restorForEditMode])

    function roleResettingValues(responseData) {
        reset({
            userType: responseData.userType,
            name: responseData.name,
            active: responseData.active,
           entity : responseData.entity !== undefined ? { valuesToBeDisplayed: responseData.entity } : []
        });
        setDefaultValuesForRole({ name: responseData.name, active: responseData.active, userType: responseData.name });
        setUserTypeRole(responseData.userType !== undefined ? { value: responseData.userType } : '')
        setBranchValue(responseData.entity !== undefined ? { valuesToBeDisplayed: responseData.entity } : []);

    }

    useEffect(() => {
        setOpenBackDrop(true);
        let currentRouterComponent;
        if (mode === 'create' && roleId === 0) {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            isRestoreButtonDisabled(false);
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                iconName: CreateIcon,
            }
            setOpenBackDrop(false);
        } else {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isRestoreButtonDisabled(false);
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                iconName: EditIcon,
            }
            roleResettingValues(roleToBeEdited);
        }
        // getAllMenuList();
        setComponentList([
            {
                path: null,
                name: <FormattedMessage id="common.menu.breadcrumb.settings" defaultMessage="Settings" />,
                iconName: IconSettings,
            },
            {
                path: '/roles',
                name: <FormattedMessage id="common.module.breadcrumb.role" defaultMessage="Role" />,
                iconName: IconRole,
            },
            currentRouterComponent
        ])
    }, [mode, roleId]);
    const onSubmit = data => {
        data['id'] = roleId;
        data['userType'] = usertypeRole.value
        data['roleModuleAccess'] = roleModuleAccessList;
        data['roleMenus'] = roleMenuAccessList;
        data['organization'] = (mode === 'create') ? currentUser.organization : roleToBeEdited.organization;
        data['createdBy'] = (mode === 'create') ? currentUser.userName : roleToBeEdited.createdBy;
        data['createdTime'] = roleToBeEdited.createdTime;
        data['updatedBy'] = currentUser.userName;
        data['updatedTime'] = roleToBeEdited.lastUpdatedDt;
        data['entity']=(mode === 'create') ? branchValue : branchValue.valuesToBeDisplayed;
        setOpenBackDrop(true);
        CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.role, data)
            .then((response) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfully" defaultMessage="Record updated successfully" />, severity: "success" });
                setOpenBackDrop(false);
                history.goBack();
            })
            .catch(function (error) {
                setOpenBackDrop(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            });

    }

    const { handleSubmit, formState: { errors }, reset, control } = useForm({
        mode: "all",
        defaultValues: {
            userType: "",
            name: "",
            active: true,
            entity: ''
        }
    });

    const [checked, setChecked] = React.useState(false);

    const handleToggle = (menuId) => async () => {
        let menuAccessibleStatus = false;
        for (let index = 0; index < roleMenuAccessList.length; index++) {
            if (roleMenuAccessList[index].menu.menuId === menuId) {
                roleMenuAccessList[index].active = !roleMenuAccessList[index].active;
                menuAccessibleStatus = true;
                setChecked(!checked);
                break;
            }
        }
        if (!menuAccessibleStatus) {
            let tempRoleMenuAccessList = roleMenuAccessList;
            tempRoleMenuAccessList.push(intializeAccessibleMenuObjectForCurrentUser(menuId));
            setRoleMenuAccessList(tempRoleMenuAccessList);
            setChecked(!checked);
        }
    };

    function intializeAccessibleMenuObjectForCurrentUser(menuId) {
        return {
            roleMenuId: 0,
            active: true,
            createdBy: currentUser.userName,
            lastUpdatedBy: currentUser.userName,
            menu: {
                menuId: menuId
            },
            menuSequence: (roleMenuAccessList.length + 1)
        };
    }

    useEffect(() => {
        isMenuAccessibleForCurrentUser();
    }, [checked])

    const isMenuAccessibleForCurrentUser = (menuId) => {
        let menuAccessibleStatus = false;
        for (let index = 0; index < roleMenuAccessList.length; index++) {
            if (roleMenuAccessList[index].menu.menuId === menuId && roleMenuAccessList[index].active) {
                menuAccessibleStatus = true;
                break;
            }
        }
        return menuAccessibleStatus;
    };

    function intializeAccessibleModuleObjectForCurrentUser(moduleId, modeOfPermission) {
        return {
            roleModuleId: 0,
            active: true,
            createFlag: (modeOfPermission === 'createFlag' ? true : false),
            readFlag: (modeOfPermission === 'readFlag' ? true : false),
            editFlag: (modeOfPermission === 'editFlag' ? true : false),
            deleteFlag: (modeOfPermission === 'deleteFlag' ? true : false),
            organization: {
                erpOrgCode: currentUser.organization.erpOrgCode
            },
            module: {
                moduleId: moduleId
            },
            createdBy: currentUser.userName,
            lastUpdatedBy: currentUser.userName,
        };
    }

    const [onModuleAccessPermissionChange, setOnModuleAccessPermissionChange] = useState(false);

    const isModulePermissionAvailable = (modeOfPermission, moduleId) => {
        let isRequestedModeIsAccessible = false;
        for (let index = 0; index < roleModuleAccessList.length; index++) {
            if (roleModuleAccessList[index].module.moduleId === moduleId && roleModuleAccessList[index].[modeOfPermission]) {
                isRequestedModeIsAccessible = true;
                break;
            }
        }
        return isRequestedModeIsAccessible;
    };

    useEffect(() => {
        isModulePermissionAvailable();
    }, [onModuleAccessPermissionChange, roleModuleAccessList]);

    function onModulePermissionChange(modeOfPermission, moduleId, value) {
        let moduleAccessibleStatus = false;
        for (let index = 0; index < roleModuleAccessList.length; index++) {
            if (roleModuleAccessList[index].module.moduleId === moduleId) {
                roleModuleAccessList[index].[modeOfPermission] = value;
                moduleAccessibleStatus = true;
                setOnModuleAccessPermissionChange(!onModuleAccessPermissionChange);
                break;
            }
        }
        if (!moduleAccessibleStatus) {
            let tempRoleModuleAccessList = roleModuleAccessList;
            tempRoleModuleAccessList.push(intializeAccessibleModuleObjectForCurrentUser(moduleId, modeOfPermission));
            setRoleModuleAccessList(tempRoleModuleAccessList);
            setOnModuleAccessPermissionChange(!onModuleAccessPermissionChange);
        }
    }

    function onSelectOrUnSelectAllModulePermission(modeOfPermission, value) {
        {
            tempMenuList && tempMenuList.map((menu, index) => {
                {
                    menu.menuModules && menu.menuModules.map((accessModule) => {
                        onModulePermissionChange(modeOfPermission, accessModule.module.moduleId, value);
                    })
                }
            })
        }
    }

    function resetAllClearThePageInfo() {
        if (mode === 'edit') {
            roleResettingValues(roleToBeEdited);
            setMenuList()
            isRestorForEditMode(!restorForEditMode);
            setValue(0);
        } else {
            reset({
                userType: '',
                name: '',
                active: true
            });
            setMenuList([])
            setResetCalled(true)
            setRoleModuleAccessList([]);
            setUserTypeRole('');
            setRoleMenuAccessList([]);
            setDefaultValuesForRole({ ...defaultValuesForRole, active: true });
            setValue(0);
            setBranchValue('');
        }
    }

    const userTypeComboRoleComboProperty = {
        name: pageLabelsConstantsAndMessages.name.userType,
        isRequired: true,
        label: intl.formatMessage({ id: "columnname.usertype.label" }),
        isAutofocus: false,
        variant: 'outlined',
        isHookFormType: true,
        isDisabled: (mode === 'edit') ? true : false,
        errors: errors.userType
    }

    const branchComboProperty = {
        name: pageLabelsConstantsAndMessages.name.entity,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.entityCode.label" }),
        isAutofocus: false,
        isDisabled: mode === 'create' ? false: true,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.entity
    }


    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForCreateOrEditRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={8} md={8} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' alignItems='center' xs={6} sm={4} md={4} lg={6} className={classes.topGrid}>
                    <Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2}
                        endIcon={mobileScreen ? null : <IconBack />} onClick={history.goBack}
                    > {mobileScreen ? <IconBack /> : <FormattedMessage id="common.button.back.lable" defaultMessage="Back" />} </Button>
                    <Button size='small' disabled={disableRestoreButton}
                        variant="contained" color="secondary" className={classes.stateButtonToRestore} elevation={2}
                        endIcon={mobileScreen ? null : <IconRestore />} onClick={(() => { resetAllClearThePageInfo() })}
                    > {mobileScreen ? <IconRestore /> : <FormattedMessage id="common.button.reset.lable" defaultMessage="Reset" />} </Button>

                    {(roleModuleAccess !== undefined ? roleModuleAccess.createFlag : false) && (<Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                        endIcon={mobileScreen ? null : <IconSaveOrUpdate />} onClick={handleSubmit(onSubmit)}
                    > {mobileScreen ? <IconSaveOrUpdate /> : saveOrUpdateButtonLabel} </Button>)}

                </Grid>
                <ChipInfoList chipInfoList={chipInfoList} />
                <Paper className={classes.paperCreateOrEditRole} elevation={3}>
                    <form className={classes.paperForCreateOrEditUser} onSubmit={handleSubmit(onSubmit)}>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} style={{ margin: '-0.5% 0 0.5% 0' }}>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.roleName}
                                        rules={{
                                            required: true,
                                            maxLength: 50,
                                            pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UHF
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                autoFocus
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                required
                                                error={errors.name}
                                                defaultValue={roleToBeEdited.name}
                                                label={<FormattedMessage id="textfield.label.role" defaultMessage="Role" />}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.roleName}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.error.roleempty.txt" defaultMessage="Role is required" />}</FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.roleName}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.label.role" defaultMessage="Role" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                        )}

                                        {_.get(`${pageLabelsConstantsAndMessages.name.roleName}.type`, errors) === "maxLength" && (
                                            <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.label.role" defaultMessage="Role" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" />}
                                            </FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <UserTypeComboRole
                                    comboProperty={userTypeComboRoleComboProperty}
                                    control={control} errors={errors}
                                    comboValue={usertypeRole}
                                    setComboValue={setUserTypeRole}
                                />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={3} lg={3}>
                                <EntityCodeCombo comboProperty={branchComboProperty} control={control} errors={errors} comboValue={branchValue} setComboValue={setBranchValue} />
                            </Grid>
                            <Grid item container xs={12} sm={12} md={3} lg={3}>
                                <Grid container justifyContent='flex-start' alignItems='center'>
                                    <Controller
                                        render={({
                                            field: { onChange } }) => (<Switch
                                                color='primary'
                                                checked={defaultValuesForRole.active}
                                                onChange={((event) => {
                                                    onChange(!defaultValuesForRole.active);
                                                    setDefaultValuesForRole({ ...defaultValuesForRole, active: event.target.checked })
                                                })}
                                            />)}
                                        type="checkbox"
                                        name={pageLabelsConstantsAndMessages.name.active}
                                        control={control}
                                        defaultValue={defaultValuesForRole.active}
                                    />
                                    <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>

                <Paper className={classes.tabContainer} elevation={3}>
                    <AppBar position="sticky" color="inherit" elevation={2}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            scrollButtons='auto'
                            textColor="secondary"
                            variant="fullWidth"
                            aria-label="accessible tabs"
                        >
                            <Tab style={{ fontWeight: 600 }} label={<FormattedMessage id="tabname.menuaccess" defaultMessage="MENU ACCESS" />} {...a11yProps(0)} />
                            <Tab style={{ fontWeight: 600 }} label={<FormattedMessage id="tabname.moduleaccess" defaultMessage="MODULE ACCESS" />} {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    {/* MENU ACCESS TAB */}
                    <TabPanel value={value} index={0}>
                        <Grid item container justifyContent='center' xs={12} sm={12} md={12} lg={12}>
                            <List style={{ width: 700 }}>
                                <ListItem className={classes.listHeader} key={0}>
                                    <ListItemIcon>
                                        <MenuIcon />
                                    </ListItemIcon>
                                    <ListItemText id="switch-list-label-menu"
                                        primary={<strong>{<FormattedMessage id="tabname.menuheading" defaultMessage="Menu Name" />}</strong>} />
                                    <ListItemSecondaryAction>
                                        <strong>{<FormattedMessage id="columnname.status.label" defaultMessage="Status" />}</strong><br />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                {menuList && menuList.map((menu, index) => {
                                    let icon;
                                    if (menu.iconName === 'IconSettings') {
                                        icon = IconSettings;
                                    } else if (menu.iconName === 'IconInterfaceConfiguration') {
                                        icon = IconInterfaceConfiguration;
                                    } else if (menu.iconName === 'IconMessageConfiguration') {
                                        icon = IconMessageConfiguration;
                                    } else if (menu.iconName === 'IconMessage') {
                                        icon = IconMessage;
                                    } else if (menu.iconName === 'IconOrder') {
                                        icon = IconOrder;
                                    } else if (menu.iconName === 'IconForecast') {
                                        icon = IconForecast;
                                    } else if (menu.iconName === 'IconViewRequest') {
                                        icon = IconViewRequest;
                                    } else if (menu.iconName === 'IconImportTemplate') {
                                        icon = IconImportTemplate;
                                    } else {
                                        icon = IconDefaultMenu;
                                    }
                                    return (<div>
                                        <ListItem key={index + 1}>
                                            <ListItemIcon>
                                                <Icon component={icon} />
                                            </ListItemIcon>
                                            <ListItemText id="switch-list-label" primary={<FormattedMessage id={menu.name} />} />
                                            <ListItemSecondaryAction>
                                                <Switch
                                                    edge="end"
                                                    color="primary"
                                                    className={classes.switchColor}
                                                    onChange={handleToggle(menu.menuId)}
                                                    checked={isMenuAccessibleForCurrentUser(menu.menuId)}
                                                    inputProps={{ 'aria-labelledby': `switch-list-label-${menu.name}` }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </div>);
                                })}
                            </List>
                        </Grid>
                        {isMenuAccessLoading && (<Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                            <CircularProgress />
                        </Grid>)}
                    </TabPanel>

                    {/* MODULE ACCESS TAB */}
                    <TabPanel value={value} index={1}>
                        <Box style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                            <Grid tem container justifyContent='center' xs={12} sm={12} spacing={1}>
                                <List style={{ width: 800, height: 'auto', padding: '10px 20px' }}>
                                    <ListItem key={0}>
                                        <Grid item container justifyContent='center' xs={12} sm={4} md={4} lg={4}>
                                            <ListItemText id="switch-list-label-menu"
                                                primary={<strong>{<FormattedMessage id="tabname.moduleheading" defaultMessage="Module Name" />}</strong>} />
                                        </Grid>
                                        <Grid item container justifyContent='center' xs={12} sm={8} md={8} lg={8}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    color='primary'
                                                    onChange={((event) => {
                                                        onSelectOrUnSelectAllModulePermission('createFlag', event.target.checked);
                                                    })}
                                                    name="checkAllCreate"
                                                />}
                                                label={<FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />}
                                                labelPlacement="top"
                                            />

                                            <FormControlLabel
                                                control={<Checkbox
                                                    color='primary'
                                                    onChange={((event) => {
                                                        onSelectOrUnSelectAllModulePermission('editFlag', event.target.checked);
                                                    })}
                                                    name="checkAllEdit"
                                                />}
                                                label={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                                                labelPlacement="top"
                                            />

                                            <FormControlLabel
                                                control={<Checkbox
                                                    color='primary'
                                                    onChange={((event) => {
                                                        onSelectOrUnSelectAllModulePermission('readFlag', event.target.checked);
                                                    })}
                                                    name="checkAllRead"
                                                />}
                                                label={<FormattedMessage id="common.tooltip.create" defaultMessage="Read" />}
                                                labelPlacement="top"
                                            />

                                            <FormControlLabel
                                                control={<Checkbox
                                                    color='primary'
                                                    onChange={((event) => {
                                                        onSelectOrUnSelectAllModulePermission('deleteFlag', event.target.checked);
                                                    })}
                                                    name="checkAllDelete"
                                                />}
                                                label={<FormattedMessage id="common.button.delete.tooltip" defaultMessage="Delete" />}
                                                labelPlacement="top"
                                            />
                                        </Grid>
                                    </ListItem>
                                    <Divider />
                                    {tempMenuList && tempMenuList.map((menu, index) => {
                                        return (
                                            <div>
                                                <ListItem dense className={classes.listHeader} key={index + 1}
                                                    onClick={() => {
                                                        handleOnListItemClick(index)
                                                    }}
                                                >
                                                    <ListItemText id="switch-list-label-menu"
                                                        primary={<strong><FormattedMessage id={menu.name} /></strong>} />
                                                    {(menu.menuModules && menu.menuModules.length > 0) ? (index === selectedIndex ? <ExpandLessIcon /> : <ExpandMoreIcon />) : ''}
                                                </ListItem>
                                                <Collapse in={index === selectedIndex} timeout="auto" unmountOnExit>
                                                    <List >
                                                        {menu.menuModules.map((accessModule, index) => {
                                                            return (
                                                                <div>
                                                                    <ListItem dense key={index + 10}>
                                                                        <Grid item container justifyContent='center' xs={12} sm={2} md={4} lg={4}>
                                                                            <ListItemText primary={<FormattedMessage id={accessModule.module.name} />} />
                                                                        </Grid>
                                                                        <Grid item container justifyContent='center' xs={12} sm={10} md={8} lg={8}>
                                                                            <div style={{ marginLeft: smallScreen ? '20%' : 0 }}>

                                                                                <Tooltip title={<FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />} placement="bottom">
                                                                                    <Checkbox className={classes.moduleAccessSecondaryIcon}
                                                                                        checked={isModulePermissionAvailable('createFlag', accessModule.module.moduleId)}
                                                                                        onChange={((event) => {
                                                                                            onModulePermissionChange('createFlag', accessModule.module.moduleId, event.target.checked);
                                                                                        })}
                                                                                        color='primary'
                                                                                        name={accessModule.module.name}
                                                                                    />
                                                                                </Tooltip>

                                                                                <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />} placement="bottom">
                                                                                    <Checkbox className={classes.moduleAccessSecondaryIcon}
                                                                                        checked={isModulePermissionAvailable('editFlag', accessModule.module.moduleId)}
                                                                                        onChange={((event) => {
                                                                                            onModulePermissionChange('editFlag', accessModule.module.moduleId, event.target.checked);
                                                                                        })}
                                                                                        color='primary'
                                                                                        name={accessModule.module.name}
                                                                                    />
                                                                                </Tooltip>

                                                                                <Tooltip title={<FormattedMessage id="common.tooltip.create" defaultMessage="Read" />} placement="bottom">
                                                                                    <Checkbox className={classes.moduleAccessSecondaryIcon}
                                                                                        checked={isModulePermissionAvailable('readFlag', accessModule.module.moduleId)}
                                                                                        onChange={((event) => {
                                                                                            onModulePermissionChange('readFlag', accessModule.module.moduleId, event.target.checked);
                                                                                        })}
                                                                                        color='primary'
                                                                                        name={accessModule.module.name}
                                                                                    />
                                                                                </Tooltip>

                                                                                <Tooltip title={<FormattedMessage id="common.button.delete.tooltip" defaultMessage="Delete" />} placement="bottom">
                                                                                    <Checkbox className={classes.moduleAccessSecondaryIcon}
                                                                                        checked={isModulePermissionAvailable('deleteFlag', accessModule.module.moduleId)}
                                                                                        onChange={((event) => {
                                                                                            onModulePermissionChange('deleteFlag', accessModule.module.moduleId, event.target.checked);
                                                                                        })}
                                                                                        color='primary'
                                                                                        name={accessModule.module.name}
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        </Grid>
                                                                    </ListItem>
                                                                    <Divider />
                                                                </div>
                                                            )
                                                        })}
                                                    </List>
                                                </Collapse>
                                                <Divider />
                                            </div>
                                        );
                                    })}
                                </List>
                            </Grid>
                            {isMenuAccessLoading && (<Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                <CircularProgress />
                            </Grid>)}
                        </Box>

                    </TabPanel>
                </Paper>
            </Grid>
        </React.Fragment>
    );
}

export default CreateOrEditRole;