import { CircularProgress, CssBaseline, FormControl, FormHelperText, Grid, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import { CommonService } from '../../../../services';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import useStyles from '../../CommonStyle/CommonStyle'
import { FormattedMessage } from 'react-intl';
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';

const RelatedProcessNameCombo = (props) => {

    const classes = useStyles();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const { comboProperty, control, errors, comboValue, setComboValue, bpTypeValueCombo, test, processMasterValue, msgDirection } = props;
    const [relatedProcessList, setRelatedProcessList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const relatedProcessNameData = relatedProcessList.map((process) => {
        return { process: process.process, processId: process.processId, inOut: process.inOut }
    });

    const [searchedRelatedProcessName, setSearchedRelatedProcessName] = useState(null);

    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.processNameCombo);

    useEffect(() => {
        if (searchedRelatedProcessName !== null) {
            setParamBody({ ...paramBody, body: { process: searchedRelatedProcessName, organization: { erpOrgCode: '', orgName: '' }, processType: 'MSG', bpType: bpTypeValueCombo.bpType, active: true }, sortDir: 'asc', sortKey: 'process', recordsPerPage: 100 });
        }
    }, [searchedRelatedProcessName]);

    useEffect(() => {
        if (paramBody.sortDir !== 'desc' && paramBody.sortKey !== 'updatedTime') {
            loadRelatedProcessNameData();
        }
    }, [paramBody]);

    const loadRelatedProcessNameData = () => {
        setLoading(true);
        CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.processMaster, paramBody)
            .then((response) => {
                setLoading(false);
                setRelatedProcessList(response.data.content);
            })
    }

    const key = "process"

    const filterRPNByPrimaryProcess = [...new Map(relatedProcessNameData.filter((data) => {
        return processMasterValue.processId !== data.processId && data.inOut === msgDirection
    }).map(item => [item[key], item])).values()];

    return (
        <React.Fragment>
            <CssBaseline />
            { comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth>
                    <Controller

                        render={({
                            field: { onChange } }) => (
                            <Autocomplete

                                onChange={(event, newValue) => {
                                    onChange(newValue)

                                    if (typeof newValue === 'string') {

                                        if (newValue != null) {
                                            setComboValue({
                                                newValue,
                                            });
                                        }
                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setComboValue({
                                            title: newValue.inputValue,
                                        });

                                    }
                                    else if (newValue !== null) {

                                        setComboValue(newValue);
                                    } else if (newValue === null) {
                                        setComboValue('')
                                    } else {
                                        setComboValue(newValue);
                                    }
                                }}
                                selectOnFocus
                                handleHomeEndKeys
                                value={((comboValue !== null && comboValue !== undefined) ? comboValue.process : null) || (test || null)}
                                autoHighlight
                                loadingText="Loading..."
                                loading={isLoading}
                                onOpen={() => {
                                    setSearchedRelatedProcessName('');
                                }}
                                options={filterRPNByPrimaryProcess}
                                getOptionLabel={(option) => {

                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.process;
                                }}
                                getoptionselected={(option, value) => option.process === value}
                                disabled={comboProperty.isDisabled}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        required
                                        autoFocus={comboProperty.isAutofocus}
                                        name={comboProperty.name}
                                        error={comboProperty.errors}
                                        size='small'
                                        label={comboProperty.label}
                                        variant={comboProperty.variant}
                                        InputLabelProps={{
                                            shrink: true,
                                            classes: {
                                                asterisk: classes.asterisk
                                            }
                                        }}
                                    />
                                )}

                            />
                        )}

                        name={comboProperty.name}
                        rules={{ required: comboProperty.isRequired }}
                        control={control}
                        defaultValue={{ relatedProcessName: '' }}
                    />
                    {comboProperty.isDisabled}
                    <div className={classes.error}>
                        {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                            <FormHelperText className={classes.error}>{comboProperty.label} <FormattedMessage id="textfield.error.isrequirededmessage" defaultMessage="is required" /></FormHelperText>
                        )}
                    </div>
                </FormControl>
            </Grid>)}
        </React.Fragment>
    );
};

RelatedProcessNameCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default RelatedProcessNameCombo;

