import { Badge, Button, CssBaseline, Grid, IconButton, Paper, Snackbar, Tooltip, Typography, useMediaQuery, useTheme, CircularProgress, Menu, MenuItem, MenuList } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    LicenseInfo, DataGridPro, getGridStringOperators,
    GridToolbarColumnsButton, GridToolbarDensitySelector
} from '@mui/x-data-grid-pro';
import Alert from '@mui/lab/Alert';
import CustomBreadCrumb from '../common/CustomBreadCrumb';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import { CommonService } from '../../services';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CustomFilters } from '../common/CustomFilters/CustomFilters';
import useStyles from '../common/CommonStyle/CommonStyle'
import { FormattedMessage, useIntl } from 'react-intl';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import { SupportedLanguageDetails } from '../common/SupportedLanguageDetails/SupportedLanguageDetails';
import CommonGridPagination from '../common/CommonGridPagination/CommonGridPagination';
import EditIcon from '@mui/icons-material/EditOutlined';
import IconUserManagement from '@mui/icons-material/AccountCircle';
import CustomerBasicDetails from './CreateOrEditCustomer/CustomerBasicDetails';
import CustomerAccountPolicy from './CreateOrEditCustomer/CustomerAccountPolicy';
import CommonDialogComponent from '../common/CommonDialogComponent/CommonDialogComponent';
import { useForm } from 'react-hook-form';
import AddressDetailsCustomer from './CreateOrEditCustomer/CustomerAddressDetails';
import ViewIcon from '@mui/icons-material/Visibility';
import ChipInfoList from '../common/ChipInfoList';
import { GlobalEdiApiConstants } from "../../Constants/GlobalEdiApiConstants";
import { ModuleAccessPermissionKey } from '../../Constants/ModuleAccessKey';
import IconSettings from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import BpEmailCommConfig from '../BPEmailMessageConfig/BpEmailCommConfig';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomFilterComponent from '../common/CustomFilterComponent/CustomFilterComponent';
import { GetFilterableColumns } from '../common/CustomFilterComponent';
import { manageUserPreferences } from '../../services/manageUserPreferences';
import saveAs from 'file-saver';
import ExportIcon from '@mui/icons-material/Publish';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CommonBackDrop from '../common/BackDrop';
import CreateIcon from '@mui/icons-material/Add';


const columnFieldsName = {
    bpCode: 'bpCode',
    orgName: 'organization.orgName',
    entityName: 'entity.entityName',
    bpCustomerName: 'bpCustomerName',
    contactPersonSalutation: 'contactPersonSalutation',
    contactPersonName: 'contactPersonName',
    contactPersonEmailId: 'contactPersonEmailId',
    contactPersonPhoneNo: 'contactPersonPhoneNo',
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    addressLine3: 'addressLine3',
    city: 'city',
    state: 'state',
    country: 'country',
    countryCode: 'countryCode',
    postalCode: 'postalCode',
    language: 'language',
    currency: 'currency',
    telNo: 'telNo',
    fax: 'fax',
    bankAccount: 'bankAccount',
    accountHolder: 'accountHolder',
    bankKey: 'bankKey',
    vatRegNo: 'vatRegNo',
    customerAccountGroup: 'customerAccountGroup',
    bpGroupName: 'bpGroupName',
    updatedBy: 'updatedBy',
    updatedTime: 'updatedTime',
    createdBy: 'createdBy',
    createdTime: 'createdTime',
    erpEntityCode: 'entity.erpEntityCode',
    erpOrgCode: 'entity.erpOrgCode',
    active: 'active'
};

function Customer(props) {
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    };
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const localtext = GridTextLocalization();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const customerModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.customer];
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [chipInfoList, setChipInfoList] = useState([]);
    const smallScreen = useMediaQuery(theme.breakpoints.down(889))
    const [branchValue, setBranchValue] = useState('');

    const [searchedBpCode, setSearchedBpCode] = useState('');
    const [searchedOrgCode, setSearchedOrgCode] = useState('');
    const [searchedCountryCode, setSearchedCountryCode] = useState('');
    const [searchedEntityCode, setSearchedEntityCode] = useState('');
    const [searchedBpCustomerName, setSearchedBpCustomerName] = useState('');
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
    const [mode, setMode] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [searchedEntityName, setSearchedEntityName] = useState('');
    const currentUser = CommonService.getLoggedInUserInfo();
    const [searchedStatus] = useState('');
    const [isResponseArrived, setResponseArrived] = useState(false);
    const [orgHeader, setOrgHeader] = useState({});
    const [isIconRequiredForTheTab] = useState(false);
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setAnchorEl(null);
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    var defaultEntity = loggedInUserInfo.defaultEntity;

    // const defaulSelectedFilters = defaultEntity !== '' ?
    //     [{ columnField: columnFieldsName.erpEntityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity }]
    //     : [];

        const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.erpEntityCode, headerName: intl.formatMessage({ id: "columnname.entitycode.label" }), id: 0, operatorValue: "contains", value: defaultEntity }]
        : [];
    const [keyValueData, setKeyValueData] = useState({});
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [disiablingDownloadButtonForZeroRecords, setDisiablingDownloadButtonForZeroRecords] = useState(false)
    const [isExportXlRequested, setExportXlRequested] = useState(false);
    const anchorRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);
    const [customerObjectToBeEditedOrView, setCustomerObjectToBeEditedOrView] = useState([]);

    const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());
    const [defaultOrgObj, setDefaultOrgObj] = useState({});
    useEffect(() => {
        if (orgInfoOfLeggedUser.erpOrgCode && mode === 'create') {
            setDefaultOrgObj({
                erpOrgCode: orgInfoOfLeggedUser.erpOrgCode, orgName: orgInfoOfLeggedUser.orgName,
                valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}`
            });
        }
    }, [orgInfoOfLeggedUser, mode])

    const handleOpenState = () => {
        setOpenCustomerDialog(true)
    }

    const closeCustomerDialog = (confirmed) => {
        if (confirmed) {
            setRefreshTable(!refreshTable);
        }
        setCustomerObjectToBeEditedOrView({});
        setFormErrors({});
        setOpenCustomerDialog(false);
        setMode('')
        setOrgName('');
        setEntityName('');
        setBranchValue('');

    }

    const [paramBody, setParamBody] = useState(
        { ...apiUrlAndParamBodyConstants.paramBodyForGetListApis.customer, sortDir: 'asc', sortKey: 'bpCustomerName' }
    );

    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.settings" defaultMessage="Settings" />,
            iconName: IconSettings,
        },
        {
            path: '/customer',
            name: <FormattedMessage id="common.menu.breadcrumb.customer" defaultMessage="Customer" />,
            iconName: IconUserManagement,
        }
    ];
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        requestXLReportExport(menuOptionsForExportButton[index], false);
    };


    const handleToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const { handleSubmit, reset, formState: { errors, isDirty, isValid }, trigger, getValues, control } = useForm({
        mode: "all",
        defaultValues: {
            active: true
        }
    });

    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState({
        ...apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference,
        columnsSort: {
            field: "bpCustomerName",
            sort: "asc"
        }
    });
    const [pinnedColumns, setPinnedColumns] = useState(preference.columnsPinned);
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }


    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);

    const columnForSupplier = [
        {
            field: 'action',
            headerName: intl.formatMessage({ id: "columnname.action.label" }),
            width: 120,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: false,
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <div>
                    {(customerModuleAccess !== undefined ? customerModuleAccess.editFlag : false) && (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                        placement='bottom' className={classes.actionButton} onClick={(() => {
                            setCustomerObjectToBeEditedOrView(cellParams.row)
                            resetCustomerValues(cellParams.row)
                            setOrgName(cellParams.row.organization.erpOrgCode);
                            setEntityName(cellParams.row.entity.erpEntityCode);
                            setMode('edit');
                            handleOpenState();
                        }
                        )}>
                        <span>
                            <IconButton className={classes.customeToolbar} size="large">
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>)}
                    {(customerModuleAccess !== undefined ? customerModuleAccess.readFlag : false) &&
                        (<Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.readButton} />}
                            placement='bottom' className={classes.actionButton} onClick={(() => {
                                setCustomerObjectToBeEditedOrView(cellParams.row)
                                resetCustomerValues(cellParams.row)
                                setOrgName(cellParams.row.organization.erpOrgCode);
                                setEntityName(cellParams.row.entity.erpEntityCode);
                                setMode('read');
                                handleOpenState();
                            }
                            )} >
                            <span>
                                <IconButton className={classes.customeToolbar} size="large">
                                    <ViewIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)}
                </div>
            )


        },
        {
            field: columnFieldsName.bpCustomerName,
            headerName: intl.formatMessage({ id: "columnname.textfield.customername.label" }),
            width: 250,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.bpCode,
            headerName: intl.formatMessage({ id: "common.textfield.columname.bpcode" }),
            width: 140,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.orgName,
            headerName: intl.formatMessage({ id: "columnname.OrganizationName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 290,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            valueGetter: (params) => `${params.row.organization.orgName}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.erpOrgCode,
            headerName: intl.formatMessage({ id: "columnname.organizationcode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            valueGetter: (params) => `${params.row.organization.erpOrgCode}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.entityName,
            headerName: intl.formatMessage({ id: "common.EntityName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 200,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            valueGetter: (params) => `${params.row.entity.entityName}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.erpEntityCode,
            headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 85,
            sortable: false,
            type: 'string',
            hide: false,
            filterable: true,
            valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: 'active',
            headerName: intl.formatMessage({ id: "columnname.status.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 140,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.customRenderStatusColumn
        },
        {
            field: columnFieldsName.contactPersonName,
            headerName: intl.formatMessage({ id: "columnname.contactpersonname.label" }),
            width: 280,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.contactPersonEmailId,
            headerName: intl.formatMessage({ id: "columnname.ContactPersonEmailID.label" }),
            width: 280,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.contactPersonPhoneNo,
            headerName: intl.formatMessage({ id: "common.textfield.columname.contactpersonPhoneNo" }),
            width: 240,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.addressLine1,
            headerName: intl.formatMessage({ id: "textfield.label.columname.address1" }),
            width: 250,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.addressLine2,
            headerName: intl.formatMessage({ id: "textfield.label.columname.address2" }),
            width: 250,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.addressLine3,
            headerName: intl.formatMessage({ id: "textfield.label.columname.address3" }),
            width: 250,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.city,
            headerName: intl.formatMessage({ id: "columnname.city.label" }),
            width: 170,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.state,
            headerName: intl.formatMessage({ id: "columnname.state.label" }),
            width: 170,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.country,
            headerName: intl.formatMessage({ id: "columnname.country.labele" }),
            width: 170,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.countryCode,
            headerName: intl.formatMessage({ id: "columnname.countrycode.label" }),
            width: 160,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.postalCode,
            headerName: intl.formatMessage({ id: "columnname.postalcode.label" }),
            width: 160,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.language,
            headerName: intl.formatMessage({ id: "columnname.language.label" }),
            width: 160,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.currency,
            headerName: intl.formatMessage({ id: "common.textfield.columname.Currency.label" }),
            width: 140,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.telNo,
            headerName: intl.formatMessage({ id: "columnname.telephone.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 160,
            sortable: true,
            type: 'number',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.fax,
            headerName: intl.formatMessage({ id: "columnname.faxno.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.bankAccount,
            headerName: intl.formatMessage({ id: "columnname.textfield.bankaccount.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 160,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.accountHolder,
            headerName: intl.formatMessage({ id: "columnname.textfield.accountholder.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.bankKey,
            headerName: intl.formatMessage({ id: "columnname.textfield.bankkey.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            width: 150,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.vatRegNo,
            headerName: intl.formatMessage({ id: "columnname.vatregno.label" }),
            width: 150,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },

        {
            field: columnFieldsName.customerAccountGroup,
            headerName: intl.formatMessage({ id: "common.textfield.columname.customergroup.label" }),
            width: 240,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },

        {
            field: columnFieldsName.bpGroupName,
            headerName: intl.formatMessage({ id: "common.textfield.columname.bpgroupname.label" }),
            width: 200,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },

        {
            field: columnFieldsName.updatedBy,
            headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
            width: 142,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.updatedTime,
            headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
            width: 200,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'dateTime',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.lastUpdatedDtDisp}</span>)
        },
        {
            field: columnFieldsName.createdBy,
            headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
            width: 140,
            sortable: true,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.createdTime,
            headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
            width: 200,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'dateTime',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.createdDtDisp}</span>)
        },
    ]
    const commonBreadCrumbToBeUsedForDialog = componentList;
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            setTableLoadingStatus(true);
            if (response.data === null) {
                setColumns(columnForSupplier);
                savePreference("visibility", columnForSupplier);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, columnForSupplier);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let orgName = null;
                let bpCustomerName = null;
                let orgCode = null;
                let bpCode = null;
                let entityCode = null;
                let entityName = null;
                let countryCode = null;
                let active = null;
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'bpCustomerName':
                            bpCustomerName = columns.value
                            break;
                        case 'organization.orgName':
                            orgName = columns.value
                            break;
                        case 'organization.erpOrgCode':
                            orgCode = columns.value
                            break;
                        case 'entity.erpEntityCode':
                            entityCode = columns.value
                            break;
                        case 'entity.entityName':
                            entityName = columns.value
                            break;
                        case 'bpCode':
                            bpCode = columns.value
                            break;
                        case 'countryCode':
                            countryCode = columns.value
                            break;
                        case 'active':
                            active = columns.value
                            break;
                    }
                })
                setSearchedBpCustomerName(bpCustomerName);
                setSearchedOrgCode(orgCode);
                setSearchedEntityCode(entityCode);
                setSearchedEntityName(entityName);
                setSearchedBpCode(bpCode);
                setSearchedCountryCode(countryCode);

                var filterBody = {
                    active: true,
                    bpCode: bpCode,
                    countryCode: countryCode,
                    bpCustomerName: bpCustomerName,
                    organization: {
                        erpOrgCode: orgCode, orgName: orgName,
                    },
                    entity: {
                        erpEntityCode: entityCode,
                        entityName: entityName
                    },
                    recordActiveStatus: active === '' ? 'All' : active === 'Active' ? true : active === 'In-Active' ? false : 'All',
                };
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0)
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, [selectedLanguage])

    const [tabvalue, setTabValue] = React.useState(0);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.customer, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                    setOrgHeader(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }

    }, [paramBody, refreshTable])


    const [hasErrors, setHasErrors] = useState('')

    function resetCustomerValues(selectObject) {
        reset({
            bpCode: selectObject.bpCode !== undefined ? selectObject.bpCode : '',
            bpCustomerName: selectObject.bpCustomerName !== undefined ? selectObject.bpCustomerName : '',
            bpGroupName: selectObject.bpGroupName !== undefined ? selectObject.bpGroupName : '',
            customerAccountGroup: selectObject.customerAccountGroup !== undefined ? selectObject.customerAccountGroup : ''
            ,
            language: selectObject.language !== undefined ? selectObject.language : null,
            currency: selectObject.currency !== undefined ? selectObject.currency : null,
            contactPersonSalutation: selectObject.contactPersonSalutation !== undefined ? selectObject.contactPersonSalutation : '',
            contactPersonName: selectObject.contactPersonName !== undefined ? selectObject.contactPersonName : '',
            contactPersonEmailId: selectObject.contactPersonEmailId !== undefined ? selectObject.contactPersonEmailId : '',
            contactPersonPhoneNo: selectObject.contactPersonPhoneNo !== undefined ? selectObject.contactPersonPhoneNo : '',
            addressLine1: selectObject.addressLine1 !== undefined ? selectObject.addressLine1 : '',
            addressLine2: selectObject.addressLine1 !== undefined ? selectObject.addressLine2 : '',
            addressLine3: selectObject.addressLine1 !== undefined ? selectObject.addressLine3 : '',
            city: selectObject.city !== undefined ? selectObject.city : '',
            state: selectObject.state !== undefined ? selectObject.state : '',
            country: selectObject.country !== undefined ? selectObject.country : '',
            countryCode: selectObject.countryCode !== undefined ? selectObject.countryCode : '',
            postalCode: selectObject.postalCode !== undefined ? selectObject.postalCode : '',
            telNo: selectObject.telNo !== undefined ? selectObject.telNo : '',
            fax: selectObject.fax !== undefined ? selectObject.fax : '',
            bankAccount: selectObject.bankAccount !== undefined ? selectObject.bankAccount : '',
            accountHolder: selectObject.accountHolder !== undefined ? selectObject.accountHolder : '',
            bankKey: selectObject.bankKey !== undefined ? selectObject.bankKey : '',
            vatRegNo: selectObject.vatRegNo !== undefined ? selectObject.vatRegNo : '',
            // entity: selectObject.entity !== undefined ? { erpEntityCode: selectObject.entity.erpEntityCode } : null,
            entity: selectObject.entity !== undefined ? { erpEntityCode: selectObject.entity.erpEntityCode, entityName: selectObject.entity.entityName } : [],
            erpInterfaceHdr: selectObject.erpInterfaceHdr !== undefined ? { intfHdrId: selectObject.erpInterfaceHdr.intfHdrId } : null,
            // organization: selectObject.organization !== undefined ? { valueToBeDisplayed: selectObject.organization } : null,
            active: selectObject.active !== undefined ? selectObject.active : true,
        });
    }

    const [orgName, setOrgName] = useState([])
    const [entityName, setEntityName] = useState([])
    const [erpInterfaceHdrId, setErpInterfaceHdrId] = useState('')

    useEffect(() => {
        CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.erpInterfaceHdr, {
            body: {
                entity: branchValue.erpEntityCode,
                active: true,
                recordActiveStatus: 'All'
            },
            pageNo: 0,
            sortDir: 'desc',
            sortKey: 'updatedTime',
            recordsPerPage: 25
        })
            .then((response) => {
                setErpInterfaceHdrId(response.data.content[0].intfHdrId);
            })
    }, [branchValue])

    
    const onSubmit = data => {
        data['erpInterfaceHdr'] = (customerObjectToBeEditedOrView.erpInterfaceHdr === undefined) ? { intfHdrId: erpInterfaceHdrId } : { intfHdrId: customerObjectToBeEditedOrView.erpInterfaceHdr.intfHdrId };
        data['organization'] = (customerObjectToBeEditedOrView.organization === undefined) ? { erpOrgCode: orgInfoOfLeggedUser.erpOrgCode } : customerObjectToBeEditedOrView.organization;
        data['entity'] = (customerObjectToBeEditedOrView.entity === undefined) ? branchValue.erpEntityCode : { erpEntityCode: customerObjectToBeEditedOrView.entity.erpEntityCode };
        data['createdBy'] = customerObjectToBeEditedOrView.createdBy !== undefined ? customerObjectToBeEditedOrView.createdBy : '';
        data['createdTime'] = customerObjectToBeEditedOrView.createdTime !== undefined ? customerObjectToBeEditedOrView.createdTime : null;
        data['updatedBy'] = currentUser.userName;
        setResponseArrived(true);
        CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.customer, data)
            .then((response) => {
                setResponseArrived(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success" });
                closeCustomerDialog(true);
            })
            .catch(function (error) {
                setResponseArrived(false);;
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            });
    }

    if (columns.length > 0) {
        const bpCustomerNameColumn = columns.find((column) => column.field === columnFieldsName.bpCustomerName);
        const bpCustomerNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.bpCustomerName);

        const bpCustomerNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.CustomerInputValue,
            }),
        );

        columns[bpCustomerNameColIndex] = {
            ...bpCustomerNameColumn,
            filterOperators: bpCustomerNameFilterOperators,
        };

        const orgCodeColumn = columns.find((column) => column.field === columnFieldsName.erpOrgCode);
        const orgCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpOrgCode);

        const orgCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgCodeInputValue,
            }),
        );

        columns[orgCodeColIndex] = {
            ...orgCodeColumn,
            filterOperators: orgCodeFilterOperators,
        };

        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };
        const orgNameColumn = columns.find((column) => column.field === columnFieldsName.orgName);
        const orgNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.orgName);

        const orgNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgNameInputValue,
            }),
        );

        columns[orgNameColIndex] = {
            ...orgNameColumn,
            filterOperators: orgNameFilterOperators,
        };
        const entityNameColumn = columns.find((column) => column.field === columnFieldsName.entityName);
        const entityNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.entityName);

        const entityNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityNameInputValue,
            }),
        );

        columns[entityNameColIndex] = {
            ...entityNameColumn,
            filterOperators: entityNameFilterOperators,
        };
        
        const statusColumn = columns.find((column) => column.field === columnFieldsName.active);
        const statusColIndex = columns.findIndex((col) => col.field === columnFieldsName.active);

        const statusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.StatusColInputValue,
            }),
        );

        columns[statusColIndex] = {
            ...statusColumn,
            filterOperators: statusFilterOperators,
        };
    }

    const resetAllClearTheBasicInfo = () => {
        if (mode === 'edit') {
            resetCustomerValues(customerObjectToBeEditedOrView);
            setCustomerObjectToBeEditedOrView({ ...customerObjectToBeEditedOrView })
            setTabValue(0);
        } else {
            reset({
                bpCode: '',
                bpCustomerName: '',
                contactPersonSalutation: '',
                contactPersonName: '',
                contactPersonEmailId: '',
                contactPersonPhoneNo: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                state: '',
                country: '',
                countryCode: '',
                postalCode: '',
                language: '',
                currency: '',
                telNo: '',
                fax: '',
                bankAccount: '',
                accountHolder: '',
                bankKey: '',
                vatRegNo: '',
                customerAccountGroup: '',
                bpGroupName: '',
                active: true
            })
            setTabValue(0);
            setDefaultOrgObj({ ...defaultOrgObj, valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}` });

        }
    }

    const onError = errors => {
        setFormErrors(errors);
    }

    useEffect(() => {
        if (totalRowCount === 0) {
            setDisiablingDownloadButtonForZeroRecords(true)
        } else {
            setDisiablingDownloadButtonForZeroRecords(false)
        }
    }, [totalRowCount])

    const menuOptionsForExportButton = [
        {
            label: intl.formatMessage({ id: "uploadCustomer.button" }),
            tooltipTitle: intl.formatMessage({ id: "exportbuttonCust.Tooltip" }),
            url: '/reports/SYS_CUSTOMER_REPORT',
            successMessage: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />,
            parameters: keyValueData
        },
        {
            label: intl.formatMessage({ id: "expBpEmailMsgConfigCust.button" }),
            tooltipTitle: intl.formatMessage({ id: "expBpEmailMsgConfigCust.tooltip" }),
            url: '/reports/SYS_CUST_BP_EMAIL_MSG_CONFIG_REPORT',
            successMessage: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />,
            parameters: keyValueData
        }
    ]
    let filterBodyObj = {
        bpCustomerName: searchedBpCustomerName === '' ? null : searchedBpCustomerName,
        bpCode: searchedBpCode === '' ? null : searchedBpCode,
        erpOrgCode: searchedOrgCode === '' ? null : searchedOrgCode,
        entityName: searchedEntityName === '' ? null : searchedEntityName,
        erpEntityCode: searchedEntityCode === null ? "" : searchedEntityCode,
        countryCode: searchedCountryCode === '' ? null : searchedCountryCode,

    }

    useEffect(() => {
        let colValDataObj = {}
        for (let key in filterBodyObj) {
            let value = filterBodyObj[key]
            if (filterBodyObj.hasOwnProperty(key) && ((value !== null) && (value !== 0) && (value !== undefined) && (value !== {}))) {
                colValDataObj[key] = value.toString();
            }
            setKeyValueData(colValDataObj)
        }
    }, [searchedBpCustomerName, searchedBpCode, searchedOrgCode, searchedEntityCode, searchedEntityName, searchedCountryCode]);


    const requestXLReportExport = (selectedExportReportTypeObj) => {
        setExportXlRequested(true);
        setOpenBackDrop(true);
        CommonService.saveOrUpdateOrPost(selectedExportReportTypeObj.url, selectedExportReportTypeObj.parameters)

            .then((response) => {
                if (response.data.sucess === "No records found for given condition") {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.sucess, severity: "error" });
                } else {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success" });
                }
                setOpenBackDrop(false);
                setExportXlRequested(false);

            })
            .catch(function (error) {
                setExportXlRequested(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                setOpenBackDrop(false);
            });
    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let orgName = null;
        let bpCustomerName = null;
        let orgCode = null;
        let bpCode = null;
        let entityCode = null;
        let entityName = null;
        let countryCode = null;
        let active = null;
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'bpCustomerName':
                    bpCustomerName = columns.value
                    break;
                case 'organization.orgName':
                    orgName = columns.value
                    break;
                case 'organization.erpOrgCode':
                    orgCode = columns.value
                    break;
                case 'entity.erpEntityCode':
                    entityCode = columns.value
                    break;
                case 'entity.entityName':
                    entityName = columns.value
                    break;
                case 'bpCode':
                    bpCode = columns.value
                    break;
                case 'countryCode':
                    countryCode = columns.value
                    break;
                case 'active':
                    active = columns.value
                    break;
            }
        })
        setSearchedBpCustomerName(bpCustomerName);
        setSearchedOrgCode(orgCode);
        setSearchedEntityCode(entityCode);
        setSearchedEntityName(entityName);
        setSearchedBpCode(bpCode);
        setSearchedCountryCode(countryCode);

        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);
        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    active: true,
                    bpCode: bpCode,
                    countryCode: countryCode,
                    bpCustomerName: bpCustomerName,
                    organization: {
                        erpOrgCode: orgCode, orgName: orgName,
                    },
                    entity: {
                        erpEntityCode: entityCode,
                        entityName: entityName
                    },
                   recordActiveStatus: active === '' ? 'All' : active === 'Active' ? true : active === 'In-Active' ? false : 'All',
                }
            }
        })
    }

    useEffect(() => {
        if (intialParamBodyApiCall)
            setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData]);

    const validateRequiredFieldsForBasicDetails = ((!(getValues('entity') !== '' && getValues('bpCustomerName') !== '' && getValues('bpCode') !== '')));

    const validateRequiredFieldsForTab1 = false;

    const validateRequiredFieldsForTab2 = false;

    const validateRequiredFieldsForTab3 = false;

    const validateRequiredFieldsForTab4 = false;

    const validateRequiredFieldsForTab5 = false;

    const errorObjectsCollectedForTab1 = (!(hasErrors['entity'] !== undefined || hasErrors['bpCustomerName'] !== undefined || hasErrors['bpCode'] !== undefined))

    const errorObjectsCollectedForTab2 = (!(hasErrors['addressLine1'] !== undefined || hasErrors['city'] !== undefined || hasErrors['state'] !== undefined || hasErrors['country'] !== undefined || hasErrors['countryCode'] !== undefined || hasErrors['postalCode'] !== undefined || hasErrors['telNo'] !== undefined || hasErrors['faxNo'] !== undefined))

    const errorObjectsCollectedForTab3 = true;

    const errorObjectsCollectedForTab4 = (!(hasErrors['bankAccount'] !== undefined || hasErrors['accountHolder'] !== undefined || hasErrors['bankKey'] !== undefined));

    const errorObjectsCollectedForTab5 = true;

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForSupplier}>
            <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6} sm={6} md={6} lg={6} className={classes.topGrid}>
                    {(customerModuleAccess !== undefined ? customerModuleAccess.createFlag : false) &&
                        (<Button size='small' variant="contained" color="primary" className={classes.createButton} elevation={2} endIcon={smallScreen ? null : <CreateIcon />} onClick={() => {
                            handleOpenState()
                            setMode('create');
                        }}>

                            {smallScreen ? <CreateIcon /> : <FormattedMessage id="common.button.Create.label" defaultMessage="Create" />}
                        </Button>)}
                </Grid>
                <Grid item container xs={12} sm={12} md={12} lg={12} >
                    <CommonDialogComponent
                        open={openCustomerDialog}
                        onClose={closeCustomerDialog}
                        title={commonBreadCrumbToBeUsedForDialog}
                       
                        basicDetails={<CustomerBasicDetails mode={mode} errors={errors} control={control} basicDetail={customerObjectToBeEditedOrView}  branchValue={branchValue} setBranchValue={setBranchValue} />}
                        contactDetailsForCustomer={<BpEmailCommConfig mode={mode} setMode={setMode} componentListForBp={componentList} customerObjectToBeEditedOrView={customerObjectToBeEditedOrView} />}
                        addressDetails={<AddressDetailsCustomer mode={mode} errors={errors} control={control} />}
                        bankOrAccountPolicyDetails={<CustomerAccountPolicy mode={mode} errors={errors} control={control} />}
                        reset={resetAllClearTheBasicInfo}
                        handleSubmit={handleSubmit}
                        submit={onSubmit}
                        contactDetailsTabtitle={<FormattedMessage id="common.contactdetails.label" defaultMessage="CONTACT DETAILS" />}
                        addressDetailsTabtitle={<FormattedMessage id="common.addressdetails.label" defaultMessage="ADDRESS DETAILS" />}
                        bankorAccountDetailstabtitle={<FormattedMessage id="common.bankdetails.label" defaultMessage="BANK DETAILS" />}
                        warningMessage={<Grid container justifyContent="flex-start" alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} >
                            {(mode === 'create' || mode === 'edit') ?
                                <InfoIcon color="primary" /> : null}
                            {(mode === 'create' || mode === 'edit') ?
                                <Typography justifyContent="flex-start" style={{ fontSize: 12, marginLeft: '1%' }}><FormattedMessage id="textfield.placeholder.dialogActions" defaultMessage="All the '*' marked fields are mandatory to submit the form" />
                                </Typography> : null}
                        </Grid>}
                        errors={onError}
                        mode={mode}
                        formErrors={formErrors}
                        isDirty={isDirty}
                        trigger={trigger}
                        getValues={getValues}
                        isValid={isValid}
                        isFormSubmitted={isResponseArrived}
                        hasErrors={!hasErrors}
                        tabvalue={tabvalue}
                        setTabValue={setTabValue}
                        orgName={orgName}
                        entityName={entityName}
                        dialogHeader={'|'}
                        isIconRequiredForTheTab={isIconRequiredForTheTab}
                        validateRequiredFieldsForBasicDetails={validateRequiredFieldsForBasicDetails}
                        validateRequiredFieldsForTab1={validateRequiredFieldsForTab1}
                        validateRequiredFieldsForTab2={validateRequiredFieldsForTab2}
                        validateRequiredFieldsForTab3={validateRequiredFieldsForTab3}
                        validateRequiredFieldsForTab4={validateRequiredFieldsForTab4}
                        validateRequiredFieldsForTab5={validateRequiredFieldsForTab5}
                        errorObjectsCollectedForTab1={errorObjectsCollectedForTab1}
                        errorObjectsCollectedForTab2={errorObjectsCollectedForTab2}
                        errorObjectsCollectedForTab3={errorObjectsCollectedForTab3}
                        errorObjectsCollectedForTab4={errorObjectsCollectedForTab4}
                        errorObjectsCollectedForTab5={errorObjectsCollectedForTab5}

                    />
                </Grid>
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <ChipInfoList chipInfoList={chipInfoList} />
                <Paper elevation={3} className={classes.gridHeightAndWidthForEntRoleUserSup}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            disableColumnFilter={true}
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            hideFooter={true}
                            localeText={localtext}
                            density="compact"
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'><GridToolbarDensitySelector variant="outlined" size="medium" className={classes.toobarStyle} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterListIcon />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip
                                                        title={menuOptionsForExportButton[selectedIndex].tooltipTitle} placement='bottom'
                                                    >
                                                        <span>
                                                            <Button size='small'
                                                                variant="outlined"
                                                                color="primary"
                                                                ref={anchorRef}
                                                                disabled={tableData.length <= 0 || isExportXlRequested}
                                                                endIcon={isExportXlRequested ? <CircularProgress color="secondary" size={20} /> : null}
                                                                onClick={handleToggle}
                                                            >
                                                                <ExportIcon />
                                                                <Typography style={{ margin: '0 5px 0 5px', fontWeight: 500, fontSize: '13px' }} ><FormattedMessage id="export.defaulttext.button" defaultMessage="EXPORT" /></Typography>
                                                                <ArrowDropDownIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>

                                                    <Menu
                                                        id="selectReportDownload"
                                                        anchorPosition={{ left: 280, top: 150 }}
                                                        anchorReference="anchorPosition"
                                                        anchorEl={anchorEl}
                                                        elevation={5}
                                                        open={Boolean(anchorEl)}
                                                        onClose={handleClose}
                                                    >
                                                        <MenuList>
                                                            {menuOptionsForExportButton.map((option, index) => (
                                                                <Tooltip title={menuOptionsForExportButton[index].tooltipTitle} placement="right-start">
                                                                    <span>
                                                                        <MenuItem
                                                                            divider
                                                                            className={classes.menuListStyle}
                                                                            key={index}
                                                                            selected={index === selectedIndex}
                                                                            onClick={(event) => handleMenuItemClick(event, index)}
                                                                        >
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    </span>
                                                                </Tooltip>
                                                            ))}
                                                        </MenuList>
                                                    </Menu>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            hideFooterSelectedRowCount={true}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </div>
                </Paper>
            </Grid>
        </React.Fragment>
    );
}

export default Customer;