import React, { useContext } from 'react'
import clsx from 'clsx'
import { Link, useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconProfile from '@mui/icons-material/AccountBox'
import IconUpdatePassword from '@mui/icons-material/Update';
import IconLogout from '@mui/icons-material/ExitToApp'
import { CommonService } from '../../../services'
import { LoggedInUserDetails } from '../LoggedInUserDetails/LoggedInUserDetails'
import useStyles from '../CommonStyle/CommonStyle'
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const AppHeaderProfile = (props) => {
  const classes = useStyles()
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null)
  const user = CommonService.getLoggedInUserInfo();

  const {loggedInUserInfo, setLoggedInUserInfo} = useContext(LoggedInUserDetails);

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12}>
      <div className={clsx('headerProfile', classes.headerProfile)}>
        <IconButton
          edge="start"
          color="secondary"
          aria-label="Search"
          className={classes.profileButton}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="large">
          <Avatar
            className={classes.profileAvatar}
           alt={user !== null ? user.userName : ''}
          />
          <span className={classes.profileName}>{user !== null ? user.userName : ''}</span>
          <IconArrowDropDown />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          classes={{
            paper: classes.profileMenu,
          }}
        >
          {/* <MenuItem onClick={handleClose} 
          component={Link} to="/profile"
          >
            <ListItemIcon className={classes.profileMenuItemIcon}>
              <IconProfile />
            </ListItemIcon>
            <ListItemText primary="Profile"/>
          </MenuItem> */}
          <MenuItem onClick={(() => {
            history.push(`/changePassword`, { renderScreenFor: 'changePassword', emailId: '' });
            handleClose();
        })}
          >
            <ListItemIcon className={classes.profileMenuItemIcon}>
              <IconUpdatePassword />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage id="dialogtitle.changepassword.label" defaultMessage="Change Password" />} />
          </MenuItem>
         <Divider style={{ backgroundColor: 'white' }} />
          <MenuItem onClick={(() => {
           setLoggedInUserInfo({ ...loggedInUserInfo, logInStatus: false, roleModuleAccess: [] });
            CommonService.Logout();
          })}
              component={Link} to="/login"
            >
              <ListItemIcon className={classes.profileMenuItemIcon}>
                <IconLogout />
              </ListItemIcon>
              <ListItemText primary={<FormattedMessage id="logout.lable" defaultMessage="Logout" />} />
            </MenuItem>
          </Menu>
      </div>
      </Grid>
  );
}

export default AppHeaderProfile
