import { Button, CssBaseline, FormControl, FormHelperText, Grid, InputLabel, Paper, Snackbar, Switch, TextareaAutosize, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Alert } from '@mui/lab';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { CommonService } from '../../../services';
import CommonBackDrop from '../../common/BackDrop';
import useStyles from '../../common/CommonStyle/CommonStyle';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import IconBack from '@mui/icons-material/BackspaceOutlined';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconMessageConfig from '@mui/icons-material/Message';
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import EntityCodeCombo from '../../common/Combos/EntityCodeCombo';
import BpTypeCombo from '../../common/Combos/BpTypeCombo/BpTypeCombo';
import ViewIcon from '@mui/icons-material/Visibility';
import BpCodeCombo from '../../common/Combos/BpCodeCombo/BpCodeCombo';
import _ from "lodash/fp";
import MessageConfigDtl from '../MessageConfigDtl/MessageConfigDtl';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import InfoIcon from '@mui/icons-material/Info';

function CreateOrEditMessageConfigHdr(props) {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const commonPatterns = GlobalEdiApiConstants();
    const { mode, configId } = props.location.state;
    const currentUser = CommonService.getLoggedInUserInfo();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const mobileScreen = useMediaQuery(theme.breakpoints.down(889))
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const messageConfigModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.messageConfig];
    const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());
    const [defaultOrgObj, setDefaultOrgObj] = useState({});
    useEffect(() => {
        if (orgInfoOfLeggedUser.erpOrgCode && mode === 'create') {
            setDefaultOrgObj({ erpOrgCode: orgInfoOfLeggedUser.erpOrgCode, orgName: orgInfoOfLeggedUser.orgName, valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}` });
        }
    }, [orgInfoOfLeggedUser])

    const pageLabelsConstantsAndMessages = {
        name: {
            organization: 'organization',
            entity: 'entity',
            active: 'active',
            bpType: 'bpType',
            bpCode: 'bpCode',
            emailTo: 'emailTo',
            emailCc: 'emailCc',
        }
    }
    const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('');
    const [componentList, setComponentList] = useState([]);
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
    const [disableSaveOrUpdateButton, issaveOrUpdateButtonDisabled] = useState(false);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [messageConfigToBeEdited, setMessageConfigToBeEdited] = React.useState({});
    const [msgConfigLoadedInitially, setMsgConfigLoadedInitially] = React.useState({});
    const [branchValue, setBranchValue] = useState('');
    const [orgValue, setOrgValue] = useState({ erpOrgCode: '', orgName: '' });
    const [bpTypeValue, setBpTypeValue] = useState('');
    const [bpCodeValue, setBpCodeValue] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [disabledForReadMode, isDisabledForReadMode] = useState(false);

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const [userReadOnlyFields, setUserReadOnlyFields] = useState({
        organization: false,
        entity: false,
        active: false,
        bpType: false,
        bpCode: false,
        emailTo: false,
        emailCc: false
    })

    const { handleSubmit, formState: { errors }, reset, control } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: {
            organization: '',
            entity: '',
            bpType: '',
            bpCode: '',
            emailTo: '',
            emailCc: '',
            active: true,
        },
    });

    useEffect(() => {
        if (configId > 0) {
            CommonService.getByIdApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.messageConfigHdr, configId)
                .then((response) => {
                    setMessageConfigToBeEdited(response.data);
                    setMsgConfigLoadedInitially(response.data);
                    msgConfigHdrResettingValues(response.data);
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    setOpenBackDrop(false);
                });
        }
    }, [mode, configId]);

    function msgConfigHdrResettingValues(responseData) {
        reset({
            organization: responseData.organization !== undefined ? { erpOrgCode: responseData.organization.erpOrgCode, orgName: responseData.organization.orgName } : defaultOrgObj,
            entity: responseData.entity !== undefined ? { erpEntityCode: responseData.entity.erpEntityCode, entityName: responseData.entity.entityName } : null,
            bpType: responseData.bpType !== undefined ? { id: 1, value: responseData.bpType } : '',
            bpCode: responseData.bpCode !== undefined ? { bpCode: responseData.bpCode } : '',
            emailTo: responseData.emailTo !== undefined ? responseData.emailTo : '',
            emailCc: responseData.emailCc !== undefined ? responseData.emailCc : '',
            active: responseData.active !== undefined ? responseData.active : true,
        });
        setOrgValue(responseData.organization !== undefined ? { valueToBeDisplayed: responseData.organization } : defaultOrgObj);
        setBranchValue(responseData.entity !== undefined ? { valuesToBeDisplayed: responseData.entity } : []);
        setBpTypeValue(responseData.bpType !== undefined ? { id: 1, value: responseData.bpType } : []);
        setBpCodeValue(responseData.bpCode !== undefined ? {
            valueToBeDisplayed: `${responseData.bpCode}-${responseData.bpName}`,
        } : []);
    }

    useEffect(() => {
        if (mode === 'create') {
            msgConfigHdrResettingValues({});
        }
    }, [defaultOrgObj])

    useEffect(() => {
        setOpenBackDrop(true);
        let currentRouterComponent;
        if (mode === 'create' && configId === 0) {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            isRestoreButtonDisabled(false);
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                iconName: CreateIcon,
            }
            setOpenBackDrop(false);
        } else if (mode === 'read') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isRestoreButtonDisabled(true);
            issaveOrUpdateButtonDisabled(true);
            isDisabledForReadMode(true);
            setUserReadOnlyFields({ ...userReadOnlyFields, entity: true, organization: true, bpType: true, bpCode: true, emailCc: true, emailTo: true })
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
                iconName: ViewIcon,
            }
            msgConfigHdrResettingValues(messageConfigToBeEdited);
        } else {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            isRestoreButtonDisabled(false);
            currentRouterComponent = {
                path: undefined,
                name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                iconName: EditIcon,
            }
            setUserReadOnlyFields({ ...userReadOnlyFields, entity: true, organization: true, bpType: true, bpCode: true, emailCc: false, emailTo: false })
            msgConfigHdrResettingValues(messageConfigToBeEdited);
        }
        setComponentList([
            {
                path: null,
                name: <FormattedMessage id="common.menu.breadcrumb.messageconfiguration" />,
                iconName: IconMessageConfiguration,
            },
            {
                path: '/messageConfigHdrs',
                name: <FormattedMessage id="common.menu.breadcrumb.messageConfig" defaultMessage="Message Config" />,
                iconName: IconMessageConfig,
            },
            currentRouterComponent
        ])
    }, []);

    const onSubmit = data => {
        data['configId'] = configId;
        data['bpType'] = data.bpType.value;
        data['bpCode'] = data.bpCode.bpCode;
        data['createdBy'] = (mode === 'create') ? currentUser.userName : messageConfigToBeEdited.createdBy;
        data['createdTime'] = (messageConfigToBeEdited.createdTime === undefined) ? '' : messageConfigToBeEdited.createdTime;
        data['updatedBy'] = currentUser.userName;
        data['updatedTime'] = (messageConfigToBeEdited.updatedTime === undefined) ? '' : messageConfigToBeEdited.updatedTime;
        data['active'] = (messageConfigToBeEdited.active === undefined) ? true : messageConfigToBeEdited.active;
        data['organization'] = (messageConfigToBeEdited.organization === undefined) ? { erpOrgCode: orgInfoOfLeggedUser.erpOrgCode } : messageConfigToBeEdited.organization;
        data['emailTo'] = data.emailTo === '' ? null : data.emailTo;
        data['emailCc'] = data.emailCc === '' ? null : data.emailCc;
        setOpenBackDrop(true)
        CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.messageConfigHdr, data)
            .then((response) => {
                setOpenBackDrop(false);
                history.goBack();
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success" });
            })
            .catch(function (error) {
                setOpenBackDrop(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            });
    }

    function resetAllClearThePageInfo() {
        if (mode === 'edit') {
            msgConfigHdrResettingValues(msgConfigLoadedInitially);
            setMessageConfigToBeEdited(msgConfigLoadedInitially);
        } else {
            reset({
                entity: null,
                active: true,
                organization: null,
            });
            setOrgValue([]);
            setBranchValue([]);
            setBpTypeValue([]);
            setBpCodeValue([]);
            setDefaultOrgObj({ ...defaultOrgObj, valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}` });
            setMessageConfigToBeEdited({ ...messageConfigToBeEdited, active: true })
        }
    }

    const closeConfirmationDialog = (confirmed) => {
        if (confirmed) {
            setMessageConfigToBeEdited({ ...messageConfigToBeEdited, active: !messageConfigToBeEdited.active });
        }
        setOpenConfirmationDialog(false);
    }

    const handleOpenActiveInactiveConfirmation = (isActive) => {
        setConfirmationMessage(isActive ? <FormattedMessage id="common.diolog.activeswitchpopup.text" /> : <FormattedMessage id="common.diolog.inactiveswitchpopup.text" />);
        setOpenConfirmationDialog(true);
    }

    const branchComboProperty = {
        name: pageLabelsConstantsAndMessages.name.entity,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.entityCode.label" }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.entity,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.entity
    }

    const bpTypeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.bpType,
        isRequired: true,
        label: intl.formatMessage({ id: 'textfield.label.bptype' }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.bpType,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.bpType
    }

    const bpCodeComboProperty = {
        name: pageLabelsConstantsAndMessages.name.bpCode,
        isRequired: true,
        label: intl.formatMessage({ id: 'common.textfield.bpcode' }),
        isAutofocus: false,
        isDisabled: userReadOnlyFields.bpCode,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.bpCode
    }

    const resetComboValueForBpType = () => {
        setBpCodeValue([]);
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForCreateOrEditRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={6} sm={8} md={8} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6} sm={4} md={4} lg={6} className={classes.topGrid}>
                    <Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2}
                        endIcon={mobileScreen ? null : <IconBack />} onClick={history.goBack}
                    > {mobileScreen ? <IconBack /> : <FormattedMessage id="common.button.back.lable" defaultMessage="Back" />} </Button>

                    <Button size='small' disabled={disableRestoreButton}
                        variant="contained" color="secondary" className={classes.stateButtonToRestore} elevation={2}
                        endIcon={mobileScreen ? null : <IconRestore />} onClick={(() => { resetAllClearThePageInfo() })}
                    > {mobileScreen ? <IconRestore /> : <FormattedMessage id="common.button.reset.lable" defaultMessage="Reset" />} </Button>

                    {(messageConfigModuleAccess !== undefined ? messageConfigModuleAccess.createFlag : false) && (<Button size='small' disabled={disableSaveOrUpdateButton}
                        variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                        endIcon={mobileScreen ? null : <IconSaveOrUpdate />} onClick={handleSubmit(onSubmit)}
                    > {mobileScreen ? <IconSaveOrUpdate /> : saveOrUpdateButtonLabel} </Button>)}
                </Grid>
                <form className={classes.paperForCreateOrEditUser} style={{ marginTop: '-0.5%' }} onSubmit={handleSubmit(onSubmit)}>
                    <Paper className={classes.formForCreateOrEditUser} elevation={3}>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} style={{ marginTop: '-0.5%' }}>
                            <Grid item container xs={12} sm={6} md={4} lg={4}>
                                <EntityCodeCombo comboProperty={branchComboProperty} control={control} errors={errors} comboValue={branchValue} setComboValue={setBranchValue} />
                            </Grid>
                            <Grid item container xs={12} sm={6} md={4} lg={4}>
                                <BpTypeCombo comboProperty={bpTypeComboProperty} control={control} errors={errors} comboValue={bpTypeValue} setComboValue={setBpTypeValue}
                                    resetComboValueForBpType={resetComboValueForBpType}
                                />
                            </Grid>
                            {!smallScreen && <Grid item container xs={12} sm={6} md={4} lg={4}>
                                <BpCodeCombo comboProperty={bpCodeComboProperty} control={control} errors={errors}
                                    comboValue={bpCodeValue}
                                    bpTypeValue={bpTypeValue}
                                    setComboValue={setBpCodeValue}
                                    branchValue={branchValue} />
                            </Grid>}
                        </Grid>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} style={{ marginTop: '0.1%' }}>
                            {smallScreen && <Grid item container xs={12} sm={6} md={4} lg={4}>
                                <BpCodeCombo comboProperty={bpCodeComboProperty} control={control} errors={errors}
                                    comboValue={bpCodeValue}
                                    bpTypeValue={bpTypeValue}
                                    setComboValue={setBpCodeValue}
                                    branchValue={branchValue} />
                            </Grid>}
                            <Grid item container xs={12} sm={6} md={6} lg={6}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.emailTo}
                                        rules={{
                                            pattern: commonPatterns.commonTextfeildPattern.patternForEmailId
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                multiline
                                                maxRows={2}
                                                label={intl.formatMessage({ id: "columnname.emailto.label" })}
                                                error={errors.emailTo}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: userReadOnlyFields.emailTo,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={6} md={12} lg={12}>
                                        <FormHelperText><FormattedMessage id="texfield.placeholder.emailid.label"
                                            defaultMessage="Separate Multiple EmailID's with Semicolon(;)" /></FormHelperText>
                                    </Grid>
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.emailTo}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfiled.error.emailtoisrequired" defaultMessage="Template Name is required" />
                                            </FormHelperText>
                                        )}

                                        {_.get(`${pageLabelsConstantsAndMessages.name.emailTo}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}><FormattedMessage id="columnname.emailto.label" defaultMessage="SIIX Email To" /> {commonPatterns.commonErrorsForTextFields.errorForEmailId}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={6} lg={6}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.emailCc}
                                        rules={{
                                            pattern: commonPatterns.commonTextfeildPattern.patternForEmailId
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="normal"
                                                id="name"
                                                size='small'
                                                multiline
                                                maxRows={2}
                                                label={intl.formatMessage({ id: "columnname.emailcc.label" })}
                                                error={errors.emailCc}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: userReadOnlyFields.emailTo,
                                                }}
                                                disabled={disabledForReadMode}
                                            />
                                        )}
                                    />
                                    <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={6} md={12} lg={12}>
                                        <FormHelperText><FormattedMessage id="texfield.placeholder.emailid.label"
                                            defaultMessage="Separate Multiple EmailID's with Semicolon(;)" /></FormHelperText>
                                    </Grid>
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.emailCc}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                <FormattedMessage id="textfiled.error.emailccisrequired" defaultMessage="Template Name is required" />
                                            </FormHelperText>
                                        )}

                                        {_.get(`${pageLabelsConstantsAndMessages.name.emailCc}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}><FormattedMessage id="columnname.emailcc.label" defaultMessage="SIIX Email CC" /> {commonPatterns.commonErrorsForTextFields.errorForEmailId}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={1}>
                            <Grid item container xs={12} sm={12} md={6} lg={8}>
                                <Grid container justifyContent='flex-start' alignItems='center'>
                                    <Controller
                                        render={({
                                            field: { onChange } }) => (<Switch
                                                color='primary'
                                                checked={messageConfigToBeEdited.active !== undefined ? messageConfigToBeEdited.active : true}
                                                onChange={((event) => {
                                                    handleOpenActiveInactiveConfirmation(event.target.checked);
                                                })}
                                                disabled={disabledForReadMode}
                                            />)}
                                        type="checkbox"
                                        name={pageLabelsConstantsAndMessages.name.active}
                                        control={control}
                                        defaultValue={messageConfigToBeEdited.active !== undefined ? messageConfigToBeEdited.active : true}
                                    />
                                    <InputLabel>{<FormattedMessage id="common.active.lable" />}</InputLabel>
                                </Grid>
                            </Grid>
                            {(mode === 'create') ? <Grid item container alignItems="center" xs={12} sm={12} md={6} lg={4}>
                                <InfoIcon color="primary" />
                                <Typography style={{ marginLeft: '1%', fontSize: '13px' }}>{<FormattedMessage id="typography.Onceafteryoufill.message" />}</Typography>
                            </Grid> : ''}
                        </Grid>
                    </Paper>
                    <ConfirmationDialog onClose={closeConfirmationDialog} message={confirmationMessage} open={openConfirmationDialog} />
                </form>
                <Grid container style={{ width: '120%', marginTop: '5px', marginLeft: '-6px', marginRight: '-7px' }}>
                    <MessageConfigDtl configId={configId} mode={mode} messageConfigToBeEdited={messageConfigToBeEdited} />
                </Grid>
            </Grid>
        </React.Fragment >
    );
}

export default CreateOrEditMessageConfigHdr;