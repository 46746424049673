import { CssBaseline, FormControl, FormHelperText, Grid,TextField, CircularProgress, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import { CommonService } from '../../../../services';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import useStyles from '../../CommonStyle/CommonStyle'

const CountryCombo = (props) => {

    const classes = useStyles();
    const { comboProperty, control, errors, comboValue, setComboValue } = props;
    const [countryList, setCountryList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const countryData = countryList.map((country) => {
        return { countryName: country.countryName, countryId: country.countryId }
    });
    const loadCityData = (searchedCountryName) => {

        if (searchedCountryName !== null && searchedCountryName !== "") {
            setLoading(true);
        }
        CommonService.GetListApi('/countries', {
            body: {
                countryId: 0,
                countryName: searchedCountryName,
                active: true
            },
            pageNo: 0,
            sortDir: 'asc',
            sortKey: 'countryName',
            recordsPerPage: 100
        })
            .then((response) => {
                setCountryList(response.data.content);
                setLoading(false);
            })
    }

    return (
        <React.Fragment>
            <CssBaseline />
        { comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth>
                <Controller
                    render={props => (
                        <Autocomplete

                            onChange={(event, newValue) => {
                                props.onChange(newValue)

                                if (typeof newValue === 'string') {

                                    if (newValue != null) {
                                        setComboValue({
                                            newValue,
                                        });
                                    }
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    setComboValue({
                                        title: newValue.inputValue,
                                    });

                                }
                                else if (newValue !== null) {
                                    setComboValue(newValue);
                                } else if (newValue === null) {
                                    setComboValue('');
                                } else {
                                    setComboValue(newValue);
                                }
                            }}
                            loadingText="Loading..."
                            loading={isLoading}
                            selectOnFocus
                            handleHomeEndKeys
                            value={comboValue.countryName || null}
                            autoHighlight
                            onOpen={() => {
                                loadCityData('');
                            }}
                            options={countryData}
                            getOptionLabel={(option) => {

                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.countryName;
                            }}
                            getoptionselected={(option, value) => option.countryName === value}
                            disabled={comboProperty.isDisabled}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    autoFocus={comboProperty.isAutofocus}
                                    name={comboProperty.name}
                                    size='small'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                    variant={comboProperty.variant}
                                />
                            )}

                        />
                    )}

                    name={comboProperty.name}
                    rules={{ required: comboProperty.isRequired }}
                    control={control}
                    defaultValue={{ countryId: 0, countryName: '' }}
                />
                {comboProperty.isDisabled && (<FormHelperText>{comboProperty.label} can't be edited</FormHelperText>)}
                <div className={classes.error}>
                    {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                        <FormHelperText className={classes.error}>{comboProperty.label} is required</FormHelperText>
                    )}
                </div>
            </FormControl>
        </Grid>)}
        { (!comboProperty.isHookFormType) && (<Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: "3px"}}>
        <FormControl fullWidth>
            <Autocomplete
                        fullWidth
                        openOnFocus = {true}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {

                                if (newValue != null) {
                                    setComboValue({
                                        newValue,
                                    });
                                }
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setComboValue({
                                    title: newValue.inputValue,
                                });
                            }
                            else if (newValue !== null) {

                                setComboValue(newValue);
                            } else if (newValue === null) {
                                setComboValue('')
                            } else {
                                setComboValue(newValue);
                            }
                        }}
                        selectOnFocus
                        loadingText="Loading..."
                        loading={isLoading}
                        handleHomeEndKeys
                        value={comboValue.countryName || null}
                        autoHighlight
                        onOpen={() => {
                            loadCityData('');
                        }}
                        options={countryData}
                        getOptionLabel={(option) => {

                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.countryName;
                        }}
                        getoptionselected={(option, value) => option.countryName === value}
                        disabled={comboProperty.isDisabled}
                        renderInput={params => (
                            <Tooltip title={comboValue.countryName || ''}>
                            <TextField
                                {...params}
                                autoFocus={comboProperty.isAutofocus}
                                name={comboProperty.name}
                                size='small'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(ev) => {
                                    if (
                                        ev.target.value !== "" ||
                                        ev.target.value !== null
                                    ) {
                                        loadCityData(ev.target.value);
                                    } 
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {isLoading ?
                                                <CircularProgress color="inherit" size={20} />
                                            : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                variant={comboProperty.variant}
                            />
                            </Tooltip>
                        )}

                    />
        </FormControl>
        </Grid>)}
        </React.Fragment>
    );
};

CountryCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default CountryCombo;

