import { CircularProgress, CssBaseline, FormControl, FormHelperText, Grid, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import { CommonService } from '../../../../services';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import useStyles from '../../CommonStyle/CommonStyle'
import { FormattedMessage } from 'react-intl';
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';

const BpCodeCombo = (props) => {

    const classes = useStyles();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const { comboProperty, control, errors, comboValue, setComboValue, bpTypeValue, branchValue } = props;
    const [isLoading, setLoading] = useState(false);
    const [supplierAndCustomerList, setSupplierAndCustomerList] = useState([]);
    const defaultSupplier = [{
        name: 'All Supplier',
        bpCode: 'All Supplier BP Code',
        valueToBeDisplayed: `All Supplier BP Code - All Supplier`
    }];
    const defaultCustomer = [{
        name: 'All Customer',
        bpCode: 'All Customer BP Code',
        valueToBeDisplayed: `All Customer BP Code - All Customer`
    }]
    const customerAndSupplierData = supplierAndCustomerList.map((customer) => {
        return {
            bpCode: customer.bpCode, name: bpTypeValue.value === "CUSTOMER" ? customer.bpCustomerName : customer.bpSupplierName, valueToBeDisplayed: `${customer.bpCode} - ${bpTypeValue.value === "CUSTOMER" ? customer.bpCustomerName : customer.bpSupplierName}`,
            earlierAcceptanceDays: customer.earlierAcceptanceDays, lateAcceptanceDays: customer.lateAcceptanceDays, acceptPriorDelivery:
                customer.acceptPriorDelivery
        }
    }).concat(bpTypeValue.value === "CUSTOMER" ? defaultCustomer : defaultSupplier);

    const [paramBodyForSuplier, setParamBodyForSupplier] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.supplier);
    const [paramBodyForCustomer, setParamBodyForCustomer] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.customer);

    useEffect(() => {
        setParamBodyForSupplier({
            ...paramBodyForSuplier, body: {
                entity: { erpEntityCode: branchValue.erpEntityCode }, active: true, bpCode: "",
                bpSupplierName: "", bpGroupName: "", supplierAccountGroup: "", organization: { erpOrgCode: '' }, erpInterfaceHdr: { intfHdrId: '' }
            }, sortDir: 'asc', sortKey: 'bpCode', recordsPerPage: 5000
        });
        setParamBodyForCustomer({
            ...paramBodyForCustomer, body: {
                entity: { erpEntityCode: branchValue.erpEntityCode }, active: true, bpCode: "",
                bpCustomerName: "",
                bpGroupName: "",
                customerAccountGroup: "", organization: { erpOrgCode: '' }, erpInterfaceHdr: { intfHdrId: '' }
            }, sortDir: 'asc', sortKey: 'bpCode', recordsPerPage: 5000
        })
    }, [branchValue]);

    const loadCustomerAndSupplierData = () => {
        if (bpTypeValue.value === 'CUSTOMER') {
            setLoading(true);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.customer, paramBodyForCustomer)
                .then((response) => {
                    setLoading(false);
                    setSupplierAndCustomerList(response.data.content);
                })
        } else if (bpTypeValue.value === 'SUPPLIER') {
            setLoading(true);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.supplier, paramBodyForSuplier)
                .then((response) => {
                    setLoading(false);
                    setSupplierAndCustomerList(response.data.content);
                })
        }
    }

    return (
        <React.Fragment>
            <CssBaseline />
            { comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth>
                    <Controller
                        render={({
                            field: { onChange } }) => (
                            <Autocomplete
                                onChange={(event, newValue) => {
                                    onChange(newValue)

                                    if (typeof newValue === 'string') {

                                        if (newValue != null) {
                                            setComboValue({
                                                newValue,
                                            });
                                        }
                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setComboValue({
                                            title: newValue.inputValue,
                                        });

                                    }
                                    else if (newValue !== null) {

                                        setComboValue(newValue);
                                    } else if (newValue === null) {
                                        setComboValue('')
                                    } else {
                                        setComboValue(newValue);
                                    }
                                }}
                                selectOnFocus
                                handleHomeEndKeys
                                value={comboValue.valueToBeDisplayed || null}
                                autoHighlight
                                loadingText="Loading..."
                                loading={isLoading}
                                onOpen={() => {
                                    loadCustomerAndSupplierData('');
                                }}
                                options={customerAndSupplierData}
                                getOptionLabel={(option) => {

                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return `${option.bpCode} - ${option.name}`;
                                }}
                                getoptionselected={(option, value) => option.bpCode === value}
                                disabled={comboProperty.isDisabled}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        required={comboProperty.isRequired}
                                        autoFocus={comboProperty.isAutofocus}
                                        name={comboProperty.name}
                                        size='small'
                                        label={comboProperty.label}
                                        error={comboProperty.errors}
                                        variant={comboProperty.variant}
                                        InputLabelProps={{
                                            shrink: true,
                                            classes: {
                                                asterisk: classes.asterisk
                                            }
                                        }}
                                    />
                                )}

                            />
                        )}

                        name={comboProperty.name}
                        rules={{ required: comboProperty.isRequired }}
                        control={control}
                        defaultValue={{ bpCode: '' }}
                    />
                    {comboProperty.isDisabled}
                    <div className={classes.error}>
                        {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                            <FormHelperText className={classes.error}>{comboProperty.label} <FormattedMessage id="textfield.error.isrequirededmessage" defaultMessage="is required" /></FormHelperText>
                        )}
                    </div>
                </FormControl>
            </Grid>)}

            { !comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "3px" }}>
                <FormControl fullWidth>
                    <Autocomplete
                        fullWidth
                        openOnFocus={true}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {

                                if (newValue != null) {
                                    setComboValue({
                                        newValue,
                                    });
                                }
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setComboValue({
                                    title: newValue.inputValue,
                                });

                            }
                            else if (newValue !== null) {

                                setComboValue(newValue);
                            } else if (newValue === null) {
                                setComboValue('')
                            } else {
                                setComboValue(newValue);
                            }
                        }}
                        selectOnFocus
                        loadingText="Loading..."
                        loading={isLoading}
                        handleHomeEndKeys
                        value={comboValue.bpCode || null}
                        autoHighlight
                        onOpen={() => {
                            loadCustomerAndSupplierData('');
                        }}
                        options={customerAndSupplierData}
                        getOptionLabel={(option) => {

                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return `${option.bpCode}`;
                        }}
                        getoptionselected={(option, value) => option.bpCode === value}
                        disabled={comboProperty.isDisabled}
                        renderInput={params => (
                            <Tooltip title={comboValue.bpCode || ''}>
                                <TextField
                                    {...params}
                                    autoFocus={comboProperty.isAutofocus}
                                    name={comboProperty.name}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(ev) => {
                                        if (
                                            ev.target.value !== "" ||
                                            ev.target.value !== null
                                        ) {
                                            loadCustomerAndSupplierData(ev.target.value);
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {isLoading ?
                                                    <CircularProgress color="inherit" size={20} />
                                                    : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    size='small'
                                    label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                    variant={comboProperty.variant}
                                />
                            </Tooltip>
                        )}

                    />
                </FormControl>
            </Grid>)}

        </React.Fragment>
    );
};

BpCodeCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default BpCodeCombo;

