import { CircularProgress, CssBaseline, FormControl, Grid, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@mui/lab';
import { CommonService } from '../../../../services';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import useStyles from '../../CommonStyle/CommonStyle'
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';

const BPMasterCombo = (props) => {

    const classes = useStyles();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const { comboProperty, comboValue, setComboValue } = props;
    const [supplierList, setSupplierList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const supplierData = supplierList.map((supplier) => {
        return {
            bpName: supplier.bpSupplierName
        }
    });
    const customerData = customerList.map((customer) => {
        return {
            bpName: customer.bpCustomerName
        }
    });
    const [searchedBpSupplierName, setSearchedBpSupplierName] = useState(null);
    const [searchedBpCustomerName, setSearchedBpCustomerName] = useState(null);

    const [paramBodyForSupplier, setParamBodyForSupplier] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.supplier);

    useEffect(() => {
        if (searchedBpSupplierName !== null) {
            setParamBodyForSupplier({ ...paramBodyForSupplier, body: { bpSupplierName: searchedBpSupplierName, recordActiveStatus: 'All' }, sortDir: 'asc', recordsPerPage: 2000 });
        }
    }, [searchedBpSupplierName]);

    const [paramBodyForCustomer, setParamBodyForCustomer] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.customer);

    useEffect(() => {
        if (searchedBpCustomerName !== null) {
            setParamBodyForCustomer({ ...paramBodyForCustomer, body: { bpCustomerName: searchedBpCustomerName, recordActiveStatus: 'All' }, sortDir: 'asc', recordsPerPage: 2000 });
        }
    }, [searchedBpCustomerName]);

    useEffect(() => {
        loadSupplierData();
        loadCustomerData()
    }, [paramBodyForSupplier, searchedBpCustomerName]);

    const loadSupplierData = () => {
        setLoading(true);
        CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.supplier, {
            body: {
                bpSupplierName: searchedBpSupplierName, recordActiveStatus: 'All'
            },
            sortDir: 'asc',
            recordsPerPage: 2000
        })
            .then((response) => {
                setLoading(false);
                setSupplierList(response.data.content);
            })
    }
    const loadCustomerData = () => {
        setLoading(true);
        CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.customer, {
            body: {
                bpCustomerName: searchedBpCustomerName, recordActiveStatus: 'All'
            },
            sortDir: 'asc',
            recordsPerPage: 2000
        })
            .then((response) => {
                setLoading(false);
                setCustomerList(response.data.content);
            })
    }

    const bpList = supplierData.concat(customerData);

    const key = 'bpName';

    const uniqueBpName = [...new Map(bpList.map(item => [item[key], item])).values()];


    return (
        <React.Fragment>
            <CssBaseline />
            { !comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "3px" }}>
                <FormControl fullWidth>
                    <Autocomplete
                        fullWidth
                        openOnFocus={true}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {

                                if (newValue != null) {
                                    setComboValue({
                                        newValue,
                                    });
                                }
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setComboValue({
                                    title: newValue.inputValue,
                                });

                            }
                            else if (newValue !== null) {

                                setComboValue(newValue);
                            } else if (newValue === null) {
                                setComboValue('')
                            } else {
                                setComboValue(newValue);
                            }
                        }}
                        selectOnFocus
                        loadingText="Loading..."
                        loading={isLoading}
                        handleHomeEndKeys
                        value={comboValue.bpName || null}
                        autoHighlight
                        options={uniqueBpName}
                        getOptionLabel={(option) => {

                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.bpName;
                        }}
                        getoptionselected={(option, value) => option.bpName === value}
                        disabled={comboProperty.isDisabled}
                        renderInput={params => (
                            <Tooltip title={comboValue.bpName || ''}>
                                <TextField
                                    {...params}
                                    autoFocus={comboProperty.isAutofocus}
                                    name={comboProperty.name}
                                    onChange={(ev) => {
                                        if (
                                            ev.target.value !== "" ||
                                            ev.target.value !== null
                                        ) {
                                            setSearchedBpSupplierName(ev.target.value);
                                            setSearchedBpCustomerName(ev.target.value);
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {isLoading ?
                                                    <CircularProgress color="inherit" size={20} />
                                                    : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    size='small'
                                    label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                    variant={comboProperty.variant}
                                />
                            </Tooltip>
                        )}

                    />
                </FormControl>
            </Grid>)}
        </React.Fragment>
    );
};

BPMasterCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default BPMasterCombo;

