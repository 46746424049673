import React, { useEffect, useState } from 'react';
import { CssBaseline, FormControl, FormHelperText, Grid, TextField, useTheme } from '@mui/material';
import { Controller, } from 'react-hook-form';
import _ from "lodash/fp";
import { FormattedMessage } from 'react-intl';
import useStyles from '../../../common/CommonStyle/CommonStyle';
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';


function BankDetails(props) {
    const classes = useStyles();
    let theme = useTheme();
    const { bankmode, control, errors } = props;
    const commonPatterns = GlobalEdiApiConstants();

    const [EntityReadOnlyFields, setEntityReadOnlyFields] = useState({
        bankAccount: false,
        bankName: false,
        bankKey: false,
        vatRegNo: false,
    });

    const pageLabelsConstantsAndMessages = {
        label: {
            bankAccount: 'bankAccount',
            bankName: 'bankName',
            bankKey: 'bankKey',
            vatRegNo: 'Vat Reg No',
        },
        name: {
            bankAccount: 'bankAccount',
            bankName: 'bankName',
            bankKey: 'bankKey',
            vatRegNo: 'vatRegNo'
        }
    }

    useEffect(() => {
        if (bankmode === 'view') {
            setEntityReadOnlyFields({
                ...EntityReadOnlyFields, vatRegNo: true, bankAccount: true,
                bankName: true,
                bankKey: true,
            })
        }
    }, [bankmode]);


    return (


        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.bankName}
                                    rules={{
                                        maxLength: 100,
                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphabetsOnly
                                    }}

                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="bankName"
                                            size='small'
                                            label={<FormattedMessage id="textfield.bankname.label" defaultMessage="Bank Name" />}
                                            {...field}
                                            error={errors.bankName}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={bankmode === 'view' ? true : false}
                                            InputProps={{
                                                readOnly: EntityReadOnlyFields.bankName,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                        />
                                    )}
                                />
                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.bankName}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}> {<FormattedMessage id="textfield.bankname.label" defaultMessage="Bank Name" />} {commonPatterns.commonErrorsForTextFields.errorForAplhabetsOnly}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.bankName}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="textfield.bankname.label" defaultMessage="Bank Name" />}{<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.bankAccount}
                                    rules={{
                                        maxLength: 100,
                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphaNumeric
                                    }}

                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="bankAccount"
                                            size='small'
                                            label={<FormattedMessage id="common.BankAccount.label" defaultMessage="Bank Account" />}
                                            {...field}
                                            error={errors.bankAccount}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={bankmode === 'view' ? true : false}
                                            InputProps={{
                                                readOnly: EntityReadOnlyFields.bankAccount,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                        />
                                    )}
                                />
                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.bankAccount}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}> <FormattedMessage id="common.BankAccount.label" defaultMessage="Bank Account" /> {commonPatterns.commonErrorsForTextFields.errorForAlphaNumeric}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.bankAccount}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="common.BankAccount.label" defaultMessage="Bank Account" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>



                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.vatRegNo}
                                    rules={{
                                        maxLength: 100,
                                        pattern: commonPatterns.commonTextfeildPattern.patternNumericOnly
                                    }}

                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="vatRegNo"
                                            size='small'
                                            label={<FormattedMessage id="columnname.vatregno.label" defaultMessage="Vat.Reg.No." />}
                                            {...field}
                                            error={errors.vatRegNo}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: EntityReadOnlyFields.vatRegNo,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={bankmode === 'view' ? true : false}
                                        />
                                    )}
                                />
                                {/* {EntityReadOnlyFields.vatRegNo && (<FormHelperText>{pageLabelsConstantsAndMessages.label.vatRegNo} can't be edited</FormHelperText>)} */}
                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.vatRegNo}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}> {<FormattedMessage id="common.VatRegNo.label" defaultMessage="Vat.Reg.No." />} {commonPatterns.commonErrorsForTextFields.errorForNumericOnly}</FormHelperText>
                                    )}
                                    {_.get(`${pageLabelsConstantsAndMessages.name.vatRegNo}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="common.VatRegNo.label" defaultMessage="Vat.Reg.No." />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.bankKey}
                                    rules={{
                                        maxLength: 100,
                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphaNumeric
                                    }}

                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="bankKey"
                                            size='small'
                                            label={<FormattedMessage id="common.BankKey.label" defaultMessage="Bank Key" />}
                                            {...field}
                                            error={errors.bankKey}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: EntityReadOnlyFields.bankKey,
                                                classes: { notchedOutline: classes.specialOutline }
                                            }}
                                            disabled={bankmode === 'view' ? true : false}
                                        />
                                    )}
                                />

                                <div className={classes.error}>
                                    {_.get(`${pageLabelsConstantsAndMessages.name.bankKey}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}> {<FormattedMessage id="common.BankKey.label" defaultMessage="Bank Key" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNumeric}</FormHelperText>
                                    )}

                                    {_.get(`${pageLabelsConstantsAndMessages.name.bankKey}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>{<FormattedMessage id="common.BankKey.label" defaultMessage="Bank Key" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>

    );
}

export default BankDetails;
