import { Checkbox, Divider, FormControl, FormHelperText, Grid, InputLabel, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import useStyles from '../components/common/CommonStyle/CommonStyle';
import _ from "lodash/fp";
import { Autocomplete } from '@mui/lab';
import TimeZoneCombo from './SchedulerTabCombos/TimeZoneCombos';

const everyHourValue = [
    { id: 1, value: '0', time: '0AM' }, { id: 2, value: '1', time: '1AM' }, { id: 3, value: '2', time: '2AM' }, { id: 4, value: '3', time: '3AM' },
    { id: 5, value: '4', time: '4AM' }, { id: 6, value: '5', time: '5AM' }, { id: 7, value: '6', time: '6AM' }, { id: 8, value: '7', time: '7AM' },
    { id: 9, value: '8', time: '8AM' }, { id: 10, value: '9', time: '9AM' }, { id: 11, value: '10', time: '10AM' }, { id: 12, value: '11', time: '11AM' },
    { id: 13, value: '12', time: '12AM' }, { id: 14, value: '13', time: '1PM' }, { id: 15, value: '14', time: '2PM' }, { id: 16, value: '15', time: '3PM' },
    { id: 17, value: '16', time: '4PM' }, { id: 18, value: '17', time: '5PM' }, { id: 19, value: '18', time: '6PM' }, { id: 20, value: '19', time: '7PM' },
    { id: 21, value: '20', time: '8PM' }, { id: 22, value: '21', time: '9PM' }, { id: 23, value: '22', time: '10PM' }, { id: 24, value: '23', time: '11PM' }
];

const everyMinuteValue = [
    { id: 1, value: '0', minute: '0' }, { id: 2, value: '1', minute: '1' }, { id: 3, value: '2', minute: '2' }, { id: 4, value: '3', minute: '3' }, 
    { id: 5, value: '4', minute: '4' }, { id: 6, value: '5', minute: '5' }, { id: 7, value: '6', minute: '6' }, { id: 8, value: '7', minute: '7' }, 
    { id: 9, value: '8', minute: '8' }, { id: 10, value: '9', minute: '9' }, { id: 11, value: '10', minute: '10' }, { id: 12, value: '11', minute: '11' },
    { id: 13, value: '12', minute: '12' }, { id: 14, value: '13', minute: '13' }, { id: 15, value: '14', minute: '14' }, { id: 16, value: '15', minute: '15' }, 
    { id: 17, value: '16', minute: '16' }, { id: 18, value: '17', minute: '17' }, { id: 19, value: '18', minute: '18' }, { id: 20, value: '19', minute: '19', }, 
    { id: 21, value: '20', minute: '20' }, { id: 22, value: '21', minute: '21' }, { id: 23, value: '22', minute: '22' }, { id: 24, value: '23', minute: '23' },
    { id: 25, value: '24', minute: '24' }, { id: 26, value: '25', minute: '25' }, { id: 27, value: '26', minute: '26' }, { id: 28, value: '27', minute: '27' }, 
    { id: 29, value: '28', minute: '28' }, { id: 30, value: '29', minute: '29' }, { id: 31, value: '30', minute: '30' }, { id: 32, value: '31', minute: '31' }, 
    { id: 33, value: '32', minute: '32' }, { id: 34, value: '33', minute: '33' }, { id: 35, value: '34', minute: '34' }, { id: 36, value: '35', minute: '35' },
    { id: 37, value: '36', minute: '36' }, { id: 38, value: '37', minute: '37' }, { id: 39, value: '38', minute: '38' }, { id: 40, value: '39', minute: '39' }, 
    { id: 41, value: '40', minute: '40' }, { id: 42, value: '41', minute: '41' }, { id: 43, value: '42', minute: '42' }, { id: 44, value: '43', minute: '43' }, 
    { id: 45, value: '44', minute: '44' }, { id: 46, value: '45', minute: '45' }, { id: 47, value: '46', minute: '46' }, { id: 48, value: '47', minute: '47' },
    { id: 49, value: '48', minute: '48' }, { id: 50, value: '49', minute: '49' }, { id: 51, value: '50', minute: '50' }, { id: 52, value: '51', minute: '51' }, 
    { id: 53, value: '52', minute: '52' }, { id: 54, value: '53', minute: '53' }, { id: 55, value: '54', minute: '54' }, { id: 56, value: '55', minute: '55' }, 
    { id: 57, value: '56', minute: '56' }, { id: 58, value: '57', minute: '57' }, { id: 59, value: '58', minute: '58' }, { id: 60, value: '59', minute: '59' },
    { id: 61, value: '*/1', minute: '1' }, { id: 62, value: '*/2', minute: '2' }, { id: 63, value: '*/3', minute: '3' }, { id: 64, value: '*/4', minute: '4' }, 
    { id: 65, value: '*/5', minute: '5' }, { id: 66, value: '*/6', minute: '6' }, { id: 67, value: '*/7', minute: '7' }, { id: 68, value: '*/8', minute: '8' }, 
    { id: 69, value: '*/9', minute: '9' }, { id: 70, value: '*/10', minute: '10' }, { id: 71, value: '*/11', minute: '11' }, { id: 72, value: '*/12', minute: '12' }, 
    { id: 73, value: '*/13', minute: '13' }, { id: 74, value: '*/14', minute: '14' }, { id: 75, value: '*/15', minute: '15' }, { id: 76, value: '*/16', minute: '16' }, 
    { id: 77, value: '*/17', minute: '17' }, { id: 78, value: '*/18', minute: '18' }, { id: 79, value: '*/19', minute: '19' }, { id: 80, value: '*/20', minute: '20' }, 
    { id: 81, value: '*/21', minute: '21' }, { id: 82, value: '*/22', minute: '22' }, { id: 83, value: '*/23', minute: '23' }, { id: 84, value: '*/24', minute: '24' }, 
    { id: 85, value: '*/25', minute: '25' }, { id: 86, value: '*/26', minute: '26' }, { id: 87, value: '*/27', minute: '27' }, { id: 88, value: '*/28', minute: '28' }, 
    { id: 89, value: '*/29', minute: '29' }, { id: 90, value: '*/30', minute: '30' }, { id: 91, value: '*/31', minute: '31' }, { id: 92, value: '*/32', minute: '32' }, 
    { id: 93, value: '*/33', minute: '33' }, { id: 94, value: '*/34', minute: '34' }, { id: 95, value: '*/35', minute: '35' }, { id: 96, value: '*/36', minute: '36' }, 
    { id: 97, value: '*/37', minute: '37' }, { id: 98, value: '*/38', minute: '38' }, { id: 99, value: '*/39', minute: '39' }, { id: 100, value: '*/40', minute: '40' }, 
    { id: 101, value: '*/41', minute: '41' }, { id: 102, value: '*/42', minute: '42' }, { id: 103, value: '*/43', minute: '43' }, { id: 104, value: '*/44', minute: '44' }, 
    { id: 105, value: '*/45', minute: '45' }, { id: 106, value: '*/46', minute: '46' }, { id: 107, value: '*/47', minute: '47' }, { id: 108, value: '*/48', minute: '48' }, 
    { id: 109, value: '*/49', minute: '49' }, { id: 110, value: '*/50', minute: '50' }, { id: 111, value: '*/51', minute: '51' }, { id: 112, value: '*/52', minute: '52' }, 
    { id: 113, value: '*/53', minute: '53' }, { id: 114, value: '*/54', minute: '54' }, { id: 115, value: '*/55', minute: '55' }, { id: 116, value: '*/56', minute: '56' }, 
    { id: 117, value: '*/57', minute: '57' }, { id: 118, value: '*/58', minute: '58' }, { id: 119, value: '*/59', minute: '59' }
];

const everyDayValue = [
    { id: 1, value: '1', ordinals: '1st' }, { id: 2, value: '2', ordinals: '2nd' }, { id: 3, value: '3', ordinals: '3rd' }, { id: 4, value: '4', ordinals: '4th' },
    { id: 5, value: '5', ordinals: '5th' }, { id: 6, value: '6', ordinals: '6th' }, { id: 7, value: '7', ordinals: '7th' }, { id: 8, value: '8', ordinals: '8th' },
    { id: 9, value: '9', ordinals: '9th' }, { id: 10, value: '10', ordinals: '10th' }, { id: 11, value: '11', ordinals: '11th' }, { id: 12, value: '12', ordinals: '12th' },
    { id: 13, value: '12', ordinals: '13th' }, { id: 14, value: '14', ordinals: '14th' }, { id: 15, value: '15', ordinals: '15th' }, { id: 16, value: '16', ordinals: '16th' },
    { id: 17, value: '17', ordinals: '17th' }, { id: 18, value: '18', ordinals: '18th' }, { id: 19, value: '19', ordinals: '19th' }, { id: 20, value: '20', ordinals: '20th' },
    { id: 21, value: '21', ordinals: '21st' }, { id: 22, value: '22', ordinals: '22nd' }, { id: 23, value: '23', ordinals: '23rd' }, { id: 24, value: '24', ordinals: '24th' },
    { id: 25, value: '25', ordinals: '25th' }, { id: 26, value: '26', ordinals: '26th' }, { id: 27, value: '27', ordinals: '27th' }, { id: 28, value: '28', ordinals: '28th' },
    { id: 29, value: '29', ordinals: '29th' }, { id: 30, value: '30', ordinals: '30th' }, { id: 31, value: '31', ordinals: '31st' }
];

const everyMonthValue = [
    { id: '1', value: 'January' },
    { id: '2', value: 'Febraury' },
    { id: '3', value: 'March' },
    { id: '4', value: 'April' },
    { id: '5', value: 'May' },
    { id: '6', value: 'June' },
    { id: '7', value: 'July' },
    { id: '8', value: 'August' },
    { id: '9', value: 'September' },
    { id: '10', value: 'October' },
    { id: '11', value: 'November' },
    { id: '12', value: 'December' },
];

const everyWeekValue = [
    { id: '1', value: 'Monday' },
    { id: '2', value: 'Tuesday' },
    { id: '3', value: 'Wednesday' },
    { id: '4', value: 'Thursday' },
    { id: '5', value: 'Friday' },
    { id: '6', value: 'Saturday' },
    { id: '7', value: 'Sunday' },
];

const CustomSchedulerTab = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const { messageConfigDtlObjectToBeEditedOrView, setMessageConfigDtlObjectToBeEditedOrView, mode, control, setValue, timeZoneValue, setTimeZoneValue } = props;
    const [disabledForReadMode, isDisabledForReadMode] = useState(false);
    const pageLabelsConstantsAndMessages = {
        name: {
            frequency: 'frequency',
            timezone: 'timezone',
        }
    }

    const [cronExp, setCronExp] = useState(messageConfigDtlObjectToBeEditedOrView.frequency !== undefined ? messageConfigDtlObjectToBeEditedOrView.frequency : '* * * * *')

    const [currentState, setCurrentState] = useState('Executes: every minute (UTC)');

    const [checked, setChecked] = React.useState(true);

    const frequency = messageConfigDtlObjectToBeEditedOrView.frequency !== undefined ? messageConfigDtlObjectToBeEditedOrView.frequency : '';

    const defaultValues = frequency.split(' ');

    const defaultHourValue = messageConfigDtlObjectToBeEditedOrView.frequency !== undefined ? defaultValues[1].split(',') : '';
    const defaultMinuteValue = messageConfigDtlObjectToBeEditedOrView.frequency !== undefined ? defaultValues[0].split(',') : '';
    const defaultDayValue = messageConfigDtlObjectToBeEditedOrView.frequency !== undefined ? defaultValues[2].split(',') : '';
    const defaultMonthValue = messageConfigDtlObjectToBeEditedOrView.frequency !== undefined ? defaultValues[3].split(',') : '';
    const defaultWeekValue = messageConfigDtlObjectToBeEditedOrView.frequency !== undefined ? defaultValues[4].split(',') : '';

    const cronHourValueLoad = everyHourValue.filter((item) => {
        if (defaultHourValue.includes(item.value)) {
            return item
        }
    })

    const [hourValue, setHourValue] = useState(cronHourValueLoad);

    const cronMinuteValueLoad = everyMinuteValue.filter((item) => {
        if (defaultMinuteValue.includes(item.value)) {
            return item
        }
    })

    const [minuteValue, setMinuteValue] = useState(cronMinuteValueLoad);

    const cronDayValueLoad = everyDayValue.filter((item) => {
        if (defaultDayValue.includes(item.value)) {
            return item
        }
    })

    const [dayValue, setDayValue] = useState(cronDayValueLoad);

    const cronMonthValueLoad = everyMonthValue.filter((item) => {
        if (defaultMonthValue.includes(item.id)) {
            return item
        }
    })

    const [monthValue, setMonthValue] = useState(cronMonthValueLoad);

    const cronWeekValueLoad = everyWeekValue.filter((item) => {
        if (defaultWeekValue.includes(item.id)) {
            return item
        }
    })

    const [weekValue, setWeekValue] = useState(cronWeekValueLoad);

    const handleChangeForHourCron = (event, values) => {
        setHourValue(values);
    }

    const handleChangeForWeekCron = (event, values) => {
        setWeekValue(values);
    }

    const handleChangeForMonthCron = (event, values) => {
        setMonthValue(values);
    }

    const handleChangeForDayCron = (event, values) => {
        setDayValue(values);
    }

    const handleChangeForMinuteCron = (event, values) => {
        setMinuteValue(values);
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {

        let cronMinuteValue = minuteValue.map((key) => {
            return key.value
        })
        if (cronMinuteValue.length === 0) {
            cronMinuteValue = ['*']
        }

        let cronHourValue = hourValue.map((key) => {
            return key.value
        })
        if (cronHourValue.length === 0) {
            cronHourValue = ['*']
        }

        let cronDayValue = dayValue.map((key) => {
            return key.value
        })
        if (cronDayValue.length === 0) {
            cronDayValue = ['*']
        }

        let cronMonthValue = monthValue.map((key) => {
            return key.id
        })
        if (cronMonthValue.length === 0) {
            cronMonthValue = ['*']
        }

        let cronWeekValue = weekValue.map((key) => {
            return key.id
        })
        if (cronWeekValue.length === 0) {
            cronWeekValue = ['*']
        }

        let currentStateMinuteValue = minuteValue.map((key) => {
            return key.value
        })
        let currentStatePerMinuteValue = minuteValue.map((key) => {
            return key.minute
        })
        if (currentStateMinuteValue.length === 0) {
            currentStateMinuteValue = ['every minute']
        } else if (currentStateMinuteValue.length > 0) {
            const minutesIncludesStar = currentStateMinuteValue.filter(minute => minute.includes('*'))
            currentStateMinuteValue = [minutesIncludesStar.length !== 0 ? `At Every ${currentStatePerMinuteValue} minute(s)` : `At ${currentStateMinuteValue} minute(s)`]
        }

        let currentStateHourValue = hourValue.map((key) => {
            return key.time
        })
        if (currentStateHourValue.length === 0) {
            currentStateHourValue = ['every hour']
        } else if (currentStateHourValue.length > 0) {
            currentStateHourValue = [`At ${currentStateHourValue} and`]
        }

        let currentStateWeekValue = weekValue.map((key) => {
            return key.value
        })
        if (currentStateWeekValue.length > 0) {
            currentStateWeekValue = [`on ${currentStateWeekValue}`]
        }

        let currentStateDayValue = dayValue.map((key) => {
            return key.ordinals
        })
        if (currentStateDayValue.length === 0 && currentStateWeekValue.length === 0) {
            currentStateDayValue = ['every day']
        } else if (currentStateDayValue.length > 0) {
            currentStateDayValue = [`on ${currentStateDayValue}`]
        }

        let currentStateMonthValue = monthValue.map((key) => {
            return key.value
        })
        if (currentStateMonthValue.length === 0) {
            currentStateMonthValue = ['Every Month']
        } else if (currentStateMonthValue.length > 0) {
            currentStateMonthValue = [`In ${currentStateMonthValue}`]
        }

        let cronExpValue = [];
        let cronHourString = cronHourValue.join(',');
        let cronMinuteString = cronMinuteValue.join(',');
        let cronDayString = cronDayValue.join(',');
        let cronMonthString = cronMonthValue.join(',');
        let cronWeekString = cronWeekValue.join(',');
        cronExpValue = cronExpValue.concat(cronMinuteString, cronHourString, cronDayString, cronMonthString, cronWeekString)

        let cronExpString = cronExpValue.join(' ')

        let currentStateExeValue = [];
        let currentHourString = currentStateHourValue.join(',');
        let currentMonthString = currentStateMonthValue.join(',');
        let currentMinuteString = currentStateMinuteValue.join(',');
        let currentDayString = currentStateDayValue.join(',');
        let currentWeekString = currentStateWeekValue.join(',');
        currentStateExeValue = currentStateExeValue.concat('Execute:', currentMonthString, currentDayString, currentWeekString, currentHourString, currentMinuteString)

        let currentExeString = currentStateExeValue.join(' ')

        if (mode === 'edit' && messageConfigDtlObjectToBeEditedOrView.frequency !== cronExpString) {
            setValue(`${pageLabelsConstantsAndMessages.name.frequency}`, cronExpString, { shouldValidate: true, shouldDirty: true });
        }

        setCronExp(cronExpString);
        setCurrentState(currentExeString);

    }, [hourValue, minuteValue, weekValue, monthValue, dayValue]);

    useEffect(() => {
        setMessageConfigDtlObjectToBeEditedOrView({ ...messageConfigDtlObjectToBeEditedOrView, frequency: cronExp });
    }, [cronExp])

    useEffect(() => {
        if (mode === 'read' || mode === 'view') {
            isDisabledForReadMode(true);
        }
    }, [mode])

    const [isCronDayFiledDisable, setIsCronDayFiledDisable] = useState(false);
    const [isWeekDayFiledDisable, setIsCronWeekFiledDisable] = useState(false);

    useEffect(() => {
        if (cronWeekValueLoad.length !== 0) {
            setIsCronDayFiledDisable(true);
        } else if (cronDayValueLoad.length !== 0) {
            setIsCronWeekFiledDisable(true);
        } else {
            setIsCronDayFiledDisable(false);
            setIsCronWeekFiledDisable(false);
        }
    }, [cronWeekValueLoad, cronDayValueLoad])

    const timeZoneComboProperty = {
        name: pageLabelsConstantsAndMessages.name.timeZone,
        isRequired: false,
        label: intl.formatMessage({ id: 'customschedulartab.timezone.label' }),
        isAutofocus: false,
        variant: 'outlined',
        isHookFormType: true,
        isDisabled: (mode === 'read' || mode === 'view') ? isDisabledForReadMode : false,
        //errors: errors.timeZone
    }

    return (
        <React.Fragment>
            <Grid container className={classes.root}>
                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.frequency}
                                    rules={{
                                        required: false,
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            {...field}
                                            size="small"
                                            variant="outlined"
                                            label={<FormattedMessage id="customschedulartab.indicator.label" defaultMessage="Indicator" />}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={cronExp}
                                            disabled={disabledForReadMode}
                                        />)}
                                />
                            </FormControl>
                            <FormHelperText>{<FormattedMessage id="textfield.indicator.message" />}</FormHelperText>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                            <TimeZoneCombo
                                comboProperty={timeZoneComboProperty}
                                control={control}
                                comboValue={timeZoneValue}
                                setComboValue={setTimeZoneValue}
                            />
                        </Grid>
                        <Grid item container xs={12} sm={12} md={12} lg={12}>
                            <FormControl fullWidth>
                                <TextField
                                    className={classes.textField}
                                    size="small"
                                    variant="outlined"
                                    label={<FormattedMessage id="customschedulartab.currentstate.label" defaultMessage="Time Zone" />}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={currentState}
                                    disabled={disabledForReadMode}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ height: '3px', width: '98%', margin: '10px 10px' }} />
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    multiple
                                    size='small'
                                    id="selectMinute"
                                    limitTags={checked ? 1 : -1}
                                    options={everyMinuteValue}
                                    filterSelectedOptions
                                    onChange={handleChangeForMinuteCron}
                                    getOptionLabel={(option) => option.value}
                                    value={minuteValue}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label={intl.formatMessage({ id: "customschedulartab.everyMinute.label" })}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                    defaultValue={cronMinuteValueLoad}
                                    disabled={disabledForReadMode}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    multiple
                                    size='small'
                                    id="selectHours"
                                    limitTags={checked ? 1 : -1}
                                    options={everyHourValue}
                                    filterSelectedOptions
                                    onChange={handleChangeForHourCron}
                                    getOptionLabel={(option) => option.value}
                                    value={hourValue}
                                    getoptionselected={(option, value) => option.value === value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label={intl.formatMessage({ id: "customschedulartab.everyHour.label" })}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                    defaultValue={cronHourValueLoad}
                                    disabled={disabledForReadMode}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    multiple
                                    size='small'
                                    id="selectDay"
                                    limitTags={checked ? 1 : -1}
                                    options={everyDayValue}
                                    filterSelectedOptions
                                    onChange={handleChangeForDayCron}
                                    getOptionLabel={(option) => option.value}
                                    value={dayValue}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label={intl.formatMessage({ id: "customschedulartab.everyDay.label" })}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                    defaultValue={cronDayValueLoad}
                                    disabled={isCronDayFiledDisable || disabledForReadMode}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    multiple
                                    size='small'
                                    id="selectMonth"
                                    limitTags={checked ? 1 : -1}
                                    options={everyMonthValue}
                                    onChange={handleChangeForMonthCron}
                                    filterSelectedOptions
                                    getOptionLabel={(option) => option.value}
                                    value={monthValue}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label={intl.formatMessage({ id: "customschedulartab.selectmonth.label" })}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                    defaultValue={cronMonthValueLoad}
                                    disabled={disabledForReadMode}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    multiple
                                    size='small'
                                    id="selectWeek"
                                    limitTags={checked ? 1 : -1}
                                    options={everyWeekValue}
                                    filterSelectedOptions
                                    onChange={handleChangeForWeekCron}
                                    getOptionLabel={(option) => option.value}
                                    value={weekValue}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label={intl.formatMessage({ id: "customschedulartab.everyDayOfWeek.label" })}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                    defaultValue={cronWeekValueLoad}
                                    disabled={isWeekDayFiledDisable || disabledForReadMode}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                            <Grid container justifyContent='flex-start' alignItems='center'>
                                <Checkbox
                                    color='primary'
                                    onChange={handleChange}
                                    checked={checked}
                                />
                                <InputLabel>{<FormattedMessage id="customschedulartab.limittags.label" defaultMessage="Limit Tags" />}</InputLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
export default CustomSchedulerTab;