import { CircularProgress, CssBaseline, FormControl, FormHelperText, Grid, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import { CommonService } from '../../../../services';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import useStyles from '../../CommonStyle/CommonStyle'
import { FormattedMessage } from 'react-intl';
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';

const MessageFormatCombo = (props) => {

    const classes = useStyles();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const { comboProperty, control, errors, comboValue, setComboValue, processNameValue, inOutValue, messageFormatList, setMessageFormatList, resetComboValueForMessageFormat, bpTypeValueCombo } = props;
    const [isLoading, setLoading] = useState(false);
    const [mssageFormatData, setMessageFormatData] = useState([]);

    useEffect(() => {
        if (messageFormatList !== null) {
            setMessageFormatData(messageFormatList.map((processMessageMapping) => {
                return {
                    msgFormat: processMessageMapping.messageMaster.msgFormat,
                    msgFormatId: processMessageMapping.messageMaster.msgFormatId
                }
            }))
        }
    }, [messageFormatList]);

    const key = "msgFormat"

    const uniqueMsgFormatData = [...new Map(mssageFormatData.map(item => [item[key], item])).values()];

    let listOfMsgFormat = [];

    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.messageFormat);

    useEffect(() => {
        setParamBody({ ...paramBody, body: { processMaster: { processId: processNameValue.processId }, messageMaster: {bpType: bpTypeValueCombo.bpType} }, sortDir: 'asc', sortKey: 'mapId', recordsPerPage: 100 });
    }, [processNameValue]);

    const loadMsgFormatData = (searchedMsgFormatCode) => {
        if (processNameValue.processId !== '' && inOutValue.dataDisplay !== '') {
            setLoading(true);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.processMessageMapping, paramBody)
                .then((response) => {
                    setLoading(false);
                    for (let index = 0; index < response.data.content.length; index++) {
                        if (response.data.content[index].messageMaster.inOut === inOutValue.value) {
                            listOfMsgFormat.push(response.data.content[index])
                        }
                    }
                    setMessageFormatList(listOfMsgFormat);
                })
        } else {
            setMessageFormatList([]);
        }
    }

    return (
        <React.Fragment>
            <CssBaseline />
            { comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth>
                    <Controller
                        render={({
                            field: { onChange } }) => (
                            <Autocomplete
                                onChange={(event, newValue) => {
                                    resetComboValueForMessageFormat()
                                    onChange(newValue)

                                    if (typeof newValue === 'string') {

                                        if (newValue != null) {
                                            setComboValue({
                                                newValue,
                                            });
                                        }
                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setComboValue({
                                            title: newValue.inputValue,
                                        });

                                    }
                                    else if (newValue !== null) {

                                        setComboValue(newValue);
                                    } else if (newValue === null) {
                                        setComboValue('')
                                    } else {
                                        setComboValue(newValue);
                                    }
                                }}
                                selectOnFocus
                                handleHomeEndKeys
                                value={comboValue.msgFormat || null}
                                autoHighlight
                                loadingText="Loading..."
                                loading={isLoading}
                                onOpen={() => {
                                    loadMsgFormatData('');
                                }}
                                options={uniqueMsgFormatData}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.msgFormat;
                                }}
                                getoptionselected={(option, value) => option.msgFormat === value}
                                disabled={comboProperty.isDisabled}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        autoFocus={comboProperty.isAutofocus}
                                        name={comboProperty.name}
                                        size='small'
                                        required
                                        label={comboProperty.label}
                                        variant={comboProperty.variant}
                                        InputLabelProps={{
                                            shrink: true,
                                            classes: {
                                                asterisk: classes.asterisk
                                            }
                                        }}
                                        error={comboProperty.errors}
                                    />
                                )}

                            />
                        )}

                        name={comboProperty.name}
                        rules={{ required: comboProperty.isRequired }}
                        control={control}
                        defaultValue={{ msgFormat: '' }}
                    />
                    {comboProperty.isDisabled}
                    <div className={classes.error}>
                        {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                            <FormHelperText className={classes.error}>{comboProperty.label} <FormattedMessage id="textfield.error.isrequirededmessage" defaultMessage="is required" /></FormHelperText>
                        )}
                    </div>
                </FormControl>
            </Grid>)}

            { !comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "3px" }}>
                <FormControl fullWidth>
                    <Autocomplete
                        fullWidth
                        openOnFocus={true}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {

                                if (newValue != null) {
                                    setComboValue({
                                        newValue,
                                    });
                                }
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setComboValue({
                                    title: newValue.inputValue,
                                });

                            }
                            else if (newValue !== null) {

                                setComboValue(newValue);
                            } else if (newValue === null) {
                                setComboValue('')
                            } else {
                                setComboValue(newValue);
                            }
                        }}
                        selectOnFocus
                        loadingText="Loading..."
                        loading={isLoading}
                        handleHomeEndKeys
                        value={comboValue.newValue || null}
                        // autoHighlight
                        onOpen={() => {
                            loadMsgFormatData('');
                        }}
                        options={uniqueMsgFormatData}
                        getOptionLabel={(option) => {

                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.msgFormat;
                        }}
                        getoptionselected={(option, value) => option.msgFormat === value}
                        disabled={comboProperty.isDisabled}
                        renderInput={params => (
                            <Tooltip title={comboValue.msgFormat || ''}>
                                <TextField
                                    {...params}
                                    autoFocus={comboProperty.isAutofocus}
                                    name={comboProperty.name}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(ev) => {
                                        if (
                                            ev.target.value !== "" ||
                                            ev.target.value !== null
                                        ) {
                                            loadMsgFormatData(ev.target.value);
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {isLoading ?
                                                    <CircularProgress color="inherit" size={20} />
                                                    : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    size='small'
                                    label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                    variant={comboProperty.variant}
                                />
                            </Tooltip>
                        )}

                    />
                </FormControl>
            </Grid>)}

        </React.Fragment>
    );
};

MessageFormatCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default MessageFormatCombo;

