import { createTheme } from '@mui/material/styles';
import { lightGreen, red, yellow } from '@mui/material/colors';

const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  props: {
    MuiPaper: {
      elevation: 0,
    },
    MuiAppBar: {
      elevation: 1,
    },
    MuiButton: {
      elevation: 1,
    },
    MuiMenu: {
      elevation: 1,
    },
    MuiCard: {
      elevation: 0,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        minWidth: 0,
      },
    },
    MuiButtonGroup: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16,
        },
      },
    },
    MuiLinearProgress: {
      root: {
        background: '#f3f3f3 !important',
      },
    },
  },

  palette: {
    primary: {
      main: '#4782da',
    },
    secondary: {
      main: '#000'
    },
    success: lightGreen,
    warning: yellow,
    error: red,
    divider: 'rgba(30, 30, 30, 0.06)',
    customSecondary: {
      main: 'rgb(35, 48, 68)',
      secondary: '#eceff1'
    },
    customPrimary: {
      main: '#ffffff',
      secondary: '#4782da',
    },
    customText: {
      main: '#ffffff'
    },
    customInherit: {
      main: '#9e9e9e'
    },
    customOrange: {
      main: '#ff9000'
    }
  },
  typography: {
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.8rem',
    },
    h3: {
      fontSize: '1.6rem',
    },
    h4: {
      fontSize: '1.4rem',
    },
    h5: {
      fontSize: '1.2rem',
    },
    h6: {
      fontSize: '1rem',
    },
  },
})

function customCheckbox() {
  return {
    '& .MuiDataGrid-cellCheckbox': {
      color: baseTheme.palette.secondary.main
    },
    '& 	.MuiDataGrid-checkboxInput': {
      color: baseTheme.palette.secondary.main,
      fontSize: '12px',
      fontWeight: 500
    }
  };
}

const adminTheme = {
  header: {
    backgroundColor: baseTheme.palette.customPrimary.main,
  },
  sidebar: {
    width: 250,
    widthCollapsed: baseTheme.spacing(7),
    backgroundColor: baseTheme.palette.customSecondary.main,
    color: '#f5f5f5',
  },
  navItemIcon: {
    minWidth: 40,
    color: 'rgb(238, 238, 238);',
  },
  switchColor: {
    color: '#4782da',
    '&$checked': {
      color: '#4782da',
    },
    '&$checked + $track': {
      backgroundColor: '#4782da',
    },
  },
  tableStyle: {
    padding: '2px 2px',
    zIndex: 0,
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: baseTheme.palette.customPrimary.secondary,
      filter: 'brightness(100%)',
      display: 'flex',
      zIndex: 1,
      color: baseTheme.palette.customPrimary.main,
    },
    '& .MuiDataGrid-row:nth-child(even)': {
      backgroundColor: '#f5f5f5',
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: '#d8d8d8 !important'
    },
    '& .MuiDataGrid-toolbarContainer': {
      marginLeft: '100%',
      backgroundColor: baseTheme.palette.customPrimary.secondary,
    },
    '& .MuiDataGrid-pinnedColumnHeaders': {
      backgroundColor: baseTheme.palette.customPrimary.secondary,
      filter: 'brightness(100%)',
      display: 'flex',
      zIndex: 1,
      color: baseTheme.palette.customPrimary.main,
    },
    ...customCheckbox(),
  },
}

const CustomTheme = {
  ...customCheckbox(),
  ...baseTheme,
  ...adminTheme,
}

export default CustomTheme
