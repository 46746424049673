import { Button, Grid, CssBaseline, CircularProgress, useTheme, useMediaQuery, Tooltip, FormControl, FormHelperText, TextField, InputLabel, Switch, IconButton, InputAdornment, Snackbar, MenuItem } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import useStyles from '../../common/CommonStyle/CommonStyle';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import CreateIcon from '@mui/icons-material/Add';
import ViewIcon from '@mui/icons-material/Visibility';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import EntityCodeCombo from '../../common/Combos/EntityCodeCombo';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import { Controller } from 'react-hook-form';
import _ from "lodash/fp";
import { T } from 'lodash/fp';
import { useForm } from 'react-hook-form';
import { CommonService } from '../../../services';
import { Alert } from "@mui/lab";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),

    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const CreateOrEditEmailServerConfig = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const { onClose, open, title, tabvalue, warningMessage, dialogHeader, orgName, entityName, mode, emailServerConfigObjectToBeEditedOrView } = props;
    const [emailServerConfigObjectToBeEdited, setEmailServerConfigObjectToBeEdited] = React.useState({});
    const [branchValue, setBranchValue] = useState('');
    const commonPatterns = GlobalEdiApiConstants();
    const theme = useTheme();
    const isScreenSizeSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [componentList, setComponentList] = useState(title);
    const [disablesaveButton, isSetSaveButtonDisabled] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const currentUser = CommonService.getLoggedInUserInfo();
    const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());



    const resetAllClearTheBasicInfo = () => {
        if (mode === 'edit' || mode === 'view') {
            functionForReset(emailServerConfigObjectToBeEditedOrView)
            setEmailServerConfigObjectToBeEdited({
                ...emailServerConfigObjectToBeEditedOrView
            });

        } else {
            reset({
                entity: null,
                smtpHost: '',
                smtpPortNumber: '',
                fromEmailId: '',
                fromEmailName: '',
                secured: true,
                verifyCertificate: true,
                smtpUserName: '',
                smtpPassword: '',
                active: true,
                isMaster: false,
                securityType: '',
                maxConcurrentConCount: '',
                maxAttachmentSize: '',
            });
            setEmailServerConfigObjectToBeEdited({ ...emailServerConfigObjectToBeEditedOrView, active: true });
        }
    }

    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const [values, setValues] = React.useState({
        smtpPassword: '',
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClose = (value) => {
        setComponentList(title);
        handlereset(true)
        onClose(value)
    }

    const handlereset = (value) => {
        reset({ keepErrors: true, keepDirty: true, keepIsSubmitted: false, keepTouched: false, keepIsValid: false, keepSubmitCount: false });
        resetAllClearTheBasicInfo(value)
    }

    const pageLabelsConstantsAndMessages = {
        label: {
            active: 'Active'
        },
        name: {
            organization: 'organization',
            entity: 'entity',
            smtpHost: 'smtpHost',
            smtpPortNumber: 'smtpPortNumber',
            fromEmailId: 'fromEmailId',
            fromEmailName: 'fromEmailName',
            smtpUserName: 'smtpUserName',
            smtpPassword: 'smtpPassword',
            secured: 'secured',
            verifyCertificate: 'verifyCertificate',
            isMaster: 'isMaster',
            active: 'active',
            securityType: 'securityType',
            maxConcurrentConCount: 'maxConcurrentConCount',
            maxAttachmentSize: 'maxAttachmentSize'
        }
    }
    const [emailServerConfigReadOnlyFields, setEmailServerConfigReadOnlyFields] = useState({
        entity: false,
        smtpHost: false,
        smtpPortNumber: false,
        fromEmailId: false,
        fromEmailName: false,
        smtpUserName: false,
        smtpPassword: false,
        secured: false,
        verifyCertificate: false,
        isMaster: false,
        active: false,
        maxConcurrentConCount: false,
        maxAttachmentSize: false
    });

    const serverTypeOption = [
        { id: 1, value: 'TLS' },
        { id: 2, value: 'SSL' },
        { id: 3, value: 'NONE' }
    ];

    useEffect(() => {
        if (mode !== '') {
            let currentRouterComponent;
            if (mode === 'create') {
                handlereset(true)
                currentRouterComponent = [
                    {
                        path: null,
                        name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                        iconName: CreateIcon,
                    }
                ]
            } else if (mode === 'edit') {
                isRestoreButtonDisabled(false)
                currentRouterComponent = [
                    {
                        path: null,
                        name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                        iconName: EditIcon,
                    }
                ]
            } else if (mode === 'read' || (mode === 'view')) {
                isRestoreButtonDisabled(true)
                currentRouterComponent = [
                    {
                        path: null,
                        name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
                        iconName: ViewIcon,
                    }
                ]
            }
            setComponentList(title.concat(currentRouterComponent !== undefined ? currentRouterComponent : []));
        }
    }, [mode]);

    useEffect(() => {
        setEmailServerConfigObjectToBeEdited(emailServerConfigObjectToBeEditedOrView);
    }, [emailServerConfigObjectToBeEditedOrView]);

    useEffect(() => {
        if (mode === 'view' || mode === 'edit') {
            setEmailServerConfigReadOnlyFields({
                ...emailServerConfigReadOnlyFields,
                erpEntityCode: true,
                smtpHost: true,
                smtpPortNumber: true,
                fromEmailId: true,
                fromEmailName: true,
                smtpUserName: true,
                smtpPassword: true,
                secured: true,
                verifyCertificate: true,
                isMaster: true,
                maxConcurrentConCount: true,
                maxAttachmentSize: true
            })
            setBranchValue(emailServerConfigObjectToBeEditedOrView.entity !== undefined ? { valuesToBeDisplayed: emailServerConfigObjectToBeEditedOrView.entity } : []);
            functionForReset(emailServerConfigObjectToBeEditedOrView);
        }
        if (mode === 'view') {
            setEmailServerConfigReadOnlyFields({
                ...emailServerConfigReadOnlyFields,
                erpEntityCode: false,
                smtpHost: false,
                smtpPortNumber: false,
                fromEmailId: false,
                fromEmailName: false,
                smtpUserName: false,
                smtpPassword: false,
                verifyCertificate: false,
                isMaster: false,
                maxConcurrentConCount: false,
                maxAttachmentSize: false
            })
        }
        if (mode === 'create') {

            setBranchValue([]);
            setEmailServerConfigReadOnlyFields({
                ...emailServerConfigReadOnlyFields,
                erpEntityCode: false,
                smtpHost: false,
                smtpPortNumber: false,
                fromEmailId: false,
                fromEmailName: false,
                smtpUserName: false,
                smtpPassword: false,
                verifyCertificate: false,
                isMaster: false,
                maxConcurrentConCount: false,
                maxAttachmentSize: false
            })

        }
    }, [mode, emailServerConfigObjectToBeEditedOrView]);


    const { handleSubmit, reset, formState: { errors, isDirty, isValid }, control } = useForm({
        mode: "all",
        reValidateMode: 'all',
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        defaultValues: {
            active: true,

        }
    });

    const branchComboProperty = {
        name: pageLabelsConstantsAndMessages.name.entity,
        isRequired: true,
        label: intl.formatMessage({ id: "textfield.entityCode.label" }),
        isAutofocus: false,
        isDisabled: (mode === 'edit' || mode === 'view') ? true : false,
        variant: 'outlined',
        isHookFormType: true,
        errors: errors.entity
    }

    const functionForReset = (responseData) => {
        reset({
            entity: responseData.entity !== undefined ? { erpEntityCode: responseData.entity.erpEntityCode, entityName: responseData.entity.entityName } : null,
            smtpHost: responseData.smtpHost !== undefined ? responseData.smtpHost : '',
            smtpPortNumber: responseData.smtpPortNumber !== undefined ? responseData.smtpPortNumber : '',
            fromEmailId: responseData.fromEmailId !== undefined ? responseData.fromEmailId : '',
            fromEmailName: responseData.fromEmailName !== undefined ? responseData.fromEmailName : '',
            replyToEmailId: responseData.replyToEmailId !== undefined ? responseData.replyToEmailId : '',
            secured: responseData.secured !== undefined ? responseData.secured : null,
            verifyCertificate: responseData.verifyCertificate !== undefined ? responseData.verifyCertificate : null,
            smtpUserName: responseData.smtpUserName !== undefined ? responseData.smtpUserName : '',
            smtpPassword: responseData.smtpPassword !== undefined ? responseData.smtpPassword : '',
            active: responseData.active !== undefined ? responseData.active : true,
            isMaster: responseData.isMaster !== undefined ? responseData.isMaster : null,
            securityType: responseData.securityType !== undefined ? responseData.securityType : '',
            maxConcurrentConCount: responseData.maxConcurrentConCount !== undefined ? responseData.maxConcurrentConCount : '3',
            maxAttachmentSize: responseData.maxAttachmentSize !== undefined ? responseData.maxAttachmentSize : '10',
        });

        setBranchValue(emailServerConfigObjectToBeEditedOrView.entity !== undefined ? { valuesToBeDisplayed: responseData.entity } : []);
    }

    const onSubmit = data => {
        data['id'] = (mode === 'create') ? 0 : emailServerConfigObjectToBeEditedOrView.id;
        data['organization'] = (emailServerConfigObjectToBeEditedOrView.organization !== undefined) ? { erpOrgCode: emailServerConfigObjectToBeEditedOrView.organization.erpOrgCode } : { erpOrgCode: orgInfoOfLeggedUser.erpOrgCode };
        data['entity'] = (emailServerConfigObjectToBeEditedOrView.entity === undefined) ? { erpEntityCode: data.entity.erpEntityCode } : { erpEntityCode: emailServerConfigObjectToBeEditedOrView.entity.erpEntityCode };
        data['createdBy'] = (mode === 'create') ? currentUser.userName : emailServerConfigObjectToBeEditedOrView.createdBy;
        data['createdTime'] = (emailServerConfigObjectToBeEditedOrView.createdTime === undefined) ? '' : emailServerConfigObjectToBeEditedOrView.createdTime;
        data['updatedBy'] = currentUser.userName;
        data['updatedTime'] = (emailServerConfigObjectToBeEditedOrView.updatedTime === undefined) ? '' : emailServerConfigObjectToBeEditedOrView.updatedTime;
        setIsFormSubmitted(true);
        CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.emailServerConfig, data)
            .then((response) => {
                setIsFormSubmitted(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success" });
                onClose(true);
            })
            .catch(function (error) {
                setIsFormSubmitted(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            });

    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container className={classes.root}>
                <Grid item container justify='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} >
                        <Dialog fullWidth maxWidth='lg' onClose={handleClose} aria-labelledby="create-or-delete-dialog" open={open} disableBackdropClick={true} >
                            <DialogTitle className={classes.titleCommonDialogComponent}>
                                <Grid item container alignItems='center' item xs={12} sm={12} md={12} lg={12} style={{ marginLeft: '-1%' }} >
                                    <Grid item container alignItems='center' xs={6} sm={8} md={8} lg={6}>
                                        <CustomBreadCrumb componentList={componentList} isDialog={true} />
                                    </Grid>
                                    <Grid item container justify='flex-end' alignItems='center' xs={6} sm={4} md={4} lg={6} style={{ color: '#fff', fontSize: '14px' }} >
                                        {orgName} {dialogHeader} {entityName}
                                    </Grid>
                                </Grid>
                            </DialogTitle>

                            <DialogContent dividers style={smallScreen ? { minHeight: '70vh' } : { minHeight: '60vh' }} >
                                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} style={{ marginBottom: '-1.4%' }}>
                                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                            <EntityCodeCombo comboProperty={branchComboProperty}
                                                control={control}
                                                errors={errors}
                                                comboValue={branchValue}
                                                setComboValue={setBranchValue} />
                                        </Grid>
                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    control={control}
                                                    name={pageLabelsConstantsAndMessages.name.smtpHost}
                                                    rules={mode === 'view' ? { required: false } : {
                                                        required: true,
                                                        maxLength: 100,
                                                        pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UHF
                                                    }}

                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextField
                                                            className={classes.textField}
                                                            variant="outlined"
                                                            margin="normal"
                                                            id="smtpHost"
                                                            size='small'
                                                            label={<FormattedMessage id="columnname.smtpHost.label" defaultMessage="smtpHost" />}
                                                            required={true}
                                                            {...field}
                                                            error={errors.smtpHost}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {
                                                                    asterisk: classes.asterisk
                                                                }
                                                            }}
                                                            InputProps={{
                                                                classes: { notchedOutline: classes.specialOutline }
                                                            }}
                                                            disabled={mode === 'view' ? true : false}
                                                        />
                                                    )}
                                                />

                                                <div className={classes.error}>
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.smtpHost}.type`, errors) === "required" && (
                                                        <FormHelperText className={classes.error}>
                                                            {<FormattedMessage id="columnname.smtpHost.label" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}
                                                        </FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.smtpHost}.type`, errors) === "pattern" && (
                                                        <FormHelperText className={classes.error}>   {<FormattedMessage id="columnname.smtpHost.label" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UCSHF}</FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.smtpHost}.type`, errors) === "maxLength" && (
                                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.smtpHost.label" defaultMessage="smtpHost" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>

                                                    )}
                                                </div>

                                            </FormControl>
                                        </Grid>
                                        <Grid item container xs={6} sm={3} md={3} lg={2}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    control={control}
                                                    name={pageLabelsConstantsAndMessages.name.smtpPortNumber}
                                                    rules={mode === 'view' ? { required: false } : {
                                                        required: true,
                                                        maxLength: 100,
                                                        pattern: commonPatterns.commonTextfeildPattern.patternNumericOnly
                                                    }}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextField
                                                            className={classes.textField}
                                                            variant="outlined"
                                                            margin="normal"
                                                            id="smtpPortNumber"
                                                            size='small'
                                                            label={<FormattedMessage id="columnname.smtpPortNumber.label" defaultMessage="smtpPortNumber" />}
                                                            required={true}
                                                            {...field}
                                                            error={errors.smtpPortNumber}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {
                                                                    asterisk: classes.asterisk
                                                                }
                                                            }}
                                                            InputProps={{
                                                                classes: { notchedOutline: classes.specialOutline }
                                                            }}
                                                            disabled={mode === 'view' ? true : false}
                                                        />
                                                    )}
                                                />

                                                <div className={classes.error}>
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.smtpPortNumber}.type`, errors) === "required" && (
                                                        <FormHelperText className={classes.error}>
                                                            {<FormattedMessage id="columnname.smtpPortNumber.label" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}
                                                        </FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.smtpPortNumber}.type`, errors) === "pattern" && (
                                                        <FormHelperText className={classes.error}>   {<FormattedMessage id="columnname.smtpPortNumber.label" />} {commonPatterns.commonErrorsForTextFields.errorForNumericOnly}</FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.smtpPortNumber}.type`, errors) === "maxLength" && (
                                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.smtpPortNumber.label" defaultMessage="smtpPortNumber" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                                    )}
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item container xs={6} sm={3} md={3} lg={2}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    control={control}
                                                    name={pageLabelsConstantsAndMessages.name.maxConcurrentConCount}
                                                    rules={mode === 'view' ? { required: false } : {
                                                        required: true,
                                                        maxLength: 100,
                                                        pattern: commonPatterns.commonTextfeildPattern.patternNumericOnly
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            className={classes.textField}
                                                            variant="outlined"
                                                            margin="normal"
                                                            id="maxConcurrentConCount"
                                                            size='small'
                                                            defaultValue={'3'}
                                                            label={<FormattedMessage id="columnname.maxConcurrentConCount.label" defaultMessage="maxConcurrentConCount" />}
                                                            required={true}
                                                            {...field}
                                                            error={errors.maxConcurrentConCount}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {
                                                                    asterisk: classes.asterisk
                                                                }
                                                            }}
                                                            InputProps={{
                                                                classes: { notchedOutline: classes.specialOutline }
                                                            }}
                                                            disabled={mode === 'view' ? true : false}
                                                        />
                                                    )}
                                                />

                                                <div className={classes.error}>
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.maxConcurrentConCount}.type`, errors) === "required" && (
                                                        <FormHelperText className={classes.error}>
                                                            {<FormattedMessage id="columnname.maxConcurrentConCount.label" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}
                                                        </FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.maxConcurrentConCount}.type`, errors) === "pattern" && (
                                                        <FormHelperText className={classes.error}>   {<FormattedMessage id="columnname.maxConcurrentConCount.label" />} {commonPatterns.commonErrorsForTextFields.errorForNumericOnly}</FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.maxConcurrentConCount}.type`, errors) === "maxLength" && (
                                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.maxConcurrentConCount.label" defaultMessage="maxConcurrentConCount" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                                    )}
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item container xs={6} sm={3} md={3} lg={2}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    control={control}
                                                    name={pageLabelsConstantsAndMessages.name.maxAttachmentSize}
                                                    rules={mode === 'view' ? { required: false } : {
                                                        required: true,
                                                        maxLength: 100,
                                                        pattern: commonPatterns.commonTextfeildPattern.patternNumericOnly
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            className={classes.textField}
                                                            variant="outlined"
                                                            margin="normal"
                                                            id="maxAttachmentSize"
                                                            size='small'
                                                            label={<FormattedMessage id="columnname.maxAttachmentSize.label" defaultMessage="maxConcurrentConCount" />}
                                                            required={true}
                                                            {...field}
                                                            error={errors.maxAttachmentSize}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {
                                                                    asterisk: classes.asterisk
                                                                }
                                                            }}
                                                            InputProps={{
                                                                classes: { notchedOutline: classes.specialOutline }
                                                            }}
                                                            disabled={mode === 'view' ? true : false}
                                                            defaultValue="10"
                                                        />
                                                    )}
                                                />

                                                <div className={classes.error}>
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.maxAttachmentSize}.type`, errors) === "required" && (
                                                        <FormHelperText className={classes.error}>
                                                            {<FormattedMessage id="columnname.maxAttachmentSize.label" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}
                                                        </FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.maxAttachmentSize}.type`, errors) === "pattern" && (
                                                        <FormHelperText className={classes.error}>   {<FormattedMessage id="columnname.maxAttachmentSize.label" />} {commonPatterns.commonErrorsForTextFields.errorForNumericOnly}</FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.maxAttachmentSize}.type`, errors) === "maxLength" && (
                                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.maxAttachmentSize.label" defaultMessage="maxAttachmentSize" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                                    )}
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    control={control}
                                                    name={pageLabelsConstantsAndMessages.name.fromEmailId}
                                                    rules={mode === 'view' ? { required: false } : {
                                                        required: true,
                                                        maxLength: 100,
                                                        pattern: commonPatterns.commonTextfeildPattern.patternForEmailId
                                                    }}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextField
                                                            className={classes.textField}
                                                            variant="outlined"
                                                            margin="normal"
                                                            id="fromEmailId"
                                                            size='small'
                                                            label={<FormattedMessage id="columnname.fromEmailId.label" defaultMessage="fromEmailId" />}
                                                            required={true}
                                                            {...field}
                                                            error={errors.fromEmailId}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {
                                                                    asterisk: classes.asterisk
                                                                }
                                                            }}
                                                            InputProps={{
                                                                classes: { notchedOutline: classes.specialOutline }
                                                            }}
                                                            disabled={mode === 'view' ? true : false}
                                                        />
                                                    )}
                                                />
                                                <div className={classes.error}>
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.fromEmailId}.type`, errors) === "required" && (
                                                        <FormHelperText className={classes.error}>
                                                            {<FormattedMessage id="columnname.fromEmailId.label" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}
                                                        </FormHelperText>
                                                    )}

                                                    {_.get(`${pageLabelsConstantsAndMessages.name.fromEmailId}.type`, errors) === "pattern" && (
                                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.fromEmailId.label" defaultMessage="From SIIX Email" /> {commonPatterns.commonErrorsForTextFields.errorForEmailId}</FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.fromEmailId}.type`, errors) === "maxLength" && (
                                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.fromEmailId.label" defaultMessage="fromEmailId" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                                    )}
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    control={control}
                                                    name={pageLabelsConstantsAndMessages.name.fromEmailName}
                                                    rules={mode === 'view' ? { required: false } : {
                                                        required: true,
                                                        maxLength: 100,
                                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphabetsOnly
                                                    }}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextField
                                                            className={classes.textField}
                                                            variant="outlined"
                                                            margin="normal"
                                                            id="fromEmailName"
                                                            size='small'
                                                            label={<FormattedMessage id="columnname.fromEmailName.label" defaultMessage="fromEmailName" />}
                                                            required={true}
                                                            {...field}
                                                            error={errors.fromEmailName}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {
                                                                    asterisk: classes.asterisk
                                                                }
                                                            }}
                                                            InputProps={{
                                                                classes: { notchedOutline: classes.specialOutline }
                                                            }}
                                                            disabled={mode === 'view' ? true : false}
                                                        />
                                                    )}
                                                />
                                                <div className={classes.error}>
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.fromEmailName}.type`, errors) === "required" && (
                                                        <FormHelperText className={classes.error}>
                                                            {<FormattedMessage id="columnname.fromEmailName.label" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}
                                                        </FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.fromEmailName}.type`, errors) === "maxLength" && (
                                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.fromEmailName.label" defaultMessage="fromEmailName" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>

                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.fromEmailName}.type`, errors) === "pattern" && (
                                                        <FormHelperText className={classes.error}><FormattedMessage id="columnname.fromEmailName.label" defaultMessage="fromEmailName" /> {commonPatterns.commonErrorsForTextFields.errorForAplhabetsOnly}</FormHelperText>
                                                    )}
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    control={control}
                                                    name={pageLabelsConstantsAndMessages.name.smtpUserName}
                                                    rules={mode === 'view' ? { required: false } : {
                                                        required: true,
                                                        maxLength: 100,
                                                        pattern: commonPatterns.commonTextfeildPattern.patternForAlphaNumericAndSpecialChar
                                                    }}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextField
                                                            className={classes.textField}
                                                            variant="outlined"
                                                            margin="normal"
                                                            id="smtpUserName"
                                                            size='small'
                                                            label={<FormattedMessage id="columnname.smtpUserName.label" defaultMessage="smtpUserName" />}
                                                            required={true}
                                                            {...field}
                                                            error={errors.smtpUserName}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {
                                                                    asterisk: classes.asterisk
                                                                }
                                                            }}
                                                            InputProps={{
                                                                classes: { notchedOutline: classes.specialOutline }
                                                            }}
                                                            disabled={mode === 'view' ? true : false}
                                                        />
                                                    )}
                                                />
                                                <div className={classes.error}>
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.smtpUserName}.type`, errors) === "required" && (
                                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.smtpUserName.label" defaultMessage="smtpUserName" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}</FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.smtpUserName}.type`, errors) === "maxLength" && (
                                                        <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.smtpUserName.label" defaultMessage="smtpUserName" />} {<FormattedMessage id="textfield.error.cannotexceedmorethanhundredcharsmessage" />}</FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.smtpUserName}.type`, errors) === "pattern" && (
                                                        <FormHelperText className={classes.error}> {<FormattedMessage id="columnname.smtpUserName.label" defaultMessage="SMTP user Name " />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNumericAndSpecialChar}</FormHelperText>
                                                    )}
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item container xs={12} sm={6} md={6} lg={6}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    control={control}
                                                    name={pageLabelsConstantsAndMessages.name.smtpPassword}
                                                    rules={mode === 'view' ? { required: false } : {
                                                        required: true,
                                                        maxLength: 50,
                                                    }}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <TextField
                                                            className={classes.textField}
                                                            variant="outlined"
                                                            margin="normal"
                                                            id="smtpPassword"
                                                            size='small'
                                                            autoComplete="new-password"
                                                            label={<FormattedMessage id="columnname.smtpPassword.label" defaultMessage="smtpPassword" />}
                                                            type={values.showPassword ? "text" : "password"}
                                                            value={values.smtpPassword}
                                                            required={true}
                                                            {...field}
                                                            error={errors.smtpPassword}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {
                                                                    asterisk: classes.asterisk
                                                                }
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            edge="end"
                                                                            size="large">
                                                                            {values.showPassword ? <Visibility className={classes.iconButton} /> : <VisibilityOff className={classes.iconButton} />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                                classes: { notchedOutline: classes.specialOutline }
                                                            }}
                                                            disabled={mode === 'view' ? true : false}
                                                        />
                                                    )}
                                                />

                                                <div className={classes.error}>
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.smtpPassword}.type`, errors) === "required" && (
                                                    <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.smtp.label" />}
                                                    {<FormattedMessage id="texfield.error.password" defaultMessage="SMTP Password is required" />}</FormHelperText>
                                                    )}
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.smtpPassword}.type`, errors) === "maxLength" && (
                                                        <FormHelperText className={classes.error}>
                                                            {<FormattedMessage id="columnname.smtpPassword.label" />}  {<FormattedMessage id="textfield.error.cannotexceedmorethanfiftycharcts" defaultMessage="SMTP password cannot exceed more than 50 chars" />}
                                                        </FormHelperText>
                                                    )}
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item container xs={12} sm={6} md={6} lg={6}  >
                                            <FormControl fullWidth>
                                                <Controller
                                                    control={control}
                                                    name={pageLabelsConstantsAndMessages.name.securityType}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            className={classes.textField}
                                                            select
                                                            required={true}
                                                            variant="outlined"
                                                            margin="normal"
                                                            id="securityType"
                                                            size='small'
                                                            label={<FormattedMessage id="columnname.securitytype.label" defaultMessage="Security Type" />}
                                                            {...field}
                                                            error={errors.securityType}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {
                                                                    asterisk: classes.asterisk
                                                                }
                                                            }}
                                                            InputProps={{
                                                                classes: { notchedOutline: classes.specialOutline }
                                                            }}
                                                            disabled={mode === 'view' ? true : false}
                                                        >
                                                            {serverTypeOption.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.value}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    )}
                                                />
                                                <div className={classes.error}>
                                                    {_.get(`${pageLabelsConstantsAndMessages.name.securityType}.type`, errors) === "required" && (
                                                        <FormHelperText className={classes.error}>
                                                            <FormattedMessage id="textfield.error.securitytype" />
                                                        </FormHelperText>
                                                    )}
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                                            <Grid container justify='flex-start' alignItems='center'>
                                                <Controller
                                                    render={({
                                                        field: { onChange } }) => (<Switch
                                                            color='primary'
                                                            checked={emailServerConfigObjectToBeEdited.active !== undefined ? emailServerConfigObjectToBeEdited.active : true}
                                                            onChange={((event) => {
                                                                onChange(!(emailServerConfigObjectToBeEdited.active !== undefined ? emailServerConfigObjectToBeEdited.active : true));
                                                                setEmailServerConfigObjectToBeEdited({ ...emailServerConfigObjectToBeEdited, active: event.target.checked })
                                                            })}
                                                            disabled={mode === 'view' ? true : false}
                                                        />)}
                                                    type="checkbox"
                                                    name={pageLabelsConstantsAndMessages.name.active}
                                                    control={control}
                                                    defaultValue={emailServerConfigObjectToBeEdited.active !== undefined ? emailServerConfigObjectToBeEdited.active : true}
                                                />
                                                <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                                            <Grid container justify='flex-start' alignItems='center'>
                                                <Controller
                                                    render={({
                                                        field: { onChange } }) => (<Switch
                                                            color='primary'
                                                            checked={emailServerConfigObjectToBeEdited.verifyCertificate !== undefined && emailServerConfigObjectToBeEdited.verifyCertificate !== null ? emailServerConfigObjectToBeEdited.verifyCertificate : true}
                                                            onChange={((event) => {
                                                                onChange(!(emailServerConfigObjectToBeEdited.verifyCertificate !== undefined && emailServerConfigObjectToBeEdited.verifyCertificate !== null ? emailServerConfigObjectToBeEdited.verifyCertificate : true));
                                                                setEmailServerConfigObjectToBeEdited({ ...emailServerConfigObjectToBeEdited, verifyCertificate: event.target.checked })
                                                            })}
                                                            disabled={mode === 'view' ? true : false}
                                                        />)}
                                                    type="checkbox"
                                                    name={pageLabelsConstantsAndMessages.name.verifyCertificate}
                                                    control={control}
                                                    defaultValue={emailServerConfigObjectToBeEdited.verifyCertificate !== undefined && emailServerConfigObjectToBeEdited.verifyCertificate !== null ? emailServerConfigObjectToBeEdited.verifyCertificate : true}
                                                />
                                                <InputLabel>{<FormattedMessage id="columnname.verifyCertificate.label" defaultMessage="VerifyCertificate" />}</InputLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                                            <Grid container justify='flex-start' alignItems='center'>
                                                <Controller
                                                    render={({
                                                        field: { onChange } }) => (<Switch
                                                            color='primary'
                                                            checked={emailServerConfigObjectToBeEdited.isMaster !== undefined && emailServerConfigObjectToBeEdited.isMaster !== null ? emailServerConfigObjectToBeEdited.isMaster : false}
                                                            onChange={((event) => {
                                                                onChange(!(emailServerConfigObjectToBeEdited.isMaster !== undefined && emailServerConfigObjectToBeEdited.isMaster !== null ? emailServerConfigObjectToBeEdited.isMaster : false));
                                                                setEmailServerConfigObjectToBeEdited({ ...emailServerConfigObjectToBeEdited, isMaster: event.target.checked })
                                                            })}
                                                            disabled={mode === 'view' ? true : false}
                                                        />)}
                                                    type="checkbox"
                                                    name={pageLabelsConstantsAndMessages.name.isMaster}
                                                    control={control}
                                                    defaultValue={emailServerConfigObjectToBeEdited.isMaster !== undefined && emailServerConfigObjectToBeEdited.isMaster !== null ? emailServerConfigObjectToBeEdited.isMaster : false}

                                                />
                                                <InputLabel>{<FormattedMessage id="columnname.isMaster.label" defaultMessage="IsMaster" />}</InputLabel>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>

                            {!mobileScreen && (
                                <DialogActions className={classes.dialogAction} >
                                    <Grid item container justifyContent="flex-start" xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} style={{ marginLeft: '-1.0%' }}>
                                        {warningMessage}
                                    </Grid>
                                    <Grid item container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} >

                                        <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2} endIcon={<ClearIcon />}
                                            onClick={() => handleClose(false)}
                                        ><FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />
                                        </Button>


                                        <Button size='small' variant="contained" color="secondary" disabled={disableRestoreButton} className={classes.stateButton} elevation={2} endIcon={<IconRestore />} onClick={(() => { handlereset(true) })}
                                        > <FormattedMessage id="common.button.reset.lable" defaultMessage="Restore" /></Button>

                                        {(mode === 'create') ?
                                            <Button size='small' variant="contained" color="primary" className={classes.commondialogstateButtonForSave}
                                                elevation={2}
                                                endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />}
                                                disabled={isFormSubmitted}
                                                onClick={handleSubmit(onSubmit, errors)}>
                                                {(mode === 'create') ? <FormattedMessage id="common.button.save" defaultMessage="Save" /> :
                                                    <FormattedMessage id="common.button.update" defaultMessage="Update" />}
                                            </Button>
                                            :
                                            (mode === 'create' || mode === 'view' || mode === 'read') ?
                                                <Button size='small'
                                                    variant="contained" color="primary" className={classes.stateButton} disabled={disablesaveButton || isFormSubmitted} elevation={2}
                                                    endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />}
                                                    onClick={handleSubmit(onSubmit, errors)}
                                                >
                                                    {(mode === 'create') ? <FormattedMessage id="common.button.save" defaultMessage="Save" /> : <FormattedMessage id="common.button.update" defaultMessage="Update" />}
                                                </Button>
                                                :
                                                (mode === 'edit') && (isDirty === false) || (!isValid) ?
                                                    <Button size='small' variant="contained" color="primary" disabled={disablesaveButton || isFormSubmitted} className={classes.commondialogstateButtonForSave}
                                                        elevation={2}
                                                        endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />}
                                                        onClick={handleSubmit(onSubmit, errors)}>
                                                        {(mode === 'create') ? <FormattedMessage id="common.button.save" defaultMessage="Save" /> :
                                                            <FormattedMessage id="common.button.update" defaultMessage="Update" />}
                                                    </Button> :
                                                    <Button size='small' variant="contained" color="primary" className={classes.commondialogstateButtonForSave}
                                                        elevation={2}
                                                        disabled={isFormSubmitted}
                                                        endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />}
                                                        onClick={handleSubmit(onSubmit, errors)}>
                                                        <FormattedMessage id="common.button.update" defaultMessage="Update" />
                                                    </Button>}
                                    </Grid>
                                </DialogActions>
                            )}

                            {mobileScreen && (<Grid item container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                <DialogActions className={classes.dialogAction} >
                                    <Grid item container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.topGrid} >
                                        <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                                            <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2}
                                                onClick={() => handleClose(false)}
                                            ><ClearIcon />
                                            </Button>
                                        </Tooltip>

                                        {(mode === 'create' || mode === 'edit') ?
                                            <Tooltip title={<FormattedMessage id="common.button.reset.lable" />}>
                                                <Button size='small' variant="contained" color="secondary" className={classes.stateButton} elevation={2} onClick={(() => { handlereset(true) })}
                                                ><IconRestore /></Button></Tooltip>
                                            :
                                            (mode === 'view' || mode === 'read') ?
                                                <Tooltip title={<FormattedMessage id="common.button.reset.lable" />}>
                                                    <Button size='small' disabled={disableRestoreButton} variant="contained" color="secondary" className={classes.stateButtonToRestore} elevation={2}
                                                        onClick={(() => { handlereset(true) })}>
                                                        <IconRestore />
                                                    </Button></Tooltip> : null}

                                        {(tabvalue === 3) && (mode === 'create') ?
                                            <Tooltip title={<FormattedMessage id="common.button.save" />}>
                                                <Button size='small' variant="contained" color="primary" className={classes.stateButtonForSave}
                                                    elevation={2}
                                                    endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : ''}
                                                    disabled={isFormSubmitted}
                                                    onClick={handleSubmit(onSubmit, errors)}>
                                                    <IconSaveOrUpdate />
                                                </Button></Tooltip>
                                            :
                                            (mode === 'create' || mode === 'view' || mode === 'read') ?
                                                <Tooltip title={<FormattedMessage id="common.button.save" />}>
                                                    <Button size='small'
                                                        variant="contained" color="primary" className={classes.stateButtonForSave} disabled={disablesaveButton || isFormSubmitted} elevation={2}
                                                        endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : ''}
                                                        onClick={handleSubmit(onSubmit, errors)}
                                                    >
                                                        <IconSaveOrUpdate />
                                                    </Button></Tooltip>
                                                :
                                                ((mode === 'edit') && (isDirty === false)) ?
                                                    <Tooltip title={<FormattedMessage id="common.button.update" />}>
                                                        <Button size='small' variant="contained" color="primary" disabled={disablesaveButton || isFormSubmitted} className={classes.stateButtonForSave}
                                                            elevation={2}
                                                            endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : ''}
                                                            onClick={handleSubmit(onSubmit, errors)}>
                                                            <IconSaveOrUpdate />
                                                        </Button></Tooltip> :
                                                    <Tooltip title={<FormattedMessage id="common.button.update" />}>
                                                        <Button size='small' variant="contained" color="primary" className={classes.stateButtonForSave}
                                                            elevation={2}
                                                            disabled={isFormSubmitted}
                                                            endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : ''}
                                                            onClick={handleSubmit(onSubmit, errors)}>
                                                            <IconSaveOrUpdate />
                                                        </Button></Tooltip>}
                                    </Grid>
                                </DialogActions>
                            </Grid>)}
                        </Dialog>
                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

export default CreateOrEditEmailServerConfig;