import { useTheme } from '@emotion/react';
import { Button, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useStyles from '../../common/CommonStyle/CommonStyle';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';
import ClearIcon from '@mui/icons-material/Clear';
import { CommonService } from '../../../services';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';

const columnFieldsName = {
    poLineNo: 'poLineNo',
    latestDataSource: 'latestDataSource',
    updatedTime: 'updatedTime',
}

function DataSourceDialog(props) {
    const { open, onClose, msgIdValue,poNumber } = props;
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [columns, setColumns] = useState([]);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [tableData, setTableData] = useState([]);

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });


    const pageLabelsConstantsAndMessages = {
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
    }

    const [paramBody, setParamBody] = useState(
        {
            body: {
                msgId: msgIdValue
            },
            sortDir: 'desc',
            sortKey: 'updatedTime',
        }
    );

    const handleClose = (value) => {
        onClose(value)
    }

    const commonColumnForDataSource = [
        {
            field: columnFieldsName.poLineNo,
            headerName: intl.formatMessage({ id: "columnname.polineno.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            width: 160,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: true,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.latestDataSource,
            headerName: intl.formatMessage({ id: "columnname.latestDataSource.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 390,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.updatedTime,
            headerName: intl.formatMessage({ id: "columnname.updatedtime.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 180,
            sortable: true,
            type: 'string',
            hide: false,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (<span>{cellParams.row.updatedTimeDisp}</span>)
        },
    ];

    useEffect(() => {
        setColumns(commonColumnForDataSource)
    }, [selectedLanguage, tableData])

    useEffect(() => {
        if (open === true) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(`/poOutboundMsgHdrs/${msgIdValue}/poOutboundDtlsAuditView`, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTableData(response.data.content);
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [open, paramBody])

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <Dialog
                        fullWidth
                        maxWidth='md'
                        aria-labelledby="create-or-delete-dialog"
                        open={open}>
                        <DialogTitle className={classes.titleCommonDialogComponent}>
                            <Grid item container xs={12} sm={12} md={12} lg={12}>
                                <Grid item container justifyContent='flex-start' xs={12} sm={6} md={6} lg={6} className={classes.dialogPOScreen} >
                                    <strong><Typography><FormattedMessage id="dilogTitle.dataSourceHistory.label" defaultMessage="Data Source History" /></Typography></strong>
                                </Grid>
                                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={6} md={6} lg={6} >
                                    <strong><Typography color="white">{poNumber}</Typography></strong>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent >
                            <Paper elevation={3} className={classes.gridHeightAndWidthForViewDataSource}>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <DataGridPro
                                        className={classes.customTableStyle}
                                        disableColumnFilter={true}
                                        loading={tableLoadingStatus}
                                        rows={tableData}
                                        columns={columns}
                                        getRowId={(row) => row.poDtlAuditId}
                                        showToolbar
                                        scrollbarSize={30}
                                        disableColumnFilter={true}
                                        hideFooter={true}
                                        density="compact"
                                        disableSelectionOnClick={true}
                                        disableMultipleColumnsSorting={true}
                                        disableColumnMenu={true}
                                        disableColumnSelector={false}
                                        localeText={localtext}
                                        hideFooterSelectedRowCount={true}
                                        onSortModelChange={(params) => {
                                            let sortModel = params[0];
                                            if (sortModel !== undefined) {
                                                setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                            }
                                        }}
                                        sortingOrder={['desc', 'asc']}
                                        filterMode="server"
                                    />
                                </div>
                            </Paper>
                        </DialogContent>
                        {!mobileScreen && (
                            <DialogActions className={classes.dialogAction} >

                                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} >
                                    <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2} endIcon={<ClearIcon />}
                                        onClick={() => handleClose(false)}
                                    ><FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />
                                    </Button>

                                </Grid>

                            </DialogActions>)}
                        {mobileScreen && (<Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                            <DialogActions className={classes.dialogAction}>
                                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.topGrid} >
                                    <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                                        <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2}
                                            onClick={() => handleClose(false)}
                                        >  <ClearIcon />
                                        </Button>
                                    </Tooltip>

                                </Grid>
                            </DialogActions>
                        </Grid>)}
                    </Dialog>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default DataSourceDialog;