import {
    Badge,
    Button, CssBaseline, Grid, IconButton, Paper,

    Snackbar, Tooltip, useMediaQuery, useTheme
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    LicenseInfo, DataGridPro, GridFilterPanel, getGridStringOperators, GridToolbarColumnsButton, GridToolbarDensitySelector
} from '@mui/x-data-grid-pro';
import { CommonService } from '../../../services';
import { LoggedInUserDetails } from '../../common/LoggedInUserDetails/LoggedInUserDetails';
import Alert from '@mui/lab/Alert';
import { useHistory } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CustomFilters } from '../../common/CustomFilters/CustomFilters';
import ViewIcon from '@mui/icons-material/Visibility';
import useStyles from '../../common/CommonStyle/CommonStyle';
import { FormattedMessage, useIntl } from 'react-intl';
import GridTextLocalization from '../../common/GridTextLocalization/GridTextLocalization';
import { SupportedLanguageDetails } from '../../common/SupportedLanguageDetails/SupportedLanguageDetails';
import CommonGridPagination from '../../common/CommonGridPagination/CommonGridPagination';
import { ModuleAccessPermissionKey } from '../../../Constants/ModuleAccessKey';
import saveAs from 'file-saver';
import GetAppIcon from '@mui/icons-material/GetApp';
import CommonBackDrop from '../../common/BackDrop';
import { FilterList } from '@mui/icons-material';
import CustomFilterComponent, { GetFilterableColumns } from '../../common/CustomFilterComponent';

const columnFieldsName = {
    msgId: 'msgId',
    msgNum: 'msgNum',
    bpCode: 'bpCode',
    bpName: 'bpName',
    bpType: 'bpType',
    plantCode: 'plantCode',
    testInd: 'testInd',
    invoiceType: 'invoiceType',
    billingType: 'billingType',
    invoiceNo: 'invoiceNo',
    invoiceDt: 'invoiceDate',
    servComplDt: 'servComplDt',
    freeText: 'freeText',
    taxExempReason: 'taxExempReason',
    receiverIdentification: 'receiverIdentification',
    senderIdentification: 'senderIdentification',
    preparedDate: 'preparedDate',
    preparedTime: 'preparedTime',
    msgPreparedDateTime: 'msgPreparedDateTime',
    interchangeControlRef: 'interchangeControlRef',
    messageRefNo: 'messageRefNo',
    messageType: 'messageType',
    messageVersionNo: 'messageVersionNo',
    messageReleaseNo: 'messageReleaseNo',
    controllingAgency: 'controllingAgency',
    associationAssignedCode: 'associationAssignedCode',
    paymentTerms: 'paymentTerms',
    buyerName: 'buyerName',
    buyerPartyCode: 'buyerPartyCode',
    buyerAddress: "buyerAddress",
    buyerCity: "buyerCity",
    buyerState: "buyerState",
    buyerPostalCode: "buyerPostalCode",
    buyerCountry: "buyerCountry",
    buyerCountryCode: "buyerCountryCode",
    invoiceeName: 'invoiceeName',
    invoiceePartyCode: 'invoiceePartyCode',
    invoiceeAddress: 'invoiceeAddress',
    invoiceeCity: 'invoiceeCity',
    invoiceeState: 'invoiceeState',
    invoiceePostalCode: 'invoiceePostalCode',
    invoiceeCountry: 'invoiceeCountry',
    invoiceeCountryCode: 'invoiceeCountryCode',
    invoiceIssuerPartyCode: 'invoiceIssuerPartyCode',
    invoiceeIssuerName: 'invoiceeIssuerName',
    invoiceIssuerCity: 'invoiceIssuerCity',
    invoiceIssuerState: 'invoiceIssuerState',
    invoiceIssuerCountry: 'invoiceIssuerCountry',
    invoiceIssuerPostalCode: 'invoiceIssuerPostalCode',
    invoiceIssuerCountryCode: 'invoiceIssuerCountryCode',
    currency: 'currency',
    currencyCode: 'currencyCode',
    homeCurrency: 'homeCurrency',
    currencyExchangeRate: 'currencyExchangeRate',
    shipToName: 'shipToName',
    shipToPartyCode: 'shipToPartyCode',
    shipToAddress: 'shipToAddress',
    shipToCity: 'shipToCity',
    shipToState: 'shipToState',
    shipToPostalCode: 'shipToPostalCode',
    shipToCountry: 'shipToCountry',
    shipToCountryCode: 'shipToCountryCode',
    shipFromPartyCode: 'shipFromPartyCode',
    shipFromName: 'shipFromName',
    shipFromAddress: 'shipFromAddress',
    shipFromCity: 'shipFromCity',
    shipFromState: 'shipFromState',
    shipFromCountry: 'shipFromCountry',
    shipFromPostalCode: 'shipFromPostalCode',
    shipFromCountryCode: 'shipFromCountryCode',
    payeePartyCode: 'payeePartyCode',
    payeeName: "payeeName",
    payeeAddress: "payeeAddress",
    payeeCity: "payeeCity",
    payeeState: 'payeeState',
    payeeCountry: 'payeeCountry',
    payeePostalCode: "payeePostalCode",
    payeeCountryCode: "payeeCountryCode",
    shipmentTerms: "shipmentTerms",
    modeOfTransport: "modeOfTransport",
    invoiceAmt: "invoiceAmt",
    totalTaxableAmt: "totalTaxableAmt",
    totalLineItemAmt: "totalLineItemAmt",
    totalTaxAmt: "totalTaxAmt",
    totalInvoiceAddtnlAmt: 'totalInvoiceAddtnlAmt',
    taxRate1: 'taxRate1',
    taxAmount1: 'taxAmount1',
    taxRate2: 'taxRate2',
    taxAmount2: 'taxAmount2',
    taxRate3: 'taxRate3',
    taxAmount3: 'taxAmount3',
    packingCharges: 'packingCharges',
    freightFaxNo: 'freightFaxNo',
    freightEmail: 'freightEmail',
    freightCharges: 'freightCharges',
    deliveryCharges: 'deliveryCharges',
    incoterm: 'incoterm',
    incotermPart2: 'incotermPart2',
    portInfoFrom: 'portInfoFrom',
    portInfoTo: 'portInfoTo',
    approved: 'approved',
    approvedBy: 'approvedBy',
    approvedDate: 'approvedDate',
    apprEmailSent: 'apprEmailSent',
    msgStatusFlag: 'msgStatusFlag',
    msgStatusRemarks: 'msgStatusRemarks',
    createdBy: 'createdBy',
    createdTime: 'createdTime',
    updatedBy: 'updatedBy',
    updatedTime: 'updatedTime',
    erpOrgCode: 'organization.erpOrgCode',
    erpEntityCode: 'entity.erpEntityCode',
    orgName: 'organization.orgName',
    entityName: 'entity.entityName',
    attribute1: 'attribute1',
    attribute2: 'attribute2',
    attribute3: 'attribute3',
    attribute4: 'attribute4',
    attribute5: 'attribute5',
    attribute6: 'attribute6',
    attribute7: 'attribute7',
    attribute8: 'attribute8',
    attribute9: 'attribute9',
    attribute10: 'attribute10',
    messageSentMode: 'messageSentMode',
    messageSentTime: 'messageSentTime',
    messageReceivedMode: 'messageReceivedMode',
    messageReceivedTime: 'messageReceivedTime',
    messageNumber: 'messageNumber',
    latestDataSource: 'latestDataSource',
    batchId: 'batchId',

};

function InvoiceInboundHdrDrpDn(props) {

    const { msgNum } = props;
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const pageLabelsConstantsAndMessages = {
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    };
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [selectedRow, setSelectedRow] = React.useState([]);
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const invoiceModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.invoiceInbound];
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [searchedEntityCode, setSearchedEntityCode] = useState('');
    const [searchedStatusFlag, setSearchedStatusFlag] = useState('');
    const [searchedApprovedRecordsRecords, setSearchedApprovedRecordsRecords] = useState(null)
    const [searchedBpCode, setSearchedBpCode] = useState('');
    const [searchedBpName, setSearchedBpName] = useState('');
    const [searchedMessageRcvMode, setSearchedMessageRcvMode] = useState('');
    const [searchedMsgRcvFromDate, setSearchedMsgRcvFromDate] = useState('');
    const [searchedMsgRcvToDate, setSearchedMsgRcvToDate] = useState('');
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [chipInfoList, setChipInfoList] = useState([]);
    const [msgHdrPopUp, setMsgHdrPopUp] = useState(false);
    const [msgDtlsPopUp, setMsgDtlsPopUp] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [disableApproveButton, isDisableApproveButton] = useState(true);
    const [keyValueData, setKeyValueData] = useState({});
    const [msgIdForDtlsExcel, setMsgIdForDtlsExcel] = useState({});
    const [msgIdValue, setMsgIdValue] = useState({});
    const [open, setOpen] = React.useState(false);
    const [disiablingDownloadButtonForZeroRecords, setDisiablingDownloadButtonForZeroRecords] = useState(false)
    const [openFilterPanel, setFilterPanel] = useState(false);

    const handleCloseForFilter = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    var defaultEntity = loggedInUserInfo.defaultEntity;
    const defaulSelectedFilters = [];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);

    const [paramBody, setParamBody] = useState(
        {
            body: {
                headChipListNotReq: true,
                msgNum: msgNum,
                recordStatusFlag: 'All',
                recordApprovedStatus: 'All',
            },
            pageNo: 0,
            sortDir: 'desc',
            sortKey: 'updatedTime',
            recordsPerPage: 25,
        }
    );

    const commonColumnForInvoiceDrpDn =

        [
            {
                field: 'action',
                headerName: intl.formatMessage({ id: "columnname.action.label" }),
                width: 100,
                sortable: false,
                hide: false,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                filterable: false,

                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (
                    <div>
                        {(invoiceModuleAccess !== undefined ? invoiceModuleAccess.readFlag : false)
                            &&
                            (<Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    let msgId = cellParams.row.msgId;
                                    history.push(`/invoiceInboundDtls/${msgId}`, { mode: 'view', msgId: msgId });
                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar} size="large">
                                        <ViewIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                    </div>
                )
            },
            {
                field: columnFieldsName.erpEntityCode,
                headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 85,
                sortable: false,
                type: 'string',
                hide: true,
                filterable: true,
                valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.invoiceNo,
                width: 190,
                headerName: intl.formatMessage({ id: "columnname.invoiceNumber.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignRight}`,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.invoiceDt,
                width: 165,
                headerName: intl.formatMessage({ id: "columnname.invoicedate.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (
                    <span>{cellParams.row.invoiceDtDisp}</span>
                )
            },
            {
                field: columnFieldsName.bpCode,
                width: 130,
                headerName: intl.formatMessage({ id: "common.textfield.columname.bpcode" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.bpName,
                width: 300,
                headerName: intl.formatMessage({ id: "columnName.bpname.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },

            {
                field: columnFieldsName.bpType,
                width: 300,
                headerName: intl.formatMessage({ id: "columnname.bptype.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },

            {
                field: columnFieldsName.approved,
                width: 150,
                headerName: intl.formatMessage({ id: "columnName.approved.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.ApproveFailureChipsForPO
            },
            {
                field: columnFieldsName.approvedBy,
                width: 170,
                headerName: intl.formatMessage({ id: "columnName.approvedBy.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.approvedDate,
                width: 165,
                headerName: intl.formatMessage({ id: "columnName.approvedDate.label" }),
                sortable: true,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (
                    <span>{cellParams.row.approvedDtDisp}</span>
                )
            },
            {
                field: columnFieldsName.msgStatusFlag,
                width: 150,
                headerName: intl.formatMessage({ id: "columnname.Msgstatus.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.PoStatusForOutChips
            },
            {
                field: columnFieldsName.messageReceivedMode,
                width: 160,
                headerName: intl.formatMessage({ id: "columnname.messageReceivedMode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: ((cellParams) => {
                    if (cellParams.row.messageRcvMode === 'AS2') {
                        return 'AS2'
                    } else if (cellParams.row.poScheduleType === 'VAN') {
                        return 'VAN'
                    }
                })
            },
            {
                field: columnFieldsName.messageReceivedTime,
                width: 160,
                headerName: intl.formatMessage({ id: "columnname.messageReceivedTimeInvoice.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                sortable: true,
                type: 'dateTime',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.messageReceivedTimeDisp}</span>)
            },
            {
                field: columnFieldsName.latestDataSource,
                width: 170,
                headerName: intl.formatMessage({ id: "columnname.latestDataSource.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.updatedBy,
                headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 155,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.updatedTime,
                headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.updatedTimeDisp}</span>)
            },
            {
                field: columnFieldsName.createdBy,
                headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 155,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.createdTime,
                headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.createdTimeDisp}</span>)
            },
            {
                field: columnFieldsName.attribute1,
                headerName: intl.formatMessage({ id: "columnname.attribute1.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute2,
                headerName: intl.formatMessage({ id: "columnname.attribute2.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute3,
                headerName: intl.formatMessage({ id: "columnname.attribute3.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute4,
                headerName: intl.formatMessage({ id: "columnname.attribute4.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute5,
                headerName: intl.formatMessage({ id: "columnname.attribute5.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute6,
                headerName: intl.formatMessage({ id: "columnname.attribute6.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute7,
                headerName: intl.formatMessage({ id: "columnname.attribute7.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute8,
                headerName: intl.formatMessage({ id: "columnname.attribute8.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute9,
                headerName: intl.formatMessage({ id: "columnname.attribute9.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.attribute10,
                headerName: intl.formatMessage({ id: "columnname.attribute10.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                width: 150,
                sortable: false,
                type: 'dateTime',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },

        ];

    useEffect(() => {
        setColumns(commonColumnForInvoiceDrpDn)
    }, [selectedLanguage])


    useEffect(() => {
        setTableLoadingStatus(true);
        setTableData([]);
        CommonService.GetListApi('/invoiceInboundHdr', paramBody)
            .then((response) => {
                setTotalRowCount(response.data.totalElements);
                setTableLoadingStatus(false);
                setTableData(response.data.content);
                (response.data.totalElements > 0 ?
                    setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                    :
                    setChipInfoList([]));
            })
            .catch(function (error) {
                setTableLoadingStatus(false);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [paramBody, refreshTable]);

    useEffect(() => {
        if (totalRowCount === 0) {
            setDisiablingDownloadButtonForZeroRecords(true)
        } else {
            setDisiablingDownloadButtonForZeroRecords(false)
        }
    }, [totalRowCount])

    if (columns.length > 0) {
        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };

        const approvedColumn = columns.find((column) => column.field === columnFieldsName.approved);
        const approvedColIndex = columns.findIndex((col) => col.field === columnFieldsName.approved);

        const approvedFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.ApprovedPOInputValue,
            }),
        );

        columns[approvedColIndex] = {
            ...approvedColumn,
            filterOperators: approvedFilterOperators,
        };

        const statusFlagColumn = columns.find((column) => column.field === columnFieldsName.msgStatusFlag);
        const statusFlagColIndex = columns.findIndex((col) => col.field === columnFieldsName.msgStatusFlag);

        const statusFlagFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.PoStatusOutboundColInputValue,
            }),
        );

        columns[statusFlagColIndex] = {
            ...statusFlagColumn,
            filterOperators: statusFlagFilterOperators,
        };

        columns[statusFlagColIndex] = {
            ...statusFlagColumn,
            filterOperators: statusFlagFilterOperators,
        };

        const commTypeColumn = columns.find((column) => column.field === columnFieldsName.messageReceivedMode);
        const commTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.messageReceivedMode);

        const commTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.MessageRcvInputValue,
            }),
        );

        columns[commTypeColIndex] = {
            ...commTypeColumn,
            filterOperators: commTypeFilterOperators,
        };

        const supplierNameColumn = columns.find((column) => column.field === columnFieldsName.bpName);
        const supplierNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.bpName);

        const supplierNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.SupplierVendorNameInputValue,
            }),
        );

        columns[supplierNameColIndex] = {
            ...supplierNameColumn,
            filterOperators: supplierNameFilterOperators,
        };
    }

    function currentlySelectedRow(selections) {
        setSelectedRow(selections)
        if (selections.length === 0) {
            isDisableApproveButton(true)
        } else {
            isDisableApproveButton(false)
        }
    }


    let filterBodyObj = {
        erpEntityCode: searchedEntityCode,
        bpCode: searchedBpCode,
        bpName: searchedBpName,
        approved: searchedApprovedRecordsRecords,
        msgStatusFlag: searchedStatusFlag,
        messageReceivedMode: searchedMessageRcvMode,
    }

    useEffect(() => {
        let colValDataObj = {}
        for (let key in filterBodyObj) {
            let value = filterBodyObj[key]
            if (filterBodyObj.hasOwnProperty(key) && ((value !== null) && (value !== "") && (value !== 0) && (value !== undefined) && (value !== {}))) {
                colValDataObj[key] = value.toString();
            }
            setKeyValueData(colValDataObj)
        }
    }, [searchedEntityCode, searchedBpCode, searchedBpName, searchedApprovedRecordsRecords, searchedStatusFlag, searchedMessageRcvMode]);

    useEffect(() => {
        if (msgHdrPopUp === true) {
            setOpenBackDrop(true);
            CommonService.saveOrUpdateOrPost('/reports/SYS_PO_HDR', searchedBpCode === '' || searchedEntityCode === '' ?
                { erpEntityCode: '', bpCode: '' } : keyValueData)
                .then((response) => {
                    showDownloadPopUpExcelHdr(response.data.fileUrl)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />, severity: "success"
                    });
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgHdrPopUp(false)
        }
    }, [msgHdrPopUp, keyValueData]);

    const showDownloadPopUpExcelHdr = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            })
    }


    useEffect(() => {
        if (msgDtlsPopUp === true) {
            setOpenBackDrop(true);
            let msgId = { msgId: msgIdValue.toString() };
            CommonService.saveOrUpdateOrPost('/reports/SYS_PO_DTL', msgId)
                .then((response) => {
                    showDownloadPopUpExcelDtls(response.data.fileUrl)
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />, severity: "success"
                    });
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgDtlsPopUp(false)
        }
    }, [msgIdValue, msgDtlsPopUp])

    const showDownloadPopUpExcelDtls = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.successexcelReport" defaultMessage="Generated the Excel Report Suceesfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            })
    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let entityCode = null;
        let invoiceNo = null;
        let approved = null;
        let msgStatusFlag = null;
        let messageReceivedMode = null;
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'invoiceNo':
                    invoiceNo = columns.value
                    break;
                case 'messageReceivedMode':
                    messageReceivedMode = columns.value
                    break;
                case 'entity.erpEntityCode':
                    entityCode = columns.value
                    break;
                case 'msgStatusFlag':
                    msgStatusFlag = columns.value
                    break;
                case 'approved':
                    approved = columns.value
                    break;
            }
        })

        setSearchedEntityCode(entityCode);
        setSearchedApprovedRecordsRecords(approved);
        setSearchedStatusFlag(msgStatusFlag);
        setSearchedMessageRcvMode(messageReceivedMode);

        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    entity: {
                        erpEntityCode: entityCode,
                    },
                    msgNum: msgNum,
                    invoiceNo: invoiceNo,
                    recordApprovedStatus: approved === '' ? 'All' : approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : 'All',
                    approved: approved === 'Not Approved' ? 0 : approved === 'Approved' ? 1 : approved === 'Rejected' ? 2 : '',
                    msgStatusFlag: msgStatusFlag === 'Not Sent' ? 0 : msgStatusFlag === 'Pending' ? 1 : msgStatusFlag === 'Successfully Sent' ? 2 : msgStatusFlag === 'Failed to Send' ? 3 : msgStatusFlag === 'Cancelled' ? 4 : '',
                    recordStatusFlag: msgStatusFlag === '' ? 'All' : msgStatusFlag,
                    messageReceivedMode: messageReceivedMode,

                }
            }
        })
    }

    useEffect(() => {
        setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CustomFilterComponent open={openFilterPanel} onClose={handleCloseForFilter} filterableColumns={filterableColumns}
                setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                defaulSelectedFilters={defaulSelectedFilters} />
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForRole}>
                <Paper elevation={3} className={classes.gridHeightAndWidthForASNDropDown}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            getRowId={(row) => row.id}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            density="compact"
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            isRowSelectable={(params) => params.row.approved === 0 ? true : false}
                            hideFooter={true}
                            //checkboxSelection={true}
                            onSelectionModelChange={currentlySelectedRow}
                            selectionModel={selectedRow}
                            localeText={localtext}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" />}
                                                        placement='bottom'><GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterList />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                    setSelectedRow([])
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>

                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>


                                        </Grid>
                                    );
                                },
                                FilterPanel: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                        >
                                            <Grid container
                                                item
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                xs={6} sm={6} md={6} lg={6}
                                            >
                                                <GridFilterPanel />
                                            </Grid>
                                            <Grid container
                                                item
                                                justifyContent="flex-end"
                                                alignItems="flex-end"
                                                xs={6} sm={6} md={6} lg={6}
                                                style={{ marginLeft: '-24%' }}
                                            >
                                                <Button color="primary" style={{ paddingBottom: '10px' }} onClick={(() => {
                                                    setParamBody(prevState => {
                                                        return {
                                                            ...prevState, body: {
                                                                entity: {
                                                                    erpEntityCode: searchedEntityCode
                                                                },
                                                                bpCode: searchedBpCode,
                                                                bpName: searchedBpName,
                                                                approved: searchedApprovedRecordsRecords,
                                                                recordApprovedStatus: searchedApprovedRecordsRecords === '' || searchedApprovedRecordsRecords === null ? 'All' : searchedApprovedRecordsRecords,
                                                                msgStatusFlag: searchedStatusFlag,
                                                                recordStatusFlag: searchedStatusFlag === '' || searchedStatusFlag === null ? 'All' : searchedStatusFlag,
                                                                messageReceivedMode: searchedMessageRcvMode,
                                                                messageReceivedFromDate: searchedMsgRcvFromDate !== null ? searchedMsgRcvFromDate.substr(1, 10) : null,
                                                                messageReceivedToDate: searchedMsgRcvToDate !== null ? searchedMsgRcvToDate.substr(1, 10) : null
                                                            },
                                                        }
                                                    });
                                                })}>
                                                    <FormattedMessage id="common.applyfilter.lable" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}

                            hideFooterSelectedRowCount={true}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                            onFilterModelChange={async (params) => {
                                let filterModelItem = params.items;
                                if (filterModelItem.length > 0) {
                                    let erpEntityCode = null;
                                    let bpCode = null;
                                    let bpName = null;
                                    let approved = null;
                                    let msgStatusFlag = null;
                                    let messageReceivedMode = null;
                                    let messageReceivedFromDate = null;
                                    let messageReceivedToDate = null;
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.erpEntityCode) {
                                            erpEntityCode = await filterModelItem[index].value === undefined ? '' : JSON.parse(filterModelItem[index].value).erpEntityCode;
                                            break;
                                        }
                                    }
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.bpCode) {
                                            bpCode = await filterModelItem[index].value === undefined ? '' : filterModelItem[index].value;
                                            break;
                                        }
                                    }
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.bpName) {
                                            bpName = await filterModelItem[index].value === undefined ? '' : filterModelItem[index].value;
                                            break;
                                        }
                                    }
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.approved) {
                                            approved = await filterModelItem[index].value === undefined ? '' : JSON.parse(filterModelItem[index].value).value;
                                            break;
                                        }
                                    }
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.msgStatusFlag) {
                                            msgStatusFlag = await filterModelItem[index].value === undefined ? '' : JSON.parse(filterModelItem[index].value).value;
                                            break;
                                        }
                                    }
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.messageReceivedMode) {
                                            messageReceivedMode = await filterModelItem[index].value === undefined ? '' : JSON.parse(filterModelItem[index].value).value;
                                            break;
                                        }
                                    }
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.messageReceivedFromDate) {
                                            messageReceivedFromDate = await ((filterModelItem[index].value !== undefined && filterModelItem[index].value !== null && filterModelItem[index].value !== 'null') ? filterModelItem[index].value : null);
                                            break;
                                        }
                                    }
                                    for (let index = 0; index < filterModelItem.length; index++) {
                                        if (filterModelItem[index].columnField === columnFieldsName.messageReceivedToDate) {
                                            messageReceivedToDate = await ((filterModelItem[index].value !== undefined && filterModelItem[index].value !== null && filterModelItem[index].value !== 'null') ? filterModelItem[index].value : null);
                                            break;
                                        }
                                    }
                                    setSearchedEntityCode(erpEntityCode);
                                    setSearchedBpCode(bpCode);
                                    setSearchedBpName(bpName);
                                    setSearchedApprovedRecordsRecords(approved);
                                    setSearchedStatusFlag(msgStatusFlag);
                                    setSearchedMessageRcvMode(messageReceivedMode);
                                    setSearchedMsgRcvFromDate(messageReceivedFromDate);
                                    setSearchedMsgRcvToDate(messageReceivedToDate);
                                }
                            }}
                        />

                    </div>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}

export default InvoiceInboundHdrDrpDn;

