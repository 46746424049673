import { CircularProgress, CssBaseline, FormControl, FormHelperText, Grid, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import { CommonService } from '../../../../services';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import useStyles from '../../CommonStyle/CommonStyle'
import { GlobalEdiApiConstants } from '../../../../Constants/GlobalEdiApiConstants';
import { FormattedMessage } from 'react-intl';

const ReportTemplateConditionCombo = (props) => {

    const classes = useStyles();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const { comboProperty, control, errors, comboValue, setComboValue, processValue } = props;
    const [ReportTemplateConditionList, setReportTemplateConditionList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const reportTemplateConditionData = ReportTemplateConditionList.map((reportCondition) => {
        return {
            columns: reportCondition.columns,
            process: reportCondition.proces,
            tmplId: reportCondition.tmplId,
        }
    });


    const [searchedReportTemplateCondition, setSearchedReportTemplateCondition] = useState(null);

    const key = "columns"

    const uniqueReportTemplateConditionData = [...new Map(reportTemplateConditionData.map(item => [item[key], item])).values()];

    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.reportTemplateCondition);

    useEffect(() => {
        if (searchedReportTemplateCondition !== null) {
            setParamBody({ ...paramBody, body: { columns: searchedReportTemplateCondition, process: processValue }, sortDir: 'asc', sortKey: 'columns', recordsPerPage: 100 });
        }
    }, [searchedReportTemplateCondition]);

    useEffect(() => {
        if (paramBody.sortDir !== 'desc' && paramBody.sortKey !== 'updatedTime') {
            reportTemplateConditionsData();
        }
    }, [paramBody]);

    const reportTemplateConditionsData = (searchedReportTemplateCondition) => {
        if (processValue !== '') {
            setLoading(true);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.reportTemplateCondition, paramBody)
                .then((response) => {
                    setLoading(false);
                    setReportTemplateConditionList(response.data.content);
                })
        }
    }

    return (
        <React.Fragment>
            <CssBaseline />
            {comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth>
                    <Controller
                        render={({
                            field: { onChange } }) => (
                            <Autocomplete

                                onChange={(event, newValue) => {
                                    onChange(newValue)

                                    if (typeof newValue === 'string') {

                                        if (newValue != null) {
                                            setComboValue({
                                                newValue,
                                            });
                                        }
                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the process input
                                        setComboValue({
                                            title: newValue.inputValue,
                                        });

                                    }
                                    else if (newValue !== null) {

                                        setComboValue(newValue);
                                    } else if (newValue === null) {
                                        setComboValue('')
                                    } else {
                                        setComboValue(newValue);
                                    }
                                }}
                                selectOnFocus
                                loadingText="Loading..."
                                loading={isLoading}
                                handleHomeEndKeys
                                value={comboValue.columns || null}
                                autoHighlight
                                onOpen={() => {
                                    setSearchedReportTemplateCondition('');
                                }}
                                options={uniqueReportTemplateConditionData}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.columns;
                                }}
                                getoptionselected={(option, value) => option.columns === value}
                                disabled={comboProperty.isDisabled}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        required={comboProperty.isRequired}
                                        autoFocus={comboProperty.isAutofocus}
                                        name={comboProperty.name}
                                        error={comboProperty.errors}
                                        size='small'
                                        label={comboProperty.label}
                                        variant={comboProperty.variant}
                                        InputLabelProps={{
                                            shrink: true,
                                            classes: {
                                                asterisk: classes.asterisk
                                            }
                                        }}
                                    />
                                )}

                            />
                        )}

                        name={comboProperty.name}
                        rules={{ required: comboProperty.isRequired }}
                        control={control}
                        defaultValue={{ processId: 1 }}
                    />
                    <div className={classes.error}>
                        {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                            <FormHelperText className={classes.error}>{comboProperty.label} <FormattedMessage id="textfield.error.isrequirededmessage" defaultMessage="is required" /></FormHelperText>
                        )}
                    </div>
                </FormControl>
            </Grid>)}

            {!comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "3px" }}>
                <FormControl fullWidth>
                    <Autocomplete
                        fullWidth
                        openOnFocus={true}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {

                                if (newValue != null) {
                                    setComboValue({
                                        newValue,
                                    });
                                }
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the process input
                                setComboValue({
                                    title: newValue.inputValue,
                                });

                            }
                            else if (newValue !== null) {

                                setComboValue(newValue);
                            } else if (newValue === null) {
                                setComboValue('')
                            } else {
                                setComboValue(newValue);
                            }
                        }}
                        selectOnFocus
                        loadingText="Loading..."
                        loading={isLoading}
                        handleHomeEndKeys
                        value={comboValue.columns || null}
                        autoHighlight
                        onOpen={() => {
                            setSearchedReportTemplateCondition('');
                        }}
                        options={uniqueReportTemplateConditionData}
                        getOptionLabel={(option) => {

                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.columns;
                        }}
                        getoptionselected={(option, value) => option.columns === value}
                        disabled={comboProperty.isDisabled}
                        renderInput={params => (
                            <Tooltip title={comboValue.columns || ''}>
                                <TextField
                                    {...params}
                                    autoFocus={comboProperty.isAutofocus}
                                    name={comboProperty.name}
                                    onChange={(ev) => {
                                        if (
                                            ev.target.value !== "" ||
                                            ev.target.value !== null
                                        ) {
                                            setSearchedReportTemplateCondition(ev.target.value);
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {isLoading ?
                                                    <CircularProgress color="inherit" size={20} />
                                                    : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    size='small'
                                    label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                    variant={comboProperty.variant}
                                />
                            </Tooltip>
                        )}

                    />
                </FormControl>
            </Grid>)}

        </React.Fragment>
    );
};

ReportTemplateConditionCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default ReportTemplateConditionCombo;

