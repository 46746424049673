import { useTheme } from '@emotion/react';
import { Button, Dialog, DialogActions, DialogContent, Grid, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useStyles from '../CommonStyle/CommonStyle';
import CustomFilterPanel from './CustomFilterPanel';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ResetIcon from '@mui/icons-material/RestartAlt';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CheckIcon from '@mui/icons-material/Check';

function CustomFilterComponent(props) {

    const classes = useStyles();
    const theme = useTheme();

    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { open, onClose, filterableColumns, setSelectedFilters, selectedFilters, applyFilter, defaulSelectedFilters } = props;

    const handleClose = (value) => {
        onClose(value);
        setDeletedFilterIndex('')
    }

    const [selectedPanelFilter, setSelectedPanelFilter] = useState('');
    const [resetFlag, setResetFlag] = useState(false);

    const initialFilterState = () => {
        return [{ selectedPanelFilter: selectedPanelFilter, emptySelectedFilter: false }]
    }

    const [filters, setFilters] = useState(() => initialFilterState());
    const [deletedFilterIndex, setDeletedFilterIndex] = useState('');

    var filteredSelectedColumn = selectedFilters.filter((col) => {
        return col !== deletedFilterIndex
    })

    const updateSelectedFilters = () => {
        let filters = filteredSelectedColumn;
        let newFilterPanel = [selectedPanelFilter];
        if (selectedPanelFilter !== '') {
            if (filteredSelectedColumn.length > 0) {
                let replaced = false;
                filteredSelectedColumn.forEach((existingFilter) => {
                    if (existingFilter.columnField === selectedPanelFilter.columnField || existingFilter.id === selectedPanelFilter.id) {
                        let index = filters.indexOf(existingFilter)
                        filters[index] = selectedPanelFilter;
                        replaced = true;
                    }
                })
                if (!replaced) {
                    filters.push(selectedPanelFilter);
                }
            } else {
                filters.push(selectedPanelFilter);
            }
        }
        return filters;
    }

    const deleteFilterPanel = () => {
        if (deletedFilterIndex !== '') {
            let filtersCol = filteredSelectedColumn;
            filtersCol.forEach((columns) => {
                if (columns.id > deletedFilterIndex.id) {
                    let index = filtersCol.indexOf(columns)
                    filtersCol[index].id = index;
                } else if (columns.id < deletedFilterIndex.id) {
                    filtersCol = filteredSelectedColumn
                }
            })
            return filtersCol
        }
    }

    useEffect(() => {
        if (!(selectedFilters.includes(deletedFilterIndex))) {
            setDeletedFilterIndex('');
        }
    }, [selectedFilters, deletedFilterIndex])

    useEffect(() => {
        if (deletedFilterIndex === '') {
            setSelectedFilters(() => updateSelectedFilters())
        } else {
            setSelectedFilters(() => deleteFilterPanel())
        }
    }, [selectedPanelFilter, deletedFilterIndex])

    const [filterStateSetTest, setFilterStateSetTest] = useState([]);

    const test = filters.filter((col) => { return col.selectedPanelFilter.columnField === selectedPanelFilter.columnField })

    useEffect(() => {
        const filtersEmptyStateFilter = filters.filter((item) => {
            return item.selectedPanelFilter === ''
        })
        setFilterStateSetTest(filtersEmptyStateFilter);
    }, [filters])

    useEffect(() => {
        if (selectedFilters.length > 0) {
            let newFilters = selectedFilters.map((filterColumn) => (
                { selectedPanelFilter: filterColumn, emptySelectedFilter: false }
            ))
            if (filterStateSetTest.length > 0) {
                if (newFilters.length !== filterStateSetTest.length) {
                    if (newFilters.length > 1 && test[0] !== undefined && test[0].selectedPanelFilter.columnField === selectedPanelFilter.columnField) {
                        setFilters(newFilters.concat(filterStateSetTest))
                    } else {
                        setFilters(newFilters.concat(filterStateSetTest).slice(0, -1))
                    }
                } else {
                    if (newFilters.length > 1 && test[0] !== undefined && test[0].selectedPanelFilter.columnField === selectedPanelFilter.columnField) {
                        setFilters(newFilters.concat(filterStateSetTest))
                    } else {
                        setFilters(newFilters.concat(filterStateSetTest).slice(0, -1))
                    }
                }
            } else {
                setFilters(newFilters)
            }
        } else {
            setFilters(() => initialFilterState())
        }
    }, [selectedFilters, selectedPanelFilter])

    const addFilterState = () => {
        setFilters(filters.concat([
            { selectedPanelFilter: '', emptySelectedFilter: true }
        ]));
        setDeletedFilterIndex('');
    }

    const handleClearFilters = () => {
        setFilters([]);
        setSelectedFilters([])
        setSelectedPanelFilter('')
        setDeletedFilterIndex('');
    }

    const handleApplyFilter = () => {
        applyFilter();
        if (selectedFilters.length > 0) {
            let column = selectedFilters.map((col) => (
                { selectedPanelFilter: col, emptySelectedFilter: false }
            ))
            setFilters(filters.length !== selectedFilters.length ? column : filters)
        }
        handleClose(false);
    }

    const handleResetFilter = () => {
        setSelectedFilters(() => defaulSelectedFilters);

        if (selectedFilters.length > 0) {
            let column = defaulSelectedFilters.map((col) => (
                { selectedPanelFilter: col, emptySelectedFilter: false }
            ))
            setFilters(column)
        }
        setResetFlag(true);
        setDeletedFilterIndex('');
    }

    useEffect(() => {
        if (deletedFilterIndex !== '') {
            setFilters(() => filters.filter(item => {
                return item.selectedPanelFilter !== deletedFilterIndex;
            }))
            setSelectedFilters(() => selectedFilters.filter(item => {
                return item !== deletedFilterIndex;
            }))
        }
    }, [deletedFilterIndex])

    return (
        <React.Fragment>
            <Dialog fullWidth open={open} maxWidth='md' >
                <DialogContent dividers style={{ minHeight: "100px" }}>
                    <Grid container justifyContent='center' alignItems='center'>
                        <React.Fragment>
                            {filters.map((columns, index) => (
                                <CustomFilterPanel filterableColumns={filterableColumns}
                                    selectedPanelFilter={columns.selectedPanelFilter} setSelectedPanelFilter={setSelectedPanelFilter}
                                    emptySelectedFilter={columns.emptySelectedFilter}
                                    itemId={index}
                                    defaultPanel={columns.selectedPanelFilter}
                                    resetFlag={resetFlag}
                                    setResetFlag={setResetFlag}
                                    setDeletedFilterIndex={setDeletedFilterIndex}
                                    key={columns.selectedPanelFilter.columnField}
                                />
                            ))}
                        </React.Fragment>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActionForFileUploading}>
                    <Grid container justifyContent='flex-start' alignItems='center' item xs={12} sm={12} md={12} lg={12} style={{ marginLeft: '-4%' }}>
                        <Button size='small' variant='text' color='primary' className={classes.buttonSpacing} style={{ fontSize: '14px' }}
                            onClick={() => {
                                addFilterState()
                            }
                            }
                        >{mobileScreen ? <AddIcon /> : <FormattedMessage id="common.filterpaneladdfilter.toolbar.lable" />}
                        </Button>
                    </Grid>
                    <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} style={{ marginRight: '-3%' }}>
                        <Button size='small' variant='text' color='primary' className={classes.buttonSpacing} style={{ fontSize: '14px' }}
                            onClick={(() => {
                                handleClose(false);
                            })}>{mobileScreen ? <CloseIcon /> : <FormattedMessage id="common.button.close.tooltip" />}
                        </Button>
                        <Button size='small' variant='text' color='primary' className={classes.buttonSpacing} style={{ fontSize: '14px' }}
                            onClick={(() => handleResetFilter())}>{mobileScreen ? <ResetIcon /> : <FormattedMessage id='common.button.reset.lable' />}
                        </Button>
                        <Button size='small' variant='text' color='primary' className={classes.buttonSpacing} style={{ fontSize: '14px' }}
                            onClick={(() => handleClearFilters())}>{mobileScreen ? <ClearAllIcon /> : <FormattedMessage id="common.button.clearall.tooltip" />}
                        </Button>
                        <Button size='small' variant='text' color='primary' className={classes.buttonSpacing} style={{ fontSize: '14px' }}
                            onClick={(() => handleApplyFilter())}
                        >
                            {mobileScreen ? <CheckIcon /> : <FormattedMessage id="common.applyfilter.lable" />}
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    )
}

export default CustomFilterComponent;
