import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,} from '@mui/material';
import React from 'react';
import IconForYes from '@mui/icons-material/CheckCircleOutlined';
import IconForCancel from '@mui/icons-material/CancelOutlined';
import useStyles from '../CommonStyle/CommonStyle';
import { FormattedMessage } from 'react-intl';

const ConfirmationDialog = (props) => {
    const classes = useStyles();
    const { onClose, message, open } = props;

    const handleClose = (value) => {
        onClose(value);
    }

    return (
        <Dialog
            fullWidth
            aria-labelledby="create-or-delete-dialog"
            open={open}>
            <DialogTitle color='primary' className={classes.title}>
                <strong>{<FormattedMessage id="dialogtitle.confirmationdialog" defaultMessage="Confirmation" />}</strong>
            </DialogTitle>
            <DialogContent dividers>               
                <div>{message}</div>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                    <Button size='small' variant='contained' color='secondary' className={classes.buttonSpacingForDialog}
                        startIcon={<IconForCancel />} onClick={(() => {
                            handleClose(false);
                        })}>{<FormattedMessage id="common.button.no.lable" defaultMessage="No" />}</Button>
                    <Button size='small' variant='contained' color='primary' className={classes.buttonSpacingForDialog}
                        startIcon={<IconForYes />} onClick={(() => {
                            handleClose(true);
                        })}>{<FormattedMessage id="common.button.yes.lable" defaultMessage="Yes" />}</Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
export default ConfirmationDialog;