import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';

const Copyright = (props) => {
    const version = `${process.env.REACT_APP_VERSION}`;
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {<FormattedMessage id="copyright.txt" defaultMessage="error" values={{ year: `${new Date().getFullYear()} `, version: ` |  ${version} | ` }} />}
            <Link color='primary' to={{ pathname: props.companyURL }} target="_blank">
                {props.companyName}
            </Link>
        </Typography>
    );
}

Copyright.propTypes = {
    companyName: PropTypes.string.isRequired,
    companyURL: PropTypes.string.isRequired,
};

export default Copyright;