import {
    Button, Chip, CircularProgress, CssBaseline, Dialog, DialogActions, DialogContent,
    DialogTitle, FormControl, FormHelperText, Grid, IconButton, Paper, Snackbar, Switch, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useMediaQuery, useTheme
} from '@mui/material';
import { Alert, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import CreateIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CommonService } from '../../../services';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import useStyles from '../../common/CommonStyle/CommonStyle'
import { FormattedMessage } from 'react-intl';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const POConfirmDateDialog = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const { onClose, open, poItemDetails, setPOIteDetailsObject, poId, msgDtlsId, poDtlObject, modeForSchedule, poNumber, itemCode, poLineNumber, itemDesc, statusFlagValue } = props;
    const currentUser = CommonService.getLoggedInUserInfo();

    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const [isResponseArrived, setResponseArrived] = useState(false);
    const [disableSubmitButton, setDisabledSubmitButton] = useState(false);
    const [msgSchId, setMsgSchId] = useState('');
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const pageLabelsNamesAndConst = {
        labels: {
            dialogTitle: 'PO Delivery Confirmation Qty & Date',
            submit: 'Submit',
            cancel: 'Cancel',
            add: 'Add',
            deleteButton: 'Delete',
        },
        name: {
            code: 'code'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    };
    const [totalConfirmedQty, setTotalConfirmedQty] = useState(0);
    const [isTableStateChanged, setTableStateChanged] = useState(false);
    const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [isTotalOutStandingQtyIsLessThanToralConfirmedQty, setIsTotalOutStandingQtyIsLessThanToralConfirmedQty] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);
    const [getCfmDate, setCfmdate] = useState('');
    const [textFieldValueChange, setTextFieldValueChange] = useState('');
    const [butDisable, setButtonDisable] = useState(true);
    const [confirmQtyWhenDlvCfmIsOne, setConfirmQtyWhenDlvCfmIsOne] = useState('');
    const [uploadedUserTypeUpdate, setUploadedUserTypeUpdate] = useState(currentUser.userType === 'SIIX-USER' ? false : true);
    const [sumbitDialogOpen, setSubmitDialogOpen] = useState(false);

    const handleClose = (value) => {
        setPOIteDetailsObject();
        setRows([]);
        onClose(value);
        setSubmitButtonDisabled(false);
        setIsTotalOutStandingQtyIsLessThanToralConfirmedQty(false);
        setCfmdate('');
        setTextFieldValueChange('');
        setDisabledSubmitButton(false);
        setConfirmQtyWhenDlvCfmIsOne('')
        setUploadedUserTypeUpdate(currentUser.userType === 'SIIX-USER' ? false : true);
    };

    function checkWhetherAnyReqFieldsAreEmpty() {
        let isAnyRequiredFieldEmpty = false;
        for (let index = 0; index < rows.length; index++) {
            if (rows[index].confirmQty === 0 || isNaN(rows[index].confirmQty) || rows[index].confirmQty === 0 || rows[index].confirmDt === null ||
                (new Date(rows[index].confirmDt.split('T')[0]).getTime() < new Date(new Date().toISOString().split('T')[0]).getTime() &&
                    new Date(rows[index].confirmDt.split('T')[0]).getTime() !== new Date(new Date().toISOString().split('T')[0]).getTime())) {
                isAnyRequiredFieldEmpty = true;
                break;
            } else if (rows[index].confirmQty > rows[index].totalOutstandingOrderQty) {
                isAnyRequiredFieldEmpty = true;
                break;
            }
        }
        if (isAnyRequiredFieldEmpty) {
            setSubmitButtonDisabled(true);
        } else {
            setSubmitButtonDisabled(false);
        }
    }

    function createData(msgDlvyCfmId, deliveryScheduleLineNo, totalOutstandingOrderQty, scheduleReqDt, confirmQty, confirmDt, lastQty, lastConfirmDtDisp, 
        updatedBy, poOutboundMsgScc, msgSchId, remarks, status, statusRemarks, createdBy, createdTime, updatedTime, interfaceStatus, source, uploadedUserType, 
        acceptPriorDelivery, bpCode, statsticalScheduleReqDt, entityCode, earlierAcceptanceDays, lateAcceptanceDays, dlvyCfmDummyDate) {
        return { msgDlvyCfmId, deliveryScheduleLineNo, totalOutstandingOrderQty, scheduleReqDt, confirmQty, confirmDt, lastQty, lastConfirmDtDisp, 
            updatedBy, poOutboundMsgScc, msgSchId, remarks, status, statusRemarks, createdBy, createdTime, updatedTime, interfaceStatus, source, uploadedUserType, 
            acceptPriorDelivery, bpCode, statsticalScheduleReqDt, entityCode, earlierAcceptanceDays, lateAcceptanceDays, dlvyCfmDummyDate };
    }

    const [rows, setRows] = useState(() => {
        return [];
    });

    useEffect(() => {
        let confirmedItemQty = 0;
        let totalOutstandingOrderQty = 0;
        for (let index = 0; index < rows.length; index++) {
            confirmedItemQty = confirmedItemQty + rows[index].confirmQty;
            totalOutstandingOrderQty = rows[index].totalOutstandingOrderQty
        }
        if (confirmedItemQty === 0) {
            setTotalConfirmedQty(totalOutstandingOrderQty);
        } else {
            setTotalConfirmedQty(confirmedItemQty);
        }
    }, [isTableStateChanged]);

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [primaryKeyOfSelectedRow, setPrimaryKeyOfSelectedRow] = useState(0);
    const [indexOfSelectedRow, setIndexOfSelectedRow] = useState(0);

    useEffect(() => {
        if (open) {
            setTableLoadingStatus(true);
            CommonService.GetListApi(`/poOutboundDtls/${msgDtlsId}/poOutboundDlvyCfms`,
                {
                    body: {
                    },
                    sortDir: 'asc',
                    sortKey: 'confirmDt',
                })
                .then((response) => {
                    setTableLoadingStatus(false);
                    let tempArray = [];
                    response.data.forEach(element => {
                        tempArray.push(createData(
                            element.msgDlvyCfmId,
                            element.poOutboundMsgScc.scheduleLineNo,
                            element.poOutboundMsgScc.totalOutstandingOrderQty,
                            element.poOutboundMsgScc.scheduleReqDt,
                            element.confirmQty === 0 ? element.poOutboundMsgScc.totalOutstandingOrderQty : element.confirmQty,
                            element.confirmDt === null ? element.poOutboundMsgScc.scheduleReqDt : element.confirmDt,
                            element.lastConfirmQty,
                            element.lastConfirmDtDisp,
                            null,
                            { msgSchId: element.poOutboundMsgScc.msgSchId },
                            element.poOutboundMsgScc.msgSchId,
                            element.remarks,
                            element.poOutboundMsgScc.poOutboundMsgDtls.statusFlag,
                            element.statusRemarks,
                            element.createdBy,
                            element.createdTime,
                            null,
                            element.interfaceStatus === null || element.interfaceStatus === '' ? 0 : 0,
                            1,
                            uploadedUserTypeUpdate === false ? 0 : currentUser.userType === 'SIIX-USER' ? 1 : 2,
                            element.acceptPriorDelivery,
                            element.bpCode,
                            element.poOutboundMsgScc.statsticalScheduleReqDt,
                            element.entityCode,
                            element.earlierAcceptanceDays,
                            element.lateAcceptanceDays,
                            element.dlvyCfmDummyDate
                        ));
                        setConfirmQtyWhenDlvCfmIsOne(element.confirmQty === 0 && poDtlObject.poLineDeliveryConfirmed === 0 ? element.poOutboundMsgScc.totalOutstandingOrderQty : element.confirmQty)
                    });
                    setRows(tempArray);
                    setTableStateChanged(!isTableStateChanged);
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [poItemDetails, refreshTable, uploadedUserTypeUpdate]);

    useEffect(() => {
        checkWhetherAnyReqFieldsAreEmpty();
    }, [isTableStateChanged]);

    function handleChangeDateForScheduleReqDt(date, index) {
        rows[index].scheduleReqDt = date;
    }

    function handleChangeDateForStatisticalScheduleReqDt(date, index) {
        rows[index].statsticalScheduleReqDt = date;
    }

    function handleChangeDate(date, index) {
        rows[index].confirmDt = date;
        setCfmdate(date);
    }

    function getRowScheduleReqDate(index) {
        return rows[index] !== undefined && rows[index].scheduleReqDt;
    }

    function getRowStatisticalScheduleReqDate(index) {
        return rows[index] !== undefined && rows[index].statsticalScheduleReqDt;
    }

    function getRowConfirmDate(index) {
        return rows[index] !== undefined && rows[index].confirmDt;
    }

    function getRemainingQtyToBeConfirmed() {
        let confirmedItemQty = 0;
        for (let index = 0; index < rows.length; index++) {
            confirmedItemQty = confirmedItemQty + rows[index].confirmQty;
        }
        return poItemDetails !== undefined ? (poItemDetails.totalOutstandingOrderQty - confirmedItemQty) : 0;
    }

    function handleTextFieldChange(value, index, columnName) {
        rows[index].[columnName] = value;
        setTextFieldValueChange(value);
    }

    function getParticularColumnData(index, columnName) {
        return rows[index].[columnName];
    }

    function isRemainingQtyMoreThanConfirmingQty(index) {
        let remainingQty = getRemainingQtyToBeConfirmed();
        let isRemainingQtyMoreThnConfirmingQty = (remainingQty) < 0;
        if (isRemainingQtyMoreThnConfirmingQty) {
            setIsTotalOutStandingQtyIsLessThanToralConfirmedQty(true);
        } else {
            setIsTotalOutStandingQtyIsLessThanToralConfirmedQty(false);
        }
        return isRemainingQtyMoreThnConfirmingQty;
    }
    const hasSameCfmDates = (rows) => {
        let duplicateCount = false;
        rows.forEach((item, index) => {
            let availableRows = rows.filter((line, idx) => idx !== index || line.msgDlvyCfmId !== item.msgDlvyCfmId)
            availableRows.forEach((row) => {

                let confirmDts = row.confirmDt.split('T')[0];
                let itemConfirmDts = item.confirmDt.split('T')[0];
                if (!duplicateCount && (row.deliveryScheduleLineNo === item.deliveryScheduleLineNo && confirmDts === itemConfirmDts)) {
                    duplicateCount = true
                }
            })
        })
        return duplicateCount
    }

    function minDateValue(index) {
        const currentDate = new Date()
        const scheduleReqDt = new Date(getRowScheduleReqDate(index));
        const statisticalScheduleReqDt = new Date(getRowStatisticalScheduleReqDate(index));
        const earlierAcceptanceDays = rows.map((item) => item.earlierAcceptanceDays).filter((value, index, self) => self.indexOf(value) === index).toString();
        const calculateValidDate = scheduleReqDt.toISOString().slice(0, 10) === statisticalScheduleReqDt.toISOString().slice(0, 10) ?
            new Date(statisticalScheduleReqDt.getFullYear(), statisticalScheduleReqDt.getMonth(), 0 + 1) : new Date(scheduleReqDt.getFullYear(), scheduleReqDt.getMonth(), 0 + 1);

        const validDateForEarlierAccDays = new Date(new Date(scheduleReqDt).setDate(scheduleReqDt.getDate() - earlierAcceptanceDays));

        if (uploadedUserTypeUpdate) {
            if (rows.map((item) => item.acceptPriorDelivery).includes(true)) {
                return currentDate
            } else {
                if (calculateValidDate.toISOString().slice(0, 10) < currentDate.toISOString().slice(0, 10)) {
                    return currentDate;
                } else {
                    if (earlierAcceptanceDays > 0) {
                        if (calculateValidDate.toISOString().slice(0, 10) > validDateForEarlierAccDays.toISOString().slice(0, 10)) {
                            return calculateValidDate;
                        } else {
                            return validDateForEarlierAccDays
                        }
                    } else {
                        return calculateValidDate;
                    }
                }
            }
        } else {
            return currentDate
        }
    }

    function maxDateValue(index) {
        const currentDate = new Date();
        const scheduleReqDt = new Date(getRowScheduleReqDate(index));
        const lateAcceptanceDays = rows.map((item) => item.lateAcceptanceDays).filter((value, index, self) => self.indexOf(value) === index).toString();
        const dlvCfmDummyDate = rows.map((item) => item.dlvyCfmDummyDate).filter((value, index, self) => self.indexOf(value) === index).toString();
        const dlvCfmDummydateValue = dlvCfmDummyDate.includes(",") ? dlvCfmDummyDate.split(",")[0] : dlvCfmDummyDate;

        const validDateForLateAccDays = new Date(new Date(scheduleReqDt).setDate(scheduleReqDt.getDate() + parseInt(lateAcceptanceDays)));
        const defaultMaxDate = new Date(new Date(dlvCfmDummydateValue).setDate(new Date(dlvCfmDummydateValue).getDate() - 1));

        if (uploadedUserTypeUpdate) {
            if (scheduleReqDt.toISOString().slice(0, 10) < currentDate.toISOString().slice(0, 10)) {
                return defaultMaxDate
            } else {
                if (lateAcceptanceDays > 0) {
                    return validDateForLateAccDays
                } else {
                    return defaultMaxDate;
                }
            }
        } else {
            return defaultMaxDate
        }
    }

    const dateValidation = (rows) => {
        let validdate = true;
        rows.forEach((item, index) => {
            let itemConfirmDts = item.confirmDt.split('T')[0];
            if (minDateValue(index) !== null) {
                if (itemConfirmDts < minDateValue(index).toISOString().slice(0, 10)) {
                    validdate = false
                }
            }
        })
        return validdate
    }

    const lateDateValidation = (rows) => {
        let validdate = true;
        rows.forEach((item, index) => {
            let itemConfirmDts = item.confirmDt.split('T')[0];
            if (maxDateValue(index) !== null) {
                if (itemConfirmDts > maxDateValue(index).toISOString().slice(0, 10)) {
                    validdate = false
                }
            }
        })
        return validdate
    }


    const sameReQuestedAndConfirmDate = (rows) => {
        let validdate = false;

        rows.forEach((item, index) => {
            let itemConfirmDts = item.confirmDt.split('T')[0];
            const scheduleReqDt = new Date(getRowScheduleReqDate(index));
            const statisticalScheduleReqDt = new Date(getRowStatisticalScheduleReqDate(index));

            if ((getRowStatisticalScheduleReqDate(index) !== getRowScheduleReqDate(index))) {
                if (itemConfirmDts === scheduleReqDt.toISOString().slice(0, 10) && getParticularColumnData(index, 'totalOutstandingOrderQty') === getParticularColumnData(index, 'confirmQty')) {
                    validdate = true;
                } else {
                    validdate = false;
                }
            } else if (getRowStatisticalScheduleReqDate(index) === getRowScheduleReqDate(index)) {
                if (itemConfirmDts === statisticalScheduleReqDt.toISOString().slice(0, 10) && getParticularColumnData(index, 'totalOutstandingOrderQty') === getParticularColumnData(index, 'confirmQty')) {
                    validdate = true;
                } else {
                    validdate = false;
                }
            }
            // validdate = getParticularColumnData(index, 'totalOutstandingOrderQty') === getParticularColumnData(index, 'confirmQty') ? true : false;
        })
        return validdate
    }

    const onSubmit = () => {
        // setResponseArrived(true);
        if (isSubmitButtonDisabled) {
            setAlertBoxState({
                ...alertBoxState, openAlert: true, message: <FormattedMessage id="warning.state.text" defaultMessage={`Either one or more required field are empty Or equals to 0`} />, severity: "warning"
            });
            setResponseArrived(false);
        } else {
            if (isTotalOutStandingQtyIsLessThanToralConfirmedQty) {
                setAlertBoxState({
                    ...alertBoxState, openAlert: true, message: <FormattedMessage id="totalConfirmation.warning.state.text" defaultMessage={`Total confirm quantities are more than ordered qty`} />, severity: "warning"
                });
                setResponseArrived(false);
            } else {
                if (hasSameCfmDates(rows)) {
                    setAlertBoxState({
                        ...alertBoxState, openAlert: true, message: "Same scheduleline cannot have same confirmation date", severity: "warning"
                    });
                    setDisabledSubmitButton(false);
                    setSubmitDialogOpen(false);
                    // setResponseArrived(false);
                } else {
                    if (!dateValidation(rows)) {
                        setAlertBoxState({
                            ...alertBoxState, openAlert: true, message: "Kindly select Confirm Date in the SIIX Dlvy Requested month", severity: "warning"
                        });
                        setDisabledSubmitButton(false);
                        setSubmitDialogOpen(false);
                    } else if (!lateDateValidation(rows)) {
                        setAlertBoxState({
                            ...alertBoxState, openAlert: true, message: "Kindly select Confirm Date within the late acceptance days", severity: "warning"
                        });
                        setDisabledSubmitButton(false);
                        setSubmitDialogOpen(false);
                    } else {
                        CommonService.saveOrUpdateOrPost(`/confirmPoLineDlvConfirmed`, {
                            msgdtlsId: msgDtlsId,
                            poLineDeliveryConfirmed: uploadedUserTypeUpdate === false ? sameReQuestedAndConfirmDate(rows) ? poDtlObject.poLineDeliveryConfirmed : 0 : 1
                        })
                            .then((response) => {
                                if (response.data === '') {
                                    CommonService.saveOrUpdateOrPost('/poOutboundDlvyCfms', rows)
                                        .then((response) => {
                                            setResponseArrived(false);
                                            setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfully" defaultMessage={'Record updated successfully'} />, severity: "success" });
                                            handleClose(true);
                                            setUploadedUserTypeUpdate(currentUser.userType === 'SIIX-USER' ? false : true);
                                        })
                                        .catch(function (error) {
                                            setResponseArrived(false);
                                            setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                                        });
                                }
                            })
                    }
                }
            }
        }
    }

    const closeConfirmationDialog = (confirmed) => {
        setOpenConfirmationDialog(false);
        if (confirmed) {
            if (primaryKeyOfSelectedRow > 0) {
                CommonService.deleteByIdApi(`/poOutboundDlvyCfms/${primaryKeyOfSelectedRow}`, {})
                    .then((response) => {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recorddeletedsuccessfully" defaultMessage="Record deleted successfully" />, severity: "success" });
                        setRefreshTable(!refreshTable);
                    })
                    .catch(function (error) {
                        let deleteMessage = (error.response.data !== undefined || error.response.data !== null) ? error.response.data.message
                            : "Error occurred while deleting!";
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: deleteMessage, severity: "error" });
                    });
            }
            let tempArray = rows;
            rows.splice(indexOfSelectedRow, 1);
            setRows(tempArray);
            isRemainingQtyMoreThanConfirmingQty();
            setTableStateChanged(!isTableStateChanged)
        }
    }

    const handleDelete = (primaryKey, msgScheduleId, nameToBeDisplacedOnConfirmationDialog, index) => {
        setIndexOfSelectedRow(index);
        setMsgSchId(msgScheduleId);
        setPrimaryKeyOfSelectedRow(primaryKey);
        setConfirmationMessage(<FormattedMessage id="deleterecord.cfmQnty.text" values={{ nameToBeDisplacedOnConfirmationDialog: `${nameToBeDisplacedOnConfirmationDialog}` }} defaultMessage={`Are you sure, You want to delete Record with 'P Cfm Qty. = ${nameToBeDisplacedOnConfirmationDialog}'?`} />);
        setOpenConfirmationDialog(true);
    }

    function disableAddButtonBasedOnCfmQty(index) {
        const confirmQtyForSelectedSch = rows.filter(line => line.deliveryScheduleLineNo === rows[index].deliveryScheduleLineNo)
        let confirmedItemQty = 0;
        for (let index = 0; index < confirmQtyForSelectedSch.length; index++) {
            confirmedItemQty = confirmedItemQty + confirmQtyForSelectedSch[index].confirmQty;
        }
        return confirmedItemQty;
    }

    useEffect(() => {
        if (getCfmDate === '') {
            setButtonDisable(true)
        } else {
            setButtonDisable(false)
        }
    }, [rows, getCfmDate])

    useEffect(() => {
        if ((textFieldValueChange === '')) {
            setButtonDisable(true)
        } else {
            setButtonDisable(false)
        }
    }, [rows, textFieldValueChange])

    const poQty = poDtlObject !== undefined ? poDtlObject.totalOutstandingOrderQty : '';

    const closeConfirmationDialogForSubmit = (confirmed) => {
        if (confirmed) {
            onSubmit();
        }
        setOpenConfirmationDialog(false);
        setSubmitDialogOpen(false);
        setDisabledSubmitButton(false);
    }

    const handleOpenUploadedUserTypeConfirmation = () => {
        setConfirmationMessage(uploadedUserTypeUpdate === false ? <FormattedMessage id="common.diolog.siixuserswitchpopupforimport.text" /> : <FormattedMessage id="common.diolog.supplierbehalfswitchpopupforimport.text" />);
        setOpenConfirmationDialog(true);
    }

    const earlierAcceptanceDaysCondtnCheck = uploadedUserTypeUpdate ? rows[0] !== undefined ? !rows[0].acceptPriorDelivery ? rows[0].earlierAcceptanceDays > 0 ? rows[0].earlierAcceptanceDays : 0 : 0 : 0 : 0;

    const lateAcceptanceDaysCondtnCheck = uploadedUserTypeUpdate ? rows[0] !== undefined ? !rows[0].acceptPriorDelivery ? rows[0].lateAcceptanceDays > 0 ? rows[0].lateAcceptanceDays : 0 : 0 : 0 : 0;

    const lateAcceptanceDaysWhenCrossMonthEnabled = uploadedUserTypeUpdate ? rows[0] !== undefined ? rows[0].acceptPriorDelivery ? rows[0].lateAcceptanceDays > 0 ? rows[0].lateAcceptanceDays : 0 : 0 : 0 : 0;


    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <ConfirmationDialog onClose={sumbitDialogOpen ? closeConfirmationDialogForSubmit : closeConfirmationDialog} message={confirmationMessage} open={openConfirmationDialog} />
            <Dialog fullWidth aria-labelledby="create-or-delete-dialog" open={open} disableBackdropClick={true} maxWidth='xl'>
                <DialogTitle color='primary' className={classes.title}>
                    <Grid container>
                        <Grid container justifyContent='flex-start' alignItems='center' item xs={12} sm={12} md={4} lg={4}>
                            <strong><FormattedMessage id="dialogtitle.podlvcfmqty" /></strong>
                        </Grid>
                        <Grid item container justifyContent='flex-end' alignItems='flex-end' xs={12} sm={12} md={8} lg={8}>
                            {poNumber} | {poLineNumber} | {itemCode} | {itemDesc}
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={mobileScreen ? { minHeight: '70vh' } : { minHeight: '60vh' }}>
                    <Grid container justifyContent='center' alignItems='center' component='main' className={classes.rootForPoConf}>
                        <Grid item justifyContent='flex-start' xs={12} sm={6} md={6} lg={6}>
                            <Chip size='small' variant='outlined' className={classes.chipThemeForPoCfmDailog} color='primary'
                                label={<FormattedMessage id="poqnty.dialog" values={{ poItemDetails: `${poDtlObject !== undefined ? poDtlObject.totalOutstandingOrderQty : 0}` }} />}
                            />
                            <Chip size='small' variant='outlined' className={classes.chipThemeForPoCfmDailog} color='primary'
                                label={<FormattedMessage id="totalcfmqnty.dialog" values={{ totalConfirmedQty: `${poDtlObject !== undefined && poDtlObject.poLineDeliveryConfirmed === 0 ? 0 : totalConfirmedQty !== undefined ? totalConfirmedQty : 0}` }} />}
                            />
                        </Grid>
                        {currentUser.userType === 'SIIX-USER' && (<Grid item container justifyContent='flex-end' alignItems='center' xs={12} sm={6} md={6} lg={6} >
                            <Typography><strong>SIIX User</strong></Typography>
                            <Switch color='success' size="medium"
                                checked={uploadedUserTypeUpdate}
                                onChange={(event) => {
                                    setUploadedUserTypeUpdate(event.target.checked)
                                }} />
                            <Typography><strong>Behalf of Supplier</strong></Typography>
                        </Grid>)}
                        {currentUser.userType !== 'SIIX-USER' && (<Grid item container justifyContent='flex-end' alignItems='center' xs={12} sm={6} md={6} lg={6} >
                        </Grid>)}

                        {(rows[0] !== undefined && rows[0].acceptPriorDelivery && uploadedUserTypeUpdate && (lateAcceptanceDaysWhenCrossMonthEnabled) > 0) && (
                            <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={12} >
                                <Typography color="red">
                                    <FormattedMessage id='notedlvcfmDate.note' />
                                    <br />
                                    <FormattedMessage id="lateacceptancewhencrossmonthallowenabled.note" values={{
                                        earlierAcceptancedays: `${earlierAcceptanceDaysCondtnCheck}`,
                                        lateAcceptancedays: `${lateAcceptanceDaysWhenCrossMonthEnabled}`
                                    }} /></Typography>
                            </Grid>
                        )}
                        {(rows[0] !== undefined && rows[0].acceptPriorDelivery && uploadedUserTypeUpdate && (lateAcceptanceDaysWhenCrossMonthEnabled) === 0) && (
                            <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={12} >
                                <Typography color="red">
                                    <FormattedMessage id='notedlvcfmDate.note' />
                                    <br />
                                    <FormattedMessage id="whencrossmonthallowed.note" values={{
                                        earlierAcceptancedays: `${earlierAcceptanceDaysCondtnCheck}`,
                                        lateAcceptancedays: `${lateAcceptanceDaysWhenCrossMonthEnabled}`
                                    }} /></Typography>
                            </Grid>
                        )}
                        {rows[0] !== undefined && !rows[0].acceptPriorDelivery && uploadedUserTypeUpdate && (earlierAcceptanceDaysCondtnCheck > 0 && lateAcceptanceDaysCondtnCheck > 0) && (
                            <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={12} >
                                <Typography color="red">
                                    <FormattedMessage id='notedlvcfmDate.note' />
                                    <br />
                                    <FormattedMessage id="ealierlateaccetancedaysnotzero.note" values={{
                                        earlierAcceptancedays: `${earlierAcceptanceDaysCondtnCheck}`,
                                        lateAcceptancedays: `${lateAcceptanceDaysCondtnCheck}`
                                    }} /></Typography>
                            </Grid>
                        )}
                        {rows[0] !== undefined && !rows[0].acceptPriorDelivery && uploadedUserTypeUpdate && (earlierAcceptanceDaysCondtnCheck === 0 && lateAcceptanceDaysCondtnCheck > 0) && (
                            <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={12} >
                                <Typography color="red">
                                    <FormattedMessage id='notedlvcfmDate.note' />
                                    <br />
                                    <FormattedMessage id="ealieraccetancedayszero.note" values={{
                                        earlierAcceptancedays: `${earlierAcceptanceDaysCondtnCheck}`,
                                        lateAcceptancedays: `${lateAcceptanceDaysCondtnCheck}`
                                    }} /></Typography>
                            </Grid>
                        )}
                        {rows[0] !== undefined && !rows[0].acceptPriorDelivery && uploadedUserTypeUpdate && (earlierAcceptanceDaysCondtnCheck > 0 && lateAcceptanceDaysCondtnCheck === 0) && (
                            <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={12} >
                                <Typography color="red">
                                    <FormattedMessage id='notedlvcfmDate.note' />
                                    <br />
                                    <FormattedMessage id="lateaccetancedayszero.note" values={{
                                        earlierAcceptancedays: `${earlierAcceptanceDaysCondtnCheck}`,
                                        lateAcceptancedays: `${lateAcceptanceDaysCondtnCheck}`
                                    }} /></Typography>
                            </Grid>
                        )}
                        {rows[0] !== undefined && !rows[0].acceptPriorDelivery && uploadedUserTypeUpdate && (earlierAcceptanceDaysCondtnCheck === 0 && lateAcceptanceDaysCondtnCheck === 0) && (
                            <Grid item container justifyContent='flex-start' xs={12} sm={12} md={12} lg={12} >
                                <Typography color="red">
                                    <FormattedMessage id='notedlvcfmDate.note' />
                                    <br />
                                    <FormattedMessage id="whencrossmonthnotallowed.note" /></Typography>
                            </Grid>
                        )}
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        {modeForSchedule === 'edit' && (<TableCell className={classes.tableHeaderPaddingAction} align="center">
                                            <FormattedMessage id="columnname.action.label" />
                                        </TableCell>)}
                                        <TableCell className={classes.tableHeaderPaddingAction} align="center"><FormattedMessage id="columnname.deliveryScheduleLineNo.label" /></TableCell>
                                        <TableCell className={classes.tableHeaderPadding} align="center"><FormattedMessage id="columnName.scheduledReqQty.label" /></TableCell>
                                        <TableCell className={classes.tableHeaderPadding} align="center"><FormattedMessage id="columnName.originalscheduledReqDt.label" /></TableCell>
                                        <TableCell className={classes.tableHeaderPadding} align="center"><FormattedMessage id="columnName.revisedscheduledReqDt.label" /></TableCell>
                                        <TableCell className={classes.tableHeaderPadding} align="center"><FormattedMessage id="cfmqty.tablecell" /></TableCell>
                                        <TableCell className={classes.tableHeaderPadding} align="center"><FormattedMessage id="cfmdate.tablecell" /></TableCell>
                                        <TableCell className={classes.tableHeaderPaddingAction} align="center"><FormattedMessage id="lastqty.tablecell" /></TableCell>
                                        <TableCell className={classes.tableHeaderPadding} align="center"><FormattedMessage id="lastcfmDt.tablecell" /></TableCell>
                                        <TableCell className={classes.tableHeaderPadding} align="center"><FormattedMessage id="columnname.remarks.label" /></TableCell>
                                        <TableCell className={classes.tableHeaderPadding} align="center"><FormattedMessage id="modifiedby.tablecell" /></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        < TableRow key={index} >
                                            {modeForSchedule === 'edit' && (<TableCell align="center" width="7%" style={{ padding: '5px 5px' }}>
                                                <Tooltip title={<FormattedMessage id="common.button.delete.tooltip" defaultMessage={pageLabelsNamesAndConst.labels.deleteButton} />}
                                                    placement='bottom' className={classes.actionButton} onClick={(() => {
                                                        handleDelete(row.msgDlvyCfmId, row.msgSchId, row.confirmQty, index);
                                                    })}>
                                                    <span>
                                                        <IconButton color='secondary'
                                                            disabled={rows.filter(line => line.deliveryScheduleLineNo === rows[index].deliveryScheduleLineNo).length === 1}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title={<FormattedMessage id="common.add.button.tooltip" defaultMessage={pageLabelsNamesAndConst.labels.add} />} placemen="bottom" className={classes.actionButton}>
                                                    <span>
                                                        <IconButton color='secondary'
                                                            disabled={disableAddButtonBasedOnCfmQty(index) >= row.totalOutstandingOrderQty}
                                                            onClick={(() => {
                                                                let tempArray = rows;
                                                                const confirmQtyForSelectedSch = rows.filter(line => line.deliveryScheduleLineNo === rows[index].deliveryScheduleLineNo)
                                                                let confirmedItemQty = 0;
                                                                for (let index = 0; index < confirmQtyForSelectedSch.length; index++) {
                                                                    confirmedItemQty = confirmedItemQty + confirmQtyForSelectedSch[index].confirmQty;
                                                                }
                                                                let remainingItemQty = row.totalOutstandingOrderQty - confirmedItemQty;
                                                                let deliveryReqQty = row.totalOutstandingOrderQty;
                                                                let deliveryScheduleLineNo = row.deliveryScheduleLineNo;
                                                                let sheduleReqDate = row.scheduleReqDt;
                                                                // let msgDlvyCfmId = getDlvCfmId();
                                                                let updatedBy = currentUser.userName;
                                                                let statsticalScheduleReqDt = row.statsticalScheduleReqDt;
                                                                tempArray.push(createData(
                                                                    0,
                                                                    deliveryScheduleLineNo,
                                                                    deliveryReqQty,
                                                                    sheduleReqDate,
                                                                    remainingItemQty,
                                                                    row.confirmDt === null ? row.scheduleReqDt : row.scheduleReqDt,
                                                                    '',
                                                                    '',
                                                                    updatedBy,
                                                                    { msgSchId: row.poOutboundMsgScc.msgSchId },
                                                                    row.poOutboundMsgScc.msgSchId,
                                                                    null,
                                                                    statusFlagValue,
                                                                    null,
                                                                    updatedBy,
                                                                    '',
                                                                    '',
                                                                    0,
                                                                    1,
                                                                    uploadedUserTypeUpdate === false ? 0 : currentUser.userType === 'SIIX-USER' ? 1 : 2,
                                                                    row.acceptPriorDelivery,
                                                                    row.bpCode,
                                                                    statsticalScheduleReqDt,
                                                                    row.entityCode,
                                                                    row.earlierAcceptanceDays,
                                                                    row.lateAcceptanceDays
                                                                ));
                                                                setRows(tempArray);
                                                                setTableStateChanged(!isTableStateChanged);
                                                            })}
                                                        ><CreateIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </TableCell>)}
                                            <TableCell align="left" scope="row" width="6%" style={{ padding: '24px 5px' }}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        className={classes.textFieldForPoCnfDialog}
                                                        variant="outlined"
                                                        margin="normal"
                                                        id="userName"
                                                        size='small'
                                                        placeholder={row.deliveryScheduleLineNo}
                                                        value={getParticularColumnData(index, 'deliveryScheduleLineNo')}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        onChange={(ev) => {
                                                            handleTextFieldChange(parseInt(ev.target.value), index, 'deliveryScheduleLineNo');
                                                            isRemainingQtyMoreThanConfirmingQty(index)
                                                            setTableStateChanged(!isTableStateChanged);
                                                        }}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left" scope="row" width="10%" style={{ padding: '24px 5px' }}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        className={classes.textFieldForPoCnfDialog}
                                                        variant="outlined"
                                                        margin="normal"
                                                        id="userName"
                                                        size='small'
                                                        value={getParticularColumnData(index, 'totalOutstandingOrderQty')}
                                                        type='number'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        disabled={true}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        onChange={(ev) => {
                                                            handleTextFieldChange(parseInt(ev.target.value), index, 'totalOutstandingOrderQty');
                                                            isRemainingQtyMoreThanConfirmingQty(index)
                                                            setTableStateChanged(!isTableStateChanged);
                                                        }}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left" width="10%" style={{ padding: '24px 5px' }}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <FormControl fullWidth>
                                                        <MobileDatePicker
                                                            renderInput={props => {
                                                                return <TextField {...props}
                                                                    variant='outlined'
                                                                    size="small"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />;
                                                            }}
                                                            placeholder="Please select valid Date"
                                                            clearable
                                                            id="validToDate"
                                                            inputFormat="dd-MM-yyyy"
                                                            minDate={row.statsticalScheduleReqDt !== null ? row.statsticalScheduleReqDt : new Date()}
                                                            value={getRowStatisticalScheduleReqDate(index)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            disabled={true}
                                                            onChange={(date) => {
                                                                handleChangeDateForStatisticalScheduleReqDt(date, index);
                                                                setTableStateChanged(!isTableStateChanged);
                                                            }}
                                                        />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="left" width="10%" style={{ padding: '24px 5px' }}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <FormControl fullWidth>
                                                        <MobileDatePicker
                                                            renderInput={props => {
                                                                return <TextField {...props}
                                                                    variant='outlined'
                                                                    size="small"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />;
                                                            }}
                                                            placeholder="Please select valid Date"
                                                            clearable
                                                            id="validToDate"
                                                            inputFormat="dd-MM-yyyy"
                                                            minDate={row.scheduleReqDt !== null ? row.scheduleReqDt : new Date()}
                                                            value={getRowScheduleReqDate(index)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            disabled={true}
                                                            onChange={(date) => {
                                                                handleChangeDateForScheduleReqDt(date, index);
                                                                setTableStateChanged(!isTableStateChanged);
                                                            }}
                                                        />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="left" scope="row" width="10%" style={{ padding: '24px 5px' }}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        className={classes.textFieldForPoCnfDialog}
                                                        variant="outlined"
                                                        margin="normal"
                                                        id="userName"
                                                        size='small'
                                                        value={row.confirmQty === 0 ? getParticularColumnData(index, 'totalOutstandingOrderQty') : getParticularColumnData(index, 'confirmQty')}
                                                        type='number'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        disabled={modeForSchedule === 'edit' ? false : true}
                                                        InputProps={{
                                                            readOnly: modeForSchedule === 'edit' ? false : true,
                                                        }}
                                                        onChange={(ev) => {
                                                            handleTextFieldChange(parseInt(ev.target.value), index, 'confirmQty');
                                                            isRemainingQtyMoreThanConfirmingQty(index)
                                                            setTableStateChanged(!isTableStateChanged);
                                                        }}
                                                        defaultValue={row.confirmQty === 0 ? getParticularColumnData(index, 'totalOutstandingOrderQty') : ''}
                                                    />
                                                    <div className={classes.error}>
                                                        {getParticularColumnData(index, 'confirmQty') > getParticularColumnData(index, 'totalOutstandingOrderQty') &&
                                                            <FormHelperText className={classes.error}>
                                                                Confirm Qty shouldn't be greater than Delivery Req Qty
                                                        </FormHelperText>
                                                        }
                                                    </div>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left" width="10%" style={{ padding: '24px 5px' }}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <FormControl fullWidth>
                                                        <MobileDatePicker
                                                            renderInput={props => {
                                                                return <TextField {...props}
                                                                    variant='outlined'
                                                                    size="small"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    defaultValue={row.confirmDt === null ? row.scheduleReqDt : ''}
                                                                />;
                                                            }}
                                                            placeholder="Please select valid Date"
                                                            clearable
                                                            id="validToDate"
                                                            inputFormat="dd-MM-yyyy"
                                                            minDate={minDateValue(index)}
                                                            maxDate={maxDateValue(index)}
                                                            value={row.confirmDt === null ? getRowStatisticalScheduleReqDate(index) !== getRowScheduleReqDate(index) ?
                                                                getRowScheduleReqDate(index) : getRowStatisticalScheduleReqDate(index) :
                                                                getRowConfirmDate(index)}
                                                            // value={row.uploadedUserType === 0 ? getRowScheduleReqDate(index) : row.confirmDt === null ? (getRowStatisticalScheduleReqDate(index) !== getRowScheduleReqDate(index) && poDtlObject.poLineStatus === "1") ||
                                                            //     (poDtlObject.poLineDeliveryConfirmed === 1) ? getRowScheduleReqDate(index) : getRowStatisticalScheduleReqDate(index) :
                                                            //     getRowConfirmDate(index)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            disabled={modeForSchedule === 'edit' ? false : true}
                                                            onChange={(date) => {
                                                                handleChangeDate(date.toISOString(), index);
                                                                setTableStateChanged(!isTableStateChanged);
                                                            }}
                                                        />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="center" width="7%" style={{ padding: '5px 5px' }}>{row.lastQty}</TableCell>
                                            <TableCell align="center" width="10%" style={{ padding: '5px 5px' }}>{row.lastConfirmDtDisp}</TableCell>
                                            <TableCell align="left" width="15%" style={{ padding: '5px 5px' }}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        className={classes.textField}
                                                        variant="outlined"
                                                        margin="normal"
                                                        id="userName"
                                                        size='small'
                                                        placeholder={row.remarks}
                                                        multiline
                                                        value={getParticularColumnData(index, 'remarks')}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={(ev) => {
                                                            if (
                                                                ev.target.value !== "" ||
                                                                ev.target.value !== null
                                                            ) {
                                                                handleTextFieldChange(ev.target.value, index, 'remarks');
                                                                setTableStateChanged(!isTableStateChanged);
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center" width="14%" style={{ padding: '5px 5px' }}>{row.updatedBy}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {tableLoadingStatus && (
                            <Grid container justifyContent='center' alignItems='center'>
                                <CircularProgress color='primary' size={40} />
                            </Grid>
                        )}
                    </Grid>
                    {(rows.length === 0 && !tableLoadingStatus) && (
                        <Paper style={{ marginTop: '20%', width: '100%' }}>
                            <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                                <Typography variant='body2' noWrap={true} className={classes.boldHeader}><FormattedMessage id="norows.label" defaultMessage="No rows" /></Typography>
                            </Grid>
                        </Paper>
                    )}
                </DialogContent>
                <DialogActions className={classes.dialogActionForPOConfirmDateDialog}>
                    <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                        <Button size='small' variant='contained' color='secondary' className={classes.buttonSpacingCancel}
                            startIcon={<ClearIcon />}
                            onClick={(() => {
                                handleClose(false);
                            })}><FormattedMessage id="common.button.cancel.lable" defaultMessage={pageLabelsNamesAndConst.labels.cancel} />
                        </Button>
                        {modeForSchedule === 'edit' && (<Button size='small' color="primary" variant='contained' className={classes.buttonSpacingForPoConf}
                            disabled={disableSubmitButton || rows.length === 0 || (poQty !== totalConfirmedQty) || butDisable || isSubmitButtonDisabled}
                            startIcon={<SaveIcon />}
                            onClick={currentUser.userType === 'SIIX-USER' ? (() => {
                                setDisabledSubmitButton(true);
                                setSubmitDialogOpen(true);
                                handleOpenUploadedUserTypeConfirmation();
                            }) : () => {
                                setDisabledSubmitButton(true);
                                onSubmit()
                            }}
                            endIcon={isResponseArrived ? <CircularProgress color="secondary" size={20} /> : null}
                        ><FormattedMessage id="common.button.submit.lable" />
                        </Button>)}
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

export default POConfirmDateDialog;