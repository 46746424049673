import { Button, Grid, CssBaseline, FormControl, TextField, Snackbar, useTheme, useMediaQuery, FormHelperText, CircularProgress, Tooltip, MenuItem, Switch, InputLabel } from '@mui/material';
import Alert from '@mui/lab/Alert';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import useStyles from '../common/CommonStyle/CommonStyle'
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import CustomBreadCrumb from '../common/CustomBreadCrumb';
import { Controller, useForm, } from 'react-hook-form';
import _ from "lodash/fp";
import { useIntl } from "react-intl";
import CreateIcon from '@mui/icons-material/Add';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { CommonService } from '../../services';
import CommonBackDrop from '../common/BackDrop';
import { GlobalEdiApiConstants } from "../../Constants/GlobalEdiApiConstants";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import UserCombo from '../common/Combos/UserCombo/UserCombo';
import InfoIcon from '@mui/icons-material/Info';
import { MobileDatePicker } from '@mui/lab';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },

});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disabletypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const EmailMessageConfigDiaglog = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();
    const commonPatterns = GlobalEdiApiConstants();

    const { onClose, open, title, mode, emailMessageConfigToBeEdited, supplierObjectToBeEditedOrView, customerObjectToBeEditedOrView } = props;
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [userValue, setUserValue] = useState({ id: '', userName: '' })
    const [componentList, setComponentList] = useState(title);
    const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('');
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
    const [disableForReadMode, setDisableForReadMode] = useState(false);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const currentUser = CommonService.getLoggedInUserInfo();
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [isResponseArrived, setResponseArrived] = useState(false);
    const [validFrom, changeValidFromDate] = useState(new Date());
    const [validToDate, changeValidToDate] = useState(null);
    const [emailConfigTobeConfigTobeEdited, setEmailConfigTobeConfigTobeEdited] = React.useState(emailMessageConfigToBeEdited);
    const [defaultToBeEdited, setDefaultToBeEdited] = useState({});
    const [loadSourceCodeInCreateMode, setLoadSourceCodeInCreateMode] = useState("");

    const handleClose = (value) => {
        setComponentList(title);
        onClose(value);
    }
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const pageLabelsConstantsAndMessages = {
        name: {
            contactSalutation: 'contactSalutation',
            contactName: 'contactName',
            project: 'project',
            countryCode: 'countryCode',
            contactNo: 'contactNo',
            emailTo: 'emailTo',
            emailCc: 'emailCc',
            emailBcc: 'emailBcc',
            validFrom: 'validFrom',
            user: 'user',
            validToDate: 'validTo',
            status: 'status',
            source: 'source'
        },
        label: {
            contactPersonSalutation: 'Salutation',
            contactPersonName: 'Contact Person Name',
            contactPersonPhoneNo: 'Contact Person PhoneNo',
            contactPersonEmailId: 'Contact Person EmailID',
        },
    }
    const [emailMessageConfigReadOnlyFields, setEmailMessageConfigReadOnlyFields] = useState({
        contactSalutation: false,
        contactName: false,
        project: false,
        countryCode: false,
        contactNo: false,
        emailTo: false,
        emailCc: false,
        emailBcc: false,
        validFrom: false,
        user: false,
        validToDate: false,
        source: false
    });
    const { handleSubmit, formState: { errors, isDirty, isValid }, control, reset, setValue } = useForm({
        mode: "all",
        reValidateMode: "all",
        defaultValues: {
            contactSalutation: '',
            contactName: '',
            project: '',
            countryCode: '',
            contactNo: null,
            emailTo: '',
            emailCc: '',
            emailBcc: '',
            validFrom: '',
            user: '',
            validFrom: new Date(),
            validToDate: '',
            status: false,
            source: ''
        },
    });

    const salutation = [
        {
            value: 'Mr',
            label: 'Mr',
        },
        {
            value: 'Ms',
            label: 'Ms',
        },
        {
            value: 'Mrs',
            label: 'Mrs',
        },
        {
            value: 'Mr/Ms',
            label: 'Mr/Ms',
        },
        {
            value: 'Dr',
            label: 'Dr',
        }
    ];

    useEffect(() => {
        if (mode === 'edit' || mode === 'read') {
            setDefaultToBeEdited(emailConfigTobeConfigTobeEdited);
        }
    }, [mode, emailConfigTobeConfigTobeEdited])

    function resetEmailMesssageConfigValues(responseData) {
        reset({
            contactSalutation: responseData.contactSalutation !== undefined ? responseData.contactSalutation : '',
            contactName: responseData.contactName !== undefined ? responseData.contactName : '',
            project: responseData.project !== undefined ? responseData.project : '',
            countryCode: responseData.countryCode !== undefined ? responseData.countryCode : '',
            contactNo: responseData.contactNo !== undefined ? responseData.contactNo : '',
            user: (responseData.user !== null && responseData.user !== undefined) ? { id: responseData.user.id, userName: responseData.user.userName } : '',
            emailTo: responseData.emailTo !== undefined ? responseData.emailTo : '',
            emailCc: responseData.emailCc !== undefined ? responseData.emailCc : '',
            emailBcc: responseData.emailBcc !== undefined ? responseData.emailBcc : '',
            validFrom: responseData.validFrom !== undefined ? responseData.validFrom : '',
            validTo: (responseData.validTo !== null && responseData.validTo !== undefined) ? responseData.validTo : '',
            source: responseData.source !== undefined ? responseData.source : '',
            status: responseData.status !== undefined ? responseData.status : '',
        });
        setUserValue((responseData.user !== null && responseData.user !== undefined) ? { id: responseData.user.id, userName: responseData.user.userName } : '')
        changeValidFromDate(new Date(responseData.validFrom));
        changeValidToDate((responseData.validTo !== null && responseData.validTo !== undefined) ? new Date(responseData.validTo) : null);
    }

    useEffect(() => {
        setEmailConfigTobeConfigTobeEdited(emailMessageConfigToBeEdited);
    }, [emailMessageConfigToBeEdited]);

    useEffect(() => {
        let currentRouterComponent;
        setValue(`${pageLabelsConstantsAndMessages.name.validFrom}`, validFrom, { shouldValidate: true, shouldDirty: true });
        if (mode === 'create') {
            isRestoreButtonDisabled(false);
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            setDisableForReadMode(false);
            currentRouterComponent = [
                {
                    path: null,
                    name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                    iconName: CreateIcon,
                }
            ];
            changeValidFromDate(new Date());
            setLoadSourceCodeInCreateMode("Global EDI");
        } else if (mode === 'edit') {
            isRestoreButtonDisabled(false);
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            resetEmailMesssageConfigValues(emailConfigTobeConfigTobeEdited);
            setDisableForReadMode(false);
            setEmailMessageConfigReadOnlyFields({
                ...emailMessageConfigReadOnlyFields, contactSalutation: false, contactName: false, project: false, countryCode: false, contactNo: false, emailTo: false,
                emailCc: false, emailBcc: false, validFrom: false, user: false, validToDate: false
            })
            currentRouterComponent = [
                {
                    path: null,
                    name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                    iconName: EditIcon,
                }
            ]
        } else if (mode === 'read') {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            isRestoreButtonDisabled(true);
            setIsSaveButtonDisabled(true);
            resetEmailMesssageConfigValues(emailConfigTobeConfigTobeEdited);
            setDisableForReadMode(true);
            setEmailMessageConfigReadOnlyFields({
                ...emailMessageConfigReadOnlyFields, contactSalutation: false, contactName: false, project: false, countryCode: false, contactNo: false, emailTo: false,
                emailCc: false, emailBcc: false, validFrom: false, user: false, validToDate: false, status: true
            })
            currentRouterComponent = [
                {
                    path: null,
                    name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
                    iconName: ViewIcon,
                }
            ]
        }
        setComponentList(title.concat(currentRouterComponent !== undefined ? currentRouterComponent : []));
    }, [mode, emailConfigTobeConfigTobeEdited]);

    const onSubmit = data => {
        data['commId'] = (mode === 'create') ? 0 : emailConfigTobeConfigTobeEdited.commId;
        data['bpType'] = supplierObjectToBeEditedOrView === undefined ? 'CUSTOMER' : 'SUPPLIER';
        data['bpCode'] = supplierObjectToBeEditedOrView === undefined ? customerObjectToBeEditedOrView.bpCode : supplierObjectToBeEditedOrView.bpCode;
        data['source'] = ((mode === 'create') || (mode === 'edit' && (emailConfigTobeConfigTobeEdited.source === 'Global EDI' || emailConfigTobeConfigTobeEdited.source === 'UI' || emailConfigTobeConfigTobeEdited.source === 'SAP'))) ? 'Global EDI' : 'SAP';
        data['createdBy'] = (mode === 'create') ? currentUser.userName : emailConfigTobeConfigTobeEdited.createdBy;
        data['createdTime'] = (emailConfigTobeConfigTobeEdited.createdTime === undefined) ? '' : emailConfigTobeConfigTobeEdited.createdTime;
        data['updatedBy'] = currentUser.userName;
        data['updatedTime'] = (emailConfigTobeConfigTobeEdited.updatedTime === undefined) ? '' : emailConfigTobeConfigTobeEdited.updatedTime;
        data['user'] = userValue.userName === '' || userValue.userName === undefined ? null : userValue;
        data['entityCode'] = supplierObjectToBeEditedOrView === undefined ? customerObjectToBeEditedOrView.entity.erpEntityCode : supplierObjectToBeEditedOrView.entity.erpEntityCode;
        setResponseArrived(true);
        CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.bpEmailMessageConfigs, data)
            .then((response) => {
                setResponseArrived(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success" });
                onClose(true);
            })
            .catch(function (error) {
                setResponseArrived(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
            });
    }

    const userComboProperty = {
        name: pageLabelsConstantsAndMessages.name.user,
        isRequired: false,
        label: intl.formatMessage({ id: 'columnname.entitycontactname.label' }),
        isAutofocus: false,
        isDisabled: disableForReadMode,
        variant: 'outlined',
        isHookFormType: true,
        fieldNameToBeDisplayed: 'userName',
        errors: errors.user,
        // allowLogin: true
    }

    function resetAllClearThePageInfo() {
        if (mode === 'edit') {
            resetEmailMesssageConfigValues(emailConfigTobeConfigTobeEdited);
            setDefaultToBeEdited({ ...emailConfigTobeConfigTobeEdited })
        } else {
            reset({
                contactSalutation: '',
                contactName: '',
                project: '',
                countryCode: '',
                contactNo: '',
                emailTo: '',
                emailCc: '',
                emailBcc: '',
                validFrom: '',
                user: '',
                validFrom: '',
                validToDate: '',
                status: false
            });
            changeValidFromDate(new Date());
            setValue(`${pageLabelsConstantsAndMessages.name.validFrom}`, new Date(), { shouldValidate: true, shouldDirty: true });
            changeValidToDate(null);
            setEmailConfigTobeConfigTobeEdited({ ...emailConfigTobeConfigTobeEdited, status: false });
            setDefaultToBeEdited({ ...defaultToBeEdited, status: false })
        }
    };

    const onError = ((error) => {
    })

    const [bpCode, setBpCode] = useState(customerObjectToBeEditedOrView === undefined ? supplierObjectToBeEditedOrView : customerObjectToBeEditedOrView);
    const [bpType, setBpType] = useState(customerObjectToBeEditedOrView === undefined ? 'SUPPLIER' : 'CUSTOMER');

    useEffect(() => {
        if ((mode === 'edit') || (mode === 'create') || (mode === 'read')) {
            setBpCode(customerObjectToBeEditedOrView === undefined ? supplierObjectToBeEditedOrView.bpCode : customerObjectToBeEditedOrView.bpCode)
            setBpType(customerObjectToBeEditedOrView === undefined ? 'SUPPLIER' : 'CUSTOMER')
        }
    }, [customerObjectToBeEditedOrView, supplierObjectToBeEditedOrView])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.root}>
                <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} >
                        <Dialog
                            fullWidth
                            maxWidth='lg'
                            aria-labelledby="create-or-delete-dialog"
                            open={open}>
                            <DialogTitle className={classes.titleCommonDialogComponent}  >
                                <Grid item container xs={12} sm={12} md={12} lg={12}>
                                    <Grid item container alignItems='center' xs={6} sm={8} md={8} lg={6} style={{ marginLeft: '-1.3%' }} >
                                        <CustomBreadCrumb componentList={componentList} isDialog={true} />
                                    </Grid>
                                    <Grid item container justifyContent='flex-end' xs={6} sm={4} md={4} lg={6} style={{ color: '#fff', fontSize: '14px', marginLeft: '0.8%' }} >
                                        {bpType} | {bpCode}
                                    </Grid>
                                </Grid>
                            </DialogTitle>

                            <DialogContent dividers style={{ minHeight: '40vh' }} >
                                <Grid container className={classes.root}>
                                    <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                                            <form className={classes.form} onSubmit={handleSubmit(onSubmit, onError)}>
                                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                                                    <Grid item container xs={12} sm={3} md={2} lg={2}>
                                                        <FormControl fullWidth>
                                                            <Controller
                                                                control={control}
                                                                name={pageLabelsConstantsAndMessages.name.source}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        className={classes.textField}
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        id="Source"
                                                                        size='small'
                                                                        label={intl.formatMessage({ id: "textfield.source.label" })}
                                                                        {...field}
                                                                        disabled
                                                                        InputLabelProps={{
                                                                            shrink: true,

                                                                        }}
                                                                        value={mode === 'create' ? loadSourceCodeInCreateMode : emailMessageConfigToBeEdited.source}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item container xs={12} sm={3} md={2} lg={1}  >
                                                        <FormControl fullWidth>
                                                            <Controller
                                                                control={control}
                                                                name={pageLabelsConstantsAndMessages.name.contactSalutation}
                                                                rules={{
                                                                    required: true,
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        className={classes.textField}
                                                                        select
                                                                        required={true}
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        id="contactSalutation"
                                                                        size='small'
                                                                        label={<FormattedMessage id="columnname.Salutation.label" defaultMessage="Salutation" />}
                                                                        {...field}
                                                                        error={errors.contactSalutation}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                            classes: {
                                                                                asterisk: classes.asterisk
                                                                            }
                                                                        }}
                                                                        InputProps={{
                                                                            readOnly: emailMessageConfigReadOnlyFields.contactSalutation,
                                                                            classes: { notchedOutline: classes.specialOutline }
                                                                        }}
                                                                        disabled={disableForReadMode}
                                                                    >
                                                                        {salutation.map((option) => (
                                                                            <MenuItem key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                            <div className={classes.error}>
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.contactSalutation}.type`, errors) === "required" && (
                                                                    <FormHelperText className={classes.error}>
                                                                        <FormattedMessage id="textfield.error.contactpersonsalution" defaultMessage="Contact Person Salution is required" />
                                                                    </FormHelperText>
                                                                )}

                                                            </div>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={2} lg={3}    >
                                                        <FormControl fullWidth>
                                                            <Controller
                                                                control={control}
                                                                name={pageLabelsConstantsAndMessages.name.contactName}
                                                                rules={{
                                                                    required: true,
                                                                    // pattern: commonPatterns.commonTextfeildPattern.patternForAlphabetsOnly
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        className={classes.textField}
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        id="contactName"
                                                                        size='small'
                                                                        label={<FormattedMessage id="columnname.textfield.attntoname.label" defaultMessage="Contact Person Name" />}
                                                                        {...field}
                                                                        error={errors.contactName}
                                                                        required={true}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                            classes: {
                                                                                asterisk: classes.asterisk
                                                                            }
                                                                        }}
                                                                        InputProps={{
                                                                            readOnly: emailMessageConfigReadOnlyFields.contactName,
                                                                        }}
                                                                        disabled={disableForReadMode}
                                                                    />
                                                                )}
                                                            />
                                                            <div className={classes.error}>
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.contactName}.type`, errors) === "required" && (
                                                                    <FormHelperText className={classes.error}>
                                                                        {<FormattedMessage id="columnname.textfield.attntoname.label" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}
                                                                    </FormHelperText>
                                                                )}
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.contactName}.type`, errors) === "pattern" && (
                                                                    <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.textfield.attntoname.label" defaultMessage="ContactPersonName" />} {commonPatterns.commonErrorsForTextFields.errorForAplhabetsOnly}</FormHelperText>
                                                                )}
                                                            </div>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                        <FormControl fullWidth>
                                                            <Controller
                                                                control={control}
                                                                name={pageLabelsConstantsAndMessages.name.project}
                                                                rules={(!defaultToBeEdited.status) ? {
                                                                    required: true,
                                                                    pattern: commonPatterns.commonTextfeildPattern.patternForAlphaNumeric
                                                                } : { required: false }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        className={classes.textField}
                                                                        required={!(defaultToBeEdited.status) ? true : false}
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        id="project"
                                                                        size='small'
                                                                        label={intl.formatMessage({ id: "common.textfield.column.project" })}
                                                                        {...field}
                                                                        error={(!defaultToBeEdited.status) ? errors.project : null}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                            classes: {
                                                                                asterisk: (!defaultToBeEdited.status) ? classes.asterisk : null
                                                                            }
                                                                        }}
                                                                        InputProps={{
                                                                            readOnly: emailMessageConfigReadOnlyFields.project,
                                                                        }}
                                                                        disabled={mode === 'read' ? disableForReadMode : (defaultToBeEdited.status)}
                                                                    />
                                                                )}
                                                                defaultValue={defaultToBeEdited.status ? 'All' : ''}
                                                            />
                                                            <div className={classes.error}>
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.project}.type`, errors) === "required" && (!defaultToBeEdited.status) && (
                                                                    <FormHelperText className={classes.error}>
                                                                        {<FormattedMessage id="common.textfield.column.project" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}
                                                                    </FormHelperText>
                                                                )}
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.project}.type`, errors) === "pattern" && (
                                                                    <FormHelperText className={classes.error}> {<FormattedMessage id="common.textfield.column.project" defaultMessage="projectCode" />} {commonPatterns.commonErrorsForTextFields.errorForAlphaNumeric}</FormHelperText>
                                                                )}
                                                            </div>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                        <UserCombo comboProperty={userComboProperty} control={control} errors={errors} comboValue={userValue} setComboValue={setUserValue} />
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}    >
                                                        <FormControl fullWidth>
                                                            <Controller
                                                                control={control}
                                                                name={pageLabelsConstantsAndMessages.name.countryCode}
                                                                rules={{
                                                                    maxLength: 3,
                                                                    pattern: commonPatterns.commonTextfeildPattern.patternForAlphabetsOnly
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        className={classes.textField}
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        id="contactName"
                                                                        size='small'
                                                                        label={<FormattedMessage id="columnname.textfield.countrycodeph.label" defaultMessage="Country Code" />}
                                                                        {...field}
                                                                        error={errors.countryCode}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                            classes: {
                                                                                asterisk: classes.asterisk
                                                                            }
                                                                        }}
                                                                        InputProps={{
                                                                            readOnly: emailMessageConfigReadOnlyFields.countryCode,
                                                                        }}
                                                                        disabled={disableForReadMode}
                                                                    />
                                                                )}
                                                            />
                                                            <div className={classes.error}>
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.countryCode}.type`, errors) === "required" && (
                                                                    <FormHelperText className={classes.error}>
                                                                        {<FormattedMessage id="columnname.textfield.countrycodeph.label" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}
                                                                    </FormHelperText>
                                                                )}
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.countryCode}.type`, errors) === "pattern" && (
                                                                    <FormHelperText className={classes.error}> {<FormattedMessage id="columnname.textfield.countrycodeph.label" defaultMessage="CountryCode" />} {commonPatterns.commonErrorsForTextFields.errorForAplhabetsOnly}</FormHelperText>
                                                                )}
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.countryCode}.type`, errors) === "maxLength" && (
                                                                    <FormHelperText className={classes.error}> {<FormattedMessage id="columnname.textfield.countrycodeph.label" defaultMessage="CountryCode" />} {<FormattedMessage id="textfield.error.countrycode.maxlength" />}</FormHelperText>
                                                                )}
                                                            </div>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}    >
                                                        <FormControl fullWidth>
                                                            <Controller
                                                                control={control}
                                                                name={pageLabelsConstantsAndMessages.name.contactNo}
                                                                rules={
                                                                    {
                                                                        minLength: 4,
                                                                        maxLength: 20,
                                                                        pattern: commonPatterns.commonTextfeildPattern.patternForTel_PhNumber,
                                                                    }
                                                                }
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        className={classes.textField}
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        id="contact No"
                                                                        size='small'
                                                                        label={<FormattedMessage id="columnname.textfield.attntocontactno.label" />}
                                                                        {...field}
                                                                        error={errors.contactNo}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                            classes: {
                                                                                asterisk: classes.asterisk
                                                                            }
                                                                        }}
                                                                        InputProps={{
                                                                            readOnly: emailMessageConfigReadOnlyFields.contactNo,
                                                                        }}
                                                                        disabled={disableForReadMode}
                                                                    />
                                                                )}
                                                            />
                                                            <div className={classes.error}>
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.contactNo}.type`, errors) === "required" && (
                                                                    <FormHelperText className={classes.error}>
                                                                        {<FormattedMessage id="columnname.textfield.attntocontactno.label" />} {<FormattedMessage id="textfield.error.isrequirededmessage" />}
                                                                    </FormHelperText>
                                                                )}

                                                                {_.get(`${pageLabelsConstantsAndMessages.name.contactNo}.type`, errors) === "maxLength" && (
                                                                    <FormHelperText className={classes.error}><FormattedMessage id="columnname.textfield.attntocontactno.label" /> {<FormattedMessage id="textfield.error.cannotexceedmorethaneighteencharacmessage" />}</FormHelperText>
                                                                )}
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.contactNo}.type`, errors) === "pattern" && (
                                                                    <FormHelperText className={classes.error}>{<FormattedMessage id="columnname.textfield.attntocontactno.label" defaultMessage="contactNo" />}  {<FormattedMessage id="common.errorForTele_PhoneNumber.message" />}</FormHelperText>
                                                                )}
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.contactNo}.type`, errors) === "minLength" && (
                                                                    <FormHelperText className={classes.error}><FormattedMessage id="columnname.textfield.attntocontactno.label" /> {<FormattedMessage id="textfield.error.cannotexceedmorethantencharsmessgage" />}</FormHelperText>
                                                                )}
                                                            </div>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <FormControl fullWidth>
                                                                <Controller
                                                                    control={control}
                                                                    name={pageLabelsConstantsAndMessages.name.validFrom}
                                                                    defaultValue={validFrom}
                                                                    render={({ field }) => (
                                                                        <MobileDatePicker
                                                                            renderInput={props => {
                                                                                return <TextField {...props}
                                                                                    variant='outlined'
                                                                                    size="small"
                                                                                    label={<FormattedMessage id="textfield.label.validfromdate" defaultMessage="Valid From  " />}
                                                                                    {...field}
                                                                                    required
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                        classes: {
                                                                                            asterisk: classes.asterisk
                                                                                        }
                                                                                    }}
                                                                                />;
                                                                            }}
                                                                            clearable
                                                                            id="validFrom"
                                                                            inputFormat="dd-MM-yyyy"
                                                                            maxDate={validToDate}
                                                                            minDate={validFrom}
                                                                            value={validFrom}
                                                                            error={errors.validFrom}
                                                                            onChange={((date) => {
                                                                                changeValidFromDate(date);
                                                                                setValue(`${pageLabelsConstantsAndMessages.name.validFrom}`, date, { shouldValidate: true, shouldDirty: true });
                                                                            })}
                                                                            disabled={disableForReadMode}
                                                                        />
                                                                    )}
                                                                />
                                                                <div className={classes.error}>
                                                                    {_.get(`${pageLabelsConstantsAndMessages.name.validFrom}.type`, errors) === "required" && validFrom === null && (
                                                                        <FormHelperText className={classes.error}>
                                                                            {<FormattedMessage id="textfield.error.validfromdateempty" defaultMessage="Valid From is required" />}
                                                                        </FormHelperText>
                                                                    )}
                                                                </div>
                                                            </FormControl>
                                                        </LocalizationProvider >
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={3} lg={3}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <FormControl fullWidth>
                                                                <Controller
                                                                    control={control}
                                                                    name={pageLabelsConstantsAndMessages.name.validToDate}
                                                                    render={({ field }) => (
                                                                        <MobileDatePicker
                                                                            renderInput={props => {
                                                                                return <TextField {...props}
                                                                                    variant='outlined'
                                                                                    size="small"
                                                                                    label={<FormattedMessage id="textfield.label.validtodate" defaultMessage="Valid To" />}
                                                                                    {...field}
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                />;
                                                                            }}
                                                                            clearable
                                                                            id="validToDate"
                                                                            inputFormat="dd-MM-yyyy"
                                                                            minDate={validFrom}
                                                                            error={errors.validToDate}
                                                                            value={validToDate}
                                                                            onChange={((date) => {
                                                                                changeValidToDate(date);
                                                                                setValue(`${pageLabelsConstantsAndMessages.name.validToDate}`, date, { shouldValidate: true, shouldDirty: true });
                                                                            }
                                                                            )}
                                                                            name={pageLabelsConstantsAndMessages.name.validToDate}
                                                                            disabled={disableForReadMode}
                                                                        />
                                                                    )}
                                                                />
                                                                <div className={classes.error}>
                                                                    {_.get(`${pageLabelsConstantsAndMessages.name.validToDate}.type`, errors) === "required" && validToDate === null && (
                                                                        <FormHelperText className={classes.error}>
                                                                            {<FormattedMessage id="textfield.error.validtodateempty" defaultMessage="Valid To is required" />}
                                                                        </FormHelperText>
                                                                    )}
                                                                </div>
                                                            </FormControl>
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                                                    <Grid item container xs={12} sm={6} md={6} lg={6}>
                                                        <FormControl fullWidth>
                                                            <Controller
                                                                control={control}
                                                                name={pageLabelsConstantsAndMessages.name.emailTo}
                                                                rules={{
                                                                    required: true,
                                                                    pattern: commonPatterns.commonTextfeildPattern.patternForEmailId
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        required={true}
                                                                        className={classes.textField}
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        id="emailTo"
                                                                        size='small'
                                                                        multiline
                                                                        maxRows={2}
                                                                        label={intl.formatMessage({ id: "textfield.bpemailto.label" })}
                                                                        {...field}
                                                                        error={errors.emailTo}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                            classes: {
                                                                                asterisk: classes.asterisk
                                                                            }
                                                                        }}
                                                                        InputProps={{
                                                                            readOnly: emailMessageConfigReadOnlyFields.emailTo,
                                                                        }}
                                                                        disabled={disableForReadMode}
                                                                    />
                                                                )}
                                                            />
                                                            {(mode === 'edit' || mode === 'create') && <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={6} md={12} lg={12}>
                                                                <FormHelperText><FormattedMessage id="texfield.placeholder.emailid.label"
                                                                    defaultMessage="Separate Multiple EmailID's with Semicolon(;)" /></FormHelperText>
                                                            </Grid>}
                                                            <div className={classes.error}>
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.emailTo}.type`, errors) === "required" && (
                                                                    <FormHelperText className={classes.error}>
                                                                        <FormattedMessage id="textfield.error.bpemailto" defaultMessage="BP Email To is required" />
                                                                    </FormHelperText>
                                                                )}

                                                                {_.get(`${pageLabelsConstantsAndMessages.name.emailTo}.type`, errors) === "maxLength" && (
                                                                    <FormHelperText className={classes.error}>
                                                                        {<FormattedMessage id="textfield.error.patternbpemailto" defaultMessage="Email Id cannot exceed more than 100 chars" />}
                                                                    </FormHelperText>
                                                                )}
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.emailTo}.type`, errors) === "pattern" && (
                                                                    <FormHelperText className={classes.error}> {<FormattedMessage id="textfield.bpemailto.label" defaultMessage="BP Email To" />} {commonPatterns.commonErrorsForTextFields.errorForEmailId}</FormHelperText>
                                                                )}
                                                            </div>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={6} lg={6}>
                                                        <FormControl fullWidth>
                                                            <Controller
                                                                control={control}
                                                                name={pageLabelsConstantsAndMessages.name.emailCc}
                                                                rules={{
                                                                    pattern: commonPatterns.commonTextfeildPattern.patternForEmailId
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        className={classes.textField}
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        id="emailCc"
                                                                        size='small'
                                                                        multiline
                                                                        maxRows={2}
                                                                        label={intl.formatMessage({ id: "textfield.bpemailfrom.label" })}
                                                                        {...field}
                                                                        error={errors.emailCc}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                            classes: {
                                                                                asterisk: classes.asterisk
                                                                            }
                                                                        }}
                                                                        InputProps={{
                                                                            readOnly: emailMessageConfigReadOnlyFields.emailCc,
                                                                        }}
                                                                        disabled={disableForReadMode}
                                                                    />
                                                                )}
                                                            />
                                                            {(mode === 'edit' || mode === 'create') && <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={6} md={12} lg={12}>
                                                                <FormHelperText><FormattedMessage id="texfield.placeholder.emailid.label"
                                                                    defaultMessage="Separate Multiple EmailID's with Semicolon(;)" /></FormHelperText>
                                                            </Grid>}
                                                            <div className={classes.error}>
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.emailCc}.type`, errors) === "required" && (
                                                                    <FormHelperText className={classes.error}>
                                                                        <FormattedMessage id="textfield.error.bpemailcc" defaultMessage="BP Email CC is required" />
                                                                    </FormHelperText>
                                                                )}

                                                                {/* {_.get(`${pageLabelsConstantsAndMessages.name.emailCc}.type`, errors) === "pattern" && (
                                                                    <FormHelperText className={classes.error}><FormattedMessage id="common.textField.error.emailid" defaultMessage="Please enter valid Email Id" /></FormHelperText>
                                                                )} */}
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.emailCc}.type`, errors) === "maxLength" && (
                                                                    <FormHelperText className={classes.error}>
                                                                        {<FormattedMessage id="textfield.error.patternbpemailto" defaultMessage="Email Id cannot exceed more than 100 chars" />}
                                                                    </FormHelperText>
                                                                )}
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.emailCc}.type`, errors) === "pattern" && (
                                                                    <FormHelperText className={classes.error}> {<FormattedMessage id="textfield.bpemailfrom.label" defaultMessage="BP Email CC" />} {commonPatterns.commonErrorsForTextFields.errorForEmailId}</FormHelperText>
                                                                )}
                                                            </div>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={6} lg={6}>
                                                        <FormControl fullWidth>
                                                            <Controller
                                                                control={control}
                                                                name={pageLabelsConstantsAndMessages.name.emailBcc}
                                                                rules={{
                                                                    pattern: commonPatterns.commonTextfeildPattern.patternForEmailId
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        className={classes.textField}
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        id="emailTo"
                                                                        size='small'
                                                                        multiline
                                                                        maxRows={2}
                                                                        label={intl.formatMessage({ id: "textfield.bpemailbcc.label" })}
                                                                        {...field}
                                                                        error={errors.emailBcc}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        InputProps={{
                                                                            readOnly: emailMessageConfigReadOnlyFields.emailBcc,
                                                                        }}
                                                                        disabled={disableForReadMode}
                                                                    />
                                                                )}
                                                            />
                                                            {(mode === 'edit' || mode === 'create') && <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={6} md={12} lg={12}>
                                                                <FormHelperText><FormattedMessage id="texfield.placeholder.emailid.label"
                                                                    defaultMessage="Separate Multiple EmailID's with Semicolon(;)" /></FormHelperText>
                                                            </Grid>}
                                                            <div className={classes.error}>


                                                                {_.get(`${pageLabelsConstantsAndMessages.name.emailBcc}.type`, errors) === "maxLength" && (
                                                                    <FormHelperText className={classes.error}>
                                                                        {<FormattedMessage id="common.textField.error.emailid" defaultMessage="Email Id cannot exceed more than 100 chars" />}
                                                                    </FormHelperText>
                                                                )}
                                                                {_.get(`${pageLabelsConstantsAndMessages.name.emailBcc}.type`, errors) === "pattern" && (
                                                                    <FormHelperText className={classes.error}> {<FormattedMessage id="textfield.bpemailbcc.label" defaultMessage="BP Email BCC" />} {commonPatterns.commonErrorsForTextFields.errorForEmailId}</FormHelperText>
                                                                )}
                                                            </div>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item container xs={12} sm={6} md={6} lg={6}>
                                                        <Grid container justifyContent='flex-start' alignItems='center'>
                                                            <Controller
                                                                render={({
                                                                    field: { onChange } }) => (<Switch
                                                                        color='primary'
                                                                        checked={defaultToBeEdited.status !== undefined ? defaultToBeEdited.status : false}
                                                                        onChange={((event) => {
                                                                            onChange(!(defaultToBeEdited.status !== undefined ? defaultToBeEdited.status : false));
                                                                            setDefaultToBeEdited({ ...defaultToBeEdited, status: event.target.checked })
                                                                            if (mode === 'create' && defaultToBeEdited.status) {
                                                                                setValue(`${pageLabelsConstantsAndMessages.name.project}`, '');
                                                                            } else if (mode === 'edit' && defaultToBeEdited.status && emailMessageConfigToBeEdited.project !== 'All') {
                                                                                setValue(`${pageLabelsConstantsAndMessages.name.project}`, `${emailMessageConfigToBeEdited.project}`);
                                                                            } else if (mode === 'edit' && defaultToBeEdited.status && emailMessageConfigToBeEdited.project === 'All') {
                                                                                setValue(`${pageLabelsConstantsAndMessages.name.project}`, '');
                                                                            } else {
                                                                                setValue(`${pageLabelsConstantsAndMessages.name.project}`, 'All');
                                                                            }
                                                                        })}
                                                                        disabled={disableForReadMode}
                                                                    />)}
                                                                type="checkbox"
                                                                name={pageLabelsConstantsAndMessages.name.status}
                                                                control={control}
                                                                defaultValue={defaultToBeEdited.status !== undefined ? defaultToBeEdited.status : false}
                                                            />
                                                            <InputLabel>{<FormattedMessage id="switch.projeectall.label" />}</InputLabel>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>

                            {(!mobileScreen) && (
                                <DialogActions className={classes.dialogAction} >
                                    <Grid container justifyContent="flex-start" alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} >
                                        {(mode === 'create' || mode === 'edit') ?
                                            <InfoIcon color="primary" /> : null}
                                        {(mode === 'create' || mode === 'edit') ?
                                            <Typography justifyContent="flex-start" style={{ fontSize: 12, marginLeft: '1%' }}><FormattedMessage id="textfield.placeholder.dialogActions" defaultMessage="All the '*' marked fields are mandatory to submit the form" /></Typography> : null}
                                    </Grid>
                                    <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} >
                                        <Button size='small' variant="contained" color="primary" className={classes.stateButton} elevation={2} endIcon={<ClearIcon />}
                                            onClick={() => handleClose(false)}
                                        ><FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />
                                        </Button>

                                        <Button size='small' variant="contained" color="secondary" className={classes.stateButtonToRestore}
                                            disabled={disableRestoreButton} elevation={2} endIcon={<IconRestore />} onClick={(() => { resetAllClearThePageInfo() })}
                                        > <FormattedMessage id="common.button.reset.lable" defaultMessage="Restore" />
                                        </Button>
                                        {(mode === 'edit') ?
                                            <Button size='small'
                                                variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                                endIcon={isResponseArrived ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />}
                                                disabled={isResponseArrived || isSaveButtonDisabled || (!isDirty && isValid)}
                                                onClick={handleSubmit(onSubmit, onError)}>
                                                {saveOrUpdateButtonLabel}
                                            </Button> : <Button size='small'
                                                variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                                endIcon={isResponseArrived ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />}
                                                disabled={isResponseArrived || isSaveButtonDisabled || (isDirty && !isValid)}
                                                onClick={handleSubmit(onSubmit, onError)}>
                                                {saveOrUpdateButtonLabel}
                                            </Button>}
                                    </Grid>
                                </DialogActions>
                            )}
                            {(mobileScreen) && (<Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                <DialogActions className={classes.dialogAction}>
                                    <Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
                                        <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                                            <Button size='small' variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2}
                                                onClick={() => handleClose(false)}
                                            >
                                                <ClearIcon />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={<FormattedMessage id="common.button.reset.lable" />}>
                                            <Button size='small' variant="contained" color="secondary" className={classes.stateButtonToRestore}
                                                disabled={disableRestoreButton} elevation={2} onClick={(() => { resetAllClearThePageInfo() })}><IconRestore />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={(mode === 'create') ? <FormattedMessage id="common.button.save" /> : <FormattedMessage id="common.button.update" />}>
                                            {(mode === 'edit') ?
                                                <Button size='small'
                                                    variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                                    endIcon={isResponseArrived ? <CircularProgress color="secondary" size={20} /> : null}
                                                    disabled={isResponseArrived || isSaveButtonDisabled || (!isDirty && isValid)}
                                                    onClick={handleSubmit(onSubmit, onError)}>
                                                    <IconSaveOrUpdate /> </Button> :
                                                <Button size='small'
                                                    variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                                                    disabled={isResponseArrived || isSaveButtonDisabled || (isDirty && !isValid)}
                                                    endIcon={isResponseArrived ? <CircularProgress color="secondary" size={20} /> : null}
                                                    onClick={handleSubmit(onSubmit, onError)}>
                                                    <IconSaveOrUpdate /></Button>}
                                        </Tooltip>
                                    </Grid>
                                </DialogActions>
                            </Grid>)}
                        </Dialog>
                    </Grid>

                </Grid>
            </Grid>
        </React.Fragment >
    );
}
export default EmailMessageConfigDiaglog;
