import React, { useEffect, useState } from 'react';
import { CssBaseline, FormControl, Grid, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Controller } from 'react-hook-form';
import _ from "lodash/fp";
import useStyles from '../../common/CommonStyle/CommonStyle';

const AddressDetails = (props) => {
    const classes = useStyles();
    const { mode, errors, control } = props;
    const pageLabelsConstantsAndMessages = {
        name: {
            addressLine1: "addressLine1",
            addressLine2: "addressLine2",
            addressLine3: "addressLine3",
            city: "city",
            state: "state",
            country: "country",
            countryCode: "countryCode",
            postalCode: "postalCode",
            fax: "fax",
            telNo: "telNo"
        }
    }

    const [addressReadOnlyFields, setAddressReadOnlyFields] = useState({
        addressLine1: false,
        addressLine2: false,
        addressLine3: false,
        city: false,
        state: false,
        country: false,
        countryCode: false,
        postalCode: false,
        fax: false,
        telNo: false
    })
    useEffect(() => {
        if (mode === 'read' || mode === 'edit') {
            setAddressReadOnlyFields({
                ...addressReadOnlyFields, addressLine1: false, addressLine2: false, addressLine3: false, city: false, state: false, countryCode: false, postalCode: false, fax: false, country: false, telNo: false
            })
        }
    }, [mode])

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.root}>
                <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} >
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.addressLine1}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="addressLine1"
                                            size='small'
                                            label={<FormattedMessage id="textfield.label.columname.address1" defaultMessage="Address Line 1" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: addressReadOnlyFields.addressLine1,
                                            }}
                                            disabled={mode === 'read' ? true : false}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.addressLine2}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="addressLine2"
                                            size='small'
                                            label={<FormattedMessage id="textfield.label.columname.address2" defaultMessage="Address Line 2" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: addressReadOnlyFields.addressLine2,
                                            }}
                                            disabled={mode === 'read' ? true : false}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.addressLine3}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="addressLine3"
                                            size='small'
                                            label={<FormattedMessage id="textfield.label.columname.address3" defaultMessage="Address Line 3" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: addressReadOnlyFields.addressLine3,
                                            }}
                                            disabled={mode === 'read' ? true : false}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.city}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="city"
                                            size='small'
                                            label={<FormattedMessage id="columnname.city.label" defaultMessage="City" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: addressReadOnlyFields.city,
                                            }}
                                            disabled={mode === 'read' ? true : false}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.state}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="state"
                                            size='small'
                                            label={<FormattedMessage id="columnname.state.label" defaultMessage=" State" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: addressReadOnlyFields.state,
                                            }}
                                            disabled={mode === 'read' ? true : false}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.country}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="country"
                                            size='small'
                                            label={<FormattedMessage id="columnname.country.labele" defaultMessage=" Country" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: addressReadOnlyFields.country,
                                            }}
                                            disabled={mode === 'read' ? true : false}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.countryCode}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="countryCode"
                                            size='small'
                                            label={<FormattedMessage id="columnname.countrycode.label" defaultMessage=" Country Code" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: addressReadOnlyFields.countryCode,
                                            }}
                                            disabled={mode === 'read' ? true : false}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.postalCode}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="postalCode"
                                            size='small'
                                            label={<FormattedMessage id="columnname.postalcode.label" defaultMessage=" Postal Code" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: addressReadOnlyFields.postalCode,
                                            }}
                                            disabled={mode === 'read' ? true : false}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.fax}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="fax"
                                            size='small'
                                            label={<FormattedMessage id="columnname.fax.label" defaultMessage=" Fax" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: addressReadOnlyFields.fax,
                                            }}
                                            disabled={mode === 'read' ? true : false}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} lg={3}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name={pageLabelsConstantsAndMessages.name.telNo}
                                    rules={
                                        { pattern: /^[0-9\b]+$/ },
                                        { minLength: 10 },
                                        { maxLength: 15 }}
                                    render={({ field }) => (
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="normal"
                                            id="telNo"
                                            size='small'
                                            label={<FormattedMessage id="columnname.telephone.label" defaultMessage=" Telephone No" />}
                                            {...field}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: addressReadOnlyFields.telNo,
                                            }}
                                            disabled={mode === 'read' ? true : false}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
export default AddressDetails;