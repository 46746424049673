import { Button, Grid, CssBaseline, Snackbar, useTheme, TextField, FormControl, FormHelperText, InputLabel, useMediaQuery, CircularProgress, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import useStyles from '../../common/CommonStyle/CommonStyle';
import PropTypes from 'prop-types';
import Alert from '@mui/lab/Alert';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import CreateIcon from '@mui/icons-material/Add';
import ViewIcon from '@mui/icons-material/Visibility';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import CustomBreadCrumb from '../../common/CustomBreadCrumb';
import { useHistory } from 'react-router-dom';
import TabPanel from '../../common/CommonDialogComponent/HorizontalTab';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ProcessNameCombo from '../../common/Combos/ProcessNameCombo/ProcessNameCombo';
import InOutCombo from '../../common/Combos/InOutCombo/InOutCombo';
import FileTypeCombo from '../../common/Combos/FileType/FileType';
import { CommonService } from '../../../services/CommonServices.service';
import CustomSchedulerTab from '../../../CustomSchedulerTab/CustomSchedulerTab';
import { GlobalEdiApiConstants } from '../../../Constants/GlobalEdiApiConstants';
import InterfaceActionCombo from '../../common/Combos/InterfaceActionCombo/InterfaceActionCombo';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),

  },

});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disabletypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);



const InterfaceDialogComponent = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();
  const [tabvalue, setTabValue] = React.useState(0);
  const { open, onClose, mode, title, intfHdrId, valuesforResetting, valuesTobeEdited, dialogHeader } = props;
  const [resettingValues, setResettingValues] = useState(props.resettingValues)
  const previousFrequency = props.resettingValues.frequency;
  const [componentList, setComponentList] = useState('');
  const [erpIinterfaceDetailReadOnlyFields, setinterfaceDetailReadOnlyFields] = useState({
    intfId: false,
    active: false,
    process: false,
    frequency: false,
    updateAction: false,
    inOut: false,
    fileType: false
  })
  const [alertBoxState, setAlertBoxState] = React.useState({
    openAlert: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
    severity: ''
  });

  const handleCloseAlert = () => {
    setAlertBoxState({ ...alertBoxState, openAlert: false });
  };
  const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
  const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [updateActionValue, setUpdateActionValue] = useState('');
  const [interfaceDefaultToBeEdited, setInterfaceDefaultToBeEdited] = useState({})
  const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('');
  const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [processMasterValue, setProcessMasterValue] = useState({ process: '' });
  const [inOutValue, setInOutValue] = useState('');
  const [fileTypeValue, setFileTypeValue] = useState('');
  const currentUser = CommonService.getLoggedInUserInfo();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
  const [showIcon, setShowIcon] = useState(null);
  const [isFileTypeWebService, setIsFileTypeWebService] = useState(false);
  const [timeZoneValue, setTimeZoneValue] = useState({ value: dialogHeader.entity === undefined ? '' : dialogHeader.entity.timeZone });
  const [allowDuplicateToBeEdited, setAllowDuplicate] = useState({ allowDuplicate: true });

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = (value) => {
    setComponentList(title);
    setTabValue(0);
    reset({ keepErrors: true, keepDirty: true, keepIsSubmitted: false, keepTouched: false, keepIsValid: false, keepSubmitCount: false, });
    onClose(value)
  }

  const pageLabelsConstantsAndMessages = {
    name: {
      active: 'active',
      intfId: 'intfId',
      processMaster: 'processMaster',
      frequency: 'frequency',
      updateAction: 'updateAction',
      inOut: 'inOut',
      fileType: 'fileType',
      allowDuplicate: 'allowDuplicate'
    },
  }

  const { handleSubmit, formState: { errors, isDirty, isValid, dirtyFields }, reset, control, getValues, setValue } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: false,
    defaultValues: {
      // active: true,
      intfId: '',
      processMaster: '',
      frequency: '',
      updateAction: '',
      inOut: '',
      fileType: '',
      allowDuplicate: true
    },
  });


  const onSubmit = (data) => {
    data['erpInterfaceHdr'] = { intfHdrId: intfHdrId };
    data['intfId'] = (mode === 'edit') ? valuesforResetting.intfId : '';
    data['createdBy'] = currentUser.userName;
    data['createdTime'] = (mode === 'edit') ? valuesforResetting.createdTime : '';
    data['updatedBy'] = currentUser.userName;
    data['fileType'] = fileTypeValue.value !== undefined ? fileTypeValue.value : '';
    data['updatedTime'] = (mode === 'edit') ? valuesforResetting.updatedTime : '';
    data['updateAction'] = (processMasterValue.process === 'PO PDF' || processMasterValue.process === 'VMI Consumption Report' || processMasterValue.process === 'Invoice' || processMasterValue.process === 'PO Delivery Confirmation') ? '' : data.updateAction.value;
    data['inOut'] = (mode === 'edit') ? (valuesforResetting.inOut === undefined ? '' : valuesforResetting.inOut) : (data.inOut !== undefined && data.fileType !== null ? data.inOut.value : '');
    data['organization'] = (valuesTobeEdited.organization === undefined) ? '' : { erpOrgCode: valuesTobeEdited.organization.erpOrgCode };
    data['entity'] = (valuesTobeEdited.entity === undefined) ? '' : { erpEntityCode: valuesTobeEdited.entity.erpEntityCode };
    data['frequency'] = resettingValues.frequency === undefined ? '* * * * *' : resettingValues.frequency;
    data['processMaster'] = (mode === 'edit') ? (valuesforResetting.processMaster === undefined) ? '' : { processId: valuesforResetting.processMaster.processId, process: valuesforResetting.processMaster.process } : data.processMaster !== undefined && data.fileType !== null ? { processId: data.processMaster.processId, process: data.processMaster.process } : '';
    data['timeZone'] = timeZoneValue.value;
    data['allowDuplicate'] = allowDuplicateToBeEdited.allowDuplicate;
    setIsFormSubmitted(true);
    CommonService.saveOrUpdateOrPost(`${apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.erpInterfaceHdr}/${intfHdrId}/erpInterfaceDtls`, data)
      .then((response) => {
        setIsFormSubmitted(false)
        setAlertBoxState({
          ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission" defaultMessage="Record updated successfully" />, severity: "success"
        });
        onClose(true);
      })
      .catch(function (error) {
        setIsFormSubmitted(false);
        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
      });
  }

  const onError = errors => {
    setAlertBoxState({ ...alertBoxState, openAlert: true, message: "The Required Fields Cannot be Empty", severity: "error", err: true }
    )
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function InOutValueReset() {
    if (resettingValues.inOut === 'IN') {
      return 'Download from ERP'
    } else if (resettingValues.inOut === 'OUT') {
      return 'Upload to ERP'
    }
  }

  function resettingErpInterfaceDetails(resetValuesForDtl) {
    reset({
      intfId: resetValuesForDtl.intfId === undefined ? '' : resetValuesForDtl.intfId,
      frequency: resetValuesForDtl.frequency !== undefined ? resetValuesForDtl.frequency : '',
      processMaster: resetValuesForDtl.processMaster === undefined ? [] : { process: resetValuesForDtl.processMaster.process },
      inOut: resetValuesForDtl.inOut === undefined ? [] : { value: resetValuesForDtl.inOut },
      updateAction: resetValuesForDtl === undefined ? [] : { value: resetValuesForDtl.updateAction },
      timeZone: resetValuesForDtl.timeZone !== undefined ? { value: resetValuesForDtl.timeZone } : '',
      allowDuplicate: resetValuesForDtl.allowDuplicate !== undefined ? resetValuesForDtl.allowDuplicate : '',
    })
    setUpdateActionValue({ value: (resetValuesForDtl.updateAction !== undefined ? resetValuesForDtl.updateAction : '') });
    setProcessMasterValue(resetValuesForDtl.processMaster === undefined ? [] : { process: resetValuesForDtl.processMaster.process });
    setFileTypeValue({ value: (resetValuesForDtl.fileType === undefined ? [] : resetValuesForDtl.fileType) });
    setInOutValue({ displayName: (resetValuesForDtl.inOut !== undefined ? InOutValueReset() : '') });
    setTimeZoneValue(resetValuesForDtl.timeZone !== undefined ? { value: resetValuesForDtl.timeZone } : '');
    setAllowDuplicate({ allowDuplicate: resetValuesForDtl.allowDuplicate !== undefined ? resetValuesForDtl.allowDuplicate : '' });
  }

  useEffect(() => {
    let currentRouterComponent;
    if (mode === 'create') {
      setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
      isRestoreButtonDisabled(false);
      setIsSaveButtonDisabled(false);
      currentRouterComponent = [
        {
          path: null,
          name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
          iconName: CreateIcon,
        }
      ]

    } else if (mode === 'edit') {
      setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
      isRestoreButtonDisabled(false);
      resettingErpInterfaceDetails(resettingValues)
      currentRouterComponent = [
        {
          path: null,
          name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
          iconName: EditIcon,
        }
      ]
      setinterfaceDetailReadOnlyFields({
        ...erpIinterfaceDetailReadOnlyFields,
        intfId: true,
        processMaster: true,
        inOut: true,
      })


    } else {
      setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
      isRestoreButtonDisabled(true);
      setIsSaveButtonDisabled(true)
      resettingErpInterfaceDetails(resettingValues)
      currentRouterComponent = [
        {
          path: null,
          name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
          iconName: ViewIcon,
        }
      ]
      setinterfaceDetailReadOnlyFields({
        ...erpIinterfaceDetailReadOnlyFields,
        intfId: true,
        active: true,
        processMaster: true,
        frequency: true,
        updateAction: true,
        inOut: true,
        fileType: true
      })
    }
    setComponentList(title.concat(currentRouterComponent !== undefined ? currentRouterComponent : []));
  }, [mode]);

  useEffect(() => {
    setInterfaceDefaultToBeEdited(valuesforResetting);
  }, [valuesforResetting]);

  function resetAllClearThePageInfo() {
    if (mode === 'edit') {
      resettingErpInterfaceDetails(resettingValues)
      setInterfaceDefaultToBeEdited({ ...valuesforResetting })
      setResettingValues({ ...resettingValues, frequency: previousFrequency });
      setTabValue(0);
      setTabValue(0)
    } else {
      reset({
        active: true,
        intfId: '',
        processMaster: '',
        frequency: '',
        updateAction: '',
        inOut: '',
        fileType: '',
        allowDuplicate: true
      });
      setUpdateActionValue([]);
      setProcessMasterValue([]);
      setFileTypeValue([]);
      setInOutValue([])
      setTimeZoneValue({ value: dialogHeader.entity === undefined ? '' : dialogHeader.entity.timeZone });
      setResettingValues({ frequency: '* * * * *' })
      setInterfaceDefaultToBeEdited({ ...valuesforResetting });
      setTabValue(0)
      setTabValue(0);
      setAllowDuplicate()
    }
  }
  const resetComboValueForProcessName = () => {
    reset({
      active: true,
      fileType: '',
      inOut: '',
      updateAction: '',
    });
    setFileTypeValue([]);
    setInOutValue([]);
    setUpdateActionValue([])
  }

  const resetComboValueForInOut = () => { }

  const validateRequiredFields = ((processMasterValue.process === 'PO PDF' || processMasterValue.process === 'VMI Consumption Report' || processMasterValue.process === 'Invoice' || processMasterValue.process === 'PO Delivery Confirmation' || allowDuplicateToBeEdited !== undefined && allowDuplicateToBeEdited.allowDuplicate === false)) ?
    (!(getValues('processMaster') !== '' && getValues('inOut') !== '' && getValues('fileType') !== '') || (processMasterValue === '') ||
      (inOutValue === '') || (fileTypeValue === '')) : (isFileTypeWebService) ? (!(getValues('processMaster') !== '' && getValues('inOut') !== '' && getValues('updateAction') !== '') || (processMasterValue === '') || (inOutValue === '')) || (updateActionValue === 'F') :
      (!(getValues('processMaster') !== '' && getValues('inOut') !== '' && getValues('fileType') !== '' && getValues('updateAction') !== '') ||
        (processMasterValue === '') || (inOutValue === '') || (fileTypeValue === '') || (updateActionValue === ''))

  useEffect(() => {
    if (!(mode === 'view')) {
      if ((mode === 'create') && (validateRequiredFields)) {
        setIsSaveButtonDisabled(true);
      } else if ((mode === 'create') && (!validateRequiredFields)) {
        setIsSaveButtonDisabled(false)
      } else if (((mode === 'edit') && (isDirty) && !validateRequiredFields || (timeZoneValue.value !== interfaceDefaultToBeEdited.timeZone))) {
        setIsSaveButtonDisabled(false);
      } else {
        setIsSaveButtonDisabled(true);
      }
    }
  }, [processMasterValue, inOutValue, fileTypeValue, updateActionValue, tabvalue, mode, isValid, interfaceDefaultToBeEdited, resettingValues, timeZoneValue, allowDuplicateToBeEdited])

  useEffect(() => {
    if (valuesTobeEdited.intfType === "WEBSERVICE") {
      setIsFileTypeWebService(true)
    }
  }, [valuesTobeEdited])

  const interfaceActionProperty = {
    name: pageLabelsConstantsAndMessages.name.updateAction,
    isRequired: (processMasterValue.process === 'PO PDF' || processMasterValue.process === 'VMI Consumption Report' || processMasterValue.process === 'Invoice' || processMasterValue.process === 'PO Delivery Confirmation') && allowDuplicateToBeEdited.allowDuplicate === false ? false : true,
    label: intl.formatMessage({ id: "common.interfaceAction.label" }),
    isAutofocus: false,
    isDisabled: (mode === 'view' || (mode === 'edit') && processMasterValue.process === 'PO PDF' || processMasterValue.process === 'VMI Consumption Report' || processMasterValue.process === 'Invoice' || processMasterValue.process === 'PO Delivery Confirmation') || allowDuplicateToBeEdited !== undefined && allowDuplicateToBeEdited.allowDuplicate === false ? true : false,
    variant: 'outlined',
    isHookFormType: true,
    errors: allowDuplicateToBeEdited !== undefined && allowDuplicateToBeEdited.allowDuplicate === true ? errors.updateAction : null,
  }

  const processMasterComboProperty = {
    name: pageLabelsConstantsAndMessages.name.processMaster,
    isRequired: true,
    label: intl.formatMessage({ id: "columnname.processMaster.label" }),
    isAutofocus: true,
    isDisabled: (mode === 'edit' || mode === 'view') ? true : false,
    variant: 'outlined',
    isHookFormType: true,
    errors: errors.processMaster,
  }

  const inOutComboProperty = {
    name: pageLabelsConstantsAndMessages.name.inOut,
    isRequired: true,
    label: intl.formatMessage({ id: "columnname.inout.label" }),
    isAutofocus: false,
    isDisabled: (mode === 'edit' || mode === 'view') ? true : false,
    variant: 'outlined',
    isHookFormType: true,
    errors: errors.inOut,
  }

  const fileTypeComboProperty = {
    name: pageLabelsConstantsAndMessages.name.fileType,
    isRequired: isFileTypeWebService ? false : true,
    label: intl.formatMessage({ id: "columnname.fileType.label" }),
    isAutofocus: false,
    isDisabled: (mode === 'view' || isFileTypeWebService) ? true : false,
    variant: 'outlined',
    isHookFormType: true,
    errors: errors.fileType,
    isFileTypeWebService: isFileTypeWebService,
  }

  useEffect(() => {
    if (mode === 'create' || mode === 'edit') {
      setShowIcon(
        <Tooltip title={<FormattedMessage id="common.errorIcon.label" />}>
          <ErrorOutlineIcon className={classes.tabIconStyling} fontSize='small' />
        </Tooltip>
      )
    } else {
      setShowIcon(null)
    }
  }, [mode])


  const [orgName, setOrgName] = useState(dialogHeader)
  const [entityName, setEntityName] = useState(dialogHeader)

  useEffect(() => {
    if ((mode === 'edit') || (mode === 'create') || (mode === 'view')) {
      setOrgName(dialogHeader.organization.erpOrgCode)
      setEntityName(dialogHeader.entity.erpEntityCode)
    }
  }, [dialogHeader])

  return (
    <React.Fragment>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseAlert} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Grid container className={classes.root}>
        <Grid item container justifyContent='center' alignItems='center' xs={12} sm={12} md={12} lg={12}>
          <Dialog
            fullWidth
            maxWidth='lg'
            aria-labelledby="create-or-delete-dialog"
            open={open}>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={openAlert}
              onClose={handleCloseAlert}
              key={vertical + horizontal}
              autoHideDuration={6000}
            >
              <Alert onClose={handleCloseAlert} severity={severity}>
                {message}
              </Alert>
            </Snackbar>
            <DialogTitle className={classes.titleCommonDialogComponent} >
              <Grid item container alignItems='center' item xs={12} sm={12} md={12} lg={12} style={{ marginLeft: '-0.3%' }} >
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <CustomBreadCrumb componentList={componentList} isDialog={true} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={12} sm={6} md={6} lg={6} style={{ color: '#fff', fontSize: '14px' }} >
                  {orgName} | {entityName}
                </Grid>
              </Grid>

            </DialogTitle>

            <DialogContent dividers style={mobileScreen ? { minHeight: '70vh' } : { minHeight: '60vh' }} >
              <AppBar position="sticky" color="inherit" elevation={2} >
                <Grid item container alignItems="flex-start" xs={12} sm={12} md={12} lg={12}>
                  <Tabs
                    value={tabvalue}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    indicatorColor="primary"
                    scrollButtons
                    textColor="secondary"
                    variant={mobileScreen ? "scrollable" : "fullWidth"}
                    aria-label="accessible tabs"
                    style={{ width: '100%' }}
                  >
                    <CommonService.ErrorTab className={`${classes.tabtextstyling}`} label={<FormattedMessage id="tabname.interfacedetails" />}{...a11yProps(0)}
                      icon={validateRequiredFields ? showIcon : setShowIcon} iconPosition="end" />
                    <Tab className={`${classes.tabtextstyling}`} label={<FormattedMessage id="columnname.frequency.label" />} {...a11yProps(1)} />
                  </Tabs>
                </Grid>
              </AppBar>
              <Grid container className={classes.root}>
                <form className={classes.form}>
                  <TabPanel value={tabvalue} index={0}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                      <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                          <FormControl fullWidth>
                            <Controller
                              control={control}
                              name={pageLabelsConstantsAndMessages.name.intfId}
                              rules={{
                                maxLength: 100,
                              }}
                              render={({ field }) => (
                                <TextField
                                  className={classes.textField}
                                  variant="outlined"
                                  margin="normal"
                                  id="intfId"
                                  size='small'
                                  label={intl.formatMessage({ id: "columnname.interfaceId.label" })}
                                  {...field}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    readOnly: erpIinterfaceDetailReadOnlyFields.intfId,
                                  }}
                                  disabled
                                />
                              )}

                            />
                            <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={12} md={12} lg={12}>
                              <FormHelperText><FormattedMessage id="texfield.placeholder.interfaceId.label"
                                defaultMessage="Auto Generated by the System" /></FormHelperText>
                            </Grid>
                          </FormControl>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                          <ProcessNameCombo
                            comboProperty={processMasterComboProperty}
                            control={control} errors={errors}
                            comboValue={processMasterValue}
                            setComboValue={setProcessMasterValue}
                            intfHdrId={intfHdrId}
                            resetComboValueForProcessName={resetComboValueForProcessName}
                            bpTypeValueCombo={''} />
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                          <InOutCombo
                            comboProperty={inOutComboProperty}
                            control={control} errors={errors}
                            comboValue={inOutValue}
                            setComboValue={setInOutValue}
                            processMasterValue={processMasterValue}
                            resetComboValueForInOut={resetComboValueForInOut} />
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4}>
                          <FileTypeCombo
                            comboProperty={fileTypeComboProperty}
                            control={control} errors={errors}
                            comboValue={fileTypeValue}
                            setComboValue={setFileTypeValue}
                            processMasterValue={processMasterValue} />
                        </Grid>
                        {(processMasterValue.process === 'PO PDF' || processMasterValue.process === 'VMI Consumption Report' || processMasterValue.process === 'Invoice' || processMasterValue.process === 'PO Delivery Confirmation') ? null :
                          <Grid item container xs={12} sm={6} md={4} lg={2} >
                            <Grid container justifyContent='flex-start' alignItems='center'>
                              <Controller
                                render={({
                                  field: { onChange } }) => (<Switch
                                    color='primary'
                                    checked={allowDuplicateToBeEdited !== undefined ? allowDuplicateToBeEdited.allowDuplicate : true}
                                    onChange={((event) => {
                                      onChange(!(allowDuplicateToBeEdited !== undefined ? allowDuplicateToBeEdited.allowDuplicate : true));
                                      setAllowDuplicate({ ...allowDuplicateToBeEdited, allowDuplicate: event.target.checked })
                                    })}
                                  />)}
                                type="checkbox"
                                name={pageLabelsConstantsAndMessages.name.allowDuplicate}
                                control={control}
                              />
                              <InputLabel>{<FormattedMessage id="common.allowDuplicate.lable" />}</InputLabel>
                            </Grid>
                          </Grid>}
                        {(processMasterValue.process === 'PO PDF' || processMasterValue.process === 'VMI Consumption Report' || processMasterValue.process === 'Invoice' || processMasterValue.process === 'PO Delivery Confirmation') ? null :
                          <Grid item container xs={12} sm={6} md={4} lg={3}>
                            <InterfaceActionCombo comboProperty={interfaceActionProperty} control={control} errors={errors} comboValue={updateActionValue} setComboValue={setUpdateActionValue} processMasterValue={processMasterValue} />
                          </Grid>
                        }
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing} >
                          <Grid item container xs={12} sm={6} md={4} lg={4}>
                            <Grid container justifyContent='flex-start' alignItems='center' className={classes.gridSpacing}>
                              <Controller
                                render={({
                                  field: { onChange } }) => (<Switch
                                    color='primary'
                                    checked={interfaceDefaultToBeEdited.active !== undefined ? interfaceDefaultToBeEdited.active : true}
                                    onChange={((event) => {
                                      onChange(!(interfaceDefaultToBeEdited.active !== undefined ? interfaceDefaultToBeEdited.active : true));
                                      setInterfaceDefaultToBeEdited({ ...interfaceDefaultToBeEdited, active: event.target.checked })
                                    })}
                                    disabled={mode === 'view' ? true : false}
                                  />)}
                                type="checkbox"
                                name={pageLabelsConstantsAndMessages.name.active}
                                control={control}
                                defaultValue={interfaceDefaultToBeEdited.active !== undefined ? interfaceDefaultToBeEdited.active : true}

                              />
                              <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabvalue} index={1}>
                    <CustomSchedulerTab control={control} errors={errors} setMessageConfigDtlObjectToBeEditedOrView={setResettingValues} messageConfigDtlObjectToBeEditedOrView={resettingValues}
                      mode={mode} reset={reset} setMsgConfigDtlToBeEdited={setInterfaceDefaultToBeEdited} setValue={setValue} setTimeZoneValue={setTimeZoneValue} timeZoneValue={timeZoneValue} />
                  </TabPanel>
                </form>
              </Grid>
            </DialogContent>

            {!mobileScreen && (
              <DialogActions className={classes.dialogAction} >
                <Grid container justifyContent='flex-start' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing}>
                  <InfoIcon color="primary" />
                  <Typography justifyContent="flex-start" style={{ fontSize: 12, marginLeft: '1%' }}><FormattedMessage id="textfield.placeholder.dialogActions" defaultMessage="All the '*' marked fields are mandatory to submit the form" /></Typography>
                </Grid>
                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.commonDialogButtonSpacing} >
                  <Button size='small' variant="contained" color="primary" className={classes.cancelbuttonSpacing} elevation={2} endIcon={<ClearIcon />}
                    onClick={() => handleClose(false)}
                  ><FormattedMessage id="common.button.cancel.lable" defaultMessage="Cancel" />
                  </Button>

                  <Button size='small' variant="contained" color="secondary" className={classes.stateButton}
                    disabled={disableRestoreButton} elevation={2} endIcon={<IconRestore />} onClick={(() => { resetAllClearThePageInfo() })}
                  > <FormattedMessage id="common.button.reset.lable" defaultMessage="Restore" />
                  </Button>

                  <Button size='small'
                    variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                    disabled={isSaveButtonDisabled || isFormSubmitted}
                    endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : <IconSaveOrUpdate />}
                    onClick={handleSubmit(onSubmit, onError)}
                  > {saveOrUpdateButtonLabel} </Button>

                </Grid>

              </DialogActions>)}

            {mobileScreen && (<Grid container justifyContent='center' alignItems='center' item xs={12} sm={12} md={12} lg={12}>
              <DialogActions className={classes.dialogAction}>
                <Grid container justifyContent='flex-end' alignItems='center' item xs={12} sm={12} md={12} lg={12} className={classes.topGrid} >
                  <Tooltip title={<FormattedMessage id="common.button.cancel.lable" />}>
                    <Button size='small' variant="contained" color="primary" className={classes.stateButtonForBack} elevation={2}
                      onClick={(() => { handleClose(true) })}>
                      <ClearIcon />
                    </Button>
                  </Tooltip>

                  <Tooltip title={<FormattedMessage id="common.button.reset.lable" />}>
                    <Button size='small' variant="contained" color="secondary" className={classes.stateButtonToRestore} elevation={2}
                      onClick={(() => { resetAllClearThePageInfo() })}>
                      <IconRestore />
                    </Button>
                  </Tooltip>
                  <Tooltip title={mode === 'create' ? <FormattedMessage id="common.button.save" /> : <FormattedMessage id="common.button.update" />}>

                    <Button size='small'
                      variant="contained" color="primary" className={classes.stateButtonForSave} elevation={2}
                      endIcon={isFormSubmitted ? <CircularProgress color="secondary" size={20} /> : null}
                      disabled={isSaveButtonDisabled || isFormSubmitted}
                      onClick={handleSubmit(onSubmit, onError)}
                    >    <IconSaveOrUpdate /> </Button>
                  </Tooltip>

                </Grid>
              </DialogActions>
            </Grid>)}
          </Dialog>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default InterfaceDialogComponent;