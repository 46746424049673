
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Button, CssBaseline, FormControl, FormHelperText, Grid,
    Paper, Snackbar, Switch, TextField, InputLabel, ListItem, List, Card, useTheme, useMediaQuery, CircularProgress
} from '@mui/material';
import Alert from '@mui/lab/Alert';
import CustomBreadCrumb from '../CustomBreadCrumb/CustomBreadCrumb';
import CreateIcon from '@mui/icons-material/Add';
import IconSaveOrUpdate from '@mui/icons-material/SaveOutlined';
import IconRestore from '@mui/icons-material/AutorenewOutlined';
import IconBack from '@mui/icons-material/BackspaceOutlined';
import _ from "lodash/fp";
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import IconSettings from '@mui/icons-material/Settings';
import CommonBackDrop from '../BackDrop'
import useStyles from '../CommonStyle/CommonStyle';
import { LoggedInUserDetails } from '../LoggedInUserDetails/LoggedInUserDetails';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconCustomerGroup from '@mui/icons-material/GroupWork';
import IconSupplierGroup from '@mui/icons-material/SupervisedUserCircle';
import { CommonService } from '../../../services';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';
import { GlobalEdiApiConstants } from "../../../Constants/GlobalEdiApiConstants";

function not(a, b) {

    return a.filter((value) => (b.findIndex(item => item.bpCode === value.bpCode)) === -1);
}

function intersection(a, b) {

    return a.filter((value) => (b.findIndex(item => item.bpCode === value.bpCode)) !== -1);
}

function union(a, b) {

    return [...a, ...not(b, a)];
}

export default function CreateOrEditBpGroup(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const isScreenSizeSmall = useMediaQuery(theme.breakpoints.down('lg'));
    const commonPatterns = GlobalEdiApiConstants();
    const { mode, bpType, supplierOrCustomerGroupId } = props.location.state;

    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const userModuleAccess = loggedInUserInfo.roleModuleAccess['common.module.breadcrumb.user'];
    const [saveOrUpdateButtonLabel, setSaveOrUpdateButtonLabel] = useState('Save');
    const [componentList, setComponentList] = useState([]);
    const [disableRestoreButton, isRestoreButtonDisabled] = useState(false);
    const [disableUpdateButton, isUpdateButtonDisabled] = useState(false)
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [supplierOrCustomerToBeEdited, setSupplierOrCustomerToBeEdited] = React.useState({});
    const currentUser = CommonService.getLoggedInUserInfo();
    const [supplierOrCustomerObjectToBeEditedOrView] = useState([]);
    const [isBpGroupMappingLoading, setBpGroupMappingLoading] = useState(false);
    const [bpList, setBpList] = useState([]);
    const [isTransferButtonsDisabled, setTransferButtonsDisabled] = useState(false);
    const [bpGroupAccessLoading, setBpGroupAccessLoading] = useState(false);
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [bpGroupTobeEdited, setBpGroupTobeEdited] = useState({});
    const [restorForEditMode, isRestorForEditMode] = useState(false);
    const [orgInfoOfLeggedUser] = useState(CommonService.getOrganizationOfLoggedInUser());
    const [defaultOrgObj, setDefaultOrgObj] = useState({});
    const [disableListItem, isListItemDisabled] = useState(false);
    const [availableSupplier, setAvailableSuppliers] = useState('Available Suppliers');
    const [groupedSupplier, setGroupedSuppler] = useState('Groupped Suppliers');
    const [warningMessage, setWarningMessage] = useState('')
    useEffect(() => {
        if (orgInfoOfLeggedUser.erpOrgCode && mode === 'create') {
            setDefaultOrgObj({ erpOrgCode: orgInfoOfLeggedUser.erpOrgCode, orgName: orgInfoOfLeggedUser.orgName, valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}` });
        }
    }, [orgInfoOfLeggedUser])

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const [userReadOnlyFields, setUserReadOnlyFields] = useState({
        groupName: false,
        groupDescription: false,
        orgCode: false,
        bpType: false,
        active: false,
    });

    useEffect(() => {
        if (mode === 'view') {
            isRestoreButtonDisabled(true);
            isListItemDisabled(true);
            isUpdateButtonDisabled(true);
            setTransferButtonsDisabled(false);
            setUserReadOnlyFields({
                ...userReadOnlyFields, groupName: true, groupDescription: true, orgCode: true, bpType: true, active: true
            })
        }
    }, [mode])

    const classess = useStyles();
    const pageLabelsConstantsAndMessages = {
        label: {
            backButton: 'Back',
            restoreButton: 'Reset',
            groupName: 'Group Name',
            groupDescription: 'Group Description',
        },
        name: {
            groupName: 'groupName',
            groupDescription: 'groupDescription',
            orgCode: 'organization',
            bpType: 'bpType',
            active: 'active'
        },
    }

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const handleToggle = (value) => () => {
        const currentIndex = checked.findIndex((column) => column.bpCode === value.bpCode);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };


    const { handleSubmit, formState: { errors }, reset, control, setValue } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
        defaultValues: {
            groupName: '',
            groupDescription: '',
            active: true,
            organization: ''
        },
    });

    useEffect(() => {
        setBpGroupTobeEdited(supplierOrCustomerToBeEdited);
    }, [supplierOrCustomerToBeEdited]);

    useEffect(() => {
        setOpenBackDrop(true);
        let currentRouterComponent = []
        setValue(`${pageLabelsConstantsAndMessages.name.bpType}`, bpType, { shouldValidate: true, shouldDirty: true });
        if (bpType === 'SUPPLIER') {
            setAvailableSuppliers(<FormattedMessage id="availablebusinesspartner.label" defaultMessage="Available Suppliers" />)
            setGroupedSuppler(<FormattedMessage id="grouppedbusinesspartner.label" defaultMessage="Grouped Suppliers" />);
            setWarningMessage(<FormattedMessage id="warningmessagegroupedsupplier.label" defaultMessage="Grouped Suppliers should be greater than 1" />)
            currentRouterComponent = [{
                path: '/suppliergroup',
                name: <FormattedMessage id="common.module.breadcrumb.columnname.suppliergroup" defaultMessage="Supplier Group" />,
                iconName: IconSupplierGroup,
            }]
        } else {
            setAvailableSuppliers(<FormattedMessage id="availablecustomers.label" defaultMessage="Available Customers" />)
            setGroupedSuppler(<FormattedMessage id="groupedcustomers.label" defaultMessage="Grouped Customers" />)
            setWarningMessage(<FormattedMessage id="warningmessagegroupedcustomer.label" defaultMessage="Grouped Customers should be greater than 1" />)
            currentRouterComponent = [{
                path: '/customergroup',
                name: <FormattedMessage id="common.module.breadcrumb.columnname.customergroup" defaultMessage="Customer Group" />,
                iconName: IconCustomerGroup,
            }]
        }
        if ((mode === 'create')) {
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.save" defaultMessage="Save" />);
            isRestoreButtonDisabled(false);
            currentRouterComponent = currentRouterComponent.concat([
                {
                    path: null,
                    name: <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />,
                    iconName: CreateIcon,
                }]);
            fetchSupplier();
            setOpenBackDrop(false);
        } else if (mode === 'read') {
            isRestoreButtonDisabled(true);
            setTransferButtonsDisabled(false);
            setUserReadOnlyFields({
                ...userReadOnlyFields, groupName: true, groupDescription: true, orgCode: true, bpType: true, active: true
            })
            resetValuesEmail(supplierOrCustomerToBeEdited);

        } else {
            if (mode === 'edit' && supplierOrCustomerGroupId > 0) {
                isRestoreButtonDisabled(false);
                setUserReadOnlyFields({
                    ...userReadOnlyFields, groupName: false, groupDescription: false, orgCode: true, bpType: true
                })
                currentRouterComponent = currentRouterComponent.concat([
                    {
                        path: null,
                        name: <FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />,
                        iconName: EditIcon,
                    }]);
                resetValuesEmail(supplierOrCustomerToBeEdited);
            } else {
                isUpdateButtonDisabled(true);
                currentRouterComponent = currentRouterComponent.concat([
                    {
                        path: null,
                        name: <FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage="View" />,
                        iconName: ViewIcon,
                    }]);
                resetValuesEmail(supplierOrCustomerToBeEdited);
            }
            setSaveOrUpdateButtonLabel(<FormattedMessage id="common.button.update" defaultMessage="Update" />);
            CommonService.getByIdApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.BpGroupMaster, supplierOrCustomerGroupId)
                .then((response) => {
                    setSupplierOrCustomerToBeEdited(response.data);
                    resetValuesEmail(response.data);
                    setOpenBackDrop(false);
                    fetchSupplier();
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error, severity: "error" });
                    setOpenBackDrop(false);
                });
        }
        setComponentList([
            {
                path: null,
                name: <FormattedMessage id="common.menu.breadcrumb.settings" defaultMessage="Settings" />,
                iconName: IconSettings,
            }
        ].concat(currentRouterComponent))
    }, [mode, bpType, restorForEditMode]);

    function resetValuesEmail(responseData) {
        reset({
            organization: responseData.organization !== undefined ? { erpOrgCode: responseData.organization.erpOrgCode, orgName: responseData.organization.orgName } : defaultOrgObj,
            bpType: responseData.bpType !== undefined ? responseData.bpType : '',
            groupName: responseData.groupName !== undefined ? responseData.groupName : '',
            active: responseData.active !== undefined ? responseData.active : true,
            groupDescription: responseData.groupDescription !== undefined ? responseData.groupDescription : '',

        });
    }

    useEffect(() => {
        if (mode === 'create') {
            resetValuesEmail({ ...supplierOrCustomerToBeEdited, bpType: bpType });
        }
    }, [defaultOrgObj])

    const onSubmit = data => {
        data['createdBy'] = (mode === 'create') ? currentUser.userName : supplierOrCustomerObjectToBeEditedOrView.createdBy;
        data['createdTime'] = (supplierOrCustomerToBeEdited.createdTime === undefined) ? '' : supplierOrCustomerToBeEdited.createdTime;
        data['updatedTime'] = (supplierOrCustomerToBeEdited.updatedTime === undefined) ? '' : supplierOrCustomerToBeEdited.updatedTime;
        data['updatedBy'] = currentUser.userName;
        data['groupId'] = supplierOrCustomerGroupId;
        data['organization'] = (supplierOrCustomerToBeEdited.organization === undefined) ? { erpOrgCode: orgInfoOfLeggedUser.erpOrgCode } : supplierOrCustomerToBeEdited.organization;
        setOpenBackDrop(true)
        if (right.length > 1) {
            CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.BpGroupMaster, data)
                .then((response) => {
                    setOpenBackDrop(false);
                    createOrUpdateBpGroupMapping(response.data.groupId);
                })
                .catch(function (error) {
                    setOpenBackDrop(false);
                    setBpGroupMappingLoading(false);
                    let message = (error.response.data !== undefined || error.response.data !== null) ? error.response.data.message
                        : "Error occurred while Save/Update!";
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: message, severity: "error" });
                });
        } else {
            setOpenBackDrop(false);
            setAlertBoxState({ ...alertBoxState, openAlert: true, message: (warningMessage), severity: "warning" });
        }
    }

    const numberOfChecked = (items) => intersection(checked, items).length;


    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }

    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    useEffect(() => {
        setLeft(not(bpList, right));
    }, [right, bpList]);

    const [paramBodyForFetchSupplier, setParamBodyForFetchSupplier] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.bpCustomerAndSupplier);

    useEffect(() => {
        setParamBodyForFetchSupplier({ ...paramBodyForFetchSupplier, sortDir: 'asc', sortKey: bpType === 'Supplier' ? 'bpSupplierName' : 'bpCustomerName', loadAllData: true, recordsPerPage: 1000 });
    }, []);

    const fetchSupplier = () => {
        setBpGroupMappingLoading(true);
        setBpList([]);
        let bpApiUrl;
        let sortKeyToBeApplied;
        if (bpType === 'Supplier') {
            bpApiUrl = apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.supplier;
            sortKeyToBeApplied = 'bpSupplierName'
        } else {
            bpApiUrl = apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.customer;
            sortKeyToBeApplied = 'bpCustomerName'
        }
        setBpGroupAccessLoading(true);
        CommonService.GetListApi(bpApiUrl, paramBodyForFetchSupplier)
            .then((response) => {
                setBpGroupAccessLoading(false);
                setBpGroupMappingLoading(false);
                let supplierOrCustomerList = response.data.content.map((supplierOrCustomer) => {
                    return {
                        bpGroupMappingId: 0,
                        bpCode: supplierOrCustomer.bpCode,
                        bpGroupMaster: {
                            groupId: supplierOrCustomerGroupId
                        },
                        organization: {
                            erpOrgCode: supplierOrCustomer.organization.erpOrgCode
                        },
                        createdBy: '',
                        createdTime: null,
                        bpSupplierName: supplierOrCustomer.bpSupplierName,
                        bpCustomerName: supplierOrCustomer.bpCustomerName,
                        active: supplierOrCustomer.active
                    }
                });
                setBpList(supplierOrCustomerList);
            })
            .catch(function (error) {
                setBpGroupAccessLoading(false);
                setBpGroupMappingLoading(false);
                let message = (error.response.data !== undefined || error.response.data !== null) ? error.response.data.message
                    : "Error occurred while Loading Bp Group List!";
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: message, severity: "error" });
            });
    }

    const [paramBodyForBpGroupMapping, setParamBodyForBpGroupMapping] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.BpGroupMapping);

    useEffect(() => {
        setParamBodyForBpGroupMapping({ ...paramBodyForBpGroupMapping, body: { bpGroupMaster: { groupId: supplierOrCustomerGroupId } }, sortDir: 'asc', });
    }, [supplierOrCustomerGroupId]);

    useEffect(() => {
        if (paramBodyForBpGroupMapping.body.bpGroupMaster.groupId !== '' && paramBodyForBpGroupMapping.body.bpGroupMaster.groupId > 0) {
            fetchBpGroupMappingList();
        }
    }, [paramBodyForBpGroupMapping, restorForEditMode])

    const fetchBpGroupMappingList = () => {
        setRight([]);
        setBpGroupAccessLoading(true);
        CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.BpGroupMapping, paramBodyForBpGroupMapping)
            .then((response) => {
                setBpGroupAccessLoading(false);
                let bpGroupMappingList = response.data.content.map((businessPartner) => {
                    return {
                        bpGroupMappingId: businessPartner.bpGroupMappingId,
                        bpCode: businessPartner.bpCode,
                        bpGroupMaster: {
                            groupId: businessPartner.bpGroupMaster.groupId
                        },
                        organization: {
                            erpOrgCode: businessPartner.organization.erpOrgCode
                        },
                        createdBy: businessPartner.createdBy,
                        createdTime: businessPartner.createdTime,
                        bpSupplierName: businessPartner.bpSupplierName,
                        bpCustomerName: businessPartner.bpCustomerName,
                        active: businessPartner.active
                    }
                });
                setRight(bpGroupMappingList);
            })
            .catch(function (error) {
                setBpGroupAccessLoading(false);
                setBpGroupMappingLoading(false);
                let message = (error.response.data !== undefined || error.response.data !== null) ? error.response.data.message
                    : "Error occurred while Loading Bp Group Mapping List!";
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: message, severity: "error" });
            });

    }

    const createOrUpdateBpGroupMapping = (groupId) => {
        right.forEach(element => {
            element.bpGroupMaster.groupId = groupId;
        });
        setOpenBackDrop(true);
        CommonService.saveOrUpdateOrPost(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.BpGroupMapping, right)
            .then((response) => {
                setOpenBackDrop(false);
                setAlertBoxState({
                    ...alertBoxState, openAlert: true, message: <FormattedMessage id="snackbar.recordupdatedsuccessfullypermission"
                        defaultMessage="Record updated successfully" />, severity: "success"
                });
                history.goBack();
            })
            .catch(function (error) {
                let message = (error.response.data !== undefined || error.response.data !== null) ? error.response.data.message
                    : "Error occurred while updating Bp Group List!";
                setOpenBackDrop(false);
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: message, severity: "error" });
            });
    }

    function resetAllClearThePageInfo() {
        isRestorForEditMode(!restorForEditMode);
        setRight([]);
        setDefaultOrgObj({ ...defaultOrgObj, valueToBeDisplayed: `${orgInfoOfLeggedUser.erpOrgCode} -  ${orgInfoOfLeggedUser.orgName}` });
        setBpGroupTobeEdited({ ...supplierOrCustomerToBeEdited, active: true });
    }

    const customList = (title, items, isloading) => (

        <Card className={classes.transferListCard} elevation={1}>
            <Grid item container alignItems="center" sm={12} xs={12} ms={12} lg={12}>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <Checkbox
                            onClick={handleToggleAll(items)}
                            checked={numberOfChecked(items) === items.length && items.length !== 0}
                            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                            disabled={items.length === 0 || isTransferButtonsDisabled || disableListItem}
                            inputProps={{ 'aria-label': 'all items selected' }}
                        />
                    }
                    title={title}
                    subheader={`${numberOfChecked(items)}/${items.length} selected`}
                />
                <Divider />
                <List className={classes.list} dense component="div" role="list">
                    {items.map((element, index) => {
                        const labelId = `transfer-list-all-item-${element.bpSupplierName}-label`;
                        return (
                            <ListItem key={element.bpCode} role="listitem" button onClick={handleToggle(element)}
                                selected={(checked.length > 0) && checked.findIndex(item => item.bpCode === element.bpCode) !== -1} classes={classess} divider disabled={isTransferButtonsDisabled || disableListItem}
                            >
                                <ListItemIcon >
                                    <Checkbox
                                        checked={checked.findIndex(item => item.bpCode === element.bpCode) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={bpType === 'Supplier'
                                    ? `${element.bpSupplierName}`
                                    : `${element.bpCustomerName}`}
                                    secondary={`Bp Code - ${element.bpCode}`} />
                            </ListItem>
                        );
                    })}
                    <ListItem />
                    {isloading && (<Grid item container justifyContent="center" alignItems="center" ><CircularProgress /></Grid>)}
                </List>
            </Grid>
        </Card >
    );


    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootCreateOrEditUser}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={12} md={6} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' alignItems='center' xs={12} sm={12} md={6} lg={6} className={classes.topGrid}>

                    <Button size='small'
                        variant="contained" color="primary" className={classes.stateButtonBack} elevation={2}
                        endIcon={<IconBack />} onClick={history.goBack}
                    > {<FormattedMessage id="common.button.back.lable" defaultMessage={pageLabelsConstantsAndMessages.label.backButton} />} </Button>

                    {!isTransferButtonsDisabled && (
                        <div>
                            <Button size='small' disabled={disableRestoreButton}
                                variant="contained" color="secondary" className={classes.stateButton} elevation={2}
                                endIcon={<IconRestore />} onClick={(() => { resetAllClearThePageInfo() })}
                            > {<FormattedMessage id="common.button.reset.lable" defaultMessage={pageLabelsConstantsAndMessages.label.restoreButton} />} </Button>

                            {(userModuleAccess !== undefined ? userModuleAccess.createFlag : false) && (<Button size='small'
                                variant="contained" color="primary" className={classes.stateButtonSave} disabled={disableUpdateButton} elevation={2}
                                endIcon={<IconSaveOrUpdate />} onClick={handleSubmit(onSubmit)}
                            > {saveOrUpdateButtonLabel} </Button>)}
                        </div>
                    )}

                </Grid>

                <Paper className={classes.paperForCreateOrEditUser} elevation={3}>
                    <form className={classes.formForCreateOrEditUser} onSubmit={handleSubmit(onSubmit)}>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} className={classes.gridSpacing}>
                            <Grid item container xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.groupName}
                                        rules={{
                                            required: true,
                                            pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UHF
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                autoFocus
                                                variant="outlined"
                                                color="primary"
                                                margin="normal"
                                                id="groupName"
                                                size='small'
                                                label={<FormattedMessage id="columnname.groupname.label" defaultMessage="Group Name" />}
                                                {...field}
                                                error={errors.groupName}
                                                required={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: userReadOnlyFields.groupName,
                                                }}
                                                disabled={mode === 'view'}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.groupName}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                {<FormattedMessage id="textfield.error.groupname" defaultMessage=" Group Name is required" />}
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.groupName}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}>{pageLabelsConstantsAndMessages.label.groupName} {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UHF}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>

                            </Grid>
                            <Grid item container xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.groupDescription}
                                        rules={{
                                            required: true,
                                            pattern: commonPatterns.commonTextfeildPattern.patternWithAlphaNum_UCSHF
                                        }}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                color="primary"
                                                margin="normal"
                                                id="addressLine1"
                                                size='small'
                                                label={<FormattedMessage id="textfield.groupdescription.label" defaultMessage="Group Description" />}
                                                {...field}
                                                InputProps={{
                                                    readOnly: userReadOnlyFields.groupDescription,
                                                }}
                                                error={errors.groupDescription}
                                                required={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }
                                                }}
                                                disabled={mode === 'view'}
                                            />
                                        )}
                                    />
                                    <div className={classes.error}>
                                        {_.get(`${pageLabelsConstantsAndMessages.name.groupDescription}.type`, errors) === "required" && (
                                            <FormHelperText className={classes.error}>
                                                {<FormattedMessage id="common.textfield.columname.error.groupdescription" defaultMessage=" Group Description is required" />}
                                            </FormHelperText>
                                        )}
                                        {_.get(`${pageLabelsConstantsAndMessages.name.groupDescription}.type`, errors) === "pattern" && (
                                            <FormHelperText className={classes.error}> {pageLabelsConstantsAndMessages.label.groupDescription} {commonPatterns.commonErrorsForTextFields.errorForAlphaNum_UCSHF}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>

                            </Grid>
                            <Grid item container xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={pageLabelsConstantsAndMessages.name.bpType}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                color="primary"
                                                margin="normal"
                                                id="addressLine1"
                                                size='small'
                                                label={<FormattedMessage id="textfield.label.bptype" defaultMessage="BP Type" />}
                                                {...field}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    readOnly: userReadOnlyFields.bpType,
                                                }}
                                                disabled={true}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} sm={12} md={12} lg={12}>
                            <Grid container justifyContent='flex-start' alignItems='center'>
                                <Controller
                                    render={({
                                        field: { onChange } }) => (<Switch
                                            color='primary'
                                            disabled={userReadOnlyFields.active}
                                            checked={bpGroupTobeEdited.active !== undefined ? bpGroupTobeEdited.active : true}
                                            onChange={((event) => {
                                                onChange(!(bpGroupTobeEdited.active !== undefined ? bpGroupTobeEdited.active : true));
                                                setBpGroupTobeEdited({ ...bpGroupTobeEdited, active: event.target.checked })
                                            })}
                                        />)}
                                    type="checkbox"
                                    name={pageLabelsConstantsAndMessages.name.active}
                                    control={control}
                                    defaultValue={bpGroupTobeEdited.active !== undefined ? bpGroupTobeEdited.active : true}
                                />
                                <InputLabel>{<FormattedMessage id="common.active.lable" defaultMessage="Active" />}</InputLabel>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Paper className={classes.paperForBpGroupMapping} elevation={1}>
                    <Grid container justifyContent="center" className={classes.transferListRoot} xs={12} sm={12} md={12} lg={12}>
                        <Grid item container justifyContent="center" alignItems="flex-start" xs={12} sm={5} md={5} lg={5}  >
                            {customList(availableSupplier, left, isBpGroupMappingLoading)}
                        </Grid>
                        <Grid item container justifyContent="center" alignItems="center" xs={12} sm={2} md={2} lg={2}>
                            <Grid item container direction={isScreenSizeSmall ? "row" : "column"} justifyContent="center" alignItems="center" >
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0 || isTransferButtonsDisabled}
                                    aria-label="move selected right"

                                >
                                    &gt;
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0 || isTransferButtonsDisabled}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="center" alignItems="flex-start" xs={12} sm={5} md={5} lg={5}>
                            {customList(groupedSupplier, right, bpGroupAccessLoading)}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </React.Fragment>
    );
}

