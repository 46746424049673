import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    LicenseInfo, DataGridPro, getGridStringOperators,
    GridToolbarColumnsButton, GridToolbarDensitySelector, getGridDateOperators
} from '@mui/x-data-grid-pro';
import { useHistory } from 'react-router-dom';
import { Badge, Button, CssBaseline, Grid, Paper, Snackbar, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import useStyles from '../common/CommonStyle/CommonStyle';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import IconERPInterfaceStatus from '@mui/icons-material/ListAlt';
import CommonGridPagination from '../common/CommonGridPagination/CommonGridPagination';
import GridTextLocalization from '../common/GridTextLocalization/GridTextLocalization';
import { SupportedLanguageDetails } from '../common/SupportedLanguageDetails/SupportedLanguageDetails';
import { Alert } from "@mui/lab";
import { CommonService } from '../../services/CommonServices.service';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomBreadCrumb from '../common/CustomBreadCrumb/CustomBreadCrumb';
import { CustomFilters } from '../common/CustomFilters/CustomFilters';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';
import IconInterfaceConfiguration from '@mui/icons-material/SwapCalls';
import CustomFilterComponent, { GetFilterableColumns } from '../common/CustomFilterComponent';
import { FilterList } from '@mui/icons-material';
import { LoggedInUserDetails } from '../common/LoggedInUserDetails/LoggedInUserDetails';
import { manageUserPreferences } from '../../services/manageUserPreferences';
import GetAppIcon from '@mui/icons-material/GetApp';
import saveAs from 'file-saver';
import CommonBackDrop from '../common/BackDrop';
import ChipInfoList from '../common/ChipInfoList/ChipInfoList';

const columnFieldsName = {
    process: 'processMaster.process',
    intfType: 'intfType',
    intfDesc: 'intfDesc',
    erpOrgCode: 'organization.erpOrgCode',
    erpEntityCode: 'entity.erpEntityCode',
    status: 'status',
    processedDate: 'processedDate',
    // processedDateTo:'processedDateTo',
    remarks: 'remarks',
    intfId: 'erpInterfaceDtls.intfId',
    fromProcessedDate: 'fromProcessedDate',
    toProcessedDate: 'toProcessedDate',
    orgName: 'organization.orgName',
    entityName: 'entity.entityName',
    messageReference: 'messageReference'
}

function ErpInterfaceStatus(props) {
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [filterCount, setFilterCount] = React.useState(0);
    var defaultEntity = loggedInUserInfo.defaultEntity;

    const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.erpEntityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity }]
        : [];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);
    const localtext = GridTextLocalization();
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [searchedIntfType, setSearchedIntfType] = useState('');
    const [searchedIntfDesc, setSearchedIntfDesc] = useState('');
    const [searchedProcess, setSearchedProcess] = useState('');
    const [searchedEntityCode, setSearchedEntityCode] = useState(defaultEntity);
    const [searchedIntfId, setSearchedIntfId] = useState('');
    const [searchedProcessedDateFrom, setSearchedProcessedDateFrom] = useState('');
    const [searchedProcessedDateTo, setSearchedProcessedDateTo] = useState('');
    const [searchedEntityName, setSearchedEntityName] = useState('');
    const [searchedRemaarks, setSearchedRemaarks] = useState('');
    const [searchedStatus, setSearchedStatus] = useState('');
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [openFilterPanel, setFilterPanel] = useState(false);
    const [chipInfoList, setChipInfoList] = useState([]);
    const handleClose = () => {
        setFilterPanel(false);
    }

    const [reportDownloadPopUp, setReportDownloadPopUp] = useState(false);
    const [keyValueData, setKeyValueData] = useState({});
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [disiablingDownloadButtonForZeroRecords, setDisiablingDownloadButtonForZeroRecords] = useState(false)

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });

    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.interfaceconfiguration" />,
            iconName: IconInterfaceConfiguration,
        },
        {
            path: '/erpInterfaceStatus',
            name: <FormattedMessage id="common.menu.breadcrumb.erpinterfacestatus" defaultMessage="ERP Interface Status" />,
            iconName: IconERPInterfaceStatus,
        }
    ];
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };


    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center'
        }
    };

    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const [tableData, setTableData] = useState([]);

    const [paramBody, setParamBody] = useState(
        apiUrlAndParamBodyConstants.paramBodyForGetListApis.erpInterfaceStatus
    );
    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(
        {
            ...apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference,
            columnsSort: {
                field: "erpInterfaceStatusId",
                sort: "desc"
            }
        });
    const [pinnedColumns, setPinnedColumns] = useState(preference.columnsPinned);
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }


    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);

    const columnsForErpInterfaceStatus = [
        {
            field: columnFieldsName.orgName,
            headerName: intl.formatMessage({ id: "columnname.OrganizationName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 210,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            valueGetter: (params) => `${params.row.organization.orgName}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.erpOrgCode,
            width: 160,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            headerName: intl.formatMessage({ id: "columnname.organizationcode.label" }),
            sortable: true,
            type: 'string',
            hide: true,
            filterable: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            valueGetter: (params) => `${params.row.organization.erpOrgCode}`,
        },
        {
            field: columnFieldsName.entityName,
            headerName: intl.formatMessage({ id: "common.EntityName.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 190,
            sortable: true,
            type: 'string',
            hide: true,
            filterable: true,
            valueGetter: (params) => `${params.row.entity.entityName}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.erpEntityCode,
            headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 85,
            sortable: false,
            type: 'string',
            hide: false,
            valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.process,
            width: 170,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            headerName: intl.formatMessage({ id: "columnname.process.label" }),
            sortable: true,
            type: 'string',
            filterable: true,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            valueGetter: (params) => `${params.row.processMaster.process}`,
        },
        {
            field: columnFieldsName.processedDate,
            headerName: intl.formatMessage({ id: 'columnname.processedDate.label' }),
            width: 200,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'dateTime',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: (cellParams) => (
                <span>{cellParams.row.processedDateDtDisp}</span>
            )
        },
        {
            field: columnFieldsName.intfId,
            width: 160,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            headerName: intl.formatMessage({ id: "columnaname.interfaceid.label" }),
            sortable: true,
            type: 'string',
            filterable: true,
            hide: false,
            valueGetter: (params) => `${params.row.erpInterfaceDtls.intfId}`,
            renderHeader: CommonService.customRenderHeaderColumns,
        },
        {
            field: columnFieldsName.intfType,
            width: 200,
            headerName: intl.formatMessage({ id: "columnname.erpInterfaceType.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            filterable: true,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.intfDesc,
            headerName: intl.formatMessage({ id: "columnname.erpInterfaceDescription.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 250,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.status,
            headerName: intl.formatMessage({ id: 'columnname.status.label' }),
            width: 140,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: true,
            type: 'string',
            filterable: true,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns,
            renderCell: CommonService.customRenderStatusColumnForErpInterface
        },
        {
            field: columnFieldsName.remarks,
            headerName: intl.formatMessage({ id: "columnname.remarks.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            width: 250,
            sortable: true,
            type: 'string',
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.messageReference,
            headerName: intl.formatMessage({ id: 'columnname.messageReference.label' }),
            width: 250,
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
            sortable: true,
            type: 'string',
            filterable: false,
            hide: false,
            renderHeader: CommonService.customRenderHeaderColumns
        },
        {
            field: columnFieldsName.fromProcessedDate,
            width: 0,
            headerName: intl.formatMessage({ id: "columnname.purchaseDateFrom.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: false,
            type: 'date',
            hide: true,
            filterable: true,
        },
        {
            field: columnFieldsName.toProcessedDate,
            width: 0,
            headerName: intl.formatMessage({ id: "columnname.purchaseDateTo.label" }),
            headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
            sortable: false,
            type: 'date',
            hide: true,
            filterable: true,
        }
    ]

    const [columns, setColumns] = useState([]);
    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(columnsForErpInterfaceStatus);
                savePreference("visibility", columnsForErpInterfaceStatus);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, columnsForErpInterfaceStatus);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let process = null;
                let intfId = null;
                let entityCode = null;
                let entityName = null;
                let status = null;
                let intfType = null;
                let intfDesc = null;
                let remarks = null;
                let fromProcessedDate = null;
                let toProcessedDate = null;
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'processMaster.process':
                            process = columns.value
                            break;
                        case 'entity.erpEntityCode':
                            entityCode = columns.value
                            break;
                        case 'entity.entityName':
                            entityName = columns.value
                            break;
                        case 'intfDesc':
                            intfDesc = columns.value
                            break;
                        case 'erpInterfaceDtls.intfId':
                            intfId = columns.value
                            break;
                        case 'intfType':
                            intfType = columns.value
                            break;
                        case 'remarks':
                            remarks = columns.value
                            break;
                        case 'status':
                            status = columns.value
                            break;
                        case 'fromProcessedDate':
                            fromProcessedDate = columns.value
                            break;
                        case 'toProcessedDate':
                            toProcessedDate = columns.value
                            break;
                    }
                })

                setSearchedIntfType(intfType);
                setSearchedIntfDesc(intfDesc);
                setSearchedProcess(process);
                setSearchedEntityCode(entityCode);
                setSearchedIntfId(intfId);
                setSearchedProcessedDateFrom(fromProcessedDate);
                setSearchedProcessedDateTo(toProcessedDate);
                setSearchedEntityName(entityName);
                setSearchedRemaarks(remarks);
                setSearchedStatus(status);
                var filterBody = {
                    status: status === 'Success' ? 0 : status === 'Failure' ? 1 : '',
                    processMaster: {
                        process: process,
                    },
                    erpInterfaceDtls: {
                        intfId: intfId,
                    },
                    intfType: intfType,
                    intfDesc: intfDesc,
                    fromProcessedDate: fromProcessedDate,
                    toProcessedDate: toProcessedDate,
                    entity: { erpEntityCode: entityCode, entityName: entityName },
                    remarks: remarks,
                    recordErrorStatus: status === '' ? 'All' : status === 'Success' ? 0 : status === 'Failure' ? 1 : 'All'
                };
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0) {
                    setSearchedEntityCode(defaultEntity);
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                }
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [selectedLanguage])



    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.erpInterfaceStatus, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [paramBody, refreshTable])


    if (columns.length > 0) {
        const branchNameColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const branchNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const branchNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[branchNameColIndex] = {
            ...branchNameColumn,
            filterOperators: branchNameFilterOperators,
        };

        const orgCodeColumn = columns.find((column) => column.field === columnFieldsName.erpOrgCode);
        const orgCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpOrgCode);

        const orgCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgCodeInputValue,
            }),
        );

        columns[orgCodeColIndex] = {
            ...orgCodeColumn,
            filterOperators: orgCodeFilterOperators,
        };

        const interfaceTypeColumn = columns.find((column) => column.field === columnFieldsName.intfType);
        const interfaceTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.intfType);

        const interfacetypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.InterfaceTypeInputValue,
            }),
        );

        columns[interfaceTypeColIndex] = {
            ...interfaceTypeColumn,
            filterOperators: interfacetypeFilterOperators,
        };

        const interfaceIdColumn = columns.find((column) => column.field === columnFieldsName.intfId);
        const interfaceIdColIndex = columns.findIndex((col) => col.field === columnFieldsName.intfId);

        const interfaceIdFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.IntfIdInputValue,
            }),
        );

        columns[interfaceIdColIndex] = {
            ...interfaceIdColumn,
            filterOperators: interfaceIdFilterOperators,
        };


        const processColumn = columns.find((column) => column.field === columnFieldsName.process);
        const processColIndex = columns.findIndex((col) => col.field === columnFieldsName.process);

        const processFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.ProcessInputValue,
            }),
        );

        columns[processColIndex] = {
            ...processColumn,
            filterOperators: processFilterOperators,
        };

        const fromProcessedDateColumn = columns.find((column) => column.field === columnFieldsName.fromProcessedDate);
        const fromProcessedDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.fromProcessedDate);

        const fromProcessedDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[fromProcessedDateColIndex] = {
            ...fromProcessedDateColumn,
            filterOperators: fromProcessedDateFilterOperators,
        };

        const toProcessedDateColumn = columns.find((column) => column.field === columnFieldsName.toProcessedDate);
        const toProcessedDateColIndex = columns.findIndex((col) => col.field === columnFieldsName.toProcessedDate);

        const toProcessedDateFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[toProcessedDateColIndex] = {
            ...toProcessedDateColumn,
            filterOperators: toProcessedDateFilterOperators,
        };

        const orgNameColumn = columns.find((column) => column.field === columnFieldsName.orgName);
        const orgNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.orgName);

        const orgNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgNameInputValue,
            }),
        );

        columns[orgNameColIndex] = {
            ...orgNameColumn,
            filterOperators: orgNameFilterOperators,
        };

        const entityNameColumn = columns.find((column) => column.field === columnFieldsName.entityName);
        const entityNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.entityName);

        const entityNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityNameInputValue,
            }),
        );

        columns[entityNameColIndex] = {
            ...entityNameColumn,
            filterOperators: entityNameFilterOperators,
        };

        const statusColumn = columns.find((column) => column.field === columnFieldsName.status);
        const statusColIndex = columns.findIndex((col) => col.field === columnFieldsName.status);

        const statusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.StatusColSuccessOrFaliureInputValue,
            }),
        );

        columns[statusColIndex] = {
            ...statusColumn,
            filterOperators: statusFilterOperators,
        };

    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let process = null;
        let intfId = null;
        let entityCode = null;
        let entityName = null;
        let status = null;
        let intfType = null;
        let intfDesc = null;
        let remarks = null;
        let fromProcessedDate = null;
        let toProcessedDate = null;
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'processMaster.process':
                    process = columns.value
                    break;
                case 'entity.erpEntityCode':
                    entityCode = columns.value
                    break;
                case 'entity.entityName':
                    entityName = columns.value
                    break;
                case 'intfDesc':
                    intfDesc = columns.value
                    break;
                case 'erpInterfaceDtls.intfId':
                    intfId = columns.value
                    break;
                case 'intfType':
                    intfType = columns.value
                    break;
                case 'remarks':
                    remarks = columns.value
                    break;
                case 'status':
                    status = columns.value
                    break;
                case 'fromProcessedDate':
                    fromProcessedDate = columns.value
                    break;
                case 'toProcessedDate':
                    toProcessedDate = columns.value
                    break;
            }
        })

        setSearchedIntfType(intfType);
        setSearchedIntfDesc(intfDesc);
        setSearchedProcess(process);
        setSearchedEntityCode(entityCode);
        setSearchedIntfId(intfId);
        setSearchedProcessedDateFrom(fromProcessedDate);
        setSearchedProcessedDateTo(toProcessedDate);
        setSearchedEntityName(entityName);
        setSearchedRemaarks(remarks);
        setSearchedStatus(status);
        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);

        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    status: status === 'Success' ? 0 : status === 'Failure' ? 1 : '',
                    processMaster: {
                        process: process,
                    },
                    erpInterfaceDtls: {
                        intfId: intfId,
                    },
                    intfType: intfType,
                    intfDesc: intfDesc,
                    fromProcessedDate: fromProcessedDate,
                    toProcessedDate: toProcessedDate,
                    entity: { erpEntityCode: entityCode, entityName: entityName },
                    remarks: remarks,
                    recordErrorStatus: status === '' ? 'All' : status === 'Success' ? 0 : status === 'Failure' ? 1 : 'All'
                }
            }
        })
    }
    let filterBodyObj = {
        erpEntityCode: searchedEntityCode === null ? '' : searchedEntityCode,
        entityName: searchedEntityName === '' ? null : searchedEntityName,
        fromProcessedDate: searchedProcessedDateFrom === '' ? null : searchedProcessedDateFrom,
        toProcessedDate: searchedProcessedDateTo === '' ? null : searchedProcessedDateTo,
        intfDesc: searchedIntfDesc === '' ? null : searchedIntfDesc,
        intfType: searchedIntfType === '' ? null : searchedIntfType,
        intfId: searchedIntfId === '' ? null : searchedIntfId,
        process: searchedProcess === '' ? null : searchedProcess,
        status: searchedStatus === '' ? null : searchedStatus,
    }

    useEffect(() => {
        if (totalRowCount === 0) {
            setDisiablingDownloadButtonForZeroRecords(true)
        } else {
            setDisiablingDownloadButtonForZeroRecords(false)
        }
    }, [totalRowCount])

    useEffect(() => {
        let colValDataObj = {}
        for (let key in filterBodyObj) {
            let value = filterBodyObj[key]
            if (filterBodyObj.hasOwnProperty(key) && ((value !== null) && (value !== 0) && (value !== undefined) && (value !== {}))) {
                colValDataObj[key] = value.toString();
            }
            setKeyValueData(colValDataObj)
        }
    }, [searchedEntityCode, searchedProcessedDateFrom, searchedProcessedDateTo, searchedIntfDesc, searchedIntfType, searchedIntfId, searchedProcess, searchedStatus]);

    useEffect(() => {
        if (reportDownloadPopUp === true) {
            setOpenBackDrop(true);
            CommonService.saveOrUpdateOrPost('/reports/SYS_ERP_INTERFACE_STATUS', searchedEntityCode === '' ?
                { erpEntityCode: '' } : keyValueData)
                .then((response) => {
                    if (response.data.sucess === "No records found for given condition")
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.sucess, severity: "error" });
                    else {
                        //  showDownloadPopUpExcelMsgLog(response.data.fileUrl)
                        setAlertBoxState({
                            ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success"
                        });
                    }
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setReportDownloadPopUp(false)
        }
    }, [reportDownloadPopUp, keyValueData]);

    const showDownloadPopUpExcelMsgLog = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }

    useEffect(() => {
        if (intialParamBodyApiCall)
            setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    return (

        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop}/>
            <Grid container className={classes.rootForBranch}>
            <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} /> 
                    <ChipInfoList chipInfoList={chipInfoList}/>   
                    <Paper elevation={3} className={classes.gridHeightAndWidthForErpStatus}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            hideFooter={true}
                            density="compact"
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            localeText={localtext}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'><GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterList />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadExcelForERPStatus" defaultMessage="Download ERP interface status" />}
                                                        placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={() => {
                                                                    setReportDownloadPopUp(true)
                                                                }}
                                                                disabled={disiablingDownloadButtonForZeroRecords}
                                                            >
                                                                <GetAppIcon size={20} />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>

                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} alignItems="center">
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            hideFooterSelectedRowCount={true}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </div>
                </Paper>
            </Grid>
        </React.Fragment>
    );

};


export default ErpInterfaceStatus;