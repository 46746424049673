import { CircularProgress, CssBaseline, FormControl, FormHelperText, Grid, TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import { CommonService } from '../../../../services';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import useStyles from '../../CommonStyle/CommonStyle'
import { FormattedMessage } from 'react-intl';

const EndTimeCombo = (props) => {

    const classes = useStyles();
    const { comboProperty, control, errors, comboValue, setComboValue } = props;
    const [endTimeList, setEndTimeList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const endTimeData = endTimeList.map((messageTransmissionLog) => {
        return { endTime: messageTransmissionLog.endTime }
    });

    const loadEndTimeData = (searchedEndTime) => {
        setLoading(true);
        CommonService.GetListApi('/messageTransmissionLog', {
            body: {
                organization: {
                    erpOrgCode: ''
                },
                entity: {
                    erpEntityCode: '',
                },
                endTime: searchedEndTime,
            },
            pageNo: 0,
            sortDir: 'asc',
            sortKey: 'endTime',
            recordsPerPage: 100
        })
            .then((response) => {
                setLoading(false);
                setEndTimeList(response.data.content);
            })
    }

    return (
        <React.Fragment>
            <CssBaseline />
            { comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth>
                    <Controller

                        render={({
                            field: { onChange } }) => (
                            <Autocomplete

                                onChange={(event, newValue) => {
                                    onChange(newValue)

                                    if (typeof newValue === 'string') {

                                        if (newValue != null) {
                                            setComboValue({
                                                newValue,
                                            });
                                        }
                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setComboValue({
                                            title: newValue.inputValue,
                                        });

                                    }
                                    else if (newValue !== null) {

                                        setComboValue(newValue);
                                    } else if (newValue === null) {
                                        setComboValue('')
                                    } else {
                                        setComboValue(newValue);
                                    }
                                }}
                                selectOnFocus
                                handleHomeEndKeys
                                value={comboValue.endTime || null}
                                autoHighlight
                                onOpen={() => {
                                    loadEndTimeData('');
                                }}
                                options={endTimeData}
                                getOptionLabel={(option) => {

                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.endTime;
                                }}
                                getoptionselected={(option, value) => option.endTime === value}
                                disabled={comboProperty.isDisabled}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        required
                                        autoFocus={comboProperty.isAutofocus}
                                        name={comboProperty.name}
                                        size='small'
                                        label={comboProperty.label}
                                        variant={comboProperty.variant}
                                        InputLabelProps={{
                                            shrink: true,
                                            classes: {
                                                asterisk: classes.asterisk
                                            }
                                        }}
                                    />
                                )}

                            />
                        )}

                        name={comboProperty.name}
                        rules={{ required: comboProperty.isRequired }}
                        control={control}
                        defaultValue={{ endTime: '' }}
                    />
                    {comboProperty.isDisabled && (<FormHelperText>{comboProperty.label} <FormattedMessage id="textfield.error.cannotbeeditedmessage" defaultMessage="can't be edited" /></FormHelperText>)}
                    <div className={classes.error}>
                        {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                            <FormHelperText className={classes.error}>{comboProperty.label} <FormattedMessage id="textfield.error.isrequirededmessage" defaultMessage="is required" /></FormHelperText>
                        )}
                    </div>
                </FormControl>
            </Grid>)}

            { !comboProperty.isHookFormType && (<Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "3px" }}>
                <FormControl fullWidth>
                    <Autocomplete
                        fullWidth
                        openOnFocus={true}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {

                                if (newValue != null) {
                                    setComboValue({
                                        newValue,
                                    });
                                }
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setComboValue({
                                    title: newValue.inputValue,
                                });

                            }
                            else if (newValue !== null) {

                                setComboValue(newValue);
                            } else if (newValue === null) {
                                setComboValue('')
                            } else {
                                setComboValue(newValue);
                            }
                        }}
                        selectOnFocus
                        loadingText="Loading..."
                        loading={isLoading}
                        handleHomeEndKeys
                        value={comboValue.endTime || null}
                        autoHighlight
                        onOpen={() => {
                            loadEndTimeData('');
                        }}
                        options={endTimeData}
                        getOptionLabel={(option) => {

                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.endTime;
                        }}
                        getoptionselected={(option, value) => option.endTime === value}
                        disabled={comboProperty.isDisabled}
                        renderInput={params => (
                            <Tooltip title={comboValue.endTime || ''}>
                                <TextField
                                    {...params}
                                    autoFocus={comboProperty.isAutofocus}
                                    name={comboProperty.name}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(ev) => {
                                        if (
                                            ev.target.value !== "" ||
                                            ev.target.value !== null
                                        ) {
                                            loadEndTimeData(ev.target.value);
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {isLoading ?
                                                    <CircularProgress color="inherit" size={20} />
                                                    : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    size='small'
                                    label={comboProperty.label + (comboProperty.isRequired ? ' *' : '')}
                                    variant={comboProperty.variant}
                                />
                            </Tooltip>
                        )}

                    />
                </FormControl>
            </Grid>)}

        </React.Fragment>
    );
};

EndTimeCombo.propTypes = {
    comboProperty: PropTypes.object.isRequired,
    control: PropTypes.any,
    errors: PropTypes.any,
    comboValue: PropTypes.any.isRequired,
    setComboValue: PropTypes.func.isRequired
};

export default EndTimeCombo;

