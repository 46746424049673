import { DataGridPro, getGridStringOperators, GridToolbarColumnsButton, GridToolbarDensitySelector, LicenseInfo } from '@mui/x-data-grid-pro';
import React from 'react';
import { useContext, useEffect, useState, useMemo } from "react";
import useStyles from "../common/CommonStyle/CommonStyle";
import GridTextLocalization from "../common/GridTextLocalization/GridTextLocalization";
import IconEmailServerConfig from '@mui/icons-material/Storage';
import { SupportedLanguageDetails } from "../common/SupportedLanguageDetails/SupportedLanguageDetails";
import { FormattedMessage, useIntl } from "react-intl";
import { CommonService } from "../../services";
import { Badge, Alert, Button, CssBaseline, Grid, IconButton, Paper, Snackbar, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import CustomBreadCrumb from "../common/CustomBreadCrumb";
import CreateIcon from '@mui/icons-material/Add';
import CommonGridPagination from "../common/CommonGridPagination/CommonGridPagination";
import RefreshIcon from '@mui/icons-material/Refresh';
import ChipInfoList from "../common/ChipInfoList";
import { CustomFilters } from "../common/CustomFilters/CustomFilters";
import { LoggedInUserDetails } from "../common/LoggedInUserDetails/LoggedInUserDetails";
import { ModuleAccessPermissionKey } from "../../Constants/ModuleAccessKey";
import { GlobalEdiApiConstants } from "../../Constants/GlobalEdiApiConstants";
import ViewIcon from '@mui/icons-material/Visibility';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import InfoIcon from '@mui/icons-material/Info';
import DialogComponent from "./DialogComponent/DialogComponent";
import { manageUserPreferences } from '../../services/manageUserPreferences';
import CustomFilterComponent, { GetFilterableColumns } from "../common/CustomFilterComponent";
import { FilterList } from "@mui/icons-material";

const columnFieldsName = {
    smtpHost: 'smtpHost',
    smtpPortNumber: 'smtpPortNumber',
    fromEmailId: 'fromEmailId',
    fromEmailName: 'fromEmailName',
    replyToEmailId: 'replyToEmailId',
    secured: 'secured',
    verifyCertificate: 'verifyCertificate',
    smtpUserName: 'smtpUserName',
    smtpPassword: 'smtpPassword',
    active: 'active',
    createdTime: 'createdTime',
    createdBy: 'createdBy',
    updatedTime: 'updatedTime',
    updatedBy: 'updatedBy',
    erpOrgCode: 'organization.erpOrgCode',
    erpEntityCode: 'entity.erpEntityCode',
    isMaster: 'isMaster',
    maxConcurrentConCount: 'maxConcurrentConCount',
    maxAttachmentSize: 'maxAttachmentSize'
}

function EmailServerConfig() {
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const moduleAccessKeyPermission = ModuleAccessPermissionKey();
    const localtext = GridTextLocalization();
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const emailServerConfigModuleAccess = loggedInUserInfo.roleModuleAccess[moduleAccessKeyPermission.emailServerConfig];
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const smallScreen = useMediaQuery(theme.breakpoints.down('890'))
    const [refreshTable, setRefreshTable] = useState(false);
    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [chipInfoList, setChipInfoList] = useState([]);
    const [basicmode, setbasicmode] = React.useState('');
    const [emailServerConfigObjectToBeEditedOrView, setEmailServerConfigObjectToBeEditedOrView] = useState([]);;
    const [openEmailServerConfigDialog, setOpenEmailServerConfigDialog] = useState(false);
    const [tableData, setTableData] = useState([]);
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    const [paramBody, setParamBody] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.emailServerConfig);
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var defaultEntity = loggedInUserInfo.defaultEntity;

    const defaulSelectedFilters = defaultEntity !== '' ?
        [{ columnField: columnFieldsName.erpEntityCode, headerName: "Ent. Code", id: 0, operatorValue: "contains", value: defaultEntity }]
        : [];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);
    const pageLabelsConstantsAndMessages = {
        label: {

            active: 'Active',
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },

    };
    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;

    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);
    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.messageconfiguration" defaultMessage="Message Configuration" />,
            iconName: IconMessageConfiguration,
        },
        {
            path: '/emailServerConfig',
            name: <FormattedMessage id="common.modue.breadcrumb.columnname.emailserverconfig" defaultMessage="Email Server Config" />,
            iconName: IconEmailServerConfig,
        }

    ];
    //start
    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState(preference.columnsPinned);
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }

    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentList[1].name.props.id)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {

            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);

    //end


    const columnsForEmailServerConfig =
        [
            {
                field: 'action',
                headerName: intl.formatMessage({ id: "columnname.action.label" }),
                width: 130,
                sortable: false,
                hide: false,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) =>
                (
                    <div>
                        {(emailServerConfigModuleAccess !== undefined ? emailServerConfigModuleAccess.editFlag : false) &&
                            (<Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.edit" defaultMessage="Edit" />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    setOpenEmailServerConfigDialog(true)
                                    setbasicmode('edit')
                                    setEmailServerConfigObjectToBeEditedOrView(cellParams.row)
                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar}>
                                        <EditIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}

                        {(emailServerConfigModuleAccess !== undefined ? emailServerConfigModuleAccess.readFlag : false) &&
                            (<Tooltip title={<FormattedMessage id="common.button.view.breadcrumb.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.readButton} />}
                                placement='bottom' className={classes.actionButton} onClick={(() => {
                                    setOpenEmailServerConfigDialog(true)
                                    setbasicmode('view')
                                    setEmailServerConfigObjectToBeEditedOrView(cellParams.row)

                                })}>
                                <span>
                                    <IconButton className={classes.customeToolbar}>
                                        <ViewIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                    </div>
                )
            },
            {
                field: columnFieldsName.erpEntityCode,
                headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 85,
                sortable: false,
                type: 'string',
                hide: false,
                filterable: true,
                valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.smtpHost,
                headerName: intl.formatMessage({ id: "columnname.smtpHost.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 200,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.smtpPortNumber,
                headerName: intl.formatMessage({ id: "columnname.smtpPortNumber.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 130,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.maxConcurrentConCount,
                headerName: intl.formatMessage({ id: "columnname.maxConcurrentConCount.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: false,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.maxAttachmentSize,
                headerName: intl.formatMessage({ id: "columnname.maxAttachmentSize.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: false,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.fromEmailId,
                headerName: intl.formatMessage({ id: "columnname.fromEmailId.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 200,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.fromEmailName,
                width: 200,
                headerName: intl.formatMessage({ id: "columnname.fromEmailName.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {

                field: columnFieldsName.replyToEmailId,
                width: 140,
                headerName: intl.formatMessage({ id: "columnname.replyToEmailId.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns,

            },
            {
                field: columnFieldsName.secured,
                headerName: intl.formatMessage({ id: "columnname.secured.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 140,
                sortable: false,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.customRenderYesOrNoColumn

            },
            {
                field: columnFieldsName.verifyCertificate,
                headerName: intl.formatMessage({ id: "columnname.verifyCertificate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 140,
                sortable: false,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.customRenderYesOrNoColumn
            },

            {
                field: columnFieldsName.smtpUserName,
                width: 200,
                headerName: intl.formatMessage({ id: "columnname.smtpUserName.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,

            },
            {
                field: columnFieldsName.smtpPassword,
                width: 190,
                headerName: intl.formatMessage({ id: "columnname.smtpPassword.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                filterable: false,
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.active,
                headerName: intl.formatMessage({ id: "columnname.status.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 140,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.customRenderStatusColumn
            },
            {
                field: columnFieldsName.createdBy,
                headerName: intl.formatMessage({ id: "columnname.columnnamecreatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 150,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.createdTime,
                headerName: intl.formatMessage({ id: "columnname.createddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.createdDtDisp}</span>)
            },

            {
                field: columnFieldsName.updatedBy,
                headerName: intl.formatMessage({ id: "columnname.updatedby.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 155,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.updatedTime,
                headerName: intl.formatMessage({ id: "columnname.updateddate.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.lastUpdatedDtDisp}</span>)
            },
            {
                field: columnFieldsName.erpOrgCode,
                headerName: intl.formatMessage({ id: "columnname.organizationcode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 140,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                valueGetter: (params) => `${params.row.organization.erpOrgCode}`,
                renderHeader: CommonService.customRenderHeaderColumns
            },

            {
                field: columnFieldsName.isMaster,
                headerName: intl.formatMessage({ id: "columnname.isMaster.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 140,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.customRenderYesOrNoColumn
            },

        ]


    const [columns, setColumns] = useState([]);

    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentList[1].name.props.id,
                tabName: componentList[1].name.props.id,
                userId: 0,
                organization: {},
                entity: {}
            }
        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(columnsForEmailServerConfig);
                savePreference("visibility", columnsForEmailServerConfig);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, columnsForEmailServerConfig);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let entityCode = '';
                let smtpUserName = '';
                let smtpHost = '';
                let smtpPortNumber = '';
                let secured = '';
                let verifyCertificate = '';
                let active = '';
                let isMaster = '';
                let maxConcurrentConCount = '';
                let maxAttachmentSize = '';
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'entity.erpEntityCode':
                            entityCode = columns
                            break;
                        case 'smtpUserName':
                            smtpUserName = columns
                            break;
                        case 'smtpHost':
                            smtpHost = columns
                            break;
                        case 'smtpPortNumber':
                            smtpPortNumber = columns
                            break;
                        case 'secured':
                            secured = columns
                            break;
                        case 'verifyCertificate':
                            verifyCertificate = columns
                            break;
                        case 'active':
                            active = columns
                            break;
                        case 'isMaster':
                            isMaster = columns
                            break;
                        case 'maxConcurrentConCount':
                            maxConcurrentConCount = columns
                            break;
                        case 'maxAttachmentSize':
                            maxAttachmentSize = columns
                            break;
                    }
                })
                var filterBody = {
                    entity: {
                        erpEntityCode: entityCode.value,
                    },
                    smtpUserName: smtpUserName.value,
                    smtpHost: smtpHost.value,
                    smtpPortNumber: smtpPortNumber.value,
                    secured: secured.value === 'Yes' ? true : secured.value === 'No' ? false : null,
                    verifyCertificate: verifyCertificate.value === 'Yes' ? true : verifyCertificate.value === 'No' ? false : null,
                    recordActiveStatus: active.value === '' ? 'All' : active.value === 'Active' ? true : active.value === 'In-Active' ? false : 'All',
                    isMaster: isMaster.value === 'Yes' ? true : isMaster.value === 'No' ? false : null,
                    maxAttachmentSize: maxAttachmentSize.value,
                    maxConcurrentConCount: maxConcurrentConCount.value
                };
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0)
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });
    }, [selectedLanguage])

    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.emailServerConfig, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [paramBody, refreshTable])



    if (columns.length > 0) {

        const orgCodeColumn = columns.find((column) => column.field === columnFieldsName.erpOrgCode);
        const orgCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpOrgCode);

        const orgCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgCodeInputValue,
            }),
        );

        columns[orgCodeColIndex] = {
            ...orgCodeColumn,
            filterOperators: orgCodeFilterOperators,
        };

        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };


        const statusColumn = columns.find((column) => column.field === columnFieldsName.active);
        const statusColIndex = columns.findIndex((col) => col.field === columnFieldsName.active);

        const statusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.StatusColInputValue,
            }),
        );

        columns[statusColIndex] = {
            ...statusColumn,
            filterOperators: statusFilterOperators,
        };

        const securedColumn = columns.find((column) => column.field === columnFieldsName.secured);
        const securedColIndex = columns.findIndex((col) => col.field === columnFieldsName.secured);

        const securedFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.YesOrNoInputValue,
            }),
        );

        columns[securedColIndex] = {
            ...securedColumn,
            filterOperators: securedFilterOperators,
        };

        const isMasterColumn = columns.find((column) => column.field === columnFieldsName.isMaster);
        const isMasterColIndex = columns.findIndex((col) => col.field === columnFieldsName.isMaster);

        const isMasterFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.YesOrNoInputValue,
            }),
        );

        columns[isMasterColIndex] = {
            ...isMasterColumn,
            filterOperators: isMasterFilterOperators,
        };

        const verifyCertificateColumn = columns.find((column) => column.field === columnFieldsName.verifyCertificate);
        const verifyCertificateColIndex = columns.findIndex((col) => col.field === columnFieldsName.verifyCertificate);

        const verifyCertificateFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.YesOrNoInputValue,
            }),
        );

        columns[verifyCertificateColIndex] = {
            ...verifyCertificateColumn,
            filterOperators: verifyCertificateFilterOperators,
        };

    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const applyFilter = () => {
        let entityCode = '';
        let smtpUserName = '';
        let smtpHost = '';
        let smtpPortNumber = '';
        let secured = '';
        let verifyCertificate = '';
        let active = '';
        let isMaster = '';
        let maxConcurrentConCount = '';
        let maxAttachmentSize = '';
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'entity.erpEntityCode':
                    entityCode = columns
                    break;
                case 'smtpUserName':
                    smtpUserName = columns
                    break;
                case 'smtpHost':
                    smtpHost = columns
                    break;
                case 'smtpPortNumber':
                    smtpPortNumber = columns
                    break;
                case 'secured':
                    secured = columns
                    break;
                case 'verifyCertificate':
                    verifyCertificate = columns
                    break;
                case 'active':
                    active = columns
                    break;
                case 'isMaster':
                    isMaster = columns
                    break;
                case 'maxConcurrentConCount':
                    maxConcurrentConCount = columns
                    break;
                case 'maxAttachmentSize':
                    maxAttachmentSize = columns
                    break;
            }
        })

        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);
        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    entity: {
                        erpEntityCode: entityCode.value,
                    },
                    smtpUserName: smtpUserName.value,
                    smtpHost: smtpHost.value,
                    smtpPortNumber: smtpPortNumber.value,
                    maxConcurrentConCount: maxConcurrentConCount.value,
                    maxAttachmentSize: maxAttachmentSize.value,
                    secured: secured.value === 'Yes' ? true : secured.value === 'No' ? false : null,
                    verifyCertificate: verifyCertificate.value === 'Yes' ? true : verifyCertificate.value === 'No' ? false : null,
                    recordActiveStatus: active.value === '' ? 'All' : active.value === 'Active' ? true : active.value === 'In-Active' ? false : 'All',
                    isMaster: isMaster.value === 'Yes' ? true : isMaster.value === 'No' ? false : null,
                },
            }
        });
    }



    useEffect(() => {
        if (intialParamBodyApiCall)
            setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };

    const closeEmailServerConfigDialog = (confirmed) => {
        if (confirmed) {
            setRefreshTable(!refreshTable);
        }
        setOpenEmailServerConfigDialog(false);
        setbasicmode('')
    }

    const onError = errors => {
        setAlertBoxState({ ...alertBoxState, openAlert: true, message: "Please fill all the required fields to Submit", severity: "error", err: true }
        )
    }



    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container className={classes.rootForRole}>
                <Grid item container justify='flex-start' alignItems='center' xs={6} sm={8} md={6} lg={6} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6} sm={4} md={6} lg={6} className={classes.topGrid}>
                    {(emailServerConfigModuleAccess !== undefined ? emailServerConfigModuleAccess.createFlag : false) && (
                        <Button size='small'
                            variant="contained" color="primary" className={classes.createButton} elevation={2} endIcon={smallScreen ? null : <CreateIcon />}
                            onClick={(() => {
                                setbasicmode('create')
                                setOpenEmailServerConfigDialog(true)
                                setEmailServerConfigObjectToBeEditedOrView({})
                            })}>
                            {smallScreen ? <CreateIcon /> : <FormattedMessage id="common.button.create.breadcrumb.tooltip" defaultMessage="Create" />}
                        </Button>)}
                </Grid>
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter} defaulSelectedFilters={defaulSelectedFilters} />
                <ChipInfoList chipInfoList={chipInfoList} />
                <DialogComponent
                    open={openEmailServerConfigDialog}
                    onClose={closeEmailServerConfigDialog}
                    title={componentList}
                    emailServerConfigObjectToBeEditedOrView={emailServerConfigObjectToBeEditedOrView}
                    mode={basicmode}
                    warningMessage={<Grid container justify="flex-start" alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.textfieldSpacing} >
                        {(basicmode === 'create' || basicmode === 'edit') ?
                            <InfoIcon color="primary" /> : null}
                        {(basicmode === 'create' || basicmode === 'edit') ?
                            <Typography justify="flex-start" style={{ fontSize: 12, marginLeft: '1%' }}><FormattedMessage id="textfield.placeholder.dialogActions" defaultMessage="All the '*' marked fields are mandatory to submit the form" />
                            </Typography> : null}
                    </Grid>}
                />

                <Paper elevation={3} className={classes.gridHeightAndWidthForRole}>
                    <div style={{ height: '100%', width: '100%' }}>

                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            showToolbar
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableMultipleColumnsSorting={true}
                            hideFooter={true}
                            localeText={localtext}
                            density="compact"
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            onSortModelChange={(params) => {
                                let sortModel = params[0];
                                if (sortModel !== undefined) {
                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                }
                            }}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}  >
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justify="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip"
                                                        defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'>
                                                        <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterList />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            hideFooterSelectedRowCount={true}
                            
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortingOrder={['desc', 'asc']}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            filterMode="server"
                        />
                    </div>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}

export default EmailServerConfig;
